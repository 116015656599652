import { HttpClient } from '@angular/common/http';
import { Injectable, NO_ERRORS_SCHEMA } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Helpers } from './helpers';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AiGeneratorService  {
  
  private API_URL:string = "";

  constructor(
    private http: HttpClient
    ) { }

  public genererCttpUsingAI(body:any):Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.post('/api/v1/ai/generate-cctp',body,options)         
    .pipe(map((response: any) => {
      return response.data.data;
    }));
  }
  public textCompletion(body:any):Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.post('/api/v1/ai/text-completion',body,options)
    .pipe(map((response:any) => { 
      return response.data.data;
    }));
  }
  
}
