<div class="container-fluid welcome-screen-wrapper">
  <div class="row flex justify-center" style="padding-top: 120px;">

    <!-- View 1 -->
    <div class="view-1 text-center" *ngIf="selectedWelcomeScreen == 'screen_1'" style="width:671px;">
      <div class="upper-logo-wrapper">
        <img src="/assets/acv-icons/hands.svg" />
      </div>
      <p class=" ag-text-down-river text-start first-p">
        Maîtrisez simplement l<span class="green-color">'impact carbone</span>  de votre projet      
      </p>
      <p class="ag-para-1 text-start" style="font-size:18px;">
        Evaluez aisément l'impact de votre projet & obtenez votre <span class="green-color">attestation RE2020</span></p>
      <p class="ag-para-1 text-start" style="font-size:18px;"> grâce à un abonnement Max ou Intégral</p>
      <div class="margin-top-49 text-start">
        <button (click)="redirectToPricingTable()" class="ag-btn-bordered-sm" style="padding:5px 24px 5px 24px">Voir les licences</button>
      </div>
    </div>
    <!-- END View 1 -->


    <!-- View 2 -->
    <div class="col-md-5 col-md-offset-4 view-2 text-center" *ngIf="selectedWelcomeScreen == 'screen_2'">
      <div class="upper-logo-wrapper">
        <img src="/assets/acv-icons/hands-leaf.svg" />
      </div>
      <p class="ag-heading-h2 ag-text-down-river text-left">
      <span class="ag-text-success-green"><b>L’Analyse de Cycle de Vie</b></span> est plus facile que ce que<br> vous pensez.
      </p>
      <p class="text-left ag-para-1">
        Pour faire le calcul de son Analyse de Cycle de Vie et obtenir son <b>attestation RE2020</b>,  il suffit de remplir une série de questionnaire qui concerne les composants, l’énergie, l’eau et les dépenses energétiques durant le chantier.
      </p>
      <p class="text-left ag-para-1">
        Nous vous recommandons d’importer votre <b>fichier RSET</b>, en effet il vous permet de compléter XX % des champs présents dans le questionnaire et d’obtenir l’attestation conforme aux normes.
      </p>
      <div class="text-left margin-top-49">
        <button class="btn btn-purple green-btn" [disabled]="isDemo"> 
          <img src="/assets/acv-icons/download-green.svg" />
          Importer mon RSET (.xml)
        </button>
      </div>

      <div class="text-right margin-top-100">
        <button class="ag-btn-dark-green">Je n’ai pas de RSET</button>
      </div>
    </div>
    <!-- END View 2 -->





  </div>
</div>
