<div class="row all">
  <ng-container *ngIf="facture$ | async as facture">
    <div *ngIf="flags$ | async as flags">

    <div *ngIf="flags.loadingData" class="spinner-center">
      <app-spinner [size]="'small'"></app-spinner>
    </div>

    <!--div [@appear]='state' class="toast">
      <i class="fa fa-floppy-o" aria-hidden="true"></i> Enregistré
    </!--div-->

      <div *ngIf="!flags.loadingData" id="tool-bar" class="row" [ngClass]='{sticky : isSticky}'>

        <div class="bonjour"></div>
        <div class="go-back">
          <span (click)='goBack()'><i class="fa fa-arrow-circle-left" style="width: 20px"></i>page précédente</span>
        </div>
        <div *ngIf="facture.status == 'answered'" class="doc alert alert-info">
          <strong>Info!</strong> Ce document est disponible en lecture seule !
        </div>
        <span *ngIf="!flags.done && facture.status != 'answered'">
          <button  (click)="saveFactureUpdate()" class="btn-boo btn-outline-success">
            <i *ngIf="flags.saving" class="fa fa-spinner fa-spin fa-fw"></i>
            <i *ngIf="!flags.saving" class="fa fa-floppy-o" aria-hidden="true"></i> Enregistrer
          </button>
          <button  *ngIf="!flags.done" class="btn-boo btn-outline-info"  (click)="scrollTo('btn-end-and-send')">
            <i class="fa fa-share-square-o" aria-hidden="true"></i>Terminer
          </button>
        </span>
        <button *ngIf="facture.architectResponse == 'accepted'" class="btn-boo btn-outline-success"  (click)="downloadFacture(facture)">
          <i class="fa fa-download" aria-hidden="true"></i>Télécharger la situation
        </button>
      </div>

      <div *ngIf="!flags.loadingData" class="container full-width">
        <div class="row">
          <div class="col-md-12" >
            <div class="row cont">
              <app-table-facture
                                [lots]="facture?.data"
                                [isFirst]="facture?.isFirst"
                                [status]="facture?.status"
                                [response]="facture?.architectResponse"
                                mode="archi" ></app-table-facture>
            </div>
        <!--********************Envoyer à l'entreprise ***********************-->
            <div id="send-to-architect" class="row floating-card">
              <!--********************Sommaire Artisan ***********************-->
              <h1>Récapitulatif</h1>
              <table class="recap-table">
                <tr>
                  <td class="recap-desc"></td>
                  <td class="trecap"><span class="rec-title">Avoir HT</span></td>
                  <td class="trecap"><span class="rec-title">Montant HT</span></td>
                  <td class="trecap"><span class="rec-title">Montant TTC</span></td>
                  <td class="trecap" *ngIf="!facture.isFirst"><span class="rec-title">Avancement préc</span></td>
                  <td class="trecap"><span class="rec-title">Avancement</span></td>
                  <td class="trecap"><span class="rec-title">Situation HT</span></td>
                  <td class="trecap"><span class="rec-title">Situation TTC</span></td>
                </tr>
                <tr (click)="scrollTo(lot.id)" class="recap-pill" *ngFor="let lot of facture?.data,let loti = index">
                  <td class="recap-desc">
                    <img *ngIf="(lot.icon||'') != ''" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" alt="" height="15">
                    <span>{{lot.indexLot}} - {{lot.title}}</span>
                  </td>
                  <td class="grecap">
                    <span class="total-recap">{{ lot.avoir | number}} €</span>
                  </td>
                  <td class="trecap">
                    <span class="total-recap">{{ lot.total | number}} €</span>
                  </td>
                  <td class="trecap">
                    <span class="total-recap">{{ lot.totalttc | number}} €</span>
                  </td>
                  <td class="trecap" *ngIf="!facture.isFirst">
                    <span class="total-recap">{{ lot.lastAcompte | number}} %</span>
                  </td>
                  <td class="frecap">
                    <span class="total-recap">{{ lot.acompte | number}} %</span>
                  </td>
                  <td class="frecap">
                    <span class="total-recap">{{ lot.devis | number}} €</span>
                  </td>
                  <td class="frecap">
                    <span class="total-recap">{{ lot.devisTtc | number}} €</span>
                  </td>
                </tr>
                <tr class="recap-pill">
                  <td class="recap-desc">
                    <h1>Total</h1>
                  </td>
                  <td class="grecap">
                    <span class="total-recap">{{facture.totalAvoir| number}} €</span>
                  </td>
                  <td class="trecap">
                    <span class="total-recap">{{facture.totalHt| number}} €</span>
                  </td>
                  <td class="trecap">
                    <span class="total-recap">{{facture.totalTtc| number}} €</span>
                  </td>
                  <td class="trecap" *ngIf="!facture.isFirst">
                    <span class="total-recap">{{facture.lastTotalAcompte  | number}} %</span>
                  </td>
                  <td class="frecap">
                    <span class="total-recap">{{facture.totalAcompte  | number}} %</span>
                  </td>
                  <td class="frecap">
                    <span class="total-recap">{{facture.totalDevis  | number}} €</span>
                  </td>
                  <td class="frecap">
                    <span class="total-recap">{{facture.totalDevisTtc  | number}} €</span>
                  </td>
                </tr>
            </table>
              <hr>
              <!--********************Envoyer à l'entreprise ***********************-->
              <div class="row" *ngIf="!flags.done && facture.status != 'answered'">
                <div class="col-md-3">

                </div>
                <div class="col-md-6">
                  <textarea placeholder="Saisir un message à destination de l'artisan" name="name" rows="10" cols="80" [(ngModel)]="artisanMessage"></textarea>
                </div>
                <div class="col-md-12">
                  <div class="col-md-2"></div>
                  <div class="col-md-3">
                    <button class="btn-danger-color" (click)="rejectFacture()">
                      <div id="btn-end-and-send" *ngIf="!flags.finishing">Refuser la situation</div>
                      <app-spinner *ngIf="flags.finishing"></app-spinner>
                    </button>
                  </div>
                  <div class="col-md-2"></div>
                  <div class="col-md-3">
                    <button class="btn-success-color" (click)="acceptFacture()">
                      <div id="btn-end-and-send" *ngIf="!flags.finishing">Accepter la situation</div>
                      <app-spinner *ngIf="flags.finishing"></app-spinner>
                    </button>
                  </div>
                  <div class="col-md-2"></div>
                </div>
              </div>
              <div class="row" *ngIf="flags.done">
                <div class="form-container check-mark-animation" >
                  <div class="check_mark">
                    <div class="sa-icon sa-success animate sa-white" [ngClass]="{'hide': !flags.done}" >
                      <span class="sa-line sa-tip animateSuccessTip"></span>
                      <span class="sa-line sa-long animateSuccessLong"></span>
                      <div class="sa-placeholder"></div>
                      <div class="sa-fix sa-white-back"></div>
                    </div>
                  </div>
                  <h2 class="center">Réponse envoyée à l'artisan !</h2>
                  <button *ngIf="facture.architectResponse == 'accepted'" (click)="downloadFacture(facture)" class="center-btn btn-primary-border">
                    <div>Exporter la situation</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!-- Need Subscriiption -->
<app-need-subscription-popup-2></app-need-subscription-popup-2>
<!-- Errors on the Document Modal -->
<app-documents-errors-modal [messageObject]="documentsErrorInfo"></app-documents-errors-modal>
<!-- Errors on the Document Modal -->
