<div class="docs-main-wrapper">

  <!-- Logo Card -->
  <div class="logo-card-container white-card margin-bottom-16">
    
    <div class="choose-logo text-center" [ngStyle]="{'display': !docsData?.logo?.cdnUrl ? 'block' : 'none' }">
      <img src="/assets/choose-logo-image.svg" class="pointer" (click)="openPfileUpload()"/>
      <!-- <p-fileUpload *ngIf="!docsData?.logo?.cdnUrl" mode="basic" chooseLabel="Ajouter le logo de l’entreprise" name="demo1" url="/api/v1/upload-file?type=assuranceFile" [auto]="true"
        (onUpload)="onUploadArtisanLogo($event)" accept="image/*"></p-fileUpload> -->
    </div>

    <!-- Show -->
    <div class="logo-img text-center" *ngIf="docsData?.logo?.cdnUrl">
      <img *ngIf="!authService.currentEnvironment" [src]="docsData?.logo?.cdnUrl" id="logo">
      <img *ngIf="authService.currentEnvironment" [src]="'api/v1/static/'+docsData?.logo?.cdnUrl" id="logo">
    </div>

    <!-- Add Logo Button -->
    <div class="relative add-logo-container margin-top-24 block-item">

      <div class="choose-logo-input-container margin-top-24">
        <input id="artisan-logo"
          type="hidden"/>
      </div>

      <div class="ag-para-1 add-logo-btn text-center">
        <div *ngIf="!docsData?.logo?.cdnUrl" >
          <p-fileUpload mode="basic" #fileUpload1 chooseLabel="Ajouter le logo de l’entreprise" name="demo1" url="/api/v1/upload-file?type=logo" [auto]="true"
          (onUpload)="onUploadArtisanLogo($event)" accept="image/*"></p-fileUpload>

        </div>
        <div *ngIf="docsData?.logo?.cdnUrl" >
          <p-fileUpload  mode="basic" chooseLabel="Modifier le logo de l’entreprise" name="demo1" url="/api/v1/upload-file?type=logo" [auto]="true"
          (onUpload)="onUploadArtisanLogo($event)" accept="image/*"></p-fileUpload>
        </div>
      </div>

    </div>
    <!-- END Add Logo Button -->

  </div>
  <!-- END Logo Card -->

  <!-- Documents Card -->
  <div class="docs-card-container white-card horizontal-items">

    
    <!-- Attestation d’assurance -->
    <div class="document-section width-50 margin-bottom-70">
      <h5 class="ag-heading-h5 ag-weight-600">Attestation d’assurance</h5>

      <div class="upload-input-container" [ngClass]="{'remove-item-from-view': docsData?.assuranceFile?.name}">
        <input id="assuranceFile" type="hidden" role="uploadcare-uploader" data-preview-step=true name="assuranceFile" />
      </div>      

      <div class="input-wrapper text-center" [ngClass]="{'remove-item-from-view': docsData?.assuranceFile?.name}">
        <p-fileUpload mode="basic" chooseLabel="Sélectionner un fichier" name="demo1" url="/api/v1/upload-file?type=assuranceFile" [auto]="true"
        (onUpload)="onUploadAssuranceFile($event)"></p-fileUpload>
      </div>

      <div class="files-list" *ngIf="docsData?.assuranceFile?.name">
        
        <div class="single-file horizontal-items margin-bottom-5">
          <p class="width-70 ag-para-1 flex-vertical justify-center">
            <a *ngIf="!authService.currentEnvironment" [href]="docsData?.assuranceFile?.cdnUrl" target="_blank">{{ docsData?.assuranceFile?.name }}</a>
            <a *ngIf="authService.currentEnvironment" [href]="'api/v1/static/'+docsData?.assuranceFile?.cdnUrl" target="_blank">{{ docsData?.assuranceFile?.name }}</a>
          </p>
          <img src="/assets/artisan-booster/remove-icon.svg" class="width-30 pointer" (click)="removeDocument('assuranceFile')">
        </div>      

      </div>
    </div>
    <!-- END Attestation d’assurance -->

    <!-- Extrait KBIS -->
    <div class="document-section width-50 margin-bottom-70">
      <h5 class="ag-heading-h5 ag-weight-600">Extrait KBIS</h5>

      <div class="upload-input-container">
        <input id="kbisFile" type="hidden" />
      </div>

      <div class="input-wrapper text-center">
        <p-fileUpload mode="basic" chooseLabel="Sélectionner un fichier" name="demo1" url="/api/v1/upload-file?type=kbis" [auto]="true"
        (onUpload)="onUploadKbisFiles($event)"></p-fileUpload>
      </div>

      <div class="files-list" *ngIf="docsData?.kbis?.name">
        
        <div class="single-file horizontal-items margin-bottom-5">
          <p class="width-70 ag-para-1 flex-vertical justify-center">
            <a *ngIf="!authService.currentEnvironment" [href]="docsData?.kbis?.cdnUrl" target="_blank">{{ docsData?.kbis?.name }}</a>
            <a *ngIf="authService.currentEnvironment" [href]="'api/v1/static/'+docsData?.kbis?.cdnUrl" target="_blank">{{ docsData?.kbis?.name }}</a>
          </p>
          <img src="/assets/artisan-booster/remove-icon.svg" class="width-30 pointer" (click)="removeDocument('kbis')">
        </div>     

      </div>
    </div>
    <!-- END Extrait KBIS -->

    <!-- Attestation de certifications Qualibat, RGE... -->
    <div class="document-section width-50 margin-bottom-30">
      <h5 class="ag-heading-h5 ag-weight-600">Attestations de certification (Qualibat, RGE...)</h5>
      
      <div class="upload-input-container">
        <input id="attestations-certification" type="hidden"/>
      </div>
      <div class="input-wrapper text-center">
        <p-fileUpload chooseLabel="Sélectionner un fichier" name="demo2" url="/api/v1/upload-file?type=attestationsCertification" (onUpload)="onUploadAttestationsCertification($event)" [multiple]="true" [auto]="true" mode="basic"
        maxFileSize="1000000"></p-fileUpload>
      </div>

      <div class="files-list">
        
        <div class="single-file horizontal-items margin-bottom-5" *ngFor="let file of docsData.attestationsCertification; let i = index;">
          <p class="width-70 ag-para-1 flex-vertical justify-center">
            <a *ngIf="!authService.currentEnvironment" [href]="file.cdnUrl" target="_blank">{{ file.name }}</a>
            <a *ngIf="authService.currentEnvironment" [href]="'api/v1/static/'+file.cdnUrl" target="_blank">{{ file.name }}</a>
          </p>
          <img src="/assets/artisan-booster/remove-icon.svg" class="width-30 pointer" (click)="removeDocument('attestationsCertification', i)">
        </div>      

      </div>
    </div>
    <!-- END Attestation de certifications Qualibat, RGE... -->
 
    <!-- Autre -->
    <div class="document-section width-50 margin-bottom-30">
      <h5 class="ag-heading-h5 ag-weight-600">Autre</h5>

      <div class="upload-input-container">
        <input id="portfolio-photo" type="hidden" />
      </div>

      <div class="input-wrapper text-center">
        <p-fileUpload mode="basic" chooseLabel="Sélectionner un fichier" name="demo2" url="/api/v1/upload-file?type=file" [auto]="true" [multiple]="true"
        (onUpload)="onUploadOthers($event)"></p-fileUpload>
      </div>

      <div class="files-list">
        
        <div class="single-file horizontal-items margin-bottom-5" *ngFor="let file of docsData.files; let i =index;">
          <p class="width-70 ag-para-1 flex-vertical justify-center">
            <a *ngIf="!authService.currentEnvironment" [href]="file.cdnUrl" target="_blank" class="">{{ file.name }}</a>
            <a *ngIf="authService.currentEnvironment" [href]="'api/v1/static/'+file.cdnUrl" target="_blank" class="">{{ file.name }}</a>
          </p>
          <img src="/assets/artisan-booster/remove-icon.svg" class="width-30 pointer" (click)="removeDocument('files', i)">
        </div>   

      </div>
    </div>
    <!-- END Autre -->

  </div>
  <!-- END Documents Card -->


  <div class="DashboardCouvertureContainer" style="display:none;"></div>

</div>