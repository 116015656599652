import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
/// services
import { AuthService } from 'src/app/_services';
import { CstbNavigationLotComponent } from 'src/app/all/cstb/cstb-navigation-lot/cstb-navigation-lot.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
declare var $ :any;
@Component({
  standalone:true,
  selector: 'app-need-subscription-popup-2',
  imports:[NgSharedModule, CstbNavigationLotComponent],
  templateUrl: './need-subscription-popup2.component.html',
  styleUrls: ['./need-subscription-popup2.component.scss']
})
export class NeedSubscriptionPopup2Component implements OnInit {

  public currentLicence;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentLicence = this.authService.licence;
  }
  public redirectToPricingTable(){
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
    $('.modal').modal('hide'); /// hide all modals
  }

}
