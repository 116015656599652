import { Component, OnInit, NO_ERRORS_SCHEMA} from '@angular/core';
import { ArtisanService,AuthService } from '../../../_services/index';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import {NgSharedModule} from 'src/app/all/ngshared.module'

import { HeaderComponent } from 'src/app/all/header/header.component';
import { CapitalizePipe } from 'src/app/_pipe/capitalize.pipe';

@Component({
    selector: 'app-delete-account-artisan',
    imports:[
    HeaderComponent,
    CapitalizePipe,
    NgSharedModule
],
    templateUrl: './delete-account-artisan.component.html',
    styleUrls: ['./delete-account-artisan.component.scss'],
    standalone: true,
    
})
export class DeleteAccountArtisanComponent implements OnInit {
  public currentUser;validityPassword=false;password="";
  private subscriptions: Array<Subscription> = [];
  constructor(private artisanService: ArtisanService,private authService: AuthService,private router: Router) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
  }
  public checkValidityPass(password){
    this.subscriptions.push(
      this.artisanService.verifyIfPasswordIsTrue(this.currentUser.id,{password:password})
        .subscribe(
          data => {
            this.validityPassword = data.validPassword;
          },
          error => {
            console.log("error",error)
      })
    );
  }
  public deleteAccount(){
    this.subscriptions.push(
      this.authService.deleteAccount(this.currentUser.id,{password:this.password,statusDeleted:true})
        .subscribe(
          data => {
            this.authService.logout()
            this.router.navigate(['login']);
          },
          error => {
            console.log("error",error)
          })
    );
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }
}
