import { Component, OnInit, ViewChild } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { Router } from '@angular/router';
// Servies
import { ArtisanService } from 'src/app/_services';
/// RxJs
import { Subscription } from 'rxjs';

import { HeaderComponent } from 'src/app/all/header/header.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { BoosterProfileFormsProgressComponent } from 'src/app/artisan/booster-profile-forms-components/booster-profile-forms-progress/booster-profile-forms-progress.component';
import { BoosterProfileInformationFormComponent } from 'src/app/artisan/booster-profile-forms-components/booster-profile-information-form/booster-profile-information-form.component';
import { BoosterProfileCorpsEtatFormComponent } from 'src/app/artisan/booster-profile-forms-components/booster-profile-corps-etat-form/booster-profile-corps-etat-form.component';
import { BoosterProfileDocumentsFormComponent } from 'src/app/artisan/booster-profile-forms-components/booster-profile-documents-form/booster-profile-documents-form.component';
import { BoosterProfilePrecisionFormComponent } from 'src/app/artisan/booster-profile-forms-components/booster-profile-precision-form/booster-profile-precision-form.component';
import { BoosterProfileRecommandationsFormComponent } from 'src/app/artisan/booster-profile-forms-components/booster-profile-recommandations-form/booster-profile-recommandations-form.component';
import { ConfirmExistBoosterFormModalComponent } from 'src/app/artisan/booster-profile-forms-components/confirm-exist-booster-form-modal/confirm-exist-booster-form-modal.component';

declare var $: any;
@Component({
    selector: 'app-artisan-booster-profile-form-page',
    imports:[
    HeaderComponent,
    SpinnerComponent,
    BoosterProfileFormsProgressComponent,
    BoosterProfileInformationFormComponent,
    BoosterProfileCorpsEtatFormComponent,
    BoosterProfileDocumentsFormComponent,
    BoosterProfilePrecisionFormComponent,
    BoosterProfileRecommandationsFormComponent,
    ConfirmExistBoosterFormModalComponent,
    NgSharedModule
],
    templateUrl: './artisan-booster-profile-form-page.component.html',
    styleUrls: ['./artisan-booster-profile-form-page.component.scss'],
    standalone: true,

})
export class ArtisanBoosterProfileFormPageComponent implements OnInit {
    
  public loading: boolean = true;
  public currentFormIsValid: boolean = false;
  private artisan: any = {};
  private artisanId: string = "";
  public currentForm: string = "information";

  /// Information
  public informationData:any={
    type: [], // Vous êtes :
    travaux: [], // Sur quel type de travaux pouvez-vous intervenir ?
    programme: [], // Quels programmes prenez-vous en charge ?
    creationYearAgency: "", // année avez-vous crée votre entreprise ?
    ca: "", // Chiffre d’affaire
  };
  /// Corps d’état
  public corpsDetatData:any = {
    corpsDetat: []
  };

  /// documents
  public documentsData:any={
    logo: {},
    assuranceFile: {},
    kbis: {},
    files: [], // attestations certifications
    photos: [],
  };

  // Precision
  public pecisionsData: any = {
    domaineSpe: "", 
    workAreaMain: []
  };

  /// recommandations
  public artisanRecommandationsList: any = [];

  public formsHeaderTitles: any= {
    "information": "Pour commencer, dites-nous en plus sur votre entreprise",
    "corpsEtat": "Quelles sont les activités professionnelles garanties par votre assurance décénnale ?",
    "documents": "Ces documents permettent de mettre en avant votre sérieux et compétences auprès des maîtres d'oeuvre.",
    "precision": "Ces informations nous permettent de pouvoir vous proposer des projets cohérents avec vos compétences",
    "recommandations": "Pour participer à des appels d’offre, merci de partager les coordonnées d’architectes avec qui vous avez travaillés pour qu’ils puissent vous recommander"
  };
  public formsStepNumber: any = {
    1: "information",
    2: "corpsEtat",
    3: "documents",
    4: "precision",
    5: "recommandations",
  };
  public showMainCard: any = {
    "information": true,
    "corpsEtat": true,
    "documents": false,
    "precision": false,
    "recommandations": true,
  }
  public makeCardScrollable: any = {
    "information": false,
    "corpsEtat": false,
    "documents": false,
    "precision": true,
    "recommandations": false,
  };
  private formsNames: any = ["information", "corpsEtat", "documents", "precision", "recommandations"];

  private subscriptions: Array<Subscription> = [];

  constructor(
    private artisanService: ArtisanService,
    private router: Router 
  ) { }

  ngOnInit(): void {
    this.getArtisanId();
    this.getArtisanProfile();
  }

  public cancel(){}
  
  // Go To Next Form
  public prevForm(){
    let currentFormIndex = this.formsNames.indexOf(this.currentForm);
    if(currentFormIndex){
      this.currentForm = this.formsNames[currentFormIndex-1];
    }
  }
  // Back To Previous Form
  public nextForm(){
    if(!this.isCurrentFormValid) return false;

    // go to the next form
    let currentFormIndex = this.formsNames.indexOf(this.currentForm);
    if(currentFormIndex < this.formsNames.length-1){
      this.currentForm = this.formsNames[currentFormIndex+1];

      /// save which form the artisan reached
      this.updateBoosterProfileFormStep(currentFormIndex+2);
    }
  }

  private isCurrentFormValid(){
    let isValid = true;

    return isValid;
  }

  // data for information, documents ...
  private setData(artisan){
    
    /// Information
    this.informationData.type = artisan["type"];
    this.informationData.travaux = artisan["travaux"];
    this.informationData.programme = artisan["programme"];
    this.informationData.creationYearAgency = artisan["creationYearAgency"];
    this.informationData.ca = artisan["ca"];    

    /// Corps d’état
    this.corpsDetatData.corpsDetat = artisan["corpsDetat"];

    // Documents
    this.documentsData.logo = artisan.logo;
    this.documentsData.assuranceFile = artisan.assuranceFile;
    this.documentsData.kbis = artisan.kbis;
    this.documentsData.files = artisan.files;
    this.documentsData.photos = artisan.photos;
    this.documentsData.attestationsCertification = artisan.attestationsCertification;

    // precisions
    this.pecisionsData.domaineSpe = artisan["domaineSpe"];
    this.pecisionsData.workAreaMain = artisan["workAreaMain"];    

    /// Recommandations
    this.artisanRecommandationsList = artisan["recommandation"] || [];
  }

  private getArtisanId(){
    var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    this.artisanId = currentUser.artisanId;    
  }
  private setInformationFormTitle(agencyName){
    this.formsHeaderTitles["information"] = `Pour commencer ${agencyName}, dites-nous en plus sur votre entreprise`;
  }
  private getArtisanProfile(){
    this.subscriptions.push(
      this.artisanService.getArtisanProfile(this.artisanId)
      .subscribe(
        data => {
          this.artisan = data;
          
          /// set current FOrm
          let boosterFormStep = data["boosterFormStep"] || 1;
          this.currentForm = this.formsStepNumber[boosterFormStep];
          // set information form header title
          this.setInformationFormTitle(this.artisan.name);

          this.setData(data);
          this.loading = false;
        },
        error => {
          console.log("getArtisanProfile : ", error);
        })
    );    
  }

  private updateArtisan(newArtisanData, navigateToBoosterProfilePage=false){
    this.subscriptions.push(
      this.artisanService.update(this.artisanId, newArtisanData)
        .subscribe(
          data => {
            // console.log("updateArtisan : ", data);
            if(navigateToBoosterProfilePage){
              this.router.navigate(['/artisan/booster-profile']);
            }
          },
          error => {
            console.log("updateArtisan : ", error);            
          })
    );
  }
  private updateBoosterProfileFormStep(step: number){
    this.updateArtisan({boosterFormStep: step});
  }

  public selectedFormIsValid(form, isValid){
    this.currentFormIsValid = isValid;
  }

  public openConfirmExistFormModal(){
    $("#confirm-exist-booster-form-modal").modal("show");
  }

  ///////////////////////////////////////
  /// Information
  ///////////////////////////////////////
  public saveInformation(information){
    this.updateArtisan(information);
  }

  ///////////////////////////////////////
  /// Recommandations
  ///////////////////////////////////////
  public createNewContact(contact){
    let newRecommandationsList = [...this.artisanRecommandationsList, contact];
    let updateData = {
      recommandation: newRecommandationsList
    };

    this.updateArtisan(updateData);
  }
  public removeContact(contacts){
    let updateData = {
      recommandation: contacts
    };
    
    this.updateArtisan(updateData);
  }


  ///////////////////////////////////////
  /// Navigate To
  ///////////////////////////////////////
  public backToprojectsPage(event){
    $(".modal").modal('hide');

    this.router.navigate(['/artisan/project-list']);
  }
  public goToBoosterProfilePage(){
    this.updateArtisan({boosterFormIsCompleted: true}, true); /// Set 
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
