import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {NgSharedModule} from 'src/app/all/ngshared.module'


// Servies
import { ArtisanService } from 'src/app/_services';
/// RxJs
import { Subscription } from 'rxjs';

import { HeaderComponent } from 'src/app/all/header/header.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { PublicBoosterProfileRealisationsListComponent } from 'src/app/artisan/public-booster-profile-components/public-booster-profile-realisations-list/public-booster-profile-realisations-list.component';
import { PublicBoosterProfileRecommandationsListComponent } from 'src/app/artisan/public-booster-profile-components/public-booster-profile-recommandations-list/public-booster-profile-recommandations-list.component';
import { BoosterProfileInfoSideBarComponent } from 'src/app/artisan/booster-profile-page-components/booster-profile-info-side-bar/booster-profile-info-side-bar.component';
import { BoosterProfileGarantieDecennaleViewOnlyComponent } from 'src/app/artisan/booster-profile-page-components/booster-profile-garantie-decennale-view-only/booster-profile-garantie-decennale-view-only.component';

declare var $: any;

@Component({
    selector: 'app-public-booster-profile',
    imports:[
    HeaderComponent,
    SpinnerComponent,
    PublicBoosterProfileRealisationsListComponent,
    PublicBoosterProfileRecommandationsListComponent,
    BoosterProfileInfoSideBarComponent,
    BoosterProfileGarantieDecennaleViewOnlyComponent,
    NgSharedModule
],
    templateUrl: './public-booster-profile.component.html',
    styleUrls: ['./public-booster-profile.component.scss'],
    standalone: true,
    
})
export class PublicBoosterProfileComponent implements OnInit {

  public loading: boolean = false;
  private artisanId: string = "";
  public seletedTab: string = "relisations";

  // Sidebar Modals Mapper
  private modalsIdsMappers: any = {
    "garantieDecennaleViewOnly": "#garantie-decennale-view-only-modal"
  };

  // Inputs Data
  public albums: any = [];
  public photos: any = [];
  public recommandation: any = [];

  // Reviews
  public reviews:any = [];
  public average:any = {};

  public sideBarData: any = {
    // general Info
    nomPrenom: "",
    logo: {},
    type: [],
    programme: [],
    creationYearAgency: "",
    ca: "",
    // Docs
    kbis: {},
    files: [],
    assuranceFile: {},
    photos: [],
    corpsDetat: [],
    poste: [],
    // currentData
    workAreaMain: []
  };

  /// corpsDetat
  public corpsDetat: any = [];

  private subscriptions: Array<Subscription> = [];

  constructor(
    private artisanService: ArtisanService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getCurrentRoute();
  }
  private getCurrentRoute(){
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params["artisanId"]){
        this.artisanId = params["artisanId"];
        // profile data
        this.getArtisanProfile();
        // Review
        this.getReviews(this.artisanId);
      }
    })
  }

  private getArtisanProfile(){
    this.loading = true;
    this.subscriptions.push(
      this.artisanService.getArtisanProfile(this.artisanId)
        .subscribe(
          data => {
            this.setData(data);
            this.loading = false;
          },
          error => {
            console.log("getArtisanProfile : ", error);
          })
    );
  }
  public getReviews(id){
    this.subscriptions.push(
      this.artisanService.getArtisanReviews(id)
      .subscribe(
        data => {
          /// reviews List
          this.reviews = data.data;
          this.reviews = this.reviews.map(review => {
            const {
              proprete, respectDelais, respectDevis, respectProjet, 
              tolerence, competitivite, disponibilite, execution } = review;

            let calculationitems = [
              proprete, respectDelais, respectDevis, respectProjet, 
              tolerence, competitivite, disponibilite, execution];

            review.average = calculationitems.reduce((partialSum, item) => partialSum + item, 0) / calculationitems.length;
            review.average = this.floorOrCeilANumber(review.average);
              
            return review;
          });

          /// Average
          this.average = data.metadata.average[0];
          // console.log("this.average : ", this.average);
        },
        err => {
          console.log(err);
        },
      )
    );
  }
  // if a number is 1.32 rount it to 1.5, if it's 1.80 round it to 2
  private floorOrCeilANumber(number) {
    let value = parseInt((number * 2).toFixed()) / 2;
    
    return value;
  }

  private setData(artisan){
    /// Albums Tab
    this.albums = artisan["albums"];
    this.photos = artisan["photos"];

    /// Recommandations Tab
    this.recommandation = artisan["recommandation"];

    // Sidebar
    // this.sideBarData["nomPrenom"] = artisan["nomPrenom"];
    this.sideBarData["name"] = artisan["name"];  
    this.sideBarData["logo"] = artisan["logo"];
    this.sideBarData["type"] = artisan["type"];
    this.sideBarData["travaux"] = artisan["travaux"];
    this.sideBarData["programme"] = artisan["programme"];
    this.sideBarData["creationYearAgency"] = artisan["creationYearAgency"];
    this.sideBarData["ca"] = artisan["ca"];
    this.sideBarData["kbis"] = artisan["kbis"];
    this.sideBarData["files"] = artisan["files"];
    this.sideBarData["assuranceFile"] = artisan["assuranceFile"];
    this.sideBarData["photos"] = artisan["photos"];
    this.sideBarData["materiauxIssusReemploi"] = artisan["materiauxIssusReemploi"];
    this.sideBarData["materiauxBasCarbones"] = artisan["materiauxBasCarbones"];
    this.sideBarData["corpsDetat"] = artisan["corpsDetat"];
    this.sideBarData["poste"] = artisan["poste"];

    // corpsDetat
    this.corpsDetat = artisan["corpsDetat"];

    // WorkMainArea
    this.sideBarData["workAreaMain"] = artisan["workAreaMain"];    
  }

  public toggleTab(tab){
    this.seletedTab = tab;
  }

  // Sidebar Events
  public openSideBarModals(modalType){
    if(this.modalsIdsMappers[modalType]){
      let modalId = this.modalsIdsMappers[modalType];
      
      $(modalId).modal("show");
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
