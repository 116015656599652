let calculations1 = [
  {
    label: "Robinet lavabo avec régulateur de débit (5L/min)",
    facteurDeCorrection: 0.91,
    type: "Lavabo", fp: "0,09",
    fequipement: "", modelVariable: "robinetLavaboRegulateurDébit5LMin",
    calculationId: "volumeConsommationEauPotable"
  },
  {
    label: "Chasse d’eau double flux 3L / 6L",
    facteurDeCorrection: 0.75,
    type: "Toilettes", fp: "0,04",
    fequipement: "", modelVariable: "chasseEauDoubleFlux3L6L",
    calculationId: "volumeConsommationEauPotable"
  },
  {
    label: "Chasse d’eau double flux 2L / 4L",
    facteurDeCorrection: 0.58,
    type: "Toilettes", fp: "0,14",
    fequipement: "", modelVariable: "ChasseEauDoubleFlux2L4L",
    calculationId: "volumeConsommationEauPotable"
  },
  {
    label: "Urinoir",
    facteurDeCorrection: 0.58,
    type: "Toilettes", fp: "0,21",
    fequipement: "", modelVariable: "urinoir",
  },
  {
    label: "Chasse d’eau avec utilisation de l’eau de pluie (if Usage is 4)",
    facteurDeCorrection: 0,
    type: "Toilettes", fp: "0,00",
    fequipement: "", modelVariable: "chasseEauAvecUtilisationEauPluie",
    calculationId: "volumeConsommationEauPotable"
  },
];
let calculations2 = [
  {
    label: "Robinet lavabo avec régulateur de débit (5L/min)",
    facteurDeCorrection: 0.86, type: "", quantite: "", fp: "", fequipement: "", modelVariable: "robinetLavaboRegulateurDébit5LMin",
    calculationId: "volumeConsommationEauPotable"
  },
  {
    label: "Chasse d’eau double flux 3L / 6L",
    facteurDeCorrection: 0.77, type: "", quantite: "", fp: "", fequipement: "", modelVariable: "chasseEauDoubleFlux3L6L",
    calculationId: "volumeConsommationEauPotable"
  },
  {
    label: "Chasse d’eau double flux 2L / 4L",
    facteurDeCorrection: 0.62, type: "", quantite: "", fp: "", fequipement: "", modelVariable: "ChasseEauDoubleFlux2L4L",
    calculationId: "volumeConsommationEauPotable"
  },
  {
    label: "Urinoir",
    facteurDeCorrection: 0.61, type: "", quantite: "", fp: "", fequipement: "", modelVariable: "urinoir"
  },
  {
    label: "Chasse d’eau avec utilisation de l’eau de pluie",
    facteurDeCorrection: 0, type: "", quantite: "", fp: "", fequipement: "", modelVariable: "chasseEauAvecUtilisationEauPluie",
    calculationId: "volumeConsommationEauPotable"
  },
]
let calculations3 = [
  {
    label: "Robinet lavabo avec régulateur de débit (5L/min)",
    facteurDeCorrection: 0.97, type: "Lavabo", quantite: "", fp: "", fequipement: "", modelVariable: "robinetLavaboRegulateurDébit5LMin",
    calculationId: "volumeConsommationEauPotable"
  },
  {
    label: "Robinet évier avec régulateur de débit (6L/min)",
    facteurDeCorrection: 0.94, type: "Lavabo", quantite: "", fp: "", fequipement: "",
    modelVariable: "RobinetEvierRégulateurDébit6LMin",
    calculationId: "volumeConsommationEauPotable"
  },
  {
    label: "Chasse d’eau double flux 3L / 6L",
    facteurDeCorrection: 0.96, type: "Toilettes", quantite: "", fp: "", fequipement: "", modelVariable: "chasseEauDoubleFlux3L6L",
    calculationId: "volumeConsommationEauPotable"
  },
  {
    label: "Chasse d’eau double flux 2L / 4L",
    facteurDeCorrection: 0.93, type: "Toilettes", quantite: "", fp: "", fequipement: "", modelVariable: "ChasseEauDoubleFlux2L4L",
    calculationId: "volumeConsommationEauPotable"
  },
  {
    label: "Toilettes sèches (Usage is 1 only)",
    facteurDeCorrection: 0.87, type: "Toilettes", quantite: "", fp: "", fequipement: "", modelVariable: "toilettesSeches",
    calculationId: "volumeConsommationEauPotable"
  },
  {
    label: "Chasse d’eau avec utilisation de l’eau de pluie",
    facteurDeCorrection: 0, type: "Toilettes", quantite: "", fp: "", fequipement: "", modelVariable: "chasseEauAvecUtilisationEauPluie",
    calculationId: "volumeConsommationEauPotable"
  },
  {
    label: "Douche économe en eau (8L/min)",
    facteurDeCorrection: 0.8, type: "Douche", quantite: "", fp: "", fequipement: "", modelVariable: "doucheEconomeEau8LMin",
    calculationId: "volumeConsommationEauPotable"
  },
  {
    label: "Distance point de puisage ECS - salle de bain < 8m",
    facteurDeCorrection: 0.98, type: "", quantite: "", fp: "", fequipement: "",
    modelVariable: "DistancePointPuisageECSSalleDeBainLessThan8m",
    calculationId: "volumeConsommationEauPotable"
  },
]


export const calculationsInformations = {
  '1': calculations3,
  '2': calculations3,
  '3': calculations2,
  '4': calculations1,
  '5': calculations1,
};

