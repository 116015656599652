<div class="callmodal-container">
  <div class="modal-content-el">
    <div class="close-popup">
      <a (click)="closePopup()"> <i class="fa fa-times"></i> </a>
    </div>
    <div class="upper-text">
      <h4 *ngIf="documentType == 'devis'">Exporter le devis</h4>
      <h4 *ngIf="documentType == 'cctp'">Exporter les CCTP</h4>
      <p>Choisissez les lots que vous souhaitez exporter au format pdf.</p>
    </div>
    <div class="row add-enterprise-container">
      <div class="col-md-12 tags-col">
        <!-- Check all & Uncheck all Buttons -->
        <div class="selection-btns  row">
          <button (click)="checkAll()"><i class="fa fa-check-circle-o"></i> Tout sélectionner</button>
          <button (click)="uncheckAll()" class="pull-right"><i class="fa fa-circle-o"></i> Tout désélectionner</button>
        </div>
        <!-- END Check Buttons -->
        <div class="content-wrapper">
          <div class="content-list">
              <div class="single-lot-card clearfix" *ngFor="let lot of dpgfLot">
                <div class="lot-check">
                  <p-checkbox binary="true" [(ngModel)]="selectedLot[lot.lotId]"></p-checkbox>
                </div>
                <div class="info-img-text-wrap" (click)="chooseLot(lot)">
                  <div class="lot-icon" >
                    <img [src]="lot.icon !='' ? '/assets/dpgf/Icon_DPGF/'+lot.icon : '/assets/rob/helmet.png'" />
                  </div>
                  <div class="lot-name">
                    <!-- <p>{{ lot.name }} <span *ngIf="lot.id != 'general'" class="lot-price">{{ lot.total | currency:'':'' }} €</span></p> -->
                    <p>{{ lot.name }} <span *ngIf="lot.id != 'general'" class="lot-price">{{ lot.total | currency: 'EUR':'symbol':undefined:'fr-FR' }}</span></p>
                  </div>
                </div>
              </div>
          </div>
      </div>
      <div class="devis-type-container mt-2">
        <p>
          <p-checkbox binary="true" [(ngModel)]="showEntrepriseComments"></p-checkbox>
          <span (click)="toggleShowEntrepriseComments()">Ajouter les commentaires de l'entreprise</span>
        </p>
      </div>
      <div class="row mt-2">
        <app-spinner *ngIf="downloadingDoc"></app-spinner>
        <!-- <button class="print-btn" *ngIf="!downloadingDoc" (click)="downloadEstimatifPdf()"><i class="fa fa-print"></i> Imprimer</button> -->
        <button class="print-btn" *ngIf="!downloadingDoc" (click)="downloadDocument()"><i class="fa fa-print"></i> Imprimer</button>
      </div>
      </div>
      <!-- ENd Left Side -->

    </div>
  </div>
</div>
