import { Component, OnInit,Input,Output, EventEmitter} from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';

// Components
import { ConsultationsTypesListComponent } from 'src/app/architect/entreprises/lots-tab/consultations-types-list/consultations-types-list.component';
import { EnterprisesLotsTableComponent } from 'src/app/architect/entreprises/lots-tab/enterprises-lots-table/enterprises-lots-table.component';

/// services
import { AuthService } from 'src/app/_services';
import { DpgfModule } from '../../dpgf.module';

// Helpers
import { Helpers } from 'src/app/_services/helpers';


declare var $ :any;

@Component({
  standalone:true,
  selector: 'app-ce-project-artisan-lot',
  imports:[
    DpgfModule,
    NgSharedModule,
    ConsultationsTypesListComponent,
    EnterprisesLotsTableComponent
  ],
  templateUrl: './ce-project-artisan-lot.component.html',
  styleUrls: ['./ce-project-artisan-lot.component.scss']
})
export class CeProjectArtisanLotComponent implements OnInit {
  @Input()project:any = {};
  @Input()lots:any = [];
  @Input()projectId :String ="";
  @Input()dpgf :any ="";
  @Input()dpgfLot :any ="";
  @Output() openAddArtisan = new EventEmitter<any>();
  @Output() openArchitectAgencyModal = new EventEmitter<any>();

  public artisans:any = [];
  public popUpArtisan:any = {artisan : {}};
  public loadingData:Boolean = false;
  public lotsDpgfFull:any = []; // this list stays untouched
  public lotsDpgf:any = []; // this list may gets filtered
  public selectedLot = {};
  
  public consultationsTypesListCount: any = [
    {value: 'offre_remise', count: 0},
    {value: 'en_cours', count: 0},
    {value: 'en_attente', count: 0},
    {value: 'a_consulter', count: 0},
    {value: 'ne_chiffre_pas', count: 0}, 
  ];

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.lotsDpgf=this.sortLots(this.lots);
    this.lotsDpgfFull = JSON.parse(JSON.stringify(this.lotsDpgf));
    this.countEnterpriseTypes();
  }
  
  private  sortLots(lots){
    return lots.sort((a,b) =>  a.indexLot - b.indexLot )
  }

  public openArtisanPopup(lot){
    if(this.authService.licence == "without"){
      $('#upgrade-modal').modal('show');
      return;
    }
    this.openAddArtisan.emit(lot);
  }
  public emitOpenArchitectAgencyModal(body){
    this.openArchitectAgencyModal.emit(body);
  }
  public getIncide(number){
    var n = number-1;
    var ordA = 'a'.charCodeAt(0);
    var ordZ = 'z'.charCodeAt(0);
    var len = ordZ - ordA + 1;
    var s = "";
    while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s.toUpperCase();
  }
  public countEnterpriseTypes(){
    const helpers = new Helpers();
    let addedInvitesIds = [];

    for(let i = 0; i < this.lotsDpgfFull.length; i++){
      let currentLot = JSON.parse(JSON.stringify(this.lotsDpgfFull[i]));

      for(let j = 0; j < currentLot.invites.length; j++ ){
        const currentInvite = currentLot.invites[j];
        
        const { _id, status, pricingStatus } = currentInvite;
        // get invite status
        const newStatus = helpers.getCeProjetInviteStatus(status, pricingStatus);

        // increase the count
        const foundConsultation = this.consultationsTypesListCount.find(item => item.value == newStatus);
        if(foundConsultation && !addedInvitesIds.includes(_id)){
          addedInvitesIds.push(_id);
          foundConsultation["count"]++;
        }

      }
    }
  }

  public consultationTypeSelected(activeTypes){
    const filteredList =  this.filterDpgfLotsbyType(activeTypes);
    this.lotsDpgf = filteredList;
  }
  private filterDpgfLotsbyType(activeTypes){
    const helpers = new Helpers();
    let filteredLots = [];
    
    // console.log(type, selected);
    for(let i = 0; i < this.lotsDpgfFull.length; i++){
      let currentLot = JSON.parse(JSON.stringify(this.lotsDpgfFull[i]));
      let filteredInvites = [];
      
      for(let j = 0; j < currentLot.invites.length; j++ ){
        const currentInvite = currentLot.invites[j];
        const { status, pricingStatus } = currentInvite;
        // get invite status
        const newStatus = helpers.getCeProjetInviteStatus(status, pricingStatus);

        if(activeTypes.includes(newStatus)){
          filteredInvites.push(currentInvite);
        }

      }
      currentLot["invites"] = filteredInvites;
      
      filteredLots.push(currentLot);
    }

    return filteredLots;
  }
  public searchAgencies(searchQuery){
    let filteredLots = [];
    const loweredSearchQuery = searchQuery.toLowerCase();

    for(let i = 0; i < this.lotsDpgfFull.length; i++){
      let currentLot = JSON.parse(JSON.stringify(this.lotsDpgfFull[i]));
      let filteredInvites = [];            

      for(let j = 0; j < currentLot.invites.length; j++ ){
        const currentInvite = currentLot.invites[j];
        const { artisan, isFactice, facticeArtisanInfo } = currentInvite;
        const { name } = artisan;
        const loweredArtisanName = name.toLowerCase();

        if(isFactice){ // search for factice artisans
          const loweredFacticeArtisanName = facticeArtisanInfo["name"].toLowerCase();
          
          if(loweredFacticeArtisanName.includes(loweredSearchQuery)){
            filteredInvites.push(currentInvite);
          }
        }else{ // search for real artisans
          if(loweredArtisanName.includes(loweredSearchQuery)){
            filteredInvites.push(currentInvite);
          }
        }

      }
      currentLot["invites"] = filteredInvites; // add filtered invites
      filteredLots.push(currentLot);
    }

    this.lotsDpgf = filteredLots;
  }

}
