const zoneClimatiqueOptions = require('../../acv-project-description-page/options').zoneClimatiqueOptions;
const zoneDonneesGenerales = require('../../acv-project-description-page/options').zoneDonneesGenerales;

export const requiredFormsInputs = (batimentWithZones=[]) => {
  let values = {
    "Projet": {
      items: [
        // {label: "Date de livraison du projet (prévue)", type: "date", modelVariable:"projectDescription.projet.DateLivraison"},
        // {
        // label: "Altitude *", type: "radio", optionName: "altitude",
        //   options: [
        //     {label: "Entre 0m et 400m inclu", value: "0"},
        //     {label: "Entre 400m et 800m inclus", value: "1"},
        //     {label: "Supérieure à 800m", value: "2"},
        //   ],
        //   modelVariable:"projectDescription.projet.altitude",
        // },
        // {label: "Zone climatique *", type: "select", placeholder: "", options: zoneClimatiqueOptions, modelVariable:"projectDescription.projet.zoneClimatique",},
        // {label: "Surface de la parcelle *", type: "number", placeholder: "0", modelVariable:"projectDescription.projet.surfaceParcelle",  inputLabel: "m²"},
        // {label: "Surface arrosée * (hors toitures et murs végétalisées)", type: "number", placeholder: "0", modelVariable:"projectDescription.projet.surfaceArrosee",  inputLabel: "m²"},
        // {label: "Surface imperméabilisée *", type: "number", placeholder: "0", modelVariable:"projectDescription.projet.surfaceImpermeabilisee",  inputLabel: "m²"},

        {
          label: "Pluviométrie annuelle du site *", type: "number", placeholder: "0",
          inputLabel: "mm/m²",
          modelVariable:"projectDescription.projet.pluviometrieAnnuelleSite",
          trimmedModelVariable:"projectDescription-projet-pluviometrieAnnuelleSite",
        },
        {
          label: "Surface végétalisée *", type: "number", placeholder: "0",
          inputLabel: "m²",
          modelVariable:"projectDescription.projet.surfaceVegetalisee",
          trimmedModelVariable:"projectDescription-projet-surfaceVegetalisee",
        },
      ]
    },
  }
  /// Add Batimes & The Zones
  for(let key in batimentWithZones){
    let trimmedBatiment = key.replace(/ /g, "-");
    let batimentZones = batimentWithZones[key];

    /// push batiment Obj
    values[trimmedBatiment] = {
      // items: [
      //   {label: "Surface d'emprise au sol *", type: "number", placeholder: "0", modelVariable:`projectDescription.${trimmedBatiment}.EmpriseAuSol`},
      //   {label: "Durée du chantier", type: "number", placeholder: "0", modelVariable:`projectDescription.${trimmedBatiment}.DureeChantier`,  inputLabel: "mois"},
      // ]
    }
    /// Zones
    for(let i = 0; i < batimentZones.length; i++){
      let trimmedZone = batimentZones[i].replace(/ /g, "-");
      /// push Zone Obj
      values[trimmedBatiment][trimmedZone] = [
          // {label: "Usage", type: "select", options: zoneDonneesGenerales, modelVariable:`projectDescription.${trimmedBatiment}.${trimmedZone}.Usage`},
          // {label: "Nombre de logements *", type: "number", placeholder: "0", modelVariable:`projectDescription.${trimmedBatiment}.${trimmedZone}.NbLogements`,  inputLabel: "logements"},
          {
            label: "Nombre d’occupants attendus *", type: "number", placeholder: "0",
            inputLabel: "occupants",
            modelVariable:`projectDescription.${trimmedBatiment}.${trimmedZone}.NbOccupants`,
            trimmedModelVariable:`projectDescription-${trimmedBatiment}-${trimmedZone}-NbOccupants`,
          },
        ]
    }

  }

  return values;
}
