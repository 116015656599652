import { Component,Input, Output,EventEmitter} from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSharedModule } from '../ngshared.module';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
declare var google: any;

@Component({
  selector: 'app-google-autocomplete',
  standalone: true,
  imports: [NgSharedModule,AutoCompleteModule, CommonModule],
  templateUrl: './google-autocomplete.component.html',
  styleUrl: './google-autocomplete.component.scss',
  
})
export class GoogleAutocompleteComponent {
  @Input() adresseValue: any;
  @Output() adresseValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() adresseJsonChange: EventEmitter<any> = new EventEmitter<any>();

  items: any[] | undefined;
  selectedItem: any;
  suggestions: any[] | undefined;
  sessionToken: any;
  service: any;
  constructor() {
  }
  search(event: AutoCompleteCompleteEvent) {
    if(!this.service){
      this.service = new google.maps.places.PlacesService(document.getElementById('google-map-div'));
    }
    // console.log('this.service',this.service||null)
    if(!this.sessionToken){
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    }
    // console.log('this.sessionToken',this.sessionToken||null)
    var autocompleteService = new google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions({
      input: event.query,
      sessionToken: this.sessionToken
    }).then((sug) => {
        this.suggestions = sug.predictions//.map(item => item.description);
    });
  }
  public onAdressChange(event){
    this.adresseValueChange.emit(this.adresseValue)
    this.service.getDetails({
      placeId: event.value.place_id,
    }, (place, status) => {
      let adresseJson = this.formateAdresse(place);
      this.adresseJsonChange.emit(adresseJson)
    });
  }
  public formateAdresse(place){
    let adresseJson = {
      adresse: "",
      addressCodePostal : "",
      addressCity : "",
      state : "",
      addressCountry : "",
      zipCode: "",
      shortZipCode: "",
    };
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          adresseJson.adresse = `${component.long_name} ${adresseJson.adresse}`;
          break;
        }
        case "route": {
          adresseJson.adresse += component.short_name;
          break;
        }
        case "postal_code": {
          adresseJson.addressCodePostal = `${component.long_name}${adresseJson.addressCodePostal}`;
          let zipCode = component.short_name;
          let shortZipCode = zipCode.substr(0,2);

          adresseJson["zipCode"] = zipCode;
          adresseJson["shortZipCode"] = shortZipCode;
          
          break;
        }
        case "postal_code_suffix": {
          adresseJson.addressCodePostal = `${adresseJson.addressCodePostal}-${component.long_name}`;
          break;
        }
        case "locality":
          adresseJson.addressCity = component.long_name;
          break;
        case "administrative_area_level_1": 
          adresseJson.state = component.short_name;
          break;
        case "country":
          adresseJson.addressCountry = component.long_name;
          break;
      }
    }
    return adresseJson
  }

}
