import {ChantierActions, ChantierActionTypes} from '../_store/chantier.actions'; 

const dotProp = require('dot-prop-immutable');

const initialState = {
    facture: null,
    flags: {
        loadingData: false,
        saving: false,
        //savingFinished: 'hide',
        done: false,
        finishing: false
    },
}

export function ChantierReducer(state= initialState, action: ChantierActions) {
    switch(action.type) {
        case ChantierActionTypes.LoadFacture :
            state = dotProp.toggle(state, `flags.loadingData`);
            return state;

        case ChantierActionTypes.LoadFactureSuccess :
            state = dotProp.toggle(state, `flags.loadingData`);
            state = dotProp.set(state, `flags.done`, false);
            return {
                ...state,
                facture: action.payload
            }

        case ChantierActionTypes.UpdateLotDevisAcompte :
            state = dotProp.toggle(state, `flags.saving`);
            var i = action.payload.loti;
            var devisAll = 0;
            var devisAllTtc = 0;
            var acompteAll = 0;
            if(action.payload.type === 'devis') {
                let newAcompte = (state.facture.data[i].devis * 100) / state.facture.data[i].total;
                state = dotProp.set(state, `facture.data.${i}.acompte`, newAcompte);
            }
            else {
                let newDevis = (state.facture.data[i].acompte * state.facture.data[i].total) / 100;
                state = dotProp.set(state, `facture.data.${i}.devis`, newDevis);
            }
            // Sous lot
            state = dotProp.set(state, `facture.data.${i}.sousLots.0.total`, state.facture.data[i].total);
            state = dotProp.set(state, `facture.data.${i}.sousLots.0.devis`, state.facture.data[i].devis);
            state = dotProp.set(state, `facture.data.${i}.sousLots.0.acompte`, state.facture.data[i].acompte);
            // TTC
            state = dotProp.set(state, `facture.data.${i}.devisTtc`, state.facture.data[i].devis * (1 + state.facture.defaultTva / 100))
            for(let i=0; i<state.facture.data.length; i++) {
                devisAll += state.facture.data[i].devis;
                devisAllTtc += state.facture.data[i].devisTtc;
            }
            // Acomptes
            acompteAll = (devisAll * 100) / state.facture.totalHt;
            // All
            state = dotProp.set(state, `facture.totalDevis`, devisAll.toFixed(2));
            state = dotProp.set(state, `facture.totalAcompte`, acompteAll.toFixed(2));
            state = dotProp.set(state, `facture.totalDevisTtc`, devisAllTtc.toFixed(2));
            
            return state;

        case ChantierActionTypes.UpdatePosteDevisAcompte : 
            state = dotProp.toggle(state, `flags.saving`);
            var i = action.payload.loti;
            var j = action.payload.sousloti;
            var k = action.payload.posti;
            var devisAll = 0;
            var devisAllTtc = 0;
            var acompteAll = 0;
        
            var price = Number(state.facture.data[i].sousLots[j].postes[k].price);

            if(action.payload.type === 'devis') {
                let devis = Number(state.facture.data[i].sousLots[j].postes[k].devis);
                let newAcompte = (devis * 100) / price;
                state = dotProp.set(state, `facture.data.${i}.sousLots.${j}.postes.${k}.acompte`, newAcompte);
            }
            else {
                let acompte = Number(state.facture.data[i].sousLots[j].postes[k].acompte);
                let newDevis = (acompte * price) / 100;
                state = dotProp.set(state, `facture.data.${i}.sousLots.${j}.postes.${k}.devis`, newDevis);
            }
            let devisLot = 0;
            let devisLotTtc = 0
            for (let j = 0; j < state.facture.data[i].sousLots.length; j++) {
                let devisSouslot = 0;
                let devisSousLotTtc = 0;
                for (let k = 0; k < state.facture.data[i].sousLots[j].postes.length; k++) {
                    devisSouslot += state.facture.data[i].sousLots[j].postes[k].devis;
                    devisSousLotTtc += state.facture.data[i].sousLots[j].postes[k].devis * (1 + (state.facture.data[i].sousLots[j].postes[k].tva || state.facture.defaultTva) / 100);
                }
                state = dotProp.set(state, `facture.data.${i}.sousLots.${j}.devis`, devisSouslot);
                state = dotProp.set(state, `facture.data.${i}.sousLots.${j}.acompte`, (devisSouslot * 100) / state.facture.data[i].sousLots[j].total);
            
                devisLot += state.facture.data[i].sousLots[j].devis;
                devisLotTtc += devisSousLotTtc;
            }
            state = dotProp.set(state, `facture.data.${i}.devis`, devisLot);
            state = dotProp.set(state, `facture.data.${i}.acompte`, (devisLot * 100) / state.facture.data[i].total);
            // TTC
            state = dotProp.set(state, `facture.data.${i}.devisTtc`, devisLotTtc);
        
            for(let i=0; i<state.facture.data.length; i++) {
                devisAll += state.facture.data[i].devis;
                devisAllTtc += state.facture.data[i].devisTtc;
            }
            // Acomptes
            acompteAll = (devisAll * 100) / state.facture.totalHt;
        
            // All
            state = dotProp.set(state, `facture.totalDevis`, devisAll.toFixed(2));
            state = dotProp.set(state, `facture.totalAcompte`, acompteAll.toFixed(2));
            state = dotProp.set(state, `facture.totalDevisTtc`, devisAllTtc.toFixed(2));

            return state;

        case ChantierActionTypes.AddPoste :
            state = dotProp.toggle(state, `flags.saving`);
            var i = action.payload.loti;
            var j = action.payload.sousloti;
            const ID = (type) => {
                return type + '_' + Math.random().toString(36).substr(2, 9);
            }
            const newPost = {
                id : ID('poste'),
                designation : "",
                price : 0,
                acompte: 0,
                devis: 0,
                lastDevis: 0,
                lastAcompte: 0,
                new: true
            }
            state = dotProp.set(state, `facture.data.${i}.sousLots.${j}.postes`, [...state.facture.data[i].sousLots[j].postes, newPost])
            
            return state;

        case ChantierActionTypes.UpdatePostePrice : 
            state = dotProp.toggle(state, `flags.saving`);
            var i = action.payload;
        
            let lotDepassement = 0;
            for(let j=0; j < state.facture.data[i].sousLots.length; j++) {
                for(let k=0; k < state.facture.data[i].sousLots[j].postes.length; k++) {
                    if(state.facture.data[i].sousLots[j].postes[k].new)
                    lotDepassement += state.facture.data[i].sousLots[j].postes[k].price;
                }
            }
            state = dotProp.set(state, `facture.data.${i}.depassement`, lotDepassement);
            
            let totalDepassement = 0;
            for(let i=0; i < state.facture.data.length; i++) {
                totalDepassement += state.facture.data[i].depassement;
            }
            // All
            state = dotProp.set(state, `facture.totalDepassement`, totalDepassement);

            return state;

        case ChantierActionTypes.DeletePoste :
            state = dotProp.toggle(state, `flags.saving`);
            var i = action.payload.loti;
            var j = action.payload.sousloti;
            var k = action.payload.posti;
            var avoir = state.facture.data[i].avoir;
            var priceVariance = state.facture.data[i].sousLots[j].postes[k].price;
            if(action.payload.type == "delete") {
                state = dotProp.set(state, `facture.data.${i}.sousLots.${j}.postes.${k}.deleted`, true);
                state = dotProp.set(state, `facture.data.${i}.avoir`, avoir+priceVariance)
            }
            else {
                state = dotProp.set(state, `facture.data.${i}.sousLots.${j}.postes.${k}.deleted`, false);
                state = dotProp.set(state, `facture.data.${i}.avoir`, avoir-priceVariance)
            }
            let totalAvoir = 0;
            for(let i=0; i < state.facture.data.length; i++) {
                totalAvoir += state.facture.data[i].avoir;
            }
            // All
            state = dotProp.set(state, `facture.totalAvoir`, totalAvoir);
            
            return state;
        
        case ChantierActionTypes.ToggleGlobalFacturation :
            state = dotProp.toggle(state, `flags.saving`);
            var i = action.payload;
            state = dotProp.toggle(state, `facture.data.${i}.acompteAll`);

            return state;

        case ChantierActionTypes.AddComment : 
            var i = action.payload.loti;
            var j = action.payload.sousloti;
            var k = action.payload.posti;
            var owner = action.payload.type;

            if(state.facture.data[i].sousLots[j].postes[k].comment) {
                state = dotProp.set(state, `facture.data.${i}.sousLots.${j}.postes.${k}.comment`,
                comment => [...comment, {com: '', by: owner, new: true}]);
            } else {
                state = dotProp.set(state, `facture.data.${i}.sousLots.${j}.postes.${k}.comment`,
                [{com: '', by: owner, new: true}]);
            }
            
            return state;

        case ChantierActionTypes.DeleteComment :
            state = dotProp.toggle(state, `flags.saving`);
            var i = action.payload.loti;
            var j = action.payload.sousloti;
            var k = action.payload.posti;
            var comi = action.payload.comi;

            state = dotProp.delete(state, `facture.data.${i}.sousLots.${j}.postes.${k}.comment.${comi}`);

            return state;
        case ChantierActionTypes.UpdatePosteDesignation :
            state = dotProp.toggle(state, `flags.saving`);
            var i = action.payload.loti;
            var j = action.payload.sousloti;
            var k = action.payload.posti;
            var newDesignation = action.payload.data.word;
            state = dotProp.set(state, `facture.data.${i}.sousLots.${j}.postes.${k}.designation`, newDesignation);
            return state;

        case ChantierActionTypes.SaveUpdateFacture :
            state = dotProp.toggle(state, `flags.saving`);
            return state;

        case ChantierActionTypes.SaveUpdateFactureSuccess :
            state = dotProp.toggle(state, `flags.saving`);
            return {
                ...state,
                facture: action.payload
            }
        case ChantierActionTypes.FinishFactureUpdate : 
            state = dotProp.toggle(state, `flags.finishing`);
            return state;

        case ChantierActionTypes.AcceptFacture : 
            state = dotProp.toggle(state, `flags.finishing`);
            return state;

        case ChantierActionTypes.RejectFacture : 
            state = dotProp.toggle(state, `flags.finishing`);
            return state;
        
        case ChantierActionTypes.SendFactureSuccess :
            state = dotProp.toggle(state, `flags.finishing`);
            state = dotProp.toggle(state, `flags.done`);
            return {
                ...state,
                facture: action.payload
            }

        case ChantierActionTypes.ErrorFacture :
            state = dotProp.set(state, `flags.loadingData`, false);
            state = dotProp.set(state, `flags.saving`, false);
            state = dotProp.set(state, `flags.done`, false);
            state = dotProp.set(state, `flags.finishing`, false);
            return state;

        default :
            return state;
    }
}