import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router} from '@angular/router';

// services
import { DpgfPostesService } from 'src/app/_services';
// Rxjs
import { Subscription } from 'rxjs';
// Modules
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { Paginator } from 'primeng/paginator';

declare var $: any;
@Component({
  selector: 'app-dpgf-versions-modal',
  standalone: true,
  imports: [
    NgSharedModule,
  ],
  templateUrl: './dpgf-versions-modal.component.html',
  styleUrl: './dpgf-versions-modal.component.scss'
})
export class DpgfVersionsModalComponent {

  @Input() dpgfId: string;
  @Input() dpgfCurrentVersion: any;
  @Output() showConfirmRestoreDceVersionModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() showConfirmIndiceModal: EventEmitter<any> = new EventEmitter<any>();

  /// Paginator
  public countHistory: number = 1;
  public loadingNewIndices: boolean = false;


  /// Verions list & indices
  public loadingVersions: boolean = true;
  public versions: any;
  public versionsCount: any = [];

  private subscriptions: Array<Subscription> = [];

  constructor(
    private router: Router,
    private dpgfPostesService: DpgfPostesService
  ){}

  ngOnInit(){
    this.getVersions({page:0});
  }

  getVersions(event){
    this.loadingVersions = true;
    this.subscriptions.push(
      this.dpgfPostesService.getDpgfVersions(this.dpgfId,event.page||0, this.dpgfCurrentVersion).subscribe((res)=>{
        this.versions = this.filterVersions(res.data);
        this.versionsCount=res.metadata.versions;
        this.countHistory = res.metadata.count;
        this.loadingVersions = false;
        this.loadingNewIndices = false;
      })
    );
  }

  public filterVersions(versions){
    if(!versions.length) return [];

    let filtredVersions = [];

    filtredVersions = versions.filter((version)=>version.currentVersion==this.dpgfCurrentVersion);

    return filtredVersions;
  }

  public openConfirmIndiceModal(){
    this.showConfirmIndiceModal.emit();
  }
  public changeIndice(value){
    this.dpgfCurrentVersion=value;
    this.getVersions({page : 0});
  }

  public goToMinorVersion(versionId){
    $("#versions-modal").modal("hide");
    
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/architect/dpgf-version-minor',versionId])
    );
    window.open(url, '_blank');
  }

  restorer(id){
    this.showConfirmRestoreDceVersionModal.emit(id);
  }

}
