<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 sidebar-wrapper">

      <div class="options-dropdown">
        <ul *ngFor="let option of sidebarList">
          <li *ngIf="!(option.title=='En détail' && !acvData.projectResult)" class="main-level ag-para-1 ag-bold flex space-between "
            [ngClass]="{'open': option.value === selectedOption}"
            (click)="selectOption(option)"
            >
            <span>{{ option.title }}</span>
            <img *ngIf="option.children" src="/assets/acv-icons/nav-arrow-bottom.svg">
          </li>
          <!-- Nest List -->
          <ul class="nested-items-ul" [ngClass]="{'open': option.value === selectedOption}">
            <li class="ag-para-1"
              *ngFor="let nestedItem of option.children; index as i"
              [ngClass]="{'selected': nestedItem.value == currentView}"
              (click)="selectListItem(nestedItem)">
              {{ nestedItem.title }}
            </li>
          </ul>

        </ul>
      </div>

    </div>
    <div class="col-md-9 main-content-wrapper scroller-1">
      <!-- Charts
      ================================= -->
      <container-element [ngSwitch]="currentView">
        <app-acv-results-brief-view *ngSwitchCase="'en_bref'" [dpgfId]="dpgfId" [ceProject]="ceProject" [acvData]="acvData" [projectTitle]="projectTitle" (showPostsEvent)="goToPosts()" (showComposantEvent)="goToZone($event)"></app-acv-results-brief-view>
        <app-acv-results-exigences-view *ngSwitchCase="'exigences_re2020'" [acvData]="acvData"></app-acv-results-exigences-view>
        <app-acv-results-fiches-plus-impactantes-view *ngSwitchCase="'les_fiches_les_plus_impactantes'" [projectTitle]="projectTitle" [acvData]="acvData"></app-acv-results-fiches-plus-impactantes-view>
        <app-acv-results-autres-indicateurs-view *ngSwitchCase="'autres_indicateurs'" [acvData]="acvData"></app-acv-results-autres-indicateurs-view>
      </container-element>
      <!-- Charts
      ================================= -->
    </div>
  </div>
</div>
