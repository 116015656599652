import { Component, OnInit, Input, OnChanges, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { RatingStarsComponent } from '../rating-stars/rating-stars.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    selector: 'app-public-booster-profile-recommandations-list',
    imports:[
      NgSharedModule,
      RatingStarsComponent 
    ],
    templateUrl: './public-booster-profile-recommandations-list.component.html',
    styleUrls: ['./public-booster-profile-recommandations-list.component.scss'],
    standalone: true,
})
export class PublicBoosterProfileRecommandationsListComponent implements OnInit, OnChanges {

  @Input() reviews: any = [];
  @Input() average: any = {};
  public globalRating: any = 1;

  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.average && Object.keys((changes.average.currentValue)||{}).length){
      let data = changes.average.currentValue;
      this.calculateGlobalRating(data);
    }
  }
  private calculateGlobalRating(averageObj){
    ///


    let {execution, disponibilite, respectDevis, respectProjet, proprete
    , respectDelais, competitivite, tolerence } = averageObj;
    
    let ratingsList = [
      execution, disponibilite, respectDevis, respectProjet, 
      proprete, respectDelais, competitivite, tolerence
    ]; 

    let calculatedAverage = ratingsList.reduce((partialSum, item) => partialSum + item, 0) / ratingsList.length;
    
    this.globalRating = calculatedAverage.toFixed(2);
    let roundedCalculatedAverage = calculatedAverage.toFixed(2); 
    this.globalRating = this.roundHalf(roundedCalculatedAverage);

    /// from 3.322 to Or from 3.87 to 4
    this.floorOrRoundRantings(averageObj);
  }
  private floorOrRoundRantings(object){
    let allowed = [
      "execution", "disponibilite", "respectDevis", "respectProjet", 
      "proprete","respectDelais", "competitivite", "tolerence",
    ];

    for(let key in object){
      if(allowed.includes(key)){
        object[key] = this.roundHalf(object[key]);
        console.log(key, object[key]);
      }
    }
  }
  
  /// round to the next whole number, or to half
  public roundHalf(num) {
    return Math.ceil(num*2)/2;
  }



}
