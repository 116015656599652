<div class="margin-left-15">
  <div class="padding-24" style="color: #0A1747">
    <p class="ag-text-18 ag-text-bold">Autres indicateurs environnementaux réglementaires</p>
    <p>Grâce aux donnnées environnementales saisies, 27 impacts environnementaux de l'ensemble du projet ont pu être calculés !</p>
    <p class="color-3805F2 ag-text-16 ag-text-bold">Tout savoir sur les indicateurs calculés</p>
    <div class="choice-switch">
      <div class="ag-text-bold color-3805F2 ag-text-underline one-choice" [ngClass]="{'one-choice-selected': selectedTab=='Impacts environnementaux'}" (click)="changeTab('Impacts environnementaux')">Impacts environnementaux</div>
      <div class="ag-text-bold color-3805F2 ag-text-underline one-choice" [ngClass]="{'one-choice-selected': selectedTab=='Consommation des ressources'}" (click)="changeTab('Consommation des ressources')">Consommation des ressources</div>
      <div class="ag-text-bold color-3805F2 ag-text-underline one-choice" [ngClass]="{'one-choice-selected': selectedTab=='Déchets et flux sortants'}" (click)="changeTab('Déchets et flux sortants')">Déchets et flux sortants</div>
    </div>
    <div class="main-container">
      <div>
        <canvas id="chart-impacts-environnementaux"></canvas>
      </div>
      <div>
        <table class="table table-striped table-borderless">
          <thead>
            <tr>
              <th style="width: 70%;">Indicateurs</th>
              <th>Total cycle de vie</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let oneInd of showIndicators() | keyvalue : returnZero">
              <td>{{oneInd.key}}</td>
              <td>{{oneInd.value | number:'1.0-2':'fr'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
