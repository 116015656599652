import { Component, OnInit, Input, NO_ERRORS_SCHEMA } from '@angular/core';
import { Router } from '@angular/router';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $ :any;
@Component({
  standalone:true,
  selector: 'app-max-library-posts-modal',
  imports: [NgSharedModule],
  templateUrl: './max-library-posts-modal.component.html',
  styleUrls: ['./max-library-posts-modal.component.scss']
})
export class MaxLibraryPostsModalComponent implements OnInit {
  @Input() showLibraryText: boolean = false;

  public currentLicence;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }
  public redirectToPricingTable(){
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
    $('.modal').modal('hide'); /// hide all modals
  }

}
