<div id="architect-emails-templates-dropdown">
  

  <div class="dropdown text-right">
    <button class="ag-btn-primary-xs dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
      Utiliser un modèle
    </button>
    <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
      <li class="pointer" 
        *ngFor="let template of emailsTemplates" (click)="emitEmailTemplateSelected(template)">
        {{ template.name }}
      </li>
    </ul>
  </div>

</div>