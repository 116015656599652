<div class="lots-table-wrapper" *ngIf="lotsDataFormatted">
  <!-- lots Table Header -->
  <div class="lots-table-header flex">
    <div class="lots-table-cell width-5"></div>
    <div class="lots-table-cell width-25">
      <p>LOTS</p>
    </div>
    <div class="lots-table-cell width-20">
      <p>RÉSUMÉ</p>
    </div>
    <div class="lots-table-cell flex width-50">
      <p>ENTREPRISES</p>
      <div class="margin-left-20 search-container">
        <span class="pointer" *ngIf="!showSearchInput">
          <i class="fa fa-search" (click)="toggleSearchInput()"></i>
        </span>
        <input type="text" placeholder="Rechercher une entreprise"
          *ngIf="showSearchInput" [(ngModel)]="searchQuery" (keyup.enter)="emitSearchAgencies()">
        
        <img src="/assets/close.svg" class="empty-search-icon pointer" (click)="clearSearchQuery()">
      </div>
    </div>
  </div>
  <!-- END lots Table Header -->
  
  <!-- lots-Table Body -->
  <div class="lots-table-body scroller-2-y">

    <div class="single-table-body-row" *ngFor="let lot of formattedLotsData; let index = index;">
      
      <!-- Info Container -->
      <div class="table-info-container flex" [ngClass]="{'opened-lot': openedLots[lot.id]}">

        <!-- Arrows -->
        <div class="lots-table-cell arrows-cell width-5 flex">
          <!-- when there is no icon, this to keep the lots icons aligned -->
          <span class="no-arrow-placeholder width-15 margin-right-15" *ngIf="!lot.artisansCards.length"></span>
          <!-- END when there is no icon, this to keep the lots icons aligned -->
          <span class="pointer margin-right-15" (click)="expandTableRow(lot.id)" *ngIf="lot.artisansCards.length">
            <img src="assets/enterprises-icons/right-icon.svg" *ngIf="!openedLots[lot.id]">
            <img src="assets/enterprises-icons/down-icon.svg" *ngIf="openedLots[lot.id]">
          </span>

          <span>
            {{ lot.indexLot }}
          </span>
        </div>
        <!-- END Arrows -->

        <!-- Lot name -->
        <div class="lots-table-cell name-cell flex width-25">
          <span class="img-container">            
            <img src="/assets/rob/helmet.png" *ngIf="!lot.icon">
            <img [src]="'assets/dpgf/Icon_DPGF/'+lot.icon" *ngIf="lot.icon">
          </span>

          <p>{{ lot.name }}</p>
        </div>
        <!-- END Lot name -->

        <!-- Resumes -->
        <div class="lots-table-cell resume-cell flex width-20">
          <span class="flex-middle" 
          [ngClass]="resumeItem.count > 0 ? disabledColorsActiveState[resumeItem.color] : resumeItem.color " 
          *ngFor="let resumeItem of lot.resumes">{{ resumeItem.count }}</span>
        </div>
        <!-- END Resumes -->

        <!-- Artisans Names -->
        <div class="lots-table-cell enterprises-cell flex flex-wrap width-50">

          <!-- Lot Estimation -->
          <div class="lot-estimation" *ngIf="openedLots[lot.id]">

            <!-- Lot generality (display the total project estimation) -->
            <p *ngIf="lot.id == 'general'">
              <span *ngIf="totalHt">Estimation : {{ totalHt | formatPrice }} €</span>
              <span *ngIf="!totalHt">Estimation :  Non renseignée</span>
            </p>

            <!-- Else display each lot Estmiation -->
            <p *ngIf="lot.id != 'general'">
              <span *ngIf="lot.totalArchi">Estimation : {{ lot.totalArchi | formatPrice }} €</span>
              <span *ngIf="!lot.totalArchi">Estimation :  Non renseignée</span>
            </p>
          </div>
          <!-- END Lot Estimation -->
          
          <!-- If No Artisans Show open modal icon -->
          <div class="open-add-artisan-icon-container" *ngIf="!lot.artisansNames.length">
            <img class="pointer" src="assets/dpgf/add-lots-enterprise.svg" (click)="emitOpenAddAgenyModal(lot)">
          </div>
          <!-- END If No Artisans Show open modal icon -->
          
          <!-- Names List -->
          <ng-container *ngIf="!openedLots[lot.id]">
            <div class="single-enterprise-item" [ngClass]="artisan.color" *ngFor="let artisan of lot.artisansNames">
              <p>{{ artisan.name }}</p>
            </div>
          </ng-container>
          <!-- END Names List -->

        </div>
        <!-- END Artisans Names -->

      </div>
      <!-- END Info Container -->

      <!-- Dropdown Container -->
      <div class="table-dropdown-container flex" [ngClass]="{'expanded': openedLots[lot.id]}">
        
        <div class="enterprises-cards-wrapper flex flex-wrap padding-top-10 padding-bottom-8">

          <!-- Single Enterprise Card -->
          <div class="single-enterprise-card margin-right-5 pointer" 
            *ngFor="let enterprise of lot.artisansCards"
            [ngClass]="enterprise.color"
            (click)="emitOpenArchitectAgencyModal(enterprise)"
            >            
            <!-- Top Section -->
            <div class="top-section flex center-items">
              <div class="img-container">
                <img [src]="enterprise.logo">
              </div>
              <p class="artisan-name">
                <span *ngIf="lot.isFactice">{{ enterprise.facticeArtisanInfo.name }}</span>
                <span *ngIf="!lot.isFactice">{{ enterprise.name }}</span>
              </p>
            </div>
            <!-- END Top Section -->

            <!-- Bottom Section -->
            <div class="bottom-section flex-space-between center-items">
              <div class="left-side">
                
                <span class="status-badge" *ngIf="enterprise.column == 'en_cours' ">Remise prévue</span>
                <span class="status-badge" *ngIf="enterprise.column == 'en_attente' ">DCE transmis</span>
                <span class="status-badge" *ngIf="enterprise.column == 'offre_remise' && enterprise?.artisanLotPrice">
                  <img src="/assets/enterprises-icons/medal-icon.svg" class="margin-right-5" *ngIf="enterprise?.lotIsSigned">
                  {{ enterprise.artisanLotPrice | formatPrice }} €
                </span>
                <span class="status-badge" *ngIf="enterprise.column == 'ne_chiffre_pas' ">Projet refusé</span>

              </div>
              
              <div class="right-side">
                
                <!-- <p *ngIf="enterprise.column == 'a_consulter' ">
                  {{ enterprise.invitation?.createdAt | date: 'dd/MM/yyyy' }}
                </p> -->
                <p *ngIf="enterprise.column == 'en_attente'">
                  {{ enterprise?.dateDceSent | date: 'dd/MM/yyyy' }}
                </p>
                <p *ngIf="enterprise.column == 'en_cours' ">
                  {{ enterprise?.deliveryDate | date: 'dd/MM/yyyy' }}
                </p>
                <p *ngIf="enterprise.column == 'offre_remise' ">
                  {{ enterprise?.sentPricingDate | date: 'dd/MM/yyyy' }}
                </p>
                <p *ngIf="enterprise.column == 'ne_chiffre_pas' ">
                  {{ enterprise?.refusalDate | date: 'dd/MM/yyyy' }}
                </p>
              </div>

            </div>
            <!-- END Bottom Section -->

          </div>
          <!-- END Single Enterprise Card -->

        </div>

      </div>
      <!-- END Dropdown Container -->      

    </div>

  </div>
  <!-- END lots-Table Body -->

</div>