<div class="modal fade" id="factice-artisan-popup">
  <div class="modal-dialog no-padding">
    <div class="modal-container">
      <!-- Upper Section -->
      <div class="upper-section">
        <div class="head margin-bottom-24">
          <a (click)="closeModal()" class="close"><i class="fa fa-times" aria-hidden="true"></i></a>
          <h4 class="margin-top-0">Créer un chiffrage</h4>
          <p>La création de chiffrage vous permet d'intégrer dans AGLO les chiffrages reçus en dehors de l'application.
            Vous pouvez également dupliquer un chiffrage déjà reçu et le modifier pour l'intégrer au comparatif.</p>
        </div>
        <!-- FORM  -->
        <div class="chiffre-form padding-24">

          <div class="horizontal-items margin-bottom-24">
              <p class="bold-sm-text no-margin">Informations du chiffrage</p>
          </div>

          <div class="horizontal-items margin-bottom-24">
              <div class="width-25">
                <p class="sm-text padding-top-5">Nom de l'entreprise</p>
              </div>

              <div class="width-70 vertical-items relative">
                <input type="text" placeholder="exemple : AGLO" class="std-input" (keyup)="changeArtisanName($event)">
                <span class="ag-text-danger margin-top-5" *ngIf="showEmptyNameErr">
                  Veuillez renseigner le nom de l'entreprise.</span>
              </div>
          </div>

          <div class="horizontal-items">
              <div class="width-25">
                <p class="sm-text">Vous souhaitez</p>
              </div>

              <div class="width-70 vertical-items relative">
                <div class="horizontal-items">
                  <p class="margin-right-10">
                    <input type="radio" name="current_list" value="lots" (change)="changeCurrentList('lots')"> Créer un nouveau chiffrage
                  </p>
                  <p>
                    <input type="radio" name="current_list" value="artisans" (change)="changeCurrentList('artisans')"> Dupliquer un chiffrage existant
                  </p>
                </div>
              <div>
                <span class="ag-text-danger bottom-5" *ngIf="showNoSelectionErr">Veuillez faire un choix.</span>
              </div>

              </div>
          </div>

        </div>
      <!-- END FORM  -->
    </div><!-- END upper Section -->

      <app-spinner *ngIf="submitting"></app-spinner>
      <div class="cards-wrapper" *ngIf="!submitting">
        <!-- Lots List -->
        <div class="lots-card margin-top-12 padding-24" *ngIf="currentList == 'lots'">
            <div class="margin-bottom-10 list-upper-text">
              <p class="bold-sm-text">Quel(s) lot(s) souhaitez-vous chiffrer ?</p>
              <span class="no-padding ag-text-danger" *ngIf="showNoLotsErr">Vous souhaitez chiffrer sur quel lot</span>
            </div>
            <div class="lots-list-wrapper scroller-1">
              <div class="single-lot" *ngFor="let lot of dpgfLot" (click)="selectLot(lot)">
                <p>
                  <input type="checkbox" [value]="lot.id" class="margin-right-15"
                    [(ngModel)]="selectedLot[lot.id]" (onChange)="selectLot(lot)">
                  <img [src]="lot.icon !='' ? '/assets/dpgf/Icon_DPGF/'+lot.icon : '/assets/rob/helmet.png'" />
                  {{ lot.name }}
                </p>
              </div>
            </div>
        </div>
        <!-- END Lots List -->

        <!-- Projects List -->
        <div class="artisans-card margin-top-12 padding-24" *ngIf="currentList == 'artisans'">
          <div class="margin-bottom-10 list-upper-text">
            <p class="bold-sm-text">De quelle entreprise souhaitez-vous dupliquer le chiffrage ? </p>
            <p class="no-padding ag-text-danger" *ngIf="showNoChiffrageErr">Veuillez choisir un chiffrage à dupliquer</p>
          </div>
          <div class="artisans-list-wrapper scroller-1">
            <div class="single-artisan" *ngFor="let invite of invitationsList" (click)="chooseArtisan($event, invite)">
              <p>
                <img [src]="(invite.artisan?.logo?.cdnUrl || 'assets/rob/helmet.png')" alt="">
                <span>{{ invite.artisanName == '' ? invite.artisan.name : invite.artisanName }}</span>
              </p>
            </div>
            <!-- Empty Artisans List -->
            <div class="empty-artisans-list-wrapper" *ngIf="invitationsList.length == 0">
              <p class="text-center">Vous n'avez pas encore reçu de chiffrage</p>
            </div>
            <!-- END Empty Artisans List -->
          </div>
        </div>
        <!-- END Projects List -->

      </div>
      <div class="bottom-nav-buttons padding-24 text-right" *ngIf="!submitting">
        <button class="ag-btn-primary"
          *ngIf="currentList == 'lots'"
          [disabled]="isProjectDemo"
          (click)="submitForm('lots')">Ajouter le chiffrage</button>

        <button class="ag-btn-primary"
          *ngIf="currentList == 'artisans'"
          [disabled]="isProjectDemo"
          (click)="submitForm('artisans')">Ajouter le chiffrage</button>

        <button class="ag-btn-primary next-btn"
          *ngIf="currentList == ''"
          [disabled]="isProjectDemo"
          (click)="nextSlide()">Ajouter le chiffrage</button>
      </div>

    </div>
  </div>
</div>
