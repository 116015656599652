<div class="page-wrapper main-page-white-lilac">
  
  <app-header type="full"></app-header>

  <app-spinner *ngIf="loading"></app-spinner>

  <div class="container-fluid" *ngIf="!loading">
    <div class="row">
      <div class="col-md-9 no-padding-horizontal">

        <!-- Tabs Toggle Buttons -->
        <div class="tabs-toggle-wrapper horizontal-items space-between">
          <div class="single-tab width-50 flex-middle pointer" 
            [ngClass]="{'choosen': seletedTab == 'relisations'}" (click)="toggleTab('relisations')">
            <p class="no-padding-bottom">
              <i class="fa fa-image margin-right-12"></i>
              Mes réalisations 
            </p>
          </div>
          <div class="single-tab width-50 flex-middle pointer" 
            [ngClass]="{'choosen': seletedTab == 'recommandation'}" (click)="toggleTab('recommandation')">
            <p class="no-padding-bottom">
              <i class="fa fa-send-o margin-right-12"></i>
              Mes demandes de recommandation
            </p>
          </div>
          
        </div>
        <!-- END Tabs Toggle Buttons -->

        <!-- Tabs Pages Wrapper -->
        <div class="tabs-pages-wrapper">
          <container-element [ngSwitch]="seletedTab">
            
            <app-booster-profile-page-realisations 
              [albums]="albums"
              [photos]="artisanPhotos"
              (saveAlbums)="saveAlbums($event)" 
              (savePhotos)="savePhotos($event)"
              *ngSwitchCase="'relisations'">
            </app-booster-profile-page-realisations>
            
            <app-booster-profile-page-demndes-recommandation
              [recommandation]="recommandation" 
              (saveContacts)="saveRecommandations($event)"
              *ngSwitchCase="'recommandation'">
            </app-booster-profile-page-demndes-recommandation>

          </container-element>
        </div>
        <!-- END Tabs Pages Wrapper -->

      </div>

      <!-- Sidebar -->
      <div class="col-md-3 sidebar-card padding-top-10 scroller-1">
        <app-booster-profile-info-side-bar 
          [artisanData]="sideBarData"
          [showEditButtons]="true"
          (openSpecificModal)="openSideBarModals($event)"
          (updateLogo)="updateModalInformation($event)"
          >
        </app-booster-profile-info-side-bar>
      </div>
      <!-- END Sidebar -->
    </div>
  </div>

  <!-- SideBar Modals
  ======================================= -->
  <ng-container *ngIf="!loading">

    <!-- Confirm Delete Logo Modal -->
    <app-confirm-modal
      [modalId]="'confirm-remove-logo'"
      [headerTitle]="'Suppression du logo'"
      [bodyText]="'Vous êtes sur le point de supprimer votre logo, êtes-vous sûr(e) ?'"
      (confirm)="updateModalInformation({logo: {} })"
    ></app-confirm-modal>
    <!-- END Confirm Delete Logo Modal -->

    <app-booster-profile-edit-ageny-name-modal
      [dataList]="sideBarData.type"
      [agenyName]="sideBarData.name"
      (updateAgencyInfo)="updateModalInformation($event)"
    ></app-booster-profile-edit-ageny-name-modal>
    
    <app-booster-profile-edit-information-modal
      [artisanData]="editInformationModalData"
      (saveData)="updateModalInformation($event)"
    ></app-booster-profile-edit-information-modal>

    <app-booster-profile-edit-garantie-decennale-modal
      [corpsDetat]="corpsDetat"
      (saveData)="updateModalInformation($event)"
    >
    </app-booster-profile-edit-garantie-decennale-modal>
    
    <app-booster-profile-garantie-decennale-view-only
      [corpsDetat]="corpsDetat"
    ></app-booster-profile-garantie-decennale-view-only>

    <app-booster-profile-edit-documents-modal
      [docsData]="docsModalData"
      (saveData)="updateModalInformation($event)"
    ></app-booster-profile-edit-documents-modal>

    <app-booster-profile-edit-construction-carbone-modal
      [artisanReemploiList]="reemploiModalData"
      (saveData)="updateModalInformation($event)"      
    ></app-booster-profile-edit-construction-carbone-modal>

    <app-booster-profile-edit-precisions-aglo-booster-modal
      [artisanData]="precisionsAgloBoosterModalData"
      (saveData)="updateModalInformation($event)"
    ></app-booster-profile-edit-precisions-aglo-booster-modal>

  </ng-container>
  
  <!-- END SideBar Modals
  ======================================= -->

</div>