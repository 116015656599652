import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { trigger,state,style,transition,animate } from '@angular/animations';
import { ActivatedRoute , Params } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as chantierActions from '../../../chantier/_store/chantier.actions';
import * as fromChantier from '../../../chantier/_store';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { DpgfPostesService, AuthService } from '../../../_services/index';
import { saveAs } from 'file-saver';
//import { ArchitectModule } from '../architect.module';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { TableFactureComponent } from 'src/app/chantier/table-facture/table-facture.component';
import { NeedSubscriptionPopup2Component } from 'src/app/architect/need-subscription-popup2/need-subscription-popup2.component';
import { DocumentsErrorsModalComponent } from 'src/app/all/documents-errors-modal/documents-errors-modal.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

declare var $:any;
@Component({
    standalone: true,

    selector: 'app-archi-chantier-facture',
    imports:[
      
      SpinnerComponent,
      TableFactureComponent,
      NeedSubscriptionPopup2Component,
      DocumentsErrorsModalComponent,
      NgSharedModule
    ],
    templateUrl: './archi-chantier-facture.component.html',
    styleUrls: ['./archi-chantier-facture.component.scss'],
    animations: [
        trigger('appear', [
            state('hide', style({
                top: '-50px',
            })),
            state('show', style({
                top: '10px',
            })),
            transition('hide => show', animate('2000ms ease-in')),
            transition('show => hide', animate('2000ms ease-in')),
        ]),
    ],

})
export class ArchiChantierFactureComponent implements OnInit {

  public facture$:Observable<any>;
  public flags$:Observable<any>;
  public documentsErrorInfo: any = {};
  public dpgfFactureId;
  public isSticky:boolean = true;
  public artisanMessage:string;

  public state: string = 'hide';
  private factureDocumentName: string = undefined;
  //public saving:boolean = false;
  //public loadingData:boolean = false;
  //public finishing:boolean = false;
  //public done:boolean = false;
  //public loading = false;

  constructor(private route: ActivatedRoute,
              private location: Location,
              private store:Store<any>,
              private DpgfPostesService: DpgfPostesService,
              private authService: AuthService,
            ) { }

  ngOnInit() {
    this.getFacture();
    this.getFlags();
  }

  private getFacture() {
    this.route.params.forEach((params: Params) => {
      this.dpgfFactureId = params['dpgfFactureId'];
      // Dispatch Load
      this.store.dispatch(new chantierActions.LoadFacture(this.dpgfFactureId));
      // Select Loaded facture
      this.facture$ = this.store.pipe(select(fromChantier.getFacture));
      /// to get the information required for downloading file name
      this.store.pipe(select(fromChantier.getFacture))
        .subscribe(
          data => {
            if(data){
              this.factureDocumentName = `Facture - ${data.ceProject.title} - ${data.artisan.name}`;
            }
          }
        );

    })
  }

  private getFlags() {
    this.flags$ = this.store.pipe(select(fromChantier.getFlags));
  }

  public saveFactureUpdate() {
    this.store.dispatch(new chantierActions.SaveUpdateFacture());
  }

  public rejectFacture() {
    if(this.authService.licence == "without"){
      $('#upgrade-modal').modal('show');
      return;
    }
    if(!environment.onPremise)window.analytics.track('refuse_situation_chantier',{properties:{acValue: 'refuse_situation_chantier'}});
    let rejectedFacture:any = {};
    rejectedFacture.message = this.artisanMessage;
    this.store.dispatch(new chantierActions.RejectFacture({factureId: this.dpgfFactureId, facture: rejectedFacture}));
  }

  public acceptFacture() {
    if(this.authService.licence == "without"){
      $('#upgrade-modal').modal('show');
      return;
    }
    if(!environment.onPremise)window.analytics.track('accept_situation_chantier',{properties:{acValue: 'accept_situation_chantier'}});
    let acceptedFacture:any = {};
    acceptedFacture.message = this.artisanMessage;
    this.store.dispatch(new chantierActions.AcceptFacture({factureId: this.dpgfFactureId, facture: acceptedFacture}));
  }

  public downloadFacture(facture) {
    let selectedLots = this.getSelectedLotsIds(facture.data || []);
    let body = {
      isArtisan: false,
      isFirst: facture.isFirst,
      selectedLots
    };
    
    this.DpgfPostesService.downloadFacturePdf(facture.dpgf, facture._id, body).subscribe(
      (data)=>{
        let fileName = this.factureDocumentName || data.data.pdfName;
        saveAs(data.data.data, `${fileName}.pdf`);
      },
      (err) => {
        this.documentsErrorInfo = ( err?.error?.err?.error || {} );
        this.openDocumentErrorsModal();
      }
    )
  }
  private getSelectedLotsIds(data){
    let selectedLotsIds = [];
    for(let i = 0; i < data.length; i++){
      selectedLotsIds.push(data[i].id);
    }
    return selectedLotsIds;
  }
  private openDocumentErrorsModal(){
    $("#documents-errors-modal").modal('show');
  }

  public scrollTo(element){
    $('html, body').animate({
      scrollTop: ($("#" +element).offset().top - 60)
    });
  }

  public goBack() {
    this.location.back();
  }

}
