import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, HostListener, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { MessageService } from 'primeng/api';
const _ = require('lodash');

import { Subscription } from 'rxjs';
import { DpgfPostesService } from 'src/app/_services/index';

import { zoneClimatiqueOptions, batimentUsageOptions,zoneDonneesGenerales} from './options';

import { reseauChaudOptions, reseauFroidOptions } from './reseauOptions';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $;

@Component({
    standalone:true,
    selector: 'app-acv-project-description-page',
    imports:[
      NgSharedModule
    ],
    providers: [MessageService],
    templateUrl: './acv-project-description-page.component.html',
    styleUrls: ['./acv-project-description-page.component.scss'],
    
})
export class AcvProjectDescriptionPageComponent implements OnInit {
  @Input() dpgfId;
  @Input() acvData;
  @Input() isProjectDemo:Boolean=false;
  @Input() state;
  @Output() sendToParent: EventEmitter<any> = new EventEmitter<any>();

  public TotalFormInputs: number = 0;
  public TotalValidFormInputs: number = 0;

  private subscriptions: Array<Subscription> = [];
  public reseauFilterdOptions: any = {
    'Commune': [],
    'nomReseau': [],
  };
  public selectOptionsPlaceholders = {
    "Typology": "Renseignez le type de batiment"
  };

  public reseauChaudOptionsCopy = reseauChaudOptions;
  public reseauFroidOptionsCopy = reseauFroidOptions;
  public selectedReseauOptions = reseauChaudOptions;

  public selectedCommuneImplantationReseau: string = "";dpgf;
  public selectedNomReseau: string = "";

  // public currentView: string = 'projet_informations_generales';
  public currentView: string = 'description_du_batiment';
  public selectedOption: string = 'projet';
  public selectedbatiment: any ={value:''};
  public selectedZone: string;
  public zoneIsSelected: boolean = false;
  /// Forms
  public selectedNatureGeotechnique;

  public prevState: any = {};
  public selectedState: any = {};loading:boolean=false;
  selectedReseauOptionsCopy: { id: string; nomReseau: string; Commune: string; contenuCO2: string; contenuCO2ACV: string; tauxEnrR: string; }[];

  constructor(
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private dpgfPostesService: DpgfPostesService
  ) { }

  @HostListener('document:click', ['$event.target']) onClick(targetElement) {
    let id = targetElement.id;
    if(!(id=='selectedCommuneImplantationReseau' || id=='selectedNomReseau' || id=='sugg-input')) {
      $('#selectedCommuneImplantationReseau').hide()
      $('#selectedNomReseau').hide()
    }
  }
  ngOnInit(): void {
    let zonesNamesWithFather = _.get(this.acvData,'projectRSET.zonesNamesWithFather',[]);
    this.fillEntities(zonesNamesWithFather);

    this.fillInputs(this.acvData['projectDescription'] || {});

    this.checkFilledFormInputs();

    // this.fromRsetToInputs(this.acvData['projectRSET']);

    // this.selectedbatiment = this.projectDescriptionOptions[0];

    this.fromGlobalStateToLocal(this.state);
  }
  private fromGlobalStateToLocal(obj){
    if(!obj)return;
    for(let key in obj){
      for(let nestedKey in obj[key]){
        _.set(this.prevState, `${key}.${nestedKey}`, obj[key][nestedKey]);
      }
    }
  }
  private fromRsetToInputs(rset){
    let dateLivraison = _.get(rset,'dateLivraison', '');
    let nomRaisonSocialeEntreprise = _.get(rset,'nomRaisonSocialeEntreprise', '');
    let dateDepotPermisConstruire = _.get(rset,'dateDepotPermisConstruire', '');
    let altitude = _.get(rset,'altitude', '');
    let zoneClimatique = _.get(rset,'zoneClimatique', '');

    /// dateLivraison
    if(!_.get(this.prevState, 'projet.description_du_batiment-DateLivraison', '')){
      _.set(this.prevState, 'projet.description_du_batiment-DateLivraison', dateLivraison);
    }
    /// nomRaisonSocialeEntreprise
    if(!_.get(this.prevState, 'projet.description_du_batiment-NomEntreprises', '')){
      _.set(this.prevState, 'projet.description_du_batiment-NomEntreprises', nomRaisonSocialeEntreprise);
    }
    /// dateDepotPermisConstruire
    if(!_.get(this.prevState, 'projet.description_du_batiment-DateDepotPC', '')){
      _.set(this.prevState, 'projet.description_du_batiment-DateDepotPC', dateDepotPermisConstruire);
    }
    /// altitude
    if(!_.get(this.prevState, 'projet.description_du_batiment-altitude', '')){
      _.set(this.prevState, 'projet.description_du_batiment-altitude', altitude);
    }
    /// zoneClimatique
    if(!_.get(this.prevState, 'projet.description_du_batiment-zoneClimatique', '')){
      _.set(this.prevState, 'projet.description_du_batiment-zoneClimatique', zoneClimatique);
    }

    /// Batiment Values
    let batiments_collection = _.get(rset, 'Datas_Comp.batiment_collection.batiment', []);
    for(let i = 0; i < batiments_collection.length; i++){
      let singleBatiment = batiments_collection[i];
      if(this.prevState[singleBatiment.Name]){
        /// set Batiment Name
        if(!_.get(this.prevState, `${singleBatiment.Name}.batiment_donnees_generales-Nom`, '')){
          _.set(this.prevState, `${singleBatiment.Name}.batiment_donnees_generales-Nom`, singleBatiment.Name);
        }
        /// set Batiment Usage
        if(!_.get(this.prevState, `${singleBatiment.Name}.batiment_donnees_generales-Typology`, '')){
          _.set(this.prevState, `${singleBatiment.Name}.batiment_donnees_generales-Typology`, singleBatiment.usage.text);
        }
      }

      /// Zone Values
      let zones_collection = _.get(singleBatiment, 'zone_collection.zone', []);
      for(let j = 0; j < zones_collection.length; j++){
        let singleZone = zones_collection[j];
        let zoneName = singleZone['Name'];
        // Usage
        let zoneUsage = _.get(singleZone, 'Usage', 1);
        // SurfaceReference
        let zoneO_SREF = _.get(singleZone, 'O_SREF', 0);
        // NbLogements
        let zoneNB_logement = _.get(singleZone, 'NB_logement', 0);
        // Scombles
        let zoneScombles = _.get(singleZone, 'Scombles', 0);
        // NbOccupants
        let zoneNbOccupants = _.get(singleZone, 'Nocc', 0);

        if(this.prevState[zoneName]){
          // Usage
          if(!_.get(this.prevState[zoneName], 'zone_donnees_generales-Usage', '')){
            _.set(this.prevState[zoneName], 'zone_donnees_generales-Usage', zoneUsage)
          }
          // SurfaceReference
          if(!_.get(this.prevState[zoneName], 'zone_donnees_generales-SurfaceReference', '')){
            _.set(this.prevState[zoneName], 'zone_donnees_generales-SurfaceReference', zoneO_SREF);
          }
          // NbLogements
          if(!_.get(this.prevState[zoneName], 'zone_donnees_generales-NbLogements', '')){
            _.set(this.prevState[zoneName], 'zone_donnees_generales-NbLogements', zoneNB_logement);
          }
          // Scombles
          if(!_.get(this.prevState[zoneName], 'zone_donnees_generales-Scombles', '')){
            _.set(this.prevState[zoneName], 'zone_donnees_generales-Scombles', zoneNB_logement);
          }
          // NbOccupants
          if(!_.get(this.prevState[zoneName], 'zone_donnees_generales-NbOccupants', '')){
            _.set(this.prevState[zoneName], 'zone_donnees_generales-NbOccupants', zoneNbOccupants);
          }
        }
      }
    }
  }
  private fillProjectDescriptionOptions(batiments=[], zones=[]){
    /// Batiments
    for(let i = 0; i < batiments.length; i++){
      let batimentObject = { title: `${batiments[i]}`, value: `${batiments[i]}`, type: 'batiment' };
      this.projectDescriptionOptions.push(batimentObject);
    }
    /// Zones
    for(let i = 0; i < zones.length; i++){
      let zoneObject = { title: `${zones[i]}`, value: `${zones[i]}`, type: 'zone' };
      this.projectDescriptionOptions.push(zoneObject);
    }
  }
  private fillEntities(batiments){
    for (const key in batiments) {
      let zones=batiments[key]
      let trimmedBatiment = key.replace(/ /g, "-");
      let batimentObject = {label: `${key}`, value: `${key}`, trimmedValue: trimmedBatiment, type: 'batiment',zones:[]};
      for(let i = 0; i < zones.length; i++){
        let trimmedZone = zones[i].replace(/ /g, "-");
        let zoneObject = {label: `${zones[i]}`, value: `${zones[i]}`, trimmedValue: trimmedZone, type: 'zone'};
        // array choices in select
        batimentObject.zones.push(zoneObject);
      }
      this.projectDescriptionOptions.push(batimentObject)
    }
    /// if no batimes add the defaults
    if(!Object.keys(batiments).length){
      let batiment = {
        label: `Bâtiment`,
        value: `Bâtiment`,
        trimmedValue: 'Bâtiment',
        type: 'batiment',
        zones:[
          {
            label: `Zone`, value: `Zone`, trimmedValue: 'Zone', type: 'zone'
          }
        ]
      };
      this.projectDescriptionOptions.push(batiment);
    }

    // set Initial
    this.selectedbatiment = this.projectDescriptionOptions[0];

  }

  // 1- Sidebar Options
  public projectDescriptionOptions: any = [
    { label: 'Projet', value: 'projet', trimmedValue: 'projet', type: 'projet'},
  ];
  private projectDescriptionOptionsViews = {
    'projet': 'description_du_batiment',
    'batiment': 'batiment_donnees_generales',
    'zone': 'zone_donnees_generales',
  };
  // 3- forms
  public forms: any = {
    //////////////////////////////////
    /// 0 - Projet
    //////////////////////////////////
    'description_du_batiment': {
      mainTitle: 'Description du projet',
      mainDescription: 'Les champs marqués par sont nécessaires pour le calcul d’ACV, tous les autres sont nécessaires pour la génération du RSEE',
      items: [
        /// 1
        {
          type: 'groupTitle', title: 'Informations générales'
        },
        {
          label: "Phase du projet", type: "select",
          modelVariable: 'Phase',
          options: [
            {num: '1', label: 'Programmation', value: '1' },
            {num: '2', label: 'Esquisse', value: '2' },
            {num: '3', label: 'APS', value: '3' },
            {num: '4', label: 'APD', value: '4' },
            {num: '5', label: 'PRO DCE', value: '5' },
            {num: '6', label: 'EXE', value: '6' },
            {num: '7', label: 'Construction', value: '7' },
            {num: '8', label: 'Exploitation', value: '8' }
          ]
        },
        {label: "Date de livraison du projet (prévue)", type: "date", modelVariable:"DateLivraison"},
        {label: "Entreprise générale responsable des travaux", type: "text", placeholder:"Nom de l”entreprise", modelVariable:"NomEntreprises"},
        /// 2
        {
          type: 'groupTitle', title: 'Permis'
        },
        {
          label: "Permis de construire", type: "radio", optionName: "permisDeConstruire",
          options: [
            {label: "Pas encore déposé", value: "0"},
            {label: "En cours", value: "1"},
            {label: "Obtenu", value: "2"},
          ],
          modelVariable:"permisDeConstruire"
        },
        {label: "Date de dépôt du permis de construire", type: "date", modelVariable:"DateDepotPC"},
        {
          label: "Raccordement au réseau de gaz prévu", type: "radio", optionName: "raccordementReseauGazPrevu",
          options: [
            {label: 'Oui', value: 'Oui'},
            {label: 'Non', value: 'Non'},
          ],
          modelVariable:"raccordementReseauGazPrevu"
        },
        {
          label: "Date d’obtention du permis d’aménager",
          type: "date", modelVariable:"DateObtentionPAGaz"
        },
        /// 3
        {
          type: 'groupTitle', title: 'Environnement du terrain'
        },
        {label: "Altitude *", type: "radio", optionName: "altitude",
          options: [
            {label: "Moins de 400m (inclus)", value: "0"},
            {label: "Entre 400m et 800m inclus", value: "1"},
            {label: "Supérieure à 800m", value: "2"},
          ],
          modelVariable:"altitude",
        },
        {label: "Zone climatique *", type: "select", options: zoneClimatiqueOptions, modelVariable:"zoneClimatique", showValue: true},
        {label: "Pluviométrie annuelle du site *", type: "number", placeholder: "0", modelVariable:"pluviometrieAnnuelleSite",  inputLabel: "mm/m²"},
        /// 4 Réseau
        {
          type: 'groupTitle', title: 'Réseau'
        },
        {label: "Type du réseau", type: "radio", optionName: "type_de_reseau",
          options: [
            {label: 'Chaud', value: '0'},
            {label: 'Froid', value: '1'},
          ],
          modelVariable:"typeReseau",
        },
        {label: "Commune d’implantation du réseau", type: "custom_select",
          options: this.selectedReseauOptions, selectedLabel: "Commune",
          modelVariable:"choixReseau",
          variableModel: 'selectedCommuneImplantationReseau'
        },
        {label: "Nom du réseau *", type: "custom_select",
          options: this.selectedReseauOptions, selectedLabel: "nomReseau",
          modelVariable:"choixReseau",
          variableModel: 'selectedNomReseau'
        },
        /// 5 Parcelle
        {
          type: 'groupTitle', title: 'Parcelle'
        },
        {label: "Surface de la parcelle *", type: "number", placeholder: "0", modelVariable:"surfaceParcelle",  inputLabel: "m²"},
        {label: "Surface arrosée * (hors toitures et murs végétalisées)", type: "number", placeholder: "0", modelVariable:"surfaceArrosee",  inputLabel: "m²"},
        {label: "Surface imperméabilisée *", type: "number", placeholder: "0", modelVariable:"surfaceImpermeabilisee",  inputLabel: "m²"},
        {label: "Surface végétalisée *", type: "number", placeholder: "0", modelVariable:"surfaceVegetalisee",  inputLabel: "m²"},
      ]

    },
    //////////////////////////////////
    /// 2 - Batiment
    //////////////////////////////////
    'batiment_donnees_generales': {
      mainTitle: 'Description générale du batiment',
      items: [
        // {label: "Nom du bâtiment", type: "text", placeholder: "Nom du bâtiment", modelVariable:"Nom",},
        {label: "Usage", type: "select", placeholder: "", options: batimentUsageOptions, modelVariable:"Typology",},
        {label: "Surface d'emprise au sol *", type: "number", placeholder: "0", modelVariable:"EmpriseAuSol",  inputLabel: "m²"},
        {label: "Durée du chantier", type: "number", placeholder: "0", modelVariable:"DureeChantier",  inputLabel: "mois"},
        // {label: "Labels, certifications, démarches", type: "number", modelVariable:"labelsCertificationsDemarches", inputLabel: "m2"},
        // {label: "Renseignez les labels visés s'il y en a", placeholder: "0", type: "number", modelVariable:"labelsCertificationsDemarches"},
      ],
    },
    //////////////////////////////////
    /// 3 - Zone
    //////////////////////////////////
    'zone_donnees_generales': {
      mainTitle: 'Données générales de la zone',
      mainDescription: 'Les champs marqués par sont nécessaires pour le calcul d’ACV, tous les autres sont nécessaires pour la génération du RSEE',
      items: [
        {label: "Usage", type: "select", options: zoneDonneesGenerales, modelVariable:"Usage",},
        {label: "Surface de référence", type: "number", placeholder: "0", modelVariable:"SurfaceReference",  inputLabel: "m²"},
        {label: "Surface de combles", type: "number", placeholder: "0", modelVariable:"Scombles",  inputLabel: "m²"},
        {label: "Nombre de logements *", type: "number", placeholder: "0", modelVariable:"NbLogements",  inputLabel: "logements"},
        {label: "Nombre d’occupants attendus *", type: "number", placeholder: "0", modelVariable:"NbOccupants",  inputLabel: "occupants"},
      ],
    },
  };
  public optionSelected(currentView, variable, value){
    let batimentKey = `${this.selectedbatiment['trimmedValue']}.${variable}`;
    let zoneKey = `${this.selectedbatiment['trimmedValue']}.${this.selectedZone}.${variable}`;
    let key = !this.zoneIsSelected ? batimentKey : zoneKey;

    /// Set Prev State
    _.set(this.prevState, key, value);
    let updateData = {
      [key]: value
    }
    this.sendDataToParent(updateData);
  }
  public radioSelected(currentView, variable, value){
    if(variable === 'typeReseau'){
      let selectedOptions = value == 0 ? reseauChaudOptions : reseauFroidOptions;
      this.selectedReseauOptions = selectedOptions;
    }
    let batimentKey = `${this.selectedbatiment['trimmedValue']}.${variable}`;
    let zoneKey = `${this.selectedbatiment['trimmedValue']}.${this.selectedZone}.${currentView}-${variable}`;
    let key = !this.zoneIsSelected ? batimentKey : zoneKey;

    /// Set Prev State
    _.set(this.prevState, key, value);

    let updateData = {
      [key]: value
    }
    // console.log('radioSelected : ', updateData);
    this.sendDataToParent(updateData);
  }
  public inputTyped(currentView, variable, value){
    let batimentKey = `${this.selectedbatiment['trimmedValue']}.${variable}`;
    let zoneKey = `${this.selectedbatiment['trimmedValue']}.${this.selectedZone}.${variable}`;
    let key = !this.zoneIsSelected ? batimentKey : zoneKey;

    /// Set Prev State
    _.set(this.prevState, key, value);
    let updateData = {
      [key]: value
    }
    this.sendDataToParent(updateData);
  }
  public getStateValue(path, value){
    return _.get(this.prevState, `${path}.${value}`, undefined);
  }

  private sendDataToParent(updateData){
    this.sendToParent.emit(updateData);
    this.checkFilledFormInputs();
  }
  private fillInputs(obj){
    for(let key in obj){
      this.prevState[key] = obj[key];
    }
    /// Fill Reasu
    let communeValue = _.get(obj, 'projet.selectedCommuneImplantationReseau', '');
    let nomReseau = _.get(obj, 'projet.selectedNomReseau', '');
    this.selectedCommuneImplantationReseau = communeValue;
    this.selectedNomReseau = nomReseau;
  }
  public resauItemSelected(variableModel, option, selectedLabel){
    // let key = `${this.selectedOption}.${this.currentView}-${variableModel}`;
    let key = `${this.selectedOption}.${variableModel}`;
    if(selectedLabel == 'nomReseau'){
      let IdReseauChFr = (option.id||'').match(/\d+/)[0];
      // let IdReseauChFrKey = `${this.selectedOption}.${this.currentView}-IdReseauChFr`;
      let IdReseauChFrKey = `${this.selectedOption}.IdReseauChFr`;
      let updateData = {
        [IdReseauChFrKey]: IdReseauChFr
      }
      this.selectedNomReseau = option.nomReseau;
      this.sendDataToParent(updateData);
    }else{
      this.selectedCommuneImplantationReseau = option.Commune;
    }

    let value = selectedLabel == 'Commune' ? option.Commune : option.nomReseau;
    this.prevState[key] = value;
    let updateData = {
      [key]: value
    }
    $('#selectedCommuneImplantationReseau').hide()
    $('#selectedNomReseau').hide()
    this.resetResauDefaultValues()
    this.cd.detectChanges()
    this.sendDataToParent(updateData);
  }
  public showOption(option,value,variableModel){
    if((option.Commune||'').toUpperCase()==(this.selectedCommuneImplantationReseau||'').toUpperCase() || variableModel=='selectedCommuneImplantationReseau') {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
  }
  public showOptions(array,id){
    if(id=='selectedNomReseau'){
      return array.filter(option=>(option.Commune||'').toUpperCase()==(this.selectedCommuneImplantationReseau||'').toUpperCase())
    }
    return array
  }
  public formatString(str){
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }
  public removeWhiteSpaces(string){
    return string.replaceAll(' ', '');
  }
  public openNestedList(currentOption, ulId){
    /// open Nested Ul
    let trimmedId = this.removeWhiteSpaces(ulId);
    $('.nested-items-ul').removeClass('open');
    $(`#${trimmedId}`).addClass('open');
    this.zoneIsSelected = false;

    /// select first item
    this.selectedOption = currentOption.trimmedValue;
    if(currentOption['type'] == 'projet'){
      this.currentView = 'description_du_batiment';
    }else{
      this.currentView = 'batiment_donnees_generales';
    }
    $('.nested-items-ul > li').removeClass('selected');

    this.selectedbatiment = currentOption;

    this.checkFilledFormInputs();
  }
  public listItemSelected(event, item){

    ///////////// NEW
    this.currentView = 'zone_donnees_generales';
    this.zoneIsSelected = true;
    this.selectedZone = item['trimmedValue'];
    let trimmedZoneName = item.trimmedValue;
    // this.selectedOption = `${this.selectedOption}.${item.value}`;
    this.selectedOption = `${this.selectedbatiment['trimmedValue']}.${trimmedZoneName}`;

    $('.nested-items-ul > li').removeClass('selected');
    $(event.currentTarget).addClass('selected');
    this.checkFilledFormInputs();
  }
  private findObjIndex(arr, key, value){
    let foundIndex = -1;
    for(let i = 0;i < arr.length; i++){
      if(arr[i][key]){
        if(arr[i][key] === value){
          foundIndex = i;
        }
      }
    }
    return foundIndex;
  }
  public prevSlide(){
    /// if nested item is Selected Choose Parent & Prev
    if(this.zoneIsSelected){
      this.selectedOption = this.selectedOption.split('.')[0];
    }

    let currentItemIndex = this.findObjIndex(this.projectDescriptionOptions, 'value', this.selectedOption);

    /// remove selected from all nested Items
    $('.nested-items-ul').removeClass('open');
    $('.nested-items-ul > li').removeClass('selected');

    if(currentItemIndex == 0){
      /// Last
      let lastOption = this.projectDescriptionOptions[this.projectDescriptionOptions.length - 1];
      this.selectedOption = lastOption.value;
      this.currentView = this.projectDescriptionOptionsViews[lastOption.type];
    }else{
      /// Prev Slide
      let prevSlide = currentItemIndex-1;
      let prevOption = this.projectDescriptionOptions[prevSlide];
      this.selectedOption = prevOption.value;
      this.currentView = this.projectDescriptionOptionsViews[prevOption.type];
    }

    this.checkFilledFormInputs();
  }
  public nextSlide(){
    /// if nested item is Selected Choose Parent & Next
    if(this.zoneIsSelected){
      this.selectedOption = this.selectedOption.split('.')[0];
    }

    let currentItemIndex = this.findObjIndex(this.projectDescriptionOptions, 'value', this.selectedOption);

    /// remove selected from all nested Items
    $('.nested-items-ul').removeClass('open');
    $('.nested-items-ul > li').removeClass('selected');

    if(currentItemIndex < this.projectDescriptionOptions.length-1){
      /// Next Slide
      let nextSlide = currentItemIndex+1;
      let nextOption = this.projectDescriptionOptions[nextSlide];
      this.selectedOption = nextOption.value;
      this.currentView = this.projectDescriptionOptionsViews[nextOption.type];
    }else{
      /// First Slide
      let firstOption = this.projectDescriptionOptions[0];
      this.selectedOption = firstOption.value;
      this.currentView = this.projectDescriptionOptionsViews[firstOption.type];
    }
    this.checkFilledFormInputs();
  }

  /// Keep The Json Loop In It's Original Order
  public unsorted(){
    return 0;
  }

  public filterCustomSelect(filterQuery, label){
    this.searchInArray(this.selectedReseauOptions, label, filterQuery);
  }
  public searchInArray(array, searchField, searchQuery){
    let result = [];
    let uniqueCommune=[]
    // this.reseauFilterdOptions=[]
    for(let i = 0; i < array.length; i++){
      let currentObj = array[i];
      let lowerSearch=currentObj[searchField].toLowerCase()
      if(currentObj[searchField] && lowerSearch.includes(searchQuery.toLowerCase()) && !uniqueCommune.includes(lowerSearch)){
        result.push(currentObj);
        uniqueCommune.push(lowerSearch)
      }
    }
    this.reseauFilterdOptions[searchField] = result;
  }
  public showSugg(row){
    $('#selectedCommuneImplantationReseau').hide()
    $('#selectedNomReseau').hide()
    $('#'+row).show()
  }
  public resetResauDefaultValues(){
    this.reseauFilterdOptions['Commune'] = [];
    this.reseauFilterdOptions['nomReseau'] = [];
  }

  private checkFilledFormInputs(){
    let skipeInputTypes = ['groupTitle'];
    // Total Inputs Number
    let totalFormInputs = (this.forms[this.currentView].items.filter(e => !skipeInputTypes.includes(e.type))||[]).length;
    // Number of valid inputs
    let validInputs = 0;

    let prevStateData = _.get(this.prevState, this.selectedOption, {});

    for(let key in prevStateData){
      if(typeof prevStateData[key] != 'object'){
        let item = prevStateData[key];
        if(item){
          validInputs++;
        }
      }
    }
    /// some times it's 18/17
    if(validInputs > totalFormInputs){
      validInputs = totalFormInputs;
    }
    this.TotalValidFormInputs = validInputs;
    this.TotalFormInputs = totalFormInputs;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }

}
