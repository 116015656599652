import { Component, OnInit, Input, ChangeDetectorRef, SimpleChanges, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { Subscription } from 'rxjs';
import { DpgfPostesService } from 'src/app/_services/index';
import { MessageService } from 'primeng/api';
import { NgSharedModule } from 'src/app/all/ngshared.module';
const _ = require('lodash');

@Component({
    standalone:true,
    selector: 'app-acv-project-energie-page',
    imports:[NgSharedModule],
    providers: [MessageService],
    templateUrl: './acv-project-energie-page.component.html',
    styleUrls: ['./acv-project-energie-page.component.scss'],
    
})
export class AcvProjectEnergiePageComponent implements OnInit {

  @Input() dpgfId;
  @Input() ceProject;
  @Input() RSETEnergy;
  @Input() isProjectDemo:Boolean=false;
  @Input() acvData;
  @Output()importRSETEvent = new EventEmitter<any>();

  private subscriptions: Array<Subscription> = [];
  public prevState: any = {};
  private jsonData: any = {};dpgf;
  public selectedOption: any;

  public projectDescriptionOptions: any = [];

  public selectedZone;
  public currentView: string = 'cards_form';
  public energyFieldMapper={
    // Chauffage
    'Chauffage':{
      "Electricité":'O_Cef_elec_imp_ch_annuel',
      "Gaz naturel":"O_Cef_gaz_imp_ch_annuel",
      'Energie importée du réseau':'O_Cef_reseau_imp_ch_annuel',
      'Bois granulé en chaudière':'O_Cef_boisgranchaud_imp_ch_annuel',
      'Bois granulé en poêle à bois':'O_Cef_boisgranpoel_imp_ch_annuel',
      'Bois plaquettes en chaudière':'O_Cef_boisplaqchaud_imp_ch_annuel',
      'Bois plaquette en poêle à bois':'O_Cef_boisplaqpoel_imp_ch_annuel',
      'Bois bûche en chaudière':'O_Cef_boisbuchchaud_imp_ch_annuel',
      'Bois bûche en poêle à bois':'O_Cef_boisbuchpoel_imp_ch_annuel',
      'Propane ou butane':'O_Cef_gaz_imp_ch_annuel'
    },
    // Refroidissement
    'Refroidissement':{
      // 'Fioul':'O_Cef_reseau_imp_fr_annuel',
      'Gaz naturel':'O_Cef_gaz_imp_fr_annuel',
      'Electricité':'O_Cef_elec_imp_fr_annuel',
      'Energie importée du réseau':'O_Cef_reseau_imp_fr_annuel',
      'Propane ou butane':'O_Cef_gaz_imp_fr_annuel'
    },
    // ECS
    'ECS':{
      'Energie importée du réseau':'O_Cef_reseau_imp_ecs_annuel',
      'Bois bûche en chaudière':'O_Cef_boisbuchchaud_imp_ecs_annuel',
      'Bois bûche en poêle à bois':'O_Cef_boisbuchpoel_imp_ecs_annuel',
      'Bois plaquettes en chaudière':'O_Cef_boisplaqchaud_imp_ecs_annuel',
      'Bois plaquette en poêle à bois':'O_Cef_boisplaqpoel_imp_ecs_annuel',
      'Bois granulé en chaudière':'O_Cef_boisgranchaud_imp_ecs_annuel',
      'Bois granulé en poêle à bois':'O_Cef_boisgranpoel_imp_ecs_annuel',
      'Gaz naturel':'O_Cef_gaz_imp_ecs_annuel',
      'Electricité':'O_Cef_elec_imp_ecs_annuel',
      'Propane ou butane':'O_Cef_gaz_imp_ecs_annuel'
    },
    // Eclairage
    'Eclairage':{
      'Electricité':'O_Cef_elec_imp_ecl_annuel',
    },
    // Auxiliaire de distribution
    'Auxiliaire de distribution':{
      'Electricité':'O_Cef_elec_imp_auxdist_annuel',
    },
    'Auxiliaire de ventilation':{
      'Electricité':'O_Cef_elec_imp_auxvent_annuel',
    },
    // Déplacements des usagers
    'Déplacements des usagers':{
      'Electricité':'O_Cef_elec_imp_deplacement_annuel',
    },
    // Equipements mobiliers
    'Equipements mobiliers':{
      'Electricité':'O_Cef_elec_imp_mobilier_annuel',
    }
  }
  // 3- forms
  public forms: any = {
    //////////////////////////////////
    /// 1 - Zone 1
    //////////////////////////////////
    'intro_form': {
      mainTitle: 'Importer votre fichier RSET',
      mainDescription: `Pour obtenir votre certification RE2020, il est obligatoire d’importer le fichier RSET.
        Ce fichier nous permet de calculer vos consommations conventionnelles d’énergie renouvelable ou non (CEP),
        vos besoins bioclimatique (Bbio) et votre degré-heure d’inconfort (DH) ainsi que les besoins énergétiques du bâtiment.`,
      mainButton: {
        title: 'Importer le RSET(.xml)',
        submitFunction: ''
      },
      items: [
        {label: "", type: "", placeholder: "", modelVariable:"", inputLabel: ""},
      ]
    },
    'cards_form': {
      mainTitle: 'Nom_du_fichier a bien été importé.',
      mainDescription: `Ce fichier nous permet de calculer vos consommations conventionnelles d’énergie primaire renouvelable ou non (Cep et Cep,nr),
      vos besoins bioclimatiques (Bbio) et votre degré-heure (DH) ainsi que les besoins énergétiques du bâtiment.`,
      mainButton: {
        title: 'Modifier le fichier RSET (.xml)',
        submitFunction: ''
      },
      cards: [
        {
          title: "Chauffage",
          icon: "/assets/acv-icons/thermometer.svg",
          isFilled:false,
          items: [
            {label: "Bois bûche en chaudière",value: 0},
            {label: "Bois bûche en poêle à bois", value: 0},
            {label: "Bois granulé en chaudière", value: 0},
            {label: "Bois granulé en poêle à bois", value: 0},
            {label: "Bois plaquettes en chaudière", value: 0},
            {label: "Bois plaquette en poêle à bois", value: 0},
            {label: "Fioul domestique chaufière", value: 0},
            {label: "Gaz naturel", value: 0},
            {label: "Propane ou butane", value: 0},
            {label: "Electricité", value: 0},
            {label: "Energie importée du réseau", value: 0},
          ]
        },
        {
          title: "ECS",
          icon: "/assets/acv-icons/ecs.svg",
          isFilled:false,
          items: [
            {label: "Electricité", value: 0},
            {label: "Fioul", value: 0},
            {label: "Gaz naturel", value: 0},
            {label: "Propane ou butane", value: 0},
            {label: "Bois bûche en chaudière", value: 0},
            {label: "Bois bûche en poêle à bois", value: 0},
            {label: "Bois granulé en chaudière", value: 0},
            {label: "Bois granulé en poêle à bois", value: 0},
            {label: "Bois plaquettes en chaudière", value: 0},
            {label: "Bois plaquette en poêle à bois", value: 0},
            {label: "Energie importée du réseau", value: 0},
          ]
        },
        {
          title: "Refroidissement",
          icon: "/assets/acv-icons/refroidissement.svg",
          isFilled:false,
          items: [
            {label: "Electricité", value: 0},
            {label: "Gaz naturel", value: 0},
            // {label: "Fioul", value: 0},
            {label: "Energie importée du réseau", value: 0},
            {label: "Propane ou butane", value: 0},
          ]
        },
        {
          title: "Auxiliaire de distribution",
          icon: "/assets/acv-icons/aux-dist.svg",
          isFilled:false,
          items: [
            {label: "Electricité", value: 0},
          ]
        },
        {
          title: "Auxiliaire de ventilation",
          icon: "/assets/acv-icons/aux-venti.svg",
          isFilled:false,
          items: [
            {label: "Electricité", value: 0},
          ]
        },
        {
          title: "Déplacements des usagers",
          icon: "/assets/acv-icons/dep-usagers.svg",
          isFilled:false,
          items: [
            {label: "Electricité", value: 0},
          ]
        },
        {
          title: "Eclairage",
          icon: "/assets/acv-icons/eclairage.svg",
          isFilled:false,
          items: [
            {label: "Electricité", value: 0},
          ]
        },



      ]
    },
  }
  selectedBatiment: any;
  constructor(
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private dpgfPostesService: DpgfPostesService
  ) { }

  ngOnInit(): void {
    let zonesNamesWithFather= _.get(this.acvData,'projectRSET.zonesNamesWithFather',[]);
    this.fillEntities(zonesNamesWithFather);

    this.selectedZone=(zonesNamesWithFather[Object.keys(zonesNamesWithFather)[0]]||[])[0]
    this.selectedBatiment=Object.keys(zonesNamesWithFather)[0]
    setTimeout(()=>{
      $(this.removeWhiteSpaces(`#nested-options-`+this.selectedBatiment)).addClass('open');
      $(this.removeWhiteSpaces('#nested-li'+'-'+this.selectedZone)).addClass('selected');
    })
    this.forms['cards_form']['mainTitle']=_.get(this.acvData,'RSETFile.name','')+' a bien été importé.'
    if(Object.keys(this.RSETEnergy).length)this.getData()
    // this.fillEntities(batimentNamesRSET, zoneNamesRSET);
    this.cd.detectChanges()
  }
  private fillEntities(batiments){
    for (const key in batiments) {
      let zones=batiments[key]
      let trimmedBatiment = key.replace(/ /g, "-");
      let batimentObject = {label: `${key}`, value: `${key}`, trimmedValue: trimmedBatiment, type: 'batiment',zones:[]};
      for(let i = 0; i < zones.length; i++){
        let trimmedZone = zones[i].replace(/ /g, "-");
        let zoneObject = {label: `${zones[i]}`, value: `${zones[i]}`, trimmedValue: trimmedZone, type: 'zone'};
        // array choices in select
        batimentObject.zones.push(zoneObject);
      }
      this.projectDescriptionOptions.push(batimentObject)
    }
    /// if no batimes add the defaults
    if(!Object.keys(batiments).length){
      let batiment = {
        label: `Bâtiment`,
        value: `Bâtiment`,
        trimmedValue: 'Bâtiment',
        type: 'batiment',
        zones:[
          {
            label: `Zone`, value: `Zone`, trimmedValue: 'Zone', type: 'zone'
          }
        ]
      };
      this.projectDescriptionOptions.push(batiment);
    }
  }
  public removeWhiteSpaces(string){
    return string.replaceAll(' ', '');
  }

  public openNestedList(currentOption, ulId){
    /// open Nested Ul
    let trimmedId = this.removeWhiteSpaces(ulId);
    $('.nested-items-ul').removeClass('open');
    $(`#${trimmedId}`).addClass('open');
    this.selectedOption = currentOption.value;
    this.selectedBatiment=currentOption.value
  }
  public listItemSelected(event, item){
    $('.nested-items-ul > li').removeClass('selected');
    $(event.currentTarget).addClass('selected');
    this.EntityItemSelected(item);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(changes.RSETEnergy && this.selectedZone && this.checkRSET()){
      this.getData();
    }
  }
  public sideBarEntities: any = [
  ];



  private getData(){
    let surfaceZone=_.get(this.acvData, `projectDescription.${(this.selectedBatiment||'').replace(/ /g, "-")}.${(this.selectedZone||'').replace(/ /g, "-")}.SurfaceReference`,0)
    let combustionGaz=parseInt(_.get(this.acvData, `projectRSET.Combustible_Gaz`,'0'))
    for (let i = 0; i < this.forms['cards_form'].cards.length; i++) {
      for (let j = 0; j < this.forms['cards_form'].cards[i].items.length; j++) {
        let value=parseFloat(this.RSETEnergy[this.selectedBatiment][this.selectedZone][this.energyFieldMapper[this.forms['cards_form'].cards[i].title][this.forms['cards_form'].cards[i].items[j].label]] || 0)
        if(value && !((combustionGaz==1 && this.forms['cards_form'].cards[i].items[j].label=='Gaz naturel') || (combustionGaz==0 && this.forms['cards_form'].cards[i].items[j].label=='Propane ou butane'))){
          this.forms['cards_form'].cards[i].items[j].value=value*(surfaceZone||1)
          this.forms['cards_form'].cards[i].isFilled=true;
        }
      }
    }
  }
  public importRSET(){
    this.importRSETEvent.emit()
  }

  public EntityItemSelected(zone){
    this.selectedZone=zone.value;
    // this.selectedBatiment=batiment.value
    this.getData();
  }

  checkRSET(){
    return Object.keys(((this.acvData||{}).RSETFile||{})).length > 0
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }

}
