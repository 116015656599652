import { Component, OnInit, Input, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';


@Component({
    standalone: true,
    selector: 'app-photos-slider',
    imports:[
      NgSharedModule
    ],
    templateUrl: './photos-slider.component.html',
    styleUrls: ['./photos-slider.component.scss'],
})
export class PhotosSliderComponent implements OnInit {

  @Input() photosList: any = [];
  @Input() currentPhotoIndex: any = 0;

  constructor() { }

  ngOnInit(): void {
  }

  public prevSlide(){
    if(this.currentPhotoIndex > 0){
      this.currentPhotoIndex--;
    }else{
      this.currentPhotoIndex = this.photosList.length-1;
    }
  }

  public nextSlide(){
    if(this.currentPhotoIndex < this.photosList.length-1){
      this.currentPhotoIndex++;
    }else{
      this.currentPhotoIndex = 0;
    }
  }


}
