<div id="upgrade-to-integral-modal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Passez sur AGLO Intégral</h4>
      </div>
      <div class="modal-body">
        <p>Le CSTB n'est pas accessible avec votre abonnement actuel. Souscrivez à AGLO Intégral pour y avoir accès.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="ag-btn-secondary-sm" data-dismiss="modal">Plus tard</button>
        <button (click)="redirectToPricingTable()" type="button" class="ag-btn-primary-sm submit-btn">C'est parti !</button>
      </div>
    </div>
  </div>
</div>
