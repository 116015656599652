import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute , Params} from '@angular/router';
import { trigger,state,style,transition,animate } from '@angular/animations';
import { PrintFacture } from '../../../../app/_interfaces/printFacture.interface';
import { Store, select } from '@ngrx/store';
import * as chantierActions from '../../../chantier/_store/chantier.actions';
import * as fromChantier from '../../../chantier/_store';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { DpgfPostesService } from '../../../_services/index';
import { saveAs } from 'file-saver';
import { NgSharedModule } from 'src/app/all/ngshared.module';

import { ArtisanCguModalComponent } from 'src/app/all/artisan-cgu-modal/artisan-cgu-modal.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { TableFactureComponent } from 'src/app/chantier/table-facture/table-facture.component';

declare var $:any;
@Component({
    selector: 'app-artisan-chantier',
    templateUrl: './artisan-chantier.component.html',
    imports:[
    ArtisanCguModalComponent,
    SpinnerComponent,
    TableFactureComponent,
    NgSharedModule
],
    styleUrls: ['./artisan-chantier.component.scss'],
    animations: [
        trigger('appear', [
            state('hide', style({
                top: '-50px',
            })),
            state('show', style({
                top: '10px',
            })),
            transition('hide => show', animate('2000ms ease-in')),
            transition('show => hide', animate('2000ms ease-in')),
        ]),
    ],
    standalone: true,

})
export class ArtisanChantierComponent implements OnInit {

  public facture$:Observable<any>;
  public flags$:Observable<any>;
  public dpgfFactureId;
  public isSticky:boolean = true;
  public respecter:boolean = false;
  public assurer:boolean = false;
  public errorSend:boolean = false;
  public archiMessage:string;

  public state: string = 'hide';
  //public saving:boolean = false;
  //public loadingData:boolean = false;
  //public done:boolean = false;
  //public finishing:boolean = false;


  constructor(private route: ActivatedRoute,
              private location:Location,
              private store: Store<any>,
              private DpgfPostesService: DpgfPostesService) { }

  ngOnInit() {
    this.getFacture();
    this.getFlags();
  }

  private getFacture() {
    this.route.params.forEach((params: Params) => {
      this.dpgfFactureId = params['dpgfFactureId'];
      // Dispatch Load
      this.store.dispatch(new chantierActions.LoadFacture(this.dpgfFactureId));
      // Select Loaded facture
      this.facture$ = this.store.pipe(select(fromChantier.getFacture));
    })
  }

  private getFlags() {
    this.flags$ = this.store.pipe(select(fromChantier.getFlags));
  }

  public saveFactureUpdate() {
    this.store.dispatch(new chantierActions.SaveUpdateFacture());
  }

  public finishFactureUpdate() {
    this.errorSend = false;
    if(this.respecter &&  this.assurer){
      let newFacture:any = {};
      newFacture.message = this.archiMessage;
      this.store.dispatch(new chantierActions.FinishFactureUpdate({factureId: this.dpgfFactureId, facture: newFacture}));
    }
    else{
      this.errorSend = true;
    }
  }

  public downloadFacture(facture) {
    let selectedLots = this.getSelectedLotsIds(facture.data || []);
    let body = {
      isArtisan: true,
      isFirst: facture.isFirst,
      selectedLots
    };
    this.DpgfPostesService.downloadFacturePdf(facture.dpgf, facture._id, body).subscribe(
      (data)=>{
        saveAs(data.data.data, `${data.data.pdfName}.pdf`);
      }
    )
  }
  private getSelectedLotsIds(data){
    let selectedLotsIds = [];
    for(let i = 0; i < data.length; i++){
      selectedLotsIds.push(data[i].id);
    }
    return selectedLotsIds;
  }

  public scrollTo(element){
    $('html, body').animate({
      scrollTop: ($("#" +element).offset().top - 60)
    });
  }

  public goBack() {
    this.location.back();
  }
}
