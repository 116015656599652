import { Component, Input, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { MessageService } from 'primeng/api';

import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone: true,

    selector: 'app-acv-results-fiches-plus-impactantes-view',
    imports:[NgSharedModule],
    providers: [MessageService],
    templateUrl: './acv-results-fiches-plus-impactantes-view.component.html',
    styleUrls: ['./acv-results-fiches-plus-impactantes-view.component.scss'],
})
export class AcvResultsFichesPlusImpactantesViewComponent implements OnInit {
  @Input() acvData;
  @Input() projectTitle;
  public posts;
  public postsPage=0;
  public declarationTypeMap={
    1:"FDES/PEP",
    2:"FDES/PEP",
    3:"Donnée par défaut",
    4:"Conventionnelle"
  }
  constructor(private messageService: MessageService) { }

  ngOnInit(): void {
    this.posts=this.acvData.posts||[]
  }
  parseLotPCE(nom){
    return (nom||'').replace(/[0-9.]/g, '')
  }
  sortBy(prop: string) {
    return this.posts.sort((a, b) => a[prop] < b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }
}
