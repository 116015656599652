<div class="margin-left-15 margin-top-32 row">

  <div class="col-md-7 ag-card-1 full-height">
    <p class="ag-para-2 ag-bold text-center margin-top-12">Impact sur le changement climatique </p>
    <div class="flex" style="width: 95%;height: 90%;">
      <p class="vertical-title">kg éq. CO2/m²</p>
      <canvas id="chart-impact-changement-climatique"></canvas>
    </div>
  </div>

  <div class="col-md-5 full-height">
    <div class="ag-card-1 margin-bottom-2" style="height: 45%;">
      <p class="ag-para-2 ag-bold text-center margin-top-12">Répartition des données utilisées</p>
      <div style="height: 80%;">
        <canvas id="chart-repartition-des-donnees-utilisees"></canvas>
      </div>
    </div>

    <div class="percentage-container height-50" style="height: 54%;">
      <div class="ag-card-1 width100 height-48">
        <div class="sub-box">
          <p class="percentage">{{acvData?.projectResult?.CO2DynamicImpacts?.Ic | number:'1.0-2':'fr'}} kg éq CO2/m²</p>
        </div>
        <p class="text-center no-margin font-weight-600">Ic Projet</p>
        <p class="usage-desc">Impact du projet par m²</p>
      </div>
      <div class="ag-card-1 width100 margin-top-9 height-48">
        <div class="sub-box">
          <p class="percentage">{{projetConstants.StockC | number:'1.0-2':'fr'}} kgC</p>
        </div>
        <p class="text-center no-margin font-weight-600">StockC</p>
        <p class="usage-desc">STOCKAGE CARBONE BIOGENIQUE</p>
      </div>
      <!-- <div class="ag-card-1 width-48 margin-top-12 height-48">
        <div class="sub-box">
          <p class="text-center no-margin second-data">{{projetConstants.PV | number:'1.0-2':'fr'}}</p>
          <p class="text-center no-margin">kgCO2eq</p>
        </div>
        <p class="text-center no-margin font-weight-600">Bexp PV</p>
        <p class="usage-desc">BENEFICE LIÉ AUX EXPORTS D'ÉNERGIE PHOTOVOLTAÏQUE</p>
      </div>
      <div class="ag-card-1 width-48 margin-left-17 margin-top-12 height-48">
        <div class="sub-box">
          <p class="text-center no-margin second-data">{{projetConstants.Coge | number:'1.0-2':'fr'}}</p>
          <p class="text-center no-margin">kgCO2eq</p>
        </div>
        <p class="text-center no-margin font-weight-600">Bexp Cogé</p>
        <p class="usage-desc">BENEFICE LIÉ AUX EXPORTS D'ÉNERGIE DE COGÉNÉRATION</p>
      </div> -->


    </div>
  </div>
</div>
