<div class="recommandation-form-wrapper">
  <h5 class="ag-heading-h5">Coordonnés du maître d’oeuvre</h5>

  <!-- Contacts Table -->
  <div class="contacts-table margin-bottom-40" *ngIf="recommandationList.length">
    <h5 class="ag-heading-h5 ag-weight-500 margin-bottom-16">Contacts transmis</h5>
    
    <!-- Table Header -->
    <div class="table-header horizontal-items margin-bottom-8">
      <div class="width-25">
        <p class="ag-para-3 ag-weight-500">Nom</p>
      </div>
      <div class="width-25">
        <p class="ag-para-3 ag-weight-500">Nom de l’agence</p>
      </div>
      <div class="width-25">
        <p class="ag-para-3 ag-weight-500">Email</p>
      </div>
      <div class="width-25">
        <p class="ag-para-3 ag-weight-500">Numéro de téléphone</p>
      </div>
    </div>
    <!-- END Table Header -->

    <!-- Table Body -->
    <div class="table-body">
      <!-- Single Row -->
      <div class="single-row horizontal-items" *ngFor="let recommandation of recommandationList; let i = index;">
        <div class="width-25">
          <p class="horizontal-items ag-weight-500">{{ recommandation.nameArchi || "" }}</p>
        </div>
        <div class="width-25">
          <p class="horizontal-items ag-weight-500">{{ recommandation.maitreDoeuvre || "" }}</p>
        </div>
        <div class="width-25">
          <p class="horizontal-items ag-weight-500">{{ recommandation.mailArchi || "" }}</p>
        </div>
        <div class="width-25 horizontal-items space-between">
          <p class="horizontal-items ag-weight-500">{{ recommandation.phoneNumber || "" }}</p>
          <img src="/assets/delete-icone.svg" class="pointer delete-img" (click)="removeContact(i)">
        </div>
      </div>
      <!-- END Single Row -->

    </div>
    <!-- END Table Body -->
  </div>
  <!-- END Contacts Table -->

  <!-- New Contact Form -->
  <div class="new-contact-form" *ngIf="showContactForm">
    
    <div class="form-row horizontal-items space-between margin-bottom-16">

      <div class="form-input width-50 padding-right-20">
        <p class="padding-bottom-6 ag-para-1 ag-weight-600">Nom de l’agence*</p>
        <input type="text" [(ngModel)]="contactForm.nameArchi">
      </div>

      <div class="form-input width-50 padding-left-20">
        <p class="padding-bottom-6 ag-para-1 ag-weight-600">Numéro de téléphone*</p>
        <input type="number" [(ngModel)]="contactForm.phoneNumber">
      </div>

    </div>
    <div class="form-row horizontal-items space-between">

      <div class="form-input width-50 padding-right-20">
        <p class="padding-bottom-6 ag-para-1 ag-weight-600">Nom du maître d’oeuvre</p>
        <input type="text" [(ngModel)]="contactForm.maitreDoeuvre">
      </div>

      <div class="form-input width-50 padding-left-20">
        <p class="padding-bottom-6 ag-para-1 ag-weight-600">Email</p>
        <input type="email" [(ngModel)]="contactForm.mailArchi">
      </div>

    </div>

  </div>
  <!-- END New Contact Form -->

  <div class="seperator"></div>

  <div class="new-contact-btn pointer" *ngIf="!showContactForm">
    <p class="ag-para-3 ag-weight-400" (click)="showAddContactForm()">
      <img class="margin-right-10" src="/assets/add-icon.svg">
      Ajouter un autre contact
    </p>
  </div>

  <div class="transmit-contact-wtb-wrapper text-center margin-top-10" *ngIf="showContactForm">
    <button class="ag-btn-primary-sm" 
      [disabled]="contactForm.nameArchi == '' || contactForm.phoneNumber == ''"
      (click)="addContact()">
      Transmettre
    </button>
  </div>


</div>