<div id="reef"
  class="modal fade reef"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog no-padding">
    <div class="modal-container no-padding">
      <p class="reef-title">Accéder au REEF</p>
      <p class="reef-sub-title">Accédez à l'ensemble des textes normatifs, techniques et réglementaires depuis AGLO</p>
      <p class="small-title">Le prix affiché est en HT et l’abonnement concerne seulement un unique utilisateur.</p>
      <a class="offers">En savoir plus</a>
      <div class="licence-change-container">
        <div class="licence-change">
          <div class="single-table" style="height: 212px;">
            <div class="inner-container">
              <h4 class="table-title">Reef Essentiel</h4>
              <h4 class="table-price">
                <span>569 € / an</span>
              </h4>
              <p class="table-description">Pour consulter, exploiter et respecter les DTU</p>
            </div>
          </div>
        </div>
      </div>
      <div class="button-container">
        <button class="ag-btn-secondary" style="height: 28px;margin-left: 24px;padding: 3px 24px 9px 24px;" (click)='hasIt()'>Déjà abonné</button>
        <a class="b2" style="padding: 4px 24px 9px 24px;text-decoration: auto;" href="https://boutique.cstb.fr/solutions-metiers/656-le-reef-essentiel.html" target='_blank'>S’abonner</a>
      </div>
    </div>
  </div>
</div>
