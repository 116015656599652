import { Component, OnInit, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/_services/auth.service';

import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

declare var only;

@Component({
    standalone: true,
    selector: 'app-add-photos-to-single-album-modal',
    imports:[
      NgSharedModule 
    ],
    templateUrl: './add-photos-to-single-album-modal.component.html',
    styleUrls: ['./add-photos-to-single-album-modal.component.scss'],
})
export class AddPhotosToSingleAlbumModalComponent implements OnInit {

  @Input() albumTitle: string = "";
  @Output() addPhotos: EventEmitter<any> = new EventEmitter<any>();

  public uploadedPhotos: any = [];
  public showAlbumPhotos: boolean = false;
  public uploading: boolean = false;
  public firstValueEnvirenementObservable = new Observable<boolean>(observer => {
    observer.next(environment.onPremise);
    observer.complete();
  });
  constructor(public authService:AuthService) { }

  ngOnInit(): void { }
  onUploadMultiPhotos(event){
    this.uploadedPhotos = [...event.originalEvent.body];
    this.showAlbumPhotos = true;
  }
  public removePhoto(index){
    this.uploadedPhotos.splice(index, 1);
  }

  public emitSetAlbumPhotos(){
    if(!this.uploadedPhotos.length) return;
    
    this.addPhotos.emit(this.uploadedPhotos);
  }

}
