<div id="upgrade-modal" class="modal fade" role="dialog">
  <div class="modal-dialog" style="margin-top: 30vh;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <!-- <h4 *ngIf="currentLicence!='without'" class="modal-title">Vous avez atteint la limite de stockage de votre bibliothèque </h4>
        <h4 *ngIf="currentLicence=='without'" class="modal-title">Passez sur AGLO Pro, Max ou Intégral</h4> -->
        <h4>Passez sur AGLO Intégral</h4>
      </div>
      <div class="modal-body">
        <p>La bibliothèque du CSTB n'est pas accessible avec votre licence. Souscrivez à AGLO Intégral pour y avoir accès.</p>
        <!-- <p *ngIf="currentLicence=='without'">
          Cette fonctionnalité n'est pas accessible en lecture seule. Souscrivez à une licence Pro, Max ou Intégral pour y avoir accès.</p>
        <p *ngIf="currentLicence=='base'">
          Vous avez atteint la limite de postes dans votre bibliothèque, passez en licence PRO pour agmenter cette limite à 200 postes ou en licence INTEGRAL pour pouvoir créer un nombre de postes illimité !
        </p>
        <p *ngIf="currentLicence=='plus' || currentLicence=='plus-annuel'">
          Vous avez atteint la limite de postes de votre bibliothèque, passez en licence INTEGRAL pour pouvoir créer un nombre illimité de poste !
        </p> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="ag-btn-secondary-sm" data-dismiss="modal">Plus tard</button>
        <button (click)="redirectToPricingTable()" type="button" class="ag-btn-primary-sm" style="width: 16%;height: 40px;margin-left: 12px;">C'est parti !</button>
      </div>
    </div>
  </div>
</div>
