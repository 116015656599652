<div *ngIf="loadingPage">
  <app-spinner></app-spinner>
</div>
<div class="row" *ngIf="!loadingPage && pricingList.length">
  <app-dpgf-result-tabs-navbar></app-dpgf-result-tabs-navbar>
</div>
<!-- No Pricing Image -->
<div style="margin-left: 80px; margin-top: 200px;" *ngIf="!loadingPage && !pricingList.length">
  <div class="container" style="margin-top: 70px">
    <div class="empty-box" style="text-align: center;">
      <h3 class="center">Vous n'avez pas encore reçu de réponse à votre consultation !</h3>
      <img src="https://media.giphy.com/media/tXL4FHPSnVJ0A/giphy.gif" alt="" height="400" class="empty-devis">
    </div>
  </div>
</div>
<!-- END No Pricing Image -->


<div class="chart-container">
  <h1 class="center margin-bottom-20">Comparatif des prix HT par lots</h1>
  <div class="canvas-wrapper">
    <canvas id="myChart" width="400" height="400"></canvas>
  </div>
</div>
