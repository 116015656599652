<section class="filter-wrapper">
  <div class="keyword-wrapper">
    <span     contentEditable
              *ngIf="modify"
              type="text"
              placeholder="Titre"
              class="edit-span"
              (focusout)="focusedOnOut()"
              (focus)="focusedOn()"
              [innerHTML]="textContentCopy"
              (input)="manageType($event)"
              (keyup)="searchTerm$.next($event.target.innerText)"
              [ngClass]="{'selected':selected || selectedSousPostes}"
              ></span>

    <span class="show-span"
          *ngIf="!modify"
          (click)="editable()"
          [ngClass]="{'selected':selected || selectedSousPostes}">
          <span [innerHTML]="textContent | linebreak"></span>
          <!-- <i *ngIf="disable" class="fa fa-lock" aria-hidden="true"></i> -->
          <span class="old-val" innerHTML="{{oldValue | linebreak}}"></span>
          <span class="place-holder" *ngIf="textContent==''">Titre</span>
    </span>

    <div  id="myInputautocomplete-list" class="autocomplete-items" *ngIf="mode != 'artisan' && isNotPrice">
      <div *ngFor="let result of results;let i = index"  [ngClass]="{'autocomplete-active':(currentFocus == i)}">
          <p #elasticResult (click)="choose(result,false)" (mousedown)="handleClick($event)" [innerHTML]="result.sugg | highlightWords: queryStr "></p>
          <img *ngIf="result.type=='poste'" src="assets/dpgf/cstb.jpg" class="cstb">
          <i *ngIf="result.type=='userpost'" class="fa fa-database cstb db"></i>
          <span *ngIf="!result.gid" class="cctp" (click)="choose(result,true)">Importer avec les prescriptions techniques</span>
      </div>
    </div>

  </div>
</section>
