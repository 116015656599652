import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import {Router} from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../_services/index';
import { NgSharedModule } from '../ngshared.module';
import { environment} from 'src/environments/environment'
declare var $:any;

@Component({
  standalone:true,
  selector: 'app-login-modal',
  imports:[NgSharedModule ],
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
  public user:any;
  public loading:boolean = false;
  public switch:string = "connecte";
  private subscriptions: Array<Subscription> = [];
  public firstValueEnvirenementObservable = new Observable<boolean>(observer => {
    observer.next(environment.onPremise);
    observer.complete();
  });
  constructor(private router:Router, public authService: AuthService,) {
    this.user ={}
  }

  ngOnInit() {
  }
  //Login
  login(){
    this.loading = true;
    $('#wrong-login').removeClass('animated slideInDown');
    $('#wrong-login').addClass('hide-wrong');
    this.subscriptions.push(
      this.authService.login(this.user)
          .subscribe(
              data => {
                  $('#login-modal').modal('toggle');
                  this.loading = false;
                  if(this.authService.currentUser.isAdmin && !environment.onPremise){
                    this.router.navigate(['/architect/my-consultations']);
                  }else if(this.authService.currentUser.isAdmin && environment.onPremise){
                    this.router.navigate(['/admin/landingpage']);
                  } if(this.authService.isArchitect() || this.authService.isCollaborateur()){
                    this.router.navigate(['/architect/my-consultations']);
                  }
              },
              error => {
                  $('#wrong-login').removeClass('hide-wrong');
                  $('#wrong-login').addClass('animated slideInDown');
                  console.log("error",error)
                  this.loading = false;
              })
      )
    }
  //Modale
  closeModal(){
    $('#login-modal').modal('toggle');
  }
  //subscribe
  gotoSubcribe(url){
    $('#login-modal').modal('toggle');
    this.router.navigate([url]);
  }
  public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }
}
