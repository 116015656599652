import { Component } from '@angular/core';
import { ArchitectService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { ActivatedRoute , Params} from '@angular/router';
import { NgSharedModule } from 'src/app/all/ngshared.module';
@Component({
  selector: 'app-create-user-password',
  standalone: true,
  imports: [NgSharedModule],
  templateUrl: './create-user-password.component.html',
  styleUrl: './create-user-password.component.scss'
})
export class CreateUserPasswordComponent {
  public password;userId
  constructor(private architectService :ArchitectService,private route: ActivatedRoute) { }
  private subscriptions: Array<Subscription> = [];

  ngOnInit(): void {
    this.route.params.forEach((params: Params) => {
      if (params['userId']) {
          this.userId =params['userId']
        }
    })
  }
  validPassword(){
    // to correct
    this.subscriptions.push(
      // this.architectService.archiChangePassword(this.userId,this.password).subscribe((data) => {
      // })
    )
  }
}
