import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/index';
import { environment } from 'src/environments/environment';


@Injectable()
export class ArchitectRouteGuard  {
  constructor(private router: Router,private authService: AuthService,private activatedRoute: ActivatedRoute) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isArchitect() || this.authService.isAdmin() || this.authService.isCollaborateur()) {
            let currentLocation = this.router.url;
            if(this.authService.isNewAccount()&&!currentLocation.includes('subscribe')){this.router.navigate(['/architect/accountValidation']);}
          return true;
        }
        // not logged in so redirect to login page with the return url
        if(!environment.onPremise)this.router.navigate(['/login'], { queryParams: { returnUrl: state.url, infoBubble: "login-required" }});
        else{
          const currentRoute = this.router.url;
          window.location.href=`api/v1/login/openid/?state=${currentRoute}`;
          window.open();
        }
        return false;
  }
}
