<!-- App Header -->
<!-- <app-header></app-header> -->


<div *ngIf="infoBubble =='expired'" class="info-bubble">
  Votre session à exprirer. Veuillez vous reconnecter
</div>
<div *ngIf="infoBubble =='login-required'"class="info-bubble">
  Connexion requise
</div>


<div class="container-fluid">
  <div class="col-md-5 hidden-sm hidden-xs full-height colored-side">
    <div>
      <img class="aglo-logo" src="/assets/rob/aglo_logo_round_blanc.png" alt="">
      <h2 class="slogan">
          L’assistant intelligent <br>
          pour votre consultation d’entreprise
      </h2>
    </div>
  </div>
  <div class="col-md-7 full-height" *ngIf="switch == 'connecte'">

    <div class="connect">
      <h1>Bienvenue sur AGLO</h1><br>
      <!-- <h2>Connectez-vous pour continuer</h2> -->
      <h3>Vous pouvez aussi vous connecter avec votre compte <img class="bam-logo" src="/assets/new-bam-logo.svg" alt="" height="30" style="margin-left:10px"></h3>
      <form>
        <div class="inputs">
          <input [(ngModel)]="user.email" type="text" name="email" placeholder="Email">
          <input [(ngModel)]="user.password" type="password" name="password" placeholder="Mot de passe">
        </div>
        <div id="wrong-form-login" class="alert alert-danger hide-wrong">
          <span class="glyphicon glyphicon-warning-sign" aria-hidden="true"></span> Utilisateur ou mot de passe <strong>incorrect!</strong>
        </div>
        <button [disabled]="loading" (click)="login()" [ngClass]="authService.currentEnvironment?'width-350px':'width-250px'" class="login btn-primary-color">
          <div *ngIf="!loading">Se connecter</div>
          <app-spinner *ngIf="loading"></app-spinner>
        </button>
      </form>
      <div class="login-help">
        <a [routerLink]="[ '/mot-de-passe-oublie']">Mot de passe oublié</a>
      </div>
      <button  *ngIf="authService.currentEnvironment" [disabled]="loading" (click)="loginWithOpenId()"  class="login width-350px btn-primary-color">
        Se connecter avec OpenId
      </button>
      <button *ngIf="!authService.currentEnvironment" (click)="gotoSubcribe('/architect/subscribe/verification')" [disabled]="loading" class="login  width-250px btn-primary-border">
        <div *ngIf="!loading">S'inscrire</div>
        <app-spinner *ngIf="loading"></app-spinner>
      </button>
    </div>

  </div>

</div>


<!-- App Footer  -->
