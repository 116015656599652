<p>styles-page works!</p>
<div class="container">
  <div class="row">
      <h4>Primary</h4>
  </div>
  <div class="row">
      <div class="col-md-3">
        <button class="ag-btn-primary">Button</button>
      </div>
      <div class="col-md-3">
        <button class="ag-btn-primary"><i class="fa fa-pencil"></i> Button</button>
      </div>
      <div class="col-md-3">
        <button class="ag-btn-primary-sm">Button</button>
      </div>
      <div class="col-md-3">
        <button class="ag-btn-primary" disabled>Disabled</button>
      </div>
  </div>
</div>

<div class="container">
    <div class="row">
        <h4>Secondary</h4>
    </div>
    <div class="row">
        <div class="col-md-3">
          <button class="ag-btn-secondary">Button</button>
        </div>
        <div class="col-md-3">
          <button class="ag-btn-secondary"><i class="fa fa-pencil"></i> Button</button>
        </div>
        <div class="col-md-3">
          <button class="ag-btn-secondary-sm" focus>Button</button>
        </div>
        <div class="col-md-3">
          <button class="ag-btn-secondary-sm" disabled>Disabled</button>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <h4>Tertiary</h4>
    </div>
    <div class="row">
        <div class="col-md-3">
          <button class="ag-btn-tertiary">Button</button>
        </div>
        <div class="col-md-3">
          <button class="ag-btn-tertiary"><i class="fa fa-pencil"></i>  Button</button>
        </div>
        <div class="col-md-3">
          <button class="ag-btn-tertiary-sm" focus>Button</button>
        </div>
        <div class="col-md-3">
          <button class="ag-btn-tertiary-sm" disabled>Disabled</button>
        </div>
    </div>
</div>


<div class="container">

  <h2 class="text-center">Hello Yassine, Anas & Achraf welcome to AGLO COMPONENTS</h2>
  <br>

  <h3>Buttons </h3>
  <div class="btns">
    <button class="btn btn-flat" href="#"> Ajouter mon poste</button>
    <button class="btn btn-gradient" href="#">J'utilise un produit issu du réemploi</button>
    <button class="btn btn-purple" href="#">Je ne trouve pas ma fiche</button>
    <button class="btn btn-gradient" href="#">En savoir plus <i class="fa fa-arrow-right"></i></button>
  </div>
  <hr>
  <h3>Icons ACV DCE </h3>
  <div class="icons_position">
    <img src="assets/dpgf/Icon_DPGF/description_tech_full.svg" alt="Appareillage installation réseaux" />
    <img src="assets/dpgf/Icon_DPGF/description_tech_empty.svg" alt="Approche simplifiée" />
    <img src="assets/dpgf/Icon_DPGF/description_enviro_full.svg" alt="Assainnissement" />
    <img src="assets/dpgf/Icon_DPGF/description_enviro_empty.svg" alt="Eclairage" />
  </div>
  <hr>
  <h3>Chips </h3>

  <div class="chip">
    Cuisine
    <span class="closebtn">&times;</span>
  </div>

  <div class="chip">
    SDB
    <span class="closebtn" onclick="this.parentElement.style.display='none'">&times;</span>
  </div>

  <div class="chip">
    Penderie
    <span class="closebtn">&times;</span>
  </div>

  <div class="chip">
    R+1
    <span class="closebtn" onclick="this.parentElement.style.display='none'">&times;</span>
  </div>
  <div class="chip">
    RDC
    <span class="closebtn">&times;</span>
  </div>

  <div class="chip">
    Garage
    <span class="closebtn" onclick="this.parentElement.style.display='none'">&times;</span>
  </div>

  <hr>
  <h3>New icons </h3>
  <div class="icons_position">
    <img src="assets/dpgf/Icon_DPGF/Aglo-Appareillage_installation_reseaux.svg" alt="Appareillage installation réseaux" />
    <img src="assets/dpgf/Icon_DPGF/Aglo-Approche_simplifiee.svg" alt="Approche simplifiée" />
    <img src="assets/dpgf/Icon_DPGF/Aglo-Assainnissement.svg" alt="Assainnissement" />
    <img src="assets/dpgf/Icon_DPGF/Aglo-Eclairage.svg" alt="Eclairage" />
    <img src="assets/dpgf/Icon_DPGF/Aglo-Energie.svg" alt="Energie" />
    <img src="assets/dpgf/Icon_DPGF/Aglo-Equipements_genie_climatique.svg" alt="Equipement de génie climatique" />
    <img src="assets/dpgf/Icon_DPGF/Aglo-Fils_cables.svg" alt="Fils et câbles" />
    <img src="assets/dpgf/Icon_DPGF/Aglo-Fluides_frigorigenes.svg" alt="Fluides frigoriènes" />
    <img src="assets/dpgf/Icon_DPGF/Aglo-Generalites.svg" alt="Generalites" />
    <img src="assets/dpgf/Icon_DPGF/Aglo-Production_locale_energie.svg" alt="Generalites" />
    <img src="assets/dpgf/Icon_DPGF/Aglo-Securite_incendie.svg" alt="Generalites" />
    <img src="assets/dpgf/Icon_DPGF/Aglo-Signaletique.svg" alt="Generalites" />
    <img src="assets/dpgf/Icon_DPGF/Aglo-Traitement_dechets.svg" alt="Generalites" />
    <img src="assets/dpgf/Icon_DPGF/airport_shuttle.svg" alt="Generalites" />
  </div>
  <hr>
  <h3>Cards </h3>

  <div class="row">
    <div class="col-md-4">
      <div class="card declarationType1">
        <div class="card-title">Fourreaux de protection en PE pour l'alimentation et la distribution</div>
        <p>Changement climatique</p>
        <div class="card-score"> <i class="fa fa-leaf goodscore"> </i><strong>12.2</strong> kg CO2 eq/m2 </div>
        <div class="hoverbtn ">
          <div class="hoverbtncenter">
            <button class="btn btn-cards" href="#"> Ajouter mon poste</button>
          </div>
          <button class="btn btn-gradient btn-gradient-position" href="#">En savoir plus <i class="fa fa-arrow-right"></i></button>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card declarationType2 ">
        <div class="card-title">Fenêtres et portes-fenêtres acier aluminium MILLET Groupe</div>
        <p>Changement climatique</p>
        <div class="card-score"> <i class="fa fa-leaf mediumscore"> </i><strong>12.2</strong> kg CO2 eq/m2 </div>
        <div class="hoverbtn ">
          <div class="hoverbtncenter">
            <button class="btn btn-cards" href="#"> Ajouter mon poste</button>
          </div>
          <button class="btn btn-gradient btn-gradient-position" href="#">En savoir plus <i class="fa fa-arrow-right"></i></button>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card declarationType3">
        <div class="card-title">Fenêtres et portes-fenêtres acier aluminium MILLET Groupe</div>
        <p>Changement climatique</p>
        <div class="card-score"> <i class="fa fa-leaf badscore"> </i><strong>12.2</strong> kg CO2 eq/m2 </div>
        <div class="hoverbtn ">
          <div class="hoverbtncenter">
            <button class="btn btn-cards" href="#"> Ajouter mon poste</button>
          </div>
          <button class="btn btn-gradient btn-gradient-position" href="#">En savoir plus <i class="fa fa-arrow-right"></i></button>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <br>
      <div class="card declarationType4">
        <div class="card-title">Fenêtres et portes-fenêtres acier aluminium MILLET Groupe</div>
        <p>Changement climatique</p>
        <div class="card-score"> <i class="fa fa-leaf goodscore"> </i><strong>12.2</strong> kg CO2 eq/m2 </div>
        <div class="hoverbtn ">
          <div class="hoverbtncenter">
            <button class="btn btn-cards" href="#"> Ajouter mon poste</button>
          </div>
          <button class="btn btn-gradient btn-gradient-position" href="#">En savoir plus <i class="fa fa-arrow-right"></i></button>
        </div>
      </div>
    </div>
  </div>
  <hr>

  <h3>Search engine </h3>
  <br><br><br><br><br>
  <form novalidate="novalidate" class="simple_form search" action="/" accept-charset="UTF-8" method="get"><input name="utf8" type="hidden" value="&#x2713;" />
    <div class="search-form-control form-group">
      <input class="form-control string required" type="text" name="search[query]" id="search_query" placeholder="Nom du poste" />
      <button name="button" type="submit" class="btn btn-flat">
        <i class="fas fa-search"></i>
      </button>
    </div>
  </form>
  <br><br><br><br><br>

  <h6><strong>Produit de constructions</strong> </h6>

  <div class="row search-engine-category">

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Voirie.svg" alt="Appareillage installation réseaux" />
        <div class="notification-content">
          <p>Voirie / réseaux divers (y compris réseaux intérieurs) et aménagements extérieurs de la parcelle </p>
        </div>

      </div>
    </div>
    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-charpente.png" alt="charpente" />
        <div class="notification-content">
          <p>Strucure / maçonnerie / gros oeuvre / charpente divers </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-façades.svg" alt="façades" />
        <div class="notification-content">
          <p>Façades</p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-couverture.svg" alt="Couverture / étanchéité" />
        <div class="notification-content">
          <p>Couverture / étanchéité</p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Menuiserie_exterieure.svg" alt="Menuiseries intérieures et extérieures / fermetures" />
        <div class="notification-content">
          <p>Menuiseries intérieures et extérieures / fermetures</p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-gros_oeuvre.svg" alt="Isolations" />
        <div class="notification-content">
          <p>Isolation</p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-cloisons.svg" alt="Cloisonnement / plafonds-suspendus" />
        <div class="notification-content">
          <p>Cloisonnement / plafonds-suspendus</p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Revetement_de_sol.svg" alt="Revêtements des sols et murs / peintures / produits de décorations" />
        <div class="notification-content">
          <p>Revêtements des sols et murs / peintures / produits de décorations</p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Demolition.svg" alt="Démolition" />
        <div class="notification-content">
          <p>Produits de préparation et de mise en oeuvre</p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Plomberie.svg" alt="Approche simplifiée : Lots forfaitaires" />
        <div class="notification-content">
          <p>Equipements sanitaires et salle d’eau</p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Panneau_sandwich_agroalimentaire.svg" alt="Revêtements des sols et murs / peintures / produits de décorations" />
        <div class="notification-content">
          <p>Panneau sandwich agroalimentaire</p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Generalites.svg" alt="Autres" />
        <div class="notification-content">
          <p>Autres</p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Approche_simplifiee.svg" alt="Approche simplifiée : Lots forfaitaires" />
        <div class="notification-content">
          <p>Approche simplifiée : Lots forfaitaires</p>
        </div>
      </div>
    </div>

  </div>
  <br><br>
  <h6><strong>Equipements électriques, électroniques et de génie climatique</strong></h6>

  <div class="row search-engine-category">

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Appareillage_installation_reseaux.svg" alt="Appareillage installation réseaux" />
        <div class="notification-content">
          <p>Appareillage d’installation pour les réseaux d’énergie électrique et de communication (≤ 63 Ampères) </p>
        </div>

      </div>
    </div>
    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Fils_cables.svg" alt="Generalites" />
        <div class="notification-content">
          <p>Fils et câbles</p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Signaletique.svg" alt="Generalites" />
        <div class="notification-content">
          <p>Sécurité des personnes et contrôle d’accès</p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Securite_incendie.svg" alt="Generalites" />
        <div class="notification-content">
          <p>Sécurité du bâtiment</p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Equipements_genie_climatique.svg" alt="Generalites" />
        <div class="notification-content">
          <p>Equipements de génie climatique</p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Energie.svg" alt="Generalites" />
        <div class="notification-content">
          <p>Production locale d’énergie</p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Eclairage.svg" alt="Eclairage" />

        <div class="notification-content">
          <p>Matériel d’éclairages</p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Approche_simplifiee.svg" alt="Approche simplifiée" />
        <div class="notification-content">
          <p>Approche simplifiée : Lots forfaitaires</p>
        </div>
      </div>
    </div>



    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-generalites.svg" alt="Generalites" />
        <div class="notification-content">
          <p>Autres</p>
        </div>
      </div>
    </div>
  </div>
  <br><br>
  <h6><strong>Service </strong></h6>

  <div class="row search-engine-category">

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Assainnissement.svg" alt="Eaux" />
        <div class="notification-content">
          <p>Eaux </p>
        </div>

      </div>
    </div>
    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Traitement_dechets.svg" alt="Traitement des déchets" />
        <div class="notification-content">
          <p>Traitement des déchets</p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/transport.svg" alt="Transport" />
        <div class="notification-content">
          <p>Transport</p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Energie.svg" alt="Energie" />
        <div class="notification-content">
          <p>Energie</p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="notification">
        <img src="assets/dpgf/Icon_DPGF/Aglo-Fluides_frigorigenes.svg" alt="Fluides_frigorigenes" />
        <div class="notification-content">
          <p>Fluides frigorigènes</p>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <h3>Form </h3>

  <div class="row justify-content-center">
    <div class="col-12 col-md-12 col-lg-8 pop-up">
      <form action="#">
        <h2 class="text-center mb-5">Demande de création de composant</h2>
          <h6>Vos informations</h6>
          <p>Vos informations vous seront demandées une seule fois pour l’ajout d’un composant.</p>
          <div class="form-row">
            <div class="col-6 form-group">
              <label for="first-name">Prénom</label>
              <input type="text" class="form-control" id="first-name" placeholder="Prénom">
            </div>
            <div class="col-6 form-group">
              <label for="last-name">Nom</label>
              <input type="text" class="form-control" id="last-name" placeholder="Nom">
            </div>
            <div class="col-6 form-group">
              <label for="email">Email</label>
              <input type="email" class="form-control" id="email" placeholder="architecte@bamarchi">
            </div>
            <div class="col-6 form-group">
              <label for="phone_number">Numéro de téléphone</label>
              <input type="tel" class="form-control" id="phone_number" placeholder="06 09 06 09 06">
            </div>
            <div class="col-6 form-group">
              <label for="job">Profession</label>
              <input type="text" class="form-control" id="job" placeholder="Architecte">
            </div>
            <div class="col-6 form-group">
              <label for="company">Entreprise</label>
              <input type="text" class="form-control" id="company" placeholder="BAM Archi">
            </div>
          </div>
          <h6 class="mb">Les informations sur le composant</h6>
          <p>Ces informations vous sont demandées afin de faire la demande de création de composant.</p>
          <div class="form-row">
            <div class="col-12 form-group">
              <label for="ded-name">Intitulé de la donnée environnementale par défaut proposée</label>
              <input type="text" class="form-control" id="ded-name" placeholder="">
            </div>
          </div>
          <div class="form-row">
            <div class="col-6 form-group">
              <label for="quantity">Quantité à modéliser</label>
              <input type="number" class="form-control" id="quantity" placeholder="1">
            </div>
            <div class="col-6 form-group">
              <label for="UF">Unité de l’unité fonctionnelle</label>
              <select id="UF" class="form-control">
                <option value="">dm²</option>
                <option value="">m²</option>
                <option value="">kg</option>
                <option value="">m³</option>
                <option value="">m</option>
                <option value="">kg CO2 eq.</option>
                <option value="">kg SO2 eq.</option>
                <option value="">kg CFC-11 eq.</option>
                <option value="">UBP</option>
                <option value="">AND MORE</option>
              </select>
            </div>
            <div class="col-6 form-group">
              <label for="product_life">Durée de vie estimée</label>
              <input type="text" class="form-control" id="product_life" placeholder="50 ans">
            </div>

            <div class="col-12 form-group">
              <label for="argumentation">Argumentaire(s)/ Justification(s) de la demande</label>
              <textarea class="form-control" id="argumentation" rows="4"></textarea>
            </div>

            <div class="col-12 form-group">
              <label for="UF">Famille de de produit</label>
              <select id="UF" class="form-control">
                <option value="">Voirie / réseaux divers (y compris réseaux intérieurs) et aménagements extérieurs de la parcelle</option>
                <option value="">Strucure / maçonnerie / gros oeuvre / charpente divers </option>
                <option value="">Façades</option>
                <option value="">Couverture / étanchéité</option>
                <option value="">Menuiseries intérieures et extérieures / fermetures</option>
                <option value="">Isolation</option>
                <option value="">Cloisonnement / plafonds-suspendus</option>
                <option value="">Revêtements des sols et murs / peintures / produits de décorations</option>
                <option value="">Produits de préparation et de mise en oeuvre</option>
                <option value="">AND MORE</option>
              </select>
            </div>
          </div>

          <div class="form-check mb-5">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate">
            <label class="form-check-label" for="flexCheckIndeterminate">
              Je consens à la politique de collecte et protection des données personnelles de MLab
            </label>
          </div>
          <div class="btn-form">
            <input type="submit" value="Envoyer ma demande de création de composant" class="btn btn-forms">
          </div>
      </form>
    </div>
  </div>

</div>
<hr>
<h3>Category filter (en cours) </h3>
<div class="row mb-5">
  <div class="col-lg-4 ">
    <button type="button" class="collapsible ">Famille de produit</button>
    <div class="content">
      <br>
      <div class="family-name">
      PRODUITS DE CONSTRUCTIONS
    </div>
      <ul>
        <li>
        <li><strong>Menuiseries intérieurs et extérieurs / fermetures (43)</strong>
          <ul>
            <li>Acier (1)</li>
            <li>Aluminium (10)</li>
            <li>PVC (10)</li>
            <li>Bois - Aluminium (10)</li>
            <li>PVC - Aluminium (12)</li>
          </ul>
        </li>
      </ul>

    </div>
    <button type="button" class="collapsible">Type de fiche</button>
    <div class="content">
      <br>
      <div class="chip-filter">
        <div id="declarationType2" class="chip">
        Collective

        </div>
        <div id="declarationType3" class="chip">
          Donnée par défaut
        </div>

        <div id="declarationType1" class="chip">
          Individuelle

        </div>
        <div id="declarationType4" class="chip">
          Conventionnelle

        </div>
      </div>
    </div>
    <button type="button" class="collapsible">Nom de l’organisme déclarant</button>
    <div class="content">
      <br>
      <input type="text" class="form-control mb-4" placeholder="Nom de l'organisme déclarant"> </div>
    <button type="button" class="collapsible">Etiquette COV</button>
    <div class="content">
      <br>
  </div>
    <button type="button" class="collapsible">Lieu de production</button>
    <div class="content">

      <div class="form-check form-check-inline m-2">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
        <label class="form-check-label" for="inlineCheckbox1">Hors Europe</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
        <label class="form-check-label" for="inlineCheckbox2">Europe</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
        <label class="form-check-label" for="inlineCheckbox2">France</label>
      </div>
 </div>
    <button type="button" class="collapsible">Performance principale de l’UF</button>
    <div class="content">
      <br>
      <div class="col-12 form-group">
        <label for="UF">Performance principale de l’UF</label>
        <select class="form-control">
          <option value="">Toutes</option>
          <option value="">Charge supportée </option>
          <option value="">Classe d’usage</option>
          <option value="">Classement Air Eau Vent</option>
          <option value="">Classement de certification UPEC de la résistante à l’usure et au poinçonnement</option>
          <option value="">AND MORE</option>
        </select>
      </div>      </div>
    <button type="button" class="collapsible">Date de mise en ligne</button>
    <div class="content">
      <div class="col-12 form-group">
        <label for="UF">Date de mise en ligne</label>
        <select class="form-control">
          <option value="">Dernière semaine</option>
          <option value="">Dernier mois </option>
          <option value="">Trois dernier mois</option>
          <option value="">Six dernier mois</option>
        </select>
      </div>
 </div>
</div>
