import { Component, OnInit , Input, SimpleChanges, ViewChild, ElementRef, NgZone} from '@angular/core';
import {FormArray, UntypedFormBuilder, UntypedFormGroup,  Validators} from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgSharedModule } from 'src/app/all/ngshared.module';

//Maps
import { GoogleAutocompleteComponent } from 'src/app/all/google-autocomplete/google-autocomplete.component';

import { PrefillClass } from './template'
import { DpgfModule } from '../../dpgf.module';
import { LotSelectComponent } from 'src/app/all/lot-select/lot-select.component';
import { CheckboxSimple } from 'src/app/all/checkbox-simple/checkbox-simple.component';

declare var $:any;

@Component({
  standalone:true,
  selector: 'app-form-new-dpgf',
  imports:[
    DpgfModule, 
    LotSelectComponent,
    CheckboxSimple,
    GoogleAutocompleteComponent,
    NgSharedModule
  ],
  templateUrl: './form-new-dpgf.component.html',
  styleUrls: ['./form-new-dpgf.component.scss']
})
export class FormNewDpgfComponent implements OnInit {
  public dpgfForm: UntypedFormGroup;
  @ViewChild("search")
  public searchElementRef: ElementRef;
  private subscriptions: Array < Subscription > = [];
  public place: any = {};
  public renovation = false;
  public showLot = false;
  public formErrors = {
    type: '',
    natureTravaux: '',
    adresse: '',
    nomPrenom: '',
    template:'',
    defaultTva:'',
  };
  public questions={
    demolition : false,
    menuiserie_interieure : false,
    revetement_sol : false,
    peinture : false,
    electricite : false,
  };
  public poste={
    demolition : {ensemble : [], cuisine : [], sdb : []},
    peinture : {peinture : [], revetement : []},
    electricite : {electricite : []},
    menuiserie_interieure : {pose : [], creation : []},
    revetement_sol : {pose : [], realisation : []}
  };
  public travauxListeAll:any = (new PrefillClass).all
  public demolutionOption:any = (new PrefillClass).demolutionOption
  public peintureOption:any = (new PrefillClass).peintureOption
  public electriciteOption:any = (new PrefillClass).electriciteOption
  public menuiserieInterieureOption:any = (new PrefillClass).menuiserieInterieureOption
  public revetementSolOption:any = (new PrefillClass).revetementSolOption
  // public contraintes:any = (new PrefillClass).contraintes
  public travauxListe:any;
  constructor(private fb: UntypedFormBuilder,
              // private mapsAPILoader: MapsAPILoader, tocorrect
              private ngZone: NgZone) { }

  ngOnInit() {
    this.travauxListe = this.travauxListeAll.slice()
    this.createForm();
  }
  //Form
  createForm() {
    this.dpgfForm = this.fb.group({
      type: ['', Validators.required],
      natureTravaux: ['', Validators.required],
      adresse: ['', Validators.required],
      prefillData: [],
      nomPrenom: '',
      template: '',
      defaultTva: '',
      // contraintes: [],
    });
    this.subscriptions.push(this.dpgfForm.valueChanges.subscribe(data => this.onValueChanged(data)));
    this.onValueChanged();
  }

  getGoogleMapValueChange(event){
    this.patchAdress(event["description"]);
  }
  //Generate Error
  onValueChanged(data ? : any) {
    if (!this.dpgfForm) {
      return;
    }
    const form = this.dpgfForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        //const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += 'Ce champ est obligatoire ';
        }
      }
    }
  }
  //Mark as touched
  public markFormAsTouched(): void {
    if (!this.dpgfForm) {
      return;
    }
    const form = this.dpgfForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      control.markAsDirty();
      //const messages = this.validationMessages[field];
      for (const key in control.errors) {
        this.formErrors[field] += 'Ce champ est obligatoire ';
      }
    }
  }
  public patchAdress(val){
      let data = this.dpgfForm.value;
      data.adresse = val;
      this.dpgfForm.patchValue(data)
  }
  public setData(data){
    let tva = data.natureTravaux=="construction"?20:10;
    this.setTva(tva,data.natureTravaux)
    this.dpgfForm.patchValue(data)
  }
  isValid(){
      this.markFormAsTouched();
      return this.dpgfForm.valid;
  }
  getFormData(){
    let value =  this.dpgfForm.value;
    let poste = {};
    for (var key in this.poste) {
      poste[key] = []
      for (var subKey in this.poste[key]) {
          poste[key] = [...poste[key],...this.poste[key][subKey]]
      }
    }
    value.poste = poste;
    return value;
  }

  public changeLot(event){
    let lots = event.value;
    let matching = {
      "Démolition - Dépose" : "demolition",
      "Maçonnerie - Gros-œuvre" : "maconnerie",
      "Menuiserie Extérieure" : "menuiserie_extérieure",
      "Cloisons - Doublages - Faux-plafonds" : "cloissons",
      "Menuiserie Intérieure - Agencement" : "menuiserie_interieure",
      "Revêtement de sol" : "revetement_sol",
      "Peinture - Revêtements Muraux - Nettoyage" : "peinture",
      "Electricité":"electricite",
      "Plomberie - Sanitaire":"plomberie",
    }
    this.questions = {
      demolition : false,
      menuiserie_interieure : false,
      revetement_sol : false,
      peinture : false,
      electricite : false,
    }
    // lots.forEach(lot=>{
    //   if(matching[lot.value])this.questions[matching[lot.value]] = true
    // })
  }
  public setTva(val,travaux){
    this.renovation = (travaux=="renovation")
    this.showLot = true;
    this.travauxListe = this.travauxListeAll.filter(val=>{
      return val.project.indexOf(travaux) != -1
    })
    let data = this.dpgfForm.value;
    data.defaultTva = val;
    data.natureTravaux = travaux;
    this.dpgfForm.patchValue(data)
  }
  //scrollTo
  public scrollTo(element) {
    $('html, body').animate({
      scrollTop: ($(element).offset().top - 70)
    });
  }
  public ngOnDestroy(): void {
        this.travauxListe = null;
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }
}
