const _ = require('lodash');

export class EmailTemplatesHelpers {

  // type de travaux
  private typeLabels: any = {
    construction: "Construction Neuve",
    extension: "Extension/Surélévation",
    renovation: "Renovation/Aménagement",
    transformation: "Transformation",
  }  

  // programme
  private programmeLabels: any = {
    maison: "Maison",
    appartement: "Appartement",
    hotel: "Café/Hôtel/Restaurant",
    commerce: "Local commercial",
    logement: "Logements collectifs",
    bureaux: "Bureaux/Tertaire",
    agricole: "Agricole/Industriel",
    autre: "Autre",
  }  

  private emailTemplatesVariablesMapper:any = {
    "%NOM_PROJET%": "ceProject.title",
    "%ADRESSE_PROJET%": "ceProject.adresse",
    "%CODE_POSTAL_PROJET%": "ceProject.addressCodePostal",
    "%VILLE_PROJET%": "ceProject.addressCity",
    "%PAYS_PROJET%": "ceProject.addressCountry",
    "%TYPE_TRAVAUX_PROJET%": "ceProject.natureTravaux",
    "%PROGRAMME_PROJET%": "ceProject.type",
    "%DATE_RENDU_OFFRES%": "ceProject.dateDce",
    "%DATE_DÉMARRAGE_TRAVAUX%": "ceProject.dateTravaux",
    "%TELEPHONE_MOE%": "user.phone",
    "%NOM_AGENCE_MOE%": "architect.name",
    "%PRENOM_MOE%": "user.firstName",
    "%NOM_MOE%": "user.lastName",
    "%NOM_ENTREPRISE%": "artisan.name"
  }

  public transformTemplate(SHOW_ARTISAN_DATA: boolean = false, emailTemplate:any, variablesValues: any){
    let transformedTemplate = emailTemplate;

    const datesToFormat = ["%DATE_RENDU_OFFRES%", "%DATE_DÉMARRAGE_TRAVAUX%"];
    const itemsToGetLabel = ["%TYPE_TRAVAUX_PROJET%","%PROGRAMME_PROJET%"];

    for(let key in this.emailTemplatesVariablesMapper){      

      // on send/update multiple DCE don't show artisan name 
      if(!SHOW_ARTISAN_DATA && key == "%NOM_ENTREPRISE%") continue;

      if(transformedTemplate.includes(key)){
        const valuePath = this.emailTemplatesVariablesMapper[key];

        // input : ceProject.address
        // output : paris 15 ...
        let extractedValue = _.get(variablesValues, valuePath, "");
        
        // if the current variable is a date, in datesToFormat list, format it
        if(extractedValue && datesToFormat.includes(key)){
          extractedValue = this.formateDate(extractedValue);          
        }

        // if the current variable is natureTravaux or type
        // show the label instead of the value
        if(extractedValue && itemsToGetLabel.includes(key)){
          let extractedValueLabel = "";

          if(key == "%TYPE_TRAVAUX_PROJET%"){
            extractedValueLabel = this.typeLabels[extractedValue];
          }else if(key == "%PROGRAMME_PROJET%"){
            extractedValueLabel = this.programmeLabels[extractedValue];
          }

          extractedValue = extractedValueLabel;          
        }        

        transformedTemplate = transformedTemplate.replaceAll(key, extractedValue);
      }

    }

    return transformedTemplate;
  }

  // input ISO date
  // output : DD/MM/AAAA
  private formateDate(isoDate){
    let splittedDate = isoDate.split("T")[0];
    const dates = splittedDate.split("-");
    let year = dates[0];
    let month = dates[1];
    let day = dates[2];

    let formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

}