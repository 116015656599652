import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, AfterViewInit, ViewChild, Renderer2, ElementRef } from '@angular/core';

// static data
import activitiesList from '../../booster-profile-forms-components/booster-profile-corps-etat-form/activities-list';
import mainRegionsList from '../../booster-profile-forms-components/booster-profile-precision-form/regionsList-list';

import { NgSharedModule } from 'src/app/all/ngshared.module';
import { FileUpload } from 'primeng/fileupload';

@Component({
    standalone: true,
    selector: 'app-booster-profile-info-side-bar',
    imports:[
      NgSharedModule 
    ],
    templateUrl: './booster-profile-info-side-bar.component.html',
    styleUrls: ['./booster-profile-info-side-bar.component.scss'],
})
export class BoosterProfileInfoSideBarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('fileUpload') fileUpload: FileUpload;
  @Input() artisanData: any;
  @Input() showEditButtons: boolean = false;
  @Output() openSpecificModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateLogo: EventEmitter<any> = new EventEmitter<any>();

  public departementsInterventionList: any = [];
  public corpsDetatItemsList: any = [
    // {
    //   title: "",
    //   list: []
    // }
  ];
  public corpsDetatItemsListCount: number = 0;


  //Type
  public typeList = {
    "contractant-general":"Contractant général",
    "entreprise-generale":"Entreprise générale",
    "bureau-d-etude":"Bureau d'études",
    "artisan-entreprise-specialisee":"Artisan / Entreprise spécialisée",
    "fabriquant-revendeur-materiaux":"Fabriquant / Revendeur matériaux",
  };
  //travaux
  public travauxList:any = {
    "renovation": "Rénovation",
    "extension": "Extension",
    "surelevation": "Surélévation",
    "construction-neuve": "Construction Neuve",
  };
  //Programme
  public programmesList:any = {
    "maison": "Maison individuelle",
    "appartement": "Appartement",
    "bureaux-tertiaire": "Bureaux",
    "immeuble": "Logements collectifs",
    "equipements-publics": "Équipements publics",
    "batiment-industriel-agricole": "Bâtiments industriels/Agricoles",
    "boutique-conceptstore": "Boutique/Concept store",
    "cafe-hotel-restaurant": "Café/Hôtel/Restaurant",
  };

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.artisanData && Object.keys(changes.artisanData.currentValue).length){
      setTimeout(() => {
        let currentData = changes.artisanData.currentValue;
        this.corpsDetatItemsList = this.setGarantieDecennaleData(currentData.corpsDetat, activitiesList);
        this.departementsInterventionList = this.setDepartementsInterventionList(mainRegionsList, currentData.workAreaMain);
      }, 400);
    }
  }

  onUploadAgencyLogo(event){
    let currentFile = event.originalEvent.body[0];
    let data = {logo: currentFile};
    this.emitUpdateLogo(data);
    this.artisanData.logo = currentFile;
  }
  openPfileUpload(){
    // console.log('fileUpload',this.fileUpload)
    // this.fileUpload.basicFileInput.nativeElement.open();
    const fileInput = this.el.nativeElement.querySelector('input[type="file"]');
    if (fileInput) {
      this.renderer.setProperty(fileInput, 'value', null);
      fileInput.click();
    }

  }
  private setGarantieDecennaleData(artisanCorpsDetat, activitiesList){
    let data = [];

    let selectedItems = {};

    let items = artisanCorpsDetat.filter(item => !item.custom);
    /// set our selected checkboxes to out Object
    for(let i = 0; i < items.length; i++){
      let singleItem = items[i];
      let singleItemParts = singleItem.split('_');

      let activity = singleItemParts[0];
      let section = singleItemParts[1];
      let checkBoxItem = singleItemParts[2];

      // if activity not already Exists in Obj add
      if(!selectedItems[activity]){
        selectedItems[activity] = {
          [section] : [checkBoxItem]
        };
      }else{ /// if activity already exists in Obj

        if(!selectedItems[activity][section]){ // if activity exists on obj, and don't have the section in it
          selectedItems[activity][section] = [checkBoxItem]
        }else{/// If activity and section exists push item to it
          selectedItems[activity][section].push(checkBoxItem);
        }

      }
    }

    let filtredActivitiesList = activitiesList.filter(item => !item.isCustom);

    for(let i = 0; i < filtredActivitiesList.length; i++){
      let singleActivity = filtredActivitiesList[i];      
      let singleActivityId = singleActivity.id;

      if(selectedItems[singleActivityId]){

        for(let j = 0; j < singleActivity.sectionsLists.length; j++){
          let singleSection = singleActivity.sectionsLists[j];
          let singleSectionId = singleSection.id;
          
          if(selectedItems[singleActivityId][singleSectionId]){
            let shownSection = {title: singleSection.title, itemsList: []};

            for(let k = 0; k < singleSection.list.length; k++){
              let singleItem = singleSection.list[k];

              if(selectedItems[singleActivityId][singleSectionId] && selectedItems[singleActivityId][singleSectionId].includes(singleItem.value)){
                shownSection.itemsList.push(singleItem);
                this.corpsDetatItemsListCount++;
              }
            }

            data.push(shownSection);
          }
        }

      }

    }

    return data;
  }

  private setDepartementsInterventionList(mainRegionsList, artisanWorkMainArea){
    let data = [];

    let artisanWorkMainAreaValues = artisanWorkMainArea.map(item => item.id);
    
    for(let i = 0; i < mainRegionsList.length; i++){
      let currentRegion = mainRegionsList[i];
      let regionDepartements = currentRegion.departements;
      let regionDepartementsKeys = regionDepartements.map(item => item.id);

      /// add region name (if all departements selected)
      if(this.arrayContainsAllItemsOfAnotherArray(artisanWorkMainAreaValues, regionDepartementsKeys)){
        data.push(currentRegion.name);
      }else{ /// add departement name (if not the entire region is selected)
        for(let j = 0; j < regionDepartements.length; j++){
          let currentDepartement = regionDepartements[j];
          
          if(artisanWorkMainAreaValues.includes(currentDepartement.id)){
            data.push(currentDepartement.departName);
          }

        }
      }

    }

    return data;
  }

  /// CHeck if array1 contains all items in array2
  private arrayContainsAllItemsOfAnotherArray(array1, array2){
    let isValid = true;
    
    for(let i = 0; i < array2.length; i++){
      if(!array1.includes(array2[i])){
        isValid = false;
      }
    }

    return isValid;
  }
  public openModal(modalType){
    this.openSpecificModal.emit(modalType);
  }
  private emitUpdateLogo(logo){
    this.updateLogo.emit(logo);
  }

}
