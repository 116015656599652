import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { Router } from '@angular/router';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $ :any;
@Component({
  standalone:true,
  selector: 'app-upgrade-to-integral-modal',
  imports:[NgSharedModule],
  templateUrl: './upgrade-to-integral-modal.component.html',
  styleUrls: ['./upgrade-to-integral-modal.component.scss']

})
export class UpgradeToIntegralModalComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }
  public redirectToPricingTable(){
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
    $('.modal').modal('hide'); /// hide all modals
  }

}
