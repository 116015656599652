import {NgModule,Component,Input,Output,EventEmitter,forwardRef} from '@angular/core';
import {ObjectUtils} from './objectutils';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { CstbNavigationLotComponent } from '../cstb/cstb-navigation-lot/cstb-navigation-lot.component';
interface SelectItem {
    label: string;
    value: any;
    gid: any;
    styleClass?: string;
}

export const SELECTBUTTON_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LotSelectComponent),
    multi: true
};

@Component({
    standalone: true,
    selector: 'app-lot-select',
    imports: [NgSharedModule, CstbNavigationLotComponent],
    template: `
        <div class="row row-eq-height">
            <div *ngFor="let option of options; let i = index" class="pointer styled-input-single ui-widget col-xs-12">
                <div class="checkbox-container"
                    [ngClass]="{'checkbox-container-active':isSelected(option),'ui-state-active':isSelected(option)}"
                    (click)="onItemClick($event,option,cbox,i)" >
                  <label class="pointer">
                    <img class="icon-clique" *ngIf="option.icon" [src]="option.icon" height="40">
                    {{option.name}}
                  </label>
                  <i *ngIf="isSelected(option)" class="fa fa-check left" aria-hidden="true"></i>
                  <input #cbox type="checkbox" [checked]="isSelected(option)" (focus)="onFocus($event)" (blur)="onBlur($event)" [attr.tabindex]="tabindex" [attr.disabled]="disabled">
                  <div class="ui-helper-hidden-accessible">
                  </div>
                </div>
                <div *ngFor="let suboption of (option.options||[]); let i = index">
                  <div class="checkbox-container" [ngClass]="{'checkbox-container-active':isSubOptionSelected(option,suboption)}"
                       (click)="onSubItemClick($event,option,suboption)">
                    <label class="pointer">
                      {{(suboption||{}).name}}
                    </label>
                    <i *ngIf="isSubOptionSelected(option,suboption)" class="fa fa-check left" aria-hidden="true"></i>
                    <input #cbox type="checkbox" [checked]="isSubOptionSelected(option,suboption)" (focus)="onFocus($event)" (blur)="onBlur($event)" [attr.tabindex]="tabindex" [attr.disabled]="disabled">
                    <div class="ui-helper-hidden-accessible">
                    </div>
                  </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./lot-select.scss'],
    providers: [ObjectUtils, SELECTBUTTON_VALUE_ACCESSOR]
})
export class LotSelectComponent implements ControlValueAccessor {

    @Input() tabindex: number;

    @Input() multiple: boolean;

    @Input() style: any;

    @Input() styleClass: string;

    @Input() lineClass: string = "col-lg-12 col-md-12 col-sm-12 col-xs-12";

    @Input() disabled: boolean;

    @Input() optionLabel: string;

    @Output() onOptionClick: EventEmitter<any> = new EventEmitter();

    @Output() onChange: EventEmitter<any> = new EventEmitter();

    value: any;

    focusedItem: HTMLInputElement;

    _options: any[];

    onModelChange: Function = () => { };

    onModelTouched: Function = () => { };

    constructor(public objectUtils: ObjectUtils) { }

    @Input() get options(): any[] {
        return this._options;
    }

    set options(val: any[]) {
        let opts = this.optionLabel ? this.objectUtils.generateSelectItems(val, this.optionLabel) : val;
        this._options = opts;
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: Function): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onModelTouched = fn;
    }

    setDisabledState(val: boolean): void {
        this.disabled = val;
    }

    onItemClick(event, option: SelectItem, checkbox: HTMLInputElement, index: number) {
        if (this.disabled) {
            return;
        }

        checkbox.focus();

        if (this.multiple) {
            let itemIndex = this.findItemIndex(option);
            if (itemIndex != -1)
                this.value = this.value.filter((val, i) => i != itemIndex);
            else
                this.value = [...this.value || [], { value: option.value, gid: option.gid, subOption: [] }];
        }
        else {
            this.value = option.value;
        }

        this.onOptionClick.emit({
            originalEvent: event,
            option: option,
            index: index
        });

        this.onModelChange(this.value);
        this.onChange.emit({
            originalEvent: event,
            value: this.value
        });
    }
    onSubItemClick(event, option: SelectItem, subOption: SelectItem) {

        let itemIndex = this.findItemIndex(option);
        if (itemIndex != -1) {
            let subIndex = (this.value[itemIndex].subOption || []).indexOf(subOption.value);
            if (subIndex == -1) {
                this.value[itemIndex].subOption.push(subOption.value)
            } else {
                this.value[itemIndex].subOption = this.value[itemIndex].subOption.filter((val, i) => i != subIndex);
            }
        }
        else {
            this.value = [...this.value || [], { value: option.value, gid: option.gid, subOption: [subOption.value] }];
        }

        this.onModelChange(this.value);
        this.onChange.emit({
            originalEvent: event,
            value: this.value
        });
    }
    onFocus(event: Event) {
        this.focusedItem = <HTMLInputElement>event.target;
    }

    onBlur(event) {
        this.focusedItem = null;
        this.onModelTouched();
    }

    isSelected(option: SelectItem) {
        if (this.multiple)
            return this.findItemIndex(option) != -1;
        else
            return option.value == this.value;
    }
    isSubOptionSelected(option: SelectItem, suboption: SelectItem) {
        let index = this.findItemIndex(option);
        if (index == -1) {
            return false;
        } else {
            return ((this.value[index].subOption || []).indexOf(suboption.value) != -1);
        }
    }
    findItemIndex(option: SelectItem) {
        let index = -1;
        if (this.value) {
            for (let i = 0; i < this.value.length; i++) {
                if (this.value[i].value == option.value) {
                    index = i;
                    break;
                }
            }
        }
        return index;
    }
}
