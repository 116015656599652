<app-header></app-header>

<div class="flex justify-center " style="width: 100%;height: 90%;align-items: center;">
    <div style="width: 520px;height: 456px;">
      <div class="flex justify-center padding-bottom-16"  >
        <img src="/assets/errors-svg/error-502.svg">
      </div>
      <div class="padding-bottom-16" style="font-size: 25px;text-align: center;font-weight: 700;">
        Erreur serveur
      </div>
      <div class="padding-bottom-16" style="font-size: 16px;text-align: center;">
       <p>
        Serveur inaccessible. Veuillez réessayer plus tard.
       </p>
      <div class="flex justify-center">
        <button [routerLink]="[returnUrl||'/']" style="font-size: 16px;padding-right: 16px;border-radius: 4px; padding-left: 16px;padding-top: 8px;padding-bottom: 8px;color:white;text-align: center;background-color: rgba(45, 4, 194, 1);">Revenir à la page précédente</button>
      </div>
    </div>
  </div>