
<div class="no-albums-container text-center margin-top-40" *ngIf="!reviews.length">
  <h5 class="ag-heading-h5">L'entreprise est en train de compléter son profil, revenez plus tard pour voir ses recommandations</h5>
</div>

<div class="outter-wrapper padding-top-45" *ngIf="reviews.length">

  <!-- Global Rating -->
  <div class="global-rating-container horizontal-items">
    
    <div class="overall-rating-container width-35 text-center">
      <h1>{{globalRating}}/5</h1>
      <div class="stars flex-middle">
        <app-rating-stars [rating]="globalRating"></app-rating-stars>
      </div>
      
      <p class="avis-count margin-top-38">{{ reviews.length }} avis</p>

    </div>
    

    <div class="detailed-rating-container width-65 horizontal-items">
      
      <!-- Left Side -->
      <div class="left-side width-50">

        <div class="single-detail-item margin-bottom-8">
          <p class="ag-para-1-sm margin-bottom-5">Qualité d'exécution</p>
          <app-rating-stars [rating]="average.execution"></app-rating-stars>
        </div>

        <div class="single-detail-item margin-bottom-8">
          <p class="ag-para-1-sm margin-bottom-5">Disponibilité en cours du chantier</p>
          <app-rating-stars [rating]="average.disponibilite"></app-rating-stars>
        </div>

        <div class="single-detail-item margin-bottom-8">
          <p class="ag-para-1-sm margin-bottom-5">Respect du devis</p>
          <app-rating-stars [rating]="average.respectDevis"></app-rating-stars>
        </div>

        <div class="single-detail-item margin-bottom-8">
          <p class="ag-para-1-sm margin-bottom-5">Respect du projet architectural</p>
          <app-rating-stars [rating]="average.respectProjet"></app-rating-stars>
        </div>

      </div>
      <!-- END Left Side -->

      <!-- Right Side -->
      <div class="right-side width-50">

        <div class="single-detail-item margin-bottom-8">
          <p class="ag-para-1-sm margin-bottom-5">Propreté du chantier et gestion des nuisances</p>
          <app-rating-stars [rating]="average.proprete"></app-rating-stars>
        </div>

        <div class="single-detail-item margin-bottom-8">
          <p class="ag-para-1-sm margin-bottom-5">Respect des délais</p>
          <app-rating-stars [rating]="average.respectDelais"></app-rating-stars>
        </div>

        <div class="single-detail-item margin-bottom-8">
          <p class="ag-para-1-sm margin-bottom-5">Compétitivité des tarifs</p>
          <app-rating-stars [rating]="average.competitivite"></app-rating-stars>
        </div>

        <div class="single-detail-item margin-bottom-8">
          <p class="ag-para-1-sm margin-bottom-5">Tolérance vis à vis des modifications de projet</p>
          <app-rating-stars [rating]="average.tolerence"></app-rating-stars>
        </div>

      </div>
      <!-- END Right Side -->

    </div>



  </div>
  <!-- END Global Rating -->

  <div class="separator block"></div>

  <!-- Reviews List -->
  <div class="reviews-list-container">    
    <div class="single-review horizontal-items" *ngFor="let review of reviews">
      
      <div class="image-side flex-middle">
        <img [src]="review.architect.logo.cdnUrl" *ngIf="review?.architect?.logo?.cdnUrl"/>
        <img src="/assets/rob/helmet.png" *ngIf="!review?.architect?.logo?.cdnUrl"/>

        <!-- <img src="https://fastly.picsum.photos/id/599/200/300.jpg?hmac=E2gUK85wncj5qALDLpEjQzqgfazui9pDGMgzVWMpqo4" /> -->
      </div>

      <div class="info-side">
      
        <div class="horizontal-items">
          <app-rating-stars [rating]="review.average"></app-rating-stars>
          <span class="margin-left-5 ag-para-2 ag-text-grey">{{ review?.createdAt | date:'longDate' }}</span>
        </div>

        <h5 class="ag-heading-h5">{{ review?.adminArchitect?.name || "" }}</h5>
        <p class="ag-para-1 ag-text-grey">
          {{ review.commentaire }}
        </p>
      
      </div>

    </div>

  </div>
  <!-- END Reviews List -->

</div>