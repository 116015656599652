import { Pipe, PipeTransform, NO_ERRORS_SCHEMA } from '@angular/core';

@Pipe({
  standalone:true,
  name: 'priceUnite'
})
export class PriceUnitePipe implements PipeTransform {

  transform(value:String,unity:String) {
      if (value) {
          return `${value} ${unity}`
      }
      return "-";
  }
}
