let activitiesList = [  
  {
    title: "Préparation et aménagement de site",
    id: "preparation-et-amenagement-site",
    icon: "bulldozer.svg",
    sectionsLists: [
      {
        title: "Désamiantage",
        id: "desamiantage",
        list: [
          {label: "Désamiantage", value: "desamiantage"},
        ]
      },{
        title: "Démolition - Dépose",
        id: "demolition-depose",
        list: [
          {label: "Démolition - Dépose", value: "demolition-depose"},
        ]
      },{
        title: "VRD",
        id: "vrd",
        list: [
          {label: "VRD", value: "vrd"},
        ]
      },{
        title: "Terrassement",
        id: "terrassement",
        list: [
          {label: "Terrassement", value: "terrassement"},
        ]
      }
    ]
  },
  {
    title: "Clos et couvert",
    id: "clos-et-couvert",
    icon: "bricks-icon.svg",
    sectionsLists: [      
      { // Added
        title: "Soutènement - Infrastructure",
        id: "soutenement-infrastructure",
        list: [
          {label: "Soutènement - Infrastructure", value: "soutenement-infrastructure"},
        ]
      },
      {
        title: "Façades",
        id: "facades",
        list: [
          {label: "Ravalement", value: "ravalement"},
          {label: "Isolation par l’extérieur", value: "isolation-par-exterieur"},
          {label: "Isolation biosourcée", "value": "isolation-biosourcee"}, // NEW ADDED
          {label: "Revêtements/Peintures extérieures", value: "revetements-peintures-exterieures"},
        ]
      },
      {
        title: "Structure",
        id: "structure",
        list: [
          {label: "Charpente bois", value: "charpente-bois"},
          {label: "Ossature bois", value: "ossature-bois"},
          {label: "Charpente métallique", value: "charpente-metallique"},
          {label: "Structure métallique", value: "structure-metallique"},
        ]
      },
      {
        title: "Couverture",
        id: "couverture",
        list: [
          {label: "Couverture - Zinguerie", value: "couverture-zinguerie"},
          {label: "Étanchéité", value: "etancheite"},
        ]
      },
      {
        title: "Gros Oeuvre",
        id: "gros-oeuvre",
        list: [
          {label: "Maçonnerie - Gros-Oeuvre", value: "maconnerie-gros-oeuvre"},
          {label: "Micropieux", value: "micropieux"},
          {label: "Béton banché", value: "beton-banche"},
          {label: "Taille de pierre", value: "taille-de-pierre"},
        ]
      },
      {
        title: "Menuiserie extérieures",
        id: "menuiserie-exterieures",
        list: [
          {label: "Menuiseries extérieures bois", value: "menuiseries-exterieures-bois"},
          {label: "Menuiseries extérieures aluminium/PVC", value: "menuiseries-exterieures-alu-pvc"},
        ]
      }
    ],
  },
  // 2- Second-oeuvre
  {
    title: "Second-oeuvre",
    id: "Second-oeuvre",
    icon: "paint-brush-icon.svg",
    sectionsLists: [
      {
        title: "Menuiseries intérieures et agencement",
        id: "menuiseries-interieures-et-agencement",
        list: [
          {label: "Menuiseries intérieures", value: "menuiseries-interieures"}, // MAYBE CHANGED
          {label: "Agencement intérieur", value: "agencement"},
        ]
      },
      {
        title: "Cloisons - Faux-plafonds",
        id: "cloisons-faux-plafonds",
        list: [
          {label: "Plâtrerie", value: "platrerie"},
          {label: "Isolation intérieure", value: "isolation-interieure"},
          {label: "Faux-plafonds", value: "faux-plafonds"},
          {label: "Staff - Stuc", value: "staff-stuc"},
        ]
      },
      { // NEW
        title: "Revêtements de sol",
        id: "revetements-de-sol-durs",
        list: [
          {label: "Revêtements de sol souples", value: "revetements-de-sol-souples"},
          {label: "Parquet", value: "parquet"},
          {label: "Chape", value: "chape"},
          {label: "Sol coulé", value: "sol-coule"},
        ]
      },
      {
        title: "Carrelage - Faïence",
        id: "carrelage-faience",
        list: [
          {label: "Carrelage - Faïence", value: "carrelage-faience"},
        ]
      },
      {
        title: "Miroiterie - Vitrerie",
        id: "miroiterie-vitrerie",
        list: [
          {label: "Miroiterie - Vitrerie", value: "miroiterie-vitrerie"},
        ]
      },
      { // NEW
        title: "Serrurerie - Ferronerie ",
        id: "serrurerie-ferronerie",
        list: [
          {label: "Serrurerie - Ferronerie ", value: "serrurerie-ferronerie"},
        ]
      },
      {
        title: "Peinture - Revêtements muraux - Nettoyage",
        id: "peinture-revetements-muraux-nettoyage",
        list: [
          {label: "Peinture - Revêtements muraux - Nettoyage", value: "peinture-revetements-muraux-nettoyage"},
        ]
      }
    ],
  },
  //// 3- Corps d’état technique
  {
    title: "Corps d’état <br> technique",
    id: "corps-etat-technique",
    icon: "pipes-icon.svg",
    sectionsLists: [
      {
        title: "Électricité",
        id: "electricite",
        list: [
          {label: "Électricité", value: "electricite"},
        ]
      },
      {
        title: "Système de sécurité et de controle d'accès",
        id: "securite",
        list: [
          {label: "Alarmes - Surveillance", value: "alarmes-surveillance"},
          {label: "Sécurité incendie", value: "securite-incendie"},
          {label: "Contrôle d'accès", value: "controle-acces"},
        ]
      },
      { // NEW
        title: "Domotique",
        id: "domotique",
        list: [
          {label: "Domotique", value: "domotique"},
        ]
      },
      {
        title: "Appareil élevateur",
        id: "appareil-elevateur",
        list: [
          {label: "Ascenseurs", value: "ascenseurs"},
        ]
      },
      {
        title: "Plomberie - Sanitaire",
        id: "plomberie-sanitaire",
        list: [
          {label: "Plomberie-Sanitaire", value: "plomberie-sanitaire"},
          {label: "Gaz", value: "gaz"},
        ]
      },
      {
        title: "CVC",
        id: "cvc",
        list: [
          {label: "Fluides frigorigènes", value: "equipements-utilisant-des-fluides-frigorigenes"},
          {label: "Chauffage", value: "chauffage-electrique"},
          {label: "Climatisation", value: "climatisation"},
        ]
      },
      {
        title: "Energies renouvelables",
        id: "energie-renouvelable",
        list: [
          {label: "Géothermie", value: "geothermie"},
          {label: "Photovoltaïque", value: "photovoltaique"},
        ]
      }
    ],
  },
  // 4- Aménagements particuliers
  {
    title: "Aménagements <br> particuliers",
    id: "amenagements-particuliers",
    icon: "chairs-icon.svg",
    sectionsLists: [
      {
        title: "Aménagement paysager",
        id: "amenagements-paysager",
        list: [
          {label: "Aménagement paysager", value: "amenagements-paysager"},
        ]
      },
      {
        title: "Piscine",
        id: "piscine",
        list: [
          {label: "Piscine", value: "piscine"},
        ]
      },
      {
        title: "Cuisine",
        id: "cuisine",
        list: [
          {label: "Cuisine", value: "cuisine"},
        ]
      }
    ]
  }  
];


export default activitiesList;