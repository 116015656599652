// constants
let PER=50;
let Texploitation=0.02;
let Tproduction=0.02;
let Tfin=0.1;
let arrCO2FF=[
  { t: 0, co2: 1, ff: 1, FIELD4: NaN },
  { t: 1, co2: 0.992, ff: 0.999, FIELD4: 0.008 },
  { t: 2, co2: 0.984, ff: 0.998, FIELD4: 0.008 },
  { t: 3, co2: 0.976, ff: 0.997, FIELD4: 0.008 },
  { t: 4, co2: 0.969, ff: 0.996, FIELD4: 0.007 },
  { t: 5, co2: 0.961, ff: 0.995, FIELD4: 0.008 },
  { t: 6, co2: 0.953, ff: 0.994, FIELD4: 0.008 },
  { t: 7, co2: 0.945, ff: 0.993, FIELD4: 0.008 },
  { t: 8, co2: 0.937, ff: 0.992, FIELD4: 0.008 },
  { t: 9, co2: 0.929, ff: 0.991, FIELD4: 0.008 },
  { t: 10, co2: 0.921, ff: 0.99, FIELD4: 0.008 },
  { t: 11, co2: 0.913, ff: 0.988, FIELD4: 0.008 },
  { t: 12, co2: 0.905, ff: 0.987, FIELD4: 0.008 },
  { t: 13, co2: 0.897, ff: 0.986, FIELD4: 0.008 },
  { t: 14, co2: 0.889, ff: 0.984, FIELD4: 0.008 },
  { t: 15, co2: 0.88, ff: 0.983, FIELD4: 0.009 },
  { t: 16, co2: 0.872, ff: 0.981, FIELD4: 0.008 },
  { t: 17, co2: 0.864, ff: 0.98, FIELD4: 0.008 },
  { t: 18, co2: 0.856, ff: 0.978, FIELD4: 0.008 },
  { t: 19, co2: 0.848, ff: 0.976, FIELD4: 0.008 },
  { t: 20, co2: 0.84, ff: 0.975, FIELD4: 0.008 },
  { t: 21, co2: 0.831, ff: 0.973, FIELD4: 0.009 },
  { t: 22, co2: 0.823, ff: 0.971, FIELD4: 0.008 },
  { t: 23, co2: 0.815, ff: 0.969, FIELD4: 0.008 },
  { t: 24, co2: 0.806, ff: 0.967, FIELD4: 0.009 },
  { t: 25, co2: 0.798, ff: 0.965, FIELD4: 0.008 },
  { t: 26, co2: 0.79, ff: 0.963, FIELD4: 0.008 },
  { t: 27, co2: 0.781, ff: 0.961, FIELD4: 0.009 },
  { t: 28, co2: 0.773, ff: 0.958, FIELD4: 0.008 },
  { t: 29, co2: 0.764, ff: 0.956, FIELD4: 0.009 },
  { t: 30, co2: 0.756, ff: 0.954, FIELD4: 0.008 },
  { t: 31, co2: 0.747, ff: 0.951, FIELD4: 0.009 },
  { t: 32, co2: 0.739, ff: 0.948, FIELD4: 0.008 },
  { t: 33, co2: 0.73, ff: 0.946, FIELD4: 0.009 },
  { t: 34, co2: 0.721, ff: 0.943, FIELD4: 0.009 },
  { t: 35, co2: 0.713, ff: 0.94, FIELD4: 0.008 },
  { t: 36, co2: 0.704, ff: 0.937, FIELD4: 0.009 },
  { t: 37, co2: 0.695, ff: 0.934, FIELD4: 0.009 },
  { t: 38, co2: 0.686, ff: 0.93, FIELD4: 0.009 },
  { t: 39, co2: 0.678, ff: 0.927, FIELD4: 0.008 },
  { t: 40, co2: 0.669, ff: 0.923, FIELD4: 0.009 },
  { t: 41, co2: 0.66, ff: 0.92, FIELD4: 0.009 },
  { t: 42, co2: 0.651, ff: 0.916, FIELD4: 0.009 },
  { t: 43, co2: 0.642, ff: 0.912, FIELD4: 0.009 },
  { t: 44, co2: 0.633, ff: 0.908, FIELD4: 0.009 },
  { t: 45, co2: 0.624, ff: 0.904, FIELD4: 0.009 },
  { t: 46, co2: 0.615, ff: 0.899, FIELD4: 0.009 },
  { t: 47, co2: 0.606, ff: 0.895, FIELD4: 0.009 },
  { t: 48, co2: 0.597, ff: 0.89, FIELD4: 0.009 },
  { t: 49, co2: 0.587, ff: 0.885, FIELD4: 0.01 },
  { t: 50, co2: 0.578, ff: 0.88, FIELD4: 0.009 }
]
const IpmdDVEgreaterPER=(quantity,DEm)=>{
  return quantity*DEm*arrCO2FF[PER]['co2'];
}
/// Si DVE < PER
// la fraction restante du cycle de vie après le dernier cycle complet
const futilDVElessPER=(dve)=>{
  return (PER/dve)-Math.floor(PER/dve)
}
// Phase d'exploitation (en cycles complets)
const beta1p=(dve,DEexploitation)=>{
  let sum=0;
  for (let index = 1; index <= PER; index++) {
    sum+=(DEexploitation/dve)*arrCO2FF[index]['co2'];
  }
  return sum
}
// Phase d'exploitation (fraction de temps restant après le dernier cycle)
const beta2p=(dve,DEfin,DEedif,DEproduction)=>{
  let sum=0;
  let alpha=Math.floor(PER/dve);
  if(alpha>1){
    for (let r = 1; r <= alpha-1; r++) {
      sum+=(arrCO2FF[dve*r]['co2'])
    }
  }
  return (DEfin+DEedif+DEproduction)*(sum+(futilDVElessPER(dve)*arrCO2FF[dve*alpha]['co2']));
}
// Phase totale d'exploitation
const IpeDVElessPER=(dve,quantity,DEfin,DEedif,DEproduction,DEexploitation)=>{
  return quantity*(beta1p(dve,DEexploitation)+beta2p(dve,DEfin,DEedif,DEproduction))
}
// Phase de fin de vie
const IpfDVElessPER=(quantity,DEfin)=>{
  return quantity*DEfin*arrCO2FF[PER]['co2']
}
// Module D
const IpmdDVElesserPER=(dve,quantity,DEm)=>{
  let alpha=Math.floor(PER/dve);
  let sum=0;
  for (let r = 1; r < alpha-1; r++) {
    sum+=(arrCO2FF[dve*r]['co2'])
  }
  sum+=(futilDVElessPER(dve)*arrCO2FF[dve*alpha]['co2'])+arrCO2FF[PER]['co2']
  return quantity*DEm*sum
}
/// ** fluides frigorigènes
/// Utilisation d'une PEP sans module B détaillé OU d'une DED ("declarationType": 3 OU "idPhase":14, 15, 16 et 17 vides)
// Module B1
const IfB1WithoutBCO2=(Texploitation,chI,PRG)=>{
  let sum=0;
  for (let a = 1; a <= PER; a++) {
    sum+=arrCO2FF[a]['ff']
  }
  return Texploitation*chI*PRG*sum;
}
// Module B4
const IfB4WithoutBCO2=(dve,Tproduction,Tfin,chI,PRG)=>{
  return (Tproduction+Tfin)*chI*PRG*gammaB4(dve);
}
// gammaB4 le facteur de pondération de changement de fluide
const gammaB4=(dve)=>{
  let sum=0;
  let alpha=Math.floor(PER/dve);
  for (let r = 1; r <= alpha-1; r++) {
    sum+=(arrCO2FF[dve*r]['ff']-arrCO2FF[dve*r]['co2'])
  }
  sum+=(futilDVElessPER(dve)*(arrCO2FF[dve*alpha]['ff']-arrCO2FF[dve*alpha]['co2']));
  return sum;
}
// Phase de fin de vie without B
const IffinWithoutBCO2=(Tfin,chI,PRG)=>{
  return Tfin*chI*PRG*(arrCO2FF[PER]['ff']-arrCO2FF[PER]['co2'])
}
/// Utilisation d'une PEP avec module B1 non vide ("idPhase":14 non vide)
// Module B1
const IfB1WithBCO2=(dve,DEB1)=>{
  let sum=0;
  for (let r = 1; r <= PER; r++) {
    sum+=(arrCO2FF[r]['ff']-arrCO2FF[r]['co2'])
  }
  return DEB1/dve*sum;
}
// Module B4
const IfB4WithBCO2=(dve,Tproduction,Tfin,Texploitation,DEB1)=>{
  return (Tproduction+Tfin)*(DEB1/(Texploitation*dve)*gammaB4(dve))
}
// Phase de fin de vie with B
const IffinWithBCO2=(dve,Tfin,DEB1,Texploitation)=>{
  return Tfin*DEB1/(Texploitation*dve)*(arrCO2FF[PER]['ff']-arrCO2FF[PER]['co2'])
}

/// Utilisation d'un lot forfaitaiire ("declarationType": 4)
// Phase d'exploitation
const gammaB4CO2=(dve)=>{
  let sum=0;
  let alpha=Math.floor(PER/dve);
  for (let r = 1; r <= alpha-1; r++) {
    sum+=arrCO2FF[dve*r]['ff']
  }
  sum+=(futilDVElessPER(dve)*arrCO2FF[dve*alpha]['ff']);
  return sum;
}
const IfB1FF=(Texploitation,chI,PRG)=>{
  let sum=0;
  for (let r = 1; r <= PER; r++) {
    sum+=arrCO2FF[r]['ff']
  }
  return Texploitation*chI*PRG*sum
}
const IfB4FF=(dve,Tproduction,Tfin,chI,PRG)=>{
  return (Tproduction+Tfin)*chI*PRG*gammaB4CO2(dve)
}
// Phase de fin de vie
const IffinFF=(Tfin,chI,PRG)=>{
  return Tfin*chI*PRG*arrCO2FF[PER]['ff']
}
const IfeWithBCO2=(dve,Texploitation,Tproduction,Tfin,DEB1)=>{
  return IfB1WithBCO2(dve,DEB1)+IfB4WithBCO2(dve,Tproduction,Tfin,Texploitation,DEB1);
}
const IfeWithoutBCO2=(dve,Texploitation,chI,PRG)=>{
  return IfB1WithoutBCO2(Texploitation,chI,PRG)+IfB4WithoutBCO2(dve,Tproduction,Tfin,chI,PRG);
}
const IfeFF=(dve,Texploitation,Tproduction,Tfin,chI,PRG)=>{
  return IfB1FF(Texploitation,chI,PRG)+IfB4FF(dve,Tproduction,Tfin,chI,PRG);
}
const calculatePostFluide=(quantity,chI,post,phases,cas)=>{
  // let phases=phaseToArray(post);
  // env variables
  let dve=post.dvt;
  let PRG=phases['7']
  let DEB1=phases['14'];
  let DEexploitation=phases['14']+phases['15']+phases['16']+phases['17']
  let DEfin=phases['25']||phases['5']
  let DEedif=phases['28']||(phases['2']+phases['3'])
  let DEproduction=phases['11']||phases['1']
  let DEm=phases['27']
  let sumFluidsExploitation=0;
  let sumFluidsFin=0,exploitation=0,fin=0,total=0,moduleD=0;
  // let cas=(post.declarationType==3||(!phases['11']&&!phases['12']&&!phases['13']&&!phases['14']))?"withoutBCO2":(phases['14']?"withBCO2":(post.declarationType==4?"FF":"noCase"))
  if(cas=="withoutBCO2"){
    sumFluidsExploitation=IfeWithoutBCO2(dve,Texploitation,chI,PRG)
    sumFluidsFin=IffinWithoutBCO2(Tfin,chI,PRG)
  }
  if(cas=="withBCO2"){
    sumFluidsExploitation=IfeWithBCO2(dve,Texploitation,Tproduction,Tfin,DEB1)
    sumFluidsFin=IffinWithBCO2(dve,Tfin,DEB1,Texploitation)
  }
  else if(cas=="FF"){
    sumFluidsExploitation=IfeFF(dve,Texploitation,Tproduction,Tfin,chI,PRG)
    sumFluidsFin=IffinFF(Tfin,chI,PRG)
  }
  exploitation=quantity*(sumFluidsExploitation+beta1p(dve,DEexploitation)+beta2p(dve,DEfin,DEedif,DEproduction))
  fin=quantity*((DEfin*arrCO2FF[PER]['co2'])+sumFluidsFin)
  moduleD=dve<PER?IpmdDVElesserPER(dve,quantity,DEm):IpmdDVEgreaterPER(quantity,DEm)
  total=(quantity*(DEedif+DEproduction))+exploitation+fin+moduleD
  return {TotalCycleDeVie:total}

  // return 
}

const phaseToArray=(post)=>{
  let phases=post.indicateurs.filter(indicator=>indicator.idIndicateur==19);
  let arr = new Array(30); for (let i=0; i<30; ++i) {
    arr[i]=0
  }
  phases=phases[0].phases;
  phases.forEach(element => {
    arr[element['idPhase']]=element['quantite'];
  });
  return arr;
}
export const calculate=(quantity,postInies,chI=1,postFluide)=>{
  // let postInies=testPosts.filter(post=>post.idFicheBase=='8601')
  let phases=phaseToArray(postFluide);
  if(postInies.dvt==0 && postInies.declarationType==4)postInies.dvt=50;
  // let cas=(postInies.declarationType==3||(!phases['11']&&!phases['12']&&!phases['13']&&!phases['14']))?"withoutBCO2":(phases['14']?"withBCO2":(postInies.declarationType==4?"FF":"noCase"))
  let cas=(postInies.declarationType==3 || ((postInies.idNorme==3 || postInies.idNorme==4) && !phases['14']))?"withoutBCO2":((phases['14'] && (postInies.declarationType==1||postInies.declarationType==2))?"withBCO2":(postInies.declarationType==4?"FF":"noCase"))
  if(postFluide.idNomenclature==848) return calculatePostFluide(quantity,chI,postFluide,phases,cas)
  // else if((postInies.idNomenclature==703 || postInies.idNomenclature==706 || postInies.idNomenclature==707 || postInies.idNomenclature==726) && cas=="withBCO2") return calculatePostFluide(quantity,chI,postInies,phases,cas)
  else return 
}
export const unitsCodes={
  1	: "dm²",
  2	: "m²",
  3	: "kg",
  4	: "m³",
  5	: "m",
  6	: "MJ",
  7	: "kWh",
  8	: "L",
  9	: "g",
  10:	"cm",
  11:	"ml",
  12:	"%",
  13:	"unité",
  14:	"kg CO2 eq.",
  15:	"kg SO2 eq.",
  16:	"kg CFC-11 eq.",
  17:	"kg C2H4 eq.",
  18:	"mg",
  19:	"mm³",
  20:	"g  eq. SO2",
  21:	"g C2H4 eq.",
  22:	"g/m²",
  23:	"kg Sb eq.",
  24:	"m3/UF",
  25:	"kg/m²",
  26:	"kWhep",
  27:	"-",
  28:	"m³ d'air",
  29:	"kg C2H4 eq.",
  30:	"kg (PO4)3- eq.",
  31:	"t",
  32:	"m³ d'eau",
  33:	"person réserve",
  34:	"kg Sb eq.",
  35:	"mole eq. H+",
  36:	"mole eq. N",
  37:	"km",
  38:	"mL",
  39:	"g.eq.CO2",
  40:	"g.eq.CFC-11",
  41:	"g.eq.P04 3-",
  42:	"g.eq.C2H4",
  43:	"g.eq.H+",
  44:	"dm³",
  45:	"an",
  46:	"kWc",
  47:	"t.km",
  48:	"daN",
  49:	"m3/h",
  50:	"mm",
  51:	"kg/m3",
  52:	"kW",
  53:	"kWc",
  54:	"db",
  55:	"K/W",
  56:	"W/M²K",
  57:	"cm²",
  58:	"mm²",
  59:	"Wc",
  60:	"étage",
  61:	"m2.K/W",
  62:	"kg/m",
  100:"Items*a",
  101:"Nm3",
  102:"EUR",
  103:"Item(s)",
  104:"kg*a",
  105:"kBq",
  106:"m²*a",
  107:"m3*a",
  108:"Items*km",
  109:"kgkm",
  110:"a",
  111:"qm",
  113:"h",
  114:"UBP",
  115:"m3*km",
}
