import { Injectable, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";
import { Helpers } from './helpers'

@Injectable()
export class ArtisanService {
    public token: string;

    constructor(private http: HttpClient) {
    }

    update(artisanId, data): Observable<boolean> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.patch('/api/v1/artisans/' + artisanId, body, options)
            .pipe(map((response: any) => {
                // login successful if there's a jwt token in the response
                let token = response && response.token;
                let userData = response && response.user;
                return true;
            }));
    }
    createStripeUser(artisanId, data): Observable<boolean> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/artisans/${artisanId}/stripe`, body, options)
            .pipe(map((response: any) => {
                // login successful if there's a jwt token in the response
                let token = response && response.token;
                let userData = response && response.user;
                return true;
            }));
    }
    updateUserInfo(userId, data): Observable<boolean> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.patch(`/api/v1/user/${userId}/artisan`, body, options)
            .pipe(map((response: any) => {
                // login successful if there's a jwt token in the response
                return response;
            }));
    }
    getAllArtisanProfile(page,limit,searchWord) : Observable<any>{
      let options = new Helpers().getHeader();
      let variables = '?page=' + page + '&limit=' + limit
      if(searchWord != "" ) variables += '&search=' + searchWord
        return this.http.get('/api/v1/artisans' + variables, options)
            .pipe(map((response: any) => {
                return response;
            }));
    }
    getArtisanProfile(artisanId) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get('/api/v1/artisans/' + artisanId, options)
            .pipe(map((response: any) => {
                this.setBoosterFormIsCompleted(response.data["boosterFormIsCompleted"]);
                return response.data;
            }));
    }
    getArtisanUrl(artisanId) : Observable<string>{
      let options = new Helpers().getHeader();
        return this.http.get('/api/v1/artisans/' + artisanId, options)
            .pipe(map((response: any) => {
                return response.data.bamUrl;
            }));
    }
    getArtisanProjects(artisanId) : Observable<any[]>{
      let options = new Helpers().getHeader();
        return this.http.get('/api/v1/artisans/' + artisanId + '/projects', options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    getArtisanReviews(artisanId) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get('/api/v1/artisans/' + artisanId + '/recommendations', options)
            .pipe(map((response: any) => {
                return response;
            }));
    }
    setArtisanCGU(artisanId) : Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/artisans/${artisanId}/update-cgu`,{}, options)
        .pipe(map((response: any) => {
          return response.data;
        }));
    }
    delete(artisanId): Observable<boolean> {
      let options = new Helpers().getHeader();
        return this.http.delete('/api/v1/artisans/' + artisanId, options)
            .pipe(map(() => {
                return true;
            }));
    }
    /// Siren Api
    searchEntreprise(creteria): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/siret-search/${creteria}`, options)
        .pipe(map((res) => {
          return res;
      }));
    }
    verifyIfPasswordIsTrue(userId,body) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/user/${userId}/comparePassword`,body, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    updateUserEmail(userId,body) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/user/${userId}/updateEmailByArtisan`,body, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    
    userChangePassword(userId,password): Observable<any> {
      let body = JSON.stringify({password: password})
      let options = new Helpers().getHeader();
        return this.http.post('/api/v1/user/'+userId, body, options)
            .pipe(map((response: any) => {
                let done = response
                return done;
            }));
    }
    updateDataAgency(artisanId, data): Observable<boolean> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.patch(`/api/v1/artisans/${artisanId}/update-artisan-agency`, body, options)
            .pipe(map((response: any) => {
                // login successful if there's a jwt token in the response
                return response;
            }));
    }

  public setBoosterFormIsCompleted(value){
    localStorage.setItem("boosterFormIsCompleted", value);
  }

}
