import { Component, OnInit, Input, ViewChild, ElementRef, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ArtisanTeam } from 'src/app/_interfaces/team.interface';
import { LotPricing } from 'src/app/_interfaces/team-lot-pricing.interface';
import { NgSharedModule } from 'src/app/all/ngshared.module';

import { TeamService } from 'src/app/_services/team.service';
import { DpgfPostesService, ErrorService, AuthService } from 'src/app/_services';

import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Subscription } from 'rxjs';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { DpgfModule } from 'src/app/dpgf/dpgf.module';
import { DpgfResultTabsNavbarComponent } from '../../dpgf-result-tabs-navbar/dpgf-result-tabs-navbar.component';
import { TeamModifierComponent } from '../team-modifier/team-modifier.component';
import { TeamMemberPricingComponent } from '../team-member-pricing/team-member-pricing.component';
import { environment } from 'src/environments/environment';

declare var $;

@Component({
  standalone:true,
  selector: 'app-team-compare',
  templateUrl: './team-compare.component.html',
  imports:[
    DpgfModule,
    DpgfResultTabsNavbarComponent,
    TeamModifierComponent,
    TeamMemberPricingComponent,
    NgSharedModule
  ],
  styleUrls: ['./team-compare.component.scss'],
  animations: [
    trigger('divStage', [
      state('normal', style({ display: 'block', opacity: 1 })),
      state('newhighlighted', style({ width: 0, 'min-width': 0, display: 'none', opacity: 0 })),
      transition('normal<=>newhighlighted', animate(100))
    ]),
    trigger('artisList', [
      state('hide', style({ display: 'none', width: 0 })),
      state('show', style({ width: '65%', display: 'block', opacity: '1' })),
      transition('hide=>show', animate(260))
    ])
  ]
})
export class TeamCompareComponent implements OnInit {
  public activeLot: string;
  public teams: ArtisanTeam[] = [];
  public modifiedTeam: ArtisanTeam = null;
  public pricings: LotPricing[] = [];
  public hideTitle: boolean;
  public lotPricingAvgs: any = {};
  public estimationsPerLot: any = {};
  public selectedTeam: any = {};
  public selectedTeamTitle: string = "";
  public loadingPage: boolean = true;
  public renamePopUp: boolean = false;
  public teamColors : string[] = ["#b8e994", "#3c6382", "#6a89cc"];
  public dpgfId:any = '';
  public artisansColorsMap: any = {};
  public colors = [
    "#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177" ,"#0d5ac1" ,
    "#f205e6" ,"#1c0365" ,"#14a9ad" ,"#4ca2f9" ,"#a4e43f" ,"#d298e2" ,"#6119d0",
    "#d2737d" ,"#c0a43c" ,"#f2510e" ,"#651be6" ,"#79806e" ,"#61da5e" ,"#cd2f00" ,
    "#9348af" ,"#01ac53" ,"#c5a4fb" ,"#996635","#b11573" ,"#4bb473" ,"#75d89e" ,
    "#2f3f94" ,"#2f7b99" ,"#da967d" ,"#34891f" ,"#b0d87b" ,"#ca4751" ,"#7e50a8" ,
    "#c4d647" ,"#e0eeb8" ,"#11dec1" ,"#289812" ,"#566ca0" ,"#ffdbe1" ,"#2f1179" ,
    "#935b6d" ,"#916988" ,"#513d98" ,"#aead3a", "#9e6d71", "#4b5bdc", "#0cd36d",
    "#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397", "#880977",
    "#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b",
    "#5be4f0", "#57c4d8", "#a4d17a", "#225b8", "#be608b", "#96b00c", "#088baf",
    "#f158bf", "#e145ba", "#ee91e3", "#05d371", "#5426e0", "#4834d0", "#802234",
    "#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941", "#41d158",
    "#fb21a3", "#51aed9", "#5bb32d", "#807fb", "#21538e", "#89d534", "#d36647",
    "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
    "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
    "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#21538e", "#89d534", "#d36647",
    "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
    "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
    "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#9cb64a", "#996c48", "#9ab9b7",
    "#06e052", "#e3a481", "#0eb621", "#fc458e", "#b2db15", "#aa226d", "#792ed8",
    "#73872a", "#520d3a", "#cefcb8", "#a5b3d9", "#7d1d85", "#c4fd57", "#f1ae16",
    "#8fe22a", "#ef6e3c", "#243eeb", "#1dc18", "#dd93fd", "#3f8473", "#e7dbce",
    "#421f79", "#7a3d93", "#635f6d", "#93f2d7", "#9b5c2a", "#15b9ee", "#0f5997",
    "#409188", "#911e20", "#1350ce", "#10e5b1", "#fff4d7", "#cb2582", "#ce00be",
    "#32d5d6", "#17232", "#608572", "#c79bc2", "#00f87c", "#77772a", "#6995ba",
    "#fc6b57", "#f07815", "#8fd883", "#060e27", "#96e591", "#21d52e", "#d00043",
    "#b47162", "#1ec227", "#4f0f6f", "#1d1d58", "#947002", "#bde052", "#e08c56",
    "#28fcfd", "#bb09b", "#36486a", "#d02e29", "#1ae6db", "#3e464c", "#a84a8f",
    "#911e7e", "#3f16d9", "#0f525f", "#ac7c0a", "#b4c086", "#c9d730", "#30cc49",
    "#3d6751", "#fb4c03", "#640fc1", "#62c03e", "#d3493a", "#88aa0b", "#406df9",
    "#615af0", "#4be47", "#2a3434", "#4a543f", "#79bca0", "#a8b8d4", "#00efd4",
    "#7ad236", "#7260d8", "#1deaa7", "#06f43a", "#823c59", "#e3d94c", "#dc1c06",
    "#f53b2a", "#b46238", "#2dfff6", "#a82b89", "#1a8011", "#436a9f", "#1a806a",
    "#4cf09d", "#c188a2", "#67eb4b", "#b308d3", "#fc7e41", "#af3101", "#ff065",
    "#71b1f4", "#a2f8a5", "#e23dd0", "#d3486d", "#00f7f9", "#474893", "#3cec35",
    "#1c65cb", "#5d1d0c", "#2d7d2a", "#ff3420", "#5cdd87", "#a259a4", "#e4ac44",
    "#1bede6", "#8798a4", "#d7790f", "#b2c24f", "#de73c2", "#d70a9c", "#25b67",
    "#88e9b8", "#c2b0e2", "#86e98f", "#ae90e2", "#1a806b", "#436a9e", "#0ec0ff",
    "#f812b3", "#b17fc9", "#8d6c2f", "#d3277a", "#2ca1ae", "#9685eb", "#8a96c6",
    "#dba2e6", "#76fc1b", "#608fa4", "#20f6ba", "#07d7f6", "#dce77a", "#77ecca"];
  public subscriptions:  Array<Subscription> = [];

  public dpgf: any = {};
  public pricingList: any[] = [];

  // Animations
  public artisListShown: boolean = false;

  constructor(
    private _teamService: TeamService,
    private route: ActivatedRoute,
    public dpgfPostesService: DpgfPostesService,
    private ErrorService: ErrorService,
    private authService: AuthService,
  ) {}

  public stateName(title) {
    return !this.modifiedTeam || this.modifiedTeam.title === title ? 'normal' : 'newhighlighted';
  }

  ngOnInit() {
    this.getData();
  }
  setArtisansColors() {
    let colorIndex: number = 0;
    this.pricingList.forEach((p, i) => {
      let factieArtisanTeam = p.ceProjectInvite?.facticeArtisanInfo?.name || p.artisanName;
      let artisanName = p.ceProjectInvite.isFactice ? factieArtisanTeam : p.artisan.name;

      if(!this.artisansColorsMap[artisanName])
        this.artisansColorsMap[artisanName] = this.colors[colorIndex++];
    });
  }
  openChangeTeam(team){
    if(this.authService.licence == "without"){
      $('#upgrade-modal').modal('show');
      return;
    }
    this.selectedTeam = team;
    this.renamePopUp = true
  }
  rename(){
    this.renamePopUp = false
    this._teamService.saveTeams(this.teams, this.dpgf.ceProject)
      .pipe(catchError(err => of("Saving failed")))
      .subscribe();
  }

  loadTeams() {
    this._teamService.getTeams(this.dpgf.ceProject, this.pricingList)
    .pipe(catchError(err =>  {console.log("error: ", err);
      return of(
        this.teams = [
          new ArtisanTeam("Hypothèse 1"),
        ]
      )}
    ))
    .subscribe((teams: ArtisanTeam[]) => {
      this.teams = teams;
    });

    this.pricings = [];
    this.pricingList.forEach(pricing => {
      let factieArtisanTeam = pricing.ceProjectInvite?.facticeArtisanInfo?.name || pricing.artisanName;
      let artisanName = pricing.ceProjectInvite.isFactice ? factieArtisanTeam : pricing.artisan.name;
      // let artisanName =  pricing.artisan.name;

      pricing.data.forEach((lot, i)=> {
        const l = new LotPricing(lot.id, pricing._id, artisanName, lot.total, pricing.dpgf);
        l.posts = this._teamService.getPosts(lot.sousLots);
        l.currentVersion = pricing.currentVersion;
        l.loti = i;
        this.pricings.push(l);
      });
    });
  }
  selectLot(id: string) {
    this.activeLot = id;
    const target = document.getElementById(id);

    const top = target.offsetTop;
    const x = top + 130;

    const modifier = document.getElementById("team-modifier");
    modifier.style.top = x + 'px';
  }
  getTeamLotPricing(i: number, lotId: string): LotPricing {
    let team = this.teams[i];
    return team ? team.getLotPricing(lotId) : null;
  }
  get selectArtisan() {
    if(!this.modifiedTeam)
      return null;

    const pricing = this.modifiedTeam.getLotPricing(this.activeLot);
    return pricing ? pricing.artisanName : null
  }
  onTeamEdit(team: ArtisanTeam) {
    if(this.authService.licence == "without"){
      $('#upgrade-modal').modal('show');
      return;
    }
    this.modifiedTeam = team;

    setTimeout(() => {
      this.hideTitle = true
      this.artisListShown = true;
    }, 300);
  }
  onTeamSave() {
    this.modifiedTeam = null;
    if(!environment.onPremise)window.analytics.track('update_hypothèse',{properties:{acValue: 'update_hypothèse'}});
    this.saveTeam();
    this.artisListShown = false;
    setTimeout(() => {
      this.hideTitle = false;
    }, 300);
  }
  saveTeam() {
    this._teamService.saveTeams(this.teams, this.dpgf.ceProject)
      .pipe(catchError(err => of("Saving failed")))
      .subscribe();
  }
  addPricing(pricing: LotPricing) {
    this.modifiedTeam.addPricing(pricing);
  }
  removePricing(pricing: LotPricing) {
    this.modifiedTeam.removePricing(pricing);
  }
  calculateLotPricingAvg() {
    const tempStorage = [];
    this.pricings.forEach(pricing => {
      const lotP = tempStorage.find(p => p.idLot === pricing.idLot);
      if(lotP) {
        lotP.sum += pricing.total;
        lotP.counter += 1;
      }
      else {
        tempStorage.push({
          sum: pricing.total,
          counter: 1,
          idLot: pricing.idLot
        });
        }
    });
    for(let i=0; i<tempStorage.length; i++) {
      this.lotPricingAvgs[tempStorage[i].idLot] =  tempStorage[i].sum / tempStorage[i].counter;
    }
  }
  setEstimations() {
    for(let i=0; i<this.dpgf.data.length; i++) {
      this.estimationsPerLot[this.dpgf.data[i].id] = this.dpgf.data[i].totalArchi;
    }
    this.loadingPage = false;
  }
  deleteTeam(title: string) {
    if(this.authService.licence == "without"){
      $('#upgrade-modal').modal('show');
      return;
    }
    this.teams = this.teams.filter(team => team.title !== title);
    this.saveTeam();
  }
  addTeam(name: string) {
    const t = this.teams.find(t => t.title === name);
    if(t)return;
    if(!environment.onPremise)window.analytics.track('create_hypothèse',{properties:{acValue: 'create_hypothèse'}});
    this.teams.push(new ArtisanTeam(name));
    this.saveTeam();
  }
  private getDPGF(){
    this.route.parent.params.forEach((params: Params) => {
      if (params['dpgfId']) {
        this.dpgfId = params['dpgfId'];
        this.subscriptions.push(
          this.dpgfPostesService.getDpgfWithPostes(this.dpgfId)
            .subscribe(
              data => {
                this.dpgf = data;
                this.getAllPricings();
              },
              error => {
                  console.log("error",error)
                  this.ErrorService.manageError(error,'/architect/edit/project/' + this.dpgfId);
              })
        )
      }
    })
  }
  public getAllPricings(){
    this.pricingList = [];
    this.subscriptions.push(
      this.dpgfPostesService.listDpgfPricing(this.dpgfId)
        .subscribe(
          data => {
            data.forEach((artisan)=>{
              artisan.total = artisan.data.reduce((accumulator, currentArtisan) => accumulator + currentArtisan.total,0)
            })
            this.pricingList = data.filter(el=>el.status == "finished").slice();

            this.setArtisansColors();

            this.loadTeams();
            this.calculateLotPricingAvg();
            this.setEstimations();
          },
          error => {
            console.log("error",error)
            // this.ErrorService.manageError(error,'/architect/edit/project/' + this.dpgfId);
          })
    )
  }

  public getData(){
    this.route.parent.params.forEach((params: Params) => {
      if (params['dpgfId']) {
        this.dpgfId = params['dpgfId'];
        this.subscriptions.push(
            this.dpgfPostesService.dpgfPricingListInfo(this.dpgfId, 'hypothese')
            .subscribe(
              data => {
                this.setCeProjectId(data['dpgf']['ceProject']);
                this.dpgf = data.dpgf;
                data.pricingList.forEach((artisan)=>{
                  artisan.total = artisan.data.reduce((accumulator, currentArtisan) => accumulator + currentArtisan.total,0)
                })
                // this.pricingList = data.pricingList.filter(el=>el.status == "finished").slice();
                this.pricingList = data.pricingList;

                this.setArtisansColors();
                this.loadTeams();
                this.calculateLotPricingAvg();
                this.setEstimations();
              },
              err => {
                console.log(err);
              }
            )
        );
      }
    });
  }
  public getPricingList(dpgfId, pricingId){
    this.subscriptions.push(
      this.dpgfPostesService.getPricingList(dpgfId, pricingId).subscribe(
        data => {
          this.selectedTeam = data;
        },
        error => {
          console.log(error);
          this.ErrorService.manageError(error,'/architect/edit/project/' + this.dpgfId);
        }
      )
    );
  }
  private setCeProjectId(projectId){
    this.dpgfPostesService.selectedProjectId.next(projectId);
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
