import { Component, EventEmitter, Input, OnInit, Output, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ArchiDbService } from 'src/app/_services';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

@Component({
    standalone:true,
    selector: 'app-archi-post-upload',
    imports:[NgSharedModule],
    providers: [MessageService],
    templateUrl: './archi-post-upload.component.html',
    styleUrls: ['./archi-post-upload.component.scss'],
})
export class ArchiPostUploadComponent implements OnInit {

  public csvHeaders: any[] = [];
  public csvRecords: any[] = [];
  public header: boolean = false;

  public uploadedCsv: boolean = false;
  public valuesIndices = {}
  public records:any[] = [];
  public submittingCSV: boolean = false;
  public matchingOption=[
    {label:'Titre du poste', value:"title"},
    {label:'Unité', value:"unity"},
    {label:'PU HT', value:"puht"},
    {label:'CCTP', value:"cctp"},
    {label:'Ne pas faire correspondre', value:"none"}
  ]
  public csv;
  public validateImportion: any = {
    title: false,
    puht: false,
    unity: false,
    cctp: false
  };
  @Input() lot;
  @Output() uploadedPosts = new EventEmitter();
  
  private subscriptions: Array<Subscription> = [];
  isPaste: boolean;
  matching: any[];
  rowsToAdd: any[];
  
  constructor(private ngxCsvParser: NgxCsvParser,
              private archiDbService: ArchiDbService,
              private messageService: MessageService,) { }

  ngOnInit(): void {
  }
  public fileChangeListener($event: any): void {
    const files = $event.srcElement.files;
    this.header = (this.header as unknown as string) === 'true' || this.header === true;
    this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {
        this.csvHeaders = result[0];
        this.csvRecords = result;
        this.csvRecords.shift();
        this.uploadedCsv = true;
        this.records = Array(result.length - 1);
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      });
  }
  public isFormValid(){
    return this.validateImportion['title'] && this.validateImportion['puht'] && this.validateImportion['unity'] && this.validateImportion['cctp']
  }
  public extractColumn(column) {
    return this.csvRecords.map(x => x[column])
  }
  public fieldSelected(option, field){
    let itemIndex = this.csvHeaders.indexOf(option);
    this.valuesIndices[field] = itemIndex;
    let items = this.extractColumn(itemIndex);
    items.forEach((item, index) => {
      let row = {}
      row[field] = item;
      this.records[index] = {...this.records[index], ...row};
    });
    this.validateImportion[field] = true;
  }
  public saveRecords(){
    this.submittingCSV=true;
    this.subscriptions.push(
      this.archiDbService.createMultiPost(this.lot._id,{posts:this.records,lot:this.lot}).subscribe(
        (data) => {
          setTimeout(()=>{
            this.uploadedPosts.emit();
            this.uploadedCsv=false;
            this.submittingCSV=false;

            this.messageService.add({ key: 'toast', severity: 'success', summary: 'Postes ajouté depuis Excel', detail: "Les postes ont bien été ajoutés", life: 5000 });
          },1100)
          // segment event
          if(!environment.onPremise)window.analytics.track('add_post_from_excel',{properties:{acValue: 'add_post_from_excel'}});
        },
        (err)=>{
          
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur d'ajout poste", detail: 'Erreur serveur', life: 5000 });
        })
    )
  }
  public pasteData(event){
    event.preventDefault()
    let rowsToAdd = [];
    let clipboardData = event.clipboardData ;
    let pastedText = clipboardData.getData("Text") || clipboardData.getData("text/plain");
    let rows = pastedText.split("\n");
    rows.forEach((row)=>{
      rowsToAdd.push(row.split("\t"))
    })
    let matching  = [];
    (rowsToAdd[0]||[]).forEach((el,index)=>{
      matching.push({value : el, key : (this.matchingOption[index]||{value : ""}).value})
    })
    this.matching=matching;
    this.rowsToAdd=rowsToAdd;
    this.rowsToAdd.shift();
    this.isPaste=true;
  }
  public saveRecordsPaste(){
    let posts=[];
    this.rowsToAdd.forEach((el,index)=>{
      let row={title:'',puht:'',unity:'',cctp:''};
      if(el.length>1){
        el.forEach((element,index) => {
          row[this.matching[index].key] = element;
        });
        posts.push(row)
      }
    })
    this.submittingCSV=true;
    this.subscriptions.push(
      this.archiDbService.createMultiPost(this.lot._id,{posts:posts,lot:this.lot}).subscribe(
        (data) => {
          setTimeout(()=>{
            this.uploadedPosts.emit();
            this.isPaste=false;
            this.submittingCSV=false;

            this.messageService.add({ key: 'toast', severity: 'success', summary: "Postes ajouté depuis Excel", detail: "Les postes ont bien été ajoutés", life: 5000 });            
          },1100)
          // segment event
          if(!environment.onPremise)window.analytics.track('add_post_from_excel',{properties:{acValue: 'add_post_from_excel'}});
        },
        (err)=>{
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur d'ajout poste", detail: "Erreur serveur", life: 5000 });
        })
    )
  }
  public cancelChoosing(){
    if(this.uploadedCsv){
      this.uploadedCsv = false;
      for(let key in this.validateImportion){
        this.validateImportion[key] = false;
      }
    }
    else if(this.isPaste) this.isPaste=false;
  }
  public changeListener(files: FileList){
    if(files && files.length > 0) {
       let file : File = files.item(0); 
         let reader: FileReader = new FileReader();
         reader.readAsText(file, 'ISO-8859-1');
         reader.onload = (e) => {
            let csv: string = reader.result as string;
            let rows=[]
            rows=csv.split('\n');
            rows.pop();
            rows.forEach((row,i)=>rows[i]=row.split(','))
            this.csvRecords = rows;
            this.csvHeaders = rows[0];
            this.csvHeaders.push('Ne pas faire correspondre')
            this.csvRecords.shift();
            this.uploadedCsv = true;
            this.records = Array(rows.length - 1);
         }
      }
  }
}
