<div class="col-md-12 table-lot" data-step="6" data-intro="Ceci est un Lot">
  <!--********************Lot Head Pill ***********************-->
    <div class="row">
      <div class="pill-col-lot">
        <!-- <button class="collapse-btn" data-toggle="collapse" [attr.data-target]="'#demo' + loti">
          <i class="fa fa-caret-right" aria-hidden="true"></i>
        </button> -->
        <button class="collapse-btn" (click)="goBacktoHome()">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <app-pill-dpgf #lotinputs
                        placeholder="Titre du lot"
                        [id]="lot.id"
                        [modify]="modify"
                        [isAutomateNumberIsChecked]="isAutomateNumberIsChecked"
                        [disable]="!modeList[mode].create"
                        [textContent]="lot.title"
                        [icon]="lot.icon "
                        [color]="lot.color"
                        [prefix]="lot.indexLot  ||loti"
                        [isLotTitle]="true"
                        (editingTitle)="updateEditingTitle($event)"
                        (changeText)="updateLot({title:$event.title,indexLot:$event.indexLot,icon:$event.icon||'generalites.png',gid :$event.gid})"
                        [attr.id]="'designation' + loti"></app-pill-dpgf>

      </div>
      <div class="total-col-lot1">
        <div class="row">
          <div class="col-md-6" style="font-weight: bold;">
            Prix Total HT
          </div>
          <div class="col-md-6 no-overflow">
            <app-unite-input-dpgf 
                                  id="total-ht"
                                  [disable]="(!modeList[mode].price && !estimate) || !lot.priceAll"
                                  [unit]="lot[modeList[mode].totalName]||0"
                                  placeHolder=""
                                  [style]="{'font-size' : '18px','font-weight': 700}"
                                  (focusedOut)="setLotTotalHt($event)"
                                  tooltip="Champs pour artisan"
                                  sign="€"
                                  hideLock="true"
                                  typeInput="number"></app-unite-input-dpgf>

          </div>
        </div>
        <!-- <div [ngClass]="{'hide-visible' : (!modeList[mode].price && !estimate)}">
          <input type="checkbox" style="margin-right:5px;" [checked]="lot.priceAll" (change)="updateLot({priceAll : !lot.priceAll})" />
          <span *ngIf="!lot.priceAll">Chiffrer ce lot dans sa globalité</span>
          <span *ngIf="lot.priceAll">Ce lot est chiffré dans sa globalité</span>
        </div> -->
      </div>
      <div class="delete-col-lot delete">
        <i *ngIf="!lot.priceAll" class="fa fa-lock" (click)="updateLot({priceAll : true})" [pTooltip]="(mode=='archi'? 'Estimer' :'Chiffrer') + ' ce lot dans sa globalité'" tooltipPosition="top"></i>
        <i *ngIf="lot.priceAll" class="fa fa-unlock" (click)="updateLot({priceAll : false})" [pTooltip]="'Ce lot est '+ (mode=='archi'? 'estimé' :'chiffré')  +' dans sa globalité'" tooltipPosition="top"></i>
      </div>
    </div>

  <!--********************Lot Description ***********************-->
  <div [id]="'demo'+loti" class="collapse in">
    <div class="text-lot row">
      <div class="col-md-12">
        <div *ngIf="modeList[mode].comment && lot.notConsulted">
          vous n'avez pas été consulté pour ce lot
        </div>
        <div class="placeholder-cctp" *ngIf="view == 'dpgf' && !modeList[mode].comment">
          <div>
            <span class="write-cctp"
                  (click)="openCctpModal($event,lot.description,lot.gid,undefined,undefined,undefined)"
                  [ngClass]="{'write-cctp-active' : (lot.description | isHtmlEmpty) }">
                  Généralités / prescriptions techniques
            </span>
          </div>
        </div>
        <div  *ngIf="view == 'cctp'" class="add-quill-styles">
          <div (click)="openCctpModal($event,lot.description,lot.gid,undefined,undefined,undefined)" class="cctp-field">
            <span *ngIf="(lot.description || '').length == 0" class="holder">Généralités / prescription techniques</span>
            <!-- <app-read-more [text]="lot.description"></app-read-more> -->
            <div [innerHTML]="lot.description"></div>
          </div>
        </div>
        <div  *ngIf=" modeList[mode].comment">
          <div class="cctp-field">
            <span *ngIf="(lot.description || '').length == 0" class="holder">Généralités / prescription techniques</span>
            <app-read-more [text]="lot.description"></app-read-more>
            <!-- <div [innerHTML]="lot.description"></div> -->
          </div>
        </div>
      </div>
    </div>

    <!--********************Sous Lot Pill ***********************-->
    <div>
      <div class="sous-lot row" [ngClass]="{'fixed-sous-lot' : sousLot.fixed}" *ngFor="let sousLot of lot.sousLots,let sousloti = index;trackBy:trackById" data-step="7" data-intro="Ceci est un Sous Lot">
      <div class="row" *ngIf="!sousLot.fixed">
        <div class="move-col">
          <div class="move" *ngIf="modeList[mode].moveLot">
            <i *ngIf="sousloti != 1" class="fa fa-arrow-up" aria-hidden="true" (click)="moveSousLotUp(sousloti)"></i>
            <i *ngIf="sousloti == 1" class="fa fa-arrow-up" aria-hidden="true"></i>
            <i class="fa fa-arrow-down" aria-hidden="true" (click)="moveSousLotDown(sousloti)"></i>
          </div>
        </div>
        <div class="total-col-lot">
          <div class="d-flex bd-highlight sous-lot-head">
            <div class="p-2 flex-fill bd-highlight padding-right-16 fit-content" >
              <app-pill-dpgf  #souslotinputs
                              placeholder="Titre de la section"
                              [disable]="!modeList[mode].create"
                              [textContent]="sousLot.title"
                              [color]="lot.secondColor"
                              [modify]="false"
                              [modifySousLotHead]="false"
                              [prefix]="lot.indexLot + '-' + sousloti"
                              (changeText)="updateSousLot(sousloti,$event.title)"
                              [attr.id]="'designation' + sousloti">
              </app-pill-dpgf>
            </div>
            <div class="flex" *ngIf="view =='dpgf' && mode == 'archi'">
            <div class="padding-right-20 padding-left-20" [pTooltip]="'Déplacer dans un autre lot'">
              <i *ngIf="!sousLotDelete[sousloti]" 
              class="fa fa-share cursor" 
              aria-hidden="true" 
              (click)="moveSection(sousloti,sousLot)"
              >
             </i>
            </div>
            <div class="padding-right-20 padding-left-20" [pTooltip]="'Dupliquer'">
              <i *ngIf="!sousLotDelete[sousloti]" 
              class="fa fa-files-o cursor" 
              aria-hidden="true"
              (click)="duplicateSection(sousLot)"
              >
              </i>
            </div>
            <div class="padding-right-20 padding-left-20" [pTooltip]="'Supprimer'">
              <i *ngIf="!sousLotDelete[sousloti]" 
              class="fa fa-trash cursor" 
              aria-hidden="true" 
              (click)="openConfirmDeleteSousLotModal(sousloti,sousLot.title)"
              >
            </i>
            </div>
          </div>
          <div class="p-2 flex-fill bd-highlight no-overflow flex" style="width:45.5%;justify-content: flex-end;" *ngIf="!lot.priceAll">
            <app-unite-input-dpgf 
                                  id="total-ht"
                                  [disable]="(!modeList[mode].price && !estimate) || !sousLot.priceAll "
                                  [unit]="sousLot[modeList[mode].totalName]"
                                  placeHolder=""
                                  [style]="{'font-size' : '18px','font-weight': 700}"
                                  (focusedOut)="setSousLotTotalHt($event,sousloti)"
                                  tooltip="Champs pour artisan"
                                  sign="€"
                                  hideLock="true"
                                  typeInput="number">
            </app-unite-input-dpgf>
          </div>
          <!-- <div class=" p-2  flex-fill bd-highlight" >       -->
            <!-- <div class="delete-col-lot delete" *ngIf="!lot.priceAll" style="margin-left: 10pxpx;">
              <i *ngIf="!sousLot.priceAll " 
                 class="fa fa-lock" 
                 (click)="updateSousLotState({priceAll : true, souslotIndex:sousloti })" 
                 [pTooltip]="(mode=='archi'? 'Estimer' :'Chiffrer') + 'la section dans sa globalité'" 
                 tooltipPosition="top">
              </i>
              <i *ngIf="sousLot.priceAll" 
                 class="fa fa-unlock" 
                 (click)="updateSousLotState({priceAll : false, souslotIndex:sousloti})" 
                 [pTooltip]="(mode=='archi'? 'Estimer' :'Chiffrer')  +'  poste par poste'" 
                 tooltipPosition="top">
              </i>
            </div> -->
          <!-- </div> -->
          
          <!-- <div class="delete-col p-2 flex-grow-1 flex-fill bd-highlight" *ngIf="modeList[mode].deleteSousLot" >             -->
            <!-- <i *ngIf="!sousLotDelete[sousloti]" 
               class="fa fa-trash" 
               aria-hidden="true" 
               (click)="sousLotDelete[sousloti] = true">
            </i> -->
            <!-- <div *ngIf="sousLotDelete[sousloti]">
              <button type="button" class="btn-outline-secondary" name="button" (click)="sousLotDelete[sousloti] = false">
                <i class="fa fa-ban" aria-hidden="true"></i>
              </button>
              <button type="button" class="btn-outline-danger" name="button" (click)="deleteSousLot(sousloti)">
                <i class="fa fa-check" aria-hidden="true"></i>
              </button>
            </div> -->
        <!-- </div> -->
        </div> 
      </div>
    </div>
    <div class="placeholder-cctp padding-left-50" *ngIf="view == 'dpgf' && !modeList[mode].comment">
      <div *ngIf="!sousLot.fixed">
        <span class="write-cctp"
              (click)="openCctpModal($event,sousLot.description,lot.gid,undefined,sousloti,undefined)"
              [ngClass]="{'write-cctp-active' : (sousLot.description | isHtmlEmpty) }">
              Généralités / prescriptions techniques
        </span>
      </div>
    </div>
    <div class="padding-left-50" *ngIf="view == 'cctp' && !modeList[mode].comment">
      <div *ngIf="!sousLot.fixed" class="cctp-field"  (click)="openCctpModal($event,sousLot.description,lot.gid,undefined,sousloti,undefined)" >
        <span *ngIf="(sousLot.description || '').length == 0" class="holder">
              Généralités / prescriptions techniques
        </span>
        <div [innerHTML]="sousLot.description"></div>
      </div>
    </div>
      <table class="marged-table">
        <!--********************Table***********************-->
        <tr *ngIf="sousLot.fixed">
          <td class="title">
              <span class="handle" style="position:absolute;left:14px">
                <input type="checkbox" [(ngModel)]="isAllPostesSelected" (click)="isAllPostesSelectedFunction()">
              </span>
          </td>
          <td class="title">Désignation du poste</td>
          <td class="center title" [class.hidev]="(view != 'dpgf')">Qté</td>
          <td class="center title" [class.hidev]="(view != 'dpgf')">U</td>
          <td class="center title" [class.hidev]="(view != 'dpgf')">TVA</td>
          <td class="center title unit-price-width" [class.hidev]="(view != 'dpgf')">PU HT</td>
          <td class="center title price-width" [class.hidev]="(view != 'dpgf')">Total HT</td>
          <td class="center title"></td>
        </tr>
        <!--  -->
        <tbody [dragula]="'POSTES'+lot.id" [dragulaModel]="sousLot.postes" (dragulaModelChange)="changePostesOrder(sousloti,$event)">
          <tr *ngFor="let post of sousLot.postes;let posti = index;trackBy:trackById">
            <td colspan="9">
              <table>
                <tbody>
                  <tr [id]="post.id" class="post-line" *ngIf="view == 'dpgf'" >
                    <td class="relative handle post-holder">
                      <!-- <div class="row-move" (click)="setPosteAttributs(sousloti,posti,{option : modeList[mode].option?(!post.option):post.option});" >
                        <i *ngIf="post.option" class="fa fa-flag handle" pTooltip="Ceci est une option" tooltipPosition="top" aria-hidden="true"></i>
                        <i *ngIf="!post.option" class="fa fa-flag-o handle" pTooltip="Option" tooltipPosition="top" aria-hidden="true"></i>
                      </div> -->
                      <span  class="handle post-num">
                          {{lineNumber(lot.indexLot,sousloti,posti)}}
                      </span>
                      <div class="post-checkbox-container">
                        <span class="handle" [ngClass]="post.selected ? 'post-checkbox-checked' : 'post-checkbox'">
                          <input type="checkbox" (click)="selectPost(sousloti,posti,post.selected)" [checked]="post.selected">
                        </span>
                      </div>
                      <i *ngIf="post.flaged && modeList[mode].showFlag" class="cstb flag-icon fa fa-flag"></i>
                      <img *ngIf="post.gid" src="assets/dpgf/cstb.jpg" class="cstb">
                      <!-- <img *ngIf="post.postType" src="assets/fa-database-img.png"  class="cstb" style="width: 23%;top: 31px;left: 3px"> -->
                    </td>
                    <td class="relative"  [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                      <!-- (focusout)="getUnite(loti,sousloti,posti)" -->
                      <!-- (changeText)="setPosteAttributs(sousloti,posti,$event)" -->
                      <!-- [disable]="!canEdit" -->
                      
                      <app-input-dpgf #unitInputs
                                      #inputs
                                      [ngStyle]="{'color': post.isAtisan?'red':'black'}"
                                      [disable]="!modeList[mode].create && !post.isAtisan"
                                      [textContent]="post.designation"
                                      (focusedOut)="setPosteAttributs(sousloti,posti,$event);"
                                      [oldValue]="post.oldDesignation"
                                      (focused)="selectElement(sousloti,posti,0);focusNext({num: $event.num, choose: false, sousloti : sousloti})"
                                      [mode]="mode"
                                      [prefix]="lineNumber(lot.indexLot,sousloti,posti)"
                                      [tags]="lot.title"
                                      [num]="incremented(posti==0&&sousloti==0?true:false)"
                                      [attr.id]="'designation' + 1000*posti+0"
                                      [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==0"></app-input-dpgf>
                    </td>
                    <td class="qte" [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                      <app-unite-input-dpgf #unitInputs
                      [num]="incremented()"
                      [unit]="post.qte"
                      [color]=" (mode=='artisan') && (post.qteArchi != post.qte) "
                      [placeHolder]="'Quantité'"
                      [disable]="!canEdit"
                      (focused)="selectElement(sousloti,posti,1)"
                      (focusedOut)="setPosteAmounts(sousloti,posti,{qte:$event})"
                      (sendNum)="focusNext({num : $event.num ,choose : $event.choose , sousloti :   sousloti})"
                      [typeInput]="'number'"
                      zeroDefault="NR"
                      tooltip="Champ bloqué"
                      (click)="checkIfNeedToAcceptFirst()"
                      [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==1"></app-unite-input-dpgf>
                    </td>
                    <td class="unite" [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                      <app-unite-input-dpgf #unitInputs
                      [num]="incremented()"
                      [unit]="post.unite"
                      [placeHolder]="'Unité'"
                      [disable]="!canEdit"
                      (focused)="selectElement(sousloti,posti,2)"
                      (focusedOut)="setPosteAttributs(sousloti,posti,{unite:$event})"
                      (sendNum)="focusNext({num : $event.num ,choose : $event.choose , sousloti :   sousloti})"
                      [typeInput]="'text'"
                      [autoSuggestOptions]="uniteSugg"
                      tooltip="Champ bloqué"
                      (click)="checkIfNeedToAcceptFirst()"
                      [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==2"></app-unite-input-dpgf>
                    </td>
                    <td class="tva" [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                      <!--  -->
                      <app-unite-input-dpgf #unitInputs
                      [num]="incremented()"
                      [unit]="post.tva"
                      [placeHolder]="'TVA'"
                      [disable]="!canEdit"
                      (focused)="selectElement(sousloti,posti,3)"
                      (focusedOut)="setPosteAmounts(sousloti,posti,{tva:$event})"
                      (sendNum)="focusNext({num : $event.num ,choose : $event.choose , sousloti :   sousloti})"
                      [typeInput]="post.tva == 'Mixte' ?'string':'number'"
                      [autoSuggestOptions]="tavSugg"
                      [sign]="post.tva == 'Mixte' ?'':'%'"
                      tooltip="Champ bloqué"
                      (click)="checkIfNeedToAcceptFirst()"
                      [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==3"></app-unite-input-dpgf>
                    </td>
                    <td class="price unit-price-width" id="unit-price" [ngClass]="{'red-back':modeList[mode].toFill && (post.unitePrice===null) && (post.price===null) && !lot.priceAll,'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                      <div class="center" *ngIf="lot.priceAll || sousLot.priceAll" pTooltip="Chiffré dans sa globalité"> - </div>
                      <app-unite-input-dpgf #unitInputs
                      [num]="incremented()"
                      [class.hide]="lot.priceAll || sousLot.priceAll"
                      [disable]="!canEdit || (!modeList[mode].price && !estimate) || lot.priceAll "
                      [unit]="post[modeList[mode].unitePriceName]"
                      placeHolder=""
                      [hideLock]="!canEdit"
                      (focused)="selectElement(sousloti,posti,4)"
                      (focusedOut)="setPosteAmounts(sousloti,posti,{value:$event,key : modeList[mode].unitePriceName})"
                      (sendNum)="focusNext({num : $event.num ,choose : $event.choose , sousloti :   sousloti})"
                      typeInput="number"
                      sign="€/u"
                      zeroDefault="NR"
                      tooltip="Champs pour artisan"
                      (click)="checkIfNeedToAcceptFirst()"
                      [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==4"></app-unite-input-dpgf>
                    </td>
                    <td class="price price-width" id="total-price" [ngClass]="{'red-back':modeList[mode].toFill && (post.price===null) && !lot.priceAll,'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                      <div class="center" *ngIf="lot.priceAll || sousLot.priceAll" pTooltip="Chiffré dans sa globalité"> - </div>
                      <app-unite-input-dpgf #unitInputs
                      [num]="incremented()"
                      [class.hide]="lot.priceAll || sousLot.priceAll"
                      [disable]="!canEdit || (!modeList[mode].price && !estimate) || lot.priceAll "
                      [unit]="post[modeList[mode].priceName]"
                      placeHolder=""
                      [hideLock]="!canEdit"
                      (focused)="selectElement(sousloti,posti,5)"
                      (focusedOut)="setPosteAmounts(sousloti,posti,{value:$event,key : modeList[mode].priceName})"
                      (sendNum)="focusNext({num : $event.num ,choose : $event.choose , sousloti :   sousloti})"
                      typeInput="number"
                      sign="€"
                      tooltip="Champs pour artisan"
                      [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==5"></app-unite-input-dpgf>
                    </td>
                    <td >
                      <div class="relative">
                        <div class="poste-menu" *ngIf="(modeList[mode].deletePost||post.isAtisan) ; else point">
                          <div class="menubtn" aria-hidden="true" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            <i class="fa fa-ellipsis-v" ></i>
                          </div>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                            <li class="click-it h-click" (click)="duplicatePoste(sousloti,posti)">
                              <i class="fa fa-files-o" aria-hidden="true"></i>
                              <span class="menu-text">Dupliquer</span>
                            </li>
                            <!-- <li class="click-it h-click" (click)="deletePoste(sousloti,posti)"> -->
                            <li class="click-it h-click" (click)="openConfirmDeletePostModal(sousloti,posti, post.designation)">
                              <i class="fa fa-trash" aria-hidden="true"></i>
                              <span class="menu-text">Supprimer</span>
                            </li>
                            <li *ngIf="modeList[mode].exportPost" class="click-it h-click" (click)="setPosteToAdd(post)">
                              <i class="fa fa-cloud-download" aria-hidden="true"></i>
                              <span class="menu-text">Exporter vers ma bibliothèque</span>
                            </li>
                            <li *ngIf="!post.flaged && modeList[mode].showFlag" class="click-it h-click" (click)="flagPost(sousloti,posti,post.flaged)">
                              <i class="fa fa-flag" aria-hidden="true"></i>
                              <span class="menu-text">Ajouter un indicateur</span>
                            </li>
                            <li *ngIf="post.flaged && modeList[mode].showFlag" class="click-it h-click" (click)="flagPost(sousloti,posti,post.flaged)">
                              <i class="fa fa-flag" aria-hidden="true"></i>
                              <span class="menu-text">Retirer l'indicateur</span>
                            </li>
                          </ul>
                        </div>
                        <ng-template #point>
                          .
                        </ng-template>
                      </div>
                    </td>
                  </tr>

                  <tr *ngIf="view == 'cctp'" [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                    <td class="relative handle">
                      <!-- <div class="row-move" (click)="setPosteAttributs(sousloti,posti,{option : modeList[mode].option?(!post.option):post.option});" >
                        <i *ngIf="post.option" class="fa fa-flag handle" pTooltip="Ceci est une option" tooltipPosition="top" aria-hidden="true"></i>
                        <i *ngIf="!post.option" class="fa fa-flag-o handle" pTooltip="Option" tooltipPosition="top" aria-hidden="true"></i>
                      </div> -->
                      <span class="handle">
                        {{lineNumber(lot.indexLot||loti,sousloti,posti)}}
                        <!-- {{lineNumberSousPoste(lot.indexLot||loti,sousloti,posti,sousPosteIndex)}} -->

                      </span>
                      <img *ngIf="post.gid" src="assets/dpgf/cstb.jpg" class="cstb">
                      <!-- <img *ngIf="post.postType" src="assets/fa-database-img.png" class="cstb" style="width: 23%;top: 31px;left: 3px;"> -->

                    </td>
                    <td class="relative auto-width" colspan="11" >
                      <!-- (focusout)="getUnite(loti,sousloti,posti)" -->
                      <!-- (changeText)="setPosteAttributs(sousloti,posti,$event)" -->
                      <app-input-dpgf #unitInputs
                                      #inputs
                                      [ngStyle]="{'color': post.isAtisan?'red':'black'}"
                                      [disable]="!modeList[mode].create && !post.isAtisan"
                                      [textContent]="post.designation"
                                      (focusedOut)="setPosteAttributs(sousloti,posti,$event);"
                                      [oldValue]="post.oldDesignation"
                                      (focused)="selectElement(sousloti,posti,0)"
                                      [prefix]="lineNumber(lot.indexLot,sousloti,posti)"
                                      [tags]="lot.title"
                                      [num]="incremented()"
                                      [attr.id]="'designation' + 1000*posti+0"
                                      [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==0"></app-input-dpgf>
                    </td>
                  </tr>

                  <tr [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}" style="height: 48px;position:relative">
                    <td></td>
                    <td [ngClass]="{'icons-holder': view == 'dpgf' && modeList[mode].cctp}">
                      <div *ngIf="view == 'dpgf' && modeList[mode].cctp" class="icon-div">
                        <img *ngIf="!(post.cctp | isHtmlEmpty)" [src]="'assets/dpgf/Icon_DPGF/' + icons['tech_empty']" class="icon-image pointer" (click)="openCctpModal($event,post.cctp,post.gid,undefined,sousloti,posti)" pTooltip='Description technique'/>
                        <img *ngIf="(post.cctp | isHtmlEmpty)" [src]="'assets/dpgf/Icon_DPGF/' + icons['tech_full']" class="icon-image pointer" (click)="openCctpModal($event,post.cctp,post.gid,undefined,sousloti,posti)" pTooltip='Description technique'/>
                        <img *ngIf="!post.postIniesCount" [src]="'assets/dpgf/Icon_DPGF/' + icons['enviro_empty']" class="enviro-icon" style="height: 21px;margin-right: 0;" (click)="openCctpModal($event,post.cctp,post.gid,undefined,sousloti,posti,'env')" pTooltip='Description environnementale'/>
                        <!-- <img  *ngIf="isRevitDesktop" [src]="postHasElements(post.id)?'assets/dpgf/Icon_DPGF/revit.png':''" class="icon-image pointer" (click)="showRevitElements(sousloti,posti,post.id,post.designation)" pTooltip='Elements Revit'/> -->
                        <img *ngIf="isRevitDesktop && !post.revitIcon" 
                              [src]="'assets/dpgf/Icon_DPGF/revit.png'" 
                              class="icon-image pointer" 
                              (click)="showRevitElements(sousloti, posti, post.id, post.designation)" 
                              pTooltip="Elements Revit" />
                        <img *ngIf="isRevitDesktop && post.revitIcon" 
                              [src]="'assets/dpgf/Icon_DPGF/revit1.png'" 
                              class="icon-image pointer" 
                              (click)="showRevitElements(sousloti, posti, post.id, post.designation)" 
                              pTooltip="Elements Revit" />

                        <img *ngIf="post.postIniesCount && !post.isIniesUncomplete" [src]="'assets/dpgf/Icon_DPGF/' + icons['enviro_full']" class="enviro-icon" (click)="openCctpModal($event,post.cctp,post.gid,undefined,sousloti,posti,'env')" pTooltip='Description environnementale'/>
                        <img *ngIf="post.postIniesCount && post.isIniesUncomplete" [src]="'assets/dpgf/Icon_DPGF/' + icons['enviro_partial_full']" class="enviro-icon" (click)="openCctpModal($event,post.cctp,post.gid,undefined,sousloti,posti,'env')" pTooltip='Description environnementale'/>
                        <img [src]="'assets/dpgf/Icon_DPGF/' + icons['sous-poste']" class="icon-image pointer" style="margin-right: 18px;margin-left: 12px;" pTooltip='Ajouter un sous poste' (click)="addSousPoste(sousloti, false,lot.title,posti)"/>
                        <!-- <i class="fa fa-brands fa-product-hunt product-icon" (click)="openProductModal(post.id)"></i> -->

                        
                        <!-- <i class="fa fa-leaf clickable" (click)="openCctpMouterodal($event,post.cctp,post.gid,undefined,sousloti,posti,'env')" [ngStyle]="{'color': post.postIniesCount?'green':'gray'}"></i>
                        <i *ngIf="!(post.cctp | isHtmlEmpty)" class="fa fa-file-text-o last-icon icon clickable" (click)="openCctpModal($event,post.cctp,post.gid,undefined,sousloti,posti)"></i>
                        <i *ngIf="(post.cctp | isHtmlEmpty)" class="fa fa-file-text last-icon clickable" (click)="openCctpModal($event,post.cctp,post.gid,undefined,sousloti,posti)"></i> -->
                      </div>
                      <div class="padding-top-6" [ngClass]="isChiffrage?'localisation-container-chiffrage':'localisation-container'">
                        <span class="margin-right-12">Localisation : </span>
                        <div *ngFor="let item of parseLocalisation(post);let j=index" class="chip margin-right-12 one-localisation">
                          <!-- <p (click)="updateLocalisation(posti,j)">{{item}}</p> -->
                          <app-unite-input-dpgf
                          [id]="'post'+posti+'chip'+j"
                          [disable]="!modeList[mode].create && !post.isAtisan"
                          [unit]="item"
                          [placeHolder]="'Localisation'"
                          [isLocalisation]="true"
                          (focused)="selectElement(sousloti,posti,6)"
                          (focusedOut)="updateLocalisation(sousloti,posti,post,j,$event)"
                          typeInput="text"
                          [autoSuggestOptions]="localisations"
                          tooltip="Champ bloqué"
                          [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==6"></app-unite-input-dpgf>
                          <span *ngIf="!(!modeList[mode].create && !post.isAtisan)" class="closebtn x-icon" (click)='deleteLocalisation(sousloti,posti,post,j)'>&times;</span>
                        </div>
                        <div *ngIf="!(!modeList[mode].create && !post.isAtisan)" class="chip margin-right-12 pointer plus-icon" (click)="addLocalisation(sousloti,posti,post,$event)">
                          +
                        </div>                      
                      </div>
                      <!-- <div *ngIf="view == 'dpgf' && modeList[mode].cctp" class="cctp-div">
                        <div (click)="openCctpModal($event,post.cctp,post.gid,undefined,sousloti,posti)" style="cursor:pointer;">
                          <span class="write-cctp-post"
                                [ngClass]="{'write-cctp-active' : (post.cctp | isHtmlEmpty)}">
                                Description de l’ouvrage
                          </span>
                        </div>
                      </div> -->
                    </td>
                      <td *ngIf="modeList[mode].price && !post.isAtisan" [ngClass]="{'hiddeit' : !showQuantity }" class="qte old-value" pTooltip="Estimatif MOE/MOA" tooltipPosition="top">
                          {{post.qteArchi || "-"}} <i class="fa fa-lock" aria-hidden="true"></i>
                      </td>
                      <td *ngIf="modeList[mode].price && !post.isAtisan" class="unite old-value" pTooltip="Estimatif MOE/MOA" tooltipPosition="top">
                          {{post.uniteArchi || "-"}} <i class="fa fa-lock" aria-hidden="true"></i>
                      </td>
                      <td *ngIf="modeList[mode].price && !post.isAtisan" class="tva old-value" pTooltip="Estimatif MOE/MOA" tooltipPosition="top">
                          {{post.tvaArchi || "-"}} % <i class="fa fa-lock" aria-hidden="true"></i>
                      </td>
                      <td class="price" id="unit-price" >

                      </td>
                      <td class="price" id="total-price">

                      </td>

                  </tr>
                  <p-dialog header="Information de produit" [(visible)]="openedProductsForms[post.id]" [style]="{ width: '40%'}" [contentStyle]="{ 'background-color': '#f8f9f9' }" class="popup">
                    <div class="flex">
                      <div class="flex product-link-container">
                          <span for="url-site" class="link-label">Url : </span>
                          <input type="text" id="url-site" name="url-site" [(ngModel)]="productUrl"/>
                      </div>
                      <app-spinner *ngIf="isLoading"></app-spinner>
                      <button *ngIf="!isLoading" class="btn-product" (click)="getSitewebData({sousLoti: sousloti, postId: post.id, posti, postProducts: post.products })">Validate</button>
                      <button class="btn-product" (click)="ajouterManuel({sousLoti: sousloti, postId: post.id, posti, postProducts: post.products })">Ajouter Manuel</button>
                    </div>
                </p-dialog>
                  <tr *ngIf="(modeList[mode].comment || mode =='view') && (post.cctp || '').length != 0" [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                    <td></td>
                    <td colspan="9" >
                      <div class="open-cctp" >
                        <span *ngIf="!showCCtp[post.id]" (click)="showCCtp[post.id]=!showCCtp[post.id]"> <i class="fa fa-chevron-right" aria-hidden="true"></i> Afficher les clauses techniques (CCTP)</span>
                        <span *ngIf="showCCtp[post.id]" (click)="showCCtp[post.id]=!showCCtp[post.id]"> <i class="fa fa-chevron-down" aria-hidden="true"></i> Masquer les clauses techniques(CCTP)</span>
                      </div>
                      <div *ngIf="showCCtp[post.id]" [innerHTML]="sanitizeHtml(post.cctp)"></div>
                    </td>
                  </tr>
                  <!-- <tr *ngIf="view == 'dpgf' && modeList[mode].cctp" >
                    <td></td>
                    <td colspan="9" >
                      <div (click)="openCctpModal($event,post.cctp,loti,sousloti,posti)" style="cursor:pointer;">
                        <span class="write-cctp"
                              [ngClass]="{'write-cctp-active' : ((post.cctp||'').length != 0)}">
                              Description de l’ouvrage
                        </span>
                      </div>
                    </td>
                  </tr> -->
                  <tr *ngIf="view == 'cctp'" [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                    <td></td>
                    <td colspan="9" >
                      <div (click)="openCctpModal($event,post.cctp,post.gid,undefined,sousloti,posti)" class="cctp-field">
                        <span *ngIf="(post.cctp || '').length == 0" class="holder">Description de l'ouvrage</span>
                        <div [innerHTML]="post.cctp"></div>
                        <!-- <app-read-more [text]="post.cctp"></app-read-more> -->
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="modeList[mode].comment" [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                    <td></td>
                    <td colspan="9" >
                      <section class="comment-section" >
                        <div *ngFor="let comment of post.comment;let i = index;trackBy:trackById" style="display:flex">
                          <i class="delete-comment fa fa-trash" aria-hidden="true" (click)="deleteComment(sousloti,posti,i)"></i>
                          <input class="comment-input" type="text" name="" value="" [ngModel]="comment.com"  (ngModelChange)="changeComment(sousloti,posti,i,$event)" placeholder="Saisir une annotation">               
                        </div>
                      </section>
                      <section class="add-comment">
                        <span (click)="addComment(sousloti,posti,'art')">Ajouter un commentaire +</span>
                      </section>
                    </td>
                  </tr>
                </tbody>
                <td  colspan="7">
                <!-- <td  colspan="7" [ngStyle]="{display:hideDiv?'':'none'}"> -->
                   <table class="marged-table">
                  <tbody [dragula]="'SOUSPOSTES'+lot.id+sousLot.id+posti" [dragulaModel]="post.sousPostes" (dragulaModelChange)="changeSousPostesOrder(sousloti,posti,$event)">
                  <tr *ngFor="let sousPoste of post?.sousPostes;let sousPosteIndex = index;trackBy:trackById" [id]="sousPoste.id"  >
                  <table class="second-table" style="margin-left: 32px;">
                    <tr>
                      <td class="title"></td>
                      <td class="center title" [class.hidev]="(view != 'dpgf')"></td>
                      <td class="center title" [class.hidev]="(view != 'dpgf')"></td>
                      <td class="center title" [class.hidev]="(view != 'dpgf')"></td>
                      <td class="center title unit-price-width" [class.hidev]="(view != 'dpgf')"></td>
                      <td class="center title price-width" [class.hidev]="(view != 'dpgf')"></td>
                      <td class="center title"></td>
                    </tr>
                    <tbody >
                      <tr [id]="sousPoste.id" class="post-line" *ngIf="view == 'dpgf'">
                          <td class="relative grab-sous-poste post-holder">
                            <span  class="grab-sous-poste post-num">
                              {{lineNumberSousPoste(lot.indexLot,sousloti,posti,sousPosteIndex)}}
                            </span>
                            
                            <i *ngIf="sousPoste.flaged && modeList[mode].showFlag" class="cstb flag-icon fa fa-flag"></i>
                            <img *ngIf="sousPoste.gid" src="assets/dpgf/cstb.jpg" class="cstb">
                            <!-- <img *ngIf="sousPoste.postType" src="assets/fa-database-img.png"  class="cstb" style="width: 23%;top: 31px;left: 3px"> -->
                          </td>
                          <td class="relative"  [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                            <!-- (focusout)="getUnite(loti,sousloti,sousPosteIndex)" -->
                            <!-- (changeText)="setPosteAttributs(sousloti,sousPosteIndex,$event)" -->
                            <app-input-dpgf #unitInputs  #inputs
                                            [ngStyle]="{'color': sousPoste.isAtisan?'red':'black'}"
                                            [disable]="!modeList[mode].create && !sousPoste.isAtisan"
                                            [textContent]="sousPoste.designation"
                                            (focusedOut)="setSousPosteAttributs(sousloti,posti,sousPosteIndex,$event);"
                                            [oldValue]="sousPoste.oldDesignation"
                                            (focused)="selectElementFromSousPostes(sousloti,posti,sousPosteIndex,0);focusNext({num:  $event.num, choose: false, sousloti : sousloti})"
                                            [mode]="mode"
                                            [prefix]="lineNumberSousPoste(loti,sousloti,posti,sousPosteIndex)"
                                            [tags]="lot.title"
                                            [num]="incremented()"
                                            [attr.id]="'designation'+ 1000*posti+(sousPosteIndex+1)*5+(sousPosteIndex+0)"
                                            [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti &&  currentSousPoste==sousPosteIndex && currentColumn==sousPosteIndex"></app-input-dpgf>
                          </td>
                          <td class="qte" [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                            <app-unite-input-dpgf #unitInputs
                            [num]="incremented()"
                            [unit]="sousPoste.qte"
                            [color]=" (mode=='artisan') && (sousPoste.qteArchi != sousPoste.qte) "
                            [placeHolder]="'Quantité'"
                            [disable]="!canEdit"
                            (focused)="selectElementFromSousPostes(sousloti,posti,sousPosteIndex,1)"
                            (focusedOut)="setSousPosteAmounts(sousloti,posti,sousPosteIndex,{qte:$event})"
                            (sendNum)="focusNext({num : $event.num,choose : $event.choose , sousloti :   sousloti})"
                            [typeInput]="'number'"
                            zeroDefault="NR"
                            tooltip="Champ bloqué"
                            (click)="checkIfNeedToAcceptFirst()"
                            [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==1 && currentSousPoste == sousPosteIndex"></app-unite-input-dpgf>
                          </td>
                          <td class="unite" [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                            <app-unite-input-dpgf #unitInputs
                            [num]="incremented()"
                            [unit]="sousPoste.unite"
                            [placeHolder]="'Unité'"
                            [disable]="!canEdit"
                            (focused)="selectElementFromSousPostes(sousloti,posti,sousPosteIndex,2)"
                            (focusedOut)="setSousPosteAttributs(sousloti,posti,sousPosteIndex,{unite:$event})"
                            (sendNum)="focusNext({num : $event.num ,choose : $event.choose , sousloti :   sousloti})"
                            [typeInput]="'text'"
                            [autoSuggestOptions]="uniteSugg"
                            tooltip="Champ bloqué"
                            (click)="checkIfNeedToAcceptFirst()"
                            [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==2 && currentSousPoste == sousPosteIndex"></app-unite-input-dpgf>
                          </td>
                          <td class="tva" [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                            <app-unite-input-dpgf #unitInputs
                            [num]="incremented()"
                            [unit]="sousPoste.tva"
                            [placeHolder]="'TVA'"
                            [disable]="!canEdit"
                            (focused)="selectElementFromSousPostes(sousloti,posti,sousPosteIndex,3)"
                            (focusedOut)="setSousPosteAmounts(sousloti,posti,sousPosteIndex,{tva:$event})"
                            (sendNum)="focusNext({num : $event.num ,choose : $event.choose , sousloti :   sousloti})"
                            [typeInput]="'number'"
                            [autoSuggestOptions]="tavSugg"
                            sign="%"
                            tooltip="Champ bloqué"
                            (click)="checkIfNeedToAcceptFirst()"
                            [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==3 && currentSousPoste == sousPosteIndex"></app-unite-input-dpgf>
                          </td>
                          <td class="price unit-price-width" id="unit-price" [ngClass]="{'red-back':modeList[mode].toFill && (sousPoste.unitePrice===null) && (sousPoste.price===null) && !lot.priceAll,'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                            <div class="center" *ngIf="lot.priceAll" pTooltip="Chiffré dans sa globalité"> - </div>
                            <app-unite-input-dpgf #unitInputs
                            [num]="incremented()"
                            [class.hide]="lot.priceAll"
                            [disable]="!canEdit || (!modeList[mode].price && !estimate) || lot.priceAll "
                            [unit]="sousPoste[modeList[mode].unitePriceName]"
                            placeHolder=""
                            [hideLock]="!canEdit"
                            (focused)="selectElementFromSousPostes(sousloti,posti,sousPosteIndex,4)"
                            (focusedOut)="setSousPosteAmounts(sousloti,posti,sousPosteIndex,{value:$event,key : modeList[mode].unitePriceName})"
                            (sendNum)="focusNext({num : $event.num ,choose : $event.choose , sousloti :   sousloti})"
                            typeInput="number"
                            sign="€/u"
                            zeroDefault="NR"
                            tooltip="Champs pour artisan"
                            (click)="checkIfNeedToAcceptFirst()"
                            [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==4 && currentSousPoste == sousPosteIndex"></app-unite-input-dpgf>
                          </td>
                          <td class="price price-width" id="total-price" [ngClass]="{'red-back':modeList[mode].toFill && (sousPoste.price===null) && !lot.priceAll,'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                            <div class="center" *ngIf="lot.priceAll" pTooltip="Chiffré dans sa globalité"> - </div>
                            <app-unite-input-dpgf #unitInputs
                            [num]="incremented()"
                            [class.hide]="lot.priceAll"
                            [disable]="!canEdit || (!modeList[mode].price && !estimate) || lot.priceAll "
                            [unit]="sousPoste[modeList[mode].priceName]"
                            placeHolder=""
                            [hideLock]="!canEdit"
                            (focused)="selectElementFromSousPostes(sousloti,posti,sousPosteIndex,5)"
                            (focusedOut)="setSousPosteAmounts(sousloti,posti,sousPosteIndex,{value:$event,key : modeList[mode].priceName})"
                            (sendNum)="focusNext({num : $event.num ,choose : $event.choose , sousloti :   sousloti})"
                            typeInput="number"
                            sign="€"
                            tooltip="Champs pour artisan"
                            [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==5 && currentSousPoste == sousPosteIndex"></app-unite-input-dpgf>
                          </td>
                          <td >
                            <div class="relative">
                              <div class="poste-menu" *ngIf="(modeList[mode].deletePost||sousPoste.isAtisan) ; else point">
                                <div class="menubtn" aria-hidden="true" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                  <i class="fa fa-ellipsis-v" ></i>
                                </div>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                                  <li class="click-it h-click" (click)="duplicateSousPoste(sousloti,posti,sousPosteIndex)">
                                    <i class="fa fa-files-o" aria-hidden="true"></i>
                                    <span class="menu-text">Dupliquer</span>
                                  </li>
                                  <!-- <li class="click-it h-click" (click)="deletePoste(sousloti,sousPosteIndex)"> -->
                                  <li class="click-it h-click" (click)="openConfirmDeleteSousPostModal(sousloti,posti, sousPoste.designation,sousPosteIndex)">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                    <span class="menu-text">Supprimer</span>
                                  </li>
                                  <li class="click-it h-click" (click)="convertSousPosteToPoste(sousloti,posti,sousPoste,sousPosteIndex)">
                                    <i class="fa fa-refresh" aria-hidden="true"></i>
                                    <span class="menu-text">Changer en Poste</span>
                                  </li>
                                  <li *ngIf="!sousPoste.flaged && modeList[mode].showFlag" class="click-it h-click" (click)="flagPost(sousloti,posti,sousPoste.flaged,sousPosteIndex)">
                                    <i class="fa fa-flag" aria-hidden="true"></i>
                                    <span class="menu-text">Ajouter un indicateur</span>
                                  </li>
                                  <li *ngIf="sousPoste.flaged && modeList[mode].showFlag" class="click-it h-click" (click)="flagPost(sousloti,posti,sousPoste.flaged,sousPosteIndex)">
                                    <i class="fa fa-flag" aria-hidden="true"></i>
                                    <span class="menu-text">Retirer l'indicateur</span>
                                  </li>
                                </ul>
                              </div>
                              <ng-template #point>
                                .
                              </ng-template>
                            </div>
                          </td>
                      </tr>
                      <tr *ngIf="view == 'cctp'" [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                        <td class="relative handle">
                          <!-- <div class="row-move" (click)="setPosteAttributs(sousloti,posti,{option : modeList[mode].option?(!post.option):post.option});" >
                            <i *ngIf="post.option" class="fa fa-flag handle" pTooltip="Ceci est une option" tooltipPosition="top" aria-hidden="true"></i>
                            <i *ngIf="!post.option" class="fa fa-flag-o handle" pTooltip="Option" tooltipPosition="top" aria-hidden="true"></i>
                          </div> -->
                          <span class="handle">
                            {{lineNumberSousPoste(lot.indexLot||loti,sousloti,posti,sousPosteIndex)}}

                          </span>
                          <img *ngIf="post.gid" src="assets/dpgf/cstb.jpg" class="cstb">
                          <!-- <img *ngIf="post.postType" src="assets/fa-database-img.png" class="cstb" style="width: 23%;top: 31px;left: 3px;"> -->
    
                        </td>
                        <td class="relative auto-width" colspan="11" >
                          <app-input-dpgf #unitInputs  #inputs
                                            [ngStyle]="{'color': sousPoste.isAtisan?'red':'black'}"
                                            [disable]="!modeList[mode].create && !sousPoste.isAtisan"
                                            [textContent]="sousPoste.designation"
                                            (focusedOut)="setSousPosteAttributs(sousloti,posti,sousPosteIndex,$event);"
                                            [oldValue]="sousPoste.oldDesignation"
                                            (focused)="selectElementFromSousPostes(sousloti,posti,sousPosteIndex,0);focusNext({num:  $event.num, choose: false, sousloti : sousloti})"
                                            [mode]="mode"
                                            [prefix]="lineNumberSousPoste(loti,sousloti,posti,sousPosteIndex)"
                                            [tags]="lot.title"
                                            [num]="incremented()"
                                            [attr.id]="'designation'+ 1000*posti+(sousPosteIndex+1)*5+(sousPosteIndex+0)"
                                            [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti &&  currentSousPoste==sousPosteIndex && currentColumn==sousPosteIndex"></app-input-dpgf>
                        </td>
                      </tr>
                      <tr [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}" style="height: 48px;position:relative">
                        <td></td>
                        <td [ngClass]="{'icons-holder': view == 'dpgf' && modeList[mode].cctp}">
                          <div *ngIf="view == 'dpgf' && modeList[mode].cctp" class="icon-div">
                            <img *ngIf="!(sousPoste.cctp | isHtmlEmpty)" [src]="'assets/dpgf/Icon_DPGF/' + icons['tech_empty']" class="icon-image pointer" (click)="openCctpModalForSousPostes($event,sousPoste.cctp,sousPoste.gid,undefined,sousloti,posti,sousPosteIndex)" pTooltip='Description technique'/>
                            <img *ngIf="(sousPoste.cctp | isHtmlEmpty)" [src]="'assets/dpgf/Icon_DPGF/' + icons['tech_full']" class="icon-image pointer" (click)="openCctpModalForSousPostes($event,sousPoste.cctp,sousPoste.gid,undefined,sousloti,posti,sousPosteIndex)" pTooltip='Description technique'/>
                            <img *ngIf="!sousPoste.postIniesCount" [src]="'assets/dpgf/Icon_DPGF/' + icons['enviro_empty']" class="enviro-icon" style="height: 21px;margin-right: 0;" (click)="openCctpModalForSousPostes($event,sousPoste.cctp,sousPoste.gid,undefined,sousloti,posti,sousPosteIndex,'env')" pTooltip='Description environnementale'/>
                            <img *ngIf="sousPoste.postIniesCount && !sousPoste.isIniesUncomplete" [src]="'assets/dpgf/Icon_DPGF/' + icons['enviro_full']" class="enviro-icon" (click)="openCctpModalForSousPostes($event,sousPoste.cctp,sousPoste.gid,undefined,sousloti,posti,sousPosteIndex,'env')" pTooltip='Description environnementale'/>
                            <img *ngIf="sousPoste.postIniesCount && sousPoste.isIniesUncomplete" [src]="'assets/dpgf/Icon_DPGF/' + icons['enviro_partial_full']" class="enviro-icon" (click)="openCctpModalForSousPostes($event,sousPoste.cctp,sousPoste.gid,undefined,sousloti,posti,sousPosteIndex,'env')" pTooltip='Description environnementale'/>
    
                            <!-- <i class="fa fa-leaf clickable" (click)="openCctpMouterodal($event,sousPoste.cctp,sousPoste.gid,undefined,sousloti,posti,'env')" [ngStyle]="{'color': post.postIniesCount?'green':'gray'}"></i>
                            <i *ngIf="!(sousPoste.cctp | isHtmlEmpty)" class="fa fa-file-text-o last-icon icon clickable" (click)="openCctpModalForSousPostes($event,sousPoste.cctp,sousPoste.gid,undefined,sousloti,posti,sousPosteIndex)"></i>
                            <i *ngIf="(sousPoste.cctp | isHtmlEmpty)" class="fa fa-file-text last-icon clickable" (click)="openCctpModalForSousPostes($event,sousPoste.cctp,sousPoste.gid,undefined,sousloti,posti,sousPosteIndex)"></i> -->
                          </div>
                          <div class="padding-top-6" [ngClass]="isChiffrage?'localisation-container-chiffrage':'localisation-container'">
                            <span class="margin-right-12">Localisation : </span>
                            <div *ngFor="let item of parseLocalisationSousPoste(sousPoste);let j=index" class="chip margin-right-12 one-localisation">
                              <!-- <p (click)="updateLocalisation(posti,j)">{{item}}</p> -->
                              <app-unite-input-dpgf 
                              [id]="'post'+posti+'chip'+j+'sousPost'+sousPosteIndex"
                              [disable]="!modeList[mode].create && !post.isAtisan"
                              [unit]="item"
                              [placeHolder]="'Localisation'"
                              [isLocalisation]="true"
                              (focused)="selectElementFromSousPostes(sousloti,posti,sousPosteIndex,6)"
                              (focusedOut)="updateSousPosteLocalisation(sousloti,posti,post,j,$event,sousPosteIndex)"
                              typeInput="text"
                              [autoSuggestOptions]="localisations"
                              tooltip="Champ bloqué"
                              [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==6 && currentSousPoste == sousPosteIndex"></app-unite-input-dpgf>
                              <span *ngIf="!(!modeList[mode].create && !post.isAtisan)" class="closebtn x-icon" (click)='deleteSousPosteLocalisation(sousloti,posti,sousPoste,j,sousPosteIndex)'>&times;</span>
                            </div>
                            <div *ngIf="!(!modeList[mode].create && !post.isAtisan)" class="chip margin-right-12 pointer plus-icon" (click)="addLocalisationToSousPost(sousloti,posti,post,$event,sousPoste,sousPosteIndex)">
                              +
                            </div>                      
                          </div>
                          <!-- <div *ngIf="view == 'dpgf' && modeList[mode].cctp" class="cctp-div">
                            <div (click)="openCctpModal($event,post.cctp,post.gid,undefined,sousloti,posti)" style="cursor:pointer;">
                              <span class="write-cctp-post"
                                    [ngClass]="{'write-cctp-active' : (post.cctp | isHtmlEmpty)}">
                                    Description de l’ouvrage
                              </span>
                            </div>
                          </div> -->
                        </td>
                          <td *ngIf="modeList[mode].price && !sousPoste.isAtisan" [ngClass]="{'hiddeit' : !showQuantity }" class="qte old-value" pTooltip="Estimatif MOE/MOA" tooltipPosition="top">
                              {{sousPoste.qteArchi || "-"}} <i class="fa fa-lock" aria-hidden="true"></i>
                          </td>
                          <td *ngIf="modeList[mode].price && !sousPoste.isAtisan" class="unite old-value" pTooltip="Estimatif MOE/MOA" tooltipPosition="top">
                              {{sousPoste.uniteArchi || "-"}} <i class="fa fa-lock" aria-hidden="true"></i>
                          </td>
                          <td *ngIf="modeList[mode].price && !sousPoste.isAtisan" class="tva old-value" pTooltip="Estimatif MOE/MOA" tooltipPosition="top">
                              {{sousPoste.tvaArchi || "-"}} % <i class="fa fa-lock" aria-hidden="true"></i>
                          </td>
                          <td class="price" id="unit-price" >
    
                          </td>
                          <td class="price" id="total-price">
    
                          </td>
    
                      </tr>
                      <tr *ngIf="(modeList[mode].comment || mode =='view') && (sousPoste.cctp || '').length != 0" [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                        <td></td>
                        <td colspan="9" >
                          <div class="open-cctp" >
                            <span *ngIf="!showCCtp[sousPoste.id]" (click)="showCCtp[sousPoste.id]=!showCCtp[sousPoste.id]"> <i class="fa fa-chevron-right" aria-hidden="true"></i> Afficher les clauses techniques (CCTP)</span>
                            <span *ngIf="showCCtp[sousPoste.id]" (click)="showCCtp[sousPoste.id]=!showCCtp[sousPoste.id]"> <i class="fa fa-chevron-down" aria-hidden="true"></i> Masquer les clauses techniques(CCTP)</span>
                          </div>
                          <div *ngIf="showCCtp[sousPoste.id]" [innerHTML]="sanitizeHtml(sousPoste.cctp)"></div>
                        </td>
                      </tr>
                      <tr *ngIf="view == 'cctp'" [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                        <td></td>
                        <td colspan="9" >
                          <div (click)="openCctpModalForSousPostes($event,sousPoste.cctp,post.gid,undefined,sousloti,posti,sousPosteIndex)" class="cctp-field add-quill-styles" style="width:93%">
                            <span *ngIf="(sousPoste.cctp || '').length == 0" class="holder">Description de l'ouvrage</span>
                            <div [innerHTML]="sousPoste.cctp"></div>
                            <!-- <app-read-more [text]="sousPoste.cctp"></app-read-more> -->
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="modeList[mode].comment" [ngClass]="{'disabled': modeList[mode].disabled && licence=='without' && projectLicence=='none'}">
                        <td></td>
                        <td colspan="9" >
                          <section class="comment-section" >
                            <div *ngFor="let commentSous of sousPoste?.comment;let i = index;trackBy:trackById" style="display:flex">
                              <i class="delete-comment fa fa-trash" aria-hidden="true" (click)="deleteComment(sousloti,posti,i,sousPosteIndex)"></i>
                              <input class="comment-input" type="text" name="" value="" [ngModel]="commentSous.com"  (ngModelChange)="changeComment(sousloti,posti,i,$event,sousPosteIndex)" placeholder="Saisir une annotation">
                            </div>
                          </section>
                          <!-- <section class="add-comment">
                            <span (click)="addComment(sousloti,posti,'art',sousPosteIndex)">Ajouter un commentaire +</span>
                          </section> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  </tr>
                  <div *ngIf="post?.products?.length">
                    <div [class.collapsible]="post.products.length" >
                        <div class="collapse-header" (click)="toggleCollapse(post.id)">
                          <p>
                            Produits ({{ post.products.length }})
                          </p>
                        </div>
                        <div *ngIf="collapseState[post.id]" class="collapse-content">
                            <div *ngFor="let product of post.products; let productIndex = index" class="product-body">
                                <app-input-dpgf
                                            [ngStyle]="{'color': post.isAtisan?'red':'black'}"
                                            [disable]="!modeList[mode].create && !post.isAtisan"
                                            [textContent]="product.title"
                                            (focusedOut)="setProductAttributs(sousloti,posti,productIndex,$event,post.products,'title');"
                                            (focused)="selectElementFromProducts(sousloti,posti,productIndex,0);"
                                            [isNotPrice]="false"
                                            [mode]="mode"
                                            [tags]="lot.title"
                                            [num]="incremented()"
                                            [attr.id]="'title'+ 1000*posti+(productIndex+1)*5+(productIndex+0)"
                                            [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti &&  currentProduct==productIndex && currentColumn==productIndex"></app-input-dpgf>
                                <app-input-dpgf
                                            [ngStyle]="{'color': post.isAtisan?'red':'black'}"
                                            [disable]="!modeList[mode].create && !post.isAtisan"
                                            [textContent]="product.description"
                                            (focusedOut)="setProductAttributs(sousloti,posti,productIndex,$event,post.products,'description');"
                                            (focused)="selectElementFromProducts(sousloti,posti,productIndex,0);"
                                            [isNotPrice]="false"
                                            [mode]="mode"
                                            [tags]="lot.title"
                                            [num]="incremented()"
                                            [attr.id]="'title'+ 1000*posti+(productIndex+1)*5+(productIndex+0)"
                                            [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti &&  currentProduct==productIndex && currentColumn==productIndex"></app-input-dpgf>
                                <!-- <app-input-dpgf
                                            [ngStyle]="{'color': post.isAtisan?'red':'black'}"
                                            [disable]="!modeList[mode].create && !post.isAtisan"
                                            [textContent]="product.price"
                                            [isNotPrice]="false"
                                            (focusedOut)="setProductAttributs(sousloti,posti,productIndex,$event,post.products,'price');"
                                            (focused)="selectElementFromProducts(sousloti,posti,productIndex,0);"
                                            [mode]="mode"
                                            [tags]="lot.title"
                                            [num]="incremented()"
                                            [attr.id]="'title'+ 1000*posti+(productIndex+1)*5+(productIndex+0)"
                                            [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti &&  currentSousPoste==productIndex && currentColumn==productIndex"></app-input-dpgf> -->
                                <app-unite-input-dpgf 
                                            [num]="incremented()"
                                            [class.hide]="lot.priceAll"
                                            [disable]="!canEdit || (!modeList[mode].price && !estimate) || lot.priceAll "
                                            [unit]="product.price"
                                            placeHolder=""
                                            [hideLock]="!canEdit"
                                            (focused)="selectElementFromProducts(sousloti,posti,productIndex,4)"
                                            (focusedOut)="setProductAttributs(sousloti,posti,productIndex,$event,post.products,'price');"
                                            (sendNum)="focusNext({num : $event.num ,choose : $event.choose , sousloti :   sousloti})"
                                            typeInput="number"
                                            sign="€/u"
                                            zeroDefault="NR"
                                            tooltip="Champs pour artisan"
                                            (click)="checkIfNeedToAcceptFirst()"
                                            [selected] = "currentLot==loti && currentSousLot==sousloti && currentPoste == posti && currentColumn==4 && currentProduct == productIndex"></app-unite-input-dpgf>
                                            <!-- <p>{{ product.price }}</p> -->
                                            <img [src]="product.image" alt="Product Image URL Not valid" class="img-product">
                                <!-- Add Font Awesome icon for deleting product -->
                                <i class="fa fa-trash delete-icon" (click)="deleteProduct(post.products, productIndex, sousloti, posti)"></i>
                            </div>
                        </div>
                    </div>
                  </div>
                </tbody>
                   </table>
                </td>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <!--********************Suggest Poste ***********************-->   
      <div *ngIf="!isChiffrage && cstbSuggestion.length>0 && sousloti == 0">
        <div class="bordertop-margins"></div>
        <div class="flex space-between" >
          <label class="title-size-paddingleft7px" *ngIf="changeButtonStateBasedOnLenghthPostes(sousLot.postes,loti)">Recommandés pour le lot</label>
          <span class="cursor padding-right-20 possition-relative-bottom-5-right-7"  (click)="changeStateSuggestion(loti)">
              <i *ngIf="!isSuggestionButtonVisible[loti]" class="fa fa-chevron-up"></i> 
              <i *ngIf="isSuggestionButtonVisible[loti]" class="fa fa-chevron-down"></i> 
            </span>
        </div>
        <div class="flex flex-wrap" *ngIf="isSuggestionButtonVisible[loti]">
          <div *ngFor="let suggestion of cstbSuggestion" class="one-sggestion" (click)="addSuggestionPoste(sousloti,suggestion)">
            <span class="center suggestion-style-size">{{suggestion.poste}}</span>
          </div>
        </div>
        <div class="bordertop-margins"></div>
      </div> 
      <!--********************Poste Ajouter***********************-->
      <div *ngIf="modeList[mode].addArtisanPost" class="add-poste pointing" (click)="addPoste(sousloti, true,lot.title)" data-step="8" data-intro="Vous pouvez ajouter des postes en cliquant ici">
        Ajouter un poste +
      </div>
      <div class="row margin-top-20">
        <div class="col-md-6 no-padding">
          <div *ngIf="modeList[mode].addPost" class="add-poste pointing" style="margin-top: 2px;" (click)="addPoste(sousloti,false,lot.title)" data-step="8" data-intro="Vous pouvez ajouter des postes en cliquant ici">
            Ajouter un poste +
          </div>
        </div>
        <div class="col-md-6 no-padding">
          <div *ngIf="modeList[mode].addPost" (click)="openArchiDb(sousloti)" class="add-poste pointing" style="margin-top: 2px;" data-step="8" data-intro="Vous pouvez ajouter des postes en cliquant ici" aria-hidden="true" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <i class="fa fa-cloud-download" aria-hidden="true"></i>
            Importer un Poste
          </div>
          <!-- <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
            <li class="click-it h-click" (click)="openCstb(sousloti)">
              <span class="menu-text">Ajouter depuis le <img height="18" src="assets/dpgf/cstb.jpg" ></span>
            </li>
            <li class="click-it h-click" (click)="openArchiDb(sousloti)">
              <span class="menu-text">Ajouter depuis ma bibli.</span>
            </li>
            <li (click)="$event.stopPropagation()">
              <span pTooltip="Cliquez sur le texte et collez depuis votre tableau excel." tooltipPosition="top" contentEditable="true" *ngIf="modeList[mode].addPost" (change)="$event.preventDefault()" class="past-excel" (paste)="pasteData($event,sousloti)">
                Coller depuis Excel
              </span>
            </li>
          </ul> -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 no-padding">
          <div *ngIf="modeList[mode].addSousLot && (sousLot.fixed || sousloti==lot.sousLots.length-1)" class="add-poste pointing" (click)="addSousLot()" data-step="8" data-intro="Vous pouvez ajouter une section en cliquant ici">
            Ajouter une section à ce lot +
          </div>
        </div>
      </div>
      <!--********************Sous Lot Ajouter***********************-->
      <!-- <div *ngIf="modeList[mode].addSousLot" class="add-sous-poste" (click)="addSousLot()" style="text-align: end;color: gray">
        Ajouter une section à ce lot +
      </div> -->
    </div>
    </div>


    <div class="lot-files" *ngIf="(lot.lotFiles||[]).length != 0">
      <div class="title">
        <button class="collapse-btn-file" data-toggle="collapse" [attr.data-target]="'#files' + loti">
          <i class="fa fa-caret-right" aria-hidden="true"></i>
          Documents
        </button>
        <!-- folders & files -->
        <span *ngIf="(lot.lotFiles||[]).length != 0" class="doc-notif">
          {{(lot.lotFiles||[]).length}}
          <i class="fa fa-paperclip" aria-hidden="true"></i>
        </span>
      </div>
      <div [id]="'files'+loti" class="collapse file-widget" >
        <app-upload-file placeholder="Ajouter un document"
                        [allFiles]="(lot.lotFiles||[])"
                        [disabled]="true"></app-upload-file>
      </div>
    </div>

  </div>

</div>

<div [id]="'paste-modal-' + loti" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h2 class="center">Merci de faire concorder les champs</h2>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Valeur</th>
              <th scope="col">Clé</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let match of matching">
              <td>
                <div class="value-data">
                  {{match.value}}
                </div>
              </td>
              <td>
                <p-dropdown [style]="{'border-bottom': '1px solid gray','border-top': '0px'}" [options]="matchingOption" [(ngModel)]="match.key"></p-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="center">
          <button type="button" class="btn-primary-color" (click)="pasteDataWithKeys()" name="button">
            Coller
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="modeList[mode].exportPost" class="modal fade modal-dpgf" id="import-post" >
  <div *ngIf="(countArchiDbPosts >= (authService.premuimFeatures[this.authService.licence]||{}).archiDbPostNb)" class="modal-dialog" style="margin-top: 30vh;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 *ngIf="licence!='without'" class="modal-title">Vous avez atteint la limite de stockage de votre bibliothèque </h4>
        <h4 *ngIf="licence=='without'" class="modal-title">Fin periode d'essai</h4>
      </div>
      <div class="modal-body">
        <p *ngIf="licence=='without'">
          Vous avez atteint la fin de votre periode d'essai, passez en licence PRO pour agmenter cette limite à 200 postes ou en licence INTEGRAL pour pouvoir créer un nombre de postes illimité !
        </p>
        <p *ngIf="licence=='base'">
          Vous avez atteint la limite de postes dans votre bibliothèque, passez en licence PRO pour agmenter cette limite à 200 postes ou en licence INTEGRAL pour pouvoir créer un nombre de postes illimité !
        </p>
        <p *ngIf="licence=='plus' || licence=='plus-trim' || licence=='plus-annuel'">
          Vous avez atteint la limite de postes de votre bibliothèque, passez en licence INTEGRAL pour pouvoir créer un nombre illimité de poste !
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="ag-btn-secondary-sm" data-dismiss="modal">Plus tard</button>
        <button (click)="redirectToPricingTable()" type="button" class="ag-btn-primary-sm" style="width: 16%;height: 40px;margin-left: 12px;">C'est parti !</button>
      </div>
    </div>
  </div>
  <div [hidden]="!(countArchiDbPosts < (authService.premuimFeatures[this.authService.licence]||{}).archiDbPostNb)" class="modal-dialog">
    <div class="modal-container" >
      <div class="row">
        <div class="col-md-11">
          <h3 class="modal-header">Exporter un poste vers ma bibliothèque</h3>
        </div>
        <a data-dismiss="modal" class="col-md-1 close"><i class="fa fa-times" aria-hidden="true"></i></a>
      </div>
      <app-archi-db-post [isDPGF]='true' [firstTime]="true" [post]="postArchiDb" [isDCE]="true" (currentLot)='sendToFather($event)' (countEvent)='getDbCount($event)'></app-archi-db-post>
    </div>
  </div>
</div>
<p-sidebar *ngIf="modeList[mode].exportPost" [(visible)]="displaySideDpgf" position="right" (onHide)="closeSideDb()" [style]="{'overflow-y': 'scroll','overflow-x':'hidden'}" styleClass="p-sidebar-lg">
  <div class="side-header">
    <div class="row">
      <h2 class="center">
        <i class="click-it fa fa-times" aria-hidden="true" (click)="displaySideDpgf=false"></i>
        Exporter vers DPGF lot
        <button type="button" class="btn-valdiate" name="button">
          Importer
        </button>
      </h2>
    </div>
  </div>
  <div class="cstb-content">
  </div>
</p-sidebar>
<div class="modal fade" id="dpgf-navigation">
  <div class="modal-dialog no-padding">
    <div class="modal-container" >
      <div class="row">
        <div class="col-md-11">
          <h3 class="modal-header">Déplacer ces postes vers</h3>
        </div>
        <a data-dismiss="modal" class="col-md-1 close"><i class="fa fa-times" aria-hidden="true"></i></a>
      </div>
      <div *ngIf="!movingPosts" class="lot-navigation-container">
          <div class="one-lot" *ngFor="let lotI of lots;let i = index" >
            <div [hidden]="lotI.id == lot.id">
  
              <i class="chevron glyphicon" 
                [ngClass]="!showListPosts[lotI.id] ? 'glyphicon-chevron-right' : 'glyphicon-chevron-down'"
                 (click)="showSousLots(lotI.id, i)" [pTooltip]="'Déplacer dans un autre sous lot'">
              </i>
              <i style="margin-right: 5px;" *ngIf="(lotI.icon||'') == ''" class="fa fa-file" aria-hidden="true"></i>
              <img style="margin-right: 5px; " *ngIf="(lotI.icon||'') != ''" class="handle grab-it" [src]="'assets/dpgf/Icon_DPGF/' +lotI.icon" alt="" height="15">
              <span class="lot-sommaire" (click)="sendPostToLot(lotI.id,lots[i].sousLots[0].id)">{{lotI.indexLot}}  -  {{lotI.title}}</span>
              <div *ngIf="showListPosts[lotI.id]"> 
                <div class="lot-sections form-check" 
                  *ngFor="let sousLot of lots[i].sousLots | slice:0 ; let j = index" 
                  (click)="sendPostToLot(lotI.id, sousLot.id)">
                  <label class="form-check-label" for="i" *ngIf="j!=0">
                    {{lotI.indexLot}}.{{j}}  - 
                    <span >{{sousLot.title}}</span> 
                    
                  </label>
                  </div>
              </div> 
  
            </div>
          </div>
        </div>
      <div *ngIf="movingPosts">
        <app-spinner ></app-spinner>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="move-section">
  <div class="modal-dialog no-padding">
    <div class="modal-container" >
      <div class="row">
        <div class="col-md-11">
          <h3 class="modal-header">Déplacer la section vers</h3>
        </div>
        <a data-dismiss="modal" class="col-md-1 close"><i class="fa fa-times" aria-hidden="true"></i></a>
      </div>
      <div *ngIf="!movingPosts" class="lot-navigation-container">
          <div class="one-lot" *ngFor="let lotI of lots;let i = index" >
            <div [hidden]="lotI.id == lot.id" class="cursor">
              <i style="margin-right: 5px;" *ngIf="(lotI.icon||'') == ''" class="fa fa-file" aria-hidden="true"></i>
              <img style="margin-right: 5px; " *ngIf="(lotI.icon||'') != ''" class="handle grab-it" [src]="'assets/dpgf/Icon_DPGF/' +lotI.icon" alt="" height="15">
              <span class="lot-sommaire" (click)="sendSectionToLot(lotI.id)">{{lotI.indexLot}}  -  {{lotI.title}}</span>
            </div>
          </div>
        </div>
      <div *ngIf="movingPosts">
        <app-spinner ></app-spinner>
      </div>
    </div>
  </div>
</div>
<!-- Max Dpgf & Library Posts Modal -->
<app-max-library-posts-modal [showLibraryText]="maxLibraryPostsModalshowLibraryText"></app-max-library-posts-modal>
<!-- END Max Dpgf & Library Posts Modal -->

<!-- Upgrade to Integral Modal -->
<app-upgrade-to-integral-modal></app-upgrade-to-integral-modal>
<!-- END Upgrade to Integral Modal -->

<!-- Need Subscription Modal -->
<app-user-on-without-license-modal
></app-user-on-without-license-modal>
<!-- END Need Subscription Modal -->

<!-- Confirm Delete Post -->
<app-delete-dce-post-modal [selectedPostToDelete]="selectedPostToDelete" [selectedSousPostToDelete]="selectedSousPostToDelete" (deletePoste)="deletePoste()" (deleteSousPoste)="deleteSousPoste()" (deleteSousLot)="deleteSousLot()"></app-delete-dce-post-modal>
<!-- END Confirm Delete Post -->
<app-delete-dce-post-modal  [selectedPostToDelete]="selectedPostToDelete" [selectedSousPostToDelete]="selectedSousPostToDelete" [selectedSousLotToDelete]="selectedSousLotToDelete" (deletePoste)="deletePoste()" (deleteSousPoste)="deleteSousPoste()" (deleteSousLot)="deleteSousLot()"></app-delete-dce-post-modal>
<app-post-to-revit *ngIf="revitButtonClicked" [dpgfId]="dpgfId" [lotId]="lotId" [lotI]="loti" [postI]="postI" [sousLotI]="sousLotI" [(posteId)]="selectedPosteIdForRevit" [posteTitle]="selectedPosteTitleForRevit"  (changeRevitIcon)="changeRevitIcon($event)" (changeOpenSliderPostToRevitValue)="changeOpenSliderPostToRevitValue($event)"></app-post-to-revit>
