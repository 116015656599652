import { Component, OnInit, Input, Output, EventEmitter, NO_ERRORS_SCHEMA, ChangeDetectorRef } from '@angular/core';
import { threadId } from 'worker_threads';
import { NgSharedModule } from '../../ngshared.module';
const _ = require('lodash');

@Component({
  standalone:true,
  selector: 'app-cstb-navigation-lot',
  imports:[NgSharedModule, CstbNavigationLotComponent],
  templateUrl: './cstb-navigation-lot.component.html',
  styleUrls: ['./cstb-navigation-lot.component.scss']
})
export class CstbNavigationLotComponent implements OnInit {

  constructor(private cd: ChangeDetectorRef,) { }

  @Input() childLot;
  @Input() manySelect:boolean;
  @Input() level;
  @Input() licence;
  @Input() showForPro;
  @Output() childSelectedEvent = new EventEmitter<any>();
  @Output() returnEvent = new EventEmitter<any>();
  public activeChild:any = {};
  public selectedPostes:any = [];

  ngOnInit(): void {
    if(this.childLot.children) {
      // this.childLot.children.forEach(child => child.open);
      this.childLot.children.forEach(child => this.openChildLot(child,true));
    }

    this.cd.detectChanges();
    
    if(this.childLot.title=="Clauses communes à tous les lots" || this.childLot.title=="Clauses générales pour Lot unique") this.showForPro=true;
  }
  openChildLot(node,state) {
    if (node.children && node.open) {
      if(!state)node.open=false;
      node.children.forEach((child) => {
        this.openChildLot(child,state);
        if(!state)child.open=false;
        this.activeChild = node;

      });
    }
  }
  public openChildren(i) {
    if(!this.childLot.children[i].open)this.openChildLot(this.activeChild,false)
    this.childLot.children[i].open = !this.childLot.children[i].open;
    this.activeChild = this.childLot.children[i];
  }

  public changeCheck(poste,i) {
    if(this.childLot.children[i].isSelected==undefined && _.get(poste,'children',null)==null) this.childLot.children[i].isSelected=true
    else if(_.get(poste,'children',null)==null) this.childLot.children[i].isSelected=!this.childLot.children[i].isSelected
    else this.openChildren(i);
    this.selectedPostes = this.childLot.children.filter((poste) => {
      delete poste.isPoste;
      delete poste.esPid;
      return poste.isSelected;
    });
    this.childSelectedEvent.emit(this.selectedPostes);
  }
  public selectOne(poste,i) {
    // console.log('poste',poste)
    if(_.get(poste,'children',null)==null) this.childSelectedEvent.emit([poste]);
    else this.openChildren(i);
  }

  public emitPostes(poste) {
    this.childSelectedEvent.emit(poste);
  }

  public returnToList() {
    this.returnEvent.emit();
  }

}
