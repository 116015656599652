import { createFeatureSelector, createSelector } from '@ngrx/store';
const dotProp = require('dot-prop-immutable');
export const getDpgfStore = createFeatureSelector('dpgf');

export const getDpgf = createSelector(
  getDpgfStore,
  (store:any) => store.dpgf
);
export const getDpgfPricingCeProjectInvite = createSelector(
  getDpgfStore,
  (store:any) => store.dpgf["ceProjectInvite"] || {}
);
export const getIsAllPostSeleted = createSelector(
  getDpgfStore,
  (store:any) => store.isAllPostesSelected || false
);
export const getceProjectId = createSelector(
  getDpgfStore,
  (store:any) => store.dpgf.ceProject
);
export const getButtonState = createSelector(
  getDpgfStore,
  (store:any) => store.buttonState
);
export const getAny = createSelector(
  getDpgfStore,
  (store:any,props) => store[props.name]
);
export const getDiscount = createSelector(
  getDpgfStore,
  (store:any,props) => store.dpgf[props.name]
);
export const getTotal = createSelector(
  getDpgfStore,
  (store:any,props) => store.dpgf.data.reduce((accumulator, lot) => accumulator + (lot[props.name]||0), 0)
);
export const getNbLinesArtisan = createSelector(
  getDpgfStore,
  (store:any,props) => store.dpgf.data.reduce((accumulator, lot) => accumulator + (lot.notConsulted ? 0 : (lot[props.name]||0)), 0)
);
export const getTotalTtcAfterDiscount = createSelector(
  getDpgfStore,
  (store:any) => {
    let totalHt = store.dpgf.data.reduce((accumulator, lot) => accumulator + (lot['total']||0), 0)
    let totalTtc = store.dpgf.data.reduce((accumulator, lot) => accumulator + (lot['totalttc']||0), 0)
    let totalHtDiscount = 0;
    if(store.dpgf.discount.discountUnite == '%'){   
      totalHtDiscount = store.dpgf.data.reduce((accumulator, lot) => 
      accumulator + ((lot['total']||0)-((lot['total']||0) * store.dpgf.discount.discountAmount)/100), 0)
      totalHtDiscount = store.dpgf.discount.discountAmount > 0 ? totalHtDiscount : 0;
    }else{
      totalHtDiscount = store.dpgf.data.reduce((accumulator, lot) => 
      accumulator + (lot['total']||0), 0)
      totalHtDiscount = store.dpgf.discount.discountAmount > 0 ? totalHtDiscount - store.dpgf.discount.discountAmount : 0;
    }
    let tauxTvaGlobal = totalTtc/totalHt;
    totalHtDiscount = store.dpgf.discount.discountAmount > 0 ? totalHtDiscount*tauxTvaGlobal : totalTtc;
    return totalHtDiscount;

  }
);
export const getTotalAfterDiscount = createSelector(
  getDpgfStore,
  (store:any,props) => {
  if(store.dpgf.discount.discountUnite == '%'){   
    let total = store.dpgf.data.reduce((accumulator, lot) => 
    accumulator + ((lot[props.name]||0)-((lot[props.name]||0) * store.dpgf.discount.discountAmount)/100), 0)
    // store=dotProp.set(store,`dpgf.${props.name}`,total)
    total = store.dpgf.discount.discountAmount > 0 ? total : 0;
    return total;
  }else{
    let total = store.dpgf.data.reduce((accumulator, lot) => 
    accumulator + (lot[props.name]||0), 0)
    // store=dotProp.set(store,`dpgf.${props.name}`,total)
    total = store.dpgf.discount.discountAmount > 0 ? total - store.dpgf.discount.discountAmount : 0;
    return total;
  }
  },
);
export const getLot = createSelector(
  getDpgfStore,
  (store:any) => store.lot
);

export const getFolder = createSelector(
  getDpgfStore,
  (store:any,props) => {
    let res = (dotProp.get(store, "dpgf." + props) || []);

    // if (props === "files") {
    //   res.sort((a, b) => a.name.localeCompare(b.name));
    // }

    // // Sort by type, folders first
    // res.sort((a, b) => {
    //   if (a.type === "folder" && b.type !== "folder") return -1;
    //   if (a.type !== "folder" && b.type === "folder") return 1;
    //   return 0;
    // });
    // Sort files by name if the prop is "files"
    res.sort((a, b) => {
      if (a.type === b.type && a.name) {
        return a.name.localeCompare(b.name);
      }
      return a.type === "folder" ? -1 : 1;
    });

    // Sort files inside folders by name
    res.forEach(item => {
      if (item.type === "folder" && item.contents) {
        item.contents.sort((a, b) => a.name.localeCompare(b.name));
      }
    });
    // Sort files inside folders by name
    res.forEach(item => {
      if (item.type === "folder" && item.children) {
        if(Array.isArray(item.children)){
          item.children.sort((a, b) => a.name.localeCompare(b.name));
        }
      }
    });

    return res;
  }
);
