<div class="container loading-page" *ngIf="loadinglicence">
  <div style="margin-top:70px;">
    <!-- <app-spinner></app-spinner> -->
  </div>
  <br><br>
</div>

<div class="row">
  <div *ngIf="!loadinglicence" class="flex">
    <p class="main-title">Facturation</p>
    <div class="right" *ngIf="hasSubscription">
      <span style="margin-right: 4px;">Taux de TVA:</span>
      <select class="input-demand scroller-1 cursor" [(ngModel)]="tax_rate_choosen" (change)="changeValueTaxe($event)" style="margin-right: 75px;">
        <option class="cursor" *ngFor="let tax_rate of taxes" [selected]="tax_rate_choosen==tax_rate.percentage">{{tax_rate.percentage}}</option>
      </select>
      <button *ngIf="!authService.currentEnvironment" class="ag-btn-secondary" (click)="resetCard()">Modifier les informations de facturation </button>
    </div>
    <div class="right" *ngIf="!hasSubscription">
      <span style="margin-right: 4px;">Taux de TVA:</span>
      <select class="input-demand scroller-1" [(ngModel)]="tax_rate_choosen" (change)="changeValueTaxe($event)" style="margin-right: 36px;">
        <option *ngFor="let tax_rate of taxes" [selected]="tax_rate_choosen==tax_rate.percentage">{{tax_rate.percentage}}</option>
      </select>
    </div>
  </div>
</div>

<div class="row">
  <div>
    <img *ngIf="!loading && invoices.length == 0" src="assets/rob/invoice.svg" style=" width: 40%;margin: auto;display: block;">
    <table class="table" *ngIf="!loading && invoices.length != 0" >
      <thead>
        <tr>
        <th class="t-head">Du</th>
        <th class="t-head">Au</th>
        <th class="t-head">Numéro de la facture</th>
        <th class="t-head">Montant</th>
        <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let invoice of invoices">
          <td>{{ invoice.period_start }}</td>
          <td>{{ invoice.period_end }}</td>
          <td>{{ invoice.number }}</td>
          <td>{{ (invoice.total/100) }}€</td>
          <td class="text-right">
            <a [href]="invoice.invoice_pdf" style="cursor: pointer;">
              <img  [pTooltip]="'Télécharger la facture'" [src]="'assets/archi/download.svg'">
            </a>
            <!-- <a [href]="invoice.invoice_pdf" class="btn pdf-btn"> <i class="fa fa-file-pdf-o"></i> </a> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
