import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, NgZone, ElementRef, ViewChild, NO_ERRORS_SCHEMA} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';

import { ArtisanService } from '../../_services'
import { MessageService } from 'primeng/api';
import {CorpsDetaData} from './corpsDetas'
import mainRegionsList from '../booster-profile-forms-components/booster-profile-precision-form/regionsList-list';

import { trigger,style,animate,transition,keyframes } from '@angular/animations';

//Maps
import { GoogleAutocompleteComponent } from 'src/app/all/google-autocomplete/google-autocomplete.component';

import { NgSharedModule } from "src/app/all/ngshared.module";

declare var $: any;

@Component({
  standalone:true,
  imports:[
    GoogleAutocompleteComponent,
    NgSharedModule
  ],
  providers: [MessageService],
  selector: 'app-artisan-singup-agency-info',
  templateUrl: './artisan-singup-agency-info.component.html',
  styleUrls: ['./artisan-singup-agency-info.component.scss'],
  animations: [
    trigger('error', [
      transition('* => true', [
        animate('2s', keyframes([
          style({ backgroundColor: 'transparent'}),
          style({ backgroundColor: 'rgba(255, 0, 0, 0.15)'}),
          style({ backgroundColor: 'transparent'})
        ]))
      ]),
    ])
  ],
  
})
export class ArtisanSingupAgencyInfoComponent implements OnInit {
  @Input() agency: any;
  @Output() agencyData: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("adresse")
  public searchElementRef: ElementRef;
  public fieldname: string = "text";
  public languages: {}[];
  public error: string='';
  public options: {};
  public place: {};
  public filesLoading: number[] = [];
  public subscriptionForm: UntypedFormGroup;
  public logoValide: Boolean = false;
  public isCreatingAccount: Boolean = false;
  public siretNum: any;
  public showSiretNumError: Boolean = false;
  public fillManually: Boolean = false;
  public siretLoading: Boolean = false;
  public siretAgencies: any = new Array(0);
  public agencyChosen: Boolean = false;
  public selectedAgency: any = {};
  public loading:Boolean = false;workAreaMain:Array<{}> = []
  public formErrors = {
    name: "",
    address: "",
    siret: "",
    creationYearAgency: "",
    tvaNumber: "",
  };
  private validationMessages = {};
  //Assurances
  public searchAssurances: {}[];
  public assurances: {}[] = [
    { id: "garantieQualibat", text: "Certification Qualibat",textEn: "Certification Qualibat"},
    { id: "garantieQualibatRGE", text: "Certification Qualibat RGE",textEn: "Certification Qualibat RGE"}
  ];

  constructor(private fb: UntypedFormBuilder,
              // private mapsAPILoader: MapsAPILoader,
              private router: Router,
              private artisanService: ArtisanService,
              private ngZone: NgZone,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.createForm();
    this.subscriptionForm.patchValue(this.agency || {});
  }
  public setEmail(mail){
    this.subscriptionForm.patchValue({emailAgency : mail})
  }
  //On change
  ngOnChanges(changes: SimpleChanges) {
    if (changes["agency"] && this.subscriptionForm) {
      this.subscriptionForm.patchValue(this.agency);
    }
  }
  //Form
  createForm() {
    this.subscriptionForm = this.fb.group({
      name: ["", [
          Validators.required, 
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/) 
        ]
      ],
      tvaNumber: "",
      address: ["", [
          Validators.required, 
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/) 
        ]
      ],
      siret: ["", [
          Validators.required, 
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/) 
        ]
      ],
      creationYearAgency: "",
    });
    this.subscriptionForm.valueChanges.subscribe(data =>
      this.onValueChanged(data)
    );
    this.onValueChanged();
    
  }
  getFormData() {
    return this.subscriptionForm.value;
  }

  getGoogleMapValueChange(event){
    let addressValue = event?.description ? event["description"] : event;

    this.patchAdress(addressValue);
  }
  //patch Adresse
  public patchAdress(val){
    let data = this.subscriptionForm.value;
    data.address = val;
    this.subscriptionForm.patchValue(data);
  }
  //Mark as touched
  public markFormAsTouched(): void {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = "";
      const control = form.get(field);
      control.markAsDirty();
      const messages = this.validationMessages[field];
      for (const key in control.errors) {
        this.formErrors[field] = "Ce champ est obligatoire";
      }
    }
  }
  //Search Function
  public search(e, dropdown, allDropdown, varName) {
    this[dropdown] = [];
    let selectedData = (this.subscriptionForm.value[varName] || []).map(
      e => e[this.fieldname]
    );
    for (let i = 0; i < this[allDropdown].length; i++) {
      let selectable = this[allDropdown][i];
      if (
        selectable[this.fieldname].toLowerCase().indexOf(e.query.toLowerCase()) != -1 &&
        selectedData.indexOf(selectable[this.fieldname]) == -1
      ) {
        this[dropdown].push(selectable);
      }
    }
  }
  public scrollTo(element, offset=0) {
    $(".form-section").animate(
      {
        scrollTop: $(element).offset().top - offset
      }
    );
  }
  public scrollToError(){
    let loop = 0;
    let keys = Object.keys(this.formErrors);
    let errorKey = ""
    while(loop < keys.length && this.formErrors[keys[loop]] =="" ){
      loop ++;
    }
    if (keys[loop]) {
      this.error = keys[loop]
      this.scrollTo(`#${keys[loop]}`, 40);
      setTimeout(()=>{this.error = ''},2000)
    }
  }
  //Validate Form
  isValid(scroll = true) {
    this.markFormAsTouched();
    if (scroll) {
      this.scrollToError()
    }
    return this.subscriptionForm.valid;
  }
  //Generate Error
  onValueChanged(data?: any) {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = "";
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = "Ce champ est obligatoire";
        }
      }
    }
  }
  public searchMyEntreprise(){
    this.showSiretNumError = false;
    if(!this.siretNum){
      this.showSiretNumError = true;
      return;
    }
    this.siretAgencies = [];
    this.siretLoading = true;
    let SELF = this;
    let isNumber = /^\d+$/.test(this.siretNum);
    /// to search text you need char_char_char
    if(!isNumber){
      // this.siretNum = this.siretNum.split(' ').join('_');
    }
    this.artisanService.searchEntreprise(this.siretNum).subscribe(
      res => {
        this.siretLoading = false;
        if(res.data.items){
          for(let i = 0; i < res.data.items.length; i++){
            if(res.data.items[i]){
              this.siretAgencies.push(res.data.items[i]);
            }
          }
        }else{
          this.siretAgencies.push(res.data);
        } 
        // let allCorpsDetap = this.groupCorpsDetaByNAFCode();
        // if(res.data.items){
        //   for(let i = 0; i < res.data.items.length; i++){
        //     if(res.data.items[i]){
        //       let agencyCorpsDeta = allCorpsDetap.find(item=>item[res.data.items[i].NAF_code]);
        //       if(agencyCorpsDeta){
        //         res.data.items[i].corpsDetat=agencyCorpsDeta[res.data.items[i].NAF_code];
        //       }
        //       this.siretAgencies.push(res.data.items[i]);
        //     }
        //   }
        // }else{
        //   let agencyCorpsDeta= allCorpsDetap.find(item=>item[res.data.NAF_code]);
        //   if(agencyCorpsDeta){
        //     res.data.corpsDetat=agencyCorpsDeta[res.data.NAF_code];
        //   }
        //   this.siretAgencies.push(res.data);
        // }
       
      },
      err => {
        this.messageService.add({ key: 'toast', severity: 'waner', summary: "SIRET ou SIRENE incorrect.", detail: "Ce numéro de SIRET ou SIRENE est inexistant.", life: 5000 });
        this.siretLoading = false;
        console.log(err);
      }
    );
  }
  public groupCorpsDetaByNAFCode(chosenCodeNaff){
    let allCorps = new CorpsDetaData();
    let corpDetat = [];
    allCorps.activitiesList.forEach(activity => {
      activity.sectionsLists.forEach(currentSection => {
        currentSection.list.forEach(currentList=>{
          if(currentList.NAF_code == chosenCodeNaff || currentList.NAF_code2 == chosenCodeNaff){
            corpDetat.push(`${activity.id}_${currentSection.id}_${currentList.value}`)
          }
        })
      });
    });
    this.selectedAgency.corpsDetat = corpDetat
  }
  public byPassSiretForm(){
    this.fillManually = true;
  }
  public fillMyEntreprise(agency){
    if(!agency) return;
    this.selectedAgency = agency;
    this.groupCorpsDetaByNAFCode(this.selectedAgency.NAF_code)
    this.workAreaMain = this.findDepartementByNum(agency.departement)
    this.patchAdress(agency.address);
    this.toggleFillManually();
    this.subscriptionForm.patchValue({
      name: agency.agencyName || '',
      tvaNumber: agency.tva || '',
      address: agency.address || '',
      siret: agency.siret || '',
      creationYearAgency: agency.immaYear || '',
    });
    this.agencyChosen = false;
  }
  public toggleFillManually(){
    this.fillManually = !this.fillManually;
  }
  public save(): void{
    this.isValid(false);
    if(this.subscriptionForm.valid){
      let data = {
        ...this.subscriptionForm.value,
        nom: this.selectedAgency.nom,
        prenom: this.selectedAgency.prenom,
        departement : this.selectedAgency.departement || '',
        corpsDetat : this.selectedAgency.corpsDetat || [],
        workAreaMain:this.workAreaMain
      }
      this.agencyData.emit(data);
    }
  }
  private findDepartementByNum(departNum) {
    for (let region of mainRegionsList) {
      for (let departement of region.departements) {
        if (departement.departNum === departNum) {
          return [{id:departement.id,text:departement.text}];
        }
      }
    }
    return []; // Return [] if no department is found with the given departNum
  }

}
