import { Component, OnInit, Output, EventEmitter,ChangeDetectorRef,ViewChild, HostListener, SimpleChanges, Input, NO_ERRORS_SCHEMA } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ArchitectService, AuthService, DpgfPostesService, relatedFichesService, ProductsService } from 'src/app/_services';
import { Subscription, Subject } from 'rxjs';
import {calculate, unitsCodes} from './calcul-frigo'
import { MessageService } from 'primeng/api';

import { ActivatedRoute, Params } from '@angular/router';
import { AcvWelcomeScreenComponent } from '../acv-welcome-screen/acv-welcome-screen.component';
import { TreeListComponent } from 'src/app/all/cstb/treeList/tree-list/tree-list.component';

import { NgSharedModule } from 'src/app/all/ngshared.module';
const only = require('only');
var _ = require('lodash');
declare var $:any;
@Component({
  standalone: true,
  
  selector: 'app-acv-project-composant-sidebar',
  imports: [
    NgSharedModule,
    AcvWelcomeScreenComponent,
    TreeListComponent
  ],
  providers: [],
  templateUrl: './acv-project-composant-sidebar.component.html',
  styleUrls: ['./acv-project-composant-sidebar.component.scss']
})
export class AcvProjectComposantSidebarComponent implements OnInit {
  @Output()closeTheCctp = new EventEmitter<any>();
  @Output()frirogeneResult = new EventEmitter<any>();
  @Output()infoPost = new EventEmitter<any>();
  @Output()addingPostIniesEvent = new EventEmitter<any>();
  @Output()openRemploiEvent = new EventEmitter<any>();
  @Output()ChangeViewEvent = new EventEmitter<any>();
  
  @Input() projectLicence:String="";
  @Input() selectedLot={lot:'VRD',father:'VRD'}
  @Input() componentView;
  @Input() cctpEditStore;
  @Input() currentContibuteur;
  @Input() selectedFriro;
  @Input() isDemo:Boolean = false;

  public cctpEdit;
  public view='importPosts';
  public subscriptions = [];
  public licence: string;
  //change
  public searchFileTerm='';
  public lotsACV:any[];
  public relatedFiles=[];
  public iniesResult=[];
  public iniesSuggResult=[];
  public selectedFiles=[];
  public checkedAllFiles: boolean;
  public searchPostsTermUpdate = new Subject<string>();
  public lotsPCE = [
    {
      name: "1- VRD",
       lots: ["1.1 Réseaux (extérieurs, jusqu'au domaine public)",
        "1.2 Stockage",
        "1.3 Aires de stationnement extérieures"]
    },
    {
      name: "2- Fondation et infrastructure",
      lots: ["2.1 Fondations",
        "2.2 Murs et structures enterrées ( escalier de cave, parking, …)",
        "2.3 Parcs de stationnement en superstructure à l'exception des garages des maisons individuelles ou accolées",
      ]
    },
    {
      name: "3- Maçonnerie et superstructure",
      lots: ["3.1 Eléments horizontaux - planchers, dalles, balcons",
        "3.2 Eléments horizontaux - poutres",
        "3.3 Eléments verticaux - façades",
        "3.4 Eléments verticaux - refends",
        "3.5 Eléments verticaux - poteaux",
        "3.6 Escaliers et rampes",
        "3.7 Eléments d'isolation",
        "3.8 Maçonneries diverses",]
    },
    {
      name: "4- Couverture - Etanchéité - Charpente - Zinguerie",
      lots: ["4.1 Toitures terrasse",
        "4.2 Toitures en pente",
        "4.3 Eléments techniques de toiture",]
    },
    {
      name: "5- Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures",
      lots: ["5.1 Cloisons et portes intérieures",
        "5.2 Doublages mur, matériaux de protection, isolants et membranes",
        "5.3 Plafonds suspendus",
        "5.4 Planchers surélevés",
        "5.5 Menuiseries, metalleries et quincalleries",]
    },
    {
      name: "6- Façades et menuiseries extérieures",
      lots: ["6.1 Revêtement, isolation et doublage extérieur",
        "6.2 Portes, fenêtres, fermetures, protections solaires",
        "6.3 Habillages et ossatures",]
    },
    {
      name: "7- Revêtements des sols, murs et plafonds - Chape - Peintures - Produits de décoration",
      lots: ["7.1 Revêtement des sols",
        "7.2 Revêtement des murs et plafonds",
        "7.3 Eléments de décoration et revêtements des menuiseries",]
    },
    {
      name: "8- CVC",
      lots: ["8.1 Equipements de production (chaud/froid) [hors cogénération]",
        "8.2 Systèmes de cogénération",
        "8.3 Systèmes d'émission",
        "8.4 Traitement de l'air et éléments de désenfumage",
        "8.5 Réseaux et conduits",
        "8.6 Stockage",
        "8.7 Fluides frigorigènes",]
    },
    {
      name: "9- Installations sanitaires",
      lots: ["9.1 Eléments sanitaires et robinetterie",
        "9.2 Canalisations, résaux et systèmes de traitement",]
    },
    {
      name: "10- Réseaux d'énergie (courant fort)",
      lots: ["10.1 Réseaux éléctriques",
        "10.2 Ensemble de dispositif pour la sécurité",
        "10.3 Eclairage intérieur",
        "10.4 Eclairage extérieur",
        "10.5 Equipements spéciaux",
        "10.6 Installations techniques",]
    },
    {
      name: "11- Réseaux de communication (courant faible)",
      lots: ["11.1 Réseaux éléctriques et de communications",
        "11.2 Réseaux et systèmes de contrôle et régulation",
        "11.3 Installations techniques et équipements spéciaux",]
    },
    {
      name: "12- Appareils élévateurs",
      lots: ["12 Appareils élévateurs",]
    },
    {
      name: "13- Equipements de production locale d'éléctricité",
      lots: ["13 Equipements de production locale d'éléctricité"]
    }
  ]
  public lotsPCECopy = [
    {
      name: "1- VRD",
       lots: ["1.1 Réseaux (extérieurs, jusqu'au domaine public)",
        "1.2 Stockage",
        "1.3 Aires de stationnement extérieures"]
    },
    {
      name: "2- Fondation et infrastructure",
      lots: ["2.1 Fondations",
        "2.2 Murs et structures enterrées ( escalier de cave, parking, …)",
        "2.3 Parcs de stationnement en superstructure à l'exception des garages des maisons individuelles ou accolées",
      ]
    },
    {
      name: "3- Maçonnerie et superstructure",
      lots: ["3.1 Eléments horizontaux - planchers, dalles, balcons",
        "3.2 Eléments horizontaux - poutres",
        "3.3 Eléments verticaux - façades",
        "3.4 Eléments verticaux - refends",
        "3.5 Eléments verticaux - poteaux",
        "3.6 Escaliers et rampes",
        "3.7 Eléments d'isolation",
        "3.8 Maçonneries diverses",]
    },
    {
      name: "4- Couverture - Etanchéité - Charpente - Zinguerie",
      lots: ["4.1 Toitures terrasse",
        "4.2 Toitures en pente",
        "4.3 Eléments techniques de toiture",]
    },
    {
      name: "5- Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures",
      lots: ["5.1 Cloisons et portes intérieures",
        "5.2 Doublages mur, matériaux de protection, isolants et membranes",
        "5.3 Plafonds suspendus",
        "5.4 Planchers surélevés",
        "5.5 Menuiseries, metalleries et quincalleries",]
    },
    {
      name: "6- Façades et menuiseries extérieures",
      lots: ["6.1 Revêtement, isolation et doublage extérieur",
        "6.2 Portes, fenêtres, fermetures, protections solaires",
        "6.3 Habillages et ossatures",]
    },
    {
      name: "7- Revêtements des sols, murs et plafonds - Chape - Peintures - Produits de décoration",
      lots: ["7.1 Revêtement des sols",
        "7.2 Revêtement des murs et plafonds",
        "7.3 Eléments de décoration et revêtements des menuiseries",]
    },
    {
      name: "8- CVC",
      lots: ["8.1 Equipements de production (chaud/froid) [hors cogénération]",
        "8.2 Systèmes de cogénération",
        "8.3 Systèmes d'émission",
        "8.4 Traitement de l'air et éléments de désenfumage",
        "8.5 Réseaux et conduits",
        "8.6 Stockage",
        "8.7 Fluides frigorigènes",]
    },
    {
      name: "9- Installations sanitaires",
      lots: ["9.1 Eléments sanitaires et robinetterie",
        "9.2 Canalisations, résaux et systèmes de traitement",]
    },
    {
      name: "10- Réseaux d'énergie (courant fort)",
      lots: ["10.1 Réseaux éléctriques",
        "10.2 Ensemble de dispositif pour la sécurité",
        "10.3 Eclairage intérieur",
        "10.4 Eclairage extérieur",
        "10.5 Equipements spéciaux",
        "10.6 Installations techniques",]
    },
    {
      name: "11- Réseaux de communication (courant faible)",
      lots: ["11.1 Réseaux éléctriques et de communications",
        "11.2 Réseaux et systèmes de contrôle et régulation",
        "11.3 Installations techniques et équipements spéciaux",]
    },
    {
      name: "12- Appareils élévateurs",
      lots: ["12 Appareils élévateurs",]
    },
    {
      name: "13- Equipements de production locale d'éléctricité",
      lots: ["13 Equipements de production locale d'éléctricité"]
    }
  ]
  public performanceUFArray=['Charge supportée',"Classe d'usage",'Classement Air Eau Vent',"Classement de certification UPEC de la résistante à l'usure et au poinçonnement"]
  public performanceUFArrayCopy=['Charge supportée',"Classe d'usage",'Classement Air Eau Vent',"Classement de certification UPEC de la résistante à l'usure et au poinçonnement"]
  public loadingPosts: boolean;
  public chosenPost;
  public iniesPostPage;
  public iniesCategories = [
    {title:"Produits de construction",
     id:"476",
     sousCategories:[
        {title:"Voirie / réseaux divers (y compris réseaux intérieurs) et aménagements extérieurs de la parcelle",icon:'voirie_et_reseaux_divers.png',id:1},
        {title:"Strucure / maçonnerie / gros oeuvre / charpente divers",icon:'charpente.png',id:2},
        {title:"Façades",icon:'facades.png',id:3},
        {title:"Couverture / étanchéité",icon:'couverture__etancheite.png',id:4},
        {title:"Menuiseries intérieures et extérieures / fermetures",icon:'menuiserie_exterieure.png',id:5},
        {title:"Isolation",icon:'maconnerie__grosuvre.png',id:6},
        {title:"Cloisonnement / plafonds-suspendus",icon:'cloisons__doublages__fauxplafonds.png',id:7},
        {title:"Revêtements des sols et murs / peintures / produits de décorations",icon:'revetement_de_sol.png',id:8},
        {title:"Produits de préparation et de mise en oeuvre",icon:'demolition__depose.png',id:9},
        {title:"Equipements sanitaires et salle d'eau",icon:'plomberie__sanitaire.png',id:446},
        {title:"Panneau sandwich agroalimentaire",icon:"Aglo-Panneau_sandwich_agroalimentaire.svg",id:860},
        {title:"Autre",icon:'generalites.png',id:478},
        {title:"Approche simplifiée",icon:"Aglo-Approche_simplifiee.svg",id:822},
      ]},
    {title:"Equipements électriques, électroniques et de génie climatique",
      id:"479",
      sousCategories:[
        {title:"Appareillage d'installation pour les réseaux d'énergie électrique et de communication (≤ 63 Ampères)",icon:"Aglo-Appareillage_installation_reseaux.svg",id:480},
        {title:"Fils et câbles",icon:"Aglo-Fils_cables.svg",id:481},
        {title:"Sécurité des personnes et contrôle d'accès",icon:'signaletique.png',id:482},
        {title:"Sécurité du bâtiment",icon:'securite_incendie.png',id:483},
        {title:"Equipements de génie climatique",icon:"Aglo-Equipements_genie_climatique.svg",id:484},
        {title:"Production locale d'énergie",icon:"Aglo-Production_locale_energie.svg",id:485},
        {title:"Matériel d'éclairage",icon:"Aglo-Eclairage.svg",id:773},
        {title:"Approche simplifiée",icon:"Aglo-Approche_simplifiee.svg",id:823},
        {title:"Autre",icon:'generalites.png',id:486},
      ]},
    {title:"Services",
      id:"827",
      sousCategories:[
        {title:"Eaux",icon:'assainissement__recuperationtraitement_des_eaux.png',id:828},
        {title:"Traitement des déchets",icon:"Aglo-Traitement_dechets.svg",id:849},
        {title:"Transports",icon:"transport.svg",id:852},
        {title:"Energies",icon:"Aglo-Energie.svg",id:831},
        // {title:"Fluides frigorigènes",icon:"Aglo-Fluides_frigorigenes.svg",id:848},
      ]},
  ];
  public unitsCodes=unitsCodes;
  public selectedCategory=[{nom:"Produits de construction",
  idCategory:"476",treeLevel:0},{nom:"Equipements électriques, électroniques et de génie climatique",
  idCategory:"479",treeLevel:0},{nom:"Services",
  idCategory:"827",treeLevel:0}]
  public loadingCategories: boolean;
  public filter = { productionPlace: { "Hors Europe": false, "Europe": false, "France": false }, performanceUF: 'Toutes', dateMiseEnLigne: "Toutes", organismeName: "", declarationType: [1, 2, 3, 4], idCategory: '', airRating: [] };
  public frigoPostParent;
  public organismeSugg=[]
  public numPostsCategory={};
  public loadingSearchPosts: boolean;
  public loadingSugg: boolean;
  dpgfId: Params;
  isSearchingKeyword: boolean;
  ceProjectId: any;
  showWelcomeScreen: any;
  constructor(
              private cd: ChangeDetectorRef,
              private messageService: MessageService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private relatedFichesService :relatedFichesService,
            ) { }

  @HostListener('document:click', ['$event.target']) onClick(targetElement) {
    $('.autocomplete-items1').css({'visibility': 'hidden'});
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.key == 'Enter')this.searchPostInies();
  }
  ngOnInit(): void {
    this.resetValues()
    this.lotsACV=[{name:'lotACV1'},{name:'lotACV2'}]
    this.licence=this.authService.licence;
    this.showWelcomeScreen= this.projectLicence!="none" || (this.authService.premuimFeatures[this.authService.licence]||{}).carbone||false;
    this.listenForPostsSearch();
    this.route.params.forEach((params: Params) => {
      if (params['ceProjectId']) {
        this.ceProjectId = params['ceProjectId'];
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.cctpEditStore){
    }
    if(changes.selectedFriro && this.selectedFriro){
    }
  }
  public closeCctp (){
    this.closeTheCctp.emit()
  }
  addFileToPost(file,isChosen=false){
    let entity=file.entity;
    let batiment=file.batiment
    let lotPCESelected=(file.lotPCE||'');
    if(isChosen) {
      file=this.chosenPost;
      this.addingPostIniesEvent.emit({name:file.nom,addingPostModal:true,...file})
    }
    // file.poste=this.postIdInies;
    // file.postName=this.cctpEdit.postDesignation;
    file.totalImpact=_.get(file,'impact.TotalCycleDeVie',0)
    // set phase 14
    let ind19=(file.indicateurs||[]).filter(ind=>ind.idIndicateur==19)
    let phase14;
    if((ind19||[]).length) phase14=ind19[0].phases.filter(phase=>phase.idPhase==14)
    if((phase14||[]).length) file.phase14=phase14[0].quantite

    // file.phase14=_.get(file,'indicateurs.TotalCycleDeVie',0)
    file.entity=entity;
    file.batiment=batiment
    // parse entity
    if(file.entity!="Parcelle")file.lotPCE=lotPCESelected;
    if(lotPCESelected.startsWith("Cette liste recense les composants ajoutés mais n'ayant été affilié à aucun Lot"))file.lotPCE='';
    if(lotPCESelected.startsWith("Ces composants concernent notamment les clôtures et la voirie hors aires de stationnement."))file.entity='Parcelle';
    //
    file.contributor=this.currentContibuteur
    file.ceProject=this.ceProjectId;
    this.loadingPosts=true;
    this.relatedFichesService.create(file).subscribe(
      (data)=>{
        this.loadingPosts=false;
        data['lotPCE'] = file.lotPCE;
        file.addingPost=false;
        this.addingPostIniesEvent.emit({addingPost:true,...data})
        this.cd.detectChanges();
        this.messageService.add({ key: 'toast', severity: 'success', summary: "Ajout d'une fiche INIES", detail: "La fiche INIES a bien été ajoutée au poste", life: 5000 });
        $(".p-component-overlay").css("display","none");
      },
      (err)=>{
        this.messageService.add({ key: 'toast', severity: 'error', summary: "Ajout d'une fiche INIES", detail: "Echec", life: 5000 });
      }
    )
  }
  parseFrigogeneName(name){
    return (name||'').replace('- DONNEE ENVIRONNEMENTALE CONVENTIONNELLE','')
  }
  
  updatePost(postData){
    this.relatedFichesService.update(postData).subscribe(
      (data)=>{
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  updateFrigogeneData(quantity,postFile){
    let post=_.get(postFile,'frigogenePost',{})
    post.frigogeneQuantity=quantity.target.value
    this.updatePost(post);
  }
  checkAllFiles(){
    if(this.selectedFiles.length==this.relatedFiles.length){
      this.selectedFiles=[];
      this.relatedFiles.forEach((elem,index) => {
        this.relatedFiles[index].checked=false;
      });
    }
    else {
      this.selectedFiles=[];
      this.relatedFiles.forEach((elem,index) => {
        this.relatedFiles[index].checked=true;
        this.selectedFiles.push(elem._id)
        this.checkedAllFiles=true
      });
    }
  }
  checkOneFile(fileId){
    let index=this.selectedFiles.indexOf(fileId);
    let index2=this.relatedFiles.findIndex((a)=>a._id==fileId);
    if(index==-1) {
      this.selectedFiles.push(fileId)
      this.relatedFiles[index2].checked=true;
    }
    else {
      this.selectedFiles.splice(index,1);
      this.relatedFiles[index2].checked=false;
      this.checkedAllFiles=false;
    }
    if(this.selectedFiles.length==this.relatedFiles.length) this.checkedAllFiles=true;
  }
  deleteSelectedFiles(){
    let arr=this.relatedFiles.filter((a)=>a.checked==true)
    this.loadingPosts=true;
    this.relatedFichesService.deleteMany(arr).subscribe(
      (data)=>{
        this.relatedFiles=this.relatedFiles.filter((a)=>!a.checked);
        this.loadingPosts=false;
        this.cctpEdit.postIniesCount=this.cctpEdit.postIniesCount-arr.length;
        this.selectedFiles=[]
        this.cd.detectChanges()
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  duplicateSelectedFiles(){
    let arr=this.relatedFiles.filter((a)=>a.checked==true)
    this.loadingPosts=true;
    this.relatedFichesService.duplicate(arr).subscribe(
      (data)=>{
        this.relatedFiles=this.relatedFiles.concat(data);
        this.loadingPosts=false;
        this.cctpEdit.postIniesCount=this.cctpEdit.postIniesCount+arr.length;
        this.selectedFiles=[]
        this.cd.detectChanges();
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  duplicateOneFile(file){
    this.loadingPosts=true;
    this.relatedFichesService.duplicate([file]).subscribe(
      (data)=>{
        this.relatedFiles.push(data[0]);
        this.loadingPosts=false;
        this.cctpEdit.postIniesCount=this.cctpEdit.postIniesCount+1;
        this.cd.detectChanges();
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  deleteOneFile(file){
    this.loadingPosts=true;
    this.relatedFichesService.deleteMany([file]).subscribe(
      (data)=>{
        this.relatedFiles=this.relatedFiles.filter((a)=>a._id!=file._id);
        this.loadingPosts=false;
        this.cctpEdit.postIniesCount=this.cctpEdit.postIniesCount-1;
        this.cd.detectChanges();
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  // products methods
  searchPostIniesSuggestion(){
    this.loadingSugg=true;
    this.iniesSuggResult=[]
    this.isSearchingKeyword=false;
    this.relatedFichesService.searchWord(this.searchFileTerm,this.filter,'true').subscribe(
      (data)=>{
        this.iniesSuggResult=data;
        this.iniesSuggResult=this.iniesSuggResult
        .filter((value, index, self) => self.map(x => x.categoryName).indexOf(value.categoryName) == index && ((value.categoryName||'').split('|')[1]||'').split('(')!='')
        this.iniesSuggResult=this.iniesSuggResult.slice(0,8)
        if(!this.isSearchingKeyword)$('.autocomplete-items1').css({'visibility': 'visible'});
        // this.view="searchInterface";
        this.loadingSugg=false
        this.cd.detectChanges()
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  triggerSearch(event){
    $('.autocomplete-items1').css({'visibility': 'hidden'});
    this.loadingSugg=true;
    if(this.searchFileTerm)this.searchPostsTermUpdate.next(event)
  }
  searchPostInies(){
    $('.ui-sidebar').animate({
      scrollTop: top
    }, 100);
    this.view="searchInterface";
    this.loadingSearchPosts=true;
    $('.autocomplete-items1').css({'visibility': 'hidden'});
    this.isSearchingKeyword=true;
    this.relatedFichesService.searchWord(this.searchFileTerm,this.filter).subscribe(
      (data)=>{
        this.iniesPostPage=0;
        this.iniesResult=data;
        this.numPostsCategory={}
        this.calculatePostNumParents()
        this.loadingSearchPosts=false;
        this.cd.detectChanges()
        
        
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  getOneCategory(categoryId,fromPost=false){
    $('.ui-sidebar').animate({
      scrollTop: top
    }, 100);
    this.loadingCategories=true;
    this.loadingSearchPosts=true;
    this.view='searchInterface'
    if(fromPost){
      categoryId=((categoryId||'').split('|')[1]||'').split('(idParent:')[1].split(')')[0]
      // this.searchFileTerm='';
    }
    this.filter.idCategory=categoryId;
    this.filter['searchQuery']=this.searchFileTerm
    $('.autocomplete-items1').css({'visibility': 'hidden'});
    this.relatedFichesService.getCategory(categoryId,this.filter).subscribe(
      data=>{
        this.iniesPostPage=0;
        let keys=[];
        keys=Object.keys(data).filter(i => (data[i]||[]).length)
        keys.push('category')
        // keys.push('posts')
        this.selectedCategory=only(data,keys)
        this.iniesResult=data['posts'];
        this.selectedCategory['category']=[this.selectedCategory['category']]
        if(this.selectedCategory['category'] && this.selectedCategory['category'].length){
          this.selectedCategory['category'][0].children=this.selectedCategory['children'];
          this.selectedCategory['category'][0].isSelected=true;
          delete this.selectedCategory['children']
        }
        if(this.selectedCategory['idParent'] && this.selectedCategory['idParent'].length){
          this.selectedCategory['idParent'][0].children=this.selectedCategory['category'];
          delete this.selectedCategory['category']
        }
        if(this.selectedCategory['parent0'] && this.selectedCategory['parent0'].length){
          this.selectedCategory['parent0'][0].children=this.selectedCategory['idParent'];
          delete this.selectedCategory['idParent']
        }
        if(this.selectedCategory['parent1'] && this.selectedCategory['parent1'].length){
          this.selectedCategory['parent1'][0].children=this.selectedCategory['parent0'];
          delete this.selectedCategory['parent0']
        }
        if(this.selectedCategory['parent2'] && this.selectedCategory['parent2'].length){
          this.selectedCategory['parent2'][0].children=this.selectedCategory['parent1'];
          delete this.selectedCategory['parent1'];
        }
        if(this.selectedCategory['parent3'] && this.selectedCategory['parent3'].length){
          this.selectedCategory['parent3'][0].children=this.selectedCategory['parent2'];
          delete this.selectedCategory['parent2']
        }
        let key=Object.keys(this.selectedCategory).filter(a=>a!='posts')||[]
        this.selectedCategory=this.selectedCategory[key[0]];
        // push root categories
        let allRoot=[{nom:"Equipements électriques, électroniques et de génie climatique",
        idCategory:"479",treeLevel:0},{nom:"Services",
        idCategory:"827",treeLevel:0},{nom:"Produits de construction",
        idCategory:"476",treeLevel:0}]
        allRoot=allRoot.filter(oneC=>oneC.idCategory!=(this.selectedCategory[0]||{}).idCategory)
        this.selectedCategory.push(allRoot[0])
        this.selectedCategory.push(allRoot[1])
        //
        this.numPostsCategory={};
        this.loadingCategories=false;
        this.loadingSearchPosts=false;
        this.calculatePostNumParents()
        this.view='searchInterface';
        this.cd.detectChanges();
      },
      (err)=>console.log('err',err)
    )
  }
  changeView(view){
    this.view=view;
    // if(view=="searchInterface")this.listenCollapse();
    this.cd.detectChanges();
  }
  openFrirogeneModal(post){
    this.frigoPostParent=post;
    this.relatedFichesService.searchWord('',{isFrirogene:true},'true').subscribe(
      (data)=>{
        data.filter(post=>post.name)
        this.frirogeneResult.emit(data);
        $('#frirogene-modal').modal('show');
      }
    )
  }
  postFrigoCase(post){
    let phases=this.phaseToArray(post);
    // let cas=(post.declarationType==3||(!phases['11']&&!phases['12']&&!phases['13']&&!phases['14']))?"withoutBCO2":(phases['14']?"withBCO2":(post.declarationType==4?"FF":null))
    let cas=(post.declarationType==3 || ((post.idNorme==3 || post.idNorme==4) && !phases['14']))?"withoutBCO2":((phases['14'] && (post.declarationType==1||post.declarationType==2))?"withBCO2":(post.declarationType==4?"FF":"noCase"))
    return true;
  }
  phaseToArray(post){
    let phases=post.indicateurs.filter(indicator=>indicator.idIndicateur==19);
    let arr = new Array(30); for (let i=0; i<30; ++i) {
      arr[i]=0
    }
    phases=phases[0].phases;
    phases.forEach(element => {
      arr[element['idPhase']]=element['quantite'];
    });
    return arr;
  }
  openInfoModal(post){
    $('#post-info-modal').modal('show');
    // parse CategoryName
    post.categoryName=post.categoryName.replace(/ *\([^)]*\) */g, "").replaceAll('|',' > ');
    let categoryArray=post.categoryName.split('>');
    categoryArray=categoryArray.reverse()
    post.categoryName=categoryArray.join(">")
    post.categoryName=post.categoryName.substring(2);
    // parse name
    post.nom=this.parseName(post.nom)
    // parse documentPath
    post.documentPathArray=((post.documentPath||'').split(',')||[])
    // parse dateEdition
    let dateEdition=(new Date(post.dateEdition)||new Date());
    // dateEdition=dateEdition.split("/");
    // dateEdition[1]=(parseInt(dateEdition[1])+5).toString();
    // dateEdition.join("/")
    post.dateEdition=dateEdition.getMonth()+'/'+(dateEdition.getFullYear()+5)
    //parse indicateurs
    let indicateurs={};
    post.indicateurs.forEach(element => {
      let phases=(element['phases']||[]);
      let phase7=phases.find((phases)=>phases.idPhase==7)
      indicateurs[element['idIndicateur']]=phase7.quantite
    });
    let ind=[{
        name:"Impacts environnementaux",data:{
          "Réchauffement climatique (kg CO2 eq.)":(post.impact||{}).TotalCycleDeVie / post.quantiteUF||1,
          "Appauvrissement de la couche d'ozone (kg CFC-11 eq.)":indicateurs[20],
          "Acidification des sols et de l'eau (kg SO2 eq.)":indicateurs[21],
          "Eutrophisation (kg (PO4)3- eq.)":indicateurs[22],
          "Formation d'ozone photochimique (kg C2H4 eq.)":indicateurs[23],
          "Epuisement des ressources abiotiques - éléments (kg Sb eq.)":indicateurs[24],
          "Epuisement des ressources abiotiques - combustibles fossiles (MJ)":indicateurs[25],
          "Pollution de l'air (m³)":indicateurs[26],
          "Pollution de l'eau (m³)":indicateurs[27]
        }
      },{
        name:"Consommation des ressources",data:{
          "Utilisation de l'énergie primaire renouvelable, à l'exclusion des ressources d'énergie primaire ... (MJ)":indicateurs[28],
          "Utilisation des ressources d'énergie primaire renouvelables utilisées en tant que matières premières (MJ)":indicateurs[29],
          "Utilisation totale des ressources d'énergie primaire renouvelables ... (MJ)":indicateurs[30],
          "Utilisation de l'énergie primaire non renouvelable, à l'exclusion ... (MJ)":indicateurs[31],
          "Utilisation des ressources d'énergie primaire non renouvelables utilisées en tant que matières premières (MJ)":indicateurs[32],
          "Utilisation totale des ressources d'énergie primaire non renouvelables ... (MJ)":indicateurs[33],
          "Utilisation de matière secondaire (kg)":indicateurs[34],
          "Utilisation de combustibles secondaires renouvelables (MJ)":indicateurs[35],
          "Utilisation de combustibles secondaires non renouvelables (MJ)":indicateurs[36],
          "Utilisation nette d'eau douce (m³)":indicateurs[37],
        }
      },{
        name:"Déchets",data:{
        "Déchets dangereux éliminés (kg)":indicateurs[38],
        "Déchets non dangereux éliminés (kg)":indicateurs[39],
        "Déchets radioactifs éliminés (kg)":indicateurs[40],
        }
      },{
      name:"Flux sortants",data:{
        "Composants destinés à la réutilisation (kg)":indicateurs[41],
        "Matériaux destinés au recyclage (kg)":indicateurs[42],
        "Matériaux destinés à la récupération d'énergie (kg)":indicateurs[43],
        "Energie fournie à l'extérieur (MJ)":indicateurs[44],
      }}]
    post.ind=ind;
    this.infoPost.emit(post);
  }
  getPostOpenModal(post){
    this.relatedFichesService.getIniesPosts([post.idFicheBase]).subscribe(
      (data)=>{
        this.openInfoModal(data[0])
      }
    )
  }
  calculateTotal(){
    let result=0;
    for (let index = 0; index < this.relatedFiles.length; index++) {
      let frigoImpact=_.get(this.relatedFiles[index],'frigogenePost.totalImpact',0);
      let frigogeneQuantity=_.get(this.relatedFiles[index],'frigogenePost.frigogeneQuantity',0);
      result+=this.relatedFiles[index].totalImpact*this.relatedFiles[index].quantity;
      if(frigoImpact)result+=frigoImpact*frigogeneQuantity;
    }
    return result;
  }
  openConfirmationPost(post){
    this.chosenPost=post
    this.addingPostIniesEvent.emit({name:post.nom,addingPostModal:false,...post})
    $('#add-post-modal').modal('show');
  }
  setFilterAttribute(value,attribute,event=null){
    if(attribute=="declarationType") {
      for (let i = 0; i < (value||[]).length; i++) {
        let index=this.filter.declarationType.findIndex(a=>a==value[i])
        if(index!=-1) this.filter.declarationType.splice(index,1);
        else this.filter.declarationType.push(value[i])
        if($(event.target).hasClass(`declarationTypeChip${value[i]}`)) $(event.target).removeClass(`declarationTypeChip${value[i]}`)
        else $(event.target).addClass(`declarationTypeChip${value[i]}`)        
      }
    }
    else if(attribute=="airRating"){
      let index=this.filter.airRating.findIndex(a=>a==value)
      if(index!=-1) this.filter.airRating.splice(index,1);
      else this.filter.airRating.push(value)
    }
    else {
      this.filter[attribute]=value;
    }
    this.searchPostInies()

  }
  resetFilter(){
    this.filter={productionPlace:{"Hors Europe":false,"Europe":false,"France":false},performanceUF:'Toutes',dateMiseEnLigne:"Toutes",organismeName:"",declarationType:[1,2,3,4],idCategory:'',airRating:[]};
    this.selectedCategory=[{nom:"Produits de construction",
        idCategory:"476",treeLevel:0},{nom:"Equipements électriques, électroniques et de génie climatique",
        idCategory:"479",treeLevel:0},{nom:"Services",
        idCategory:"827",treeLevel:0}]
    $('.one-ticket').removeClass("selected")
    for (let index = 1; index < 5; index++) {
      if(!$('.chip'+index).hasClass(`declarationTypeChip${index}`)) $('.chip'+index).addClass(`declarationTypeChip${index}`)
    }
  }
  setSelected(event){
    if($(event.target).hasClass("selected")) $(event.target).removeClass("selected")
    else $(event.target).addClass("selected")
  }
  openModal(id){
    $(id).modal('show');
  }
  openRemploi(id){
    this.openRemploiEvent.emit(id);
  }
  sendChangeView(view){
    this.ChangeViewEvent.emit(view);
  }
  searchOrganisme(){
    this.relatedFichesService.getOrganismeSugg(this.filter.organismeName).subscribe(
      (data)=>{
        this.organismeSugg=data;
        $('.autocomplete-items-organisme').css({'visibility': 'visible'});
        this.cd.detectChanges()
      }
    )
  }
  selectOrganisme(selected){
    this.filter.organismeName=selected;
    $('.autocomplete-items-organisme').css({'visibility': 'hidden'});
    this.searchPostInies()
  }
  public listenForPostsSearch(){
    this.searchPostsTermUpdate.pipe(
      debounceTime(600),
      distinctUntilChanged()
    ).subscribe(value => {
      this.searchPostIniesSuggestion();
    })
  }
  clearInput(attribute){
    if(this.filter[attribute]=="Toutes")this.filter[attribute]="";
  }
  unclearInput(attribute){
    if(this.filter[attribute]=="")this.filter[attribute]="Toutes";
  }
  resetValues(){
    this.searchFileTerm='';
    this.selectedFiles=[];
    $('.autocomplete-items1').css({'visibility': 'hidden'});
    this.resetFilter()
  }
  parseName(name){
    return name.split('- ID')[0]
  }
  setVisibilityDropdown(id,visibility){
    setTimeout(()=>$(id).css({'visibility': visibility}),10);
  }
  setPostPCE(oneLot,i){
    this.relatedFiles[i].lotPCE=oneLot
    this.updatePost(this.relatedFiles[i])
  }
  filterLotsPCE(word){
    for (let i = 0; i < this.lotsPCECopy.length; i++) {
      this.lotsPCECopy[i].lots=this.lotsPCE[i].lots.filter(lot=>lot.toLowerCase().includes(word.toLowerCase()))
    }
  }
  filterPerf(){
    this.performanceUFArrayCopy=this.performanceUFArray.filter(a=>a.toLowerCase().includes(this.filter.performanceUF.toLowerCase()))
  }
  calculatePostNum(category){
    let children=_.get(category,'children',[]);
    children.forEach(element => {
      for (let i = 0; i < this.iniesResult.length; i++) {
        if(element.idCategory==this.iniesResult[i].idNomenclature || element.idCategory==this.iniesResult[i].idNomenclature2 || element.idCategory==this.iniesResult[i].idNomenclature3) this.numPostsCategory[element.idCategory]=(this.numPostsCategory[element.idCategory]||0)+1
      }
      if(_.get(element,'children.length',0)){
        this.calculatePostNum(element)
      }
    });
  }
  calculatePostNumParents(){
    for (let index = 0; index < (this.iniesResult||[]).length; index++) {
      for (let i = 0; i < ((this.iniesResult[index].categoryName||'').split('|')).length-1; i++) {
        let categoryId=((this.iniesResult[index].categoryName||'').split('|')[i]||'').split('(idParent:')[1].split(')')[0]
        if(categoryId)this.numPostsCategory[categoryId]=(this.numPostsCategory[categoryId]||0)+1
      }
    }
  }
  parseLotName(name){
    return name.replace(/[0-9]/g, '').replace('.','').replace('-','')
  }
  expandFilter(event){
    let parent = $(event.target).closest('.collapsible');
    let clickedItem=parent.nextAll('.content:first')
    let openArrow = parent.children(".open-arrow")
    let closeArrow = parent.children(".close-arrow")
    $('.close-arrow').addClass('hidden-item');
    $('.open-arrow').removeClass('hidden-item');

    if(clickedItem.hasClass('expand')){
      clickedItem.removeClass('expand');
    }else{
      // $('.content').removeClass('expand');
      clickedItem.addClass('expand');
      openArrow.addClass('hidden-item');
      closeArrow.removeClass('hidden-item');
    }
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }
}

