import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Helpers } from "./helpers";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

type T = Window & typeof globalThis;
// @ts-ignore
interface window extends T {
    chrome?: any;
    CefSharp?:any;
    dispatchWebViewEvent?: Function;
    ClickRevit?: Function;
    ManyClickRevit?: Function;
}


@Injectable({
    providedIn: 'root'
  })

export class WebView2Service {
  window: window;
  eventCaptureElement = document.createElement("a");
  
  constructor(private http: HttpClient) {
    const FEET = 3.28084;
    const FEET_Square = 10.7639;
    const FEET_Cube = 35.3146;
    this.window = window;
    this.window.dispatchWebViewEvent =  ( ({ action, payload }) =>{
      const e = action.toLowerCase();
        payload = payload.map(a=>{
          let newA = {...a,volume : 0,surface : 0,longeur : 0,ens : 1};
          (a.parameters||[]).forEach(p=>{
            if(p.parameter.includes("VOLUME"))newA.volume = Math.round((p.value+ Number.EPSILON )*100 / FEET_Cube) / 100;
            if(p.parameter.includes("AREA"))newA.surface = Math.round((p.value+ Number.EPSILON )*100 / FEET_Square) / 100;
            if((p.parameter.includes("LENGTH")||p.parameter.includes("CURVE_ELEM_LENGTH")))newA.longeur = Math.round((p.value+ Number.EPSILON )*100 / FEET) / 100; 
          })
          newA.parameters = null;
          return newA
        })
      this.eventCaptureElement.dispatchEvent(new CustomEvent(e, { detail: payload }));
    }).bind(this)

    this.clickOnRevitSimulationTestOnly()
  }

  clickOnRevitSimulationTestOnly(){
    let categories=['murs','fenetres','portes','modeles generiques', 'Garde-corps']
    this.window.ClickRevit =  ( (id) =>{
      this.window.dispatchWebViewEvent({
        action : "SelectionChanged",
        payload :  [{
          "id":id?id :Math.random().toString(36).substring(2, 15) ,
          "category":categories[Math.floor(Math.random() * categories.length)],
          "family":  `${categories[Math.floor(Math.random() * categories.length)]}Type Bateau 1 m:641182`,
          "type":"Type Bateau 1 m:641182",
          parameters : [
            {"parameter":"CURVE_ELEM_LENGTH","value":88.58267716535434},
            {"parameter":"HOST_VOLUME_COMPUTED","value":1531.2439490437623},
            {"parameter":"HOST_AREA_COMPUTED","value":2333.61577}]
        }]
      })
    }).bind(this)

    this.window.ManyClickRevit =  ( (ids,l) =>{
      let payload = [];
      for (let index = 0; index < l; index++) {
        payload.push({
          "id":ids[index]?ids[index] :Math.random().toString(36).substring(2, 15) ,
          "category":categories[Math.floor(Math.random() * categories.length)],
          "family":  `${categories[Math.floor(Math.random() * categories.length)]}Type Bateau 1 m:641182`,
          "type":"Type Bateau 1 m:641182",
          parameters : [
            {"parameter":"CURVE_ELEM_LENGTH","value":88.58267716535434},
            {"parameter":"HOST_VOLUME_COMPUTED","value":1531.2439490437623},
            {"parameter":"HOST_AREA_COMPUTED","value":2333.61577}]
        })        
      }
      this.window.dispatchWebViewEvent({
        action : "SelectionChanged",
        payload :  payload
      })
    }).bind(this)
  }

  addBimElements(posteId,dpgfId,body):Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/dpgfs/${dpgfId}/poste/${posteId}/bim`,body,options)         
    .pipe(map((response: any) => {
      return response.data;
    }));
  }
  attachPostsToBimElement(dpgfId,body):Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/dpgfs/${dpgfId}/bim/attach-bim-post`,body,options)         
    .pipe(map((response: any) => {
      return response.data;
    }));
  }

  detachElementFromPost(dpgfId,body){
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/dpgfs/${dpgfId}/bim/detach-bim-post`,body, options)
    .pipe(map((response:any)=>{
      return response.data;
    }))
  }
  getPosteElments(posteId,dpgfId):Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.get(`/api/v1/dpgfs/${dpgfId}/poste/${posteId}/bim`,options)
    .pipe(map((response:any)=>{
      return response.data;
    }))
  }
  deleteElementFromPoste(posteId,elementId):Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.delete(`/api/v1/bim/${elementId}`,options)
    .pipe(map((response:any)=>{
      return response.data;
    }))
  }
  getPostesUsingElementId(dpgfId,elementId):Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.get(`/api/v1/dpgfs/${dpgfId}/bim/${elementId}`,options)
    .pipe(map((response:any)=>{
      return response.data;
    }))
  }
  

addPoste(dpgfId,lotId,data):Observable<any>{
    let options = new Helpers().getHeader();
    
    return this.http.patch(`/api/v1/dpgfs/${dpgfId}/lot/${lotId}/bim`,data, options)
    .pipe(map((res:any)=>{
      return res.data;
    }))
  }

  updatePost(posteId,dpgfId,data):Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.patch(`/api/v1/dpgfs/${dpgfId}/poste/${posteId}`,data, options)
    .pipe(map((res:any)=>{
      return res.data;
    }))
  }
  subscribeToWebView2Event(eventName, handler) {
    this.eventCaptureElement.addEventListener(eventName, handler);
  }

  unsubscribeToWebView2Event(eventName, handler) {
    this.eventCaptureElement.removeEventListener(eventName, handler);
  }

  postWebView2Message({ action, payload }) {
    if (!action) {
      return;
    }
    // Assurer que CefSharp est disponible
    console.log({ action, payload } )
    if (typeof this.window.CefSharp !== 'undefined' && this.window.CefSharp.PostMessage) {
      let  message = { action, payload };
      let messageString;
      if(payload.length==null)  messageString =JSON.stringify(action);
      messageString = JSON.stringify(message);
      // Utilise CefSharp pour envoyer le message
      this.window.CefSharp.PostMessage(messageString);
    }  
  }

  isInWebViewContext() {
      return !!this.window.CefSharp
  }
}

        // if(e!=="getdatastructureofcategories"){
        //   alert("hello")
        //   let dataStructure = payload.reduce((result, elem) => {
        //     let category = result.get(elem.category);
        //     if (!category) {
        //       category = { name: elem.category, families: [] };
        //       result.set(elem.category, category);
        //     }
          
        //     let family = category.families.find(item => item.name === elem.family);
        //     if (!family) {
        //       family = { name: elem.family, types: [] };
        //       category.families.push(family);
        //     }
          
        //     let type = family.types.find(item => item.name === elem.type);
        //     if (!type) {
        //       type = { name: elem.type, products: [] };
        //       family.types.push(type);
        //     }
          
        //     type.products.push({
        //       id: elem.id,
        //       parameters: {
        //         volume: elem.volume,
        //         surface: elem.surface,
        //         longeur: elem.longeur,
        //         ens: elem.ens
        //       }
        //     });
        //     return result;
        //   }, new Map());
        // dataStructure = [...dataStructure.values()];

        // }