<div class="wrapper">
  <div *ngIf="pricing; else noPricing" class="row">
    <div *ngIf="!pricing.isModifying; else isModifying;">
      <div class="pricing-wrapper ">
        <p class="artisan">
          <span class="artisan-name" [style.background]="colors[pricing.artisanName]">
            {{ pricing.artisanName }}
          </span>
        </p>
        <p class="indice">
            ind.{{ pricing.currentVersion | pricingIndex }}
        </p>
      </div>
      <div class="total">
        {{ pricing.total | number:'1.0-0':'fr'  | priceUnite:'€' }}
      </div>
      <div class="estim-diff" *ngIf="estimation" pTooltip="Ecart à l'estimatif" tooltipPosition="top">
        <div *ngIf="pricing.total > estimation; else inf">
          <span class="sup">
            + {{ getEstimationDiff | number:'1.0-0':'fr'  | priceUnite:'€' }}
          </span>
        </div>
        <ng-template #inf>
          <span class="inf">
            - {{ getEstimationDiff | number:'1.0-0':'fr'  | priceUnite:'€' }}
          </span>
        </ng-template>
      </div>
    </div>
    <ng-template #isModifying>
      <div class=" is-modifying">
        <i class="fa fa-spinner"></i>
      </div>
      <p class="small-text">{{ isModifyingMessage }}</p>
    </ng-template>
  </div>
    <ng-template #noPricing>
      <div class="no-pricing">
        <i class="fa fa-plus"></i>
      </div>
    </ng-template>
</div>
