import { Component, OnInit, Input, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  standalone:true,
  selector: 'app-read-more',
  imports:[NgSharedModule],
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent implements OnInit {
  @Input()text:string = "";
  public toShow:any ="";
  private max = 3;
  public height = 200;
  public shown = "less";
  public top = "150px";
  public showToggle = true;
  public overflow='hidden';
  public position="absolute";
  public myDiv;
  constructor() { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
  }
  show(){
    if(this.shown == "more"){
      this.myDiv=document.getElementById('cctp-holder');
      $(this.myDiv).animate(
        { scrollTop: "0" }, 500);
      // this.myDiv.scrollTop = 0;
      this.shown = "less"
      this.height = 200;
      this.top = "150px";
      this.position="absolute"
      this.overflow='hidden'
    }else{
      this.shown = "more"
      this.height = 400;
      this.top="0px";
      this.position="relative"
      this.overflow='auto'
    }
  }
  
}
