import { Component, OnInit, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  standalone:true,
  selector: 'app-rdv-calendar',
  imports:[NgSharedModule],
  templateUrl: './rdv-calendar.component.html',
  styleUrls: ['./rdv-calendar.component.scss']
})
export class RdvCalendarComponent implements OnInit {
  public creneaux:any = [
    "08h-09h",
    "09h-10h",
    "10h-11h",
    "11h-12h",
    "12h-13h",
    "13h-14h",
    "14h-15h",
    "15h-16h",
    "16h-17h",
    "17h-18h",
    "18h-19h",
    "19h-20h",
  ]
  public months:any= ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
  public monthLength:any= [31,28,31,30,31,30,31,31,30,31,30,31]
  public weekDay = [{day:1,date:'',label:'Lundi'},{day:2,date:'',label:'Mardi'},{day:3,date:'',label:'Mercredi'},{day:4,date:'',label:'Jeudi'},{day:5,date:'',label:'Vendredi'}];
  public weeks = [];
  public month:number = 0;
  public unix:number = 0;
  public monthName:string = "";
  public dayMonth:number = 0;

  public dayNumber:number = 0;
  public weekNumber:number = 0;

  public weekStart:string = "0";
  public weekEnd:string = "0";

  public chosenCreneau:any = [];
  @Output() rdvChange = new EventEmitter();
  @Input()
  get rdv() {
    return this.chosenCreneau;
  }

  set rdv(val) {
    this.chosenCreneau = val;
    this.rdvChange.emit(this.chosenCreneau);
  }
  constructor() { }

  ngOnInit() {
    this.createAgenda();
  }
  public createAgenda(){
    let date = new Date();
    this.unix = Date.now();
    this.dayNumber = date.getDay();
    this.dayMonth = date.getDate();
    this.month = date.getMonth();
    this.monthName = this.months[date.getMonth()];
    if(this.dayNumber> 4){
      this.nextWeek();
    }
    this.calculateEndStart();
  }
  //Calculate End andStart
  public calculateEndStart(){
    let weekStart = this.dayMonth - this.dayNumber + 1 + this.weekNumber*7;
    let weekEnd   = this.dayMonth - this.dayNumber + 5 + this.weekNumber*7;
    if(weekStart > this.monthLength[this.month]){
      weekStart = weekStart - this.monthLength[this.month];
      this.monthName = this.months[this.month+1];
    }
    if(weekEnd > this.monthLength[this.month]){
      weekEnd = weekEnd - this.monthLength[this.month];
      this.monthName = this.months[this.month+1];
    }
    this.weekStart = this.numberToString(weekStart);
    this.weekEnd = this.numberToString(weekEnd);
    this.calculateWeekDate();
    this.calculateMonth();
  }
  public calculateMonth(){
    let month = new Date( this.unix + (5 - this.dayNumber + 7*this.weekNumber) * 86400000 ).getMonth();
    this.monthName = this.months[month]
  }
  //Calculate weekdays
  public calculateWeekDate(){
    this.weekDay.map((day)=>{
      let newDay = Object.assign(day);
      newDay.date = this.getDate(this.unix + (day.day - this.dayNumber + 7*this.weekNumber) * 86400000 );
      return newDay;
    })
  }
  //Next and Prev Week
  public nextWeek(){
    if(this.weekNumber < 4){
      this.weekNumber = this.weekNumber + 1;
      this.calculateEndStart();
    }
  }
  public prevWeek(){
    if(this.weekNumber > 0){
      this.weekNumber = this.weekNumber - 1;
      this.calculateEndStart();
    }
  }
  //Add
  public addToChosen(val,selectable){
    if(selectable){
      let index = this.chosenCreneau.indexOf(val)
      if(index == -1){
        this.chosenCreneau.push(val)
      }else{
        this.chosenCreneau.splice(index, 1);
      }
    }
  }
  //Get Date formate
  private getDate(timestamp){
    var a = new Date(timestamp);
    var year = a.getFullYear();
    var month = "0" + (a.getMonth()+1);
    var date = "0" + a.getDate();
    return  date.substr(-2) + '/' + month.substr(-2) + '/' + year
  }
  private numberToString(number){
    let s =  "0" + number;
    return s.substr(-2);
  }
}
