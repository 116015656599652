<div class="albums-wrapper">

  <div class="no-albums-container text-center margin-top-40" *ngIf="!albums.length">
    <h5 class="ag-heading-h5">L'entreprise est en train de compléter son profil, revenez plus tard pour voir ses réalisations</h5>
  </div>

  <!-- Artisan Photos (Autres Files) -->
  <div class="single-album">
    <ng-container *ngIf="photos.length">
      <h5 class="ag-heading-h5">Mes réalisations</h5>
      <div class="album-photos-list horizontal-items flex-wrap" [id]="'realisations'">
        <div class="single-photo relative margin-bottom-9" 
          *ngFor="let photo of photos; let photoIndex = index;"
          (click)="openAlbumsSlider(photos, photoIndex, 'photos')">
          <img [src]="photo.cdnUrl" class="full-width">
        </div>
      </div>
      <div class="album-footer text-right margin-top-8 margin-right-100">
        <p class="ag-para-1 ag-weight-500 pointer" (click)="toggleExpandClass('realisations')">
          <span *ngIf="!openedAlbums['realisations']">Tout afficher </span> 
          <span *ngIf="openedAlbums['realisations']">Afficher moins</span> 
          ({{photos.length}} photos)
        </p>
      </div>
    </ng-container>
  </div>
  <!-- END Artisan Photos (Autres Files) -->

  <!-- Single Album -->
  <div class="single-album" *ngFor="let album of albums; let i = index;">
    <ng-container *ngIf="album.photos.length">
      <h5 class="ag-heading-h5">{{ album.title }}</h5>
      <div class="album-photos-list horizontal-items flex-wrap" [id]="'photos-list-'+i">
        <div class="single-photo relative margin-bottom-9" 
          *ngFor="let photo of album.photos; let photoIndex = index;"
          (click)="openAlbumsSlider(album.photos, photoIndex)"
          >
          <img [src]="photo.uploadURL" class="full-width">
        </div>
      </div>
      <div class="album-footer text-right margin-top-8 margin-right-100">
        <p class="ag-para-1 ag-weight-500 pointer" (click)="toggleExpandClass('photos-list-'+i)">
          <span *ngIf="!openedAlbums['photos-list-'+i]">Tout afficher </span> 
          <span *ngIf="openedAlbums['photos-list-'+i]">Afficher moins</span> 
          ({{album.photos.length}} photos)
        </p>
      </div>
    </ng-container>
  </div>
  <!-- END Single Album -->

</div>

<app-photos-slider 
  [photosList]="sliderAlbumPhotos" 
  [currentPhotoIndex]="sliderPhotoIndex">
</app-photos-slider>