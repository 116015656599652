import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges,ChangeDetectorRef, ViewChild, ViewChildren, QueryList, HostListener, NO_ERRORS_SCHEMA } from '@angular/core';
import { ArchiDbService } from 'src/app/_services/archi-db.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Store , select} from '@ngrx/store';
import * as fromSelectors from '../../../dpgf/dce-edition/_store';
import * as actions from '../../../dpgf/dce-edition/_store/dpgf.actions';
import { DomSanitizer } from '@angular/platform-browser';
import generateCCTP from './cctp-generator';
var html2json = require('html2json4wx').html2json;
import { ArchitectService, AuthService, DpgfPostesService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { CstbNavigationComponent } from '../../../all/cstb/cstb-navigation/cstb-navigation.component';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Editor } from 'src/app/all/editor/editor.component';
import { ArchiDbPostComponent } from 'src/app/dpgf/dce-edition/archi-db-post/archi-db-post.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

declare var $:any;

@Component({
    standalone:true,
    selector: 'app-archi-post-slider',
    imports:[NgSharedModule,ArchiDbPostComponent,Editor,CstbNavigationComponent],
    templateUrl: './archi-post-slider.component.html',
    styleUrls: ['./archi-post-slider.component.scss'],
    providers: []
})
export class ArchiPostSliderComponent implements OnInit {

  @Input() post;
  @Input() lots;
  @Input() lot;
  @Input() adding;
  @Input() firstTime;
  @Input() isPost=true;iconSelected=false;
  @Input() searching;
  @Input() firstTimeLot;
  @Input() pathArray;
  @Input() userData;
  @Output()closeThePostSlider = new EventEmitter<any>();
  @Output()newPostEvent= new EventEmitter<any>();
  @Output()newLotEvent= new EventEmitter<any>();
  @Output()updateLotEvent= new EventEmitter<any>();
  @Output()updatePostEvent= new EventEmitter<any>();
  public filteredLots=['haha'];
  public newLot={ title :'test', icon:'',description:''};newSelectedLot;
  public icon;
  public archiId=JSON.parse(localStorage.getItem('currentUser')).archiId;
  @ViewChild(CstbNavigationComponent) cstbNav: CstbNavigationComponent
  public cctpEdit$;
  public ceproject;
  public cctpEdit;
  public indexPost;
  public cctpEditValue = '';
  public gid = '';
  public error = 0;i=0;
  public value = 'statique';
  public cstb;
  public lodingCstb = false;
  public poste = {
    title: "",
    cctp : [],
  }
  public editing;
  public postTitle;
  public subscriptions = [];
  public chosenLot;
  public searchTerm;
  public results=[];
  public postForm = new UntypedFormGroup({
    title: new UntypedFormControl(''),
    unity: new UntypedFormControl(''),
    puht: new UntypedFormControl(''),
    ec: new UntypedFormControl(''),
    cctp:new UntypedFormControl(''),
    gid:new UntypedFormControl(''),
    lotTitle: new UntypedFormControl(''),
    firstTime: new UntypedFormControl(''),
    quantity: new UntypedFormControl(''),
    totPrice: new UntypedFormControl(''),
    postOr: new UntypedFormControl(''),
  });
  public lotForm = new UntypedFormGroup({
    title: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
    icon: new UntypedFormControl(''),
    parentLot: new UntypedFormControl('')
  });
  licence: string;
  addingPost: boolean;
  public autoSuggestOptions =["m2","m3","ml","m","u","ens","forfait","hr","kg","l"];
  public icons = [
    'amenagements_exterieurs__espaces_verts.png',
    'ascenseurs.png',
    'assainissement__recuperationtraitement_des_eaux.png',
    'generalites.png',
    'carrelage__faience.png',
    'charpente.png',
    'chauffage__ventilation__climatisation_cvc.png',
    'cloisons__doublages__fauxplafonds.png',
    'couverture__etancheite.png',
    'cvc.png',
    'demolition__depose.png',
    'desamiantage.png',
    'electricite.png',
    'energies_renouvelables.png',
    'equipements_techniques.png',
    'facades.png',
    'gaz.png',
    'maconnerie__grosuvre.png',
    'menuiserie_exterieure.png',
    'menuiserie_interieure__agencement.png',
    'miroiterie__vitrerie.png',
    'mobilier.png',
    'ossature_boismetallique.png',
    'peinture__revetements_muraux__nettoyage.png',
    'piscine.png',
    'plomberie__sanitaire.png',
    'revetement_de_sol.png',
    'securite_incendie.png',
    'serrurerie__metallerie.png',
    'signaletique.png',
    'stores__fermetures__occultations.png',
    'terrassement__soutenements__infrastructures.png',
    'voirie_et_reseaux_divers.png',
    "Aglo-Appareillage_installation_reseaux.svg",
    "Aglo-Approche_simplifiee.svg",
    "Aglo-Eclairage.svg",
    "Aglo-Energie.svg",
    "Aglo-Equipements_genie_climatique.svg",
    "Aglo-Fils_cables.svg",
    "Aglo-Fluides_frigorigenes.svg",
    "Aglo-Panneau_sandwich_agroalimentaire.svg",
    "Aglo-Production_locale_energie.svg",
    "Aglo-Traitement_dechets.svg"
  ];
  public children=[];
  public cstbToImport='';
  loadingChild: boolean;
  isLotSelected: boolean;
  public choosedCstb=true;
  public accessToCstb = JSON.parse(localStorage.getItem('currentUser')).accessCstb;

  constructor(private archiDbService: ArchiDbService,
    private dpgfPostesService :DpgfPostesService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private cd: ChangeDetectorRef,
    private store: Store,
    private authService: AuthService,
    private archiService: ArchitectService,
    private messageService: MessageService,) { }

  @HostListener('document:click', ['$event.target']) onClick(targetElement) {
    let id = targetElement.id;
    let index=this.children.findIndex((poste)=>poste.key==id)
    if(id && index!=-1 && !this.choosedCstb){
      let newPost=this.createHtml(this.children[index]['poste']['cctp']);
      if(!this.cstbToImport.includes(newPost)) this.cstbToImport=this.cstbToImport+newPost;
      else this.cstbToImport=this.cstbToImport.replace(newPost,'')
    }
  }
  ngOnInit(): void { 
    this.licence=this.authService.licence;
    $(document).mouseup(function(e) {
      var container = $("#lot-dropdown");
      if (!container.is(e.target) && container.has(e.target).length === 0 && !$(".directory-icon").is(e.target)) container.hide();
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    this.chosenLot=null;
    // case of modifying lot
    if(!this.adding && !this.isPost && this.lot){
      this.icon=this.lot.icon;
      this.iconSelected=true;
      // this.lot=changes.lot;
        this.lotForm.setValue({
          title: (this.lot.title || ''),
          icon: (this.lot.icon || ''),
          description: (this.lot.description || ''),
          parentLot: (this.lot.lotParent || '')
        });
    }
    // case of modifying post
    else if(!this.adding && this.isPost && this.post){
        this.newSelectedLot=this.lot;
        this.getCSTB(this.post);
        this.gid=this.post.gid;
        this.postTitle=this.post.title;
      this.postForm.setValue({
        title: (this.post.title || ''),
        unity: (this.post.unity || ''),
        puht: (this.post.puht || ''),
        cctp: (this.post.puht || ''),
        gid:(this.post.gid || ''),
        ec: (this.post.ec || ''),
        lotTitle: (this.post.lotTitle || ''),
        totPrice: (this.post.totPrice || ''),
        quantity: (this.post.quantity || ''),
        firstTime:false,
        postOr:this.post.postOr,
      });
    }
    // case of adding post
    else if(this.adding && this.isPost){
      this.onSelectionChange('statique');
      this.postTitle='Titre du poste';
      this.newSelectedLot=this.lot;
      this.postForm.setValue({
        title: '',
        unity: '',
        puht: '',
        cctp: '',
        gid:'',
        ec: '',
        lotTitle: '',
        firstTime:true,
        totPrice: '',
        quantity: '',
        postOr:10000
      });
    }
    // case of adding lot
    else if(this.adding && !this.isPost){
      this.icon='';
      this.iconSelected=false;
        this.lotForm.setValue({
          title: '',
          icon: '',
          description: '',
          parentLot: (this.lot && this.lot.parentLot)? this.lot._id : '',
        });
    }
  }

  public setChosenLot(lot){
    if(lot=="MonBdd") this.chosenLot={title:'Ma bibliothèque'};
    else this.chosenLot=lot;
    
    if(this.isPost)this.updatePost(false);
    if(lot._id!=this.lot._id && this.isPost) this.updatePostEvent.emit(this.post._id);

    $("#lot-dropdown").toggle();
  }

  public setIcon(icon){
    this.icon=icon;
    this.iconSelected=true;
    this.lotForm.patchValue({icon:icon});
  }
  public setLot(lot=this.lot){
    this.newSelectedLot=lot;
  }

  public treatPost (){
    (this.adding)? this.addPost():this.updatePost(true);

    // hide overlay bg
    $(".p-component-overlay").css("display","none");
  }
  public treatLot(){
    (this.adding)?this.addLot():this.updateLot();
  }

  public closePostSlider (pushArray=true,postId='',updatedPost={}){
    let event={adding:this.adding,searching:this.searching,pushArray:pushArray,postId:postId,updatedPost:updatedPost}
    this.closeThePostSlider.emit(event);
  }

  public addPost(){
    this.addingPost=true;
    this.postForm.patchValue({firstTime: this.firstTime})
    this.postForm.patchValue({lotTitle:this.lot.title})
    this.postForm.patchValue({title:this.postTitle})
    this.subscriptions.push(
      this.archiDbService.createPost(this.lot._id,this.postForm.value).subscribe(
        (data) => {
          let max = this.authService.premuimFeatures.base.archiDbPostNb;
          this.indexPost=(this.indexPost)?this.indexPost+1:this.post.postOr+1;
          this.closePostSlider(false);
          this.newPostEvent.emit(data)

          this.messageService.add({ key: 'toast', severity: 'success', summary: "Ajout d'un poste", detail: "Le poste a été ajouté", life: 5000 });
          this.addingPost=false;
          // segment event
          if(!environment.onPremise)window.analytics.track('add_post_db',{properties:{acValue: 'add_post_db'}});
        },
        (err)=>{
          this.addingPost=false;
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur d'ajout poste", detail: 'Erreur serveur', life: 5000 });
        })
    )
  }
  // update post data and lot
  public updatePost(closeIt){
    this.addingPost=true;
    let newPost=this.postForm.value;
    let lotId=(this.chosenLot)? this.chosenLot._id:this.post.lot;
    let lotTitle=(this.chosenLot)? this.chosenLot.title:this.post.lotTitle;
    newPost.cctp=this.post.cctp;
    newPost.lot=lotId;
    newPost.lotTitle=lotTitle;
    newPost.title=this.postTitle;
    this.subscriptions.push(
      this.archiDbService.updatePost(lotId,this.post._id,newPost).subscribe(
        (data)=>{
          this.addingPost=false;
          newPost._id=this.post._id;
          newPost.archiId=this.archiId;
          if(this.chosenLot && closeIt) this.closePostSlider(false,this.post._id,newPost); 
          else if(closeIt) this.closePostSlider(false,'',newPost);
          this.messageService.add({ key: 'toast', severity: 'success', summary: "Mise à jour d'un poste", detail: "Le poste a été mis à jour", life: 5000 });
        },
        (err)=>{
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur de modification", detail: "Erreur serveur", life: 5000 });
        })
    )   
  }

  public addLot(){
    this.addingPost=true;
    if(this.pathArray && this.pathArray.length>2) this.lotForm.patchValue({parentLot:this.lot._id})
    this.subscriptions.push(
      this.archiDbService.createLot(this.lotForm.value).subscribe(
        (data)=>{
          this.addingPost=false;
          this.newLotEvent.emit(data);
          this.closePostSlider(false,'');
          this.messageService.add({ key: 'toast', severity: 'success', summary: "Ajout d'un dossier", detail: "Le dossier a été ajouté", life: 5000 });
          // segment event
          if(!environment.onPremise)window.analytics.track('add_folder_db',{properties:{acValue: 'add_folder_db'}});
        },
        (err)=>{
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur d'ajout dossier", detail: "Erreur serveur", life: 5000 });
        })
    )
  }

  public updateLot(){
    this.addingPost=true;
    this.iconSelected=true;
    if(this.chosenLot && this.chosenLot._id)this.lotForm.patchValue({parentLot:this.chosenLot._id})
    else if(this.chosenLot && this.chosenLot.title=="Ma bibliothèque") this.lotForm.patchValue({parentLot:''})
    else this.lotForm.patchValue({parentLot:this.lot.parentLot})
    this.subscriptions.push(
      this.archiDbService.updateLot(this.lot._id,this.lotForm.value).subscribe(
        (data)=>{
        this.addingPost=false;
        if(this.chosenLot && this.chosenLot.title=="Ma bibliothèque") data.type='Ma bibliothèque'
        this.updateLotEvent.emit(data)
        this.messageService.add({ key: 'toast', severity: 'success', summary: "Mis à jour un dossier", detail: "Le Dossier a été bien mis à jour", life: 5000 });
        this.closePostSlider(false);
        },
        (err)=>{
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur de mis à jour", detail: "Erreur serveur", life: 5000 });
        })
    )
  }
// ************cstb**************
  public onSelectionChange(val) {
    if(this.value !== val) {
      this.value = val;
    }
  }
  redirectToPricingTable(){
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
  }
  public setCctpText(text){
    if(text==null) this.post.cctp='';
    else {
      this.postForm.patchValue({cctp: text})
      this.post.cctp=text
    }
  }
  public presetFields(){
    if((this.gid||"") != ""){
      $('.cstb-content input:checkbox ').prop('checked', true)
      $('.cstb-content .folder-checkbox').prop('checked', false)
      $(".cstb-content input[type='radio']").get().reverse().forEach(a=>{$(a).prop("checked", true);});
    }
  }
  public createHtmlGenerality(poste,level,index){
    let cstbText = '';
    if((poste.children||[]).length) {
      let id=`${level}.${index}`
      let newTitle=poste.content.slice(0, 4) + `style='margin-left:14px !important' id="${id}"` + poste.content.slice(4);
      
      cstbText +=`<input class='folder-checkbox' type="checkbox" id="${id}" style="position:absolute;padding-top: 3px;">` +newTitle
      this.children.push({key:id,poste:{cctp:poste,title:newTitle}})
    }
    else cstbText +=poste.content;

    for(let i=0; i<(poste.children||[]).length; i++) {
      let cctpLevel = poste.children[i];
      cstbText += `<div class="cctp-section">
        ${this.createHtmlGenerality(cctpLevel,level+1,i)}`
    }
    for(let i=0; i<(poste.children||[]).length; i++) {
      cstbText += "</div>";
    }
    return cstbText
  }
  public selectPost(postes){
    if(postes[0]){
      this.getCSTB(postes[0])
      this.gid=postes[0].gid
      this.cstbNav.goBack();
    }
  }
  public removeCstbId(){
    let gid = this.gid
    if(gid) this.cstbNav.getCstbLot({gid : gid.split("-")[0]})
    else this.cstbNav.getAllCstbLotNoChildren();
    setTimeout(()=>{this.gid='';
    },500)
  }
  // get cstb from gid post *****
  public getCSTB(post){
    if(this.isPost && post.gid){
      this.lodingCstb = true;
      this.subscriptions.push(
        this.dpgfPostesService.getCCTPCSTBFromElastic(post.gid,"5fa00f120df1ff44504a8cb7").subscribe((res)=>{
          this.lodingCstb = false;
          this.poste = res.data;
          this.error = 0;
          this.formatPosteCSTB()
          this.cd.markForCheck();
        },
        err => {
          console.log(err)
          this.error = err.status
          this.lodingCstb = false;
          this.cstb = "";
          this.cd.markForCheck();
        })
      )
    }
  }

  public formatPosteCSTB() {
    //this.poste.variantes[0]
    let cstbText = "";
    if(Array.isArray(this.poste?.cctp)){
      this.choosedCstb=true;
      cstbText = this.createHtml({content : "" , children : this.poste.cctp})
    }else{
      this.choosedCstb=false;
      this.children=[]
      this.cstbToImport='';
      cstbText = this.createHtmlGenerality(this.poste.cctp,0,0)
    }
    cstbText=cstbText.replace(/\.html/g,'')
    this.cstb = this.sanitizer.bypassSecurityTrustHtml(cstbText);
    setTimeout(()=>{
      this.presetFields()
      if(!this.authService.currentEnvironment){
        $('.cstb-content a').css( {"color":'blue','text-decoration': 'underline'} );
        $('.cstb-content a').attr("target","_blank");
      }else {
        $('.cstb-content a').css( {"cursor":'default',"color":"black"} );
        $('.cstb-content a').on("click", function(event) {
          event.preventDefault(); // Prevent the default behavior of the link
       })}
    },100)
    this.archiService.updateUserInfo({descView:this.userData.descView+1},this.userData._id).subscribe(
      (data)=>{
        this.userData.descView=this.userData.descView+1;
      }
    )
  }
  public validateCctp() {
    this.choosedCstb=true;
    this.cstbToImport=this.cstbToImport.replace(/\.html/g,'')
    this.cstb=this.sanitizer.bypassSecurityTrustHtml(this.cstbToImport);
    setTimeout(()=>{
      this.presetFields()
      if(!this.authService.currentEnvironment){
        $('.cstb-content a').css( {"color":'blue','text-decoration': 'underline'} );
        $('.cstb-content a').attr("target","_blank");
      }else {
        $('.cstb-content a').css( {"cursor":'default',"color":"black"} );
        $('.cstb-content a').on("click", function(event) {
          event.preventDefault(); // Prevent the default behavior of the link
       })}
     },100)
  }
  public createHtml(poste){
    let cstbText = '';
    cstbText += poste.content;
    for(let i=0; i<(poste.children||[]).length; i++) {
      let cctpLevel = poste.children[i];
      // style="margin-left:${i*15}px"
      cstbText += `<div class="cctp-section" >
        ${this.createHtml(cctpLevel)}`
    }
    for(let i=0; i<(poste.children||[]).length; i++) {
      cstbText += "</div>";
    }
    return cstbText;
  }

  // set gid post **********
  public generateCctp(type) {
    $.each($("input[type='checkbox']:checked + label"), function(){
      $(this).attr("selected", true);
    });
    $.each($("input[type='radio']:checked + label"), function(){
      $(this).attr("selected", true);
    });
    $.each($(".liradio:has(> input[type='radio']:checked)"), function(){
      $(this).attr("selected", true);
    });
    $.each($("textarea[id^='uis']"), function() {
      $(this).attr("value", $(this).val());
    })
    // html 2 json
    let cctpHtml = $('#cctp').html();
    let cctpJson = html2json(cctpHtml);
    // call to generation function
    let htmlGen = generateCCTP(cctpJson , 0);
    //remove first br
    htmlGen = htmlGen.replace("<h3><br>","<h3>")
    // Update statique cctp value
    this.cctpEditValue = htmlGen
    if(type == "replace"){
      // replace post cctp
      let newPost=this.post;
      newPost.cctp=this.cctpEditValue;
      newPost.gid=this.gid;
      this.archiDbService.updatePost(this.lot._id,this.post._id,newPost).subscribe()
    }else{
      let newPost=this.post;
      if(!this.post.cctp) newPost.cctp=this.cctpEditValue;
      else newPost.cctp=(this.post.cctp||"") + (((this.cctpEditValue||"")=="")?"":" <br><br>") + this.cctpEditValue
      this.archiDbService.updatePost(this.lot._id,this.post._id,newPost).subscribe()
      // this.store.dispatch(actions.attachCctpText(this.cctpEditValue));
    }
    this.onSelectionChange('statique');
  }
  //********
  public searchPost(){
    this.dpgfPostesService.searchElasticSearchPostsCSTB(this.searchTerm,13)
            .subscribe(res => {
              this.results=res.cstb;
              this.results=this.results.filter((post)=>post.type=='poste')
              this.cd.markForCheck();
            })
  }
  
  public selectedPost(i){
    this.searchTerm='';
    this.cctpEdit = this.results[i];
    this.gid = this.results[i].gid || "";
    this.getCSTB(this.results[i]);
  }
  public openDropdown(){
    if(this.licence=='without') $('#upgrade-modal').modal("show");
    else $('#lot-dropdown').toggle()
  }
  public activateTitleInput(){
    this.editing=true;
    setTimeout(()=>$('.title-input').focus(),10)
  }
  // search unity
  public search(val){
    $('#myInputautocomplete-list').show();
    if(this.autoSuggestOptions.length != 0){
      this.results = this.autoSuggestOptions.filter(sugg => (sugg.toLowerCase().indexOf(val.toLowerCase())!=-1)).slice(0,6)
    }
  }
  public choose(val){
    this.postForm.patchValue({unity:val});
    this.results = [];
  }
  public focusedOutOn(){
    $('#myInputautocomplete-list').hide();
  }
  //
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }
}
