import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
// Servies
import { ArtisanService } from 'src/app/_services';
/// RxJs
import { Subscription } from 'rxjs';
import {NgSharedModule} from 'src/app/all/ngshared.module'

import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { BoosterProfilePageRealisationsComponent } from 'src/app/artisan/booster-profile-page-components/booster-profile-page-realisations/booster-profile-page-realisations.component';
import { BoosterProfilePageDemndesRecommandationComponent } from 'src/app/artisan/booster-profile-page-components/booster-profile-page-demndes-recommandation/booster-profile-page-demndes-recommandation.component';
import { BoosterProfileInfoSideBarComponent } from 'src/app/artisan/booster-profile-page-components/booster-profile-info-side-bar/booster-profile-info-side-bar.component';
import { ConfirmModalComponent } from 'src/app/all/confirm-modal/confirm-modal.component';
import { BoosterProfileEditInformationModalComponent } from 'src/app/artisan/booster-profile-page-components/booster-profile-edit-information-modal/booster-profile-edit-information-modal.component';
import { BoosterProfileEditAgenyNameModalComponent } from 'src/app/artisan/booster-profile-page-components/booster-profile-edit-ageny-name-modal/booster-profile-edit-ageny-name-modal.component';
import { BoosterProfileEditGarantieDecennaleModalComponent } from 'src/app/artisan/booster-profile-page-components/booster-profile-edit-garantie-decennale-modal/booster-profile-edit-garantie-decennale-modal.component';
import { BoosterProfileEditDocumentsModalComponent } from 'src/app/artisan/booster-profile-page-components/booster-profile-edit-documents-modal/booster-profile-edit-documents-modal.component';
import { BoosterProfileEditConstructionCarboneModalComponent } from 'src/app/artisan/booster-profile-page-components/booster-profile-edit-construction-carbone-modal/booster-profile-edit-construction-carbone-modal.component';
import { BoosterProfileEditPreCisionsAgloBoosterModalComponent } from 'src/app/artisan/booster-profile-page-components/booster-profile-edit-precisions-aglo-booster-modal/booster-profile-edit-precisions-aglo-booster-modal.component';
import { BoosterProfileGarantieDecennaleViewOnlyComponent } from 'src/app/artisan/booster-profile-page-components/booster-profile-garantie-decennale-view-only/booster-profile-garantie-decennale-view-only.component';

declare var $: any;
@Component({
    selector: 'app-booster-profile-page',
    imports:[
    SpinnerComponent,
    HeaderComponent,
    BoosterProfilePageRealisationsComponent,
    BoosterProfilePageDemndesRecommandationComponent,
    BoosterProfileInfoSideBarComponent,
    ConfirmModalComponent,
    BoosterProfileEditInformationModalComponent,
    BoosterProfileEditAgenyNameModalComponent,
    BoosterProfileEditGarantieDecennaleModalComponent,
    BoosterProfileEditDocumentsModalComponent,
    BoosterProfileEditConstructionCarboneModalComponent,
    BoosterProfileEditPreCisionsAgloBoosterModalComponent,
    BoosterProfileGarantieDecennaleViewOnlyComponent,
    NgSharedModule
    ],
    templateUrl: './booster-profile-page.component.html',
    styleUrls: ['./booster-profile-page.component.scss'],
    standalone: true,
    
})
export class BoosterProfilePageComponent implements OnInit {

  public loading: boolean = false;
  private artisanId: string = "";
  public seletedTab: string = "relisations";

  // Sidebar Modals Mapper
  private modalsIdsMappers: any = {
    "removeAgencyLogo": "#confirm-remove-logo",
    "agencyName": "#booster-profile-edit-agency-name-modal",
    "information": "#booster-profile-edit-agency-information-modal",
    "garantieDecennale": "#booster-profile-edit-agency-garantie-decennale-modal",
    "documents": "#booster-profile-edit-agency-documents-modal",
    "constructionCarbone": "#booster-profile-edit-agency-construction-carbone-modal",
    "precisionsAgloBooster": "#booster-profile-edit-agency-precision-modal",
    "garantieDecennaleViewOnly": "#garantie-decennale-view-only-modal"
  };
  
  // Inputs Data
  public albums: any = [];
  public artisanPhotos: any = [];

  public recommandation: any = [];
  public sideBarData: any = {
    // general Info
    nomPrenom: "",
    logo: {},
    type: [],
    programme: [],
    creationYearAgency: "",
    ca: "",
    // Docs
    kbis: {},
    files: [],
    assuranceFile: {},
    photos: [],
    // corpsDetat
    corpsDetat: [],
    // currentData
    workAreaMain: [],
    domaineSpe: "",
  };
  public editInformationModalData: any = {
    travaux: [],
    programme: [],
    creationYearAgency: "",
    ca: "",
  };
  public docsModalData: any = {
    assuranceFile: {},
    kbis: {},
    files: [], // attestations certifications
    photos: [],
  };
  public reemploiModalData: any = {
    materiauxIssusReemploi: [],
    materiauxBasCarbones: []
  };
  public precisionsAgloBoosterModalData: any = {
    domaineSpe: "",
    workAreaMain: [],
  };
  public workAreaMain: any = [];

  // corpsDetat
  corpsDetat = [];

  private subscriptions: Array<Subscription> = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private artisanService: ArtisanService
  ) { }

  ngOnInit(): void {
    this.getQueryParams();
  }
  private getQueryParams(){
    let currentUser = this.getCurrentUser();

    let authUserType = undefined;
    if(currentUser.artisanId && currentUser.artisanId != ""){
      authUserType = "artisan"
    }else if(currentUser.isAdmin){
      authUserType = "admin";
    }    

    this.activatedRoute.queryParams
      .subscribe((params: Params) => {
        /// if admin logged In
        if(authUserType == 'admin' && params["artisanId"]){
          this.artisanId = params["artisanId"];
        }else{ /// if artisan logged In
          this.artisanId = currentUser.artisanId;    
        }
        this.getArtisanProfile();
      }
    );
  }

  public toggleTab(tab){
    this.seletedTab = tab;
  }
  private getCurrentUser(){
    var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};

    return currentUser;
  }
  private getArtisanProfile(){
    this.loading = true;
    this.subscriptions.push(
      this.artisanService.getArtisanProfile(this.artisanId)
        .subscribe(
          data => {
            this.setData(data);
            this.loading = false;
          },
          error => {
            console.log("getArtisanProfile : ", error);
          })
    );
  }
  private updateArtisan(newArtisanData, loadProfileAgain=true){
    this.artisanService.update(this.artisanId, newArtisanData)
      .subscribe(
        data => {
          $(".modal").modal("hide");
          if(loadProfileAgain){
            this.getArtisanProfile();
          }
        },
        error => {
          console.log("updateArtisan : ", error);            
        });
  }
  // data for information, documents ...
  private setData(artisan){
    /// Albums Tab
    this.albums = artisan["albums"];
    this.artisanPhotos = artisan["photos"];

    /// Recommandations Tab
    this.recommandation = artisan["recommandation"];

    // Sidebar
    // this.sideBarData["nomPrenom"] = artisan["nomPrenom"];
    this.sideBarData["name"] = artisan["name"];    
    this.sideBarData["logo"] = artisan["logo"];
    this.sideBarData["type"] = artisan["type"];
    this.sideBarData["travaux"] = artisan["travaux"];
    this.sideBarData["programme"] = artisan["programme"];
    this.sideBarData["creationYearAgency"] = artisan["creationYearAgency"];
    this.sideBarData["ca"] = artisan["ca"];
    this.sideBarData["kbis"] = artisan["kbis"];
    this.sideBarData["files"] = artisan["files"];
    this.sideBarData["assuranceFile"] = artisan["assuranceFile"];
    this.sideBarData["photos"] = artisan["photos"];
    this.sideBarData["attestationsCertification"] = artisan["attestationsCertification"];   

    this.sideBarData["materiauxIssusReemploi"] = artisan["materiauxIssusReemploi"];
    this.sideBarData["materiauxBasCarbones"] = artisan["materiauxBasCarbones"];
    this.sideBarData["corpsDetat"] = artisan["corpsDetat"];
    this.sideBarData["workAreaMain"] = artisan["workAreaMain"];
    this.sideBarData["domaineSpe"] = artisan["domaineSpe"];   

    //////////////////
    // Modals
    //////////////////

    /// Information
    this.editInformationModalData["travaux"] = artisan["travaux"];
    this.editInformationModalData["programme"] = artisan["programme"];
    this.editInformationModalData["creationYearAgency"] = artisan["creationYearAgency"];
    this.editInformationModalData["ca"] = artisan["ca"];

    /// Documents
    this.docsModalData["assuranceFile"] = artisan["assuranceFile"];
    this.docsModalData["kbis"] = artisan["kbis"];
    this.docsModalData["files"] = artisan["files"];
    this.docsModalData["attestationsCertification"] = artisan["attestationsCertification"];

    /// Construction bas carbone
    this.reemploiModalData["materiauxIssusReemploi"] = artisan["materiauxIssusReemploi"];
    this.reemploiModalData["materiauxBasCarbones"] = artisan["materiauxBasCarbones"];

    // Précisions AGLO BOOSTER
    this.precisionsAgloBoosterModalData["domaineSpe"] = artisan["domaineSpe"];
    this.precisionsAgloBoosterModalData["workAreaMain"] = artisan["workAreaMain"];

    this.corpsDetat = artisan["corpsDetat"];
  }

  public saveAlbums(albums){
    this.updateArtisan({albums}, false);
  }
  public savePhotos(photos){
    this.updateArtisan({photos}, false);
  }

  public saveRecommandations(recomandations){
    this.recommandation = recomandations;

    this.updateArtisan({recommandation: recomandations});
  }

  // Sidebar Events
  public openSideBarModals(modalType){
    if(this.modalsIdsMappers[modalType]){
      let modalId = this.modalsIdsMappers[modalType];
      
      $(modalId).modal("show");
    }
  }
  public updateModalInformation(newData){
    this.updateArtisan(newData);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
