<div>
    <app-header ></app-header>
    <div class="flex padding-top-15">
        <div class="cursor wid50-text-center font-size-18-color-blue" [ngClass]="{active: archiOrUser=='users'}" (click)="choose('users')">Utilisateurs</div>
        <div class="cursor wid50-text-center font-size-18-color-blue" [ngClass]="{active: archiOrUser=='architects'}" (click)="choose('architects')">Groupes/Départements</div>
    </div>
    <div>
        <div *ngIf="archiOrUser=='users'">
            <app-admin-users></app-admin-users>
            <!-- <app-admin-architects></app-admin-architects> -->

        </div>
        <div *ngIf="archiOrUser=='architects'" >
            <app-admin-departement></app-admin-departement>
        </div>
    </div>
</div>