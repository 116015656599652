import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, NO_ERRORS_SCHEMA } from '@angular/core';
import { Store } from '@ngrx/store';
import { DpgfPostesService, AuthService } from 'src/app/_services';
import { WebView2Service } from 'src/app/_services/webview2.service.service';
import { MessageService } from 'primeng/api';
import {  Subscription } from 'rxjs';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { InputDpgfComponent } from '../input-dpgf/input-dpgf.component';
import { CstbNavigationComponent } from 'src/app/all/cstb/cstb-navigation/cstb-navigation.component';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';
import * as actions from '../_store/dpgf.actions';

const getId = (type) => {
  return type + '_' + Math.random().toString(36).substr(2, 9);
}

@Component({
  standalone:true,
  selector: 'app-revit-to-post',
  imports:[
    DpgfModule, 
    SpinnerComponent, 
    InputDpgfComponent,
    CstbNavigationComponent,
    NgSharedModule
  ],
  templateUrl: './revit-to-post.component.html',
  styleUrls: ['./revit-to-post.component.scss']
})
export class RevitToPostComponent implements OnInit {
  @Input() dpgfId: string;
  @Input() postToRevitSliderOpen: string;
  @Input() lots: Array<any>;
  @Output() openRevitToPostSlider = new EventEmitter<any>();
  private subscriptions: Array<Subscription> = [];
  public postes: any;
  public openSlider: boolean = false;
  public openPostesSlider: boolean = false;
  public displaySideCstb: boolean = false;
  public loadingPostes: boolean = false;
  public selectedRevitElements: Array<any> = [];
  public selectImportLot: any;
  public importLotPostes: Array<any> = [];
  public searchTermCstb: string = "";
  public selectedPostesCstb : Array<any> = [];
  public units: Array<any> = [
    { value: 'ens', label: 'Ens' },
    { value: 'm', label: 'm' },
    { value: 'm2', label: 'm2' },
    { value: 'm3', label: 'm3' }
  ];
  public selectedUnit:string = "";
  public loaders: any = {
    importing: false,
  }
  isRevit: boolean;
  public searchTerm;
  results: any;

  constructor(
    private cd: ChangeDetectorRef,
    public authService: AuthService,
    private DpgfPostesService: DpgfPostesService,
    private wv2Service: WebView2Service,
    private store: Store,
    private messageService: MessageService,) {
  }

  ngOnInit(): void {
    this.isRevit = navigator.userAgent.includes("Autodesk.Revit")
    this.subscribeElementRevitOnClick()
  }

  public subscribeElementRevitOnClick() {
    this.wv2Service.subscribeToWebView2Event("selectionchanged", (e) => {
      let revitElements = [...e.detail];
      if (this.postToRevitSliderOpen) return;
      if (revitElements.length == 0) return;
      // if (revitElements.length > 1) {
      //   this._notificationService.error('Erreur', "Merci de séléctionner un seul element BIM", { timeOut: 10000 })
      //   return;
      // }
      this.openSlider = true;
      this.selectedRevitElements = revitElements;
      this.openRevitToPostSlider.emit(this.openSlider)
      this.getpostes()
    });
  }
  public getpostes() {
    let elmentsId = this.selectedRevitElements.map(el => el.id).join(",");
    this.loadingPostes = true
    this.subscriptions.push(
      this.wv2Service.getPostesUsingElementId(this.dpgfId, elmentsId).subscribe(
        (res) => {
          let lots = []
          this.lots.forEach((lot, indexLot) => {
            lots.push({
              color: lot.color, id: lot.id, title: lot.title, key: lot.key, icon: lot.icon, indexLot: indexLot,
              sousLots: lot.sousLots, postes: (res.find(poste => poste._id == lot.id) || { postes: [] }).postes
            })
          })
          this.postes = lots;
          this.loadingPostes = false
          this.cd.detectChanges();
        },
        (err) => {
          this.loadingPostes = false
          console.log(err.message);
        }
      ));
  }
  public addNewPost(lotId) {
    const dpgfMaxPosts = this.authService.premuimFeatures[this.authService.licence].lineNb;
    const id = getId('poste');
    const poste = {
      "id": id,
      "designation": "",
      "localisation": "",
      "unite": "ens",
      "qte": this.selectedRevitElements.length,
      "tva": 20,
      "price": null,
      "unitePrice": null,
      "comment": [],
      "postType": "",
      "postIniesCount": 0,
      "selected": false,
      "pricettcArchi": 0,
      "revitIcon":true

    };

    this.subscriptions.push(
      this.wv2Service.addPoste(this.dpgfId, lotId, { lotId, sousLotI: 0, post: [poste], bimElement: this.selectedRevitElements })
        .subscribe(
          (res) => {
            //this.store.dispatch(actions.loadDpgfLot({ lotId, dpgfId: this.dpgfId }));
            this.getpostes();
            this.cd.detectChanges();
          })
    );
    if(!environment.onPremise)window.analytics.track('create_post');
  }
  public searchPost(){
    this.DpgfPostesService.searchElasticSearchPostsCSTB(this.searchTerm,13)
            .subscribe(res => {
              this.results=res.cstb;
              this.cd.markForCheck();
            })
  }
  public importCstbPostes() {
    this.loaders.importingcstb = true;
    this.searchTerm='';
    let postes = this.selectedPostesCstb.map(sp=>{
      return {
        "id":  getId('poste'),
        "designation": sp.title,
        "gid" : sp.gid,
        "localisation": "",
        "unite": "ens",
        "qte": this.selectedRevitElements.length,
        "tva": 20,
        "price": null,
        "unitePrice": null,
        "comment": [],
        "postType": "",
        "postIniesCount": 0,
        "selected": false,
        "pricettcArchi": 0,
        "revitIcon":true
      }
    })
    this.subscriptions.push(
      this.wv2Service.addPoste(this.dpgfId, this.selectImportLot.id, { lotId : this.selectImportLot.id, sousLotI: 0, post: postes, bimElement: this.selectedRevitElements })
      .subscribe(
        (res) => {
            this.loaders.importingcstb = false;
            this.displaySideCstb = false;
            //this.store.dispatch(actions.loadDpgfLot({ lotId:this.selectImportLot.id, dpgfId: this.dpgfId }));
            this.getpostes();
            this.cd.detectChanges();
          })
    );
  }
  public selectPosteCstb(e) {
    this.selectedPostesCstb=e;
  }
  public selectedPost(post){
    let posti={gid:post.gid,title:post.sugg}
    this.selectedPostesCstb.push(posti);
  }
  public savePosteData(event, {posteId, loti, sousLoti, posti, dpgfPostIndex, lotId }) {
    this.postes[loti].postes[posti].designation = event.word;
    this.subscriptions.push(
      this.wv2Service.updatePost(posteId, this.dpgfId, { lotId, sousLoti, posti: dpgfPostIndex, designation: event.word }).subscribe(
        (res) => {
          this.store.dispatch(actions.loadDpgfLot({ lotId, dpgfId: this.dpgfId }));
          //this.getpostes();
        }
      )
    )
  }
  public changePostUnit(unite, { posteId ,loti, sousLoti, posti, dpgfPostIndex, lotId }) {
    this.subscriptions.push(
      this.wv2Service.updatePost(posteId, this.dpgfId, { lotId, sousLoti, posti: dpgfPostIndex, unite: unite })
      .subscribe(
        (res) => {
          this.postes[loti].postes[posti].qte = res.computedQte;
          this.cd.detectChanges();
          //this.store.dispatch(actions.loadDpgfLot({ lotId, dpgfId: this.dpgfId }));
          //this.getpostes();
        }
      )
    )
  }
  public detachElementFromPost(unite, { posteId ,loti, sousLoti, posti, dpgfPostIndex, lotId }) {
    let elmentsId = this.selectedRevitElements.map(el => el.id);
    this.subscriptions.push(
      this.wv2Service.detachElementFromPost(this.dpgfId, {poste:posteId, elmentsId : elmentsId})
      .subscribe(
        (res)=>{
          this.changePostUnit(unite, { posteId ,loti, sousLoti, posti, dpgfPostIndex, lotId });
          this.getpostes();
        }
      )
    )   
  }
  public lineNumber(loti, sousloti, posti) {
    let res = (loti) + '.' + (sousloti) + '.' + (posti + 1);
    return res;
  }
  public closeRevitSide() {
    this.openSlider = false;
    this.openRevitToPostSlider.emit(this.openSlider)
    $(".p-component-overlay").css("display","none");
  }
  public openCstb(lot){
    this.displaySideCstb = true;
    this.selectImportLot = lot;
  }
  //Select Post 
  public openSelect(lot) {
    this.openPostesSlider = true;
    this.selectImportLot = lot;
    this.getAllLotPoste()
  }
  public getAllLotPoste() {
    this.subscriptions.push(
      this.DpgfPostesService.getDpgfLotPostes(this.dpgfId, this.selectImportLot.id).subscribe(
        (res) => {
          this.importLotPostes = res.filter(p => (p?.postes?.designation || '') != '');
          this.cd.detectChanges();
        }
      )
    )
  }
  public attachSelectPostsToBim() {
    this.loaders.importing = true;
    let selectedPostes = this.importLotPostes.filter(p => p.checked)
    selectedPostes = selectedPostes.map(p => p.postes.id);
    this.subscriptions.push(
      this.wv2Service.attachPostsToBimElement(this.dpgfId, { postes: selectedPostes, elements: this.selectedRevitElements }).subscribe(
        (res) => {
          this.openPostesSlider = false;
          this.loaders.importing = false;
          this.getpostes();
          //this.store.dispatch(actions.loadDpgfLot({ lotId, dpgfId: this.dpgfId }));
          //this.getpostes(this.selectedRevitElement.id);
        }
      )
    )
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}