<!-- App Header -->
<app-header></app-header>
<app-artisan-cgu-modal></app-artisan-cgu-modal>

<div class="page-wrapper main-page-white-lilac" *ngIf="loadingData">
  <app-spinner></app-spinner>
</div>

<div class="page-wrapper main-page-white-lilac" *ngIf="!loadingData">

  <!-- Upper Container -->
  <div class="upper-back-container">
    <p class="no-padding pointer" (click)="goToConsultationsPage()">
      <img src="assets/arrow-left.svg">
      Retour
    </p>
  </div>
  <!-- END Upper Container -->

  <!-- Project Information Cards -->
  <div class="project-cards-info-container container">
    <div class="row">
      
      <!-- Left Side -->
      <div class="left-side col-md-6">
        
        <!-- Information du Project Card
        ===================================== -->
        <div class="ag-card-1 margin-bottom-24 no-border horizontal-items space-between">
          <div class="width-60">
            <h5 class="ag-heading-h5 margin-bottom-8">Informations du projet</h5>
            <div>            
              <p class="horizontal-items space-between">
                <span>Type de travaux</span>
                <span>{{(invite.ceProject ||{}).natureTravaux}} de {{(invite.ceProject ||{}).type}}</span>
              </p>
              <p class="horizontal-items space-between">
                <span>Surface</span>
                <span>{{(invite.ceProject ||{}).surface}} m2</span>
              </p>
              <p class="horizontal-items space-between">
                <span>Lieu</span>
                <span>{{(invite.ceProject ||{}).adresse}}</span>
              </p>

            </div>
          </div>
          <!-- Folder Icon -->
          <div class="width-40 flex-middle" *ngIf="!showDossierConsultationCard">
            <div class="padding-top-15 pointer" (click)="openProject()">
              <img src="assets/blue-folder-icon.svg">
              <p class="ag-weight-700 margin-top-6">Voir le projet</p>  
            </div>
          </div>
          <!-- END Folder Icon -->
        </div>
        <!-- END Information du Project Card
        ===================================== -->

        <!-- Dossier de consultation Card
        ===================================== -->
        <div class="ag-card-1 margin-bottom-24 no-border horizontal-items space-between"
          *ngIf="showDossierConsultationCard && !showArtisanRefusedCard">
          <div class="width-60">
            <h5 class="ag-heading-h5 margin-bottom-8">Dossier de consultation</h5>
            <p>Vous pouvez désormais chiffrer le projet</p>
          </div>
          
          <div class="width-40 flex-middle" *ngIf="!showArchitectRefusedCard">
            <div class=" text-right">
              <button class="ag-btn-blue pointer" (click)="openChiffragePage()">Chiffrer le projet</button>
            </div>
          </div>
        </div>
        <!-- END Dossier de consultation Card
        ===================================== -->

        <!-- accepté votre chiffrage Card
        ===================================== -->
        <div class="ag-card-1 margin-bottom-24 no-border horizontal-items space-between"
          *ngIf="showArchitectAcceptedChiffrageCard">
          <div class="width-60">
            <p><b>{{ invite.architect.name }} a accepté votre chiffrage</b></p>
          </div>
          <!-- green check Icon -->
          <div class="width-40 text-right">
            <img src="assets/green-check-icon.svg">
          </div>
          <!-- END green check Icon -->
        </div>
        <!-- END accepté votre chiffrage Card
        ===================================== -->

        <!-- architect refuse votre chiffrage Card
        ===================================== -->
        <div class="ag-card-1 margin-bottom-24 no-border horizontal-items space-between"
          *ngIf="showArchitectRefusedCard">
          <div class="width-60">
            <p><b>{{ invite?.architect?.name || "" }} n’a pas retenu votre chiffrage</b></p>
          </div>
          <!-- green check Icon -->
          <div class="width-40 text-right">
            <img src="assets/close-red.svg">
          </div>
          <!-- END green check Icon -->
        </div>
        <!-- END architect refuse votre chiffrage Card
        ===================================== -->


        <!-- Artisan refusé chiffrage Card
        ===================================== -->
        <div class="ag-card-1 margin-bottom-24 no-border horizontal-items space-between" 
          *ngIf="showArtisanRefusedCard">
          <div class="width-60">
            <p><b>Vous avez refusé le projet</b></p>
          </div>
          <!-- green check Icon -->
          <div class="width-40 text-right">
            <img src="assets/close-red.svg">
          </div>
          <!-- END green check Icon -->
        </div>
        <!-- END Artisan refusé chiffrage Card
        ===================================== -->

        <!-- Chantier Card
        ===================================== -->
        <div class="chantier-card ag-card-1 no-border"
          *ngIf="showChantierCard">
          <h5 class="ag-heading-h5 margin-bottom-8">Chantier</h5>
          <p class="ag-para-4 margin-bottom-16">Vous avez remporté le marché, transmettez votre première situation de chantier au maître d’oeuvre afin de le notifier de votre avancement.</p>
          <!-- <p class="ag-para-1 ag-weight-700 margin-bottom-16">Situation(s)</p> -->

          <app-facture-list [factures]="inviteFactures" [ceProjectInviteId]="ceProjectInviteId" owner="artisan"></app-facture-list>
          <!-- <div class="chantier-list-container flex space-between"> -->
            <div class="chantier-list-container flex space-between">
            

            <!-- <div class="chantier-item">
              <div class="text-center">
                <p class="chantier-status">Envoyée</p>
                <h4 class="chantier-progress no-margin">12 %</h4>
                <p class="chantier-date no-margin">18 octobre 2021</p>
                <button class="ag-btn-bordered-xs open-chantier-btn">Ouvrir</button>
              </div>
            </div>
            <div class="chantier-item">
              <div class="text-center">
                <p class="chantier-status accepte">Envoyée</p>
                <h4 class="chantier-progress no-margin">12 %</h4>
                <p class="chantier-date no-margin">18 octobre 2021</p>
                <button class="ag-btn-bordered-xs open-chantier-btn">Ouvrir</button>
              </div>
            </div>

            <div class="chantier-item add-new-chantier-widget pointer flex-middle">
              <div class="text-center">
                <img src="assets/add-icon.svg">
                <p>Ajouter une situation</p>
              </div>
            </div> -->

          </div>
          
        </div>
        <!-- END Chantier Card
        ===================================== -->
      </div>
      <!-- END Left Side -->

      <!-- Right Side
        ======================================= -->
      <div class="right-side col-md-6">

        <!-- Historique des évènements -->
        <div class="ag-card-1">
          <h5 class="ag-heading-h5 margin-bottom-8">Historique des évènements</h5>

          <div class="history-list-container">
            
            <!-- <div class="single-history-item flex" *ngFor="let event of invite.eventsHistory"> -->
              <div class="single-history-item flex" *ngFor="let event of eventsHistory; let last = last;">
              <div class="item-status width-10">
                <span class="status-dot" [style.backgroundColor]="event.circleColor || '#0275D8'"></span>
                <div class="vertical-line" *ngIf="!last"></div>
              </div>
              <div class="item-description width-90">
                <p class="ag-para-1 title">{{ event.text || "" }}</p>
                <p class="ag-para-1 margin-top-4 date" *ngIf="event.date"> {{ event.date | date: 'dd/MM/yyyy' }}</p>
              </div>
            </div>

          </div>

        </div>
        <!-- END Historique des évènements -->


      </div>
      <!-- END Right Side -->

    </div>
  </div>
  <!-- END Project Information Cards -->

</div>




<!-- TODO: TO BE REMOVED :'( -->
<div class="all" *ngIf="false">
  <app-spinner *ngIf="loadingData"></app-spinner>

  <div *ngIf="!loadingData" class="container" >
    <div class="row">
      <div class="col-md-12">
        <div *ngIf="steps.step1" class="floating-card">
          <div class="relative">
            <div class="title-number">1</div>
            <h1>Informations du projet</h1>
          </div>
          <div class="row">
            <table>
              <tr>
                <td class="tilte-table">Nom de l'architecte</td>
                <td>{{(invite.architect ||{}).name}}</td>
              </tr>
              <tr>
                <td class="tilte-table">Type de Travaux</td>
                <td>{{(invite.ceProject ||{}).natureTravaux}} {{(invite.ceProject ||{}).type}}</td>
              </tr>
              <tr>
                <td class="tilte-table">Surface</td>
                <td>{{(invite.ceProject ||{}).surface}} m2</td>
              </tr>
              <tr>
                <td class="tilte-table">Adresse</td>
                <td>{{(invite.ceProject ||{}).adresse}}</td>
              </tr>
            </table>
          </div>
          <hr>
          <div class="row" *ngIf="invite.status == 'artisan-invite'">
            <div class="initial-choise">
              <div class="col-md-6">
                <button [disabled]="setLoading" (click)="refuseInvite()" class="btn-no">
                  <div *ngIf="!setLoading">Refuser le projet</div>
                  <app-spinner *ngIf="setLoading"></app-spinner>
                </button>
              </div>
              <div class="col-md-6">
                <button [disabled]="setLoading" (click)="acceptInvite()"  class="btn-yes">
                  <div *ngIf="!setLoading">Accepter le projet</div>
                  <app-spinner *ngIf="setLoading"></app-spinner>
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="invite.status == 'artisan-refuser'" class="center">
            <div class='red-alert'><i class="fa fa-window-close-o" aria-hidden="true"></i> Vous avez refusé le projet.</div>
          </div>
          <div *ngIf="invite.status != 'artisan-invite' && invite.status != 'artisan-refuser' " class="center">
            <div class='green-alert'><i class="fa fa-check-square-o" aria-hidden="true"></i> Vous avez accepté le projet.</div>
          </div>
        </div>

        <div *ngIf="steps.step3" class="floating-card">
          <div class="relative">
            <div class="title-number">2</div>
            <h1>Dossier de consultation</h1>
          </div>
          <div *ngIf="!invite.dpgfSent">
            L'architecte n'a pas encore communiqué le DCE.
          </div>
          <div *ngIf="invite.pricingStatus == 'pricing-sent'">
            L'architecte vous a communiqué son DCE, merci de chiffrer le projet.
            <a [routerLink]="['/artisan','dpgf', invite.dpgfPricing._id]" [queryParams]="{'code':invite.dpgfPricing.code,'app':true}">
              <button class="btn-primary-color" type="button" name="button">
                Chiffrer le projet
              </button>
            </a>
          </div>
          <div *ngIf="invite.pricingStatus == 'pricing-done'">
            Vous avez terminé votre chiffrage
            <a [routerLink]="['/artisan','dpgf', invite.dpgfPricing._id]" [queryParams]="{'code':invite.dpgfPricing.code,'app':true}">
              <button class="btn-secondary-border" type="button" name="button">
                Accéder au chiffrage
              </button>
            </a>
          </div>
          <div *ngIf="invite.status == 'signe'">
            L'architecte a retenu votre chiffrage.
          </div>
        </div>
        <div *ngIf="steps.step4 && invite.status == 'selectionne'" class="floating-card" >
          <div class="relative">
            <div class="title-number">4</div>
            <h1>Gestion de chantier</h1>
          </div>
          <!-- @mohcine ajouter les lots sur les quels l'entreprise à été retenu  -->
          L'architecte a sélectionné votre chiffrage. Attendez la signature pour pouvoir commencer la gestion de votre chantier !
        </div>
        <div *ngIf="steps.step4 && invite.status == 'signe'" class="floating-card" >
          <div class="relative">
            <div class="title-number">3</div>
            <h1>Gestion de chantier</h1>
          </div>
          <div *ngIf="inviteFactures.length == 0 && !loadingFactures" class="alert alert-success">
            <strong>Félicitations!</strong> L'architecte s'est engagé avec vous. Vous pouvez dès maintenant créez vos situations de chantier et les envoyer pour validation à l'architecte
          </div>
          <div *ngIf="!loadingFactures">
            <app-facture-list [factures]="inviteFactures" [ceProjectInviteId]="ceProjectInviteId" owner="artisan"></app-facture-list>
          </div>
          <div *ngIf="loadingFactures">
            <app-spinner></app-spinner>
          </div>
        </div>
        <div *ngIf="invite.status == 'refuse'" class="center">
          <label class="question-label">L'architecte n'a pas choisi votre chiffrage.</label>
        </div>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog #cd>
  <p-footer>
        <button type="button" pButton class="reject-btn" icon="fa fa-close" label="Annuler" (click)="cd.reject()"></button>
        <button type="button" pButton class="confirm-btn" icon="fa fa-check" label="Confirmer le rendez-vous" (click)="cd.accept()"></button>
    </p-footer>
</p-confirmDialog>
