import { Component, OnInit, Input, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ChantierModule } from '../chantier.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';


@Component({
    selector: 'app-facture-graphic',
    imports:[
      ChantierModule,
      NgSharedModule
    ],
    templateUrl: './facture-graphic.component.html',
    styleUrls: ['./facture-graphic.component.scss'],
    providers: [DatePipe],
    standalone: true,

})
export class FactureGraphicComponent implements OnInit {

  @Input() graphicData;

  public data:any = [];
  public drawing:boolean = false;

  constructor(public datepipe: DatePipe) { }

  ngOnInit() {
    this.drawChart(this.graphicData);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.drawChart(changes.graphicData.currentValue)
  }

  public drawChart(graphicData) {
    this.drawing = true;
    let labels = graphicData.map(graph => this.datepipe.transform(graph.date, 'dd/MM/yyyy'));
    let datasets = [
      {
        label: 'Acompte (%)',
        data: graphicData.map(graph => graph.acompte.toFixed(2)),
        fill: false,
        borderColor: 'blue'
      }
    ]
    this.data = {
      labels,
      datasets
    }
    this.drawing = false;
  }

}
