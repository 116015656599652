import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { ArtisanService } from '../../_services/index';
import { AuthService } from '../../_services/index';
import { NgSharedModule } from '../ngshared.module';

declare var $:any;
declare var only:any;

@Component({
  standalone:true,
  selector: 'app-artisan-cgu-modal',
  imports:[NgSharedModule],
  templateUrl: './artisan-cgu-modal.component.html',
  styleUrls: ['./artisan-cgu-modal.component.scss']
})
export class ArtisanCguModalComponent implements OnInit {

  public cgu;
  public firstTimeInit=true;
  public artisanId: string = "";

  constructor(private artisanService: ArtisanService,
              public authService: AuthService) { }

  ngOnInit(): void {
    this.handleModal();
  }

  public handleModal(){
    const currentUser = (JSON.parse(localStorage.getItem("currentUser"))||{});
    const { artisanId } = currentUser;
    const AuthUserIsArtisan = !!Object.keys(currentUser).length && !!artisanId;

    if(!AuthUserIsArtisan) return; // if the auth user not artisan don't show the modal

    this.artisanId = artisanId;
    
    const cgu_2021 = (JSON.parse(localStorage.getItem("cgu_2021")) || {});
    const cgu_2021IsSet = Object.keys(cgu_2021).length ? true : false;
    const { isCGU } = cgu_2021;

    if(cgu_2021IsSet && isCGU){
      $('#accept-cgu-modal').modal('show');
    }else if(!cgu_2021IsSet){ // if the artisan didn't accept yet show the modal
      this.getArtisanData();
    }

    // if(!cgu_2021IsSet){
    //   this.getArtisanData();
    // }else if(isCGU){ // if the artisan didn't accept yet show the modal
    //   $('#accept-cgu-modal').modal('show');
    // }
  }

  // Get artisan data (cgu)
  public getArtisanData(){
    this.artisanService.getArtisanProfile(this.artisanId)
      .subscribe(
        data => {
          let cguObject = only(data,'cgu_2021'),storage;
          this.cgu=cguObject.cgu_2021;
          
          if(this.cgu) $('#accept-cgu-modal').modal('show');

          storage={"isCGU":this.cgu,"artisanId":this.artisanId}
          localStorage.setItem("cgu_2021",JSON.stringify(storage));
        })
  }
  public acceptCGU(){
    const artisanId = this.artisanId;    
    this.artisanService.setArtisanCGU(artisanId).subscribe(
      data=>{
        let storage={"isCGU":false,"artisanId":artisanId}
        localStorage.setItem("cgu_2021",JSON.stringify(storage));
        $("body").css("overflow","auto");
        $('#accept-cgu-modal').modal('toggle');
      }
  )
  }
}
