<app-spinner *ngIf="loading"></app-spinner>
<div *ngIf="!loading" class="lot">
  <div *ngIf="!firstTime">
      <div *ngIf="lots.sousLots && !lots.sousLots.length">
        Ce dossier est vide
      </div>
      <div *ngFor="let lot of lots.sousLots; let i = index">
          <div class="one-dce">
              <i *ngIf="lot.loading" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
              <i *ngIf="!lot.open && lot.sousLots && !lot.loading" (click)="openLotWithoutPost(lot._id,i)" class="fa fa-chevron-right" aria-hidden="true"></i>
              <i *ngIf="lot.open && lot.sousLots && !lot.loading" (click)="lot.open = false" class="fa fa-chevron-down" aria-hidden="true"></i>
              <i *ngIf="!lot.open && lot.sousLots && !lot.loading" class="fa fa-folder" aria-hidden="true"></i>
              <i *ngIf="lot.open && lot.sousLots && !lot.loading" class="fa fa-folder-open" aria-hidden="true"></i>
              <span *ngIf="!lot.loadingPost && !isDCE" class="main-lot" (click)="setChosenLot(lot)"> {{lot.title}} </span>
              <span *ngIf="!lot.loadingPost && isDCE" class="main-lot" (click)="sendPostToLot(lot,i)"> {{lot.title}} </span>
              <app-spinner class="loader" *ngIf="lot.loadingPost"></app-spinner>
              <div *ngIf="lot.open">
                  <app-archi-db-post [isDPGF]='isDPGF' [lots]=(newLots) [firstTime]="false" [post]="post" [isDCE]="isDCE" [lotInputNotShowId]="lotInputNotShowId" (chosenLotEvent1)='setChosenLot($event)' (currentLot)='sendToFather($event)' [currentLotChild]='currentLotChild'></app-archi-db-post>
              </div>
          </div>
      </div>
      <div *ngIf="writingFolder">
        <i class="fa fa-folder" aria-hidden="true"></i>
        <input (focusout)='addFolder($event,"focusout")' (keyup.enter)='addFolder($event,"enter")' [(ngModel)]="newFolderName">
      </div>
      <div class="one-dce" *ngIf="isDCE && !loading">
        <div class="add-folder" (click)='writingFolder=true'><i class="fa fa-plus" aria-hidden="true"></i> Ajouter Dossier</div>
      </div>
  </div>
  <div *ngIf="firstTime">
      <div *ngIf="lots && !lots.length">
        <h1 style="margin-left: 0px;margin-right: 70px;font-weight: normal;color: black;">Votre bibliothèque est vide, <a (click)="navigateArchiDb()">créez votre premier dossier.</a></h1>
      </div>
      <div *ngIf="!isDCE && lotInputNotShowId && !hideMyDb" class="one-dce">
        <i class="fa fa-folder-open" aria-hidden="true"></i>
        <span class="main-lot" (click)="sendPostToMonBDD()"> Ma bibliothèque </span>
      </div>
      <div *ngFor="let lot of lots; let i = index;">
          <div class="one-dce">
              <i *ngIf="lot.loading" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
              <i *ngIf="!lot.open && lot.sousLots && !lot.loading" (click)="openLotWithoutPost(lot._id,i)" class="fa fa-chevron-right" aria-hidden="true"></i>
              <i *ngIf="lot.open && lot.sousLots && !lot.loading" (click)="lot.open = false" class="fa fa-chevron-down" aria-hidden="true"></i>
              <i *ngIf="!lot.open && lot.sousLots && !lot.loading" class="fa fa-folder" aria-hidden="true"></i>
              <i *ngIf="lot.open && lot.sousLots && !lot.loading" class="fa fa-folder-open" aria-hidden="true"></i>
              <span *ngIf="!lot.loadingPost && !isDCE" class="main-lot" (click)="sendLotToLot(lot)"> {{lot.title}} </span>
              <span *ngIf="!lot.loadingPost && isDCE" class="main-lot" (click)="sendPostToLot(lot,i)"> {{lot.title}} </span>
              <app-spinner class="loader" *ngIf="lot.loadingPost && isDCE"></app-spinner>
              <ng-template [ngIf]="lot.open">
                  <app-archi-db-post [isDPGF]='isDPGF' [lots]=(newLots) [firstTime]="false" [post]="post" [isDCE]="isDCE" [lotInputNotShowId]="lotInputNotShowId" (chosenLotEvent1)='setChosenLot($event)' (currentLot)='sendToFather($event)' [currentLotChild]='currentLotChild'></app-archi-db-post>
              </ng-template>
          </div>
      </div>
      <div *ngIf="writingFolder">
        <i class="fa fa-folder" aria-hidden="true"></i>
        <input (focusout)='addFolder($event,"focusout")' (keyup.enter)='addFolder($event,"enter")' [(ngModel)]="newFolderName">
      </div>
      <div class="one-dce" *ngIf="isDCE && !loading && lots && lots.length">
        <div class="add-folder" (click)='writingFolder=true'><i class="fa fa-plus" aria-hidden="true"></i> Ajouter Dossier</div>
      </div>
  </div>
</div>
