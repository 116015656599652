<div id="consult-booster"
  class="modal fade choose-demand-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog no-padding margin-top-10vh">
    <div class="modal-container no-padding wid480 border-radius-16" style="background-color: white;">
      <div class="flex boder-bottom-silver space-between padding-top-bottom">
        <p class="title-consult padding-left-24 margin-0" *ngIf="pageName=='first' || pageName=='send-chiffrage'">Obtenir un chiffrage </p>
        <p class="title-consult padding-left-24 margin-0" *ngIf="pageName=='send-lot-to-artisan'">Batimut </p>
        <p class="title-consult padding-left-24 margin-0" *ngIf="pageName=='add-entreprise'">Ajouter une entreprise à mon carnet d’adresse </p>

        <img src="assets/close-icone-black.svg" class="cursor padding-right-25"  data-dismiss="modal">
      </div>
      <div *ngIf="pageName=='first'">
     <div class="padding-left-40 color-grey padding-top-10">
      <span class="current-title cursor padding-right-10" (click)="agloBossterOrCarnetAdress('agloBooster')" [ngClass]="{'selected-tab': AgloOrCarnet=='agloBooster'}">AGLO BOOSTER©</span>
      <span class="current-title cursor padding-left-12" (click)="agloBossterOrCarnetAdress('carnet')" [ngClass]="{'selected-tab': AgloOrCarnet=='carnet'}">Carnet d’adresse</span>
     </div>
     <div  *ngIf="AgloOrCarnet=='agloBooster'" >
     <div class="color-blue-river flex padding-top-10 justify-center border-bottom-silver">
      <div>
      <p class="font-weight-700 margin-bottom-0">Faites chiffrer des entreprises qualifiées et suivies par nos équipes</p>
      <p class="margin-bottom-8 padding-left-8">L’accès à notre réseau est inclus dans votre licence. <a style="text-decoration: underline;color:inherit;" href="https://www.aglo.ai/contact-aglo-booster/" target="_blank">  En savoir plus</a></p>
      <div class="margin-bottom-14">
      <span class="padding-left-14"  style="position: relative;"> <img src="assets/checked-icon.svg" style="left:-15px;top:0.5px;position:absolute;"> Entreprises <span class="font-weight-700"> recommandées et notées</span> par plusieurs architectes</span><br>
      <span class="padding-left-14"  style="position: relative;"> <img src="assets/checked-icon.svg" style="left:-15px;top:0.5px;position:absolute;"> Profil complet avec <span class="font-weight-700"> portfolio</span>, accessible en clin d’oeil sur AGLO</span><br>
      <span class="padding-left-14"  style="position: relative;"> <img src="assets/checked-icon.svg" style="left:-15px;top:0.5px;position:absolute;"> Dossier <span class="font-weight-700">administratif</span> prêt à être consulté</span><br>
      <span class="padding-left-14"  style="position: relative;"> <img src="assets/checked-icon.svg" style="left:-15px;top:0.5px;position:absolute;"> <span class="font-weight-700"> Mise en relation, suivi</span> du chiffrage et <span class="font-weight-700">relance</span> </span>
      </div>
      </div>
     </div>
      <div class="boder-bottom-silver padding-top-bottom">
        <div class="flex" style="justify-content: space-between;">
          <p class="title-consult padding-left-24 margin-0">Tous les lots </p>
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="view" (click)="changeView()">
            <span class="checkmark"></span>
          </label>
          
        </div>
        <div class="scroller-1-y" style="height:280px;">
          <div  *ngFor="let lot of dpgf?.data,let i = index;" class="cursor padding-left-24" >
            <div class="flex" [ngStyle]="{'padding-top': i==0 ?'10px':'4px'}" (click)="addLotToSend(lot)" [ngClass]="{'selectedLot': idsSelected.includes(lot.id)}">
              <img id="lot-icon" *ngIf="lot.icon" class="img-wid-height-24" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" alt="" height="15">
              <div *ngIf="!lot.icon" class="padding-4">
                <i id="lot-icon-arrow" class="fa fa-arrows" aria-hidden="true"></i>
              </div>
              <span class="padding-left-16" [ngStyle]="{'padding-top': lot.title? '0px':'4px','padding-left': lot.title ? '16px': '20px' }" >{{lot.title || 'Faut nommer votre lot'}}</span>
            </div>
          </div>

        </div>
      </div>
      <div class="padding-top-20 flex justify-center padding-bottom-20">
        <button class="send-chiffrage-button" [ngClass]="{'disabled':sendChiffrageButton}" [disabled]="sendChiffrageButton" (click)="goToPage('send-chiffrage')" >Envoyer ma demande de chiffrage</button>
      </div>
      </div>
      <div  *ngIf="AgloOrCarnet=='carnet'">
        <div class="padding-left-24 padding-top-12 padding-bottom-12 padding-right-24 color-blue-river border-bottom-silver">Choisissez une entreprise, assignez-lui les lots à chiffrer et validez votre sélection.</div>
        <input type="search" class="border-1px border-radius-8 margin-top-24 margin-right-24 margin-bottom-8 margin-left-24 wid424" [(ngModel)]="artisanSearchField" (keyup)="searchEnterprises()" >
        <div class="border-radius-8 border margin-right-left-24 border margin-bottom-24">
        <p-multiSelect
            id="carnet"
            placeholder="Filtrer par corps d'état"
            [panelStyle]="{minWidth:'100%'}"
            [options]="allTags"
            [(ngModel)]="selectedSearchTags"
            optionLabel="name"
            (onChange)="tagSelected()"
           ></p-multiSelect>
        </div>
        <div  class="height-280 scroller-1-y" >
        <div *ngFor="let relation of relationsList" class="row single-carnet flex cursor" (click)="chooseEntreprise(relation)" >
          <div class="padding-12">
            <img class="img-container" [src]="relation.artisanOwned?.logo != undefined ? relation.artisanOwned.logo.cdnUrl : 'assets/new-icone-casque.svg'">
          </div>
          <div class="text-container padding-top-7">
            <span class="user-name font-size-12" >{{relation.artisanOwned.name}}({{relation.artisanOwned.emailAgency}})</span>
            <div class="flex flex-wrap">
              <div *ngFor="let tag of relation?.tags" class="background-color-blueciel padding-bottom-5 padding-top-5 padding-left-8 padding-right-8 margin-bottom-5">
                <span class="font-size-10">{{(tag?.name||'_')  }}</span></div>
            </div>
          </div>
        </div>
      </div>
        <div class="padding-top-20-bottom-13 border-top-1px">
          <span class="font-size-14 padding-left-44 padding-right-10 flex cursor" (click)="goToPage('add-entreprise')" ><img src="assets/icone-plus.svg"> Ajouter une entreprise à mon carnet d’adresse</span>
        </div>
      </div>
    </div>
    <div class="boder-bottom-silver padding-top-bottom" *ngIf="pageName=='send-lot-to-artisan'">
      <div class="flex" style="justify-content: space-between;">
        <p class="title-consult padding-left-24 margin-0">Tous les lots </p>
          <!-- <label class="switch" style="padding-right:26px;">
            <input type="checkbox" [(ngModel)]="view" (click)="changeView()" >
            <span></span>
          </label> -->
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="view" (click)="changeView()">
            <span class="checkmark"></span>
          </label>
          

      </div>
      <div  *ngFor="let lot of dpgf?.data,let i = index;" class="cursor padding-left-24">
        <div class="flex"  [ngStyle]="{'padding-top': i==0 ?'10px':'4px'}" (click)="addLotToSend(lot)" [ngClass]="{'selectedLot': idsSelected.includes(lot.id)}">
          <img id="lot-icon" *ngIf="lot.icon" class="img-wid-height-24" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" alt="" height="15">
          <div *ngIf="!lot.icon" class="padding-4">
            <i id="lot-icon-arrow" class="fa fa-arrows handle grab-it" aria-hidden="true"></i>
          </div>

          <span class="padding-left-16">{{lot.title}}</span>
        </div>
      </div>
      <div class="padding-top-20 flex justify-center padding-bottom-20">
        <button class="back-button padding-right-40 color-blue-river" (click)="goToPage('first')"><img class="width-14" src="assets/chevron-left-blue.svg"> Retour</button>
        <button class="send-chiffrage-button" [ngClass]="{'disabled':sendChiffrageButton}" [disabled]="sendChiffrageButton" (click)="addEntreprise()">
          <p *ngIf="!addEntrepriseLoading" class="no-margin">Ajouter l’entreprise</p>
          <app-spinner *ngIf="addEntrepriseLoading"></app-spinner></button>
      </div>
    </div>
    <div *ngIf="pageName=='add-entreprise'" class="flex justify-center">
      <form [formGroup]="artisanForm" class="new-artisan-form" (ngSubmit)="createNewArtisan()"> 
      <p class="padding-top-24">Nom de l’entreprise *</p>
      <input type="email" name="name" class="form-control add-entreprise-input" placeholder="Nom d'entreprise" formControlName="name">
      <span class="text-danger" *ngIf="artisanForm.get('name').touched && !artisanForm.get('name').valid">Nom est obligatoire</span>
      <p class="padding-top-12">Prénom du contact</p>
      <input type="email" name="name" class="form-control add-entreprise-input" placeholder="Prénom" formControlName="firstName">
      <span class="text-danger" *ngIf="artisanForm.get('firstName').touched && !artisanForm.get('firstName').valid">Prénom est  obligatoire</span>
      <p class="padding-top-12">Nom du contact</p>
      <input type="email" name="name" class="form-control add-entreprise-input" placeholder="Nom" formControlName="lastName">
      <span class="text-danger" *ngIf="artisanForm.get('lastName').touched && !artisanForm.get('lastName').valid">Le nom obligatoire</span>
      <p class="padding-top-12">Email *</p>
      <input type="email" name="email" class="form-control add-entreprise-input" placeholder="adresse@email.com" formControlName="emailAgency">
      <span class="text-danger" *ngIf="artisanForm.get('emailAgency').touched && !artisanForm.get('emailAgency').valid">Email obligatoire</span>
      <p class="padding-top-12">Numéro de téléphone</p>
      <input type="email" name="name" class="form-control add-entreprise-input" placeholder="Numéro du telephone" formControlName="telAgency">
      <span class="text-danger" *ngIf="artisanForm.get('telAgency').touched && !artisanForm.get('telAgency').valid">Le téléphone est obligatoire</span>
      <p class="padding-top-12">Tag</p>
      <div class="border margin-bottom-47 border-radius-8 max-wid446">
      <p-multiSelect
            id="carnet"
            placeholder="Filtrer par corps d'état"
            [panelStyle]="{minWidth:'100%'}"
            [options]="allTags"
            formControlName="tags"
            optionLabel="name"
            (onChange)="tagSelected()"></p-multiSelect>
      </div>
      <div class="padding-top-20 flex justify-center padding-bottom-20 ">
        <button class="back-button padding-right-40 color-blue-river" (click)="goToPage('first')"><img class="width-14" src="assets/chevron-left-blue.svg"> Retour</button>
        <button class="send-chiffrage-button" [ngClass]="{'disabled':!artisanForm.valid}" [disabled]="!artisanForm.valid">
          <p *ngIf="!addNewArtisanLoading" class="no-margin">Ajouter l’entreprise à mon carnet d’adresses</p>
          <app-spinner *ngIf="addNewArtisanLoading"></app-spinner>
        </button>           
      </div>
    </form>
    </div>
    <div *ngIf="pageName=='send-chiffrage'" class="flex justify-center">
      <form [formGroup]="budgetForm" class="new-artisan-form" (ngSubmit)="sendRequest()">
      <p class="padding-top-46">Quel est le budget du projet ?*</p>
      <input name="name" class="form-control add-entreprise-input" type="number" placeholder="100 000€" formControlName="budget" >
      <p>Quelle est la date de début des travaux</p>
      <input type="date" name="dateTravaux" class="form-control add-entreprise-input wid419 height-14" placeholder="XX-XX-XXXX" formControlName="dateTravaux" value="26-04-2023">
      <p>Avez-vous des précisions à apporter au projet </p>
      <textarea name="name" class="form-control add-entreprise-input height-110" placeholder="Précisions" formControlName="messageBody"></textarea>
      <div class="padding-top-20 flex justify-center padding-bottom-20 padding-top-156">
        <button class="back-button padding-right-40 color-blue-river" (click)="goToPage('first')"><img class="width-14" src="assets/chevron-left-blue.svg"> Retour</button>
        <button class="send-chiffrage-button" [ngClass]="{'disabled':!budgetForm.valid}" [disabled]="!budgetForm.valid">
          <p *ngIf="!buttonSendRequest" class="no-margin">Envoyer ma demande de chiffrage</p>
          <app-spinner *ngIf="buttonSendRequest"></app-spinner>
        </button>
      </div>
    </form>
    </div>
  </div>
  </div>
</div>