<div class="container-fluid">
  <div class="col-md-5 full-height colored-side">
    <div>
      <img class="aglo-logo" src="/assets/rob/aglo_logo_round_blanc.png" alt="">
      <h2 class="slogan">
          L’assistant intelligent <br>
          pour votre consultation d’entreprise
      </h2>
    </div>
  </div>
  <div class="col-md-7 full-height">

    <div class="connect">
      <h1>Mot de passe oublié</h1><br>
      <!-- <h2>Connectez-vous pour continuer</h2> -->
      <h3>Veuillez entrer votre adresse e-mail pour recevoir un lien de réactivation</h3>
      <div *ngIf="!sent" class="row">
        <div class="col-md-6 col-md-offset-3 forget-form">
          <form [formGroup]="forgetForm" novalidate>
            <div class="input-group input-group-lg">
              <input formControlName="email"  type="text" class="form-control" placeholder="Email">
            </div>
            <small *ngIf="formErrors.email" class="text-danger">
              {{ formErrors.email }}
            </small>
          </form>
          <button [disabled]="loading" (click)="forgetPassword()" class="btn-primary-color">
            <div *ngIf="!loading">Envoyer le mail</div>
            <app-spinner *ngIf="loading"></app-spinner>
          </button>
        </div>
      </div>
      <div *ngIf="sent"class="row">
        <div class="col-md-6 col-md-offset-3 forget-form">
          <img id="email-sent" class="hide" src="assets/email-sent.png" width=200 alt="">
          <br><br>
          <h3 class="center gray-color">Vérifiez votre email! Vous avez sûrement reçu un petit lien</h3>
        </div>
      </div>
    </div>

  </div>

</div>
