<div class="row">
  <div class="col-md-12" *ngFor="let file of files ; let i = index; let isLast=last; let isFirst = first ">
    <br><br>
    <a href="{{file.cdnUrl}}" target="_blank">
      <img *ngIf="isImage" src="{{file.cdnUrl}}" alt="" class="thumb-nail-project">
      <span *ngIf="!isImage">{{file.name}}</span>
    </a><br><br>

    <div class="float-rigth">
       <button *ngIf="!isLast && order" (click)="getDown(i)" type="button" class="half btn">
        <i class="fa color-red fa-arrow-down" aria-hidden="true"></i>
      </button>
      <button *ngIf="!isFirst && order" (click)="getUp(i)" type="button" class="half btn">
        <i class="fa color-green fa-arrow-up" aria-hidden="true"></i>
      </button>
        <button type="button" class="half btn drop-btn dropdown-toggle"
            mwlConfirmationPopover
            [popoverTitle]="'Supprimer'"
            [popoverMessage]="'Vous êtes sûr?'"
            [cancelText]="'Non'"
            [confirmText]="'Oui' "
            placement="left"
            (confirm)="deleteImages(i)"
            (cancel)="true">
        <i class="fa color-black fa-trash" aria-hidden="true"></i>
      </button>
    </div>
    <hr>
  </div>
</div>
<br>
<!-- <app-ligth-box [images]="(files||[])" #ligthboxImages></app-ligth-box> -->
