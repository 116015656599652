<div class="columns-wrapper flex">

  <!-- Single Column Container -->
  <div class="single-column-container" *ngFor="let column of columnsList">
    <!-- Title -->
    <div class="column-title flex space-between">
      <div class="left-side flex">
        <p class="title">{{ column.label }}</p>
        <span class="count-badge pointer" [ngClass]="column.value">{{ allArtisans[column.dataSource].length }}</span>
      </div>

      <div class="right-side flex" *ngIf="column.icon">
        <span class="remise_medal_counter" 
          *ngIf="column.value == 'offre_remise'"
            (mouseover)="showTooltip = true"
            (mouseout)="showTooltip = false"
          >{{ numberOfSignedAgencies }}</span>
        <div class="img-container relative">
          <img [src]="column.icon" class="medal-img pointer" 
            (click)="onColumnIconClicked(column.value)"
            (mouseover)="showTooltip = true"
            (mouseout)="showTooltip = false"
            >
          <span class="medal-tooltip" [ngClass]="showTooltip && 'shown' " *ngIf="column.value == 'offre_remise' ">Nombre de marchés signés</span>
        </div>
      </div>
    </div>
    <!-- END Title -->
    
    <!-- List Wrapper -->
    <div class="column-list-wrapper scroller-2-y">
      <!-- Single Card -->
      <div class="single-column-card flex center-items ag-card-1 pointer" 
        *ngFor="let invite of allArtisans[column.dataSource]"
        [class]="column.value"
        [ngClass]="{'large-name-padding': invite.artisan.name.length > 21 }"
        (click)="emitOpenArtisanModal(invite, column.value)"
        >
        <div class="full-width">

          <!-- Refused or Accepted Icons -->
          <div class="refused-or-accepted-icons">
            <img src="/assets/enterprises-icons/chiffrage-accepted.svg" *ngIf="invite.status == 'selectionne'">
            <img src="/assets/enterprises-icons/chiffrage-refused.svg" *ngIf="invite.status == 'refuse'">
          </div>
          <!-- END Refused or Accepted -->

          <!-- Upper Section -->
          <div class="upper-section flex space-between">
            <!-- Left side -->
            <div class="right-side flex">
              <div class="img-container">
                <!-- <img src="/assets/worker.png" alt=""> -->
                <img [src]="invite.artisan.logo?.cdnUrl || '/assets/worker.png'" alt="">
                
              </div>
              <p>
                <span *ngIf="invite.isFactice">{{ invite?.facticeArtisanInfo?.name || '' }}</span>
                <span *ngIf="!invite.isFactice">{{ invite.artisan.name }}</span>
              </p>
            </div>
            <!-- END Left side -->

            <!-- Right side -->
            <div class="left-side">
              <span class="indice-badge flex-middle"
                [ngClass]="{'old-version': (invite.dpgfPricing||{}).currentVersion != (dpgf||{}).currentVersion }" 
                *ngIf="invite.dpgfPricing">
                {{ invite.dpgfPricing.currentVersion | indice }}
              </span>
            </div>
            <!-- END Right side -->
          </div>
          <!-- END Upper Section -->

          <!-- Bottom Section -->
          <div class="bottom-section flex space-between">
            <div class="left-side flex padding-left-5">
              <div class="flex">
                <img src="/assets/enterprises-icons/medal-icon.svg" class="margin-right-5" *ngIf="invite?.status == 'signe'">
                <span class="price-badge flex" *ngIf="invite.pricingStatus == 'pricing-done'">
                  {{ invite.dpgfPricing.totalHt | formatPrice }} 
                  <i class="margin-left-4 ag-normal-font-style">€</i>
                </span>
              </div>
              
              <span class="status-badge" *ngIf="column.value == 'en_attente' ">DCE transmis</span>
              <span class="status-badge" *ngIf="column.value == 'en_cours' ">Remise prévue</span>
              <span class="status-badge" *ngIf="column.value == 'ne_chiffre_pas' ">Projet refusé</span>

            </div>

            <div class="right-side">
              <p class="date-value" *ngIf="column.value == 'en_attente' && invite?.dateDceSent">
                {{ invite.dateDceSent | date: 'dd/MM/yyyy' }}
              </p>
              <p class="date-value" *ngIf="column.value == 'en_cours' && invite?.deliveryDate">
                {{ invite?.deliveryDate | date: 'dd/MM/yyyy' }}
              </p>
              <p class="date-value" *ngIf="column.value == 'offre_remise' && invite?.sentPricingDate">
                {{ invite?.sentPricingDate | date: 'dd/MM/yyyy' }}
              </p>
              <p class="date-value" *ngIf="column.value == 'ne_chiffre_pas' && invite?.refusalDate">
                {{ invite?.refusalDate | date: 'dd/MM/yyyy' }}
              </p>
            </div>

          </div>
          <!-- END Bottom Section -->

        </div>
        
      </div>
      <!-- END Single Card -->

    </div>
    <!-- END List Wrapper -->

  </div>
  <!-- END Single Column Container -->

</div>
