import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";
import { Helpers } from './helpers'

@Injectable()
export class PaymentsService {

    public token: string;
    public licenceNotification: boolean = true;
    constructor(private http: HttpClient) {
    }
    choosePlanArchitect(body): Observable<any> {
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/payments/set-subscription-architect`, body ,options)
            .pipe(map((response: any) => {
                return response;
            }));
    }
    choosePlanUser(body): Observable<any> {
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/payments/set-subscription-user`, body ,options)
            .pipe(map((response: any) => {
                return response;
            }));
    }
    createUser (): Observable<any> {
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/payments/create-user`, {} ,options)
            .pipe(map((response: any) => {
                return response;
            }));
    }
    resetPayement (typePayment): Observable<any> {
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/payments/reset-payement`, typePayment ,options)
            .pipe(map((response: any) => {
                return response;
            }));
    }
    cancelPayement (): Observable<any> {
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/payments/cancel-payement`, {} ,options)
            .pipe(map((response: any) => {
                return response;
            }));
    }
    /// get customer invoices
    getcustomerInvoices(): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/payments/get-customer-invoices`, options)
        .pipe(map((response: any) => {
          return response;
      }));
    }
    // get customer subscriptions
    getcustomerSubscriptions(): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/payments/get-customer-subscriptions`, options)
        .pipe(map((response: any) => {
          return response.data;
      }));
    }
    getCoupon(coupon): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/payments/get-coupon?coupon=${coupon}`, options)
        .pipe(map((response: any) => {
          return response.data;
      }));
    }
    generateSessionPortal(): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/payments/generate-session-portal`, options)
        .pipe(map((response: any) => {
          return response.data;
      }));
    }
    
    getTaxesRate(): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/payments/get-taxes-rates`, options)
        .pipe(map((response: any) => {
          return response.data;
      }));
    }
}
