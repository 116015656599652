import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { CommonModule } from '@angular/common';


@Pipe({
  standalone:true,
  name: 'highlightWords'
})
export class HighLightWordsPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer){}

    transform(value: any, args: any): any {
      if(!args){
        return value;
      }
      let words =args.split(' ');
      words.forEach((el) => {
        if(el.length > 1){
          var pattern = new RegExp(el, 'gi');
          value =value.replace(pattern, "<mark>"+el+"</mark>");
        }

      });
      return value;
    }



}
