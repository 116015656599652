import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AuthService } from '../../../_services/index';
import { MessageService } from 'primeng/api';
import initHelpHero from 'helphero';
import { NgSharedModule } from 'src/app/all/ngshared.module';

//Service
import { ErrorService } from '../../../_services/index';
import { CeProjectService, UserService } from '../../../_services/index';
import { ArchitectService } from '../../../_services/index';
import { IdentifySegement } from 'src/app/all/segment/identify';

// Components
import { HeaderComponent } from 'src/app/all/header/header.component';
import { ArchiExpiredModalComponent } from 'src/app/all/archi-expired-modal/archi-expired-modal.component';
import { CeProjectListComponent } from 'src/app/dpgf/global/ce-project-list/ce-project-list.component';

import { ProjectsSearchbarComponent } from 'src/app/architect/projects/projects-searchbar/projects-searchbar.component';
import { ProjectsCardsListComponent } from 'src/app/architect/projects/projects-cards-list/projects-cards-list.component';
// design system components
import { UserOnWithoutLicenseModalComponent } from 'src/app/_design_system_components/user-on-without-license-modal/user-on-without-license-modal.component';


// Others
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-architect-my-consultation',
  imports: [
    HeaderComponent,
    ProjectsSearchbarComponent,
    CeProjectListComponent,
    UserOnWithoutLicenseModalComponent,
    ArchiExpiredModalComponent,
    ProjectsCardsListComponent,
    NgSharedModule
  ],
  templateUrl: './architect-my-consultation.component.html',
  styleUrls: ['./architect-my-consultation.component.scss'],
  standalone: true,

})
export class ArchitectMyConsultationComponent implements OnInit {

  public loading: boolean = true;
  private FULL_PROJECTS: any = []; // this keeps all projects
  public projects: any = []; // this might changed depending on the searched, or filterd project
  public filtredProjects: any = []; // when you search in projects, & want to filter them (or vice versa)
  private currentFilter:string = "all";

  public currentlySelectedProjectId: string; // set this value, when we want to delete, duplicate, archiver
  
  // User
  public isAdmin: boolean = false; 
  public hasDpgf: boolean = false; 
  public currentUser;
  public license: string;
  public authUserFavoritedProjects: any = [];

  // revit
  public isRevitDesktop:boolean;

  // minarm
  public onPermis: boolean = false;

  // modals
  public confirmModalTitle :string = "Supprimer le projet ?";
  public confirmModalDescription :string = "Le projet sera supprimé et ne pourra pas être récupéré.";
  public confirmModalAcceptLabel :string = "Supprimer";

  
  private subscriptions: Array<Subscription> = []; 

  constructor(
    private title: Title,
    private router: Router,
    private ceProjectService: CeProjectService,
    private architectService: ArchitectService,
    private userService: UserService,
    public authService: AuthService,
    public messageService: MessageService,
    private ErrorService: ErrorService,
    private identifySegment: IdentifySegement) {
      this.title.setTitle('Aglo - Mes Consultations');
  }

  ngOnInit() {
    // revit
    this.isRevitDesktop = navigator.userAgent.includes("Autodesk.Revit/2024");

    if(!environment.onPremise)window.analytics.page('pageview_main_page');
    // minarm
    this.onPermis = environment.onPremise;

    // auth user
    this.currentUser = this.authService.getAuthUser(); // from loadStorage (does not have all data)
    this.getUserLicence();

    this.loadAuthUser(); // from db

    this.getArchiProjects();
  }

  //////////////////////////////////////////////////////////////
  /// UI
  //////////////////////////////////////////////////////////////
  public openConfirmRemoveProjectModal(projectId){
    this.currentlySelectedProjectId = projectId;
    $("#confirm-remove-project-modal").modal('show');
  }
  public openNeedLicenseModal(){
    $("#user-on-without-license-modal").modal('show');
  }
  private removeProjectFromList(projectId){
    this.FULL_PROJECTS = this.FULL_PROJECTS.filter(ceProject => ceProject["_id"] != projectId );
    this.projects = this.FULL_PROJECTS;
  }
  private addNewProjectToList(project){
    this.FULL_PROJECTS.unshift(project);
    this.projects = this.FULL_PROJECTS;
  }

  //////////////////////////////////////////////////////////////
  /// Functionality
  //////////////////////////////////////////////////////////////
  public getUserLicence(){
    this.license = this.authService.licence;
    // is admin
    this.isAdmin = this.currentUser.isAdmin;
  }
  public duplicate(projectId){
    this.duplicateProject(projectId);
  }
  public toggleFavorite(body){
    this.authUserFavoritedProjects = body["favoritedProjects"];

    this.updateAuthUser(body);
  }
  public archiveProject(projectBody){
    const { projectId, archiStatus } = projectBody;

    let body = { archiStatus };

    this.updateProject(projectId, body);
  }
  public removeProject(){
    let body = {status: 'deleted'};
    // update project
    this.updateProject(this.currentlySelectedProjectId, body);
    if(!environment.onPremise)window.analytics.track('delete_project',{properties:{acValue: 'delete_project'}});

    // remove project from UI
    this.removeProjectFromList(this.currentlySelectedProjectId);
    // set initial value
    this.currentlySelectedProjectId = "";
  }
  public searchProjects(searchQuery){

    // is searchQuery is an empty project, get all
    const showAllProjects = !Object.keys(searchQuery).length ? true : false;

    // show all projects, no need to search
    if(showAllProjects){
      this.projects = this.FULL_PROJECTS;
      this.filtredProjects = [];
      if(this.currentFilter != "all"){
        this.filterProjects(this.currentFilter);
      }
      return;
    }

    this.filtredProjects = [];
    
    for(let i = 0; i < this.FULL_PROJECTS.length; i++){
      let currentProject = this.FULL_PROJECTS[i];

      let currentProjectMatchTheSearchQuery = this.matchTheProjectQuery(currentProject, searchQuery);

      if(currentProjectMatchTheSearchQuery){
        this.filtredProjects.push(currentProject);
      }

    }
    
    this.projects = this.filtredProjects;
    
    // if filter is selected, filter the new searched projects
    if(this.currentFilter != "all"){
      this.filterProjects(this.currentFilter);
    }

  }
  private matchTheProjectQuery(project, searchQuery){

    let numberOfSeachQueries = Object.keys(searchQuery).length; // how many elements to search by (text, nature, programme) (the ones selected)
    let currentProjectPassedSearchQueries = 0;// how many elements the current project has passed (if it's the same number as above) then show it
    
    const { title, addressCity, addressCodePostal, addressCountry, adresse } = project;
    const textQuerySearchFields = [title||"", addressCity||"", addressCodePostal||"", addressCountry||"", adresse||""];

    for(let key in searchQuery){
      /// Search String
      // if text is filled 
      if(key == "textQuery"){
        let textQuery = searchQuery[key].toLowerCase();

        // if one of the items, includes the same textQuery, then it should be
        let queryMatchOneOfTheElements = textQuerySearchFields.filter(item => item.toLowerCase().includes(textQuery));
        
        if(queryMatchOneOfTheElements.length){
          currentProjectPassedSearchQueries++;
        }
        
      }else if(key == "status"){
        let projectStatusMatch = this.verifyProjectByStatus(project, searchQuery[key]);
        
        if(projectStatusMatch){
          currentProjectPassedSearchQueries++;
        }
      }

      // Search Array
      if(key in project){
        
        // this might be : natureTravaux or type (these are strings)
        let projectValue = project[key];
        let searchByValues = searchQuery[key];

        if(searchByValues.includes(projectValue)){
          currentProjectPassedSearchQueries++;
        }
      }

    }

    return currentProjectPassedSearchQueries >= numberOfSeachQueries ? true : false;
  }

  private verifyProjectByStatus(project, statusArray){
    let projectMatchTheCriteria = false;

    if(statusArray.includes("en_cours")){
      
      if((project.status != "deleted" && project.archiStatus != "archive") || project.demo){
        projectMatchTheCriteria = true;
      }

    }else if(statusArray.includes("archived")){
      if(project.archiStatus == "archive"){
        projectMatchTheCriteria = true;
      }
    }

    return projectMatchTheCriteria;
  }

  public filterProjects(filter){
    this.currentFilter = filter;    

    let projectsSource = this.filtredProjects.length ? [...this.filtredProjects] : [...this.FULL_PROJECTS];    

    if(filter == "all"){
      this.projects = projectsSource;
    }else if(filter == "favorite"){
      this.projects = projectsSource.filter(project => this.authUserFavoritedProjects.includes(project._id));
    }else if(filter == "my_projects"){
      const authUserId = this.currentUser["id"];
      this.projects = projectsSource.filter(project => project?.user?._id == authUserId);
    }else if(filter == "shared_with_me"){
      const authUserId = this.currentUser["id"];
      // find projects where collaborateurs _id equals auth id
      this.projects = projectsSource.filter(project => project.collaborateurs.find(collab => collab._id == authUserId));
    }else if(filter == "projects_esid"){
      const authArchitectId = this.currentUser["archiId"];
      // find projects where architect _id equals auth architect
      this.projects = projectsSource.filter(project => project.architect._id == authArchitectId);
    }
    
  }
  public sortProjects(body){
    const { sortBy, direction } = body;
    
    // if sort by title (don't send http request, sort locally)
    if(sortBy == "title"){
      
      // -1 from new to old
      // 1 from old to new
      // sort projects by title
      const sortAscending = (a, b) => a.title.localeCompare(b.title); // from a to x
      const sortDescending = (a, b) => b.title.localeCompare(a.title); // from x to a

      const orderFunction = direction == -1 ? sortDescending : sortAscending;
    
      this.projects.sort((a, b) => orderFunction(a,b));  
    }else{
      this.getArchiProjects(sortBy, direction);
    }

  }

  public openProject(projet){
    if(this.isRevitDesktop){
      this.router.navigate(['/architect','dpgf',projet.dpgf]);
    }
    else{
      this.router.navigate(['/architect','ce-project',projet._id]);
    }
  }
  public goToNewProjectPage(){
    if(this.license == "without"){
      $("#user-on-without-license-modal").modal('show');
      return;
    }

    this.router.navigate(['architect/new-ce-project']);
  }

  private getEncoursProjects(projectsList){
    this.projects = projectsList.filter(project => ( (project.status != "deleted" && project.archiStatus != "archive") || project.demo ) );    
  }

  //////////////////////////////////////////////////////////////
  /// Http Requests
  //////////////////////////////////////////////////////////////
  public getArchiProjects(sortBy='createdAt', direction='-1') {
    this.loading = true;
    this.subscriptions.push(
      this.ceProjectService.getAllProject(sortBy, direction)
        .subscribe(
          data => {
            this.FULL_PROJECTS = data;
            this.getEncoursProjects(data); // show only "en_cours" & demo projects
            this.loading = false;
            // segment
            this.identifySegment.identifyNumProjects(data.length);
          },
          error => {
            console.log("error", error)
            this.ErrorService.manageError(error, '/architect/my-projects');
          })
    );
  }
  public updateProject(projectId, body){
    this.loading = true;
    this.subscriptions.push(
      this.ceProjectService.update(projectId, body)
      .subscribe(
        data => {
          this.loading = false;
        },
        error => {
          console.log("error", error)
          this.ErrorService.manageError(error, '/architect/my-projects');
        })
    );
  }
  public duplicateProject(projectId){
    this.loading = true;
    this.subscriptions.push(
      this.ceProjectService.duplicate(projectId)
      .subscribe(
        data => {
          // add newly duplicated project to UI
          if(!environment.onPremise)window.analytics.track('duplicate_project',{properties:{acValue: 'duplicate_project'}});
          this.addNewProjectToList(data);
          this.loading = false;
        },
        error => {
          console.log("error", error)
          this.ErrorService.manageError(error, '/architect/my-projects');
        })
    );
  }
  public loadAuthUser(){
    const userId = this.currentUser["id"];

    // this.loading = true;
    this.subscriptions.push(
      this.authService.getUser(userId).subscribe({
        next: (data) => {
          this.authUserFavoritedProjects = data.favoritedProjects || [];
        },
        error: (err) => {
          console.log(err);
        },
      })
    );
  }
  public updateAuthUser(body){
    const userId = this.currentUser["id"];

    this.loading = true;
    this.subscriptions.push(
      this.authService.updateUser(userId, body).subscribe({
        next: (data) => {
          this.loading = false;
        },
        error: (err) => {
          console.log(err);
        },
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
