import { environment } from "src/environments/environment";

declare var amplitude: any;

export class userActionLogger {
    logEvent(event) {
      if(!environment.onPremise){
      let isAdmin = localStorage.getItem('isAdminBam')
      if(amplitude.getInstance() && isAdmin!="true"){
        try{
          amplitude.getInstance().logEvent(event)
        }catch(error){
          console.log(error)
        }
      }
      }
    }
    setUserId(id){
      if(!environment.onPremise){
      let isAdmin = localStorage.getItem('isAdminBam')
      if(amplitude.getInstance() && isAdmin!="true"){
        try{
          amplitude.getInstance().setUserId(id)
        }catch(error){
          console.log(error)
        }
      }
    }
    }
}
