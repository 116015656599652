<!-- Header -->
<app-header></app-header>
<!-- END Header -->

<!-- Side Navbar -->
<app-ce-project-nav 
  [disable]="loadingData$ | async" 
  [projectId]="(dpgf$|async)?.ceProject" 
  selected="dpgf"
  [devisNumber]="0"
  [DPGFId]="(dpgf$|async)?._id"
></app-ce-project-nav>
<!-- END Side Navbar -->


<div class="main-dce-page-wrapper" *ngIf="!(loadingData$ | async)">
  
  <!-- Top Navbar -->
  <app-dce-top-nav-bar
    [currentView]="currentView"
    [saving]="(saving$ | async)"
    [changes]="(changes$ | async)"
    [checkedPostsNumber]="checkedPostsNumber"
    [exportingDocs]="(exportDoc$ | async)"
    [projectTitle]="projectData.title"
    [isAutomateNumberIsChecked]="isAutomateNumberIsChecked"
    [dpgfCurrentVersion]="(dpgf$|async)?.currentVersion"
    [defaultTva]="(dpgf$|async)?.defaultTva"
    [currentView]="currentView"
    (changeView)="changeView($event)"
    (openIndiceModal)="openIndiceModal()"
    (saveData)="saveDpgfModification()"
    (changeExportDocsView)="changeExportDocsView($event)"
    (exportDocs)="exportDocs()"
    (setDpgfAttribute)="setDPGFAttribut($event)"
    (updateProject)="updateProject($event)"
    (projectOverlayPanelClosed)="projectOverlayPanelClosed($event)"
    (duplicateManyPosts)="duplicateManyPosts()"
    (deleteManyPosts)="deleteManyPosts()"
    (sendDbPosts)="sendDbPosts()"
    (movePoste)="movePoste()"
    (flagPosts)="flagPosts()"
  ></app-dce-top-nav-bar>
  <!-- END Top Navbar -->

  <!-- Views Wrapper -->
  <div class="views-wrapper" [ngClass]="{ 'show-full-table': (currentView != 'redaction' || showTableDpgf ), 'sidebar-opened': consulteSidebarOpened }">
    
    <!-- Lots List View -->
    <div class="lots-list-view-container relative">
      <app-lots-list-view *ngIf="!showTableDpgf && currentView == 'redaction'"
        [dpgf]="lotsListViewData"
        [nbLines]="(nbLines$|async)"
        [totalHt]="(total$ | async)"
        [totalTtc]="(totalTTC$ | async)"
        (openConfirmRemoveLotModal)="openConfirmRemoveLotModal($event)"
        (duplicateLot)="duplicateLot($event)"
        (openImportLotModal)="openImportLotModal()"
        (updateLotStatus)="updateLotStatus($event)"
        (addLot)="addLot()"
        (openLot)="openLot($event)"
      ></app-lots-list-view>
    </div>
    <!-- END Lots List View -->

    <!-- Dpgf Lots Table -->
    <div class="table-dpgf-container">
      <app-table-dpgf *ngIf="showTableDpgf && currentView == 'redaction'"
        mode="archi" 
        [isAutomateNumberIsChecked]="isAutomateNumberIsChecked" 
        [dpgf]="(dpgf$|async)" 
        [openSpecificLot]="openSpecificLot"
        [triggerAddNewLot]="triggerAddNewLot"
        (lotAdded)="updateTrigger()"
        (showReductionView)="hideTableDpgf()"
      ></app-table-dpgf>
    </div>
    <!-- END Dpgf Lots Table -->

    <!-- Dpgf Documents View -->
    <div class="dpgf-folders-container" *ngIf="currentView == 'documents'">
      <app-dpgf-folders
        [mode]="mode"
      ></app-dpgf-folders>
    </div>
    <!-- END Dpgf Documents View -->

    <!-- Export Documents View -->
    <div *ngIf="currentView == 'exportDocs'">
      
      <!-- *ngIf="exportView=='perso'" -->
      <app-archi-edit-document 
        [ngClass]="{'hidden' : exportView!='perso'}"
        (sendDpgfEvent)="setDpgfChange($event)"
      ></app-archi-edit-document>

      <!-- [ngClass]="{'visibility': (exportView=='perso')?'hidden':'visible' }"  -->
      <!-- *ngIf="exportView=='export'" -->
      <app-dpfg-docs-popup 
        [ngClass]="{'hidden' : exportView!='export'}"
        [callExport]='callExport'
        [dpgf]="(dpgf$|async)"
        [licence]="licence"
        [isAutomateNumberIsChecked]="isAutomateNumberIsChecked"
        (sendDpgfEvent)="setDpgfChange($event)"
        (saveDPGFEvent)='saveDocModification()' 
        (returnToDCETab)="changeView('redaction')"
        (exportEvent)='changeExportButton($event)'
      ></app-dpfg-docs-popup>

    </div>
    <!-- END Export Documents View -->

    <!-- Right Sidebar Here -->
    <div class="right-sidebar-container">
      <app-right-bar-consult-add-entreprise
        [consulteSidebarOpened]="consulteSidebarOpened"
        (toggleSidebar)="toggleSidebar()"
        (openConsulteBoosterModal)="openConsulteBoosterModal()"
      ></app-right-bar-consult-add-entreprise>
    </div>
    <!-- END Right Sidebar Here -->

  </div>
  <!-- END Views Wrapper -->

</div>


<!-- Modals 
  ============================================ -->

  <!-- Need Subscription Modal -->
  <app-user-on-without-license-modal
  ></app-user-on-without-license-modal>
  <!-- END Need Subscription Modal -->

  <!-- Confirm Remove Lot -->
  <app-confirm-remove-lot-modal
    [lotTitle]="selectedLotTitleToRemove"
    (confirmRemoveLot)="confirmRemoveLot()"
  ></app-confirm-remove-lot-modal>
  <!-- END Confirm Remove Lot -->

  <!-- Dpgf Versions -->
  <app-dpgf-versions-modal *ngIf="showDpgfVersionModal"
    [dpgfId]="dpgfId"
    [dpgfCurrentVersion]="dpgfCurrentVersion"
    (showConfirmRestoreDceVersionModal)="showConfirmRestoreDceVersionModal($event)"
    (showConfirmIndiceModal)="showConfirmIndiceModal()"
  ></app-dpgf-versions-modal>
  <!-- END Dpgf Versions -->

  <!-- Import Lots -->
  <app-dpgf-import-lot-popup *ngIf="showImportDpgfLotsModal"
    (selectedLots)="addSelectedLots($event)"
  ></app-dpgf-import-lot-popup>
  <!-- END Import Lots -->

  <!-- Confirm Restore Dce Version Modal -->
  <app-confirm-restore-dce-version-modal
    (confirmRestoreVersion)="confirmRestoreVersion()"
  ></app-confirm-restore-dce-version-modal>
  <!-- END Confirm Restore Dce Version Modal -->

  <!-- Confirm GO To Next Indice Modal -->
  <app-confirm-modal
    [modalId]="'confirm-next-indice-modal'"
    [headerTitle]="nextIndiceModalTitle"
    [bodyText]="nextIndiceModalBody"
    [confirmButtonLabel]="'Oui, créer un nouvel indice'"
    [cancelButtonLabel]="'Annuler'"
    (confirm)="confirmGoToNextIndice()"
  ></app-confirm-modal>
  <!-- END Confirm GO To Next Indice Modal -->

  <!-- Max Dpgf & Library Posts Modal -->
  <app-max-library-posts-modal 
    [showLibraryText]="maxLibraryPostsModalshowLibraryText"
  ></app-max-library-posts-modal>
  <!-- END Max Dpgf & Library Posts Modal -->

  <!-- PrimeNg Confirm Dialog -->
  <p-confirmDialog 
    acceptButtonStyleClass="oui-version" 
    acceptLabel="Créer un nouvel indice" 
    rejectLabel="Annuler" 
    header="Changement de version" 
    width="425"
  ></p-confirmDialog>
  <!-- END PrimeNg Confirm Dialog -->

  <!-- Consulte -->
  <app-modal-consult-entreprise  *ngIf="showConsulteEnterpriseModal"
    [ceProjectId]="ceProjectId" 
    [dpgf]="(dpgf$|async)"
  ></app-modal-consult-entreprise>
  <!-- END Consulte -->



<!-- END Modals 
  ============================================ -->

<!-- Prime-ng Dialogs
  ============================================ -->
  <!-- Delete posts -->
  <p-confirmDialog key="deletePosts"></p-confirmDialog>

  <!-- Duplicate posts -->
  <p-confirmDialog key="duplicatePosts"></p-confirmDialog>
<!-- END Prime-ng Dialogs
  ============================================ -->