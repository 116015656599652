<form [formGroup]="projectForm" class="margin-size" novalidate>

  <div [@error]='error=="title"' class="floating-card" id="q1" (click)="scrollTo('#q1')">
    <label id="title" class="required-star question-label">Titre du projet (Maître d'ouvrage)</label>
    <div class="row">
      <input formControlName="title" placeholder="Titre du projet" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control">
    </div>
    <small *ngIf="formErrors.title" class="text-danger">
      {{ formErrors.title }}
    </small>
  </div>

  <div class="floating-card" [@error]='error=="adresse"' id="q2" (click)="scrollTo('#q2')">
    <label class="required-star question-label" id='adresse'>Adresse du projet</label>
    <div class="row">
      <div class="col-md-6">        
        <app-google-autocomplete 
          [adresseValue]="project.adresse" 
          (adresseJsonChange)="getGoogleMapValueChange($event)" 
          (adresseValueChange)="adresseValueChange($event)"
          ></app-google-autocomplete>
        <small *ngIf="formErrors.adresse" class="text-danger no-padding-left">
          {{ formErrors.adresse }}
        </small>
      </div>

      <div class="col-md-6">
        <input placeholder="Code postal" autocorrect="off" id="addressCodePostal" autocapitalize="off" spellcheck="off" type="text" class="form-control" formControlName="addressCodePostal">
        <small *ngIf="formErrors.addressCodePostal" class="text-danger no-padding-left">
          {{ formErrors.addressCodePostal }}
        </small>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <input placeholder="Ville" autocorrect="off" id="addressCity" autocapitalize="off" spellcheck="off" type="text" class="form-control" formControlName="addressCity">
        <small *ngIf="formErrors.addressCity" class="text-danger no-padding-left">
          {{ formErrors.addressCity }}
        </small>
      </div>
      <div class="col-md-6">
        <input placeholder="Pays" autocorrect="off" id="addressCountry" autocapitalize="off" spellcheck="off" type="text" class="form-control" formControlName="addressCountry">
        <small *ngIf="formErrors.addressCountry" class="text-danger no-padding-left">
          {{ formErrors.addressCountry }}
        </small>
      </div>
    </div>

  </div>




  <div class="floating-card" [@error]='error=="natureTravaux"' id="q3" (click)="scrollTo('#q3')">
    <label id="natureTravaux" class="required-star question-label">Quel est le type de travaux à réaliser ?</label><br>
    <div class="cc-selector row">
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
          <input id="construction" type="radio" name="natureTravaux" formControlName="natureTravaux" value="construction" />
          <label class="drinkcard-cc construction" for="construction"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="extension" type="radio" name="natureTravaux" formControlName="natureTravaux" value="extension" />
        <label class="drinkcard-cc extension"for="extension"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="renovation" type="radio" name="natureTravaux" formControlName="natureTravaux" value="renovation" />
        <label class="drinkcard-cc renovation"for="renovation"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="transformation" type="radio" name="natureTravaux" formControlName="natureTravaux" value="transformation" />
        <label class="drinkcard-cc transformation" for="transformation"></label>
      </div>
    </div>
    <small *ngIf="formErrors.type" class="text-danger">
      {{ formErrors.type }}
    </small>
  </div>

  <div [@error]='error=="type"' class="floating-card" id="q4" (click)="scrollTo('#q4')">
    <label id="type" class="required-star question-label">Quel est le programme ?</label><br>
    <div class="cc-selector row">
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="maison" type="radio" name="type" formControlName="type" value="maison" />
        <label class="drinkcard-cc four maison" for="maison"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6" *ngIf="this.projectForm.value.natureTravaux != 'construction'">
        <input id="appartement" type="radio" name="type" formControlName="type" value="appartement" />
        <label class="drinkcard-cc four appartement"for="appartement"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="hotel" type="radio" name="type" formControlName="type" value="hotel" />
        <label class="drinkcard-cc four hotel"for="hotel"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="commerce" type="radio" name="type" formControlName="type" value="commerce" />
        <label class="drinkcard-cc four commerce"for="commerce"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="logement" type="radio" name="type" formControlName="type" value="logement" />
        <label class="drinkcard-cc four logement"for="logement"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="bureaux" type="radio" name="type" formControlName="type" value="bureaux" />
        <label class="drinkcard-cc four bureaux"for="bureaux"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="agricole" type="radio" name="type" formControlName="type" value="agricole" />
        <label class="drinkcard-cc four agricole"for="agricole"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="autre" type="radio" name="type" formControlName="type" value="autre" />
        <label class="drinkcard-cc four autre"for="autre"></label>
      </div>
    </div>
    <small *ngIf="formErrors.natureTravaux" class="text-danger">
      {{ formErrors.natureTravaux }}
    </small>
  </div>



  <!-- <div [@error]='error=="budget"' class="floating-card" id="q5" (click)="scrollTo('#q5')">
    <label id="budget" class="required-star question-label">Enveloppe prévisionnelle</label>
    <div class="row">
      <input formControlName="budget" placeholder="Enveloppe prévisionnelle" type = "number">
    </div>
    <small *ngIf="formErrors.budget" class="text-danger">
      {{ formErrors.budget }}
    </small>
  </div> -->

  <div [@error]='error=="surface"' class="floating-card" id="q6" (click)="scrollTo('#q6')">
    <!-- ADDED REQUIRED-STAR -->
    <label id="surface" class="question-label">Surface en m² de plancher à créer/rénover : </label>
    <div class="row">
      <input formControlName="surface" placeholder="Surface m²" type = "number">
    </div>
    <small *ngIf="formErrors.surface" class="text-danger">
      {{ formErrors.surface }}
    </small>
  </div>

  <!-- <div id="q7" [@error]='error=="dateDce"' (click)="scrollTo('#q7')" class="floating-card">
    <label id="dateDce" class="required-star question-label">Date prévisionnelle DCE</label>
    <div class="row">
      <p-calendar  formControlName="dateDce" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2040"></p-calendar>
    </div>
    <small *ngIf="formErrors.dateDce" class="text-danger">
      {{ formErrors.dateDce }}
    </small>
  </div> -->

  <div id="q7" [@error]='error=="dateTravaux"' (click)="scrollTo('#q7')" class="floating-card">
    <label id="dateTravaux" class="question-label">Date prévisionnelle début des travaux</label>
    <div class="row p-fluid">
      <p-calendar dateFormat="dd/mm/yy" formControlName="dateTravaux" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2040"></p-calendar>
    </div>
    <small *ngIf="formErrors.dateTravaux" class="text-danger">
      {{ formErrors.dateTravaux }}
    </small>
  </div>
  
  <div id="q8"[@error]='error=="budget"'  (click)="scrollTo('#q8')" class="floating-card">
    <label class="question-label">Budget (k€)</label>
    <div class="row">
      <input type="number" class="no-input-number-arrows" placeholder="100" formControlName="budget">
    </div>
  </div>

  <!-- [@error]='error=="image"' (click)="scrollTo('#q9')" -->
  <div id="q9" class="floating-card">
    <label id="dateTravaux" class="question-label">Ajouter une image au projet</label>

    <div class="row">
      <!-- delete or modify thumbnail image -->
      <div [ngStyle]="{'display': updating ? 'block' : 'none', 'margin-bottom': '15px' }">
        <img *ngIf="!authService.currentEnvironment" id="img1" [src]='mainImage' height="100" />
        <img *ngIf="authService.currentEnvironment" id="img1" [src]="onpremiseProjectImage" height="100" /> 
      </div>
    </div>      
    <!-- <div  class="row margin-bottom-15" *ngIf="!updating &&(mainImage || minarmProjectImage)">
      <img id="img1" [src]='mainImage || minarmProjectImage' height="100" />
    </div> -->
    <div class="row image-container">
      
      
      <div class="block margin-bottom-10" *ngIf="!updating && (mainImage || onpremiseProjectImage)">
        <img id="img1" [src]='mainImage || onpremiseProjectImage' height="100" />
      </div>

      <div class="flex">
        <p-fileUpload mode="basic" accept=".png, .jpg, .jpng" chooseLabel="Naviguer" name="demo1" url="/api/v1/upload-file" [auto]="true"
        (onUpload)="onUploadSingleImage($event)"></p-fileUpload>

        <ng-container *ngIf="updating && (project.mainImage || mainImage)">
          <button class="ag-btn-bordered-sm remove-btn margin-left-15" (click)="confirmRemove()">Supprimer</button>
        </ng-container>

      </div>      
      <!-- toCheck -->
      <!-- <ng-container *ngIf="updating && (project.mainImage || mainImage)">
        <button class="ag-btn-bordered-sm remove-btn margin-right-5" (click)="confirmRemove()">Supprimer</button>
      </ng-container>
      <p-fileUpload mode="basic" chooseLabel="Naviguer" name="demo1" url="/api/v1/upload-file" [auto]="true"
      (onUpload)="onUploadSingleImage($event)"></p-fileUpload> -->
      
      <div *ngIf="uploading" style="margin-left: 20px;">
        <app-spinner></app-spinner>
      </div>
      
    </div>


    <small *ngIf="imageError && !uploading" class="text-danger">
      Désolé, l'image n'a pas pu être téléchargé
    </small>
  </div>

</form>


<p-confirmDialog header="Supprimer l'image du projet" icon="pi pi-exclamation-triangle" #cd>
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Annuler" (click)="cd.reject()"></button>
    <button  class="modal-confirm-button" type="button" pButton icon="pi pi-check" label="Supprimer" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>


<div id="remove-thumbnail-modal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Remove Thumbnail </h4>
      </div>
      <div class="modal-body">
        <p>
          Are you sure you want to remove the Thumbnail?
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Plus tard</button>
        <button (click)="removeThumnail()" type="button" class="btn btn-primary">C'est parti !</button>
      </div>
    </div>

  </div>
</div>
