import { Pipe, PipeTransform, NO_ERRORS_SCHEMA } from '@angular/core';

@Pipe({
  standalone:true,
  name: 'dateFr'
})
export class DateFrPipe implements PipeTransform {
  public months = ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
  transform(value:number) {
      if (value) {
          var d = new Date(value);
          return d.getDate() + " " + this.months[d.getMonth()]  + " " + d.getFullYear();
      }
      return value;
  }
}
