import { Injectable } from '@angular/core';
import { Helpers } from './helpers'
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })

export class ProductService {

  constructor(private http: HttpClient){}
  
  getWebsiteData(url) : Observable<any>{
    let option = new Helpers().getHeader();
    return this.http.get(`/api/v1/products/scrappe?url=${url}`,{headers : option.headers})
    .pipe(map((response: any) => {
      return response;
    }));;
  }

  generatePDF(dpgfId: string): Observable<Blob> {
    let option = new Helpers().getHeader();
    return this.http.post(`/api/v2/dpgfs/${dpgfId}/generate-products-pdf`, {}, {
      headers : option.headers,
      responseType: 'blob'
    });
  }
}