<div class="margin-left-15 margin-top-32">
  <div class="ag-card-1 padding-24" style="color: #0A1747">
    <p class="ag-heading-h4">Liste des fiches les plus impactantes sur le projet <span class="ag-text-success-tertiary">{{ projectTitle }}</span></p>
    <table class="table table-striped table-borderless">
      <thead>
        <tr>
          <th>Désignation fiche INIES</th>
          <th>Lot</th>
          <th style="text-align: center;">Type de donnée</th>
          <th style="text-align: center;">Part d'impact sur le projet</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let post of sortBy('totalImpactQuantity') | paginate: { itemsPerPage: 30, currentPage: postsPage}">
          <td>{{post.nom}} </td>
          <td>{{post.lotPCE | parseLotName}}</td>
          <td>
            <div class="one-card" [ngClass]="post.declarationType? 'declarationTypeCard'+post.declarationType:'declarationTypeCard1'">
              <p>{{declarationTypeMap[post.declarationType]}}</p>
            </div>
          </td>
          <td style="text-align: center;">{{(post.totalImpact*post.quantity | parsePercentageImpact: acvData?.projectResult?.CO2DynamicImpacts?.CO2DynamicTotalCDV) | number:'1.0-2':'fr'}} %</td>
        </tr>
      </tbody>
    </table>
    <div class="pagination-container" *ngIf="posts.length">
      <pagination-controls previousLabel="Précédente" nextLabel="Suivante" (pageChange)="postsPage = $event"></pagination-controls>
    </div>
  </div>
</div>
