import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ChangeDetectorRef, NO_ERRORS_SCHEMA } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AuthService } from '../../../_services/index';
import { NgSharedModule } from 'src/app/all/ngshared.module';

import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';

@Component({
    selector: 'app-artisan-edit-info-perso',
    imports:[
    SpinnerComponent,
    NgSharedModule
],
    templateUrl: './artisan-edit-info-perso.component.html',
    styleUrls: ['./artisan-edit-info-perso.component.scss'],
    standalone: true,

})
export class ArtisanEditInfoPersoComponent implements OnInit {
  @Input() user: any;
  @Input() artisanEmailAgency: any;
  @Output() userUpdated: EventEmitter<any> = new EventEmitter<any>();
  
  public subscriptionForm: UntypedFormGroup;userId;artisanId;disabled= true;loadingButton:boolean=false;
  public formErrors = {
    firstName: "",
    lastName: "",
    phone:"",
    emailAgency:""
  };
  private validationMessages = {
    lastName: {
      required: "Le nom est obligatoire."
    },
    firstName: {
      required: "Le prénom est obligatoire."
    },
    phone:{
      required: "Le numéro de téléphone est obligatoire."
    },
    emailAgency:{
      required: "Le mail de l'agence est obligatoire"
    }
  };
  constructor(private fb: UntypedFormBuilder,private authService: AuthService,private cd: ChangeDetectorRef) { }
  
  ngOnInit(): void {
    this.createForm();
    this.subscriptionForm.patchValue({firstName:this.user.firstName,lastName:this.user.lastName,emailAgency:this.artisanEmailAgency,phone:this.user.phone});
  }
  private createForm() {
    this.subscriptionForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailAgency: ['', [Validators.required,Validators.email]],
      phone: ['', Validators.required],
    });
    this.onValueChanged();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.createForm();
    if ((changes["user"] || changes["artisanEmailAgency"]) && this.subscriptionForm) {
      this.userId = changes.user.currentValue._id;
      this.subscriptionForm.patchValue({firstName:changes.user.currentValue.firstName,lastName:changes.user.currentValue.lastName,emailAgency:this.artisanEmailAgency||changes.artisanEmailAgency.currentValue,phone:changes.user.currentValue.phone});
    }
  }
  public UpdateUserData(){
    let body = this.subscriptionForm.value;
    this.loadingButton = true;
    if(this.isValid()){
    this.authService.updateUserInformation(this.userId,body)
        .subscribe(
          data => {
              this.disabled = true
              this.artisanEmailAgency = body.emailAgency;
              let userData = [{
                email:this.user.email,
                firstName:body.firstName,
                lastName:body.lastName,
                phone:body.phone,
                _id:this.userId
              }]
              
              let objUser = {
                user:userData,
                artisant:this.artisanEmailAgency
              }
              this.userUpdated.emit(objUser)
              this.loadingButton = false;
            },
            error => {
                this.loadingButton = false;
                console.log("error",error)
            });
    }
  }
  public valueChanged (val,name){
    this.disabled = false;
  }
  private onValueChanged(data ? : any) {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
            this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
  public markFormAsTouched():void {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      control.markAsDirty();
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
    }
  }
  public isValid(){
    this.markFormAsTouched();
    return this.subscriptionForm.valid
  }

  public getFormData() {
    return this.subscriptionForm.value;
  }
}
