<div class="progress-menu-items flex space-between">

  <div class="single-menu-item text-center width-20" 
    *ngFor="let menuItem of progressMenuItems; let i = index; let last = last;" 
    [ngClass]="{'selected': selectedStep == menuItem.value,
      'done': progressMenuItemsNumbers[selectedStep] > i }">
    <div class="menu-item-circle inline-block-item">
      <span class="inner-dot"></span>
      <div class="check-icon">
        <img src="/assets/corps-etats/check-icon.svg" />
      </div>
    </div>
    <p class="menu-item-label">{{ menuItem.label }}</p>
    <span class="progress-bar" *ngIf="!last"></span>
  </div>

</div>