import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, NO_ERRORS_SCHEMA } from '@angular/core';
import { MessageService } from 'primeng/api';
const _ = require('lodash');
import {
  calculationsInformations
} from './data';

import { DpgfPostesService } from 'src/app/_services/index';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone:true,
    selector: 'app-acv-project-eau-page',
    imports:[NgSharedModule],
    providers: [MessageService],
    templateUrl: './acv-project-eau-page.component.html',
    styleUrls: ['./acv-project-eau-page.component.scss'],
})
export class AcvProjectEauPageComponent implements OnInit {
  @Input() dpgfId;
  @Input() acvData;
  @Input() isProjectDemo:Boolean=false;
  @Input() state;
  @Output() sendToParent = new EventEmitter<any>();

  public selectedUsage = '1';
  public calculationPreFixName: String = 'calculation';
  public customLockPreFixName: String = 'custom_lock';
  private Q_convValues: any = {
    '1': 48,
    '2': 48,
    '3': 5.58,
    '4': 1.44,
    '5': 1.44,
  };
  public customCalculatedSections: any = {
    // 'volumeConsommationEauPotable': 0,
    // 'volumeEauxUsees': 0,
    // 'eauxPluviales': 0,
    // 'parcelleVolumeConsommationEauPotable': 0,
    // 'parcelleVolumeEauxUseesRejetees': 0
  }
  public calculatedSections: any = {
    'volumeConsommationEauPotable': 0,
    'volumeEauxUsees': 0,
    'eauxPluviales': 0,
    'parcelleVolumeConsommationEauPotable': 0,
    'parcelleVolumeEauxUseesRejetees': 0
  }
  public prevState: any = {};

  public currentView: string = '';
  public selectedOption: string = '';
  public selectedFormType: string = '';
  public selectedbatiment: string;
  public selectedZone: string;
  public zoneIsSelected: boolean = false;dpgf;

  public zonesNamesWithFather: any;

  constructor(
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private dpgfPostesService:DpgfPostesService
  ) { }

  ngOnInit(): void {
    this.fillInputs(this.acvData['projectEau']);

    // let zonesNamesWithFather = _.get(this.acvData,'projectRSET.zonesNamesWithFather',[]);
    this.zonesNamesWithFather = _.get(this.acvData,'projectRSET.zonesNamesWithFather',[]);
    this.fillEntities(this.zonesNamesWithFather);

    this.fillLocksStates(this.zonesNamesWithFather);

    /// set Selected Zone Usage
    this.selectedUsage = _.get(this.acvData['projectDescription'], `${this.selectedbatiment['trimmedValue']}.${this.selectedZone}.Usage`, 1);

    this.toggleEquipementsSanitaires();
    this.fromGlobalStateToLocal(this.state);
    this.cd.detectChanges();
    // console.log('disabledInputs : ', this.disabledInputs);
  }
  private fromGlobalStateToLocal(obj){
    if(!obj)return;
    for(let key in obj){
      for(let nestedKey in obj[key]){
        _.set(this.prevState, `${key}.${nestedKey}`, obj[key][nestedKey]);
      }
    }
  }

  private batimentsZonesPaths:any = [];
  private fillEntities(batiments=[]){

    for (const key in batiments) {

      let zones=batiments[key]
      let trimmedBatiment = key.replace(/ /g, "-");
      let batimentObject = {label: `${key}`, value: `${key}`, trimmedValue: trimmedBatiment, type: 'batiment',zones:[]};

      for(let i = 0; i < zones.length; i++){
        let trimmedZone = zones[i].replace(/ /g, "-");
        let zoneObject = {label: `${zones[i]}`, value: `${zones[i]}`, trimmedValue: trimmedZone, type: 'zone'};
        /// Default radios
        let path = `${trimmedBatiment}.${trimmedZone}`;
        this.batimentsZonesPaths.push(path);
        // array choices in select
        batimentObject.zones.push(zoneObject);

        /// Get & Set Lock States
        let lockStateVolumeConsommationEauPotable = _.get(this.acvData['projectEau'], `${trimmedBatiment}.${trimmedZone}.lock_state-volumeConsommationEauPotable`, false);
        let lockStateVolumeEauxUsees = _.get(this.acvData['projectEau'], `${trimmedBatiment}.${trimmedZone}.lock_state-volumeEauxUsees`, false);
        let lockStateEauxPluviales = _.get(this.acvData['projectEau'], `${trimmedBatiment}.${trimmedZone}.lock_state-eauxPluviales`, false);

        _.set(this.disabledInputs, `${trimmedBatiment}.${trimmedZone}`, {
            'volumeConsommationEauPotable': lockStateVolumeConsommationEauPotable,
            'volumeEauxUsees': lockStateVolumeEauxUsees,
            'eauxPluviales': lockStateEauxPluviales,
        });

      }
      this.projectDescriptionOptions.push(batimentObject)
    }
    /// if no batimes add the defaults
    if(!Object.keys(batiments).length){
      let batiment = {
        label: `Bâtiment`,
        value: `Bâtiment`,
        trimmedValue: 'Bâtiment',
        type: 'batiment',
        zones:[
          {
            label: `Zone`, value: `Zone`, trimmedValue: 'Zone', type: 'zone'
          }
        ]
      };
      // _.set(this.disabledInputs, `${batiment['trimmedValue']}.${batiment.zones[0]['trimmedValue']}`, disabledInputsValues);

      /// Get & Set Lock States
      let lockStateVolumeConsommationEauPotable = _.get(this.acvData['projectEau'], `${batiment['trimmedValue']}.${batiment['zones'][0]['trimmedValue']}.lock_state-volumeConsommationEauPotable`, false);
      let lockStateVolumeEauxUsees = _.get(this.acvData['projectEau'], `${batiment['trimmedValue']}.${batiment['zones'][0]['trimmedValue']}.lock_state-volumeEauxUsees`, false);
      let lockStateEauxPluviales = _.get(this.acvData['projectEau'], `${batiment['trimmedValue']}.${batiment['zones'][0]['trimmedValue']}.lock_state-eauxPluviales`, false);

      _.set(this.disabledInputs, `${batiment['trimmedValue']}.${batiment['zones'][0]['trimmedValue']}`, {
          'volumeConsommationEauPotable': lockStateVolumeConsommationEauPotable,
          'volumeEauxUsees': lockStateVolumeEauxUsees,
          'eauxPluviales': lockStateEauxPluviales,
      });

      this.projectDescriptionOptions.push(batiment);
    }
    this.projectDescriptionOptions.push({ label: 'Parcelle', value: 'parcelle', type: 'parcelle', trimmedValue: 'parcelle' });

    // set initial Form
    this.selectedbatiment = this.projectDescriptionOptions[0];
    // this.selectedOption = this.selectedbatiment['value'];
    this.currentView = this.projectDescriptionOptions[0]['zones'][0]['type'];
    this.selectedZone = this.projectDescriptionOptions[0]['zones'][0]['trimmedValue'];
    this.selectedOption = `${this.selectedbatiment['trimmedValue']}.${this.selectedZone}`;

    this.zoneIsSelected = true;
    this.selectedFormType = 'zone';

    /// set default Radios
    this.setDefaultValues(this.batimentsZonesPaths);
  }

  private toggleEquipementsSanitaires(){
    for(let key in _.get(this.prevState, `${this.selectedOption}`,{})){
      if(key.includes('UtilisationEquipementsSanitairesEconomes')){
        let value = _.get(this.prevState, `${this.selectedOption}.${key}`,'') == 'Oui' ? true : false;
        this.disabledInputs['UtilisationEquipementsSanitairesEconomes'] = value; /// this makes it shows
      }
    }
  }

  public disabledInputs: any = {
    'reseauxCollecteEauxUseesPluviales': false,

    // 'UtilisationEquipementsSanitairesEconomes': false,
    // 'volumeEauxUsees': false,
    // 'EauxPluvialesTitle': false,
    // /// Parcell
    // 'parcelleVolumeConsommationEauPotable':false,
    // 'parcelleVolumeEauxUseesRejetees':false,
    // 'reseauxCollecteEauxUseesPluviales': false,
    'parcelle': {
      'parcelleVolumeConsommationEauPotable': false,
      'parcelleVolumeEauxUseesRejetees': false,
    }
  }

  // 1- Sidebar Options
  public projectDescriptionOptions: any = [];

  // 3- forms
  public forms: any = {
    'zone': {
      mainTitle: 'Contributeur Eau',
      mainDescription: 'Le contributeur Eau concerne les consommations et rejets d’eau, et couvre, pour la phase d’exploitation du bâtiment, tous les usages de l’eau à l’échelle du bâtiment et la gestion des eaux pluviales captées par le bâtiment ainsi que leur assainissement.',
      items: [
        {
          rowTitle: "Volume de consommation d'eau potable",
          type: "lock_title",
          // modelVariable:"UtilisationEquipementsSanitairesEconomes",
          modelVariable:"volumeConsommationEauPotable",
          inputLabel: "",
          calculationVariable: "volumeConsommationEauPotable"
        },
        {
          label: "Surface végétalisée de la zone arrosée (murs et toitures végétalisés)",
          type: "number",
          placeholder: "0",
          modelVariable: "surfaceVegetaliseeAoneArrosee",
          inputLabel: "m²",
          lockId: "UtilisationEquipementsSanitairesEconomes",
          calculationId:"volumeConsommationEauPotable"
          // lockId: 'UtilisationEquipementsSanitairesEconomes'
        },
        // {
        //   label: "Utilisation d'équipements sanitaires économes",
        //   labelIsBold: true,
        //   isToggle: true,
        //   // lock: true,
        //   type: 'radio',
        //   disabled: false,
        //   modelVariable: "UtilisationEquipementsSanitairesEconomes",
        //   optionName: "UtilisationEquipementsSanitairesEconomes",
        //   options: [
        //     {label: 'Oui', value: 'Oui'},
        //     {label: 'Non', value: 'Non'},
        //   ],
        //   defaultOption: 'Non',
        //   defaultValue: 'Non'
        // },
        // {
        //   label: '',
        //   lockId: "UtilisationEquipementsSanitairesEconomes",
        //   labels: ["Type d’équipement", "Quantité"],
        //   labelIsBold: true,
        //   type: 'table',
        //   options: calculationsInformations[this.selectedUsage]
        // },
        {
          rowTitle: "Volume des eaux usées",
          labelIsBold: true,
          modelVariable: "volumeEauxUsees",
          // rowDescription: "Cette partie permet d’évaluer l’impact de eaux usées rejetées sur toute la durée de vie du bâtiment.",
          type: "lock_title",
          calculationVariable: "volumeEauxUsees"
        },
        {
          label: "Système d'assainissement *",
          type: "radio", placeholder: "",
          disabled: true,
          options: [
            {value: 'Collectif', label: 'Collectif'},
            {value: 'Non collectif', label: 'Non collectif'}
          ],
          defaultOption: 'Non collectif',
          optionName: "systemeAssainissement",
          modelVariable:"systemeAssainissement",
        },
        {
          label: "Quantité d'eau potable utilisée pour l'arrosage des surfaces végétalisées",
          type: "number", placeholder: "0",
          modelVariable: "QuantiteEauPotableArrosageSurfacesVegetalisees",
          inputLabel: "m³",
          lockId: "volumeEauxUsees",
          calculationId:'volumeEauxUsees'
        },
        {
          label: "Quantité d'eau de pluie utilisée pour les usages intérieurs", type: "number",
          placeholder: "0", options: [],
          modelVariable: "quantiteEauPluieUtiliseeUsagesInterieurs",
          inputLabel: "m³",
          lockId: "volumeEauxUsees",
          calculationId:'volumeEauxUsees'
        },
        {
          rowTitle: "Eaux pluviales",
          // rowDescription: "Cette partie permet d’évaluer l’impact de eaux pluviales rejetées sur toute la durée de vie du bâtiment.",
          type: "lock_title",
          // modelVariable: "EauxPluvialesTitle",
          modelVariable: "eauxPluviales",
          calculationVariable: "eauxPluviales"
        },
        {
          label: "Réseaux de collecte des eaux usées et pluviales *",
          type: "radio", placeholder: "",
          isToggle: true,
          options: [
            {value: 'Unitaire', label: 'Unitaire'},
            {value: 'Séparatif', label: 'Séparatif'}
          ],
          defaultOption: 'Séparatif',
          optionName: "reseauxCollecteEauxUseesPluviales",
          modelVariable:"reseauxCollecteEauxUseesPluviales",
        },
        {
          label: "Surface de toiture (bâtiments et aires de stationnement couverts)*",
          type: "number", placeholder: "0",
          modelVariable:"surfaceToitureBatiments",
          inputLabel: "m²",
          // lockId: "EauxPluvialesTitle",
          lockId: "reseauxCollecteEauxUseesPluviales",
          calculationId:'eauxPluviales'
        },
        {
          label: "Surface de parkings impéerméabilisés (non couverts)*",
          type: "number", placeholder: "0",
          modelVariable:"surfaceParkingsImpeermeabilises",
          inputLabel: "m²",
          // lockId: "EauxPluvialesTitle",
          lockId: "reseauxCollecteEauxUseesPluviales",
          calculationId:'eauxPluviales'
        },
      ]
    },
    //////////////////////////////////
    /// 2 - Parcelle
    //////////////////////////////////
    'parcelle': {
      items: [
        {
          rowTitle: "Votre consommation d’eau potable",
          type: "lock_title",
          modelVariable:"parcelleVolumeConsommationEauPotable",  inputLabel: "",
          calculationVariable: "parcelleVolumeConsommationEauPotable"
        },
        {
          label: "Surface extérieure nettoyée",
          type: "number",
          placeholder: "0",
          modelVariable:"surfaceExterieureNettoyee",
          inputLabel: "m²",
          showCustomUnite: true, customUnitVariable: 'surfaceExterieureNettoyee',
          lockId: "parcelleVolumeConsommationEauPotable",
          calculationId:'parcelleVolumeConsommationEauPotable'
        },
        {
          label: "Consommation d’eau particulières (piscine, spa, etc.)", type: "number", placeholder: "0",
          modelVariable:"consommationEauParticulieres", inputLabel: "m³", showCustomUnite: true,
          customUnitVariable: 'consommationEauParticulieres',
          lockId: "parcelleVolumeConsommationEauPotable",
          calculationId:'parcelleVolumeConsommationEauPotable'
        },
        {
          label: "Quantité d'eau pluviale utilisée pour les besoins de la parcelle (arrosage et usages particuliers)",
          type: "number", placeholder: "0", modelVariable:"quantiteEauPluvialeUtiliseeBesoinsParcelle",
          inputLabel: "m³", showCustomUnite: true, customUnitVariable: 'quantitéEauPluvialeUtilisee',
          lockId: "parcelleVolumeConsommationEauPotable",
          calculationId:'parcelleVolumeConsommationEauPotable'
        },
        {
          rowTitle: "Volume d’eaux usées rejetées",
          type: "lock_title",
          modelVariable:"parcelleVolumeEauxUseesRejetees",  inputLabel: "",
          calculationVariable: "parcelleVolumeEauxUseesRejetees"
        },
        {
          label: "Surfaces imperméabilisés sur la parcelle (hors parking et emprise au sol du bâtiment) *",
          type: "number", placeholder: "0",
          modelVariable:"surfacesImpermeabilisesParcelle",  inputLabel: "m²",
          showCustomUnite: true, customUnitVariable: 'surfacesImpermeabilisesParcelle',
          lockId: "parcelleVolumeEauxUseesRejetees",
          calculationId:"parcelleVolumeEauxUseesRejetees"
        },
      ]
    }
  };

  public optionSelected(currentView, variable, value){
    // let key = `${this.selectedOption}.${variable}`;
    let batimentKey = `${this.selectedbatiment['trimmedValue']}.${variable}`;
    let zoneKey = `${this.selectedbatiment['trimmedValue']}.${this.selectedZone}.${variable}`;
    let key = !this.zoneIsSelected ? batimentKey : zoneKey;
    // console.log(key);

    // this.prevState[key] = value;
    _.set(this.prevState, `${key}`,value)

    let updateData = {
      [key]: value
    }
    this.sendDataToParent(updateData);
  }
  private toggleRadioInputs(modelVariable, value){
    if(modelVariable == 'UtilisationEquipementsSanitairesEconomes'){
      let toggle = false;
      value == 'Oui' ? toggle = true : toggle = false;
      this.disabledInputs[modelVariable] = toggle;
    }else if(modelVariable == 'reseauxCollecteEauxUseesPluviales'){
      let toggle = false;
      value == 'Séparatif' ? toggle = true : toggle = false;
      /// may need to be removed
      this.disabledInputs[modelVariable] = toggle;

      _.set(this.disabledInputs, `${this.selectedOption}.reseauxCollecteEauxUseesPluviales`, toggle);
    }
  }
  public radioSelected(currentView, row, value){
    if(row.isToggle){
      this.toggleRadioInputs(row.modelVariable, value);
    }
    let variable = row.modelVariable;
    let batimentKey = `${this.selectedbatiment['trimmedValue']}.${variable}`;
    let zoneKey = `${this.selectedbatiment['trimmedValue']}.${this.selectedZone}.${variable}`;
    let key = !this.zoneIsSelected ? batimentKey : zoneKey;

    // this.prevState[key] = value;
    _.set(this.prevState, `${key}`,value)

    let updateData = {
      [key]: value
    }

    this.sendDataToParent(updateData);
  }
  public inputTyped(currentView, row, value){
    let batimentKey = `${this.selectedbatiment['trimmedValue']}.${row.modelVariable}`;
    let zoneKey = `${this.selectedbatiment['trimmedValue']}.${this.selectedZone}.${row.modelVariable}`;
    let key = !this.zoneIsSelected ? batimentKey : zoneKey;

    // this.prevState[key] = value;
    _.set(this.prevState, `${key}`,value)

    let updateData = {
      [key]: value
    }
    this.sendDataToParent(updateData);
    if(row.calculationId)
      switch(row.calculationId){
      case 'volumeConsommationEauPotable':
        this.eauPotableFormula();
      break;
      case 'volumeEauxUsees':
        this.eauxUseesFormula();
      break;
      case 'eauxPluviales':
        this.eauxPluvialesFormula();
      case 'parcelleVolumeConsommationEauPotable':
        this.parcellEauPotableFormula();
        break;
      case 'parcelleVolumeEauxUseesRejetees':
        this.parcellEauxUseesFormula();
      break;
    }
  }
  public getStateValue(path, value){
    return _.get(this.prevState, `${path}.${value}`, undefined);
  }
  public getCalculationsStateValue(path, value){
    return _.get(this.calculatedSections, `${path}.${value}`, 0);
  }
  public getCustomCalculationValue(path, value){
    // console.log('getCustomCalculationValue : ', path, value);
    return _.get(this.customCalculatedSections, `${path}.${value}`, 0);
  }
  public calculationInputTyped(modelVariable, variable, value){
    let batimentKey = `${this.selectedbatiment['trimmedValue']}.${this.customLockPreFixName}_${variable}`;
    let zoneKey = `${this.selectedbatiment['trimmedValue']}.${this.selectedZone}.${this.customLockPreFixName}_${variable}`;
    let calculationName = !this.zoneIsSelected ? batimentKey : zoneKey;

    // this.prevState[calculationName] = value;
    _.set(this.prevState, `${calculationName}`,value)

    let updateData = {
      // [key]: value
      [calculationName]: value
    }

    let calcVariable = `${this.selectedbatiment['trimmedValue']}.${this.selectedZone}.${this.customLockPreFixName}_${variable}`;
    // _.set(this.customCalculatedSections, calcVariable, value);
    _.set(this.customCalculatedSections, calculationName, value);

    this.sendDataToParent(updateData);
  }
  private sendDataToParent(updateData){
    // console.log('sendDataToParent : ', updateData);
    this.sendToParent.emit(updateData);
  }
  private fillInputs(obj){
    for(let key in obj){
      _.set(this.prevState, `${key}`,{..._.get(this.prevState, `${key}`,{}), ...obj[key]})
      this.fillCalculationsInputs(key, obj[key]);
    }
  }
  private fillLocksStates(batiments){
    /// Parcelle
    let lockStateParcelleVolumeEauxUseesRejetees = _.get(this.acvData['projectEau'], `parcelle.lock_state-parcelleVolumeEauxUseesRejetees`, false);
    let lockStateParcelleVolumeConsommationEauPotable = _.get(this.acvData['projectEau'], `parcelle.lock_state-parcelleVolumeConsommationEauPotable`, false);

    _.set(this.disabledInputs['parcelle'], `parcelleVolumeEauxUseesRejetees`, lockStateParcelleVolumeEauxUseesRejetees);
    _.set(this.disabledInputs['parcelle'], `parcelleVolumeConsommationEauPotable`, lockStateParcelleVolumeConsommationEauPotable);

    for (const key in batiments) {
      let trimmedBatiment = key.replace(/ /g, "-");
      let zones=batiments[key];
      for(let i = 0; i < zones.length; i++){
        let trimmedZone = zones[i].replace(/ /g, "-");

        let lockStateVolumeConsommationEauPotable = _.get(this.acvData['projectEau'], `${trimmedBatiment}.${trimmedZone}.lock_state-volumeConsommationEauPotable`, false);
        let lockStateVolumeEauxUsees = _.get(this.acvData['projectEau'], `${trimmedBatiment}.${trimmedZone}.lock_state-volumeEauxUsees`, false);
        let lockStateEauxPluviales = _.get(this.acvData['projectEau'], `${trimmedBatiment}.${trimmedZone}.lock_state-eauxPluviales`, false);

        // _.set(this.disabledInputs, `${trimmedBatiment}.${trimmedZone}.volumeConsommationEauPotable`, lockStateVolumeConsommationEauPotable);
        // _.set(this.disabledInputs, `${trimmedBatiment}.${trimmedZone}.volumeEauxUsees`, lockStateVolumeEauxUsees);
        // _.set(this.disabledInputs, `${trimmedBatiment}.${trimmedZone}.eauxPluviales`, lockStateEauxPluviales);
      }
    }
  }

  private fillCalculationsInputs(key, obj){
    let caluclationKeys = ['calculation-', 'custom_lock_'];
    /// Either The Calculation or 0

    if(key == 'parcelle'){
      _.set(this.calculatedSections, `${key}.calculation-parcelleVolumeEauxUseesRejetees`, obj['calculation-parcelleVolumeEauxUseesRejetees']);
      _.set(this.calculatedSections, `${key}.calculation-parcelleVolumeConsommationEauPotable`, obj['calculation-parcelleVolumeConsommationEauPotable']);

      _.set(this.customCalculatedSections, `${key}.custom_lock_parcelleVolumeConsommationEauPotable`, obj['custom_lock_parcelleVolumeConsommationEauPotable']);
      _.set(this.customCalculatedSections, `${key}.custom_lock_parcelleVolumeEauxUseesRejetees`, obj['custom_lock_parcelleVolumeEauxUseesRejetees']);
    }
    else{
      for(let nestedKey in obj){
          if(Object.keys(obj).length){
            for(let zoneKey in obj[nestedKey]){
              if(zoneKey.includes(caluclationKeys[0])){
                /// Calculations
                _.set(this.calculatedSections, `${key}.${nestedKey}.${zoneKey}`, obj[nestedKey][zoneKey]);
              }
              if(zoneKey.includes(caluclationKeys[1])){
                /// Custom Inputs
                _.set(this.customCalculatedSections, `${key}.${nestedKey}.${zoneKey}`, obj[nestedKey][zoneKey]);
              }
            }
          }
      }
    }
  }
  public getLockPath(path){
    let value = _.get(this.disabledInputs, `${path}`, false);
    return  _.get(this.disabledInputs, `${path}`, false);
  }

  private calculationsInformationsTypeCount(key){
    let data = {};
    let arr = calculationsInformations[key];

    for(let i = 0; i < arr.length; i++){
      let {type} = arr[i];
      if(!data[type]){
        data[type] = 1;
      }else{
        data[type]++;
      }
    }
    return data;
  }

  private getFequipement(){
    let Fequipement = 0;
    let selectedZoneUsage = parseFloat(_.get(this.acvData, `projectDescription.${this.selectedOption}.Usage`, 1));
    let arr = calculationsInformations[selectedZoneUsage];

    let typeCounts = this.calculationsInformationsTypeCount(selectedZoneUsage);
    // FP:  (1-Facteur de correction p)*(Quantity of equipement p/Total quantity of equipments of type i)

    for(let i = 0; i < arr.length; i++){
      let { facteurDeCorrection, type, modelVariable } = arr[i];
      facteurDeCorrection = parseFloat(facteurDeCorrection).toFixed(2);

      let quantityOfEquipement = 0;

      if(_.get(this.prevState, `${this.selectedOption}.${modelVariable}`, false)){
        // quantityOfEquipement = this.prevState[this.selectedOption][modelVariable];
        quantityOfEquipement = _.get(this.prevState, `${this.selectedOption}.${modelVariable}`, 0);
        // console.log('quantityOfEquipement : ', quantityOfEquipement);
      }
      if(facteurDeCorrection){
        // console.log('facteurDeCorrection : ', facteurDeCorrection);
        let fp = (1 - facteurDeCorrection) * (quantityOfEquipement/typeCounts[type]);
        // !isNaN(fp) ? Fequipement += fp : null;
        !isNaN(fp) ? Fequipement += fp : 0;
      }
    }

    return 1;
  }
  /// Zone Calculations
  private eauPotableFormula(){
    // Q_conv*Fequipement*Nocc+SVégétaliseeArrosee*0.66
    let descriptionDonnesgenerales = _.get(this.acvData, `projectDescription.${this.selectedOption}.Usage`, 1);

    let Q_conv = parseFloat(this.Q_convValues[descriptionDonnesgenerales]);

    // let Fequipement = this.getFequipement();
    let Fequipement = 1;
    // let Nocc = parseFloat(this.acvData['projectDescription']['zone']['zone_donnees_generales-NbOccupants']) || 0;
    let Nocc = parseFloat(_.get(this.acvData, `projectDescription.${this.selectedOption}.NbOccupants`, 0))
    // let SVégétaliseeArrosee = parseFloat(this.prevState[this.selectedOption]['surfaceVegetaliseeAoneArrosee']) || 0;
    let SVégétaliseeArrosee = parseFloat(_.get(this.prevState, `${this.selectedOption}.surfaceVegetaliseeAoneArrosee`, 0));
    let value = Q_conv * Fequipement * Nocc + SVégétaliseeArrosee * 0.66;
    if(!value || Number.isNaN(value)){
      value = 0;
    }

    /// save the Calculation
    let calculationName = `${this.selectedOption}.${this.calculationPreFixName}-volumeConsommationEauPotable`;
    _.set(this.calculatedSections, calculationName, value);

    ///

    let body = {
      [calculationName]: value
    };
    // console.log('eauPotableFormula Calculation : ', body);
    this.sendDataToParent(body);
  }
  public eauxUseesFormula(){
    // Q_EauPotable+Q_eauPluieUsagesIntérieurs-0.5*Q_eauPotableArrosage

    // let Q_EauPotable = parseFloat(this.calculatedSections['volumeConsommationEauPotable']) || 0;
    let Q_EauPotable = parseFloat( _.get(this.calculatedSections, `${this.selectedOption}.${this.calculationPreFixName}-volumeConsommationEauPotable`, 0) );
    let Q_eauPluieUsagesIntérieurs = parseFloat( _.get(this.prevState, `${this.selectedOption}.quantiteEauPluieUtiliseeUsagesInterieurs`, 0) );
    let Q_eauPotableArrosage = parseFloat( _.get(this.prevState, `${this.selectedOption}.QuantiteEauPotableArrosageSurfacesVegetalisees`, 0) );
    let value = Q_EauPotable + Q_eauPluieUsagesIntérieurs-0.5*Q_eauPotableArrosage;
    if(!value || Number.isNaN(value)){
      value = 0;
    }

    /// save the Calculation
    let calculationName = `${this.selectedOption}.${this.calculationPreFixName}-volumeEauxUsees`;
    _.set(this.calculatedSections, calculationName, value);

    let body = {
      [calculationName]: value
    };
    this.sendDataToParent(body);
    // console.log('eauxUseesFormula Calculation : ', body);
  }
  public eauxPluvialesFormula(){
    // pluviometrieAnnuelleSite/1000*(Stoitures+SurfaceParkingsImperNonCouverts)

    let pluviometrieAnnuelleSite = parseFloat( _.get(this.acvData['projectDescription'], 'projet.pluviometrieAnnuelleSite', 0) );

    let Stoitures = parseFloat(_.get(this.prevState, `${this.selectedOption}.surfaceToitureBatiments`, 0));
    let SurfaceParkingsImperNonCouverts = parseFloat(_.get(this.prevState, `${this.selectedOption}.surfaceParkingsImpeermeabilises`, 0));
    let value = pluviometrieAnnuelleSite / 1000 * (Stoitures+SurfaceParkingsImperNonCouverts);
    if(!value || Number.isNaN(value)){
      value = 0;
    }

    /// save the Calculation
    let calculationName = `${this.selectedOption}.${this.calculationPreFixName}-eauxPluviales`;
    _.set(this.calculatedSections, calculationName, value);

    let body = {
      [calculationName]: value
    };
    this.sendDataToParent(body);
    // console.log('eauxPluvialesFormula Calculation : ', body);
  }

  ////////////////////////////////////
  /// Parcell Calculations
  ////////////////////////////////////
  public parcellEauPotableFormula(){
    // surfaceVegetalisee*0.66+ConsommationParticuliere+SurfaceNettoyee*0.004-Quantité d'eau pluviale utilisée pour les besoins de la parcelle (arrosage et usages particuliers)


    let surfaceVegetalisee = parseFloat(_.get(this.acvData, 'projectDescription.projet.surfaceVegetalisee', 0));
    let consommationParticuliere = parseFloat(_.get(this.prevState, 'parcelle.consommationEauParticulieres', 0));
    let SurfaceNettoyee = parseFloat(_.get(this.prevState, 'parcelle.surfaceExterieureNettoyee', 0));
    let quantiteEauPluvialeUtiliseeBesoinsParcelle = parseFloat(_.get(this.prevState, 'parcelle.quantiteEauPluvialeUtiliseeBesoinsParcelle',0));

    let value = surfaceVegetalisee*0.66+consommationParticuliere+SurfaceNettoyee*0.004-quantiteEauPluvialeUtiliseeBesoinsParcelle;
    if(!value || Number.isNaN(value)){
      value = 0;
    }

    /// save the Calculation
    let calculationName = `${this.selectedOption}.${this.calculationPreFixName}-parcelleVolumeConsommationEauPotable`;
    _.set(this.calculatedSections, calculationName, value);

    let body = {
      [calculationName]: value
    };
    this.sendDataToParent(body);
  }
  public parcellEauxUseesFormula(){
    // pluviometrieAnnuelleSite/1000*SurfaceImpermeabilisee+ConommationParticuliere+SurfaceNettoyee*0.004

    // let pluviometrieAnnuelleSite = parseFloat(this.acvData['projectDescription']['project']['project_environnement_du_terrain-pluviometrieAnnuelleSite']) || 0;
    let pluviometrieAnnuelleSite = parseFloat(_.get(this.acvData, 'projectDescription.projet.pluviometrieAnnuelleSite', 0));
    let surfaceImpermeabilisee = parseFloat(_.get(this.prevState, 'parcelle.surfacesImpermeabilisesParcelle', 0));
    let consommationParticuliere = parseFloat(_.get(this.prevState, 'parcelle.consommationEauParticulieres', 0));
    let SurfaceNettoyee = parseFloat(_.get(this.prevState, 'parcelle.surfaceExterieureNettoyee', 0));

    let value = pluviometrieAnnuelleSite/1000*surfaceImpermeabilisee+consommationParticuliere+SurfaceNettoyee*0.004;
    if(!value || Number.isNaN(value)){
      value = 0;
    }

    /// save the Calculation
    let calculationName = `${this.selectedOption}.${this.calculationPreFixName}-parcelleVolumeEauxUseesRejetees`;
    _.set(this.calculatedSections, calculationName, value);

    let body = {
      [calculationName]: value
    };
    this.sendDataToParent(body);
  }
  public removeWhiteSpaces(string){
    return string.replaceAll(' ', '');
  }
  public openNestedList(currentOption, ulId){
    /// open Nested Ul
    let trimmedId = this.removeWhiteSpaces(ulId);

    $('.nested-items-ul').removeClass('open');
    $(`#${trimmedId}`).addClass('open');
    // this.zoneIsSelected = false;

    /// select first item
    this.selectedOption = currentOption.value;
    this.selectedbatiment = currentOption;

    if(currentOption['type'] == 'parcelle'){
      this.currentView = 'parcelle';
      this.selectedOption = 'parcelle';
      this.selectedFormType = 'parcelle';
      this.selectedZone = 'parcelle';
      this.zoneIsSelected = false;
    }else{
      this.currentView = currentOption['zones'][0]['type'];
      this.selectedOption = `${currentOption['trimmedValue']}.${currentOption['zones'][0]['trimmedValue']}`;
      this.zoneIsSelected = true;
      this.selectedZone = currentOption['zones'][0]['trimmedValue'];
      this.selectedFormType = currentOption['zones'][0]['type'];
    }
  }
  public listItemSelected(event, item){
    ///////////// NEW
    this.currentView = 'zone';
    this.selectedFormType = item.type || 'zone';

    this.zoneIsSelected = true;
    this.selectedZone = item['trimmedValue'];
    this.selectedOption = `${this.selectedbatiment['trimmedValue']}.${item['trimmedValue']}`;

    $('.nested-items-ul > li').removeClass('selected');
    $(event.currentTarget).addClass('selected');
  }

  public selectOption(option){
    this.selectedOption = option.value;
    this.selectedFormType = option.type || 'zone';
    this.currentView = this.forms[option.type];
  }

  /// Check if Inputs are filled to calculate
  private isCalculationValid(calculationVariable){
    let isValid = true;

    let eauPotableFormulaValues = []
    let eauxUseesFormulaValues = [];
    let eauxPluvialesFormulaValues = [];
    let parcellEauPotableFormulaValues = [];
    let parcellEauxUseesFormulavalues = [];

    /// 0- Shared
    let consommationParticuliere = _.get(this.prevState,'parcelle.consommationEauParticulieres', 0);
    let SurfaceNettoyee = _.get(this.prevState,'parcelle.surfaceExterieureNettoyee', 0);
    let pluviometrieAnnuelleSite = _.get(this.acvData,'projectDescription.projet.pluviometrieAnnuelleSite', 0);

    /// 1- eauPotableFormula
    if(calculationVariable == 'volumeConsommationEauPotable'){
      let zone_donnees_generalesUsage = _.get(this.acvData,`projectDescription.${this.selectedOption}.Usage`, 1);

      let Q_conv = this.Q_convValues[zone_donnees_generalesUsage];
      // let Fequipement = this.getFequipement();
      let Fequipement = 1;
      let Nocc = _.get(this.acvData, `projectDescription.${this.selectedOption}.NbOccupants`, 0);
      let SVégétaliseeArrosee = _.get(this.prevState, `${this.selectedOption}.surfaceVegetaliseeAoneArrosee`, 0);
      SVégétaliseeArrosee = parseFloat(SVégétaliseeArrosee);
      eauPotableFormulaValues = [Q_conv, Fequipement, Nocc, SVégétaliseeArrosee];
      // console.log(eauPotableFormulaValues);
    }

    /// 2-eauxUseesFormula
    if(calculationVariable == 'volumeEauxUsees'){
      let Q_EauPotable = _.get(this.prevState,`${this.selectedOption}.QuantiteEauPotableArrosageSurfacesVegetalisees`, 0);
      Q_EauPotable = parseFloat(Q_EauPotable);
      let Q_eauPluieUsagesIntérieurs = _.get(this.prevState,`${this.selectedOption}.quantiteEauPluieUtiliseeUsagesInterieurs`, 0);
      Q_eauPluieUsagesIntérieurs = parseFloat(Q_eauPluieUsagesIntérieurs);
      // let Q_eauPotableArrosage = this.prevState[this.selectedOption]['QuantiteEauPotableArrosageSurfacesVegetalisees'];
      let Q_eauPotableArrosage = _.get(this.prevState,`${this.selectedOption}.QuantiteEauPotableArrosageSurfacesVegetalisees`, 0);
      Q_eauPotableArrosage = parseFloat(Q_eauPotableArrosage);

      eauxUseesFormulaValues = [Q_EauPotable, Q_eauPluieUsagesIntérieurs, Q_eauPotableArrosage];
      // console.log(eauxUseesFormulaValues);
    }

    /// 3-eauxPluvialesFormula
    if(calculationVariable == 'eauxPluviales'){
    // let Stoitures = this.prevState[this.selectedOption]['surfaceToitureBatiments'];
      let Stoitures = _.get(this.prevState,`${this.selectedOption}.surfaceToitureBatiments`, 0);
      Stoitures = parseFloat(Stoitures);
      // let SurfaceParkingsImperNonCouverts = this.prevState[this.selectedOption]['surfaceParkingsImpeermeabilises'];
      let SurfaceParkingsImperNonCouverts = _.get(this.prevState,`${this.selectedOption}.surfaceParkingsImpeermeabilises`, 0);
      SurfaceParkingsImperNonCouverts = parseFloat(SurfaceParkingsImperNonCouverts);

      eauxPluvialesFormulaValues = [pluviometrieAnnuelleSite, Stoitures, SurfaceParkingsImperNonCouverts];
      // console.log(eauxPluvialesFormulaValues);
    }

    /// 4-parcellEauPotableFormula
    if(calculationVariable == 'parcelleVolumeConsommationEauPotable'){
      let surfaceVegetalisee = _.get(this.prevState,'zone_1.surfaceVegetaliseeAoneArrosee', 0);
      let quantiteEauPluvialeUtiliseeBesoinsParcelle = _.get(this.prevState,'parcelle.quantiteEauPluvialeUtiliseeBesoinsParcelle', 0);

      surfaceVegetalisee = parseFloat(surfaceVegetalisee);
      consommationParticuliere = parseFloat(consommationParticuliere);
      SurfaceNettoyee = parseFloat(SurfaceNettoyee);
      quantiteEauPluvialeUtiliseeBesoinsParcelle = parseFloat(quantiteEauPluvialeUtiliseeBesoinsParcelle);

      parcellEauPotableFormulaValues = [surfaceVegetalisee, consommationParticuliere, SurfaceNettoyee, quantiteEauPluvialeUtiliseeBesoinsParcelle];
      // console.log(parcellEauPotableFormulaValues);
    }

    /// 5-parcellEauxUseesFormula
    if(calculationVariable == 'parcelleVolumeEauxUseesRejetees'){
      let surfaceImpermeabilisee = _.get(this.prevState, 'parcelle.surfacesImpermeabilisesParcelle', 0);

      pluviometrieAnnuelleSite = parseFloat(pluviometrieAnnuelleSite);
      surfaceImpermeabilisee = parseFloat(surfaceImpermeabilisee);
      consommationParticuliere = parseFloat(consommationParticuliere);
      SurfaceNettoyee = parseFloat(SurfaceNettoyee);

      parcellEauxUseesFormulavalues = [pluviometrieAnnuelleSite, surfaceImpermeabilisee, consommationParticuliere, SurfaceNettoyee];
      // console.log(parcellEauxUseesFormulavalues);
    }

    let calulationsValues = {
      'volumeConsommationEauPotable': eauPotableFormulaValues,
      'volumeEauxUsees': eauxUseesFormulaValues,
      'eauxPluviales': eauxPluvialesFormulaValues,
      'parcelleVolumeConsommationEauPotable': parcellEauPotableFormulaValues,
      'parcelleVolumeEauxUseesRejetees': parcellEauxUseesFormulavalues,
    };

    // if(calulationsValues[calculationVariable] && calulationsValues[calculationVariable] != 0){
    if(calulationsValues[calculationVariable]){
      let valuesItems = calulationsValues[calculationVariable];
      for(let i = 0; i < valuesItems.length; i++){
        if(!valuesItems[i]) {
          isValid = false;
        }
      }
    }

    return isValid;
  }

  public unlock(calculationVariable, modelVariable){
    /// NOT used for now
    // this.disabledInputs[modelVariable] = !this.disabledInputs[modelVariable];
    let currentLockState = _.get(this.disabledInputs, `${this.selectedOption}.${modelVariable}`, false);
    _.set(this.disabledInputs, `${this.selectedOption}.${modelVariable}`, !currentLockState);

    let lockStateValue = _.get(this.disabledInputs, `${this.selectedOption}.${modelVariable}`, false);
    let lockStateName = `lock_state-${modelVariable}`;
    /// Save Lock State
    this.saveLockState(lockStateName, lockStateValue);

    let lockPath = this.getLockPath(this.selectedOption+'.'+modelVariable);

    if(!lockPath){
      switch(calculationVariable){
        case 'volumeConsommationEauPotable':
          this.eauPotableFormula();
        break;
        case 'volumeEauxUsees':
          this.eauxUseesFormula();
        break;
        case 'eauxPluviales':
          this.eauxPluvialesFormula();
        case 'parcelleVolumeConsommationEauPotable':
          this.parcellEauPotableFormula();
          break;
        case 'parcelleVolumeEauxUseesRejetees':
          this.parcellEauxUseesFormula();
        break;
      }
    }

  }
  private saveLockState(name, value){
    let batimentKey = `${this.selectedbatiment['trimmedValue']}.${name}`;
    let zoneKey = `${this.selectedbatiment['trimmedValue']}.${this.selectedZone}.${name}`;
    let key = !this.zoneIsSelected ? batimentKey : zoneKey;
    /// Set Lock State
    _.set(this.disabledInputs, key, value);
    let lockStateBody = {
      [key]: value
    }
    this.sendDataToParent(lockStateBody);
  }
  private setDefaultValues(paths){
    for(let i = 0; i < paths.length; i++){
      let valueExists1 = _.get(this.prevState, `${paths[i]}.UtilisationEquipementsSanitairesEconomes`, false);
      let valueExists2 = _.get(this.prevState, `${paths[i]}.systemeAssainissement`, false);
      let valueExists3 = _.get(this.prevState, `${paths[i]}.reseauxCollecteEauxUseesPluviales`, false);

      !valueExists1 ? _.set(this.prevState, `${paths[i]}.UtilisationEquipementsSanitairesEconomes`, 'Non') : null;
      !valueExists2 ? _.set(this.prevState, `${paths[i]}.systemeAssainissement`, 'Non collectif') : null;
      !valueExists3 ? _.set(this.prevState, `${paths[i]}.reseauxCollecteEauxUseesPluviales`, 'Séparatif') : null;

      /// on init make this Hide or show inputs
      let value3CheckedOrNot = valueExists3 == 'Séparatif' ? true : false;
      _.set(this.disabledInputs, `${paths[i]}.reseauxCollecteEauxUseesPluviales`, value3CheckedOrNot);

    }
    // console.log('this.disabledInputs : ', this.disabledInputs);
    this.cd.detectChanges();
  }
  public getDisabledInputValue(lockId){
    let val = _.get(this.disabledInputs, `${this.selectedOption}.${lockId}`, false);
    return val;
  }

  public ngOnDestroy(): void {

  }

}
