import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter , NO_ERRORS_SCHEMA } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgSharedModule } from 'src/app/all/ngshared.module';

// Services Imports
import { DpgfPostesService } from '../../../_services/index';

import { saveAs } from 'file-saver';
import { DpgfModule } from '../../dpgf.module';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  standalone:true,
  selector: 'app-dpgf-devis-popup',
  imports:[
    DpgfModule,
    NgSharedModule
  ],
  templateUrl: './dpgf-devis-popup.component.html',
  styleUrls: ['./dpgf-devis-popup.component.scss']
})
export class DpgfDevisPopupComponent implements OnInit, OnChanges {

  @Input() dpgfPricing:any;
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() openPdfErrorsModal = new EventEmitter<any>();
  @Input() documentType: String = 'devis';
  public selectedLot: any = {};
  public dpgfLot: [] = [];
  public showEntrepriseComments: boolean = false;
  public downloadingDoc: boolean = false;
  public docTypeTitle: any = {
    'devis': 'Exporter le devis',
    'cctp': 'Exporter les CCTP'
  }
  private subscriptions: Array<Subscription> = [];

  constructor(
    private dpgfPostesService: DpgfPostesService,
  ) { }

  ngOnInit(): void {
    this.onModalClose();
  }

  ngOnChanges(changes: SimpleChanges){
      if(changes.dpgfPricing){
        if(changes.dpgfPricing.previousValue){
          this.filterDpgf();
        };
      }
  }

  public filterDpgf(){
    let dpgfLot =  this.dpgfPricing.data.map(lot=>{
      return {
        lotId: lot.id,
        id:lot.title,
        name:lot.title,
         requested:0,
         icon : (lot.icon||""),
         total:(lot.total||0) }
    }).filter(lot => lot.name != "")
    this.dpgfLot =  dpgfLot;
  }

  public chooseLot(lot){
    this.selectedLot[lot.lotId] = !this.selectedLot[lot.lotId];
  }
  public checkAll(){
    for(let lot of this.dpgfLot){
       if(lot['lotId']){
        this.selectedLot[lot['lotId']] = true;
      }
    }
  }
  public uncheckAll(){
    for(let lot of this.dpgfLot){
      this.selectedLot[lot['lotId']] = false;
    }
  }
  public toggleShowEntrepriseComments(){
    this.showEntrepriseComments = !this.showEntrepriseComments;
  }
  public filterSelectedLots(obj){
    let filterd = [];
    if(Object.keys(obj).length > 0){
      for(let key of Object.keys(obj)){
        if(key != 'undefined'){
          if(key && obj[key]){
            filterd.push(key);
          }
        }
      }
    }
    return filterd;
  }
  public downloadDocument(){
    switch(this.documentType){
      case 'devis':
        this.downloadEstimatifPdf();
        break;
      case 'cctp':
        this.downloadCctp();
        break;
      default:
        this.downloadEstimatifPdf();
    }
  }
  public userInfo(){
    let data = {};
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(currentUser && (currentUser.archiId != '' || currentUser.artisanId != '')){
      currentUser.archiId != '' ?
      data = {'type' : 'architect', 'architectId' : currentUser.archiId}
      :
      data = {'type' : 'artisan', 'artisanId' : currentUser.artisanId}
    }
    return data;
  }
  /// Estimatif
  public downloadEstimatifPdf(){
    let lotsChoosen =this.filterSelectedLots(this.selectedLot);
    let devisType = this.showEntrepriseComments ? 'devisComm' : '';
    let sender = this.userInfo();
    this.downloadingDoc = true;
    this.subscriptions.push(
        this.dpgfPostesService.downloadDevisPdf(this.dpgfPricing._id, lotsChoosen, devisType, sender, this.dpgfPricing.code)
        .subscribe(
          data => {
            saveAs(data.data.data, `${data.data.pdfName}.pdf`);
            if(!environment.onPremise)window.analytics.track('download_devis',{properties:{acValue: 'download_devis'}});
            // if(!environment.onPremise)window.analytics.track('download_recap_estimation',{properties:{acValue: 'download_recap_estimation',model:this.dpgf.docData}});
            this.downloadingDoc = false;
          },
          err => {
            this.downloadingDoc = false;
            // Show Pdf Error Modal
            let documentsErrorInfo = ( err?.error?.err?.error || {} );
            this.openPdfErrorsModal.emit(documentsErrorInfo);
          }
        )
    );
  }
  /// CCTP
  public downloadCctp(){
    let lotsChoosen =this.filterSelectedLots(this.selectedLot);
    this.downloadingDoc = true;
    this.subscriptions.push(
        this.dpgfPostesService.downloadCCTPPricingdf(this.dpgfPricing._id, lotsChoosen, this.dpgfPricing.code,true)
        .subscribe(
          data => {
            saveAs(data.data.data, `${data.data.pdfName}.pdf`);
            this.downloadingDoc = false;
          },
          err => {
            this.downloadingDoc = false;
          }
        )
    );
  }


  public closePopup(){
    this.closeModal.emit();
  }
  public onModalClose(){
    let _SELF = this;
    $("#print-dpgf-devis").on("hidden.bs.modal", function () {
      _SELF.uncheckAll();
      _SELF.showEntrepriseComments = false;
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
