<div class="all">
    <app-header type="full"></app-header>
    <div *ngIf="loading">
        <app-spinner></app-spinner>
    </div>
</div>    
<div class="modal fade" id="activation-account-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered wid433" role="document">
      <div class="modal-content height-320" *ngIf="!accountValidated && errorValidation!='Token invalide ou expiré' && !loading">
        <div class="modal-body height-100percent padding-right-left-10 padding-bottom-0">
            <div class="text-center padding-top-25" [ngStyle]="{'padding-top':emailSended?'9px':'25px'}">
                <img class="padding-bottom-16" src="assets/mail-icone.svg">
                <p class="margin-bottom-0 padding-bottom-16 font-size-18 font-weight-700">Confirmez votre adresse email</p>
            </div>
            <div class="padding-right-left-10" [ngStyle]="{'padding-bottom':emailSended?'16px':'47px'}" >
                <div>
                    <p *ngIf="!emailSended" class="margin-bottom-0 font-size-16">Activez votre compte et débutez sur AGLO en cliquant sur le lien contenu dans l’email envoyé à votre adresse :</p>
                    <p *ngIf="emailSended" class="margin-bottom-0 font-size-16 text-center">Nous avons renvoyé un email à votre adresse :</p>
                    <p class="margin-bottom-0 text-center font-weight-700 padding-top-12">{{currentUser.email}}</p>
                    <div *ngIf="emailSended" class="text-center padding-right-left-10 padding-top-7">
                        Un e-mail de vérification vous a été envoyé. Veuillez vérifier votre boîte de réception et suivre les instructions pour finaliser la validation de votre compte.
                    </div>
                </div>
            </div>
            <div class="font-size-12 padding-left-11">
                <p  class="margin-bottom-0 line-height-16">Vous n’avez pas reçu d’email ?</p>
                <p class="cursor margin-bottom-0 line-height-16 font-weight-700 blue-color" (click)="sendEmail()">Renvoyer l’email de confirmation</p>
            </div>
        </div>
      
       
      </div>
      <div class="modal-content height-320" *ngIf="accountValidated" >
        <div class="modal-body flex justify-center align-items height-100percent" >
                <div class="text-center">
                <img src="assets/checked-icone.svg">
                <div class="font-size-18">
                    <p class="margin-bottom-0 font-weight-700 padding-bottom-16">Compte vérifié</p>
                    <p class="margin-bottom-0">Votre compte a bien été activé, vous allez être redirigé vers l’accueil de votre compte.</p>
                </div>
            </div>
        </div>
      </div>
      <div class="modal-content height-320" *ngIf="errorValidation=='Token invalide ou expiré'">
         <div class="modal-body padding-right-left-10 height-100percent padding-bottom-0">
            <div class="text-center padding-top-47" >
                <img class="padding-bottom-16" src="assets/link-expired-icone.svg">
                <p class="margin-bottom-0 padding-bottom-16 font-weight-700 font-size-18">Lien expiré</p>
            </div>
            <div class="div-padding">
                <p class="margin-bottom-0">Le lien que vous avez utilisé pour vérifier votre email est expiré. Assurez-vous d’utiliser le lien présent dans <span class="font-weight-700"> le dernier email </span>que vous avez reçu de notre part.</p>
            </div>
            <div class="font-size-12 padding-left-11">
                <p  class="margin-bottom-0 line-height-16">Vous n’avez pas reçu d’email ?</p>
                <p class="cursor margin-bottom-0 blue-color line-height-16 font-weight-700" (click)="sendEmail()">Renvoyer l’email de confirmation</p>
            </div>
         </div>
        </div>
    </div>
  </div>