import { Component, EventEmitter, OnInit, Output, NO_ERRORS_SCHEMA } from '@angular/core';
const _ = require("lodash")

/// Services
import { AuthService } from '../../_services';
import { PaymentsService } from '../../_services/index';
import { MessageService } from 'primeng/api';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
declare var Stripe: any;

@Component({
    standalone:true,
    selector: 'app-architect-bills',
    imports:[NgSharedModule],
    providers: [],
    templateUrl: './architect-bills.component.html',
    styleUrls: ['./architect-bills.component.scss'],
})
export class ArchitectBillsComponent implements OnInit {
  @Output() tvaChanged = new EventEmitter<{}>();
  public subscription: any;
  public invoices: any = [];
  public loading: boolean = true;
  public loadinglicence: boolean = false;
  public hasSubscription:boolean = false;
  public defaultTaxeRate=20;taxes;tax_rate_choosen;userId;
  public firstValueEnvirenementObservable = new Observable<boolean>(observer => {
    observer.next(environment.onPremise);
    observer.complete();
  });
  //changeBaka
  public stripe;
  constructor(
    public authService: AuthService,
    public paymentsService: PaymentsService,
    private messageService: MessageService,
  ) {
    if(!environment.onPremise)this.stripe = Stripe(environment.stripeKey);
  }

  ngOnInit() {
    this.getTaxesRates();
    this.loadCustomerInvoices();
    this.getUserData();
  }

  loadCustomerInvoices(){
    this.paymentsService.getcustomerInvoices().subscribe(data => {
      this.loading = false;
      this.invoices = data.data.data;
      // console.log('this.invoices',this.invoices)
      this.invoices=this.invoices.filter(invoice=>_.get(invoice,'lines.data[0].plan.name','')!='Veille')
      // this.invoices=this.invoices.filter(invoice=> invoice.status!='open')
      this.invoices.forEach((element,i) => {
        // this.invoices[i].period_start=this.toShortFormat(this.epochToDate(this.invoices[i].period_start));
        // this.invoices[i].period_end=this.toShortFormat(this.epochToDate(this.invoices[i].period_end));
        this.invoices[i].period_start=this.toShortFormat(this.epochToDate(this.invoices[i].lines.data[0].period.start));
        this.invoices[i].period_end=this.toShortFormat(this.epochToDate(this.invoices[i].lines.data[0].period.end));
      });
    });
  }
  getUserData(){
    this.authService.getUserLicenceStripeUser()
    .subscribe((res)=>{
      this.hasSubscription =( res.data.stripeCustomerID||"") != "";
      this.userId = res.data._id;
      this.tax_rate_choosen = res.data.defaulTVA?.percentage >=0? res.data?.defaulTVA?.percentage : 20;
    })
  }
  resetCard(){
    this.loadinglicence = true;
    this.paymentsService.resetPayement({})
    .subscribe((data) => {
      this.stripe.redirectToCheckout({
        sessionId: data.data.id
      }).then(function (result) {

      });
      // Refresh page !
    },
    err =>{
      this.loadinglicence = false;
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: err.error.err.message, life: 5000 });
    })
  }
  getTaxesRates(){
    this.paymentsService.getTaxesRate().subscribe((data)=>{
      this.taxes = data.data.sort((a,b)=>a.percentage-b.percentage);
    })
  }
  changeValueTaxe(event){
    let choosenTax = this.taxes.find((tax)=>this.tax_rate_choosen==tax.percentage)
    let obj = {
      id:choosenTax.id,
      percentage:choosenTax.percentage
    }
    this.authService.updateDefaultTva(this.userId,obj).subscribe((data)=>{
      this.messageService.add({ key: 'toast', severity: 'success', summary: 'TVA modifiée', detail: 'Votre taux de TVA a été modifé avec succès et sera utilisé pour vos prochaines factures', life: 5000 });
      this.tvaChanged.emit(obj)
    })
  }
  epochToDate(epoch){
    var date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(epoch);

    return date;
  }
  toShortFormat = (date) =>{

    let monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
  
    let day = date.getDate();
    
    let monthIndex = date.getMonth();
    let monthName = monthNames[monthIndex];
    
    let year = date.getFullYear();
    
    return `${day} ${monthName} ${year}`;  
  }
}
