import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";
import { Helpers } from './helpers'

@Injectable()
export class CeProjectInvitesService {
    public token: string;

    constructor(private http: HttpClient) {
    }
    getCeInvite(id) : Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/ce-project-invites/${id}`, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    adoptCeInvite(id) : Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/ce-project-invites/${id}/adopt`,{}, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    setCeInvite(id,data) : Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.patch(`/api/v1/ce-project-invites/${id}`,data, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    getCeInviteListOfCeProject(projectId) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`api/v1/ce-project/${projectId}/ce-project-invites`, options)
            .pipe(map((response: any) => {
                return response.data; 
            }));
    }
    getArtisanInvite() : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get('/api/v1/ce-project-invites/artisan/getall', options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    accepteArtisanInvite(ceProjectInvitesId, body) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/ce-project-invites/${ceProjectInvitesId}/accepte`, body, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    refusArtisanInvite(ceProjectInvitesId, body) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/ce-project-invites/${ceProjectInvitesId}/refuse`, body, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    acceptRdvArtisanInvite(ceProjectInvitesId, body) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/ce-project-invites/${ceProjectInvitesId}/artisan/validate-rdv`, body, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    cancelRdvArtisanInvite(ceProjectInvitesId, body) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/ce-project-invites/${ceProjectInvitesId}/artisan/cancel-rdv`, body, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    createNewArtisanByArchi (data, projectId) {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/ce-projects/${projectId}/invite-artisan/architect`, body, options)
            .pipe(map((response: any) => {
              return response.data;
            }));
    }
    createRelanceNow(ceProjectInvitesId, body): Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/ce-project-invites/${ceProjectInvitesId}/architect/create-relance`, body, options)
          .pipe(map((response: any) => {
            return response.data;
          }));
    }
    scheduleRelance(ceProjectInvitesId, body): Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.patch(`/api/v1/ce-project-invites/${ceProjectInvitesId}/architect/schedule-relance`, body, options)
          .pipe(map((response: any) => {
            return response.data;
          }));
    }
    getProjectAcceptedInvites(ceProjectId) : Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/ce-project/${ceProjectId}/ce-project-accepted-invites`, options)
        .pipe(map((response: any) => {
          return response.data;
        }));
    }
    retirerCeProjectInvite(ceProjectInviteId) : Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/ce-project-invites/${ceProjectInviteId}/status-to-delete`, {}, options)
        .pipe(map((response: any) => {
          return response.data;
        }));
    }
    loadArchiLogs(ceProjectInviteId) : Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/loggs/${ceProjectInviteId}`, options)
        .pipe(map((response: any) => {
          return response.data;
        }));
    }
    architectCreateComment(ceProjectInviteId, body){
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/loggs/${ceProjectInviteId}`, body, options)
        .pipe(map((response: any) => {
          return response.data;
        }));
    }
    loadArchiEmails(ceProjectInviteId) : Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/loggs/${ceProjectInviteId}/emails`, options)
        .pipe(map((response: any) => {
          return response.data;
        }));
    }
    architectCreateMail(ceProjectInviteId, body){
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/loggs/${ceProjectInviteId}/emails`, body, options)
        .pipe(map((response: any) => {
          return response.data;
        }));
    }

    unsignWithArtisan(ceProjectId,ceProjectInviteId,data) : Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/ce-project/${ceProjectId}/ce-project-invites/${ceProjectInviteId}/unsign-lots`,data, options)
        .pipe(map((response: any) => {
          return response.data;
        }));
    }
}
