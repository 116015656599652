import { Component, OnInit, Input, Output,EventEmitter, NgZone} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Promise}  from 'bluebird';
// import * as AWS from 'aws-sdk';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { NgSharedModule } from '../ngshared.module';
@Component({
  standalone:true,
  selector: 'app-upload-file',
  imports:[NgSharedModule],
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  public loadingFiles:boolean = false;
  @Input()allFiles:any = [];
  @Input()placeholder:string;
  @Input()disabled:boolean;
  @Output()files = new EventEmitter<any>();

  constructor(public _ngZone: NgZone) { }

  ngOnInit() {
  }
  public fileEvent(fileInput: any) {
    const AWSService = AWS;
    const region = 'eu-central-1';
    const bucketName = 'bam-consultation-slickshot';
    const IdentityPoolId = 'eu-central-1:7a0af26b-a2d9-4bd5-aa01-dff3f719b7db';
    let files = fileInput.files ||[];
    this.loadingFiles = true;
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
      })
    });
    const s3 = new S3({
      apiVersion: '2006-03-01',
      params: { Bucket: bucketName}
    });
    const ID = function () {
      return Math.random().toString(36).substr(2, 9);
    };
    let filesUpload = files.map(file=>s3.upload({ Key: ID()+"-"+file.name , Bucket: bucketName, Body: file, ACL: 'public-read'}).promise());
    Promise.all(filesUpload).then((filesUploaded)=> {
      this.loadingFiles = false;
      this.allFiles = [...(this.allFiles||[]), ...filesUploaded];
      this.files.emit(this.allFiles)
    }).catch(err=>{
      console.log(err);
      this.loadingFiles = false;
    })
  }
  public removeFile(i){
    this.allFiles.splice(i, 1);
    this.files.emit(this.allFiles)
  }
}
