<div class="all">
  <!-- <app-header type="light"></app-header> -->
  <app-header type="full"></app-header>
  <app-archi-expired-modal></app-archi-expired-modal>
  <div class="main-title">
    Carnet d’adresses
  </div>
  <div class="container mt-2">
    <app-spinner *ngIf="loading"></app-spinner>
    <!-- open import csv relations -->
    <div class="row" *ngIf="!loading">
      <div class="block import-csv-btn-cont">
        <button type="button" class="btn btn-info btn-lg import-csv-btn" data-toggle="modal" (click)="openImportCsv()">Importer depuis csv</button>
      </div>
    </div>

    <div class="row " *ngIf="!loading">
      <div class="col-md-4 floating-card artisans-list-card pb-0">
        <div class="artisans-search-area">
          <input type="text" pInputText placeholder="Rechercher" [(ngModel)]="artisanSearchField" (keyup)="searchEnterprises()" />
          <div class="p-fluid">            
            <p-multiSelect
            id="carnet"
            class="width-100"
            placeholder="Filtrer par corps d'état"
            [panelStyle]="{minWidth:'100%'}"
            [options]="allTags"
            [(ngModel)]="selectedSearchTags"
            optionLabel="name"
            (onChange)="tagSelected()"
            ></p-multiSelect>
          </div>
        </div>
        <hr>
        <div class="horizontal-artisans-list">
          <div *ngFor="let relation of relationsList" class="row single-carnet">
            <div class="col-md-4" (click)="openArtisanInfo(relation)">
              <img class="img-container" [src]="relation.artisanOwned?.logo != undefined ? relation.artisanOwned.logo.cdnUrl : 'assets/rob/helmet.png'">
            </div>
            <div class="text-container col-md-8">
              <h4 class="user-name" (click)="openArtisanInfo(relation)">{{relation.artisanOwned.name}}</h4>
              <p class="phone-num">{{(relation.artisanOwned.telAgency||'_')  }}</p>

                <i mwlConfirmationPopover
                  class="fa fa-trash"
                  popoverTitle="Supprimer"
                  popoverMessage="Êtes vous sur de vouloir supprimer cette relation ?"
                  cancelText="X"
                  confirmText="✓"
                  placement="left"
                  (confirm)="removeArtisan(relation._id)"
                  (cancel)="true"></i>
                <!-- Modal -->
            </div>
          </div>
        </div>

        <div class="add-btn-container">
          <span *ngIf="showLeftArrow" (click)="prevPage()" class="fa fa-angle-double-left left-arrow"></span>
          <!-- <button class="add-btn" data-toggle="modal" data-target="#ajouter-artisan-popup" type="button" (click)="openAddArtisanPopup()"> -->
          <button class="add-btn" (click)="openAddArtisanPopup()">
            <i class="fa fa-plus"></i>Ajouter une entreprise
          </button>
          <span *ngIf="showRightArrow" (click)="nextPage()" class="fa fa-angle-double-right right-arrow"></span>
        </div>
      </div>
      <div class="col-md-1"></div>



      <div class="col-md-7 no-artisan-selected" *ngIf="!(selectedRelation.artisanOwned||{}).name">
        <img src="assets/archi/undraw_filter.svg" alt="">
      </div>

      <div class="col-md-7 floating-card selected-artisan-card" *ngIf="(selectedRelation.artisanOwned||{}).name">
        <div class="selected-artisan-info">
          <div class="img-container">
            <img [src]="selectedRelation.artisanOwned.logo != undefined ? selectedRelation.artisanOwned.logo.cdnUrl : 'assets/rob/helmet.png'">
          </div>
          <h2 class="selected-artisan-name">{{ selectedRelation.artisanOwned.name }}</h2>
          <div style="text-align:right">
            <a *ngIf="!this.selectedArtisan.notReal" target="_blank" [href]="'/artisan-profil/'+selectedRelation.artisanOwned._id">Voir le profil</a>
          </div>
          <div class="artisan-details">
            <ul>
              <li class="single-detail-row">
                <div *ngIf="!showEditTags || !selectedArtisan.notReal">
                  <span class="fa fa-user-o"></span>
                  <span class="info-field">
                    {{ selectedArtisan.name }}
                    <!-- <span *ngIf="selectedArtisan.nomPrenom">/ {{ selectedArtisan.lastName }}{{ selectedArtisan.firstName }}</span> -->
                  </span>
                </div>
                <div *ngIf="showEditTags && selectedArtisan.notReal">
                  <span class="fa fa-user-o"></span>
                  <input [(ngModel)]="selectedArtisan.name"  type="text" name="name" class="form-control form-add" placeholder="Nom de l'entreprise">
                </div>
              </li>

              <!-- First Name -->
              <li class="single-detail-row">
                <div *ngIf="(!showEditTags || !selectedArtisan.notReal) && selectedArtisan?.firstName">
                  <span class="fa fa-user-o"></span>
                  <span class="info-field">
                    {{ selectedArtisan.firstName  }}
                  </span>
                </div>
                <div *ngIf="showEditTags && selectedArtisan.notReal">
                  <span class="fa fa-user-o"></span>
                  <input [(ngModel)]="selectedArtisan.firstName"  type="text" class="form-control form-add" placeholder="prénom">
                </div>
              </li>

              <li class="single-detail-row">
                <div *ngIf="(!showEditTags || !selectedArtisan.notReal) && selectedArtisan?.firstName">
                  <span class="fa fa-user-o"></span>
                  <span class="info-field">
                    {{ selectedArtisan.lastName  }}
                  </span>
                </div>
                <div *ngIf="showEditTags && selectedArtisan.notReal">
                  <span class="fa fa-user-o"></span>
                  <input [(ngModel)]="selectedArtisan.lastName"  type="text" class="form-control form-add" placeholder="nom">
                </div>
              </li>

              
              <li class="single-detail-row">
                <div *ngIf="!showEditTags || !selectedArtisan.notReal">
                  <span class="fa fa-phone"></span>
                  <span span class="info-field">
                    {{ selectedArtisan.telAgency }}
                  </span>
                </div>
                <div *ngIf="showEditTags && selectedArtisan.notReal">
                  <span class="fa fa-phone"></span>
                  <input [(ngModel)]="selectedArtisan.telAgency"  type="text" name="name" class="form-control form-add" placeholder="Numéro de téléphone">
                </div>
              </li>

              <li class="single-detail-row">
                <div *ngIf="!showEditTags || !selectedArtisan.notReal">
                  <span class="fa fa-envelope-o"></span>
                  <span span class="info-field">
                    {{ selectedArtisan.emailAgency }}
                  </span>
                </div>
                <div *ngIf="showEditTags && selectedArtisan.notReal">
                  <span class="fa fa-envelope-o"></span>
                  <input [(ngModel)]="selectedArtisan.emailAgency"  type="text" name="name" class="form-control form-add" placeholder="Mail de l'agence">
                </div>
              </li>

              <li class="single-detail-row">
                  <span *ngIf="!showEditTags || !selectedArtisan.notReal">
                    <span class="fa fa-map-marker"></span><span span class="info-field">{{ selectedRelation.artisanOwned.address || 'Non renseigné' }}</span>
                  </span>

                  <!-- <div *ngIf="showEditTags && selectedArtisan.notReal"> -->
                  <div [ngClass]="(showEditTags && selectedArtisan.notReal) ? 'flex' : 'hidden-input'">
                    <span class="fa fa-map-marker margin-right-10"></span>
                    <div class="p-fluid full-width">
                      <app-google-autocomplete [adresseValue]="selectedRelation.artisanOwned.address" (adresseValueChange)="getGoogleMapValueChange($event)" ></app-google-autocomplete>
                    </div>
                  </div>
              </li>

              <!-- Tags -->
              <li class="single-detail-row">
                <p>
                  <span *ngIf="!showEditTags">
                    <span class="fa fa-tags margin-right"></span>
                    <span class="lot-tag" *ngFor="let tag of selectedRelation.tags">
                      {{ tag.name }}
                    </span>
                  </span>

                  <span class="edit-tags-input flex" *ngIf="showEditTags">
                    <span class="fa fa-tags multi-icon"></span>
                    <div class="p-fluid full-width">
                      <p-multiSelect
                      class="form-add"
                      id="carnet"
                      [placeholder]="'choisir'"
                      [selectedItemsLabel]="'Plusieurs éléments sélectionnés'"
                      [panelStyle]="{minWidth:'100%'}"
                      [options]="allTags"
                      [(ngModel)]="selectedTags"
                      optionLabel="name"
                      (onChange)="tagAdded($event)"
                      ></p-multiSelect>
                    </div>
                  </span>

                </p>
              </li>

            </ul>
            <div class="row" *ngIf="showEditTags">
              <app-spinner *ngIf="creatingTags" style="display: inline-block;  position:relative; top:14px;"></app-spinner>
              <button type="button" name="button" class="btn cancel-btn" (click)="toggleEditTags()" *ngIf="!creatingTags">
                Annuler
              </button>
              <button *ngIf="!creatingTags" class="btn save-btn" (click)="updateArtisan()" >
                Enregistrer
              </button>
            </div>
            <div class="edit-artisan-btn-container">
              <p (click)="toggleEditTags()" *ngIf="!showEditTags"><span class="fa fa-pencil"></span> Editer </p>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>


  <!-- Create Artisan -->
  <div id="ajouter-artisan-popup"
    class="modal"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="callmodal-container">

        <form [formGroup]="artisanForm" class="new-artisan-form" (ngSubmit)="createNewArtisan()">

          <div class="form-group">
            <label class="required-star" for="">Email</label>
            <input type="email" name="email" class="form-control" placeholder="example@domain.com" formControlName="emailAgency">
            <span class="text-danger" *ngIf="artisanForm.get('emailAgency').touched && !artisanForm.get('emailAgency').valid">Email obligatoire</span>
          </div>

          <div class="form-group">
            <label class="required-star" for="">Nom de l’entreprise</label>
            <input type="text" name="name" class="form-control" placeholder="Nom de l’entreprise" formControlName="name">
            <span class="text-danger" *ngIf="artisanForm.get('name').touched && !artisanForm.get('name').valid">Nom de l’entreprise obligatoire</span>
          </div>
          <div class="form-group">
            <label class="required-star" for="">Nom</label>
            <input type="text" name="name" class="form-control" placeholder="Nom" formControlName="lastName">
            <span class="text-danger" *ngIf="artisanForm.get('lastName').touched && !artisanForm.get('lastName').valid">Nom obligatoire</span>
          </div> <div class="form-group">
            <label class="required-star" for="">Prénom</label>
            <input type="text" name="name" class="form-control" placeholder="Prénom" formControlName="firstName">
            <span class="text-danger" *ngIf="artisanForm.get('firstName').touched && !artisanForm.get('firstName').valid">Prénom obligatoire</span>
          </div>
          <div class="form-group">
            <label  for="">Filtres</label>
            <div class="p-fluid">
              <p-autoComplete placeholder="Filtres"
              styleClass="wid100"
              formControlName="tags"
              [suggestions]="tags"
              (completeMethod)="searchTags($event)"
              field="name"
              [dropdown]="true"
              [multiple]="true"></p-autoComplete>
            </div>
            <span class="text-danger" *ngIf="artisanForm.get('name').touched && !artisanForm.get('name').valid">Nom obligatoire</span>
          </div>

          <div class="form-group">
            <label for="">Téléphone</label>
            <input type="string" name="telAgency" class="form-control" placeholder="06 66 55 44 33" formControlName="telAgency">
            <span class="text-danger" *ngIf="artisanForm.get('telAgency').touched && !artisanForm.get('telAgency').valid">Téléphone obligatoire</span>
          </div>

          <div class="form-group">
            <app-spinner *ngIf="creatingTags"></app-spinner>
            <button *ngIf="!creatingTags" [disabled]="!artisanForm.valid" class="btn">Ajouter</button>
          </div>

        </form>

      </div>
    </div>
  </div>


  <!-- Import carnet d'adress from csv -->
  <div id="importCarnetAdress" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Importer les données des entreprises</h4>
        </div>
        <app-archi-upload-carnet-adresse (archiAdded)="searchEnterprises()"></app-archi-upload-carnet-adresse>
      </div>

    </div>
  </div>
  <!-- Need Subscriiption -->
  <app-need-subscription-popup-2></app-need-subscription-popup-2>



</div>
