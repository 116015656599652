<section class="filter-wrapper">
  <div class="keyword-wrapper">
    <span *ngIf="!showInput"
          class="edit-span"
          (click)="editable()"
          [ngClass]="{'selected':selected}"
          [ngStyle]="style"
          [attr.id]="id">
            <span *ngIf="typeInput == 'number' && zeroDefault != ''" [ngClass]="{colored : color}">
              <span *ngIf="unit===0" pTooltip="Non renseigné" tooltipPosition="top">{{zeroDefault}}</span>
              <span *ngIf="unit!==0">{{unit | formatPrice}}</span>
            </span>
            <span *ngIf="typeInput == 'number' && zeroDefault == ''" [ngClass]="{colored : color}">
              {{unit | formatPrice}}
            </span>
            <span *ngIf="typeInput != 'number'">{{unit===0?'-':unit}}</span>

            <span class="place-holder" *ngIf="unit === ''">{{placeHolder}}</span>
            <span *ngIf="unit">{{sign}}</span>
            <i *ngIf="disable && !hideLock" class="fa fa-lock tooltip-el" aria-hidden="true">
              <span class="tooltiptext">{{tooltip}}</span>
            </i>
    </span>

    <input id="unit-input"
           class="input-field"
           *ngIf="showInput"
           [ngStyle]="style"
           [type]="typeInput"
           [placeholder]="placeHolder"
           [ngModel]="unit"
           (focus)="focusedOn()"
           (focusout)="focusedOutOn()"
           (keydown)="onKeydownOnInput($event)"
           (ngModelChange)="manageType($event)"
           [ngClass]="{'selected':selected}">

    <div id="myInputautocomplete-list" class="autocomplete-items">
     <div *ngFor="let result of results;let i = index" (mousedown)="choose(result)" (keydown.enter)="choose(result)" class="unit-proposition" [ngClass]="{'autocomplete-active':(currentFocus == i)}">
         {{result}}
     </div>
    </div>

  </div>
</section>
