import { Pipe, PipeTransform } from '@angular/core';
const _ = require('lodash');

@Pipe({
  standalone:true,
  name: 'getStatePath'
})
export class GetStatePath implements PipeTransform {
  transform(state, path=undefined, defaultValue=undefined) {
    return _.get(state, path, defaultValue);
  }
}
