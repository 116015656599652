import { Injectable, NO_ERRORS_SCHEMA } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { map, mergeMap, catchError,withLatestFrom, switchMap } from 'rxjs/operators';
import { DpgfPostesService, ErrorService} from 'src/app/_services';
import { Observable } from 'rxjs'
import 'rxjs/add/observable/of';
import * as dpgfActions  from './dpgf.actions';

const getId = (type) =>{
  return type + '_' + Math.random().toString(36).substr(2, 9);
}
 
@Injectable()
export class DpgfEffects {
  public savingLot = false;

  loadDpgf$ = createEffect(() => {
    return this.actions$.pipe(
    ofType(dpgfActions.loadDpgf),
    withLatestFrom(this.store$),
    mergeMap(([action,state]) => {
      if(state["dpgf"].type == "dpgf-minor-v"){
        return this.dpgfService.getDpgfMinorVersion(action.payload.dpgfId)
        .pipe(
          mergeMap(response => {
            if(state["dpgf"].mode == "archi"){
            return of(
              { type: '[DPGF] set Dpgf', payload: response["dpgf"] },
            )}
            else return of({ type: '[DPGF] set Dpgf', payload: response["dpgf"] })
            
          }),
          catchError((error) => (Observable.of( dpgfActions.errorloadDpgf(error) ) ))
        )
      }else{
        return this.dpgfService.getDpgf(action.payload.dpgfId)
        .pipe(
          mergeMap(response => {
            if(state["dpgf"].mode == "archi"){
            return of(
              { type: '[DPGF] set Dpgf', payload: response },
              dpgfActions.getSizeDPGF({})
            )
          }else{
            return of({ type: '[DPGF] set Dpgf', payload: response },)
          }
          }),
          catchError((error) => (Observable.of( dpgfActions.errorloadDpgf(error) ) ))
        )
      }
      })
    )}
  );
  loadDpgfPricing$ = createEffect(() => {
    return this.actions$.pipe(
    ofType(dpgfActions.loadDpgfPricing),
    mergeMap((action:any) => {
      return this.dpgfService.getDpgfPricing(action.payload.dpgfId, action.payload.code)
      .pipe(
        map(dpgf => ({ type: '[DPGF] set Dpgf Pricing', payload: dpgf })),
        catchError((error) => (Observable.of( dpgfActions.errorloadDpgfPricing(error) ) ))
      )})
    )}
  );
  finishDpgfPricing$ = createEffect(() => {
    return this.actions$.pipe(
    ofType(dpgfActions.finishDpgfPricing),
    withLatestFrom(this.store$),
    mergeMap(([action,state]) => {
      return this.dpgfService.patchDpgfPricingFinished(state["dpgf"].dpgf._id,state["dpgf"].code,action.payload)
      .pipe(
        map(dpgf => ({ type: '[DPGF] finish Dpgf pricing done' })),
        catchError((error) => (Observable.of( dpgfActions.errorSaveDpgfPricing(error) ) ))
      )})
    )}
  );
  saveDpgf$ = createEffect(() => {
    return this.actions$.pipe(
    ofType(dpgfActions.saveDpgfToServer),
    withLatestFrom(this.store$),
    mergeMap(([action,state]) => {
      let query = ""
      if(action.payload.goNext == "yes"){
        query = "?gonext=yes"
      }
      if(action.payload.updateLotsData == "yes"){
        query = "?updateLotsData=yes"
      }
      if(state["dpgf"].type == "pricing"){
        return this.dpgfService.patchDpgfPricing(state["dpgf"].dpgf._id,state["dpgf"].code,state["dpgf"].dpgf,query)
        .pipe(
          mergeMap(() => {
            if(state["dpgf"].mode == "archi")
              return [
            dpgfActions.setSaveDone({ done: true }),
            dpgfActions.getSizeDPGF({})
            ];
            else{
              return [dpgfActions.setSaveDone({ done: true })]
            }  
        }),          
          catchError((error) => (Observable.of( dpgfActions.errorSaveDpgfPricing(error) ) ))
          )
      }else{
        return this.dpgfService.patchDpgf(state["dpgf"].dpgf._id,state["dpgf"].dpgf,query)
        .pipe(
          mergeMap(() => {
            if(state["dpgf"].mode == "archi")
              return [
            dpgfActions.setSaveDone({ done: true }),
            dpgfActions.getSizeDPGF({})
            ];
            else{
              return [dpgfActions.setSaveDone({ done: true })]
            }  
        }),          
          catchError((error) => (Observable.of( dpgfActions.errorSaveDpgf(error) ) ))
          )
      }
      })
    )}
  );
  //Lot Management
  loadDpgfLot$ = createEffect(() => {
    return this.actions$.pipe(
    ofType(dpgfActions.loadDpgfLot),
    withLatestFrom(this.store$),
    mergeMap(([action,state]) => {
      if(state["dpgf"].type == "pricing"){
        return this.dpgfService.getDpgfPricingLot(action.payload.dpgfId,action.payload.lotId,state["dpgf"].code)
        .pipe(
          map(dpgf => ( dpgfActions.setDpgfLot(dpgf) )),
          catchError((error) => (Observable.of( dpgfActions.errorSaveDpgfPricing(error) ) ))
        )
      }else if(state["dpgf"].type == "dpgf-minor-v"){
        return this.dpgfService.getDpgfMinorVersionLot(action.payload.dpgfId,action.payload.lotId)
        .pipe(
          map(dpgf => ( dpgfActions.setDpgfLot(dpgf) )),
          catchError((error) => (Observable.of( dpgfActions.errorSaveDpgf(error) ) ))
        )
      }else{
        return this.dpgfService.getDpgfLot(action.payload.dpgfId,action.payload.lotId)
        .pipe(
          map((dpgf:any) => ( dpgfActions.setDpgfLot(dpgf) ) ),
          catchError((error) => (Observable.of( dpgfActions.errorSaveDpgf(error) ) ))
        )
      }
    }))}
  );
  loadSameDpgfLot$ = createEffect(() => {
    return this.actions$.pipe(
    ofType(dpgfActions.loadSameDpgfLot),
    withLatestFrom(this.store$),
    mergeMap(([action,state]) => {
       if((state["dpgf"].lot||{}).id){
         return this.dpgfService.getDpgfLot(state["dpgf"].dpgf._id,state["dpgf"].lot.id)
         .pipe(
           map(dpgf => ( dpgfActions.setDpgfLot(dpgf) )),
           catchError((error) => (Observable.of( dpgfActions.errorSaveDpgf(error) ) ))
         )
       }else{
         return EMPTY;
       }
    }))}
  );
  addLot$ = createEffect(() => {
    return this.actions$.pipe(
    ofType(dpgfActions.addnewLot),
    withLatestFrom(this.store$),
    mergeMap(([action,state]) => {
      return this.dpgfService.createDpgfLot(state["dpgf"].dpgf._id,{defaultTva : state["dpgf"].dpgf.defaultTva,indexLot:action.payload.indexLot})
      .pipe(
        map(dpgf => ( dpgfActions.addnewLotDone(dpgf) )),
        catchError((error) => (Observable.of( dpgfActions.errorSaveDpgf(error) ) ))
      )})
    )}
  );
  getSize$ = createEffect(() => {
    return this.actions$.pipe(
    ofType(dpgfActions.getSizeDPGF),
    withLatestFrom(this.store$),
    mergeMap(([action,state]) => {
      return this.dpgfService.getSizeProject(state["dpgf"].dpgf._id)
      .pipe(
        map((resp) => dpgfActions.setSizeDPGF({ size: resp.size_MB })),         
        catchError((error) => (Observable.of( dpgfActions.errorSaveDpgf(error) ) ))
        )
    }))
  }
  );
  // addManyLots$ = createEffect(() => {
  //   return this.actions$.pipe(
  //   ofType(dpgfActions.addLot),
  //   withLatestFrom(this.store$),
  //   mergeMap(([action,state]) => {
  //     return this.dpgfService.addManyDpgfLot(state["dpgf"].dpgf._id,action.payload)
  //     .pipe(
  //       map(dpgf =>  dpgfActions.loadDpgf({dpgfId:state["dpgf"].dpgf._id})),
  //       tap(dpgf => this.store$.dispatch(dpgfActions.addDuplicatedLotDone(dpgf)) ),
  //       catchError((error) => (Observable.of( dpgfActions.errorSaveDpgf(error) ) ))
  //     )})
  //   )}
  // );

// Assuming this is inside a class where actions$, store$, and dpgfService are defined

addManyLots$ = createEffect(() => {
  return this.actions$.pipe(
    ofType(dpgfActions.addLot),
    withLatestFrom(this.store$),
    switchMap(([action, state]) => {
      const dpgfId = state["dpgf"].dpgf._id;
      // Call the dpgfService.addManyDpgfLot first
      // we add the indexLox on the back-end, we need the dpgf length to be sent on the body
      const lastLotIndexLot = state["dpgf"].dpgf.data[state["dpgf"].dpgf.data.length-1].indexLot;
      
      // action.payload["dpgfDataLength"] = state["dpgf"].dpgf.data.length;
      action.payload["lastLotIndexLot"] = lastLotIndexLot+1;
      

      return this.dpgfService.addManyDpgfLot(dpgfId, action.payload).pipe(
        switchMap((dpgf) => {
          // Dispatch the loadDpgf action after addManyDpgfLot is done
          this.store$.dispatch(dpgfActions.loadDpgf({ dpgfId }));
          // Wait for the '[DPGF] set Dpgf' action to finish
          return this.actions$.pipe(
            ofType('[DPGF] set Dpgf'),
            map((dpgf) => dpgfActions.addDuplicatedLotDone(dpgf)),
            catchError((error) => of(dpgfActions.errorSaveDpgf(error)))
          );
        }),
        catchError((error) => of(dpgfActions.errorSaveDpgf(error)))
      );
    })
  );
});

  updateDpgfLotOrder$ = createEffect(() => {
    return this.actions$.pipe(
    ofType(dpgfActions.changeOrderLot),
    withLatestFrom(this.store$),
    mergeMap(([action,state]) => {
      return this.dpgfService.updateDpgfLotOrder(state["dpgf"].dpgf._id,{lots : action.payload})
      .pipe(
        mergeMap(() => {
          if(state["dpgf"].mode == "archi")
            return [
          dpgfActions.setSaveDone({ done: true }),
          dpgfActions.getSizeDPGF({})
          ];
          else{
            return [dpgfActions.setSaveDone({ done: true })]
          }  
      }),  
        catchError((error) => (Observable.of( dpgfActions.errorSaveDpgf(error) ) ))
      )})
    )}
  );
  removeLot$ = createEffect(() => {
    return this.actions$.pipe(
    ofType(dpgfActions.removeLot),
    withLatestFrom(this.store$),
    mergeMap(([action,state]) => {
      return this.dpgfService.deleteDpgfLot(state["dpgf"].dpgf._id, action.payload.lotId)
      .pipe(
        mergeMap(dpgf => [ dpgfActions.removeLotDone(dpgf) ,
          dpgfActions.getSizeDPGF({})
          ]),
        catchError((error) => (Observable.of( dpgfActions.errorSaveDpgf(error) ) ))
      )})
    )}
  );

  saveLotFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dpgfActions.updateLotFiles),
      withLatestFrom(this.store$),
      mergeMap(([action,state]) => {
        let query = ""
        if(action.payload.goNext == "yes"){
          query = "?gonext=yes"
        }
        let lot = state["dpgf"].dpgf.data[action.payload.i];
        return this.dpgfService.setDpgfLotFiles(state["dpgf"].dpgf._id,lot.id,lot.lotFiles,query)
        .pipe(
          mergeMap(() => {
            if(state["dpgf"].mode == "archi")
              return [
            dpgfActions.setSaveDone({ done: true }),
            dpgfActions.getSizeDPGF({})
            ];
            else{
              return [dpgfActions.setSaveDone({ done: true })]
            }  
        }),
          catchError((error) => (Observable.of( dpgfActions.errorSaveDpgfPricing(error) ) ))
        )
      })
    )
  });

  saveLot$ = createEffect(() => {
    return this.actions$.pipe(
    ofType(dpgfActions.saveDpgfLotToServer),
    withLatestFrom(this.store$),
    mergeMap(([action,state]) => {
      let query = "?"
      if(action.payload.goNext == "yes")query += "gonext=yes&"
      if((state["dpgf"].lot||{}).lastSaveAt)query += "lastsave=" + state["dpgf"].lot.lastSaveAt
      if(!state["dpgf"].lot || !(state["dpgf"].lot||{}).id){
        return EMPTY
      }
      if(state["dpgf"].type == "pricing"){
        return this.dpgfService.setDpgfPricingLot(state["dpgf"].dpgf._id,state["dpgf"].lot.id,state["dpgf"].code,state["dpgf"].lot,query)
        .pipe(
          mergeMap(() =>{
            if(state["dpgf"].mode == "archi")
              return [
            dpgfActions.setSaveDone({ done: true }),
            dpgfActions.getSizeDPGF({})
            ];
            else{
              return [dpgfActions.setSaveDone({ done: true })]
            }  
        }),
          catchError((error) => (Observable.of( dpgfActions.errorSaveDpgfPricing(error) ) ))
        )
      }else{
        // console.log('state["dpgf"]',state["dpgf"])
        let lot = JSON.parse(JSON.stringify(state["dpgf"].lot));
        let nowDate = Date.now();
        lot.lastSaveAt = nowDate;
        // console.log('lot',lot)
        // console.log('lot.sousLots',lot.sousLots)
        // console.log('lot.sousLots.length',(lot.sousLots||{}).length)
        for(let i=0;i<(lot.sousLots||{}).length;i++){
          if(lot.sousLots[i].postes){
            for(let j=0;j<lot.sousLots[i].postes.length;j++){
              lot.sousLots[i].postes[j].selected=false;
            }
          }
        }
        if(this.savingLot){
          return EMPTY;
        }
        this.savingLot = true;
        return this.dpgfService.setDpgfLot(state["dpgf"].dpgf._id,state["dpgf"].lot.id,lot,query)
        .pipe(
          mergeMap(() => {
            if(state["dpgf"].mode == "archi")
              return [
            dpgfActions.setSaveDone({ done: true, nowDate }),
            dpgfActions.getSizeDPGF({})
            ];
            else{
              return [dpgfActions.setSaveDone({ done: true, nowDate })]
            }  
        }),
          catchError((error) => (Observable.of( dpgfActions.errorSaveDpgf(error) ) ))
        )
      }
      })
    )}
  );

  moveLotPosts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dpgfActions.movePostes),
      withLatestFrom(this.store$),
      mergeMap(([action,state]) => {
        let selectedPosts=[];
        let lot = JSON.parse(JSON.stringify(state["dpgf"].lot));
        for(let i=0;i<lot.sousLots.length;i++){
          for(let j=0;j<lot.sousLots[i].postes.length;j++){
            if(lot.sousLots[i].postes[j].selected==true){
              let newId = getId('poste');
              lot.sousLots[i].postes[j].selected=false;
              lot.sousLots[i].postes[j].id=newId;
              selectedPosts.push(lot.sousLots[i].postes[j]);
            }
          }
        }
        return this.dpgfService.movePostsDPGF(state["dpgf"].dpgf._id,action.payload.lotDestinationID,action.payload.sousLotDestinationID,selectedPosts)
        .pipe(         
          map(() => ( dpgfActions.removeManyPosts({posts:selectedPosts,isLoading:true}) )),
          catchError((error) => (Observable.of( dpgfActions.errorSaveDpgfPricing(error) ) ))
        )
      })
    )
  });
  moveLotSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dpgfActions.moveSection),
      withLatestFrom(this.store$),
      mergeMap(([action,state]) => {
        
        return this.dpgfService.moveSection(state["dpgf"].dpgf._id,action.payload.lotDestinationID,action.payload.sousLotDestinationID,action.payload.sousLot)
        .pipe(         
          map(() => ( dpgfActions.removeSection({data:action.payload,isLoading:true}) )),
          catchError((error) => (Observable.of( dpgfActions.errorSaveDpgfPricing(error) ) ))
        )
      })
    )
  });
  anyEffect$ = createEffect(() => {
    return this.actions$.pipe(
      withLatestFrom(this.store$),
      mergeMap(([action,state]) => {
        if(action.type.includes("saveLotNow")){
          return Observable.of( dpgfActions.saveDpgfLotToServer({}) );
        }else if(action.type.includes("addChange")){
          return Observable.of( dpgfActions.addChange({}) );
        }else if(action.type.includes("recalculatetotal")){
          return Observable.of( dpgfActions.calculate({}) );
        }else if(action.type.includes("saveDpgf")){
          return Observable.of( dpgfActions.saveDpgfToServer({}) );
        }else if(action.type.includes("countLine")){
          return Observable.of( dpgfActions.nbLine() );
        }else if(action.type.includes("saveDone")){
          this.savingLot = false;
          return EMPTY;
        }else if(action.type.includes("saveLocalisations") && state["dpgf"].mode == "archi" && state["dpgf"].saveLocalisationToServer ){
          return this.dpgfService.updateLocalisations(state["dpgf"].dpgf._id, state["dpgf"].dpgf.localisations)
          .pipe(
            mergeMap(() => {
              if(state["dpgf"].mode == "archi")
                return [
              dpgfActions.setSaveDone({ done: true }),
              dpgfActions.getSizeDPGF({})
              ];
              else{
                return [dpgfActions.setSaveDone({ done: true })]
              }  
          }),            
            catchError((error) => (Observable.of( dpgfActions.errorSaveDpgf(error) ) ))
          )
        }
        else if(action.type.includes("error")){
          this.savingLot = false;          
          /// when the project is demo don't show notification toast message
          if(action["payload"].error && !action["payload"].error.message.includes("Only demo")){
            this.errorService.manageError(action["payload"], null);
          }
          return EMPTY;
        }
        return EMPTY;
      })
    )}
  );

  constructor(
    private actions$: Actions,
    private dpgfService: DpgfPostesService,
    private errorService: ErrorService,
    private store$: Store
  ) {}
}
