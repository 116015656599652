<div class="floating-card">
  <div class="row">
    <div class="col-xs-6">
      <h2 class="secondary">TÂCHE</h2>
    </div>
    <div class="col-xs-6">
      <button *ngIf="!addingTask" class="add-btn" type="button" name="button" (click)="openTaskPopup()">
        Nouvelle tâche
      </button>
    </div>
  </div>
  <app-spinner *ngIf="getLoading"></app-spinner>
  <div *ngIf="addingTask" class="row">
    <div class="task-card new-task-form">
      <div class="row">
        <div class="col-md-12">
          <input [ngClass]="{'task-input': showNewTaskErrors }" [(ngModel)]="task.title" type="text" name="" value="" placeholder="Titre de la tâche">
        </div>
        <div class="col-md-12">
          <textarea [(ngModel)]="task.task"
          type="text"
          placeholder="Description de la tâche"
          class="md-textarea form-control small"
          [ngClass]="{'task-input': showNewTaskErrors }"></textarea>
        </div>
        <div class="col-md-12">
          <p-multiSelect
          [options]="collaborateurs" optionLabel="email"
          placeholder="Assigner cette tâche à un collaborateur" [(ngModel)]="task.users"
          [maxSelectedLabels]="1"
          [selectedItemsLabel]="'Plusieurs éléments sélectionnés'"
          ></p-multiSelect>
        </div>
        <div class="col-md-12 margin-top-12">
          <app-spinner *ngIf="creatingTask"></app-spinner>
          <button *ngIf="!creatingTask" class="add-btn" type="button" name="button" (click)="addTask()">
            Ajouter
          </button>
          <button *ngIf="addingTask" class="add-btn" type="button" name="button" (click)="openTaskPopup()">
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!getLoading && !addingTask">
    <div class="row" >
      <div id="all-tabs" class="tab-content">
        <div id="encours" class="tab-pane fade in active">
          <div *ngFor="let tsk of waitingTasks" class="row">
            <div class="task-card">
              <div class="titre-task">
                {{tsk.title}}
                <!-- <i class="fa fa-check done-btn" tooltipPosition="top" pTooltip="Tâche effectuée" aria-hidden="true" (click)="doneTask(tsk._id)"></i> -->
                <div class="time-task">
                  <i class="fa fa-clock-o" aria-hidden="true"></i> {{tsk.createdAt |dateFr}}
                </div>
              </div>
              <div class="row content-task">
                {{tsk.task}}
              </div>
              <div class="row">
                <div class="user-name" *ngFor="let user of tsk.users"
                    [ngStyle]="{'border-color': user.color ,'color' :user.color}">
                  {{(user.email || "").substring(0,1) | capitalize}}
                </div>

                <span>
                  <i class="fa fa-check done-btn" aria-hidden="true" (click)="doneTask(tsk._id)">&nbsp; <span>Terminer</span></i>

                </span>

              </div>
            </div>
          </div>
          <img *ngIf="waitingTasks.length == 0" src="/assets/rob/undraw_chat.svg" class="empty">
        </div>
        <div id="done" class="tab-pane fade in">
          <div *ngFor="let tsk of doneTasks"  class="row">
            <div class="task-card">
              <div class="titre-task">
                {{tsk.title}}
                <div class="time-task">
                  <i class="fa fa-clock-o" aria-hidden="true"></i> {{tsk.createdAt |dateFr}}
                </div>
              </div>
              <div class="row content-task">
                {{tsk.task}}
              </div>
              <div class="row">
                <div class="user-name" *ngFor="let user of tsk.users"
                    [ngStyle]="{'border-color': user.color ,'color' :user.color}">
                  {{(user.email || "").substring(0,1) | capitalize}}
                </div>

              </div>
            </div>
          </div>
          <img *ngIf="doneTasks.length == 0" src="/assets/rob/undraw_chat.svg" class="empty">
        </div>
      </div>
    </div>
    <div class="row">
      <ul class="nav nav-pills nav-center">
        <li class="nav-item active"><a data-toggle="tab" href="#encours">En cours</a></li>
        <li class="nav-item" ><a data-toggle="tab" href="#done">Terminé</a></li>
      </ul>
    </div>

  </div>
</div>
