import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-remove-lot-modal',
  standalone: true,
  imports: [],
  templateUrl: './confirm-remove-lot-modal.component.html',
  styleUrl: './confirm-remove-lot-modal.component.scss'
})
export class ConfirmRemoveLotModalComponent {

  @Input() lotTitle: string="";
  @Output() confirmRemoveLot:EventEmitter<any> = new EventEmitter<any>();

  public emitConfirmRemoveLot(){
    this.confirmRemoveLot.emit();
  }

}
