<div class="container-fluid margin-top-32">
  <div class="row">
    <div class="col-md-9">

      <div *ngIf="!isInvalidProject && isProjectCompleted()" class="bravo-container flex">
        <img src="/assets/acv-icons/green-bravo.svg">
        <div class="details">
          <p class="title">Bravo !</p>
          <p class="sub-title">Le projet <strong>{{projectTitle}}</strong> respecte les exigences de la <span style="color:#008037;">RE2020</span> !</p>
          <button class="download-button" [disabled]="notValidBool && permis==0" (click)="downloadAttestation()">
            <span *ngIf="!loadingDownload">Télécharger mon attestation</span>
            <app-spinner *ngIf="loadingDownload"></app-spinner>
          </button>
        </div>
      </div>
      <div *ngIf="isInvalidProject && isProjectCompleted()" class="bravo-container flex">
        <img src="/assets/acv-icons/red-bravo.svg">
        <div class="details">
          <p class="title">Dommage</p>
          <p class="sub-title">Le projet <strong>{{projectTitle}}</strong> ne respecte pas les exigences de la <span style="color:#008037;">RE2020</span> !</p>
          <button class="download-button" [disabled]="notValidBool && permis==0" (click)="downloadAttestation()">
            <span *ngIf="!loadingDownload">Télécharger mon attestation</span>
            <app-spinner *ngIf="loadingDownload"></app-spinner>
          </button>
        </div>
      </div>
      <!-- Alert Danger -->
      <div *ngIf="notValidBool && permis==0" class="ag-alert-danger margin-bottom-20">
        <img src="/assets/acv-icons/danger-exclamation.svg" class="margin-right-6">
        <p class="no-margin-bottom">Attention ! Le {{notValidValues}} {{checkNumValidValues()? "sont":"est"}} au-dessus du seuil maximal . Vous devez refaire votre étude thermique afin d'obtenir le permis de construire.</p>
      </div>
      <!-- Alert Danger -->
      <div *ngIf="!(batiments[selectedBatiment]||{}).art20 && checkRSET()" class="ag-alert-danger margin-bottom-20">
        <img src="/assets/acv-icons/danger-exclamation.svg" class="margin-right-6">
        <p class="no-margin-bottom">Votre projet ne respecte pas la validation sur les systèmes de ventilation.</p>
      </div>
      <!-- Alert Danger -->
      <!-- <div *ngIf="(((!(batiments[selectedBatiment]||{}).art23 || !(batiments[selectedBatiment]||{}).art23_1 || !(batiments[selectedBatiment]||{}).art23_2) && (batiments[selectedBatiment]||{}).usage=='1') || ((!(batiments[selectedBatiment]||{}).art23 && !(batiments[selectedBatiment]||{}).art23_1 && !(batiments[selectedBatiment]||{}).art23_2) && (batiments[selectedBatiment]||{}).usage=='2')) && checkRSET()" class="ag-alert-danger margin-bottom-20"> -->
      <div *ngIf="((!(batiments[selectedBatiment]||{}).art23 && !(batiments[selectedBatiment]||{}).art23_1 && !(batiments[selectedBatiment]||{}).art23_2 && ((batiments[selectedBatiment]||{}).usage=='1' || (batiments[selectedBatiment]||{}).usage=='2'))) && checkRSET()" class="ag-alert-danger margin-bottom-20">
        <img src="/assets/acv-icons/danger-exclamation.svg" class="margin-right-6">
        <p class="no-margin-bottom">Les exigences d'accès à l'éclairage naturel ne sont pas respectées.</p>
      </div>
      <!-- END Alert Danger -->


      <!-- Etat Card 1 -->
      <div *ngIf="!isProjectCompleted()" class="ag-card-1 etat-card">
        <h4 class="ag-heading-h3">Etat de l’ACV</h4>
        <p class="ag-para-1 margin-bottom-23">
          L’étude de l’ACV de votre projet n’est pas complète et les résultats ne sont que des résultats partiels,
          <b>remplissez les conditions ci-dessous afin d’avoir une étude complète.</b>
        </p>
        <div>
          <ul class="no-padding-left">
            <li *ngFor="let item of etatCardList" class="flex"
              [ngClass]="{'hidden-item': (item?.missingFieldsCount && item?.missingFieldsCount == 0) }">
              <div class="flex">
                <img [src]="item.labelIcon" *ngIf="item.labelIcon != ''" class="margin-right-11">
                <i *ngIf="item.labelIcon == ''" class="fa fa-check check-icon" aria-hidden="true"></i>
                <p class="item-desc no-margin-bottom" [innerHtml]="item.label"></p>
              </div>

              <span *ngIf="item.show" class="item-action margin-left-47 ag-para-1-primary pointer" (click)="clickEvent(item.id)">
                {{ item.actionLabel }}
                <img [src]="item.actionIcon" *ngIf="item.actionIcon != ''" class="margin-left-9">
              </span>
            </li>
          </ul>
        </div>
      </div>
      <!-- End Etat Card 1 -->

      <!-- indicateurs Card -->
      <div class="ag-card-1 indicateurs-card margin-top-20 margin-bottom-14 flex">
        <h4 class="ag-heading-h3 margin-right-32">Indicateurs réglementaires du</h4>
        <select class="ag-select-2" (change)="putBatimentValues($event.target.value)">
          <option *ngIf="!selectedBatiment" selected disabled hidden>Select Batiment</option>
          <option *ngFor="let batiment of batiments | keyvalue" [value]="batiment.key">{{batiment.key}}</option>
        </select>
      </div>
      <!-- END indicateurs Card -->

      <!-- Performance Cards -->
      <div class="row">
        <div class="col-md-6 no-padding-left margin-bottom-12">
          <div class="ag-card-1">
            <p class="ag-para-2 ag-bold text-center margin-vertical-15">Performance énergétique et confort d’été</p>

            <div class="performance-card padding-30" *ngFor="let item of cardsList.performanceCardsList">
              <!-- upper -->
              <div class="upper-cont flex">
                <div class="left-content flex">
                    <img [src]="item.icon" *ngIf="item.icon" style="max-width:26px;">
                  <div class="margin-left-24 flex-vertical">
                    <p class="ag-para-1-primary ag-bold">{{ item.title }}</p>
                    <p class="ag-para-3 ag-text-grey">{{ item.description }}</p>
                  </div>
                </div>
                <div class="right-content">
                  <div class="flex">
                    <div class="flex-vertical margin-right-5">
                      <span class="dot-indicator" [ngClass]="item.uniteValue!='0'? item.uniteValue > item.barValue1 ? 'dot-red':'dot-green':'' "></span>
                    </div>
                    <div class="flex-vertical">
                      <p class="unite-val">{{ item.uniteValue | number:'1.0-2':'fr' }}</p>
                      <p class="unite">{{ item.unite }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END upper -->

              <!-- Bar -->
              <div class="bar-container">
                <div *ngIf="checkRSET()" class="bar">
                  <div class="bar-indicator" [ngClass]="{'border-green': borderValue(item.uniteValue,item.barValue1)}" [ngStyle]="{'left': barValue(item.uniteValue,item.barValue1),'border-top:': borderValue(item.uniteValue,item.barValue1)}"></div>

                  <!-- double progress -->
                  <ng-container *ngIf="item.barType == 'double'">
                    <div class="bar-progress half green"></div>
                    <div class="bar-progress half red"></div>
                  </ng-container>
                  <!-- END double progress -->

                  <!-- 3 bars Progress -->
                  <ng-container *ngIf="item.barType == 'triple'">
                    <div class="bar-progress quarter green"></div>
                    <div class="bar-progress quarter yellow"></div>
                    <div class="bar-progress quarter red"></div>
                  </ng-container>
                  <!-- END 3 bars Progress -->

                  <div class="bar-values">

                    <div *ngIf="item.barType == 'double'" class="full-width">
                      <span class="centerd-value">{{ item.barValue1 | number:'1.0-0':'fr' }}</span>
                    </div>

                    <div *ngIf="item.barType == 'triple'" class="quarterd-value">
                      <span class="quarterd-value">{{ item.barValue1 | number:'1.0-0':'fr' }}</span>
                      <span class="quarterd-value">{{ item.barValue2 | number:'1.0-0':'fr' }}</span>
                    </div>

                  </div>

                </div>
              </div>
              <div class="flex-center" *ngIf="!checkRSET()">
                <img src="/assets/archi/licence_warning.svg" alt="" class="warning-icon">
                <p class="empty-brief">Importer le RSET pour découvrir la valeur du {{item.title}}</p>
              </div>
              <!-- END Bar -->
            </div>

          </div>

        </div>

        <div class="col-md-6 no-padding-right">

          <div class="ag-card-1">
            <p class="ag-para-2 ag-bold text-center margin-vertical-15">Performance environnementale</p>
            <div class="performance-card padding-30" *ngFor="let item of cardsList.performanceEnvironnementaleCardsList">
              <!-- upper -->
              <div class="upper-cont flex">
                <div class="left-content flex">
                    <img [src]="item.icon" *ngIf="item.icon" style="max-width:26px;">
                  <div class="margin-left-24 flex-vertical">
                    <p class="ag-para-1-primary ag-bold">{{ item.title }}</p>
                    <p class="ag-para-3 ag-text-grey">{{ item.description }}</p>
                  </div>
                </div>
                <div class="right-content">
                  <div class="flex">
                    <div class="flex-vertical margin-right-5">
                      <span class="dot-indicator" [ngClass]="item.uniteValue!='0'? item.uniteValue > item.barValue1 ? 'dot-red':'dot-green':'' "></span>
                    </div>
                    <div class="flex-vertical">
                      <p class="unite-val">{{ item.uniteValue | number:'1.0-2':'fr' }}</p>
                      <p class="unite">{{ item.unite }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END upper -->

                       <!-- Bar -->
              <div class="bar-container" *ngIf="checkRSET() && !(item.id=='Icc' && (etatCardList[1].show || etatCardList[2].show))">
                <div class="bar">
                  <div class="bar-indicator" [ngClass]="{'border-green': borderValue(item.uniteValue,item.barValue1)}" [ngStyle]="{'left': barValue(item.uniteValue,item.barValue1),'border-top:': borderValue(item.uniteValue,item.barValue1)}"></div>
                  <!-- double progress -->
                  <ng-container *ngIf="item.barType == 'double'">
                    <div class="bar-progress half green"></div>
                    <div class="bar-progress half red"></div>
                  </ng-container>
                  <!-- END double progress -->

                  <!-- 3 bars Progress -->
                  <ng-container *ngIf="item.barType == 'triple'">
                    <div class="bar-progress quarter green"></div>
                    <div class="bar-progress quarter yellow"></div>
                    <div class="bar-progress quarter red"></div>
                  </ng-container>
                  <!-- END 3 bars Progress -->

                  <div class="bar-values">

                    <div *ngIf="item.barType == 'double'" class="full-width">
                      <span class="centerd-value">{{ item.barValue1 | number:'1.0-0':'fr' }}</span>
                    </div>

                    <div *ngIf="item.barType == 'triple'" class="quarterd-value">
                      <span class="quarterd-value">{{ item.barValue1 | number:'1.0-0':'fr' }}</span>
                      <span class="quarterd-value">{{ item.barValue2 | number:'1.0-0':'fr' }}</span>
                    </div>

                  </div>

                </div>
              </div>
              <div class="flex-center" *ngIf="item.id=='Icc' && (etatCardList[1].show || etatCardList[2].show || !checkRSET())">
                <img src="/assets/archi/licence_warning.svg" alt="" class="warning-icon">
                <p class="empty-brief">Votre étude n’est pas complète, les résultats sont partiels</p>
              </div>
              <!-- END Bar -->
            </div>
          </div>

          <div class="ag-card-1 margin-top-30">
            <p class="ag-para-2 ag-bold text-center margin-vertical-15">Impact du {{selectedBatiment}}</p>
            <div class="padding-15" *ngFor="let item of cardsList.impactBatiment">
              <div class="flex impact-info">
                <img [src]="item.icon" *ngIf="item.icon" class="margin-right-15">
                <p class="font-20"><strong>{{item.uniteValue | number:'1.0-2':'fr'}}</strong></p>
                <p class="padding-left-15">{{item.unite}}</p>
              </div>
              <div class="blue-bar"></div>
            </div>
          </div>

        </div>

      </div>


    </div>
    <!-- =================================
      Right SideBar
    ================================= -->
    <div class="col-md-3 right-sidebar-list">
      <!-- Top Card -->
      <div *ngIf="!isProjectCompleted()" class="ag-card-1 padding-15">
        <h4 class="ag-heading-h3 text-center">{{ attestationCard.title || '' }}</h4>
        <div class="" [innerHtml]="attestationCard.description"></div>
        <div *ngIf="checkRSET()" class="center">
          <button class="ag-btn-bordered-sm" [disabled]="notValidBool && permis==0" (click)="OpenAttestationModal()">
            <span *ngIf="!loadingDownload">{{ attestationCard.buttonLabel || '' }}</span>
            <app-spinner *ngIf="loadingDownload"></app-spinner>
          </button>
        </div>
        <div *ngIf="!checkRSET()">
          <div class="flex-center" style="justify-content: left;">
            <img src="/assets/acv-icons/cross.svg" class="margin-right-11">
            <p class="item-desc no-margin-bottom" [innerHtml]="etatCardList[0].label"></p>
          </div>
          <span class="item-action ag-para-1-primary pointer" (click)="clickEvent(etatCardList[0].id)">
            {{ etatCardList[0].actionLabel }}
            <img [src]="etatCardList[0].actionIcon" *ngIf="etatCardList[0].actionIcon != ''" class="margin-left-9">
          </span>
        </div>
      </div>
      <!-- END Top Card -->

      <!-- Fiches Cards List -->
      <div class="margin-top-30">
        <p class="ag-para-2 ag-bold margin-bottom-12">Les fiches les plus impactantes</p>

        <!-- Fiche Cards -->
        <div class="ag-card-1 fiche-card margin-bottom-16" [ngClass]="item.declarationType? 'declarationTypeCard'+item.declarationType:'declarationTypeCard1'" *ngFor="let item of ficheCards">
          <p class="ag-para-3 a-bold">{{ item.title }}</p>
          <!-- type -->
          <p class="align-right ag-para-3 margin-top-8 ag-text-grey">{{ item.content }}</p>
          <!--  -->
          <div class="align-right">
            <img [src]="item.icon" *ngIf="item.icon" class="margin-right-15">
            <span class="ag-para-1 ag-bold">{{ item.value*item.quantity | formatPrice }} kg éq.CO2</span>
          </div>
          <!-- icon -->
          <!-- value -->
        </div>
        <!-- END Fiche Cards -->

        <a class="ag-para-1-primary full-width text-center" (click)="goToPosts()">Voir les fiches les plus impactantes</a>

      </div>
      <!-- END Fiches Cards List -->

    </div>
    <!-- =================================
      END Right SideBar
    ================================= -->

  </div>
</div>

<!-- Telecharger Attestation fin Travaux -->
<div class="modal fade" role="dialog" id="telecharger-attestation-fin-travaux">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h2 class="ag-heading-h2 ag-bold no-margin">Télécharger l’attestation de fin de travaux</h2>
      </div>
      <div class="modal-body">
        <p class="ag-heading-h3 ag-weight-400 ag-text-black margin-bottom-32">Choisissez le type de document que vous souhaitez télécharger :</p>
        <div class="radio-options-cont">
          <p class="ag-para-1 margin-bottom-14">
            <input type="radio" name="attestation-type"
              value="xml" [(ngModel)]="selectedAttestationType">
              <span class="margin-left-12">RSEE au format XML</span>
          </p>
          <p class="ag-para-1 margin-bottom-14">
            <input type="radio" name="attestation-type"
              value="1" [(ngModel)]="selectedAttestationType">
              <span class="margin-left-12">RSEE au format PDF (partie “Étude thermique”)</span>
          </p>
          <p class="ag-para-1 margin-bottom-14">
            <input type="radio" name="attestation-type"
              value="2" [(ngModel)]="selectedAttestationType">
              <span class="margin-left-12">RSEE au format PDF (partie “Étude environnementale”)</span>
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="ag-btn-bordered-sm margin-right-27" data-dismiss="modal">Annuler</button>
        <button type="button" class="ag-btn-primary-sm" (click)="downloadAttestation()" [disabled]="selectedAttestationType == ''">
          <span *ngIf="!loadingDownload">Télécharger</span>
          <app-spinner *ngIf="loadingDownload"></app-spinner>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- END Telecharger Attestation fin Travaux -->

<!-- Champs nécessaires au calcul de l’ACV -->
<div class="modal fade" role="dialog" id="required-fields-calcul-acv">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header no-border-bottom">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <p class="ag-para-2 ag-bold no-margin">Champs nécessaires au calcul de l’ACV</p>
      </div>
      <div class="modal-body" *ngFor="let item of formsInputs | keyvalue : returnZero">
        <h3>{{ fillAcvFilledFieldsTitles[item.key] || item.key }}</h3>
        <div *ngFor="let nestedItem of item.value | keyvalue : returnZero">

          <!-- Project & Batiment -->
          <ng-container *ngIf="nestedItem.key == 'items'">
            <div class="form-row flex space-between"
              *ngFor="let row of nestedItem.value"
              [ngClass]="{'hidden-item': missingFieldsStructure[item.key+'-'+row.modelVariable]}"
              >
              <div class="row-label flex-vertical">
                <p class="ag-para-1">{{ row.label }}</p>
              </div>
              <app-form-inputs [row]="row" (valueInserted)="acvFieldsFilledInput($event)"></app-form-inputs>
            </div>
          </ng-container>
          <!-- END Project & Batiment -->

          <!-- Zones -->
          <ng-container *ngIf="nestedItem.key != 'items'">
            <p class="ag-para-1 margin-vertical-10">{{ fillAcvFilledFieldsTitles[nestedItem.key] }}</p>
            <div class="form-row flex space-between"
              *ngFor="let row of nestedItem.value"
              [ngClass]="{'hidden-item': missingFieldsStructure[row.modelVariable]}"
              >
              <div class="row-label flex-vertical">
                <p class="ag-para-1">{{ row.label }}</p>
              </div>
              <app-form-inputs [row]="row" (valueInserted)="acvFieldsFilledInput($event)"></app-form-inputs>
            </div>
          </ng-container>
          <!-- END Zones -->

        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="ag-btn-primary-sm" (click)="updateRequiredFields()">Valider</button>
      </div>
    </div>
  </div>
</div>
<!-- END Champs nécessaires au calcul de l’ACV -->

<!-- lot PCE nécessaires au calcul de l’ACV -->
<div class="modal fade" role="dialog" id="required-lot-pce-acv">
  <div class="modal-dialog" style="width: 50%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <p class="ag-para-2 ag-bold no-margin">Lots PCE nécessaires au calcul de l’ACV</p>
      </div>
      <div class="modal-body" *ngFor="let zone of zones | keyvalue">
        <div *ngIf="zone.value.length>4">
          <h3>{{ zone.key }} ({{9 - (13 - zone.value.length)}} lots à renseigner)</h3>
          <div class="form-row flex space-between" *ngFor="let item of zone.value">
            <div>
              {{lotPCEArray[item-1]}}
            </div>
            <div class="float-right">
              <a (click)="goToZone(zone.key,item)" style="color:#3805F2">Aller à ce lot <img src="/assets/acv-icons/right-arrow.svg"> </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END Lot PCE nécessaires au calcul de l’ACV -->
