<form [formGroup]="subscriptionForm">
<div>
    <p class="text-size-18-weight-700 color-lineheight" style="padding-bottom:8px;">Informations personnelles</p>
    <p style="padding-bottom:32px;width: 77%;">Ces informations n'apparaissent pas sur votre profil. Elles sont disponibles pour les maîtres d’oeuvre avec lesquels vous avez travaillé.</p>
    <div class="row">
    <div class="flex" style="padding-bottom:16px;">
        <div>
            <p class="text-size-14-weight-400 color-lineheight">Prénom</p>
            <input formControlName="lastName" type="text" class="form-control input-styling margin-right-32 padding-left-10" (ngModelChange)="valueChanged($event,'lastName')" placeholder="Nom">
            <small *ngIf="formErrors.lastName" class="text-danger">
                {{ formErrors.lastName }}
              </small>
        </div>
        <div>
            <p class="text-size-14-weight-400 color-lineheight">Nom</p>
            <input formControlName="firstName" type="text" class="form-control input-styling padding-left-10" (ngModelChange)="valueChanged($event,'firstName')"placeholder="Prénom">
            <small *ngIf="formErrors.firstName" class="text-danger">
                {{ formErrors.firstName }}
              </small>
        </div>
    </div>        
    <div class="flex">
        <div>
            <p class="text-size-14-weight-400 color-lineheight">Email de contact</p>
            <input formControlName="emailAgency" type="text" class="form-control input-styling margin-right-32 padding-left-10" (ngModelChange)="valueChanged($event,'email')" placeholder="Email">
            <small *ngIf="formErrors.emailAgency" class="text-danger">
                {{ formErrors.emailAgency }}
              </small>
        </div>
        <div>
            <p class="text-size-14-weight-400 color-lineheight">Numéro de téléphone</p>
            <input formControlName="phone" type="text" class="form-control input-styling padding-left-10" (ngModelChange)="valueChanged($event,'phone')" placeholder="téléphone">
            <small *ngIf="formErrors.phone" class="text-danger">
                {{ formErrors.phone }}
              </small>
        </div>
    </div>
    <div class="text-color-blue padding-top-41 wid76 flex justify-center">
        <!-- <button class="cancel-button">Annuler les modifications</button> -->
        <button class="save-button" [disabled]="disabled" [ngClass]="{'disabled':disabled}" (click)="UpdateUserData()">
            <p class="margin0" *ngIf="!loadingButton">Enregistrer les modifications</p>
            <div *ngIf="loadingButton">
                <app-spinner></app-spinner>
            </div>
        </button>
    </div>
    </div>
    
</div>
</form>