<div id="booster-profile-edit-agency-documents-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header no-padding">
        <h4 class="ag-heading-h4 no-margin margin-bottom-24">Documents</h4>

        <p class="ag-para-1 ag-weight-500 text-center">Ces documents permettent de mettre en avant votre sérieux et compétences auprès des maîtres d'oeuvre.</p>
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">
        
        <div class="docs-card-container white-card horizontal-items">
    
          <!-- Attestation d’assurance -->
          <div class="document-section width-50 margin-bottom-70">
            <h5 class="ag-heading-h5 ag-weight-600">Attestation d’assurance</h5>
            
            <div class="upload-input-container" [ngClass]="{'remove-item-from-view': docsData?.assuranceFile?.name}">
              <input id="assuranceFile"  type="hidden" role="uploadcare-uploader" data-preview-step=true name="assuranceFile" />
            </div>
      
            <div class="input-wrapper text-center" [ngClass]="{'remove-item-from-view': docsData?.assuranceFile?.name}">
              <p-fileUpload mode="basic" chooseLabel="Sélectionner un fichier" name="demo1" url="/api/v1/upload-file?type=assuranceFile" [auto]="true"
              (onUpload)="onUploadAttestationAssurance($event)"></p-fileUpload>
            </div>
      
            <div class="files-list" *ngIf="docsData?.assuranceFile?.name">
              
              <div class="single-file horizontal-items margin-bottom-5">
                <p class="width-80 ag-para-1 flex-vertical justify-center">
                  <a *ngIf="!authService.currentEnvironment" [href]="docsData?.assuranceFile?.cdnUrl" target="_blank">{{ docsData?.assuranceFile?.name }}</a>
                  <a *ngIf="authService.currentEnvironment" [href]="'api/v1/static'+docsData?.assuranceFile?.cdnUrl" target="_blank">{{ docsData?.assuranceFile?.name }}</a>
                </p>
                <img src="/assets/artisan-booster/remove-icon.svg" class="width-10 pointer" (click)="removeDocument('assuranceFile')">
              </div>      
      
            </div>
          </div>
          <!-- END Attestation d’assurance -->
      
          <!-- Extrait KBIS -->
          <div class="document-section width-50 margin-bottom-70">
            <h5 class="ag-heading-h5 ag-weight-600">Extrait KBIS</h5>

            <div class="upload-input-container">
              <input id="kbisFile" type="hidden" role="uploadcare-uploader" data-preview-step=true name="kbisFile" />
            </div>
            <div class="input-wrapper text-center">
              <p-fileUpload mode="basic" chooseLabel="Sélectionner un fichier" name="demo1" url="/api/v1/upload-file?type=kbis" [auto]="true"
              (onUpload)="onUploadKbisFiles($event)"></p-fileUpload>
            </div>
      
            <div class="files-list" *ngIf="docsData?.kbis?.name">
              
              <div class="single-file horizontal-items margin-bottom-5">
                <p class="width-80 ag-para-1 flex-vertical justify-center">
                  <a [href]="docsData?.kbis?.cdnUrl" target="_blank">{{ docsData?.kbis?.name }}</a></p>
                <img src="/assets/artisan-booster/remove-icon.svg" class="width-10 pointer" (click)="removeDocument('kbis')">
              </div>     
      
            </div>
          </div>
          <!-- END Extrait KBIS -->
      
          <!-- Attestation de certifications Qualibat, RGE... -->
          <div class="document-section width-50 margin-bottom-30">
            <h5 class="ag-heading-h5 ag-weight-600">Attestations de certification (Qualibat, RGE...)</h5>

            <div class="upload-input-container">
              <input id="attestations-certification" type="hidden" role="uploadcare-uploader" data-preview-step=true data-multiple=true />
            </div>
            <div class="input-wrapper text-center">
              <p-fileUpload chooseLabel="Sélectionner un fichier" name="demo2" url="/api/v1/upload-file?type=attestationsCertification" (onUpload)="onUploadAttestationsCertification($event)" [multiple]="true" [auto]="true" mode="basic"
              maxFileSize="1000000"></p-fileUpload>
            </div>
      
            <div class="files-list">
              
              <div class="single-file horizontal-items margin-bottom-5" *ngFor="let file of docsData.attestationsCertification; let i = index;">
                <p class="width-80 ag-para-1 flex-vertical justify-center">
                  <a [href]="file.cdnUrl" target="_blank">{{ file.name }}</a>
                </p>
                <img src="/assets/artisan-booster/remove-icon.svg" class="width-10 pointer" (click)="removeDocument('attestationsCertification', i)">
              </div>      
      
            </div>
          </div>
          <!-- END Attestation de certifications Qualibat, RGE... -->
      
          <!-- Autre -->
          <div class="document-section width-50 margin-bottom-30">
            <h5 class="ag-heading-h5 ag-weight-600">Autre</h5>
            
            <div class="upload-input-container">
              <input id="portfolio-photo" type="hidden" role="uploadcare-uploader" data-preview-step=true data-multiple=true
               data-crop="free" data-image-shrink="1600x1600"/>
            </div>
            <div class="input-wrapper text-center">
              <p-fileUpload chooseLabel="Sélectionner un fichier" name="demo2" url="/api/v1/upload-file?type=files" (onUpload)="onUploadPortfolioPhoto($event)" [multiple]="true" [auto]="true" mode="basic"
               maxFileSize="1000000"></p-fileUpload>
            </div>
      
            <div class="files-list">
              
              <div class="single-file horizontal-items margin-bottom-5" *ngFor="let file of docsData.files; let i = index;">
                <p class="width-80 ag-para-1 flex-vertical justify-center">
                  <a [href]="file.originalUrl" target="_blank" class="">{{ file.name }}</a>
                </p>
                <img src="/assets/artisan-booster/remove-icon.svg" class="width-10 pointer" (click)="removeDocument('files', i)">
              </div>   
      
            </div>
          </div>
          <!-- END Autre -->
      
        </div>

        <div class="submit-btn-container text-center margin-top-30 ">
          <button class="ag-btn-primary-sm" (click)="emitUpdateDocument()">Enregistrer les nouveaux documents</button>
        </div>

      </div>
    </div>
  </div>

  <div class="DashboardCouvertureContainer" style="display:none;"></div>

</div>
