import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';


@Component({
    standalone: true,
    selector: 'app-booster-profile-edit-construction-carbone-modal',
    imports:[
      NgSharedModule 
    ],
    templateUrl: './booster-profile-edit-construction-carbone-modal.component.html',
    styleUrls: ['./booster-profile-edit-construction-carbone-modal.component.scss'],
})
export class BoosterProfileEditConstructionCarboneModalComponent implements OnInit, OnChanges {

  @Input() artisanReemploiList: any;
  @Output() saveData: EventEmitter<any> = new EventEmitter<any>();

  public materiauxIssuesReemploiList: any = [
    {name: "Source les matériaux", value: "source-les-materiaux"},
    {name: "Utilise les matériaux", value: "utilise-les-materiaux"},
    {name: "Transporte les matériaux", value: "transporte-les-materiaux"},
    {name: "Stock les matériaux", value: "stock-les-materiaux"},
  ];

  public materiauxBasCarbonesList: any = [
    {name: "Produits bio-sourcés ", value: "produits-bio-sources"},
    {name: "Matériaux locaux", value: "materiaux-locaux"},
    {name: "Produits alternatifs à faible émissions", value: "produits-alternatifs-a-faible-emissions"},
  ];

  public selectedReemploiItems: any = [];
  public selectedCarbonesItems: any = [];

  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.artisanReemploiList && Object.keys(changes.artisanReemploiList.currentValue).length){
      let data = changes.artisanReemploiList.currentValue;
      this.setData(data);
    }
  }
  private setData(data){
    let artisanMateriauxIssusReemploi = data["materiauxIssusReemploi"];
    let artisanMateriauxBasCarbones = data["materiauxBasCarbones"];
    
    for(let i = 0; i < artisanMateriauxIssusReemploi.length; i++){
      this.selectedReemploiItems.push(artisanMateriauxIssusReemploi[i].value);
    }

    for(let i = 0; i < artisanMateriauxBasCarbones.length; i++){
      this.selectedCarbonesItems.push(artisanMateriauxBasCarbones[i].value);
    }
    
  }

  public chooseReemploiItem(itemValue){
    if(this.selectedReemploiItems.includes(itemValue)){
      this.selectedReemploiItems = this.selectedReemploiItems.filter(item => item != itemValue);
    }else{
      this.selectedReemploiItems.push(itemValue);
    }
  }
  public chooseItem(type, itemValue){
    if(type == "reemploi"){
      this.selectedReemploiItems = this.addOrRemoveFromArray(this.selectedReemploiItems, itemValue);
    }else if(type == "carbones"){
      this.selectedCarbonesItems = this.addOrRemoveFromArray(this.selectedCarbonesItems, itemValue);
    }
  }
  private addOrRemoveFromArray(array, itemValue){
    if(array.includes(itemValue)){
      array = array.filter(item => item != itemValue);
    }else{
      array.push(itemValue);
    }

    return array;
  } 

  private getArrayItemsById(originalArray, idsArray){
    let data = [];

    for(let i = 0; i < originalArray.length; i++){
      let currentItem = originalArray[i];
      if(idsArray.includes(currentItem.value)){
        data.push(currentItem);
      }
    }

    return data;
  }

  public emitSaveData(){
    let materiauxReemploiData = this.getArrayItemsById(this.materiauxIssuesReemploiList, this.selectedReemploiItems);
    let materiauxBasCarbonesData = this.getArrayItemsById(this.materiauxBasCarbonesList, this.selectedCarbonesItems);

    let body = {
      materiauxIssusReemploi: materiauxReemploiData,
      materiauxBasCarbones: materiauxBasCarbonesData
    };
    
    this.saveData.emit(body);
  }

}
