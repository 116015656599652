<div id="max-posts-modal" class="modal fade" role="dialog">
  <div class="modal-dialog" style="margin-top: 30vh;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <!-- When there are 500 posts or more in a DPGF, if the user clicks on "Ajouter un poste", "Importer un poste", duplicater a lot, duplicate a post, import a lot from another project -->
        <h4 class="modal-title" *ngIf="!showLibraryText">Vous avez atteint la limite de postes dans votre projet</h4>

        <!-- When there are 200 posts or more in the library, if the user tries to send a post to the library from the DCE tab or directly add a post or import one in the library -->
        <h4 class="modal-title" *ngIf="showLibraryText">Vous avez atteint la limite de stockage de votre bibliothèque</h4>

      </div>
      <div class="modal-body">
        <!-- When there are 500 posts or more in a DPGF, if the user clicks on "Ajouter un poste", "Importer un poste", duplicater a lot, duplicate a post, import a lot from another project -->
        <p *ngIf="!showLibraryText">Vous avez atteint la limite de 500 postes pour ce projet. Pour pouvoir ajouter plus d'ouvrages, passez en licence Max ou Intégral.</p>
        
        <!-- When there are 200 posts or more in the library, if the user tries to send a post to the library from the DCE tab or directly add a post or import one in the library -->
        <p *ngIf="showLibraryText">
          Vous avez atteint la limite de postes de votre bibliothèque, passez en licence Max ou Intégral pour pouvoir stocker un nombre illimité de postes !
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="ag-btn-secondary-sm" data-dismiss="modal">Plus tard</button>
        <button (click)="redirectToPricingTable()" type="button" class="ag-btn-primary-sm" style="height: 40px;margin-left: 12px;">C'est parti !</button>
      </div>
    </div>
  </div>
</div>
