export class CorpsDetaData{
    public activitiesList = [  
        {
          title: "Préparation et aménagement de site",
          id: "preparation-et-amenagement-site",
          icon: "bulldozer.svg",
          sectionsLists: [
            {
              title: "Désamiantage",
              id: "desamiantage",
              list: [
                {label: "Désamiantage", value: "desamiantage",NAF_code:""},
              ]
            },{
              title: "Démolition - Dépose",
              id: "demolition-depose",
              list: [
                {label: "Démolition - Dépose", value: "demolition-depose",NAF_code:"43.11Z"},
              ]
            },{
              title: "VRD",
              id: "vrd",
              list: [
                {label: "VRD", value: "vrd",NAF_code:"43.21B"},
              ]
            },{
              title: "Terrassement",
              id: "terrassement",
              list: [
                {label: "Terrassement", value: "terrassement",NAF_code:"43.12A",NAF_code2:"43.12B"},
              ]
            }
          ]
        },
        {
          title: "Clos et couvert",
          id: "clos-et-couvert",
          icon: "bricks-icon.svg",
          sectionsLists: [      
            { // Added
              title: "Soutènement - Infrastructure",
              id: "soutenement-infrastructure",
              list: [
                {label: "Soutènement - Infrastructure", value: "soutenement-infrastructure",NAF_code:"43.99C"},
              ]
            },
            {
              title: "Façades",
              id: "facades",
              list: [
                {label: "Ravalement", value: "ravalement",NAF_code:"43.34Z"},
                {label: "Isolation par l’extérieur", value: "isolation-par-exterieur",NAF_code:"43.29A"},
                {label: "Isolation biosourcée", value: "isolation-biosourcee",NAF_code:""}, // NEW ADDED
                {label: "Revêtements/Peintures extérieures", value: "revetements-peintures-exterieures",NAF_code:"43.34Z"},
              ]
            },
            {
              title: "Structure",
              id: "structure",
              list: [
                {label: "Charpente bois", value: "charpente-bois",NAF_code:"43.91A"},
                {label: "Ossature bois", value: "ossature-bois",NAF_code:""},
                {label: "Charpente métallique", value: "charpente-metallique",NAF_code:"43.32B",NAF_code2:"43.91A"},
                {label: "Structure métallique", value: "structure-metallique",NAF_code:"43.99B"},
              ]
            },
            {
              title: "Couverture",
              id: "couverture",
              list: [
                {label: "Couverture - Zinguerie", value: "couverture-zinguerie",NAF_code:"43.91B"},
                {label: "Étanchéité", value: "etancheite",NAF_code:"43.99A"},
              ]
            },
            {
              title: "Gros Oeuvre",
              id: "gros-oeuvre",
              list: [
                {label: "Maçonnerie - Gros-Oeuvre", value: "maconnerie-gros-oeuvre",NAF_code:"43.99C"},
                {label: "Micropieux", value: "micropieux",NAF_code:"43.99C"},
                {label: "Béton banché", value: "beton-banche",NAF_code:"43.99C"},
                {label: "Taille de pierre", value: "taille-de-pierre",NAF_code:"43.99C"},
              ]
            },
            {
              title: "Menuiserie extérieures",
              id: "menuiserie-exterieures",
              list: [
                {label: "Menuiseries extérieures bois", value: "menuiseries-exterieures-bois",NAF_code:"43.32A"},
                {label: "Menuiseries extérieures aluminium/PVC", value: "menuiseries-exterieures-alu-pvc",NAF_code:"43.32A"},
              ]
            }
          ],
        },
        // 2- Second-oeuvre
        {
          title: "Second-oeuvre",
          id: "Second-oeuvre",
          icon: "paint-brush-icon.svg",
          sectionsLists: [
            {
              title: "Menuiseries intérieures et agencement",
              id: "menuiseries-interieures-et-agencement",
              list: [
                {label: "Menuiseries intérieures", value: "menuiseries-interieures",NAF_code:"43.32A"}, // MAYBE CHANGED
                {label: "Agencement intérieur", value: "agencement",NAF_code:"31.01Z"},
              ]
            },
            {
              title: "Cloisons - Faux-plafonds",
              id: "cloisons-faux-plafonds",
              list: [
                {label: "Plâtrerie", value: "platrerie",NAF_code:"43.31Z"},
                {label: "Isolation intérieure", value: "isolation-interieure",NAF_code:"43.29A"},
                {label: "Faux-plafonds", value: "faux-plafonds",NAF_code:"43.31Z"},
                {label: "Staff - Stuc", value: "staff-stuc",NAF_code:"43.31Z"},
              ]
            },
            { // NEW
              title: "Revêtements de sol",
              id: "revetements-de-sol-durs",
              list: [
                {label: "Revêtements de sol souples", value: "revetements-de-sol-souples",NAF_code:"43.33Z"},
                {label: "Parquet", value: "parquet",NAF_code:"43.33Z"},
                {label: "Chape", value: "chape",NAF_code:"43.33Z"},
                {label: "Sol coulé", value: "sol-coule",NAF_code:"43.33Z"},
              ]
            },
            {
              title: "Carrelage - Faïence",
              id: "carrelage-faience",
              list: [
                {label: "Carrelage - Faïence", value: "carrelage-faience",NAF_code:"43.33Z"},
              ]
            },
            {
              title: "Miroiterie - Vitrerie",
              id: "miroiterie-vitrerie",
              list: [
                {label: "Miroiterie - Vitrerie", value: "miroiterie-vitrerie",NAF_code:"43.34Z"},
              ]
            },
            { // NEW
              title: "Serrurerie - Ferronerie ",
              id: "serrurerie-ferronerie",
              list: [
                {label: "Serrurerie - Ferronerie ", value: "serrurerie-ferronerie",NAF_code:"43.32B"},
              ]
            },
            {
              title: "Peinture - Revêtements muraux - Nettoyage",
              id: "peinture-revetements-muraux-nettoyage",
              list: [
                {label: "Peinture - Revêtements muraux - Nettoyage", value: "peinture-revetements-muraux-nettoyage",NAF_code:"43.34Z"},
              ]
            }
          ],
        },
        //// 3- Corps d’état technique
        {
          title: "Corps d’état <br> technique",
          id: "corps-etat-technique",
          icon: "pipes-icon.svg",
          sectionsLists: [
            {
              title: "Électricité",
              id: "electricite",
              list: [
                {label: "Électricité", value: "electricite",NAF_code:"43.21A"},
              ]
            },
            {
              title: "Système de sécurité et de controle d'accès",
              id: "securite",
              list: [
                {label: "Alarmes - Surveillance", value: "alarmes-surveillance",NAF_code:"80.20Z"},
                {label: "Sécurité incendie", value: "securite-incendie",NAF_code:"80.20Z"},
                {label: "Contrôle d'accès", value: "controle-acces",NAF_code:""},
              ]
            },
            { // NEW
              title: "Domotique",
              id: "domotique",
              list: [
                {label: "Domotique", value: "domotique",NAF_code:"43.21A"},
              ]
            },
            {
              title: "Appareil élevateur",
              id: "appareil-elevateur",
              list: [
                {label: "Ascenseurs", value: "ascenseurs",NAF_code:"28.22Z"},
              ]
            },
            {
              title: "Plomberie - Sanitaire",
              id: "plomberie-sanitaire",
              list: [
                {label: "Plomberie-Sanitaire", value: "plomberie-sanitaire",NAF_code:"43.22A"},
                {label: "Gaz", value: "gaz",NAF_code:"43.22A"},
              ]
            },
            {
              title: "CVC",
              id: "cvc",
              list: [
                {label: "Fluides frigorigènes", value: "equipements-utilisant-des-fluides-frigorigenes",NAF_code:"43.22B"},
                {label: "Chauffage", value: "chauffage-electrique",NAF_code:"43.22B"},
                {label: "Climatisation", value: "climatisation",NAF_code:"43.22B"},
              ]
            },
            {
              title: "Energies renouvelables",
              id: "energie-renouvelable",
              list: [
                {label: "Géothermie", value: "geothermie",NAF_code:"43.22B"},
                {label: "Photovoltaïque", value: "photovoltaique",NAF_code:"43.22B"},
              ]
            }
          ],
        },
        // 4- Aménagements particuliers
        {
          title: "Aménagements <br> particuliers",
          id: "amenagements-particuliers",
          icon: "chairs-icon.svg",
          sectionsLists: [
            {
              title: "Aménagement paysager",
              id: "amenagements-paysager",
              list: [
                {label: "Aménagement paysager", value: "amenagements-paysager",NAF_code:"81.30Z"},
              ]
            },
            {
              title: "Piscine",
              id: "piscine",
              list: [
                {label: "Piscine", value: "piscine",NAF_code:""},
              ]
            },
            {
              title: "Cuisine",
              id: "cuisine",
              list: [
                {label: "Cuisine", value: "cuisine",NAF_code:"31.02Z"},
              ]
            }
          ]
        }  
      ];
}

  