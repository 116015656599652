import { ApplicationRef, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { ArchiDbService, AuthService } from 'src/app/_services';
import { MessageService } from 'primeng/api';
import * as fromSelectors from '../_store';
import { CommonModule } from '@angular/common';
import * as actions from '../_store/dpgf.actions';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { DpgfModule } from '../../dpgf.module';
import { environment } from 'src/environments/environment';
declare var $:any;
@Component({
  standalone:true,
  selector: 'app-archi-db-post',
  imports:[
    DpgfModule,
    SpinnerComponent,
    ArchiDbPostComponent,
    NgSharedModule
  ],
  templateUrl: './archi-db-post.component.html',
  styleUrls: ['./archi-db-post.component.scss']
})
export class ArchiDbPostComponent implements OnInit {

  @Input() firstTime;
  @Input() lots;
  @Input() newLots;
  @Input() sousLot;
  @Input() post;
  @Input() isDCE;
  @Input() hideMyDb;
  @Input() isDPGF;
  @Input() lotInputNotShowId;
  @Input() chosenLot;
  @Input() currentLotChild;

  @Output()chosenLotEvent = new EventEmitter<any>();
  @Output()chosenLotEvent1 = new EventEmitter<any>();
  @Output()currentLot=new EventEmitter<any>();
  @Output() countEvent = new EventEmitter<any>();

  public loading=false;
  public openedLotIndex=null;
  public selectedPosts=[];
  public error=false;
  public allLots;
  public writingFolder;
  public newFolderName;
  public moveMany;dpgfId;archiId;
  public lot;
  licence: string;
  constructor(private archiDbService: ArchiDbService,
              private cdr: ChangeDetectorRef,
              private appRef: ApplicationRef,
              private router: Router,
              private store: Store,
              private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private messageService: MessageService
              ) { }

  ngOnInit(): void {
    this.store.pipe(select(fromSelectors.getAny, {name : "exportManyPostsDb"})).subscribe((moveMany)=>{
      this.moveMany = moveMany;
    })
    this.store.pipe(select(fromSelectors.getLot)).subscribe((lot)=>{
      this.lot = lot;
    })
    this.getRoutes();
    this.licence=this.authService.licence;
  }
  private getRoutes(){
    this.archiId=this.activatedRoute.snapshot.queryParamMap.get('archiId')
    this.activatedRoute.params.forEach((params: Params) => {
      if (params['dpgfId']) {
        this.dpgfId = params['dpgfId'];
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.getRoutes();

    if(changes.firstTime && changes.firstTime.currentValue) this.getFirstLots()
    if(changes.lots && !this.firstTime && this.lots.sousLots) {
      let lotNotShowId;
      this.lots.sousLots.forEach(element => {
        element.open=false;element.loading=false;element.loadingPost=false;
        if(!this.isDCE && this.lotInputNotShowId && element._id==this.lotInputNotShowId) lotNotShowId=element._id;
      });
      if(lotNotShowId)this.lots.sousLots=this.lots.sousLots.filter(element=>{return element._id!=lotNotShowId})
    }
    if(changes.lotInputNotShowId && this.firstTime && this.lots){
      this.lots=this.allLots;
      let lotNotShowId;
      this.lots.forEach(element => {
        element.open=false;element.loading=false;element.loadingPost=false;
        if(!this.isDCE && this.lotInputNotShowId && element._id==this.lotInputNotShowId) lotNotShowId=element._id;
      });
      if(lotNotShowId)this.lots=this.lots.filter(element=>{return element._id!=lotNotShowId})
    }
  }
  getFirstLots(){
    this.loading=true;
    this.archiDbService.getAllLot("title sousLots",true,{dpgfId:this.dpgfId,archiId:this.archiId,idLot:this.activatedRoute.snapshot.queryParamMap.get('idLot')}).subscribe(
    (data)=>{
      let lotNotShowId;
      this.allLots=data.dbs;
      this.countEvent.emit({count:data.count,licence:data.licence})
      this.lots=data.dbs;
      this.lots.forEach(element => {
        element.open=false;element.loading=false;element.loadingPost=false;
        if(!this.isDCE && this.lotInputNotShowId && element._id==this.lotInputNotShowId) lotNotShowId=element._id;
      });
      if(lotNotShowId)this.lots=this.lots.filter(element=>{return element._id!=lotNotShowId})
      this.loading=false;
    })
  }
  openLotWithoutPost(lotId,j){
    this.newLots=[];
    this.writingFolder=false;
    if(this.firstTime){
      if(this.openedLotIndex!=null) this.lots[this.openedLotIndex].open=false;
      this.lots[j].open=true;
      this.lots[j].loading=true;
    }
    else{
      if(this.openedLotIndex!=null) this.lots.sousLots[this.openedLotIndex].open=false;
      this.lots.sousLots[j].open=true;
      this.lots.sousLots[j].loading=true;
    }
    this.archiDbService.getLot(lotId).subscribe(
      (data)=>{
        this.newLots=data;
        if(this.firstTime){
          this.currentLot.emit(this.lots[j]);
          this.currentLotChild=this.lots[j];
          this.lots[j].loading=false;
        }
        else{
          this.currentLot.emit(this.lots.sousLots[j]);
          this.currentLotChild=this.lots.sousLots[j];
          this.lots.sousLots[j].loading=false;
        }
        this.openedLotIndex=j;
        
        this.cdr.detectChanges();
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  sendPostToLot(lot,j){
    if(this.firstTime) this.lots[j].loadingPost=true;
    else this.lots.sousLots[j].loadingPost=true;
    
    if(this.moveMany){
      let postes=[];
      for(let i=0;i<this.lot.sousLots.length;i++){
        let newPost;
        for(let j=0;j<this.lot.sousLots[i].postes.length;j++){
          if(this.lot.sousLots[i].postes[j].selected==true){
            let post=this.lot.sousLots[i].postes[j];
            newPost=post;
            newPost.title=post.designation;
            newPost.quantity=post.qte;
            newPost.puht=post.unitePriceArchi;
            newPost.unity=post.unite;
            newPost.lotTitle=lot.title;
            postes.push(post);
          }
        }
      }
      this.archiDbService.createMultiPost(lot._id,{posts:postes,lot:lot}).subscribe(
        (data)=>{
          if(this.firstTime) this.lots[j].loadingPost=false;
          else this.lots.sousLots[j].loadingPost=false;
          $('#import-post').modal('toggle')
          this.cdr.detectChanges();
          this.store.dispatch(actions.setExportPostsDb({manyPost:false,setSelected:true}));
          this.messageService.add({ key: 'toast', severity: 'success', summary: "Export d'un poste", detail: "Le(s) poste(s) ont bien été exportés", life: 5000 });
          // segment event
          if(!environment.onPremise)window.analytics.track('export_to_database',{properties:{acValue: 'export_to_database'}});
        },
        (err)=>{
          this.store.dispatch(actions.setExportPostsDb({manyPost:false}));
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Export d'un poste", detail: "Erreur serveur", life: 5000 });
        }
      )
    }
    else {
      let newPost=this.post;
      if(this.isDPGF){
        newPost.title=this.post.designation;
        newPost.quantity=this.post.qte;
        newPost.puht=this.post.unitePriceArchi;
        newPost.unity=this.post.unite;
        newPost.lotTitle=lot.title;
        if(this.post?.sousPostes?.length>0)newPost.sousPostes = this.post.sousPostes;
      }
      // newPost._id='';
      this.archiDbService.createPost(lot._id,newPost).subscribe(
        (data)=>{
          if(this.firstTime) this.lots[j].loadingPost=false;
          else this.lots.sousLots[j].loadingPost=false;
          $('#import-post').modal('toggle')
          this.cdr.detectChanges();
          this.messageService.add({ key: 'toast', severity: 'success', summary: "Export d'un poste", detail: "Le poste a bien été importé", life: 5000 });
          // segment event
          if(!environment.onPremise)window.analytics.track('export_to_database',{properties:{acValue: 'export_to_database'}});
        },
        (err)=>{
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Export d'un poste", detail: "Erreur serveur", life: 5000 });
        }
      )
    }
  }
  sendPostsToLot(lot,j){
    
  }
  sendLotToLot(lot){
    this.chosenLotEvent.emit(lot);
  }
  setChosenLot(lot){
    if(!this.firstTime)this.chosenLotEvent1.emit(lot);
    else this.sendLotToLot(lot);
  }
  sendToFather(event){
  }
  sendPostToMonBDD(){
    this.chosenLotEvent.emit('MonBdd');
  }
  addFolder(event,type){
    let lots=[],idParent;
    this.firstTime? lots=this.lots : lots=this.lots.sousLots
    lots.length? idParent=lots[0].parentLot : idParent=this.currentLotChild._id
    
    if(type=='enter') {
      let lotData={title:event.target.value,parentLot:idParent}
      this.archiDbService.createLot(lotData).subscribe(
        data=>{
          data.loading=false;data.loadingPost=false;data.open=false;
          this.firstTime? this.lots.push(data):this.lots.sousLots.push(data);
          this.cdr.detectChanges();
        }
      )
      this.newFolderName='';
    }  
    this.writingFolder=false;
  }
  public navigateArchiDb(){
    this.router.navigate(['/architect/archi-db']);
    $('#import-post').modal('hide');
  }
}
