<div class="modal fade" id="confirm-remove-lot-modal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="title">Supprimer le lot ?</h6>
      </div>
      <div class="modal-body">
        <p>
          Cette action supprimera définitivement le lot <b> {{ lotTitle }}, êtes-vous sûr(e) de vouloir continuer ? </b>
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-cancel ag-btn-tertiary-sm margin-right-16" data-dismiss="modal">Annuler</button>
        <button type="button" class="btn-accept ag-btn-tertiary-sm" data-dismiss="modal" (click)="emitConfirmRemoveLot()">
          <img src="/assets/remove2-icon.svg" class="margin-right-12" alt="">
          Oui, supprimer le lot
        </button>
      </div>
    </div>
  </div>
</div>
