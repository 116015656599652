<div class="all">
  <app-header [cssClass]="'fixed-bar'"></app-header>
  <app-ce-project-nav [disable]="loadingData"
                    [projectId]="projectId"
                    selected="payement"
                    [devisNumber]="0"
                    [DPGFId]="(project.dpgf||{})._id">
</app-ce-project-nav>

<div class="container loading-page" *ngIf="loadingData">
  <div style="margin-top:70px;">
    <app-spinner></app-spinner>
  </div>
</div>

    <div class="container-box">
        <div class="float-switch">
          <label class="switch">
            <span class="switch-label">Entreprises</span>
            <div class="checkbox-cont">
              <input type="checkbox" [(ngModel)]="view">
              <span></span>
            </div>
            <span class="switch-label lots-label">Lots</span>
          </label>
          <div class="switch-label">
          </div>
        </div>

        <div [ngClass]="{hide: view}">
          <div class="col-md-4">
            <div class="add-enterprise-container">
              <div class="col-md-12">
                  <div class="content-wrapper">
                    <span class="entreprise"><h3>Entreprises retenues</h3></span>
                    <div *ngIf="loadingData">
                      <app-spinner></app-spinner>
                    </div>
                    <div class="content-list" *ngIf="invites.length != 0 && !loadingData">
                      <div class="single-artisan-card clearfix"
                          [ngClass]="{'chosen-artisan' : activeInvite._id == invite._id}"
                          *ngFor="let invite of invites"
                          (click)="selectInvite(invite)">
                        <div class="img-wrap">
                          <img class="img-ese" [src]="invite.artisan.logo != undefined ? invite.artisan.logo.cdnUrl : 'assets/rob/helmet.png'" />
                        </div>
                        <div class="text-wrap">
                          <p *ngIf="invite.isFactice">{{ invite?.facticeArtisanInfo?.name || invite?.artisanName }}</p>
                          <p *ngIf="!invite.isFactice">{{ invite.artisan.name }}</p>
                        </div>
                        <div *ngIf="invite.status == 'signe'" class="signed-icon">
                          <i class="fa fa-check-square-o"></i>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="invites.length == 0 && !loadingData" class="alert alert-info">
                      Vous n'avez pas encore sélectionné les entreprises retenues !
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="col-md-8" *ngIf="!loadingData">

            <div *ngIf="(!view && !artisanSelected) || (view && !lotSelected)" class="situation-pic">
              <img src="assets/rob/empty_invoice.svg" alt="" height="400px" width="400px">
            </div>

            <ng-container *ngIf="artisanSelected || lotSelected">

              <div class="floating-card content-wrapper" *ngIf="activeInvite.status == 'signe'">
                <div class="tags">
                  <h4 class="tag-title">Lot(s) pris en compte par l'entreprise</h4>
                  <ng-container *ngFor="let lot of activeInvite.signedLots">
                    <span class="tag" *ngIf="lot.signed">
                      <img class="lot-icon" [src]="lot.icon !='' ? '/assets/dpgf/Icon_DPGF/'+lot.icon : '/assets/rob/helmet.png'" />
                      {{ lot.title }}
                    </span>
                  </ng-container>
                </div>
                <hr>
                <div *ngIf="!loadingFactures && artisanSelected">
                  <app-facture-list [factures]="inviteFactures" owner="architect"></app-facture-list>
                </div>
                <div *ngIf="loadingFactures">
                  <app-spinner></app-spinner>
                </div>
                <div  *ngIf="!loadingFactures && inviteFactures.length == 0" class="alert alert-info no-situation-msg flex-container">
                  L'artisan ne vous a pas encore envoyé de situation.
                  <button (click)="confirmUnsign()">Annuler la signature</button>
                  <!-- @mohcine  idée il faut ajouter un bouton "Inviter l'artisan à créer une situation" qui envoie un mail à l'artisan en question avec le lien de l'interace dans AGLO -->
                </div>
              </div>

              <div class="floating-card content-wrapper" *ngIf="activeInvite.status == 'selectionne'">
                <h4>Veuillez sélectionner les lots pour lequels vous voulez signer avec {{ activeInvite.artisan.name }} !</h4>
                <hr>
                <div class="alert alert-info" role="alert" *ngIf="activeInvite.pricingStatus == 'pricing-sent'">
                  Attention, cette entreprise est en cours de chiffrage, vous vous apprêter à signer pour une ancienne version de DCE !
                </div>
                <div class="content-list">
                  <div class="single-lot-card clearfix" *ngFor="let lot of lotsToSign">
                  <!-- @mohcine  il faut afficher tout les lots possible et mettre une petite étoile à coté des lots sur les quel l'entreprise a été préinvité ! à Valider avec Boris -->
                      <div class="info-img-text-wrap" [ngClass]="{'already-signed': lot.alreadySigned}" (click)="chooseLot(lot)">
                        <div class="lot-check" (click)="chooseLot(lot)">
                          <p-checkbox binary="true" [(ngModel)]="lot.signed"></p-checkbox>
                        </div>
                        <div class="lot-icon">
                          <img [src]="lot.icon ? '/assets/dpgf/Icon_DPGF/'+lot.icon : '/assets/rob/helmet.png'" />
                        </div>
                        <div class="lot-name">
                          <p>{{ lot.title }}</p>
                          <span *ngIf="lot.invited" class="inv-sign"><i class="fa fa-check-circle"></i></span>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <button class="btn-primary-color" (click)="confirmSignature()" [disabled]="isDemoProject">
                    <div *ngIf="!signing">Signer</div>
                    <app-spinner *ngIf="signing"></app-spinner>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>

        </div>

        <!--          lots signés         -->
        <div [ngClass]="{hide: !view}">
          <div class="col-md-4">
            <div class="add-enterprise-container">
              <div class="col-md-12">
                  <div class="content-wrapper">
                    <span class="entreprise"><h3>Lots signés</h3></span>
                    <div *ngIf="loadingData">
                      <app-spinner></app-spinner>
                    </div>
                    <div class="content-list" *ngIf="signedLots.length != 0 && !loadingData">
                      <div class="single-artisan-card clearfix"
                          [ngClass]="{'chosen-artisan' : activeLot.title == lot.title}"
                          *ngFor="let lot of signedLots"
                          (click)="selectLot(lot)">
                        <div class="img-wrap">
                          <img class="img-lot" [src]="lot.icon !='' ? '/assets/dpgf/Icon_DPGF/'+lot.icon : '/assets/rob/helmet.png'" />
                        </div>
                        <div class="text-wrap">
                          <p>{{ lot.title }}</p>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="signedLots.length == 0 && !loadingData" class="alert alert-info">
                      Vous n'avez aucun lot signé !
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="col-md-8">

            <div *ngIf="(!view && !artisanSelected) || (view && !lotSelected)" class="situation-pic">
              <img src="assets/rob/empty_invoice.svg" alt="" height="400px" width="400px">
            </div>

            <ng-container *ngIf="lotSelected">

              <div class="floating-card content-wrapper">
                <div class="ese">
                  <table class="recap-table">
                    <tr>
                      <td class="trecap">Entreprise</td>
                      <td class="trecap">Total HT</td>
                      <td class="trecap">Total Payé HT</td>
                    </tr>
                    <tr>
                      <td class="precap">
                        <div class="artisan-card-lot">
                          <img [src]="activeLot.artisan.logo != '' ? activeLot.artisan.logo : 'assets/rob/helmet.png'" />
                          <h5 class="artisan-name">{{ activeLot.artisan.name }}</h5>
                        </div>
                      </td>
                      <td class="crecap">
                        {{ activeLot.total | number }} €
                      </td>
                      <td class="ccrecap">
                        <div *ngIf="!activeLot.graphicData; else graph"> - </div>
                        <ng-template #graph>
                          {{ activeLot.graphicData[activeLot.graphicData.length-1].devis | number }} €
                        </ng-template>
                      </td>
                    </tr>
                  </table>
                  <hr *ngIf="!activeLot.graphicData">
                </div>
                <div class="row" *ngIf="activeLot.graphicData">
                  <app-facture-graphic [graphicData]="activeLot.graphicData"></app-facture-graphic>
                </div>
                <div *ngIf="!activeLot.graphicData" class="alert alert-info no-situation-msg">
                  <strong>Info!</strong> Ce lot n'a pas encore commencé !
                </div>
              </div>
            </ng-container>
          </div>
        <div>
    </div>
</div>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Oui" acceptButtonStyleClass="oui-version" 
rejectLabel="Non" #cd>
  <!-- <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Oui" (click)="cd.accept()"></button>
</p-footer> -->
</p-confirmDialog>

<!-- Need Subscriiption -->
<app-need-subscription-popup-2></app-need-subscription-popup-2>
