import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { Router } from '@angular/router';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $ : any;

@Component({
    standalone:true,
    selector: 'app-need-subscription-popup',
    imports:[NgSharedModule],
    templateUrl: './need-subscription-popup.component.html',
    styleUrls: ['./need-subscription-popup.component.scss'],
})
export class NeedSubscriptionPopupComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  redirectToPricingTable(){
    $('#need-subscription-max-premium-modal').modal('hide');
    $('#need-subscription-premium-modal').modal('hide');
    $('.modal').modal('hide'); /// hide all modals
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
  }

}
