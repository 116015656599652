import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AllModule } from '../all.module';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone:true,
    selector: 'app-error',
    imports:[
      HeaderComponent,
      NgSharedModule
    ],
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],

})
export class ErrorComponent implements OnInit {
  public returnUrl = ''
  constructor(private router:Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.returnUrl = params['returnUrl']||"";
      });
  }
  public goBack(){
    this.router.navigate([this.returnUrl]);
  }
}
