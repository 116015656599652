import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone:true,
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value:string) {
      if (value) {
          var numbers = value.split("");
          var result = "";
          for (let i = 0; i < numbers.length; i=i+2) {
              result = result + numbers[i] + (numbers[i+1]||"") + " ";
          }
          return result;
      }
      return value;
  }
}
