<div class="container loading-page" *ngIf="loadingSub" >
  <div style="margin-top:70px;">
    <!-- <app-spinner></app-spinner> -->
  </div>
  <br><br>
</div>
<div *ngIf="!loadingSub" style="margin-top:0;">
<!-- <div  *ngIf="disableBtnLicence" style="display: flex;justify-content:center;">
  <div class="flex" style="background-color:#F7D9D9;color:red;font-size:14px;width: fit-content;">
    <img src="assets/error-circle-red.svg" style="margin-left:8px;margin-right:8px;margin-bottom: 8px;">
    <div style="margin-top: 7px;margin-bottom:7px;" >
     Le paiement lié à votre prise d’abonnement nécessite une <p>validation, confirmez le paiement en cliquant <a [href]="disableBtnLicence" style="margin-right:5px;margin-left:5px;text-decoration: underline;color:#0275D8;">ici</a></p>
    </div>
    <img src="assets/close-red.svg" style="margin-left:8px;margin-right:8px;margin-bottom: 8px;">
  </div>
</div> -->
<div class="row my-licence">
  <div class="row title-container">
    <p class="main-title-licence">Licence</p>
    <div style="font-size:14px;color:rgba(10, 23, 71, 1);">
      <div style="padding-bottom: 34px;">
        <p *ngIf="!dateEndTrial&&licenceC=='without'"> Vous n’avez aucun abonnement en cours.</p>
        <p *ngIf="licenceC!='without'&&!archiSub?.cancel_at_period_end&&!dateEndTrial"> Vous bénéficiez de la licence <span style="font-weight: 700;color:rgba(56, 5, 242, 1);"> {{licences[licenceC]}}</span>  jusqu’au {{endDateLicence||periodEnd}}.</p>
        <p *ngIf="licenceC!='without'&&!archiSub?.cancel_at_period_end&&dateEndTrial"> Vous bénéficiez de la licence <span style="font-weight: 700;color:rgba(56, 5, 242, 1);"> Essai gratuit</span>  jusqu’au {{dateEndTrial}}.</p>
        <p *ngIf="licenceC!='without'&&archiSub?.cancel_at_period_end">Vous avez résilié votre licence <span style="font-weight: 700;color:rgba(56, 5, 242, 1);"> {{licences[licenceC]}}</span>. Elle reste active jusqu’au {{endDateLicence||periodEnd}}. </p>
      </div>
      <div style="padding-bottom: 16px;">
        <p *ngIf="licenceC=='without'"> Vous souhaitez passer au niveau supérieur ?</p>
        <p *ngIf="licenceC!='without'"> Vous souhaitez changer d’offre ?</p>
      </div>
        <div class="btn-container" style="padding-bottom: 32px;">
          <button class="ag-btn-primary change-btn"  (click)="showLicenceModal()"[disabled]="disableBtnLicence3ds">Voir les licences</button>
          <!-- <button (click)="openPortal()">To Portal</button> -->
        </div>  
        <div class="btn-container" *ngIf="!dateEndTrial && licenceC!='without'&&!archiSub?.cancel_at_period_end" style="padding-bottom: 85px;">
          <p> Voulez-vous résilier votre abonnement ?</p>
          <button  class="ag-btn-secondary" (click)="stopLicence()" [disabled]="disableBtnLicence">Arrêter votre abonnement</button>
        </div>
    </div>
  </div>
<!--   
  <div *ngIf="!dateEndTrial" class="row licence-price">
    <h4 *ngIf="licenceC=='plus' || licenceC=='premium' || licenceC=='premiumn' || licenceC=='max'" class="table-price"><span>{{price}} €</span> HT / mois / personne</h4>
    <h4 *ngIf="licenceC=='premium-annuel'" class="table-price"><span>{{price*12}} €</span> HT / an / personne</h4>
    <h4 *ngIf="licenceC=='plus-annuel'" class="table-price"><span>{{price*12}} €</span> HT / an / personne</h4>
    <h4 *ngIf="licenceC=='premiumn-annuel'" class="table-price"><span>{{price*12}} €</span> HT / an / personne</h4>
    <h4 *ngIf="licenceC=='max-annuel'" class="table-price"><span>{{price*12}} €</span> HT / an / personne</h4>
    <h4 *ngIf="licenceC=='base'" class="table-price"><span>0 €</span> HT / mois / personne</h4>

  </div> -->
</div>

<!-- Table Title -->

<div class="container loading-page" *ngIf="loadinglicence">
  <div style="margin-top:70px;">
    <!-- <app-spinner></app-spinner> -->
  </div>
</div>

<div id="change-licence" class="modal fade" role="dialog" aria-hidden="true">
  <div *ngIf="stepPayment=='first'" class="modal-dialog" style="width: 1200px;background-color: #E5E5E5;">
    <button type="button" class="close close-btn" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <div >
      <div class="padding-left-70 padding-top-16">
        <span class="title-modal">Nos licences</span>
        <p class="text-descriptif margin-bottom-0 padding-bottom-28">Des tarifs adaptés à votre usage et à la taille de votre équipe</p>
      </div>
      <div class="flex" style="justify-content: space-around;">
      <div>
        <div class="padding-8 color-white background-color-blue margin-bottom-34 border-radius-32 width-fit-content">
          <span >Engagement annuel</span>
        </div>
        <p class="text margin-bottom-0"> <img class="padding-right-10 margin-top-2" src="assets/card-bancaire.svg">Règlement par IBAN ou CB</p>
        <p class="text margin-bottom-0"> <img class="padding-right-10 margin-top-2" src="assets/4-time-payment-icone.svg">Paiement en 4 fois</p>
      </div>
      <div class="font-size-14">
        <span class="licence-name flex justify-center padding-bottom-12">AGLO Pro</span>
        <p class="font-size-20 color-gray margin-bottom-0"><span style="font-size:32px;font-weight: 800;color:black">{{truncNumber(licencesPrices['plus-annuel'])}} €</span> HT/an</p>
        <p class="color-gray margin-bottom-0">par utilisateur</p>
        <p class="color-gray margin-bottom-0">Engagement annuel</p>
        <button class="button-choose-licence margin-top-12" *ngIf="licence!='plus-annuel'&&licence!='plus-trim'" [disabled]="licence=='plus-annuel'||licence=='plus-trim'" (click)="navigateToPaymentModalPart('second',licencesPrices['plus-annuel'],'plus-annuel')">Choisir cette licence</button>
        <button class="button-choose-licence margin-top-12" *ngIf="licence=='plus-annuel'||licence=='plus-trim'" [disabled]="licence=='plus-annuel'||licence=='plus-trim'" >Licence active</button>

      </div>
      <div class="font-size-14">
        <span class="licence-name flex justify-center padding-bottom-12">AGLO Max</span>
        <p class="font-size-20 color-gray margin-bottom-0"><span style="font-size:32px;font-weight: 800;color:black">{{truncNumber(licencesPrices['max-annuel'])}} €</span> HT/an</p>
        <p class="color-gray margin-bottom-0">par utilisateur</p>
        <p class="color-gray margin-bottom-0">Engagement annuel</p>
        <button class="button-choose-licence margin-top-12" *ngIf="licence!='max-annuel'&&licence!='max-trim'" [disabled]="licence=='max-annuel' ||licence=='max-trim'" (click)="navigateToPaymentModalPart('second',licencesPrices['max-annuel'],'max-annuel')">Choisir cette licence</button>
        <button class="button-choose-licence margin-top-12" *ngIf="licence=='max-annuel'||licence=='max-trim'" [disabled]="licence=='max-annuel'||licence=='max-trim'" >Licence active</button>

      </div>
      <div class="font-size-14" style="z-index: 1000;">
        <span class="licence-name flex justify-center padding-bottom-12" >AGLO Intégral</span>
        <p class="font-size-20 color-gray margin-bottom-0"><span style="font-size:32px;font-weight: 800;color:black">{{truncNumber(licencesPrices['premiumn-annuel'])}} €</span> HT/an</p>
        <p class="color-gray margin-bottom-0">par utilisateur</p>
        <p class="color-gray margin-bottom-0">Engagement annuel</p>
        <button class="button-choose-licence margin-top-12" *ngIf="licence!='premiumn-annuel'&&licence!='premiumn-trim'&&licence!='premium'&&licence!='premium-annuel'" [disabled]="licence=='premiumn-annuel'||licence=='premiumn-trim'||licence=='premium'||licence=='premium-annuel'" (click)="navigateToPaymentModalPart('second',licencesPrices['premiumn-annuel'],'premiumn-annuel')">Choisir cette licence</button>
        <button class="button-choose-licence margin-top-12" *ngIf="licence=='premiumn-annuel'||licence=='premiumn-trim'||licence=='premium'||licence=='premium-annuel'" [disabled]="licence=='premiumn-annuel'||licence=='premiumn-trim'||licence=='premium'||licence=='premium-annuel'" >Licence active</button>
      </div>
      <div class="border-licence-more-choosen">
        <div style="position: absolute;bottom: 0;top: -11px;right: 21%;background: rgba(56, 5, 242, 1);height:26px;width:126px;border-radius: 32px;color:white;padding-top: 2px;text-align: center;">
     PLUS PRISÉ
        </div>
      </div>
     </div>
     <div>
      <span class="essentiel">Les essentiels</span>
      <div class="padding-top-10">
        <table class="table color-blue font-size-14" style="margin-left:75px;width:92%">
          <tbody>
            <tr></tr>
            <tr>
              <td class="wid-27">Nombre de postes par projet
                <div class="tooltip" [ngStyle]="{'z-index': '1100'}">
                  <img class="margin-bottom-4 margin-left-10" src="/assets/help-icone.svg">
                  <span class="tooltiptext">Créez un dossier projet pour chacun de vos chantiers et centralisez toutes vos informations et documents au même endroit.</span>
                </div>
              </td>
              <td class="wid-23 center padding-right-56">50</td>
              <td class="wid-27 center padding-right-28">Illimité</td>
              <td class="wid-23 center">Illimité</td>
            </tr>
            <tr>
              <td class="wid-27">Capacité de la bibliothèque personnelle
                <div class="tooltip" [ngStyle]="{'z-index': '1050'}">
                  <img class="margin-bottom-4 margin-left-10" src="/assets/help-icone.svg">
                  <span class="tooltiptext">Créez votre propre base de données de descriptifs techniques sur votre bibliothèque AGLO. Enregistrez vos postes et descriptifs techniques, à retrouver facilement pour tous vos projets.</span>
                </div>
              </td>
              <td class="wid-23 center padding-right-56">50</td>
              <td class="wid-27 center padding-right-28">Illimité</td>
              <td class="wid-23 center">Illimité</td>
            </tr>
            <tr>
              <td class="wid-27">Format des exports
                <div class="tooltip" [ngStyle]="{'z-index': '1000'}">
                <img class="margin-bottom-4 margin-left-10" src="/assets/help-icone.svg">
                <span class="tooltiptext">Exportez et téléchargez vos pièces écrites et estimatifs budgétaires sous différents formats.</span>
                </div>
              </td>
              <td class="wid-23 center padding-right-56">PDF</td>
              <td class="wid-27 center padding-right-28">PDF / WORD / EXCEL</td>
              <td class="wid-23 center">PDF / WORD / EXCEL</td>
            </tr>
            <tr>
              <td class="wid-27">Consultation d’entreprises
              <div class="tooltip" [ngStyle]="{'z-index': '950'}">
                <img class="margin-bottom-4 margin-left-10" src="/assets/help-icone.svg">
                <span class="tooltiptext">Gérez en ligne toutes vos consultations d'entreprises, du lancement à l'analyse des offres, jusqu'à la sélection.</span>
              </div>
              </td>
              <td class="wid-23 center padding-right-56"><img src="/assets/checked.svg"></td>
              <td class="wid-27 center padding-right-28"><img src="/assets/checked.svg"></td>
              <td class="wid-23 center"><img src="/assets/checked.svg"></td>
            </tr>
            <tr>
              <td class="wid-27">AGLO GPT
                <div class="tooltip" [ngStyle]="{'z-index': '900'}">
                  <img class="margin-bottom-4 margin-left-10" src="/assets/help-icone.svg">
                  <span class="tooltiptext">Générez automatiquement vos descriptifs grâce à notre intelligence artificielle </span>
                </div>
              </td>
              <td class="wid-23 center padding-right-56"><img src="/assets/close.svg"></td>
              <td class="wid-27 center padding-right-28"><img src="/assets/checked.svg"></td>
              <td class="wid-23 center"><img src="/assets/checked.svg"></td>
            </tr>
            <tr>
              <td class="wid-27">Accès aux entreprises <span class="font-weight-600"> AGLO BOOSTER©</span>
                <div class="tooltip" [ngStyle]="{'z-index': '800'}">
                  <img class="margin-bottom-4 margin-left-10" src="/assets/help-icone.svg">
                  <span class="tooltiptext">Bénéficiez d'un réseau de plus de 3000 artisans et entreprises spécialisés, partout en France, recommandés par vos confrères. Décrivez-nous votre projet, on s'occupe du reste.</span>
                </div>
              </td>
              <td class="wid-23 center padding-right-56"><img src="/assets/checked.svg"></td>
              <td class="wid-27 center padding-right-28"><img src="/assets/checked.svg"></td>
              <td class="wid-23 center"><img src="/assets/checked.svg"></td>
            </tr>
            <tr>
              <td class="wid-27"><img class="margin-right-10 margin-bottom-4" src="/assets/green-circle-add-icone.svg">Module <span class="font-weight-600"> d’Analyse de Cycle de Vie</span>
                <div class="tooltip" [ngStyle]="{'z-index': '750'}">
                  <img class="margin-bottom-4 margin-left-10" src="/assets/help-icone.svg">
                  <span class="tooltiptext">Réalisez l'Analyse de Cycle de Vie de votre projet. Calculez son impact carbone et obtenez, en fonction de vos résultats, l'attestation RE2020 directement via AGLO.</span>
                </div>
              </td>
                <td class="wid-23 center padding-right-56"><img src="/assets/close.svg"></td>
              <td class="wid-27 center padding-right-28"><img src="/assets/checked.svg"></td>
              <td class="wid-23 center"><img src="/assets/checked.svg"></td>
            </tr>
            <tr>
              <td class="wid-27"><img class="margin-right-10 margin-bottom-4" src="/assets/green-circle-add-icone.svg">Accès au <span class="font-weight-600">CSTB</span>
                <div class="tooltip" [ngStyle]="{'z-index': '700'}">
                  <img class="margin-bottom-4 margin-left-10" src="/assets/help-icone.svg">
                  <span class="tooltiptext">Profitez d'une base de données de plus 40 000 descriptifs techniques et 35 généralités pré-rédigés et mis à jour automatiquement. Importez-les dans votre DCE et rédigez vos pièces écrites en un clic.</span>
                </div>
              </td>
              <td class="wid-23 center padding-right-56"><img src="/assets/close.svg"></td>
              <td class="wid-27 center padding-right-28"><img src="/assets/close.svg"></td>
              <td class="wid-23 center"><img src="/assets/checked.svg"></td>
            </tr>
            <tr></tr>
          </tbody>
        </table>
      </div>
      <hr>
     </div>
     </div>
    <!-- </div> -->
  </div>
  <div *ngIf="stepPayment=='second'"class="modal-dialog" style="width: 600px;background-color: #E5E5E5;">
    <button type="button" class="close close-btn" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <div>
      <p class="second-step-title">Modalité de paiement</p>
      <div class="flex justify-center">
        <div>
        <p class="text-descriptif2">Passez au niveau supérieur et gagner du temps</p>
        <div class="flex justify-center" style="padding-bottom:48px;">
          <div class="font-size-14" style="border:2px solid rgba(219, 221, 225, 1);width:216px;height:110px;border-radius: 8px;">
            <p class="licence-name margin-bottom-0 padding-top-12" style="padding-left:16px;">{{licences[licence]}}</p>
            <p class="margin-bottom-0" style="padding-left:16px;"> <span style="font-size:32px;font-weight: 800;color:black"> {{licencesPrices[licence.includes('trim')?licence.replace('trim','annuel'):licence]}}</span> € HT/an</p>
            <p class="color-gray margin-bottom-0"style="padding-left:16px;">par utilisateur</p>
          </div>
          <div class="flex" style="align-content: center;">
            <img style="padding-right: 26px;padding-left: 12px;width: 83px;" src="assets/arrow-right-modal.svg" alt="">
          </div>
          <div style="border:2px solid rgba(56, 5, 242, 1);width:216px;border-radius: 8px;">
            <p class="licence-name margin-bottom-0 padding-top-12" style="padding-left:16px;">{{licences[newChoosenPlan]}}</p>
            <p class="margin-bottom-0" style="padding-left:16px;"><span style="font-size:32px;font-weight: 800;color:black"> {{stepPrice}}</span> € HT/an</p>
            <p class="color-gray margin-bottom-0" style="padding-left:16px;">par utilisateur</p>
          </div>
        </div>
      </div>
      </div>
      <div style="padding-right:24px;padding-left:24px">
        <div class="flex space-between" [ngStyle]="{'color':typePayment=='cb'?'rgba(10, 23, 71, 1)':'rgba(124, 138, 184, 1)'}">
          <div>
            <input class="cursor" style="width: 17px;height: 17px;" type="radio" id="huey" name="cb" value="cb" [(ngModel)]="typePayment" (change)="trimestrielOrAnnuel()">
            <span style="position:absolute;margin-right: 15.5px;margin-top: 3px;padding-left: 12px;" >Comptant</span>
          </div>
          <span style="margin-top: 3px;"> {{truncNumber(stepPrice*(1+currentTva.percentage/100))}} € TTC</span>
        </div>
        <div>
          <div class="flex space-between" style="padding-bottom:24px;"[ngStyle]="{'color':typePayment=='sepa'?'rgba(10, 23, 71, 1)':'rgba(124, 138, 184, 1)'}">
            <div>
              <input class="cursor" style="width: 17px;height: 17px;" type="radio" id="huey" name="iban" value="sepa" [(ngModel)]="typePayment" (change)="trimestrielOrAnnuel()">
              <span style="position:absolute;margin-right: 15.5px;margin-top: 3px;padding-left: 12px;" >Paiement en 4 fois sans frais</span>
            </div>
            <span style="margin-top: 3px;"> {{truncNumber((stepPrice*(1+currentTva.percentage/100))/4)}} € TTC x 4 </span>
          </div>
          <div *ngIf="typePayment=='sepa'" style="width: 539px;height:153px;background-color: rgba(236, 236, 236, 1);padding:19px;">
            <div class="flex" style="justify-content: space-around;" >
              <div> 
                <span style="font-size:17px;font-weight: 700;">
                  {{truncNumber(stepPrice*(1+currentTva.percentage/100)/4)}} € TTC
                </span>
                <p>Aujourd’hui</p>
                <span style="width: 8px;height: 8px;background: blue;border-radius: 8px;display: block;position:relative;background-color: rgba(10, 23, 71, 1);"></span>
              </div>
              <div> 
                <span style="font-size:17px;font-weight: 700;">
                  {{truncNumber(stepPrice*(1+currentTva.percentage/100)/4)}} € TTC
                </span>
                <p>Dans 3 mois</p>
                <span style="width: 8px;height: 8px;background: blue;border-radius: 8px;display: block;background-color: rgba(10, 23, 71, 1);"></span>
              </div>
              <div> 
                <span style="font-size:17px;font-weight: 700;">
                  {{truncNumber(stepPrice*(1+currentTva.percentage/100)/4)}} € TTC
                </span>
                <p>Dans 6 mois</p>
                <span style="width: 8px;height: 8px;background: blue;border-radius: 8px;display: block;background-color: rgba(10, 23, 71, 1);"></span>
              </div>
              <div> 
                <span style="font-size:17px;font-weight: 700;">
                  {{truncNumber(stepPrice*(1+currentTva.percentage/100)/4)}} € TTC
                </span>
                <p>Dans 9 mois</p>
                <span style="width: 8px;height: 8px;background: blue;border-radius: 8px;display: block;background-color: rgba(10, 23, 71, 1);"></span>
              </div>  
  
            </div>
            <div style="font-size:12px;color:rgba(10, 23, 71, 1);padding-left: 51px;padding-top: 24px;">
              <p style="margin: 0;display:list-item;">Obtenez votre licence aujourd’hui, payez tous les trois mois.</p>
              <p style="margin: 0;display:list-item;">Aucun frais. Prélèvement automatique.</p>       
            </div>
          </div>
          <div *ngIf="typePayment=='sepa'" class="line-hori"  [ngStyle]="{'top': couponOff?.percent_off || couponOff?.amount_off || couponValid? paymentMethod?.type=='card' || (!paymentMethod&&customerId)? '51.1%':'52.9%': paymentMethod?.type=='card' || (!paymentMethod&&customerId)?'53.2%':'55.1%' }"></div>
        </div>
        <hr style="border-color:rgba(219, 221, 225, 1);">
        <div>
          <p>Code promotionnel</p>
          <input (focusout)="checkCoupon()"[(ngModel)]="coupon" style="position: relative;width:172px;height:35px;border-radius:8px;background-color: white !important;margin-right: 10px;border:1px solid rgba(219, 221, 225, 1);padding-left: 46px;">
          <button (click)="checkCoupon()" class="ag-btn-bordered-xs" style="padding-left: 15px;padding-right: 15px;">Appliquer</button>
          <img src="assets/code-promotionnel.svg" alt="" style="position:absolute;left:33px;" [ngStyle]="{'bottom': typePayment=='sepa' ? couponOff?.percent_off || couponOff?.amount_off || couponValid? paymentMethod?.type=='card' || (!paymentMethod&&customerId)?'242px': '211px': paymentMethod?.type=='card' || (!paymentMethod&&customerId)?'209Px':'179px' : couponOff?.percent_off || couponOff?.amount_off || couponValid?'144px':'112px'}">
          <p *ngIf="couponOff?.percent_off" style="padding-top: 12px;"><img src="assets/validate-icon.svg"> {{couponOff?.percent_off}}% de réduction - Calculé à la prochaine étape</p>
          <p *ngIf="couponOff?.amount_off" style="padding-top: 12px;"><img src="assets/validate-icon.svg"> {{couponOff?.amount_off/100}}€ de réduction - Calculé à la prochaine étape</p>
          <p *ngIf="couponValid" style="padding-top: 12px;"><img src="assets/close-red.svg"> Ce code promo n’est pas valide. Vérifiez et réessayer.</p>
        </div>
        <hr style="border-color:rgba(219, 221, 225, 1);">
        <div>
          <div *ngIf="typePayment=='sepa'">
            <div class="flex">
            <img src="assets/info-icon.svg" style="padding-right: 5px;">
            <p  style="line-height: 17px;font-size:14px;margin-top: 9px;padding-left: 10px;">Le mode de paiement en 4 fois se fait uniquement par prélevement SEPA sécurisé.</p>
            </div>
          <p *ngIf="paymentMethod?.type=='card' || (!paymentMethod && customerId)" (click)="resetCard()" style="text-decoration: underline;color:blue;cursor:pointer;padding-left: 33px;">Renseigner mon IBAN</p>
          </div>
          <div class="flex space-between" [ngStyle]="{'padding-top':typePayment=='sepa'?'30px':'0'}">
            <div (click)="goToLicences('first')" class="cursor">
              <img style="margin-bottom: 3px;padding-right:12px;" src="assets/chevron-left.svg" alt=""> 
              <span>Voir les licences</span>
            </div>
            <div style="padding-bottom:29px" *ngIf="typePayment=='sepa'">
              <button *ngIf="!paymentMethod||paymentMethod?.type=='card'" style="border-radius: 4px;background-color: rgba(10, 23, 71, 1);color:white;padding-bottom: 6px;padding-top: 6px;padding-right: 24px;padding-left: 24px;border:none;" [disabled]="paymentMethod&&paymentMethod.type!='sepa_debit' " (click)="goToLicences('third')">Valider</button>
              <button  *ngIf="paymentMethod?.type=='sepa_debit'"style="border-radius: 4px;background-color: rgba(10, 23, 71, 1);color:white;padding-bottom: 6px;padding-top: 6px;padding-right: 24px;padding-left: 24px;border:none;" [disabled]="newChoosenPlan==licence" (click)="goToLicences('third')">Valider </button>
            </div>
            <div style="padding-bottom:29px" *ngIf="typePayment=='cb'">
              <button style="border-radius: 4px;background-color: rgba(10, 23, 71, 1);color:white;padding-bottom: 6px;padding-top: 6px;padding-right: 24px;padding-left: 24px;border:none;" [disabled]="newChoosenPlan==licence" (click)="goToLicences('third')">Valider</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="stepPayment=='third'"class="modal-dialog" style="width: 576px;background-color: #E5E5E5;padding:24px;">
    <div style="padding-bottom:16px;">
      <p style="font-size:24px;font-weight: 900;line-height: 22px;color:rgba(10, 23, 71, 1);">Détails</p>
    </div>
    <div style="border-top:1px solid rgba(219, 221, 225, 1);border-bottom:1px solid rgba(219, 221, 225, 1);padding-top:23px;padding-bottom: 23px;" class="flex space-between">
      <div class="flex">
        <img src="assets/aglo-logo-rond.svg" style="padding-right: 16px;">
        <div style="font-size:18px;font-weight: 900;line-height: 22px;color:rgba(56, 5, 242, 1);padding-top: 10px;width: 250px;">{{licences[newChoosenPlan]}} x  {{newChoosenPlan.includes('plus')?1:users.length}}</div> 
      </div>  
      <div>
        <p style="font-size:14px;font-weight: 700;line-height: 17px;">{{stepPrice}} € HT/an</p>
        <span style="font-size:14px;line-height: 17px;color:rgba(0, 0, 0, 0.5);">par utilisateur</span>
      </div>
    </div>
      <div>
        <div class="flex space-between" style="font-size:14px;font-weight: 700;line-height: 17px;padding-top: 16px;padding-bottom:16px;" *ngIf="couponOff?.percent_off || couponOff?.amount_off ">
          <span>Sous-total hors taxes</span>
          <span>{{stepPrice}} €</span>
        </div>
        <div class="flex space-between" style="padding-top: 16px;padding-bottom:16px;" *ngIf="couponOff?.percent_off">
          <span style="color:rgba(0, 0, 0, 0.5);font-size:14px;line-height: 17px;" >Code promo - {{couponOff?.percent_off}}% de réduction</span>
          <span style="font-size:14px;font-weight: 700;line-height: 17px;">-{{truncNumber(stepPrice*(couponOff?.percent_off/100))}} €</span>
        </div>
        <div class="flex space-between" style="padding-top: 16px;padding-bottom:16px;" *ngIf="couponOff?.amount_off">
          <span style="color:rgba(0, 0, 0, 0.5);font-size:14px;line-height: 17px;" >Code promo - {{couponOff?.amount_off/100}}€ de réduction</span>
          <span style="font-size:14px;font-weight: 700;line-height: 17px;">-{{couponOff?.amount_off/100}} €</span>
        </div>
        <div class="flex space-between" style="font-size:14px;font-weight: 700;line-height: 17px;padding-top: 16px;padding-bottom:16px;">
          <span>Total hors taxes</span>
          <span>{{priceWithReduction}} €</span>
        </div>
        <div class="flex space-between" style="border-bottom:1px solid rgba(219, 221, 225, 1);padding-top: 16px;padding-bottom:16px;">
          <span style="color:rgba(0, 0, 0, 0.5);font-size:14px;line-height: 17px;">TVA ({{truncNumber(currentTva.percentage)}}%)</span>
          <span style="font-size:14px;font-weight: 700;line-height: 17px;">{{truncNumber((priceWithReduction)*(currentTva.percentage/100||currentTva.percentage))}} €</span>
        </div>
        <div class="flex space-between" style="font-size:14px;font-weight: 700;line-height: 17px;padding-top: 16px;padding-bottom:16px;">
          <span>Total TTC </span>
          <span>{{truncNumber(priceWithReduction*(1+currentTva.percentage/100||0.2))}}€</span>
        </div>
        <div *ngIf="typePayment=='sepa'">
          <span style="font-size:14px;font-weight: 500;line-height: 17px;padding-top: 16px;padding-bottom:16px;color:rgba(0, 0, 0, 0.5);">Facilité de paiement : 4 x {{truncNumber((priceWithReduction*(1+currentTva.percentage/100||0.2))/4)}}€  </span>
        </div>
        <div  *ngIf="typePayment=='sepa'" class="flex space-between" style="font-size:14px;font-weight: 700;line-height: 17px;padding-top: 16px;padding-bottom:16px;">
          <span>TOTAL TTC dû à ce jour </span>
          <span>{{truncNumber((priceWithReduction*(1+currentTva.percentage/100||0.2))/4)}} €</span>
        </div>
        <div class="flex space-between" style="padding-top:54px;">
          <div (click)="goToLicences('second')" class="cursor">
            <img style="margin-bottom: 3px;padding-right:12px;" src="assets/chevron-left.svg" alt=""> 
            <span>Modalité de paiement</span>
          </div>
          <div style="padding-bottom:29px">
            <button style="border-radius: 4px;background-color: rgba(10, 23, 71, 1);color:white;padding-bottom: 6px;padding-top: 6px;padding-right: 24px;padding-left: 24px;border:none;">
              <span  (click)="requestPlan(newChoosenPlan)" *ngIf="!loadinglicenceSubmit">
                Je valide le paiement
              </span>  
              <app-spinner *ngIf="loadinglicenceSubmit"></app-spinner>
            </button>
          </div>
        </div>
      </div>
    </div>
</div>
<div id="churned-form" class="modal fade" role="dialog" aria-hidden="true">
    <div class="modal-dialog" style="width: 670px;">
      <div class="churned-form-modal-container">
          <h2>
            Vous repassez déjà en licence Essentiel ?
          </h2>
          <div *ngIf="users && users.length>1 && licenceC!='plus' && licenceC!='plus-annuel'" class="warning-container warning-container2">
            <img src="/assets/archi/licence_warning.svg" alt="" class="warning-icon">
            <p class="user-warning">Attention ! En souscrivant à une licence Essentiel, tous vos utilisateurs passeront en licence Essentiel.</p>
          </div>
          <div class="subtite">
            Indiquez-nous la raison de votre départ et nous travaillerons d'arrache-pied pour améliorer votre expérience sur AGLO
          </div>
          <br>
          <div class="color-blue">
          <div class="p-field-radiobutton">
              <p-radioButton inputId="prix_eleve" name="churnedraison" value="prix_eleve" [(ngModel)]="churnedraison" ></p-radioButton>
              <label for="prix_eleve">Prix trop élevé</label>
          </div>
          <div class="p-field-radiobutton">
              <p-radioButton inputId="autre_logiciel" name="churnedraison" value="autre_logiciel" [(ngModel)]="churnedraison" ></p-radioButton>
              <label for="autre_logiciel">Passage à un autre logiciel</label>
          </div>
          <div class="p-field-radiobutton">
              <p-radioButton inputId="plus_besoin" name="churnedraison" value="plus_besoin" [(ngModel)]="churnedraison" ></p-radioButton>
              <label for="plus_besoin">Plus besoin d'AGLO en ce moment</label>
          </div>
          <div class="p-field-radiobutton">
              <p-radioButton inputId="problems_techniques" name="churnedraison" value="problems_techniques" [(ngModel)]="churnedraison" ></p-radioButton>
              <label for="problems_techniques">Problèmes techniques</label>
          </div>
          <div class="p-field-radiobutton">
              <p-radioButton inputId="autres" name="churnedraison" value="autres" [(ngModel)]="churnedraison" ></p-radioButton>
              <label for="autres">Autre</label>
          </div>
          <br><br>
        </div>
          <div>
            <button class="cancel-modal" (click)="closeModalOut()">Finalement, je garde ma licence</button>
            <button class="submit" (click)="preRequestPlan('without')">Passer en Essentiel</button>
          </div>
      </div>
    </div>
</div>
</div>
<div id="upgrade-licence"
  class="modal fade upgrade-modal-witout"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog no-padding">
    <div class="modal-container no-padding" style="border-radius: 18px;">
      <div style="border-bottom:1px solid rgba(237, 237, 237, 1)">
      <p class="upgrade-title">Arrêt de votre abonnement</p>
      </div>
      <div *ngIf="stepDelete=='one'">
      <div class="">
        <div class="delete-licence-text">
          Indiquez-nous la raison de votre départ et nous travaillerons d'arrache-pied pour améliorer votre expérience sur AGLO.
        </div>
        <div class="margin-left-10 " style="font-size:14px; color: rgba(10, 23, 71, 1);margin-bottom: 32px;">
        <div class="churns-list">
          <p-radioButton inputId="prix_eleve" name="prix_eleve" value="prix_eleve" [(ngModel)]="churnedraison" ></p-radioButton>
          <label for="prix_eleve">Prix trop élevé</label>
        </div>
        <div class="churns-list">
            <p-radioButton inputId="autre_logiciel" name="autre_logiciel" value="autre_logiciel" [(ngModel)]="churnedraison" ></p-radioButton>
            <label for="autre_logiciel">Passage à un autre logiciel</label>
        </div>
        <div class="churns-list">
            <p-radioButton inputId="plus_besoin" name="plus_besoin" value="plus_besoin" [(ngModel)]="churnedraison" ></p-radioButton>
            <label for="plus_besoin">Plus besoin d'AGLO en ce moment</label>
        </div>
        <div class="churns-list">
            <p-radioButton inputId="problems_techniques" name="problems_techniques" value="problems_techniques" [(ngModel)]="churnedraison" ></p-radioButton>
            <label for="problems_techniques">Problèmes techniques</label>
        </div>
        <div>
            <p-radioButton inputId="autres" name="autres" value="autres" [(ngModel)]="churnedraison" ></p-radioButton>
            <label style="font-weight: 400;" for="autres">Autre</label>
        </div>
      </div>
      </div>
      <div class="button-container">
        <button class="b1" data-dismiss="modal">Annuler</button>
        <button class="b2" [disabled]='!churnedraison' (click)="goToStepTwo('two')">
          <div *ngIf="!loadinglicenceSubmit">Confirmer</div>
          <app-spinner *ngIf="loadinglicenceSubmit"></app-spinner>
        </button>
      </div>
    </div>
    <div *ngIf="stepDelete=='two'">
       <div class="delete-licence-text" style="padding-bottom: 20px;">
        <p>Vous êtes sur le point d’arrêter votre abonnement. Votre abonnement restera actif jusqu’à la fin de la période réglée, vous passerez ensuite en lecture seule.</p>
        <p style="font-weight: 700;margin-bottom: 0;">Êtes-vous sûr de vouloir continuer ?</p>
       </div>
       <div class="button-container">
        <div class="cursor" (click)="goToStepTwo('one')" style="padding-right:40px;"[ngStyle]="{'padding-top':loadinglicenceSubmit?'15px':'5px'}"> <div class="flex"> <img src="assets/arrow-left.svg"><span style="padding-left: 9px;">Retour</span></div></div>
        <button class="b2" style="background-color: rgba(217, 4, 4, 1);margin-right: 24px;"> 
          <div *ngIf="!loadinglicenceSubmit" (click)="preRequestPlan('without')" >Oui, j’arrête mon abonnement</div>
          <app-spinner *ngIf="loadinglicenceSubmit"></app-spinner>
        </button>
       </div>
    </div>
    </div>
  </div>
</div>
<p-confirmDialog key="change" #cs>
  <p-footer>
    <button type="button" pButton icon="fa fa-close" label="Non" (click)="cs.reject()"></button>
    <button type="button" pButton icon="fa fa-check" label="Oui" (click)="cs.accept()"></button>
  </p-footer>
</p-confirmDialog>