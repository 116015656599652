import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, NO_ERRORS_SCHEMA } from '@angular/core';
import { DpgfPostesService, AuthService } from 'src/app/_services';

import { Observable, Subscription } from 'rxjs';

import { CstbNavigationLotComponent } from '../cstb-navigation-lot/cstb-navigation-lot.component';
import { NgSharedModule } from '../../ngshared.module';

@Component({
  standalone:true,
  selector: 'app-cstb-navigation',
  imports:[
    NgSharedModule,
    CstbNavigationLotComponent],
  templateUrl: './cstb-navigation.component.html',
  styleUrls: ['./cstb-navigation.component.scss']
})
export class CstbNavigationComponent implements OnInit {

  @Input() manySelect:boolean;
  @Input() isLot;
  @Output() selectedCstbEvent = new EventEmitter<any>();
  @Output() showButton = new EventEmitter<any>();
  private subscriptions: Array<Subscription> = [];
  public cstbLots:any = [];
  public activeCstbLot:any = {};
  public loadingLots:boolean = true;
  public loadingChild:boolean = false;
  public isLotSelected:boolean = false;
  public allPostes = [];
  public selectedPostes = [];
  public licence= {};

  constructor(private dpgfService: DpgfPostesService,
              private cd: ChangeDetectorRef,
              private authService: AuthService) { }

  ngOnInit() {
    this.getAllCstbLotNoChildren();
     this.authService.features.subscribe((l)=>{
       this.licence = l;
    });
  }

  public getAllCstbLotNoChildren() {
    this.subscriptions.push(
      this.dpgfService.getAllCstbLotsNoChildren()
      .subscribe(
        data => {
          this.loadingLots = false;
          this.cstbLots = data;
          this.cd.markForCheck();
        },
        error => {
          console.log("error",error)
        }
      )
    )
  }

  public getCstbLot(lot) {
    this.showButton.emit(true)
    this.isLotSelected = true;
    this.loadingChild = true;
    this.subscriptions.push(
      this.dpgfService.getCstbLotById(lot.gid)
      .subscribe(data => {
        this.loadingChild = false;
        if(this.isLot){
          (data.children||[]).unshift({
            gid: data.gid,
            isPoste: true,
            open: false,
            generalite : true,
            title: "Généralités",
          })
        }
        if(lot.gid == "AHFA"){
          data.children.forEach(lot => lot.generalite = true);
        }
        if(lot.latestGID) {
          let indices = this.openParents(data,lot.latestGID);
          data =  this.setOpenByIndices(data, indices)
        }
        this.activeCstbLot = data;
        this.cd.markForCheck();
      })
    )
  }
//   public openParents(table, selectedGid) {
//     // Helper function to traverse the structure recursively
//     function traverse(node, selectedGid, parents?,tabi?) {
//       console.log('tabi',tabi)
//         // Check if current node's gid matches the selectedGid
//         if (node.gid === selectedGid) {
//             // Set open to true for all parent nodes
//             console.log('equals ',node.gid === selectedGid)
//             console.log('parents',parents)
//             console.log('tabi',tabi)
//             parents.forEach(parent => {
//                 parent.open = true;
//             });
//             console.log('parents 0',parents)
            
//         }
//         // console.log('node',node)
//         // If the current node has children, continue traversing
//         let tab = []
//         if (node.children) {
//             // Add current node to parents list for its children
//             for (let i=0;i < node.children.length;i++) {
//                 traverse(node.children[i], selectedGid, [node],[...tab,i]);
//             }
//         }
//     }

//     // Start the traversal from the root node
//     traverse(table, selectedGid);
// }
// public openParents(table, selectedGid) {
//   // Helper function to traverse the structure recursively
//   function traverse(node, selectedGid, parents = [], indices = []) {
//       // Check if the current node's gid matches the selectedGid
//       if (node.gid === selectedGid) {
//           // Set open to true for all parent nodes
//           parents.forEach(parent => {
//               parent.open = true;
//           });
          
//           console.log('Path to selected node:', indices);
//           return indices;
//           // Optionally, you can log the indices to see which path was taken
//       }

//       // If the current node has children, continue traversing
//       if (node.children) {
//           // Add current node to parents list for its children
//           for (let i = 0; i < node.children.length; i++) {
//               traverse(node.children[i], selectedGid, parents.concat(node), indices.concat(i));
//           }
//       }
//   }
  
//   // Start the traversal from the root node
//   return traverse(table, selectedGid);
// }
public openParents(table, selectedGid) {
  // Helper function to traverse the structure recursively
  function traverse(node, selectedGid, parents = [], indices = []) {
      // Check if the current node's gid matches the selectedGid
      if (node.gid === selectedGid) {
          // Set open to true for all parent nodes
          parents.forEach(parent => {
              parent.open = true;
          });
          // Log the path to the selected node
          return indices;
      }

      // If the current node has children, continue traversing
      if (node.children) {
          // Add current node to parents list for its children
          for (let i = 0; i < node.children.length; i++) {
              const result = traverse(node.children[i], selectedGid, parents.concat(node), indices.concat(i));
              if (result) {
                  return result; // Return the result if found
              }
          }
      }
      
      // Return null if the selectedGid is not found in this branch
      return null;
  }
  // Start the traversal from the root node
  return traverse(table, selectedGid);
}
public setOpenByIndices(table, indices) {
  let current = table;
  current.open = true;  // Set open for the final node
  for (let i = 0; i < indices?.length; i++) {
      current = current.children[indices[i]];
      current.open = true;
  }
  return table;
}
  public getSelectedPoste(postes) {
    if(this.manySelect){
      postes.forEach(poste => {
        const exists = this.allPostes.some(posteAll => posteAll.gid === poste.gid);
        if(!exists) {
          this.allPostes.push(poste);
        }
      });
      this.selectedPostes = this.allPostes.filter(poste => poste.isSelected);
      this.selectedCstbEvent.emit(this.selectedPostes);
    }else{
      this.selectedCstbEvent.emit(postes);
    }
  }

  public getLotCSTB(lot) {
    this.selectedCstbEvent.emit([lot]);
  }

  public goBack() {
    this.isLotSelected = false;
    this.allPostes = [];
    this.selectedPostes = [];
    this.showButton.emit(false);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
}
}
