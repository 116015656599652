let mainRegionsList = [
  {name: "Auvergne-Rhône-Alpes", value: "auvergne-rhone-alpes",
    departements: [
      { departNum: "1", departName: "Ain", id :"FR-1", text: "01-Ain" },
      { departNum: "3", departName: "Allier", id :"FR-3", text: "03-Allier" },
      { departNum: "7", departName: "Ardèche", id :"FR-7", text: "07-Ardèche" },
      { departNum: "15", departName: "Cantal", id :"FR-15", text: "15-Cantal" },
      { departNum: "26", departName: "Drôme", id :"FR-26", text: "26-Drôme" },
      { departNum: "38", departName: "Isère", id :"FR-38", text: "38-Isère" },
      { departNum: "42", departName: "Loire", id :"FR-42", text: "42-Loire" },
      { departNum: "43", departName: "Haute-Loire", id :"FR-43", text: "43-Haute-Loire" },
      { departNum: "63", departName: "Puy-de-Dôme", id :"FR-63", text: "63-Puy-de-Dôme" },
      { departNum: "69", departName: "RhôneNote 6", id :"FR-69", text: "69-Rhône" },
      { departNum: "73", departName: "Savoie", id :"FR-73", text: "73-Savoie" },
      { departNum: "74", departName: "Haute-Savoie", id :"FR-74", text: "74-Haute-Savoie" },
    ]
  },
  {name: "Bourgogne-Franche-Comté", value: "bourgogne-franche-comte",
    departements: [
      { departNum: "21", departName: "Côte-d'Or", id :"FR-21", text: "21-Côte-d'Or" },
      { departNum: "25", departName: "Doubs", id :"FR-25", text: "25-Doubs" },
      { departNum: "39", departName: "Jura", id :"FR-39", text: "39-Jura" },
      { departNum: "58", departName: "Nièvre", id :"FR-58", text: "58-Nièvre" },
      { departNum: "70", departName: "Haute-Saône", id :"FR-70", text: "70-Haute-Saône" },
      { departNum: "71", departName: "Saône-et-Loire", id :"FR-71", text: "71-Saône-et-Loire" },
      { departNum: "89", departName: "Yonne", id :"FR-89", text: "89-Yonne" },
      { departNum: "90", departName: "Territoire de Belfort", id :"FR-90", text: "90-Territoire de Belfort" },
    ]
  },
  { name: "Bretagne", value: "bretagne",
    departements: [
      { departNum: "22", departName: "Côtes-d'Armor", id :"FR-22", text: "22-Côtes-d'Armor" },
      { departNum: "29", departName: "Finistère", id :"FR-29", text: "29-Finistère" },
      { departNum: "35", departName: "Ille-et-Vilaine", id :"FR-35", text: "35-Ille-et-Vilaine" },
      { departNum: "56", departName: "Morbihan", id :"FR-56", text: "56-Morbihan" },
    ]
  },
  { name: "Centre-Val de Loire", value: "centre-val-de-Loire",
    departements: [
      { departNum: "18", departName: "Cher", id :"FR-18", text: "18-Cher" },
      { departNum: "28", departName: "Eure-et-Loir Note 4", id :"FR-28", text: "28-Eure-et-Loir" },
      { departNum: "36", departName: "Indre", id :"FR-36", text: "36-Indre" },
      { departNum: "37", departName: "Indre-et-Loire", id :"FR-37", text: "37-Indre-et-Loire" },
      { departNum: "41", departName: "Loir-et-Cher", id :"FR-41", text: "41-Loir-et-Cher" },
      { departNum: "45", departName: "Loiret", id :"FR-45", text: "45-Loiret" },
    ]
  },
  { name: "Corse", value: "corse",
    departements: [
      { departNum: "2A", departName: "Corse-du-Sud", id :"FR-2A", text: "2A-Corse-du-Sud"},
      { departNum: "2B", departName: "Haute-Corse", id :"FR-2B", text: "2B-Haute-Corse"},
    ]
  },
  { name: "Grand Est", value: "grand-est", 
    departements: [
      { departNum: "8", departName: "Ardennes", id :"FR-8", text: "08-Ardennes" },
      { departNum: "10", departName: "Aube", id :"FR-10", text: "10-Aube" },
      { departNum: "51", departName: "Marne", id :"FR-51", text: "51-Marne" },
      { departNum: "52", departName: "Haute-Marne", id :"FR-52", text: "52-Haute-Marne" },
      { departNum: "54", departName: "Meurthe-et-Moselle", id :"FR-54", text: "54-Meurthe-et-Moselle" },
      { departNum: "55", departName: "Meuse", id :"FR-55", text: "55-Meuse" },
      { departNum: "57", departName: "Moselle", id :"FR-57", text: "57-Moselle" },
      { departNum: "67", departName: "Bas-Rhin10", id :"FR-67", text: "67-Bas-Rhin" },
      { departNum: "68", departName: "Haut-Rhin10", id :"FR-68", text: "68-Haut-Rhin" },
      { departNum: "88", departName: "Vosges", id :"FR-88", text: "88-Vosges" },
    ]
  },
  { name: "Hauts-de-France", value: "hauts-de-france",
    departements: [
      { departNum: "2", departName: "Aisne", id :"FR-2", text: "02-Aisne"},
      { departNum: "59", departName: "Nord", id :"FR-59", text: "59-Nord"},
      { departNum: "60", departName: "Oise", id :"FR-60", text: "60-Oise"},
      { departNum: "62", departName: "Pas-de-Calais", id :"FR-62", text: "62-Pas-de-Calais"},
      { departNum: "80", departName: "Somme", id :"FR-80", text: "80-Somme"},
    ]
  },
  { name: "Île-de-France", value: "ile-de-france",
    departements: [
      { departNum: "75", departName: "Paris", id :"FR-75", text: "75-Paris" },
      { departNum: "77", departName: "Seine-et-Marne", id :"FR-77", text: "77-Seine-et-Marne" },
      { departNum: "78", departName: "Yvelines", id :"FR-78", text: "78-Yvelines" },
      { departNum: "91", departName: "Essonne", id :"FR-91", text: "91-Essonne" },
      { departNum: "92", departName: "Hauts-de-Seine (m.p.)", id :"FR-92", text: "92-Hauts-de-Seine" },
      { departNum: "93", departName: "Seine-Saint-Denis", id :"FR-93", text: "93-Seine-Saint-Denis" },
      { departNum: "94", departName: "Val-de-Marne", id :"FR-94", text: "94-Val-de-Marne" },
      { departNum: "95", departName: "Val-d'Oise", id :"FR-95", text: "95-Val-d'Oise" },
    ]
  },
  { name: "Normandie", value: "normandie",
    departements: [
      { departNum: "14", departName: "Calvados", id :"FR-14", text: "14-Calvados" },
      { departNum: "27", departName: "Eure", id :"FR-27", text: "27-Eure" },
      { departNum: "50", departName: "Manche", id :"FR-50", text: "50-Manche" },
      { departNum: "61", departName: "Orne", id :"FR-61", text: "61-Orne" },
      { departNum: "76", departName: "Seine-Maritime", id :"FR-76", text: "76-Seine-Maritime" },
    ]
  },
  { name: "Nouvelle-Aquitaine", value: "nouvelle-aquitaine",
    departements: [
      { departNum: "16", departName: "Charente", id :"FR-16", text: "16-Charente" },
      { departNum: "17", departName: "Charente-Maritime", id :"FR-17", text: "17-Charente-Maritime" },
      { departNum: "19", departName: "Corrèze", id :"FR-19", text: "19-Corrèze" },
      { departNum: "23", departName: "Creuse", id :"FR-23", text: "23-Creuse" },
      { departNum: "24", departName: "Dordogne", id :"FR-24", text: "24-Dordogne" },
      { departNum: "33", departName: "Gironde", id :"FR-33", text: "33-Gironde" },
      { departNum: "40", departName: "Landes", id :"FR-40", text: "40-Landes" },
      { departNum: "47", departName: "Lot-et-Garonne", id :"FR-47", text: "47-Lot-et-Garonne" },
      { departNum: "64", departName: "Pyrénées-Atlantiques", id :"FR-64", text: "64-Pyrénées-Atlantiques" },
      { departNum: "79", departName: "Deux-Sèvres", id :"FR-79", text: "79-Deux-Sèvres" },
      { departNum: "86", departName: "Vienne", id :"FR-86", text: "86-Vienne" },
      { departNum: "87", departName: "Haute-Vienne", id :"FR-87", text: "87-Haute-Vienne" },
    ]
  },
  { name: "Occitanie", value: "occitanie",
    departements: [
      { departNum: "9", departName: "Ariège", id :"FR-9", text: "09-Ariège" },
      { departNum: "11", departName: "Aude", id :"FR-11", text: "11-Aude" },
      { departNum: "12", departName: "Aveyron", id :"FR-12", text: "12-Aveyron" },
      { departNum: "30", departName: "Gard", id :"FR-30", text: "30-Gard" },
      { departNum: "31", departName: "Haute-Garonne", id :"FR-31", text: "31-Haute-Garonne" },
      { departNum: "32", departName: "Gers", id :"FR-32", text: "32-Gers" },
      { departNum: "34", departName: "Hérault", id :"FR-34", text: "34-Hérault" },
      { departNum: "46", departName: "Lot", id :"FR-46", text: "46-Lot" },
      { departNum: "48", departName: "Lozère", id :"FR-48", text: "48-Lozère" },
      { departNum: "65", departName: "Hautes-Pyrénées", id :"FR-65", text: "65-Hautes-Pyrénées" },
      { departNum: "66", departName: "Pyrénées-Orientales", id :"FR-66", text: "66-Pyrénées-Orientales" },
      { departNum: "81", departName: "Tarn", id :"FR-81", text: "81-Tarn" },
      { departNum: "82", departName: "Tarn-et-Garonne", id :"FR-82", text: "82-Tarn-et-Garonne" },
    ]
  },
  { name: "Pays de la Loire", value: "pays-de-la-loire",
    departements: [
      { departNum: "44", departName: "Loire-Atlantique", id :"FR-44", text: "44-Loire-Atlantique"},
      { departNum: "49", departName: "Maine-et-Loire Note 5", id :"FR-49", text: "49-Maine-et-Loire"},
      { departNum: "53", departName: "Mayenne", id :"FR-53", text: "53-Mayenne"},
      { departNum: "72", departName: "Sarthe", id :"FR-72", text: "72-Sarthe"},
      { departNum: "85", departName: "Vendée", id :"FR-85", text: "85-Vendée"},
    ]
  },
  { name: "Provence-Alpes-Côte d'Azur", value: "provence-alpes-cote-dazur",
    departements: [
      { departNum: "4", departName: "Alpes-de-Haute-Provence", id :"FR-4", text: "04-Alpes-de-Haute-Provence" },
      { departNum: "5", departName: "Hautes-Alpes", id :"FR-5", text: "05-Hautes-Alpes" },
      { departNum: "6", departName: "Alpes-Maritimes", id :"FR-6", text: "06-Alpes-Maritimes" },
      { departNum: "13", departName: "Bouches-du-Rhône", id :"FR-13", text: "13-Bouches-du-Rhône" },
      { departNum: "83", departName: "Var", id :"FR-83", text: "83-Var" },
      { departNum: "84", departName: "Vaucluse", id :"FR-84", text: "84-Vaucluse" },
    ]
  }
];

export default mainRegionsList;