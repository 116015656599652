<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 sidebar-wrapper">
      <div class="options-dropdown">
        <ul *ngFor="let option of projectDescriptionOptions; index as i">
          <li class="main-level ag-para-1 ag-bold flex space-between"
            [ngClass]="{'open': (option.value == selectedOption)}"
            (click)="openNestedList(option, 'nested-options-'+option.value)">
            <span>{{ option.label }}</span>
            <img src="/assets/acv-icons/nav-arrow-bottom.svg" *ngIf="option.zones">
          </li>
          <!-- Nest List -->
          <ul class="nested-items-ul" [id]="removeWhiteSpaces('nested-options-'+option.value)">
            <li class="ag-para-1" [id]="'nested-li'+'-'+nestedItem.value"
              *ngFor="let nestedItem of option.zones; index as i"
              (click)="listItemSelected($event, nestedItem)"
              >
              {{ nestedItem.label }}
            </li>
          </ul>
        </ul>
      </div>

    </div>
    <div class="col-md-9 main-content-wrapper silver-arthens-grey-bg scroller-1" *ngIf="!loading" >
      <!-- FORM
      ================================= -->
      <div class="main-form-wrapper" [ngClass]="{'prevent-event':isProjectDemo}">
        <h4 class="ag-heading-h4 margin-bottom-16">{{ forms[currentView].mainTitle || '' }}</h4>
        <div class="form-rows-container">

          <!-- Single row -->
          <div class="form-row flex space-between center-items" *ngFor="let row of forms[currentView].items">
            <div class="row-label">
              <p class="ag-para-1">{{ row.label }}</p>
            </div>

            <ng-container [ngSwitch]="row.type">

              <!-- Group Title -->
              <div class="row-widget full-width" *ngSwitchCase="'groupTitle'">
                <p class="ag-para-2 ag-bold">{{ row.title }}</p>
              </div>
              <!-- END Group Title -->

              <!-- Select -->
              <div class="row-widget" *ngSwitchCase="'select'">
                <select class="ag-select-1 min-width-170" (change)="optionSelected(currentView, row.modelVariable, $event.target.value)">
                  <option selected disabled hidden>
                    <!-- Choix -->
                    {{ selectOptionsPlaceholders[row.modelVariable] || "Choix" }}
                  </option>
                  <option *ngFor="let option of row.options" [value]="option.value"
                    [selected]="option.value === ( prevState |  getStatePath: selectedOption+'.'+row.modelVariable : false ) ? true : false"
                    >

                  {{ row.showValue ? option.value : option.label }}
                </option>
                </select>
              </div>
              <!-- END Select -->

              <!-- Filterable Select -->
              <div style="position: relative;" *ngSwitchCase="'custom_select'">
                <input class="post-select"
                  id="sugg-input"
                  style="width: 220px;z-index: 10000;padding-right: 26px;"
                  aria-haspopup="true" aria-expanded="false"
                  (focusin)="showSugg(row.variableModel)"
                  (keyup)="filterCustomSelect($event.target.value, row.selectedLabel)"
                  [(ngModel)]="row.variableModel == 'selectedCommuneImplantationReseau' ? selectedCommuneImplantationReseau : selectedNomReseau"
                  autocomplete="nope"
                >
                <div [id]="row.variableModel" class="dropdown-menu custom_select_container scroller-1"
                  >
                  <div *ngIf="!reseauFilterdOptions[row.selectedLabel].length">
                    <div *ngFor="let option of showOptions(selectedReseauOptions,row.variableModel) | slice:0:30;" class="single-option-item"
                       (click)="resauItemSelected(row.variableModel, option, row.selectedLabel)">
                      <p class="title">{{ showOption(option,option[row.selectedLabel],row.variableModel) }}</p>
                    </div>
                  </div>
                  <div *ngIf="reseauFilterdOptions[row.selectedLabel].length" >
                    <div *ngFor="let option of showOptions(reseauFilterdOptions[row.selectedLabel],row.variableModel) | slice:0:30;" class="single-option-item"
                       (click)="resauItemSelected(row.variableModel, option,  row.selectedLabel)">
                      <p class="title">{{ showOption(option,option[row.selectedLabel],row.variableModel) }}</p>
                    </div>
                  </div>

                </div>
                <img src="/assets/archi/select-arrow.svg" class="select-arrow" style="right: 15px;position: relative;">
              </div>
              <!-- END Filterable Select -->

              <!-- Radio Input -->
              <div class="row-widget flex" *ngSwitchCase="'radio'">
                <p *ngFor="let option of row.options; index as i"  [ngClass]="{'margin-left-32' : i > 0 }">
                  <input type="radio" [name]="row.optionName" class="ag-input-1" [value]="option.value"
                  (change)="radioSelected(currentView, row.modelVariable, $event.target.value)"
                  [checked]="option.value === ( prevState |  getStatePath: selectedOption+'.'+row.modelVariable : false ) ? true : false"
                  >
                  <!-- [checked]="option.value === (prevState[selectedOption]||{})[row.modelVariable] ? true : false" -->
                  {{ option.label }}
                </p>
              </div>
              <!-- END Radio Input -->

              <!-- Other Inputs -->
              <div class="row-widget flex center-items" *ngSwitchDefault>
                  <input [type]="row.type" [placeholder]="row.placeholder"
                    [ngClass]="{'ag-input-1': true, 'month-input': row.inputLabel, 'min-width-170': !row.inputLabel }"
                    (change)="inputTyped(currentView, row.modelVariable, $event.target.value)"
                    [value]="( prevState |  getStatePath: selectedOption+'.'+row.modelVariable : false ) || ''"
                    >

                <span *ngIf="row.inputLabel" class="margin-left-32 input-label">{{row.inputLabel}}</span>
              </div>
              <!-- END Other Inputs -->
            </ng-container>

          </div>
          <!-- END Single row -->

        </div>
      </div>
      <!-- END FORM
      ================================= -->

      <!-- Navigation Arrows -->
      <div class="nav-arrows-container flex space-between">
        <div class="arr-item prev" (click)="prevSlide()">
          <img src="/assets/acv-icons/nav-arrow-prev.svg"> Précédent
        </div>
        <div class="arr-item next" (click)="nextSlide()">
          Suivant <img src="/assets/acv-icons/nav-arrow-next.svg">
        </div>
      </div>
      <!-- END Navigation Arrows -->

      <!-- Required Fields Card -->
      <div class="ag-card-1 required-fileds-card flex space-between margin-top-15">
        <div class="margin-right-20">
          <p class="ag-para-1">Champs complétés pour le RSEE</p>
        </div>
        <div>
          <p class="ag-para-1">
            <b>{{ TotalValidFormInputs }}</b>/{{ TotalFormInputs }}
          </p>
        </div>
      </div>
      <!-- END Required Fields Card -->

    </div>
  </div>
</div>
