import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var amplitude: any;

@Injectable()
export class LogService {

  constructor() { }

  logEvent(event) {
    if(!environment.onPremise){
    let isAdmin = localStorage.getItem('isAdminBam')
    if(amplitude.getInstance() && isAdmin!="true"){
      try{
        amplitude.getInstance().logEvent(event)
      }catch(error){
        console.log(error)
      }
    }else{
      console.log(event)
    }
    }
  }
  setUserId(id){
    if(!environment.onPremise){
    let isAdmin = localStorage.getItem('isAdminBam')
    if(amplitude.getInstance() && isAdmin!="true"){
      try{
        amplitude.getInstance().setUserId(id)
      }catch(error){
        console.log(error)
      }
    }}
  }

}
