import { Pipe, PipeTransform } from '@angular/core'


@Pipe({
  standalone:true,
  name: 'location'
})
export class LocationPipe implements PipeTransform {

  transform(value:any) {
      if (value) {
          var elements = value.split("-");
          elements.shift();
          return (elements.join("-") || "");
      }
      return value;
  }

}
