import { Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';


@Pipe({
  standalone:true,
  name: 'pricingIndex'
})
export class PricingVersionIndex implements PipeTransform {

  transform(version) {
    var n = version-1;
    var ordA = 'a'.charCodeAt(0);
    var ordZ = 'z'.charCodeAt(0);
    var len = ordZ - ordA + 1;
    var s = "";
    while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s.toUpperCase();
  }
}
