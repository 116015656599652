<div id="need-subscription-max-premium-modal" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Passez sur AGLO Max ou AGLO Intégral </h4>
      </div>
      <div class="modal-body">
        <p>
          Cette fonctionnalité est disponible seulement pour les utilisateurs d'AGLO Max ou AGLO Intégral !
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Plus tard</button>
        <button (click)="redirectToPricingTable()" type="button" class="btn btn-primary">C'est parti !</button>
      </div>
    </div>

  </div>
</div>

<div id="need-subscription-premium-modal" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Passez sur AGLO INTÉGRAL </h4>
      </div>
      <div class="modal-body">
        <p>
          Cette fonctionnalité est disponible seulement pour les utilisateurs d'AGLO INTÉGRAL !
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Plus tard</button>
        <button (click)="redirectToPricingTable()" type="button" class="btn btn-primary">C'est parti !</button>
      </div>
    </div>

  </div>
</div>
