<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 sidebar-wrapper">

      <div class="options-dropdown">
        <ul *ngFor="let option of projectDescriptionOptions; index as i">
          <li class="main-level ag-para-1 ag-bold flex space-between"
            [ngClass]="{'open': (option.value == selectedOption || option.value == selectedbatiment['value'])}"
            (click)="openNestedList(option, 'nested-options-'+option.value)">
            <span>{{ option.label }}</span>
            <img *ngIf="option.value!='parcelle'" src="/assets/acv-icons/nav-arrow-bottom.svg">
          </li>
          <!-- Nest List -->
          <ul class="nested-items-ul" [id]="removeWhiteSpaces('nested-options-'+option.value)"
            [ngClass]="{'open': option.value == selectedbatiment['value']}">
            <li class="ag-para-1" [id]="'nested-li'+'-'+nestedItem.value"
            [ngClass]="{'selected': nestedItem.trimmedValue == selectedZone}"
              *ngFor="let nestedItem of option.zones; index as i"
              (click)="listItemSelected($event, nestedItem)"
              >
              {{ nestedItem.label }}
            </li>
          </ul>
        </ul>
      </div>

    </div>
    <div class="col-md-9 main-content-wrapper scroller-1">
      <!-- FORM
      ================================= -->
      <div class="main-form-wrapper" *ngIf="currentView != ''" [ngClass]="{'prevent-event':isProjectDemo}">
        <h4 class="ag-heading-h4 margin-bottom-16">{{ forms[selectedFormType].mainTitle || '' }}</h4>
        <p class="ag-para-1 margin-bottom-59" *ngIf="forms[selectedFormType].mainDescription != ''">{{ forms[selectedFormType].mainDescription || '' }}</p>
        <div class="form-rows-container">

          <!-- Single row -->
          <div class="form-row" *ngFor="let row of forms[selectedFormType].items">
            <div class="grey-bg flex space-between center-items">
              <p class="ag-para-1 label-text"
                 *ngIf="row.label"
                [ngClass]="{'ag-text-bold' : row.labelIsBold, 'margin-left-47': row.type === 'table'}">{{ row.label }}</p>

              <ng-container [ngSwitch]="row.type">

                <!-- Lock Title -->
                <div class="row-widget full-width" *ngSwitchCase="'lock_title'">
                  <div class="flex space-between center-items lock-heading-wrapper">
                      <h4 class="ag-heading-h4" *ngIf="row.rowTitle && row.rowTitle != ''">{{ row.rowTitle }}</h4>
                    <div class="">
                      <span class="lock-btn">
                        <input type="number" class="ag-input-1 custom-number margin-left-10"
                          *ngIf="getLockPath(selectedOption+'.'+row.modelVariable)"
                          (change)="calculationInputTyped(row.modelVariable, row.calculationVariable, $event.target.value)"
                          [value]="( customCalculatedSections |  getStatePath: selectedOption+'.'+customLockPreFixName+'_'+row.calculationVariable : '' )"
                          >
                          <!-- Lock is Closed Calculations -->
                          <span *ngIf="!getLockPath(selectedOption+'.'+row.modelVariable)"> {{ getCalculationsStateValue(selectedOption, calculationPreFixName+'-'+row.calculationVariable) | formatPrice }}</span>
                          <!-- Custom Input Values -->
                          <span class="margin-left-10">m³/an</span>
                        <!-- /an -->
                        <i (click)="unlock(row.calculationVariable, row.modelVariable)"
                          [ngClass]="!getLockPath(selectedOption+'.'+row.modelVariable) ? 'fa fa-lock' : 'fa fa-unlock' "></i>
                      </span>
                    </div>
                  </div>
                  <p class="ag-para-1 label-text">{{ row.rowDescription }}</p>
                </div>
                <!-- END Lock Title -->

                <!-- Section Title -->
                <div class="row-widget full-width" *ngSwitchCase="'section_title'">
                  <div class="flex space-between center-items">
                    <h4 class="ag-heading-h4" *ngIf="row.rowTitle && row.rowTitle != ''">{{ row.rowTitle }}</h4>
                  </div>
                  <p *ngIf="row.rowDescription && row.rowDescription != ''" class="ag-para-1 label-text">{{ row.rowDescription }}</p>
                </div>
                <!-- END Section Title -->

                <!-- Select Input -->
                <div class="row-widget" *ngSwitchCase="'select'">
                  <select class="ag-select-1" (change)="optionSelected(currentView, row.modelVariable, $event.target.value)">
                    <option selected disabled hidden>Choix</option>
                    <option *ngFor="let option of row.options"
                      [value]="option.value"
                      [selected]="option.value === ( prevState | getStatePath : selectedOption+'.'+row.modelVariable : undefined ) ? true : false"
                      >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <!-- END Select Input -->

                <!-- Radio Input -->
                <div class="row-widget flex" *ngSwitchCase="'radio'">
                  <p *ngFor="let option of row.options; index as i" [ngClass]="{'margin-left-32' : i > 0 }">
                    <input type="radio" [name]="row.optionName" class="ag-input-1" [value]="option.value"
                    (change)="radioSelected(currentView, row, $event.target.value)"
                    [checked]="option.value === ( prevState | getStatePath : selectedOption+'.'+row.modelVariable : undefined )  ? true : false"
                    >
                    {{ option.label }}
                  </p>
                </div>
                <!-- END Radio Input -->

                <!-- table -->
                <div class="full-width table-widget" *ngSwitchCase="'table'">
                  <ng-container *ngIf="disabledInputs[row.lockId]">
                    <div class="table-header">
                      <ul class="flex space-between no-padding-left">
                        <li *ngFor="let labelItem of row.labels">
                          <span class="ag-heading-h6">{{ labelItem }}</span>
                        </li>
                      </ul>
                    </div>
                    <div class="table-body">
                      <div class="flex space-between margin-bottom-5" *ngFor="let tableOption of row.options">
                        <div class="table-label">
                          <span class="ag-para-1">{{ tableOption.label }}</span>
                        </div>
                        <div class="table-input">
                          <!-- [value]="(prevState[selectedOption]||{})[tableOption.modelVariable] ? (prevState[selectedOption]||{})[tableOption.modelVariable] : '0'"> -->
                          <input type="number" placeholder="0" ngClass="ag-input-1"
                            (change)="inputTyped(currentView, tableOption, $event.target.value)"
                            [value]="( prevState | getStatePath : selectedOption+'.'+tableOption.modelVariable : undefined )"
                            >
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <!-- END table -->

                <!-- Other Inputs -->
                <div class="row-widget flex center-items fields-wrapper" *ngSwitchDefault>
                  <div *ngIf="!row.lockId; else lockItem">
                    <input [type]="row.type" [placeholder]="row.placeholder" [ngClass]="{'ag-input-1': true, 'month-input': row.inputLabel}"
                        (change)="inputTyped(currentView, row, $event.target.value)"
                        [value]="( prevState | getStatePath : selectedOption+'.'+row.modelVariable : undefined ) || ''"
                        >
                      <!-- Default Unite -->
                      <span *ngIf="row.inputLabel" class="input-label-text margin-left-32">{{ row.inputLabel }}</span>
                  </div>

                  <!-- Lock Item -->
                  <ng-template #lockItem>
                    <!-- <div *ngIf="!disabledInputs[row.lockId]; else showHidden"> -->
                    <div *ngIf="!getDisabledInputValue(row.lockId); else showHidden">
                      <input [type]="row.type" [placeholder]="row.placeholder" [ngClass]="{'ag-input-1': true, 'month-input': row.inputLabel}"
                          (change)="inputTyped(currentView, row, $event.target.value)"
                          [value]="( prevState | getStatePath : selectedOption+'.'+row.modelVariable : undefined ) || ''"
                          >
                        <span *ngIf="row.inputLabel" class="input-label-text margin-left-32">{{ row.inputLabel }}</span>
                    </div>
                    <ng-template #showHidden>
                      <span>-</span>
                    </ng-template>
                </ng-template>

                </div>
                <!-- END Other Inputs -->

              </ng-container>
            </div>
          </div>
          <!-- END Single row -->

        </div>
      </div>
      <!-- END FORM
      ================================= -->
    </div>
  </div>
</div>
