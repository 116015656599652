<div *ngFor="let file of allFiles; let i = index">
  <a *ngIf="file.Location" [href]="file.Location" target="_blank">
    {{file.Key}}
  </a>
  <a *ngIf="file.cdnUrl" [href]="file.cdnUrl" target="_blank">
    {{file.name}}
  </a>
  <span *ngIf="!disabled" class="delete-file" (click)="removeFile(i)">x</span>
</div>

<p-fileUpload name="myfile[]"
              *ngIf="!loadingFiles && !disabled"
              multiple="multiple"
              customUpload="true"
              (uploadHandler)="fileEvent($event)"
              auto="true"
              [chooseLabel]="placeholder"
              mode="basic"></p-fileUpload>

<app-spinner *ngIf="loadingFiles"></app-spinner>
