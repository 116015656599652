<div class="all">
  <app-header type="full"></app-header>
  <app-archi-expired-modal></app-archi-expired-modal>

  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <!-- Header titles -->
        <div class="text-header">
          <h2 class="ag-heading-h2 ag-bold">Générez vos descriptifs techniques</h2>
          <p class="text-description">
            Pour une description optimale, décrivez l'ouvrage dans sa globalité : type de pose, matériau..</p>
        </div>
        <!-- END Header titles -->
      </div>
    </div>

    <!-- Alert Message -->
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-info alert-text" role="alert">
          <p class="horizontal-items">
            <img class="margin-right-8" src="/assets/exclamation-mark-sky-blue.svg">
            AgloGPT est un outil d'aide à la rédaction généré par un algorithme d'intelligence artificielle.
            Il appartient au concepteur de relire, modifier et compléter les résultats pour l'adapter à son projet et aux normes en vigueur.</p>
        </div>
      </div>
    </div>
    <!-- END Alert Message -->

    <!-- Input Form -->
    <div class="row poste-input">
      <div class="col-md-10">
        <input type="text"
          #inputArea
          [ngStyle]="{'border-color': (errorMessage ?'red':'gainsboro')  }"
          class="input-field" 
          id="text-input"
          placeholder="Titre du poste à décrire"
          (keydown)="changeErrorMessage()"
          (keydown.tab)="GoToTheEndLine($event)"
          (keydown.enter)="generateCCTP($event)"
          [(ngModel)]="textInput"
        >

        <p class="text-danger" *ngIf="errorMessage">Veuilliez saisir votre poste!!!</p>
      </div>
      <div class="col-md-2">
        <button class="btn1 btn btn-primary" (click)="generateCCTP($event)" [disabled]="loadingData">Générer la description</button>
      </div>
    </div>

    
    <div class="row" *ngIf="divVisibility">
      <div class="col-md-12">  
        <div class="result">
          
          <div class="horizontal-items justify-right">

            <p class="score-fiabilite-text  ag-para-1 ag-weight-700 horizontal-items margin-right-18">
              <span class="margin-right-7">Score de fiabilité : </span>
              <span [ngStyle]=" {'color': color}">  {{score}} %</span>
            </p>
            
            <div class="copie" (click)="copyToClipboardWithParameter(myText)" > 
              <i matTooltip="Copie"  class="fa fa-clone" ></i>
              Copier le résultat
            </div>

          </div>

          <p #myText [innerHTML]="resultat"></p> 
        </div>
      </div>
    </div>
    <!-- END Input Form -->

  </div>
  
  <app-spinner-with-quotes *ngIf="loadingData"></app-spinner-with-quotes>
</div>

<app-chatgpt-regenerate-modal
  (regenerate)="regenerateCCTP()"
></app-chatgpt-regenerate-modal>

<app-archi-expired-gpt-modal></app-archi-expired-gpt-modal>