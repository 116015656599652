import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgSharedModule } from 'src/app/all/ngshared.module';


// Servies
import { ArtisansSuggestionsService } from 'src/app/_services';
/// RxJs
import { Subscription } from 'rxjs';
//import { ArchitectModule } from '../architect.module';

@Component({
    selector: 'app-suggeted-artisans',
    imports:[
      
      NgSharedModule
    ],
    templateUrl: './suggeted-artisans.component.html',
    styleUrls: ['./suggeted-artisans.component.scss'],
    standalone: true,

})
export class SuggetedArtisansComponent implements OnInit {
  
  private ceProjectId: string = "";
  public projectInfo = {
    title: "",
    project: ""
  }
  public loading: boolean = true;
  public suggestionsData: any[] = [];
  public artisansIdsList: any[] = [];
  public artisansRatings: any = {} // key is artisan._id value is the average rating

  //Type
  public typeList = {
    'contractant-general':"Contractant général",
    'entreprise-generale':"Entreprise générale",
    'bureau-d-etude':"Bureau d'études",
    'artisan-entreprise-specialisee':"Artisan / Entreprise spécialisée",
    'fabriquant-revendeur-materiaux':"Fabriquant / Revendeur matériaux",
  };
  //travaux
  public travauxList:any = {
    "renovation": "Rénovation",
    "extension": "Extension",
    "surelevation": "Surélévation",
    "construction-neuve": "Construction Neuve",
  };

  public budgetColors: any = {
    "a": "#11AF22",
    "b": "#E08D00",
    "c": "#F7653B",
    "d": "#EF3061",
  };
  public budgetTexts: any = {
    a: "Note de solvabilité excellente",
    b: "Note de solvabilité bonne",
    c: "Note de solvabilité modérée",
    d: "Note de solvabilité faible"
  }

  private subscriptions: Array<Subscription> = [];

  constructor(
    private artisansSuggestionsService: ArtisansSuggestionsService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getCurrentRoute();
  }
  private getCurrentRoute(){
    this.route.params.forEach((params: Params) => {
      if (params['ceProjectId']) {
        this.ceProjectId = params['ceProjectId'];
        this.loadArtisansSuggestions();
      }
    })
  }
  private showProjectInfo(suggestedArtisan){
    let { title='', natureTravaux='', type='', surface='0' } = suggestedArtisan;

    // make first letter uppercase
    natureTravaux = this.capitalizeFirstLetter(natureTravaux);
    type =  this.capitalizeFirstLetter(type);

    // set data
    this.projectInfo.title = title;
    this.projectInfo.project = `${natureTravaux} - ${type} - ${surface} m²`;    
  }

  private loadArtisansSuggestions(){
    this.loading = true;
    this.subscriptions.push(
      this.artisansSuggestionsService.getArtisansSuggetions(this.ceProjectId)
        .subscribe(
          data => {
            this.showProjectInfo(data[0]);
            this.suggestionsData = this.formatArtisansSuggestionsByLots(data);
            
            this.loading = false;
          },
          err => {
            console.log("loadArtisansSuggestions : ", err);
          },
        )
    );
  }
  private loadArtisansRecommendations(artisansIdsList){
    this.subscriptions.push(
      this.artisansSuggestionsService.getArtisansRecommendations(artisansIdsList)
        .subscribe(
          data => {
            this.formateArtisansRatings(data);
          },
          err => {
            console.log("loadArtisansRecommendations :", err);
          },
        )
    );
  }
  private formateArtisansRatings(recommendations){
    // artisansRatings
    for (let i = 0; i < recommendations.length; i++) {
      const element = recommendations[i];

      let {artisan,
        execution, disponibilite, respectDevis, respectProjet, proprete, 
        respectDelais, competitivite, tolerence 
      } = element;
        
      let ratingsList = [
        execution, disponibilite, respectDevis, respectProjet, 
        proprete, respectDelais, competitivite, tolerence
      ];

      let calculatedAverage = ratingsList.reduce((partialSum, item) => partialSum + item, 0) / ratingsList.length;
      let roundedCalculatedAverage = calculatedAverage.toFixed(2);      
      
      let artisanAverageRating = this.roundHalf(roundedCalculatedAverage);

      this.artisansRatings[artisan] = artisanAverageRating;   
    }
  }
  /// round to the next whole number, or to half
  public roundHalf(num) {
    return Math.ceil(num*2)/2;
  }
  private capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  private formatArtisansSuggestionsByLots(data){
    let formattedData = [];
    
    for(let i = 0; i < data.length; i++){
      const updatedAt = data[i].updatedAt;
      let artisan = data[i].artisan;

      const { albums } = artisan;


      let lots = data[i].lots;
      artisan["budget"] = data[i].budget; 
      artisan["message"] = data[i].message; 
      artisan["docsAvailable"] = data[i].docsAvailable;

      // if artisan don't have logo, use one of his realisations photo
      if(albums.length){
        const firstAlbum = albums[0];
        const { photos } = firstAlbum;
      }

      const allLotsAssingedToThisArtisan = this.allLotsAssignedToArtisan(lots);

      if(allLotsAssingedToThisArtisan){
        artisan["allLotsAssinged"] = true;
      }

      // push artisan id if not aleady added
      if(!this.artisansIdsList.includes(artisan._id)){
        this.artisansIdsList.push(artisan._id);
      }

      for(let j = 0; j < lots.length; j++){
        let currentLot = lots[j];
        const { id } = currentLot;

        let lotExists = formattedData.find(item => item.lotName == currentLot.name);

        // if artisan has all lots assigned, & the current lot id is not general, don't push to it
        if(artisan["allLotsAssinged"] && id != "general"){
          break;
        }

        if(lotExists){ // lot exists (push artisan to it)
          lotExists.artisans.push(artisan);
        }else{ // add new lot with the current loop artisan
          formattedData.push(
            {
              lotName: currentLot.name,
              artisans: [artisan],
              updatedAt: updatedAt
            }
          );
        }
      }

    }
    /// fetch all artisans recommendations
    this.loadArtisansRecommendations(this.artisansIdsList);

    // make "Tout corps d'états"  appear first
    const toutCorpsEtatsIndex = formattedData.findIndex(item => item["lotName"] == "Tout corps d'états");
    if(toutCorpsEtatsIndex != -1 && toutCorpsEtatsIndex != 0){
      // make copy
      const sectionCopy = {...formattedData[toutCorpsEtatsIndex]};
      // remove from the array
      formattedData.splice(toutCorpsEtatsIndex, 1);
      // push the copied selction to top
      formattedData.unshift(sectionCopy);
    }

    return formattedData;
  }

  private allLotsAssignedToArtisan(lots){
    let allLotsAssigned = false;

    for(let i = 0; i < lots.length; i++){
      let currentLot = lots[i];

      if(currentLot["id"] == "general"){
        allLotsAssigned = true;
        break;
      }
    }

    return allLotsAssigned
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
