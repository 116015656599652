<div class="all card-style-2">
  <app-header [cssClass]="'fixed-bar'" ></app-header>
  <app-ce-project-nav
    [disable]="loadingData"
    [projectId]="projectId"
    selected="acv"
    [DPGFId]="dpgfId"></app-ce-project-nav>
  <div *ngIf="showWelcomeScreen">

  <div class="loading-page" *ngIf="loadingData">
    <div style="margin-top:70px;">
      <app-spinner></app-spinner>
    </div>
  </div>

  <div style="margin-left: 100px;">
    <div class="container-fluid header-container" >
      <app-acv-main-navbar 
      [dpgfId]="dpgfId" 
      [ceProject]="projectId"
      [isProjectDemo]="isProjectDemo"
      [acvData]="acvData"
      [showWelcomeScreen]="showWelcomeScreen" 
      [projectTitle]="projectTitle" 
      (tabSelected)="selectTab($event)" 
      (resultEvent)="setResult($event)"
      (updateAcv)="setNewAcv($event)"
      ></app-acv-main-navbar>
    </div>


    <div class="container-fluid silver-arthens-grey-bg loading-page" *ngIf="loadingData && showWelcomeScreen">
      <app-spinner></app-spinner>
    </div>
    <div class="container-fluid silver-arthens-grey-bg" *ngIf="!loadingData && showWelcomeScreen">
      <container-element [ngSwitch]="selectedTab">
        <!-- Description -->
        <app-acv-project-description-page *ngSwitchCase="'description_du_project'"
          (sendToParent)="dataChanged('projectDescription', $event)"
          [dpgfId]="dpgfId"
          [isProjectDemo]="isProjectDemo"
          [acvData]="acvData"
          [state]="globalTabsState['projectDescription']"
          >
        </app-acv-project-description-page>
        <!-- Composant -->
        <!-- tocorect (process issue) -->
        <app-acv-project-composant-page *ngSwitchCase="'composant'"
          [dpgfId]="dpgfId"
          [ceProject]="projectId"
          [acvData]="acvData"
          [selectedZone]="selectedZone"
          [isProjectDemo]="isProjectDemo"
          >
        </app-acv-project-composant-page>
        <!-- Energy -->
        <app-acv-project-energie-page *ngSwitchCase="'energie'"
          [dpgfId]="dpgfId"
          [ceProject]="projectId"
          [RSETEnergy]="RSETEnergy"
          [isProjectDemo]="isProjectDemo"
          [acvData]="acvData"
          (importRSETEvent)="clickRSETinput()"
          >
        </app-acv-project-energie-page>
        <!-- Eau -->
        <app-acv-project-eau-page *ngSwitchCase="'eau'"
          (sendToParent)="dataChanged('projectEau', $event)"
          [dpgfId]="dpgfId"
          [isProjectDemo]="isProjectDemo"
          [acvData]="acvData"
          [state]="globalTabsState['projectEau']"
          >
        </app-acv-project-eau-page>
        <!-- Chantier -->
        <!-- tocorect (process issue) -->
        <app-acv-project-chantier-page *ngSwitchCase="'chantier'"
          (sendToParent)="dataChanged('projectChantier', $event)"
          [dpgfId]="dpgfId"
          [ceProjectId]="projectId"
          [isProjectDemo]="isProjectDemo"
          [projectLicence]="projectLicence"
          [acvData]="acvData"
          >
        </app-acv-project-chantier-page>

        <!-- resultats_et_certifications -->
        <app-acv-results-page *ngSwitchCase="'results'"
          [dpgfId]="dpgfId"
          [ceProject]="projectId"
          [projectTitle]="projectTitle"
          [acvData]="acvData"
          (showComposantEvent)="goToZone($event)">
        </app-acv-results-page>

      </container-element>
    </div>
  </div>

  <!-- Need Subscriiption  -->
  <app-need-subscription-popup-2></app-need-subscription-popup-2>
  </div>
  <app-acv-welcome-screen *ngIf="!showWelcomeScreen" [isDemo]="isProjectDemo" [selectedWelcomeScreen]="selectedWelcomeScreen"></app-acv-welcome-screen>

</div>
