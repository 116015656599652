<!-- App Header -->
<div class="all">

  <app-header></app-header>
  <app-artisan-cgu-modal></app-artisan-cgu-modal>
  <br><br>
  <div class="modal-spinner" *ngIf="loadingData">
    <div class="spinner-center">
      <app-spinner [size]="'big'"></app-spinner>
    </div>
  </div>
  <div class="text-size-weight padding-left-165 col-md-4">
    <div class="cursor" (click)="showProfil('perso')" [ngClass]="{'actived': page=='perso'}">
      Informations personnelles
    </div>
    <div class="cursor" (click)="showProfil('infoEntreprise')" [ngClass]="{'actived': page=='infoEntreprise'}" style="padding-top: 21px;">
      Informations de l’entreprise
    </div>
    <div class="cursor" (click)="showProfil('gestionCompte')" [ngClass]="{'actived': page=='gestionCompte'}" style="padding-top: 21px;">
      Gestion du compte
    </div>
  </div>
  <div *ngIf="page=='perso'" id="perso" class="col-md-8">
    <app-artisan-edit-info-perso (userUpdated)="userNewInfo($event)" [user]="userArtisan" [artisanEmailAgency]="artisan.emailAgency"></app-artisan-edit-info-perso>
  </div>
  <div *ngIf="page=='infoEntreprise'" id="infoEntreprise" class="col-md-8">
    <app-artisan-edit-info-entreprise (artisanData)="updateDataArtisan($event)" [(artisan)]="artisan"></app-artisan-edit-info-entreprise>
  </div>
  <div *ngIf="page=='gestionCompte'" id="gestionCompte" class="col-md-8">
    <app-artisan-edit-gestion-compte [(user)]="userArtisan" (newUserEmail)="getTheNewEmailUser($event)"></app-artisan-edit-gestion-compte>
  </div>
  
</div>
<!-- <div class="all">

  <app-header></app-header>
  <app-artisan-cgu-modal></app-artisan-cgu-modal>
  <br><br>
  <div class="modal-spinner" *ngIf="loadingData">
    <div class="spinner-center">
      <app-spinner [size]="'big'"></app-spinner>
    </div>
  </div>
  <div class="row">
    <div class="container-box">
      <div class="floating-card">
        <ul class="nav nav-pills nav-justified">
          <li class="nav-item active"><a data-toggle="tab" href="#perso" (click)="setButtonHide()">Informations personnelles </a></li>
          <li class="nav-item"><a data-toggle="tab" href="#genral" (click)="setButtonVisible()">Informations principales</a></li>
          <li class="nav-item"><a data-toggle="tab" href="#detail" (click)="formDetail.setDepartement();setButtonVisible()">Informations complémentaires</a></li>
          <li class="nav-item"><a data-toggle="tab" href="#doc" (click)="setButtonVisible()">Documents</a></li>
        </ul>

        <div class="row">
          <div id="all-tabs" class="tab-content">
            <div id="perso" class="tab-pane fade in active">
              <div class="col-md-2"></div>
              <div class="col-md-8 col-xs-11 form">
                <app-artisan-signup-login [disabled]=false [(artisan)]="user" [hidePassword]=true></app-artisan-signup-login>
              </div>
              <div class="col-md-2"></div>
            </div>
            <div id="doc" class="tab-pane fade">
              <div class="col-md-2"></div>
              <div class="col-md-8 col-xs-11 form">
                <app-artisan-signup-file (fileAdded)="docFileUploaded()" [(artisan)]="document"></app-artisan-signup-file>
              </div>
              <div class="col-md-2"></div>
            </div>
            <div id="genral" class="tab-pane fade">
              <div class="col-md-2"></div>
              <div class="col-md-8 col-xs-11 form">
                <app-artisan-signup-general [(agency)]="general"></app-artisan-signup-general>
              </div>
              <div class="col-md-2"></div>
            </div>

            <div id="detail" class="tab-pane fade">
              <div class="col-md-2"></div>
              <div class="col-md-8 col-xs-11 form">
                <app-artisan-signup-detail [(detail)]="detail" [(departement)]="departement"></app-artisan-signup-detail>
              </div>
              <div class="col-md-2"></div>
            </div>

          </div>
        </div>
        <div class="row pt-1">
          <div class="center">
            <button *ngIf="visibleButton" [disabled]="loading" (click)="update()" href="#" class="btn-primary-color">
              <div *ngIf="!loading">Enregistrer les modifications</div>
              <app-spinner *ngIf="loading"></app-spinner>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
