import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  selector: 'app-architect-emails-templates-dropdown',
  standalone: true,
  imports: [
    NgSharedModule
  ],
  templateUrl: './architect-emails-templates-dropdown.component.html',
  styleUrl: './architect-emails-templates-dropdown.component.scss'
})
export class ArchitectEmailsTemplatesDropdownComponent {

  @Input() emailsTemplates: any = [];
  @Output() emailTemplateSelected: EventEmitter<any> = new  EventEmitter<any>();

  public emitEmailTemplateSelected(emailTemplate){
    this.emailTemplateSelected.emit(emailTemplate);
  }

}
