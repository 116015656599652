import { Component, OnInit, Input,Output,EventEmitter,ChangeDetectionStrategy, ElementRef, ChangeDetectorRef, ApplicationRef, SimpleChanges, HostListener, ViewChildren, QueryList, NO_ERRORS_SCHEMA} from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { UnitClass } from './units'
import { DragulaService } from 'ng2-dragula'; 
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { Store , select} from '@ngrx/store';
import { ArchiDbService, AuthService, DpgfPostesService, ProductService } from '../../../_services/index';
import * as actions from '../_store/dpgf.actions';
import * as fromSelectors from '../_store';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ViewEncapsulation } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser';
import { UniteInputDpgfComponent } from '../unite-input-dpgf/unite-input-dpgf.component';
import { InputDpgfComponent } from '../input-dpgf/input-dpgf.component';
import { int } from 'aws-sdk/clients/datapipeline';

import { WebView2Service } from 'src/app/_services/webview2.service.service';
import { DpgfModule } from '../../dpgf.module';
import { PillDpgfComponent } from '../pill-dpgf/pill-dpgf.component';
import { ReadMoreComponent } from 'src/app/all/read-more/read-more.component';
import { UploadFileComponent } from 'src/app/all/upload-file/upload-file.component';
import { ArchiDpgfComponent } from 'src/app/pages/architect/archi-dpgf/archi-dpgf.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { MaxLibraryPostsModalComponent } from 'src/app/architect/max-library-posts-modal/max-library-posts-modal.component';
import { UpgradeToIntegralModalComponent } from 'src/app/architect/upgrade-to-integral-modal/upgrade-to-integral-modal.component';
import { DeleteDCEPostModalComponent } from '../delete-dcepost-modal/delete-dcepost-modal.component';
import { RevitToPostComponent } from '../revit-to-post/revit-to-post.component';
import { PostToRevitComponent } from '../post-to-revit/post-to-revit.component';
import { ArchiDbPostComponent } from '../archi-db-post/archi-db-post.component';// design system components
import { UserOnWithoutLicenseModalComponent } from 'src/app/_design_system_components/user-on-without-license-modal/user-on-without-license-modal.component';

import { environment } from 'src/environments/environment';
const _ = require('lodash');

declare var $;

type T = Window & typeof globalThis;
// @ts-ignore
interface window extends T {
    chrome?: any;
    analytics?: any;
    clickRevit?: Function;
}


@Component({
  standalone: true,
  
  selector: 'app-table-lot-dpgf',
  templateUrl: './table-lot-dpgf.component.html',
  styleUrls: ['./table-lot-dpgf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports:[
    DpgfModule,
    PillDpgfComponent,
    UniteInputDpgfComponent,
    ReadMoreComponent,
    InputDpgfComponent,
    UploadFileComponent,
    ArchiDpgfComponent,
    SpinnerComponent,
    MaxLibraryPostsModalComponent,
    UpgradeToIntegralModalComponent,
    DeleteDCEPostModalComponent,
    PostToRevitComponent,
    ArchiDbPostComponent,
    UserOnWithoutLicenseModalComponent,
    NgSharedModule
  ],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({maxHeight: '300px', opacity: 0}),
          animate('1000ms', style({maxHeight: '0px', opacity: 1}))
        ]),
        transition(':leave', [
          style({maxHeight: '0px', opacity: 1}),
          animate('1000ms', style({maxHeight: '300px', opacity: 0}))
        ])
      ]
    )
  ],
})
export class TableLotDpgfComponent implements OnInit {
  public nextFocusInput=0
  public selectedLotSousLots = {};
  public inputChildrenArray: UniteInputDpgfComponent[];
  public inputDesignationChildrenArray: InputDpgfComponent[];
  public tabToTry: [];

  //rachid
  postProducts: { [postId: string]: any[] } = {};
  productEntries: any[] = [];
  private allProducts : any[] = [];
  public products : any = [];
  public productUrl : String = '';
  public openedProductsForms: any = {}
  collapseState: { [postId: string]: boolean } = {};
  public isLoading : Boolean = false;

  public isChoose: any;mixedTab = [];
  public showListPosts: any = {};isAllPostesSelected:boolean = false;sousLot;sectionMoved=false;
  public isNewItemAdded = false;
 
  window: window;
  eventCaptureElement = document.createElement("a");
  //public eventCaptureElement: HTMLAnchorElement = document.createElement("a");
  //public window: window;
  public openSlider: boolean = false;
  public postes: any;
  public dpgfId: any;
  public chosenSousLotI: any;sousLots: any;postI: any;sousLotI: any;lotId: any;isRevitDesktop: boolean;posti=0;
 // public revitElements =[];
  @Input()canEdit:boolean = true;
  @Input()lot:any;
  @Input()loti:any;
  @Input()view:any;
  @Input()estimate:boolean;
  @Input()showQuantity:boolean;
  @Input()mode:string;
  @Input()modeList;
  @Input()currentLot:string;
  @Input()currentSousLot:string;
  @Input()currentPoste:string;
  @Input()currentSousPoste:string;
  @Input()currentColumn:number;
  @Input()currentProduct:string;
  @Input()localisations;
  @Input() isAutomateNumberIsChecked;
  @Input() modify : boolean;
  @Input() editingTitle: boolean ;
  @Input()displaySide:boolean=false;
  @Input()tab: string='listPostes';
  @Input()displayRevitSide:boolean=false;
  @Output()openCctp = new EventEmitter<any>();
  @Output()openRevitSide = new EventEmitter<any>();
  @Output()openSideCstb = new EventEmitter<any>();
  @Output()openSideArchiDb = new EventEmitter<any>();
  @Output()showAcceptModal:EventEmitter<any> = new EventEmitter<any>();
  @Output()showDpgfTable:EventEmitter<any> = new EventEmitter<any>();
  @Output()elementFromRevit :EventEmitter<any> = new EventEmitter<any>();
  @Output()postesElements :EventEmitter<any> = new EventEmitter<any>();
  @Output()openAddElementToPost = new EventEmitter<any>();
  @Output()openPostToRevitSlider = new EventEmitter<any>();
  @ViewChildren(UniteInputDpgfComponent) inputChildren: QueryList<UniteInputDpgfComponent>;

  @ViewChildren(InputDpgfComponent) inputDesignationChildren: QueryList<InputDpgfComponent>;

  @ViewChildren('inputs') inputChilds:QueryList<InputDpgfComponent>;
  @ViewChildren('unitInputs') unitInputChilds:QueryList<UniteInputDpgfComponent>;
  public revitButtonClicked: boolean=false;
  public isPostHasElments: boolean=false;
  
  @HostListener('document:keydown.tab', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) { 
      
      if(this.editingTitle) return;
      event.preventDefault();
      if(this.nextFocusInput>=1 && !this.isChoose && this.nextFocusInput%6!=0) this.inputChildrenArray[this.nextFocusInput].focusedOutOn();
      else if(this.nextFocusInput>1 && !this.isChoose && this.nextFocusInput%6==0){}
      if((this.nextFocusInput+1)%6==0){
        // this.inputChildrenArray[this.nextFocusInput].focusedOutOn();
        this.nextFocusInput+=1;
      }
      setTimeout(() => {
        if(this.inputChildrenArray[this.nextFocusInput+1]){
          this.inputChildrenArray[this.nextFocusInput+1].focusedOn();
          this.inputChildrenArray[this.nextFocusInput+1].editable();
        }
      }, 100);
  }
  @HostListener('document:keydown.shift.tab', ['$event'])
    onKeyDown(event) {
      if(this.editingTitle) return;
      event.preventDefault();
      if(this.nextFocusInput>=1 && !this.isChoose && this.nextFocusInput%6!=0) this.inputChildrenArray[this.nextFocusInput].focusedOutOn();
      else if(this.nextFocusInput>1 && !this.isChoose && this.nextFocusInput%6==0){}
      if((this.nextFocusInput-1)%6==0){
        // this.inputChildrenArray[this.nextFocusInput].focusedOutOn();
        this.nextFocusInput-=1;
      }
      setTimeout(() => {
        if(this.inputChildrenArray[this.nextFocusInput-1]){
          this.inputChildrenArray[this.nextFocusInput-1].focusedOn();
          this.inputChildrenArray[this.nextFocusInput-1].editable();
        }
      }, 100);
    }
    @HostListener('document:keydown.enter', ['$event'])
    onKeyDownEnter(event) {
      event.preventDefault();
      if(this.nextFocusInput%6!=0)this.inputChildrenArray[this.nextFocusInput].focusedOutOn();
      else {
       let position = (this.inputDesignationChildrenArray||[]).findIndex(el=>el.num==this.nextFocusInput);
       if(position != -1){
         this.inputDesignationChildren['_results'][position].focusedOnOut();
       }
      }
    }
  public selectedPosteIdForRevit: string='';
  public selectedPosteTitleForRevit: string='';
  private subscriptions: Array<Subscription> = [];
  public units = new UnitClass().all;
  public localisationSugg = ["Ensemble","Séjour","Cuisine","Entrée","Chambre 1","Dressing","Chambre 2","Chambre 3","Chambre 4","SDE 1","SDE 2","Rez-de-chaussée","R+1","R+2","R+3","Garage","Extérieur","Piscine","Jardin","Terrasse","Combles","Couloir"]
  public uniteSugg =["m2","m3","ml","m","u","ens","forfait","hr","kg","l"];
  public tavSugg = [20,10,5.5];
  public vue = "dpgf"
  public nbLine = 0;
  public newDossier;
  public hasCstb = false;projectLicence;
  public maxNbLine = 200;
  public cctpEdit = {
    cctp : "" ,loti : 1, sousloti : 1,posti : 1
  };
  public showCCtp = {};
  public pasteRows = [];
  public sousLotDelete = {};
  public sousLotPast = 0;hideDiv;
  public matchingOption = [
    {label:'Titre du poste', value:"designation"},
    {label:'Localisation', value:"localisation"},
    {label:'Quantité', value:"qte"},
    {label:'Unité', value:"unite"},
    {label:'TVA', value:"tva"},
    {label:'CCTP', value:"cctp"},
    {label:'Prix U. HT', value:"unitePriceArchi"},
    {label:'Prix HT', value:"priceArchi"},
    {label:'Ne pas matcher', value:"none"}]
  public matching = []
  public next;
  public postArchiDb;
  public refreshLots=false;
  public currentDossier: any;
  public countArchiDbPosts: any = 0;
  public currentLicence: any;posteRemove=false;
  public displaySideDpgf;
  public lots;
  public movingPosts;
  public chosenLotI;
  public licence: string;
  public accessCstb: any;removeSousLot = false;isPosteDupplicated:boolean=false;incrementIndexes = 0;incrementIndexes2 = 0;
  public cstbSuggestion:any = [];
  public submited:any = {};
  public isSuggestionButtonVisible={};sousLotiIndex;indexPoste;
  public recommendedLots = ['Enlèvement des gravois','Cloisons de distribution en plaques de plâtre sur ossature métallique - Cloison de type 72/48','Cloisons de distribution en plaques de plâtre sur ossature métallique - Cloison de type 72/48','Enlèvement des gravois','lenoCloisons de distribution en plaques de plâtre sur ossature métallique - Cloison de type 72/48vo','Démolition de murs en élévation','Enlèvement des gravois',];
  public icons = { "tech_empty": "description_tech_empty.svg", "tech_full": "description_tech_full.svg", "enviro_empty": "description_enviro_empty.svg", "enviro_full": "description_enviro_full.svg",'enviro_partial_full':"description_enviro_partial_full.svg",'sous-poste':'add-sous-poste.svg' }
  public isChiffrage;currentSousLoti=0;
  public maxLibraryPostsModalshowLibraryText: boolean = false;newSousLot=false;sousPostDeleted = false;
  public selectedPostToDelete: any = {
    sousloti: "",
    posti: "",
    designation: "",
  };
  public selectedSousPostToDelete: any = {
    sousloti: "",
    posti: "",
    designation: "",
    sousPosteIndex:""
  };indexSousLot=0;
  public selectedSousLotToDelete: any = {
    sousloti: "",
    title:""
  };
  constructor(
              private dragulaService: DragulaService, 
              private cdr: ChangeDetectorRef,
              private confirmationService: ConfirmationService,
              private store: Store,
              public authService: AuthService,
              private archiDbService: ArchiDbService,
              private dpgfPostesService: DpgfPostesService,
              private router: Router,
              private cd: ChangeDetectorRef,
              private sanitizer: DomSanitizer,
              private wv2Service:WebView2Service,
              private productService:ProductService) {}

  ngOnInit() {
    this.isRevitDesktop = navigator.userAgent.includes("Autodesk.Revit/2024");
    this.store.pipe(select(fromSelectors.getTotal, { name: "nbLines" })).subscribe(data=>{
      this.nbLine = data;
    })
    this.store.pipe(select(fromSelectors.getDpgf)).subscribe((dpgf)=>{
      this.lots=dpgf.data;
      // console.log('this.lots',this.lots)
      this.dpgfId= dpgf._id;
    })
    this.store.pipe(select(fromSelectors.getButtonState)).subscribe(data=>{
      this.isSuggestionButtonVisible = data;
    })
    this.store.pipe(select(fromSelectors.getDpgfStore)).subscribe((dpgf)=>{
      this.isChiffrage=_.get(dpgf,'type','')=="pricing"?true:false
    })
    this.store.pipe(select(fromSelectors.getIsAllPostSeleted)).subscribe((state)=>{
      this.isAllPostesSelected=state;
    })
    // this.subscriptions.push(
      this.store.pipe(select(fromSelectors.getAny,{name:'movingPosts'})).subscribe((movingPosts)=>{
      if(this.movingPosts && this.movingPosts!=movingPosts) {
        $("#dpgf-navigation").hide();
        $("#move-section").hide();
        $(".modal-backdrop").hide();
      }
      this.movingPosts=movingPosts;
    })
    this.store.pipe(select(fromSelectors.getAny,{name:'projectLicence'})).subscribe((data)=>{
     this.projectLicence = data;
    })
    // )
    this.licence = this.authService.licence || "base";
    this.accessCstb=_.get(this.authService,'currentUser.accessCstb',false)
    this.authService.features.subscribe((f)=>{
      if(f.lineNb){
        this.maxNbLine = f.lineNb || 200;
      }
      this.hasCstb = f.cstb
    })
    if(!this.isChiffrage)this.getCstbSuggestion()
    if(this.lot.id){
      // setTimeout to wait for the dragula to be created from the html [dragula]="''"
      setTimeout(() => { // remove dragable posts & sousPosts for artisan
        this.removeDraggable("POSTES" + this.lot.id);
        this.removeDraggable("SOUSPOSTES" + this.lot.id+this.lot.sousLots[this.indexSousLot].id+this.posti);
      }, 1000);
   }
    this.isSuggestionButtonVisible[this.loti] = true;
  }
  ngAfterViewInit(){
    this.inputChildrenArray = this.unitInputChilds['_results'];
    // this.inputDesignationChildrenArray = this.inputChilds['_results']
    // console.log('this.inputDesignationChildrenArray',this.inputDesignationChildrenArray)
  }
  ngAfterViewChecked(){
    if(this.isNewItemAdded || this.removeSousLot || this.posteRemove || this.sousPostDeleted){
      this.inputChildrenArray = this.unitInputChilds['_results'];
      this.isNewItemAdded = false;
      this.posteRemove = false;
      this.removeSousLot = false;
      this.sousPostDeleted = false;
    }
    // this.inputDesignationChildrenArray = this.inputChilds['_results']

  }
  private removeDraggable(dragulaGroupName){
    let found = this.dragulaService.find(dragulaGroupName);
    if(found){
      found.options.moves = (el, container, handle) => {
        if(typeof handle.className == "string"){
          return  handle.className.includes('handle') && this.mode == "archi";
        }else{
          return false;
        }
      }
    }
  }

  incremented(isFirst?){
    if(isFirst) this.incrementIndexes=0;
    return this.incrementIndexes++
  }
  ngOnChanges(changes: SimpleChanges): void {
    
    if(changes?.isAutomateNumberIsChecked?.currentValue){
      this.isAutomateNumberIsChecked = changes.isAutomateNumberIsChecked.currentValue;
      this.cdr.detectChanges();
    }

    if(changes.lot){
      if(changes.lot.firstChange && changes.lot.currentValue.recalculate) {
        this.store.dispatch(actions.calculate({modeList : this.modeList , mode : this.mode}))
        this.store.dispatch(actions.updateLot({lotId : this.lot.id , data : {recalculate:false}}));
      }
    }
  }
  public moveSection(indexSousLoti,sousLot){
    this.sousLot = sousLot;
    let licence=this.authService.licence;
    this.indexSousLot = indexSousLoti
    this.sectionMoved = true;
    if(licence!='without' || this.projectLicence!='none'){
      $('#move-section').modal('toggle');
    }
    else $('#upgrade-modal').modal('show');
  }
  public closeModal(){
    $("#add-post-to-element").modal('toggle');
  }
  public isHtmlEmpty(text){
    return (text||'').replace(/<[^>]*>?/gm, '').length != 0
  }
  public lineNumber(loti,sousloti,posti){
    let res = (loti) + '.' + (sousloti) + '.'  + (posti+1) + ' - ';
    return res;
  }
  public lineNumberSousPoste(loti,sousloti,posti,sousPostIndex){
    let res = (loti) + '.' + (sousloti) + '.'  + (posti+1) + '.' + (sousPostIndex+1) +' - ';
    return res;
  }
  trackById(index,item){
    if(!item)return index;
    return (item.id + "_" +  index);
  }
  //Lot
  public updateEditingTitle(e){
    this.editingTitle=e;
  }
  public goBacktoHome(){
    this.showDpgfTable.emit(true);
    this.store.dispatch(actions.saveDpgfLotToServer({}));
    this.store.dispatch(actions.emptyLot({}));
  }
  public deleteLot(){
    this.store.dispatch(actions.removeLot({loti : this.lot.id}));
  }
  public updateLot(data){
    if(this.licence!='without' || this.projectLicence!='none'){
      this.store.dispatch(actions.updateLot({loti : this.loti , data : data}));
      this.store.dispatch(actions.calculate({}));
    }
    else $('#upgrade-modal').modal('show');
  }
  public setLotTotalHt(value){
    this.store.dispatch(actions.updatePriceLot({totalName : this.modeList[this.mode].totalName, totalttcName : this.modeList[this.mode].totalttcName , value : value}));
  }
  //SousLot
  public updateSousLotState(data){
    if(this.licence!='without' || this.projectLicence!='none'){
      this.store.dispatch(actions.updateSousLotState({loti : this.loti , data : data}));
      this.store.dispatch(actions.calculate({}));
    }
    else $('#upgrade-modal').modal('show');
  }
  public duplicateSection(section){
    this.store.dispatch(actions.duplicateSection({sousLot:section}))
    if(!environment.onPremise)window.analytics.track('duplicate_section',{properties:{acValue: 'duplicate_section'}});
    this.newSousLot = true;
    this.isNewItemAdded = true;
  }
  public addSousLot(){
    let licence=this.authService.licence;
    if(licence!='without' || this.projectLicence!='none'){
      this.store.dispatch(actions.addNewSousLot({loti : this.loti}));
      this.newSousLot = true;
      this.isNewItemAdded = true;
      setTimeout(()=>{
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
      },10)
    }
    else $('#upgrade-modal').modal('show');
  }
  public changeStateSuggestion(key){
    this.submited[key] = true;
    this.store.dispatch(actions.changeStateButtonSuggestion({buttonSuggestion:!this.isSuggestionButtonVisible[key],key}))
  }
  public changeButtonStateBasedOnLenghthPostes(postes,key){
    if((postes||[]).length>4 && !this.submited[key]){
      this.store.dispatch(actions.changeStateButtonSuggestion({buttonSuggestion:false,key:key}))
    }
    return true
  }
  public deleteSousLot(){
    // this.sousLotDelete[sousloti] = false
    $("#confirm-delete-dce-sousLot-modal").modal("hide");
    this.store.dispatch(actions.removeSousLot({loti : this.loti,sousloti:this.selectedSousLotToDelete.sousloti}));
    if(!environment.onPremise)window.analytics.track('delete_section',{properties:{acValue: 'delete_section'}});
    this.removeSousLot = true;
    // this.sousLotiIndex = sousloti;
  }
  public updateSousLot(sousloti,title){
    this.store.dispatch(actions.updateSousLot({loti : this.loti,sousloti,title}));
  }
  public moveSousLotDown(j){
    this.store.dispatch(actions.switchSousLot({loti : this.loti, sousLot1 : j,sousLot2 : j+1}));
    if(!environment.onPremise)window.analytics.track('reorganize_section',{properties:{acValue: 'reorganize_section'}});
  }
  public moveSousLotUp(j){
    this.store.dispatch(actions.switchSousLot({loti : this.loti, sousLot1 : j,sousLot2 : j-1}));
    if(!environment.onPremise)window.analytics.track('reorganize_section',{properties:{acValue: 'reorganize_section'}});
  }
  public setSousLotTotalHt(value,sousloti){
    this.store.dispatch(actions.updatePriceSousLot({
      // totalName : this.modeList[this.mode].totalName,
      // totalttcName : this.modeList[this.mode].totalttcName, 
      sousloti : sousloti , 
      value : value,
      // loti : this.loti
    }));
    
  }
  //Poste
  public openCstb(sousloti){
    let maxLibraryPosts = this.authService.premuimFeatures[this.authService.licence].archiDbPostNb;
    //change
    if(this.hasCstb || this.accessCstb || this.projectLicence!='none'){
      this.openSideCstb.emit(sousloti);
    }else if((this.licence == 'plus' || this.licence == 'plus-trim' || this.licence == 'plus-annuel') && this.nbLine >= maxLibraryPosts){
      this.maxLibraryPostsModalshowLibraryText = false;
      $('#max-posts-modal').modal('show');
    }else{
      $('#upgrade-modal').modal('show');
    }
  }
  public openArchiDb(sousloti){
    let maxLibraryPosts = this.authService.premuimFeatures[this.authService.licence].archiDbPostNb;
    let dpgfMaxPosts = (this.authService.premuimFeatures[this.authService.licence]).lineNb;
    if(this.hasCstb || this.accessCstb || this.projectLicence!='none'){
      this.openSideArchiDb.emit(sousloti);
    }else if(this.licence=='without') {
      $('#upgrade-modal').modal("show");
    }else if((this.licence == 'plus' || this.licence == 'plus-trim' || this.licence == 'plus-annuel') && (this.nbLine >= dpgfMaxPosts || this.nbLine >= maxLibraryPosts)){
      this.maxLibraryPostsModalshowLibraryText = false;
      $('#max-posts-modal').modal('show');
    }else{
      this.openSideArchiDb.emit(sousloti);
    } 
  }
  public convertSousPosteToPoste(sousloti,posti,sousPost,sousPosteIndex){
    this.selectedSousPostToDelete.sousloti = sousloti;
    this.selectedSousPostToDelete.posti = posti;
    this.selectedSousPostToDelete.sousPosteIndex = sousPosteIndex; 
    this.deleteSousPoste(true);
    this.store.dispatch(actions.addNewPosteFromSousPoste({loti : this.loti,sousloti,sousPost,posti}));
    if(!environment.onPremise)window.analytics.track('change_souspost_into_post',{properties:{acValue: 'change_souspost_into_post'}});
    this.isNewItemAdded = true;
  }
  public addPoste(sousloti,isAtisan,lotTitle){
    /// if not Auth add new Post (without checking licence)
    let authUser = this.authService.getAuthUser();
    if(!authUser){
      let unit = ((this.units[lotTitle]||{}).unite ||"");
      this.store.dispatch(actions.addNewPoste({loti : this.loti,sousloti,isAtisan,unit}));
      this.sousLotiIndex = sousloti;
      this.isNewItemAdded = true;
      // segment event
      if(!environment.onPremise)window.analytics.track('create_post');
      this.store.dispatch(actions.calculate({}));

      return;
    }

    if(!this.canEdit){
      this.showAcceptModal.emit({});
    }else{
      let dpgfMaxPosts = (this.authService.premuimFeatures[this.authService.licence]).lineNb;
      if(this.licence=='without' && this.projectLicence=='none'){
        $('#user-on-without-license-modal').modal('show');
      }else if( (this.licence == 'plus' || this.licence == 'plus-trim' || this.licence == 'plus-annuel') && this.nbLine >= dpgfMaxPosts && this.projectLicence=='none'){
        this.maxLibraryPostsModalshowLibraryText = false;
        $('#max-posts-modal').modal('show');
      }else{
        let unit = ((this.units[lotTitle]||{}).unite ||"");
        this.store.dispatch(actions.addNewPoste({loti : this.loti,sousloti,isAtisan,unit}));
        this.sousLotiIndex = sousloti;
        this.isNewItemAdded = true;
        // segment event
        if(!environment.onPremise)window.analytics.track('create_post');
        this.store.dispatch(actions.calculate({}));
      }
    }
  }
  addSousPoste(sousloti,isAtisan,lotTitle,indexPoste){
    if(this.licence=='without' && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    
    this.sousLotiIndex = sousloti;
    this.indexPoste = indexPoste;
    let unit = ((this.units[lotTitle]||{}).unite ||"");
    this.store.dispatch(actions.addNewSousPoste({loti : this.loti,sousloti,isAtisan,unit,indexPoste}));
    this.isNewItemAdded = true;
    if(!environment.onPremise)window.analytics.track('add_sous-poste',{properties:{acValue: 'add_sous-poste'}});
  }
  public duplicatePoste(sousloti,posti){
    let dpgfMaxPosts = (this.authService.premuimFeatures[this.authService.licence]).lineNb;
    if(this.licence == 'without' && this.projectLicence=="none"){
      $('#upgrade-modal').modal('show');
    }else if( (this.licence == 'plus' || this.licence == 'plus-trim' || this.licence == 'plus-annuel') && this.nbLine >= dpgfMaxPosts && this.projectLicence=="none"){
      this.maxLibraryPostsModalshowLibraryText = false;
      $('#max-posts-modal').modal('show');
    }else{
      this.store.dispatch(actions.emptyTypeAmount());
      this.store.dispatch(actions.duplicatePoste({loti : this.loti,sousloti,posti}));
      this.sousLotiIndex = sousloti;
      this.indexPoste = posti;
      this.isPosteDupplicated = true;
    }
  }
  public duplicateSousPoste(sousloti,posti,sousPosteIndex){
    let dpgfMaxPosts = (this.authService.premuimFeatures[this.authService.licence]).lineNb;
    if(this.licence == 'without' && this.projectLicence=="none"){
      $('#upgrade-modal').modal('show');
    }else if( (this.licence == 'plus' || this.licence == 'plus-trim' || this.licence == 'plus-annuel') && this.nbLine >= dpgfMaxPosts && this.projectLicence=="none"){
      this.maxLibraryPostsModalshowLibraryText = false;
      $('#max-posts-modal').modal('show');
    }else{
      this.store.dispatch(actions.emptyTypeAmount());
      this.store.dispatch(actions.duplicateSousPoste({loti : this.loti,sousloti,posti,sousPosteIndex}));
      if(!environment.onPremise)window.analytics.track('duplicate_sous-poste',{properties:{acValue: 'duplicate_sous-poste'}});
      this.indexPoste = posti;
      this.sousLotiIndex = sousloti;
    }
  }
  public openConfirmDeletePostModal(sousloti,posti, designation){
    this.selectedPostToDelete.sousloti = sousloti;
    this.selectedPostToDelete.posti = posti;
    this.selectedPostToDelete.designation = designation;    
    $("#confirm-delete-dce-post-modal").modal("show");
  }
  public openConfirmDeleteSousPostModal(sousloti,posti, designation,sousPosteIndex){
    this.selectedSousPostToDelete.sousloti = sousloti;
    this.selectedSousPostToDelete.posti = posti;
    this.selectedSousPostToDelete.designation = designation;   
    this.selectedSousPostToDelete.sousPosteIndex = sousPosteIndex; 
    $("#confirm-delete-dce-sous-post-modal").modal("show");
  }
  public deletePoste(){
    // hide confirm modal popup   
    $("#confirm-delete-dce-post-modal").modal("hide");
    if(this.licence!='without' || this.projectLicence!='none'){
      // this.store.dispatch(actions.removePoste({loti : this.loti,sousloti,posti}));
      this.store.dispatch(actions.removePoste({
        loti : this.loti,
        sousloti: this.selectedPostToDelete.sousloti,
        posti: this.selectedPostToDelete.posti
      }));
      this.posteRemove =  true;
    }
    else{
      $('#upgrade-modal').modal('show');
      // $('#upgrade-to-integral-modal').modal('show');
    }

  }
  public deleteSousPoste(fromConvert=false){
    // hide confirm modal popup   
    $("#confirm-delete-dce-sous-post-modal").modal("hide");

    if(this.licence!='without' || this.projectLicence!='none'){
      this.store.dispatch(actions.removeSousPoste({
        loti : this.loti,
        sousloti: this.selectedSousPostToDelete.sousloti,
        posti: this.selectedSousPostToDelete.posti,
        sousPosteIndex: this.selectedSousPostToDelete.sousPosteIndex,
      }));
      if(!environment.onPremise && !fromConvert)window.analytics.track('delete_sous-poste',{properties:{acValue: 'delete_sous-poste'}});
      this.sousPostDeleted = true;
    }
    else{
      $('#upgrade-modal').modal('show');
      // $('#upgrade-to-integral-modal').modal('show');
    }

  }
  public openConfirmDeleteSousLotModal(sousloti?,title?){
    this.selectedSousLotToDelete['sousloti'] = sousloti;
    this.selectedSousLotToDelete['title'] = title; 
    $("#confirm-delete-dce-sousLot-modal").modal("show");
  }
  public setPosteToAdd(post){
    let maxLibraryPosts = this.authService.premuimFeatures[this.authService.licence].archiDbPostNb;

    if(this.licence=='without' && this.projectLicence=='none'){
      $('#upgrade-modal').modal("show");
    }else if( (this.licence == 'plus' || this.licence == 'plus-trim' || this.licence == 'plus-annuel') && this.countArchiDbPosts >= maxLibraryPosts && this.projectLicence=='none'){
      this.maxLibraryPostsModalshowLibraryText = true;
      $('#max-posts-modal').modal('show');
    }else{
      this.postArchiDb=post;
      $('#import-post').modal("show");
    }
  }
  public changePostesOrder(sousloti,postes){
    if(this.licence!='without' || this.projectLicence!='none'){
      this.store.dispatch(actions.setAllPosteInSouLot({sousloti,postes}));
    }
    else $('#upgrade-modal').modal('show');
  }
  public changeSousPostesOrder(sousloti,posti,sousPostes){
    this.posti = posti;
    this.indexSousLot = sousloti;
    if(this.licence!='without' || this.projectLicence!='none'){
      this.store.dispatch(actions.setOrderSousPosteInPoste({sousloti,posti,sousPostes}));
    }
    else $('#upgrade-modal').modal('show');
  }
  public setPosteAttributs(sousloti,posti,data){

    this.store.dispatch(actions.setPosteData({loti : this.loti,sousloti,posti,data}));
    this.getCstbSuggestion()
    if(data.gid){
      
    }
  }
  public setSousPosteAttributs(sousloti,posti,sousPosteIndex,data){
    if(data.word==this.lot.sousLots[sousloti]['postes'][posti]['sousPostes'][sousPosteIndex].designation) return;
    this.store.dispatch(actions.setSousPosteData({loti : this.loti,sousloti,posti,sousPosteIndex,data}));
    this.getCstbSuggestion2()
    if(data.gid){
    }
  }
  public getCstbSuggestion(){
    let posteIds = this.lot.sousLots.reduce((acc,souslot)=>{
      return acc.concat((souslot.postes||[]).map(poste=>poste.gid||""))
    },[]).filter(a=>a!="")
    this.dpgfPostesService.suggestCstb({lot : this.lot.title, poste : posteIds}).subscribe(
      data=>{   
        this.cstbSuggestion = data;
        this.cdr.detectChanges();
      }
    )
  }
  public getCstbSuggestion2(){
    let sousPosteIds = this.lot.sousLots[0].postes[0].sousPostes.reduce((acc,sousPoste)=>{
      return acc.concat(sousPoste.gid||"")
    },[]).filter(a=>a!="")
    this.dpgfPostesService.suggestCstb({lot : this.lot.title, poste : sousPosteIds}).subscribe(
      data=>{   
        this.cstbSuggestion = data;
        this.cdr.detectChanges();
      }
    )
  }
  public addSuggestionPoste(sousloti,selected){
    let dpgfMaxPosts = (this.authService.premuimFeatures[this.authService.licence]).lineNb;
    let licence=this.licence;

    if(licence == 'without' && this.projectLicence=='none'){
      $('#upgrade-modal').modal('show');
    }else if(['plus','plus-trim','plus-annuel'].includes(licence) && this.nbLine >= dpgfMaxPosts && this.projectLicence=='none'){
      this.maxLibraryPostsModalshowLibraryText = false;
      $('#max-posts-modal').modal('show');
    }else{
      this.store.dispatch(actions.addSuggestionPoste({loti : this.loti,sousloti,selected}));
      this.isNewItemAdded = true;
      setTimeout(()=>{this.getCstbSuggestion()},100)
      if(!environment.onPremise)window.analytics.track('add_suggestion',{properties:{acValue: 'add_suggestion',lot:this.lot.title}});
    }
  }
  public selectPost(sousloti,posti,selected){
    this.store.dispatch(actions.selectPost({loti : this.loti,sousloti,posti,selected}));
  }
  public flagPost(sousloti,posti,flaged,sousPosteIndex?){
    if(sousPosteIndex==undefined) this.store.dispatch(actions.flagPost({loti : this.loti,sousloti,posti,flaged}));
    else {
      this.store.dispatch(actions.flagSoustPost({loti : this.loti,sousloti,posti,flaged,sousPosteIndex}));
      if(!environment.onPremise)window.analytics.track('add_indicateur_sous-poste',{properties:{acValue: 'add_indicateur_sous-poste',lot:this.lot.title}});
    }
  }
  
  public sendPostToLot(lotId, sousLotId){
    this.store.dispatch(actions.movePostes({lotDestinationID:lotId, sousLotDestinationID:sousLotId}));
    this.showListPosts={}

  }
  public sendSectionToLot(lotId){
    this.store.dispatch(actions.moveSection({lotDestinationID:lotId, indexSection:this.indexSousLot,sousLot:this.sousLot}));
    if(!environment.onPremise)window.analytics.track('send_section_other_lot',{properties:{acValue: 'send_section_other_lot'}});
    this.dragulaService.destroy("SOUSPOSTES" + this.lot.id+this.lot.sousLots[this.indexSousLot].id);
  }

  public showSousLots(lotId:string,lotIndex:int): void{
    this.showListPosts[lotId] = this.showListPosts[lotId] ? false : true;
   this.subscriptions.push(
      this.dpgfPostesService.getSousLotsData(this.dpgfId,lotId).subscribe(
        (res)=>{
        // this.sousLots=res.sousLots;
          
          //this.selectedLotSousLots[lotId].push(...res.sousLots);
          this.lots[lotIndex].sousLots=res.sousLots;
          this.cdr.detectChanges();
      },
      (err) => {
        console.log("err ", err.message)
      })
     );
  }

  public setPosteAmounts(sousloti,posti,data){
    this.store.dispatch(actions.setPosteAmount({loti : this.loti,sousloti,posti,data}));
  }
  public setSousPosteAmounts(sousloti,posti,sousPosteIndex,data){
    this.store.dispatch(actions.setSousPosteAmount({loti : this.loti,sousloti,posti,sousPosteIndex,data}));
  }
  
  public pasteData(event,sousloti){
    event.preventDefault()
    if(this.licence!='without' || this.projectLicence!='none'){
      let rowsToAdd = [];
      let clipboardData = event.clipboardData ;
      let pastedText = clipboardData.getData("Text") || clipboardData.getData("text/plain");
      let rows = pastedText.split("\n");
      rows.forEach((row)=>{
        rowsToAdd.push(row.split("\t"))
      })
      let matching  = [];
      (rowsToAdd[0]||[]).forEach((el,index)=>{
        matching.push({value : el, key : (this.matchingOption[index]||{value : ""}).value})
      })
      this.matching = matching;
      $('#paste-modal-' + this.loti).modal('toggle')
      this.pasteRows = rowsToAdd;
      this.sousLotPast = sousloti;
      this.store.dispatch(actions.emptyTypeAmount());
    }
    else $('#upgrade-modal').modal('show');
  }
  public pasteDataWithKeys(){
    this.store.dispatch(actions.addManyPostes({loti : this.loti,sousloti : this.sousLotPast , pasteRows :this.pasteRows, matching: this.matching}));
    $('#paste-modal-' + this.loti).modal('toggle');
  }
  // emit to show (need to accept first before editing or submiting)
  public checkIfNeedToAcceptFirst(){
    if(!this.canEdit){
      this.showAcceptModal.emit({});
    }
  }
  //Comment
  public addComment(sousloti,posti,by,sousPosteIndex?){
    if(!this.canEdit){
      this.showAcceptModal.emit({});
    }else if(sousPosteIndex==undefined){
      this.store.dispatch(actions.addPosteComment({loti : this.loti,sousloti,posti,by}));
    }else{
      this.store.dispatch(actions.addSousPosteComment({loti : this.loti,sousloti,posti,sousPosteIndex,by}));
    }
  }
  public deleteComment(sousloti,posti,commenti,sousPosteIndex?){
    if(sousPosteIndex==undefined)this.store.dispatch(actions.removePosteComment({loti : this.loti,sousloti,posti,commenti}));
    else this.store.dispatch(actions.removeSousPosteComment({loti : this.loti,sousloti,posti,sousPosteIndex,commenti}));
  }
  public changeComment(sousloti,posti,commenti,com,sousPosteIndex?){
    if(sousPosteIndex==undefined)this.store.dispatch(actions.setPosteCommentData({loti : this.loti,sousloti,posti,commenti,com}));
    else this.store.dispatch(actions.setSousPosteCommentData({loti : this.loti,sousloti,posti,sousPosteIndex,commenti,com}));
  }
  //CCTP Edit
  public openCctpModal(event, cctp,gid,tab ,sousloti,posti,tabPage='CSTB'){
    // console.log('event',event,'cctp',cctp,'gid',gid,'sousloti',sousloti);
    if(this.modeList[this.mode].cctp && event.target.className.indexOf("hider") == -1 && (this.licence!='without' || this.projectLicence!='none')){
      let poste = false;
      let lot = false;
      if(posti || (posti == 0) )poste = true
      else lot = true
      if(sousloti!=undefined && posti!=undefined)this.store.dispatch(actions.setCctp({loti : this.loti,sousloti,posti,cctp,gid,poste,lot,lotId:this.lot.id,souslotId:this.lot.sousLots[(sousloti||0)].id,posteId:this.lot.sousLots[(sousloti||0)].postes[(posti||0)].id,postDesignation:this.lot.sousLots[(sousloti||0)].postes[(posti||0)].designation,postIniesCount:(this.lot.sousLots[(sousloti||0)].postes[(posti||0)].postIniesCount||0)}));
      else this.store.dispatch(actions.setCctp({loti : this.loti,sousloti,posti,cctp,gid,poste,lot,sousLotTitle:this.lot.sousLots[(sousloti||0)].title || " "}))
      this.openCctp.emit({value:(tab ||"statique"),tab:tabPage,lotId:(this.lot||{}).id,souslotId:(this.lot.sousLots[(sousloti||0)]||{}).id,posteId:(this.lot.sousLots[(sousloti||0)].postes[(posti||0)]||{}).id,fromCstb:(this.lot.sousLots[(sousloti||0)].postes[(posti||0)]||{}).fromCstb|| false});
    }
    else $('#upgrade-modal').modal('show');
  }
  public openCctpModalForSousPostes(event, cctp,gid,tab ,sousloti,posti,sousPosteIndex,tabPage='CSTB'){
    if(this.modeList[this.mode].cctp && event.target.className.indexOf("hider") == -1 && (this.licence!='without' || this.projectLicence!='none')){
      let poste = false;
      let lot = false;
      let sousPostes = false;

      if(posti || (posti == 0) ){
        poste = true;
        if(sousPosteIndex || sousPosteIndex==0) sousPostes=true;
      }
      else lot = true
      let newCctp = {loti : this.loti,sousloti,posti,sousPosteIndex,cctp,gid,poste,lot,lotId:this.lot.id,souslotId:this.lot.sousLots[(sousloti||0)].id,posteId:this.lot.sousLots[(sousloti||0)].postes[(posti||0)].id,sousPosteId:this.lot.sousLots[(sousloti||0)].postes[(posti||0)]['sousPostes'][(sousPosteIndex||0)].id,postDesignation:this.lot.sousLots[(sousloti||0)].postes[(posti||0)].sousPostes[(sousPosteIndex||0)].designation,postIniesCount:(this.lot.sousLots[(sousloti||0)].postes[(posti||0)]['sousPostes'][(sousPosteIndex||0)].postIniesCount||0)};
      if(sousloti!=undefined && posti!=undefined && sousPosteIndex!=undefined)this.store.dispatch(actions.setCctp(newCctp));
      else this.store.dispatch(actions.setCctp({loti : this.loti,sousloti,posti,sousPosteIndex,cctp,gid,poste,lot,sousPostes}))
      this.openCctp.emit({value:(tab ||"statique"),tab:tabPage,lotId:(this.lot||{}).id,souslotId:(this.lot.sousLots[(sousloti||0)]||{}).id,posteId:(this.lot.sousLots[(sousloti||0)].postes[(posti||0)]||{}).id,sousPosteId:(this.lot.sousLots[(sousloti||0)].postes[(posti||0)]||{})['sousPostes'][(sousPosteIndex||0)].id});
    }
    else $('#upgrade-modal').modal('show');
  }
  public selectElement(souslot,post,column){
    this.currentSousLot = souslot;
    this.currentPoste = post;
    this.currentColumn = column;
  }
  public selectElementFromSousPostes(souslot,post,sousPoste,column){
    this.currentSousLot = souslot;
    this.currentPoste = post;
    this.currentSousPoste = sousPoste;
    this.currentColumn = column;
  }
  public setModif(){
  }
  public setOption(option){
    option = !option;
  }
  public addDossier(){
    // this.refreshLots=true;
    this.archiDbService.createLot({title:this.newDossier,parentLot:this.currentDossier}).subscribe(
      data=>{
        this.newDossier='';
        // this.archiDbPost.ngOnInit()
        // this.refreshLots=false;
        this.cdr.detectChanges();
      }
    )
  }
  public sendToFather(event){
    this.currentDossier=event._id;
  }
  public getDbCount(event){
    this.countArchiDbPosts=event.count;

    // console.log("this.countArchiDbPosts : ", this.countArchiDbPosts);
    this.currentLicence=event.licence;
  }
  public closeSideDb(){
    this.displaySideDpgf=false;
    $("body").css("overflow","scroll");
  }
  public redirectToPricingTable(){
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
    $('#import-post').modal("hide")
  }
  sanitizeHtml(html){
    return this.sanitizer.bypassSecurityTrustHtml(html) ;
  }
  parseLocalisation(post){
    if(!post.localisation) return [];
    post.localisation = (post.localisation||"").replaceAll("Renseignez une localisation,", "");
    
    return (post.localisation||'').split(',')
  }
  parseLocalisationSousPoste(post){
    if(!post.localisation) return [];
    post.localisation = (post.localisation||"").replaceAll("Renseignez une localisation,", "");
    return (post.localisation||'').split(',')
  }
  updateLocalisation(sousloti,posti,post,j,localisation){
    let localisationArray=(post.localisation||'').split(',')
    localisationArray[j]=localisation;
    let data={localisation:localisationArray.join(',')}
    this.store.dispatch(actions.setPosteData({loti : this.loti,sousloti,posti,data}));
  }
  updateSousPosteLocalisation(sousloti,posti,post,j,localisation,sousPosteIndex){
    let localisationArray=(post.sousPostes[sousPosteIndex].localisation||'').split(',')
    localisationArray[j]=localisation;
    let data={localisation:localisationArray.join(',')}
    this.store.dispatch(actions.setSousPosteData({loti : this.loti,sousloti,posti,sousPosteIndex,data}));
  }
  addLocalisation(sousloti,posti,post,event){
    let localisationArray;
    if(post.localisation) {
      localisationArray=(post.localisation||'').split(',')
      localisationArray.push('')
    }
    else localisationArray=['Renseignez une localisation'];
    // if((post.localisation||'').split(',')[0]=='') localisationArray=['']
    // else
    let data={localisation:localisationArray.join(',')}
    this.store.dispatch(actions.setPosteData({loti : this.loti,sousloti,posti,data}));
    this.cdr.detectChanges();
  }
  addLocalisationToSousPost(sousloti,posti,post,event,sousPoste?,sousPosteIndex?){
    let localisationArray;
      if(post['sousPostes'][sousPosteIndex].localisation) {
        localisationArray=(post['sousPostes'][sousPosteIndex].localisation||'').split(',')
        localisationArray.push('')
      }
      else localisationArray=['Renseignez une localisation'];
      // if((post.localisation||'').split(',')[0]=='') localisationArray=['']
      // else
      let data={localisation:localisationArray.join(',')}
      this.store.dispatch(actions.setSousPosteData({loti : this.loti,sousloti,posti,sousPosteIndex,data}));
      this.cdr.detectChanges();
  }
//show bim elements 
  public showRevitElements(sousLoti,posti,posteId,posteTitle){
    this.revitButtonClicked=true;
    this.selectedPosteIdForRevit = posteId;
    this.sousLotI = sousLoti;
    this.postI=posti;
    this.lotId=this.lot.id;
    this.selectedPosteTitleForRevit = posteTitle;
  }
  public changeOpenSliderPostToRevitValue(value){
    this.openPostToRevitSlider.emit(value);
    value?this.revitButtonClicked=true: this.revitButtonClicked=false
  }
  changeRevitIcon(value){
    this.isPostHasElments=value;
  }
  deleteLocalisation(sousloti,posti,post,j){
    let localisationArray=(post.localisation||'').split(',')
    localisationArray.splice(j,1);
    let data={localisation:localisationArray.join(',')}
    this.store.dispatch(actions.setPosteData({loti : this.loti,sousloti,posti,data}));
  }
  deleteSousPosteLocalisation(sousloti,posti,sousPoste,j,sousPosteIndex){
    let localisationArray=(sousPoste.localisation||'').split(',')
    localisationArray.splice(j,1);
    let data={localisation:localisationArray.join(',')}
    this.store.dispatch(actions.setSousPosteData({loti : this.loti,sousloti,posti,sousPosteIndex,data}));
  }
  private getPrevSousLotsPostsLength(currentSousLotIndex){
    let prevPostsCount = 0;
    for(let i = 0; i < currentSousLotIndex; i++){
      let sousLotPostsLength = this.lot.sousLots[i].postes.length;
      // for(let j = 0;j<this.lot.sousLots[i].postes.length;j++){
      //   sousLotPostsLength = this.lot.sousLots[i].postes[j].sousPostes.length;
      // }
      prevPostsCount += sousLotPostsLength;
      // console.log(this.lot.sousLots[i]);
    }
    // 6 is the number of inputs in every post row
    return prevPostsCount*5;
  }
  
  focusNext(event){
    // let prevPostsLength = this.getPrevSousLotsPostsLength(event.sousloti);
    if(event.num>=0)this.nextFocusInput=event.num
    // this.nextFocusInput+=prevPostsLength;
    // this variable for unity and tva cuz we have either choose() or focusonout()
    this.currentSousLoti = event.sousloti;
    this.isChoose=event.choose
  }
  public ngOnDestroy(): void {
    this.dragulaService.destroy("POSTES" + this.lot.id)    // (this was already commented) if(this.sectionMoved && this.lot.sousLots.length>this.indexSousLot){this.dragulaService.destroy("SOUSPOSTES" + this.lot.id+this.lot.sousLots[this.indexSousLot]?.id);this.sectionMoved=false;}
    this.lot.sousLots.forEach((sousLot) => {
      sousLot.postes.forEach((poste,indexPoste) => {
      this.dragulaService.destroy("SOUSPOSTES" + this.lot.id+sousLot.id+indexPoste);
      });
    });    
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.store.dispatch(actions.unselectPosts({}));
  }
  hideSousPostes(){
    this.hideDiv = !this.hideDiv
  }
  isAllPostesSelectedFunction(){
    this.isAllPostesSelected = !this.isAllPostesSelected
    this.store.dispatch(actions.selectAllPostes({isAllPostesSelected:this.isAllPostesSelected}));
  }

  getSitewebData({ sousLoti, postId, posti, postProducts }) {
    if (this.saveUrl(this.productUrl)) {
      this.isLoading = true;
      this.productService.getWebsiteData(this.productUrl)
        .subscribe(
          (responseData: any) => {
            const product = {
              title: responseData.title,
              description: responseData.description,
              price: responseData.price,
              image: responseData.image
            };
            const newProductsList = [...(postProducts||[]), product];
            this.setPosteAttributs(sousLoti, posti, { 'products': newProductsList });
            this.cdr.detectChanges();
            this.store.dispatch(actions.saveDpgfToServer({}));
            this.store.dispatch(actions.saveDpgfLotToServer({}));
            this.isLoading = false;
            this.productUrl = '';
            this.openProductModal(postId)
          },
          error => {
            console.error('Error fetching website data:', error);
            this.isLoading = false;
            this.cdr.detectChanges();
          }
        );
      
    } else {
      console.log('Please Enter Product Url');
    }
  }

  deleteProduct(products, index, sousloti, posti){
    const newProductsList = [...products.slice(0, index), ...products.slice(index + 1)];
    console.log(newProductsList);
    this.setPosteAttributs(sousloti, posti, { 'products': newProductsList })
    this.cdr.detectChanges();
    this.store.dispatch(actions.saveDpgfToServer({}));
    this.store.dispatch(actions.saveDpgfLotToServer({}));
  }

  toggleCollapse(postId: string) {
    this.collapseState[postId] = !this.collapseState[postId];
  }

  
  openProductModal(postId) {
    let isPostProductFormOpened = !!this.openedProductsForms[postId];  
    this.openedProductsForms[postId] = !isPostProductFormOpened;
  }

  public saveUrl(url){
    if(this.validateUrl(url)){
      return true;
    }else{
      return false;
    }
  }

  // Function for URL validation
  validateUrl(urlString) {
    const urlPattern = /^(?:(?:https?:\/\/)?www\.)([a-zA-Z0-9-]+)\.(?:com|fr)(?:\/.*)?$/i;
    const match = urlString.match(urlPattern);
    console.log(!!match)
    return !!match; // Returns true if there's a match, false otherwise

  }

  // // Function for extracting website name
  // extractWebsiteName(urlString) {
  //   const urlPattern = /^(?:(?:https?:\/\/)?www\.)([a-zA-Z0-9-]+)\.(?:com|fr)(?:\/.*)?$/i;
  //   const match = urlString.match(urlPattern);
  //   if (match) {
  //     return match[1]; // Returns the captured website name
  //   } else {
  //     return null; // Returns null if there's no match
  //   }
  // }

  public setProductAttributs(sousloti,posti,productIndex,data,product,changingElement){
    const newProductsList = product;
    if(changingElement=='title'){
      newProductsList[productIndex].title = data.word
    }
    if(changingElement == 'description'){
      newProductsList[productIndex].description = data.word
    }
    if(changingElement == 'price'){
      newProductsList[productIndex].price = data

    }

    this.setPosteAttributs(sousloti, posti, { 'products': newProductsList })
    this.cdr.detectChanges();
    this.store.dispatch(actions.saveDpgfToServer({}));
    this.store.dispatch(actions.saveDpgfLotToServer({}));

  }

  public selectElementFromProducts(souslot,post,productIndex,column){
    this.currentSousLot = souslot;
    this.currentPoste = post;
    this.currentProduct = productIndex;
    this.currentColumn = column;
  }

  public ajouterManuel({ sousLoti, postId, posti, postProducts }){
    const product = {
      title: "title",
      description: "Description",
      price: "00",
      image: "URL de l'image"
    };
    const newProductsList = [...(postProducts||[]), product];
    this.setPosteAttributs(sousLoti, posti, { 'products': newProductsList });
    this.cdr.detectChanges();
    this.store.dispatch(actions.saveDpgfToServer({}));
    this.store.dispatch(actions.saveDpgfLotToServer({}));
    this.openProductModal(postId)
  }

  
}
