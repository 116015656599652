import { Component, OnInit, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSharedModule } from 'src/app/all/ngshared.module';


@Component({
    standalone: true,
    selector: 'app-confirm-exist-booster-form-modal',
    imports:[
      NgSharedModule
    ],
    templateUrl: './confirm-exist-booster-form-modal.component.html',
    styleUrls: ['./confirm-exist-booster-form-modal.component.scss'],
})
export class ConfirmExistBoosterFormModalComponent implements OnInit {
  
  @Output() confirmExist: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  public emitConfirmExist(){
    this.confirmExist.emit(true);
  }

}
