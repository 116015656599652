import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({
  standalone:true,
  name: 'iniesSuggestion'
})
export class IniesSuggestion implements PipeTransform {

    constructor(private sanitizer: DomSanitizer){}

    transform(value: any,word:any): any {
      let category=((value.categoryName||'').split('|')[1]||'').split('(')[0];
      // value.nom.replace(word,`<strong>${word}</strong>`)
      return category
    }
}
