<div class="modal fade" id="change-password" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 358px;height: 322px;">
      <form class="modal-content text-color-size" style="padding: 32px 24px;" #passwordForm="ngForm">
        <p>Mot de passe actuel</p>
        <input type="password" class="wid310-height35"   name="password" [(ngModel)]="actualPassword" (keyup)="checkValidityPass(actualPassword)"  minlength="6" required #actualPwd="ngModel">
        <small *ngIf="actualPwd.errors?.minlength" class="color-red">
          Minimum 6 caractères.
        </small>
        <br *ngIf="actualPwd.errors?.minlength">
        <small *ngIf="actualPwd.errors?.required" class="color-red">
          Le mot de passe est obligatoire.
        </small>
        <br *ngIf="!validityPassword && keysUp">
        <small *ngIf="!validityPassword && keysUp" class="color-red">Votre mot de passe actuel est incorrect</small>
        <p class="padding-top-12">Nouveau mot de passe</p>
        <input class="wid310-height35" name="newPassword" [(ngModel)]="newPassword"type="password" minlength="6"  required #pwd1="ngModel">
        <small *ngIf="pwd1.errors?.minlength" class="color-red">
          Minimum 6 caractères.
        </small>
        <br> 
        <small *ngIf="pwd1.errors?.required && submited">
          Le mot de passe est obligatoire.
        </small>
        <p class="padding-top-12">Retaper le mot de passe</p>
        <input class="wid310-height35"   name="compareNewPwd" [(ngModel)]="compareNewPwd" type="password" minlength="6" [pattern]="newPassword" required #pwd2="ngModel">
        <small *ngIf="pwd2.errors?.minlength" class="color-red">
          Minimum 6 caractères.
        </small>
        <br>
        <small *ngIf="pwd2.errors?.required && submited">
          Le mot de passe est obligatoire.
        </small>
        <small *ngIf="newPassword !== compareNewPwd && (compareNewPwd?.length>0 && newPassword?.length>0) " class="color-red">Les mots de passe saisis ne sont pas identiques.</small>
        <!-- <small *ngIf="newPassword!=compareNewPwd" class="color-red">Les mots de passe doivent etre pareil.</small> -->        
        <div class="flex justify-center padding-top-20">
          <button class="button-change-password" (click)="changeUserPassword(newPassword)" [ngClass]="{'disabled':(!validityPassword || passwordForm.invalid)}" >Changer le mot de passe</button>
            <!-- <button class="button-change-password" (click)="changeUserPassword(newPassword)" [disabled]="newPassword!=compareNewPwd && newPassword?.length>0" >Changer le mot de passe</button> -->
        </div>
      </form>
    </div>
  </div>
  <!-- <div class="modal fade" id="change-password" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="width:700px">
      <div class="modal-content">
        <div class="modal-body">
        </div>
      </div>
    </div>
  </div> -->
  
  