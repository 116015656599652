<app-spinner *ngIf="loadingData"></app-spinner>

<div class="" style="overflow: hidden;" *ngIf="!loadingData">

  <!-- Upper Consultations List -->
  <div class="upper-consultations-list-container flex center-items">
    <app-consultations-types-list
      [consultationsTypesListCount]="consultationsTypesListCount"
      (typeSelected)="consultationTypeSelected($event)"
    ></app-consultations-types-list>
  </div>
  <!-- END Upper Consultations List -->

  <!-- Enterprises Lots Table -->
  <app-enterprises-lots-table 
    [lots]="lotsDpgf"
    [totalHt]="dpgf.totalHt"
    (searchAgencies)="searchAgencies($event)"
    (openAddAgenyModal)="openArtisanPopup($event)"
    (openArchitectAgencyModal)="emitOpenArchitectAgencyModal($event)"
  ></app-enterprises-lots-table>
  <!-- END Enterprises Lots Table -->
  
</div>
