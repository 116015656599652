import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { Router } from '@angular/router';
import { NgSharedModule } from '../ngshared.module';

declare var $;
@Component({
  standalone:true,
  selector: 'app-documents-errors-modal',
  imports:[NgSharedModule],
  templateUrl: './documents-errors-modal.component.html',
  styleUrls: ['./documents-errors-modal.component.scss']
})
export class DocumentsErrorsModalComponent implements OnInit, OnChanges {
  
  @Input() messageObject:any;
  @Input() ceProjectId:string;
  @Output() goToLot: EventEmitter<any> = new EventEmitter<any>();
  public errorMessage:string = ""; 
  public messageLocation:string = "";
  private ERROR_TYPE:string = "";
  private lotId:string = "";
  private lotName:string = "";
  private lotNumber:string = "";

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges){
    
    if(changes.messageObject && !changes.messageObject.firstChange){
      let errorData = changes.messageObject.currentValue;
      this.transformMessageObject(errorData);
    }

  }

  private transformMessageObject(messageObject){    
    let errorMessage = messageObject?.errorMessage;
    this.errorMessage = errorMessage;
    
    let errorText = messageObject?.errorText;

    if(errorText.includes("\n")){
      errorText = errorText.replace("\n","");
    }
    if(typeof errorText == "object"){
      let errorTextData = "";
      errorText.forEach((item) => {
        errorTextData += item?.text;
      });
      errorText = errorTextData;
    }

    let parsedErrorLocation = JSON.parse((messageObject?.errorLocation || "{}"));

    this.lotId = parsedErrorLocation?.lotId || undefined;
    this.lotName = parsedErrorLocation?.lotName || "";
    this.lotNumber = parsedErrorLocation?.lotNumber || "";

    let errorType = parsedErrorLocation.type;
    let errorLocation = [];

    let ignoredKeys = ["type","lotId","lotName","lotNumber", "cctpPostIndex", "cctpListPostIndex"];

    for(let key in parsedErrorLocation){
      // if(key != "type"){
      if(!ignoredKeys.includes(key)){
        // errorLocation.push(`${key} ${parsedErrorLocation[key]} >`);
        errorLocation.push(`${parsedErrorLocation[key]}.`);
      }
    }

    // remove dot from last item
    if(errorLocation.length){
      errorLocation[errorLocation.length - 1] = errorLocation[errorLocation.length - 1].replace(".", "");
    }

    // use this to redirect to project image or agency logo, or open the lot that has the issue
    this.ERROR_TYPE = errorType;

    this.messageLocation = this.messageMapper(errorType, errorText, errorLocation);
  }
  private messageMapper(type, errorText, errorLocation){
    let errorPath = errorLocation.join("");

    let messagesMapper = {
      // Lot Title
      "lotTitle": `Une erreur empêche le téléchargement de votre document.<br>
        Cette erreur se trouve au niveau du <b>nom du lot ${this.lotNumber}</b> <br><br>
        L’erreur soulevée est : <b>${this.errorMessage}</b> `,

      // SousLot Title
      "SousLotTitle":`Une erreur empêche le téléchargement de votre document.<br><br>
        Cette erreur se trouve au niveau du <b>nom de la section ${errorPath}</b> <br><br>
        L’erreur soulevée est : <b>${this.errorMessage}</b> `,

      // Lot Desription
      "lotDescriptionPost": `Une erreur empêche le téléchargement de votre document.<br><br>
        Cette erreur se trouve au niveau des <b>généralités du lot ${this.lotNumber}</b> <br><br>
        L’erreur soulevée est : <b>${this.errorMessage}</b> `,      

      // Post
      "post": `Une erreur empêche le téléchargement de votre document.<br><br>
        Cette erreur se trouve au niveau du <b>nom du poste ${errorPath}</b> <br><br>
        L’erreur soulevée est : <b>${this.errorMessage}</b>`,

      // post Localisation
      "postLocalisation": `Une erreur empêche le téléchargement de votre document.<br><br>
        Cette erreur se trouve au niveau de la localisation du <b>poste ${errorPath}</b> <br><br>
        L’erreur soulevée est : <b>${this.errorMessage}</b>`,

        "cctpPost": `Une erreur empêche le téléchargement de votre document.<br><br>
      Cette erreur se trouve au niveau de la description du <b>poste ${errorPath}</b> <br><br>
      L’erreur soulevée est : <b>${this.errorMessage}</b>`,

      // CCtp Post
      "cctpListPost": `Une erreur empêche le téléchargement de votre document.<br><br>
        Cette erreur se trouve au niveau de la description du <b>poste ${errorPath}</b> <br><br>
        L’erreur soulevée est : <b>${this.errorMessage}</b>`,

      // SousLot Pricing
      "sousLotPricing": `Une erreur empêche le téléchargement de votre document.<br><br>
        Cette erreur se trouve au niveau du <b>prix de la section ${errorPath}</b> <br><br>
        L’erreur soulevée est : <b>${this.errorMessage}</b>`,

      /// Project Image
      "projectImage": `Cette erreur est causée par l’image du projet qui est sous un format non<br><br> pris en charge. Changez-la pour un des formats suivants : png, jpeg, jpg.`,
      /// Logo Image
      "logoImage": `Cette erreur est causée par votre logo qui est sous un format non<br><br> pris en charge. Changez-le pour un des formats suivants : png, jpeg, jpg.`,
    };

    return messagesMapper[type] || "";
  }

  public goToErrorLocation(){
    // redirect to edit project, so the user can change project image
    if(this.ERROR_TYPE == "projectImage"){
      this.router.navigate(["architect/modify-ce-project", this.ceProjectId]);
    }else if(this.ERROR_TYPE == "logoImage"){
      // redirect to edit profile so the user can change logo image
      this.router.navigate(["/architect/profil"]);
    }else{
      // emit & open the lot that has the Issue
      this.goToLot.emit({lotId: this.lotId});
    }
    $('.modal').modal('hide');
  }

}
