<div class="modal fade" [id]="modalId" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="title">{{ title }}</h6>
      </div>
      <div class="modal-body">
        <p>
          {{ description }}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-cancel ag-btn-tertiary-sm margin-right-16" data-dismiss="modal">Annuler</button>
        <button type="button" class="btn-accept ag-btn-tertiary-sm" data-dismiss="modal" (click)="emitConfirm()">
          <img src="/assets/remove2-icon.svg" class="margin-right-12" alt="">
          {{ confirmButtonLabel }}
        </button>
      </div>
    </div>
  </div>
</div>
