import { Component, OnInit, Input,ChangeDetectorRef, NO_ERRORS_SCHEMA } from '@angular/core';
import { Subscription } from 'rxjs';
import { ArchitectService, AttributService, CeProjectInvitesService, CeProjectService } from 'src/app/_services';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

declare var $;
@Component({
  standalone:true,
  
  selector: 'app-modal-consult-entreprise',
  imports:[
    DpgfModule,
    SpinnerComponent,
    NgSharedModule
  ],
  providers: [MessageService],
  templateUrl: './modal-consult-entreprise.component.html',
  styleUrls: ['./modal-consult-entreprise.component.scss']
})
export class ModalConsultEntrepriseComponent implements OnInit {
  @Input()dpgf;
  @Input() ceProjectId;

  public AgloOrCarnet:String='agloBooster';selectedSearchLots=[];artisanSearchField="";selectedSearchTags=[];relationsList;budget;dateTravaux;
  private subscriptions: Array<Subscription> = [];totalRelations;creatingTags:Boolean = false;addNewArtisanLoading:Boolean = false;
  public artisanForm: UntypedFormGroup;budgetForm : UntypedFormGroup;lotSelected=[];idsSelected:Array<Subscription>=[];pageName = 'first';entrepriseChosen;
  public sendChiffrageButton:boolean=true;addEntrepriseLoading:Boolean = false;buttonSendRequest:Boolean = false;
  public allTags: Array<Object> =[];view:boolean=false;

  constructor(
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
    private ceProjectService: CeProjectService,
    private fb: UntypedFormBuilder,
    private ceProjectInvitesService: CeProjectInvitesService,
    private attributService: AttributService,
    private architectService: ArchitectService) {}

  ngOnInit(): void {
    this.searchEnterprises();
    this.allTags =this.attributService.getRelationTags();
    this.createForm();  
    this.createFormChiffrage();
    var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    if(currentUser.archiId)this.getCeProject();
  }
  public createForm(){
    this.artisanForm = this.fb.group({
      emailAgency: ['',
      Validators.compose([
        Validators.required, Validators.email
      ])
      ],
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      tags: [[]],
      telAgency: '',
      demo: false
    });
  }
  public createFormChiffrage(){
    this.budgetForm = this.fb.group({
      budget: [this.budget || "", Validators.required],
      dateTravaux: Date,
      messageBody: ''
    });
  }
  public goToPage(page){
    this.pageName = page;
  }
  public agloBossterOrCarnetAdress(type:String){
    this.AgloOrCarnet = type;
  }
  public searchEnterprises(){
    let body ={
      searchQuery: this.artisanSearchField,
      tags: this.selectedSearchTags
    }
    this.subscriptions.push(
      this.architectService.searchEntreprise(body, 1)
      .subscribe(
        data => {
          this.relationsList = data.data.relations;
          this.totalRelations = data.metadata.count;
        this.cd.detectChanges();
        },
        err => {
          // this.ErrorService.manageError(err,"");
          console.log('error',err)
        }
      )
    );
  }
  changeView(){
    this.view = !this.view;
    if(this.view){
      this.lotSelected = this.dpgf.data
      this.idsSelected = this.dpgf.data.map((lot)=>lot.id);
    }else{
      this.lotSelected = [];
      this.idsSelected = [];
    }
    let hasId = this.idsSelected.length ? false : true;
    this.sendChiffrageButton = this.dpgf.demo? true:hasId;
  }
  tagSelected(){
    this.searchEnterprises();
  }
  public createNewArtisan(){
    this.creatingTags =true;
    this.addNewArtisanLoading = true;
    this.architectService.addCarnetAdressesArtisan(this.artisanForm.value)
    .subscribe(data => {
      this.artisanForm.reset();
      this.idsSelected = [];
      this.lotSelected = [];
      this.addNewArtisanLoading = false;
      this.pageName='first';
      $('#consult-booster').modal('hide');
      this.creatingTags =false;
      this.entrepriseChosen = data;
      this.searchEnterprises();
      if(!environment.onPremise)window.analytics.track('add_companies_dce',{properties:{acValue: 'add_companies_dce'}});
      this.messageService.add({ key: 'toast', severity: 'success', summary: "Ajout d'une entreprise", detail: "L'entreprise a été invité dans votre réseau", life: 5000 });
    },
    err => {
      this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur d'ajout", detail: "Erreur serveur", life: 5000 });
    }
    );
  }
  addLotToSend(lot){
    if(this.idsSelected.includes(lot.id)){
      this.lotSelected=this.lotSelected.filter((thisLot)=>thisLot==lot.id);
      this.idsSelected=this.idsSelected.filter((ids)=> ids!=lot.id)
    }else{
      this.lotSelected.push(lot);
      this.idsSelected.push(lot.id)
    }
    let hasId = this.idsSelected.length ? false : true;
    this.sendChiffrageButton = this.dpgf.demo? true:hasId;
  }
  public chooseEntreprise(entreprise){
    this.entrepriseChosen = entreprise;
    this.idsSelected = [];
    this.lotSelected = [];
    this.pageName = 'send-lot-to-artisan';
  }
  public addEntreprise(){
    let lotToSend = this.lotSelected.map((lot)=> {
      return {
        icon:lot.icon,
        id:lot.id,
        name:lot.title,
        requested:0
      }
    })
    let body = {
      artisanId :this.entrepriseChosen.artisanId || this.entrepriseChosen.artisanOwnedId,
      lots:lotToSend
    }
    if(body.artisanId){
      this.addEntrepriseLoading = true;
      this.subscriptions.push(
        this.ceProjectInvitesService.createNewArtisanByArchi(body, this.dpgf.ceProject)
        .subscribe(() => {
          $('#consult-booster').modal('hide');
          if(!environment.onPremise)window.analytics.track('add_company',{properties:{acValue: 'add_company'}});
          this.pageName = 'first';
          this.addEntrepriseLoading = false;
        },
        err => {
          console.log('err',err);
        })
        )
  }}
  public sendRequest(){
    this.buttonSendRequest = true;
    let requestedArtisans = [];
    this.lotSelected.forEach(singleLot => {
      let item = {id: singleLot.id, name: singleLot.title}
      requestedArtisans.push(item)
    });
    this.subscriptions.push(
      this.ceProjectService.requestArtisan(this.dpgf.ceProject,{requestArtisans : requestedArtisans, messageBody : this.budgetForm.value.messageBody,budget:this.budgetForm.value.budget,date:this.budgetForm.value.date})
      .subscribe((res) => {
        // segment
        if(!environment.onPremise)window.analytics.track('ask_for_companies_dce',{properties:{acValue: 'ask_for_companies_dce'}});
        this.buttonSendRequest = false;
        $('#consult-booster').modal('hide');
      },
      err => {
        console.log('err',err);
      }
      )
    )
  }
  private getCeProject(){
    let idProject;
    if (typeof this.ceProjectId === 'object' ) idProject = this.ceProjectId._id
     else idProject = this.ceProjectId
    
    this.ceProjectService.getCeProjectDateAndBudget(idProject).subscribe(data=>{
      this.budget =  data.budget;
      if(this.dateTravaux){
        const date = new Date(data.dateTravaux);
        this.dateTravaux = date.toISOString().slice(0, 10);
      }
      let thisData = {
        budget:data.budget,
        dateTravaux:this.dateTravaux||""
      }
      this.budgetForm.patchValue(thisData);
    })
  }
}
