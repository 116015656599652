import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone:true,
  name: 'ceProject'
})
export class CeProjectPipe implements PipeTransform {
  public data:any = {
    ensemble : "Pour l'ensemble des travaux",
    lots : "Pour des lots en particulier",
    entrepriseGenerale : "Entreprises générales",
    entrepriseSpecialise : "Entreprises en corps d'état séparés",
    oui: "Oui",
    non : "Non",
    demolition: "Démolition",
    vrd: "VRD",
    grosOeuvre: "Gros-oeuvre – Maçonnerie",
    charpente: "Charpente et ossature bois",
    couverture: "Couverture - Zinguerie",
    etancheite: "Etanchéité",
    platerie: "Plâtrerie",
    stores: "Stores et fermeture",
    cloisons: "Cloisons / Doublages / Faux-plafonds",
    vitrerie: "Vitrerie - Miroiterie",
    metallerie: "Métallerie / Serrurerie",
    menuiseries: "Menuiseries intérieures",
    revetement: "Revêtement de sol / mural",
    peinture: "Peinture",
    electricite: "Electricité",
    plomberie: "Plomberie / Sanitaires",
    chauffage: "Chauffage / Climatisation",
    "1":"1",
    "2":"2",
    "3":"3",
    "4":"4 ou plus",
    qualiteExecution:"Qualité d'exécution",
    respectProjet:"Respect du projet architectural",
    reactiviteChantier:"Réactivité en cours du chantier",
    propreteChantier:"Propreté du chantier / gestion des nuisances",
    ReactiviteChiffrage:"Réactivité en phase de chiffrage",
    CompetitiviteTarif:"Compétitivité des tarifs pratiqués",
    comprehensionProjet:"Compréhension du projet lors du chiffrage",
    respectDelais:"Respect des délais",
    tolerance:"Tolérance vis à vis des modifications de projet en cours de chantier",
    esquisse : "Esquisse / Faisabilité",
    avantProjet : "Avant-projet",
    pcDepose : "PC déposé",
    proEnCours : "PRO en cours",
    dce : "DCE",
    immediatement : "Immédiatement",
    definir : "Définir une date"
  };
  transform(value) {
      if(typeof value == "string"){
        return this.data[value] || value;
      }
      else if (typeof value == "object"){
        let newValue = value.slice(0);
        newValue = newValue.map(e => this.data[e] || e)
        return newValue.join(' / ')
      }
      return value;
  }
}
