import { Injectable, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";
import { Helpers } from './helpers'

@Injectable()
export class VariantesService {

  public BASE_API = "/api/v1";

  constructor(private http: HttpClient) {
  }

  getVariantes(dpgfID) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.get(`${this.BASE_API}/variantes?dpgfID=${dpgfID}`, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  createVariantes(data) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.post(`${this.BASE_API}/variantes`, data, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  updateVariantes(varianteId, body) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.patch(`${this.BASE_API}/variantes/${varianteId}`, body, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  renameVariantes(varianteId, body) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.patch(`${this.BASE_API}/variantes/${varianteId}/rename`, body, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  deleteVariantes(varianteId) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.delete(`${this.BASE_API}/variantes/${varianteId}`, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  selectVariante(varianteId) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.get(`${this.BASE_API}/variantes/${varianteId}`, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }

}
