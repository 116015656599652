<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 sidebar-wrapper">

      <h4 class="ag-heading-h4" style="padding-left:26px">Entité</h4>

      <!-- <ul class="sidebar-entities no-padding">
        <li class="ag-para-1" [ngClass]="{'open':item.label==selectedZone }" *ngFor="let item of sideBarEntities" (click)="item.type == 'zone' && EntityItemSelected(item)">
          {{ item.label }}
        </li>
      </ul> -->

      <div class="options-dropdown">
        <ul *ngFor="let option of projectDescriptionOptions; index as i">
          <li class="main-level ag-para-1 ag-bold flex space-between"
            [ngClass]="{'open': option.value == selectedBatiment}"
            (click)="openNestedList(option, 'nested-options-'+option.value)">
            <span>{{ option.label }}</span>
            <img src="/assets/acv-icons/nav-arrow-bottom.svg" *ngIf="option.zones">
          </li>
          <!-- Nest List -->
          <ul class="nested-items-ul" [id]="removeWhiteSpaces('nested-options-'+option.value)">
            <li class="ag-para-1" [id]="removeWhiteSpaces('nested-li'+'-'+nestedItem.value)"
              *ngFor="let nestedItem of option.zones; index as i"
              (click)="listItemSelected($event, nestedItem)"
              >
              {{ nestedItem.label }}
            </li>
          </ul>
        </ul>
      </div>

    </div>
    <div class="col-md-9 main-content-wrapper scroller-1">
      <!-- FORM
      ================================= -->
      <div *ngIf="checkRSET()" class="main-form-wrapper">
        <h4 class="ag-heading-h4 margin-bottom-16">{{ forms[currentView].mainTitle || '' }}</h4>
        <p class="ag-para-1 margin-bottom-16" *ngIf="forms[currentView].mainDescription && forms[currentView].mainDescription != ''">
          {{ forms[currentView].mainDescription || '' }}
        </p>
        <button class="btn btn-purple action-btn margin-bottom-20" *ngIf="forms[currentView].mainButton" [disabled]='isProjectDemo' (click)="importRSET()">
          <img src="/assets/acv-icons/download-green.svg" alt="">
          {{ forms[currentView].mainButton.title }}
        </button>
        <h4 *ngIf="selectedZone" class="cards-title margin-bottom-20">Consommation énergétique de l’entité {{selectedZone}}</h4>
        <div class="form-rows-container row">

          <!-- Single Card -->
          <div class="col-md-4 margin-bottom-24" *ngFor="let card of forms[currentView]?.cards;let i=index">
            <div *ngIf="card.isFilled" class="single-card">
              <div class="single-card-header flex">
                <img [src]="card.icon" alt="">
                <p>{{ card.title }}</p>
              </div>
              <div class="single-card-body">
                <div class="flex space-between info-row" *ngFor="let item of card.items">
                  <span *ngIf="item.value!=0" class="row-label">{{ item.label }}</span>
                  <span *ngIf="item.value!=0" class="row-value">{{ item.value | formatPrice }} kWh</span>
                </div>
              </div>
              <!-- <div *ngIf="!card.isFilled">
                <p>empty</p>
              </div> -->
            </div>
          </div>
          <!-- END Single Card -->

        </div>
      </div>

      <div *ngIf="!checkRSET()" class="main-form-wrapper">
        <h4 class="ag-heading-h4 margin-bottom-16">Importer votre fichier RSET</h4>
        <div class="ag-para-1 margin-bottom-16">
          <p>Pour obtenir votre <span style="font-weight: 700;color: #008037;">certification RE2020</span>, il est obligatoire d’importer le fichier RSET.</p>
          <p>Ce fichier nous permet de calculer vos consommations conventionnelles d’énergie renouvelable ou non (CEP),</p>
          <p>vos besoins bioclimatique (Bbio) et votre degré-heure d’inconfort (DH) ainsi que les besoins énergétiques du bâtiment.</p>
        </div>
        <button class="btn btn-purple action-btn green-btn margin-bottom-20" (click)="importRSET()">
          <img src="/assets/acv-icons/download-green.svg" alt="">
          Importer le RSET(.xml)
        </button>
      </div>
      <!-- END FORM
      ================================= -->
    </div>
  </div>
</div>
