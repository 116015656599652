<form [formGroup]="form" novalidate>

  <label>Dans quelle(s) région(s) exercez-vous principalement ?</label>
  <div id="mapdiv" style="background-color:white; height: 500px;"></div>
  <span *ngFor="let area of detail.workAreaMain" href="#" class="tag tag-1">{{area.text}}</span><br><br>


  <label id=type class="required-star">Vous êtes :</label>
  <div [@error]="error=='type'" class="input-group input-group-lg">
    <app-checkbox-simple name="type" formControlName="type" [options]="type" multiple="multiple"></app-checkbox-simple>
  </div>
  <small *ngIf="formErrors.type" class="text-danger">
    {{ formErrors.type }}
  </small><br>

  <label id="poste" class="required-star">Quelles sont les activités professionnelles garanties par votre assurance décennale ?</label>
  <div class="input-group input-group-lg">
    <span [@error]="error=='poste'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='poste'" class="glyphicon secondary glyphicon-home" aria-hidden="true"></span></span>
    <p-autoComplete id="poste"
                    placeholder = "Choisissez dans la liste"
                    forceSelection="true"
                    styleClass="wid100"
                    formControlName="poste"
                    [suggestions]="searchPoste"
                    (completeMethod)="search($event,'searchPoste','poste','poste')"
                    [field]="fieldname"
                    [dropdown]="true"
                    [multiple]="true"></p-autoComplete><br>
  </div>
  <small *ngIf="formErrors.poste" class="text-danger">
    {{ formErrors.poste }}
  </small><br>

  <label id='travaux' class="required-star">Sur quel(s) type(s) de travaux pouvez-vous intervenir ?</label>
  <div [@error]="error=='travaux'" class="input-group input-group-lg">
    <app-checkbox-simple name="travaux" formControlName="travaux" [options]="travaux" multiple="multiple"></app-checkbox-simple>
  </div>
  <small *ngIf="formErrors.travaux" class="text-danger">
    {{ formErrors.travaux }}
  </small><br>

  <label>Parmi les programmes ci-dessous, pour lesquels avez-vous au moins 3 réalisations terminées ?</label>
  <div class="input-group input-group-lg">
    <app-checkbox-simple name="programme" formControlName="programme" [options]="programme" multiple="multiple"></app-checkbox-simple>
  </div>
  <small *ngIf="formErrors.programme" class="text-danger">
    {{ formErrors.programme }}
  </small><br>

  <label id="domaineSpe">Avez-vous un domaine de spécialisation ?</label>
  <textarea id="domaineSpe"
             formControlName="domaineSpe"
             type="text"
             class="md-textarea form-control small"
             placeholder=""
             maxlength="5000"
             rows="5"
             aria-describedby="sizing-addon1"></textarea>
  <!--  <label>Parmi les postes suivants, lesquels avez-vous l'habitude de sous-traiter ?</label>
  <div class="input-group input-group-lg">
    <span class="input-group-addon" id="sizing-addon1"><span class="glyphicon secondary glyphicon-home" aria-hidden="true"></span></span>
    <p-autoComplete id="posteSousTraite"
                    placeholder = "Postes de Sous-traitance"
                    forceSelection="true"
                    styleClass="wid100"
                    formControlName="posteSousTraite"
                    [suggestions]="searchPosteSousTraite"
                    (completeMethod)="search($event,'searchPosteSousTraite','poste','posteSousTraite')"
                    [field]="fieldname"
                    [dropdown]="true"
                    [multiple]="true"></p-autoComplete><br>
  </div>
  <small *ngIf="formErrors.posteSousTraite" class="text-danger">
    {{ formErrors.posteSousTraite }}
  </small><br>



 <label>A partir de quel budget (montant global des travaux HT) les projets vous intéressent-ils ?</label>
  <p-slider id="minBudgetMain"
            formControlName="minBudgetMain"
            [style]="{'width':'100%'}"
            [step]="50"
            [min]="0"
            [max]="2000"></p-slider>
  <div class="meter">
    <span class="meter-icon glyphicon secondary glyphicon-euro" aria-hidden="true"></span> {{form.value.minBudgetMain | number:0}} K€
  </div>
  <small *ngIf="formErrors.minBudgetMain" class="text-danger">
    {{ formErrors.minBudgetMain }}
  </small><br>

  <label>A partir de quel budget (montant global des travaux HT) êtes-vous prêt à vous déplacer dans toute la France ?</label>
  <p-slider id="minBudgetOther"
            formControlName="minBudgetOther"
            [style]="{'width':'100%'}"
            [step]="50"
            [min]="0"
            [max]="2000"></p-slider>
  <div class="meter">
    <span class="meter-icon glyphicon secondary glyphicon-euro" aria-hidden="true"></span> {{form.value.minBudgetOther | number:0 }} K€
  </div>
  <small *ngIf="formErrors.minBudgetOther" class="text-danger">
    {{ formErrors.minBudgetOther }}
  </small><br>

  <label>En moyenne, combien de projets réalisez-vous par an ?</label>
  <div class="input-group input-group-lg">
    <span class="input-group-addon" id="sizing-addon1"><span class="secondary ">N°</span></span>
    <input id="nbProjetRealise"
           formControlName="nbProjetRealise"
           type="number"
           min="0"
           step="1"
           class="form-control"
           placeholder="Projets réalisez-vous par an"
           aria-describedby="sizing-addon1">
  </div>
  <small *ngIf="formErrors.nbProjetRealise" class="text-danger">
    {{ formErrors.nbProjetRealise }}
  </small><br>

  <label>Quel est le budget moyen de ces projets ?</label>
  <div class="input-group input-group-lg">
    <span class="input-group-addon" id="sizing-addon1"><span class="secondary ">€</span></span>
    <input id="budgetMoyen"
           formControlName="budgetMoyen"
           type="number"
           min="0"
           step="1000"
           class="form-control"
           placeholder="Budget moyen"
           aria-describedby="sizing-addon1">
  </div>
  <small *ngIf="formErrors.budgetMoyen" class="text-danger">
    {{ formErrors.budgetMoyen }}
  </small><br> -->

  <label>Pouvez-vous citer des agences d'architecture en mesure de vous recommander ?</label>
  (Plus vous avez de recommandations et plus les architectes auront tendance à vous faire confiance.)
  <div formArrayName="recommandation">
      <div class="row" *ngFor="let address of form['controls'].recommandation['controls']; let i=index">
        <div class="recommende-top">
        </div>
        <div [formGroupName]="i">
          <div class="form-group col-sm-6">
            <label>Nom de l'agence {{i + 1}}</label>
            <div class="input-group input-group-lg">
              <span class="input-group-addon" id="sizing-addon1"><span class="glyphicon secondary glyphicon-user "></span></span>
              <input type="text" class="form-control" formControlName="nameArchi">
            </div>
          </div>
          <div class="form-group col-sm-6">
            <label>Email de l'agence {{i + 1}}</label>
            <span class="glyphicon glyphicon-remove pull-right" *ngIf="form['controls'].recommandation['controls'].length > 1" (click)="removeRecommandation(i)"></span>
            <div class="input-group input-group-lg">
              <span class="input-group-addon" id="sizing-addon1"><span class="secondary ">&#64;</span></span>
              <input type="text" class="form-control" formControlName="mailArchi">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row margin-20">
      <a (click)="addRecommandation({})" style="cursor: default">
        Ajouter une autre recommandation +
      </a>
    </div>

</form>
