import { Component, OnInit, ViewChild, ElementRef, NgZone, NO_ERRORS_SCHEMA } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { ArchitectService, AttributService, ArtisanService, AuthService } from '../../../_services';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { NgSharedModule } from 'src/app/all/ngshared.module';

//Maps
import { GoogleAutocompleteComponent } from 'src/app/all/google-autocomplete/google-autocomplete.component';

import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { ArchiExpiredModalComponent } from 'src/app/all/archi-expired-modal/archi-expired-modal.component';
import { ArchiUploadCarnetAdresseComponent } from 'src/app/architect/archi-upload-carnet-adresse/archi-upload-carnet-adresse.component';
import { NeedSubscriptionPopup2Component } from 'src/app/architect/need-subscription-popup2/need-subscription-popup2.component';
import { environment } from 'src/environments/environment';

declare var $;

@Component({
  standalone:true,
  selector: 'app-carnet-adresses',
  imports:[    
    SpinnerComponent,
    HeaderComponent,
    ArchiExpiredModalComponent,
    ArchiUploadCarnetAdresseComponent,
    NeedSubscriptionPopup2Component,
    GoogleAutocompleteComponent,
    NgSharedModule
  ],
  providers: [],
  templateUrl: './carnet-adresses.component.html',
  styleUrls: ['./carnet-adresses.component.scss']
})
export class CarnetAdressesComponent implements OnInit {
  @ViewChild("search")
  public searchElementRef: ElementRef;

  public allTags: Array<Object> =[];
  public selectedRelation:any = {artisanOwned: {}};
  public selectedSearchTags =[];
  public artisanSearchField ='';
  public tags: Array<Object> =[];
  public updateTags: Array<Object> =[];
  public newUpdateTags;
  public showEditTags:Boolean =false;
  public creatingTags:Boolean =false;
  public loading:Boolean = true;
  public artisanForm: UntypedFormGroup;
  public relationsList:any=[];
  public selectedArtisan:any ={
    _id : "",
    artisanOwned: "",
    tags : [],
  };
  public isFirstRequest: Boolean = false;
  public currentPage: number = 1;
  public totalRelations: number = 0;
  public recordsPerPage: number = 20;
  public selectedTags: any = [];
  public showLeftArrow: Boolean = true;
  public showRightArrow: Boolean = true;

  private subscriptions: Array<Subscription> = [];

  constructor(
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private architectService: ArchitectService,
    private artisanService: ArtisanService,
    private attributService: AttributService,
    // private mapsAPILoader: MapsAPILoader, tocorrect
    private AuthService: AuthService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.noMultiAccountsAllowed();
    this.createForm();
    this.searchEnterprises();
    this.allTags =this.attributService.getRelationTags();
    if(!environment.onPremise)window.analytics.page('access_contacts');

  }
  getGoogleMapValueChange(event){
    this.selectedRelation.artisanOwned['address'] = event["description"];
  }

  public toggleEditTags(){
    if(this.AuthService.licence == "without"){
      $('#upgrade-modal').modal('show');
      return;
    }
    this.selectedTags = [...this.selectedRelation.tags];
    this.showEditTags = !this.showEditTags;
  }

  public openArtisanInfo(relation){
    this.selectedRelation =relation;
    let artisanOwned = relation.artisanOwned;
    artisanOwned.address = artisanOwned.address ||"";
    this.selectedArtisan = artisanOwned;
    this.showEditTags = false;
  }
  public openImportCsv(){
    if(this.AuthService.licence == "without"){
      $('#upgrade-modal').modal('show');
      return;
    }
    $('#importCarnetAdress').modal('show');
  }
  public openAddArtisanPopup(){
    if(this.AuthService.licence == "without"){
      $('#upgrade-modal').modal('show');
    }else{
      $('#ajouter-artisan-popup').modal('show');
    }
  }

  public createForm(){
    this.artisanForm = this.fb.group({
      emailAgency: ['',
      Validators.compose([
        Validators.required, Validators.email
      ])
      ],
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      tags: [[]],
      telAgency: '',
      demo: false
    });
  }

  public searchTags(event){
    this.tags =this.allTags.filter((e, i) => e['value'].toLowerCase().includes(event.query.toLowerCase()));
  }
  public searchUpdateTags(event){
    this.updateTags =this.allTags.filter((e, i) => e['value'].toLowerCase().includes(event.query.toLowerCase()));
  }
  public createNewArtisan(){
    this.creatingTags =true;
    this.architectService.addCarnetAdressesArtisan(this.artisanForm.value)
    .subscribe(data => {
      $('#ajouter-artisan-popup').modal('toggle');
      this.artisanForm.reset();
      this.creatingTags =false;
      this.searchEnterprises();

      this.messageService.add({ key: 'toast', severity: 'success', summary: "Ajout d'une entreprise", detail: "L'entreprise a été invité dans votre réseau", life: 5000 });

      // segment event
      if(!environment.onPremise)window.analytics.track('add_contact',{properties:{acValue: 'add_contact'}});
    },
    err => {
      this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur d'ajout", detail: 'Erreur serveur', life: 5000 });
    }
    );
  }
  public removeArtisan(relationId){
    this.architectService.removeCarnetAdressesArtisan(relationId)
      .subscribe(data => {
        this.selectedRelation = {artisanOwned: {}};
        this.relationsList =this.relationsList.filter((e) => e._id != data.relationId);

        this.messageService.add({ key: 'toast', severity: 'success', summary: "Entreprise supprimée", detail: "Cette entreprise à été supprimée de votre réseau", life: 5000 });

        // segment event
        if(!environment.onPremise)window.analytics.track('delete_contact',{properties:{acValue: 'delete_contact'}});
      });
  }
  /// WILL BE USED LATER
  public getRelationTags(){
    this.subscriptions.push(
      this.architectService.getRelationTags()
      .subscribe(data => {
        this.messageService.add({ key: 'toast', severity: 'success', summary: 'Remove New Relation', detail: 'Remove New Relation Description', life: 5000 });
      },
      err => {
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Remove Relation Error', detail: 'Remove Relation Description Error', life: 5000 });
      }
      )
    );
  }
  public removeTagsDuplicat(tags){
    let data=tags.filter((t,i,a) => a.findIndex(e=>(e.value === t.value))===i);
    return data;
  }
  public updateRelationTags(tags){
    this.creatingTags =true;
    let body ={
      tags
    };
    this.subscriptions.push(
      this.architectService.updateRelationTags(this.selectedRelation['_id'], body)
      .subscribe(data => {
        this.selectedRelation['tags'] =body.tags;
        this.newUpdateTags=[];
        this.creatingTags =false;
        this.toggleEditTags();
        // this._notificationService.success("Modification enregistrée","Modification des tags enregistrée", {timeOut: 5000});
      },
      err => {
        this.creatingTags =false;
        this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur d'ajout", detail: 'Erreur serveur', life: 5000 });
      }
      )
    );
  }

  public deleteTag(tag){

    this.creatingTags =true;
    this.selectedRelation['tags'] =this.selectedRelation['tags'].filter((e, i) => e.value != tag.value);
    let body ={tags: this.selectedRelation['tags']};

    this.subscriptions.push(
      this.architectService.updateRelationTags(this.selectedRelation['_id'], body)
      .subscribe(data => {
        this.creatingTags =false;
        this.messageService.add({ key: 'toast', severity: 'success', summary: 'Modification enregistrée', detail: 'Modification des tags enregistrée', life: 5000 });
      },
      err => {
        this.creatingTags =false;
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: 'Erreur serveur', life: 5000 });
      }
      )
    );

  }
  changeTag(event){
    // let selectedTag =this.allTags.find((e, i) => e['value'] == event.target.value);
    let selectedTag =this.allTags.find((e, i) => e['value'] == event.value);
    /// check if already in tags List
    let tags = this.selectedRelation['tags'] ||[];
    if (tags.filter(e => e.value === selectedTag['value']).length === 0) {
      let body =[...tags, selectedTag];
      this.updateRelationTags(body);
    }
  }
  tagSelected(){
    this.currentPage = 1;
    this.searchEnterprises();
  }

  tagAdded(event){
    this.selectedRelation['tags'] = event.value;
  }

  public updateArtisan(){
    this.updateRelationTags(this.selectedRelation['tags']);
    
    // console.log("this.selectedArtisan : ", this.selectedArtisan);

    this.creatingTags = true;
    this.subscriptions.push(
      this.artisanService.update(this.selectedArtisan._id , this.selectedArtisan)
      .subscribe(
        data => {
          this.creatingTags = false;
          this.showEditTags = false;
          this.messageService.add({ key: 'toast', severity: 'success', summary: 'Modification enregistrée', detail: 'Votre contact a été modifié', life: 5000 });
          // segment event
          if(!environment.onPremise)window.analytics.track('update_contact',{properties:{acValue: 'update_contact'}});
        },
        err => {
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Search', detail: 'Erreur serveur', life: 5000 });
        }
      )
    )
  }
  searchEnterprises(){
    let body ={
      searchQuery: this.artisanSearchField,
      tags: this.selectedSearchTags
    }
    this.subscriptions.push(
      this.architectService.searchEntreprise(body, this.currentPage)
      .subscribe(
        data => {
          // console.log("data : ", data.data);
          this.loading  = false;
          this.relationsList = data.data.relations;
          if(!this.isFirstRequest){
            this.totalRelations = data.metadata.count;
            this.isFirstRequest = true;
          }
          this.showAndHideArrows();
        },
        err => {
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Search', detail: 'Erreur serveur', life: 5000 });
        }
      )
    );
  }

  prevPage(){
    let page = this.currentPage + this.recordsPerPage;

    this.currentPage--;
    if(this.currentPage >= 1){
      this.searchEnterprises();
    }else{
      this.currentPage = 1;
    }
    this.showAndHideArrows();
  }
  nextPage(){
    let page = this.currentPage * this.recordsPerPage;

    if(page <= this.totalRelations){
      this.currentPage++;
      this.searchEnterprises();
    }
    this.showAndHideArrows();
  }

  public showAndHideArrows(){
    let page = this.currentPage * this.recordsPerPage;

    if(this.currentPage == 1){
      this.showLeftArrow = false;
    }else{
      this.showLeftArrow = true;
    }

    if(page <= this.totalRelations){
      this.showRightArrow = true;
    }else{
      this.showRightArrow = false;
    }

  }
  private noMultiAccountsAllowed(){
    this.subscriptions.push(
      this.AuthService.noMultiAccountsAllowed()
        .subscribe(
          data => {
            
          }  
        )
    );
  }
  public ngOnDestroy(): void {
    $('#artisan-popup').modal('hide');

    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }

}
