import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  standalone:true,
  name: 'dpgfLotTitle'
})
export class DpgfLotTitlePipe implements PipeTransform {

  transform(value:string) {
      if (value) {
          var words = value.split(" ");
          let total = 0;
          let result = "";
          let threepoint = true
          words.forEach((word)=>{
            total = total + word.length
            if(total < 15)result += word + " "
            else if(threepoint) {
              result += " ...";
              threepoint = false;
            }
          })

          return result;
      }
      return value;
  }
}
