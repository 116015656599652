<div class="chantier-list-container flex">    

  <!-- Single Chantier Card -->
  <div class="chantier-item margin-left-15" 
    *ngFor="let facture of factures; let i = index" [ngClass]="{'margin-left-15': i > 0}">
    <div class="text-center">
      
      <p class="chantier-status" [ngClass]="facture.status" *ngIf="facture.status != 'answered' && owner == 'artisan'">{{ matching[facture.status] }}</p>
      <p class="chantier-status" [ngClass]="facture.architectResponse" *ngIf="facture.status == 'answered'">{{ matching[facture.architectResponse] }}</p>

      <h4 class="chantier-progress no-margin">{{ facture.totalAcompte + facture.lastTotalAcompte | number}}%</h4>
      <p class="chantier-date no-margin"> {{ facture.createdAt | date: "mediumDate" }}</p>
      <button class="ag-btn-bordered-xs open-chantier-btn" (click)="openSingleChantierPage(facture._id)">Ouvrir</button>
    </div>
  </div>
  <!-- END Single Chantier Card -->

  <!-- new Chantier Card -->
  <div  *ngIf="owner == 'artisan'" class="chantier-item margin-left-15 add-new-chantier-widget pointer flex-middle" (click)="onCreateFacture()">
    <div class="text-center">
      <img src="assets/add-icon.svg">
      <p>Ajouter une situation</p>
    </div>
  </div>
  <!-- END new Chantier Card -->

</div>

<div class="container" *ngIf="false">

  <div class="row">
  <h4 class="situations-title">Situation(s)</h4>
  <div *ngFor="let facture of factures" class="col-md-3">
    <a class='text-color' [routerLink]="owner == 'artisan' ? ['/artisan','dpgf-chantier', facture._id] : ['/architect', 'chantier-facture', facture._id]">
      <div class="floating-card card">
        <div class="row">
          <div *ngIf="facture.status != 'answered' && owner == 'artisan'" class="tag" [ngClass]="facture.status">
            {{ matching[facture.status] }}
          </div>
          <div *ngIf="facture.status == 'answered'" class="tag" [ngClass]="facture.architectResponse">
            {{ matching[facture.architectResponse] }}
          </div>
          <div class="card-title">
            {{ facture.totalAcompte + facture.lastTotalAcompte | number}}%
          </div>
          <div class="card-sub-title">
            {{ facture.createdAt | date:'mediumDate':'+0200':'fr'}}<br>
            {{ facture.createdAt | date:'shortTime':'+0200':'fr'}}
          </div>
        </div>
        <div class="open-btn">
          <a [routerLink]="owner == 'artisan' ? ['/artisan','dpgf-chantier', facture._id] : ['/architect', 'chantier-facture', facture._id]">
            <button  class="login btn-sm btn-primary-border">
              Ouvrir
            </button>
          </a>
        </div>
      </div>
    </a>
  </div>

  <div *ngIf="owner == 'artisan'" pTooltip="Ajouter une situation" tooltipPosition="top" class="col-md-3 col-sm-4 col-xs-6">
    <a (click)="onCreateFacture()" >
      <div id="add-card" class="floating-card card">
          <i class="fa fa-plus plus scale-up" aria-hidden="true"></i>
      </div> 
    </a>
  </div>

</div>
</div>
