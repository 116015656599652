import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone:true,
  name: 'parseLotName'
})
export class ParseLotNamePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return (value||'').replace(/[0-9]/g, '').replace('.','').replace('-','')
  }

}
