import { Component } from '@angular/core';
import { AdminUsersComponent } from "../admin-users/admin-users.component";
import { HeaderComponent } from "../../../all/header/header.component";
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { AdminDepartementComponent } from "../admin-departement/admin-departement.component";

import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-admin-interface',
    standalone: true,
    templateUrl: './admin-interface.component.html',
    styleUrl: './admin-interface.component.scss',
    imports: [AdminUsersComponent, HeaderComponent, NgSharedModule, AdminDepartementComponent]
})
export class AdminInterfaceComponent {
  public archiOrUser='users';
  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Dashboard');
  }
  public choose(state){
    this.archiOrUser = state;
  }

}
