<div id="artisanModal" class="modal fade" role="dialog" > 
  <div class="modal-dialog">
    <div class="modal-content" style=" width: 798px; height: 620px;  display: flex;border-radius: 8px; flex-direction: row; background-color: #E0E0E0;">
      <div class="inner-content">
        <div class="left-column">
          <div class="left-column-flex">
            <div class="left-column-header">
              <div class="company-global-information">
                <div class="layout">
                  <img
                  *ngIf="((invitation.artisan||{}).logo|| {}).cdnUrl" src="{{((invitation.artisan||{}).logo|| {}).cdnUrl}} "
                    class="logo-company"
                  />
                  <img
                  *ngIf="!((invitation.artisan||{}).logo|| {}).cdnUrl" src="assets/rob/helmet.png"
                    class="logo-company"
                  />
                </div>

                <div class="company-references" >
                  <div class="company-name">
                    {{ invitation.artisanName == '' ? (invitation.artisan||{}).name : invitation.artisanName}}
                  </div>
                  <div class="contact" *ngIf="!invitation.isFactice">
                    <div class="email-box">
                      <img class="mail-icon" [src]="'assets/dpgf/Icon_DPGF/mail_outline.svg'" alt="">
                      <div class="email">
                        {{(invitation.artisan||{}).emailAgency}}
                      </div>
                    </div>
                    <div class="phone-box">
                      <img class="phone-icon" [src]="'assets/dpgf/Icon_DPGF/phone_android.svg'" alt="">
                      <div class="phone">{{(invitation.artisan||{}).telAgency}}</div>
                    </div>
                    <div class="company-adress" *ngIf="invitation.artisan?.address">
                      <img class="location-icon" [src]="'assets/dpgf/Icon_DPGF/location_on.svg'" alt="">
                      <div class="adress">
                        {{(invitation.artisan||{}).address}}
                      </div>
                    </div>
                    <div class="lot-label" >
                      <img class="tag-icon" [src]="'assets/dpgf/Icon_DPGF/label.svg'" alt="">
                      <div class="text-label">
                        {{relationArtisanTags}}
                      </div>
                    </div>
                  </div>
                  <div class="no-infos" *ngIf="invitation.isFactice"><p>Chiffrage créé par l'utilisateur</p></div>

                  <div class="artisan-info-btns-wrapper" *ngIf="!removingInvite && invitation.artisanName == ''">
                    <img class="open-in-new-icon" [src]="'assets/dpgf/Icon_DPGF/Open-in-new.svg'" alt="">
                    <a class="see-profile" [href]="'/artisan-profil/' + (invitation.artisan||{})._id" target="_blank">Voir le profil</a>
                  </div>
                </div>

              </div>
              <div class="activities" >
                <div style="display: flex;flex-direction: row;">
                  <div class="comments"  
                        [ngStyle]="{'color': showComentSection ? '#3805f2' : '#000', 'border-bottom' : showComentSection ? '3px solid #3805f2': 'none' }">
                    <div class="comments-label"  (click)="setVariablesStatusOnSwitchBetweenEmailAndComment($event)">
                      Activités/Commentaires
                    </div>
                  </div>
                  <div class="email-label" 
                        [ngStyle]="{'color': showEmailSection ? '#3805f2' : '#000', 'border-bottom' : showEmailSection ? '3px solid #3805f2': 'none' }" 
                        (click)="setVariablesStatusOnSwitchBetweenEmailAndComment($event)">Email
                  </div>
                </div>
                <div class="aglo-booster" *ngIf="invitation.agloPlus" >
                    <span>AGLO BOOSTER©</span>
                </div>
              </div>
            </div>
            
            <div *ngIf="showComentSection" class="comment-section">
              <textarea class="scrollable-area" 
                placeholder="Ce commentaire ne sera visible que par vous et votre équipe&#10;&#10;Tapez @ pour notifier un collaborateur."
                rows="8" cols="80"
                #CommentTextArea
                (keyup)="newCommentChange($event.target.value)"
                [(ngModel)]="newComment">
              </textarea>
              <div class="collabs-list" *ngIf="showCollabDropdown">
                <ul>
                  <li *ngFor="let collab of collabsList" (click)="addCollab(collab)">
                    <ng-container *ngIf="collab.firstName">
                      <p>{{ collab.firstName + collab.lastName }}</p>
                    </ng-container>
                    <ng-container *ngIf="!collab.firstName">
                      <p>{{ collab.email }}</p>
                    </ng-container>
                  </li>
                </ul>
              </div>
            </div>
            <div class="new-comment-btn" *ngIf="newComment != '' && showComentSection ">
              <div class="" *ngIf="!creatingComment">
                <button class="default-button shallow-button"  (click)="sendNewLogComment()">Envoyer le commentaire</button>
              </div>
            </div>
            <div class="activities-logs spinner" *ngIf="loadingCommentsAndActivities">
              <app-spinner></app-spinner>
            </div>
            <div *ngIf="showComentSection && !loadingCommentsAndActivities" 
                  class="activities-logs scroller-2-y " 
                  [ngStyle]="{'top':(newComment != '')? '261px':'230px'}">
              <div class="activity-information" [ngClass]="{'display-comment-item': (!(comment |loggs) && comment.type != 'comment')}" *ngFor="let comment of commentsAndActivities">
                <div class="img-chat-bubble" >
                  <img *ngIf="comment.type=='comment'"  [src]="'assets/dpgf/Icon_DPGF/chat_bubble_outline.svg'" alt="">
                  <img *ngIf="comment.type=='send_reminder'"  [src]="'assets/dpgf/Icon_DPGF/mail_outline.svg'" alt="">            
                </div>
                <div class="div-32">
                  <div class="user-comment">
                    <div *ngIf="comment.type != 'admin_invite_artisan' && comment.type != 'admin_invite_artisan_plus' && comment.user" 
                          class="first-name-letter" [ngStyle]="{'border-color': comment?.user?.color ,'color' :comment?.user?.color}">
                      <p >{{ (comment.userName||"Entreprise")| uppercase | slice:0:1  }}</p>
                    </div>
                    <div *ngIf=" !comment.user" 
                          class="artisan-logo"  [ngStyle]="{ 'background': artisanHasLog ? 'url(' + ((invitation.artisan || {}).logo || {}).cdnUrl + ')': 'url(assets/worker.png)' , 'border-radius':'50px', 'width':'16px','height':'16px','background-size': 'cover'}">
                    </div>
                    <div class="comment-message" *ngIf="comment.type != 'comment' && comment !=''">
                      <p class="comment-value"  [innerHTML]="comment |loggs"></p>
                    </div>
                    <div *ngIf="comment.type == 'comment'" class="comment-message">
                      <p class="commenter-name"><strong >{{ (comment.userName ||"") }}</strong> {{ comment.message }}</p>
                    </div>
                  </div>
                  <div class="cmt-date">
                    <p>{{comment?.createdAt | customFormatDate }}</p>
                  </div>
                </div>                            
              </div>
            </div>
            <div class="sent-dce-in-comment-section" *ngIf="showComentSection" >
              <button [disabled]="disabledButtons" 
                      [disabled]="isProjectDemo" (click)="toggleDCEMailSection()"
                      *ngIf="invitation.col == 'a_consulter' && !invitation.isFactice "
                      [ngStyle]="{'background-color': (showEmailSection ) ? 'rgba(10, 23, 71, 1)' : '', 'color': (showEmailSection) ? 'white' : 'rgba(10, 23, 71, 1)'}" >
                Envoyer le DCE
              </button>
              <div class="update-dce" (click)="toggleShowUpdateDCEMail()" 
                    *ngIf="invitation.col !='ne_chiffre_pas' && invitation.col !='a_consulter' && (invitation.dpgfPricing||{}).currentVersion != (dpgf||{}).currentVersion && !sendingEmail ">
                <img [src]="'assets/dpgf/Icon_DPGF/update-icon.svg'"alt="">
                <span>
                  Mettre à jour le DCE
                </span>
              </div>
              <div class="update-dce no-border" *ngIf="sendingEmail">
                <app-spinner></app-spinner>
              </div>
            </div>
            <div *ngIf="showEmailSection" class="left-column-body"> 
              
              <div class="flex space-between" *ngIf="!invitation.isFactice">
                
                <p>Envoyer un email à cette entreprise</p>

                <div>
                  <app-architect-emails-templates-dropdown
                    [emailsTemplates]="architectEmailTemplates"
                    (emailTemplateSelected)="emailTemplateSelected($event)"
                  ></app-architect-emails-templates-dropdown>
                </div>

              </div>

              <div class="div-help" [ngClass]="{'scroller-2-y':showEmailSection}" >
                <div class="email-object" *ngIf="showEmailSection && !invitation.isFactice">
                  <input (ngModelChange)="inputChanged($event)" type="text" class="input-item width-100" style="border-bottom: none;height: 100%; padding-left: 9px; width: 490px;"  placeholder="Objet" [(ngModel)]="newEmail.subject">
                  <!-- <p class="text-danger no-padding" *ngIf="emailFormSubjectError">Vous devez renseigner un objet</p> -->
                </div>
                <div class="email-body" *ngIf="showEmailSection && !invitation.isFactice">
                  <app-editor class="cctp-editor editor"
                              placeholder="ex: Bonjour..."
                              Description
                              name="name"
                              [style]="{'height':'322px'}"
                              [(ngModel)]="newEmail.message"
                              (ngModelChange)="inputChanged($event)"></app-editor>
                  <!-- <p class="text-danger no-padding" *ngIf="emailFormMessageError">Vous devez renseigner un message</p> -->
                </div>


                <div class="envoyer-dce" *ngIf="!invitation.isFactice">
                  <button [disabled]="disabledButtons" [disabled]="isProjectDemo" (click)="toggleDCEMailSection()"
                    [ngClass]="{'btn-disabled': disabledButtons}"
                    *ngIf="invitation.col == 'a_consulter' && showEmailSection && !sendingDCE"  >
                    Envoyer le DCE
                  </button>
                  <button [disabled]="disabledButtons" *ngIf="invitation.col != 'a_consulter' && showEmailSection &&  !sendingEmail " [ngClass]="{'btn-disabled': disabledButtons}"  (click)="sendEmail()">Envoyer</button>
                  <div class="horizontal-items" *ngIf="showEmailSection">
                    <div class="quantity-checkbox" *ngIf="invitation.col == 'a_consulter'">
                      <p-checkbox  binary="true" [(ngModel)]="showQuantity"></p-checkbox>
                    </div>
                    <div class="quantity-checkbox" *ngIf=" (invitation.col != 'a_consulter' && invitation.col != 'ne_chiffre_pas') && (invitation.dpgfPricing||{}).currentVersion != (dpgf||{}).currentVersion ">
                      <p-checkbox  binary="true" (onChange)="controlEmail()" [(ngModel)]="updateSelected"></p-checkbox>
                    </div>
                    <div class="quantity-label">
                      <p class="item-label"  *ngIf="invitation.col == 'a_consulter' && invitation.pricingStatus == 'no-pricing'">Inclure le quantitatif</p>
                      <p class="item-label"  *ngIf="(invitation.col !='a_consulter' && invitation.col != 'ne_chiffre_pas') && (invitation.dpgfPricing||{}).currentVersion != (dpgf||{}).currentVersion ">Mettre à jour le DCE</p>
                    </div>

                  </div>
                </div>
                <div class="envoyer-dce" *ngIf="(sendingDCE || sendingEmail)">
                  <app-spinner></app-spinner>
                </div>

                <!-- factice email section -->

                <div class="factice-email-section" *ngIf="invitation.isFactice">
                  <div class="transmit-chiffrage-section" *ngIf="!showFacticeMailForm">
                    <p class="paragraph-1">
                        Vous êtes sur la carte d'une entreprise factice, les emails ne sont pas disponibles.
                    </p>
                    <p class="paragraph-2">
                      Vous pouvez transmettre ce chiffrage par email à une entreprise pour qu'elle le récupère sur son compte AGLO et puisse continuer de chiffrer.<br /><br />
                      Attention ! Une fois le chiffrage récupéré par une entreprise vous ne pourrez plus l'éditer.
                    </p>
                    <button class="transmit-btn" *ngIf="!sendingDCE" (click)="showFacticeMailFormEmailSection()">Transmettre ce chiffrage</button>
                  </div>
                  <div class="email-section" *ngIf="showFacticeMailForm">
                    <div class="email-enterprise">
                      <label class="input-label" for="email-input">Email de l’entreprise</label>
                      <input type="text" placeholder="Email de l’entreprise" [ngClass]="{'danger-outline':facticeFormMailError}" (ngModelChange)="inputChanged($event)" [(ngModel)]="facticeArtisanEmail.email" id="email-input">
                      <p class="text-danger no-padding email-error"  *ngIf="facticeFormMailError">Vous devez renseigner un email</p>
                    </div>
                    <div class="email-object1">
                      <label class="input-label" for="object-input">Objet</label>
                      <input type="text"  placeholder="Objet de l’e-mail" (ngModelChange)="inputChanged($event)" [(ngModel)]="facticeArtisanEmail.subject" id="object-input">
                    </div>
                    <div class="email-content">
                      <label class="input-label" for="message-input">Message</label>
                      <app-editor
                        id="message-input"
                        class="cctp-editor editor"
                        placeholder="Rédigez le contenu de l’e-mail"
                        Description
                        name="name"
                        [style]="{'height':'216px','border': '1px solid var(--silver-iron-dbdde-1, #DBDDE1)',
                        'background': 'white',
                        'border-bottom-left-radius': '4px',
                        'border-bottom-right-radius': '4px'}"
                        (ngModelChange)="inputChanged($event)"
                        [(ngModel)]="facticeArtisanEmail.message">
                      </app-editor>
                    </div>
                    <div class="email-bottom">
                      <button class="transmit-btn" [disabled]="disabledFacticeButtons" [ngClass]="{'transmit-btn-allowed':!disabledFacticeButtons}"  (click)="sendFacticeMail()"> Transmettre ce chiffrage</button>
                      <button class="cancel-btn" (click)="cancelFacticeEmail()">Annuler</button>
                    </div>
                  </div>
                </div>

                <!-- email loggs section -->

                <div *ngIf="showEmailSection" class="emails-loggs">
                  <div class="single-mail-item" *ngFor="let email of emailsList">
                    <div class="single-mail-item-header">
                      <div class="logo-company" [ngStyle]="{'color':email?.user?.color , 'border-color':email?.user?.color}" >
                          <p>{{email?.userName |uppercase | slice:0:1}}</p>
                      </div>
                      <div class="artisan-and-architect-names">
                        <p class="name">{{email?.userName}}</p>
                        <p class="name" style="font-weight: 400;">À {{this.invitation.artisan.name}}</p>
                      </div>
                      <div class="last-sent-time"><span>{{email?.createdAt }}</span></div>
                    </div>
                    <div class="single-mail-item-body">
                      <h4 class="single-mail-item-title sections-title">{{ email?.subject }}</h4>
                      <p class="single-mail-item-message" [innerHTML]="email?.message"></p>
                    </div>
                  </div>
                  <div class="no-emails-cont" *ngIf="emailsList.length == 0">
                    <p>Il n’y a pas encore d’échange d’email avec cette entreprise.</p>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>

        <!-- moda's right side section -->

        <div class="right-column">
          <div class="icon-close" (click)="closeModal()">
            <img [src]="'assets/dpgf/Icon_DPGF/icon_close.svg'">
          </div>
          <div class="text">Suivi du chiffrage </div>
          <div class="status">Statut</div>
          <div class="status-label" (click)="displayDropDownList(invitation.col, invitation.status)">
            <span *ngIf="invitation.col == 'a_consulter' && invitation.pricingStatus == 'no-pricing'" 
                  [ngStyle]="{'background-color':statusColors[invitation.col]}">À CONSULTER</span>   
            <span *ngIf="invitation.status =='artisan-invite' && invitation.pricingStatus == 'pricing-sent'"
                  [ngStyle]="{'background-color':statusColors[invitation.col]}" > EN ATTENTE </span> 
            <span *ngIf="invitation.pricingStatus=='pricing-sent' && invitation.status !='artisan-invite' && invitation.col=='en_cours'"
                  [ngStyle]="{'background-color':statusColors[invitation.col]}" >EN COURS</span>
            <span *ngIf="  invitation.col=='offre_remise'"
                  [ngStyle]="{'background-color':statusColors[invitation.col]}" >OFFRE REMISE</span>
            <span *ngIf="invitation.col == 'ne_chiffre_pas' || invitation.status=='artisan-refuser' && invitation.pricingStatus=='pricing-sent'"
                  [ngStyle]="{'background-color':statusColors[invitation.col]} " >NE CHIFFRE PAS</span>
          </div>  
          <div *ngIf="showDropdown" [class.show]="showDropdown" class="dropdown-status-list">
            <div *ngFor=" let status of listOfStatusToChangeTo">
              <a (click)="updateInvitation(status)">{{status}}</a>
            </div>
          </div>
          <div class="delivery-date" *ngIf=" invitation.pricingStatus=='pricing-sent' &&  contains(invitation.status,enCours)">
            <p class="label">Remise prévue</p>
          </div>
          <p-calendar *ngIf=" invitation.pricingStatus =='pricing-sent' && contains(invitation.status,enCours)" 
                      styleClass="myclass"  
                      class="date-group"
                      dateFormat="dd/mm/yy"
                      [inputStyle]="{'border-bottom': 'none','height': '28px', 'width': '100%', 'padding': '0px 9px'}" 
                      [(ngModel)]="invitation.deliveryDate" 
                      [iconDisplay]="'input'" 
                      [showIcon]="true" 
                      inputId="templatedisplay"
                      (onSelect)="updateInvitation($event)">
              <ng-template  pTemplate="inputicon" let-clickCallBack="clickCallBack">
                  <img style=" width: 16px; height: 16px; position: absolute; top: 6px; left: 97px; cursor: pointer;" 
                      class="pi pi-user pointer-events-none" 
                      (click)="clickCallBack($event)" 
                      [src]="'assets/dpgf/Icon_DPGF/calendar.svg'">
              </ng-template>
          </p-calendar>
          <div class="date-info" *ngIf="invitation.pricingStatus=='pricing-done'">
            <p *ngIf="invitation.status !='signe' && invitation.sentPricingDate"> {{invitation.sentPricingDate |date: 'dd/MM/yyyy'}}</p>
            <p *ngIf="invitation.status=='signe' && invitation.signingDate"> {{invitation.signingDate |date: 'dd/MM/yyyy'}}</p>
          </div>
          <div class="date-info" *ngIf="invitation.pricingStatus == 'pricing-sent' && invitation.status=='artisan-refuser'&& invitation.refusalDate">
            <p>{{invitation.refusalDate  | date: 'dd/MM/yyyy'}}</p>
          </div>
          <div class="date-info" *ngIf="invitation.col == 'en_attente' && invitation.dateDceSent">
            <p>{{ invitation.dateDceSent | date: 'dd/MM/yyyy' }}</p>
          </div>
          <div class="accept-refuse-group" 
                *ngIf=" invitation.col=='offre_remise'&& invitation.status!='signe' && !isRefusedOrAcceptedStatusChanged">
            <div class="accepte-btn" [ngStyle]="{'background-color':(invitation.status=='selectionne')? '#0A1747':''}">
              <div class="accept-grp"   (click)="changeAccepteOrRefuseStatus($event,'selectionne')">
                <img *ngIf="invitation.status!='selectionne'" class="accept-icon" [src]="'assets/dpgf/Icon_DPGF/validate-icon.svg'" alt="">
                <img *ngIf="invitation.status=='selectionne'" [src]="'assets/dpgf/Icon_DPGF/validate-white-icon.svg'" alt=""> 
                <button class="btn-accept" [ngStyle]="{'color':(invitation.status=='selectionne')?'white':'#0A1747'}" >Accepter</button>
              </div>
            </div>

            <div class="refuse-btn" [ngStyle]="{'background-color':(invitation.status=='refuse')? '#0A1747':''}">
              <div class="refuse-grp" (click)=" changeAccepteOrRefuseStatus($event, 'refuse')">
                <img *ngIf="invitation.status!='refuse'"  [src]="'assets/dpgf/Icon_DPGF/refuse-icon.svg'" alt="">
                <img *ngIf="invitation.status=='refuse'" [src]="'assets/dpgf/Icon_DPGF/refuse-white-icon.svg'" alt="">
                <button [ngStyle]="{'color':(invitation.status=='refuse')?'white':'#0A1747'}" >Refuser</button>
              </div>
            </div>
          </div>

          <div class="accept-refuse-group spinner" *ngIf="isRefusedOrAcceptedStatusChanged">
            <app-spinner></app-spinner>
          </div>
          
          <div class="assigned-lots-text">
            <p *ngIf="invitation.col!='offre_remise'">{{invitation?.lots?.length}} lots assignés sur {{dpgf.data.length}}</p>
            <p *ngIf="invitation.col =='offre_remise'">{{numberOfPricingLots}} lots chiffrés sur {{invitation?.lots?.length}} assignés</p>
            <div class="project-index" *ngIf="invitation.dpgfPricing?._id" [ngClass]="{'old-version': (invitation.dpgfPricing||{}).currentVersion != (dpgf||{}).currentVersion }">
              <span class="indice-badge flex-middle" 
                *ngIf="invitation.dpgfPricing">
                Ind.{{ invitation.dpgfPricing.currentVersion | indice }}
              </span>
            </div>
          </div>

          <div class="lots-part"  >
            <ul class="lots-list scroller"  >
              <li class="lot-item" *ngFor="let lot of invitation.lots;  let indexlot = index" >
                <div class="lot-info">
                  <div class="lot-icon">
                    <img *ngIf="lot.icon"
                    [src]="'assets/dpgf/Icon_DPGF/' +lot.icon"
                    class="lot-icon"
                    />
                  </div>
                  <div class="lot-index">{{lot.indexLot}}</div>
                  <div class="lot-name scroller-2-x" [ngClass]="{'crop-text':lot.total}">{{lot.name || lot.title}} </div>                   
                </div>
                <div *ngIf="invitation.pricingStatus == 'pricing-done' && lot.total" class="lot-pricing">{{lot.total | number:'1.0-0':'fr' | priceUnite:'€ HT'}}</div>
              </li>
            </ul>

            <button  class="add-btn" *ngIf="invitation.col !='offre_remise'" (click)="showLotsModal()">
              <img [src]="'assets/dpgf/Icon_DPGF/add.svg'" alt="">
            </button>

            <div class="total-pricing" *ngIf="invitation.pricingStatus =='pricing-done'" >
              <p class="label"> <strong>Total</strong></p>
              <p class="price-value">{{invitation?.dpgfPricing.totalHt | number:'1.0-0':'fr' | priceUnite:'€ HT'}}</p>
            </div>

            <button class="chiffrer-btn" 
                    *ngIf="invitation.col != 'ne_chiffre_pas' && invitation.isFactice" 
                    [ngStyle]="{'top': (invitation?.dpgfPricing?.status == 'finished' || invitation?.dpgfPricing?.pricingVersion >1)?'328px':'365px'}" 
                    (click)="navigateToChiffragePage()" >Chiffrer
            </button>
          </div>

          <div class="see-pricing" 
               *ngIf="invitation.pricingStatus!='no-pricing' && (invitation?.dpgfPricing?.status == 'finished' || invitation?.dpgfPricing?.pricingVersion >1)" 
               (click)="openDevis()" >
               <button>Voir le chiffrage</button>
          </div>

          <div class="withdraw-artisan-company" *ngIf="invitation.pricingStatus !='pricing-done'" (click)="retirerCeProjectInvite()" >
            <button>Retirer l’entreprise de la consultation</button>
          </div>

          <div class="withdraw-artisan-company" 
               *ngIf="invitation.dpgfPricing?.artisanFiles.length != 0 && invitation.pricingStatus =='pricing-done'">
               <button (click)="downloadArtisanFiles(invitation.dpgfPricing?.artisanFiles)">
                <img [src]="'assets/dpgf/Icon_DPGF/download-icon.svg'"alt=""> Télécharger l’offre au format .zip</button>
          </div>  

        </div>
      </div>
    </div>
  </div>
</div>


<!-- modal for lots part -->

<app-modal-lots-list [dpgfLot]="dpgfLot" 
                     [choosenLot]="invitation.lots" 
                     [openAddAssignedLotsModal]="openArchitectArtisanPopUP"
                     (addLots)="updateAssignedLots($event)">
</app-modal-lots-list>

