import { Component, OnInit, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone: true,
    selector: 'app-booster-profile-recommandations-form',
    imports:[
      NgSharedModule
    ],
    templateUrl: './booster-profile-recommandations-form.component.html',
    styleUrls: ['./booster-profile-recommandations-form.component.scss'],
})
export class BoosterProfileRecommandationsFormComponent implements OnInit {

  @Input() recommandationList: any = [];
  @Output() createNewContact: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteContact: EventEmitter<any> = new EventEmitter<any>();
  @Output() formIsValid: EventEmitter<any> = new EventEmitter<any>();

  public showContactForm: boolean = false;
  public contactForm: any = {
    nameArchi: "",
    phoneNumber: "",
    maitreDoeuvre: "",
    mailArchi: ""
  };
  
  constructor() { }

  ngOnInit(): void {
    this.showContactForm = this.recommandationList.length ? false : true;
    setTimeout(() => {
      this.emitFormIsValid();
    }, 200);
  }
  public showAddContactForm(){
    this.showContactForm = true;
  }

  public addContact(){
    let newContact = {...this.contactForm} 
    newContact["date"] = new Date(Date.now()).toLocaleString().split(',')[0];

    this.recommandationList.push(newContact);

    this.createNewContact.emit(newContact);

    this.emptyContactForm();
  }
  private emptyContactForm(){
    for(let key in this.contactForm){
      this.contactForm[key] = "";
    }
  }
  
  public removeContact(contactIndex){    
    this.recommandationList = this.recommandationList.filter((value, index) => index != contactIndex);

    this.deleteContact.emit(this.recommandationList);
  }

  private emitFormIsValid(){
    this.formIsValid.emit(true);
  }

}
