<div class="lot" class="scroller-1-y" style="max-height: calc(85vh - 130px);height: fit-content;">
    <app-spinner *ngIf="loading"></app-spinner> 
    <div *ngIf="!firstTime" >
        <div *ngIf="lots.sousLots && !lots.sousLots.length && !posts.length">
            Ce dossier est vide
        </div>
        <div class="lot" *ngFor="let lot of lots.sousLots; let i = index">
            <div class="one-dce">
                <i *ngIf="lot.loading" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                <!-- <i *ngIf="!lot.open && lot.sousLots && !lot.loading" (click)="openLot(lot._id,i)" class="fa fa-chevron-right" aria-hidden="true"></i>
                <i *ngIf="lot.open && lot.sousLots && !lot.loading" (click)="lot.open = false" class="fa fa-chevron-down" aria-hidden="true"></i>
                <i *ngIf="!lot.open && lot.sousLots && !lot.loading && !lot.icon" class="fa fa-folder" aria-hidden="true"></i>
                <i *ngIf="lot.open && lot.sousLots && !lot.loading && !lot.icon" class="fa fa-folder-open" aria-hidden="true"></i> -->
                <img class="margin-right-8 margin-bottom-4half img-icon" *ngIf="!lot.open && lot.sousLots && !lot.loading"  src="/assets/chevron-right.svg" (click)="openLot(lot._id,i)">
                <img class="margin-right-8 margin-bottom-4half img-icon" *ngIf="lot.open && lot.sousLots && !lot.loading" (click)="lot.open = false" src="/assets/chevron-down.svg">
                <img class="margin-right-8 margin-bottom-4half img-icon" *ngIf="!lot.open && lot.sousLots && !lot.loading"  src="/assets/blue-folder-icone.svg">
                <img class="margin-right-8 margin-bottom-4half img-icon" *ngIf="lot.open && lot.sousLots && !lot.loading"  src="/assets/folder-open-full.svg">
           
                <img *ngIf="lot.sousLots && !lot.loading && lot.icon" class="handle" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" height="15" alt="">
                <span class="secondary-lot" (click)="openLot(lot._id,i)"> {{lot.title}} </span>
                <div *ngIf="lot.open">
                    <app-archi-db-navigation [lots]=(newLots) [posts]=(newPosts) [firstTime]="false"></app-archi-db-navigation>
                </div>
            </div>
        </div>
        <div class="lot" *ngFor="let post of posts; let i = index">
            <span>
                <label class="form-control">
                <input type="checkbox" [checked]="checkPost(post)" (click)="selectPost(i)">
                </label>
            </span>
            <span class="lot-title" (click)="selectPost(i)">{{post.title}}</span>
        </div>
    </div>
    <div *ngIf="firstTime && rootLots.length"  style="max-height: calc(85vh - 130px);height: fit-content;">
        <div class="one-dce" *ngFor="let rootlot of rootLots; let j = index;">
            <div *ngIf="lots && !lots.length">
                Ce dossier est vide
            </div>
            <i *ngIf="rootlot.loading" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
            <!-- <i *ngIf="!rootlot.open && rootlot.lots && !rootlot.loading" (click)="openRootLot(j)" class="fa fa-chevron-right" aria-hidden="true"></i>
            <i *ngIf="rootlot.open && rootlot.lots && !rootlot.loading" (click)="rootlot.open = false" class="fa fa-chevron-down" aria-hidden="true"></i>
            <i *ngIf="!rootlot.open && rootlot.lots && !rootlot.loading" class="fa fa-folder" aria-hidden="true"></i>
            <i *ngIf="rootlot.open && rootlot.lots && !rootlot.loading" class="fa fa-folder-open" aria-hidden="true"></i> -->
            <img class="margin-right-8 margin-bottom-3 img-icon" *ngIf="!rootlot.open && rootlot.lots && !rootlot.loading"  src="/assets/chevron-right.svg" (click)="openRootLot(j)">
            <img class="margin-right-8 margin-bottom-3 img-icon" *ngIf="rootlot.open && rootlot.lots && !rootlot.loading" (click)="rootlot.open = false" src="/assets/chevron-down.svg">
            <img class="margin-right-8 margin-bottom-3 img-icon" *ngIf="!rootlot.open && rootlot.lots && !rootlot.loading"  src="/assets/blue-folder-icone.svg">
            <img class="margin-right-8 margin-bottom-3 img-icon" *ngIf="rootlot.open && rootlot.lots && !rootlot.loading"  src="/assets/folder-open-full.svg">
            <span class="main-lot" (click)='openRootLot(j)'> {{rootlot.archiName}} </span>
            <div *ngIf="rootlot.open" style="margin-left: 30px;">
                <div *ngFor="let lot of rootlot.lots; let i = index;">
                    <div class="one-dce">
                        <i *ngIf="lot.loading" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        <!-- <i *ngIf="!lot.open && lot.sousLots && !lot.loading" (click)="openLot(lot._id,i,lot)" class="fa fa-chevron-right" aria-hidden="true"></i>
                        <i *ngIf="lot.open && lot.sousLots && !lot.loading" (click)="lot.open = false" class="fa fa-chevron-down" aria-hidden="true"></i>
                        <i *ngIf="!lot.open && lot.sousLots && !lot.loading && !lot.icon" class="fa fa-folder" aria-hidden="true"></i>
                        <i *ngIf="lot.open && lot.sousLots && !lot.loading && !lot.icon" class="fa fa-folder-open" aria-hidden="true"></i> -->
                        <img class="margin-right-8 margin-bottom-3 img-icon" *ngIf="!lot.open && lot.sousLots && !lot.loading"  src="/assets/chevron-right.svg" (click)="openLot(lot._id,i,lot)">
                        <img class="margin-right-8 margin-bottom-3 img-icon" *ngIf="lot.open && lot.sousLots && !lot.loading" (click)="lot.open = false" src="/assets/chevron-down.svg">
                        <img class="margin-right-8 margin-bottom-3 img-icon" *ngIf="!lot.open && lot.sousLots && !lot.loading"  src="/assets/blue-folder-icone.svg">
                        <img class="margin-right-8 margin-bottom-3 img-icon" *ngIf="lot.open && lot.sousLots && !lot.loading"  src="/assets/folder-open-full.svg">
            
                        <img *ngIf="lot.sousLots && !lot.loading && lot.icon" class="handle" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" height="15" alt="">
                        <span class="main-lot" (click)="openLot(lot._id,i,lot)"> {{lot.title}} </span>
                        <ng-template [ngIf]="lot.open">
                            <app-archi-db-navigation [lots]=(newLots) [posts]=(newPosts) [firstTime]="false"></app-archi-db-navigation>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
