import { Component, OnInit, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone: true,

    selector: 'app-form-inputs',
    imports:[NgSharedModule],
    templateUrl: './form-inputs.component.html',
    styleUrls: ['./form-inputs.component.scss'],
})
export class FormInputsComponent implements OnInit {
  @Input() row;
  @Output() valueInserted: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public inputTyped(modelVariable, value){
    let  body = {
      [modelVariable]: value
    }
    this.valueInserted.emit(body);
  }


}
