<div class="modal fade" id="gpt-expired-free" role="dialog">
<div class="modal-dialog"  style="width: 576px;">
    <div class="modal-content" style="border-radius:13px;">
    <div class="modal-header">
        <span class="title">Limite de génération de descriptifs atteinte</span>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding:35px;">
        <p class="body-text">Vous avez atteint la limite de descriptions générées pendant la période d’essai.
            Souscrivez à une licence Max ou Intégral pour générer plus de descriptions.</p>
    </div>
    <div class="modal-footer" style="border-top:0px">
        <button type="button" class="btn-cancel" data-dismiss="modal">Annuler</button>
        <button type="button" class="btn-letgo" data-dismiss="modal" [routerLink]="['/architect/profil']" [queryParams]="{tab: 'licence'}" >C'est parti !</button>
    </div>
    </div>
</div>
</div>

<div class="modal fade" id="gpt-expired-pro" role="dialog">
    <div class="modal-dialog" style="width: 576px;">
        <div class="modal-content" style="border-radius:13px;">
        <div class="modal-header">
            <span class="title">Passez sur AGLO Max ou Intégral</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="padding:35px;">
            <p class="body-text">Cette fonctionnalité n’est disponible que pour les utilisateurs bénéficiants d’une licence AGLO Max ou AGLO Intégral.
                Souscrivez à une de ces licences pour pouvoir l’utiliser.</p>
        </div>
        <div class="modal-footer" style="border-top:0px" >
            <button type="button" class="btn-cancel" data-dismiss="modal">Annuler </button>
            <button type="button" class="btn-letgo" data-dismiss="modal" [routerLink]="['/architect/profil']" [queryParams]="{tab: 'licence'}">C'est parti !</button>
        </div>
        </div>
    </div>
    </div>