<form [formGroup]="subscriptionForm" novalidate>
  <div *ngIf="!isProfile">
    <div class="row">
      <div class="col-md-6">
        <label *ngIf="disabled">Prénom</label><br>
        <div class="input-group input-group-lg">
          <!-- <span class="input-group-addon" [@error]="error=='firstName'" id="sizing-addon1"><span [@error]="error=='firstName'" class="glyphicon secondary glyphicon-user" aria-hidden="true"></span></span> -->
          <input type="text" formControlName="firstName"  type="text" class="form-control" placeholder="Prénom" aria-describedby="sizing-addon1">
        </div>
        <small *ngIf="formErrors.firstName" class="text-danger">
          {{ formErrors.firstName }}
        </small>
      </div>
      <div class="col-md-6">
        <label *ngIf="disabled">Nom</label><br>
        <div class="input-group input-group-lg">
          <!-- <span class="input-group-addon" [@error]="error=='lastName'" id="sizing-addon1"><span [@error]="error=='lastName'" class="glyphicon secondary glyphicon-user" aria-hidden="true"></span></span> -->
          <input formControlName="lastName" type="text" class="form-control" placeholder="Nom">
        </div>
        <small *ngIf="formErrors.lastName" class="text-danger">
          {{ formErrors.lastName }}
        </small>
      </div>
    </div>
    <br>

    <div class="row">
      <div class="col-md-12">
        <div *ngIf="disabled">
          <label>Adresse email de connexion</label><br>
        </div>
        <div class="input-group input-group-lg">
          <!-- <span class="input-group-addon secondary" [@error]="error=='email'" id="sizing-addon1"><span [@error]="error=='email'" class="secondary" >@</span></span> -->
          <input formControlName="email" type="email" class="form-control" placeholder="Email" (focusout)="focusOutEmail(false)">
        </div>
        <small *ngIf="formErrors.email" class="text-danger">
          {{ formErrors.email }}
        </small>
        <small *ngIf="!validEmail" class="text-danger">
          Vous ne pouvez pas ajouter un utilisateur ayant déjà un compte AGLO.
        </small>
      </div>
    </div>
    <br>
  </div>
  <div *ngIf="isProfile">
    <p class="title">Informations</p>
    <div style="margin-left: 24px;">
      <label>Prénom</label>
      <div class='input-container'>
        <input type="text" formControlName="firstName"  type="text" class="input-data" placeholder="Renseigner un prénom" aria-describedby="sizing-addon1">
        <small *ngIf="formErrors.firstName" class="text-danger">
          {{ formErrors.firstName }}
        </small>
      </div>
      <label>Nom</label>
      <div class='input-container'>
        <input formControlName="lastName" type="text" class="input-data" placeholder="Renseigner un nom">
        <small *ngIf="formErrors.lastName" class="text-danger">
          {{ formErrors.lastName }}
        </small>
      </div>
      <label>Adresse e-mail de connexion</label>
      <div class='input-container'>
        <input formControlName="email" type="email" class="input-data" placeholder="exemple@xyz.fr" (focusout)="focusOutEmail(false)">
        <small *ngIf="formErrors.email" class="text-danger">
          {{ formErrors.email }}
        </small>
      </div>
      <label>Numéro de téléphone</label>
      <div class="input-container">
        <input formControlName="phone"  type="text" class="input-data" placeholder="Numéro de téléphone">
        <small *ngIf="formErrors.phone" class="text-danger">
          {{ formErrors.phone }}
        </small><br>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!hidePassword">
    <div class="col-md-12">
      <div class="input-group input-group-lg">
        <input formControlName="phone"  type="text" class="form-control" placeholder="Numéro de téléphone">
      </div>
      <small *ngIf="formErrors.phone" class="text-danger">
        {{ formErrors.phone }}
      </small><br>
    </div>
    <div class="col-md-12">
      <div class="input-group input-group-lg">
        <input formControlName="password"  type="password" class="form-control" placeholder="Mot de passe">
      </div>
      <small *ngIf="formErrors.password" class="text-danger">
        {{ formErrors.password }}
      </small><br>
    </div>
    <br>
    <div class="col-md-12">
      <label class="required-star"><a target="_blank" href="https://www.iubenda.com/conditions-generales/77697615">Conditions Générales d'Utilisation d'AGLO</a></label><br>
      <div class="input-group input-group-lg">
        <!-- <span class="input-group-addon" id="sizing-addon1"><i class="fa secondary fa-gavel" aria-hidden="true"></i></span> -->
        <p-toggleButton formControlName="cgu" offLabel="Accepter les Conditions Générales d'Utilisation" onLabel="CGU acceptée " [style]="{'width':'100%','heigth':'40px'}"></p-toggleButton>
      </div>
    </div>
  </div>

</form>
