import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { BoosterProfilePageCreateAlbumModalComponent } from '../booster-profile-page-create-album-modal/booster-profile-page-create-album-modal.component';
import { AddPhotosToSingleAlbumModalComponent } from '../add-photos-to-single-album-modal/add-photos-to-single-album-modal.component';
import { BoosterProfileEditAlbumTitleModalComponent } from '../booster-profile-edit-album-title-modal/booster-profile-edit-album-title-modal.component';
import { ConfirmModalComponent } from 'src/app/all/confirm-modal/confirm-modal.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $: any;
@Component({
    standalone: true,
    selector: 'app-booster-profile-page-realisations',
    imports:[
      NgSharedModule,
      BoosterProfilePageCreateAlbumModalComponent,
      AddPhotosToSingleAlbumModalComponent,
      BoosterProfileEditAlbumTitleModalComponent,
      ConfirmModalComponent
    ],
    templateUrl: './booster-profile-page-realisations.component.html',
    styleUrls: ['./booster-profile-page-realisations.component.scss'],
})
export class BoosterProfilePageRealisationsComponent implements OnInit {

  @Input() albums: any = [];
  @Input() photos: any = [];
  @Output() saveAlbums: EventEmitter<any> = new EventEmitter<any>();
  @Output() savePhotos: EventEmitter<any> = new EventEmitter<any>();

  // no_albums (when there is no albums yet)
  // show_all_albums (when there is albums)
  // show_album_photos (when clicking on an Album, show it's photos )
  public currentView: string = "no_albums";
  public selectedAlbum:any = {};
  private selectedAlbumIndex: number;
  private selectedPhotoIndex: number;

  public isArtisanPhotosAlbum: boolean = false; /// when Mes réalisation album is selected

  public showAddAlbumModal: boolean = false;
  public showAddPhotosToAlbumModal: boolean = false;

  public confirmDeleteAlbumBody:string = "";
  public albumCoverPlaceholder:string = "https://s3.eu-central-1.amazonaws.com/bam-consultation-slickshot/22698xu9urkjo4uqwj%2F13787886-architectural-project-architect-rolls-and-plans.jpg";
  
  constructor() { }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges){
    if(changes.albums && Object.keys(changes.albums.currentValue).length){
      this.setCurrentView("show_all_albums");
    }
  }

  public setCurrentView(view){
    this.currentView = view;
  }

  public openNewAlbumModal(){
    this.showAddAlbumModal = true;
    
    setTimeout(function(){
      $("#create-new-album-modal").modal("show");
    }, 100);    
  }
  public openEditModalTitleModal(){
    $("#edit-album-title-modal").modal("show");
  }
  public openConfirmDeleteAlbumModal(){
    this.confirmDeleteAlbumBody = `Vous êtes sur le point de suspprimer le dossier ${this.selectedAlbum.title}, les informations ne pourront pas être récupérées.`;
    $("#confirm-delete-album-modal").modal("show");
  }
  public openAddPhotosToCurrentAlbum(){
    this.showAddPhotosToAlbumModal = true;
    
    setTimeout(function(){
        $("#add-photos-to-album-modal").modal("show");
      }, 100);
    }
    public openRemovePhotoModal(photoIndex){
    this.selectedPhotoIndex = photoIndex;

    $("#confirm-delete-photo-modal").modal("show");
  }

  public openAlbum(index, album){
    this.isArtisanPhotosAlbum = false;
    this.selectedAlbum = album;
    this.selectedAlbumIndex = index;

    this.setCurrentView("show_album_photos");
  }
  public openPhotosAlbum(){
    this.isArtisanPhotosAlbum = true;
    this.selectedAlbum = {title: 'Mes réalisations', photos: this.photos}
    this.selectedAlbumIndex = 0;

    this.setCurrentView("show_album_photos");
  }

  public createNewAlbum(newAlbum){
    this.albums.push(newAlbum);

    this.emitSaveAlbums(this.albums);

    $("#create-new-album-modal").modal("hide");
    this.showAddAlbumModal = false;
  }
  public confirmDeleteCurrentAlbum(){
    this.setCurrentView("show_all_albums");

    // delete mes Réalisations album
    if(this.isArtisanPhotosAlbum){
      this.photos = [];
      this.emitSavePhotos([]);
    }else{ /// delete current album
      this.albums = this.albums.filter((album, index) => index != this.selectedAlbumIndex);
      this.emitSaveAlbums(this.albums);
    }
    
    $("#confirm-delete-album-modal").modal("hide");
  }
  public confirmDeleteCurrentSelectedPhoto(){    
    // delete Single photo from mes Réalisations album
    if(this.isArtisanPhotosAlbum){
      this.photos = this.photos.filter((photo, index) => index != this.selectedPhotoIndex);
      this.selectedAlbum.photos = this.photos;

      this.emitSavePhotos(this.photos);
    }else{
      let albumPhotos = this.albums[this.selectedAlbumIndex].photos;

      albumPhotos = albumPhotos.filter((photos, index) => index != this.selectedPhotoIndex);
      this.albums[this.selectedAlbumIndex].photos = albumPhotos;
      
      this.emitSaveAlbums(this.albums);
    }    
    
    $("#confirm-delete-photo-modal").modal("hide");
  }

  public changeCurrentAlbumTitle(newAlbumTitle){
    this.selectedAlbum.title = newAlbumTitle;
    this.albums[this.selectedAlbumIndex] = this.selectedAlbum;

    this.emitSaveAlbums(this.albums);

    $("#edit-album-title-modal").modal("hide");
  }
  public addPhotosToCurrentAlbum(newPhotos){
    this.selectedAlbum["photos"].push(...newPhotos);
    this.albums[this.selectedAlbumIndex] = this.selectedAlbum;

    this.emitSaveAlbums(this.albums);

    $("#add-photos-to-album-modal").modal("hide");
    this.showAddPhotosToAlbumModal = false;
  }

  private emitSaveAlbums(albums){
    this.saveAlbums.emit(albums);
  }
  private emitSavePhotos(photos){
    this.savePhotos.emit(photos);
  }

}
