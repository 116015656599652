import { Component, OnInit, Input, NO_ERRORS_SCHEMA } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Store } from '@ngrx/store';
import * as chantierActions from '../_store/chantier.actions'; 
import { UniteInputDpgfComponent } from 'src/app/dpgf/dce-edition/unite-input-dpgf/unite-input-dpgf.component';
import { InputDpgfComponent } from 'src/app/dpgf/dce-edition/input-dpgf/input-dpgf.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { ChantierModule } from '../chantier.module';


declare var $;
@Component({
    selector: 'app-table-lot-facture',
    imports:[
      ChantierModule,
      UniteInputDpgfComponent,
      InputDpgfComponent,
      NgSharedModule
    ],
    templateUrl: './table-lot-facture.component.html',
    styleUrls: ['./table-lot-facture.component.scss'],
    standalone: true,

})
export class TableLotFactureComponent implements OnInit {
  @Input()lot:any;
  @Input()lotIndex:any;
  @Input()loti:any;
  @Input()mode:string;
  @Input()isFirst:boolean;
  @Input()status:string;
  @Input()response:string

  public modeList = {
    artisan: {
      chantier: true,
      acompte: true,
      comment: true,
      addPost: true
    },
    archi: {
      chantier: true,
      acompte: false,
      comment: true,
      addPost: false
    }
  }

  constructor(private store: Store<any>) {}

  ngOnInit() { }

  public addPoste(loti, sousloti){
    this.store.dispatch(new chantierActions.AddPoste({loti, sousloti}));
  }

  public calculateLotDevis(loti) {
    this.store.dispatch(new chantierActions.UpdateLotDevisAcompte({loti, type: 'devis'}));
  }

  public calculateLotAcompte(loti) {
    this.store.dispatch(new chantierActions.UpdateLotDevisAcompte({loti, type: 'acompte'}));
  }

  public calculatePosteDevis(loti, sousloti, posti) {
    this.store.dispatch(new chantierActions.UpdatePosteDevisAcompte({ loti, sousloti, posti, type: 'devis' }));
  }

  public calculatePosteAcompte(loti, sousloti, posti) {
    this.store.dispatch(new chantierActions.UpdatePosteDevisAcompte({ loti, sousloti, posti, type: 'acompte' }));
  }

  public calculatePrice(loti) {
    this.store.dispatch(new chantierActions.UpdatePostePrice(loti));
  }

  public deletePoste(type, loti , sousloti, posti){
    this.store.dispatch(new chantierActions.DeletePoste({ loti, sousloti, posti, type }));
  }

  public updateDesignation(loti, sousloti, posti, data) {
    this.store.dispatch(new chantierActions.UpdatePosteDesignation({ loti, sousloti, posti, data }));
  }
  
  public toggleGlobalFacturation(loti) {
    this.store.dispatch(new chantierActions.ToggleGlobalFacturation(loti));
  }

  public addComment(loti, sousloti, posti) {
    this.store.dispatch(new chantierActions.AddComment({loti, sousloti, posti, type: this.mode}));
  }

  public updateComment() {
    this.store.dispatch(new chantierActions.SaveUpdateFacture());
  }

  public deleteComment(loti, sousloti, posti, comi) {
    this.store.dispatch(new chantierActions.DeleteComment({loti, sousloti, posti, comi}));
  }

  public lineNumber(loti, sousloti, posti){
    return `${loti}.${sousloti+1}.${posti+1} - `;
  }

  public converteNumber(data){
    return Number(data);
  } 

}
