<div id="booster-profile-edit-agency-information-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header no-padding-left">
        <h4 class="ag-heading-h4 ag-weight-600 no-margin">Informations de l'enterprise</h4>
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding padding-left-35">        
        
        <div class="info-section margin-bottom-30">
          <h5 class="ag-heading-h5 ag-weight-600">Sur quel type de travaux pouvez-vous intervenir ?</h5>
          <div class="checkboxes-list-wrapper">
            <span class="checkbox-item" 
            [ngClass]="{'choosed': selectedTravaux.includes(item.value)}"
            (click)="chooseItem('type', item.value)"
            *ngFor="let item of travauxList">{{item.name}}</span>
          </div>
        </div>
        
        <div class="info-section margin-bottom-30">
          <h5 class="ag-heading-h5 ag-weight-600">Quels programmes prenez-vous en charge ?</h5>
          <div class="checkboxes-list-wrapper">
            <span class="checkbox-item" 
              [ngClass]="{'choosed': selectedPrograme.includes(programme.value)}"
              *ngFor="let programme of programmesList"
              (click)="chooseItem('programme', programme.value)"
              >{{programme.name}}</span>
          </div>
        </div>
        
        
        <div class="info-section margin-bottom-30">
          <h5 class="ag-heading-h5 ag-weight-600">En quelle année avez-vous crée votre entreprise ?</h5>
          <input type="text" class="padding-left-12" [(ngModel)]="creationYearAgency">
        </div>

        <div class="info-section margin-bottom-30">
          <h5 class="ag-heading-h5 ag-weight-600">Chiffre d’affaire</h5>
          <p>Pour l’année précédente, quel a été le chiffre d’affaire de votre entreprise ? </p>
          <div class="number-input-holder relative width-15">
            <input type="number" class="padding-left-12" [(ngModel)]="ca">
            <img src="/assets/euro-icon.svg">
          </div>
        </div>
        
        <div class="submit-btn-container text-center margin-top-30 ">
          <button class="ag-btn-primary-sm" (click)="emitSaveData()">Modifier les informations</button>
        </div>

      </div>
    </div>
  </div>
</div>
