import { Component, OnInit , Input,Output,EventEmitter, SimpleChanges, ViewChild, ElementRef, NgZone} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,  Validators, AbstractControl } from '@angular/forms';
import { trigger,style,animate,transition,keyframes } from '@angular/animations';
import { Observable, Subscription } from 'rxjs';
import { AuthService, UserService } from "src/app/_services"
//Maps
import { GoogleAutocompleteComponent } from 'src/app/all/google-autocomplete/google-autocomplete.component';
// PrimeNG
import {ConfirmationService} from 'primeng/api';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import {environment} from 'src/environments/environment'
declare var $:any;

@Component({
  standalone:true,
  selector: 'app-architect-new-project',
  imports:[NgSharedModule,GoogleAutocompleteComponent ],
  templateUrl: './architect-new-project.component.html',
  styleUrls: ['./architect-new-project.component.scss'],
  animations: [
    trigger('error', [
      transition('* => true', [
        animate('2s', keyframes([
          style({ backgroundColor: 'transparent'}),
          style({ backgroundColor: 'rgba(255, 0, 0, 0.15)'}),
          style({ backgroundColor: 'transparent'})
        ]))
      ]),
    ])
  ]
})
export class ArchitectNewProjectComponent implements OnInit {
  @Output() deleteThumbnail: EventEmitter<any> = new EventEmitter();
  @Input() project: any;
  @Input() updating: any = false;

  @ViewChild("search")
  public searchElementRef: ElementRef;
  public projectForm: UntypedFormGroup;
  public error: string='';
  public place: any = {};
  public zipCode: string = '';
  public shortZipCode: string = '';
  public filesLoading: any = [];onpremiseProjectImage:any;
  public formErrors = {
    natureTravaux: '',
    type: '',
    adresse: '',
    title: '',
    surface: '',
    dateTravaux:'',
    addressCodePostal: '',
    addressCity: '',
    addressCountry: ''
  };
  public addressCodePostal:string = "";
  public addressCity:string = "";
  public addressCountry:string = "";

  public uploading: boolean;
  public mainImage: any;
  public imageError: boolean=false;
  private subscriptions: Array < Subscription > = [];
  public firstValueEnvirenementObservable = new Observable<boolean>(observer => {
    observer.next(environment.onPremise);
    observer.complete();
  });
  constructor(private fb: UntypedFormBuilder,
              private confirmationService: ConfirmationService,
              public authService:AuthService
              ) {}

  ngOnInit() {
    this.createForm();
    this.setData()
  }
  //On change
  ngOnChanges(changes: SimpleChanges) {
    if (changes['project'] && this.projectForm) {
      this.setData()
    }
  }
  public setData(){
    let project = this.project ||{};
    project.dateDce = project.dateDce?new Date(project.dateDce):null
    project.dateTravaux = project.dateTravaux?new Date(project.dateTravaux):null
    this.projectForm.patchValue(this.project);
    this.place = this.project.place || {};
    this.mainImage = this.project.mainImage || '';
    let { adresse, addressCity, addressCodePostal, addressCountry } = project;
    let addressData = {
      codePostal: addressCodePostal||"",
      city: addressCity || "",
      country: addressCountry || "",
      street: adresse || "",
    };
    this.fillAddressFields(addressData);
  }
  public adresseValueChange(value){
    this.projectForm.patchValue({adresse: value});
  }
  getGoogleMapValueChange(event){
    let address = event.adresse || undefined;
    // set address to the project object
    if(address){
      this.project["adresse"] = address;
    }
    if(event.shortZipCode){
      this.shortZipCode = event.shortZipCode;
    }
    if(event.zipCode){
      this.zipCode = event.zipCode;
    }

    this.projectForm.patchValue(event);
  }
  private fillAddressFields(obj){
    /// Fill Adress Fields
    if(obj['postcode']){
      this.projectForm.patchValue({addressCodePostal: obj['postcode']});
    }
    if(obj['locality']){
      this.projectForm.patchValue({addressCity: obj['locality']});
    }
    if(obj['country']){
      this.projectForm.patchValue({addressCountry: obj['country']});
    }
    if(obj['address']){
      this.projectForm.patchValue({adresse: obj['address']});
    }
  }
  onUploadSingleImage(event){
    let location = event.originalEvent.body[0].location;
    this.projectForm.patchValue({
      mainImage: location
    });
    if(environment.onPremise)this.onpremiseProjectImage = 'api/v1/static/'+location;
    else this.mainImage = location;
  }
  //Form
  createForm() {
    this.projectForm = this.fb.group({
      natureTravaux: ['', Validators.required],
      type: ['', Validators.required],
      adresse: ['', Validators.required],
      title: ['', Validators.required],
      // budget: [0, Validators.compose([Validators.required, this.budgetValidators])],
      budget: [''],
      surface: "",
      // dateDce:['', Validators.required],
      dateTravaux:"",
      // moreInformation:[''],
      mainImage:"",
      addressCodePostal: ['', Validators.required],
      addressCity: ['', Validators.required],
      addressCountry: ['', Validators.required]
    });
    this.subscriptions.push(this.projectForm.valueChanges.subscribe(data => this.onValueChanged(data)));
    this.onValueChanged();
  }
  //Create Custom Validators
  public budgetValidators(control: AbstractControl){
    if (control.value == 0) {
      return {'budget':'true'};
    }
    return null;
  }

  //Generate Error
  onValueChanged(data ? : any) {
    if (!this.projectForm) {
      return;
    }
    const form = this.projectForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        //const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += 'Ce champ est obligatoire ';
        }
      }
    }
  }
  //Mark as touched
  public markFormAsTouched(): void {
    if (!this.projectForm) {
      return;
    }
    const form = this.projectForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      control.markAsDirty();

      for (const key in control.errors) {
        this.formErrors[field] += 'Ce champ est obligatoire';
      }
    }
  }
  //patch Adresse
  public patchAdress(val){
      let data = this.projectForm.value;
      data.adresse = val;
      this.projectForm.patchValue(data)
  }
  //scrollTo
  public scrollTo(element, offset = 80) {
    $('html, body').animate({
      scrollTop: ($(element).offset().top - offset)
    });
  }
  public scrollToError(){
    let loop = 0;
    let keys = Object.keys(this.formErrors);
    let errorKey = ""
    while(loop < keys.length && this.formErrors[keys[loop]] =="" ){
      loop ++;
    }
    if (keys[loop]) {
      this.error = keys[loop]
      this.scrollTo(`#${keys[loop]}`, 40);
      setTimeout(()=>{this.error = ''},2000)
    }
  }
  //Validate Form
  isValid(){
    this.markFormAsTouched();
    this.scrollToError();
    // if(this.place.formatted_address != this.projectForm.value.adresse){
    //   this.patchAdress("");
    // }
    return this.projectForm.valid;
  }
  getFormData(){
    let value =  Object.assign({},this.projectForm.value);
    // value.adresse = this.place.formatted_address;
    value.place = this.place;
    // value.zipCode = this.getZipCodeFromAdress(this.place);
    value.zipCode = this.zipCode;
    value.shortZipCode = this.shortZipCode;
    return value;
  }
  public deleteFormImage(){
    this.projectForm.patchValue({
      mainImage:"",
    });
  }
  public confirmRemove(){
    this.confirmationService.confirm({
      message: "Êtes-vous sûr(e) de vouloir supprimer l'image du projet ?",
      accept: () => {
        this.removeThumnail();
      }
    });
  }
  public removeThumnail(): void{
    this.deleteThumbnail.emit({});
    $('#remove-thumbnail-modal').modal('hide');
    this.project.mainImage = '';
    this.mainImage = '';
  }
  private getZipCodeFromAdress(place): string{   
    if(Object.keys(place).length==0) return;
    let zipCode = '';
    let address_components = place.address_components[place.address_components.length-1];
    if(address_components.types.includes("postal_code")){
     zipCode = address_components.short_name;
     let shortZipCode = zipCode.substr(0,2);
     this.shortZipCode = shortZipCode;
    }
    return zipCode;
  }
  public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }
}
