import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/_services/index';
import { AllModule } from '../all.module';
import { HeaderComponent } from 'src/app/all/header/header.component';

import { NgSharedModule } from 'src/app/all/ngshared.module';


@Component({
    selector: 'app-no-multiple-accounts-allowed',
    imports:[
      NgSharedModule,
      HeaderComponent
    ],
    templateUrl: './no-multiple-accounts-allowed.component.html',
    styleUrls: ['./no-multiple-accounts-allowed.component.scss'],
    standalone: true,

})
export class NoMultipleAccountsAllowedComponent implements OnInit {

  public count:number = 15;
  public counterInterval;

  public userSessionLocation = {
    country: "",
    city: "",
  };
  private COOKIE_NAME="authUserCookie";
  private LOCAL_STORAGE_NAME="userSessionLocation";

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // get country & city from LocalStorage
    this.getLocationFromLocalStorage();
    this.segmentEvent();
    this.countDown();
  }

  private getLocationFromLocalStorage(){
    let localStorageSessionLocation = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_NAME));

    // if(localStorageSessionLocation){
      this.userSessionLocation.country = localStorageSessionLocation["country"];
      this.userSessionLocation.city = localStorageSessionLocation["city"];
    // }
  }

  private countDown(){
    /// remove cookie
    this.removeLoginCookie(this.COOKIE_NAME);
    
    this.counterInterval = setInterval(() => {
      if(this.count > 0){
        this.count--;

      }else{
        this.redirect();
      }
    }, 1000);
  }

  private redirect(){
    clearInterval(this.counterInterval);
    
    // logout
    this.authService.logout(true);
    /// remove localStorage
    this.removeLocalStorage();
     
    this.router.navigate(["/login"]);
  }

  private removeLoginCookie(cookieName){
    let documentCookies = document.cookie;
    /// get, set, delete cookies 
    // https://www.guru99.com/cookies-in-javascript-ultimate-guide.html
    if(documentCookies.includes(cookieName)){
      document.cookie = `${cookieName}= ;expires = Thu, 01 Jan 1970 00:00:00 GMT;`;
    }    
  }
  private removeLocalStorage(){
    localStorage.removeItem(this.LOCAL_STORAGE_NAME);
  }
  private segmentEvent(){
    window.analytics.track('connexion_blocked',{properties:{acValue: 'connexion_blocked'}});
  }

  public ngOnDestroy(): void {
    clearInterval(this.counterInterval);
  } 

}
