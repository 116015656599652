import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';


declare var $;
@Component({
  standalone:true,
  selector: 'app-right-bar-consult-add-entreprise',
  imports:[DpgfModule,NgSharedModule],
  templateUrl: './right-bar-consult-add-entreprise.component.html',
  styleUrls: ['./right-bar-consult-add-entreprise.component.scss']
})
export class RightBarConsultAddEntrepriseComponent {
  
  @Input() consulteSidebarOpened: boolean = false;
  @Output() toggleSidebar: EventEmitter<any> = new EventEmitter<any>();  
  @Output() openConsulteBoosterModal: EventEmitter<any> = new EventEmitter<any>();    
  
  
  public emitToggleSidebar(){
    this.toggleSidebar.emit();
  }

  public emitOpenConsulteBoosterModal(){
    this.openConsulteBoosterModal.emit();
  }

}
