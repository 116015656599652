<div class="all">
    <app-header></app-header>
    <div class="flex color-downriver" style="padding-top:32px;">
    <div class="padding-left-53 cursor"  [routerLink]="['/artisan/edit/profil']"><img src="assets/arrow-left.svg" class="padding-right-12"> Retour</div>
    <div class="flex justify-center wid87">
    <div>
    <div class="wid518 padding-bottom-32">
        <div class="flex justify-center">
            <span class="title"> Supprimer votre compte </span>
        </div>
       <p class="font-size-12 text-center">Si vous supprimez votre compte, vous ne serez pas en mesure de récupérer vos données.Toutes les données de votre compte AGLO seront supprimées.</p>
    </div>
    <div class="card">
        <div class="flex justify-center">
            <!-- <div class="device user-name border-color-blue">
                <span class="position-letter">{{(currentUser.firstName || "").substring(0,1) | capitalize}}</span>
            </div> -->
            <span class="color-downriver font-size-16 padding-top-12 font-weight-700"> {{currentUser.firstName | capitalize}} {{currentUser.lastName | capitalize}} </span>
        </div>
        <p class="font-size-16 text-center padding-top-32 font-weight-700">Vous êtes sur le point de supprimer votre compte de manière définitive. Cette action ne peut être annulé. </p>
        <p class="font-size-16 text-center font-weight-700">  Veuillez entrez votre mot de passe pour continuer.</p>
        <div class="flex justify-center padding-top-32">
        <div>
        <p class="font-size-16 text-left">Mot de passe actuel</p>
        <input type="password"class="input-style" [(ngModel)]="password" (keyup)="checkValidityPass(password)">
        <br *ngIf="!validityPassword && password.length>0">
        <small *ngIf="!validityPassword && password.length>0" class="color-red">Votre mot de passe est incorrect.</small>
        <br>
        <div class="flex justify-center" style="padding-top: 32px;">
        <button class="font-size-14 button-delete" [ngClass]="{'disabled':!validityPassword}" [disabled]="!validityPassword" (click)="deleteAccount()">Supprimer le compte définitivement</button>
        </div>
        <div class="flex justify-center">
        <button class="button-cancel">Annuler</button>
        </div>
        </div>
        </div>
    </div>
    </div>
    </div>
    </div>
</div>