import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import {  } from 'stream';

declare var $;
@Component({
  selector: 'app-modal-lots-list',
  standalone: true,
  imports: [NgSharedModule],
  templateUrl: './modal-lots-list.component.html',
  styleUrl: './modal-lots-list.component.scss'
})
export class ModalLotsListComponent implements OnInit, OnChanges{
  @Input() dpgfLot: any=[];
  @Input() choosenLot:any;
  @Input() openAddAssignedLotsModal:boolean;
  @Output() addLots = new EventEmitter<any[]>();
  public selectedAgencyName: string = "";
  public selectedLots: { [key: string]: boolean } = {};
  public numberOfLotsSelected: number = 0;

  
  constructor(){

  }
  ngOnInit(): void {
    this.modalFocusOutEvent();
    if(this.choosenLot!=undefined){
      this.choosenLot.forEach(lot=>{
        this.selectedLots[lot.id] = true;
      })

    }
  }
  ngOnChanges(changes: SimpleChanges): void { 
    if(changes.choosenLot && changes.choosenLot.currentValue){
      const { icon, id, indexLot, name } = changes.choosenLot.currentValue;
      this.selectedLots = {};
      this.choosenLot.forEach(lot=>{
        this.selectedLots[lot.id] = true;
      })
    } 
  }

  public closeModal(){
    $("#assignedLotsModal").modal('hide');
    this.choosenLot=[];
  }

  public allLotsChecked({checked}){
    if(!checked){
      this.selectedLots = {};
      this.numberOfLotsSelected = 0;
      return;
    }
    this.numberOfLotsSelected = 0;
    for(let i = 0; i < this.dpgfLot.length; i++){
      const { id } = this.dpgfLot[i];
      this.numberOfLotsSelected++;
      this.selectedLots[id] = true;
    }
  }
  public toggleLot(lotId){
    let lotIsSelected = !this.selectedLots[lotId];
    this.selectedLots[lotId] = !this.selectedLots[lotId];
    !lotIsSelected ? this.numberOfLotsSelected++ : this.numberOfLotsSelected--;
  }

  public emitAddLots(){
    let lots = this.getSelectedLots();
    this.addLots.emit(lots);
    $("#assignedLotsModal").modal('hide');
  }

  private getSelectedLots() {
    const selectedLots = [];
    
    for (const lotId in this.selectedLots) {
      if (this.selectedLots[lotId]) {
        selectedLots.push(lotId);
      }
    }
  
    const result = this.dpgfLot.filter(lot => selectedLots.includes(lot.id));
    
    return result;
  }

  public modalFocusOutEvent(){
    let _SELF=this;
      $("#assignedLotsModal").on("hidden.bs.modal", function () {
        $("#assignedLotsModal").modal('hide');
        _SELF.choosenLot=[];

      });
  }


  
}
