<div class="horizontal-items space-between infromation-cards-wrapper">
  
  <!-- Informations de votre entreprise -->
  <div class="ag-card-1 width-50">
    
    <div class="card-main-title margin-bottom-20 horizontal-items space-between">
      <h5 class="ag-heading-h5">Informations de votre entreprise</h5>

      <div class="">
        
        <button class="ag-btn-bordered-xs edit-btn" [disabled]="!canEditOrSubmit" 
          (click)="toggleUpdateInputs()" *ngIf="!showEditInputs">
          <img src="/assets/dpgf/edit-pen.svg" class="margin-right-6">
          Modifier
        </button>
        
        <div class="edit-buttons" *ngIf="showEditInputs">
          <button class="ag-btn-bordered-xs margin-right-32 cancel-btn" (click)="toggleUpdateInputs()">Annuler</button>
          <button class="ag-btn-primary-xs margin-left-10 save-btn" (click)="updateArtisanInfo()">
            <img src="/assets/dpgf/save-white.svg" class="margin-right-6" />
            Enregistrer
          </button>
        </div>

    </div>

    </div>
    
    <div class="sides-wrapper horizontal-items space-between">
      <!-- Left Side -->
      <div class="width-60">
        <div class="single-info-row horizontal-items">
          <p class="info-row-label width-30 flex-vertical">Nom de l’entreprise</p>
          <p class="info-row-value" *ngIf="!showEditInputs">{{ artisanInfoData?.name || "" }} </p>          
          <p class="info-row-input" *ngIf="showEditInputs">
            <input type="text" [(ngModel)]="artisanInfoData.name">
          </p>
        </div>

        <div class="single-info-row horizontal-items">
          <p class="info-row-label width-30 flex-vertical">Adresse</p>
          <p class="info-row-value" *ngIf="!showEditInputs">{{ artisanInfoData?.address || "" }} </p>
          <p class="info-row-input" *ngIf="showEditInputs">
            <input type="text" [(ngModel)]="artisanInfoData.address">
          </p>
        </div> 

        <div class="single-info-row horizontal-items">
          <p class="info-row-label width-30 flex-vertical">Email</p>
          <p class="info-row-value" *ngIf="!showEditInputs">{{ artisanInfoData?.emailAgency || "" }} </p>
          <p class="info-row-input" *ngIf="showEditInputs">
            <input type="text" [(ngModel)]="artisanInfoData.emailAgency">
          </p>
        </div>

      </div> <!-- END Left Side -->

      <!-- Right Side -->
      <div class="width-40">
        <!-- artisanPhone -->
        <div class="single-info-row horizontal-items">
          <p class="info-row-label width-30 flex-vertical">Téléphone</p>
          <p class="info-row-value" *ngIf="!showEditInputs">{{ artisanInfoData?.telAgency || "" }} </p>
          <p class="info-row-input" *ngIf="showEditInputs">
            <input type="text" [(ngModel)]="artisanInfoData.telAgency">
          </p>
        </div>

        <div class="single-info-row horizontal-items">
          <p class="info-row-label width-30 flex-vertical">N° TVA </p>
          <p class="info-row-value" *ngIf="!showEditInputs">{{ artisanInfoData?.tvaNumber || "" }} </p>
          <p class="info-row-input" *ngIf="showEditInputs">
            <input type="text" [(ngModel)]="artisanInfoData.tvaNumber">
          </p>
        </div>
        
        <div class="single-info-row horizontal-items">
          <p class="info-row-label width-30 flex-vertical">Assurance</p>
          <p class="info-row-value" *ngIf="!showEditInputs">{{ artisanInfoData?.assuranceNumber || "" }} </p>
          <p class="info-row-input" *ngIf="showEditInputs">
            <input type="text" [(ngModel)]="artisanInfoData.assuranceNumber">
          </p>
        </div>
      </div> <!-- END Right Side -->
  </div>

  </div>
  
  <!-- Informations du projet -->
  <div class="ag-card-1 width-25">
    
    <div class="card-main-title margin-bottom-20">
      <h5 class="ag-heading-h5">Informations du projet</h5>
    </div>

    <div class="vertical-items">
      <div class="single-info-row horizontal-items">
        <p class="info-row-label width-55">Maître d’oeuvre </p>
        <p class="info-row-value">{{  (dpgf$|async)?.architectName }}</p>
      </div>
      <div class="single-info-row horizontal-items">
        <p class="info-row-label width-55">Date limite de rendu des offres</p>
        <p class="info-row-value">{{ (dpgf$|async)?.ceProject?.dateDce | date : 'dd/MM/yyyy' }}</p>
      </div>
      <div class="single-info-row horizontal-items">
        <p class="info-row-label width-55">Indice</p>
        <p class="info-row-value">{{(dpgf$|async)?.currentVersion | pricingIndex}}</p>
      </div>          
    </div> 
 
  </div>

  <!-- Récapitulatif -->
  <div class="ag-card-1 width-25">
    <div class="card-main-title margin-bottom-20">
      <h5 class="ag-heading-h5">Récapitulatif</h5>
    </div>    

    <div class="single-info-row horizontal-items space-between">
      <p class="info-row-label">Prix total HT</p>
      <p class="info-row-value">{{ (total$ |async) | formatNumberPrice }} €</p>
    </div>
    <div class="single-info-row horizontal-items space-between" *ngIf="(dpgf$|async)?.ceProjectInvite?.agloPlus">
      <p class="info-row-label size-12">Service AGLO Booster</p>
      <p class="info-row-value size-12">{{ (dpgf$|async)?.ceProjectInvite.commission }}%</p>
    </div>
    <div class="single-info-row horizontal-items space-between">
      <p class="info-row-label">Prix total TTC </p>
      <p class="info-row-value">{{ totalTTC$ |async | formatNumberPrice }} €</p>
    </div>

  </div>

</div>
