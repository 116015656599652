<div>
  <p-sidebar  [ngClass]="isRevit?'revit-sidebar': ''" [(visible)]="openProjectArborescence" position="right" >
    <div class="header">
      <div class="row">
        <div class=" center">
          <div class="inner-container">
            <div style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
              <i class="click-it fa fa-times" aria-hidden="true" (click)="closeProjectArborescence()"></i>
              <h2>Arborescence du projet</h2>  
            </div>
            <div>
              <button *ngIf="!isDataLoaded" class="btn-show-elements" (click)="getAllDifferentElements()">Afficher sur la maquette</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-spinner *ngIf="isDataLoaded"></app-spinner>
    <div class="families" *ngIf="!isDataLoaded">      
      <p-tree  [value]="dataSource"  (click)="stopSelectionPropagation($event)"  (onNodeUnselect)="nodeUnselect($event.node)" (onNodeSelect)="changeNodeSelectedValue($event.node)" [selectionMode]="'checkbox'" [(selection)]="selectedNodes">
        <ng-template let-node pTemplate="default" >

          <div class="mat-tree-node" (click)="stopSelectionPropagation($event)" style="display: flex; justify-content: space-between; align-items: center; width: 100%;">

            <div (click)="stopSelectionPropagation($event)" style=" width: 90%; flex-grow: 1;">
              <span *ngIf="!isLastLeaf(node)" style="text-wrap: wrap; position: relative; padding-left: 5px;">{{ node.label }}</span>
              <span *ngIf="isLastLeaf(node)" style="text-wrap: wrap; position: relative; padding-left: 5px;">IFC_GUID : {{ node.data.ifcGuid }}</span>
            </div>
            <!-- use node parent  -->
            <div (click)="stopSelectionPropagation($event)" class="node-eye"  *ngIf="node.isBeforeLastNode">
              <div class="selected-unit">{{node.quantity ||node.children.length}}</div>
              <select  name="unit" id="units" style="border: none; outline: none;"
              (change)="countAllQuantities($event.target.value,node)">
                <option class="select-unit" *ngFor="let unit of units"  [ngValue]="unit.value " style="border: none; outline: none;">{{unit.label}}</option>
              </select>
              <i
                (click)="getSelectedElements(node)"
                [ngClass]="isAnyChildSelescted(node) ? 'fa fa-eye' : 'fa fa-eye-slash'"
                [pTooltip]="isAnyChildSelescted(node) ? 'Afficher sur maquette' : 'Veuillez sélectionner un élément'"
                style="margin-left: 10px; color: rgb(0, 0, 0);"
              ></i>
            </div>

            <div (click)="stopSelectionPropagation($event)" class="node-eye" *ngIf="isLastLeaf(node)">
              <div class="selected-unit">{{node.quantity ||1}}</div>
              <select  name="unit" id="units" style="border: none; outline: none;"
              (change)="countSingleQuantity($event.target.value,node)">
                <option  *ngFor="let unit of units"  [ngValue]="unit.value " style="border: none; outline: none;" class="select-unit"
                >{{unit.label}}</option>
              </select>
              <i
                (click)="getSelectedElement(node)"
                [ngClass]="node.selected ? 'fa fa-eye' : 'fa fa-eye-slash'"
                [pTooltip]="(node.selected) ? 'Afficher sur maquette' : 'Veuillez sélectionner un élément'"
                style="margin-left: 10px; color: rgb(0, 0, 0);"
              ></i>
            </div>

          </div>

        </ng-template>
      </p-tree>
    </div>
  </p-sidebar>
</div>
