import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../../_services/index';
//import { ArchitectModule } from '../architect.module';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { ArchiExpiredModalComponent } from 'src/app/all/archi-expired-modal/archi-expired-modal.component';
import { ArchiLotPostComponent } from 'src/app/architect/archi-lot-post/archi-lot-post/archi-lot-post.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

// rxJs
import { Subscription } from 'rxjs';

@Component({
    standalone: true,

    selector: 'app-archi-db',
    imports:[
      
      HeaderComponent,
      ArchiExpiredModalComponent,
      ArchiLotPostComponent,
      NgSharedModule
    ],
    templateUrl: './archi-db.component.html',
    styleUrls: ['./archi-db.component.scss'],
})
export class ArchiDbComponent implements OnInit {

  private subscriptions: Array<Subscription> = [];

  constructor(
    private title: Title,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.noMultiAccountsAllowed();
    this.title.setTitle('Aglo - Bibliothèques')
    // segment page event
    if(!environment.onPremise)window.analytics.page('pageview_database');
  }
  private noMultiAccountsAllowed(){
    this.subscriptions.push(
      this.authService.noMultiAccountsAllowed()
        .subscribe(
          data => {
            
          }  
        )
    );
  }

}
