// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  onPremise: false,
  stripeKey : 'pk_test_bC70vyPoOmEIpP0F3ZZtPQQ1',
  // local  
  // stripeKey : 'pk_test_51LQBg7GtSDuV98Pbo4fF2FB2FGnFZUDSSzelYIIMK9jLUqEVo4iOooyYF5grEK7FgrOmosDL5DwDhCxlF4mvBp6k00LUpFu8O4',
  segmentKey:"RsIXJfibJzTd37nZRcTTrsOcTyctWL4W"
};
 