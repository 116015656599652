import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone: true,
    selector: 'app-booster-profile-edit-ageny-name-modal',
    imports:[
      NgSharedModule,
    ],
    templateUrl: './booster-profile-edit-ageny-name-modal.component.html',
    styleUrls: ['./booster-profile-edit-ageny-name-modal.component.scss'],
})
export class BoosterProfileEditAgenyNameModalComponent implements OnInit, OnChanges {

  @Input() dataList: any = [];
  @Input() agenyName: any = "";
  @Output() updateAgencyInfo: EventEmitter<any> = new EventEmitter<any>();
  
  public type = [
    {value:'contractant-general',name:"Contractant général"},
    {value:'entreprise-generale',name:"Entreprise générale"},
    {value:'bureau-d-etude',name:"Bureau d'études"},
    {value:'artisan-entreprise-specialisee',name:"Artisan / Entreprise spécialisée"},
    {value:'fabriquant-revendeur-materiaux',name:"Fabriquant / Revendeur matériaux"},
  ];    
  public selectedTypes: any = [];

  constructor() { }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dataList && changes.dataList.currentValue.length){
      this.selectedTypes = [...changes.dataList.currentValue];
    }
  }

  public chooseItem(value){
    if(this.selectedTypes.includes(value)){
      // remove
      this.selectedTypes = this.selectedTypes.filter(item => item != value);
    }else{
      // add
      this.selectedTypes.push(value);
    }
  }

  public update(){
    if(this.selectedTypes == '' || this.agenyName == '') return;

    let body = {
      name: this.agenyName,
      type: this.selectedTypes
    };

    this.updateAgencyInfo.emit(body);
  }

}
