import { Component, OnInit, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from '../ngshared.module';

@Component({
  standalone:true,
  selector: 'app-file-manager',
  imports:[NgSharedModule ],
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit {
  @Input()files:any;
  @Input()isImage:boolean;
  @Input()order:boolean;
  @Output() imageDeleted: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }
  //Delete Image
  public deleteImages(i){
    this.files.splice(i, 1);
    this.imageDeleted.emit();
  }
  public getDown(i){
    if(i < (this.files.length -1)){
      let tmp = this.files[i];
      this.files[i] = this.files[i+1];
      this.files[i+1] = tmp
    }
  }
  public getUp(i){
    if(i > 0){
      let tmp = this.files[i];
      this.files[i] = this.files[i-1];
      this.files[i-1] = tmp
    }
  }
}
