<div>
  <p-sidebar class="revit-sidebar" [ngClass]="isRevit?'revit-sidebar': ''" [(visible)]="openSlider" position="right" styleClass="p-sidebar-lg">
    <div class="header">
      <div class="row">
        <div class="center">
          <div class="inner-container">
            <i class="click-it fa fa-times" aria-hidden="true" (click)="closeRevitSide()"></i>
            <p class="post-title">
              <span *ngIf="selectedRevitElements.length == 1; else elseBlock">
                Elément {{selectedRevitElements[0]?.category}} 
              </span>
              <ng-template #elseBlock>
                {{selectedRevitElements.length}} Eléments séléctionnés
              </ng-template>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="content-cstb">
      <div class="revit-page">
        <div class="paraghra">
          <!-- <p style="text-align: start !important">Les postes attachés à l'élément {{selectedRevitElement?.category}} </p> -->
          <app-spinner *ngIf="loadingPostes"></app-spinner>
          <div *ngIf="!loadingPostes" class="result">
            <div  *ngFor="let lot of postes;  let loti = index" class="postes-list">
              <span class="lot-name">
                <img *ngIf="(lot.icon||'') != ''" [src]="'assets/dpgf/Icon_DPGF/' + lot.icon"
                  height="15">{{lot.indexLot}}
                - {{lot.title}}
              </span>
              <button class="add-btn" pTooltip='Ajouter un poste' (click)="addNewPost(lot.id)">+</button>
              <button class="add-btn" pTooltip='Lier à un poste déjà existant' (click)="openSelect(lot)"> <i class="fa fa-folder-open-o" aria-hidden="true"></i>
              </button>
              <button class="add-btn" pTooltip='Ajouter un poste depuis le CSTB' (click)="openCstb(lot)"> CSTB</button>

              <table class="postes-list" >
                  <tr class="table-row" *ngFor="let post of lot.postes;  let posti = index">
                    <td class="relative number-col">
                      <span class="handle">
                        {{lineNumber(loti,post.sousLots,post.postIndex)}}
                      </span>
                      <img *ngIf="post.gid" src="assets/dpgf/cstb.jpg" class="cstb">
                    </td>

                    <td class="relative designation-col">
                      <app-input-dpgf #inputs [ngStyle]="{'color': post.isAtisan?'red':'black'}" [disable]="false"
                        [textContent]="post.designation"
                        (focusedOut)="savePosteData($event, {posteId : post.id, loti : loti,sousLoti : post.sousLots, posti : posti ,dpgfPostIndex: post.postIndex,lotId : lot.id})"
                        [prefix]="lineNumber(loti,post.sousLots,post.postIndex)" [tags]="lot.title"
                        [attr.id]="'designation-revit' + post.id"></app-input-dpgf>
                    </td>
                    <td class="relative unit-col">
                      <select name="unit" 
                              id="units" 
                              [(ngModel)]="post.unite" 
                              (change)="changePostUnit(post.unite, {posteId : post.id, loti : loti, sousLoti : post.sousLots, posti : posti ,dpgfPostIndex: post.postIndex,lotId : lot.id})">
                        <option *ngFor="let unit of units" [ngValue]="unit.value" >{{unit.label}}</option>
                      </select>
                    </td>
                    <td class="relative quantity-col">
                      {{post.qte}}
                    </td>
                    <td class="relative unlink-col" style="cursor: pointer;">
                      <i class="fa fa-chain-broken"  (click)="detachElementFromPost(post.unite, {posteId : post.id, loti : loti, sousLoti : post.sousLots, posti : posti ,dpgfPostIndex: post.postIndex,lotId : lot.id})" aria-hidden="true"></i>
                    </td>
                  </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-sidebar>


  <p-sidebar [(visible)]="openPostesSlider" position="bottom" height="80%" styleClass="p-sidebar-lg">
    <div class="header">
      <div class="row">
        <div class="center">
          <div class="inner-container">
            <i class="click-it fa fa-times" aria-hidden="true" (click)="openPostesSlider = false"></i>
            <p class="post-title">
              <span class="lot-name">
                <img *ngIf="(selectImportLot?.icon||'') != ''" [src]="'assets/dpgf/Icon_DPGF/' + selectImportLot?.icon"
                  height="15">{{selectImportLot?.indexLot}}
                - {{selectImportLot?.title}}
              </span>
            </p>
            <button class="btn-import" (click)="attachSelectPostsToBim()">
              <div *ngIf="!loaders?.importing">Importer les postes</div>
              <span *ngIf="loaders?.importing" class="loader" style="height: 20px; width : 20px"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="content-cstb">
      <div class="revit-page">
        <div class="paraghra">
          <p style="text-align: start !important">Choisir les postes à ajouter a cette element</p>
          <div class="result">
          </div>
        </div>
        <table class="lot-postes">
          <tr *ngFor="let post of importLotPostes;  let posti = index">
              <td class="relative check-col">
                  <p-checkbox [(ngModel)]="post.checked" [binary]="true"></p-checkbox>
              </td>
              <td class="relative number-col">
                  {{lineNumber(post.indexLot,post.postes.sousLots,post.postes.postIndex)}}
              </td>
              <td class="relative designation-col">
                {{post.postes.designation}}
              </td>
          </tr>
        </table>
      </div>
    </div>
  </p-sidebar>
  <p-sidebar [(visible)]="displaySideCstb" position="bottom" height="80%" styleClass="p-sidebar-lg">
    <div class="header">
      <div class="row">
        <div class="center">
          <div class="inner-container">
            <i class="click-it fa fa-times" aria-hidden="true" (click)="openPostesSlider = false"></i>
            <p class="post-title">
              CSTB
            </p>
            <button class="btn-import" (click)="importCstbPostes()">
              <div *ngIf="!loaders?.importingcstb">Importer</div>
              <span *ngIf="loaders?.importingcstb" class="loader" style="height: 20px; width : 20px"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="cstb-content">
      <div>
        <input class="input-search" [(ngModel)]="searchTerm" (keyup)='searchPost()'>
        <i class="fa fa-search search-icon" (click)='searchPost()'></i>
      </div>
      <div *ngIf="searchTerm">
        <p class="result-title">Resultats de <b>{{searchTerm}}</b> :</p>
        <div class="post-container" *ngFor="let post of results;let i=index">
          <input type='checkbox' (click)='selectedPost(post)'>
          <p class="post">{{post.sugg}}</p>
        </div>
      </div>
      <app-cstb-navigation [hidden]="searchTerm" manySelect="true" (selectedCstbEvent)="selectPosteCstb($event)"></app-cstb-navigation>
    </div>
  </p-sidebar>

  <!-- <p-sidebar [(visible)]="displaySideCstb" position="bottom" height="80%" styleClass="p-sidebar-lg">
    <div class="header">
      <div class="row">
        <div class="center">
          <div class="inner-container">
            <i class="click-it fa fa-times" aria-hidden="true" (click)="openPostesSlider = false"></i>
            <p class="post-title">
              CSTB
            </p>
            <button class="btn-import" (click)="importCstbPostes()">
              <div *ngIf="!loaders?.importingcstb">Importer</div>
              <span *ngIf="loaders?.importingcstb" class="loader" style="height: 20px; width : 20px"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="cstb-content">
      <app-cstb-navigation [hidden]="searchTermCstb" manySelect="true" (selectedCstbEvent)="selectPosteCstb($event)"></app-cstb-navigation>
    </div>
  </p-sidebar> -->
</div>