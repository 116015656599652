import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';

import mainRegionsList from '../../booster-profile-forms-components/booster-profile-precision-form/regionsList-list';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone: true,
    selector: 'app-booster-profile-edit-precisions-aglo-booster-modal',
    imports:[
      NgSharedModule
    ],
    templateUrl: './booster-profile-edit-precisions-aglo-booster-modal.component.html',
    styleUrls: ['./booster-profile-edit-precisions-aglo-booster-modal.component.scss'],

})
export class BoosterProfileEditPreCisionsAgloBoosterModalComponent implements OnInit, OnChanges {

  @Input() artisanData:any = {};
  @Input() workAreaMain:any = []; // Check if still used
  @Output() saveData: EventEmitter<any> = new EventEmitter<any>();

  public domaineSpe: string = "";
  public showSuggestionsList: boolean = false;
  public regionsList: any = [];

  public regionSelectedDepartementsNumber: any = {};
  public selectedDepartements: any = {};

  constructor() { }

  ngOnInit(): void {
    this.setRegionsList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.artisanData && changes.artisanData?.currentValue?.workAreaMain.length){
      let { domaineSpe, workAreaMain } = changes.artisanData.currentValue;
      
      this.domaineSpe = domaineSpe;

      /// wail till the list is Loaded
      setTimeout(() => {
        this.setAlreadySelectedData(workAreaMain);
      }, 1000);
    }
  }
  private setRegionsList(){
    this.regionsList = mainRegionsList;
  }

  private setAlreadySelectedData(workAreaMain){
    let selectedIds = workAreaMain.map(item => item.id);

    // get the region id from departement id, then select departement
    for(let i = 0; i < this.regionsList.length; i++){
      let currentRegion = this.regionsList[i];
      for (let j = 0; j < currentRegion["departements"].length; j++) {
        let currentDepartement = currentRegion["departements"][j];
        if(selectedIds.includes(currentDepartement.id)){
          this.selectDepartement(currentRegion.value, currentDepartement.id);
        }
      }
    }

  }

  public toggleShowSuggestionsList(){
    this.showSuggestionsList = !this.showSuggestionsList;
  }

  public selectDepartement(regionValue, id){

    let departementAlreadySelected = this.selectedDepartements[id];

    if(departementAlreadySelected){ // is Exists delete
      
      delete this.selectedDepartements[id];
      // add count
      this.regionSelectedDepartementsNumber[regionValue]--;
      if(this.regionSelectedDepartementsNumber[regionValue] == 0){
        delete this.regionSelectedDepartementsNumber[regionValue];
      }

    }else{ // Add
      this.selectedDepartements[id] = true;
      
      if(this.regionSelectedDepartementsNumber[regionValue]){
        this.regionSelectedDepartementsNumber[regionValue]++;
      }else{
        this.regionSelectedDepartementsNumber[regionValue] = 1;
      }

    }
  }
  public checkRegionDepartements(regionValue, regionDepartements){
    let isChecked = this.regionSelectedDepartementsNumber[regionValue] == regionDepartements.length;

    if(!isChecked){ // Check All Departements
      regionDepartements.forEach(item => this.selectedDepartements[item.id] = true);

      this.regionSelectedDepartementsNumber[regionValue] = regionDepartements.length;
    }else{ // UnCheck all Departements
      
      for(let i = 0; i < regionDepartements.length; i++){
        let currentDepartement = regionDepartements[i];

        if(this.selectedDepartements[currentDepartement.id]){
          delete this.selectedDepartements[currentDepartement.id];
        }
      }
      this.regionSelectedDepartementsNumber[regionValue] = 0;
    }
  }
  
  private getDepartementsData(departementsIdsArray){
    let departements = [];

    for(let i = 0; i < this.regionsList.length; i++){
      let currentRegion = this.regionsList[i];
      for (let j = 0; j < currentRegion["departements"].length; j++) {
        let currentDepartement = currentRegion["departements"][j];
        if(departementsIdsArray.includes(currentDepartement.id)){
          departements.push({id: currentDepartement.id, text: currentDepartement.text});
        } 
      }
    }

    return departements;
  }

  public emitSaveData(){
    let workAreaMainData = this.getDepartementsData(Object.keys(this.selectedDepartements));

    let body = {
      domaineSpe: this.domaineSpe,
      workAreaMain: workAreaMainData
    };

    this.saveData.emit(body);
  }  

}
