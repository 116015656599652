import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../_services/index';
import * as actions from '../_store/dpgf.actions';
import { MessageService } from 'primeng/api';

declare var $;
@Component({
  selector: 'app-excel-import',
  standalone: true,
  imports: [NgSharedModule],
  templateUrl: './excel-import.component.html',
  styleUrl: './excel-import.component.scss'
})
export class ExcelImportComponent {
  @Input()loti:any;
  @Input()sousloti:any;
  @Output() posteFromExcelAdded: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isCopied:boolean = false;
  public csvData: string[] = [
    'Tuyaux ronds en zinc naturel - De diamètre...',
    'm2',
    '7',
    'Description de l’ouvrage',
    '2200',
    '15540',
    '20%',
    '1.1.2.5'
  ];
  public data;columnNames;
  public agloOptions = [
    {'Titre du poste' : 'designation'},
    {'Quantité' : 'qte'},
    {'Unité' : 'unite'},
    {'Localisation' : 'localisation'},
    {'Pu' : 'unitePriceArchi'},
    {'totalHT' : 'priceArchi'},
    {'TVA' : 'tva'},
    {'Description' : 'cctp'},
    {'nothing' : 'rien'}
  ];
  public matchingOption = [
    {label:'Titre du poste', value:"designation"},
    {label:'Localisation', value:"localisation"},
    {label:'Quantité', value:"qte"},
    {label:'Unité', value:"unite"},
    {label:'TVA', value:"tva"},
    {label:'CCTP', value:"cctp"},
    {label:'Prix U. HT', value:"unitePriceArchi"},
    {label:'Prix HT', value:"priceArchi"},
    {label:'Ne pas importer', value:"none"}]
  public matching = []
  public pasteRows = [];
  public sousLotDelete = {};
  public sousLotPast = 0;hideDiv;
  public agloMapping: string[] = new Array(this.csvData.length).fill('');
  csvRecords: any[];
  uploadedCsv: boolean;
  csvHeaders: any;
  records: any[];
  licence



  constructor(
    private store: Store,
    private authService:AuthService,
    private messageService: MessageService
  ){
    this.licence = this.authService.licence || "base";
  }
  cancel() {
    // Logique pour annuler l'action
    this.isCopied = false;
  }

  import() {
    // Logique pour importer les données
    console.log(this.agloMapping);
  }
  onFileUploadClick() {
    document.getElementById('fileUpload')?.click();
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files = Array.from(input.files);
      // Ajoutez ici la logique pour traiter les fichiers sélectionnés
    }
  }
  public changeListener(files: FileList){
    //to remove
    if(files && files.length > 0) {
       let file : File = files.item(0); 
         let reader: FileReader = new FileReader();
         reader.readAsText(file, 'ISO-8859-1');
         reader.onload = (e) => {
            let csv: string = reader.result as string;
            let rows=[]
            rows=csv.split('\n');
            rows.pop();
            rows.forEach((row,i)=>rows[i]=row.split(','))
            this.csvRecords = rows;
            this.csvHeaders = rows[0];
            this.csvRecords.shift();
            this.uploadedCsv = true;
            this.records = Array(rows.length - 1);
         }
      }
  }

  onFileChange(event: any) {
    // const target: DataTransfer = <DataTransfer>(event.target);
    // if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    
    // const reader: FileReader = new FileReader();
    
    // reader.onload = (e: any) => {
    //   const binaryStr: string = e.target.result;
    //   const workbook: XLSX.WorkBook = XLSX.read(binaryStr, { type: 'binary' });

    //   const firstSheetName: string = workbook.SheetNames[0];
    //   const worksheet: XLSX.WorkSheet = workbook.Sheets[firstSheetName];
      
    //   const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    //   if (jsonData.length > 0) {
    //     this.columnNames = jsonData[1]; // First row are the column names
    //     this.data = jsonData.slice(1);  // Remaining rows are the data
    //   } else {
    //     this.columnNames = [];
    //     this.data = [];
    //   }
    // };
    
    // reader.readAsBinaryString(target.files[0]);
  }

  public pasteData(event,sousloti){
    this.isCopied = true;
    event.preventDefault()
    if(this.licence!='without'){
      let rowsToAdd = [];
      let clipboardData = event.clipboardData ;
      let pastedText = clipboardData.getData("Text") || clipboardData.getData("text/plain");
      let rows = pastedText.split("\n");
      rows.forEach((row)=>{
        if(row.length>0)rowsToAdd.push(row.split("\t"))
      })
      let matching  = [];
      
      (rowsToAdd[0]||[]).forEach((el,index)=>{
        matching.push({value : el, key : (this.matchingOption[index]||{value : ""}).value})
      })
      this.matching = matching;
      // $('#paste-modal-' + this.loti).modal('toggle')
      this.pasteRows = rowsToAdd;
      this.sousLotPast = sousloti;
      this.store.dispatch(actions.emptyTypeAmount());
    }
    else $('#upgrade-modal').modal('show');
  }
  public pasteDataWithKeys(){
    this.isCopied = false;
    this.store.dispatch(actions.addManyPostes({loti : this.loti,sousloti : this.sousloti ||0 , pasteRows :this.pasteRows, matching: this.matching}));
    // $('#paste-modal-' + this.loti).modal('toggle');
    this.posteFromExcelAdded.emit(true)
    this.messageService.add({ key: 'toast', severity: 'success', summary: "Import de postes", detail: 'Vos postes ont bien été importés depuis Excel', life: 5000 });
  }
}