import { Component, OnInit, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/_services';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

@Component({
    standalone: true,
    selector: 'app-booster-profile-edit-documents-modal',
    imports:[
      NgSharedModule,
    ],
    templateUrl: './booster-profile-edit-documents-modal.component.html',
    styleUrls: ['./booster-profile-edit-documents-modal.component.scss'],
})
export class BoosterProfileEditDocumentsModalComponent implements OnInit {

  @Input() docsData: any = {};
  @Output() saveData: EventEmitter<any> = new EventEmitter<any>();

  public filesLoading: number[] = [];
  public uploadedDocs = {};
  public firstValueEnvirenementObservable = new Observable<boolean>(observer => {
    observer.next(environment.onPremise);
    observer.complete();
  });
  constructor(public authService:AuthService) { }

  ngOnInit(): void {}
  public onUploadAttestationAssurance(event){
    let assuranceFileData = event.originalEvent.body[0];
    /// show added files on interface
    this.uploadedDocs["assuranceFile"] = assuranceFileData;
    this.docsData.assuranceFile = assuranceFileData;
  }
  public onUploadKbisFiles(event){
    let kbisFileData = event.originalEvent.body[0];
    /// show added files on interface
    this.docsData.kbis = kbisFileData;
    this.uploadedDocs["kbis"] = kbisFileData;
  }
  public onUploadAttestationsCertification(event){
    let currentFiles = event.originalEvent.body;
    let artisanFiles = [...this.docsData.attestationsCertification];
    artisanFiles= [...artisanFiles,...currentFiles];
    /// show added files on interface
    this.docsData.attestationsCertification = artisanFiles;
    this.uploadedDocs["attestationsCertification"] = artisanFiles;
  }
  public onUploadPortfolioPhoto(event){
    let currentFiles = event.originalEvent.body;
    let artisanFiles = [...this.docsData.files];
    artisanFiles = [...artisanFiles,...currentFiles];
    /// show added files on interface
    this.docsData.files = artisanFiles;
    this.uploadedDocs["files"] = artisanFiles;
  }

  public removeDocument(documentType, index=-1){    
    if(index>-1){
      let filteredDocuments = this.docsData[documentType].filter((item, i) => i != index);

      // this.emitUpdateDocument({[documentType]: filteredDocuments});
      this.uploadedDocs[documentType] = filteredDocuments;

      /// remove data from the view
      this.docsData[documentType] = filteredDocuments;
    }else{
      // this.emitUpdateDocument({[documentType]: {}});
      this.uploadedDocs[documentType] = {};
    
      /// remove data from the view
      this.docsData[documentType] = {};
    }    
  }
  
  public emitUpdateDocument(){
    this.saveData.emit(this.uploadedDocs);    
  }

}
