import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup,  Validators} from '@angular/forms';
import {Title,Meta} from '@angular/platform-browser';
import { AuthService } from '../../../_services/index';
import { MessageService } from 'primeng/api';
import { NgSharedModule } from 'src/app/all/ngshared.module';
//Interface
import { Architect } from '../../../_interfaces/architect.interface';
import { AllModule } from '../all.module';
interface Window
{
    prerenderReady:boolean;
}
declare var window:Window;

declare var $:any;

@Component({
  standalone:true,
    selector: 'app-forgot-password',
    imports:[
      NgSharedModule
    ],
    providers: [],
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  public loading:boolean = false;
  public formErrors = {'email': ''};
  public sent = false;
  public forgetForm: UntypedFormGroup;
  private validationMessages = {
    'email': {
      'required': 'Le mail est obligatoire.',
      'email':'Email invalide'
    }
  };
  constructor(private title: Title, 
    private fb: UntypedFormBuilder, 
    private authService: AuthService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.createForm();
    this.title.setTitle('Aglo - Mot de passe oublié' );
    window.prerenderReady = true;
  }
  //Create Form
  private createForm() {
    this.forgetForm = this.fb.group({
      email: ['', [Validators.required,Validators.email]],
    });
    this.forgetForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged();
  }

  //Generate Error
  private onValueChanged(data ? : any) {
    if (!this.forgetForm) {
      return;
    }
    const form = this.forgetForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
  //Mark as touched
  private markFormAsTouched():void {
    if (!this.forgetForm) {
      return;
    }
    const form = this.forgetForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      control.markAsDirty();
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
    }
  }
  //Validate Form
  public isValid(){
      this.markFormAsTouched()
      return this.forgetForm.valid
  }
  public forgetPassword(){
    if(this.isValid()){
      this.loading=true;
      this.authService.forgetPassword(this.forgetForm.value.email)
          .subscribe(
              data => {
                this.loading=false;
                this.sent = true;
                setTimeout(()=>{
                  $('#email-sent').addClass('animated fadeInLeft');
                  $('#email-sent').removeClass('hide');
                }, 500);
              },
              error => {
                this.loading=false;
                this.messageService.add({ key: 'toast', severity: 'error', summary: "404", detail: "Cet utilisateur n\'est pas dans notre base", life: 5000 });
                console.log(error)
              })
    }
  }

}
