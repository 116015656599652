<div class="all">
  <div class="main-container">
    <div class="editor-container scroller-1">
      <!-- <h1 class="main-title">Personnalisation des documents</h1> -->
      <div class="text-settings">
        <h2 style="margin-left: 0;">Page de garde</h2>
        <div class="row warning-container2">
          <img src="/assets/archi/licence_warning.svg" alt="" class="warning-icon">
          <p class="user-warning">Cette partie concerne les exports PDF uniquement.</p>
        </div>
        <div class="margin-left-24 margin-bottom-24 no-padding">
          <p class="title">Police</p>
          <select class="form-select font-select" (change)="changeFont($event.target.value)">
            <option [selected]="docData.font=='Lato'" value="Lato">Lato</option>
            <option [selected]="docData.font=='Poppins'" value="Poppins">Poppins</option>
            <option [selected]="docData.font=='Garamond'" value="Garamond">Garamond</option>
            <option [selected]="docData.font=='TimesNewRoman'" value="TimesNewRoman">Times New Roman</option>
            <option [selected]="docData.font=='OpenSans'" value="OpenSans">Open Sans</option>
            <option [selected]="docData.font=='Roboto'" value="Roboto">Roboto</option>
            <option [selected]="docData.font=='Arial'" value="Arial">Arial</option>
            <option [selected]="docData.font=='CenturySchoolbook'" value="CenturySchoolbook">Century School book</option>
          </select>
        </div>
        <div class="margin-left-24 margin-bottom-24 no-padding">
          <p class="title">Modèle</p>
          <div class="choice-number">
            <div *ngFor="let i of [1,2,3,4]" class="one-box-container" (click)='setAgencement(i)'>
              <div class='choice-box black-blue'>
                <p [ngClass]="docData.agencement!=i? 'absolute-number white':'absolute-number'"class="absolute-number">{{i}}</p>
                <div *ngIf="docData.agencement==i" class="inner-box-selected"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="margin-left-24 margin-bottom-24 no-padding" style="position: relative;">
          <p class="title">Couleur</p>
          <div style="display:flex;">
            <div class="choice-number">
              <!-- <div *ngFor="let color of colors;let i=index" [ngClass]="i<4 ? 'one-box-container':'one-box-container margin-top-20'" class="one-box-container"><div [ngClass]="color.isSelected ? 'choice-box-selected '+ color.color :'choice-box '+color.color" (click)='changeColor(color.hex,i)'></div></div> -->
              <div *ngFor="let color of colors;let i=index" [ngClass]="i<4 ? 'one-box-container':'one-box-container margin-top-20'" class="one-box-container">
                <div [ngClass]="'choice-box ' + color.color" (click)='changeColor(color.hex,i)'>
                  <div *ngIf="color.isSelected" class="inner-box-selected">
                  </div>
                </div>
              </div>
            </div>
            <div class="color-picker">
              <button (click)='op.toggle($event)' class="open-color"><i class="fa fa-search"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-settings">
        <h2 style="margin-left: 0;">Equipe</h2>
        <div class="row">
          <div class="info-container col-md-4">
            <p class="title">Operation</p>
            <textarea class="main-info" type="text"
                      (focusout)="sendDpgfToParent()"
                      (ngModelChange)="saveInfo({key:'operationText',value:$event})"
                      [ngModel]="dpgf?.operationText"></textarea>
          </div>
          <div class="info-container col-md-4">
            <p class="title">Maitre d'ouvrage</p>
            <textarea class="main-info" type="text"
                      (focusout)="sendDpgfToParent()"
                      (ngModelChange)="saveInfo({key:'maitreOuvrage',value:$event})"
                      [ngModel]="dpgf?.maitreOuvrage"></textarea>
          </div>
          <div class="info-container col-md-4">
            <p class="title">Maitre d'oeuvre</p>
            <textarea class="main-info" type="text"
                      (focusout)="sendDpgfToParent()"
                      (ngModelChange)="saveInfo({key:'maitreOeuvre',value:$event})"
                      [ngModel]="dpgf?.maitreOeuvre"></textarea>
          </div>
        </div>
        <div class="row table-header">
          <p class="title">Equipe de la maîtrise d’oeuvre</p>
          <p class="add-assisstant" (click)='addAssistant()'>+ Ajouter un partenaire</p>
        </div>
        <div class="row table-container">
          <table class="table assisstant-table">
            <thead>
              <tr>
              <th>Rôle</th>
              <th>Nom de l’entreprise</th>
              <th>Adresse e-mail</th>
              <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let assistant of (dpgf$|async)?.docData?.assistants;let i = index">
                <!-- (ngModelChange)="setDPGFAttribut({key:'assisstants[i].name',value:$event})" -->
                <td><input class="table-input" [(ngModel)]="assistant.role" (focusout)="addChange()" placeholder="Rôle"></td>
                <td><input class="table-input" [(ngModel)]="assistant.name" (focusout)="addChange()" placeholder="Nom"></td>
                <td><input class="table-input" [(ngModel)]="assistant.email" (focusout)="addChange()" placeholder="E-mail"></td>
                <td>
                  <img src="/assets/archi/trash.svg" class="clickable" (click)='deleteAssistant(i)'>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="text-settings">
        <h2 style="margin-left: 0;">Options</h2>
        <div class="row warning-container2">
          <img src="/assets/archi/licence_warning.svg" alt="" class="warning-icon">
          <p class="user-warning">Cette partie concerne les exports PDF uniquement.</p>
        </div>
        <!-- <div class="flex-horizontal-left" style="position: relative">
          <div style="padding-top: 12px;display:flex" (click)='check("showDate")'>
            <input type="checkbox" [checked]='docData.showDate'>
            <p class="option-p">Date d'émission</p>
          </div> -->
          <!-- <button (click)='op1.toggle($event)' class="ag-secondary-btn">pick date</button> -->
          <!-- <i *ngIf="docData.showDate" class="fa fa-calendar calendar-icon"></i> -->
          <!-- <p-calendar *ngIf="docData.showDate" dateFormat="dd/mm/yy" [inputStyle] = "{'width':'90px','padding-left':'28px','margin-left':'12px','border':'1px solid #DBDDE1','border-radius':'8px'}" [monthNavigator]="true" [yearNavigator]="true" [(ngModel)]="selectedDate" (onSelect)='treatDate()' yearRange="2000:2040"></p-calendar> -->
          <!-- <input type="date" [ngModel]="dpgf?.dceDate | date:'yyyy-MM-dd' "
          (ngModelChange)="saveInfo({ key  : 'dceDate', value : $event});this.sendDpgfToParent()"
          placeholder="Date du DCE" pattern = "dd-MM-yyyy" style="width: 30%;margin-left: 12px;background-color: white;border: 1px solid #DBDDE1;border-radius: 8px;padding-left: 12px;padding-right: 6px;"
          [disabled]='!docData.showDate'>
        </div> -->
        <div class="">
          <div style="padding-top: 12px;display:flex" (click)='check("showDate")'>
            <input type="checkbox" [checked]='docData.showDate'>
            <p class="option-p no-margin-bottom">Date d'émission</p>
          </div>
          <label class="container">
            <input class="to-day-date" (change)="handleDateSection($event)" [checked]="!docData.customDate" type="radio"  name="radio">
            <span class="check-box-radio">Date du jour</span>
          </label> 
          <!-- *ngIf="docData.format != 'word' && docData.format != 'excel' " -->
          <label class="container" >
            <input class="custom-date" 
             (change)="handleDateSection($event)" [checked]="docData.customDate" type="radio" name="radio">
            <span class="check-box-radio">Date personnalisée</span>
            <input  *ngIf="docData.customDate" type="date" [ngModel]="dpgf?.dceDate | date:'yyyy-MM-dd' "
                   (ngModelChange)="saveInfo({ key  : 'dceDate', value : $event});this.sendDpgfToParent()"
                   placeholder="Date du DCE" pattern = "dd-MM-yyyy" style="width: 30%; font-weight: 100; margin-left: 12px;background-color: white;border: 1px solid #DBDDE1;border-radius: 8px;padding-left: 12px;padding-right: 6px;"
                   [disabled]='!docData.showDate'>
          </label>
        </div>
        <div class="flex-horizontal-left margin-bottom-10" (click)='check("showLogo")'>
          <input type="checkbox" [checked]='docData.showLogo'>
          <p class="option-p">Afficher le logo</p>
        </div>
        <div class="flex-horizontal-left margin-bottom-5" (click)='check("showIndice")'>
          <input type="checkbox" [checked]='docData.showIndice'>
          <p class="option-p">Afficher l'indice du DCE</p>
        </div>
        <div class="flex-horizontal-left indice" (click)="changeTypeIndice('Aglo')">
          <input type="radio" name="indice" [checked]="docData.typeIndice=='Aglo' || !docData.typeIndice" [disabled]="!docData.showIndice">
          <p class="option-p">Indice Aglo</p>
        </div>
        <div class="flex-horizontal-left center-items indice" (click)="changeTypeIndice('Perso')">
          <input type="radio" name="indice" value="male" [checked]="docData.typeIndice=='Perso'" [disabled]="!docData.showIndice">
          <p class="option-p">Indice personnalisé</p>
          <input type="text" class="indice-input" (focusout)="changeIndice()" [(ngModel)]="docData.indice" [disabled]="docData.typeIndice=='Aglo' || !docData.showIndice" placeholder="Exemple : A">
        </div>
      </div>
    </div>
    <div class="divider">
    </div>
    <div class="preview-container scroller-1">
      <div *ngIf="docData.agencement==1 && !loadingData" class="pdf-size template1">
        <div>
          <img *ngIf="!authService.currentEnvironment" [src]="project.mainImage || 'https://s3.eu-central-1.amazonaws.com/bam-consultation-slickshot/22698xu9urkjo4uqwj%2F13787886-architectural-project-architect-rolls-and-plans.jpg'" height="280" width="447"/>

          <img *ngIf="authService.currentEnvironment" [src]="onpremiseProjectImage" height="280" width="447" />
        </div>
        <!-- <div *ngIf="checkbox.logo" class="row logo-row">
          <img [src]='imageUrl' alt="" [ngStyle]='style["logoStyle"]'>
        </div> -->
        <div class="detail-container">
          <div class="row title-style" [ngStyle]="style['color']">
            <p>{{(dpgf$|async)?.operationText}}</p>
          </div>
          <div class="cctp-row">
            <div class="secondary-title">
              <p class="title">{{docTitle}}</p>
              <p *ngIf="docData.showIndice && docData.typeIndice=='Aglo'" class="detail-parag">Indice {{(dpgf$|async)?.currentVersion | pricingIndex}} {{(dpgf$|async)?.docData?.options?.provisoire ? '- Version provisoire' : ''}}</p>
              <p *ngIf="docData.showIndice && docData.typeIndice!='Aglo'" class="detail-parag">Indice {{docData?.indice}} {{(dpgf$|async)?.docData?.options?.provisoire ? '- Version provisoire' : ''}}</p>
              <p *ngIf="docData.showDate" class="detail-parag">{{formateDate((dpgf$|async)?.dceDate || (dpgf$|async)?.createdAt)}}</p>
            </div>
          </div>
          <div *ngIf="(dpgf$|async)?.maitreOuvrage" class="cctp-row">
            <div class="master-container">
              <p class="master-title">Maître d'ouvrage</p>
              <p >{{(dpgf$|async)?.maitreOuvrage | formatMO:2}}</p>
            </div>
          </div>
          <div *ngIf="(dpgf$|async)?.maitreOeuvre" class="second-master">
            <div class="master-container">
              <p class="master-title">Maître d'oeuvre</p>
              <p >{{(dpgf$|async)?.maitreOeuvre | formatMO:2}}</p>  
            </div>
          </div>
          <div style="position: relative;">
            <div *ngIf="docData.showLogo" class="row logo">
              <img [src]="(logo || '/assets/rob/aglo_logo_round.png')" alt="" class="smol-logo">
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="docData.agencement==3 && !loadingData" class="pdf-size template3">
        <div class="title-style" [ngStyle]="style['color']">
          <p>{{(dpgf$|async)?.operationText}}</p>
        </div>
        <div class="img-container">
          <img *ngIf="!authService.currentEnvironment" [src]="project.mainImage || 'https://s3.eu-central-1.amazonaws.com/bam-consultation-slickshot/22698xu9urkjo4uqwj%2F13787886-architectural-project-architect-rolls-and-plans.jpg'" height="120" width="170"/>
          <img *ngIf="authService.currentEnvironment" [src]="onpremiseProjectImage" height="120" width="170" />        </div>
        <!-- <div *ngIf="checkbox.logo" class="row logo-row">
          <img [src]='imageUrl' alt="" [ngStyle]='style["logoStyle"]'>
        </div> -->
        <div class="detail-container">
          <div class="cctp-row">
            <div class="secondary-title">
              <p class="title">{{docTitle}}</p>
              <p *ngIf="docData.showIndice && docData.typeIndice=='Aglo'" class="detail-parag">Indice {{(dpgf$|async)?.currentVersion | pricingIndex}} {{(dpgf$|async)?.docData?.options?.provisoire ? '- Version provisoire' : ''}}</p>
              <p *ngIf="docData.showIndice && docData.typeIndice!='Aglo'" class="detail-parag">Indice {{docData?.indice}} {{(dpgf$|async)?.docData?.options?.provisoire ? '- Version provisoire' : ''}}</p>
              <p *ngIf="docData.showDate" class="detail-parag">{{formateDate((dpgf$|async)?.dceDate || (dpgf$|async)?.createdAt)}}</p>
            </div>
          </div>
          <div class="cctp-row" [ngStyle]="{'margin-top': (dpgf$|async)?.docData?.assistants?.length ? ((dpgf$|async)?.docData?.assistants?.length<4 ? '74px':'20px'):'154px'}">
            <div *ngIf="(dpgf$|async)?.maitreOuvrage" class="master-container">
              <p class="master-title">Maître d'ouvrage</p>
              <span style="white-space: pre-line" >{{(dpgf$|async)?.maitreOuvrage}}</span>
            </div>
          </div>
          <div *ngIf="(dpgf$|async)?.maitreOeuvre" class="second-master">
            <div class="master-container">
              <p class="master-title">Maître d'oeuvre</p>
              <span style="white-space: pre-line" >{{(dpgf$|async)?.maitreOeuvre}}</span>
            </div>
          </div>
        </div>
        <div class="master-title" *ngIf="(dpgf$|async)?.docData?.assistants?.length">
          Équipe de maîtrise d’œuvre
        </div>
        <div class="assisstant-container">
          <div class="one-assistants" *ngFor="let assistant of (dpgf$|async)?.docData.assistants;let j = index">
            <p style="font-weight: 700;">{{assistant.role}}</p>
            <p *ngIf="assistant.name" >{{assistant.name}}</p>
            <p *ngIf="assistant.email" >{{assistant.email}}</p>
          </div>
        </div>
        <div style="display: flex;justify-content: center;">
          <div *ngIf="docData.showLogo" class="row logo">
            <img [src]='imageUrl' alt="" class="smol-logo">
          </div>
        </div>
      </div>	
      <div *ngIf="docData.agencement==2 && !loadingData" class="pdf-size template2">
        <div *ngIf="docData.showLogo" class="logo-row">
          <img [src]='imageUrl' alt="" class="logo" height="100">
        </div>
        <div *ngIf="!docData.showLogo" style="height: 70px;"></div>
        <div class="title-style" [ngStyle]="style['color']">
          <p>{{(dpgf$|async)?.operationText}}</p>
        </div>
        <div class="detail-container">
          <div class="cctp-row">
            <div class="secondary-title">
              <p class="title">{{docTitle}}</p>
              <p *ngIf="docData.showIndice && docData.typeIndice=='Aglo'" class="detail-parag">Indice {{(dpgf$|async)?.currentVersion | pricingIndex}} {{(dpgf$|async)?.docData?.options?.provisoire ? '- Version provisoire' : ''}}</p>
              <p *ngIf="docData.showIndice && docData.typeIndice!='Aglo'" class="detail-parag">Indice {{docData?.indice}} {{(dpgf$|async)?.docData?.options?.provisoire ? '- Version provisoire' : ''}}</p>
              <p *ngIf="docData.showDate" class="detail-parag">{{formateDate((dpgf$|async)?.dceDate || (dpgf$|async)?.createdAt)}}</p>
            </div>
          </div>
          <div class="cctp-row" [ngStyle]="{'margin-top': (dpgf$|async)?.docData?.assistants?.length ? ((dpgf$|async)?.docData?.assistants?.length<4 ? '74px':'20px'):'154px'}">
            <div *ngIf="(dpgf$|async)?.maitreOuvrage" class="master-container">
              <p class="master-title">Maître d'ouvrage</p>
              <p >{{(dpgf$|async)?.maitreOuvrage | formatMO:2}}</p>
            </div>
          </div>
          <div *ngIf="(dpgf$|async)?.maitreOeuvre" class="second-master">
            <div class="master-container">
              <p class="master-title">Maître d'oeuvre</p>
              <p >{{(dpgf$|async)?.maitreOeuvre | formatMO:3}}</p>
            </div>
          </div>
        </div>
        <div class="master-title" *ngIf="(dpgf$|async)?.docData?.assistants?.length" style="margin-left: 45px;">
          Équipe de maîtrise d’œuvre
        </div>
        <div class="assisstant-container">
          <div class="one-assistants" *ngFor="let assistant of (dpgf$|async)?.docData.assistants;let j = index">
            <p style="font-weight: 700;">{{assistant.role}}</p>
            <p *ngIf="assistant.name" >{{assistant.name}}</p>
            <p *ngIf="assistant.email" >{{assistant.email}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="docData.agencement==4 && !loadingData" class="pdf-size template4">
        <div *ngIf="docData.showLogo" class="row logo-row">
          <img [src]='imageUrl' alt="" class="logo" height="100">
        </div>
        <div *ngIf="!docData.showLogo" style="height: 70px;"></div>
        <div class="title-style" [ngStyle]="style['color']">
          <p>{{(dpgf$|async)?.operationText}}</p>
        </div>
        <div class="detail-container">
          <div class="cctp-row">
            <div class="secondary-title">
              <p class="title">{{docTitle}}</p>
              <p *ngIf="docData.showIndice && docData.typeIndice=='Aglo'" class="detail-parag">Indice {{(dpgf$|async)?.currentVersion | pricingIndex}} {{(dpgf$|async)?.docData?.options?.provisoire ? '- Version provisoire' : ''}}</p>
              <p *ngIf="docData.showIndice && docData.typeIndice!='Aglo'" class="detail-parag">Indice {{docData?.indice}} {{(dpgf$|async)?.docData?.options?.provisoire ? '- Version provisoire' : ''}}</p>
              <p *ngIf="docData.showDate" class="detail-parag">{{formateDate((dpgf$|async)?.dceDate || (dpgf$|async)?.createdAt)}}</p>
            </div>
          </div>
          <div class="cctp-row">
            <div *ngIf="(dpgf$|async)?.maitreOuvrage" class="master-container">
              <p class="master-title">Maître d'ouvrage</p>
              <span style="white-space: pre-line" >{{(dpgf$|async)?.maitreOuvrage}}</span>
            </div>
          </div>
          <div *ngIf="(dpgf$|async)?.maitreOeuvre" class="second-master">
            <div class="master-container">
              <p class="master-title">Maître d'oeuvre</p>
              <span style="white-space: pre-line" >{{(dpgf$|async)?.maitreOeuvre}}</span>
            </div>
          </div>
        </div>
      </div>
      <app-spinner *ngIf="loadingData"></app-spinner>	
    </div>
  </div>
</div>
<p-overlayPanel #op [showCloseIcon]="true" [appendTo]="'body'" >
  <ng-template *ngIf="docData.colorHistory && docData.colorHistory.length" pTemplate>
    <color-sketch [color]="docData.color" width='200px' (onChangeComplete)="changeColor($event.color.hex)" [presetColors]="docData.colorHistory"></color-sketch>
  </ng-template>
</p-overlayPanel>

<!-- <div class="logo-container">
          <p class="title">Logo</p>
          <div class="flex-horizontal">
            <p>Emplacement</p>
            <select class="form-select" (change)="treatLogo($event.target.value, 'left')">
              <option selected value="left">CSG</option>
              <option value="center">Milieu</option>
              <option value="right">CSD</option>
            </select>
          </div>
          <div class="flex-horizontal">
            <p>Taille</p>
            <input type="range" class="form-range" (change)="treatLogo($event.target.value, 'height')">
          </div>
          <div class="flex-horizontal">
            <input type="checkbox" >
            <p>Afficher dans l'en-tête du document</p>
          </div>
        </div> -->