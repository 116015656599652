import { Component, OnInit, Input,Output,EventEmitter,SimpleChanges, HostListener, ViewChildren,ViewChild, QueryList,ChangeDetectorRef,ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { InputDpgfComponent } from '../input-dpgf/input-dpgf.component'
import { UniteInputDpgfComponent } from '../unite-input-dpgf/unite-input-dpgf.component'
import { CctpEditionComponent } from '../../../all/cstb/cctp-edition/cctp-edition.component'
import { DceSwitchComponent } from '../dce-switch/dce-switch.component'
import { AuthService, DpgfPostesService,relatedFichesService, ProductsService, ArchitectService, AttributService, CeProjectInvitesService, CeProjectService } from 'src/app/_services';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgSharedModule } from 'src/app/all/ngshared.module';


import {ConfirmationService} from 'primeng/api';
import { DragulaService } from 'ng2-dragula'; 
import { Store , select} from '@ngrx/store';
import * as fromSelectors from '../_store';
import { ModeClass } from '../_store/mode'
import * as actions from '../_store/dpgf.actions';
import { CstbNavigationComponent } from '../../../all/cstb/cstb-navigation/cstb-navigation.component';

import { ArchiDbNavigationComponent } from '../archi-db-navigation/archi-db-navigation.component';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { unitsCodes } from 'src/app/architect/acv/acv-project-composant-sidebar/calcul-frigo';
import { WebView2Service } from 'src/app/_services/webview2.service.service';
import { bool } from 'aws-sdk/clients/signer';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { PillDpgfComponent } from '../pill-dpgf/pill-dpgf.component';
import { TableLotDpgfComponent } from '../table-lot-dpgf/table-lot-dpgf.component';
import { DpgfFoldersComponent } from '../dpgf-folders/dpgf-folders.component';
import { RightBarConsultAddEntrepriseComponent } from '../right-bar-consult-add-entreprise/right-bar-consult-add-entreprise.component';
import { DpgfImportLotPopupComponent } from '../dpgf-import-lot-popup/dpgf-import-lot-popup.component';
import { RevitToPostComponent } from '../revit-to-post/revit-to-post.component';
import { RevitProjectArborescenceComponent } from '../revit-project-arborescence/revit-project-arborescence.component';
import { ModalConsultEntrepriseComponent } from '../modal-consult-entreprise/modal-consult-entreprise.component';
import { NeedSubscriptionPopup2Component } from 'src/app/architect/need-subscription-popup2/need-subscription-popup2.component';
import { MaxLibraryPostsModalComponent } from 'src/app/architect/max-library-posts-modal/max-library-posts-modal.component';
import { FormatPrice } from 'src/app/_pipe/formatPrice.pipe';
import { DpgfModule } from '../../dpgf.module';
import { environment } from 'src/environments/environment';
import { ExcelImportComponent } from '../excel-import/excel-import.component';
// design system components
import { UserOnWithoutLicenseModalComponent } from 'src/app/_design_system_components/user-on-without-license-modal/user-on-without-license-modal.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


const _ = require('lodash');

declare var $;

@Component({
  standalone:true,
  selector: 'app-table-dpgf',
  imports:[
    DpgfModule, 
    DceSwitchComponent,
    SpinnerComponent,
    PillDpgfComponent,
    TableLotDpgfComponent,
    DpgfFoldersComponent,
    RightBarConsultAddEntrepriseComponent,
    DpgfImportLotPopupComponent,
    RevitToPostComponent,
    CctpEditionComponent,
    CstbNavigationComponent,
    ArchiDbNavigationComponent,
    RevitProjectArborescenceComponent,
    ModalConsultEntrepriseComponent,
    NeedSubscriptionPopup2Component,
    MaxLibraryPostsModalComponent,
    FormatPrice,
    NgSharedModule,
    ExcelImportComponent,
    UserOnWithoutLicenseModalComponent
  ],
  providers: [],
  templateUrl: './table-dpgf.component.html',
  styleUrls: ['./table-dpgf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableDpgfComponent implements OnInit {

  @Input()mode:string;
  @Input()isAutomateNumberIsChecked;
  @Input()isFactice;
  @Input()dpgf;
  @Input()open;
  @Input()openSpecificLot;
  @Input()triggerAddNewLot: boolean = false;
  @Input()canEdit:boolean = true;
  @Input()editingTitle:boolean;
  @Output()showAcceptModal:EventEmitter<any> = new EventEmitter<any>();
   
  public nmbrCheckedAuth;currentTab:string='CSTB'
  @Output()showReductionView:EventEmitter<any> = new EventEmitter<any>();
  @Output()lotAdded:EventEmitter<any> = new EventEmitter<any>();
  
  
  public modify: boolean=false; 
  public sideBarIsExpanded: boolean = false;
  public allTags: Array<Object> =[];
  public postesRevitElements:any ={};
  public tab:string='';
  public addElement:boolean=false;
  public maxLibraryPostsModalshowLibraryText: boolean = false;
  private subscriptions: Array<Subscription> = [];
  public artisanForm: UntypedFormGroup;budgetForm : UntypedFormGroup;
  public loadingLot$;lot$;total$;totalTTC$;nbLine$;addnewLot$;selectedPosts$;
  public maxChanges = 2;creatingTags:Boolean = false;
  public selectedPosteCstb = [];
  public displaySide:boolean = false;lotSelected=[];idsSelected:Array<Subscription>=[];
  public displaySideCstb:boolean = false;budget:Number = 0;dateProject:Date;
  public displaySideDb:boolean = false;sendChiffrageButton:boolean=false;
  public nbChanges = 0;
  public currentLot = 0;isDuplicatedLot:boolean=false;selectedLotLength:number=0;
  public cstbSousloti = 0;relationsList;pageName = 'first';entrepriseChosen;
  public currentSousLot = 0;etatBare=false;
  public selectedSearchTags =[];
  public currentPoste = 0;selectedSearchLots=[];artisanSearchField="";
  public currentColumn = 0;cstbToogle:boolean=true;biblioToggle=[];
  public columns = ["N°","Désignation","Localisation","Unité","Qte","TVA"]
  public vue = {};
  public view = "dpgf";
  public searchTerm='';
  public results=[];resultsBiblio = [];
  public collabs=[];
  public currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
  public modeList = new ModeClass().modeList;
  public archiDbSousloti: any;
  public selectedPostsCstb=[];
  public selectedLotIcon: any;buttonSendRequest:Boolean= false;addNewArtisanLoading:Boolean = false;
  public currentLotId: any;
  public selectedProduct: any;
  public choosenPost: any;
  public icons=["amenagements_exterieurs__espaces_verts.png","ascenseurs.png","assainissement__recuperationtraitement_des_eaux.png","generalites.png","carrelage__faience.png","charpente.png","chauffage__ventilation__climatisation_cvc.png","cloisons__doublages__fauxplafonds.png","couverture__etancheite.png","cvc.png","demolition__depose.png","desamiantage.png","electricite.png","energies_renouvelables.png","equipements_techniques.png","facades.png","gaz.png","maconnerie__grosuvre.png","menuiserie_exterieure.png","menuiserie_interieure__agencement.png","miroiterie__vitrerie.png","mobilier.png","ossature_boismetallique.png","peinture__revetements_muraux__nettoyage.png","piscine.png","plomberie__sanitaire.png","revetement_de_sol.png","securite_incendie.png","serrurerie__metallerie.png","signaletique.png","stores__fermetures__occultations.png","terrassement__soutenements__infrastructures.png","voirie_et_reseaux_divers.png","Aglo-Appareillage_installation_reseaux.svg","Aglo-Approche_simplifiee.svg","Aglo-Eclairage.svg","Aglo-Energie.svg","Aglo-Equipements_genie_climatique.svg","Aglo-Fils_cables.svg","Aglo-Fluides_frigorigenes.svg","Aglo-Panneau_sandwich_agroalimentaire.svg","Aglo-Production_locale_energie.svg","Aglo-Traitement_dechets.svg"];
  public frirogeneResult;
  public selectedFriroi;
  public selectedFriro;
  public addingPostInies;
  public infoPost;
  public postInfoTab='resume';
  public postIdInies="";
  public demandesSugg=[];totalRelations;
  public postName='';addEntrepriseLoading:Boolean = false;cssColDependindOfUserTypeConnected:string="col-md-9";
  public currentContributor='Composants';
  public demandeData={id:"",terms:false,nom:"",prenom:"",metier:"",mail:"",telephone:"",entreprise:"",nature:"Creation",id_demandeur:parseInt(this.currentUser['idDemandeur']||''),quantity:1,dve:"",unity:"",poste:this.postIdInies,postName:this.postName,content:{
    creationArgument:"",
    creationIdentification:"",
    creationSource:"",
    creationUf:"",
    creationLabel:"",
    creationRank:{
      creationRankId:1,
      creationIsNewRank:false,
      creationRankName:"",
      creationRankParent:1
    }
  }}
  public iniesCategories=[{title:"Voirie / réseaux divers (y compris réseaux intérieurs) et aménagements extérieurs de la parcelle",icon:'voirie_et_reseaux_divers.png',id:1},
  {title:"Strucure / maçonnerie / gros oeuvre / charpente divers",icon:'charpente.png',id:2},
  {title:"Façades",icon:'facades.png',id:3},
  {title:"Couverture / étanchéité",icon:'couverture__etancheite.png',id:4},
  {title:"Menuiseries intérieures et extérieures / fermetures",icon:'menuiserie_exterieure.png',id:5},
  {title:"Isolation",icon:'maconnerie__grosuvre.png',id:6},
  {title:"Cloisonnement / plafonds-suspendus",icon:'cloisons__doublages__fauxplafonds.png',id:7},
  {title:"Revêtements des sols et murs / peintures / produits de décorations",icon:'revetement_de_sol.png',id:8},
  {title:"Produits de préparation et de mise en oeuvre",icon:'demolition__depose.png',id:9},
  {title:"Equipements sanitaires et salle d'eau",icon:'plomberie__sanitaire.png',id:446},
  {title:"Panneau sandwich agroalimentaire",icon:"Aglo-Panneau_sandwich_agroalimentaire.svg",id:860},
  {title:"Approche simplifiée",icon:"Aglo-Approche_simplifiee.svg",id:822},
  {title:"Appareillage d'installation pour les réseaux d'énergie électrique et de communication (≤ 63 Ampères)",icon:"Aglo-Appareillage_installation_reseaux.svg",id:480},
  {title:"Fils et câbles",icon:"Aglo-Fils_cables.svg",id:481},
  {title:"Sécurité des personnes et contrôle d'accès",icon:'signaletique.png',id:482},
  {title:"Sécurité du bâtiment",icon:'securite_incendie.png',id:483},
  {title:"Equipements de génie climatique",icon:"Aglo-Equipements_genie_climatique.svg",id:484},
  {title:"Production locale d'énergie",icon:"Aglo-Production_locale_energie.svg",id:485},
  {title:"Matériel d'éclairage",icon:"Aglo-Eclairage.svg",id:773},
  {title:"Approche simplifiée",icon:"Aglo-Approche_simplifiee.svg",id:823},
  {title:"Eaux",icon:'assainissement__recuperationtraitement_des_eaux.png',id:828},
  {title:"Traitement des déchets",icon:"Aglo-Traitement_dechets.svg",id:849},
  {title:"Transports",icon:"transport.svg",id:852},
  {title:"Energies",icon:"Aglo-Energie.svg",id:831},
  {title:"Autre",icon:'generalites.png',id:0},
  ]
  public unitsCodes=unitsCodes;messageBody:String;currentIndexLot:Number = 0;
  public xmlFileToUpload: File | null = null;projectLicence;
  loadingDemand: boolean;

  nameChosenPost: any;
  public lot;lots;AgloOrCarnet:String='agloBooster';
  detailModalFromSideBar: boolean;
  public showDpgfTable: boolean = true;
  // public showDpgfSidebar: boolean = true;
  sommaireDisplay: boolean=true;
  innerWidth: number;
  loti: any;
  public sizeDpgf$: Observable<number>;


  public tab1: string;
  public openAddElementToPost: boolean;
  public displayRevitSide: boolean=false;

  public openSlider: boolean=false;
  public isPostToRevitSliderOpen: boolean;
  public openProjectArborescence: boolean=false;
  public dataStructureOfProject: any[];
  public isVisible: boolean=false;selectedBiblioPosts=[];
  public revitAction:string='getdatastructureofcategories';
  public isRevitDesktop: boolean;
  public searchTermUpdate = new Subject<string>();
  public revitIcon:boolean;cstbAddButton:boolean = false;
  @ViewChild(CctpEditionComponent) cctpEdition: CctpEditionComponent;
  @ViewChild(DceSwitchComponent) dceSwitch: DceSwitchComponent;
  @ViewChild(CstbNavigationComponent) cstbNavigation: CstbNavigationComponent;
  @ViewChild(ArchiDbNavigationComponent) archiDbNavigation: ArchiDbNavigationComponent;
  @ViewChildren('inputs') inputChilds:QueryList<InputDpgfComponent>;
  @ViewChildren('unitInputs') unitInputChilds:QueryList<UniteInputDpgfComponent>;
  @ViewChildren('unitSousPostInputs') unitSousPostInputChilds:QueryList<UniteInputDpgfComponent>;

  @ViewChild('op') op: OverlayPanel;
 

  @HostListener('document:keydown', ['$event']) handleArrowKeyPress(event: KeyboardEvent) {
    if(document.activeElement){
      let selectedTag = document.activeElement.tagName;
      let selectedClass = document.activeElement.className;
      let fields = ["TEXTAREA","SPAN","INPUT"]
      if(fields.indexOf(selectedTag) == -1 && selectedClass != "ql-editor"){
        switch(event.which) {
          case 40://Down
          event.preventDefault()
          this.goDown()
          break;
          case 38://Up
          event.preventDefault()
          this.goUp()
          break;
          case 39://Rigth
          event.preventDefault()
          this.currentColumn = Math.min(this.currentColumn + 1,6);
          break;
          case 37://Left
          event.preventDefault()
          this.currentColumn = Math.max(this.currentColumn - 1,0);
          break;
          case 9://Tab
            event.preventDefault()
            this.currentColumn = Math.min(this.currentColumn + 1,5);
          break;
        }
      }
    }
  }
  ngOnInit() {
   this.isRevitDesktop = navigator.userAgent.includes("Autodesk.Revit");
    if(this.currentUser.archiId)this.dpgfPostesService.getArchiCollabs().subscribe(
      data=>{
        sessionStorage.setItem('sharedWith',JSON.stringify(_.get(data,'sharedWith',[])));
      })
    // this.cssColDependindOfUserTypeConnected = this.currentUser.archiId && !this.isFactice && !this.authService.currentEnvironment ? "col-md-8" : "col-md-9"
    this.cssColDependindOfUserTypeConnected = this.currentUser.archiId && !this.isFactice && !this.authService.currentEnvironment ? "col-md-9" : "col-md-9"
    this.loadingLot$ = this.store.pipe(select(fromSelectors.getAny , {name : "loadingLot"} ))
    this.addnewLot$ = this.store.pipe(select(fromSelectors.getAny , {name : "addnewLot"} ))
    this.selectedPosts$ = this.store.pipe(select(fromSelectors.getAny , {name : "selectedPosts"} ))
    this.lot$ = this.store.pipe(select(fromSelectors.getLot))
    this.lot = this.store.pipe(select(fromSelectors.getLot)).subscribe(
      (lot)=>{
        this.lot=lot;
      }
    )
    // listen for lot Load finished, this only done once for every lot,
    this.store.pipe(select(fromSelectors.getAny , {name : "loadingLot"} )).subscribe(loading => {
      // check if lot has cctpCount property, if not count all posts with cctp in them, then set cctpCount on the opened Lot
      // if(!loading && lotData && !lotHasnumberOfPostsWithCctpValue){
      if(!loading){
        this.updateLotCCtpCount();
      }
    });
    this.sizeDpgf$ = this.store.pipe(select(fromSelectors.getAny, {name : "sizeDPGF"}));
    this.total$ = this.store.pipe(select(fromSelectors.getTotal, { name: this.modeList[this.mode].totalName }))
    this.totalTTC$ = this.store.pipe(select(fromSelectors.getTotal, { name: this.modeList[this.mode].totalttcName }))
    //new (achraf)
    // this.nbLine$ = this.store.pipe(select(fromSelectors.getTotal, { name: "nbLines" }))
    this.nbLine$ = this.mode=='artisan'? this.store.pipe(select(fromSelectors.getNbLinesArtisan, {name : "nbLines"})):this.store.pipe(select(fromSelectors.getTotal, { name: "nbLines" }))
    
    this.store.pipe(select(fromSelectors.getAny, {name : "cctpEdit"})).subscribe((cctpEdit)=>{
      if(cctpEdit){
        this.postIdInies=cctpEdit.lotId+'|'+cctpEdit.souslotId+'|'+cctpEdit.posteId;
        this.postName=cctpEdit.postDesignation;
        // if cctp added to post, update the lot numberOfPostsWithCctp & percentageOfPostsWithCctp values
      } 
    })
    this.store.pipe(select(fromSelectors.getAny,{name:'projectLicence'})).subscribe((data)=>{
      this.projectLicence = data;
    })
    this.searchEnterprises();
    this.allTags =this.attributService.getRelationTags();
    this.listenForProductChange();  
    this.createForm();  
    this.createFormChiffrage();  
    this.listenForsearchTerm()
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes["open"] && this.open.lotId) {
      this.getLot({toElement : {className : ""} },changes["open"].currentValue.lotId)
    }
    if(changes["openSpecificLot"]?.currentValue && changes["openSpecificLot"].firstChange){  
      this.openLotWithId(changes["openSpecificLot"].currentValue.lotId);
    }
    if(changes["triggerAddNewLot"] && changes["triggerAddNewLot"]?.currentValue){ // if input value is true
      this.addLot(true, true, this.dpgf.data.length);
    }
    // if(changes.openProjectArborescence.currentValue){ 
    //   this.wv2Service.postWebView2Message({action:"getdata",payload:[]})
    // }
  }
  private openLotWithId(lotId){    
    this.store.dispatch(actions.setLoadingDpgfLot(true));
    this.store.dispatch(actions.loadDpgfLot({lotId,dpgfId:this.dpgf._id}));
  }

  constructor(
              private dragulaService: DragulaService, 
              private messageService: MessageService,
              private cd: ChangeDetectorRef,
              private dpgfPostesService :DpgfPostesService,
              private store: Store,
              private ceProjectService: CeProjectService,
              private fb: UntypedFormBuilder,
              private ceProjectInvitesService: CeProjectInvitesService,
              private router: Router,
              private confirmationService: ConfirmationService,
              public authService: AuthService,
              private relatedFichesService :relatedFichesService,
              private attributService: AttributService,
              private architectService: ArchitectService,
              private productsService: ProductsService,
              private wv2Service: WebView2Service) {
    dragulaService.createGroup("SOMMAIRE", { 
      moves: (el, container, handle) => {
        return this.mode == "archi";
      }
    });
  }
  public createForm(){
    this.artisanForm = this.fb.group({
      emailAgency: ['',
      Validators.compose([
        Validators.required, Validators.email
      ])
      ],
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      tags: [[]],
      telAgency: '',
      demo: false
    });
  }
  public createFormChiffrage(){
    this.budgetForm = this.fb.group({
      budget: ['', Validators.required],
      date: '',
      messageBody: ''
    });
  }
  getDpgfTable(){
    return [];
  }
  public showDpgfTableFnction(value){
    // this.showDpgfSidebar=value;
    this.showReductionView.emit();
  }
  public sortLots(lots){
    if(this.isAutomateNumberIsChecked)return lots;
    return lots.sort((a,b) =>  a.indexLot - b.indexLot );
  }
  trackById(index,item){
    if(!item)return null;
    return item.id;
  }

  public getAllPostes(postesElements){
    this.postesRevitElements=postesElements;
    this.cd.detectChanges()
  }
  public openAddElementToPostmodal(value){
    this.openAddElementToPost=value;
  }

  public openRevitSide(value){
    this.displayRevitSide=value.displayRevitSide;
    $("body").css("overflow","hidden");
  }

  public closeRevitSide(event){
    this.displayRevitSide=false;
    this.tab=event.tab;
    //this.addElement=event.addElement;
    this.store.dispatch(actions.setCctpOnDpgf({}));
    $("body").css("overflow","scroll");
  }
  /************* Move ***************/
  public goDown(){
    let isNumber = (val)=>{return (typeof val == "number")}
    if(isNumber(this.currentLot) && isNumber(this.currentSousLot)){
      if(this.currentPoste+1 < this.dpgf.data[this.currentLot].sousLots[this.currentSousLot].postes.length){
        this.currentPoste ++;
      }
    }
  }
  public goUp(){
    let isNumber = (val)=>{return (typeof val == "number")}
    if(isNumber(this.currentLot) && isNumber(this.currentSousLot)){
      if(this.currentPoste-1 >= 0){
        this.currentPoste --;
      }
    }
  }
  /************* Lot ***************/
  public addLot(editingTitle,modify,indexLot){
    let licence=this.authService.licence;
    if(licence!='without' || this.projectLicence!='none') {
      this.modify=modify;
      this.editingTitle=editingTitle;
      this.store.dispatch(actions.addnewLot({indexLot:indexLot}));
      if(!environment.onPremise && !this.triggerAddNewLot)window.analytics.track('create_lot_sommaire',{properties:{acValue: 'create_lot_sommaire'}});
      if(!environment.onPremise && this.triggerAddNewLot){
        window.analytics.track('create_lot',{properties:{acValue: 'create_lot'}});
        this.lotAdded.emit();
      }
    }
    // else $('#upgrade-modal').modal('show');
    else $("#user-on-without-license-modal").modal('show');
  }
  public redirectToPricingTable(){
    $('#upgrade-modal').modal('hide');
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
  }
  public importLot(){
    let dpgfMaxPosts = (this.authService.premuimFeatures[this.authService.licence]||{}).lineNb;
    let licence=this.authService.licence;
    if(licence == 'without' && this.projectLicence=='none'){
      $('#user-on-without-license-modal').modal('show');
    }else if(['plus','plus-trim','plus-annuel'].includes(licence) && this.dpgf.nbLine >= dpgfMaxPosts && this.projectLicence=='none'){
      this.maxLibraryPostsModalshowLibraryText = false;
      $('#max-posts-modal').modal('show');
    }else{
      $('#import-lot').modal('show');
    }
  }
  public addSelectedLots(lots){
    let clonedLots = JSON.parse(JSON.stringify(lots));
    this.selectedLotLength = clonedLots.lot.length;
    this.store.dispatch(actions.addLot({...clonedLots, isAutomatic: this.isAutomateNumberIsChecked,dpgfDataLength:this.dpgf.data.length}));
    this.addnewLot$.subscribe((val)=>{
      if(!val&& this.selectedLotLength){
        let textToShow = this.selectedLotLength>1?'Les lots ont bien été importés':'Le lot a bien été importé';
        this.messageService.add({ key: 'toast', severity: 'success', summary: "Import de lot", detail: textToShow, life: 5000 });
        this.selectedLotLength=0;
      }
    });
    // show new lots list view
    this.showDpgfTableFnction("");
  }
  public updateTab(tab){
    this.currentTab = tab;
  }
  public changeLotOrder(event){
    /// if the "Numérotation automatique" is checked don't reorder the lots
    if(!this.isAutomateNumberIsChecked) return;
    
    let licence=this.authService.licence;
    if(licence!='without' || this.projectLicence!='none'){
      let lotsData = [];
      for(let i = 0; i < event.length; i++){
        let lot = event[i];
        lotsData.push({lotId: lot.id, indexLot: lot.indexLot});
      }
      this.store.dispatch(actions.changeOrderLot(lotsData));
    }
    else $('#upgrade-modal').modal('show');
  }
  public getLot(event, lotId,indexLot?){
    this.modify=false;
    var focused = document.activeElement;
    $(focused).blur();
    this.selectedLotIcon=lotId;
    if(event.target.id=="lot-icon" || event.target.id=="lot-icon-arrow"){
      if(this.currentLotId==this.selectedLotIcon){
        this.op.toggle(event);
        return;
      }
      else this.op.toggle(event);
    }
    if(!this.dpgf.gotoNextVersion && this.dpgf.status != "finished" && this.mode!='view'){
      this.store.dispatch(actions.saveDpgfLotToServer({}));
    }
    if(  (((event||{}).toElement||{}).className || "").indexOf("no-open") == -1){
      if(this.view == "files"){
        this.view = "dpgf";
        if(this.dceSwitch){
          this.dceSwitch.value = "dpgf";
        }
      }
      this.store.dispatch(actions.setLoadingDpgfLot(true));
      this.scrollTo("top-lot");
      this.store.dispatch(actions.loadDpgfLot({lotId,dpgfId:this.dpgf._id}));
    }
    this.currentLotId=lotId;
    this.currentIndexLot = indexLot;
  }
  /********Delete**********/
  public deleteLot(lotId){
    let licence=this.authService.licence;
    if(licence!='without' || this.projectLicence!='none'){
      this.confirmationService.confirm({
           message: 'Êtes vous sur de vouloir supprimer ce lot ?',
           header: 'Supprimer',
           acceptLabel : "Supprimer",
           rejectLabel : "Annuler",
           accept: () => {
            this.store.dispatch(actions.removeLot({lotId : lotId, isAutomatic: this.isAutomateNumberIsChecked}));
            if(!environment.onPremise)window.analytics.track('delete_lot_sommaire',{properties:{acValue: 'delete_lot_sommaire'}});
            if(this.isAutomateNumberIsChecked){         
              setTimeout(() => {
              //Good (Single Api to update all lots)
              this.updateAllLotsIndex();
              this.showDpgfTableFnction("");
              }, 1000);
            }
           }
       });
    }
    else $('#upgrade-modal').modal('show');
  }
  private updateAllLotsIndex(){
    let dpgfId = this.dpgf._id;
    this.subscriptions.push(
      this.dpgfPostesService.updateDpgfLotsIndex(dpgfId).subscribe(
        data => {
          // console.log("updateAllLotsIndex : ", data);
        },
        err => {
          console.log("updateAllLotsIndex : ", err);
        }
      )
    );
  }
  public dupliquateLot(lot){
    let dpgfMaxPosts = (this.authService.premuimFeatures[this.authService.licence]||{}).lineNb;
    let licence=this.authService.licence;

    if(licence == 'without' && this.projectLicence=='none'){
      $('#upgrade-modal').modal('show');
    }else if(['plus','plus-trim','plus-annuel'].includes(licence) && this.dpgf.nbLine >= dpgfMaxPosts && this.projectLicence!='none'){
      this.maxLibraryPostsModalshowLibraryText = false;
      $('#max-posts-modal').modal('show');
    }else{
      this.isDuplicatedLot = true;
      let newLot = JSON.parse(JSON.stringify(lot))
      this.store.dispatch(actions.addLot({lot :[newLot] ,dpgfId : this.dpgf._id, isAutomatic: this.isAutomateNumberIsChecked,dpgfDataLength:this.dpgf.data.length}));     
      this.addnewLot$.subscribe((val)=>{
        if(!val&&this.isDuplicatedLot){
          this.messageService.add({ key: 'toast', severity: 'success', summary: "Duplication de lot", detail: "Votre lot a bien été dupliqué", life: 5000 });
          this.isDuplicatedLot=false;
        }
      });
      // this.store.dispatch(actions.loadDpgf ({dpgfId :  this.dpgf._id}));

      if(!environment.onPremise)window.analytics.track('duplicate_lot_sommaire',{properties:{acValue: 'duplicate_lot_sommaire'}});
    }
  }
  //*******ChangeView********/
  public changeView(view){
    let post = this.getFirstPost();
    this.view = view;
    if(post){
      if($(post.id).offset()){
        setTimeout(()=>{
          try{
            window.scrollTo(0,$(post.id).offset().top - post.top )
          }catch(err){}
        },100)
      }
    }
  }
  public getFirstPost(){
    var $window = $(window);
    var scrollTop = $window.scrollTop();
    var postes = $(".post-line");
    for(var i =0 ; i<postes.length;i++){
      if($(postes[i]).offset().top > scrollTop + 70){
        return {id : "#"+$(postes[i]).attr('id') , top : ($(postes[i]).offset().top-scrollTop) }
      }
    }
  }
  public scrollTo(element) {
    $('html, body').animate({
      scrollTop: ($("#" +element).offset().top - 60)
    });
  }
  public openSide(event){
    this.displaySide=true;
    this.cctpEdition.value = event.value;
    this.cctpEdition.onSelectionChangeTab(event.tab,event);
    this.cctpEdition.fromCstb = event.fromCstb;
    $("body").css("overflow","hidden");
  }

  public closeSide(){
    this.displaySide=false;
    this.tab1='revit_postes';
    this.store.dispatch(actions.setCctpOnDpgf({}));
    $("body").css("overflow","scroll");


    // increase lot "" count if cctp added, or decrease if removed
    let cctpEdit = this.cctpEdition.cctpEdit;
    let postCctpChanged = cctpEdit.poste; // this returns true or false if the post cctp this returns true

    // cctp 
    if(postCctpChanged){
      this.updateLotCCtpCount();
    }
  }
  public closeSideCstb(){
    // this.displaySideCstb=false;
    this.displaySideDb=false;

    $("body").css("overflow","scroll");
  }
  public closeSideDb(){
    this.displaySideDb=false;
    $("body").css("overflow","scroll");
  }
  public listenForsearchTerm(){
    this.searchTermUpdate.pipe(
      debounceTime(600),
      distinctUntilChanged()
    ).subscribe(value => {
      this.searchPost();
    })
  }
  public openSideCstb(sousloti){
    this.displaySideCstb=true;
    this.cstbSousloti = sousloti;
    $("body").css("overflow","hidden");
  }
  public openSideArchiDb(sousloti){
    this.displaySideDb=true;
    this.cstbSousloti = sousloti;
    this.archiDbSousloti = sousloti;
    $("body").css("overflow","hidden");
  }
  /********Calculate**********/
  public calculateAllNoEmit(){
    this.store.dispatch(actions.calculate({modeList : this.modeList , mode : this.mode}));
  }
  public calculateLines(){
    this.store.dispatch(actions.nbLine());
  }
  public calculateAll(){
    this.calculateAllNoEmit();
  }
  /********CSTB**********/
  public selectPosteCstb(postes){
    
    this.selectedPosteCstb = postes;
  }
  public showButton(event){
    this.cstbAddButton = event;
  }
  // public showButtonBiblo(event){
  //   console.log('event',event)
  //   this.cstbAddButton = event;
  // }
  public selectBiblio(postes){
    this.selectedPosteCstb = postes;
  }
  public selectedPost(post){
    let posti={gid:post.gid,title:post.sugg}
    this.selectedPosteCstb.push(posti);
  }
  public selectedCstbPost(post){
    let posti={gid:post.gid,title:post.sugg}
    let index = this.selectedPosteCstb.findIndex(val=>val.gid==post.gid)
    if(index!=-1){
      this.selectedPosteCstb.splice(index,1)
    }else{
      this.selectedPosteCstb.push(posti);
    }   
    if(!environment.onPremise)window.analytics.track('post_from_cstb',{properties:{acValue: 'post_from_cstb'}});
  }
  public selectedBiblioPost(post){
    let posti={gid:post.gid,title:post.title}
    let index = this.selectedPosteCstb.findIndex(val=>val.gid==post.gid)
    if(index!=-1){
      this.selectedPosteCstb.splice(index,1)
    }else{
      this.selectedPosteCstb.push(posti);
    }
    if(!environment.onPremise)window.analytics.track('post_from_db',{properties:{acValue: 'post_from_db'}});
  }
  public selectedPostsBiblio(posts){
    this.selectedBiblioPosts = posts
  }
  public importCstbPostes(){
    this.store.dispatch(actions.addManyPostesCstb({ postes :this.selectedPosteCstb , sousloti : this.cstbSousloti}));
    this.selectedPosteCstb=[];
    this.searchTerm='';
    this.cstbNavigation.goBack();
    this.closeSideCstb();

    // recount nbLines
    this.calculateLines(); 

    if(!environment.onPremise)window.analytics.track('post_from_cstb',{properties:{acValue: 'post_from_cstb'}});
  }
  public importArchiDbPostes(){
    this.store.dispatch(actions.emptyTypeAmount());
    this.archiDbNavigation.importPostes();
    this.calculateAll()
    this.closeSideDb();
    // if(!environment.onPremise)window.analytics.track('add_post_from_user_db',{properties:{acValue: 'add_post_from_user_db'}});
    // hide overlay bg
    $(".p-component-overlay").css("display","none");
  }
  public toogleCstb(){
    this.cstbToogle = !this.cstbToogle;
  }
  public toogleBiblio(index){
    this.biblioToggle[index]=!this.biblioToggle[index];
  }
  public searchPost(){
    if(this.searchTerm.trim().length>0)
    this.dpgfPostesService.searchElasticSearchPostsCSTB(this.searchTerm,13)
            .subscribe(res => {
              this.results=res.cstb;
              if((res.userPost[0]||[])._id!=this.currentUser.archiId){
                let index = res.userPost.findIndex(val=>val._id==this.currentUser.archiId);
                if (index !== -1) {
                  const item = res.userPost.splice(index, 1)[0]; // Remove the item at the found index
                  res.userPost.unshift(item); // Add the item back to the beginning of the array
                }            
                }
                res.userPost.forEach((val,index)=>this.biblioToggle[index]=true)
                this.resultsBiblio = res.userPost;
              this.cd.markForCheck();
            })
  }
  public emptySearchTerm(){
    this.searchTerm = '';
    this.results = [];
    this.resultsBiblio = [];
  }
  public setIcon(icon){
    this.store.dispatch(actions.updateLot({lotId : this.selectedLotIcon , data : {icon:icon}}));
  }
  // inies
  selectFriro(i){
    this.selectedFriroi=i;
  }
  validateFriro(){
    this.selectedFriro=this.frirogeneResult[this.selectedFriroi];
  }
  onSelectionChangeTab(tab){
    this.postInfoTab=tab;
  }
  setInfoPost(post){
    this.infoPost=post;
    this.postInfoTab='resume'
    this.detailModalFromSideBar=true
  }
  expandDetail(event){
    let parent = $(event.target).closest('.single-detail');
    let clickedItem = parent.find('.detail-description');
    let openArrow = parent.find('.open-arrow');
    let closeArrow = parent.find('.close-arrow');

    $('.close-arrow').addClass('hidden-item');
    $('.open-arrow').removeClass('hidden-item');

    if(clickedItem.hasClass('expand')){
      clickedItem.removeClass('expand');
    }else{
      $('.detail-description').removeClass('expand');
      clickedItem.addClass('expand');
      openArrow.addClass('hidden-item');
      closeArrow.removeClass('hidden-item');
    }
  }
  addFileToPost(){
    this.cctpEdition.addFileToPost({},true);
  }
  setaddingPostInies(file){
    this.addingPostInies=file.addingPost;
    this.nameChosenPost=file.name;
    if(!file.addingPost)$('#add-post-modal').modal('hide');
  }
  demandePost(type){
    this.demandeData={id:"",terms:false,nom:"",prenom:"",metier:"",mail:"",telephone:"",entreprise:"",nature:"Creation",id_demandeur:parseInt(this.currentUser['idDemandeur']||''),quantity:1,dve:"",unity:"",poste:this.postIdInies,postName:this.postName,content:{
      creationArgument:"",
      creationIdentification:"",
      creationSource:"",
      creationUf:"",
      creationLabel:"",
      creationRank:{
        creationRankId:1,
        creationIsNewRank:false,
        creationRankName:"",
        creationRankParent:1
      }
    }}
    this.demandeData.nature=type
    $('#add-demand-modal').modal('show');
  }
  openModalChoice(id){
    if(id=='Reemploi') this.demandePost(id)
    else $(id).modal('show');
  }
  openConfirmationPost(post){
    this.cctpEdition.openConfirmationPost(post);
    $('#post-info-modal').modal('hide');
  }
  submitDemande(){
    this.loadingDemand=true;
    this.demandeData.poste=this.postIdInies;
    this.demandeData.postName=this.postName;
    this.relatedFichesService.sendDemande(this.demandeData).subscribe(
      (data)=>{
        if(this.demandeData.nature=="Creation"){
          this.currentUser['idDemandeur']=data['idDemandeur'];
          localStorage.setItem('currentUser',JSON.stringify(this.currentUser))  
          this.messageService.add({ key: 'toast', severity: 'success', summary: "Demande envoyée", detail: "Votre demande de création de DED a bien été transmise aux services INIES, un mail récapitulatif vous a été envoyé", life: 5000 });
        }
        let post={
          idBase : 0,
          idFicheBase : 25765,
          postName:this.postName,
          serialIdentifier : "INIES_ICOM20210120_120948",
          nom : this.demandeData.content.creationLabel,
          uniteUF : this.demandeData.unity,
          quantity : this.demandeData.quantity,
          dve : this.demandeData.dve,
          typeDonnees : 8,
          poste:this.postIdInies,
          // commentaire:this.demandeData.id,
          commentaire:(data['data']||{}).idDemand || this.demandeData.id,
          contributor:this.currentContributor,
        }
        this.cctpEdition.addFileToPost(post);
        this.messageService.add({ key: 'toast', severity: 'success', summary: "Mise à jour Demande", detail: "La demande a été mis à jour", life: 5000 });// else 
        this.loadingDemand=false;
        $('#add-demand-modal').modal('hide');
        this.cd.detectChanges();
      },
      (err)=>{
        this.loadingDemand=false;
        this.messageService.add({ key: 'toast', severity: 'error', summary: "Traiter Demande", detail: "Echec", life: 5000 });
      }
    )
  }
  addPostExistDemand(){
    this.demandeData.poste=this.postIdInies;
    this.demandeData.postName=this.postName;
    let post={
      idBase : 0,
      idFicheBase : 25765,
      postName:this.postName,
      serialIdentifier : "INIES_ICOM20210120_120948",
      nom : this.demandeData.content.creationLabel,
      uniteUF : this.demandeData.unity,
      quantity : this.demandeData.quantity,
      dve : this.demandeData.dve,
      typeDonnees : 8,
      poste:this.postIdInies,
      commentaire:this.demandeData.id,
      contributor:this.currentContributor,
    }
    this.cctpEdition.addFileToPost(post);
    $('#add-demand-modal').modal('hide');
  }
  getDemendSugg(){
    this.relatedFichesService.getDemandSugg().subscribe(
      (data)=>{
        this.demandesSugg=data;
        $('.autocomplete-items-demand').css({'visibility': 'visible'});
        this.cd.detectChanges()
      }
    )
  }
  setChosenDemand(result){
    this.demandeData.id=result.idDemand;
    this.demandeData.dve=result.dve;
    this.demandeData.quantity=result.quantity;
    this.demandeData.unity=result.unit;
    this.demandeData.content.creationLabel=result.name;
    $('.autocomplete-items-demand').css({'visibility': 'hidden'});
  }
  addPostIniesReemploi(){
    let post={
      idBase : 0,
      idFicheBase : 27546,
      postName:this.postName,
      serialIdentifier : "INIES_ICOM20211011_160700",
      nom : this.demandeData.content.creationLabel,
      uniteUF : this.demandeData.unity,
      quantity : this.demandeData.quantity,
      dve : this.demandeData.dve,
      typeDonnees : 8,
      poste:this.postIdInies,
      contributor:this.currentContributor,
    }
    this.cctpEdition.addFileToPost(post);
    $('#add-demand-modal').modal('hide');
  }
  importXml(event){
    // https://services.comenv-cstb.eu/api/importFicheConfigure
    if (event.target.files.length > 0) {
      this.xmlFileToUpload = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (evt) => {
          const xmlData: string = (evt as any).target.result;
      };
      let wa=reader.readAsText(this.xmlFileToUpload);
      this.relatedFichesService.checkXml(this.xmlFileToUpload).subscribe(
        (data)=>{
        }
      )
    }
  }
  //
  public listenForProductChange(){
    this.subscriptions.push(
      this.productsService.selectedScrappedProduct
        .subscribe(product => {
          this.selectedProduct = product;
        })
    );
    this.subscriptions.push(
      this.productsService.selectedPost
        .subscribe(post => {
          this.choosenPost = post;
        })
    );

  }
  public saveProduct(){
    this.cctpEdition.saveProduct();
  }
  public openProductUrl(){
    window.open(this.selectedProduct.url, '_blank').focus();
  }
  public removeProduct(){
    this.cctpEdition.removeProduct();
  }
  public agloBossterOrCarnetAdress(type:String){
    this.AgloOrCarnet = type;
  }
  public searchEnterprises(){
    let body ={
      searchQuery: this.artisanSearchField,
      tags: this.selectedSearchTags
    }
    this.subscriptions.push(
      this.architectService.searchEntreprise(body, 1)
      .subscribe(
        data => {
          this.relationsList = data.data.relations;
          this.totalRelations = data.metadata.count;
        this.cd.detectChanges();
        },
        err => {
          // this.ErrorService.manageError(err,"");
          console.log('error',err)
        }
      )
    );
  }
  tagSelected(){
    this.searchEnterprises();
  }
  public createNewArtisan(){
    this.creatingTags =true;
    this.addNewArtisanLoading = true;
    this.architectService.addCarnetAdressesArtisan(this.artisanForm.value)
    .subscribe(data => {
      this.artisanForm.reset();
      this.idsSelected = [];
      this.lotSelected = [];
      this.addNewArtisanLoading = false;
      this.pageName='first';
      this.creatingTags =false;
      this.entrepriseChosen = data;
      this.searchEnterprises();
      this.messageService.add({ key: 'toast', severity: 'success', summary: "Ajout d'une entreprise", detail: "L'entreprise a été invité dans votre réseau", life: 5000 });
      // segment event
      // if(!environment.onPremise)window.analytics.track('add_contact',{properties:{acValue: 'add_contact'}});
    },
    err => {
      this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur d'ajout", detail: "Erreur serveur", life: 5000 });
    }
    );
  }
  addLotToSend(lot){
    if(this.idsSelected.includes(lot.id)){
      this.lotSelected=this.lotSelected.filter((thisLot)=>thisLot==lot.id);
      this.idsSelected=this.idsSelected.filter((ids)=> ids!=lot.id)
    }else{
      this.lotSelected.push(lot);
      this.idsSelected.push(lot.id)
    }
    this.sendChiffrageButton = this.idsSelected.length>0 ? true : false;
  }
  public chooseEntreprise(entreprise){
    this.entrepriseChosen = entreprise;
    this.idsSelected = [];
    this.lotSelected = [];
    this.pageName = 'send-lot-to-artisan';
  }
  public addEntreprise(){
    let body = {
      artisanId :this.entrepriseChosen.artisanId || this.entrepriseChosen.artisanOwnedId,
      lots:this.lotSelected
    }
    if(body.artisanId){
      this.addEntrepriseLoading = true;
      this.subscriptions.push(
        this.ceProjectInvitesService.createNewArtisanByArchi(body, this.dpgf.ceProject)
        .subscribe(() => {
          $('#consult-booster').modal('hide');
          this.pageName = 'first';
          this.addEntrepriseLoading = false;
        }
        ,
        err => {
          console.log('err',err);
        })
        )
  }}
  public sendRequest(){
    this.buttonSendRequest = true;
    let requestedArtisans = [];
    this.lotSelected.forEach(singleLot => {
      let item = {id: singleLot.id, name: singleLot.title}
      requestedArtisans.push(item)
    });
    this.subscriptions.push(
      this.ceProjectService.requestArtisan(this.dpgf.ceProject,{requestArtisans : requestedArtisans, messageBody : this.budgetForm.value.messageBody,budget:this.budgetForm.value.budget,date:this.budgetForm.value.date})
      .subscribe((res) => {
        // segment
        this.buttonSendRequest = false;
        $('#consult-booster').modal('hide');
      },
      err => {
        console.log('err',err);
      }
      )
    )
  }
  private updateLotCCtpCount(){
    // only call on architect side
    if(this.mode != "archi") return;

    const { id, sousLots } = this.lot;
    let numberOfPostsWithCctp = 0;
    let totalNumberOfPosts = 0;
    
    for(let i = 0; i < sousLots.length; i++){
      let currentSousLot = sousLots[i];
      const { postes } = currentSousLot;
      // 
      totalNumberOfPosts += postes.length;

      for(let j = 0; j < postes.length; j++){
        let currentPost = postes[j];
        const { cctp } = currentPost;
        if(cctp && cctp != ""){
          numberOfPostsWithCctp++;
        }
      }
    }

    // total number of posts with cctp / total number of posts
    let percentageOfPostsWithCctp = (numberOfPostsWithCctp / totalNumberOfPosts) * 100;
    percentageOfPostsWithCctp = Math.round(percentageOfPostsWithCctp);

    // update lot    
    this.store.dispatch(actions.updateLot({lotId : id , data : {numberOfPostsWithCctp, percentageOfPostsWithCctp} }));
  }
  
  public goToPage(page){
    this.pageName = page;
  }
  public getStateSideBar(event){
    this.sideBarIsExpanded = event.sideBarIsExpanded
  }
  public emitShowAcceptModal(){
    this.showAcceptModal.emit({});
  }
  public openRevitToSlider(value){
    this.openSlider=value;
  }
  public changeSliderPostToRevitValue(value:boolean){
    this.isPostToRevitSliderOpen=value;
  }
  public openRevitOrbarescenceProject=(event)=>{
    this.wv2Service.postWebView2Message({action:"getdata",payload:[]})
    this.openProjectArborescence =true;
  }
  // public  subscribeElementRevitOnClick(){
  //   this.dataStructureOfProject=[];
  //   this.wv2Service.subscribeToWebView2Event("getdatastructureofcategories", (e) => {
  //     let dataStructureOfProject = [...e.detail];
  //     this.dataStructureOfProject= dataStructureOfProject;
  //     if(this.dataStructureOfProject.length>0) return;
  //   });
  //   this.cd.detectChanges();
  // }
  
  public changeOpenProjectArborescenceValue(value){
    this.openProjectArborescence=value;
  }
  public ngOnDestroy(): void {
      this.dragulaService.destroy("SOMMAIRE"); 
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }
}
