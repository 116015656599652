import { Injectable } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { AuthService } from './auth.service';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorService {

  constructor(private router: Router,
              public authService: AuthService ,
              private route: ActivatedRoute
              // public messageService: MessageService tocorrect
              ) { }

  public manageError(error,route){
    let currentRoute = window.location.pathname;
    var currentUser = JSON.parse(localStorage.getItem('currentUser') || "{}") || {};
    let err = {message : ""} //JSON.parse(error._body)
    
    if(error.status == 405){}
    else if(error.status == 409){}
    else if(error.status == 0){
      // this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur", detail: "Problème de connexion Internet", life: 5000 }); tocorrect
    }else if(error.error.message == "Only demo"){
      // tocorrect see with Louis if we must use it (it was commented)
      // this._notificationService.error('Projet demo',"Disponible en lecture seule." , {timeOut: 10000})
    }
    else if(error.status == 401){
      this.authService.logout()
      if(!environment.onPremise)this.router.navigate(['login'], { queryParams: { infoBubble: "login-required" }});
      else{
        const currentRoute = this.router.url;
        window.location.href=`api/v1/login/openid/?state=${currentRoute}`;
        window.open();
      }
    }else if (error.status > 499 && error.status < 600 ){
      // this.router.navigate(['/error'],{ queryParams: { returnUrl: currentRoute}});
    }else{
      let body:any = {};
      try{
        body = JSON.parse(error._body||"{}")
      }catch(err){};
      let message = ((body.err||{}).message || "")
      // this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur", detail: "Statut " + error.status + " " + message, life: 5000 }); tocorrect
    }
  }

}
