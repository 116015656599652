import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { IdentifySegement } from 'src/app/all/segment/identify';
import { ArchitectService, AuthService, PaymentsService } from 'src/app/_services';
import { environment } from '../../../environments/environment';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $:any;
declare var Stripe: any;

@Component({
    standalone:true,
    selector: 'app-architect-users',
    imports:[NgSharedModule],
    templateUrl: './architect-users.component.html',
    styleUrls: ['./architect-users.component.scss'],
})
export class ArchitectUsersComponent implements OnInit {

  private archiData;
  public password;passwordConfirmation;
  public user;
  public archiId;
  public userForm: UntypedFormGroup;
  public loadingUsers=true;loadingSubs=true;creatingUser=false;mailExist=false;isAdminArchitect;
  public quantity;plan;
  public posSubMonth=-1;posSubYear=-1;posSubAdmin=-1;
  public loadinglicence:Boolean = false;
  public licences={
    plus : "AGLO PRO Mensuel",
		premium : "AGLO Intégral Mensuel",
		base : "AGLO Essentiel",
		"plus-annuel" : "AGLO PRO Annuel",
		"plus-trim" : "AGLO PRO Annuel",
		"premium-annuel" : "AGLO Intégral Annuel",
    "premiumn" : "AGLO Intégral mensuel",
    "premiumn-annuel" : "AGLO Intégral annuel",
    "premiumn-trim" : "AGLO Intégral annuel",
    "max" : "AGLO Max-mensuel",
    "max-trim" : "AGLO Max annuel",
    "max-annuel" : "AGLO Max annuel",
  };
  public stripe 
  @Input() subscriptions;
  @Input() users;
  @Output() getSubsEvent = new EventEmitter<string>();
  @Output() getUsersEvent = new EventEmitter<string>();
  public deletingUser: boolean=false;
  public warningMessage: any;
  public warningMessage2: any;
  public loadingPassword: boolean;
  public firstTime=true;
  dateEndTrial: any;

  constructor(
    private route: ActivatedRoute,
    private archiService: ArchitectService,
    private authService: AuthService,
    public paymentsService: PaymentsService,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private identifySegment: IdentifySegement
    ) { 
      if(!environment.onPremise)this.stripe = Stripe(environment.stripeKey);
    }

  ngOnInit(): void {
    setTimeout(() => {
      this.plan = this.authService.licence;
    }, 1000);
    this.route.queryParams.subscribe((params: Params) => {
      if(params.doneUser){
        this.messageService.add({ key: 'toast', severity: 'success', summary: 'Mise à jour des abonnoments', detail: 'Votre abonnements ont été bien mise à jour', life: 5000 });
      }
    })
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes.subscriptions && !changes.subscriptions.firstChange) {
      this.getSubInfo();
    }
    if(this.users) {
      this.loadingUsers=true;
      this.createForm();
    }
  }
  public createForm(){
    let currentUser = JSON.parse(localStorage.getItem("currentUser"))
    this.archiData = currentUser;
    let archiUser=this.users.filter(user=>user.isAdminArchitect==true);
    this.warningMessage=archiUser[0].warningMessage;
    if(this.firstTime) {
      this.warningMessage2=this.warningMessage;
      this.firstTime=false;
    }
    this.dateEndTrial=archiUser[0].dateEndTrial;
    this.isAdminArchitect=currentUser.isAdminArchitect;
    this.archiId=currentUser.archiId;
    this.userForm = this.fb.group({
      email: ['',
      Validators.compose([
        Validators.required, Validators.email
      ])
      ],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: '',
      archiID:this.archiId,
      archiName:this.archiData.firstName,
      plan:'',
      quantity:0,
      type:'',
      userId:''
    });
    this.loadingUsers=false;
  }
  public onKeyUpMail(){
    this.authService.validateEmail(this.userForm.value.email).subscribe(
      (data)=>{
          this.mailExist=data;
      },
      (err)=>{
        console.log(err);
      })
  }

  getSubInfo(){
    this.quantity=(this.subscriptions && this.subscriptions.length) ? this.subscriptions[0].items.data[0].quantity : 0;
    this.loadingSubs=false;
  }

  requestPlan(plan,quantity,type,userId){
    this.loadinglicence = true;
    this.userForm.patchValue({plan:plan,quantity:quantity,type:type,userId:userId})
    let reqBody = this.userForm.value;
    reqBody.warningMessage= this.warningMessage;
    if(type=='delete') this.deletingUser=true;
    this.paymentsService.choosePlanUser(reqBody)
    .subscribe(data => {
        if(type!='delete') {
          setTimeout(()=>{
            if(this.warningMessage!=this.warningMessage2) {
              this.warningMessage2=this.warningMessage;
              $('#ajouter-user-popup-2').modal('toggle')
            }
            else if(this.warningMessage) $('#ajouter-user-popup-2').modal('toggle');
            else if(!this.warningMessage) $('#ajouter-user-popup').modal('toggle');
            this.loadinglicence = false;
            this.getUsersEvent.emit();
            this.getSubsEvent.emit();
            this.messageService.add({ key: 'toast', severity: 'success', summary: 'Mise à jour des abonnements', detail: 'Les utilisateurs et abonnements ont bien été mis à jour', life: 5000 });
          },5000)
          // segment event
          if(!environment.onPremise){
            window.analytics.track('add_collaborator',{properties:{acValue: 'add_collaborator'}});
            this.identifySegment.identifyNumUsers(this.users.length+1)
          }
          //
        }else {
          // segment event
          if(!environment.onPremise)window.analytics.track('delete_collaborator',{properties:{acValue: 'delete_collaborator'}});
          //
          this.loadinglicence = false;
          this.deletingUser=false;
          $('#delete-user').modal('toggle');
          this.users = this.users.filter(users => users._id != userId)
          if(!environment.onPremise)this.identifySegment.identifyNumUsers(this.users.length);
          this.messageService.add({ key: 'toast', severity: 'success', summary: 'Mise à jour des abonnements', detail: 'Les utilisateurs et abonnements ont bien été mis à jour', life: 5000 });
        }
    },
    err =>{
      this.loadinglicence = false;
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: err.error.err.message, life: 5000 });
    });
  }
  public setUser(user){
    this.user=user;
  }
  public createNewUser(){
    this.requestPlan(this.plan,this.quantity+1,this.userForm.value.email,null);
  }
  public changeUserPassword(){
    this.loadingPassword=true;
    this.archiService.userChangePassword(this.user._id,this.password).subscribe(
      (data)=>{
        this.loadingPassword=false;
        $('#modify-user-password-popup').modal('toggle');
        this.messageService.add({ key: 'toast', severity: 'success', summary: "Mot de pass d'utilisateur", detail: 'Le mot de pass a été changer', life: 5000 });
        this.password="";this.passwordConfirmation="";
      },
      (err)=>{
        this.messageService.add({ key: 'toast', severity: 'error', summary: "Mot de pass d'utilisateur", detail: "Erreur Serveur", life: 5000 });
      }
    )
  }

  public deleteUser(user) {
    this.requestPlan(this.plan,this.quantity-1,'delete',user._id)
  }
  public openModal(){
    $('#ajouter-user-popup').modal('toggle');
    setTimeout(()=>{
      $('#ajouter-user-popup-2').modal('toggle');
    },450)
  }
  public checkWarningMessage(){
    this.warningMessage2=this.warningMessage;
    this.warningMessage=false;
  }
}
