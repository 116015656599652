<div id="confirm-exist-booster-form-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="ag-heading-h4 ag-weight-600 no-margin">Êtes-vous sûr de vouloir quitter ?</h4>
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">

        <p class="main-text">
          Vous êtes sur le point de quitter la création de votre profil <span class="booster-color">AGLO BOOSTER©</span>
          Ça vous prendra <b>moins de 5 minutes</b> et nos équipes pourront vous proposer
          des projets sur mesure.
        </p>

        <div class="submit-btn-container text-right margin-top-30 ">
          <button class="ag-btn-bordered-sm margin-right-40" data-dismiss="modal">Annuler</button>
          <button class="ag-btn-primary-sm" (click)="emitConfirmExist()">Quitter</button>
        </div>

      </div>
    </div>
  </div>
</div>
