<!-- Search Sirets -->
<div class="siret-section" [ngStyle]="{'display': (!fillManually) ? 'block' : 'none' }">
  <div class="search-siret">
    <label for=""></label>
    <div class="input-cont">
      <input *ngIf="!siretLoading" [(ngModel)]="siretNum" type="text" placeholder="Rechercher votre entreprise par SIRET ou par nom" (keyup.enter)="searchMyEntreprise()">
      <button [disabled]="siretNum?.length<3" *ngIf="!siretLoading" (click)="searchMyEntreprise()">Rechercher</button>
      <app-spinner *ngIf="siretLoading"></app-spinner>
      <small *ngIf="siretNum && siretNum?.length<3" class="text-danger">Veuillez entrer 3 caractères au minimum</small>
      <small *ngIf="showSiretNumError" class="text-danger">Rechercher votre entreprise par SIRET ou par nom</small>
    </div>
    <div class="horizontal-items space-between">
      <p class="get-siret-text">Retrouvez votre siret <a target="_blank" href="https://www.societe.com/cgi-bin/recherche"><b>ici.</b></a></p>
      <!-- <p class="bypass-form"><button class="" href="#">Je ne trouve pas mon entreprise</button></p> -->
      <button class="bypass-form" (click)="byPassSiretForm()">Je ne trouve pas mon entreprise</button>
    </div>
  </div>
  <div class="siret-results">
    <p class="results-label" *ngIf="siretAgencies.length > 0">Choisissez votre entreprise :</p>
    <div class="siret-results-wrapper">
        <div class="single-siret horizontal-items space-between" *ngFor="let agency of siretAgencies">
          <div>
            <span class="agency-name">{{ agency?.agencyName || "" }}</span>
          </div>
            
          <div class="align-right">
            <button (click)="fillMyEntreprise(agency)">C’est mon entreprise</button>
            <span class="siret-meta-info">
              <span class="siret-code-zip">{{ agency?.zipCode }}</span>
              <span class="siret-num">Siret: {{ agency.siret }}</span>
            </span>
          </div>

        </div>
    </div>
  </div>
</div>
<!-- END Search Sirets --> 

<div class="form-section" [ngStyle]="{'display': (fillManually) ? 'block' : 'none' }">
  <form [formGroup]="subscriptionForm" novalidate>

    <label id="name" class="required-star">Nom de l'entreprise </label>
    <div class="input-group input-group-lg">
      <input id="name"
             formControlName="name"
             type="text"
             class="form-control"
             placeholder="Nom de l'entreprise "
             aria-describedby="sizing-addon1">
    </div>
    <small *ngIf="formErrors.name" class="text-danger">
      {{ formErrors.name }}
    </small><br>

    <label id="siret" class="required-star">Numéro SIRET</label>
    <div class="input-group input-group-lg">
      <input id="siret"
             formControlName="siret"
             type="text"
             class="form-control"
             placeholder="Numéro SIRET"
             aria-describedby="sizing-addon1">
    </div>
    <small *ngIf="formErrors.siret" class="text-danger">
      {{ formErrors.siret }}
    </small><br>

    <label id="tvaNumber required-star">N° TVA</label>
    <div class="input-group input-group-lg">
      <input id="tvaNumber"
             formControlName="tvaNumber"
             type="text"
             class="form-control"
             placeholder="N° TVA"
             aria-describedby="sizing-addon1">
    </div>
    <small *ngIf="formErrors.tvaNumber" class="text-danger">
      {{ formErrors.tvaNumber }}
    </small><br>

    <label id="address" class="required-star">Adresse</label>
    <div class="input-group input-group-lg">
      <app-google-autocomplete [adresseValue]="subscriptionForm.get('address').value" (adresseValueChange)="getGoogleMapValueChange($event)"></app-google-autocomplete>
    </div>
    <small *ngIf="formErrors.address" class="text-danger">
      {{ formErrors.address }}
    </small><br>

  </form>
</div>

<div class="buttons-cont clearfix">
  <button *ngIf="fillManually" class="back-btn" [disabled]="loading" (click)="toggleFillManually()">
    <i class="fa fa-close"></i> Revenir
  </button>
  <button *ngIf="fillManually" [disabled]="loading" class="finish-btn" (click)="save();">
    Valider
  </button>
</div>
