import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArtisanTeam } from '../_interfaces/team.interface';
import { LotPricing } from '../_interfaces/team-lot-pricing.interface';
import { CeProjectService } from '.';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TeamService {

  constructor(private ceProjectService: CeProjectService) { }

  saveTeams(teams: ArtisanTeam[], ceProjectId: string): Observable<boolean> {
    return new Observable(sub => {
      try {
        const data: any[] = teams.map(team => ({
          title: team.title,
          lots: team.lotPricing.map(lot => ({
            lotId: lot.idLot,
            dpgfId: lot.idpricing
          }))
        }));
        this.ceProjectService.update(ceProjectId, {teams: data})
        .pipe(catchError(err =>{ console.log(err.message); return null}))
        .subscribe();
      }
      catch(err) {
        sub.error(err.message);
      }
      finally {
        sub.complete();
      }
    });
  }
  getTeams(ceProject, pricingList): Observable<ArtisanTeam[]> {
    return new Observable(sub => {
      this.ceProjectService.getProject(ceProject).subscribe(value => {
        const teams = value.teams;

        if(teams.length === 0) {
          sub.error([]);
          sub.complete();
        }

        const teamsArray: ArtisanTeam[] = [];
        teams.forEach(team => {
          const pricings: LotPricing[] = [];

          team.lots.forEach(lot => {
            pricings.push(this.getPricing(lot.dpgfId, lot.lotId, pricingList));
          });
          const t: ArtisanTeam = new ArtisanTeam(team.title);
          t.lotPricing = pricings;
          teamsArray.push(t);
        });

        sub.next(teamsArray);
        sub.complete();
      });
    })
  }
  private getPricing(dpgfId, lot, pricings: any[]): LotPricing {
    for(let i in pricings) {
      const pricing = pricings[i];
      if(pricing._id === dpgfId) {
        for(let j in pricing.data) {
          if(pricing.data[j].id === lot) {
            let factieArtisanTeam = pricing.ceProjectInvite?.facticeArtisanInfo?.name || pricing.artisanName;
            let artisanName = pricing.ceProjectInvite.isFactice ? factieArtisanTeam : pricing.artisan.name;
            
            const lotPricing = new LotPricing(lot, pricing._id, artisanName,
              pricing.data[j].total, pricing.dpgf);

            lotPricing.currentVersion = pricing.currentVersion;
            lotPricing.posts = this.getPosts(pricing.data[j].sousLots);
            return lotPricing;
          }
        }
      }
    }
    const p = new LotPricing(lot, "", "", -1, dpgfId);
    p.isModifying = true;
    return p;
  }
  public getPosts(sousLots) {
      const posts = [];
      for(let i in sousLots) {
          for(let j in sousLots[i].postes){
            let post = sousLots[i].postes[j];
            post.sousLoti = i;
            posts.push(post);
          }

      }
      return posts;
  }
}
