import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";
import { Helpers } from './helpers';

import * as xmljs from 'xml-js';

@Injectable()
export class relatedFichesService {
    constructor(private http: HttpClient) {
    }

    searchWord(word,filter={},quickSearch='') : Observable<any[]>{
      let options = new Helpers().getHeader();
      let query=`word=${word}&declarationType=${filter['declarationType']}&organismeName=${filter['organismeName']}&performanceUF=${filter['performanceUF']}&productionPlace=${JSON.stringify(filter['productionPlace'])}&dateMiseEnLigne=${filter['dateMiseEnLigne']}&airRating=${filter['airRating']}&isFrirogene=${filter['isFrirogene']||''}&idCategory=${filter['idCategory']}&quickSearch=${quickSearch}`
        return this.http.get('/api/v1/relatedFichesServer?'+ query, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    create(file) : Observable<any[]>{
        let options = new Helpers().getHeader();
          return this.http.post(`/api/v1/relatedFiches` ,file, options)
              .pipe(map((response: any) => {
                  return response.data;
              }));
    }
    listAll(postId,entity='',contributor='',ceProjectId='',isResult=false,batiment='') : Observable<any[]>{
        let options = new Helpers().getHeader();
          return this.http.get(`/api/v1/relatedFiches?poste=${postId}&entity=${entity}&contributor=${contributor}&isResult=${isResult}&ceProjectId=${ceProjectId}&batiment=${batiment}` , options)
              .pipe(map((response: any) => {
                  return response.data;
              }));
    }
    update(file) : Observable<any[]>{
        let options = new Helpers().getHeader();
          return this.http.patch(`/api/v1/relatedFiches` ,file, options)
              .pipe(map((response: any) => {
                  return response.data;
              }));
    }
    duplicate(files) : Observable<any[]>{
        let options = new Helpers().getHeader();
          return this.http.post(`/api/v1/relatedFiches/duplicate` ,files, options)
              .pipe(map((response: any) => {
                  return response.data;
              }));
    }
    deleteMany(files) : Observable<any[]>{
        let options = new Helpers().getHeader();
          return this.http.post(`/api/v1/relatedFiches/deleteMany` ,files, options)
              .pipe(map((response: any) => {
                  return response.data;
              }));
    }
    deleteOne(file) : Observable<any[]>{
        let options = new Helpers().getHeader();
          return this.http.post(`/api/v1/relatedFiches/delete` ,file, options)
              .pipe(map((response: any) => {
                  return response.data;
              }));
    }
    getCategories() : Observable<any[]>{
        let options = new Helpers().getHeader();
          return this.http.get(`/api/v1/relatedFiches/getCategories` , options)
              .pipe(map((response: any) => {
                  return response.data;
              }));
    }
    getCategory(idCategory,filter) : Observable<any[]>{
        let query=`declarationType=${filter['declarationType']}&organismeName=${filter['organismeName']}&performanceUF=${filter['performanceUF']}&productionPlace=${JSON.stringify(filter['productionPlace'])}&dateMiseEnLigne=${filter['dateMiseEnLigne']}&airRating=${filter['airRating']}&isFrirogene=${filter['isFrirogene']||''}&searchQuery=${filter['searchQuery']}`
        let options = new Helpers().getHeader();
          return this.http.get(`/api/v1/relatedFiches/getCategories?idCategory=${idCategory}&`+query , options)
              .pipe(map((response: any) => {
                  return response.data;
              }));
    }
    sendDemande(json) : Observable<any[]>{
        let options = new Helpers().getHeader();
          return this.http.post(`/api/v1/relatedFiches/sendDemand` ,json, options)
              .pipe(map((response: any) => {
                  return response.data;
              }));
    }
    getDemandSugg() : Observable<any[]>{
        let options = new Helpers().getHeader();
          return this.http.get(`/api/v1/relatedFiches/getDemandSugg` , options)
              .pipe(map((response: any) => {
                  return response.data;
              }));
    }
    getOrganismeSugg(word) : Observable<any[]>{
        let options = new Helpers().getHeader();
          return this.http.get(`/api/v1/relatedFiches/getOrganismeSugg?name=`+word , options)
              .pipe(map((response: any) => {
                  return response.data;
              }));
    }
    getIniesPosts(postIds) : Observable<any[]>{
        let options = new Helpers().getHeader();
          return this.http.post(`/api/v1/relatedFichesServer` ,{postIds:postIds}, options)
              .pipe(map((response: any) => {
                // if(isResult) return (response.data.hits||[]).filter(post=>(post.contributor=='Chantier' && post.entity)||(post.contributor=='Composants' && post.entity && post.lotPCE))
                return response.data;
              }));
    }
    checkXml(xmlFileToUpload): Observable<any>{
        let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/relatedFiches/checkXml` ,xmlFileToUpload, options)
              .pipe(map((response: any) => {
                  return response
              }));
    }
    getEntities(ceProjectId) : Observable<any[]>{
        let options = new Helpers().getHeader();
          return this.http.get(`/api/v1/relatedFiches/getEntities/${ceProjectId}` , options)
              .pipe(map((response: any) => {
                  return response.data
              }));
    }
    getLotForfaitaire(body) : Observable<any[]>{
        let options = new Helpers().getHeader();
          return this.http.post(`/api/v1/relatedFiche/getLotForfaitaire`,body , options)
              .pipe(map((response: any) => {
                  return response.data
              }));
    }


    ///////////////////////////
    /// XML To JSON Helper
    ///////////////////////////
    private RemoveJsonTextAttribute(value,parentElement){
        var keyNo = Object.keys(parentElement._parent).length;
        var keyName = Object.keys(parentElement._parent)[keyNo-1];
        parentElement._parent[keyName] = value;
    }
    public parseXmlStringToJSON(xmlString:string){
        let xmlJSON = xmljs.xml2json(xmlString,  {compact: true, spaces: 4, textFn: this.RemoveJsonTextAttribute });
        let parsedXml = JSON.parse(xmlJSON);

        return parsedXml;
    }
    
}