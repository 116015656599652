
<app-spinner *ngIf="loadingLots"></app-spinner>
<div class="scroller-1-y"  *ngIf="!isLotSelected && !loadingLots" style="height:calc(90vh - 130px);">
    <div *ngFor="let lot of cstbLots; let i = index" style="padding-left: 6px;border:1px solid rgba(242, 244, 247, 1);border-radius: 4px;margin-bottom: 8px;" class="flex">
        <!-- <img src="/asssets/chevron-down.svg"> -->
        <img class="pointer" (click)="getCstbLot(lot)" src="/assets/chevron-right.svg">
        <div class="one-cstb" (click)="getCstbLot(lot)" style="font-weight: 400;font-size: 12px;color:rgba(10, 23, 71, 1);">
            <!-- <i class="fa fa-folder" aria-hidden="true"></i> -->
            <img src="/assets/blue-folder-icone.svg" class="img-icon margin-8">
            {{lot.title}}
        </div>
    </div>
</div>
<app-spinner *ngIf="loadingChild && isLotSelected"></app-spinner>
<div class="scroller-1-y"  *ngIf="!loadingChild && isLotSelected" style="height:calc(85vh - 130px);">
    <app-cstb-navigation-lot [manySelect]="manySelect" (returnEvent)="goBack()" (childSelectedEvent)="getSelectedPoste($event)" [childLot]="activeCstbLot" level="1" [licence]="licence"></app-cstb-navigation-lot>
</div>
