<div class="albums-wrapper">

  <!-- Add New Albums -->
  <div class="add-albums-container text-center" *ngIf="currentView == 'no_albums'">
    
    <h5 class="ag-heading-h5 ag-weight-600">
      Votre onglet "Mes réalisations" est vide
    </h5>
    <p>Grâce aux albums photos, vous augmentez vos chances d’éblouir les maîtres d’oeuvre</p>

    <div class="add-album-button-container text-center pointer" (click)="openNewAlbumModal()">

      <div class="button-sign flex-middle">
        <img src="/assets/artisan-booster/add-sign.svg">
      </div>

      <h5 class="ag-heading-h5 ag-weight-700 margin-top-32">Créer un album</h5>
    </div>

  </div>
  <!-- END Add New Albums -->


  <!-- Albums Container -->
  <div class="albums-container" *ngIf="currentView == 'show_all_albums'">
    <h5 class="ag-heading-h5 ag-weight-600">
      Réalisations<br>
    </h5>
    <p class="ag-para-1">Beau travail, continuez de nous épater avec vos réalisations</p> 

    <div class="albums-list margin-top-32 horizontal-items">

      <!-- Photos -->
      <div class="single-album relative pointer"
        *ngIf="photos.length"
        (click)="openPhotosAlbum()">
        <div class="album-thumbnail relative margin-bottom-8">
          <img *ngIf="photos[0]" [src]="photos[0].cdnUrl || albumCoverPlaceholder">
        </div>
        <h5 class="album-title margin-bottom-4 ag-heading-h5 ag-weight-600">Mes réalisations</h5>
        <p class="album-counter ag-para-1">{{ photos.length }} photos</p>
      </div>
      <!-- Photos -->

      <!-- Profile Albums -->
      <div class="single-album relative pointer" 
        *ngFor="let album of albums; let i = index;" 
        (click)="openAlbum(i, album)">
        <div class="album-thumbnail relative margin-bottom-8">
          <img *ngIf="!album.photos[0]" [src]="albumCoverPlaceholder">
          <img *ngIf="album.photos[0]" [src]="album.photos[0].uploadURL || albumCoverPlaceholder">
        </div>
        <h5 class="album-title margin-bottom-4 ag-heading-h5 ag-weight-600">{{ album.title }}</h5>
        <p class="album-counter ag-para-1">{{ album.photos.length }} photos</p>
      </div>
      <!-- Profile Albums -->

      <div class="add-new-album pointer" (click)="openNewAlbumModal()">
        <div class="grey-bg flex-middle margin-bottom-8">
          <img src="/assets/artisan-booster/add-sign.svg">          
        </div>
        <h5 class="ag-heading-h5 ag-weight-600">Créer un nouvel album</h5>
      </div>

    </div>



  </div>
  <!-- END Albums Container -->

  <!-- Album Photos -->
  <div class="album-photos-container" *ngIf="currentView == 'show_album_photos'">

    <div class="upper-info-text">
      <div class="bread-crumb">
        <p class="ag-para-1">
          <u class="pointer" (click)="setCurrentView('show_all_albums')">Réalisations</u>
           > <b>{{ selectedAlbum.title }}</b> 
           <img src="/assets/artisan-booster/edit-pen-sign.svg" class="pointer" 
            (click)="openEditModalTitleModal()" 
            *ngIf="!isArtisanPhotosAlbum">
           <img src="/assets/delete-icone.svg" class="pointer margin-left-10" 
            (click)="openConfirmDeleteAlbumModal()"
            >
            <!-- *ngIf="!isArtisanPhotosAlbum" -->
          </p>
        <p class="ag-para-1">Il y a actuellement {{ selectedAlbum.photos.length }} photos visibles dans votre album {{ selectedAlbum.title }}</p>
      </div>
    </div>

    <div class="photos-list horizontal-items">
      
      <div class="single-album-photo relative" *ngFor="let photo of selectedAlbum.photos; let i = index;">
        <!-- *ngIf="!isArtisanPhotosAlbum" -->
        <span class="remove-icon" (click)="openRemovePhotoModal(i)">
          <img src="assets/close-icone-black.svg"/>
        </span>
        <img class="album-photo" [src]="!isArtisanPhotosAlbum ? photo.uploadURL : photo.cdnUrl">
      </div>
      
      <div class="add-new-photo pointer" (click)="openAddPhotosToCurrentAlbum()" *ngIf="!isArtisanPhotosAlbum">
        <div class="grey-bg flex-middle margin-bottom-8">
          <img src="/assets/artisan-booster/add-sign.svg">          
        </div>
        <p class="ag-para-1 ag-weight-500">Ajouter des photos à l’album</p>
      </div>

    </div>

  </div>
  <!-- END Album Photos -->

  <!-- Create new Album Modal -->
  <app-booster-profile-page-create-album-modal 
    *ngIf="showAddAlbumModal"
    (createNewAlbum)="createNewAlbum($event)">
  </app-booster-profile-page-create-album-modal>
  <!-- END Create new Album Modal -->

  <!-- Add Photos to Single Album Modal -->
  <app-add-photos-to-single-album-modal
    *ngIf="showAddPhotosToAlbumModal"
    [albumTitle]="selectedAlbum.title"
    (addPhotos)="addPhotosToCurrentAlbum($event)"
  ></app-add-photos-to-single-album-modal>
  <!-- END Add Photos to Single Album Modal -->

  <!-- Edit Album Title Modal -->
  <app-booster-profile-edit-album-title-modal 
    [albumTitle]="selectedAlbum.title" 
    (saveTitle)="changeCurrentAlbumTitle($event)">
  </app-booster-profile-edit-album-title-modal>
  <!-- END Edit Album Title Modal -->

  <!-- Confirm Delete Album Modal -->
  <app-confirm-modal
    [modalId]="'confirm-delete-photo-modal'"
    [headerTitle]="'Supprimer une image'"
    [bodyText]="'Vous êtes sur le point de supprimer une image.
    Souhaitez-vous continuer ? '"
    [confirmButtonLabel]="'Supprimer l’image'"
    (confirm)="confirmDeleteCurrentSelectedPhoto()"
  ></app-confirm-modal>
  <!-- END Confirm Delete Album Modal -->

  <!-- Confirm Delete Album Modal -->
  <app-confirm-modal
    [modalId]="'confirm-delete-album-modal'"
    [headerTitle]="'Supprimer le dossier'"
    [bodyText]="confirmDeleteAlbumBody"
    [confirmButtonLabel]="'Supprimer'"
    (confirm)="confirmDeleteCurrentAlbum()"
  ></app-confirm-modal>
  <!-- END Confirm Delete Album Modal -->

</div>