<form [formGroup]="subscriptionForm">
    <div>
        <p class="text-size-18-weight-700 color-lineheight" style="padding-bottom:8px;">Informations de l’entreprise</p>
        <p style="padding-bottom:32px;width: 90%;">Les informations de votre entreprise sont éditables à tout moment.</p>
        <div class="row">
        <div class="flex" style="padding-bottom:16px;">
            <div>
                <p class="text-size-14-weight-400 color-lineheight">Nom de l’entreprise</p>
                <input formControlName="name" type="text" (ngModelChange)="valueChanged($event)" class="form-control input-styling margin-right-32 padding-left-10">
                <small *ngIf="!isAdminLoggedIn && formErrors.name" class="text-danger">
                    {{ formErrors.name }}
                  </small>
            </div>
            <div>
                <p class="text-size-14-weight-400 color-lineheight">SIRET</p>
                <input formControlName="siret" type="text" (ngModelChange)="valueChanged($event)" class="form-control input-styling padding-left-10">
                <small *ngIf="!isAdminLoggedIn && formErrors.siret" class="text-danger">
                    {{ formErrors.siret }}
                  </small>
            </div>
        </div>        
        <div class="flex">
            <div>
                <p class="text-size-14-weight-400 color-lineheight">Adresse </p>
                <input formControlName="address" type="text" (ngModelChange)="valueChanged($event)" class="form-control input-styling margin-right-32 padding-left-10">
                <small *ngIf="!isAdminLoggedIn && formErrors.address" class="text-danger">
                    {{ formErrors.address }}
                  </small>
            </div>
            <div>
                <p class="text-size-14-weight-400 color-lineheight">N° de téléphone de l’entreprise </p>
                <input formControlName="telAgency" type="text" (ngModelChange)="valueChanged($event)" class="form-control input-styling padding-left-10">
                <small *ngIf="!isAdminLoggedIn && formErrors.telAgency" class="text-danger">
                    {{ formErrors.telAgency }}
                  </small>
            </div>
        </div>
        <div class="flex">
            <div>
                <p class="text-size-14-weight-400 color-lineheight">Nom de l’assurance </p>
                <input formControlName="assurancesName" type="text" (ngModelChange)="valueChanged($event)" class="form-control input-styling margin-right-32 padding-left-10">
                <small *ngIf="!isAdminLoggedIn && formErrors.assurancesName" class="text-danger">
                    {{ formErrors.assurancesName }}
                  </small>
            </div>
            <div>
                <p class="text-size-14-weight-400 color-lineheight">N° d’assurance</p>
                <input formControlName="assuranceNumber" type="text" (ngModelChange)="valueChanged($event)" class="form-control input-styling padding-left-10">
                <small *ngIf="!isAdminLoggedIn && formErrors.assuranceNumber" class="text-danger">
                    {{ formErrors.assuranceNumber }}
                  </small>
            </div>
        </div>
        <div>
            <p class="text-size-14-weight-400 color-lineheight">N° de TVA</p>
            <input formControlName="tvaNumber" type="text" (ngModelChange)="valueChanged($event)" class="form-control input-styling padding-left-10">
            <small *ngIf="formErrors.tvaNumber" class="text-danger">
                {{ formErrors.tvaNumber }}
              </small>
        </div>
        </div> 
        <div class="text-color-blue flex justify-center wid76 padding-top-41 margin-bottom-100">
            <!-- <button class="cancel-button">Annuler les modifications</button> -->
            <button class="save-button" [disabled]="!isAdminLoggedIn && disabled" [ngClass]="{'disabled':!isAdminLoggedIn && disabled}" (click)="UpdateUserData()">
                <p class="margin-0" *ngIf="!loadingButton">Enregistrer les modifications</p>
                <div *ngIf="loadingButton">
                    <app-spinner></app-spinner>
                </div>
            </button>
        </div>
    </div>
    </form>