import { Component, OnInit } from '@angular/core';
import {Title,Meta} from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
//Interface

@Component({
  standalone:true,
    selector: 'app-not-found',
    imports:[
      NgSharedModule,
      HeaderComponent
    ],
    templateUrl: './404.component.html',
    styleUrls: ['./404.component.scss'],

})
export class notFoundComponent implements OnInit {
  public projects:any = [];
  constructor(private title: Title,
    private route:ActivatedRoute 
  ) { }

  ngOnInit() {
    this.title.setTitle('Aglo - Oops 404' );
  }

}
