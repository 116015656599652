import { Component, OnInit, OnChanges, SimpleChanges, Input, NO_ERRORS_SCHEMA } from '@angular/core';

const _ = require('lodash');

// static data
import activitiesList from '../../booster-profile-forms-components/booster-profile-corps-etat-form/activities-list';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone: true,
    selector: 'app-booster-profile-garantie-decennale-view-only',
    imports:[
      NgSharedModule
    ],
    templateUrl: './booster-profile-garantie-decennale-view-only.component.html',
    styleUrls: ['./booster-profile-garantie-decennale-view-only.component.scss'],
})
export class BoosterProfileGarantieDecennaleViewOnlyComponent implements OnInit, OnChanges {

  @Input() corpsDetat:any;
  @Input() isPublicProfile:boolean = false;

  public agencyActivitiesList: any = [];
  public customAgencyActivitiesList: any = [];

  public selectedItems: Array<string> = [];

  constructor() { }

  ngOnInit(): void {
    
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.corpsDetat && Object.keys(changes.corpsDetat.currentValue).length){
      setTimeout(() => {
        this.agencyActivitiesList = this.setArtisanData([...activitiesList], changes.corpsDetat.currentValue);
      }, 200);
    }
  }
  /// remove activities that are not choosed
  private setArtisanData(activitiesList, corpsDetat){
    let myActivitiesList = [...activitiesList];
    let filterdActivities = [];

    let selectedItems = {};
    // {
    //   activityName: {
    //     sectionName: [itemsList],
    //     otherSectionName: [itemsList],
    //   }
    // }

    let items = corpsDetat.filter(item => !item.custom);
    let customItems = corpsDetat.filter(item => item.custom);
    this.customAgencyActivitiesList = customItems;

    /// set our selected checkboxes to out Object
    for(let i = 0; i < items.length; i++){
      let singleItem = items[i];
      let singleItemParts = singleItem.split('_');

      let activity = singleItemParts[0];
      let section = singleItemParts[1];
      let checkBoxItem = singleItemParts[2];

      // if activity not already Exists in Obj add
      if(!_.has(selectedItems, activity)){
        _.set(selectedItems, activity, { [section]: [checkBoxItem] });
      }else{
        if(!_.has(selectedItems, `${activity}.${section}`)){
          _.set(selectedItems, `${activity}.${section}`, [checkBoxItem]);
        }else{
          let currentSection = _.get(selectedItems, `${activity}.${section}`);
          currentSection.push(checkBoxItem);
        }
      }

    }

    /// removed unselected activities
    for(let i = 0; i < activitiesList.length; i++){
      let singleActivity = {...myActivitiesList[i]};
      let singleActivityId = singleActivity.id;

      let filteredSections = [];

      if(selectedItems[singleActivityId]){
        filterdActivities.push(singleActivity);

        /// Keep Only Selected Sections
        for(let j = 0; j < singleActivity.sectionsLists.length; j++){
          let singleSection = {...singleActivity.sectionsLists[j]};
          let filtredList = [];

          if(selectedItems[singleActivity.id][singleSection.id]){
            filteredSections.push(singleSection);

            for(let k = 0; k < singleSection.list.length; k++){
              let listItem = {...singleSection.list[k]};            
              if(selectedItems[singleActivity.id][singleSection.id] && selectedItems[singleActivity.id][singleSection.id].includes(listItem.value)){
                filtredList.push(listItem);
              }
            }

          }
          singleSection.list = filtredList;

        }
        singleActivity.sectionsLists = filteredSections;
      }
    }

    return filterdActivities;
  }



}
