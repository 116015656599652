import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { AuthService } from 'src/app/_services';
import { ArtisanService } from '../../../_services/index';
import { Subscription } from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup,  Validators} from '@angular/forms';

import { ArtisanModalChangePasswordComponent } from '../artisan-modal-change-password/artisan-modal-change-password.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';


@Component({
    selector: 'app-artisan-edit-gestion-compte',
    imports:[
    ArtisanModalChangePasswordComponent,
    NgSharedModule
],
    templateUrl: './artisan-edit-gestion-compte.component.html',
    styleUrls: ['./artisan-edit-gestion-compte.component.scss'],
    standalone: true,

})
export class ArtisanEditGestionCompteComponent implements OnInit {
  @Input() user: any;
  @Output() newUserEmail: EventEmitter<any> = new EventEmitter<any>();

  public newEmail;mailExist;currentUser
  private subscriptions: Array<Subscription> = [];
  public subscriptionForm: UntypedFormGroup;
  public formErrors = {'email': ''};
  private validationMessages = {
    'email': {
      'required': "L'email est obligatoire.",
      'email':'Email invalide'
    }
  };
  constructor(private authService: AuthService,private artisanService: ArtisanService,private fb: UntypedFormBuilder) { }
  

  ngOnInit(): void {
    this.createForm();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.subscriptionForm.patchValue({email:this.user.email})

  }
  public createForm(){
    this.subscriptionForm = this.fb.group({
      email: ['', [Validators.required,Validators.email]],
    });
    this.subscriptions.push(
      this.subscriptionForm.valueChanges.subscribe(data => this.onValueChanged(data))
    )
  }
  private onValueChanged(data ? : any) {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
            this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
  public markFormAsTouched():void {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      control.markAsDirty();
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
    }
  }
  public isValid(){
    this.markFormAsTouched();
    return this.subscriptionForm.valid
  }
  public onKeyUpMail(){
    this.authService.validateEmail(this.subscriptionForm.value.email).subscribe(
      (data)=>{
          this.mailExist=data;
      },(err)=>{
        console.log(err);
      })
  }
  public updateUserEmail(){
    if(this.isValid())
    this.subscriptions.push(
      this.artisanService.updateUserEmail(this.currentUser.id,{newEmail:this.subscriptionForm.value.email})
        .subscribe(
          data => {
            this.mailExist = false;
            let newUser = [{...this.user}];
            newUser[0].email = this.subscriptionForm.value.email;
            this.newUserEmail.emit(newUser)
          },
          error => {
            console.log("error",error)
          })
    );
  }
  ngOnChanges(changes: SimpleChanges) {
    this.createForm();
    if (changes["user"] && this.subscriptionForm){
      this.user= changes.user.currentValue
      this.subscriptionForm.patchValue({email:this.user.email})
      // this.userId = changes.user.currentValue._id;
      // this.subscriptionForm.patchValue({firstName:changes.user.currentValue.firstName,lastName:changes.user.currentValue.lastName,emailAgency:this.artisanEmailAgency||changes.artisanEmailAgency.currentValue,phone:changes.user.currentValue.phone});
    }
  }
}
