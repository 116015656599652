<div *ngIf="loading">
  <img src="/assets/rob/empty.svg" class="empty-img" alt="">
  <div class="center title">
      Vos DCE sont en train de charger ...
   </div>
  <div class="center description">
    Le saviez-vous ? Vous pouvez désormais dupliquer un projet directement 
    <p>
      depuis la page d’accueil grâce aux options du projet.
    </p> 
  </div>
</div>
<div *ngIf="!loading">
<div *ngFor="let dpgf of dpgfs" class="col-md-3 col-sm-4 col-xs-6 espace">
    <div class="floating-card card scale-up">
      <div class="row">
        <div class="col-xs-12 card-title">
          {{dpgf.nomPrenom }}
        </div>
        <div class="col-xs-12 card-sub-title">
          {{dpgf.adresse}}
        </div>
      </div>
      <div class="open-btn">
        <button (click)="chooseDpgf(dpgf._id)" class="login btn-primary-border duplicate-post">
          Dupliquer
        </button>
      </div>
    </div>
</div>
</div>

