import {Component, NgModule, EventEmitter, OnInit, Input,Output, HostListener, ViewChild, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core'
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription } from 'rxjs';
import { DpgfPostesService } from 'src/app/_services';
import * as actions from '../_store/dpgf.actions';
import { Store , select} from '@ngrx/store';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';


declare var $: any;

@Component({
  standalone:true,
  selector: 'app-pill-dpgf',
  imports:[
    DpgfModule,
    SpinnerComponent,
    NgSharedModule
  ],
  templateUrl: './pill-dpgf.component.html',
  styleUrls: ['./pill-dpgf.component.scss']
})
export class PillDpgfComponent implements OnInit {
  @Input()textContent:string = "";
  @Input()typeInput;
  @Input()color;
  @Input()selected;
  @Input()disable;
  @Input()placeholder;
  @Input()prefix;
  @Input()icon: any;
  @Input()id;
  @Input()loadingLot;
  @Input()isAutomateNumberIsChecked;
  @Input()modify:boolean ;
  @Input()modifySousLotHead:boolean=true;
  @Input()isLotTitle:boolean=false;
  @Output()changeText = new EventEmitter<any>();
  @Output() editingTitle =new EventEmitter<any>();
  @Output()selectDropDown = new EventEmitter<any>();
  @ViewChild('op') op: OverlayPanel;
  public results:any = [];
  public lot;
  public icons=["amenagements_exterieurs__espaces_verts.png","ascenseurs.png","assainissement__recuperationtraitement_des_eaux.png","generalites.png","carrelage__faience.png","charpente.png","chauffage__ventilation__climatisation_cvc.png","cloisons__doublages__fauxplafonds.png","couverture__etancheite.png","cvc.png","demolition__depose.png","desamiantage.png","electricite.png","energies_renouvelables.png","equipements_techniques.png","facades.png","gaz.png","maconnerie__grosuvre.png","menuiserie_exterieure.png","menuiserie_interieure__agencement.png","miroiterie__vitrerie.png","mobilier.png","ossature_boismetallique.png","peinture__revetements_muraux__nettoyage.png","piscine.png","plomberie__sanitaire.png","revetement_de_sol.png","securite_incendie.png","serrurerie__metallerie.png","signaletique.png","stores__fermetures__occultations.png","terrassement__soutenements__infrastructures.png","voirie_et_reseaux_divers.png","Aglo-Appareillage_installation_reseaux.svg","Aglo-Approche_simplifiee.svg","Aglo-Eclairage.svg","Aglo-Energie.svg","Aglo-Equipements_genie_climatique.svg","Aglo-Fils_cables.svg","Aglo-Fluides_frigorigenes.svg","Aglo-Panneau_sandwich_agroalimentaire.svg","Aglo-Production_locale_energie.svg","Aglo-Traitement_dechets.svg"];
  public currentFocus:number = 0;
  private subscriptions: Array<Subscription> = [];
  
  selectedLotIcon: any;
  currentLotId: any;
  dpgf: any;
  mode: string;
  view: string;
  dceSwitch: any;
  indexLot: any;
  title : String;
  lotNumber: any;
  lotIcon: String;
  public gid:string;

  constructor(private DpgfPostesService: DpgfPostesService,
              private elRef: ElementRef,
              private store: Store,
              private cd: ChangeDetectorRef) {
  }
  ngOnInit() {
    this.focusOutDiv();
   }
 
   public getLot(event, lotId){
     this.op.toggle(event);
   }
   scrollTo(arg0: string) {
     throw new Error('Method not implemented.');
   }
 
   public setIcon(icon){
     this.icon=icon;
     this.modify=true;
     //this.store.dispatch(actions.updateLot({lotId : this.selectedLotIcon , data : {icon:icon}}));
   }
   public changeLotNumber(title,number,icon){
     //this.prefix=event.target.value;
     this.changeText.emit({title : title,indexLot:parseInt(number), icon:icon })
     this.modify=false;
     this.editingTitle.emit(false);
    //  if(!environment.onPremise)window.analytics.track('change_souspost_into_post',{properties:{acValue: 'change_souspost_into_post'}});
   }
 
   public focusOutDiv(){
     $(document).mouseup((e) => {
       var container = $("#head-lot");
       this.title=$("#title").val();
       this.lotNumber=$("#nmbr").val()||this.prefix;
       if (!container.is(e.target) && container.has(e.target).length === 0 && this.modify){
        let gidVaule = this.gid || undefined;
         this.changeText.emit({title:this.title ,indexLot:parseInt(this.lotNumber), icon:this.icon, gid: gidVaule});
         this.modify=false;
         this.editingTitle.emit(false);
       }
       });
     
   }
  public onKeydownOnInput(event){
    if(this.modify){
      switch(event.which) {
        case 40:
          this.currentFocus += 1;
        break;
        case 38:
          this.currentFocus -= 1;
        break;
        case 13:
          if(this.results[this.currentFocus]){
            event.preventDefault()
            this.choose(this.results[this.currentFocus],this.prefix);
          }
        break;
        case 27:
          this.modify = false;
          this.results = [];
        break;
      }
    }
  }
  public onkeyTab(e){
    if (e.target.id === "title") {
      $('#nmbr').focus(); // cancel default logic
    }
    else{
      $('#title').focus();
    }
} 

  public searchLot() {
    this.results=[];
    let newResult = [];
    this.currentFocus = 0;
    let looked = this.textContent.replace(/\s+$/,"");
    let words = looked.split(" ");
    let homoGram = words[words.length-1].toLowerCase();
    this.subscriptions.push(
      this.DpgfPostesService.searchLotWord(homoGram)
      .subscribe( result => {
        result.forEach(val=>{
          // let suggestion = this.capitalizeFirstLetter(`${words.slice(0, words.length-1).join(" ")}${words.length==1?"":" "}${val.name}`)
          newResult.push({sugg : val.name, color1 : val.color1, color2 : val.color2,icon : val.icon,gid : val.id_cstb})
        })

        this.results = newResult;
        this.cd.markForCheck();
      })
    )
  }
  public capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  public manageType(title) {
    this.textContent = title;
    this.searchLot();
    //this.changeText.emit({title : title,indexLot:indexLot, icon :icon});
  }
  public choose(res,lotNumber){
    this.textContent = res.sugg;
    this.results = [];
    this.indexLot=lotNumber;
    this.gid=res.gid || undefined;
    this.changeText.emit({title:res.sugg ,indexLot:lotNumber, color1 : res.color1, color2 : res.color2, icon : res.icon, gid :res.gid });
    this.selectDropDown.emit()
  }
  public editable(){
    if(!this.disable){
      this.modify =true;
      this.editingTitle.emit(true);
      this.cd.markForCheck();
      setTimeout(() => {
        this.elRef.nativeElement.getElementsByClassName("edit-span")[0].focus()
      }, 100)
    }
  }
  public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
  }

}
