<div id="create-new-album-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="ag-heading-h4 ag-weight-500 no-margin">Créer un album</h4>
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">        
        
        <div class="album-name-input-wrapper horizontal-items margin-bottom-20">
          <h4 class="ag-heading-h4 ag-weight-500 no-margin margin-right-10" [pTooltip]="'Modifier le mot de passe'">Nom de l’album : </h4>
          <input type="text" [(ngModel)]="album.title">
        </div>

        <div class="photos-preview-wrapper horizontal-items" *ngIf="showAlbumPhotos">
          <div class="single-photo margin-right-32" *ngFor="let photo of album.photos">
            <img [src]="photo.uploadURL" class="full-width">
          </div>
        </div>

        <!-- <div id="DashboardContainer" [ngStyle]="{'display' : !showAlbumPhotos ? 'block' : 'none' }"></div> -->
        <div [ngStyle]="{'display' : !showAlbumPhotos ? 'block' : 'none' }">
        <p-fileUpload name="demo2" url="/api/v1/upload-file" chooseLabel="Browse files" (onUpload)="onUploadMultiFiles($event)" [multiple]="true" [auto]="true"
        accept="image/*" maxFileSize="1000000">
        <!-- <ng-template pTemplate="content" let-files>
        <div style="height: calc(100px - 14px);display: flex;justify-content: center;align-items: center;">
          Drop files here or browse files
        </div>
        </ng-template> -->
        </p-fileUpload>
      </div>
        <div class="submit-btn-container text-center margin-top-30 ">
          <button class="ag-btn-primary-sm" (click)="emitCreateAlbum()">Créer le dossier</button>
        </div>

      </div>
    </div>
  </div>
</div>
