import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup,  Validators} from '@angular/forms';
import {Router, ActivatedRoute, Params} from '@angular/router';

import { PasswordValidation } from '../../../_helpers/password-validation';
import { AuthService } from '../../../_services/index';
import { MessageService } from 'primeng/api';
import { AllModule } from '../all.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $:any;

@Component({
    selector: 'app-reset-password',
    imports:[
      NgSharedModule
    ],
    providers: [],
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: true,

})
export class ResetPasswordComponent implements OnInit {
  public loading:boolean = false;
  public formErrors:any = {'password': '','password2': ''};
  public done:boolean = false;
  public token:string;
  public resetForm: UntypedFormGroup;
  private validationMessages = {
    'password': {
      'required': 'Le mot de passe est obligatoire.',
      'minlength':"Minimum 6 characteres",
      'MatchPassword' : "Les mots de passe saisis ne sont pas identiques"
    },
    'password2': {
      'required': 'Le mot de passe est obligatoire.',
      'minlength':"Minimum 6 characteres",
      'MatchPassword' : "Les mots de passe saisis ne sont pas identiques"
    }
  };
  constructor(private router:Router,
    private messageService: MessageService,
    private fb: UntypedFormBuilder, public authService: AuthService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.createForm()
    this.getToken()
  }
  //Get Token
  private getToken() {
    this.route.params.forEach((params: Params) => {
      if (params['token']) {
        let token = params['token'];
        this.token = token;
      }
    })
  }
  //Create Form
  private createForm() {
    this.resetForm = this.fb.group({
      password: ['', [Validators.required,Validators.minLength(6)]],
      password2: ['', [Validators.required,Validators.minLength(6)]],
    }, {
      validator: PasswordValidation.MatchPassword // your validation method
    });
    this.resetForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged();
  }

  //Generate Error
  private onValueChanged(data ? : any) {
    if (!this.resetForm) {
      return;
    }
    const form = this.resetForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
  //Mark as touched
  private markFormAsTouched():void {
    if (!this.resetForm) {
      return;
    }
    const form = this.resetForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      control.markAsDirty();
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
    }
  }
  //Validate Form
  public isValid(){
      this.markFormAsTouched()
      return this.resetForm.valid
  }
  public goToConnect(){
    this.router.navigate(['/login']);
  }
  public resetPassword(){
    if(this.isValid()){
      this.loading=true;
      this.authService.resetPassword(this.token,this.resetForm.value.password)
          .subscribe(
              data => {
                this.loading=false;
                this.done = true;
                setTimeout(()=>{
                  $('#email-sent').addClass('animated rotateIn');
                  $('#email-sent').removeClass('hide');
                }, 500);
              },
              error => {
                this.loading=false;
                this.messageService.add({ key: 'toast', severity: 'error', summary: '404', detail: "Votre lien n\'existe pas ou a expiré", life: 5000 });
                console.log(error)
              })
    }
  }
}
