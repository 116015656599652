<!-- Siret Api Input -->
  <!-- <div [ngStyle]="{'display': (isCreatingAccount) ? 'block' : 'none' }">
    <label id="siret">Numéro SIRET</label>
    <div class="input-group pl-2">
      <input type="text" [(ngModel)]="siretNum" class="form-control ml-2" placeholder="Search for...">
      <span class="input-group-btn">
        <button (click)="getSiret()" class="btn btn-primary-color search-siret-btn" type="button">Submit</button>
      </span>
    </div>
    <small>
      <a class="fill-link" href="javascript:void(0)" (click)="toggleCreatingAccount()">Fill Manually</a>
    </small>
  </div> -->
<!-- END Siret Api Input -->

<form [formGroup]="subscriptionForm" novalidate>

<div [ngStyle]="{'display': (!isCreatingAccount) ? 'block' : 'none' }">
  <label id="name" class="required-star">Nom de l'entreprise </label>
  <div class="input-group input-group-lg">
    <span [@error]="error=='name'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='name'" class="glyphicon secondary glyphicon-bullhorn" aria-hidden="true"></span></span>
    <input id="name"
           formControlName="name"
           type="text"
           class="form-control"
           placeholder="Nom de l'entreprise "
           aria-describedby="sizing-addon1">
  </div>
  <small *ngIf="formErrors.name" class="text-danger">
    {{ formErrors.name }}
  </small><br>

  <label id="file-label" class="required-star">Logo de l'entreprise </label><br>
  <div *ngIf="((agency.logo||{}).cdnUrl|| '') != ''">
    <img src="{{agency.logo.cdnUrl}}" alt="" class="thumb-nail">
  </div>
  
  <small *ngIf="formErrors.logoAgency" class="text-danger text-center">
    {{ formErrors.name }}
  </small><br>

  <label id='telAgency' class="required-star">Numéro de téléphone</label>
  <div class="input-group input-group-lg">
    <span [@error]="error=='telAgency'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='telAgency'" class="glyphicon glyphicon-phone secondary" aria-hidden="true"></span></span>
    <input id="telAgency"
           formControlName="telAgency"
           type="text"
           class="form-control"
           placeholder="Numéro de teléphone"
           aria-describedby="sizing-addon1">
  </div>
  <small *ngIf="formErrors.telAgency" class="text-danger">
    {{ formErrors.telAgency }}
  </small><br>

  <label id='emailAgency' class="required-star">Mail de contact</label>
  <div class="input-group input-group-lg">
    <span [@error]="error=='emailAgency'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='emailAgency'" class="glyphicon glyphicon-envelope secondary" aria-hidden="true"></span></span>
    <input id="emailAgency"
           formControlName="emailAgency"
           type="text"
           class="form-control"
           placeholder="Mail"
           aria-describedby="sizing-addon1">
  </div>
  <small *ngIf="formErrors.emailAgency" class="text-danger">
    {{ formErrors.emailAgency }}
  </small><br>

  <label id="address" class="required-star">Adresse</label>
  <div class="input-group input-group-lg">
    <span [@error]="error=='address'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='address'" class="glyphicon secondary glyphicon-pushpin" aria-hidden="true"></span></span>
    <app-google-autocomplete (adresseValueChange)="getGoogleMapValueChange($event)"></app-google-autocomplete>
  </div>
  <small *ngIf="formErrors.address || !agency.place?.geometry" class="text-danger">
    Vous devez choisir une adresse dans la liste proposée
  </small><br>

  <label id="tvaNumber">N° TVA</label>
  <div class="input-group input-group-lg">
    <span [@error]="error=='tvaNumber'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='tvaNumber'" class="secondary" aria-hidden="true">%</span></span>
    <input id="tvaNumber"
           formControlName="tvaNumber"
           type="text"
           class="form-control"
           placeholder="N° TVA"
           aria-describedby="sizing-addon1" #adresse>
  </div>
  <small *ngIf="formErrors.tvaNumber" class="text-danger">
    {{ formErrors.tvaNumber }}
  </small><br>


  <label id="website">Avez-vous un site internet ?</label>
  <div class="input-group input-group-lg">
    <span [@error]="error=='website'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='website'" class="secondary" >W</span></span>
    <input id="website"
           formControlName="website"
           type="text"
           class="form-control"
           placeholder="Site internet"
           aria-describedby="sizing-addon1">
  </div><br>

  <label id="siret" class="required-star">Numéro SIRET</label>
  <div class="input-group input-group-lg">
    <span [@error]="error=='siret'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='siret'"class="secondary" >N°</span></span>
    <input id="siret"
           formControlName="siret"
           type="text"
           class="form-control"
           placeholder="Numéro SIRET"
           aria-describedby="sizing-addon1">
  </div>
  <small *ngIf="formErrors.siret" class="text-danger">
    {{ formErrors.siret }}
  </small><br>

  <label>Année immatriculation</label>
  <div id="creationYearAgency" class="input-group input-group-lg">
    <span [@error]="error=='creationYearAgency'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='creationYearAgency'" class="glyphicon secondary glyphicon-education" aria-hidden="true"></span></span>
    <input id="creationYearAgency"
           formControlName="creationYearAgency"
           type="number"
           min="0"
           class="form-control"
           placeholder="Année immatriculation"
           aria-describedby="sizing-addon1">
  </div>
  <small *ngIf="formErrors.creationYearAgency" class="text-danger">
    {{ formErrors.creationYearAgency }}
  </small><br>

  <label id="team-photo-label">Photographie de vous ou votre équipe</label><br>
  <div *ngIf="((agency.artisanImage||{}).cdnUrl|| '') != ''">
    <img src="{{agency.artisanImage.cdnUrl}}" alt="" class="thumb-nail">
  </div>
  <p class="uploader-secondary-color">
    <img src="" alt="">
    <input id="team-photo"
           type="hidden"
           role="uploadcare-uploader"
           name="team_photo"
           data-images-only
           data-crop="free"
           data-image-shrink="1000x1000"/><br>
  </p>
  <br>

  <label id="ca">Votre chiffre d'affaire</label>
  <div class="input-group input-group-lg">
    <span [@error]="error=='ca'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='ca'" class="secondary ">€</span></span>
    <input id="ca"
           formControlName="ca"
           type="number"
           min="0"
           step="1000"
           class="form-control"
           placeholder="Votre chiffre d'affaire"
           aria-describedby="sizing-addon1">
  </div>
  <small *ngIf="formErrors.ca" class="text-danger">
    {{ formErrors.ca }}
  </small><br>

  <label id="assurances">Avez-vous des certifications ?</label><br>
  <div class="input-group input-group-lg">
    <span [@error]="error=='assurances'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='assurances'" class="glyphicon secondary glyphicon-home" aria-hidden="true"></span></span>
    <p-autoComplete id="assurances"
                    placeholder = "Selectionnez dans la liste"
                    forceSelection="true"
                    styleClass="wid100"
                    formControlName="assurances"
                    [suggestions]="searchAssurances"
                    (completeMethod)="search($event,'searchAssurances','assurances','assurances')"
                    [field]="fieldname"
                    [dropdown]="true"
                    [multiple]="true"></p-autoComplete><br>
  </div>
  <small *ngIf="formErrors.assurances" class="text-danger">
    {{ formErrors.assurances }}
  </small><br>

  <label id="assuranceNumber">Assurance et N° de contrat</label>
  <div class="input-group input-group-lg">
    <span [@error]="error=='assuranceNumber'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='assuranceNumber'" class="secondary" aria-hidden="true">N°</span></span>
    <input id="assuranceNumber"
           formControlName="assuranceNumber"
           type="text"
           class="form-control"
           placeholder="Assurance et N° de contrat"
           aria-describedby="sizing-addon1" #adresse>
  </div>
  <small *ngIf="formErrors.assuranceNumber" class="text-danger">
    {{ formErrors.assuranceNumber }}
  </small><br>

</div>

</form>
