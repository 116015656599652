import { Component, OnInit , ViewChild, NO_ERRORS_SCHEMA} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
//Components
import { NgSharedModule } from 'src/app/all/ngshared.module';

import { ArtisanSignupFileComponent } from '../../../artisan/artisan-signup-file/artisan-signup-file.component';

//Services
import { MessageService } from 'primeng/api';
import { ArtisanService } from '../../../_services/index';

import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { HeaderComponent } from 'src/app/all/header/header.component';
declare var $:any;
@Component({
    selector: 'app-artisan-subscribe-file',
    imports:[
    SpinnerComponent,
    ArtisanSignupFileComponent,
    HeaderComponent,
    NgSharedModule
],
    providers: [],
    templateUrl: './artisan-subscribe-file.component.html',
    styleUrls: ['./artisan-subscribe-file.component.scss'],
    standalone: true,
    
})
export class ArtisanSubscribeFileComponent implements OnInit {
  public artisan: any;
  public loading:Boolean = false;
  @ViewChild(ArtisanSignupFileComponent) form: ArtisanSignupFileComponent
  constructor(private title: Title,
              private router: Router,
              private messageService: MessageService,
              private artisanService: ArtisanService,) {
    this.artisan = {
      files : [],
      photos : [],
      assuranceFile: []
    }
   }


  ngOnInit() {
    this.title.setTitle('BAM - S\'inscrire' );
    this.scrollToTop()
  }
  private scrollToTop(){
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }
  save(){
    this.loading = true;
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let artisanId = currentUser.artisanId;
    let isValid = this.form.isValid()
    let agencyData = this.form.getFormData();
    /// need At least 4 portfolio images
    if(agencyData.photos.length < 4){
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Formulaire incomplet', detail: 'Veuillez télécharger au moins 4 photos de portfolio', life: 5000 });
      this.loading = false;
      return false;
    }
    if(isValid && agencyData.photos.length > 3){
      this.artisanService.update(artisanId, agencyData)
          .subscribe(
              data => {
                  this.loading = false;
                  this.router.navigate(['artisan/project-list']);
                  this.messageService.add({ key: 'toast', severity: 'success', summary: 'Informations principales enregistrées', detail: 'Vos informations principales ont été enregistrées', life: 5000 });
              },
              error => {
                  console.log("error",error)
                  this.loading = false;
                  this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: error.message, life: 5000 });
              });
    }else{
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Formulaire incomplet', detail: "Veuillez remplir tous les champs obligatoires", life: 5000 });
      this.loading = false;
    }
  }

  }
