<div class="warning-demo" *ngIf="!hideWarningDemoDialog">
	<span class="close-warning-demo" (click)="closeWarningDemo()"><i class="fa fa-close"></i></span>
	<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
	Vous êtes actuellement en licence Essentiel gratuite, passez en licence Pro ou Intégral pour pouvoir créer des DCE de plus de 200 postes, accéder à des descriptifs pré-rédigés et créer votre propre bibliothèque de postes.<br>
	<a [routerLink]="['/architect','profil']" [queryParams]="{page: 'licence'}"> passez en licence Pro ou Intégral</a>
</div>
<header *ngIf="type == 'full'">
	<!-- [ngStyle]="{'position': isFixed ? 'fixed':'relative'}" -->
	<div [ngClass]="['main-header',cssClass]" >
    <div class="logo-holder">
  		<a *ngIf="logoUrl == 'app'" [routerLink]="['/architect/my-consultations']" class="logo archi-logo">
				<img src="/assets/rob/aglo_logo_round.png" alt="" class="logo-img">
  		</a>
			<a *ngIf="logoUrl == 'www'" href="https://www.aglo.ai/" class="logo">
				<img src="/assets/rob/aglo_logo_round.png" alt="" class="logo-img">
  		</a>
    </div>
		<div *ngIf="(authService.isArchitect() || authService.isCollaborateur()) && showNav" class="link-holder">
			<a [routerLink]="['/architect/my-consultations']" [ngClass]="(url=='/architect/my-consultations')?'one-link-decorated':'one-link'"><p>Projets</p></a>
			<a [routerLink]="['/architect/archi-db']" [ngClass]="(url=='/architect/archi-db')?'one-link-decorated':'one-link'" class="hide-min"><p>Bibliothèque</p></a>
			<a *ngIf="!authService.currentEnvironment" [routerLink]="['/architect/carnet-adresses']" [ngClass]="(url=='/architect/carnet-adresses')?'one-link-decorated':'one-link'" class="hide-min"><p>Carnet d’adresse</p></a>
			<a *ngIf="!authService.currentEnvironment" [routerLink]="['/architect/aglo-GPT']" [ngClass]="(url=='/architect/aglo-GPT')?'one-link-decorated':'one-link'" class="hide-min"><p>AgloGPT</p></a>
		</div>
		<div *ngIf="authService.isAdmin() && authService.currentEnvironment" class="link-holder">
			<div [routerLink]="['/admin/utilisateurs']" class="padding-left-48" [ngClass]="(url=='/admin/utilisateurs')?'one-link-decorated':'one-link'"><p class="cursor">Utilisateurs</p></div>
			<div [routerLink]="['/admin/departements']" class="padding-left-48" [ngClass]="(url=='/admin/departements')?'one-link-decorated':'one-link'"><p class="cursor">Départements</p></div>
		</div>
		<div *ngIf="licence=='without'" class="read-only-button">
			<p class="button-read">Lecture seule</p>
		</div>
		<div class="licence-remaining-days" *ngIf="initialDateEndTrial">
			<span>{{ initialDateEndTrial | remainingDays }} jours d’essai restant </span>
		</div>
		<!-- Right button -->
		<div class="padding-top-077-percent font-size-16 cursor" style="width:87%" *ngIf="authService.userLoggedIn && (authService.currentUser.artisanId || '') != ''" >
			<span [routerLink]="['/artisan','project-list']" class="padding-left-4point4 padding-right-63percent">Mes consultations</span> 
			<button (click)="goToBoosterPage()" id="go-to-booster-profile-btn">Profil AGLO BOOSTER©</button>
		</div>
    <div class="menu-contain">
			<!-- <button *ngIf="authService.userLoggedIn && (authService.currentUser.artisanId || '') != ''"
							class="choice btn nav-button-architect button-border-radius"
							[routerLink]="['/artisan','project-list']" >
				<i class="fa fa-users" aria-hidden="true"></i> Mes clients1
			</button> -->

			<button *ngIf="guide == 'client'" type="button" class="tablet choice btn nav-button" (click)="diplayHelp = true" >Guides</button>
      <button *ngIf="!authService.userLoggedIn" type="button" class="choice btn-menu" href="#" data-toggle="modal" data-target="#login-modal">Se connecter</button>
			<!-- Architect -->
			<div *ngIf="authService.isArchitect() || authService.isCollaborateur()" class="btn-group show-on-hover choice">
				<div class="upgrade-text-container" *ngIf="authService.userLoggedIn && authService.isArchitect() && (licence == 'base' || licence=='without')">
					<a href="" [routerLink]="['/architect','profil']" [queryParams]="{tab: 'licence'}">
						<p><img src="/assets/plane_icon.svg" /> Mise à niveau</p>
					</a>
				</div>

				<button type="button" class="btn-menu dropdown-toggle" data-toggle="dropdown" (click)="getUrl()">
					<span class="device">
						<img *ngIf="authService.currentUser.image" [src]="authService.currentUser.image" alt="">
						<div class="user-name"
								 *ngIf="!authService.currentUser.image"
								 [ngStyle]="{'border-color': authService.currentUser.color , 'color' :authService.currentUser.color  }">
							{{(authService.currentUser.firstName || "").substring(0,1) | capitalize}}
						</div>
						<span class="user-arrow fa fa-sort-desc"
							[ngStyle]="{'color' :authService.currentUser.color  }">
						</span>
					</span>
					<span class="desk">
						<i class="fa fa-bars" aria-hidden="true"></i>
					</span>
				</button>
				<ul class="dropdown-menu dropdown-menu-right archi-dropdown" role="menu">
					<li class="dropdown-element full-name-item">
						<p class="name">{{(authService.currentUser.firstName || "") | capitalize}} {{authService.currentUser.lastName || "" | capitalize}}</p>
						<p class="email">{{(authService.currentUser.email || "") }}</p>
						<p class="licence" *ngIf="authService.userLoggedIn && authService.isArchitect()">
							<span *ngIf="licence=='base'" class="badge badge-basic">Essentiel</span>
							<span *ngIf="licence=='plus'|| licence=='plus-trim' || licence=='plus-annuel'" class="badge badge-plus">Pro</span>
							<span *ngIf="(licence=='premium' || licence=='premium-annuel' || licence=='premiumn' || licence=='premiumn-annuel' ||licence=='premiumn-trim') && !dateEndTrial" class="badge badge-premium">Intégral</span>
							<span *ngIf="licence=='max' || licence=='max-trim' || licence=='max-annuel'" class="badge badge-premium">Max</span>
							<span *ngIf="(licence=='premium' || licence=='premium-annuel' || licence=='premiumn' || licence=='premiumn-trim'|| licence=='premiumn-annuel') && dateEndTrial" class="badge badge-premium">Essai gratuit</span>
							<span *ngIf="licence=='custom'" class="badge badge-premium">Sur-mesure</span>
						</p>
					</li>
					<!-- <li class="dropdown-element"><a [routerLink]="[ '/architect/my-consultations' ]" ><i class="glyphicon fa fa-suitcase" aria-hidden="true"></i>Projets</a></li> -->
					<!-- <li class="divider"></li> -->
					<!-- <li class="dropdown-element"><a [routerLink]="[ '/architect/carnet-adresses' ]"><span class="glyphicon glyphicon-list-alt" aria-hidden="true"></span>Carnet d'adresses</a></li>
					<li class="dropdown-element"><a [routerLink]="[ '/architect/archi-db' ]"><i class="glyphicon fa fa-database" aria-hidden="true"></i>Bibliothèques</a></li> -->
					<li class="dropdown-element"><a [routerLink]="[ '/architect/profil' ]" ><span class="glyphicon glyphicon-user" aria-hidden="true"></span>Profil</a></li>
					<!-- <li class="dropdown-element"><a [routerLink]="[ '/architect/parrainage' ]" ><i class="glyphicon fa fa-share" aria-hidden="true"></i>Parrainage</a></li> -->
					<li *ngIf="!authService.currentEnvironment"  class="dropdown-element"><a href="https://www.aglo.ai/documentation/" target="_blank"><i class="glyphicon fa fa-question-circle" aria-hidden="true"></i>Guide</a></li>
					<li class="divider"></li>
					<li class="dropdown-element"><a (click)="logout()"><span class="glyphicon glyphicon-log-out" aria-hidden="true"></span>Se déconnecter</a></li>
				</ul>
			</div>
			<!-- -->
			<!-- Artisan -->
			<div *ngIf="authService.userLoggedIn && (authService.currentUser.artisanId || '') != ''" class="btn-group show-on-hover choice">
				<button type="button" class="btn-menu dropdown-toggle" data-toggle="dropdown" (click)="getUrl()">
					<span class="device user-name"
						[ngStyle]="{'border-color': authService.currentUser.color , 'color' :authService.currentUser.color  }">
						{{(authService.currentUser.firstName || "").substring(0,1) | capitalize}}
					</span>
					<span class="user-arrow fa fa-sort-desc"
						[ngStyle]="{'color' :authService.currentUser.color  }">
					</span>

					<span class="desk">
						<i class="fa fa-bars" aria-hidden="true"></i>
					</span>
				</button>
				<ul class="dropdown-menu dropdown-menu-right" role="menu">
					<li class="dropdown-element full-name-item flex padding-20-important">
						<div class="flex" style="align-items: center;">
							<span class="device user-name circle-name flex"
							[ngStyle]="{'border-color': authService.currentUser.color , 'color' :authService.currentUser.color  }">
						<div class="center-firstletter">	
							{{(authService.currentUser.firstName || "").substring(0,1) | capitalize}}
						</div>
						</span>
						<div class="padding-left-20 padding-top-5" style="text-align: start;">
							<p class="name">{{(authService.currentUser.firstName || "") | capitalize}} {{authService.currentUser.lastName || "" | capitalize}}</p>
							<p class="pointer" [routerLink]="['/artisan','project-list']" style="font-size:12px;text-align: start;color:#0A1747;" >Voir le portfolio</p>
						</div>	
						<!-- <p class="email">{{(authService.currentUser.email || "") }}</p> -->
					</div>
					</li>
					<!-- <li class="dropdown-element"><a [routerLink]="['/artisan','project-list']" ><i class="glyphicon fa fa-users" aria-hidden="true"></i> Mes clients</a></li> -->
					<!-- <li class="dropdown-element"><a [routerLink]="[ '/artisan-profil/' + authService.currentUser.artisanId ]" ><span class="glyphicon glyphicon-user" aria-hidden="true"></span>Voir mon profil</a></li> -->
					<!-- <li class="divider"></li> -->
					<li class="dropdown-element font-size-14"><a [routerLink]="[ '/artisan/edit/profil']" ><img class="padding-right-15" src="assets/parameter-icon.svg" >Paramètres</a></li>
					<li class="dropdown-element font-size-14"><a href="https://www.aglo.ai/documentation/artisans-entreprises/" target="_blank"><img class="padding-right-15" src="assets/guide-icone.svg">Guide d’utilisation</a></li>
					<!-- <li class="divider"></li> -->
					<li class="dropdown-element font-size-14"><a (click)="logout()"><img class="padding-right-6" src="assets/logout-icone.svg"> Me déconnecter</a></li>
				</ul>
				<!-- <ul class="dropdown-menu dropdown-menu-right" role="menu">
					<li class="dropdown-element full-name-item">
						<p class="name">{{(authService.currentUser.firstName || "") | capitalize}} {{authService.currentUser.lastName || "" | capitalize}}</p>
						<p class="email">{{(authService.currentUser.email || "") }}</p>
					</li>
					<li class="dropdown-element"><a [routerLink]="['/artisan','project-list']" ><i class="glyphicon fa fa-users" aria-hidden="true"></i> Mes clients</a></li>
					<li class="dropdown-element"><a [routerLink]="[ '/artisan-profil/' + authService.currentUser.artisanId ]" ><span class="glyphicon glyphicon-user" aria-hidden="true"></span>Voir mon profil</a></li>
					<li class="divider"></li>
					<li class="dropdown-element"><a [routerLink]="[ '/artisan/edit/profil']" ><span class="glyphicon glyphicon-list" aria-hidden="true"></span>Editer mon profil</a></li>
					<li class="divider"></li>
					<li class="dropdown-element"><a (click)="logout()"><span class="glyphicon glyphicon-log-out" aria-hidden="true"></span>Se déconnecter</a></li>
				</ul> -->
			</div>
			<!-- -->
			<!-- Admin -->
			<div *ngIf="authService.userLoggedIn && (authService.currentUser.isAdmin)" class="btn-group show-on-hover choice">
				<button type="button" class="btn-menu dropdown-toggle" data-toggle="dropdown" (click)="getUrl()">
					<span class="device user-name"
						[ngStyle]="{'border-color': authService.currentUser.color , 'color' :authService.currentUser.color  }">
						{{(authService.currentUser.firstName || "").substring(0,1) | capitalize}}
					</span>
					<span class="user-arrow fa fa-sort-desc"
						[ngStyle]="{'color' :authService.currentUser.color  }">
					</span>

					<span class="desk">
						<i class="fa fa-bars" aria-hidden="true"></i>
					</span>
				</button>
				<ul class="dropdown-menu dropdown-menu-right" role="menu">
					<li class="dropdown-element full-name-item">
						<p class="name">{{(authService.currentUser.firstName || "") | capitalize}} {{authService.currentUser.lastName || "" | capitalize}}</p>
						<p class="email">{{(authService.currentUser.email || "") }}</p>
					</li>

					<li class="dropdown-element"><a (click)="logout()"><span class="glyphicon glyphicon-log-out" aria-hidden="true"></span>Se déconnecter</a></li>
				</ul>
			</div>
			<!-- Admin -->


    </div>
	</div>
</header>


<header class="light-header" *ngIf="type == 'light'">
	<img class="light-logo" [routerLink]="['/architect/my-consultations']"  src="assets/rob/aglo_logo_round.png" alt="">
	<div class="menu-contain">
		<button *ngIf="guide == 'client'" type="button" class="tablet choice btn nav-button" (click)="diplayHelp = true" >Guides</button>
		<button *ngIf="!authService.userLoggedIn" type="button" class="choice btn-menu" href="#" data-toggle="modal" data-target="#login-modal">Se connecter</button>
		<!-- Architect -->
		<button *ngIf="authService.userLoggedIn && authService.isArchitect() && licence == 'base'"
						class="choice btn nav-button-architect button-border-radius"
						[routerLink]="['/architect','profil']" [queryParams]="{tab: 'licence'}">
			<i class="fa fa-fighter-jet" aria-hidden="true"></i> Mise à niveau
		</button>
		<div *ngIf="authService.isArchitect() || authService.isCollaborateur()" class="btn-group show-on-hover choice">
			<button type="button" class="btn-menu btn-menu-full dropdown-toggle" data-toggle="dropdown" (click)="getUrl()">
				<span class="device">
					<img *ngIf="authService.currentUser.image" [src]="authService.currentUser.image" alt="">
					<div class="user-name"
							 *ngIf="!authService.currentUser.image"
							 [ngStyle]="{'border-color': authService.currentUser.color , 'color' :authService.currentUser.color  }">
						{{(authService.currentUser.firstName || "").substring(0,1) | capitalize}}
					</div>
					{{authService.currentUser.firstName || "" | capitalize}} {{authService.currentUser.lastName || "" | capitalize}} <span class="caret"></span>
					<span *ngIf="licence=='base'" class="badge badge-basic">Essentiel</span>
					<span *ngIf="licence=='plus' || licence=='plus-annuel' || licence=='plus-trim'" class="badge badge-plus">Pro</span>
					<span *ngIf="licence=='premium' || licence=='premium-annuel'" class="badge badge-premium">Intégral</span>
					<span *ngIf="licence=='custom'" class="badge badge-premium">Sur-mesure</span>
				</span>
				<span class="desk">
					<i class="fa fa-bars" aria-hidden="true"></i>
				</span>
			</button>
			<ul class="dropdown-menu dropdown-menu-right" role="menu">
				<li class="dropdown-element"><a [routerLink]="[ '/architect/my-consultations' ]" ><i class="glyphicon fa fa-suitcase" aria-hidden="true"></i>Projets</a></li>
				<li class="divider"></li>
				<li class="dropdown-element"><a [routerLink]="[ '/architect/carnet-adresses' ]"><span class="glyphicon glyphicon-list-alt" aria-hidden="true"></span>Mon carnet d'adresses</a></li>
				<li class="dropdown-element"><a [routerLink]="[ '/architect/archi-db' ]"><i class="glyphicon fa fa-database" aria-hidden="true"></i>Bibliothèques</a></li>
				<li class="dropdown-element"><a [routerLink]="[ '/architect/profil' ]" ><span class="glyphicon glyphicon-user" aria-hidden="true"></span>Profil</a></li>
				<li class="dropdown-element"><a [routerLink]="[ '/architect/parrainage' ]" ><i class="glyphicon fa fa-share" aria-hidden="true"></i>Parrainage</a></li>
				<li *ngIf="!authService.currentEnvironment" class="dropdown-element"><a href="https://aglo.tawk.help/" target="_blank"><i class="glyphicon fa fa-question-circle" aria-hidden="true"></i>FAQ</a></li>
				<li class="divider"></li>
				<li class="dropdown-element"><a (click)="logout()"><span class="glyphicon glyphicon-log-out" aria-hidden="true"></span>Se déconnecter</a></li>
			</ul>
		</div>
		<!-- -->
		<!-- Artisan -->
		<div *ngIf="authService.userLoggedIn && (authService.currentUser.artisanId || '') != ''" class="btn-group show-on-hover choice">
			<button type="button" class="btn-menu btn-menu-full dropdown-toggle" data-toggle="dropdown" (click)="getUrl()">
				<span class="device">
					{{authService.currentUser.firstName || "" | capitalize}} {{authService.currentUser.lastName || "" | capitalize}} <span class="caret"></span>
				</span>
				<span class="desk">
					<i class="fa fa-bars" aria-hidden="true"></i>
				</span>
			</button>
			<ul class="dropdown-menu dropdown-menu-right" role="menu">
				<li class="dropdown-element full-name-item">
					<p>{{(authService.currentUser.firstName || "") | capitalize}} {{authService.currentUser.lastName || "" | capitalize}}</p>
				</li>
				<li class="dropdown-element"><a [routerLink]="['/artisan','project-list']" ><i class="glyphicon fa fa-users" aria-hidden="true"></i> Mes clients</a></li>
				<li class="dropdown-element"><a [routerLink]="[ '/artisan-profil/' + authService.currentUser.artisanId ]" ><span class="glyphicon glyphicon-user" aria-hidden="true"></span>Voir mon profil</a></li>
				<li class="divider"></li>
				<li class="dropdown-element"><a [routerLink]="[ '/artisan/edit/profil']" ><span class="glyphicon glyphicon-list" aria-hidden="true"></span>Editer mon profil</a></li>
				<li class="divider"></li>
				<li class="dropdown-element"><a (click)="logout()"><span class="glyphicon glyphicon-log-out" aria-hidden="true"></span>Se déconnecter</a></li>
			</ul>
		</div>
		<!-- -->
	</div>
</header>


<app-login-modal></app-login-modal>
<app-architect-three-ds-modal *ngIf="stripeUrl" [url]='stripeUrl'></app-architect-three-ds-modal>
