import { Component, OnInit, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from '../ngshared.module';

@Component({
  standalone:true,
  selector: 'app-confirm-modal',
  imports:[NgSharedModule],
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  @Input() modalId: string="";
  @Input() headerTitle: string="";
  @Input() bodyText: string="";
  @Input() confirmButtonLabel: string="confirmer";
  @Input() cancelButtonLabel: string="Annuler";
  @Output() confirm: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public emitConfirm(){
    this.confirm.emit({});
  }

}
