import { Helpers } from '../../../_services/helpers';
export class LoggsClass{
  public getIncide(number){
    if(number){
      var n = number-1;
      var ordA = 'a'.charCodeAt(0);
      var ordZ = 'z'.charCodeAt(0);
      var len = ordZ - ordA + 1;
      var s = "";
      while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
      }
      return s.toUpperCase();
    }
  }
  public trasnform = {
    download_dce : (log) => {
      return `L’enteprise <strong class="strong">${(log.artisan||{}).name} </strong> a téléchargé le DCE.`
    },
    admin_invite_artisan_plus : (log) => {
      return `L’enteprise <strong class="strong"> ${(log.artisan||{}).name}</strong>   a été ajoutée à la consultation via AGLO Booster.`
    },
    admin_invite_artisan : (log) => {
      return `L’enteprise <strong class="strong">${(log.artisan||{}).name}</strong>   a été ajoutée à la consultation. `
    },
    create_invite : (log)=>{
      return `<strong class="strong"> ${(log.userName||(log.user||{}).firstName+' '+(log.user||{}).lastName)}</strong>  a ajouté cette entreprise à la consultation`
    },
    send_reminder : (log)=>{
      return `<strong class="strong" >${(log.userName||(log.user||{}).firstName+' '+(log.user||{}).lastName)}</strong>  a envoyé un mail à cette entreprise`
    },
    artisan_accept_invite: (log)=>{ 
      return `<strong class="strong">${(log.userName||(log.user||{}).firstName+' '+(log.user||{}).lastName)}</strong>  a accepté le projet`
    },
    artisan_refuse_invite: (log)=>{
      return `<strong class="strong">${(log.userName||(log.user||{}).firstName+' '+(log.user||{}).lastName)}</strong>  a refusé le projet`
    },
    send_dce: (log)=>{
      let agencyName = (log.artisan||{}).name;
      let facticeArtisanName = (log.ceProjectInvite||{}).artisanName;
      let artisanName = agencyName || facticeArtisanName; 
      return `<strong class="strong">${(log.userName||(log.user||{}).firstName+' '+(log.user||{}).lastName)}</strong> a partagé le DCE à <strong class="strong">${artisanName}</strong>.`
    },
    set_status: (log)=>{
      let helpers:any ;
      helpers = new Helpers();
      let oldStatus:string;
      let currentStatus:string;
      if(log.oldStatus){
        oldStatus = helpers.getCeProjetInviteStatus(log.oldStatus,log.oldPricingStatus || log.ceProjectInvite.pricingStatus)
        oldStatus = helpers.pricingStatusLabel(oldStatus)
      }
      let col = helpers.getCeProjetInviteStatus(log.status, log.pricingStatus || log.ceProjectInvite.pricingStatus)
      currentStatus = helpers.pricingStatusLabel(col)
      // console.log('col',col)
      // console.log('oldStatus',oldStatus)
      // console.log('currentStatus',currentStatus)

      // if(log.isFactice){
      //   let allStatus = {
      //     'selectionne':'Sélectionné',
      //     'artisan-invite':log.pricingStatus=='pricing-sent'?'En cours':'Chiffrage reçu',
      //     'artisan-accepter':log.pricingStatus=='pricing-sent'?'En cours':'Chiffrage reçu',
      //     'refuse':'Ne chiffre pas'
      //   }
      //   oldStatus = allStatus[log.oldStatus]
      //   currentStatus = allStatus[log.status]
      // }else{
      //   let allStatus = {
      //   'selectionne':'Sélectionné',
      //   'artisan-invite':log.pricingStatus=='pricing-sent'?'En attente':'Chiffrage reçu',
      //   'artisan-accepter':log.pricingStatus=='pricing-sent'?'En cours':'Chiffrage reçu',
      //   'refuse':'Ne chiffre pas'
      //   }
      //   oldStatus = allStatus[log.oldStatus]
      //   currentStatus = allStatus[log.status]
      // }
      if(col=='offre_remise' && log.status=='selectionne'){
        return `<strong class="strong">${(log.userName||(log.user||{}).firstName+' '+(log.user||{}).lastName)}</strong> a sélectionné le chiffrage.`
      }else if(col=='offre_remise' && log.status=='refuse'){
        return `<strong class="strong">${(log.userName||(log.user||{}).firstName+' '+(log.user||{}).lastName)}</strong> a refusé le chiffrage.`
      }
      if(oldStatus==currentStatus) {
        return;
      }else{
        return `<strong class="strong">${(log.userName||(log.user||{}).firstName+' '+(log.user||{}).lastName)}</strong> a changé le statut de  ${oldStatus} à  ${currentStatus}.`
      }
    },
    update_pricing_dce_version: (log)=>{
      return `<strong class="strong">${(log?.user?.firstName||'')} ${(log?.user?.lastName||'')}</strong> a envoyé le DCE mis à jour (Indice.${this.getIncide((log.params||{}).indice)}) à l'entreprise ${ (log?.artisan?.name || '') }.`
    },
    finish_dce_pricing: (log)=>{
      log.message = (log.message || "").replace(/\n/g,'<br/>');
      let agencyMessage = log.message != '' ? `Message de l'entreprise : <br/> ${log.message||''}` : '';
      let isFactice = log.ceProjectInvite.isFactice;
      let agencyName = (log.artisanName && log.artisanName != '') ? log.artisanName : (log.artisan||{}).name;
      if(isFactice){
        agencyName = log.ceProjectInvite.artisanName;
      }

      if(agencyMessage != ''){
        return `L'entreprise <strong class="strong">${ agencyName }</strong> a envoyé son chiffrage version v.${(log.params||{}).indice ||""}.<br/>
        ${agencyMessage}
      `
      }else {
        return `L'entreprise <strong class="strong">${ agencyName }</strong> a envoyé son chiffrage version v.${(log.params||{}).indice ||""}.`
      }

    },
  }
}
