<div id="expired-licence"
  class="modal fade expired-licence"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog no-padding">
    <div class="modal-container no-padding">
      <p class="upgrade-title">Votre essai gratuit Intégral vient de se terminer.</p>
      <p class="upgrade-sub-title">Que se passe-t-il maintenant ?</p>
      <div class="detail-container">
        Pas d'inquiétude, toutes vos données restent sauvegardées, accessibles et exportables depuis AGLO.
        <br><br>
        Vous ne pouvez plus :
        <ul>
          <li>Accéder aux fonctionnalités de la licence Intégral</li>
          <li>Créer un projet ou modifier un projet existant</li>
          <li>Modifier votre bibliothèque ou carnet d'adresses</li>
          <li>Vous pouvez bien sûr souscrire à une licence Pro ou Intégral à tout moment et retrouver toutes vos données dans l'application.</li>
        </ul>
      </div>
        <div class="button-container">
          <button class="b2" data-dismiss="modal">Compris</button>
        </div>
    </div>
  </div>
</div>