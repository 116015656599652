import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CeProjectInvitesService } from 'src/app/_services';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { NewArchitectArtisansPopupComponent } from 'src/app/dpgf/dce-artisan-management/new-architect-artisans-popup/new-architect-artisans-popup.component';

@Component({
  selector: 'app-enterprises-invite-columns',
  standalone: true,
  imports: [
    NgSharedModule,
    NewArchitectArtisansPopupComponent
  ],
  templateUrl: './enterprises-invite-columns.component.html',
  styleUrl: './enterprises-invite-columns.component.scss'
})
export class EnterprisesInviteColumnsComponent {
  @Input()invites:any = [];
  @Input()canAdd:Boolean;
  @Input()allArtisans:any = {
    "a_consulter": [],
    "en_attente": [],
    "en_cours": [],
    "offre_remise": [],
    "ne_chiffre_pas": [],
  };
  @Input()project:any = {};
  @Input()projectId :String ="" ;
  @Input()dpgf :any ="";
  @Input()collabs :any = [];
  
  @Output() openArtisanModal = new EventEmitter<any>();

  public numberOfSignedAgencies: number = 0;

  constructor(
    private ceProjectInvitesService: CeProjectInvitesService,
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.allArtisans && changes.allArtisans.firstChange){
      this.setNumberOfSignedAgencies();
    } 
  }

  public showTooltip: boolean = false;
  public numberOfPricingLots=0;
  public columnsList: any[] = [
    {label: 'À CONSULTER', value: 'a_consulter', dataSource: 'a_consulter'},
    {label: 'EN ATTENTE', value: 'en_attente', dataSource: 'en_attente'},
    {label: 'EN COURS', value: 'en_cours', dataSource: 'en_cours'},
    {label: 'OFFRE REMISE', value: 'offre_remise', dataSource: 'offre_remise', icon: '/assets/enterprises-icons/medal-icon.svg'},
    {label: 'NE CHIFFRE PAS', value: 'ne_chiffre_pas', dataSource: 'ne_chiffre_pas'},
  ];
  
  public onColumnIconClicked(value){
  }
  public setNumberOfSignedAgencies(){
    const { en_cours, offre_remise, } = this.allArtisans;

    let allInvites = [...en_cours, ...offre_remise];
    
    for(let i = 0; i < allInvites.length; i++){
      const { status } = allInvites[i];
      if(status == 'signe'){
        this.numberOfSignedAgencies++;
      }
    }
  }
  
  public emitOpenArtisanModal(invitation, column){
    let dpgfPricingLots=invitation.dpgfPricing?.data;
    let openArchitectArtisanPopUP=true;
    if(dpgfPricingLots){
      this.updateAssignedLot(dpgfPricingLots );
      if(invitation.deliveryDate){
        invitation.deliveryDate=new Date(invitation.deliveryDate);
      }
    }
    if(invitation.pricingStatus=='pricing-done' && invitation.status!='signe' ){
      this.getSentPricingDate(invitation);
    }
    this.openArtisanModal.emit({invitation, column,numberOfPricingLots:this.numberOfPricingLots, openArchitectArtisanPopUP});
  } 

  private getSentPricingDate(invitation){
    let finishDcePricingArray=[];
    let countFinishDcePricing=0;
    this.ceProjectInvitesService.loadArchiLogs(invitation._id).subscribe(
      data => {
        for(let i=0; i<data.length;i++){
          if(data[i].type=='finish_dce_pricing'){
            finishDcePricingArray.push(data[i]);
            countFinishDcePricing++;
          }
        }
        for(let j=0; j<finishDcePricingArray.length;j++){
          if(finishDcePricingArray[j].params.indice==countFinishDcePricing){
            invitation.sentPricingDate=finishDcePricingArray[j].createdAt;
          }
        }
      },
      err => {
       // this.messageService.add({ key: 'toast', severity: 'danger', summary: "Loading Mails", detail: "Mails Loading Failed", life: 5000 });
      }
    )
  }
// assignedLots,
  private updateAssignedLot( dpgfPricingLots) {
    let pricingLots = dpgfPricingLots.filter(lot => lot.total !== 0);
    this.numberOfPricingLots = pricingLots.length;
  }

}
