import { Component, OnInit } from '@angular/core';
//import { ArchitectModule } from '../architect.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    selector: 'app-archi-subscribe-question',
    imports:[
      
      NgSharedModule
    ],
    templateUrl: './archi-subscribe-question.component.html',
    styleUrls: ['./archi-subscribe-question.component.scss'],
    standalone: true,

})
export class ArchiSubscribeQuestionComponent implements OnInit {
  public choice = "bam";
  constructor() { }

  ngOnInit() {
  }

}
