import { Component, OnInit } from '@angular/core';
import { ActivatedRoute , Params } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { NgSharedModule } from 'src/app/all/ngshared.module';

// Services Imports
import { DpgfPostesService, AuthService } from '../../../_services/index';
//import { ArchitectModule } from '../architect.module';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { CeProjectNavComponent } from 'src/app/dpgf/global/ce-project-nav/ce-project-nav.component';
import { NeedSubscriptionPopup2Component } from 'src/app/architect/need-subscription-popup2/need-subscription-popup2.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-archi-dpgf-result',
    templateUrl: './archi-dpgf-result.component.html',
    styleUrls: ['./archi-dpgf-result.component.scss'],
    standalone: true,
    imports:[      
      HeaderComponent,
      CeProjectNavComponent,
      NeedSubscriptionPopup2Component,
      NgSharedModule
    ]
})
export class ArchiDpgfResultComponent implements OnInit {
  public dpgfId:string;
  public projectId:string;
  public loadingData:boolean;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private title: Title,
    private authService: AuthService,
    private DpgfPostesService: DpgfPostesService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.noMultiAccountsAllowed();
    this.title.setTitle('Aglo - Editer Mon DPGF' );
    // this.getCeProjectId();
    this.currentRoute();
    if(!environment.onPremise)window.analytics.page('pageview_chiffrage');
  }
  private currentRoute(){
    this.loadingData = true;
    this.getCeProjectId();
    this.route.params.forEach((params: Params) => {
      if (params['dpgfId']) {
        let dpgfId = params['dpgfId'];
        this.dpgfId = dpgfId;
      }
    });
  }
  private getCeProjectId(){
    this.subscriptions.push(
      this.DpgfPostesService.selectedProjectId
        .subscribe(
          data => {
            this.projectId = data;
            this.loadingData = false;
          },
          error => {
            console.log('getCeProjectId : ', error);
            this.loadingData = false;
          },
        )
    )
  }
  private noMultiAccountsAllowed(){
    this.subscriptions.push(
      this.authService.noMultiAccountsAllowed()
        .subscribe(
          data => {
            
          }  
        )
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }
}
