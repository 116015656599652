import { Component, OnInit, ViewChild, Input, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services'
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  standalone:true,
  selector: 'app-ce-project-nav',
  imports:[DpgfModule,NgSharedModule],
  templateUrl: './ce-project-nav.component.html',
  styleUrls: ['./ce-project-nav.component.scss']
})
export class CeProjectNavComponent implements OnInit {

  @Input() projectId : string;
  @Input() selected : string;
  @Input() disable : string;
  @Input() DPGFId : any;
  @Input() baseRoot : string = "architect";
  @Input() devisNumber : number =0;
  public tableEntry = [
    {id: "projet", name : "Accueil", link:'', icon:"fa fa-tachometer" , image: "home.png"},
    // {id: "detail", name : "DÉTAIL", link:'', icon:"fa fa-cogs" , image: "notepad.png"},
    {id: "dpgf", name : "DCE", link:'', icon:"fa fa-folder-open-o" , image: "files.png"},
    {id: "artisan", name : "Entreprises", link:'/artisan', icon:"fa fa-user-o" , image: "group.png"},
    {id: "devis", name : "Chiffrages", link:'', icon:"fa fa-bar-chart" , image: "invoice.png"},
    {id: "payement", name : "Chantier", link:'', icon:"fa fa-cube" , image: "ruler_square.png", isBeta :true},
    {id: "acv", name : "ACV", link:'', icon:"fa fa-leaf" , image: "ruler_square.png"},
  ];
  constructor( private authService: AuthService,
               private router: Router ) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
        if (changes['projectId'] && this.projectId) {
            this.authService.getProjectLicence(this.projectId)
        }
    }
  public navigation (index) {
    if(!this.disable){
      this.selected = this.tableEntry[index].id
      if (this.selected == "detail") {
        this.router.navigate(['/',this.baseRoot,'modify-ce-project' , this.projectId]);
      } else if(this.selected == "artisan") {
        this.router.navigate(['/',this.baseRoot,'ce-project', this.projectId , 'artisan']);
      } else if(this.selected == "payement") {
        this.router.navigate(['/',this.baseRoot,'ce-project',this.projectId,'payement']);
      } else if(this.selected == "projet") {
        this.router.navigate(['/',this.baseRoot,'ce-project', this.projectId ]);
      } else if(this.selected == "dpgf") {
        if(this.DPGFId){
          this.router.navigate(['/',this.baseRoot,'dpgf', this.DPGFId ]);
        }else{
          this.router.navigate(['/',this.baseRoot,'new-dpgf',this.projectId])
        }
      }else if(this.selected == "devis") {
        if(this.DPGFId){
          this.router.navigate(['/',this.baseRoot,'dpgf', this.DPGFId, "result", "comparatif"]);
        }else{
          this.router.navigate(['/',this.baseRoot,'new-dpgf',this.projectId])
        }
      }
      else if(this.selected == "acv"){
        if(this.projectId){
          this.router.navigate(['/',this.baseRoot,'ce-project', this.projectId, "acv"]);
        }
      }

    }
  }

}
