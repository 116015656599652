<div class='row'>
  <div *ngIf="invites.projects.length == 0 ">
    <h3 class="center licence" *ngIf="invites.state=='en cours'">Merci pour la création de votre compte. Vous verrez apparaître ici les projets sur lesquels vous êtes invité à chiffrer. </h3>
    <h3 class="center licence" *ngIf="invites.state=='archive'">Vous n'avez aucun projet archivé pour le moment. </h3>
    <img class="image-all" src="/assets/rob/empty.svg" style="padding-top:40px;" height="280" alt="">
    <!-- <iframe class="iframe-call" src="https://bamarchi.typeform.com/to/cqigGF" width="" height=""></iframe> -->
  </div>

  <div *ngFor="let invite of invites.projects" class="col-md-3 col-sm-4 col-xs-6 espace">
      <div class="card scale-up" style="padding:0;object-fit:cover;position:relative" [routerLink]="['/','artisan','project',invite._id]">
        <img class="card-img-top" style="width: 100%;height: 190px;" [src]="invite?.ceProject?.mainImage || 'https://s3.eu-central-1.amazonaws.com/bam-consultation-slickshot/22698xu9urkjo4uqwj%2F13787886-architectural-project-architect-rolls-and-plans.jpg'">
        <div class="card-title">
          {{invite.architect.name|capitalize}}
        </div>
        <div class="card-sub-title" style="padding-bottom: 5px;">
          {{invite.ceProject.adresse}}
        </div>
        <div class="absolute-position wid100">
        <div  class="margin-top-14" style="background-color:#DBDDE1;width: 86%;height: 1px;margin-left: 19px;"></div>
        <div class=" flex" style="justify-content: flex-end;padding-top:13px;padding-bottom: 15px;">
          <div class="tag" *ngIf="invite.status=='selectionne'" [ngClass]="invite.status">
            {{matching[invite.status]}}
          </div>
          <div class="tag" *ngIf="invite.status=='artisan-refuser'" [ngClass]="invite.status">
            {{matching[invite.status]}}
          </div>
          <div class="tag" *ngIf="invite.status=='refuse'" [ngClass]="invite.status">
            {{matching[invite.status]}}
          </div>
          <div class="tag" *ngIf="invite.statusChantier" [ngClass]="invite.statusChantier">
            {{matching[invite.statusChantier]}}
          </div>
          <div class="tag" *ngIf="invite.status=='artisan-accepter' && invite.pricingStatus!='pricing-done'" [ngClass]="invite.status">
            {{matching[invite.status]}}
          </div>
          <div class="tag" *ngIf="invite.status=='artisan-accepter' && invite.pricingStatus=='pricing-done'" [ngClass]="invite.pricingStatus">
            {{matching[invite.pricingStatus]}}
          </div>
          <div class="tag" *ngIf="invite.status=='signe' && !invite.statusChantier" [ngClass]="invite.status">
            {{matching[invite.status]}}
          </div>
          <div class="tag" *ngIf="invite.status=='artisan-invite'" [ngClass]="invite.status">
            {{matching[invite.status]}}
          </div>
        </div>
        </div>
        <!-- <div class="row">
          <div class="tag" [ngClass]="invite.status">
            {{matching[invite.status]}}
          </div>
          <div class="col-xs-12 card-title">
            {{invite.architect.name|capitalize}}
          </div>
          <div class="col-xs-12 card-sub-title project-title">
            {{invite.ceProject.title}}
          </div>
          <div class="col-xs-12 card-sub-title">
            {{invite.ceProject.adresse}}
          </div>
        </div>
        <div class="open-btn">
          <button  class="login btn-primary-border">
            Ouvrir
          </button>
        </div> -->

      </div>
  </div>
</div>
