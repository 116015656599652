<div class="slider-container" *ngIf="isPost">
  <div class="header">
    <div class="row" *ngIf="adding">
      <h2 class="center" style="height: 21px;">
        <i
          class="click-it fa fa-times"
          aria-hidden="true"
          (click)="closePostSlider()"
        ></i>
        <div class="title-lot">
          <span [hidden]="editing || (lot && lot.archiId!=archiId)" (click)="activateTitleInput()">
            {{ postTitle || 'Titre du poste' }}
          </span>
          <span *ngIf="lot && lot.archiId!=archiId">{{ postTitle }}</span>
          <span [hidden]="!editing">
            <input class="title-input" type="text" [(ngModel)]="postTitle" (focusout)="editing=false" (keydown.enter)="editing=false">
          </span>
        </div>
        <ng-template [ngIf]="value === 'CSTB' && !lodingCstb">
          <button *ngIf="choosedCstb" type="button" class="btn-valdiate" (click)="generateCctp('add')">
            Ajouter
          </button>
          <button *ngIf="!choosedCstb" type="button" class="btn-valdiate" (click)="validateCctp()">
            Valider
          </button>
          <button *ngIf="(post.cctp||'') != '' && (post && post.archiId==archiId || !post?.title) && choosedCstb" type="button" class="btn-valdiate" (click)="generateCctp('replace')">
            Éscraser
          </button>
        </ng-template>
        <ng-template [ngIf]="value != 'CSTB' && adding && post && (post && post.archiId==archiId || !post?.title)">
          <button *ngIf="!addingPost" type="submit" form="postForm" class="btn-valdiate">
            Ajouter le poste
          </button>
          <div class="add-post-spinner" *ngIf="addingPost">
            <app-spinner ></app-spinner>
          </div>
        </ng-template>
      </h2>
    </div>
    <div class="row" style="padding-top: 10px" *ngIf="!adding">
      <div class="title-container">
        <i
          class="click-it fa fa-times fa-lg x-button"
          aria-hidden="true"
          (click)="closePostSlider()"
        ></i>
        <div class="title-lot" *ngIf="post">
          <!-- <p [(ngModel)]="postTitle">{{ post.title }}</p> -->
          <span *ngIf="!(editing || (lot && lot.archiId!=archiId))" (click)="activateTitleInput()" class="title-span">
            {{ postTitle }}
          </span>
          <span *ngIf="lot && lot.archiId!=archiId">{{ postTitle }}</span>
          <span *ngIf="editing" style="display: inline-block;width: 500px;">
            <input class="title-input" type="text" [(ngModel)]="postTitle" (focusout)="editing=false" (keydown.enter)="editing=false" style="width: 100% !important;">
          </span>
        </div>
          <div *ngIf="value === 'CSTB' && !lodingCstb && (post && post.archiId==archiId || !post?.title)" style="display:flex;float:left;margin-left:auto;margin-top: 4px;">
            <button *ngIf="choosedCstb" type="button" class="btn-valdiate" (click)="generateCctp('add')">
              Ajouter
            </button>
            <button *ngIf="!choosedCstb" type="button" class="btn-valdiate" (click)="validateCctp()" [disabled]="!cstbToImport">
              Valider
            </button>
            <button *ngIf="(post.cctp||'') != '' && choosedCstb" type="button" class="btn-valdiate" (click)="generateCctp('replace')" style="margin-left: 10px;">
              Éscraser
            </button>
          </div>
          <ng-template [ngIf]="value != 'CSTB' && !adding && (post && post.archiId==archiId || !post?.title)">
            <button *ngIf="!addingPost" type="submit" form="postForm" class="btn-valdiate" style="position: relative;top: 4px;">
              Enregistrer
            </button>
            <div class="add-post-spinner" *ngIf="addingPost" style="float:right;margin-left:auto;">
              <app-spinner ></app-spinner>
            </div>
          </ng-template>
      </div>

    </div>
  </div>
  <div class="post-form">
    <div class="row switch cont" *ngIf="post && post.archiId==archiId">
      <div class="switch-toggle switch-3 switch-candy">
        <div class="choice" (click)="onSelectionChange('statique')"  [ngClass]="{'selectd-choice' : value === 'statique'}">
          Caractéristiques
        </div>
        <!-- <div class="choice" (click)="onSelectionChange('CCTP');presetFields()" [ngClass]="{'selectd-choice' : value === 'CCTP'}">
          CCTP
        </div> -->
        <div *ngIf="(post && post.archiId==archiId || !post?.title)" class="choice" (click)="onSelectionChange('CSTB');presetFields()" [ngClass]="{'selectd-choice' : value === 'CSTB'}">
          CSTB
        </div>
        <!-- <div class="choice" (click)="onSelectionChange('EC');presetFields()" [ngClass]="{'selectd-choice' : value === 'EC'}">
          EC
        </div> -->
      </div>
    </div>
    <form [formGroup]="postForm" id="postForm" (ngSubmit)="treatPost()">
        <div *ngIf="value == 'statique'" class="row cont" style="margin-left: 126px !important;margin-top: 0px;">
          <div [ngClass]="((post && post.archiId && post.archiId!=archiId) || adding)?'col-md-6':'col-md-4'" [ngStyle]="{'padding-left': (post && post.archiId && post.archiId!=archiId || adding)? '33px':'32px'}">
            <label class="question-label">Unité</label>
            <input
              formControlName="unity"
              type="text"
              class="form-control"
              placeholder="Unité"
              aria-describedby="sizing-addon1"
              [readonly]="(post && post.archiId!=archiId && post.title)"
              [ngStyle]="{'width': (post && post.archiId && post.archiId!=archiId || adding)? '66%':'50%'}"
              (focus)="search('')"
              (focusout)="focusedOutOn()"
              (keyup)="search($event.target.value)"
            />
            <div id="myInputautocomplete-list" class="autocomplete-items">
              <div *ngFor="let result of results;let i = index" (mousedown)="choose(result)" class="unit-proposition">
                  {{result}}
              </div>
            </div>
          </div>
          <div [ngClass]="((post && post.archiId && post.archiId!=archiId) || adding)?'col-md-6':'col-md-4'" [ngStyle]="{'padding-left': (post && post.archiId && post.archiId!=archiId || adding)? '0px':'22px'}">
            <label class="question-label">PU HT</label>
            <input
              formControlName="puht"
              type="text"
              class="form-control"
              placeholder="PU HT"
              aria-describedby="sizing-addon1"
              [readonly]="(post && post.archiId!=archiId && post.title)"
              [ngStyle]="{'width': (post && post.archiId && post.archiId!=archiId || adding)? '66%':'50%'}"
            />
          </div>
          <div *ngIf="!adding && (post && post.archiId==archiId || !post?.title)" class="col-md-4" style="margin-top: 2px;z-index: 100;display: flex;flex-direction: column;" id="panel-container">
            <div class="parent-lot-container">
              <p class="question-label" style="font-weight: 700;">Emplacement actuel </p>
            </div>
            <div class="directory-container">
              <p class="directory-title">{{(chosenLot)? chosenLot.title : pathArray[pathArray.length-1].name}}</p>
              <i class="fa fa-edit directory-icon dropdown-toggle" (click)="openDropdown()"></i>
            </div>
            <!-- <button style="width: 50%;height: 36px;" type="button" class="btn btn-outline-secondary" data-toggle="dropdown" id='dropdownMenuButton1'>
              Déplacer vers
            </button> -->
            <div *ngIf="lot" id="lot-dropdown" style="left: 16px;top: 52px;" class="dropdown-menu list-dossier scroller-1" aria-labelledby="dropdownMenuButton1" (click)='$event.stopPropagation()'>
              <app-archi-db-post [firstTime]="true" [isDCE]="false" (chosenLotEvent)='setChosenLot($event)' [post]="post"></app-archi-db-post>
            </div>
          </div>
            <!-- <div class="col-md-12" *ngIf="value == 'statique'">
              <label class="question-label">Titre Poste</label>
              <textarea
                formControlName="title"
                type="text"
                class="form-control"
                placeholder="Désignation du poste"
                aria-describedby="sizing-addon1"
                [readonly]="(post && post.archiId!=archiId && post.title)"
                ></textarea>
            </div> -->
            <!-- <ng-template [ngIf]="value == 'EC'">
            <div class="col-md-3">
              <label class="question-label">Empreinte Carbone</label>
              <input
                type="text"
                class="form-control"
                formControlName="ec"
                placeholder="Empreinte Carbone"
                aria-describedby="sizing-addon1"
                [readonly]="(post && post.archiId!=archiId && post.title)"
              />
            </div>
            </ng-template> -->
        </div>
        <div class="row margin-top-25" [ngClass]="{hide: value !== 'statique'}">
          <div class="description-title">
            <label class="question-label">Description du poste</label>
          </div>
            <app-editor [ngClass]="{hide: value !== 'statique'}" class="cctp-editor editor"
                  placeholder="Rédigez vos CCTP"
                  Description
                  name="name"
                  [style]="{'height':'350px'}"
                  [ngModel]="post?.cctp"
                  (ngModelChange)="setCctpText($event)"
                  [ngModelOptions]="{standalone: true,}"
                  [readonly]="(post && post.archiId!=archiId && post.title)"
                  >
            </app-editor>
        </div>
    </form>

        <div *ngIf="lot" class="row">
          <div *ngIf="(post && post.archiId==archiId || !post?.title)" class="cstb floating-card" [ngClass]="{hide: value !== 'CSTB'}">
            <div>
              <input class="input-search" [(ngModel)]="searchTerm" (keyup)='searchPost()'>
              <i class="fa fa-search search-icon" (click)='searchPost()'></i>
            </div>
            <div *ngIf="gid != ''">
              <app-spinner *ngIf="lodingCstb"></app-spinner>
              <div *ngIf="!lodingCstb && !searchTerm" style="display: flex;">
                <button class="return" (click)="removeCstbId()"><i class="fa fa-arrow-left"></i></button>
                <div id="cctp" class="cstb-content" [innerHTML]="cstb"></div>
                <div *ngIf="error == 404" style="width: 100%;">
                  <h3 class="center">Oups ! Ce poste n'existe plus dans le CSTB</h3>
                </div>
                <div *ngIf="error == 403 && !accessToCstb" style="width: 100%;">
                  <h3 class="center">Le CSTB est limité aux utilisateurs AGLO Intégral</h3>
                  <button (click)="redirectToPricingTable()" type="button" class="center btn-go">C'est parti !</button>
                </div>
                <div *ngIf="error == 402" style="width: 100%;">
                  <h3 class="center">Vous avez atteint la limite de postes que vous pouvez importer depuis le CSTB avec l’essai gratuit. Pour pouvoir en importer plus, souscrivez à une licence AGLO Intégral.</h3>
                  <button (click)="redirectToPricingTable()" type="button" class="center btn-go">C'est parti !</button>
                </div>
              </div>
            </div>
            <div [ngClass]="{hidden : (gid != ''|| searchTerm)}">
              <app-cstb-navigation isLot="true" (selectedCstbEvent)="selectPost($event)"></app-cstb-navigation>
            </div>
            <div *ngIf="searchTerm && (licence=='premium' || licence=='premium-annuel' || licence=='premiumn' || licence=='premiumn-trim' || licence=='premiumn-annuel')">
              <p class="result-title">Resultats de <b>{{searchTerm}}</b> :</p>
              <div class="post-container" *ngFor="let post of results;let i=index">
                <input type='checkbox' (click)='selectedPost(i)'>
                <p class="post">{{post.sugg}}</p>
              </div>
            </div>
          </div>
        </div>
  </div>
</div>

<div class="slider-container" *ngIf="!isPost">
  <div class="header">
    <div class="row">
      <h2 class="center">
        <i
          class="click-it fa fa-times"
          aria-hidden="true"
          (click)="closePostSlider()"
        ></i>
        <ng-template [ngIf]="lot && lot.archiId==archiId || !lot">
          {{ adding ? "Ajouter Dossier" : "Informations du Dossier" }}
        </ng-template>
        <ng-template [ngIf]="lot && lot.archiId!=archiId">
          {{ "Details Dossier" }}
        </ng-template>
        <button *ngIf="(lot && lot.archiId==archiId || !lot) && !addingPost" type="submit" form="lotForm" class="btn-valdiate">
          {{ adding ? "Ajouter" : "Enregistrer" }}
        </button>
        <div class="add-post-spinner" *ngIf="addingPost">
          <app-spinner ></app-spinner>
        </div>
      </h2>
    </div>
  </div>
  <div class="post-form">
    <form [formGroup]="lotForm" id="lotForm" (ngSubmit)="treatLot()">
      <div class="row">
        <div *ngIf="!adding && lot && lot.archiId==archiId" style="position: absolute;right: 199px;display: flex;flex-direction: column;z-index: 100;" id="panel-container">
          <!-- <div class="parent-lot-container">
            <p class="question-label" style="font-weight: 700;">Dossier parent : </p> <p style="padding-top: 1px;padding-left: 9px;">{{(chosenLot)? chosenLot.title : pathArray[pathArray.length-2].name}}</p>
          </div>
          <button type="button" class="btn btn-outline-secondary" data-toggle="dropdown" id='dropdownMenuButton'>
            Modifier l'emplacement
          </button> -->
          <div class="parent-lot-container">
            <p class="question-label" style="font-weight: 700;">Emplacement actuel </p>
          </div>
          <div class="directory-container">
            <p class="directory-title">{{(chosenLot)? chosenLot.title : pathArray[pathArray.length-2].name}}</p>
            <i class="fa fa-edit directory-icon dropdown-toggle" (click)="openDropdown()"></i>
          </div>
          <div *ngIf="lot" id="lot-dropdown" class="dropdown-menu list-dossier" aria-labelledby="dropdownMenuButton" (click)='$event.stopPropagation()'>
            <app-archi-db-post [firstTime]="true" [isDCE]="false" [lotInputNotShowId]="lot._id" (chosenLotEvent)='setChosenLot($event)'></app-archi-db-post>
          </div>
          <!-- <p class="chosen-lot" *ngIf="chosenLot">{{chosenLot.title}}</p> -->
        </div>
        <div class="col-md-8 col-md-offset-2">
          <label class="question-label">Nom du dossier</label>
          <input
            formControlName="title"
            type="text"
            class="form-control"
            placeholder="Titre dossier"
            aria-describedby="sizing-addon1"
            [readonly]="lot && lot.archiId!=archiId"
            [ngStyle]="{'width': (!adding && lot && lot.archiId==archiId)?'67%':'97%'}"
          />
        </div>
        <div class="col-md-8 col-md-offset-2">
          <label class="question-label">Description dossier</label>
          <textarea
            id="description"
            formControlName="description"
            type="text"
            class="form-control description"
            placeholder="Généralités / prescription techniques"
            aria-describedby="sizing-addon1"
            [readonly]="lot && lot.archiId!=archiId"
          ></textarea>
        </div>
        <div *ngIf="lot && lot.archiId==archiId" class="col-md-6 col-md-offset-2">
          <button type="button" class="btn btn-outline-secondary" (click)="op.toggle($event)">
            Choisissez une icone
          </button>
          <img *ngIf="iconSelected" [src]="'assets/dpgf/Icon_DPGF/' + icon" alt="" height="35" style="padding-left: 3px" />
        </div>
        <!-- <div *ngIf="!adding && lot && lot.archiId==archiId" class="col-md-6 col-md-offset-3 margin-top-12" id="panel-container">
          <button type="button" class="btn btn-outline-secondary" data-toggle="dropdown" id='dropdownMenuButton'>
            Déplacer vers
          </button>
          <div *ngIf="lot" id="lot-dropdown" class="dropdown-menu list-dossier" aria-labelledby="dropdownMenuButton" (click)='$event.stopPropagation()'>
            <app-archi-db-post [firstTime]="true" [isDCE]="false" [lotInputNotShowId]="lot._id" (chosenLotEvent)='setChosenLot($event)'></app-archi-db-post>
          </div>
          <p class="chosen-lot" *ngIf="chosenLot">{{chosenLot.title}}</p>
        </div> -->
      </div>
    </form>
  </div>
</div>

<p-overlayPanel #op [showCloseIcon]="true" [appendTo]="'body'">
  <ng-template pTemplate>
    <ul class="icon-list">
      <li
        class="icons"
        *ngFor="let icon of icons"
        (click)="setIcon(icon); op.hide()"
      >
        <img [src]="'assets/dpgf/Icon_DPGF/' + icon" />
      </li>
    </ul>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #op1 [showCloseIcon]="true" [appendTo]="'body'" style="margin-bottom: 200px;">
  <ng-template pTemplate>
    <app-archi-db-post [firstTime]="true" [isDCE]="false"></app-archi-db-post>
  </ng-template>
</p-overlayPanel>
