import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { trigger,style,animate,transition,keyframes } from '@angular/animations';
import { CheckboxSimple } from "src/app/all/checkbox-simple/checkbox-simple.component";

//Service
import { AttributService } from "../../_services/index";
import { NgSharedModule } from "src/app/all/ngshared.module";

declare var jsonConcat: any;
declare var only: any;
declare var AmCharts: { AmMap: any; maps: any; SmallMap: any };
declare var $;

@Component({
    standalone: true,
    selector: "app-artisan-signup-detail",
    imports:[
      NgSharedModule,
      CheckboxSimple
    ],
    templateUrl: "./artisan-signup-detail.component.html",
    styleUrls: ["./artisan-signup-detail.component.scss"],
    animations: [
        trigger('error', [
            transition('* => true', [
                animate('2s', keyframes([
                    style({ backgroundColor: 'transparent' }),
                    style({ backgroundColor: 'rgba(255, 0, 0, 0.15)' }),
                    style({ backgroundColor: 'transparent' })
                ]))
            ]),
        ])
    ],
    
})
export class ArtisanSignupDetailComponent implements OnInit {
  //Inputs
  @Input() detail: any;
  @Input() departement: any;
  //Type
  public type = [
    {value:'entreprise-generale',name:"Entreprise générale"},
    {value:'bureau-d-etude',name:"Bureau d'études"},
    {value:'artisan-entreprise-specialisee',name:"Artisan / Entreprise spécialisée"},
    {value:'fabriquant-revendeur-materiaux',name:"Fabriquant / Revendeur matériaux"},
  ]
  //travaux
  public travaux: {}[] = [
    { value: "renovation", name: "Rénovation"},
    //{ value: "renovation-lourde", name: "Rénovation lourde"},
    //{ value: "renovation-simple", name: "Rénovation simple"},
    { value: "extension", name: "Extension"},
    { value: "surelevation", name: "Surélévation"},
    { value: "construction-neuve", name: "Construction Neuve"},
  ];
  //Programme
  public programme: {}[] = [
    { value: "maison", name: "Maison"},
    { value: "appartement", name: "Appartement"},
    { value: "cafe-hotel-restaurant", name: "Café / Hôtel / Restaurant"},
    { value: "boutique-conceptstore", name: "Boutique / Concept Store"},
    { value: "bureaux-tertiaire", name: "Bureaux / Tertiaire"},
    { value: "batiment-industriel-agricole", name: "Bâtiment Industriel / Agricole"},
    { value: "immeuble", name: "Immeuble"},
    { value: "equipements-publics", name: "Équipements publics"},
  ];
  //Postes
  public poste: {}[];
  public searchPoste: {}[];
  //Poste Sous traite
  public searchPosteSousTraite: {}[];
  //Areas
  public areas: {}[];
  public searchAreas: {}[];
  public fieldname: string = "text";
  //all
  public map: any;
  public error: string='';
  public workAreaMainValide: Boolean = true;
  public firstMapLoad: Boolean = true;
  public form: UntypedFormGroup;
  public formErrors = {
    type: "",
    poste: "",
    travaux: "",
    programme: ""
  };
  private validationMessages = {
    minBudgetMain: {
      required: "Le budget minimum est obligatoire."
    },
    minBudgetOther: {
      required: "Le budget minimum est obligatoire."
    }
  };
  constructor(
    private attributService: AttributService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.getAttribute("artisanPostes","poste");
    this.createForm();
    // this.createMap();
  }

  //Get data from server
  private getAttribute(attributeName,attribute) {
    this.attributService.getAllActive(attributeName).subscribe(
      data => {
        this[attribute] = data.map(val => {
          let newVal = { id: "", text: "", textEn: "" };
          newVal.id = val._id;
          newVal.text = val.name;
          newVal.textEn = val.nameEn || "No Traduction";
          return newVal;
        });
      },
      error => {
        console.log("error", error);
      }
    );
  }

  //Set Archi on creation
  ngOnChanges(changes: SimpleChanges) {
    if (changes["detail"] && this.form) {
      this.form.patchValue(this.detail);
      this.popRecommandation();
      this.detail.recommandation.forEach((e)=>{
        this.addRecommandation(e);
      })
    }
  }
  //Set Map if Needed
  public setDepartement() {
    setTimeout(() => {
      if (this.firstMapLoad) {
        for (let i = 0; i < this.departement.length; i++) {
          let departementID = this.departement[i].id;
          this.map.clickMapObject(this.map.getObjectById(departementID));
        }
        this.firstMapLoad = false;
      }
    }, 1000);
  }

  //create Form
  private createForm() {
    this.form = this.fb.group({
      type:[[], Validators.required],
      travaux:[[], Validators.required],
      programme:[],
      poste:[[], Validators.required],
      domaineSpe:"",
      //posteSousTraite:[[]],
      //budgetMoyen: "",
      //nbProjetRealise: "",
      //minBudgetMain: [{ value: 0, disabled: false }],
      //minBudgetOther: [{ value: 0, disabled: false }],
      recommandation: this.fb.array([
                this.createRecommendationForm({}),
      ]),
    });
    this.form.valueChanges.subscribe(data =>
      this.onValueChanged(data)
    );
    this.onValueChanged();
  }
  //Create recommendation forms
  public createRecommendationForm(rec){
    return this.fb.group({
        nameArchi: (rec.nameArchi || ''),
        mailArchi: (rec.mailArchi || ''),
        date:rec['date']? rec['date']:new Date(Date.now()).toLocaleString().split(',')[0]
    });
  }
  popRecommandation() {
      const control = <UntypedFormArray>this.form.controls['recommandation'];
      control.removeAt(0);
  }
  addRecommandation(rec) {
      const control = <UntypedFormArray>this.form.controls['recommandation'];
      control.push(this.createRecommendationForm(rec));
  }

  removeRecommandation(i: number) {
      const control = <UntypedFormArray>this.form.controls['recommandation'];
      control.removeAt(i);
  }
  //Create map
  private createMap() {
    this.map = new AmCharts.AmMap();
    this.map.balloon.color = "#000000";

    var dataProvider = {
      mapVar: AmCharts.maps.franceDepartmentsLow,
      getAreasFromMap: true
    };

    this.map.dataProvider = dataProvider;

    this.map.listeners = [
      {
        event: "clickMapObject",
        method: event => {
          this.map.selectedObject = this.map.dataProvider;
          event.mapObject.showAsSelected = !event.mapObject.showAsSelected;
          this.map.returnInitialColor(event.mapObject);
          var workAreaMain = [];
          for (var i in this.map.dataProvider.areas) {
            var area = this.map.dataProvider.areas[i];
            if (area.showAsSelected) {
              workAreaMain.push({ text: area.title, id: area.id });
            }
          }
          this.detail.workAreaMain = workAreaMain;
        }
      }
    ];
    this.map.areasSettings = {
      selectedColor: "#A387FF",
      color: "#eee",
      colorSolid: "#eee",
      rollOverColor: "#A387FF",
      rollOverOutlineColor: "#A387FF",
      selectable: true
    };
    //this.map.smallMap = new AmCharts.SmallMap();
    this.map.write("mapdiv");
  }
  //Generate Error
  private onValueChanged(data?: any) {
    if (!this.form) {
      return;
    }
    const form = this.form;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = "";
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = "Ce champ est obligatoire";
        }
      }
    }
  }

  //Mark as touched
  private markFormAsTouched(): void {
    if (!this.form) {
      return;
    }
    const form = this.form;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = "";
      const control = form.get(field);
      control.markAsDirty();
      const messages = this.validationMessages[field];
      for (const key in control.errors) {
        this.formErrors[field] = "Ce champ est obligatoire";
      }
    }
  }
  //Search Function
  public search(e, dropdown, allDropdown, varName) {
    this[dropdown] = [];
    let selectedData = (this.form.value[varName] || []).map(
      e => e[this.fieldname]
    );
    for (let i = 0; i < this[allDropdown].length; i++) {
      let selectable = this[allDropdown][i];
      if (
        selectable[this.fieldname].toLowerCase().indexOf(e.query.toLowerCase()) != -1 &&
        selectedData.indexOf(selectable[this.fieldname]) == -1
      ) {
        this[dropdown].push(selectable);
      }
    }
  }

  //Form Validation
  private validateWorkAreaMain() {
    if ((this.detail.workAreaMain || []).length == 0) {
      this.workAreaMainValide = false;
    } else {
      this.workAreaMainValide = true;
    }
  }
  //Valdiate Data
  public isValid(scroll=true) {
    this.markFormAsTouched();
    if (scroll) {
      this.scrollToError()
    }
    this.validateWorkAreaMain();
    return this.form.valid;
  }
  //Get data
  public getFormData() {
    return jsonConcat(
      this.form.value,
      only(this.detail, "workAreaMain")
    );
  }
  //scrollTo
  public scrollTo(element, offset = 0) {
    $('html, body').animate({
      scrollTop: ($(element).offset().top - offset)
    });
  }
  public scrollToError(){
    let loop = 0;
    let keys = Object.keys(this.formErrors);
    let errorKey = ""
    while(loop < keys.length && this.formErrors[keys[loop]] =="" ){
      loop ++;
    }
    if (keys[loop]) {
      this.error = keys[loop]
      this.scrollTo(`#${keys[loop]}`, 10);
      setTimeout(()=>{this.error = ''},2000)
    }
  }
}
