import { Component, OnInit, Input,Output, EventEmitter,ChangeDetectorRef} from '@angular/core';
import { Subscription } from 'rxjs';
import { DpgfPostesService } from 'src/app/_services';
import { ErrorService } from 'src/app/_services';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

declare var $

@Component({
  standalone:true,
  selector: 'app-dpgf-import-lot-popup',
  imports:[
    DpgfModule,
    SpinnerComponent,
    NgSharedModule
  ],
  templateUrl: './dpgf-import-lot-popup.component.html',
  styleUrls: ['./dpgf-import-lot-popup.component.scss']
})
export class DpgfImportLotPopupComponent implements OnInit {
  @Output() selectedLots = new EventEmitter<any>();
  private subscriptions: Array<Subscription> = [];
  public loading = true;
  public loadingLot = false;
  public loadingTemplates = true;
  public loadingLotTemplates = false;
  public archiId:string = "";
  public selectedId:string = "";
  public dpgfs = [];
  public dpgfsTemplates = [];
  public lots = [];
  public lotsTemplates = [];
  public count = 0;
  public countTemplates = 0;
  constructor(private DpgfPostesService: DpgfPostesService,
              private ErrorService: ErrorService,
              private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.listAllDpgf({page : 0})
    this.listAllDpgfTemplates({page : 0})
  }
  public listAllDpgf({page}){
    this.loading = true;
    let currentUser = (JSON.parse(localStorage.getItem("currentUser"))||{})
    this.archiId = (currentUser.archiId||"")
    if(this.archiId != ""){
      this.subscriptions.push(
        this.DpgfPostesService.listDpgf(this.archiId, page, 'status')
        .subscribe(
          data => {
            this.dpgfs = [...data.data];
            this.count = data.metadata.count;
            this.loading = false;
            this.cd.detectChanges();
          },
          error => {
            console.log("error",error)
            this.ErrorService.manageError(error,"");
          })
        )
    }
  }
  public listAllDpgfTemplates({page}){
    this.loading = true;
    let currentUser = (JSON.parse(localStorage.getItem("currentUser"))||{})
    this.archiId = (currentUser.archiId||"")
    if(this.archiId != ""){
      this.loadingTemplates = true;
      this.subscriptions.push(
        this.DpgfPostesService.listDpgfTemplates(page)
        .subscribe(
          data => {
            this.dpgfsTemplates = [...data.data];
            this.countTemplates = data.metadata.count;
            this.loadingTemplates = false;
            this.cd.detectChanges();
          },
          error => {
            console.log("error",error)
            this.ErrorService.manageError(error,"");
          })
        )
    }
  }
  public getDpgf(index){
    let dpgfs = this.dpgfs.slice();
    dpgfs = dpgfs.map((val)=>{
      val.open = false;
      return val;
    })
    dpgfs[index].open = true;
    let id = dpgfs[index]._id;
    this.selectedId = id
    this.dpgfs = dpgfs.slice();
    this.loadingLot = true;

    this.subscriptions.push(
      this.DpgfPostesService.getDpgf(id)
          .subscribe(
              data => {
                this.loadingLot = false;
                this.lots = data.data.map(lot=>{
                  lot.selected = false;
                  return lot;
                });
                this.cd.detectChanges();
              },
              error => {
                  console.log("error",error)
                  this.ErrorService.manageError(error,"");
              })
          )
  }
  public getDpgfTemplates(index){
    let dpgfs = this.dpgfsTemplates.slice();
    dpgfs = dpgfs.map((val)=>{
      val.open = false;
      return val;
    })
    dpgfs[index].open = true;
    let id = dpgfs[index]._id
    this.selectedId = id;
    this.dpgfs = dpgfs.slice();
    this.loadingLotTemplates = true;

    this.subscriptions.push(
      this.DpgfPostesService.getDpgf(id)
          .subscribe(
              data => {
                this.loadingLotTemplates = false;
                this.lotsTemplates = data.data.map(lot=>{
                  lot.selected = false;
                  return lot;
                });
                this.cd.detectChanges();
              },
              error => {
                  console.log("error",error)
                  this.ErrorService.manageError(error,"");
              })
          )
  }
  public chooseLot(){
    let selectedLot = [...this.lots].filter(lot=>lot.selected).slice()
    this.selectedLots.emit({lot :selectedLot,dpgfId : this.selectedId})
    if(!environment.onPremise)window.analytics.track('import_lot',{properties:{acValue: 'import_lot'}});
    this.lots = [];
    this.close()
  }
  public chooseLotTemplates(){
    let selectedLot = [...this.lotsTemplates].filter(lot=>lot.selected).slice()
    this.selectedLots.emit({lot :selectedLot,dpgfId : this.selectedId})
    this.lotsTemplates = [];
    this.close()
  }

  public close(){
    $("#import-lot").modal("hide");
  }
  public ngOnDestroy(): void {
      this.subscriptions.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
      });
  }

}
