import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';

import { ArchitectService } from '../../_services';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

declare var $;

@Component({
    standalone: true,

    selector: 'app-archi-upload-carnet-adresse',
    imports:[NgSharedModule],
    providers: [],
    templateUrl: './archi-upload-carnet-adresse.component.html',
    styleUrls: ['./archi-upload-carnet-adresse.component.scss'],
})
export class ArchiUploadCarnetAdresseComponent implements OnInit {
  @Output() archiAdded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('fileImportInput') fileImportInput: any;

  public csvHeaders: any[] = [];
  public csvRecords: any[] = [];
  public header: boolean = false;

  public uploadedCsv: boolean = false;
  public valuesIndices = {}
  public records:any[] = [];
  public submittingCSV: boolean = false;
  public validateImportion: any = {
    name: false,
    email: false,
    phone: false,
    firstName:false,
    lastName:false
  };

  private subscriptions: Array<Subscription> = [];

  constructor(
    private ngxCsvParser: NgxCsvParser,
    private architectService: ArchitectService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
  }
  public fileChangeListener($event: any): void {
    const files = $event.srcElement.files;
    this.header = (this.header as unknown as string) === 'true' || this.header === true;
    this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {
        this.csvHeaders = result[0];
        this.csvRecords = result;
        this.csvRecords.shift();
        this.uploadedCsv = true;
        this.records = Array(result.length - 1);
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      });
  }
  public extractColumn(column) {
    return this.csvRecords.map(x => x[column])
  }
  public fieldSelected(option, field){
    let itemIndex = this.csvHeaders.indexOf(option);
    this.valuesIndices[field] = itemIndex;
    let items = this.extractColumn(itemIndex);
    items.forEach((item, index) => {
      let row = {}
      row[field] = item;
      this.records[index] = {...this.records[index], ...row};
    });
    this.validateImportion[field] = true;
  }
  public isFormValid(){
    return this.validateImportion['name'] && this.validateImportion['email'];  
  }
  public saveRecords(){
    this.submittingCSV = true;
    this.subscriptions.push(
      this.architectService.addCarnetAdressesFromCsv(this.records).subscribe(
        data => {
          this.submittingCSV = false;
          this.messageService.add({ key: 'toast', severity: 'success', summary: "Ajout d'une entreprise", detail: "L'entreprise a été invité dans votre réseau", life: 5000 });
          this.archiAdded.emit(true);
          $('#importCarnetAdress').modal('hide');
          // segment event
          if(!environment.onPremise)window.analytics.track('add_contact_from_csv',{properties:{acValue: 'add_contact_from_csv'}});
        },
        error => {
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur d'ajout", detail: 'Erreur serveur', life: 5000 });          
          this.submittingCSV = false;
        }
      )
    )

  }
  public changeListener(files: FileList){
    if(files && files.length > 0) {
       let file : File = files.item(0); 
         let reader: FileReader = new FileReader();
         reader.readAsText(file, 'ISO-8859-1');
         reader.onload = (e) => {
            let csv: string = reader.result as string;
            let rows=[]
            rows=csv.split('\n');
            rows.pop();
            rows.forEach((row,i)=>rows[i]=row.split(','))
            this.csvRecords = rows;
            this.csvHeaders = rows[0];
            this.csvRecords.shift();
            this.uploadedCsv = true;
            this.records = Array(rows.length - 1);
         }
      }
  }
  public cancelChoosing(){
    this.uploadedCsv = false;
    for(let key in this.validateImportion){
      this.validateImportion[key] = false;
    }
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }


}
