import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone:true,
  name: 'isHtmlEmpty'
})
export class IsHtmlEmpty implements PipeTransform {

  transform(value:string) {
      return (value||'').replace(/<[^>]*>?/gm, '').length != 0;
  }

}
