import { Component, OnInit, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { formateImage } from "../../_helpers/formate-image";
import { CommonModule } from '@angular/common';

//Helpers
import {Promise}  from 'bluebird';
import { FileManagerComponent } from 'src/app/all/file-manager/file-manager.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var jsonConcat: any;
declare var only: any;
declare var $: any;

@Component({
    standalone: true,
    selector: 'app-artisan-signup-file',
    imports:[
      NgSharedModule,
      FileManagerComponent
    ],
    templateUrl: './artisan-signup-file.component.html',
    styleUrls: ['./artisan-signup-file.component.scss'],
})
export class ArtisanSignupFileComponent implements OnInit {
  @Input() artisan: any;
  @Output() fileAdded: EventEmitter<any> = new EventEmitter<any>();
  public filesLoading: number[] = [];
  public assuranceNumber: any;
  constructor() { }

  ngOnInit() {
  }
  public scrollTo(element) {
    $("html, body").animate(
      {
        scrollTop: $(element).offset().top
      },
      1500
    );
  }
  //Validate Form
  isValid() {
    if (this.filesLoading.length != 0) this.scrollTo("#file-label");
    return this.filesLoading.length == 0 && this.artisan.assuranceFile && Object.keys(this.artisan.assuranceFile).length !== 0;
  }
  getFormData() {
    return only(this.artisan, "files photos assuranceFile kbis assuranceNumber");
  }
  public imageRemoved(){
    this.fileAdded.emit();
  }
}
