<div class="page-wrapper main-page-white-lilac">
  
  <app-header type="full"></app-header>

  <app-spinner *ngIf="loading"></app-spinner>

  <div class="container-fluid" *ngIf="!loading">
    <div class="row">
      <!-- Main Content -->
      <div class="col-md-9 no-padding-horizontal">

        <!-- Tabs Toggle Buttons -->
        <div class="tabs-toggle-wrapper horizontal-items space-between">
          <div class="single-tab width-50 flex-middle pointer" 
            [ngClass]="{'choosen': seletedTab == 'relisations'}" (click)="toggleTab('relisations')">
            <p class="no-padding-bottom">
              <i class="fa fa-image margin-right-12"></i>
              Réalisations
            </p>
          </div>
          <div class="single-tab width-50 flex-middle pointer" 
            [ngClass]="{'choosen': seletedTab == 'recommandation'}" (click)="toggleTab('recommandation')">
            <p class="no-padding-bottom">
              <img src="/assets/artisan-booster/stars-sign.svg" class=" margin-right-12">
              Recommandations
            </p>
          </div>
          
        </div>
        <!-- END Tabs Toggle Buttons -->

        <!-- Tabs Pages Wrapper -->
        <div class="tabs-pages-wrapper">
          <container-element [ngSwitch]="seletedTab">
            
            <app-public-booster-profile-realisations-list 
              [albums]="albums"
              [photos]="photos"
              *ngSwitchCase="'relisations'">
            </app-public-booster-profile-realisations-list>
            
            <app-public-booster-profile-recommandations-list
              [reviews]="reviews"
              [average]="average"
              *ngSwitchCase="'recommandation'">
            </app-public-booster-profile-recommandations-list>

          </container-element>
        </div>
        <!-- END Tabs Pages Wrapper -->

        </div>
      <!-- END Main Content -->

        <!-- Sidebar -->
        <div class="col-md-3 sidebar-card padding-top-10 scroller-1">
          <app-booster-profile-info-side-bar 
            [artisanData]="sideBarData"
            [showEditButtons]="false"
            (openSpecificModal)="openSideBarModals($event)"
            ></app-booster-profile-info-side-bar>
        </div>
        <!-- END Sidebar -->

      </div>
    </div>

    <!-- SideBar Modals
  ======================================= -->
  <ng-container *ngIf="!loading">

    <app-booster-profile-garantie-decennale-view-only
      [corpsDetat]="corpsDetat"
      [isPublicProfile]="true"
    ></app-booster-profile-garantie-decennale-view-only>
  
  </ng-container>

</div>