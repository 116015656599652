<!-- App Header -->
<app-header></app-header>

<div class="flex justify-center " style="width: 100%;height: 90%;align-items: center;">
  <div style="width: 520px;height: 456px;">
    <div class="flex justify-center padding-bottom-16"  >
      <img style="width: 300px;" src="/assets/errors-svg/error-404.svg">
    </div>
    <div class="padding-bottom-16" style="font-size: 25px;text-align: center;font-weight: 700;">
      Page introuvable
    </div>
    <div class="padding-bottom-16" style="font-size: 16px;text-align: center;">
     <p>
      La page que vous essayez d’atteindre n’existe pas. 
      <br>
      Vérifiez l’url et réessayez.
    </p>

    </div>
    <div class="flex justify-center">
      <button [routerLink]="['/']" style="font-size: 16px;padding-right: 16px;border-radius: 4px; padding-left: 16px;padding-top: 8px;padding-bottom: 8px;color:white;text-align: center;background-color: rgba(45, 4, 194, 1);">Revenir à l’accueil</button>
    </div>
  </div>
</div>

<!-- App Footer  -->
