<div class="modal fade" id="user-on-without-license-modal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="title">Abonnement requis</h6>
      </div>
      <div class="modal-body">
        <p>
          Vous êtes actuellement en lecture seule et ne pouvez pas réaliser cette action. Souscrivez à une de nos licences pour y accéder.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-cancel ag-btn-tertiary-sm margin-right-16" data-dismiss="modal">Annuler</button>
        <button type="button" class="btn-accept ag-btn-tertiary-sm" data-dismiss="modal" (click)="redirectToLicensesPage()">
          Voir les licences
        </button>
      </div>
    </div>
  </div>
</div>
