import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ds-confirm-modal',
  standalone: true,
  imports: [],
  templateUrl: './confirm-modal.component.html',
  styleUrl: './confirm-modal.component.scss'
})
export class ConfirmModalComponent {

  @Input() modalId: string = "";
  @Input() title: string = "";
  @Input() description: string = "";
  @Input() confirmButtonLabel: string = "";
  @Output() confirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  public emitConfirm(){
    this.confirm.emit();
  }

  public emitCancel(){
    this.cancel.emit();
  }

}
