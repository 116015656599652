import { Component,Input, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import Chart from 'chart.js/auto';
import { MessageService } from 'primeng/api';
import { NgSharedModule } from 'src/app/all/ngshared.module';
const _ = require('lodash');


@Component({
    standalone: true,

    selector: 'app-acv-results-exigences-view',
    imports:[NgSharedModule],
    providers: [MessageService],
    templateUrl: './acv-results-exigences-view.component.html',
    styleUrls: ['./acv-results-exigences-view.component.scss'],
    
})
export class AcvResultsExigencesViewComponent implements OnInit {
  @Input() acvData;
  impactChangementClimatiqueChart: any;
  repartitionDonneesUtiliseesChart: any;
  projetConstants={
    'UDD':0,
    'StockC':0,
    'PV':0,
    'Coge':0
  }
  chart: any;
  predefinedColors=['#B86B40', '#A08C58', '#4B827D', '#962702', '#9D51C0', '#C6599A']

  constructor(private messageService: MessageService) { }

  ngOnInit(): void {
    this.impactChangementClimatiqueChart = document.getElementById('chart-impact-changement-climatique');
    this.repartitionDonneesUtiliseesChart = document.getElementById('chart-repartition-des-donnees-utilisees');
    // Chart.register(...registerables);
    this.setImpactChangementClimatiqueChart();
    this.setRepartitionDonneesUtiliseesChart();
    this.setProjetConstants()
  }
  random_rgba() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
  }
  private setImpactChangementClimatiqueChart(): void{
    let result=this.acvData.projectResult
    let datasets=[{
      label: 'Impact lie à la construction',
      data: [0],
      backgroundColor: [
        '#7FEFBD',
      ]
    },{
      label: 'Impact du contributeur énergie',
      data: [0],
      backgroundColor: [
        '#FFBA08',
      ]
    },{
      label: 'Impact du contributeur eau',
      data: [0],
      backgroundColor: [
        '#0275D8',
      ]
    },{
      label: 'Impact de parcelle',
      data: [_.get(result,'parcel.CO2DynamicImpacts.Ic',0)],
      backgroundColor: [
        '#008037',
      ]
    }]
    let labels=['Ic projet']
    // parse project
    

    for (let i = 0; i < (result.batiments||[]).length; i++) {
      let oneBatiment=result.batiments[i]
      labels.push(oneBatiment['Name'])

      datasets[0].data.push(oneBatiment['IcConstruction'])
      datasets[1].data.push(oneBatiment['IcEnergie'])
      datasets[2].data.push(oneBatiment['IcEau'])
      datasets[3].data.push(0)

      datasets.push({
        label: oneBatiment['Name'],
        data: [oneBatiment['Ic']],
        backgroundColor: [
          i<6?this.predefinedColors[i]:this.random_rgba(),
        ]
      })
    }
    labels.push('Ic parcelle')
    datasets[3].data.push(_.get(result,'parcel.CO2DynamicImpacts.Ic',0))

    new Chart(this.impactChangementClimatiqueChart, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options:{
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
              callbacks: {
                  label: (item) =>
                      `${item.dataset.label}: ${item.formattedValue} kg éq.CO2/m²`,
              },
          },
        },
      }
    });
  }
  private setRepartitionDonneesUtiliseesChart(): void{
    let posts=this.acvData.posts||[];
    let labels=[],dataCanvas=[];
    let data={
      'Composants issus du réemploi':0,
      'Composants vides':0,
      'Données environnementales par défaut':0,
      'FDES/PEP':0
    }
    for (let i = 0; i < posts.length; i++) {
      let onePost = posts[i];
      // Répartition des données utilisées
      if(onePost.idFicheBase=="27546" || onePost.idFicheBase==27546) data['Composants issus du réemploi']++;
      else if(onePost.idFicheBase=="25765" || onePost.idFicheBase==25765) data['Composants vides']++;
      else if(onePost.declarationType==3) data['Données environnementales par défaut']++;
      else if(onePost.declarationType==1 || onePost.declarationType==2) data['FDES/PEP']++;
    }
    for (const key in data) {
      labels.push(key)
      dataCanvas.push(data[key])
    }
    new Chart(this.repartitionDonneesUtiliseesChart, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [{
          // label: 'My First Dataset',
          data: dataCanvas,
          backgroundColor: [
            'rgb(54, 205, 86)',
            'rgb(54, 162, 235)',
            '#7D80DA',
            '#BD4089'
          ],
          hoverOffset: 4
        }]
      },
      options:{
        maintainAspectRatio: false,
      }
    });
  }
  setProjetConstants(){
    this.projetConstants['StockC']=_.get(this.acvData,'projectResult.StockC',0)
    this.projetConstants['UDD']=_.get(this.acvData,'projectResult.CO2DynamicImpacts.Udd',0)
    this.projetConstants['PV']=_.get(this.acvData,'projectResult.StockC',0)
    this.projetConstants['Coge']=_.get(this.acvData,'projectResult.StockC',0)
  }


}
