import { Component, OnInit, Input, SimpleChanges, NgZone, ElementRef, ViewChild, NO_ERRORS_SCHEMA } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { ArtisanSingupAgencyInfoComponent } from '../../../artisan/artisan-singup-agency-info/artisan-singup-agency-info.component';
import {Title,Meta} from '@angular/platform-browser';
import { NgSharedModule } from 'src/app/all/ngshared.module';

import { Subscription } from 'rxjs';

//Services
import { MessageService } from 'primeng/api';
import { ArtisanService } from '../../../_services/index';

declare var $:any;

@Component({
    selector: 'app-artisan-subscribe-agency-info',
    imports:[
    ArtisanSingupAgencyInfoComponent,
    NgSharedModule
    ],
    providers: [],
    templateUrl: './artisan-subscribe-agency-info.component.html',
    styleUrls: ['./artisan-subscribe-agency-info.component.scss'],
    standalone: true,
    
})
export class ArtisanSubscribeAgencyInfoComponent implements OnInit {
  public artisan: any;
  public loading:Boolean = false;
  private subscriptions: Array<Subscription> = [];
  @ViewChild(ArtisanSingupAgencyInfoComponent) form: ArtisanSingupAgencyInfoComponent
  constructor(private title: Title,
              private router: Router,
              private messageService: MessageService,
              private route: ActivatedRoute,
              private artisanService: ArtisanService,) {
    this.artisan = {}
   }

  ngOnInit() {
    this.title.setTitle('BAM - S\'inscrire' );
    this.scrollToTop();
    this.getMail();
  }
  public getMail(){
    this.subscriptions.push(
      this.route.queryParams.subscribe((params: Params) => {
        if(params['email']){
          this.artisan.emailAgency = params['email'];
        }
      })
    )
  }
  private scrollToTop(){
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }
  save(){
    this.loading = true;
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let artisanId = currentUser.artisanId;
    let isValid = this.form.isValid()
    let agencyData = this.form.getFormData()
    if(isValid){
      this.artisanService.update(artisanId, agencyData)
          .subscribe(
              data => {
                  this.loading = false;
                  this.router.navigate(['artisan/project-list']);
                  this.messageService.add({ key: 'toast', severity: 'success', summary: 'Informations principales enregistrées', detail: 'Vos informations principales ont été enregistrées', life: 5000 });
              },
              error => {
                  console.log("error",error)
                  this.loading = false;

                  this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: error.message, life: 5000 });
              });
    }else{
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Formulaire incomplet', detail: "Veuillez remplir tous les champs obligatoires", life: 5000 });
      this.loading = false;
    }
  }

  public redirectToHome(){
    // this.router.navigate(['artisan/project-list']);
    this.form.toggleFillManually();
  }

}
