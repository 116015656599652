import { Component, OnInit, Input, NO_ERRORS_SCHEMA } from '@angular/core';
import { LotPricing } from 'src/app/_interfaces/team-lot-pricing.interface';
import { DpgfModule } from 'src/app/dpgf/dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
@Component({
  standalone:true,
  selector: 'app-team-member-pricing',
  imports:[
    DpgfModule,
    NgSharedModule
  ],
  templateUrl: './team-member-pricing.html',
  styleUrls: ['./team-member-pricing.scss']
})
export class TeamMemberPricingComponent implements OnInit {

  public isModifyingMessage: string;

  @Input() pricing: LotPricing;
  @Input() lotPricingAvg: number;
  @Input() colors: string;
  @Input() estimation: number;

  constructor() {
    this.isModifyingMessage = "L'entreprise affectée à ce lot est en train de modifer son chiffrage."
  }

  ngOnInit() {
  }
  getDifference(total: number): number {
    return Math.abs(total - this.lotPricingAvg);
  }
  get getEstimationDiff(): number {
    return Math.abs(this.pricing.total - this.estimation);
  }
}
