import { Component, OnInit, ViewChild, NO_ERRORS_SCHEMA} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute , Params,Router} from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
import {ConfirmationService} from 'primeng/api';
import { NgSharedModule } from 'src/app/all/ngshared.module';

//Services
import { CeProjectInvitesService,ErrorService} from '../../../_services'
import { MessageService } from 'primeng/api';
import { ChantierService } from '../../../../app/_services/chantier.service';

import { ArtisanCguModalComponent } from 'src/app/all/artisan-cgu-modal/artisan-cgu-modal.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { FactureListComponent } from 'src/app/chantier/facture-list/facture-list.component';

@Component({
    selector: 'app-artisan-ce-project',
    imports:[
    HeaderComponent,
    ArtisanCguModalComponent,
    SpinnerComponent,
    FactureListComponent,
    NgSharedModule
],
    providers: [MessageService],
    templateUrl: './artisan-ce-project.component.html',
    styleUrls: ['./artisan-ce-project.component.scss'],
    standalone: true,

})
export class ArtisanCeProjectComponent implements OnInit {
  private subscriptions: Array<Subscription> = [];

  public loading:Boolean = true;
  public setLoading:Boolean = false;
  public invite:any = {};
  public allRequests:any = [];
  public ceProjectInviteId:String = "";
  public artisanMot:String = "";
  public raison:String = "";
  public artisanRaisonRefus:String = "";
  public action:String = "none";
  public loadingData:boolean = true;
  public loadingFactures:boolean = false;
  public eventsHistory: any = [];
  public steps = {
    step1 : true,
    step2 : false,
    step3 : false,
    step4 : false,
  }
  public allStatus={
    "waiting-archi" : "Validation de l'archi",
    "waiting-client" : "Validation du client",
    "waiting-admin" : "En attente du virement",
    "valid" : "Virement effectué",
    "archi-refuse" : "Refuser par l'archi"
  }
  private historiqueDesEvenementsMapper: any = {
    "send_dce": (log) => {
      return {
        circleColor: "#0275D8",
        text: `${log?.architect?.name} vous a consulté sur ce projet`,
        date: log.createdAt,
      }
    },
    "update_pricing_dce_version": (log) => {
      return {
        circleColor: "#0275D8",
        text: `${log?.architect?.name} vous a envoyé une version mise à jour du DCE (Indice ${this.getIncide((log.params||{}).indice)})`,
        date: log.createdAt,
      }
    },
    "artisan_refuse_invite": (log) => {
      return {
        circleColor: "#EA3E3E",
        text: `Vous avez refusé de chiffrer ce projet`,
        date: log.createdAt,
      }
    },
    "artisan_accept_invite": (log) => {
      return {
        circleColor: "#93E5AC",
        text: `Vous avez accepté de chiffrer ce projet`,
        date: log.createdAt,
      }
    },
    "finish_dce_pricing": (log) => {
      return {
        circleColor: "#0275D8",
        text: `Vous avez envoyé la version (${this.getIncide((log.params||{}).indice)}) de votre chiffrage`,
        date: log.createdAt,
      }
    },
    /// FROM HERE
    // "Chiffrage accepted by the architect": (log) => {
    //   return {
    //     circleColor: "#93E5AC",
    //     text: `${log?.architect?.name} a sélectionné votre chiffrage`,
    //     date: log.createdAt,
    //   }
    // },
    // "Chiffrage refused by the architect": (architect_agency_name, log) => {
    //   return {
    //     circleColor: "#EA3E3E",
    //     text: `${architect_agency_name} a refusé votre chiffrage`,
    //     date: log.createdAt,
    //   }
    // },
    // "Situation sent by the artisan": (log) => {
    //   return {
    //     circleColor: "#0275D8",
    //     text: `Vous avez envoyé une situation de chantier`,
    //     date: log.createdAt,
    //   }
    // },
    // "Situation accepted by the architect": (date_envoi_situation, log) => {
    //   return {
    //     circleColor: "#93E5AC",
    //     text: `Votre situation de chantier du ${date_envoi_situation} a été acceptée par le maître d’oeuvre`,
    //     date: log.createdAt,
    //   }
    // },
    // "Situation refused by the architect": (date_envoi_situation, log) => {
    //   return {
    //     circleColor: "#EA3E3E",
    //     text: `Votre situation de chantier du ${date_envoi_situation} a été refusée par le maître d’oeuvre`,
    //     date: log.createdAt,
    //   }
    // },    
  };
  

  public inviteFactures:any = [];
  // cards showing/hiding
  public showDossierConsultationCard:Boolean = false;
  public showArchitectAcceptedChiffrageCard:Boolean = false;
  public showChantierCard:Boolean = false;
  public showArtisanRefusedCard:Boolean = false;
  public showArchitectRefusedCard:Boolean = false;

  constructor(private title: Title,
              private messageService: MessageService,
              private ceProjectInvitesService:CeProjectInvitesService,
              private ErrorService: ErrorService,
              private route: ActivatedRoute,
              private router:Router,
              private confirmationService: ConfirmationService,
              private chantierService: ChantierService) { }

  ngOnInit() {
    this.getCurrentInvite();
    this.title.setTitle('Aglo -  Mon projet Aglo' );
  }

  public getCurrentInvite(){
      this.route.params.forEach((params: Params) => {
        if (params['ceProjectInviteId']) {
          this.ceProjectInviteId = params['ceProjectInviteId'];
          this.subscriptions.push(
            this.ceProjectInvitesService.getCeInvite(this.ceProjectInviteId)
                .subscribe(
                    data => {
                        this.invite = data;
                        this.showOrHideCards(data["status"]);
                        this.loadLoggs();
                        
                        let factureStatus = ['selectionne', 'refuse', 'signe'];
                        if (factureStatus.includes(data.status)) {
                          // get factures
                          this.getInviteFactures();
                        }
                        // this.loadingData = false;

                        /// TO be removed
                        if(data.status  == 'artisan-accepter'){
                          this.steps.step2 = true;
                          this.steps.step3 = true; //Added This to remove RDV
                        }else if (data.status  == 'rdv-pris'){
                          this.steps.step2 = true;
                          this.steps.step3 = true;
                        }else if (data.status == 'selectionne' || data.status == 'refuse' || data.status == 'signe') {
                          // get factures
                          this.getInviteFactures();
                          this.steps.step2 = true;
                          this.steps.step3 = true;
                          this.steps.step4 = true;
                        }
                    },
                    error => {
                        console.log("error",error)
                        this.ErrorService.manageError(error,'/artisan/project/' + this.ceProjectInviteId);
                    })
          )
        }
      })
  }
  private loadLoggs(){
    this.subscriptions.push(
      this.ceProjectInvitesService.loadArchiLogs(this.ceProjectInviteId)
        .subscribe(
          data => {
            this.formatLog(data);
            this.loadingData = false;
          },
          err => {
            this.loadingData = false;
            console.log("loadLoggs : ", err);
          },
        )
    );
  }
  private showOrHideCards(status=""){
    if(!status || status == "") return;
    
    this.showDossierConsultationCard = status == "artisan-invite" ? false : true;
    this.showArchitectAcceptedChiffrageCard = ["selectionne","signe"].includes(status) ? true : false;
    this.showChantierCard = status == "signe" ? true : false;
    this.showArtisanRefusedCard = ["artisan-refuser"].includes(status) ? true : false;
    this.showArchitectRefusedCard = ["refuse"].includes(status) ? true : false;
  }
  /// Historique des évènements
  private formatLog(logs){
    // console.log("logs : ", logs);
    
    for(let i = 0; i < logs.length; i++){
      let currentLog = logs[i];
      let currentLotType = currentLog["type"];

      if(this.historiqueDesEvenementsMapper[currentLotType]){
        let historyItem = this.historiqueDesEvenementsMapper[currentLotType](currentLog);
        this.eventsHistory.push(historyItem);
      }
    }

  }
  public getIncide(number){
    if(number){
      var n = number-1;
      var ordA = 'a'.charCodeAt(0);
      var ordZ = 'z'.charCodeAt(0);
      var len = ordZ - ordA + 1;
      var s = "";
      while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
      }
      return s.toUpperCase();
    }
  }

  // TODO: To be removed
  public acceptInvite(){
    let data = { artisanMot : this.artisanMot};
    this.setLoading = true;
    this.subscriptions.push(
      this.ceProjectInvitesService.accepteArtisanInvite(this.ceProjectInviteId,data)
          .subscribe(
              () => {
                  this.setLoading = false;
                  this.getCurrentInvite()
              },
              error => {
                  this.ErrorService.manageError(error,'');
              })
    )
  }
  // TODO: To be removed
  public refuseInvite(){
    let data = { artisanRaisonRefus : this.artisanRaisonRefus};
    this.setLoading = true;
    this.subscriptions.push(
      this.ceProjectInvitesService.refusArtisanInvite(this.ceProjectInviteId,data)
          .subscribe(
              () => {
                  this.setLoading = false;
                  this.getCurrentInvite()
              },
              error => {
                  this.ErrorService.manageError(error,'');
              })
    )
  }

  //Choose RDV
  public confirmInvite(chosenRdv) {
    let date = "le " + chosenRdv.split("T")[0] + " à " + chosenRdv.split("T")[1]
       this.confirmationService.confirm({
           message: `Voulez vous confirmer le rendez-vous suivant : ${date} ?`,
           header: 'Confirmation',
           accept: () => {
            this.chooseRdv(chosenRdv)
           },
           reject: () => {
           }
       });
  }
  public chooseRdv(chosenRdv){
    this.loading = true;
    this.subscriptions.push(
      this.ceProjectInvitesService.acceptRdvArtisanInvite(this.ceProjectInviteId,{"chosenRdv":chosenRdv})
          .subscribe(
              data => {
                this.getCurrentInvite();

                this.messageService.add({ key: 'toast', severity: 'success', summary: 'Votre rendez-vous a été validé avec succès', detail: 'Vous allez recevoir une confirmation par mail', life: 5000 });
                this.loading = false;
              },
              error => {
                this.loading = false;
                if(error.status == 400){
                  this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: 'Ce créneau a déja été choisi par un autre architecte', life: 5000 });
                }else{
                  console.log("error",error)
                  this.ErrorService.manageError(error,'');
                }
              })
          )
  }
  //Update RDV
  public cancelRdv(){
    this.setLoading = true;
    this.subscriptions.push(
      this.ceProjectInvitesService.cancelRdvArtisanInvite(this.ceProjectInviteId,{"raison" : this.raison})
          .subscribe(
              data => {
                this.getCurrentInvite();
                this.messageService.add({ key: 'toast', severity: 'success', summary: 'Le rendez-vous a bien été annulé', detail: 'Vos nouvelles disponibilités vont être transmises au client', life: 5000 });
                this.setLoading = false;
              },
              error => {
                  console.log("error",error)
                  this.ErrorService.manageError(error,'');
              })
    )
  }

  // Chantier
  public getInviteFactures() {
    this.loadingFactures = true;
    let criteria = {
      type: 'artisan'
    }
    this.subscriptions.push(
      this.chantierService.getFactures(this.ceProjectInviteId, criteria)
        .subscribe(factures => {
          this.inviteFactures = factures;
          this.loadingFactures = false;
        })
    )
  }

  ////////////////////////////////////////////////
  /// New UI Functions
  ////////////////////////////////////////////////
  public openProject(){
    if(this.invite?.status != "artisan-refuser"){
      this.openChiffragePage();
    }
  }
  public openChiffragePage(){
    this.router.navigate(
      ['/artisan','dpgf', this.invite.dpgfPricing._id],
      { queryParams: {'code': this.invite.dpgfPricing.code, 'app':true} }
    );
  }
  public goToConsultationsPage(){
    this.router.navigate(['/artisan/project-list']);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
