<div id="booster-profile-edit-agency-construction-carbone-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h4 class="ag-heading-h4 ag-weight-600 no-margin">Les matériaux issues du réemploi.</h4> -->
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">        
        
        <div class="section-container margin-bottom-30">
          <h4 class="ag-heading-h4 ag-weight-600 no-margin margin-bottom-24">Les matériaux issues du réemploi.</h4>
          <p class="ag-para-1 margin-bottom-10">L’entreprise Celestin Charpente : </p>
          <div class="checkboxes-list-wrapper">
            <span class="checkbox-item ag-para-1" 
              *ngFor="let item of materiauxIssuesReemploiList"
              [ngClass]="{'choosed': selectedReemploiItems.includes(item.value)}"
              (click)="chooseItem('reemploi', item.value)"
              >{{ item.name }}</span>
          </div>
        </div>

        <div class="section-container">
          <h4 class="ag-heading-h4 ag-weight-600 no-margin margin-bottom-24">Les matériaux bas carbones.</h4>
          <p class="ag-para-1 margin-bottom-10">Votre entreprise utilise :  </p>
          <div class="checkboxes-list-wrapper">
            <span class="checkbox-item ag-para-1" 
              *ngFor="let item of materiauxBasCarbonesList"
              [ngClass]="{'choosed': selectedCarbonesItems.includes(item.value)}"
              (click)="chooseItem('carbones', item.value)"
              >{{ item.name }}</span>
          </div>
        </div>
                
        <div class="submit-btn-container text-center margin-top-30" (click)="emitSaveData()">
          <button class="ag-btn-primary-sm">Modifier les informations</button>
        </div>

      </div>
    </div>
  </div>
</div>
