import { Pipe, PipeTransform, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';


@Pipe({
  standalone:true,
  name: 'linebreak'
})
export class LinebreakPipe implements PipeTransform {

  transform(value:any) {
      if (value) {
          return value.replace(new RegExp("\n", 'g'), "<br>");;
      }
      return value;
  }

}
