<div class="month">
  <ul>
    <li class="prev" (click)="prevWeek()">&#10094;</li>
    <li class="next" (click)="nextWeek()">&#10095;</li>
    <li>
      {{monthName}}<br>
      <span style="font-size:18px">du {{weekStart}}-{{weekEnd}}</span>
    </li>
  </ul>
</div>

<ul class="weekdays">
  <li *ngFor="let day of weekDay">
    {{day.label}}<br>
    {{day.date}}
  </li>
</ul>

<ul class="days">
  <li *ngFor="let day of weekDay" >
    <div *ngFor="let creneau of creneaux">
      <span (click)="addToChosen(day.date+'T'+creneau, (day.day > dayNumber || weekNumber!=0))" [ngClass]="{'selectable': day.day > dayNumber || weekNumber!=0,'active': chosenCreneau.indexOf(day.date+'T'+creneau) != -1}">
        {{creneau}}
      </span>
    </div>
  </li>
</ul>
<br>
<span class="tag tag-1"*ngFor="let creneau of chosenCreneau">{{creneau| dateRDV}} </span>


<!-- <li><span class="active">10</span></li> -->
