<div class="modal fade" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog">
    <div class="loginmodal-container" *ngIf="switch == 'connecte'">
      <div class="head">
        <a (click)="closeModal()" class="close">Fermer</a>
      </div>
      <div class="form-container">
        <h1>Se connecter</h1><br>
        <form>
          <input [(ngModel)]="user.email" type="text" name="email" placeholder="Email">
          <input [(ngModel)]="user.password" type="password" name="password" placeholder="Mot de passe">
          <button [disabled]="loading" (click)="login()" class="login loginmodal-submit">
            <div *ngIf="!loading">Se connecter</div>
            <app-spinner *ngIf="loading"></app-spinner>
          </button>
        </form>
        <div id="wrong-login" class="alert alert-danger hide-wrong">
          <span class="glyphicon glyphicon-warning-sign" aria-hidden="true"></span> Utilisateur ou mot de passe <strong>incorrect!</strong>
        </div>
        <div class="login-help">
          <a [routerLink]="[ '/mot-de-passe-oublie']" (click)="closeModal()">Mot de passe oublié</a>
        </div>
        <hr>
        <button *ngIf="!authService.currentEnvironment" (click)="gotoSubcribe('/architect/subscribe')" (click)="switch = 'subscribe'" class="login loginmodal-submit-reverse" >S'inscrire</button>
      </div>
    </div>
  </div>
</div>
