<div class="nav-bar hidden-xs hidden-sm">
  <div class="align-verticaly">
    <div *ngFor="let entry of tableEntry; first as first; let i = index"
    (click) = "navigation(i)"
    [pTooltip]="entry.name"
    [class.selected]='this.selected == entry.id'
    class='ce-nav-button'>
    <i class='{{entry.icon}} fa-2x' aria-hidden="true"></i>
    <span class="bubble" *ngIf="entry.id == 'devis' && devisNumber!=0">{{devisNumber}}</span>
    <span *ngIf="entry.isBeta" class="beta-tag">Beta</span>
  </div>
  </div>
</div>
