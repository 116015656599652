<div id="confirm-delete-dce-post-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title modal-header-title">Suppression d’un poste</h3>
      </div>
      <div class="modal-body">
        <p>Vous allez supprimer le poste <b>{{ selectedPostToDelete?.designation || ""}}</b>, êtes-vous sûr de vouloir continuer ?</p>
      </div>
      <div class="modal-footer text-center">
        <button type="button" data-dismiss="modal" class="ag-btn-tertiary-sm margin-right-20">Annuler</button>
        <button type="button" class="ag-btn-primary-sm submit-btn" (click)="confirmDeletePost()">Continuer</button>
      </div>
    </div>
  </div>
</div>

<div id="confirm-delete-dce-sous-post-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title modal-header-title">Suppression d’un sous poste</h3>
      </div>
      <div class="modal-body">
        <p>Vous allez supprimer le sous poste <b>{{ selectedSousPostToDelete?.designation || ""}}</b>, êtes-vous sûr de vouloir continuer ?</p>
      </div>
      <div class="modal-footer text-center">
        <button type="button" data-dismiss="modal" class="ag-btn-tertiary-sm margin-right-20">Annuler</button>
        <button type="button" class="ag-btn-primary-sm submit-btn" (click)="confirmDeleteSousPost()">Continuer</button>
      </div>
    </div>
  </div>
</div>
<div id="confirm-delete-dce-sousLot-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title modal-header-title">Suppression d’une section</h3>
      </div>
      <div class="modal-body">
        <p>Vous allez supprimer la section <b>{{ selectedSousLotToDelete?.title || ""}}</b>, êtes-vous sûr de vouloir continuer ?</p>
      </div>
      <div class="modal-footer text-center">
        <button type="button" data-dismiss="modal" class="ag-btn-tertiary-sm margin-right-20">Annuler</button>
        <button type="button" class="ag-btn-primary-sm submit-btn" (click)="confirmDeleteSousLot()">Confirmer</button>
      </div>
    </div>
  </div>
</div>