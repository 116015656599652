import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';

// static data
import activitiesList from '../../booster-profile-forms-components/booster-profile-corps-etat-form/activities-list';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone: true,
    selector: 'app-booster-profile-edit-garantie-decennale-modal',
    imports:[
      NgSharedModule
    ],
    templateUrl: './booster-profile-edit-garantie-decennale-modal.component.html',
    styleUrls: ['./booster-profile-edit-garantie-decennale-modal.component.scss'],
})
export class BoosterProfileEditGarantieDecennaleModalComponent implements OnInit, OnChanges {

  @Input() corpsDetat:any;
  @Output() saveData: EventEmitter<any> = new EventEmitter<any>();

  public selectedCorps: any = {};
  public agencyActivitiesList: any = [];  
  public customAgencyActivitiesList: any = {
    inputsList: []
  }
  public checkedActivities:any = {};

  constructor() { }

  ngOnInit(): void {
    this.agencyActivitiesList = activitiesList;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.corpsDetat && Object.keys(changes.corpsDetat.currentValue).length){
      setTimeout(() => {
        this.setTheSelectedData(changes.corpsDetat.currentValue);
      }, 200);
    }
  }
  private setTheSelectedData(corpsDetat){
    for(let i = 0; i < corpsDetat.length; i++){
      let singleItem = corpsDetat[i];
      if(typeof singleItem != "object"){
        let singleItemParts = singleItem.split('_');

        let activity = singleItemParts[0];
        let section = singleItemParts[1];
        let checkBoxItem = singleItemParts[2];

        let selected_item_id = `${activity}_${section}_${checkBoxItem}`;

        this.selectedCorps[selected_item_id] = true;
      }else{
        this.customAgencyActivitiesList.inputsList.push(singleItem);
      }
    }

    this.checkIfAllItemsSelectedInSection(corpsDetat);
  }
  private checkIfAllItemsSelectedInSection(artisanCorpsDetat){
    let sectionsMapper = {};

    /// count all Activities items
    for(let i = 0; i < this.agencyActivitiesList.length; i++){
      let currentActivity = this.agencyActivitiesList[i];
      
      sectionsMapper[currentActivity.id] = {};

      let listItemsCount = 0;
      for(let j = 0; j < currentActivity.sectionsLists.length; j++){
        let currentSection = currentActivity.sectionsLists[j];

        listItemsCount+=currentSection.list.length;
      }
      sectionsMapper[currentActivity.id] = listItemsCount;
    }


    /// count already checked items for every section
    let checkedItems = {};

    let clonedArtisanCorpsDetat = artisanCorpsDetat.filter(item => typeof item == "string");

    for(let i = 0; i < clonedArtisanCorpsDetat.length; i++){
      let splittedItems = clonedArtisanCorpsDetat[i].split("_");

      let activityId = splittedItems[0];

      if(!checkedItems[activityId]){
        checkedItems[activityId] = 1;
      }else{
        checkedItems[activityId]++;
      }
        
    }

    /// see if all items checked on a single activity, if it does make the ativity id tru
    for(let key in checkedItems){
      if(sectionsMapper[key] && (sectionsMapper[key] == checkedItems[key])){
        this.checkedActivities[key] = true;
      }
    }
  }


  public checkAllSections(checked, activity){
    
    for(let i = 0; i < activity.sectionsLists.length; i++){
      let currentSection = activity.sectionsLists[i];

      for(let j = 0; j < currentSection.list.length; j++){
        let singleListItem = currentSection.list[j];
        let itemId = `${activity.id}_${currentSection.id}_${singleListItem.value}`;

        if(checked){
          this.selectedCorps[itemId] = true;
        }else{
          if(this.selectedCorps[itemId]){
            delete this.selectedCorps[itemId];
          }
        }

      }
    }
    
    // this.emitSave();
  }
  public chooseItem(activityId, sectionId, checkboxValue){
    let selected_item_id = `${activityId}_${sectionId}_${checkboxValue}`;
    
    // remove
    if(this.selectedCorps[selected_item_id]){
      delete this.selectedCorps[selected_item_id];
    }else{ // add
      this.selectedCorps[selected_item_id] = true;
    }
    
    /// save after every check/uncheck
    // this.emitSave();
  }

  public removeCustomItem(itemIndex){
    this.customAgencyActivitiesList.inputsList = 
    this.customAgencyActivitiesList.inputsList.filter((item, index) => index != itemIndex);

    // this.emitSave();
  }

  public emitSave(){
    /// Merge Custom Lists & non Custom Lists into one Array
    let mainData = [...Object.keys(this.selectedCorps)];
    
    // if there is custom data merge it
    if(this.customAgencyActivitiesList.inputsList.length){
      mainData.push(...this.customAgencyActivitiesList.inputsList);
    }  
    
    // emit Save Data
    this.saveData.emit({corpsDetat: mainData});
  }



}
