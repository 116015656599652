import { Component, OnInit,Input,Output, EventEmitter, NO_ERRORS_SCHEMA} from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';


@Component({
    selector: 'app-artisan-project-list',
    templateUrl: './artisan-project-list.component.html',
    imports:[
      NgSharedModule
    ],
    styleUrls: ['./artisan-project-list.component.scss'],
    standalone: true,
    
})
export class ArtisanProjectListComponent implements OnInit {
  @Input()invites:any;
  public matching:any = {
    "artisan-invite" :"Invité",
    "artisan-accepter":"En cours",
    "artisan-refuser" : "Refusé",
    "rdv-pris" : "Chiffrage",
    "selectionne" : "Marché remporté",
    "refuse": "Non sélectionné",
    "signe": "Signé",
    "chantier":"Chantier",
    "encours":"En cours",
    "pricing-done":'Envoyé',
  }
  constructor() { }

  ngOnInit() {
  }
}
