import { Component, OnInit, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';


@Component({
    standalone: true,
    selector: 'app-booster-profile-edit-album-title-modal',
    imports:[
      NgSharedModule
    ],
    templateUrl: './booster-profile-edit-album-title-modal.component.html',
    styleUrls: ['./booster-profile-edit-album-title-modal.component.scss'],
})
export class BoosterProfileEditAlbumTitleModalComponent implements OnInit {

  @Input() albumTitle: string = "";
  @Output() saveTitle: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public emitSaveAlbumTitle(){
    console.log(this.albumTitle);

    this.saveTitle.emit(this.albumTitle);
  }

}
