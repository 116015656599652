import { Component, OnInit, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';


@Component({
    standalone: true,
    selector: 'app-add-recommandations-contact-modal',
    imports:[
      NgSharedModule
    ],
    templateUrl: './add-recommandations-contact-modal.component.html',
    styleUrls: ['./add-recommandations-contact-modal.component.scss'],
})
export class AddRecommandationsContcatModalComponent implements OnInit {
  @Output() saveContacts: EventEmitter<any> = new EventEmitter<any>();

  public newContactForm: any = {
    nameArchi: "",
    phoneNumber: "",
    maitreDoeuvre: "",
    mailArchi: ""
  };
  public showContactForm : boolean = true;
  public contactsList: any = [];

  constructor() { }

  ngOnInit(): void {
  }
  private resetContactForm(){
    for(let key in this.newContactForm){
      this.newContactForm[key] = "";
    }
  }
  public showAddNewContactForm(){
    this.showContactForm = true;
  }
  public saveNewContact(){
    let newContact = {...this.newContactForm}
    this.resetContactForm();

    this.contactsList.push(newContact);

    this.showContactForm = false;

    // Emit Save Contacts
    this.emitSaveContacts(this.contactsList);
  }
  public removeContact(contactIndex){
    // Filter
    this.contactsList = this.contactsList.filter((item, index) => index != contactIndex);

    // Emit Save Contacts
    this.emitSaveContacts(this.contactsList);
  }

  private emitSaveContacts(contactsList){
    this.saveContacts.emit(contactsList);
  }

}
