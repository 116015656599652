import {NgModule,Component,ElementRef,AfterViewInit,Input,Output,EventEmitter,ContentChild,OnChanges,forwardRef, Renderer2} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms';
import * as Quill from 'quill';
import BlotFormatter from 'quill-blot-formatter';
// import ImageUploader from "quill-image-uploader";
import { MessageService } from 'primeng/api';
import * as IAM from 'aws-sdk/clients/iam';
import CustomLinkSanitizer from './sanitzieLink'
import CustomImage from './imageQuill';
import { NgSharedModule } from '../ngshared.module';
import { AuthService } from 'src/app/_services';
import { DpgfPostesService } from 'src/app/_services';
import { environment } from 'src/environments/environment';
const _ = require('lodash')
Quill.register('modules/blotFormatter', BlotFormatter);
// Quill.register("modules/imageUploader", ImageUploader);
Quill.register({'formats/image': CustomImage});
Quill.register(CustomLinkSanitizer, true)

export const EDITOR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Editor),
  multi: true
};
// <button class="ql-image" aria-label="Insert Image"></button>
const Inline = Quill.import('blots/inline');
    class PlainTextLink extends Inline {
      static blotName = 'link';
      static tagName = 'SPAN';
      static className = 'ql-link';  // Ensures the custom class is used

      static create(value: string) {
        let node = super.create(value);
        node.classList.add(PlainTextLink.className);  // Apply custom class
        node.removeAttribute('href');
        node.setAttribute('data-href', value);
        return node;
      }
      static formats(node: HTMLElement): string {
        return node.getAttribute('data-href');
      }
    }
@Component({
    standalone:true,
    selector: 'app-editor',
    imports:[NgSharedModule],
    providers: [EDITOR_VALUE_ACCESSOR],
    styleUrls: ['./editor.component.scss'],
    template: `
        <div [ngClass]="'ui-widget ui-editor-container ui-corner-all'" [class]="styleClass">
            <div class="ui-editor-toolbar ui-corner-top" [ngClass]="{'ui-widget-header' : theme == 'snow'}">
                <span class="ql-formats">
                    <select class="ql-header">
                        <option value="1">H1</option>
                        <option value="2">H2</option>
                        <option value="3">H3</option>
                        <option value="4">H4</option>
                        <option value="5">H5</option>
                        <option value="6">H6</option>
                        <option value="">Normal</option>
                    </select>
                </span>
                <span class="ql-formats">
                    <button class="ql-bold" aria-label="Bold"></button>
                    <button class="ql-italic" aria-label="Italic"></button>
                    <button class="ql-underline" aria-label="Underline"></button>
                </span>
                <span class="ql-formats">
                    <button class="ql-list" value="bullet" aria-label="Unordered List"></button>
                    <select class="ql-align">
                        <option selected></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                    </select>
                </span>
                <span class="ql-formats">
                    <select class="ql-color">
                        <option value="rgb(0, 0, 0)"></option>
                        <option value="rgb(230, 0, 0)"></option>
                        <option value="rgb(255, 153, 0)"></option>
                        <option value="rgb(255, 255, 0)"></option>
                        <option value="rgb(0, 138, 0)"></option>
                        <option value="rgb(0, 102, 204)"></option>
                        <option value="rgb(153, 51, 255)"></option>
                        <option value="rgb(255, 255, 255)"></option>
                        <option value="rgb(250, 204, 204)"></option>
                        <option value="rgb(255, 235, 204)"></option>
                        <option value="rgb(204, 224, 245)"></option>
                        <option value="rgb(235, 214, 255)"></option>
                        <option value="rgb(187, 187, 187)"></option>
                        <option value="rgb(102, 185, 102)"></option>
                    </select>
                </span>
                <span class="ql-formats">
                    <button class="ql-image"></button>
                </span>
                <span class="ql-formats">
                    <button class="ql-indent" value="-1" ngbPopover="Indent -1" triggers="mouseenter:mouseleave"></button>
                    <button class="ql-indent" value="+1" ngbPopover="Indent +1" triggers="mouseenter:mouseleave"></button>
                </span>
                <span class="ql-formats">
                    <button class="ql-link" aria-label="Insert Link"></button>
                </span>
                <span class="ql-formats">
                    <button class="ql-clean" aria-label="Remove Styles"></button>
                </span>
            </div>
            <div class="ui-editor-content" [ngStyle]="style" [style.height.px]="height"></div>
        </div>
    `
})
export class Editor implements AfterViewInit,ControlValueAccessor {
    // <button class="ql-list" value="ordered" aria-label="Ordered List"></button>

    @Output() onTextChange: EventEmitter<any> = new EventEmitter();

    @Output() onSelectionChange: EventEmitter<any> = new EventEmitter();

    // @ContentChild(Header, { static: false }) toolbar;

    @Input() theme:string = "snow";

    @Input() style: any;

    @Input() styleClass: string;

    @Input() placeholder: string;

    @Input() formats: string[];

    @Input() modules: any;

    @Input() bounds: any;

    @Input() scrollingContainer: any;

    @Input() debug: string;

    @Output() onInit: EventEmitter<any> = new EventEmitter();

    value: string;

    height: number = 500;

    _readonly: boolean;

    showToolBar: boolean = false;

    onModelChange: Function = () => {};

    onModelTouched: Function = () => {};

    quill: any;
    pastedText: any;

    constructor(public el: ElementRef,private renderer: Renderer2,private messageService: MessageService,private authService:AuthService,private dpgfPostesService: DpgfPostesService,) {}

    ngAfterViewInit() {
        if(this.authService.currentEnvironment) Quill.register(PlainTextLink);
        let editorElement = this.findSingle(this.el.nativeElement ,'div.ui-editor-content');
        let toolbarElement = this.findSingle(this.el.nativeElement ,'div.ui-editor-toolbar');
        let defaultModule = {toolbar: toolbarElement,blotFormatter: {},history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true
          },clipboard: {
            matchVisual: false
          }
        };
        // let imageUploaderModule = {
        //   upload: async (file) => {
        //     return new Promise((resolve, reject) => {
        //       setTimeout(() => {
        //         console.log(file);
        //         resolve(
        //           "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/480px-JavaScript-logo.png"
        //         );
        //       }, 1000);
        //     });


        //   }
        // }
        let modules = this.modules ? {...defaultModule, ...this.modules} : defaultModule;
        // modules["imageUploader"] = imageUploaderModule;

        this.renderer.listen(editorElement, 'paste', (event) => {
            // OLD Code
            // this.pastedText = event.clipboardData.getData('text');
            // this.pastedText = event.clipboardData.getData('text/html');            

            setTimeout(()=>{
                let htmlContent = this.quill.root.innerHTML; // get quill html
                const formattedOldText = this.removeSpecialCharacters(htmlContent); // remove special characters

                this.quill.root.innerHTML = formattedOldText; // set the new formeted html

                
                // OLD Code
                // this.pastedText='';
                
                // const oldText = this.quill.getText();

                // const formattedOldText = this.removeSpecialCharacters(htmlContent);
                // console.log("formattedOldText : ", formattedOldText);

                // this.quill.setText(formattedOldText);
            },50);

          });
        this.quill = new Quill(editorElement, {
          modules: modules,
          placeholder: this.placeholder,
          readOnly: this.readonly,
          theme: this.theme,
          formats: this.formats,
          bounds: this.bounds,
          debug: this.debug,
          scrollingContainer: this.scrollingContainer
        });
        this.quill.blur();//make quil editor not focused by default: Noura

        if(this.value) {
            this.quill.pasteHTML(this.value.replace(new RegExp("\n", 'g'),"<br>").replace(new RegExp("\r", 'g'),"<br>"));
            // this.height = Math.max(editorElement.children[0].scrollHeight,550)
        }

        this.quill.on('text-change', (delta, oldContents, source) => {
            if (source === 'user') {
                console.log()
                let image='';
                delta.ops.forEach((element,index) => {
                    if(_.get(element,'insert.image','')) image=_.get(element,'insert.image','')
                    if(image && !environment.onPremise) window.analytics.track('add_image_cctp',{properties:{acValue: 'add_image_cctp'}});

                });

                if(image && image.indexOf('image/png')==-1 && image.indexOf('image/jpg')==-1 && image.indexOf('image/jpeg')==-1){
                    this.quill.history.undo();
                    this.messageService.add({ key: 'toast', severity: 'error', summary: "Format d'image non pris en charge", detail: "Le format d'image que vous importez n'est pas pris en charge. Les formats supportés sont : png, jpg et jpeg", life: 5000 });
                    return
                }
                let html = editorElement.children[0].innerHTML;
                // html = this.removeSpecialCharacters(html);
                // when the user types something, emit the current cursor range to add variables in the right places
                const range = this.quill.getSelection();
                this.onSelectionChange.emit({
                    range: range,
                });


                let text = this.quill.getText();
                // const formattedText = this.removeSpecialCharacters(text);

                if (html === '<p><br></p>') {
                    html = null;
                }
                this.onTextChange.emit({
                    htmlValue: html,
                    textValue: text,
                    delta: delta,
                    source: source
                });

                this.onModelChange(html);
                this.onModelTouched();
            }
        });
        this.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
            if(this.pastedText){
                delta.ops = delta.ops.map(op => {
                    if(!_.get(op,'insert.image','')) return {insert: op.insert}
                    else return {insert:''}
                })
            }
            return delta
          })
        this.quill.on('selection-change', (range, oldRange, source) => {
          if(range){
            this.showToolBar = true;
          }else{
            this.showToolBar = false;
          }
          let html = editorElement.children[0].innerHTML;
            if (html === '<p><br></p>') {
                html = null;
            }
            
            this.onSelectionChange.emit({
                range: range,
                oldRange: oldRange,
                htmlValue: html,
                source: source
            });
        });

        this.onInit.emit({
            editor: this.quill
        });
    }
    public findSingle(element: any, selector: string): any {
       if (element) {
           return element.querySelector(selector);
       }
       return null;
   }
    writeValue(value: any) : void {
        this.value = value;

        if(this.quill) {
            if(value)
                this.quill.pasteHTML(value.replace(new RegExp("\n", 'g'),"<br>").replace(new RegExp("\r", 'g'),"<br>"));
            else
                this.quill.setText('');
        }
    }

    registerOnChange(fn: Function): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onModelTouched = fn;
    }

    getQuill() {
        return this.quill;
    }

    @Input() get readonly(): boolean {
        return this._readonly;
    }

    set readonly(val:boolean) {
        this._readonly = val;

        if(this.quill) {
            if(this._readonly)
                this.quill.disable();
            else
                this.quill.enable();
        }
    }
    private removeSpecialCharacters(text){
        let forrmattedText = text.replaceAll('0x202F', ' ').replaceAll('0xfeff', ' ');
        return forrmattedText;
    }

    public insertVariable(data) {
        if (this.quill) {
          if (data.range) {
            // Insert variable at the current cursor position
            this.quill.insertText(data.range.index, data.variable);
            this.quill.setSelection(data.range.index + data.variable.length);
          } else {
            // Append variable at the end if no cursor position found
            this.quill.root.innerHTML += data.variable;
          }
          const htmlContent = this.quill.root.innerHTML;
          this.onTextChange.emit({
            htmlValue: htmlContent
          });
        }
    }
    
    public getCursorPostion(){
        const range = this.quill.getSelection();
        return range;
    }
    
//   private uploadImage(file): Promise<any>{
//     return this.dpgfPostesService.uploadFile(file).toPromise();
//   }

}
