<section class="filter-wrapper">
  <div class="keyword-wrapper">
    <!-- <span #inputSpan contenteditable="true" placeholder="Description du poste" class="edit-span" [textContent]="textContent" (input)="manageType($event.target.textContent)"></span> -->
    <div class="head" *ngIf="modify"   id="head-lot" >
      <div class="flex-middle icon" (click)="getLot($event,id)" *ngIf="isLotTitle">
        <img id="lot-icon" *ngIf="(icon) != ''" class="handle grab-it" [src]="'assets/dpgf/Icon_DPGF/' +icon" alt="" height="15" width="15">
      </div> 

    <div id="disabled-lot-number-container" [ngStyle]="{'display': (isAutomateNumberIsChecked && isLotTitle) ?  'block':'none'  }">
      <input  #disabledNmbr
              id="nmbr" 
              [value]="prefix"
              (input)="prefix" 
              class="nmbr" disabled 
              aria-disabled="true" 
              >
      <span class="mytooltip">L’édition de la numérotation est automatique <br>Pour la changer, désactivez l’option de numérotation automatique</span>
    </div>
      <input #lotNumbr  [ngStyle]="{'display': (!isAutomateNumberIsChecked && isLotTitle) ?  'block':'none'  }"
          id="nmbr" 
          [value]="prefix" 
          (input)="prefix"
          class="nmbr" 
          [(ngModel)]="prefix"
          [disabled]="false"
          (keydown)="onKeydownOnInput($event)"
          (keydown.enter)="changeLotNumber(inpuText.value, lotNumbr.value||disabledNmbr.value, icon)"
          >
      <textarea autosize #inpuText            
              type="text"
              [placeholder]="placeholder"
              class="title1 edit-span"
              id="title"
              (keydown)="onKeydownOnInput($event)"
              (keydown.enter)="changeLotNumber(inpuText.value, lotNumbr.value||disabledNmbr.value, icon)"
              [value]="textContent||''"
              (input)="manageType(inpuText.value)"
              [ngClass]="{'selected':selected}"
              autofocus
              >
      </textarea>
      <div  id="myInputautocomplete-list" class="autocomplete-items">
        <div *ngFor="let result of results;let i = index" (click)="choose(result,lotNumbr.value||prefix)" [ngClass]="{'autocomplete-active':(currentFocus == i)}">
          <div class="flex-middle" style=" background-color: transparent; margin: 5px; margin-right: 10px; margin-left: 10px; width: 10px; height: 20px;"> 
            <img *ngIf="(result.icon||icon) != ''" [src]="'assets/dpgf/Icon_DPGF/' + result.icon||icon" alt="" height="20">
          </div>
          <span >{{result.sugg}}</span>
        </div>
      </div> 
    </div>

    <span class="title"
          *ngIf="!modify "
          [id]="'lot- header'+id"
          (click)="editable()"
          [ngClass]="{'selected':selected}"
          [ngStyle]="{'background-color': color}">
          <img *ngIf="(icon||'generalites.png') != ''" [src]="'assets/dpgf/Icon_DPGF/' + icon" alt="" height="20">
          {{prefix}} -
          {{textContent}} <span class="place-holder" *ngIf="textContent==''">{{placeholder}}</span>
    </span>
  </div>
  <p-overlayPanel #op [showCloseIcon]="true" [appendTo]="'body'">
  <ng-template pTemplate>
    <ul *ngIf="!(loadingLot | async)" class="icon-list">
      <li
        class="icons"
        *ngFor="let icon of icons"
        (click)="setIcon(icon); op.hide()">
        <img [src]="'assets/dpgf/Icon_DPGF/' + icon" />
      </li>
    </ul>
    <app-spinner *ngIf="(loadingLot | async)"></app-spinner>
  </ng-template>
</p-overlayPanel>
</section>
