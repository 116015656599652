import { Component, OnInit, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';


import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var only;

@Component({
    standalone: true,
    selector: 'app-booster-profile-page-create-album-modal',
    imports:[
      NgSharedModule, 
    ],
    templateUrl: './booster-profile-page-create-album-modal.component.html',
    styleUrls: ['./booster-profile-page-create-album-modal.component.scss'],
})
export class BoosterProfilePageCreateAlbumModalComponent implements OnInit {

  @Output() createNewAlbum: EventEmitter<any> = new EventEmitter<any>();

  public album: any = {
    title: "",
    photos: []
  };
  public showAlbumPhotos: boolean = false;
  public uploading: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
  
  onUploadMultiFiles(event){
    if(event.originalEvent.body.length != 0){
      // for(let i = 0 ; i< event.originalEvent.body.length ; i++){  
      //   // let photo = only(event.originalEvent.body[i], "id name uploadURL size extension");
      //   let photo = {
      //     id:event.originalEvent.body[i].key,
      //     name:event.originalEvent.body[i].originalname, 
      //     uploadURL:event.originalEvent.body[i].location, 
      //     size:event.originalEvent.body[i].size, 
      //     extension:event.originalEvent.body[i].mimetype,
      //   }
      //   this.album.photos.push(photo);
      // }
      this.album.photos=[...event.originalEvent.body];
      this.showAlbumPhotos = true;
    }

  }
  public emitCreateAlbum(){
    this.createNewAlbum.emit(this.album);
  }
  
}
