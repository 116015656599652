import { Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import { environment } from 'src/environments/environment';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/first';
import { Store , select} from '@ngrx/store';
import * as fromSelectors from '../_store';
import * as actions from '../_store/dpgf.actions';
import { NgSharedModule } from 'src/app/all/ngshared.module';


import { Observable } from 'rxjs';
import {ConfirmationService} from 'primeng/api';
import { saveAs } from 'file-saver';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { AuthService, DpgfPostesService ,ArchitectService} from 'src/app/_services';
import { FileFolderPipe } from 'src/app/_pipe/fileFolder.pipe';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { DpgfModule } from '../../dpgf.module';
declare var $:any;

declare var only;
declare var JSZip;
@Component({
  standalone:true,
  selector: 'app-dpgf-folders',
  imports:[
    DpgfModule,
    FileFolderPipe,
    SpinnerComponent,
    NgSharedModule
  ],
  templateUrl: './dpgf-folders.component.html',
  styleUrls: ['./dpgf-folders.component.scss']
})
export class DpgfFoldersComponent implements OnInit {
  @Input() mode;
  @Input() dpgfPricingId:any;
  @Input() code:any;
  private STATIC_FILES_API_URL:string = "api/v1/upload-files";
  public baseApi = '';
  public dpgf;
  public loadingFiles = false;
  public uploading = false;
  public renameData = {name: "", path : "", id :""};
  public selectedFolder = {path : "files",name :"", noUpload : false, noFolder : false, noEdit : false};
  public isSafari = false;
  public downloadAllVar = false;
  public imageExtension = ["tif","jpg","jpeg","png","gif"];
  public allExtension = ["tif","jpg","jpeg","png","gif","xlsx","docx","pdf"];
  public files$:Observable<any[]>;
  public files;folderFiles;dpgfFolder;
  public renamePopUp:boolean = false;
  public licence;
  public pathArray = [{path : "files",name :"DCE", noUpload : false, noFolder : false, noEdit : false}];
  folderlength: number;
  downloadingPdf: boolean;
  downloadingCCTP: boolean;
  public useXHRPlugin:boolean = false;
  public userLoggedIn:boolean = false;projectLicence = 'none';
  public firstValueEnvirenementObservable = new Observable<boolean>(observer => {
    observer.next(environment.onPremise);
    observer.complete();
  });
  constructor(private store: Store,
              private confirmationService: ConfirmationService,
              private cd: ChangeDetectorRef,
              public authService: AuthService,
              public dpgfPostesService: DpgfPostesService,
              private architectService: ArchitectService
            ) { }

  ngOnInit(): void {
    /// this is used to check if Enviroment is Minar, to use S3 or Upload on backend Statics folder
    this.isSafari =  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.files$ = this.store.pipe(select(fromSelectors.getFolder,this.selectedFolder.path));
    this.files$.subscribe(
      (data)=>{
        this.folderlength=data.filter(f=>f.type== 'folder' || f.title).length;
      }
    )
    this.store.pipe(select(fromSelectors.getFolder,"files")).subscribe((files)=>{
      this.files = files;
    })
    this.store.pipe(select(fromSelectors.getAny, {name : "projectLicence"} )).subscribe((licence)=>{
      this.projectLicence = licence;
    })
    this.store.pipe(select(fromSelectors.getDpgf)).subscribe((dpgf)=>{
      this.dpgf = dpgf;
    })
    this.store.pipe(select(fromSelectors.getDpgf)).subscribe((dpgf)=>{
      this.dpgfFolder = { type : "folder" , name : "Par lot" , children : []};
      for(let i = 0 ; i< dpgf.data.length; i ++){
        this.dpgfFolder.children.push({type : "folder" , name : dpgf.data[i].title  , children : dpgf.data[i].lotFiles })
      }
    })
    
    this.licence = this.authService.licence;
    this.userLoggedIn = this.authService.userLoggedIn;
  }
  public fileEvent(fileInput: any) {
    const AWSService = AWS;
    const region = 'eu-central-1';
    const bucketName = 'bam-consultation-slickshot';
    const IdentityPoolId = 'eu-central-1:7a0af26b-a2d9-4bd5-aa01-dff3f719b7db';
    let files = fileInput.files ||[];
    this.loadingFiles = true;
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
      })
    });
    const s3 = new S3({
      apiVersion: '2006-03-01',
      params: { Bucket: bucketName}
    });
    const ID = function () {
      return Math.random().toString(36).substr(2, 9);
    };
    let filesUpload = files.map(file=>s3.upload({ Key: ID()+"-"+file.name , Bucket: bucketName, Body: file, ACL: 'public-read'}).promise());
    Promise.all(filesUpload).then((filesUploaded)=> {
      this.loadingFiles = false;
      let files = []
      filesUploaded.forEach((f)=>{
        let name = f["Key"].replace(f["Key"].split("-").shift()+"-","")
        files.push({
          name : name,
          cdnUrl : f["Location"],
          extension : f["Key"].split(".").pop(),
          type: "file"
        })
      })
      this.store.dispatch(actions.addInManyFile({path : this.selectedFolder.path , file : files}))
      this.saveModifictaionToServer()
    }).catch(err=>{
      console.log(err);
      this.loadingFiles = false;
    })
  }
  // tocorrect static files
  onUploadMultiFiles(event){
      if(event.originalEvent.body.length != 0){
        let files = event.originalEvent.body;
         this.store.dispatch(actions.addInManyFile({path : this.selectedFolder.path , file : files}))
         this.saveModifictaionToServer()
       }
  }
  saveModifictaionToServer(){
    if(this.selectedFolder.path.includes("data") && this.selectedFolder.path.includes("lotFiles")){
      let i = Number(this.selectedFolder.path.replace("data.","").replace(".lotFiles",""))
      if(!isNaN(i)){
        this.store.dispatch(actions.updateLotFiles({i}))
      }
    }else{
      this.store.dispatch(actions.saveDpgfToServer({}))
    }
  }
  addFolder(){
    if(this.licence=='without' && this.projectLicence=='none') $('#upgrade-modal').modal("show");
    else {
      let id = Math.random().toString(36).substr(2, 9);
      this.store.dispatch(actions.addInFile({ path : this.selectedFolder.path , file : {_id : id, name : "Nouveau dossier" , type : "folder" , children : []}}))
      this.openRenameId( {name : "Nouveau dossier"} , id)
    }
  }
  onFileUpload(event){
  }
  goToPath(i){
    if(i<this.pathArray.length-1){
      this.selectedFolder = this.pathArray[i];
      this.files$ = this.store.pipe(select(fromSelectors.getFolder,this.selectedFolder.path));
      this.files$.subscribe(
        (data)=>{
          this.folderlength=data.filter(f=>f.type== 'folder' || f.title).length

        }
      )
      this.pathArray = this.pathArray.slice(0,i+1);
    }
  }
  getFilesOrFolder(files,type){
    if(type=='folder') return files.filter(f=>f.type== 'folder' || f.title)
    return files.filter(f=>!(f.type== 'folder' || f.title))
  }
  open(file,index){
    if(file.type == "folder"){
      let path = this.selectedFolder.path + "." + index + ".children"
      this.selectedFolder = {name : file.name , path : path,noUpload : false, noFolder :false , noEdit : false};
      this.pathArray.push(this.selectedFolder)
      this.files$ = this.store.pipe(select(fromSelectors.getFolder,path))
      this.files$.subscribe(
        (data)=>{
          this.folderlength=data.filter(f=>f.type== 'folder' || f.title).length;
        }
      )
    }else if (file.cdnUrl){
      if(!file?.isStaticFile){
        window.open(file.cdnUrl, '_blank');
      }else{
        let link = `api/v1/static/${file.cdnUrl}`;
        window.open(link, '_blank')
      }
    }else if (file.Location){
      window.open(file.Location, '_blank');
    }else if (file.type ==  "lot"){
      let path = "data"
      this.selectedFolder = {name : "Par Lot" , path : path,noUpload : true, noFolder :true , noEdit : true};
      this.pathArray.push(this.selectedFolder)
      this.files$ = this.store.pipe(select(fromSelectors.getFolder,path))
      this.files$.subscribe(
        (data)=>{
          this.folderlength=data.filter(f=>f.type== 'folder' || f.title).length

        }
      )
    }else if(file.title){
      let path = this.selectedFolder.path + "." + index + ".lotFiles"
      this.selectedFolder = {name : file.title , path : path, noFolder :true,noUpload : false , noEdit : false}
      this.pathArray.push(this.selectedFolder)
      this.files$ = this.store.pipe(select(fromSelectors.getFolder,this.selectedFolder.path))
      this.files$.subscribe(
        (data)=>{
          this.folderlength=data.filter(f=>f.type== 'folder' || f.title).length

        }
      )
    }
    if(this.dpgf.docsDownloaded){
      this.markAsDownloaded();
    }
  }
  openRenameId (file,id){
    this.renameData.name = file.name;
    this.renameData.id = id
    this.renameData.path = ""
    this.renamePopUp = true;
  }
  openRename(file,index,type=''){
    if(type=='files') index+=this.folderlength
    this.renameData.name = file.name;
    this.renameData.path = this.selectedFolder.path + "." + index + ".name"
    this.renameData.id = ""
    this.renamePopUp = true;
  }
  openDeletePopup(file,index,type=''){
    if(type=='files') index+=this.folderlength
    let toDelete = this.selectedFolder.path + "." + index
    this.confirmationService.confirm({
           message: 'Êtes vous sur de vouloir supprimer cet élément?',
           header: 'Supprimer',
           acceptLabel : "Supprimer",
           rejectLabel : "Annuler",
           accept: () => {
             this.store.dispatch(actions.deleteFile({path : toDelete}));
             this.saveModifictaionToServer()
           }
       });
  }

  directDelete(file,index,type=''){
    if(type=='files') index+=this.folderlength
    let toDelete = this.selectedFolder.path + "." + index
     this.store.dispatch(actions.deleteFile({path : toDelete}));
     this.saveModifictaionToServer()
  }

  public fileProcess(files,path){
    let allFiles = [];
    for(var i =0; i<files.length ; i++){
      if(files[i].type == "folder"){
        allFiles = allFiles.concat(this.fileProcess((files[i].children ||[]),path  + files[i].name + "/"));
      }else{
        files[i].path = path
        allFiles.push(files[i]);
      }
    }
    return allFiles
  }
  public slugify (str) {
      var map = {
          'a' : 'á|à|ã|â|À|Á|Ã|Â',
          'e' : 'é|è|ê|É|È|Ê',
          'i' : 'í|ì|î|Í|Ì|Î',
          'o' : 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
          'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
          'c' : 'ç|Ç',
          'n' : 'ñ|Ñ'
      };
      for (var pattern in map) {
          str = str.replace(new RegExp(map[pattern], 'g'), pattern);
      };
      return str.replace(new RegExp(" ", 'g'), "_").toLowerCase().replace("/","");
  };
  public async downloadAll(singleLotFiles=null) {
    var zip = new JSZip();
    let files;
    /// Download all lots files
    if(!singleLotFiles){
      files = [...this.files];
      files.push(this.dpgfFolder);
    }else{ // Download single Lot Files
      files = [...singleLotFiles];
    }
    files = this.fileProcess(files,"/");
    let  filesLocation = [];
    filesLocation = files.map(file=>file);
    // get all paths documents
    let slash = filesLocation.map((file)=>file.path).filter(path=>path!='/')
    let uniquePathsArray = [...new Set(slash)]
    let filesPathTab = []
    // create path and a file location empty
    for(let i =0;i<uniquePathsArray.length;i++){
      filesPathTab.push({path:uniquePathsArray[i],fileLocation:[]})
    }
    // fill fileLocations depends on path
    for(let i=0;i<filesLocation.length;i++){
      let currentFile=filesLocation[i];
      for(let j=0;j<filesPathTab.length;j++){
        if(currentFile.path==filesPathTab[j].path){
          filesPathTab[j].fileLocation.push(currentFile.cdnUrl || currentFile.Location)
        }
      }
    }
    //  add  path '/' to the table and then push on it every path equal to /
    filesPathTab.push({path:'/',fileLocation:[]})
    for(let i = 0;i<filesLocation.length;i++){
      if(filesLocation[i]?.path=='/')
      filesPathTab[filesPathTab.length-1].fileLocation.push(filesLocation[i].cdnUrl || filesLocation[i].Location)
    }
    let filesObj = {
      uniquePathsArray,
      filesPathTab,
      dpgfName:this.dpgf.nomPrenom,
      archiId:this.dpgf.architect
    }
    this.downloadAllVar = true;
    this.architectService.generateZIP(this.dpgf._id,filesObj).subscribe((data)=>{
      let url = data.Location
      window.location.href = url
      this.downloadAllVar = false;
      $('#download-zip').modal('hide');
      this.cd.markForCheck();
      if(!this.dpgf.docsDownloaded){
        this.markAsDownloaded();
        
      }
    },err=>{
      console.log('err',err);
      this.downloadAllVar = false;
    });  
  }
  
  public async downloadAllChiffrage() {
    // var zip = new JSZip();
    let tasks$=[];
    tasks$.push(this.dpgfPostesService.downloadDpgfPdfArtisan(this.dpgfPricingId, this.code).first());
    tasks$.push(this.dpgfPostesService.downloadCCTPPricingdf(this.dpgfPricingId, {}, this.code,true).first());
    let files = [...this.files]
    files.push(this.dpgfFolder)
    files = this.fileProcess(files,"/");
    let  filesLocation = [];
    filesLocation = files.map(file=>file);
    let slash = filesLocation.map((file)=>file.path).filter(path=>path!='/')
    let uniquePathsArray = [...new Set(slash)]
    let filesPathTab = []

    for(let i =0;i<uniquePathsArray.length;i++){
      filesPathTab.push({path:uniquePathsArray[i],fileLocation:[]})
    }

    for(let i=0;i<filesLocation.length;i++){
      let currentFile=filesLocation[i];
      for(let j=0;j<filesPathTab.length;j++){
        if(currentFile.path==filesPathTab[j].path){
          filesPathTab[j].fileLocation.push(currentFile.cdnUrl || currentFile.Location)
        }
      }
    }
    // console.log('filesPathTab',filesPathTab)
    filesPathTab.push({path:'/',fileLocation:[]})
    for(let i = 0;i<filesLocation.length;i++){
      if(filesLocation[i]?.path=='/')
      filesPathTab[filesPathTab.length-1].fileLocation.push(filesLocation[i].cdnUrl || filesLocation[i].Location)
    }
    let filesObj = {
      files,
      uniquePathsArray,
      filesPathTab,
      dpgfName:this.dpgf.nomPrenom,
      artisanEmail:this.dpgf.artisanEmail
    }
    this.downloadAllVar = true;
    this.architectService.generateZIP(this.dpgf._id,filesObj).subscribe((data)=>{
      let url = data.Location
      window.location.href = url
      $('#download-zip').modal('hide');
      this.downloadAllVar = false;
    },err=>{
      console.log('err',err);
      this.downloadAllVar = false;
    }); 
    this.cd.markForCheck();
    //if(this.dpgf.docsDownloaded==false){
      this.markAsDownloaded();        
    //}
  }
  public downloadSingleLotFiles(lot){
    let children = lot.lotFiles || lot.children;
    if(children){
      this.downloadAll(children);
    }
  }
  private markAsDownloaded(){
    this.dpgfPostesService.markDocsAsDownloaded(this.dpgf._id)
      .subscribe(
        data => {
          this.dpgf.docsDownloaded = true;
        },
        error => {
          // console.log('ERROR : ', );
        },
      );
  }
  showPop(){
    $('#upgrade-modal').modal("show");
  }
  rename(){
    this.store.dispatch(actions.renameFile({
      id : this.renameData.id,
      selectedFolderPath : this.selectedFolder.path,
      path : this.renameData.path ,
      name : this.renameData.name
    }));
    this.renamePopUp = false;
    this.saveModifictaionToServer()
  }
  /// download dpgf artisan
  public downloadDpgfPdfArtisanF(returnFile=false){
    this.downloadingPdf = true;
    this.dpgfPostesService.downloadDpgfPdfArtisan(this.dpgfPricingId, this.code)
      .subscribe(
        data => {
          if(returnFile) return data.data
          saveAs(data.data.data, `${data.data.pdfName}.pdf`);
          this.downloadingPdf = false;
        },
        err => {
          this.downloadingPdf = false;
        }
      )
  }
  /// CCTP
  public downloadCctp(returnFile=false){
    this.downloadingCCTP = true;
      this.dpgfPostesService.downloadCCTPPricingdf(this.dpgfPricingId, {}, this.code,true)
      .subscribe(
        data => {
          if(returnFile) return data.data
          saveAs(data.data.data, `${data.data.pdfName}.pdf`);
          this.downloadingCCTP = false;
        },
        err => {
          this.downloadingCCTP = false;
        }
      )
  }
  public downloadAllCheck(){
    if(environment.onPremise){
      this.downloadAllForOnPremise();
    }else{
      this.downloadAll();
      if(!environment.onPremise)window.analytics.track('download_DCE_documents',{properties:{acValue: 'download_DCE_documents'}});
    }
  }
  public downloadAllForOnPremise(){
    var zip = new JSZip();
    let files = [...this.files];
    files.push(this.dpgfFolder);
    files = this.fileProcess(files,"/");
    let filesIds = files
      .filter(file => file?.isStaticFile)
      .map(item => {
        return {
          id: item.id,
          name: item.name,
          path: item.path,
          cdnUrl: item.cdnUrl,
        }
      });
    this.downloadAllVar = true;

    this.dpgfPostesService.getStaticFiles(filesIds)
      .subscribe(
        async (data) => {
          for(let i = 0; i < data.length; i++){
            let singleFile = data[i];
            zip.file(this.slugify(singleFile.path) + singleFile.name ,singleFile.blobData["data"]);
          }
          let blob = await zip.generateAsync({type:"blob"});
          saveAs(blob, `DCE-${this.dpgf.nomPrenom}.zip`);
          $('#download-zip').modal('toggle');
          this.downloadAllVar = false;
          this.cd.markForCheck();
          if(!this.dpgf.docsDownloaded){
            this.markAsDownloaded();
          }
        },
        error => {
          console.log("error : ", error);
          $('#download-zip').modal('toggle');
          this.downloadAllVar = false;
        }
      );
  }
}
