<!-- <form [formGroup]="subscriptionForm" > -->
    <div class="color-text">
        <p class="text-size-18-weight-700 color-lineheight" style="padding-bottom:8px;">Gestion du compte</p>
        <p style="padding-bottom:32px;width: 77%;">Apportez des modifications à votre adresse e-mail et à votre mot de passe. Ces informations sont privées et n’apparaîtront pas dans votre profil public.</p>
        <div class="row">
            <form style="padding-bottom:32px;" class="font-size-14-weight-400" [formGroup]="subscriptionForm">
                <p class="font-size-14-weight-400">Identifiant de connexion</p>
                <input formControlName="email" class="input-style" (keyup)="onKeyUpMail()">
                <button (click)="updateUserEmail()" style="border:none;background-color:transparent;" [disabled]="!mailExist" >Changer l’identifiant </button>
                <br *ngIf="formErrors.email">
                <small *ngIf="formErrors.email" class="text-red">
                    {{ formErrors.email }}
                </small>
           </form>
        <div class="backgroud-color-wid77-height1px"></div>
        <div style="padding-bottom:32px;" class="font-size-14-weight-400">
            <p class="text-size-16-weight-700 color-lineheight" style="padding-top:32px;padding-bottom:32px;">Modifier le mot de passe</p>
            <button class="padding-left-right-24 padding-top-bottom-5 update-password-button" data-toggle="modal" data-target="#change-password">Modifier le mot de passe</button>
       </div>
       <div class="backgroud-color-wid77-height1px"></div>
       <div  class="font-size-12-weight-400">
        <p class="text-size-16-weight-700 color-lineheight" style="padding-top:32px;padding-bottom:8px;">Supprimer vos données et votre compte</p>
        <p style="padding-bottom:32px;">Supprimer votre compte et les données qui y sont associées</p>
        <button class="padding-left-right-24 padding-top-bottom-9 delete-account-button" [routerLink]="['/artisan/user',currentUser.id,'delete-account']">Supprimer le compte</button>
   </div>
        </div>
        
    </div>
    <!-- </form> -->

    <app-artisan-modal-change-password></app-artisan-modal-change-password>