import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgSharedModule } from 'src/app/all/ngshared.module';

// import Chart from 'chart.js'
import { Chart, registerables } from 'chart.js';
import { DpgfPostesService, ErrorService } from '../../../_services/index';
import { DpgfModule } from '../../dpgf.module';
import { DpgfResultTabsNavbarComponent } from '../dpgf-result-tabs-navbar/dpgf-result-tabs-navbar.component';
import { environment } from 'src/environments/environment';

@Component({
  standalone:true,
  selector: 'app-dpgf-result-comparison',
  imports:[
    DpgfModule,
    DpgfResultTabsNavbarComponent,
    NgSharedModule
  ],
  templateUrl: './dpgf-result-comparison.html',
  styleUrls: ['./dpgf-result-comparison.scss']
})
export class DpgfResultComparisonComponent implements OnInit {
  public chart: any;
  public loadingPage: boolean = true;
  public dpgfId:any = '';
  public chartData:any =  [];
  public chartOptions:any =   {
    indexAxis: 'y',
    scales: {
        xAxes: [{
            ticks: {
                beginAtZero: true,
                autoSkip: false
            },
            scaleLabel: {
                  display: true,
                  labelString: 'Prix HT en €'
              }
        }],
        yAxes : [{
            ticks: {
                autoSkip: false
            },
            categoryPercentage: 0.5,
            barPercentage: 0.9
        }]
    }
  }
  public artisansColorsMap: any = {};
  public colors = ['#2196f3','#e91e63','#9c27b0','#4caf50','#673ab7','#ff9800',
    '#cfdfff','#d0f0fd','#c2f5e9','#d1f7c4','#fee2d5','#ffeab6','#ffdce5',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A'];

  public subscriptions:  Array<Subscription> = [];

  public dpgf: any[any];
  public pricingList: any = [];

  constructor(
    private route: ActivatedRoute,
    public dpgfPostesService: DpgfPostesService,
    private ErrorService: ErrorService
  ) { }

  ngOnInit() {
    Chart.register(...registerables);
    if(!environment.onPremise)window.analytics.track('consult_graph',{properties:{acValue: 'consult_graph'}});
    this.getData();
  }

  setArtisansColors() {
    let colorIndex: number = 0;
    this.pricingList.forEach((p, i) => {
      if(!this.artisansColorsMap[p.artisanName])
        this.artisansColorsMap[p.artisanName] = this.colors[colorIndex++];
    });
  }

  public drawChart(){
    let dpgfData = this.dpgf.data;
    let labels = dpgfData.map(val => (val.title.substring(0,40) + ((val.title.length>40)?"...":"")))
    let dataSets = []
    dataSets.push({
      label: "Estimatif",
      backgroundColor: this.colors[0],
      borderColor: this.colors[0],
      data: dpgfData.map(val =>  Math.round(((val.totalArchi  || 0) ) * 100) / 100)
    });
    this.pricingList.forEach((artisan,i)=>{
      let facticeArtisanName = artisan?.ceProjectInvite?.facticeArtisanInfo?.name || artisan.artisanName;

      dataSets.push({
        // label: artisan?.artisan?.name || artisan.artisanName,
        label: artisan?.ceProjectInvite?.isFactice ? facticeArtisanName : artisan?.artisan?.name,
        backgroundColor: this.colors[i+1],
        borderColor: this.colors[i + 1],
        data: artisan.data.map(val =>  Math.round((val.total) * 100) / 100)
      })
    })
    // this.chartData = {
    //   labels: labels,
    //   datasets: dataSets
    // }
    this.loadingPage = false;
    this.setChart(labels, dataSets);
  }
  public getData(){
    this.route.parent.params.forEach((params: Params) => {
      if (params['dpgfId']) {
        this.dpgfId = params['dpgfId'];
        this.subscriptions.push(
          this.dpgfPostesService.dpgfPricingListInfo(this.dpgfId, 'graphique')
          .subscribe(
            data => {
              this.setCeProjectId(data['dpgf']['ceProject']);
              /// set dpgf
              this.dpgf = data.dpgf;
              /// set pricing List
              data.pricingList.forEach((artisan)=>{
                artisan.total = artisan.data.reduce((accumulator, currentArtisan) => accumulator + currentArtisan.total,0)
              })
              // this.pricingList = data.pricingList.filter(el=> el.status == "finished" || el.status == "re-submited").slice();
              this.pricingList = data.pricingList;

              this.setArtisansColors();
              this.drawChart();
            },
            error => {
              console.log(error);
              this.ErrorService.manageError(error,'/architect/edit/project/' + this.dpgfId);
            }
          )
        );
      }
    });
  }
  private setCeProjectId(projectId){
    this.dpgfPostesService.selectedProjectId.next(projectId);
  }
  public setChart(labels=[], dataSets=[]): void{
    const ctx = document.getElementById('myChart') as HTMLCanvasElement;
    const myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: dataSets
        },
        options: this.chartOptions
        // {
          // maintainAspectRatio: false,
          // indexAxis: 'y',
          // scales: {
          //   y: {
          //       beginAtZero: true,
          //   }
          // }
        // }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }


}
