<div class="container-fluid">
  <div class="col-md-5 full-height colored-side">
    <div>
      <img class="aglo-logo" src="/assets/rob/aglo_logo_round_blanc.png" alt="">
      <h2 class="slogan">
          L’assistant intelligent <br>
          pour votre consultation d’entreprise
      </h2>
    </div>
    <div class="connect">
      <a [routerLink]="['/login']">Déjà un compte sur Aglo ?</a>
    </div>
  </div>
  <div class="col-md-7 full-height">
    <div class="form">
      <h1 class="title">Votre entreprise</h1>
      <app-artisan-singup-agency-info [(agency)]="artisan"></app-artisan-singup-agency-info>
      <div class="buttons-cont clearfix">
        <button class="back-btn" [disabled]="loading" (click)="redirectToHome();">
          <i class="fa fa-close"></i> Revenir
        </button>
        <button [disabled]="loading" (click)="save();" class="finish-btn">
          Terminer mon inscription
        </button>
      </div>
    </div>
  </div>
</div>
