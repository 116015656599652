import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';

import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  standalone: true,
    
  selector: 'app-tree-list',
  imports: [
    NgSharedModule,
    TreeListComponent
  ],
  templateUrl: './tree-list.component.html',
  styleUrls: ['./tree-list.component.scss']
})
export class TreeListComponent implements OnInit {
  @Input() treeData: [];
  @Input() isRoot;
  @Input() numPostsCategory={};
  @Output() getCategoryEvent = new EventEmitter<any>();

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.numPostsCategory) this.cd.detectChanges();
  }
  goToCategory(idCategory){
    this.getCategoryEvent.emit(idCategory)
  }
}
