import { Component, OnInit, Input,Output, EventEmitter} from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { DpgfPostesService } from '../../../_services/index';
import { ErrorService } from '../../../_services/index';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  standalone:true,
  selector: 'app-dpgf-list',
  imports:[DpgfModule,NgSharedModule],
  templateUrl: './dpgf-list.component.html',
  styleUrls: ['./dpgf-list.component.scss']
})
export class DpgfListComponent implements OnInit {
  @Output() duplicate = new EventEmitter<boolean>();
  private subscriptions: Array<Subscription> = [];
  public loading = true;
  public archiId:string = "";
  public dpgfs = [];

  constructor(private DpgfPostesService: DpgfPostesService,
              private ErrorService: ErrorService) { }

  ngOnInit() {
    this.listAllDpgf()
  }
  public listAllDpgf(){
    let currentUser = JSON.parse(localStorage.getItem("currentUser"))
    this.archiId = (currentUser.archiId||"")

    this.subscriptions.push(
      this.DpgfPostesService.listDpgf(this.archiId, null, 'status')
          .subscribe(
              data => {
                console.log(data)
                this.dpgfs = data.data;
                this.loading = false;
              },
              error => {
                  console.log("error",error)
                  this.ErrorService.manageError(error,"");
              })
    )
  }
  public chooseDpgf(id){
    this.duplicate.emit(id);
  }
  public ngOnDestroy(): void {
      this.subscriptions.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
      });
  }

}
