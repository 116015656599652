<!-- App Header -->

<div class="all">
  <app-header type="full"></app-header>
  <app-artisan-cgu-modal></app-artisan-cgu-modal>

  <div class="row" style="background-color:rgba(255, 255, 255, 1);height:140px;">
      <!-- <div class="col-xs-12">
        <h1>
          Projets en cours
          <button class="btn-primary-border" data-toggle="modal" data-target="#update-profile-modal">
            Je ne trouve pas mon projet
          </button>
        </h1>
      </div> -->
      <div class="flex space-around wid100 font-size-16 padding-top-15 padding-bottom-23" >
        <div style="padding-left:72px">
          <p>Rechercher un projet</p>
          <input placeholder="Saisissez un nom, un architecte, une adresse" style="width:314px;border-radius: 5px;padding-left:6px;height:36px;border:1px solid rgba(0, 0, 0, 0.2);" [(ngModel)]="projectsFound"
          (keyup)="search($event,searchProject,invites)">
        </div>
        <div >
          <p>Etape du projet</p>
          <p-dropdown [style]="{'border': '1px solid rgba(0, 0, 0, 0.2)','line-height':'0.9','height':'36px', 'width':'198px'}" [options]="statusProjects" [(ngModel)]="seletedStatus" optionLabel="name" (onChange)="viewStateProject(seletedStatus)"></p-dropdown>

        </div>
        <div style="padding-top:17px;">
          <button class="btn-project-not-found" data-toggle="modal" data-target="#update-profile-modal">
            Je ne trouve pas mon projet
          </button>
        </div>
      </div>
      <div class="flex  cursor font-size-14" style="color:rgba(0, 0, 0, 0.5);">
        <div style="width: 50%;padding-bottom:9px;background-color: white;" class="center" (click)="toogleProject('encours')"  [ngClass]="{'active':showProject == 'encours'}">
        <span  >Projets en cours</span>
        </div>
        <div style="width: 50%;padding-bottom:9px;background-color: white;" class="center" [ngClass]="{'active':showProject == 'archive'}" (click)="toogleProject('archive')">
          <span>Projets archivés</span>
        </div>
      </div>
  </div>

  <app-spinner *ngIf="loading"></app-spinner>
  <div *ngIf="!loading">
    <div class="container-box" *ngIf="showProject=='encours'">
      <app-artisan-project-list [invites]="{state:'en cours',projects:searchProject||invites}"></app-artisan-project-list>
    </div>
    <div class="container-box" *ngIf="showProject=='archive'">
      <app-artisan-project-list [invites]="{state:'archive',projects:projectArchive}"></app-artisan-project-list>
    </div>
  </div>

  <!-- Update profile popup -->
  <div class="modal fade" id="update-profile-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="myModalLabel">Récupérer un projet</h4>
        </div>
        <div class="modal-body adopt-modal">
          <div>
            <div>
              Si un projet n'apparait pas sur votre compte, il a peut être été transmis sur une autre adresse email que celle associée à ce compte. <br>
              Renseignez le code indiqué dans l'email pour récupérer le projet. <br>
            </div>
            <br>
            <label for="coderecuperation">Code de récupération : </label> <br>
            <input id="coderecuperation" [(ngModel)]="codeRecuperation"/>
            <div class="row">
              <button *ngIf="!loadingAdpot" type="button" class="btn btn-primary adopt-btn" (click)="adoptCeInvite()">
                Récupérer mon projet
              </button>
              <app-spinner *ngIf="loadingAdpot"></app-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Update profile popup -->
  <div class="modal fade" id="addProjectPopup" tabindex="-1" role="dialog" aria-labelledby="addProjectPopup">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="myModalLabel">Complétez votre fiche entreprise pour maximiser vos chances de convaincre !</h4>
        </div>
        <div class="modal-body">
          <p>Votre fiche entreprise est accessible pour les architectes et maîtres d'ouvrage qui vous consultent.</p>
          <p>En las complétant vous mettez toutes les chances de votre côté pour remporter vos appels d'offre.</p>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-default" data-dismiss="modal">Pas maintenant</button> -->
          <button type="button" class="btn btn-primary finish-btn">
            <a class="finish-link-href" [routerLink]="['/artisan/edit/profil']" [queryParams]="{ tab: 'genral'}">Finaliser ma fiche entreprise</a>
          </button>
          <p class="est-time-text">Temps estimé: 5min</p>
        </div>
      </div>
    </div>
  </div>

</div>
