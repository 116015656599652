import { Component, OnInit, Input, NO_ERRORS_SCHEMA } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ChantierService, ErrorService } from '../../../app/_services';
import { MessageService } from 'primeng/api';
import { ChantierModule } from '../chantier.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-facture-list',
    imports:[
      ChantierModule,
      NgSharedModule
    ],
    templateUrl: './facture-list.component.html',
    styleUrls: ['./facture-list.component.scss'],
    standalone: true,

})
export class FactureListComponent implements OnInit {

  @Input() factures;
  @Input() ceProjectInviteId;
  @Input() owner;

  private subscriptions: Array<Subscription> = [];

  public isDisabled = true;
  public matching:any = {
    "created" :"Créée",
    "submited":"Envoyée",
    "accepted" : "Acceptée",
    "rejected": "Refusée"
  }
  
  constructor(
    private chantierService: ChantierService,
    private errorService: ErrorService,
    private router: Router,
    public messageService: MessageService
    ) { }

  ngOnInit() {
  }

  public onCreateFacture() {
    if(this.factures.length == 0) {
      this.createFacture();
    }
    else {
      if(this.factures.length == 0 || this.factures[this.factures.length-1].architectResponse != 'accepted')

        this.messageService.add({ key: 'toast', severity: 'error', summary: "Création échouée", detail: "Votre denière situation n\'a pas encore été acceptée.", life: 5000 });
      else this.createFacture();
    }   
  }

  public createFacture() {
    this.subscriptions.push(
      this.chantierService.postFacture(this.ceProjectInviteId)
        .subscribe(data => {
          this.router.navigate(['/artisan/dpgf-chantier/'+data._id]);
          this.messageService.add({ key: 'toast', severity: 'success', summary: "Situation", detail: "Votre situation a été créée.", life: 5000 });
        },
        error => {
          console.log("error",error)
          this.errorService.manageError(error,'/artisan/dpgf-chantier/'+this.ceProjectInviteId);
        }
        )
    )
  }

  public openSingleChantierPage(factureId){
    let routePath = [];
    if(!environment.onPremise)window.analytics.track('check_situation_chantier',{properties:{acValue: 'check_situation_chantier'}});

    if(this.owner == 'artisan'){
      routePath = ['/artisan','dpgf-chantier', factureId];
    }else{
      routePath = ['/architect', 'chantier-facture', factureId];
    }

    this.router.navigate(routePath);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }
}
