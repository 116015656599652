<!-- App Header -->
<app-header></app-header>

<div class="hero">

  <h1>
    Bienvenue sur BAM
  </h1>
</div>
<div class="container">
  <div >
    <div class="row sec">
      <div class="col-md-4">
        <img class="section-img float-img" src="/assets/archi/profil-capture.png" alt="">
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-7">
        <div class="sub-title">Accédez à des commandes de qualité.</div>
        <div class="title">PROSPECTION CLIENTS</div>
        <div class="all-el">
          <div class="el">
            <img src="/assets/shap-circle.svg" alt="">
            <p>
              Créez votre portfolio et mettez en avant l'identité de votre agence à travers vos réalisations
            </p>
          </div>
          <div class="el">
            <img src="/assets/shap-circle.svg" alt="">
            <p>
              Indiquez les projets pour lesquels vous souhaitez être contactés par une clientèle qualifiée
            </p>
          </div>
          <div class="el">
            <img src="/assets/shap-circle.svg" alt="">
            <p>
              Profitez de l'accompagnement BAM pour valoriser votre agence auprés des clients
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row sec">
      <div class="col-md-7">
        <div class="sub-title">Le réseau d'entreprises collaboratif, la révolution BAM !</div>
        <div class="title">CONSULTATION D'ENTREPRISES</div>
        <div class="all-el">
          <div class="el">
            <img src="/assets/shap-circle.svg" alt="">
            <p>
              Accédez à un réseau d’artisans triés sur le volet, recommandés et notés par vos confrères architectes.
            </p>
          </div>
          <div class="el">
            <img src="/assets/shap-circle.svg" alt="">
            <p>
              Uploadez ou réalisez votre DCE en ligne grâce à notre outil innovant de création de DPGF/CCTP.
            </p>
          </div>
          <div class="el">
            <img src="/assets/shap-circle.svg" alt="">
            <p>
              Gérez vos échanges avec les artisans via l’interface de gestion des chiffrages puis
              Recevez des devis normés et comparez les facilement via notre outil d’analyse.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-4 relative">
        <img class="superposed-img1 float-img" src="/assets/archi/rob-capture3.png" alt="">
        <img class="superposed-img2 float-img" src="/assets/archi/rob-capture.png" alt="">
      </div>
    </div>
    <div class="row sec">
      <div class="col-md-4">
        <img class="section-img" src="/assets/concours.png" alt="">
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-7">
        <div class="sub-title">Toujours plus</div>
        <div class="title">CONCOURS D'ARCHITECTURE</div>
        <div class="all-el">
          <div class="el">
            <img src="/assets/shap-circle.svg" alt="">
            <p>
              Postulez à des concours d'architecture hors du commun en quelques minutes, grâce à votre portfolio BAM.
            </p>
          </div>
          <div class="el">
            <img src="/assets/shap-circle.svg" alt="">
            <p>
              Participez à la promotion d’une architecture de qualité, éthique et durable !
            </p>
          </div>
          <div class="el">
            <img src="/assets/shap-circle.svg" alt="">
            <p>
              Réfléchissez aux grands défis de demain que l’architecture devra relever !
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="center">
      <button [routerLink]="['/architect','my-consultations']" class="btn-primary-color">
        <div>C'est partie <i class="fa fa-arrow-right" aria-hidden="true"></i></div>
      </button>
    </div>
  </div>
</div>


<!-- App Footer  -->
