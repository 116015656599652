import { Component, OnInit, Input, SimpleChanges, NgZone, ElementRef, ViewChild, NO_ERRORS_SCHEMA} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { ArtisanService } from '../../_services'
import { MessageService } from 'primeng/api';
import { formateImage } from "../../_helpers/formate-image";
import { trigger,style,animate,transition,keyframes } from '@angular/animations';
//Maps
import { GoogleAutocompleteComponent } from 'src/app/all/google-autocomplete/google-autocomplete.component';
import { NgSharedModule } from "src/app/all/ngshared.module";

declare var jsonConcat: any;
declare var only: any;
declare var $: any;

@Component({
  standalone:true,
  selector: "app-artisan-signup-general",
  imports:[
    GoogleAutocompleteComponent,
    NgSharedModule
  ],
  providers: [],
  templateUrl: "./artisan-signup-general.component.html",
  styleUrls: ["./artisan-signup-general.component.scss"],
  animations: [
    trigger('error', [
      transition('* => true', [
        animate('2s', keyframes([
          style({ backgroundColor: 'transparent'}),
          style({ backgroundColor: 'rgba(255, 0, 0, 0.15)'}),
          style({ backgroundColor: 'transparent'})
        ]))
      ]),
    ])
  ],
  schemas:[NO_ERRORS_SCHEMA]
})
export class ArtisanSignupGeneralComponent implements OnInit {
  @Input() agency: any;
  @ViewChild("adresse")
  public searchElementRef: ElementRef;
  public fieldname: string = "text";
  public languages: {}[];
  public error: string='';
  public options: {};
  public place: {};
  public filesLoading: number[] = [];
  public subscriptionForm: UntypedFormGroup;
  public logoValide: Boolean = false;
  public isCreatingAccount: Boolean = false;
  public siretNum: any;
  public formErrors = {
    name: "",
    address: "",
    telAgency: "",
    emailAgency: "",
    siret: "",
    creationYearAgency: "",
    tvaNumber: "",
    assuranceNumber: "",
    //nCollaborateur: "",
    ca: "",
    assurances:"",
    logoAgency: ""
  };
  private validationMessages = {};
  //Assurances
  public searchAssurances: {}[];
  public assurances: {}[] = [
    { id: "garantieQualibat", text: "Certification Qualibat",textEn: "Certification Qualibat"},
    { id: "garantieQualibatRGE", text: "Certification Qualibat RGE",textEn: "Certification Qualibat RGE"}
  ];

  constructor(private fb: UntypedFormBuilder,
              // private mapsAPILoader: MapsAPILoader,
              private router: Router,
              private artisanService: ArtisanService,
              private ngZone: NgZone,
              private messageService: MessageService,) {}

  ngOnInit() {
    this.createForm();
    this.subscriptionForm.patchValue(this.agency || {});
  }
  getGoogleMapValueChange(event){
    this.patchAdress(event["description"]);
  }
  public setEmail(mail){
    this.subscriptionForm.patchValue({emailAgency : mail})
  }
  //On change
  ngOnChanges(changes: SimpleChanges) {
    if (changes["agency"] && this.subscriptionForm) {
      this.subscriptionForm.patchValue(this.agency);
    }
  }
  //Form
  createForm() {
    this.subscriptionForm = this.fb.group({
      name: ["", Validators.required],
      telAgency: ["", Validators.required],
      emailAgency: ["", [Validators.required,Validators.email]],
      tvaNumber: "",
      assuranceNumber: "",
      address: ["", Validators.required],
      website: "",
      siret: ["", Validators.required],
      creationYearAgency: "",
      //nCollaborateur: "",
      ca: "",
      assurances: [[]],
      logoAgency: [[]],
    });
    this.subscriptionForm.valueChanges.subscribe(data =>
      this.onValueChanged(data)
    );
    this.onValueChanged();
  }
  //patch Adresse
  public patchAdress(val){
      let data = this.subscriptionForm.value;
      data.address = val;
      this.subscriptionForm.patchValue(data)
  }
  //Generate Error
  onValueChanged(data?: any) {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = "";
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = "Ce champ est obligatoire";
        }
      }
    }
  }

  //Mark as touched
  public markFormAsTouched(): void {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = "";
      const control = form.get(field);
      control.markAsDirty();
      const messages = this.validationMessages[field];
      for (const key in control.errors) {
        this.formErrors[field] = "Ce champ est obligatoire";
      }
    }
  }
  //Search Function
  public search(e, dropdown, allDropdown, varName) {
    this[dropdown] = [];
    let selectedData = (this.subscriptionForm.value[varName] || []).map(
      e => e[this.fieldname]
    );
    for (let i = 0; i < this[allDropdown].length; i++) {
      let selectable = this[allDropdown][i];
      if (
        selectable[this.fieldname].toLowerCase().indexOf(e.query.toLowerCase()) != -1 &&
        selectedData.indexOf(selectable[this.fieldname]) == -1
      ) {
        this[dropdown].push(selectable);
      }
    }
  }
  public scrollTo(element, offset=0) {
    $("html, body").animate(
      {
        scrollTop: $(element).offset().top - offset
      }
    );
  }
  public scrollToError(){
    let loop = 0;
    let keys = Object.keys(this.formErrors);
    let errorKey = ""
    while(loop < keys.length && this.formErrors[keys[loop]] =="" ){
      loop ++;
    }
    if (keys[loop]) {
      this.error = keys[loop]
      this.scrollTo(`#${keys[loop]}`, 40);
      setTimeout(()=>{this.error = ''},2000)
    }
  }
  //Validate Form
  isValid(scroll = true) {
    this.markFormAsTouched();
    if (scroll) {
      this.scrollToError()
    }
    // return this.subscriptionForm.valid && this.filesLoading.length == 0 && (this.agency.place||{}).geometry;
    return this.subscriptionForm.valid && this.filesLoading.length == 0;
  }
  getFormData() {
    return jsonConcat(this.subscriptionForm.value, only(this.agency, "logo artisanImage place"));
  }
  toggleCreatingAccount(){
    this.isCreatingAccount = !this.isCreatingAccount;
  }


}
