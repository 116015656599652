<div class="outer-wrapper" *ngIf="!loading">

  <!-- Single Activity Item -->
  <div class="single-activity-item horizontal-items" *ngFor="let agencyActivity of agencyActivitiesList">
    <!-- Icon Side -->
    <div class="icon-side text-center width-15">
      <img [src]="'/assets/corps-etats/'+agencyActivity.icon">
      <h5 class="ag-heading-h5 ag-weight-600 margin-vertical-8" [innerHtml]="agencyActivity.title"></h5>
      <p class="ag-para-1">
        <input type="checkbox" 
          (change)="checkAllActivitySection($event.target.checked, agencyActivity)"
          [checked]="checkedActivities[agencyActivity.id]"
          > <span class="margin-left-12">Tout séléctionner</span>
      </p>
    </div>
    <!-- END Icon Side -->
    
    <!-- Checkboxes Side -->
    <div class="inputs-side padding-left-50 width-85">
      
      <!-- single check boxes section -->
      <div class="single-checkboxs-section" *ngFor="let singleCheckboxSection of agencyActivity.sectionsLists; let i = index;">
        <h6 class="ag-heading-h6 ag-heading-h6 margin-bottom-7">{{ singleCheckboxSection.title }}</h6>
        <div class="check-boxes-list horizontal-items">

          <!-- [ngClass]="{'choosed': selectedItems.includes(agencyActivity.id+'_'+singleCheckboxSection.id+'_'+checkboxItem.value)}" -->
          
          <span class="checkbox-item" 
            *ngFor="let checkboxItem of singleCheckboxSection.list; let k = index;"
            [ngClass]="{'choosed': selectedItems[agencyActivity.id+'_'+singleCheckboxSection.id+'_'+checkboxItem.value]}"
            (click)="chooseItem(agencyActivity.id, singleCheckboxSection.id, checkboxItem.value)"
            >{{ checkboxItem.label }}</span>

        </div>
      </div>
      <!-- END single check boxes section -->

    </div>
    <!-- END Checkboxes Side -->
  </div>
  <!-- END Single Activity Item -->

  <!-- Add new corps d’état Button -->
  <div class="add-new-corps-etat-wrapper horizontal-items margin-top-53">

    <div class="pointer width-15 flex-middle">
      <p class="ag-para-4" (click)="openAddNewCorpsEtatModal()">
        <img class="margin-right-10" src="/assets/add-icon.svg">
        Ajouter un corps d’état
      </p>
    </div>

    <div class="custom-coprs-list padding-left-50 horizontal-items width-75">
      <span class="checkbox-item" 
        *ngFor="let customCheckboxItem of customAgencyActivitiesList; let i = index;">
        {{ customCheckboxItem.label }}
        <i class="fa fa-times remove-icon" 
        (click)="removeCustomItem(i)"></i>
      </span>
    </div>

  </div>
  <!-- END Add new corps d’état Button -->
  
  <!-- Add new corps d’état Modal -->
  <app-booster-profile-add-corps-etat-modal 
    (createCustomCorpsEtats)="createCustomCorpsEtats($event)"
  ></app-booster-profile-add-corps-etat-modal>
  <!-- END Add new corps d’état Modal -->

</div>