import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
//import { ArchitectModule } from '../architect.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
​
@Component({
    selector: 'app-styles-page',
    imports:[
      
      NgSharedModule
    ],
    templateUrl: './styles-page.component.html',
    styleUrls: ['./styles-page.component.scss'],
    standalone: true,

})
export class StylesPageComponent implements OnInit {
​
  constructor() { }
​
  ngOnInit(): void {
    this.listenCollapse()
  }
  public listenCollapse=()=>{
    var coll = document.getElementsByClassName("collapsible");
    var i;
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.display === "block") {
          content.style.display = "none";
        } else {
          content.style.display = "block";
        }
      });
    }
  }
}
