<div class="page-wrapper main-page-white-lilac">

  <app-header type="full" *ngIf="!loading"></app-header>

  <app-spinner *ngIf="loading"></app-spinner>

  <div class="container margin-top-30" *ngIf="!loading">
    <app-booster-profile-forms-progress [selectedStep]="currentForm"></app-booster-profile-forms-progress>

    <h4 class="ag-heading-h4 ag-weight-600 text-center form-title">{{ formsHeaderTitles[currentForm] }}</h4>

    <div class="horizontal-items margin-top-10 flex-center" *ngIf="currentForm == 'precision'">
      <img src="/assets/artisan-booster/exclamation-light-blue.svg"/>
      <p class="ag-para-1 ag-weight-500 padding-left-8">
        Votre spécialisation et vos départements d’exercices n’apparaissent pas sur votre profil.  Ça reste entre vous et nous.
      </p>
    </div>

    <!-- Forms Container  -->
    <div class="forms-container margin-top-40">
      <!--  -->
      <div class="forms-body" [ngClass]="{'form-card': showMainCard[currentForm], 'scroller-1-y': showMainCard[currentForm] }">
        
        <!-- Forms Goes Here -->
        <container-element [ngSwitch]="currentForm">

          <app-booster-profile-information-form 
            [informationData]="informationData"
            (saveInformation)="saveInformation($event)"
            (formIsValid)="selectedFormIsValid('information', $event)"
            *ngSwitchCase="'information'">
          </app-booster-profile-information-form>
          
          <app-booster-profile-corps-etat-form
            [corpsDetatData]="corpsDetatData" 
            (saveData)="saveInformation($event)"
            (formIsValid)="selectedFormIsValid('corpsEtat', $event)"
            *ngSwitchCase="'corpsEtat'">
          </app-booster-profile-corps-etat-form>
          
          <app-booster-profile-documents-form
            [docsData]="documentsData" 
            (updateDocument)="saveInformation($event)" 
            (formIsValid)="selectedFormIsValid('documents', true)"
            *ngSwitchCase="'documents'">
          </app-booster-profile-documents-form>

          <app-booster-profile-precision-form 
            [artisanData]="pecisionsData"
            (saveData)="saveInformation($event)"
            (formIsValid)="selectedFormIsValid('precision', $event)"
            *ngSwitchCase="'precision'">
          </app-booster-profile-precision-form>

          <app-booster-profile-recommandations-form
            [recommandationList]="artisanRecommandationsList"
            (createNewContact)="createNewContact($event)"
            (deleteContact)="removeContact($event)"
            (formIsValid)="selectedFormIsValid('recommandations', true)"
            *ngSwitchCase="'recommandations'">
          </app-booster-profile-recommandations-form>
          
        </container-element>    
        <!-- END Forms Goes Here -->

      </div>

      <div class="footer-buttons flex space-between">
        <div class="flex-middle">
          <button class="ag-btn-tertiary-sm" *ngIf="currentForm == 'information'" (click)="openConfirmExistFormModal()">Annuler</button>
          <button class="ag-btn-tertiary-sm" *ngIf="currentForm != 'information'" (click)="prevForm()">Retour</button>
        </div>
        
        <div class="flex-middle">
          <button class="ag-btn-primary-sm" (click)="nextForm()" [disabled]="!currentFormIsValid" *ngIf="currentForm != 'recommandations'">Suivant</button>
          <button id="see-booster-profile-btn" (click)="goToBoosterProfilePage()" 
            *ngIf="currentForm == 'recommandations'">Voir mon Profil AGLO BOOSTER© </button>
        </div>
      </div>

    </div>
    <!-- END Forms Container  -->

  </div>
</div>

<!-- Modals -->
<app-confirm-exist-booster-form-modal
  (confirmExist)="backToprojectsPage($event)"
></app-confirm-exist-booster-form-modal>
<!-- END Modals -->
