
<div class="all">
  <app-header cssClass="fixed-bar"></app-header>
  <app-ce-project-nav           [disable]="loadingData"
                                [projectId]="projectId"
                                selected="dpgf"></app-ce-project-nav>
  <div class="container" >
    <div *ngIf="dpgfType == 'none'"  class="panel pricing-table global-container">

      <div class="pricing-plan">
        <h2 class="pricing-header">Dupliquer un ancien DCE</h2>
        <img src="/assets/rob/empty_cart.svg" class="image-icon" alt="">
        <a (click)="dpgfType = 'template'" *ngIf="hasLicence" class="pricing-button">Choisir</a>
      </div>

      <div class="pricing-plan">
        <h2 class="pricing-header">A partir de zéro</h2>
        <img src="/assets/rob/blank_canvas.svg" class="image-icon" alt="">
        <a (click)="dpgfType = 'scratch'" class="pricing-button">Commencer</a>
      </div>


    </div>
    <div [class.hide]="dpgfType != 'scratch'" class="global-container">
      <a (click)="dpgfType = 'none'">
        <p class="chevron-return"><</p>
      </a>
      <app-form-new-dpgf></app-form-new-dpgf>
      <div class="center">
        <button [disabled]="loading" (click)="create('online');" href="#" class="btn-primary-color">
          <div *ngIf="!loading">Créer</div>
          <app-spinner *ngIf="loading"></app-spinner>
        </button>
      </div>
    </div>
    <div [class.hide]="dpgfType != 'template'" class="global-container">
      <a (click)="dpgfType = 'none'">
        <p class="chevron-return"><</p>
      </a>
      <app-dpgf-list (duplicate)="duplicate($event)"></app-dpgf-list>
    </div>


  </div>


</div>
