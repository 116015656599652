<div class="modal fade" tabindex="-1" role="dialog" id="send-mutiple-mail-to-atisans-popup">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title ag-heading-h3 ag-text-down-river">Envoyer un email aux entreprises</h4>
      </div>

      <div class="modal-body">
        <!-- Artisans List Wrapper -->
        <div class="artisans-list-wrapper" *ngIf="selectedView == 'artisans-list'">
          
          <div class="filter-input-container">
            <input type="text" placeholder="Rechercher une entreprise par nom" (keyup)="filterArtisansByName($event.target.value)">
            <p class="progress-value selected-lots edit-tags-input borderd-element p-fluid">
              <p-multiSelect
                class="form-add"
                id="carnet"
                [placeholder]="lotsFilterLabel"
                [selectedItemsLabel]="'Plusieurs éléments sélectionnés'"
                [panelStyle]="{minWidth:'100%'}"
                [options]="dpgfLots"
                optionLabel="name"
                (onChange)="filterArtisans($event.value)"
               ></p-multiSelect>
            </p>

          </div>

          <!-- Select Artisans By Status -->
          <div class="select-artisans-status-wrapper">
            <p class="title no-margin-bottom">Statut</p>
            <div class="status-checkboxes flex">
              
              <div class="single-checkbox">
                <input type="checkbox" checked (change)="statusCheckBoxChanged('offre_remise')">
                <span>Offre remise</span>
              </div>
              <div class="single-checkbox">
                <input type="checkbox" checked (change)="statusCheckBoxChanged('en_cours')">
                <span>En cours</span>
              </div>
              <div class="single-checkbox">
                <input type="checkbox" checked (change)="statusCheckBoxChanged('ne_chiffre_pas')">
                <span>Ne chiffre pas</span>
              </div>           
              <div class="single-checkbox">
                <input type="checkbox" checked (change)="statusCheckBoxChanged('en_attente')">
                <span>En attente</span>
              </div>
              
            </div>
          </div>
          <!-- END Select Artisans By Status -->

          <div class="artisans-list ag-card-1 scroller-1">
            
            <!-- Single Artisan item -->
            <div class="single-artisan-item horizontal-items center-items pointer" 
              [ngClass]="{'selected': this.selectedArtisans[artisan._id]}"
              [id]="'single-artisan-card-'+i"
              *ngFor="let artisan of clonedArtisansList; let i = index"
              (click)="artisanSelected(artisan._id)"
              >
              <div class="left-side width-20 flex-middle">
                <div class="horizontal-items">
                  <input type="checkbox" [checked]="this.selectedArtisans[artisan._id]">
                  <img [src]="artisan.artisan?.logo?.cdnUrl || '/assets/dpgf/dce-artisan-avatar.svg'">
                </div>
              </div>
              <div class="right-side width-80">
                <p class="artisan-name-info no-margin"  *ngIf="artisan.artisanName == ''">
                  {{ artisan.artisan.name }} ( {{ artisan.artisan.emailAgency }} )
                </p>
                <p  *ngIf="artisan.artisanName != ''">
                  {{ artisan.artisanName }}
                </p>
                <div class="artisan-lots-container scroller-1">
                  <span class="pill-nav margin-right-6 margin-bottom-3" *ngFor="let lot of artisan.lots">{{ lot.name }}</span>
                </div>
              </div>
            </div>
            <!-- END Single Artisan item -->

          </div>

          <div class="artisans-count-container margin-top-10 flex space-between">
            <p class="ag-para-1">Entreprises sélectionnées : {{ numberOfSelectedArtisans }}</p>
            
            <div *ngIf="selectedView == 'artisans-list'">
              <button type="button" class="ag-text-blue next-btn transparent" 
                [disabled]="!numberOfSelectedArtisans" (click)="changeView('mail-form')">
                Suivant
                <img src="/assets/dpgf/right-blue-arrow.svg" class="margin-left-12" *ngIf="numberOfSelectedArtisans">
                <img src="/assets/dpgf/right-grey-arrow.svg" class="margin-left-12" *ngIf="!numberOfSelectedArtisans">
              </button>
            </div>

          </div>
          
        </div>
        <!-- END Artisans List Wrapper -->

        <!-- Send DCE Form -->
        <div class="dce-form-wrapper" *ngIf="selectedView == 'mail-form'">

          <div class="">
            <app-architect-emails-templates-dropdown
              [emailsTemplates]="architectEmailTemplates"
              (emailTemplateSelected)="emailTemplateSelected($event)"
            ></app-architect-emails-templates-dropdown>
          </div>
          
          <div class="dce-form-input margin-bottom-12">
            <p>Objet du mail</p>
            <input type="text" 
              [placeholder]="mailSubjectPlaceholder" 
              [(ngModel)]="mailSubject">
          </div>

          <div class="dce-form-input">
            <p>Corps du mail</p>
            <app-editor 
              class="cctp-editor editor"
              placeholder="ex: Bonjour..."
              Description
              name="name"
              [theme]="'bubble'"
              [style]="{'height':'250px', 'background':'#FFFFFF'}"
              [(ngModel)]="mailMessage"
            ></app-editor>
          </div>

        </div>
        <!-- END Send DCE Form -->

      </div>

      <!-- Footer -->
      <div class="modal-footer">
        
        <app-spinner *ngIf="loading"></app-spinner>

        <div *ngIf="!loading">
          
          <div *ngIf="selectedView == 'mail-form'" class="horizontal-items space-between">
            <button type="button" class="ag-text-blue next-btn transparent" 
              [disabled]="!numberOfSelectedArtisans" (click)="changeView('artisans-list')">
              <img src="/assets/dpgf/left-blue-arrow.svg" class="margin-left-12">
              Précédent
            </button>
            <button class="ag-btn-primary-xs" [disabled]="isDemo" (click)="emitSendMailsToManyArtisans()">Envoyer l'email</button>
          </div>

        </div>

      </div><!-- /.modal-footer -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->