<div class="row container-fluid">
  <div class="col-md-3 sidebar-wrapper scroller-1">
    <p style="padding-left:12px;font-weight: 600;font-size: 16px;">Entité</p>
    <div class="options-dropdown">
      <ul *ngFor="let option of sideBarEntities; index as i">
        <li class="main-level ag-para-1 ag-bold flex space-between"
          [ngClass]="{'open': (option.value == selectedOption && (option.type=='batiment' || option.batiment==selectedBatiment || option.value=='parcelle'))}"
          (click)="openNestedList(option, 'nested-options-'+option.value+option.batiment)">
          <span>{{ option.label }}</span>
          <img src="/assets/acv-icons/nav-arrow-bottom.svg" *ngIf="option.value!='parcelle'">
        </li>
        <!-- Nest List -->
        <div class="nested-items-ul options-dropdown"
         [id]="removeWhiteSpaces('nested-options-'+option.value+option.batiment)">
          <p class="side-secondary-title one-lot one-option" (click)="setLotSelected(noLot)" [ngClass]="{'selected' : selectedLotPCE.name==noLot.name }">{{noLot.name}}</p>
          <!-- sidebar nav List -->
          <ul>
            <li class="ag-para-1 one-option" 
            *ngFor="let item of lotsPCE; index as i"
            (click)="setLotSelected(item)"
            [ngClass]="{'selected' : selectedLotPCE.name==item.name }">
              <p>
                {{ item.name }}
              </p>
            </li>
          </ul>
          <!-- END sidebar nav List -->
        </div>
        <!-- <ul class="nested-items-ul" [id]="removeWhiteSpaces('nested-options-'+option.value)">
          <li class="ag-para-1" [id]="'nested-li'+'-'+nestedItem.value"
            *ngFor="let nestedItem of option.zones; index as i"
            (click)="listItemSelected($event, nestedItem)"
            >
            {{ nestedItem.label }}
          </li>
        </ul> -->

      </ul>
    </div>



   
    <div class="incomplete-lot">
      <div class="one-lot flex next-container" (click)="changeEntity('Incomplete')" [ngClass]="{'selected' : selectedEntity=='Incomplete' }" style="display: flex;">
        <img src="/assets/archi/licence_warning.svg" alt="" class="warning-icon">
        <p style="font-weight: 700;margin-left: 6px;" >{{incompleteLot.name}}</p>
      </div>
    </div>
  </div>
  <div class="col-md-9 posts-container scroller-1" [ngClass]="{'prevent-event':isProjectDemo}" >
    <div *ngIf="!loadingPosts">
      <div *ngIf="showOperations()" class="icon-holder margin-right-24">
        <i class="fa fa-trash margin-right-12 pointer" pTooltip='Supprimer' (click)="deleteSelectedFiles()"></i>
        <i class="fa fa-copy pointer" pTooltip='Dupliquer' (click)='duplicateSelectedFiles()'></i>
      </div>
      <div *ngFor="let lot of iterateLots();let j=index" class="row table-container" >
        <div [ngStyle]="{'margin-top': (j==1) ? '0px' : '48px' ,'padding-bottom': showLotForfaitaire(lotId) ? '51px': '0'}">
          <div *ngIf="j==0">
            <p class="selected-entity">{{lot}}</p>
            <div *ngIf="lot.includes('10-')">
              <button class="add-lot-forfitaire-button button-style" [title]="disabledButtonAddLotForfaitaire(10)? tooltipAddLotForfaitaire : tooltipenableLotForfaitaire" [disabled]="isProjectDemo || !disabledButtonAddLotForfaitaire(10)" *ngIf="showButtonLotForfaitaire(10)" (click)="openModalLotForfitaire('create',10)" >Utiliser un lot forfaitaire </button>
              <!-- <button class="add-lot-forfitaire-button button-style" [title]="disabledButtonAddLotForfaitaire(10)? tooltipAddLotForfaitaire : tooltipenableLotForfaitaire" [disabled]="isProjectDemo || !disabledButtonAddLotForfaitaire(10)" *ngIf="showButtonLotForfaitaire(10)" (click)="openModalLotForfitaire('create',10)" >Utiliser un lot forfaitaire </button> -->
            </div>
            <div *ngIf="lot.includes('11-')">
              <!-- <button class="add-lot-forfitaire-button button-style" [title]="disabledButtonAddLotForfaitaire(11)? tooltipAddLotForfaitaire : tooltipenableLotForfaitaire" [disabled]="isProjectDemo || !disabledButtonAddLotForfaitaire(11)" *ngIf="showButtonLotForfaitaire(11)" (click)="openModalLotForfitaire('create',11)" >Utiliser un lot forfaitaire </button> -->
              <button class="add-lot-forfitaire-button button-style" [title]="disabledButtonAddLotForfaitaire(11)? tooltipAddLotForfaitaire : tooltipenableLotForfaitaire" [disabled]="isProjectDemo || !disabledButtonAddLotForfaitaire(11)" *ngIf="showButtonLotForfaitaire(11)" (click)="openModalLotForfitaire('create',11)" >Utiliser un lot forfaitaire </button>
            </div>
          </div>
          <div *ngIf="lot.includes('10-')|| lot.includes('11-')">
            <p *ngIf="showLotForfaitaire(lotId)" style="color:rgba(56, 5, 242, 1);font-size:14px;font-weight: 700;padding-left: 24px;padding-top: 28px;">Lot forfaitaire</p>
            <div class="margin-left-24" *ngIf="showLotForfaitaire(lotId)">
              <table class="table table-striped table-borderless2">
                <thead>
                  <tr>
                    <th style="padding-left:0;padding-top: 10px;">Désignation</th>
                    <!-- <th>Désignation du poste</th> -->
                    <th style="text-align: center;">Quantité</th>
                    <th style="text-align: center;">Unité</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let postFile of FilerLotForfaitaire(lot);let i=index;">
                    <tr style="position: relative;">
                      <td (click)="getPostOpenModal(postFile)" style="padding-left:0;"><div class="post-name no-margin pointer">{{postFile.nom}}</div></td>
                      <td style="text-align: center;"><input (change)='updatePost(postFile)' type="number" step="any" [(ngModel)]="postFile.quantity" class="quantity-input"></td>
                      <td style="text-align: center;">{{(postFile.uniteUF||'m3')}}</td>
                      <td>
                      <!-- <div>ModifSupprim</div>   -->
                      <div class="flex">
                      <div class="padding-right-10 cursor" *ngIf="lot.includes('10-')" (click)="openModalLotForfitaire('update',10)" ><img src="assets/modifier-icone.svg"></div>
                      <div class="padding-right-10 cursor" *ngIf="lot.includes('11-')" (click)="openModalLotForfitaire('update',11)" ><img src="assets/modifier-icone.svg"></div>
                      <div (click)="$event.stopPropagation();deleteOneFile(postFile)">
                        <!-- <i class="fa fa-trash click-delete-sommaire" aria-hidden="true"></i> -->
                        <img class="cursor" src="assets/delete-icone-acv.svg">
                      </div>
                      </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <div class="margin-left-24" *ngIf="j!=0"  [ngClass]="{'opacity01': showLotForfaitaire(lotId)}">
            <div *ngIf="selectedEntity!='Parcelle' && selectedLotPCE.name!=noLot.name && selectedLotPCE.name!=incompleteLot.name && selectedLotPCE.name!=lotsPCE[12].name && selectedLotPCE.name!=lotsPCE[11].name" class="flex">
              <input type="checkbox" (click)='checkAllFiles(lot)' [checked]='checkedLot(lot)'>
              <p class="selected-entity margin-left-24">{{lot}}</p>
            </div>
            <div *ngIf="selectedEntity=='Parcelle' || selectedLotPCE.name==noLot.name || selectedLotPCE.name==incompleteLot.name" class="flex">
              <p style="margin-left: -24px;">{{lot}}</p>
            </div>
            <table class="table table-striped table-borderless">
              <thead>
                <tr>
                  <th>
                    <div *ngIf="(!isLotStartsWithNumber(lot))  || this.selectedLotPCE.name==this.lotsPCE[12].name || this.selectedLotPCE.name==this.lotsPCE[11].name">
                      <input type="checkbox" (click)='checkAllFiles(lot)' [checked]='checkedAllFiles[lot]'>
                    </div>
                  </th>
                  <th>Désignation Fiche INIES</th>
                  <th>Désignation du poste</th>
                  <th>Entité</th>
                  <th style="text-align: center;">Quantité</th>
                  <th style="text-align: center;">Unité</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let postFile of filterPostByLot(lot);let i=index;">
                  <tr style="position: relative;">
                    <td style="height: 1px;">
                      <!-- style="height: 100%;align-items: center;padding-bottom: 5px;" -->
                      <div class="flex">
                        <input type="checkbox" (click)='checkOneFile(postFile._id,lot)' [checked]='postFile.checked'>
                      </div>
                    </td>
                    <td (click)="getPostOpenModal(postFile)"><p class="post-name no-margin pointer">{{postFile.nom}}</p></td>
                    <td style="position: relative;">
                      <!-- <select class="post-select" (change)='updatePost(postFile)' [(ngModel)]="postFile.contributor" aria-placeholder="Choisir le contributeur">
                        <option *ngFor="let contributor of contributors">{{contributor}}</option>
                      </select>
                      <img src="/assets/archi/select-arrow.svg" class="select-arrow" style="top: 20px;right: 20px;"> -->
                      <p class="post-name no-margin">{{postFile.postName}}</p>
                    </td>
                    <td style="position: relative;">
                      <select *ngIf="postFile.contributor!='Chantier'" class="post-select" (change)='changeEntitySelect($event,postFile._id)' aria-placeholder="Choisir l'entité">
                        <option [selected]="!postFile.entity" disabled hidden>Choisir l'entité</option>
                        <!-- <option [selected]="postFile.entity=='Parcelle'">Parcelle</option> -->
                        <ng-container *ngFor="let batiment of selectEntities">
                          <option [selected]="postFile.entity==batiment.label" [value]="batiment.label">{{ batiment.label }}</option>
                          <option *ngFor="let zone of batiment.zones" [selected]="postFile.entity==zone.label && batiment.label==postFile.batiment" [value]="zone.label+'^'+batiment.label">
                            {{ zone.label }}
                          </option>
                        </ng-container>
                      </select>
                      <select *ngIf="postFile.contributor=='Chantier'" class="post-select" (change)='changeEntitySelect($event,postFile._id)' aria-placeholder="Choisir l'entité">
                        <option [selected]="!postFile.entity" disabled hidden>Choisir l'entité</option>
                        <ng-container *ngFor="let batiment of selectEntities">
                          <option [selected]="postFile.entity==batiment.label" [value]="batiment.label">{{ batiment.label }}</option>
                          <option *ngFor="let zone of batiment.zones" [selected]="postFile.entity==zone.label" [value]="zone.label+'^'+batiment.label">
                            {{ zone.label }}
                          </option>
                        </ng-container>
                      </select>
                      <img src="/assets/archi/select-arrow.svg" class="select-arrow" style="top: 20px;right: 20px;">
                      <!-- <p class="post-select">{{postFile.entity}}</p> -->
                    </td>
                    <td style="text-align: center;"><input (change)='updatePost(postFile)' type="number" step="any" [(ngModel)]="postFile.quantity" class="quantity-input"></td>
                    <td style="text-align: center;">{{(postFile.uniteUF||'m3')}}</td>
                    <td [ngClass]="{'prevent-event':isProjectDemo}">
                      <!-- <div class="dropdown"> -->
                        <div class="pointer" aria-hidden="true" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fa fa-ellipsis-v no-open" ></i>
                        </div>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <li (click)="$event.stopPropagation();duplicateOneFile(postFile)">
                            <i class="fa fa-clone click-delete-sommaire" aria-hidden="true"></i>
                            <span class="menu-text pointer">Dupliquer</span>
                          </li>
                          <li (click)="$event.stopPropagation();deleteOneFile(postFile)">
                            <i class="fa fa-trash click-delete-sommaire" aria-hidden="true"></i>
                            <span class="menu-text pointer">Supprimer</span>
                          </li>
                          <li (click)="$event.stopPropagation();openAffiliationModal(postFile)">
                            <i class="fa fa-edit click-delete-sommaire" aria-hidden="true"></i>
                            <span class="menu-text pointer">Modifier l'affiliation</span>
                          </li>
                        </ul>
                      <!-- </div> -->
                    </td>
                  </tr>
                  <tr class="friro-row" *ngIf="showFrigo(postFile)">
                    <div *ngIf="!postFile.frigogenePost" class="frirogene-container">
                      <i class="fa fa-warning frigo-icons"></i>
                      <p class="margin-left-5" style="margin-bottom: 0px;">Votre composant Climatisation utilise un fluide frigorigène qu'il faut également indiquer.</p>
                      <a class="margin-left-5 purple-color" (click)="openFrirogeneModal(postFile)">Choisir le fluide frigorigène correspondant</a>
                    </div>
                    <div *ngIf="postFile.frigogenePost" class="frirogene-container-selected">
                      <i class="fa fa-check-circle-o frigo-icons"></i>
                      <p class="margin-left-5">Votre composant Climatisation utilise le fluide frigorigène {{(postFile?.frigogenePost?.nom)||''}}. Sa charge initiale est de </p>
                      <input type="text" class="frigogene-input" [ngModel]="postFile.frigogenePost.frigogeneQuantity" (focusout)="updateFrigogeneData($event,postFile)"> kg
                      <a class="margin-left-5 purple-color" (click)="openFrirogeneModal(postFile)">Modifier</a>
                    </div>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div *ngIf="showAdd()" class="flex pointer" style="margin-bottom: 12px;align-items: baseline;" [ngClass]="{'prevent-event':isProjectDemo}">
              <i class="fa fa-plus purple-color"></i>
              <p class="purple-color no-margin margin-left-10"  (click)="openSide(lot)">Ajouter un composant</p>
            </div>
          </div>
        </div>
      </div>
    <div *ngIf="showAdd() && selectedEntity!='Parcelle'" class="flex margin-left-24 margin-top-24" style="width: 87%">
      <div (click)="goNextPrevious('previous')" class="flex next-container pointer">
        <i class="fa fa-chevron-left margin-right-5"></i>
        <p>Précédent</p>
      </div>
      <div (click)="goNextPrevious('next')" class="flex next-container float-right pointer">
        <p>Suivant</p>
        <i class="fa fa-chevron-right margin-left-5"></i>
      </div>
    </div>
    </div>
    <div style="margin-top: 24px;">
      <app-spinner *ngIf="loadingPosts"></app-spinner>
    </div>
  </div>
</div>
<p-sidebar [(visible)]="displaySide" position="right" (onHide)="closeSide()" autoZIndex="false" styleClass="p-sidebar-lg">
  <div class="header">
    <div class="row">
      <div class="center">
        <div class="inner-container">
          <i class="click-it fa fa-times" aria-hidden="true" (click)="closeSide()"></i>
          <p class="post-title">{{selectedLot.father | parseLotName}} > {{selectedLot.lot | parseLotName}}</p>
        </div>
      </div>
    </div>
  </div>
  <app-acv-project-composant-sidebar [selectedLot]='selectedLot' [isDemo]="isProjectDemo" (closeTheCctp)="closeSide()" (frirogeneResult)="frirogeneResult=$event" (infoPost)="setInfoPost($event)" [selectedFriro]="selectedFriro" (addingPostIniesEvent)="setaddingPostInies($event)" (openRemploiEvent)="openModalChoice($event)" [currentContibuteur]="'Composants'" styleClass="p-sidebar-lg"></app-acv-project-composant-sidebar>
</p-sidebar>
<div id="frirogene-modal"
  class="modal fade frirogene-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-container">
      <p class="modal-title">Choisir le fluide frigorigène correspondant :</p>
      <div class="detail-container scroller-1">
        <ul *ngFor="let item of frirogeneResult;let i=index" [ngClass]="{'selected-friro': selectedFriroi==i}">
          <li (click)="selectFriro(i)">{{item.nom}}</li>
        </ul>
      </div>
      <div class="button-container" style="justify-content: left;">
        <button class="btn btn-flat" (click)='validateFriro()' data-dismiss="modal">Valider le nouveau fluide frigorigène</button>
      </div>
    </div>
  </div>
</div>
<div id="post-info-modal"
  class="modal fade post-info-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-container">
      <div class="choice-container">
        <div class="choice" (click)="onSelectionChangeTab('resume')"  [ngClass]="{'selectd-choice' : postInfoTab === 'resume'}">
          Résumé
        </div>
        <div class="choice" (click)="onSelectionChangeTab('detail')" [ngClass]="{'selectd-choice' : postInfoTab === 'detail'}">
          Détail
        </div>
      </div>
      <div class="margin-top-12" style="width: 80%;">
        <!-- <div class="rating-container">
          <img src="/assets/archi/leaf.svg"/>
          <p class="rating">B</p>
          <p>Faible Impact</p>
        </div> -->
        <p class="post-title no-margin">{{infoPost?.nom}}</p>
        <p class="post-title">ID : {{infoPost?.idFicheBase}}</p>
        <p class="sub-title">{{infoPost?.categoryName}}</p>
      </div>
      <div class="resume-post" *ngIf="postInfoTab=='resume'">
        <div *ngIf="(infoPost||{}).declarationType"><p class="mini-title">Type de déclaration :</p> {{infoPost?.declarationType}}<br></div>
        <div *ngIf="(infoPost||{}).organismeName"><p class="mini-title">Organisme déclarant :</p> {{infoPost?.organismeName}}<br></div>
        <div *ngIf="(infoPost||{}).descriptionUF"><p class="mini-title">Unité fonctionnelle : <span class="span-black">{{infoPost?.descriptionUF||'Aucun'}}</span></p><br></div>
        <div *ngIf="(infoPost||{}).productionPlace"><p class="mini-title">Lieu de production  :</p> {{infoPost?.productionPlace||'Aucun'}}<br></div>
        <div *ngIf="(infoPost||{}).performanceUFUnite"><p class="mini-title">Performance principale de l'UF :</p> {{infoPost?.performanceUFQuantite||0}} {{infoPost?.performanceUFUnite||''}}<br><br></div>
        <a style="color: #0275d8 !important" target="_blank" [href]='"https://www.base-inies.fr/iniesV4/dist/consultation.html?id=" + (infoPost||{}).idFicheBase'>Voir la fiche</a> <i class="fa fa-external-link" style="color: #0275d8 !important;margin-left: 3px;"></i>
      </div>
      <div class="detail-post" *ngIf="postInfoTab=='detail'">
        <div class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Informations générales</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <div *ngIf="(infoPost||{}).nbrReferencesCommerciales"><p class="mini-title">Nombre de références commerciales couvertes :</p> {{infoPost?.nbrReferencesCommerciales||0}}<br></div>
            <div *ngIf="(infoPost||{}).declarationType"><p class="mini-title">Type de déclaration :</p> {{infoPost?.declarationType||1}}<br></div>
            <div *ngIf="(infoPost||{}).dateEdition"><p class="mini-title">Date d'édition de la déclaration :</p> {{infoPost?.dateEdition| date: 'dd/MM/yyyy'}}<br></div>
            <div *ngIf="(infoPost||{}).dateMiseAJour"><p class="mini-title">Date de dernière mise à jour :</p> {{infoPost?.dateMiseAJour| date: 'dd/MM/yyyy'}}<br></div>
            <div *ngIf="(infoPost||{}).dateVerification"><p class="mini-title">Date de vérification :</p> {{infoPost?.dateVerification| date: 'dd/MM/yyyy'}}<br></div>
            <div *ngIf="(infoPost||{}).Version"><p class="mini-title">Version :</p> {{infoPost?.Version||'Aucun'}}<br></div>
            <div *ngIf="(infoPost||{}).dateEdition"><p class="mini-title">Date de fin de validité :</p> {{infoPost?.dateEdition||'Aucun'}}<br></div>
            <div *ngIf="(infoPost||{}).referencesCommerciales"><p class="mini-title">Références commerciales couvertes et fabricants :</p> {{infoPost?.referencesCommerciales||'Aucun'}}<br></div>
            <div *ngIf="(infoPost||{}).aptitudeUsage"><p class="mini-title">Preuves d'aptitude à l'usage :</p> {{infoPost?.aptitudeUsage||'Aucun'}}<br></div>
          </div>
        </div>
        <div class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Unités fonctionnelles</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <div *ngIf="(infoPost||{}).descriptionUF"><p class="mini-title">Unité fonctionnelle :<span class="span-black">{{infoPost?.descriptionUF||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost||{}).performanceUF"><p class="mini-title">Performance principale de l'UF :</p> {{infoPost?.performanceUF||'Aucun'}} {{infoPost?.performanceUF?':':''}} {{infoPost?.performanceUFQuantite}} {{infoPost?.performanceUFUnite}}<br></div>
            <div *ngIf="(infoPost||{}).quantiteUF"><p class="mini-title">Quantité :</p> {{infoPost?.quantiteUF||'Aucun'}} {{unitsCodes[infoPost?.uniteUF]}}<br></div>
            <div *ngIf="(infoPost||{}).dvt"><p class="mini-title">Durée de vie de référence (DVR) :</p> {{infoPost?.dvt}} ans<br></div>
            <div *ngIf="(infoPost||{}).infosComplementaires"><p class="mini-title">Caractéristiques non contenues dans l'U.F. :<span class="span-black"> {{infoPost?.infosComplementaires||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost||{}).tauxChute"><p class="mini-title">Taux de chute à l'étape du processus de construction :</p> {{infoPost?.tauxChute||0}}%<br></div>
            <div *ngIf="(infoPost||{}).frequenceEntretien"><p class="mini-title">Fréquence d'entretien :</p> {{infoPost?.frequenceEntretien+1||'Aucun'}} ans<br></div>
            <div *ngIf="(infoPost||{}).declarationContenu"><p class="mini-title">Déclaration de contenu :<span class="span-black">{{infoPost?.declarationContenu||'Aucun'}}</span></p><br></div>
          </div>
        </div>
        <div class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Indicateurs</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <div *ngFor="let indicateur of infoPost.ind">
              <p class="green-title">{{indicateur.name}}</p>
              <div class="flex" style="padding: 0px 0px 6px 0px;">
                <p style="font-weight: bold;">Indicateurs</p>
                <p class="float-right">Total cycle de vie</p>
              </div>
              <div *ngFor="let item of indicateur.data | keyvalue; let i=index">
                <div class="flex" style="padding: 5px 0px;" [ngStyle]="{'background': i%2==0?'#DFF8EC':'none'}">
                  {{item.key}}
                  <p class="float-right">{{(item.value||0) | formatPrice}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Santé</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <p class="green-title">Air intérieur</p>
            <div *ngIf="(infoPost.sante||{}).airRating"><p class="mini-title">Arrêté du 19 avril 2011 relatif à l'étiquetage des produits de construction ou de revêtement de mur ou de sol et des peintures et vernis sur leurs émissions de polluants volatils : <span class="span-black">{{infoPost?.sante?.airRating||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).eCovFormaldehyde"><p class="mini-title">Autres émissions de polluants volatils dans l'air intérieur hors etiquette réglementaire :<span class="span-black">{{infoPost?.sante?.eCovFormaldehyde||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).eRadioactive"><p class="mini-title">Émissions radioactives naturelles : <span class="span-black">{{infoPost?.sante?.eRadioactive||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).otherHealthInfo"><p class="mini-title">Autres informations sur la qualité sanitaire des espaces intérieurs : <span class="span-black">{{infoPost?.sante?.otherHealthInfo||'Aucun'}}</span></p><br></div>
            <p class="green-title">Sol et eau</p>
            <div *ngIf="(infoPost.sante||{}).infosDrinkingWater"><p class="mini-title">Le produit est-il en contact avec de l'eau destinée à la consommation humaine ? <span class="span-black">{{infoPost?.sante?.infosDrinkingWater||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).isContactNotDrinkingWater"><p class="mini-title">Le produit est-il en contact avec les eaux de ruissellement, les eaux d'infiltration, les eaux de surface ou les eaux de la nappe phréatique ? <span class="span-black">{{infoPost?.sante?.isContactNotDrinkingWater||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).infosNotDrinkingWater"><p class="mini-title">Informations sur la qualité des eaux de ruissellement, des eaux d'infiltration, des eaux de surface ou des eaux de la nappe phréatique <span class="span-black">{{infoPost?.sante?.infosNotDrinkingWater||'Aucun'}}</span></p><br></div>
          </div>
        </div>
        <div *ngIf="(infoPost||{}).confort.comfortHygrothermal || infoPost.confort.comfortAcoustic || infoPost.confort.comfortVisual || infoPost.confort.comfortOlfactory || infoPost.confort.otherComfortInfos" class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Confort</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <p *ngIf="(infoPost||{}).confort.comfortHygrothermal" class="green-title1">Confort hygrothermique</p><span class="span-black">{{infoPost?.confort?.comfortHygrothermal||'Aucun'}}</span><br>
            <p *ngIf="(infoPost||{}).confort.comfortAcoustic" class="green-title1">Confort acoustique</p><span class="span-black"> {{infoPost?.confort?.comfortAcoustic||'Aucun'}}</span><br>
            <p *ngIf="(infoPost||{}).confort.comfortVisual" class="green-title1">Confort visuel</p><span class="span-black">{{infoPost?.confort?.comfortVisual||'Aucun'}}</span><br>
            <p *ngIf="(infoPost||{}).confort.comfortOlfactory" class="green-title1">Confort olfactif</p><span class="span-black"> {{infoPost?.confort?.comfortOlfactory||'Aucun'}}</span><br>
            <p *ngIf="(infoPost||{}).confort.otherComfortInfos" class="green-title1">Autres informations sur le confort</p><span class="span-black">{{infoPost?.confort?.otherComfortInfos||'Aucun'}}</span><br>
          </div>
        </div>
      </div>
      <div class="margin-top-12 add-post-detail" *ngIf="detailModalFromSideBar" style="width: 100%;">
        <button class="btn btn-cards" style="background-color: #5A9E6F;width: 304px;" (click)="openConfirmationPost(infoPost)">Ajouter la fiche</button>
      </div>
    </div>
  </div>
</div>

<div id="choose-demand-modal"
  class="modal fade choose-demand-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog no-padding">
    <div class="modal-container no-padding">
      <!-- <p class="upgrade-title">{{lot?.title}} > {{cctpEdition?.cctpEdit?.postDesignation}}</p> -->
      <p class="upgrade-title">Vous ne trouvez pas votre fiche ?</p>
      <p class="upgrade-sub-title">Il est possible que votre fiche n'existe pas encore dans la base de donnée. Souhaitez-vous faire la demande création ?</p>
      <div class="button-container">
        <button class="btn btn-forms" data-dismiss="modal" (click)="demandePost('Update')">Une demande existe déjà</button>
        <button class="btn btn-forms" style="margin-left: 24px;" data-dismiss="modal" (click)="demandePost('Creation')">Faire une demande</button>
      </div>
    </div>
  </div>
</div>
<div id="add-demand-modal"
  class="modal fade add-demand-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog no-padding" style="width: 770px;">
    <div class="modal-container no-padding">
      <div class="row justify-content-center main-container scroller-1 col-md-12">
        <form class="form-container1">
          <p *ngIf="demandeData.nature=='Creation'" class="text-center title">Demande de création de composant</p>
          <p *ngIf="demandeData.nature=='Update'" class="text-center title">Demande de composant déjà existant</p>
          <p *ngIf="demandeData.nature=='Reemploi'" class="text-center title">Votre composant réemploi</p>
            <div *ngIf="!currentUser.idDemandeur && demandeData.nature!='Reemploi'">
              <p class="sub-title" style="margin-bottom: 3px;">Vos informations</p>
              <p class="info">Vos informations vous seront demandées une seule fois pour l'ajout d'un composant.</p>
              <div class="form-row">
                <div class="col-md-6 form-group">
                  <label class="required-star" for="first-name">Prénom</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.prenom" name="first-name" placeholder="Prénom">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="last-name">Nom</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.nom" name="last-name" placeholder="Nom">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="email">Email</label>
                  <input class="input-demand" type="email" [(ngModel)]="demandeData.mail" name="email" placeholder="architecte@gmail.com">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="phone_number">Numéro de téléphone</label>
                  <input class="input-demand" type="tel" [(ngModel)]="demandeData.telephone" name="phone_number" placeholder="06 09 06 09 06">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="job">Profession</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.metier" name="job" placeholder="Architecte">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="company">Entreprise</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.entreprise" name="company" placeholder="Bam Architecture">
                </div>
              </div>
            </div>
            <p class="sub-title">Les informations sur le composant</p>
            <!-- <p>Ces informations vous sont demandées afin de faire la demande de création de composant.</p> -->
            <div *ngIf="demandeData.nature=='Creation'">
              <div class="form-row">
                <div class="col-md-12 form-group">
                  <label class="required-star" for="ded-name">Intitulé de la donnée environnementale par défaut proposée</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.content.creationLabel" name="ded-name" placeholder="">
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 form-group">
                  <label class="required-star" for="quantity">Quantité à modéliser</label>
                  <input class="input-demand" type="number" [(ngModel)]="demandeData.quantity" name="quantity" placeholder="1">
                </div>
                <div class="col-md-4 form-group">
                  <label class="required-star" for="UF">Unité de l'unité fonctionnelle</label>
                  <select class="input-demand scroller-1" name="UF" [(ngModel)]="demandeData.unity">
                    <option *ngFor="let unit of unitsCodes | keyvalue">{{unit.value}}</option>
                  </select>
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="product_life">Durée de vie estimée</label>
                  <input class="input-demand" type="number" [(ngModel)]="demandeData.dve" name="product_life" placeholder="50 ans">
                </div>
                <div class="col-md-12 form-group">
                  <label class="required-star" for="argumentation">Argumentaire(s)/ Justification(s) de la demande</label>
                  <textarea class="input-demand no-resize" style="height: 100px;" [(ngModel)]="demandeData.content.creationArgument" name="argumentation" rows="4" placeholder="Expliciter au maximum votre constat et votre demande "></textarea>
                </div>
                <div class="col-md-12 form-group">
                  <label class="required-star" for="argumentation">Identification du produit de référence servant à construire la donnée environnementale par défaut et dimensionnement du produit</label>
                  <textarea class="input-demand no-resize" style="height:75px" [(ngModel)]="demandeData.content.creationIdentification" name="argumentation" rows="4" placeholder="Produit de référence à utiliser pour contruire la donnée environnementale par défaut"></textarea>
                </div>
                <div class="col-md-12 form-group">
                  <label for="argumentation">Identification de l'échantillon/donnée source pour établir le calcul</label>
                  <textarea class="input-demand no-resize" style="height:75px" [(ngModel)]="demandeData.content.creationSource" name="argumentation" rows="4" placeholder="Echantillon source à utiliser pour établir le calcul"></textarea>
                </div>
                <div class="col-md-12 form-group">
                  <label for="UF">Famille de de produit</label>
                  <select class="input-demand" name="CR" [(ngModel)]="demandeData.content.creationRank.creationRankId">
                    <option *ngFor="let item of iniesCategories" [value]="item.id"><img [src]="'assets/dpgf/Icon_DPGF/'+item.icon"/>{{item.title}}</option>
                  </select>
                </div>
                <div [hidden]="demandeData.content.creationRank.creationRankId!=0">
                  <div class="col-md-12">
                    <p class="sub-title1">Votre famille de produit n'est pas dans liste.</p>
                  </div>
                  <div class="col-md-12 form-group">
                    <label for="product_life">Renseigner votre nom de famille de produit</label>
                    <input class="input-demand" type="text" [(ngModel)]="demandeData.content.creationRank.creationRankName" name="nom-famille">
                  </div>
                  <div class="col-md-12 form-group">
                    <label for="product_life">Dans quelle catégorie votre famille de produit se trouve-t-elle ?</label>
                    <select class="input-demand" name="UF" [(ngModel)]="demandeData.content.creationRank.creationRankParent">
                      <option *ngFor="let item of iniesCategories" [value]="item.id"><img [src]="'assets/dpgf/Icon_DPGF/'+item.icon"/>{{item.title}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div style="position: relative;" *ngIf="demandeData.nature=='Update'">
              <div class="col-md-6 form-group">
                <label class="required-star">Numéro de la demande Mlab</label>
                <input id="id-input" class="input-demand" type="text" autocomplete="off" [(ngModel)]="demandeData.id" (focus)="getDemendSugg()" name="num-mlab">
                <div id="myInputautocomplete-list" class="autocomplete-items-demand">
                  <div style="overflow: auto;height: 156px;" class="scroller-1">
                    <div *ngFor="let result of demandesSugg;let i = index" (mousedown)="setChosenDemand(result)" class="unit-proposition">
                      <div class="flex">
                        <p style="font-weight: 900;">{{result.idDemand}} - {{result.name}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label class="required-star">Intitulé de la donnée environnementale</label>
                <input class="input-demand" type="text" [(ngModel)]="demandeData.content.creationLabel" name="creationLabel">
              </div>
              <div class="col-md-6 form-group">
                <label class="required-star">Durée de vie estimée</label>
                <input class="input-demand" type="number" [(ngModel)]="demandeData.dve" name="dve">
              </div>
              <div class="col-md-6 form-group">
                <label class="required-star">Quantité à modéliser</label>
                <input class="input-demand" type="number" [(ngModel)]="demandeData.quantity" name="quantity">
              </div>
              <div class="col-md-5 form-group">
                <label class="required-star">Unité de l'unité fonctionnelle</label>
                <select class="input-demand" name="UF" [(ngModel)]="demandeData.unity">
                  <option *ngFor="let unit of unitsCodes | keyvalue">{{unit.value}}</option>
                </select>
              </div>
            </div>
            <div *ngIf="demandeData.nature=='Reemploi'">
              <div class="col-md-6 form-group">
                <label>Nom du composant</label>
                <input class="input-demand" type="text" [(ngModel)]="demandeData.content.creationLabel" name="num-mlab">
              </div>
              <div class="col-md-6 form-group">
                <label>Durée de vie estimée</label>
                <input class="input-demand" type="number" [(ngModel)]="demandeData.dve" name="dve">
              </div>
              <div class="col-md-6 form-group">
                <label>Quantité à modéliser</label>
                <input class="input-demand" type="number" [(ngModel)]="demandeData.quantity" name="quantity">
              </div>
              <div class="col-md-5 form-group" style="margin-bottom: 30px;">
                <label>Unité de l'unité fonctionnelle</label>
                <select class="input-demand" name="UF" [(ngModel)]="demandeData.unity">
                  <option *ngFor="let unit of unitsCodes | keyvalue">{{unit.value}}</option>
                </select>
              </div>
            </div>
            <div *ngIf="demandeData.nature!='Reemploi'" class="terms-container col-md-12">
              <input class="checkbox-box" type="checkbox" [checked]="demandeData.terms" name="flexCheckIndeterminate" [(ngModel)]="demandeData.terms">
              <label for="flexCheckIndeterminate" style="margin-top: 20px;padding-left: 17px;">
                Je consens à la politique de collecte et protection des données personnelles de MLab
              </label>
            </div>
            <div class="btn-form col-md-12">
              <button *ngIf="demandeData.nature=='Creation'" type="submit" class="btn btn-forms" (click)="submitDemande()" [disabled]="!((currentUser.idDemandeur && demandeData.terms && demandeData.content.creationLabel && demandeData.quantity && demandeData.unity && demandeData.content.creationArgument && demandeData.content.creationIdentification) || (!currentUser.idDemandeur && demandeData.nom && demandeData.prenom && demandeData.mail && demandeData.telephone && demandeData.metier && demandeData.entreprise && demandeData.content.creationLabel && demandeData.quantity && demandeData.unity && demandeData.content.creationArgument && demandeData.content.creationIdentification && demandeData.terms))">
                <p *ngIf="!loadingDemand" class="no-margin">Envoyer ma demande de création de fiche INIES</p>
                <app-spinner *ngIf="loadingDemand"></app-spinner>
              </button>
              <button *ngIf="demandeData.nature=='Update'" type="submit" class="btn btn-forms" (click)="submitDemande()" [disabled]="!((currentUser.idDemandeur && demandeData.id && demandeData.terms) || (!currentUser.idDemandeur && demandeData.nom && demandeData.prenom && demandeData.mail && demandeData.telephone && demandeData.metier && demandeData.entreprise && demandeData.id && demandeData.terms))">
                <p *ngIf="!loadingDemand" class="no-margin">Renseigner ma demande de création de fiche</p>
                <app-spinner *ngIf="loadingDemand"></app-spinner>
              </button>
              <button *ngIf="demandeData.nature=='Reemploi'" type="submit" class="btn btn-forms" (click)="addPostIniesReemploi()">Ajouter mon composant réemploi</button>
            </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div id="add-post-modal" class="modal fade add-post-modal" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
        <h3 class="modal-title modal-header-title">{{selectedLot.father | parseLotName}} > {{selectedLot.lot | parseLotName}}</h3>
        <div class="flex margin-top-24">
          <img src="/assets/archi/correct-circle.svg" class="emoji">
          <p class="description">
            La fiche <b>{{nameChosenPost}}</b> va être ajoutée au lot <b>{{selectedLot.lot | parseLotName}}</b>
          </p>
        </div>
      <div class="margin-top-24 flex align-centre" style="height: 37px;justify-content: center;">
        <button type="button" class="btn btn-grey" data-dismiss="modal">Annuler l'ajout</button>
        <button type="button" class="btn btn-forms margin-left-24" (click)="addFileToPost()">
          <span *ngIf="!addingPostInies">Continuer</span>
          <app-spinner *ngIf="addingPostInies" style="position: relative;bottom: 10px;"></app-spinner>
        </button>
      </div>
    </div>
  </div>
</div>

<div id="edit-post-modal" class="modal fade add-post-modal edit-post-modal" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <p class="modal-title">Contributeur auquel affilier la fiche :</p>
      <div class="flex" style="width: 41%;justify-content: space-between;margin: 20px;">
        <div>
          <input type="radio" name='affiliation' value="Composant" [checked]="selectedPost.contributor=='Composants'" (click)="setContributeur('Composants')">
          <label class="margin-left-12">Composant</label>
        </div>
        <div>
          <input type="radio" name='affiliation' value="Chantier" [checked]="selectedPost.contributor=='Chantier'" (click)="setContributeur('Chantier')">
          <label class="margin-left-12">Chantier</label>
        </div>
      </div>
      <div style="position: relative;" *ngIf="selectedPost.contributor=='Composants'">
        <p>Lot :</p>
        <input class="post-select" style="width: 90%;z-index: 10000;padding-right: 26px;margin-left: 20px;"
        [(ngModel)]="selectedPost.lotPCE" (focusout)='lotsPCECopy=lotPCE' (keyup)="filterLotsPCE(selectedPost.lotPCE)"
        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" autocomplete="off" placeholder="Choisir le lot PCE">
        <div id="lot-pce-dropdown" style="left: 0;width: 486px;margin-left: 20px;" class="dropdown-menu lot-pce scroller-1" aria-labelledby="dropdownMenuButton">
          <div *ngIf="selectedPost.lotPCE">
            <div *ngFor="let lotPCE of lotsPCECopy">
              <p *ngIf="lotPCE.lots.length" class="title">{{lotPCE.name}}</p>
              <div *ngIf="lotPCE.lots.length" style="padding-left: 18px;">
                <p *ngFor="let oneLot of lotPCE.lots" (click)="setPostPCE(oneLot)">{{oneLot}}</p>
              </div>
              <hr *ngIf="lotPCE.lots.length">
            </div>
          </div>
          <div *ngIf="!selectedPost.lotPCE">
            <div *ngFor="let lotPCE of lotsPCE">
              <p class="title">{{lotPCE.name}}</p>
              <div style="padding-left: 18px;">
                <p *ngFor="let oneLot of lotPCE.lots" (click)="setPostPCE(oneLot)">{{oneLot}}</p>
              </div>
              <hr>
            </div>
          </div>
        </div>
        <img src="/assets/archi/select-arrow.svg" class="select-arrow" style="top: 41px;right: 67px;">
      </div>
      <div class="flex margin-top-24">
        <button type="button" data-dismiss="modal" class="btn btn-grey" style="margin-left: auto;">Annuler</button>
        <button type="button" class="btn btn-forms margin-left-24" style="float: right;" (click)="setUpdatePost(selectedPost)">Valider</button>
      </div>
    </div>
  </div>
</div>
<div id="import-files-modal" class="modal fade add-post-modal" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
        <h3 class="modal-title modal-header-title" style="text-align: center;">Votre fiche configurée </h3>
        <div class="margin-top-24">
          <!-- <p class="description"> -->
            Les fiches configurées peuvent être réalisées sur les sites homologués suivants :
            <div style="padding-left: 10px;">
              <div style="display: flex;height: 24px;margin-top: 2px;">&bull;&nbsp; <a class="one-link" href="https://www.snbpe.org/index.php/developpement_durable/calculette" target="_blank">BETIE  <i class="fa fa-external-link"></i></a></div>              
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://www.save-construction.com/" target="_blank">Solutions Acier Valeurs environnementales  <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://de-bois.fr/fr" target="_blank">DE-BOIS  <i class="fa fa-external-link"></i></a></div>
              <!-- <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">AKacia  <i class="fa fa-external-link"></i></a></div> -->
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="http://www.environnement-ib.com/login" target="_blank">Environnement IB  <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://www.de-boisdefrance.fr/" target="_blank">DE - Bois De France   <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">Fichier extrapolation PEP </a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://calculateurco2.ciments-hoffmann.fr/" target="_blank">Hoffmann Green Cement Technologies   <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://www.bankiz-fdes.fr/app/" target="_blank">BANKIZ  <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">VICAT </a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">VINCI Construction </a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://www.de-baie.fr/" target="_blank">DE-Baie   <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">3B </a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">BSM </a></div>
            </div>
            <p class="margin-top-12">Elles permettent de modéliser des composants qui ne seraient pas présents dans la base INIES</p>
        </div>
      <div class="margin-top-24 flex align-centre" style="height: 37px;justify-content: center;">
        <button type="button" class="btn btn-forms" (click)="fileInput.click()">
          <input
            #fileInput
            id="file"
            type="file"
            class="form-control"
            style="display:none;"
            (change)="importXml($event)">
          <span *ngIf="!addingPostInies">Importer ma fiche configurée</span>
          <app-spinner *ngIf="addingPostInies" style="position: relative;bottom: 10px;"></app-spinner>
        </button>
      </div>
    </div>
  </div>
</div>
<div id="add-lot-forfitaire" class="modal fade add-post-modal" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
        <h3 class="modal-title modal-header-title" style="color:rgba(56, 5, 242, 1);font-weight: 700;font-size:14px;">Utiliser un lot forfaitaire</h3>
        <div class="margin-top-24">
          <!-- <p class="description"> -->
            <div style="border-left: 21px solid yellow;color:rgba(10, 23, 71, 1);" *ngIf="lotId==10" >
              <div class="padding-top-bottom-8 cursor" [ngClass]="{'background-color-light-blue': serialIdentif=='INIES_D[RE20210628_114431'}" (click)="getIdSerialFiche('INIES_D[RE20210628_114431')"><span class="blue-color margin-left-12" >Lot 10 : Maison individuelle</span></div>
              <div class="padding-top-bottom-8 cursor" [ngClass]="{'background-color-light-blue': serialIdentif=='INIES_D[RE20210628_095521'}" (click)="getIdSerialFiche('INIES_D[RE20210628_095521')"><span class="blue-color margin-left-12">Lot 10 : Logement collectif </span></div>
              <div class="padding-top-bottom-8 cursor" [ngClass]="{'background-color-light-blue': serialIdentif=='INIES_DBDE20211012_091819'}" (click)="getIdSerialFiche('INIES_DBDE20211012_091819')"><span class="blue-color margin-left-12">Lot 10 : Bureaux</span></div>
              <div class="padding-top-bottom-8 cursor" [ngClass]="{'background-color-light-blue': serialIdentif=='INIES_DOQZ20211012_091545'}" (click)="getIdSerialFiche('INIES_DOQZ20211012_091545')"><span class="blue-color margin-left-12">Lot 10 : Enseignement</span></div>
            </div>
            <div style="border-left: 21px solid yellow;color:rgba(10, 23, 71, 1);" *ngIf="lotId==11" >
              <div class="padding-top-bottom-8 cursor" [ngClass]="{'background-color-light-blue': serialIdentif=='INIES_D[RE20210628_114445'}" (click)="getIdSerialFiche('INIES_D[RE20210628_114445')"><span class="blue-color margin-left-12">Lot 11 : Maison individuelle</span></div>
              <div class="padding-top-bottom-8 cursor" [ngClass]="{'background-color-light-blue': serialIdentif=='INIES_D[RE20210628_114548'}" (click)="getIdSerialFiche('INIES_D[RE20210628_114548')"><span class="blue-color margin-left-12">Lot 11 : Logement collectif </span></div>
              <div class="padding-top-bottom-8 cursor" [ngClass]="{'background-color-light-blue': serialIdentif=='INIES_DUVJ20211012_091757'}" (click)="getIdSerialFiche('INIES_DUVJ20211012_091757')"><span class="blue-color margin-left-12">Lot 11 : Bureaux</span></div>
              <div class="padding-top-bottom-8 cursor" [ngClass]="{'background-color-light-blue': serialIdentif=='INIES_DICI20211012_091525'}" (click)="getIdSerialFiche('INIES_DICI20211012_091525')"><span class="blue-color margin-left-12">Lot 11 : Enseignement</span></div>
            </div>
        </div>
      <div class="margin-top-24" >
        <button class="button-style border-none text-font" (click)="getFicheIniesById(lotId)" [disabled]="disableButtonAddLotForfaitaire()" [ngClass]="{disabled: disableButtonAddLotForfaitaire()}" >
          <p *ngIf="!loadingForfaitaire && stateModal=='create'" class="no-margin">Ajouter le lot forfaitaire</p>
          <p *ngIf="!loadingForfaitaire && stateModal=='update'" class="no-margin">Modifier le lot forfaitaire</p>
          <app-spinner *ngIf="loadingForfaitaire"></app-spinner>
        </button>
      </div>
    </div>
  </div>
</div>
