<!-- App Header -->
<app-header></app-header>

<div class="container">
  <div class="center col-md-8 col-md-offset-2">
    <h1>Erreur</h1><br>
    <h4>
      Nous rencontrons un problème avec notre serveur. Veuillez recharger la page et vérifiez que vous êtes bien connecté.<br>
      Si le problème persiste, vous pouvez contacter notre service technique à l'adresse support&#64;bam.archi
    </h4><br>
    <img src="/assets/error.gif" alt="">
    <hr>

    <button (click)="goBack()" class="btn-primary-color">
      Revenir à la page précédente
    </button>
  </div>
</div>

<!-- App Footer  -->
