import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  standalone:true,
  name: 'artisan'
})
export class ArtisanPipe implements PipeTransform {
  public data:any = {
    'fabriquant-revendeur-materiaux':"Fabriquant / Revendeur matériaux",
    'artisan-entreprise-specialisee':"Artisan / Entreprise spécialisée",
    'entreprise-generale':"Entreprise générale",
    'bureau-d-etude':"Bureau d'études",
    "renovation-lourde":"Rénovation lourde",
    "renovation-simple":"Rénovation simple",
    "renovation" : "Rénovation",
    "extension":"Extension",
    "surelevation":"Surélévation",
    "construction-neuve":"Construction Neuve",
    "maison":"Maison",
    "appartement":"Appartement",
    "cafe-hotel-restaurant":"Café / Hôtel / Restaurant",
    "boutique-conceptstore":"Boutique / Concept Store",
    "bureaux-tertiaire":"Bureaux / Tertiaire",
    "batiment-industriel-agricole":"Bâtiment Industriel / Agricole",
    "immeuble":"Immeuble",
    "equipements-publics":"Équipements publics",
  };
  transform(value) {
      if(typeof value == "string"){
        return this.data[value] || value;
      }
      else if (typeof value == "object"){
        let newValue = value.slice(0);
        newValue = newValue.map(e => this.data[e] || e)
        return newValue.join(' / ')
      }
      return value;
  }
}
