import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragulaService , DragulaModule } from 'ng2-dragula'; 
import { HTTP_INTERCEPTORS } from '@angular/common/http';
//Component
import { SpinnerComponent } from './spinner/spinner.component';

//Pipes
import { CapitalizePipe } from '../_pipe/capitalize.pipe';
import { LocationPipe } from '../_pipe/location.pipe';
import { PhonePipe } from '../_pipe/phone.pipe';
import { TypeProjetPipe } from '../_pipe/typeProjet.pipe';
import { CeProjectPipe } from '../_pipe/ceProject.pipe';
import { ArtisanPipe } from '../_pipe/artisan.pipe';
import { SafePipe } from '../_pipe/safe.pipe';
import { DateFrPipe } from '../_pipe/dateFr.pipe';
import { DateRDVPipe } from '../_pipe/dateRDV.pipe';
import { DpgfLotTitlePipe } from '../_pipe/dpgfLotTitle.pipe';
import { FileFolderPipe } from '../_pipe/fileFolder.pipe';
import { LinebreakPipe } from '../_pipe/linebreak.pipe';
import { PriceUnitePipe } from '../_pipe/priceUnite.pipe';
import { PricingVersionIndex } from '../_pipe/pricingVersionIndex.pipe';
import { HighLightWordsPipe } from '../_pipe/highlightWords.pipe';
import { IsHtmlEmpty } from '../_pipe/isHtmlEmpty.pipe';
import { DateAgoPipe } from '../_pipe/date-ago.pipe';
import { FormatPrice } from '../_pipe/formatPrice.pipe';
import { IniesSuggestion } from '../_pipe/iniesSuggestion.pipe';
import { ParseLotNamePipe } from '../_pipe/parse-lot-name.pipe';
import { ParsePercentageImpactPipe } from '../_pipe/parse-percentage-impact.pipe';
import { FormatMOPipe } from '../_pipe/format-mo.pipe';
import { GetStatePath } from '../_pipe/getStatePath.pipe';
import { RemainingDaysPipe } from '../_pipe/RemainingDays.pipe';
import { FormatNumberPricePipe } from '../_pipe/format-number-price.pipe';
import { LoggsPipe } from '../_pipe/logs.pipe';
import { IndicePipe } from '../_pipe/indice.pipe';


//Ng Prime components
import {SliderModule} from 'primeng/slider';
import {RadioButtonModule} from 'primeng/radiobutton';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {InputMaskModule} from 'primeng/inputmask';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {DropdownModule} from 'primeng/dropdown';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {SidebarModule} from 'primeng/sidebar';
import {ChartModule} from 'primeng/chart';
import {CalendarModule} from 'primeng/calendar';
import {RatingModule} from 'primeng/rating';
import {InputSwitchModule} from 'primeng/inputswitch';
import {CheckboxModule} from 'primeng/checkbox';
import {DialogModule} from 'primeng/dialog';
import {MultiSelectModule} from 'primeng/multiselect';
import {FileUploadModule} from 'primeng/fileupload';
import {TooltipModule} from 'primeng/tooltip';
import {EditorModule} from 'primeng/editor';
import {PaginatorModule} from 'primeng/paginator';
import { TreeModule} from 'primeng/tree';

// Aglo Design System Components
import { ConfirmModalComponent } from 'src/app/_design_system_components/confirm-modal/confirm-modal.component';

//External Libs
import { ColorSketchModule } from "ngx-color/sketch";
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import {NgxPaginationModule} from 'ngx-pagination';

// Interceptos
import { GlobalResponseInterceptorInterceptor } from 'src/app/_interceptors/global-response-interceptor.interceptor';
import { CustomFormatDatePipe } from '../_pipe/custom-format-date.pipe';
import { DateFormatPipe } from '../_pipe/date-format.pipe';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    //Prime NG
    SliderModule,
    RadioButtonModule,
    AutoCompleteModule,
    InputMaskModule,
    SelectButtonModule,
    ToggleButtonModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    SidebarModule,
    ChartModule,
    CalendarModule,
    RatingModule,
    CheckboxModule,
    DialogModule,
    MultiSelectModule,
    FileUploadModule,
    TooltipModule,
    EditorModule,
    PaginatorModule,
    DropdownModule,
    InputSwitchModule,
    // Aglo Design System Components
    ConfirmModalComponent,

    // Angular material
    // MatCheckbox,
    // componenets
    SpinnerComponent,
    // pipes
    CapitalizePipe,
    LocationPipe,
    PhonePipe,
    TypeProjetPipe,
    CeProjectPipe,
    SafePipe,
    ArtisanPipe,
    DateFrPipe,
    DateRDVPipe,
    DpgfLotTitlePipe,
    LinebreakPipe,
    PriceUnitePipe,
    FileFolderPipe,
    RemainingDaysPipe,
    FormatNumberPricePipe,
    HighLightWordsPipe,
    IsHtmlEmpty,
    DateAgoPipe,
    FormatPrice,
    IniesSuggestion,
    ParseLotNamePipe,
    ParsePercentageImpactPipe,
    FormatMOPipe,
    LoggsPipe,
    IndicePipe,
    PricingVersionIndex,
    GetStatePath,
    CustomFormatDatePipe,
    DateFormatPipe,
    //ArchiExpiredModalComponent,
    //External
    ColorSketchModule,
    TreeModule,
    // NgxPaginationModule,
    ConfirmationPopoverModule,
    ConfirmationPopoverModule.forRoot(),
    // TextareaAutosizeModule,
    NgxPaginationModule,
    // SwingModule,
    // TextareaAutosizeModule,
    // DragulaModule,
    DragulaModule.forRoot()

  //   ConfirmationPopoverModule.forRoot({
  //     confirmButtonType: 'danger'
  //   })
  ],
  exports:[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    //Components
    SpinnerComponent,
    // ConfirmModalComponent,
    //Pipes
    CapitalizePipe,
    CustomFormatDatePipe,
    DateFormatPipe,
    LocationPipe,
    PhonePipe,
    TypeProjetPipe,
    CeProjectPipe,
    SafePipe,
    ArtisanPipe,
    DateFrPipe,
    DateRDVPipe,
    DpgfLotTitlePipe,
    LinebreakPipe,
    PriceUnitePipe,
    FileFolderPipe,
    RemainingDaysPipe,
    FormatNumberPricePipe,
    HighLightWordsPipe,
    LoggsPipe,
    IndicePipe,
    IsHtmlEmpty,
    DateAgoPipe,
    FormatPrice,
    IniesSuggestion,
    ParseLotNamePipe,
    ParsePercentageImpactPipe,
    FormatMOPipe,
    PricingVersionIndex,
    GetStatePath,
    //Other Modules
    //Prime NG
    SliderModule,
    // DragulaModule,
    RadioButtonModule,
    AutoCompleteModule,
    InputMaskModule,
    SelectButtonModule,
    ToggleButtonModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    SidebarModule,
    ChartModule,
    CalendarModule,
    RatingModule,
    CheckboxModule,
    DialogModule,
    MultiSelectModule,
    FileUploadModule,
    TooltipModule,
    EditorModule,
    PaginatorModule,
    DropdownModule,
    InputSwitchModule,
    // Aglo Design System Components
    ConfirmModalComponent,

    //External
    ColorSketchModule,
    TreeModule,
    // NgxPaginationModule,
    ConfirmationPopoverModule,
    // TextareaAutosizeModule,
    DragulaModule,

    NgxPaginationModule,
    // ConfirmationPopoverModule,
    // SwingModule,
    // TextareaAutosizeModule,
  ],
  declarations: [
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: GlobalResponseInterceptorInterceptor, multi:true }
  ]
})
export class NgSharedModule {
}
