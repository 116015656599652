import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upper-info-bar',
  standalone: true,
  imports: [
    NgSharedModule
  ],
  templateUrl: './upper-info-bar.component.html',
  styleUrl: './upper-info-bar.component.scss'
})
export class UpperInfoBarComponent {
  
  @Input() projectTitle:string = "";
  @Input() consultedEnterprisesCount:any = 0;
  @Input() showSendDCEIcon:boolean = false;
  @Input() showUpdateDCEIcon:boolean = false;
  @Input() showSendMailIcon:boolean = false;

  @Output() tabChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() dateDceChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() openAddEnterpriseModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() openCreateChiffrageModal: EventEmitter<any> = new EventEmitter<any>();

  @Output() openSendDceToMultipleArtisansModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() openUpdateDceForMultipleArtisansModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() openSendMailToMultipleArtisansModal: EventEmitter<any> = new EventEmitter<any>();

  public selectedTab: string = "enterprises"; // enterprises, lots

  @Input() dateDce;
  // public dateDce;

  public emitDateSelected(){
    // due to hours difference between MOR & FR
    // when you select a day it subtract 2 hours in FR
    // Thus selecting prev day (this is the fix)
    let formatedDate:any = new Date(this.dateDce);
    formatedDate.setHours(formatedDate.getHours() + 12);    
    let projectData = { dateDce : formatedDate};
    if(!environment.onPremise)window.analytics.track('set_deadline_consultation',{properties:{acValue: 'set_deadline_consultation'}});
    this.dateDceChanged.emit(projectData);
  }

  public changeTab(newTab){
    this.selectedTab = newTab;

    this.tabChanged.emit(newTab);
  }

  public emitOpenAddEnterpriseModal(){
    this.openAddEnterpriseModal.emit();
  }
  public emitOpenCreateChiffrageModal(){
    this.openCreateChiffrageModal.emit();
  }

  public emitSendDceToMultipleArtisans(){
    this.openSendDceToMultipleArtisansModal.emit();
  }
  public emitUpdateDceForMultipleArtisans(){
    this.openUpdateDceForMultipleArtisansModal.emit();
  }
  public emitSendMailToMultipleArtisans(){
    this.openSendMailToMultipleArtisansModal.emit();
  }

}
