<div class="all">

  <!-- App Header -->
  <app-header type="full" cssClass='fixed-bar'></app-header>
  <app-archi-expired-modal></app-archi-expired-modal>
  <div class="nav-bar" *ngIf="!authService.currentEnvironment">
    <div>
      <div *ngFor="let entry of tableEntry; first as first; let i = index"
        (click) = "changeTab(entry.id)"
        [pTooltip]="entry.name"
        [class.selected]='selected == entry.id'
        class='ce-nav-button'>
        <i class='{{entry.icon}} fa-2x' aria-hidden="true"></i>
      </div> 
    </div>
  </div>

  <div class="modal-spinner" *ngIf="loadingData && loadingSubs">
    <div class="spinner-center">
    </div>
  </div>
  
  <div *ngIf="currentPage=='licence'" class="flex center-items" style="justify-content: center;padding-top: 85px;">
    <div class="switch-toggle switch-3 switch-candy">
      <ul style="margin-bottom: 0px;padding: 0;" *ngIf="archiId != ''">
        <li class="choice" (click)="currentTab='licence'" [ngClass]="{'selectd-choice' : currentTab=='licence'}" >
          <a data-toggle="tab">Licence</a>
        </li>
        <li class="choice" (click)="currentTab='bills'" [ngClass]="{'selectd-choice' : currentTab=='bills'}">
          <a data-toggle="tab">Facturation</a>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="!loadingUsers">
  <div  *ngIf="users[0].stripe3dsUrl && isAdminArchitect && currentTab==='licence' && currentPage==='licence'" class="flex" style="justify-content:center;">
    <div class="flex warning-info" >
      <img src="assets/error-circle-red.svg" class="margins-8">
      <div class="margin-top-bottom-7" >
       Le paiement lié à votre prise d’abonnement nécessite une <p>validation, confirmez le paiement en cliquant <a [href]="users[0].stripe3dsUrl" class="href-style">ici</a></p>
      </div>
      <img src="assets/close-red.svg" class="margins-8">
    </div>
  </div>
</div>
  <div class="main-container" [ngClass]="{'padding-top-30-important' : currentPage == 'licence'}" >
    <div class='secondary-container'>
      <div id="all-tabs" class="tab-content">
        <div id="perso" class="tab-pane fade in" [ngClass]="{active : currentPage == 'perso'}">
          <div>
            <div class="form" style="margin-top: 36px;">
              <app-form1 [disabled]=false [hidePassword]=true [(architect)]="dataArchi" [isProfile]='true'></app-form1>
            </div>
          </div>
          <div *ngIf="isAdminArchitect">
            <div class="form">
              <app-form3 
                [(agency)]="agencyArchi" 
                [isProfile]='true'
                [disabled]="true" 
                (removeLogo)="removeArchitectLogo($event)" 
                ></app-form3>
            </div>
          </div>
        </div>
        <div *ngIf="isAdminArchitect" id="licence" class="tab-pane fade in" [ngClass]="{active : currentPage==='licence'}">
          <app-architect-pricing-tables [hidden]="currentTab=='bills'" [subscriptions]="subs" [paymentMethod]="methodPayment" [users]="users" [tvaUser]="tvaDefault" (getSubsEvent)="getUserSubs()"></app-architect-pricing-tables>
          <app-architect-users [hidden]="currentTab=='bills'" [subscriptions]="subs" [users]="users" (getSubsEvent)="getUserSubs();getUsers()" (getUsersEvent)="getUsers()"></app-architect-users>
          <!-- <app-architect-bills [hidden]="currentTab=='licence'"></app-architect-bills> -->
          <app-architect-bills [hidden]="currentTab=='licence'" (tvaChanged)="sendTvaToPricingTable($event)"></app-architect-bills>
        </div>
        <div id='parrain' class="tab-pane fade in" [ngClass]="{active : currentPage==='parrain'}">
          <app-archi-parrainage></app-archi-parrainage>
        </div>
        <!-- <div id="bills" class="tab-pane fade in" [ngClass]="{active : currentTab==='bills'}">
        </div> -->
        <!-- <div id="users" class="tab-pane fade in" [ngClass]="{active : currentTab==='users'}"> -->
        <!-- </div> -->
      </div>
    </div>

    <div class="col-md-12" style="padding-bottom: 24px; background-color: #f5f7fb;"  *ngIf="(archiId != '') && currentPage=='perso'">
      <div class="btn-container">
        <button [disabled]="loading" (click)="cancelChanges()" class="ag-btn-secondary" style="padding: 9px 24px 9px 24px;">
          Annuler les modifications
        </button>    
        <button [disabled]="loading" (click)="updateAgencyOrUser()" href="#" class="ag-btn-primary" style="padding: 9px 24px 9px 24px;margin-left: 12px;">
        <div *ngIf="!loading">Enregistrer les modifications</div>
        <app-spinner *ngIf="loading"></app-spinner></button>
      </div>
    </div>
    <div class="row">

      <app-architect-emails-tempaltes-editor *ngIf="currentPage=='email-templates'"
        [architectName]="agencyArchi.name"
        [emailsTemplatesSenderName]="emailsTemplatesSenderName"
        [emailsTemplates]="emailsTemplates"
        (updateEmailTemplates)="updateArchitectEmailTemplates($event)"
      ></app-architect-emails-tempaltes-editor>
      
    </div>
    
  </div>

</div>

<div id="check-done"
    class="modal"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="check-donemodal-container">
        <div class="success-checkmark">
          <div class="check-icon">
            <span class="icon-line line-tip"></span>
            <span class="icon-line line-long"></span>
            <div class="icon-circle"></div>
            <div class="icon-fix"></div>
          </div>
        </div>
        <div class="center">
          <h2>
            Votre licence a bien été mise à jour.
          </h2>
          <div class="subtite">
            Si la mise à jour n'est pas visible sur votre application n'hésitez à rafraichir la page.
            Si cela persiste, contactez nous à l'adresse bonjour&#64;aglo.ai
          </div>
        </div>
      </div>
    </div>
</div>
