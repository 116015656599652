<div class="modal fade" id="create-user" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body padding42-bottom20">
          <div class="title-text">
           <h3> Création d'un utilisateur</h3>
        </div>
        <form [formGroup]="subscriptionForm">
            <p class="padding-top-10 margin-bottom-0 bold">Nom</p>
            <input type="text" formControlName="lastName"  type="text" class="form-control" placeholder="Nom"> 
            <small *ngIf="formErrors.lastName" class="text-danger">
              {{ formErrors.lastName }}
            </small>
            <p class="padding-top-10 margin-bottom-0 bold">Prenom</p>
            <input type="text" formControlName="firstName"  type="text" class="form-control" placeholder="Prenom"> 
            <small *ngIf="formErrors.firstName" class="text-danger">
              {{ formErrors.firstName }}
            </small>
            <p class="padding-top-10 bold">Email</p>
            <input type="text" formControlName="email"  type="text" placeholder="Email" class="form-control" (focusout)="checkMailExistence()">
            <small *ngIf="formErrors.email" class="text-danger">
              {{ formErrors.email }}
            </small>
            <small *ngIf="emailAlreadyExist" class="text-danger">
              {{ emailAlreadyExist }}
            </small>
            <!-- <p class="padding-top-10 margin-bottom-0">Type d'utilisateur</p>
            <p-radioButton name="isAdmin" [value]="true" formControlName="isAdmin" label="Admin"></p-radioButton>
            <p-radioButton name="isAdmin" [value]="false" formControlName="isAdmin" label="Simple"></p-radioButton> -->
        </form>
        <div class="modal-footer" >
          <div class="flex float-right">
          <button type="button" class="annuler-button" data-dismiss="modal">Annuler</button>
          <button type="button" *ngIf="!loading" class="send-button"  (click)="createUser()" [disabled]="!emailExist || autorise" >Valider</button>
          <button type="button" *ngIf="loading" class="send-button"  [disabled]="autorise">
            <app-spinner *ngIf="loading"></app-spinner>
        </button>
        </div>
       </div>
        </div>
      </div>
  </div>
