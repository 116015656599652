export class ModeClass{
  public modeList = {
    artisan : {
      addPost : false,
      addArtisanPost : true,
      addSousLot : false,
      addLot : false,
      lotcomment : false,
      deleteLot : false,
      deleteSousLot : false,
      deletePost : false,
      moveLot :false,
      movePost :false,
      create:false,
      price:true,
      toFill: true,
      comment : true,
      recap :true,
      lotFiles: false,
      cctp : false,
      option : false,
      cctpDpfg : "Voir",
      priceName : "price",
      pricettcName : "pricettc",
      unitePriceName : "unitePrice",
      totalName : "total",
      discountArtisan: 'discount',
      totalttcName : "totalttc",
      nbLineDone : "nbLineDone",
      estimated : "Chiffré",
      exportPost:false,
      showFlag:false,
      disabled:false
    },
    archi : {
      addPost : true,
      addArtisanPost : false,
      addSousLot : true,
      lotcomment : true,
      addLot : true,
      deleteLot : true,
      deleteSousLot : true,
      deletePost : true,
      moveLot :true,
      movePost :true,
      create:true,
      price:false,
      toFill: false,
      comment : false,
      recap :false,
      lotFiles: true,
      cctp : true,
      option : true,
      cctpDpfg : "Editer",
      priceName : "priceArchi",
      pricettcName : "pricettcArchi",
      unitePriceName : "unitePriceArchi",
      totalName : "totalArchi",
      discountArtisan: 'discount',
      totalttcName : "totalttcArchi",
      nbLineDone : "nbLineDoneArchi",
      estimated : "Estimé",
      exportPost:true,
      showFlag:true,
      disabled:true
    },
    view : {
      addPost : false,
      addArtisanPost : false,
      addSousLot : false,
      addLot : false,
      lotcomment : false,
      deleteLot : false,
      deleteSousLot : false,
      deletePost : false,
      moveLot :false,
      movePost :false,
      create:false,
      price:false,
      toFill: false,
      comment : false,
      recap :false,
      lotFiles: false,
      cctp : false,
      option : false,
      cctpDpfg : "Voir",
      priceName : "price",
      pricettcName : "pricettc",
      unitePriceName : "unitePrice",
      totalName : "total",
      discountArtisan: 'discount',
      totalttcName : "totalttc",
      nbLineDone : "nbLineDone",
      estimated : "Chiffré",
      exportPost:false,
      showFlag:false,
      disabled:false
    }
  }
}
