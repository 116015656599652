import { Injectable } from '@angular/core';
import {  ActivatedRoute, Params, Router} from '@angular/router';
import {
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class GlobalResponseInterceptorInterceptor implements HttpInterceptor {

  constructor(
    private router:Router,
    private route:ActivatedRoute  ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      let currentRoute = window.location.pathname;
      var res = next.handle(request);
      let apiUrl = request.url;
      let apiMethod = request.method;
      
      return res.pipe(
        catchError((error: HttpErrorResponse) => {
          this.handleError(error, currentRoute, apiUrl, apiMethod); // Handle navigation based on error status
          return throwError(error);
        })
      );
    }
  
    private handleError(error: HttpErrorResponse, currentRoute: string, apiUrl:string, requestMethod:string): void {
      
      // when an error occurs on suggestions api don't go to errors page, just throw error on console
      let isSuggestionsAPI = apiUrl.includes("/cstb-suggestions") && requestMethod == "POST";
      
      if (error.status === 451) {
        let userSessionLocation = {
          country: error.error["country"],
          city: error.error["city"],
        };
        localStorage.setItem("userSessionLocation", JSON.stringify(userSessionLocation));
        this.router.navigate(["/no-multi-users"], { replaceUrl: true });
      } else if (error.status === 500 && !isSuggestionsAPI) {
        this.router.navigate(['/server-error'], { queryParams: { returnURL: currentRoute } });
      } else if (error.status === 502) {
        this.router.navigate(['/server-inaccessible'], { queryParams: { returnURL: currentRoute } });
      } else if (error.status === 403 && error.error.message != 'login failed' && error.error.message != "Only demo" && error.error.message!='User Not authorized to use cstb' && !error.error.err.message.includes('You dont have the rights to view')) { 
        this.router.navigate(['/access-denied'], { queryParams: { returnURL: '/' } });
      } else if (error.status === 404 && currentRoute!='/artisan/subscribe'  && error.error.err.message!='Lot has not been found') {
        this.router.navigate(['/**'], { queryParams: { returnURL: '/' } });
      } else if (error.status === 413) {
        this.router.navigate(['/entity-too-large'], { queryParams: { returnURL: currentRoute } });
      }
    }

  // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  //   let currentRoute = window.location.pathname;
  //   var res = next.handle(request);
  //   return res.pipe(
  //     // on Response Succsss
  //     // map(
  //     //   (event: HttpEvent<any>) => {
  //     //     if (event instanceof HttpResponse) {
  //     //         // console.log("response : ", event.headers);
  //     //         // event = event.clone({body: this.modifyBody(event.body)});
  //     //     }
  //     //     return event;
  //     //   }),
  //     // on response Error
  //     catchError((error: HttpErrorResponse) => {
  //       console.log('error',error)
  //       if(error.status === 451){
  //         // console.log("Ip Address : ", error.error.clientIp);

  //         let userSessionLocation = {
  //           country: error.error["country"],
  //           city: error.error["city"],
  //         };
          
  //         localStorage.setItem("userSessionLocation", JSON.stringify(userSessionLocation));

  //         this.router.navigate(["/no-multi-users"], { replaceUrl: true })
  //       } else if(error.status === 500){
  //         this.router.navigate(['/server-error'], { queryParams: { returnURL: currentRoute }});
  //       } else if(error.status === 502){
  //         this.router.navigate(['/server-inaccessible'], { queryParams: { returnURL: currentRoute }});
  //       } else if(error.status === 403 && error.error.message !='login failed'){
  //         this.router.navigate(['/access-denied'], { queryParams: { returnURL: '/' }});
  //       } else if(error.status === 404){
  //         this.router.navigate(['/**'], { queryParams: { returnURL: '/' }});
  //       } else if(error.status === 413){
  //         this.router.navigate(['/entity-too-large'], { queryParams: { returnURL: '/' }});
  //       }
  //       return throwError(error);
  //     })
  //   );        

  // }
 
}

