import { Component, OnInit, Input, Output, SimpleChanges,ChangeDetectorRef, HostListener } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from "@angular/forms";
import { trigger,style,animate,transition,keyframes } from '@angular/animations';

import { PasswordValidation } from "../../_helpers/password-validation";
import { ArtisanService, AuthService } from "../../_services/index";
import { ActivatedRoute } from "@angular/router";
import { MessageService } from 'primeng/api';
import { NotificationsService } from "angular2-notifications";
import { Countries_codes } from "./countries-code";
import { NgSharedModule } from "src/app/all/ngshared.module";
declare var $;

@Component({
    standalone:true,
    selector: "app-artisan-signup-login",
    imports:[
      NgSharedModule
    ],
    providers: [MessageService],
    templateUrl: "./artisan-signup-login.component.html",
    styleUrls: ["./artisan-signup-login.component.scss"],
    animations: [
        trigger('error', [
            transition('* => true', [
                animate('2s', keyframes([
                    style({ backgroundColor: 'transparent' }),
                    style({ backgroundColor: 'rgba(255, 0, 0, 0.15)' }),
                    style({ backgroundColor: 'transparent' })
                ]))
            ]),
        ])
    ],
})
export class ArtisanSignupLoginComponent implements OnInit {
  @Input() artisan: any;
  @Input() disabled: boolean;
  @Input() isSignUp:boolean;
  @Input() hidePassword: boolean = false;
  public subscriptionForm: UntypedFormGroup;
  public validEmail: boolean = true;
  public first: boolean = true;
  public error : string = '';currentUrl;lastName;firstName;selectedCountryCode="";
  public formErrors = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    // password2: ""
  };
  private validationMessages = {
    lastName: {
      required: "Le nom est obligatoire."
    },
    firstName: {
      required: "Le prénom est obligatoire."
    },
    email: {
      required: "Le mail est obligatoire.",
      // email: "Email invalide"
      "email": "",
      'pattern': 'Email invalide' // this is for regex pattern 
    },
    phone: {
      required: "Le Téléphone est obligatoire.",
    },
    password: {
      required: "Le mot de passe est obligatoire.",
      minlength: "Minimum 6 caractères",
      MatchPassword: "Les mots de passe saisis ne sont pas identiques"
    },
    // password2: {
    //   required: "Le mot de passe est obligatoire.",
    //   minlength: "Minimum 6 caractères",
    //   MatchPassword: "Les mots de passe saisis ne sont pas identiques"
    // }
  };
  loadingData: boolean;countries_codes;
  // @HostListener('input', ['$event']) onInputChange(event) {
  //   console.log('event',event)
  //   const input = event.target as HTMLInputElement;
  //   console.log('this.sub',this.subscriptionForm.value.codePhone);
  //   if(input.id === 'phoneId'){
  //   let inputValue = input.value;
  //   // remove all non-numeric characters
  //   console.log('this.sub 2',this.subscriptionForm.value.codePhone);

  //   inputValue = inputValue.replace(/\D/g, '');
  //   // format the input value as a phone number
  //   if(inputValue.length <= 3)inputValue = inputValue.replace(/(\d{3})/, '+$1');
  //   else if(inputValue.length <= 4)inputValue = inputValue.replace(/(\d{3})(\d{1})/, '+$1 $2');
  //   else if(inputValue.length <=6)inputValue = inputValue.replace(/(\d{3})(\d{1})(\d{2})/, '+$1 $2 $3');
  //   else if(inputValue.length <=8)inputValue = inputValue.replace(/(\d{3})(\d{1})(\d{2})(\d{2})/, '+$1 $2 $3 $4');
  //   else if(inputValue.length <=10)inputValue = inputValue.replace(/(\d{3})(\d{1})(\d{2})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5');
  //   else if(inputValue.length >10)inputValue = inputValue.replace(/(\d{3})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5 $6');
  //   input.value = inputValue;
  // }
  // }

  constructor(private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef, 
    private authService: AuthService,
    private artisanService: ArtisanService,
    private route: ActivatedRoute,
    private _notificationService: NotificationsService,
    private messageService: MessageService
    ) {
      this.countries_codes =Countries_codes
  }

  ngOnInit() {
    this.createForm();
    this.subscriptionForm.patchValue(this.artisan || {});
  }

  //Set Archi on creation
  ngOnChanges(changes: SimpleChanges) {
    if (changes["artisan"] && this.subscriptionForm) {
      this.subscriptionForm.patchValue(this.artisan);
    }
  }
  //Create Form
  private createForm() {
    this.subscriptionForm = this.fb.group({
      firstName: [{value: this.firstName,disabled: this.disabled}, Validators.required],
      lastName: [{value: this.lastName,disabled: this.disabled}, Validators.required],
      email: [{value: '',disabled: this.disabled}, [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$")
      ]],
      phone: [{value: '', disabled: this.disabled}, Validators.required],
      password: [{value: '',disabled: this.disabled}, [Validators.required,Validators.minLength(6)]],
      codePhone: [{value: this.countries_codes[0].dial_code,disabled: this.disabled}, [Validators.required]],
      // password2: [{value: '',disabled: this.disabled}, [Validators.required,Validators.minLength(6)]],
      cgu: [{value: false , disabled: this.disabled}, [Validators.required]],
    })
    //  {
    //   validator: PasswordValidation.MatchPassword // your validation method
    // });
    this.subscriptionForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged();
  }
  formatPhoneNumber() {
    const input = document.getElementById("phoneId") as HTMLInputElement;
    if(this.subscriptionForm.value.codePhone.length<=3){
    input.addEventListener("keyup", function(e) {
      if (isNaN(parseInt(e.key)) && e.keyCode !== 8) {
        return;
    }
    input.value = input.value.replace(/\D/g, '').replace(/(.{2})/g, "$1 ").trim();
    });
    }else{
      input.addEventListener("keyup", function(e) {
        if (isNaN(parseInt(e.key)) && e.keyCode !== 8) {
            return;
        }
        input.value = input.value.replace(/\D/g, '').replace(/(.{1})(.{2})/g, "$1 $2").trim();
    });
    }
  }
  //Check email
  public focusOutEmail() {
    if (!this.formErrors.email) {
      this.authService
        .validateEmail(this.subscriptionForm.value.email)
        .subscribe(
          data => {
          if(this.subscriptionForm.value.email==this.artisan.email)  this.validEmail = true;
          else this.validEmail = data;
            //this.scrollToError()
          },
          error => {
            console.log(error);
          }
        );
    } else {
      //this.scrollToError();
    }
  }
  //Generate Error
  private onValueChanged(data?: any) {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = "";
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + " ";
        }
      }
    }
  }

  //Mark as touched
  private markFormAsTouched(): void {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = "";
      const control = form.get(field);
      control.markAsDirty();
      const messages = this.validationMessages[field];
      for (const key in control.errors) {
        this.formErrors[field] += messages[key] + " ";
      }
    }
    this.first = true;
  }
  //Validate Form
  public isValid() {
    this.markFormAsTouched();
    this.focusOutEmail();
    return (
      this.subscriptionForm.valid &&
      this.validEmail &&
      this.subscriptionForm.value.cgu
    );
  }
  public getFormData() {
    return this.subscriptionForm.value;
  }
  public scrollTo(element, offset = 0) {
    $('html, body').animate({
      scrollTop: ($(element).offset().top - offset)
    });
  }
  public scrollToError(){
    let loop = 0;
    let keys = Object.keys(this.formErrors);
    let errorKey = ""
    while(loop < keys.length && this.formErrors[keys[loop]] =="" ){
      loop ++;
    }
  }
  update(){
    if(this.validEmail) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.subscriptionForm.value.phone = this.subscriptionForm.value.dial_code.concat(this.subscriptionForm.value.phone)
      this.subscriptionForm.removeControl('codePhone')
      let artisanData=this.subscriptionForm.value;
      artisanData.artisanId = currentUser.artisanId;
      this.loadingData =true;
      let isValid=this.isValid();
      this.artisanService.updateUserInfo(currentUser.id, artisanData)
      .subscribe(
              data => {
                  this.loadingData = false;
                  artisanData={...currentUser,...artisanData}
                  localStorage.setItem('currentUser', JSON.stringify(artisanData));
                  this._notificationService.success("Merci","Vos modifications ont été enregistrées", {timeOut: 5000})
              },
              error => {
                  console.log("error",error)
              });
    }
    else {
      this._notificationService.error('Formulaire incomplet','Veuillez remplir tous les champs obligatoires', {timeOut: 5000})
    }
  }
}
