import { Component, OnInit, Input, NO_ERRORS_SCHEMA, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  standalone: true,

  selector: 'app-acv-welcome-screen',
  imports:[NgSharedModule],
  templateUrl: './acv-welcome-screen.component.html',
  styleUrls: ['./acv-welcome-screen.component.scss']
})
export class AcvWelcomeScreenComponent implements OnInit {
  @Input() selectedWelcomeScreen: string = 'screen_1';
  @Input() isDemo: Boolean = false;

    constructor(private router: Router) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges){
  }
  redirectToPricingTable(){
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
  }
}
