import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone: true,
    selector: 'app-booster-profile-edit-information-modal',
    imports:[
      NgSharedModule
    ],
    templateUrl: './booster-profile-edit-information-modal.component.html',
    styleUrls: ['./booster-profile-edit-information-modal.component.scss'],
})
export class BoosterProfileEditInformationModalComponent implements OnInit, OnChanges  {

  @Input() artisanData;
  @Output() saveData: EventEmitter<any> = new EventEmitter<any>();

  public travauxList:any = [
    { value: "renovation", name: "Rénovation"},
    { value: "extension", name: "Extension"},
    { value: "surelevation", name: "Surélévation"},
    { value: "construction-neuve", name: "Construction Neuve"},
  ];
  public programmesList:any = [
    { value: "maison", name: "Maison individuelle"},
    { value: "appartement", name: "Appartement"},
    { value: "bureaux-tertiaire", name: "Bureaux"},
    { value: "immeuble", name: "Logements collectifs"},
    { value: "equipements-publics", name: "Équipements publics"},
    { value: "batiment-industriel-agricole", name: "Bâtiments industriels/Agricoles"},
    { value: "boutique-conceptstore", name: "Boutique/Concept store"},
    { value: "cafe-hotel-restaurant", name: "Café/Hôtel/Restaurant"},
  ];

  public selectedTravaux: Array<string> = [];
  public selectedPrograme: Array<string> = [];
  public creationYearAgency: string = "";
  public ca: string = "";

  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.artisanData && Object.keys(changes.artisanData.currentValue).length){
      let data = changes.artisanData.currentValue;
      this.setArtisanSelectedData(data);
    }
  }
  private setArtisanSelectedData(artisanData){
    this.selectedTravaux = [...artisanData["travaux"]];
    this.selectedPrograme = [...artisanData["programme"]];
    this.creationYearAgency = artisanData["creationYearAgency"] || "";
    this.ca = artisanData["ca"] || "";
  }

  public chooseItem(type, itemValue){
    if(type == "type"){
      this.selectedTravaux = this.addOrRemoveFromArray(this.selectedTravaux, itemValue);
    }else if(type == "programme"){
      this.selectedPrograme = this.addOrRemoveFromArray(this.selectedPrograme, itemValue);
    }
  }
  private addOrRemoveFromArray(array, itemValue){
    if(array.includes(itemValue)){
      array = array.filter(item => item != itemValue);
    }else{
      array.push(itemValue);
    }

    return array;
  }

  public emitSaveData(){
    let data = {
      travaux: this.selectedTravaux,
      programme: this.selectedPrograme,
      creationYearAgency: this.creationYearAgency,
      ca: this.ca
    };
    
    this.saveData.emit(data);
  }

}
