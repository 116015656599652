<div id="add-photos-to-album-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="ag-heading-h4 ag-weight-500 no-margin margin-right-10">
          <b>Nom de l’album :</b> 
          {{ albumTitle }} 
        </h4>
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">    
        
        <div class="photos-preview-wrapper horizontal-items" *ngIf="showAlbumPhotos">
          <div class="single-photo margin-right-32" *ngFor="let photo of uploadedPhotos; let i = index;">
            <span class="remove-icon" (click)="removePhoto(i)"><img src="assets/close-icone-black.svg" /></span>
            <img *ngIf="!authService.currentEnvironment" [src]="photo.uploadURL" class="img-dimensions">
            <img *ngIf="authService.currentEnvironment"  [src]="'api/v1/static/'+photo.uploadURL" class="img-dimensions">
          </div>
        </div>

        <!-- <div id="albumPhotosDashboardContainer" [ngStyle]="{'display' : !showAlbumPhotos ? 'block' : 'none' }"></div> -->
        <p-fileUpload [ngStyle]="{'display' : !showAlbumPhotos ? 'block' : 'none' }" name="demo2" url="/api/v1/upload-file" (onUpload)="onUploadMultiPhotos($event)" [multiple]="true" [auto]="true"
        accept="image/*" maxFileSize="1000000">
        </p-fileUpload>
        <div class="submit-btn-container text-center margin-top-30 ">
          <button class="ag-btn-primary-sm" [disabled]="!uploadedPhotos.length" (click)="emitSetAlbumPhotos()">Ajouter les photos</button>
        </div>

      </div>
    </div>
  </div>
</div>
