import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from '../ngshared.module';

@Component({
  standalone:true,
  selector: 'app-archi-expired-gpt-modal',
  imports:[NgSharedModule],
  templateUrl: './archi-expired-gpt-modal.component.html',
  styleUrls: ['./archi-expired-gpt-modal.component.scss']
})
export class ArchiExpiredGptModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
}
