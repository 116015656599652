import { Component, OnInit, Input, NO_ERRORS_SCHEMA } from '@angular/core';
import { PhotosSliderComponent } from '../../photos-slider/photos-slider.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';



declare var $: any;

@Component({
    standalone: true,
    selector: 'app-public-booster-profile-realisations-list',
    imports:[
      NgSharedModule,
      PhotosSliderComponent
    ],
    templateUrl: './public-booster-profile-realisations-list.component.html',
    styleUrls: ['./public-booster-profile-realisations-list.component.scss'],
})
export class PublicBoosterProfileRealisationsListComponent implements OnInit {

  @Input() albums: any;
  @Input() photos: any = [];
  public openedAlbums: any = {
    'realisations': false
  };
  public sliderAlbumPhotos: any = [];
  public sliderPhotoIndex: any = 0;

  constructor() { }

  ngOnInit(): void {
  }

  public openImageInNewTab(imageUrl){
    window.open(imageUrl, '_blank');
  }
  public openAlbumsSlider(albumPhotos, index, type=""){
    this.sliderAlbumPhotos = albumPhotos;
    this.sliderPhotoIndex = index;

    if(type == "photos"){ /// for mes réalisations album
      this.sliderAlbumPhotos = this.sliderAlbumPhotos.map(item => {
        item["uploadURL"] = item["cdnUrl"];

        return item;
      });  
    }

    $("#photos-slider-modal").modal("show");
  }

  public toggleExpandClass(albumId){
    let albumIsOpened = this.openedAlbums[albumId] ? true : false;
    this.openedAlbums[albumId] = !albumIsOpened;

    $(`#${albumId}`).toggleClass("opened");
  }

}
