<div id="chatgpt-regenerate-modal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center margin-bottom-30">
          <img id="not-found-image" src="assets/error.gif" alt="">
        </div>

        <p class="text-center ag-para-1-sm ag-weight-400">J’ai rencontré un souci lors de la génération de votre<br> 
          description, veuillez regénérer la description ou bien<br> changer votre requête </p>
      </div>

      <div class="modal-footer text-center">
        <button type="button" class="ag-btn-secondary-sm margin-right-15" (click)="closeModalOrRedirect()">
          <i class="fa fa-pencil"></i>
          Modifier ma requête
        </button>        
        <button type="button" class="ag-btn-secondary-sm submit-btn" (click)="emitRegenerate()">
          <i class="fa fa-rotate-right"></i>
          Regénérer
        </button>
      </div>
    </div>
  </div>
</div>

