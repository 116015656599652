
<div class="container-fluid">
  <div class="col-md-5 full-height colored-side">
    <div>
      <img class="aglo-logo" src="/assets/rob/aglo-logo-b.png" alt="">
      <h2 class="slogan">
          L’assistant intelligent <br>
          pour votre consultation d’entreprise
      </h2>
    </div>
    <!-- <div class="connect">
      <a [routerLink]="['/login']">Déjà un compte sur Aglo ?</a>
    </div> -->
  </div>
  <div class="col-md-7 full-height ">
    <div class="form">
      <div class="row">
        <div class="col-md-12 progress-text">Informations générales</div>
        <!-- <div class="col-md-6 progress-text">Informations secondaires</div> -->
      </div>
      <div class="progress">
        <div class="progress-bar" style="width: 50%;"></div>
      </div>
      <h1 class="title">Créez votre compte pour accéder à votre invitation</h1>
      <app-form1 [noEmailValidation]="true"  ></app-form1>
      <div class="center">
        <button [disabled]="loading" (click)="register();" href="#" class="300-w btn-primary-color">
          <div *ngIf="!loading">S'inscrire</div>
          <app-spinner *ngIf="loading"></app-spinner>
        </button>
      </div>
    </div>
  </div>
</div>
