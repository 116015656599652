import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import {map} from "rxjs/operators";
import { Helpers } from './helpers'

@Injectable()
export class ProductsService {

  public selectedScrappedProduct: Subject<any> = new Subject<any>();
  public selectedPost: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  searchProducts(body) : Observable<any[]>{
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/products/search`,body, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }

  getPostProducts(postId) : Observable<any[]>{
    let options = new Helpers().getHeader();
    return this.http.get(`/api/v1/products/get-post-products/${postId}`, options)
      .pipe(map((response: any) => {
          return response.data;
      }));

  }

  createPostToProduct(body) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/products/post-to-products`,body,options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }

  deletePostProduct(postId, productRef) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.delete(`/api/v1/products/delete-product/${postId}/ref/${productRef}`, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }

}
