import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { ColorSketchModule } from 'ngx-color/sketch';

//Component
import { DpgfResultDetailsComponent } from './pricing-results/dpgf-result-details/dpgf-result-details.component';


import { StoreModule } from '@ngrx/store';
import { dpgfReducer } from './dce-edition/_store/dpgf.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DpgfEffects } from './dce-edition/_store/dpgf.effects';

import { GlobalResponseInterceptorInterceptor } from 'src/app/_interceptors/global-response-interceptor.interceptor';
import {MatTreeModule} from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgSharedModule } from '../all/ngshared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSharedModule,
    ColorSketchModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    StoreModule.forFeature('dpgf',dpgfReducer),
    EffectsModule.forFeature([DpgfEffects])
  ],
  exports:[
  ],
  declarations: [
    // DpgfResultDetailsComponent, tocorrect
  ],
  // providers: [
  //   { 
  //     provide: HTTP_INTERCEPTORS, useClass: GlobalResponseInterceptorInterceptor, multi:true
  //   },
  // ]
})
export class DpgfModule {
}
