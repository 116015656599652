<div *ngIf="loadingPage">
  <app-spinner></app-spinner>
</div>
<div class="row" *ngIf="!loadingPage && pricingList.length">
  <app-dpgf-result-tabs-navbar></app-dpgf-result-tabs-navbar>
</div>
<!-- No Pricing Image -->
<div style="margin-left: 80px;" *ngIf="!loadingPage && !pricingList.length">
  <div class="container" style="margin-top: 70px">
    <div class="empty-box">
      <h3 class="center">Vous n'avez pas encore reçu de réponse à votre consultation !</h3>
      <img src="https://media.giphy.com/media/tXL4FHPSnVJ0A/giphy.gif" alt="" height="400" class="empty-devis">
    </div>
  </div>
</div>
<!-- END No Pricing Image -->

<div *ngIf="!loadingPage && pricingList.length">
<div id="result-summary">
  <div id="head" class="row sticky floating-card">
    <div class=" head-float">
    <!--******************** Total ***********************-->
      <div class="row">
        <div class="line-container padding-top-10">
          <div class="title-no-follow">Entreprises</div>
          <div class="center artisan" *ngIf="!filteredArtisans.includes('estim')">
            <span class="artisan-name">Estimatifs</span>
            <div class="colored-box" [ngStyle]="{'background-color' : 'lightblue' }"></div>
            <br> <span class="small-gray">Ind.{{dpgf.currentVersion | pricingIndex}}</span>
          </div>
          <ng-container *ngFor="let artisan of pricingList; let i = index">
            <div class="center artisan" *ngIf="!filteredArtisans.includes(artisan.artisanIndex)">
              <div class="dropdown dropdown-list">
                    <i (click)="getDevis(i)" class="fa fa-print cliquable" aria-haspopup="true" aria-expanded="true"></i>
              </div>
              
              <!-- Factice Artisan Name -->
              <span class="artisan-name" title="Voir details" (click)="onArtisanNameClick(i)" *ngIf="artisan?.ceProjectInvite?.isFactice">
                {{ artisan?.ceProjectInvite?.facticeArtisanInfo?.name || artisan?.artisanName }}
              </span>
              <!-- Real Artisan Name -->
              <span class="artisan-name" title="Voir details" (click)="onArtisanNameClick(i)" *ngIf="!artisan?.ceProjectInvite?.isFactice">
                {{ artisan?.artisan?.name ? artisan.artisan.name :  artisan.artisanName }}
              </span>

              <div class="colored-box" [ngStyle]="{'background-color' : colors[i] }"></div>
              <br> <span class="small-gray">Ind.{{artisan.currentVersion | pricingIndex}}</span>
            </div>
          </ng-container>
        </div>
        <div class="line-container">
          <div class="title-no-follow">Total</div>
          <div *ngIf="!filteredArtisans.includes('estim')" class="center artisan">
            <ng-container *ngIf="viewingVariante">
              <span class="price-tag" *ngIf="ht">{{dpgf.totalHt | number:'1.0-0':'fr' | priceUnite:'€ HT'}}</span>
            </ng-container>
            <ng-container *ngIf="!viewingVariante">
              <span class="price-tag" *ngIf="ht">{{dpgf.totalHt | number:'1.0-0':'fr' | priceUnite:'€ HT'}}</span>
              <span class="price-tag" *ngIf="!ht">{{dpgf.totalTtc | number:'1.0-0':'fr' | priceUnite:'€ TTC'}}</span>
            </ng-container>
          </div>
          <ng-container *ngFor="let artisan of pricingList">
            <div *ngIf="!filteredArtisans.includes(artisan.artisanIndex)" class="center artisan">
              <ng-container *ngIf="viewingVariante">
                <span class="price-tag" *ngIf="ht">{{artisan.totalVariante | number:'1.0-0':'fr' | priceUnite:'€ HT'}}</span>
              </ng-container>
              <ng-container *ngIf="!viewingVariante">
                <span class="price-tag" *ngIf="ht">{{artisan.total | number:'1.0-0':'fr' | priceUnite:'€ HT'}}</span>
                <span class="price-tag" *ngIf="!ht">{{artisan.totalTtc  | number:'1.0-0':'fr' | priceUnite:'€ TTC'}}</span>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="true" style="height:120px"></div>
  <div class="summary-wrapper">

    <div class="card-overlay" *ngIf="showSubmitButton">
      <div class="text-cont">
        <p>Relancez le calcul pour mettre à jour la variante</p>
          <app-spinner *ngIf="creatingVariante"></app-spinner>
          <button class="save-variante-btn margin-left-10" *ngIf="!creatingVariante" (click)="saveOrUpdateVariante()">
          <span class="ag-tooltip-1">Enregistrer et recalculer</span>
          <img src="/assets/variante_save_icon.svg" alt="">
        </button>
      </div>
    </div>

    <ng-container *ngFor="let lot of showedData,let loti = index">
      <div *ngIf="!filteredLots.includes(lot.id)" [ngClass]="['row floating-card lot-container', lot.id]"
        [@showHide]="hiddenLots.includes(lot.id) ? 'out' : 'in'">
        <div class="col-md-12" style="background: white;">
          <!--********************Lot ***********************-->
          <div class="line-container">
            <div class="title-flex lot_title_container">
              <span class="collapse-lot" (click)="toggleLot(lot.id, loti)">
                <i class="fa fa-caret-right"></i>
              </span>
              <span class="lot-pill" [ngStyle]="{'background-color': lot.color}">
                {{lot.indexLot}} - {{lot.title}}
              </span>
            </div>
            <!-- Estimatif Lot -->
            <div *ngIf="!filteredArtisans.includes('estim')" class="center artisan" >
              <span class="price-tag lot-price-tag">
                {{ lot.totalArchi | number:'1.0-0':'fr'  | priceUnite:'€'}}
              </span>

            </div>
            <!-- Chiffrages Lot -->
            <ng-container *ngFor="let price of lot.priceList; let i = index;">
              <!-- *ngIf="!filteredArtisans.includes('artisan'+i)" -->
              <div class="center artisan">
                <ng-container *ngIf="viewingVariante">
                  <span class="price-tag lot-price-tag"
                        *ngIf="ht"
                        [ngStyle]="{color : (price||{}).isMin?'green':(price||{}).isMax?'red':'black'}">
                        {{(price||{}).totalVariante | number:'1.0-0':'fr'  | priceUnite:'€'}}
                  </span>
                </ng-container>
                <ng-container *ngIf="!viewingVariante">
                  <span class="price-tag lot-price-tag"
                        *ngIf="ht"
                        [ngStyle]="{color : (price||{}).isMin?'green':(price||{}).isMax?'red':'black'}">
                        {{(price||{}).total | number:'1.0-0':'fr'  | priceUnite:'€'}}
                  </span>
                  <span class="price-tag lot-price-tag"
                        *ngIf="!ht"
                        [ngStyle]="{color : (price||{}).isMin?'green':(price||{}).isMax?'red':'black'}">
                        {{(price||{}).totalTtc | number:'1.0-0':'fr'  | priceUnite:'€'}}
                  </span>
              </ng-container>
              </div>
            </ng-container>
          </div>

          <ng-container *ngIf="lot.sousLots">
          <div class="sous-lot" *ngFor="let souslot of lot.sousLots,let sousloti = index"
            [ngClass]="souslot.fixed ? 'fixed-sous-lot' : 'section-sous-lot'">
            <!--******************** SOus Lot ***********************-->
            <div *ngIf="!souslot.fixed" class="line-container">
              <div class="title-flex">
                <span [ngClass]="['lot-pill', souslot.fixed ? '' : 'underlined']" [ngStyle]="{'background-color': lot.secondColor}">
                  {{souslot.title}}
                </span>
              </div>
              <!-- Estimatif souslots -->
              <div *ngIf="!filteredArtisans.includes('estim')" class="center sous-lot-price artisan">
                  <span class="price-tag">
                    {{souslot.totalArchi | number:'1.0-0':'fr'  | priceUnite:'€'}}
                  </span>
                <ng-template #emptySousLot>
                  -
                </ng-template>
              </div>
              <!-- Chiffrages souslots -->
              <ng-container *ngFor="let price of souslot.priceList; let i = index;">
                <div *ngIf="!filteredArtisans.includes('artisan'+i)" class="center sous-lot-price artisan">
                  <div *ngIf="(price||{}).total != '-'; else emptySousLot">
                    <ng-container *ngIf="viewingVariante">
                      <span class="price-tag" *ngIf="ht">{{(price||{}).totalVariante | number:'1.0-0':'fr'  | priceUnite:'€'}}</span>
                    </ng-container>
                    <ng-container *ngIf="!viewingVariante">
                      <span class="price-tag" *ngIf="ht">{{(price||{}).total | number:'1.0-0':'fr'  | priceUnite:'€'}}</span>
                      <span class="price-tag" *ngIf="!ht">{{(price||{}).totalTtc | number:'1.0-0':'fr'  | priceUnite:'€'}}</span>
                    </ng-container>
                  </div>
                  <ng-template #emptySousLot>
                    -
                  </ng-template>
                </div>
              </ng-container>
            </div>

            <div *ngFor="let poste of souslot.postes;let posti = index" class="poste">
              <!--******************** Poste ***********************-->
              <div class="line-container">
                <div *ngIf="poste.isAtisan" class="worker"></div>
                <div class="title-flex" [ngStyle]="{'color':poste.color}">
                  <div class="post-content">
                    <span>
                      <i *ngIf="poste.comment.length != 0" class="fa fa-commenting-o" aria-hidden="true" (click)="selectCom($event,poste.comment,op)"></i>
                    </span>
                    <i *ngIf="poste.option" class="fa fa-flag" pTooltip="Option" tooltipPosition="top" aria-hidden="true"></i>
                    {{lineNumber(lot.indexLot,sousloti,posti)}} <span innerHTML="{{poste.designation | linebreak}}"></span>

                  </div>
                </div>
                <!-- Estimation posts -->
                <div class="center artisan" *ngIf="!filteredArtisans.includes('estim')" [ngStyle]="{'color' : ((creatingVarianteMode || showingVariante ) && checkedLots[lot.id+'-'+poste.id]) ? '#D1D1D1' : '' }">
                    <span *ngIf="!lot.priceAll; else hiddenPrice">
                      {{ poste.priceArchi | formatPrice | priceUnite:'€'}}
                      <span class="gte-tag" *ngIf="!poste.isAtisan">
                        ({{ poste.qte }}) 
                      </span>
                    </span>
                    <ng-template #hiddenPrice>
                      <span>-</span>
                    </ng-template>


                  <ng-template #emptyPoste>
                    -
                  </ng-template>
                </div>
                  <!-- Chiffrage posts -->
                <ng-container *ngFor="let price of poste.priceList; let i = index">
                  <div class="center artisan" *ngIf="!filteredArtisans.includes('artisan'+i)">
                    <div *ngIf="(price||{}).total != '-'; else emptyPoste" [ngStyle]="{'color' : ((creatingVarianteMode || showingVariante ) && checkedLots[lot.id+'-'+poste.id]) ? '#D1D1D1' : '' }">
                      <span class="price-tag" *ngIf="ht ">{{(price||{}).total | formatPrice | priceUnite:'€'}}</span>
                      <span class="price-tag" *ngIf="!ht">{{(price||{}).totalTtc | formatPrice | priceUnite:'€'}}</span>
                      <span class="gte-tag" 
                        [ngClass]="{'orange-text': (price||{}).qte != poste.qte}"
                        [ngStyle]="{'color' : ((creatingVarianteMode || showingVariante ) && checkedLots[lot.id+'-'+poste.id]) ? '#D1D1D1' : '' }">
                        ({{(price||{}).qte}})
                      </span>
                    </div>
                    <ng-template #emptyPoste>
                      -
                    </ng-template>
                  </div>
                </ng-container>
              </div>
              <div *ngFor="let sousPoste of poste.sousPostes;let sousPosti = index" class="poste">
                <div class="line-container">
                  <div *ngIf="sousPoste.isAtisan" class="worker"></div>
                  <div class="title-flex" [ngStyle]="{'color':poste.color}">
                    <div class="post-content">
                      <span>
                        <i *ngIf="poste.comment.length != 0" class="fa fa-commenting-o" aria-hidden="true" (click)="selectCom($event,poste.comment,op)"></i>
                      </span>
                      <i *ngIf="sousPoste.option" class="fa fa-flag" pTooltip="Option" tooltipPosition="top" aria-hidden="true"></i>
                      {{lineNumber(lot.indexLot,sousloti,sousPosti)}} <span innerHTML="{{sousPoste.designation | linebreak}}"></span>
                    </div>
                  </div>
                  <div class="center artisan" *ngIf="!filteredArtisans.includes('estim')" [ngStyle]="{'color' : ((creatingVarianteMode || showingVariante ) && checkedLots[lot.id+'-'+poste.id]) ? '#D1D1D1' : '' }">
                      <span *ngIf="!lot.priceAll; else hiddenPrice">
                        {{ sousPoste.priceArchi | formatPrice | priceUnite:'€'}}
                        <span class="gte-tag" *ngIf="!sousPoste.isAtisan">
                          ({{ sousPoste.qte }}) 
                        </span>
                      </span>
                      <ng-template #hiddenPrice>
                        <span>-</span>
                      </ng-template>
                    <ng-template #emptyPoste>
                      -
                    </ng-template>
                  </div>
                    <!-- Chiffrage posts -->
                  <ng-container *ngFor="let sousPostePrice of sousPoste.priceList; let i = index">
                    <div class="center artisan">
                      <div *ngIf="!filteredArtisans.includes('estim')" [ngStyle]="{'color' : ((creatingVarianteMode || showingVariante ) && checkedLots[lot.id+'-'+poste.id]) ? '#D1D1D1' : '' }">
                        <span class="price-tag" *ngIf="ht ">{{(sousPostePrice||{}).total | priceUnite:'€'}}</span>
                        <span class="price-tag" *ngIf="!ht">{{(sousPostePrice||{}).price | priceUnite:'€'}}</span>
                        <span class="gte-tag" 
                          [ngClass]="{'orange-text': (sousPostePrice||{}).qte != poste.qte}"
                          [ngStyle]="{'color' : ((creatingVarianteMode || showingVariante ) && checkedLots[lot.id+'-'+poste.id]) ? '#D1D1D1' : '' }">
                          ({{(sousPostePrice||{}).qte}})
                        </span>
                      </div>
                      <ng-template #emptyPoste>
                        -
                      </ng-template>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          </ng-container>
<!-- HERE  -->

        </div>
      </div>
    </ng-container>
    <div id="fixed-bar" [class.shadow-right]="shadowRight">

      <div class="row sticky floating-card" [class.shadow-right]="shadowRight">

        <div class=" head-float">
          <!-- Variantes -->
          <div class="row variantes-wrapper">
            <p class="padding-top-10">Variante: </p>
            <div class="horizontal-items">
                <div class="variantes-items margin-left-10">
                  <app-spinner *ngIf="creatingVariante"></app-spinner>
                  <div class="dropdown" *ngIf="!creatingVariante">
                    <button class="btn btn-default dropdown-toggle current-var-title" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      <span class=" padding-right-10">{{ selectedVarianteTitle || "Initiale" }}</span>
                      <i class="caret"></i>
                    </button>
                    <ul class="dropdown-menu variantes-dropdown" aria-labelledby="dropdownMenu1">
                      <li class="single-var-item" (click)="loadInitialPricings()">
                        <p class="pointer">Initiale</p>
                      </li>
                      <li class="single-var-item" *ngFor="let variante of variantesList; let i = index">
                        <p class="pointer" (click)="selectVariante(i)">{{variante.title}}</p>
                        <div class="action-btns">
                          <span class="margin-right-5 pointer" (click)="openUpdateVarianteModel(variante)" data-toggle="modal" data-target="#renameVarianteModal"><i class="fa fa-edit"></i></span>
                          <span class="pointer" (click)="openDeleteVarianteModel(variante)" data-toggle="modal" data-target="#deleteVarianteModal"><i class="fa fa-times"></i></span>
                        </div>
                      </li>
                      <!-- <li class="single-var-item new-variante pointer no-margin" data-toggle="modal" data-target="#newVarianteModal"> -->
                      <li *ngIf="!dpgf?.demo" class="single-var-item new-variante pointer no-margin" data-toggle="modal" (click)="openCreateVariante()">
                        <i class="fa fa-plus margin-right-5"></i> Créer une variante
                      </li>
                    </ul>
                  </div>


                </div>
                <ng-container *ngIf="!creatingVariante">
                  <button class="save-variante-btn margin-left-10" *ngIf="showSubmitButton" (click)="saveOrUpdateVariante()">
                    <span class="ag-tooltip-1">Enregistrer et recalculer</span>
                    <img src="/assets/variante_save_icon.svg" alt="">
                  </button>
                </ng-container>
                <div class="download-excel-container margin-left-10">
                  <button (click)="downloadFile()" [disabled]="downloadingExcel">
                    <!-- <i *ngIf="!downloadingExcel" class="fa fa-file-excel-o" aria-hidden="true"></i> -->
                    <i *ngIf="downloadingExcel" class="fa fa-spinner excel-spinner" aria-hidden="true"></i>
                    <img *ngIf="!downloadingExcel" src="/assets/icon_button.svg" />
                    Télécharger (.xls)
                    </button>
                </div>
                <!-- <button class="save-variante-btn margin-left-10" *ngIf="showSubmitButton()" (click)="saveOrUpdateVariante()"><i class="fa fa-download"></i></button> -->
              </div>
          </div>
          <!-- END Variantes -->
          <div class="row">
            <div class="line-container filters-container">
              <span class="filters-title">Filtrer : </span>
              <div class="btn-group filter-container">
                  <button type="button" class="filter-btn dropdown-toggle" data-toggle="dropdown">
                    <i class="fa fa-filter"></i> Entreprises
                  </button>
                  <ul class="dropdown-menu filter-content ag-silver-border scroller-1-y" role="menu">
                    <li (click)="onArtisanFilterGlobal('check')">
                      <label for="">
                        <input type="checkbox" [(ngModel)]="artisansFilterChecked">
                        Toutes les entreprises
                      </label>
                    </li>
                    <li>
                      <label for="estim">
                          <input type="checkbox" [checked]="filteredArtisans.indexOf('estim') === -1"
                            value="estim" id="estim" (change)="onArtisanFilterChange($event)">
                            Estimatifs
                          <div class="colored-box" [ngStyle]="{'background-color' : 'lightblue' }"></div>
                        </label>
                      </li>
                    <li class="cb" *ngFor="let pricing of pricingList; let i = index">
                      <label [for]="pricing._id + '|'+i">
                        <input type="checkbox" [checked]="filteredArtisans.indexOf(pricing.artisanIndex) === -1"
                         [value]="pricing.artisanIndex" [id]="pricing._id+'|'+i" (change)="onArtisanFilterChange($event)">
                        {{ pricing.artisanName }}
                        <div class="colored-box" [ngStyle]="{'background-color' : colors[i] }"></div>
                      </label>
                    </li>

                  </ul>
                </div>
                <div class="btn-group filter-container">
                  <button type="button" class="filter-btn dropdown-toggle" data-toggle="dropdown">
                    <i class="fa fa-filter"></i> Lots
                  </button>
                  <ul class="dropdown-menu filter-content scroller-1-y" role="menu">
                    <li (click)="onLotFilterGlobal('check')">
                      <label for="">
                        <input type="checkbox" [(ngModel)]="lotsFilterChecked">
                        Tous les lots
                      </label>
                    </li>
                    <li class="cb" *ngFor="let lot of showedData; let i = index;">
                      <label [for]="lot.id + '|' + i">
                        <input type="checkbox" [checked]="filteredLots.indexOf(lot.id) === -1"
                         [value]="lot.id" [id]="lot.id + '|' + i" (change)="onLotFilterChange($event)">
                        {{ lot.title }}
                      </label>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let lot of showedData,let loti = index">
        <div [ngClass]="['row floating-card lot-container', lot.id]" *ngIf="!filteredLots.includes(lot.id)"
          [@showHide]="hiddenLots.includes(lot.id) ? 'out' : 'in'">
          <div class="col-md-12" style="background: white; padding-right: 0;">
            <!--********************Lot ***********************-->
            <div class="line-container">
              <div class="title-flex lot_title_container">
                <span class="collapse-lot" (click)="toggleLot(lot.id, loti)">
                  <i class="fa" [ngClass]="{'fa-caret-right' : hiddenLots.includes(lot.id) , 'fa-caret-down' : !hiddenLots.includes(lot.id)}"></i>
                </span>
                <!-- cross icon -->
                <span [ngClass]="['times-icon', selectedLots[lot.id] ? 'checked' : '']" (click)="checkEntireLot(lot)" *ngIf="(!showingVariante && creatingVarianteMode)">
                  <i class="fa fa-close"></i>
                </span>

                <span class="lot-pill" [ngStyle]="{'background-color': lot.color}">
                  {{ lot.indexLot }} - {{ lot.title }}
                </span>


                <div [id]="'loader-'+loti" class="loader-container hidden">
                  <app-spinner></app-spinner>
                </div>
              </div>
            </div>
            <ng-container *ngIf="lot.sousLots">
            <div class="sous-lot" *ngFor="let souslot of lot.sousLots,let sousloti = index"
             [ngClass]="souslot.fixed ? 'fixed-sous-lot' : 'section-sous-lot'">
              <!--******************** SOus Lot ***********************-->
              <div *ngIf="!souslot.fixed" class="line-container">
                <!-- lot-checked -->
                <div class="title-flex">

                  <!-- cross icon -->
                  <span *ngIf="(!showingVariante && creatingVarianteMode)">
                    <span [ngClass]="['times-icon', selectedLots[souslot.id] ? 'checked' : '']" (click)="checkEntireSousLot(lot.id, souslot)">
                      <i class="fa fa-close"></i>
                    </span>

                  </span>

                  <span [ngClass]="['lot-pill', 'check-lot-title', souslot.fixed ? '' : 'underlined',  ]" [ngStyle]="{'background-color': lot.secondColor}">
                    {{souslot.title}}
                  </span>

                </div>
              </div>
              <div *ngFor="let poste of souslot.postes;let posti = index" class="poste">
                <!--******************** Poste ***********************-->
                <div class="line-container">
                  <div *ngIf="poste.isAtisan" class="worker"></div>
                  <div class="title-flex"  [ngStyle]="{'color':poste.color}">
                    <div class="post-content">
                      <span style="width:15px;display: inline-block;color: #6e74e8;">
                        <i *ngIf="poste.comment.length != 0" class="fa fa-commenting-o" aria-hidden="true" (click)="selectCom($event,poste.comment,op)"></i>
                      </span>
                      <i *ngIf="poste.option" class="fa fa-flag" pTooltip="Option" tooltipPosition="top" aria-hidden="true"></i>
                      <span class="checkbox-pos-fix" *ngIf="(!showingVariante && creatingVarianteMode)">
                        <!-- Checked post -->
                        <span *ngIf="checkedLots[lot.id+'-'+poste.id]" (click)="toggleVariantePost(lot.id, poste.id)" class="checked-poste-times"><i class="fa fa-times"></i></span>
                        <!-- Unchecked post -->
                        <span *ngIf="!checkedLots[lot.id+'-'+poste.id]" (click)="toggleVariantePost(lot.id, poste.id)" class="uncheck-poste-times"><i class="fa fa-times"></i></span>
                      </span>

                      <span [ngStyle]="{'color' : ((creatingVarianteMode || showingVariante ) && checkedLots[lot.id+'-'+poste.id]) ? '#D1D1D1' : '' }">{{lineNumber(lot.indexLot,sousloti,posti)}}</span>
                      <span [ngStyle]="{'color' : ((creatingVarianteMode || showingVariante ) && checkedLots[lot.id+'-'+poste.id]) ? '#D1D1D1' : '' }" innerHTML="{{poste.designation | linebreak}}"></span>
                      <!-- <span class="annotation" *ngFor="let comment of poste.comment" [ngStyle]="{'color':comment.color}">
                        <i class="fa fa-commenting-o" aria-hidden="true"></i> {{comment.com}}
                      </span> -->
                    </div>
                  </div>
                </div>
                <div *ngIf="poste.sousPostes"></div>
                <div *ngFor="let sousPostes of poste.sousPostes;let sousPosti = index" class="poste">
                  <!--******************** Poste ***********************-->
                  <div class="line-container">
                    <div *ngIf="sousPostes.isAtisan" class="worker"></div>
                    <div class="title-flex"  [ngStyle]="{'color':sousPostes.color}">
                      <div class="post-content">
                        <span style="width:15px;display: inline-block;color: #6e74e8;">
                          <i *ngIf="poste.comment.length != 0" class="fa fa-commenting-o" aria-hidden="true" (click)="selectCom($event,sousPostes.comment,op)"></i>
                        </span>
                        <i *ngIf="sousPostes.option" class="fa fa-flag" pTooltip="Option" tooltipPosition="top" aria-hidden="true"></i>
                        <span class="checkbox-pos-fix" *ngIf="(!showingVariante && creatingVarianteMode)">
                          <!-- Checked post -->
                          <span *ngIf="checkedLots[lot.id+'-'+sousPostes.id]" (click)="toggleVariantePost(lot.id, sousPostes.id)" class="checked-poste-times"></span>
                          <!-- Unchecked post -->
                          <span *ngIf="!checkedLots[lot.id+'-'+sousPostes.id]" (click)="toggleVariantePost(lot.id, sousPostes.id)" class="uncheck-poste-times"></span>
                        </span>
  
                        <span [ngStyle]="{'color' : ((creatingVarianteMode || showingVariante ) && checkedLots[lot.id+'-'+poste.id]) ? '#D1D1D1' : '' }">{{lineNumber(lot.indexLot,sousloti,posti,sousPosti)}}</span>
                        <span [ngStyle]="{'color' : ((creatingVarianteMode || showingVariante ) && checkedLots[lot.id+'-'+poste.id]) ? '#D1D1D1' : '' }" innerHTML="{{sousPostes.designation | linebreak}}"></span>
                        <!-- <span class="annotation" *ngFor="let comment of poste.comment" [ngStyle]="{'color':comment.color}">
                          <i class="fa fa-commenting-o" aria-hidden="true"></i> {{comment.com}}
                        </span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </ng-container>
            <!-- HERE -->

          </div>
        </div>
    </ng-container>
    </div>
  </div>
</div>
</div>
<!-- Print dpgfPricing lots  Popup -->
<div id="print-dpgf-devis-popup"
      tabindex="-1"
      class="modal"
      role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <app-dpgf-devis-popup [dpgfPricing]="selectedDpgfPricing" (closeModal)="closeDevisPopup()"></app-dpgf-devis-popup>
  </div>
</div>
<!-- END Print dpgfPricing lots  Popup -->

<!-- New Variante Popup -->
<div class="modal fade" id="newVarianteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel">Créer une nouvelle variante</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="padding-right-20">
          <input type="text" placeholder="Titre de variante" class="std-input" [(ngModel)]="newVarianteTitle">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
        <button type="button" class="ag-btn-primary-sm margin-left-10" (click)="createNewVariante()" [disabled]="newVarianteTitle == ''">Créer une variante</button>
      </div>
    </div>
  </div>
</div>
<!-- END New Variante Popup -->

<!-- Delete Variante Popup  -->
<div class="modal fade" id="deleteVarianteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel">Suppression de variante</h4>
      </div>
      <div class="modal-body">
        <p>Êtes-vous sûr(e) de vouloir supprimer cette variante ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
        <button type="button" class="ag-btn-primary-sm margin-left-10" [disabled]="deletingVariante" (click)="deleteVariante()">Supprimer la variante</button>
      </div>
    </div>
  </div>
</div>
<!-- END Delete Variante Popup  -->

<!-- Rename Variante Popup  -->
<div class="modal fade" id="renameVarianteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel">Renommer une variante</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="padding-right-20">
          <input type="text" placeholder="Titre de variante" class="std-input" [(ngModel)]="updateVarianteTitle">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
        <button type="button" class="ag-btn-primary-sm margin-left-10" (click)="renameVariante()" [disabled]="updateVarianteTitle == ''">Renommer la variante</button>
      </div>
    </div>
  </div>
</div>
<!-- END Rename Variante Popup  -->



<p-overlayPanel #op>
  <span class="annotation" *ngFor="let comment of toggleComments" [ngStyle]="{'color':comment.color}">
    <i class="fa fa-commenting-o" aria-hidden="true"></i> {{comment.com}}
  </span>
</p-overlayPanel>
