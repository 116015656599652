import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// import { DpgfModule } from '../../app/dpgf/dpgf.module'; 'router-outlet' is not a known element

//Ngrx 
import { StoreModule } from '@ngrx/store';
import { ChantierReducer } from './_store/chantier.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ChantierEffects } from './_store/chantier.effects';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        // DpgfModule,
        StoreModule.forFeature('chantier', ChantierReducer),
        EffectsModule.forFeature([ChantierEffects]),
    ],
    exports: [
    ]
})
export class ChantierModule { }
