import { Component, OnInit, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { Router } from '@angular/router';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $ :any;
@Component({
  standalone:true,
  selector: 'app-chatgpt-regenerate-modal',
  imports:[NgSharedModule],
  templateUrl: './chatgpt-regenerate-modal.component.html',
  styleUrls: ['./chatgpt-regenerate-modal.component.scss']
})
export class ChatgptRegenerateModalComponent implements OnInit {

  @Input() redirectToChatGptPage: boolean = false;
  @Output() regenerate: EventEmitter<any> = new EventEmitter<any>();
  @Output() modifyRequest: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  public closeModalOrRedirect(){
    if(this.redirectToChatGptPage){
      this.router.navigate(['/architect/aglo-GPT']);
      $('.modal').modal('hide');
    }else{
      $('#chatgpt-regenerate-modal').modal('hide');
    }
  }

  public emitRegenerate(){
    this.regenerate.emit();
  }

}
