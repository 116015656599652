import { Component, OnInit, Input, ViewChild, HostListener, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute , Params,Router} from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { saveAs } from 'file-saver';
import { DpgfPostesService, ErrorService, ArchitectService } from 'src/app/_services';
import { downloadZip } from "../../../../../node_modules/client-zip/index.js"
import { DpgfResultComponent } from '../dpgf-result/dpgf-result.component';
import { DpgfModule } from '../../dpgf.module';
import { DpgfResultTabsNavbarComponent } from '../dpgf-result-tabs-navbar/dpgf-result-tabs-navbar.component';
import { DpgfDevisPopupComponent } from '../dpgf-lots-popup/dpgf-devis-popup.component';
import { environment } from 'src/environments/environment';
declare var JSZip;
declare var $:any;
@Component({
  standalone:true,
  selector: 'app-dpgf-result-details',
  imports:[
    DpgfModule,
    DpgfResultTabsNavbarComponent,
    DpgfDevisPopupComponent,
    NgSharedModule
  ],
  templateUrl: './dpgf-result-details.component.html',
  styleUrls: ['./dpgf-result-details.component.scss']
})
export class DpgfResultDetailsComponent implements OnInit {
  public dpgfId:string;
  public selectedAgency:number = -1;
  public curentVersion:number = 0;
  public detailDPgf:any = [];
  public pricing:any = {};
  public selectedFiles:any =  [];
  public versions:any =  [];
  public loadingPage: boolean = true;
  public loading: boolean = false;
  public devis:any;
  public selectedDpgfPricing: any = {};versionId;
  public showDownloadDevis: boolean = false;downloadStarted:boolean = false;
  
  public pricingList: any = [];
  public computedDPGF: any = [];
  public curentIndice:number;
  public dpgf:any;
  public subscriptions: Array<Subscription> = [];

  constructor(private dpgfPostesService: DpgfPostesService,
              private route: ActivatedRoute,
              private router: Router,
              private ErrorService: ErrorService,
              private architectService:ArchitectService
            ) { }

  ngOnInit() {
    this.getData();
  }
  public getCurrentRoute(){
    this.route.queryParams.subscribe(params => {
      this.devis = params['devis'];
      if(this.devis){
        this.pricingList.find((item, index) => {
          if(item._id == this.devis){
            this.getPricingList(item, index);
          }
        });
      }
  });
  }

  public getDetail(index){
    let selectedArtisanVersions = this.pricingList[this.selectedAgency].versions;
    let lastItem = selectedArtisanVersions[selectedArtisanVersions.length - 1];
    this.loadPricing(lastItem, index, true);
  }
  public getPricing(pricing, index){
    this.pricing = pricing;
    this.detailDPgf =this.sortLots(this.mergeDPGFAndPricing(pricing.data)) ;
    this.selectedFiles = pricing.artisanFiles

    this.loading = false;
  }
  public sendSegment(){
    if(!environment.onPremise)window.analytics.track('download_pricing_file',{properties:{acValue: 'download_pricing_file'}});
  }
  public slugify (str) {
    var map = {
        'a' : 'á|à|ã|â|À|Á|Ã|Â',
        'e' : 'é|è|ê|É|È|Ê',
        'i' : 'í|ì|î|Í|Ì|Î',
        'o' : 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        'c' : 'ç|Ç',
        'n' : 'ñ|Ñ'
    };
    for (var pattern in map) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern);
    };
    return str.replace(new RegExp(" ", 'g'), "_").toLowerCase().replace("/","");
};
  public async  downLoadAllDocument() {
    // define what we want in the ZIP
    this.downloadStarted = true;
    let filesLocation = this.selectedFiles.map((file:any)=> file?.cdnUrl|| file.Location)
    filesLocation = filesLocation.filter((file)=>file)
    let filesPathTab = [{path:'/',fileLocation:filesLocation}]
    let uniquePathsArray=['/'];
    let filesObj = {
      uniquePathsArray,
      filesPathTab,
      // versionId:this.versionId||this.versions[this.versions.length-1].id,
      dpgfName:this.pricing.artisanName|| "",
      archiId:this.dpgf.architect
    }
    this.architectService.generateZIP(this.dpgfId,filesObj).subscribe((data)=>{
      let url = data.Location
      window.location.href = url
      if(!environment.onPremise)window.analytics.track('download_pricing_files',{properties:{acValue: 'download_pricing_files'}});

      $('#download-zip').modal('hide');
      this.downloadStarted = false;
    },err=>{
      console.log('err',err);
      this.downloadStarted = false;
    }); 
  }
  public mergeDPGFAndPricing(pricing){
    let res = pricing.slice()
    // console.log('this.computedDPGF',this.computedDPGF)

    for (let i = 0; i < res.length; i++) {
      for (let j = 0; j < res[i].sousLots.length; j++) {
          for (let k = 0; k < res[i].sousLots[j].postes.length; k++) {
            let currentPoste = res[i].sousLots[j].postes[k];
            
            let oldData = undefined;

            if(this.computedDPGF[i].sousLots[j]){
              oldData = this.computedDPGF[i].sousLots[j].postes.find((e)=>{return (e.id == currentPoste.id)});
            }
            
            if(oldData){
              currentPoste.oldlocalisation = oldData.localisation;
              currentPoste.oldunite = oldData.unite;
              currentPoste.oldqte = oldData.qte;
              currentPoste.oldtva = oldData.tva;
              if(Array.isArray(currentPoste.sousPostes)){
                  for (let m = 0; m < currentPoste.sousPostes.length; m++) {
                    let currentSousPoste = currentPoste.sousPostes[m];
                    if(currentSousPoste){
                      currentSousPoste.oldlocalisation = currentSousPoste.localisation;
                      currentSousPoste.oldunite = currentSousPoste.uniteArchi;
                      currentSousPoste.oldqte = currentSousPoste.qteArchi;
                      currentSousPoste.oldtva = currentSousPoste.tvaArchi;
                    }
                  }
              }
            }
          }
      }
    }
    return res
  }
  public loadPricing(id,version, isLatestVersion=false){
    this.versionId = id.createdAt ? id.id : id;
    this.loading = true;
    this.dpgfPostesService.getPricingList(this.dpgf._id, this.versionId).subscribe((data) =>{
      if(!environment.onPremise)window.analytics.track('check_other_version_pricing',{properties:{acValue: 'check_other_version_pricing'}});
      this.detailDPgf = this.mergeDPGFAndPricing(data.pricingList.data);
      this.pricing = data.pricingList;
      this.selectedFiles = data.pricingList.artisanFiles;
      this.curentVersion = version;
      this.curentIndice = (data.pricingList.currentVersion);
      this.loading = false;
    });
  }
  public mergePricingWithSelf(pricing){
    let res = pricing.slice()
    for (let i = 0; i < res.length; i++) {
      for (let j = 0; j < res[i].sousLots.length; j++) {
          for (let k = 0; k < res[i].sousLots[j].postes.length; k++) {
            let currentPoste = res[i].sousLots[j].postes[k];
              currentPoste.oldlocalisation = currentPoste.localisation
              currentPoste.oldunite = currentPoste.unite
              currentPoste.oldqte = currentPoste.qte
              currentPoste.oldtva = currentPoste.tva
              // if(Array.isArray(currentPoste.sousPostes)){
              //     for (let k = 0; k < currentPoste.sousPostes.length; k++) {
              //       let currentSousPoste = currentPoste.sousPostes[k];
              //       if(currentSousPoste){
              //         currentSousPoste.oldlocalisation = currentSousPoste.localisation;
              //         currentSousPoste.oldunite = currentSousPoste.unite;
              //         currentSousPoste.oldqte = currentSousPoste.qte;
              //         currentSousPoste.oldtva = currentSousPoste.tva;
              //       }
              //     }
              // }
          }
      }
    }
    return res
  }
  public lineNumber(loti,sousloti,posti,sousPosti?){
    let res = (loti) + '.' + (sousloti) + '.'  + (posti+1) + ' - ';
    if(sousPosti|| sousPosti===0)res = (loti) + '.' + (sousloti) + '.'  + (posti+1) + '.' +(sousPosti+1) + ' - ';
    return res;
  }

  public getDevis(){
    $("#print-dpgf-devis").modal('show');
  }
  public closeDevisPopup(){
    $("#print-dpgf-devis").modal("hide");
  }
  public getData(){
    this.route.parent.params.forEach((params: Params) => {
      if (params['dpgfId']) {
        this.dpgfId = params['dpgfId'];
        this.dpgfPostesService.dpgfPricingListInfo(this.dpgfId, 'chiffrage')
        .subscribe(
          data => {
            this.setCeProjectId(data['dpgf']['ceProject']);
            /// set dpgf
            this.dpgf  = data.dpgf;
            this.computedDPGF = data.dpgf['data'];
            
            /// set pricing List
            data.pricingList.forEach((artisan)=>{
              artisan.total = artisan.data.reduce((accumulator, currentArtisan) => accumulator + currentArtisan.total,0)
            })
            // console.log('data.pricingList',data.pricingList)
            this.pricingList = data.pricingList;
            this.loadingPage = false;
            this.getCurrentRoute();
          },
          error => {
            console.log(error);
            this.ErrorService.manageError(error,'/architect/edit/project/' + this.dpgfId);
          }
        );
      }
    });
  }
  public getPricingList(pricing, index){
    this.selectedAgency = index;
    this.loading = true;
    this.subscriptions.push(
      this.dpgfPostesService.getPricingList(pricing.dpgf, pricing._id).subscribe(
        data => {
          data.pricingList.data.map(obj => {
            let foundLot = this.dpgf.data.find(({ id }) => id === obj.id);
            if(foundLot){
              obj.indexLot = foundLot.indexLot;
            }
            return obj;
          });
          this.detailDPgf = this.mergeDPGFAndPricing(data.pricingList.data);
          this.pricing = data.pricingList;
          this.versions = data.versions;
          this.versions.reverse();
          this.curentVersion = data.versions.length || 1;
          this.showDownloadDevis = true;
          this.curentIndice = data.currentVersion;
          this.getPricing(data.pricingList, index);
        },
        error => {
          console.log(error);
          this.loading = false;
          this.ErrorService.manageError(error,'/architect/edit/project/' + this.dpgfId);
        }
      )
    );
  }
  private setCeProjectId(projectId){
    this.dpgfPostesService.selectedProjectId.next(projectId);
  }
  private  sortLots(lots){
    return lots.sort((a,b) =>  a.indexLot - b.indexLot )
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
