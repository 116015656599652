import { Component, OnInit , ViewChild, NO_ERRORS_SCHEMA} from '@angular/core';
import { Router, ActivatedRoute,Params } from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { NgSharedModule } from 'src/app/all/ngshared.module';

//Components
import { Form3Component } from '../../../architect/form3/form3.component';
//Services
import { LogService } from '../../../_services/index';
import { ArchitectService } from '../../../_services/index';
import { MessageService } from 'primeng/api';
//import { ArchitectModule } from '../architect.module';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
declare var $:any;

@Component({
    selector: 'app-archi-subscribe2',
    imports:[
      
      Form3Component,
      SpinnerComponent,
      NgSharedModule
    ],
    templateUrl: './archi-subscribe2.component.html',
    styleUrls: ['./archi-subscribe2.component.scss'],
    standalone: true,

})
export class ArchiSubscribe2Component implements OnInit {
  public agencyArchi: any;
  public loading:Boolean = false;
  public gotopay:String = "false";
  private subscriptions: Array<Subscription> = [];
  @ViewChild(Form3Component) form: Form3Component
  constructor(private title: Title,
              private router: Router,
              private route: ActivatedRoute,
              private architectService: ArchitectService,
              private logService: LogService,
              private messageService: MessageService,) {
    this.agencyArchi = {}
   }

  ngOnInit() {
    this.title.setTitle('Aglo - S\'inscrire' );
    this.getMail()
  }
  public getMail(){
    this.subscriptions.push(
      this.route.queryParams.subscribe((params: Params) => {
        if(params['email']){
          this.agencyArchi.emailAgency = params['email'];
        }
        this.gotopay = params['gotopay'] || "false";
      })
    )
  }
  save(){
    this.loading = true;
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let archiId = currentUser.archiId;
    let isValid = this.form.isValid();
    let agencyData = this.form.getFormData();
    agencyData.step = 3;
    let archiInfo = {
      'firstName': currentUser.firstName,
      'lastName': currentUser.lastName,
      'phone': currentUser.phone,
      'email': currentUser.email
    }
    let data = {
      agencyData,
      archiInfo
    };
    if(isValid){
      this.subscriptions.push(
        this.architectService.update(archiId, data)
            .subscribe(
                data => {
                    this.loading = false;
                    this.logService.logEvent("Architect Set Informations")
                    if(this.gotopay == "true"){
                      this.router.navigate(['/architect/profil'], { queryParams: { page: 'licence' } });
                    }else{
                      currentUser.isNewAccount ? this.router.navigate(['/architect/accountValidation']):this.router.navigate(['/architect/my-consultations']);
                    }
                    this.messageService.add({ key: 'toast', severity: 'success', summary: 'Vos informations principales ont été enregistrées', detail: 'Vos informations principales ont été enregistrées', life: 5000 });

                },
                error => {
                    console.log("error",error)
                    this.loading = false;
                    this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: error.message, life: 5000 });
                })
      )
    }else{
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: "Veuillez remplir tous les champs obligatoires et attendre la fin du chargement des fichiers", life: 5000 });

      this.loading = false;
    }
  }
  public ngOnDestroy(): void {
      this.subscriptions.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
      });
  }

}
