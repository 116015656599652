<div *ngIf="loadingPage">
  <app-spinner></app-spinner>
</div>
<div *ngIf="!loadingPage">
<div class="row" *ngIf="!loadingPage && pricingList.length">
  <app-dpgf-result-tabs-navbar></app-dpgf-result-tabs-navbar>
</div>
<!-- No Pricing Image -->
<div style="margin-left: 80px;" *ngIf="!loadingPage && !pricingList.length">
  <div class="container" style="margin-top: 70px">
    <div class="empty-box">
      <h3 class="center">Vous n'avez pas encore reçu de réponse à votre consultation !</h3>
      <img src="https://media.giphy.com/media/tXL4FHPSnVJ0A/giphy.gif" alt="" height="400" class="empty-devis">
    </div>
  </div>
</div>
<!-- END No Pricing Image -->

<span id="result-details" *ngIf="!loadingPage && pricingList.length">
    <div class="floating-card ">
        <h3 class="center">Choisir une entreprise</h3>
        <div class="agency-boxes scroller-1">
          <div *ngFor="let pricing of pricingList; let i = index" class="agency-box" (click)="getPricingList(pricing, i)"
            [ngClass]="{'selected' : (i == selectedAgency)}">
            
            <span *ngIf="pricing.ceProjectInvite.isFactice">{{ pricing?.ceProjectInvite?.facticeArtisanInfo?.name || pricing?.artisanName }}</span>
            <span *ngIf="!pricing.ceProjectInvite.isFactice">{{ pricing?.artisan?.name || "" }}</span>

            <!-- {{ pricing?.artisan?.name ? pricing.artisan.name :  pricing.artisanName }} -->

          </div>
        </div>
        <div class="row">
          <!-- <div *ngIf="curentVersion != 0" class="dropdown dropdown-list"> -->
            <div *ngIf="!loading && showDownloadDevis" class="dropdown dropdown-list">
            <ul class="download-btn-ul" *ngIf="!loading">
              <li>
                <button class="download-devis-btn" (click)="getDevis()">Télécharger le devis</button>
              </li>
            </ul>
            <button class="btn-boo dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              V.{{curentVersion}} / Ind.{{curentIndice | pricingIndex}}
              <span class="caret"></span>
            </button>
            <!-- <ul *ngIf="versions.length != 0" class="dropdown-menu" aria-labelledby="dropdownMenu1"> -->
            <ul *ngIf="versions.length" class="dropdown-menu" aria-labelledby="dropdownMenu1">
              <li *ngFor="let version of versions,let j = index" class="version-item" (click)="loadPricing(version, versions.length-j)">
                <!-- <span class="clic-version" (click)="loadPricing(version, versions.length-j)">Voir la V.{{versions.length-j}}</span> -->
                <span class="clic-version">Voir la V.{{versions.length-j}}</span>
              </li>
            </ul>
          </div>

        </div>

        <div *ngIf="selectedFiles.length > 0">
          <button *ngIf="selectedFiles.length>1"  class="download-devis-btn" style="margin:0 0 10px 10px;" data-toggle="modal" data-target="#download-zip">Télécharger tous les documents</button>
          <p class="margin-left-10 ag-text-17">Documents envoyés par l’entreprise:</p>
          <div *ngFor="let file of selectedFiles" class="agency-files">
            <a [href]="file.Location" (click)="sendSegment()">{{file.key}}</a>
          </div>
        </div>

      </div>
      <app-spinner *ngIf="loading"></app-spinner>
      <!-- <div [ngClass]="{'not-visible' : loading}"> -->
      <div [ngClass]="{'not-visible' : loading}">
        <div *ngFor="let lot of detailDPgf,let loti = index" >
          <div *ngIf="lot.total != 0" class="row floating-card" [attr.id]="lot.id">
              <div class="col-md-12" data-step="6" data-intro="Ceci est un Lot" >
                <!--********************Lot Head Pill ***********************-->
                  <div class="row">

                    <div class="pill-col-lot">
                      <span class="title-lot">
                        {{lot.indexLot}} - {{lot.title}}
                      </span>

                    </div>
                    <div class="total-col-lot">
                      {{lot.total | number:'1.0-0':'fr'}} €
                    </div>
                  </div>

                <!--********************Lot Description ***********************-->
                <div [id]="'demo'+loti" *ngIf="!lot.priceAll" >
                  <!-- <div class="text-lot">
                    <span innerHTML="{{lot.description | linebreak}}"></span>
                  </div> -->
                  <!--********************Sous Lot Pill ***********************-->
                  <div class="sous-lot row" [ngClass]="{'fixed-sous-lot' : sousLot.fixed}" *ngFor="let sousLot of lot.sousLots,let sousloti = index" data-step="7" data-intro="Ceci est un Sous Lot">
                    <div class="row" *ngIf="!sousLot.fixed" >
                      <div class="pill-col">
                        {{sousLot.title}}
                      </div>
                      <div class="total-col">
                        <div class="total center">{{sousLot.total | number:'1.0-0':'fr'}} €</div>
                      </div>
                    </div>

                    <table nfif>
                      <!--********************Table***********************-->
                      <tr *ngIf="sousLot.fixed">
                        <td class="title">Désignation du poste</td>
                        <td class="center title">Localisation</td>
                        <td class="center title">U</td>
                        <td class="center title">Qté</td>
                        <td class="center title">TVA</td>
                        <td class="center title">PU HT</td>
                        <td class="center title">Total HT</td>
                      </tr>
                      <tbody *ngFor="let post of sousLot.postes;let posti = index">
                        <tr >
                          <td class="relative"  >
                            <span [ngClass]="{'higth-ligth' : post.isAtisan}">
                              <i *ngIf="post.option" class="fa fa-flag" pTooltip="Option" tooltipPosition="top" aria-hidden="true"></i>
                              {{lineNumber(lot.indexLot,sousloti,posti)}} <span innerHTML="{{post.designation | linebreak}}"></span>
                            </span>
                            <span *ngFor="let comment of post.comment" class="comment">
                              <i class="fa fa-commenting-o" aria-hidden="true"></i> {{comment.com}}
                            </span>
                          </td>
                          <td class="localisation center">
                            <span *ngIf="post.localisation == post.oldlocalisation || post.isAtisan; else localisation">
                              {{post.localisation}}
                            </span>
                            <ng-template #localisation>
                              <span class="new-val">{{post.localisation}}</span>
                              <span class="old-val">{{post.oldlocalisation}}</span>
                            </ng-template>
                          </td>
                          <td class="unite center">
                            <span *ngIf="post.unite == post.oldunite || post.isAtisan ; else unite">
                              {{post.unite}}
                            </span>
                            <ng-template #unite>
                              <span class="new-val">{{post.unite}}</span>
                              <span class="old-val">{{post.oldunite}}</span>
                            </ng-template>
                          </td>
                          <td class="qte center">
                            <span 
                              [ngClass]="{'orange-text': post.qte != post.qteArchi}"
                              *ngIf="post.qte == post.oldqte || post.isAtisan ; else qte">
                              {{post.qte}}
                            </span>
                            <ng-template #qte>
                              <span class="new-val">{{post.qte}}</span>
                              <span class="old-val">{{post.oldqte}}</span>
                            </ng-template>
                          </td>
                          <td class="tva center">
                            <span *ngIf="post.tva == post.oldtva|| post.isAtisan ; else tva">
                              {{post.tva}} %
                            </span>
                            <ng-template #tva>
                              <span class="new-val">{{post.tva}} %</span>
                              <span class="old-val">{{post.oldtva}} %</span>
                            </ng-template>
                          </td>
                          <td class="price center" id="unit-price">
                            {{post.unitePrice | formatPrice}} €/unité
                          </td>
                          <td class="price center" id="total-price">
                            {{post.price | formatPrice}} €
                          </td>
                        </tr>
                            <tr *ngFor="let sousPost of post.sousPostes;let sousPosti = index">
                              <td class="relative padding-left-7">
                                <span [ngClass]="{'higth-ligth' : sousPost.isAtisan}">
                                  <i *ngIf="post.option" class="fa fa-flag" pTooltip="Option" tooltipPosition="top" aria-hidden="true"></i>
                                  {{lineNumber(lot.indexLot,sousloti,posti,sousPosti)}} <span innerHTML="{{sousPost.designation | linebreak}}"></span>
                                </span>
                              </td>
                              <td class="localisation center">
                                <span *ngIf="sousPost.localisation == sousPost.oldlocalisation || sousPost.isAtisan; else localisationSousposte">
                                  {{sousPost.localisation}}
                                </span>
                                <ng-template #localisationSousposte>
                                  <span class="new-val">{{sousPost.localisation}}</span>
                                  <span class="old-val">{{sousPost.oldlocalisation}}</span>
                                </ng-template>
                              </td>
                              <td class="unite center">
                                <span *ngIf="sousPost.unite == sousPost.oldunite || sousPost.isAtisan ; else uniteSousPoste">
                                  {{sousPost.unite}}
                                </span>
                                <ng-template #uniteSousPoste>
                                  <span class="new-val">{{sousPost.unite}}</span>
                                  <span class="old-val">{{sousPost.oldunite}}</span>
                                </ng-template>
                              </td>
                              <td class="qte center">
                                <span 
                                  [ngClass]="{'orange-text': sousPost.qte != sousPost.qteArchi}"
                                  *ngIf="sousPost.qte == sousPost.oldqte || sousPost.isAtisan ; else qteSousPoste">
                                  {{sousPost.qte}}
                                </span>
                                <ng-template #qteSousPoste>
                                  <span class="new-val">{{sousPost.qte}}</span>
                                  <span class="old-val">{{sousPost.oldqte}}</span>
                                </ng-template>
                              </td>
                              <td class="tva center">
                                <span *ngIf="sousPost.tva == sousPost.oldtva|| sousPost.isAtisan ; else tvaSousposte">
                                  {{sousPost.tva}} %
                                </span>
                                <ng-template #tvaSousposte>
                                  <span class="new-val">{{sousPost.tva}}%</span>
                                  <span class="old-val">{{sousPost.oldtva}}%</span>
                                </ng-template>
                              </td>
                              <td class="price center" id="unit-price">
                                {{sousPost.unitePrice | formatPrice}} €/unité
                              </td>
                              <td class="price center" id="total-price">
                                {{sousPost.price | formatPrice}} €
                              </td>
                      </tbody>
                    </table>
                </div>
              </div>
              </div>
        </div>
      </div>
      <div *ngIf="detailDPgf.length != 0 && !loading" class="row floating-card">
        <!--********************Sommaire Artisan ***********************-->
        <h1>Récapitulatif</h1>
        <div  *ngFor="let lot of detailDPgf,let loti = index" >
          <div class="recap-pill" *ngIf="lot.total != 0">
            <img *ngIf="(lot.icon||'') != ''" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" alt="" height="15">
            <span>{{lot.indexLot}} - {{lot.title}}</span>
            <!-- <span class="total-recap">{{lot.total | number}} € HT</span> -->
            <span class="total-recap">{{ lot.total | currency: 'EUR':'symbol':undefined:'fr-FR' }}</span>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-push-6 col-md-6 recap-total">
            Total HT
            <span class="total-recap">{{pricing.totalHt| number}} € HT</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-push-6 col-md-6 recap-total">
            Total TTC
            <span class="total-recap">{{pricing.totalTtc  | number}} € TTC</span>
          </div>
        </div> -->
        <div class="borders-padding-12-size-20">
          <div class="row">Prix total HT:
            <span class="total-recap">{{pricing.totalHt  | currency: 'EUR':'symbol':undefined:'fr-FR' }}</span>
          </div>
          <div *ngIf="pricing?.discount?.discountAmount>0" class="row flex" >
            <div class="size-14-top-6 relative">
            <img class="relative paddingright-13-bottom-2" src="assets/remise-icones-violet.svg" alt=""> Remise commerciale
           </div>
            <div class="flex float-right">
            <span > {{pricing?.discount?.discountAmount}} {{pricing?.discount?.discountUnite}}</span></div>
          </div>
          <div *ngIf="pricing?.discount?.discountTotal>0" class="row bordertop-margins-padding"></div>
          <div *ngIf="pricing?.discount?.discountTotal>0" class="row">Prix total HT après remise
            <span class="total-recap"> {{pricing?.discount?.discountTotal | currency: 'EUR':'symbol':undefined:'fr-FR'}} HT</span>
          </div>
          <div class="row">Prix total TTC
            <span *ngIf="pricing?.discount?.discountAmount==0" class="total-recap">{{pricing?.totalTtc  | currency: 'EUR':'symbol':undefined:'fr-FR' }} TTC</span>
            <span *ngIf="pricing?.discount?.discountAmount>0" class="total-recap">{{pricing?.discount?.discountTotalTtc  | currency: 'EUR':'symbol':undefined:'fr-FR' }} TTC</span>
          </div>
       </div>
      </div>

    <!-- Print dpgfPricing lots  Popup -->
    <div id="print-dpgf-devis"
          tabindex="-1"
          class="modal"
          role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <app-dpgf-devis-popup [dpgfPricing]="pricing" (closeModal)="closeDevisPopup()"></app-dpgf-devis-popup>
      </div>
    </div>
    <!-- END Print dpgfPricing lots  Popup -->

</div>

</span>
</div>
<div id="download-zip"
  class="modal fade upgrade-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-2 no-padding" style="margin-top: 25vh;width: 465px;">
    <div class="modal-container no-padding">
      <p class="upgrade-title">Fichier zip</p>
      <p class="upgrade-sub-title upgrade-sub-title-2">Le fichier que vous allez télécharger est un fichier zip.<br>
        Si vous n’avez pas de logiciel pour ouvrir ce type de fichier vous pouvez utiliser <a href="https://www.win-rar.com/start.html?&L=10">winrar</a>.</p>
      <p class="upgrade-sub-title upgrade-sub-title-2">Si le teléchargement est trop long, un email vous sera envoyé avec un lien de téléchargement.</p>  
      <button class="ag-btn-primary-sm b1" (click)="downLoadAllDocument()">
        <span *ngIf="!downloadStarted">Télécharger</span>
        <div *ngIf="downloadStarted">
          <app-spinner></app-spinner>
        </div>
      </button>
    </div>
  </div>
</div>