<!-- <div class="container">
    <div>
        <p class="title-text">Importer des postes depuis un fichier Excel</p>
        <p class="font-size-14">Sélectionner la manière dont vous souhaitez importer vos postes.</p>
    </div>

    <div>
        <p class="title-text">Importer un fichier CSV</p>
        <p class="font-size-14">Pour importer intégralement votre fichier Excel, veuillez le télécharger au format CSV.</p>
        <div>
            <div>
                <input type="file">
                <p>Sélectionnez ou déposez vos fichiers ici</p>
                <img src="/assets/upload_icon.svg" >
            </div>
            <div>
                <p>Limite : 100 fichiers, 30Mo par fichier</p>
            </div>
        </div>
        <div class="flex">
            <img src="/assets/file_copy.svg">
            <div>
                <p class="title-text">Copier-coller des postes depuis Excel</p>
                <p class="font-size-14">Pour importer seulement une partie de vos données, il suffit de les copier et de coller ici.</p>
            </div>
        </div>
    </div>
</div> -->

<div class="container-main">
    <div *ngIf="!isCopied">
    <div>
        <p class="title-text">Importer des postes depuis un fichier Excel</p>
        <p class="font-size-14">Sélectionner la manière dont vous souhaitez importer vos postes.</p>
    </div>
    <div class="section-global padding-left-12">
        <div *ngIf="false" class="sections height-189 background-color padding-16">
                <p class="title-text">Importer un fichier CSV</p>
                <p class="font-size-14">Pour importer intégralement votre fichier Excel, veuillez le télécharger au format CSV.</p>
                <div class="upload-box" (click)="onFileUploadClick()">
                    <input type="file" id="fileUpload"  accept=".csv,.xls,.xlsx" (change)="onFileChange($event)">
                    <div class="flex space-between">
                        <label class="font-size-16" for="fileUpload">Sélectionnez ou déposez vos fichiers ici </label><img src="/assets/upload_icon.svg" >
                    </div>
                </div>
                <p class="font-size-14 limit-text-color">Limite : 100 fichiers, 30Mo par fichier</p>
        </div>
        <div class="sections background-color flex padding-16" (click)="$event.stopPropagation()" contentEditable="true" (paste)="pasteData($event, sousloti)" (change)="$event.preventDefault()">
            <div class="padding-right-8">
                <img src="/assets/file_copy.svg">
            </div>
        <div>
            <p class="title-text margin-0">Copier-coller des postes depuis Excel</p>
            <p class="font-size-14 margin-0">Pour importer seulement une partie de vos données, il suffit de les copier et de coller ici.</p>
       </div>
        </div>
    </div>

    </div>
    <div *ngIf="isCopied">
        <div class="padding-bottom-24">
            <p class="title-text">Copier-Coller depuis Excel</p>
            <p class="font-size-14">Veuillez faire correspondre les colonnes de votre fichier Excel avec les champs d’AGLO</p>
        </div>
        <div  class="flex justify-center">
            <div>
            <div class="flex space-between padding-bottom-8">
                <div class="width-50">
                    <span class="title-text-14">Colonne CSV</span>
                </div>
                <div class="width-50">
                    <span class="title-text-14">Correspondance AGLO</span>
                </div>
            </div>
            <div *ngFor="let match of matching;let i= index" class="flex space-between">
                <div class="width-50">
                    <div class="column-csv flex"> {{ match.value }} </div>
                </div>
                <div class="form-group width-50">
                    <p-dropdown [style]="{'border': '1px solid gray','width':'285px','height':'44px'}" [options]="matchingOption" [(ngModel)]="match.key"></p-dropdown>
                </div>
            </div>
            <div class="buttons">
            <button style="color:rgba(10, 23, 71, 1);background-color: transparent;padding:8px 16px 8px 16px;border:none;margin-right: 32px;" (click)="cancel()">Annuler</button>
            <button style="background-color: rgba(10, 23, 71, 1);color:white;font-size: 14px;padding-right: 16px;padding-left: 16px;border-radius: 4px;" (click)="pasteDataWithKeys()">Importer</button>
            </div>
        </div>
    </div>

    </div>
</div>

