import { Component, OnInit, Output, EventEmitter,ChangeDetectorRef,ViewChild, HostListener, SimpleChanges, Input, NO_ERRORS_SCHEMA } from '@angular/core';
import {  Router } from '@angular/router';
import { Store , select} from '@ngrx/store';
import * as fromSelectors from '../../../dpgf/dce-edition/_store';
import * as actions from '../../../dpgf/dce-edition/_store/dpgf.actions';
import { DomSanitizer } from '@angular/platform-browser';
import generateCCTP from './cctp-generator';
var html2json = require('html2json4wx').html2json;
import { ArchitectService, AuthService, DpgfPostesService, relatedFichesService, ProductsService  } from 'src/app/_services';
// import { AiGeneratorService } from 'src/app/_services/ai-generator.service'
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CstbNavigationComponent } from '../cstb-navigation/cstb-navigation.component';
import { Editor } from '../../editor/editor.component';
import {calculate} from './calcul-frigo'
import { MessageService } from 'primeng/api';
import { unitsCodes } from 'src/app/architect/acv/acv-project-composant-sidebar/calcul-frigo';
import { AiGeneratorService } from 'src/app/_services/ai-generator.service';
import { WebView2Service } from 'src/app/_services/webview2.service.service';
import { int } from 'aws-sdk/clients/datapipeline';

import { ChatgptRegenerateModalComponent } from 'src/app/architect/chatgpt-regenerate-modal/chatgpt-regenerate-modal.component';
import { FormatPrice } from 'src/app/_pipe/formatPrice.pipe';
import { ArchiExpiredGptModalComponent } from '../../archi-expired-gpt-modal/archi-expired-gpt-modal.component';
import { SpinnerWithQuotesComponent } from 'src/app/architect/spinner-with-quotes/spinner-with-quotes.component';
import { SpinnerComponent } from '../../spinner/spinner.component';
import { AcvProjectComposantSidebarComponent } from 'src/app/architect/acv/acv-project-composant-sidebar/acv-project-composant-sidebar.component';
import { NgSharedModule } from '../../ngshared.module';
import { environment } from 'src/environments/environment';
const only = require('only');
var _ = require('lodash');
declare var $:any;
@Component({
  standalone:true,
  selector: 'app-cctp-edition',
  imports:[
    NgSharedModule,
    ChatgptRegenerateModalComponent,
    ArchiExpiredGptModalComponent,
    SpinnerWithQuotesComponent, 
    CstbNavigationComponent, 
    AcvProjectComposantSidebarComponent, 
    Editor],
    providers: [],
  templateUrl: './cctp-edition.component.html',
  styleUrls: ['./cctp-edition.component.scss'],
})
export class CctpEditionComponent implements OnInit {
  @ViewChild(CstbNavigationComponent) cstbNav: CstbNavigationComponent
  @ViewChild(Editor) editor: Editor
  
  @Output()closeTheCctp = new EventEmitter<any>();
  @Output()frirogeneResult = new EventEmitter<any>();
  @Output()infoPost = new EventEmitter<any>();
  @Output()addingPostIniesEvent = new EventEmitter<any>();
  @Output()openRemploiEvent = new EventEmitter<any>();

  @Input() dpgfId;
  @Input() selectedFriro;
  @Input() displaySide;

  @Input() postesRevitElements:any={};
  @Input() canEdit:boolean;
  @Input() lots:any;

  public allowedImagesFormats:string[] = ['png', 'jpg', 'jpeg'];

  public cctpEdit$;
  public ceproject;
  public cctpEdit;
  public fromCstb:boolean = false;
  public choosedCstb=true;projectLicence
  public checkedFolder=true;
  public cctpEditValue = '';
  public gid = '';
  public error = 0;
  public value = 'statique';
  public tab='CSTB';
  public cstb;
  public lodingCstb = false;
  public switchTo = true;
  public poste = {
    title: "",
    cctp : [],
  }
  public view='importPosts';
  public searchTerm;
  public results=[];
  public subscriptions = [];
  public children=[];
  public cstbToImport=[];
  public loadingChild: boolean;
  public isLotSelected: boolean;
  public licence: string;
  //change
  public descView: any;
  public searchFileTerm='';
  public lot: any;
  public postIdInies: string;
  public lotsACV:any[];
  public relatedFiles=[];
  public iniesResult=[];
  public iniesSuggResult=[];
  public selectedFiles=[];
  public checkedAllFiles: boolean;
  public contributors=['Composants','Chantier'];oldScore:Number=0;
  public lotsPCE = [
    {
      name: "1- VRD",
       lots: ["1.1 Réseaux (extérieurs, jusqu'au domaine public)",
        "1.2 Stockage",
        "1.3 Aires de stationnement extérieures"]
    },
    {
      name: "2- Fondation et infrastructure",
      lots: ["2.1 Fondations",
        "2.2 Murs et structures enterrées ( escalier de cave, parking, …)",
        "2.3 Parcs de stationnement en superstructure à l'exception des garages des maisons individuelles ou accolées",
      ]
    },
    {
      name: "3- Maçonnerie et superstructure",
      lots: ["3.1 Eléments horizontaux - planchers, dalles, balcons",
        "3.2 Eléments horizontaux - poutres",
        "3.3 Eléments verticaux - façades",
        "3.4 Eléments verticaux - refends",
        "3.5 Eléments verticaux - poteaux",
        "3.6 Escaliers et rampes",
        "3.7 Eléments d'isolation",
        "3.8 Maçonneries diverses",]
    },
    {
      name: "4- Couverture - Etanchéité - Charpente - Zinguerie",
      lots: ["4.1 Toitures terrasse",
        "4.2 Toitures en pente",
        "4.3 Eléments techniques de toiture",]
    },
    {
      name: "5- Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures",
      lots: ["5.1 Cloisons et portes intérieures",
        "5.2 Doublages mur, matériaux de protection, isolants et membranes",
        "5.3 Plafonds suspendus",
        "5.4 Planchers surélevés",
        "5.5 Menuiseries, metalleries et quincalleries",]
    },
    {
      name: "6- Façades et menuiseries extérieures",
      lots: ["6.1 Revêtement, isolation et doublage extérieur",
        "6.2 Portes, fenêtres, fermetures, protections solaires",
        "6.3 Habillages et ossatures",]
    },
    {
      name: "7- Revêtements des sols, murs et plafonds - Chape - Peintures - Produits de décoration",
      lots: ["7.1 Revêtement des sols",
        "7.2 Revêtement des murs et plafonds",
        "7.3 Eléments de décoration et revêtements des menuiseries",]
    },
    {
      name: "8- CVC",
      lots: ["8.1 Equipements de production (chaud/froid) [hors cogénération]",
        "8.2 Systèmes de cogénération",
        "8.3 Systèmes d'émission",
        "8.4 Traitement de l'air et éléments de désenfumage",
        "8.5 Réseaux et conduits",
        "8.6 Stockage",
        "8.7 Fluides frigorigènes",]
    },
    {
      name: "9- Installations sanitaires",
      lots: ["9.1 Eléments sanitaires et robinetterie",
        "9.2 Canalisations, résaux et systèmes de traitement",]
    },
    {
      name: "10- Réseaux d'énergie (courant fort)",
      lots: ["10.1 Réseaux éléctriques",
        "10.2 Ensemble de dispositif pour la sécurité",
        "10.3 Eclairage intérieur",
        "10.4 Eclairage extérieur",
        "10.5 Equipements spéciaux",
        "10.6 Installations techniques",]
    },
    {
      name: "11- Réseaux de communication (courant faible)",
      lots: ["11.1 Réseaux éléctriques et de communications",
        "11.2 Réseaux et systèmes de contrôle et régulation",
        "11.3 Installations techniques et équipements spéciaux",]
    },
    {
      name: "12- Appareils élévateurs",
      lots: ["12 Appareils élévateurs",]
    },
    {
      name: "13- Equipements de production locale d'éléctricité",
      lots: ["13 Equipements de production locale d'éléctricité"]
    }
  ]
  public lotsPCECopy = [
    {
      name: "1- VRD",
       lots: ["1.1 Réseaux (extérieurs, jusqu'au domaine public)",
        "1.2 Stockage",
        "1.3 Aires de stationnement extérieures"]
    },
    {
      name: "2- Fondation et infrastructure",
      lots: ["2.1 Fondations",
        "2.2 Murs et structures enterrées ( escalier de cave, parking, …)",
        "2.3 Parcs de stationnement en superstructure à l'exception des garages des maisons individuelles ou accolées",
      ]
    },
    {
      name: "3- Maçonnerie et superstructure",
      lots: ["3.1 Eléments horizontaux - planchers, dalles, balcons",
        "3.2 Eléments horizontaux - poutres",
        "3.3 Eléments verticaux - façades",
        "3.4 Eléments verticaux - refends",
        "3.5 Eléments verticaux - poteaux",
        "3.6 Escaliers et rampes",
        "3.7 Eléments d'isolation",
        "3.8 Maçonneries diverses",]
    },
    {
      name: "4- Couverture - Etanchéité - Charpente - Zinguerie",
      lots: ["4.1 Toitures terrasse",
        "4.2 Toitures en pente",
        "4.3 Eléments techniques de toiture",]
    },
    {
      name: "5- Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures",
      lots: ["5.1 Cloisons et portes intérieures",
        "5.2 Doublages mur, matériaux de protection, isolants et membranes",
        "5.3 Plafonds suspendus",
        "5.4 Planchers surélevés",
        "5.5 Menuiseries, metalleries et quincalleries",]
    },
    {
      name: "6- Façades et menuiseries extérieures",
      lots: ["6.1 Revêtement, isolation et doublage extérieur",
        "6.2 Portes, fenêtres, fermetures, protections solaires",
        "6.3 Habillages et ossatures",]
    },
    {
      name: "7- Revêtements des sols, murs et plafonds - Chape - Peintures - Produits de décoration",
      lots: ["7.1 Revêtement des sols",
        "7.2 Revêtement des murs et plafonds",
        "7.3 Eléments de décoration et revêtements des menuiseries",]
    },
    {
      name: "8- CVC",
      lots: ["8.1 Equipements de production (chaud/froid) [hors cogénération]",
        "8.2 Systèmes de cogénération",
        "8.3 Systèmes d'émission",
        "8.4 Traitement de l'air et éléments de désenfumage",
        "8.5 Réseaux et conduits",
        "8.6 Stockage",
        "8.7 Fluides frigorigènes",]
    },
    {
      name: "9- Installations sanitaires",
      lots: ["9.1 Eléments sanitaires et robinetterie",
        "9.2 Canalisations, résaux et systèmes de traitement",]
    },
    {
      name: "10- Réseaux d'énergie (courant fort)",
      lots: ["10.1 Réseaux éléctriques",
        "10.2 Ensemble de dispositif pour la sécurité",
        "10.3 Eclairage intérieur",
        "10.4 Eclairage extérieur",
        "10.5 Equipements spéciaux",
        "10.6 Installations techniques",]
    },
    {
      name: "11- Réseaux de communication (courant faible)",
      lots: ["11.1 Réseaux éléctriques et de communications",
        "11.2 Réseaux et systèmes de contrôle et régulation",
        "11.3 Installations techniques et équipements spéciaux",]
    },
    {
      name: "12- Appareils élévateurs",
      lots: ["12 Appareils élévateurs",]
    },
    {
      name: "13- Equipements de production locale d'éléctricité",
      lots: ["13 Equipements de production locale d'éléctricité"]
    }
  ]
  public performanceUFArray=['Charge supportée',"Classe d'usage",'Classement Air Eau Vent',"Classement de certification UPEC de la résistante à l'usure et au poinçonnement"]
  public performanceUFArrayCopy=['Charge supportée',"Classe d'usage",'Classement Air Eau Vent',"Classement de certification UPEC de la résistante à l'usure et au poinçonnement"]
  public entities;
  public entitiesBatiments=[]
  public products=[];
  public addedProducts=[];
  public searchProductTerm;
  public searchProductTermUpdate = new Subject<string>();
  public searchTermUpdate = new Subject<string>();
  public selectedProduct;
  public selectedRemoveProduct;
  public showAddedProducts: boolean = false;
  public loadingPosts: boolean;
  public dpgf: any;
  public chosenPost;
  public addingPost;hasOptionsOrTextarea:boolean = true;
  public iniesPostPage;user;currentUser;
  public iniesCategories = [
    {title:"Produits de construction",
     id:"476",
     sousCategories:[
        {title:"Voirie / réseaux divers (y compris réseaux intérieurs) et aménagements extérieurs de la parcelle",icon:'voirie_et_reseaux_divers.png',id:1},
        {title:"Strucure / maçonnerie / gros oeuvre / charpente divers",icon:'charpente.png',id:2},
        {title:"Façades",icon:'facades.png',id:3},
        {title:"Couverture / étanchéité",icon:'couverture__etancheite.png',id:4},
        {title:"Menuiseries intérieures et extérieures / fermetures",icon:'menuiserie_exterieure.png',id:5},
        {title:"Isolation",icon:'maconnerie__grosuvre.png',id:6},
        {title:"Cloisonnement / plafonds-suspendus",icon:'cloisons__doublages__fauxplafonds.png',id:7},
        {title:"Revêtements des sols et murs / peintures / produits de décorations",icon:'revetement_de_sol.png',id:8},
        {title:"Produits de préparation et de mise en oeuvre",icon:'demolition__depose.png',id:9},
        {title:"Equipements sanitaires et salle d'eau",icon:'plomberie__sanitaire.png',id:446},
        {title:"Panneau sandwich agroalimentaire",icon:"Aglo-Panneau_sandwich_agroalimentaire.svg",id:860},
        {title:"Autre",icon:'generalites.png',id:478},
        {title:"Approche simplifiée",icon:"Aglo-Approche_simplifiee.svg",id:822},
      ]},
    {title:"Equipements électriques, électroniques et de génie climatique",
      id:"479",
      sousCategories:[
        {title:"Appareillage d'installation pour les réseaux d'énergie électrique et de communication (≤ 63 Ampères)",icon:"Aglo-Appareillage_installation_reseaux.svg",id:480},
        {title:"Fils et câbles",icon:"Aglo-Fils_cables.svg",id:481},
        {title:"Sécurité des personnes et contrôle d'accès",icon:'signaletique.png',id:482},
        {title:"Sécurité du bâtiment",icon:'securite_incendie.png',id:483},
        {title:"Equipements de génie climatique",icon:"Aglo-Equipements_genie_climatique.svg",id:484},
        {title:"Production locale d'énergie",icon:"Aglo-Production_locale_energie.svg",id:485},
        {title:"Matériel d'éclairage",icon:"Aglo-Eclairage.svg",id:773},
        {title:"Approche simplifiée",icon:"Aglo-Approche_simplifiee.svg",id:823},
        {title:"Autre",icon:'generalites.png',id:486},
      ]},
    {title:"Services",
      id:"827",
      sousCategories:[
        {title:"Eaux",icon:'assainissement__recuperationtraitement_des_eaux.png',id:828},
        {title:"Traitement des déchets",icon:"Aglo-Traitement_dechets.svg",id:849},
        {title:"Transports",icon:"transport.svg",id:852},
        {title:"Energies",icon:"Aglo-Energie.svg",id:831},
        // {title:"Fluides frigorigènes",icon:"Aglo-Fluides_frigorigenes.svg",id:848},
      ]},
  ];
  public unitsCodes=unitsCodes
  public allCategories=[];
  public selectedCategory=[{nom:"Produits de construction",
  idCategory:"476",treeLevel:0},{nom:"Equipements électriques, électroniques et de génie climatique",
  idCategory:"479",treeLevel:0},{nom:"Services",
  idCategory:"827",treeLevel:0}]
  public loadingCategories: boolean;
  public filter={productionPlace:{"Hors Europe":false,"Europe":false,"France":false},performanceUF:'Toutes',dateMiseEnLigne:"Toutes",organismeName:"",declarationType:[1,2,3,4],idCategory:'',airRating:[]};
  public frigoPostParent;
  public organismeSugg=[]
  public numPostsCategory={};
  public loadingSearchPosts: boolean;
  public selectedBatiment: string;
  public result: any;
  public score: any;
  public color: string;
  public loadingData: boolean=false;
  public divVisibility: boolean =false;
  private agloGptSelectedPostType: string = "";fromSelect:boolean = false;
  private typedCctp:string = "";
  posteId: any;
  postes: any;
  indexLot: int;
  public cctpChanged: boolean = false;
  public accessToCstb = JSON.parse(localStorage.getItem('currentUser')).accessCstb;
  
  
  // frirogeneResult: any[];
  constructor(private store: Store,
              private dpgfPostesService :DpgfPostesService,
              private sanitizer: DomSanitizer,
              private router: Router,
              private cd: ChangeDetectorRef,
              private archiService: ArchitectService,
              public authService: AuthService,
              private relatedFichesService :relatedFichesService,
              private productsService: ProductsService,
              private aiservice: AiGeneratorService,
              private wv2Service:WebView2Service,
              private messageService: MessageService
            ) { }

  @HostListener('document:click', ['$event.target']) onClick(targetElement) {
    let id = targetElement.id;
    let index=this.children.findIndex((poste)=>poste.key==id)
    $('.autocomplete-items2').css({'visibility': 'hidden'});
    if(id=='0.0'){
      if(this.checkedFolder){
        this.cstbToImport=[];
        if(this.children.length==1){
          $('.cstb-content .folder-checkbox').prop('checked', this.checkedFolder)
          this.cstbToImport.push({post:this.createHtml(this.children[0]['poste']['cctp'])})
        }
        else{
          this.cstbToImport.push({post:this.children[0].poste.title,id:'title'})
          this.children.forEach((elem,index)=>{
            if(elem.key.startsWith('1')) {
              $('.cstb-content .folder-checkbox').prop('checked', this.checkedFolder)
              this.cstbToImport.push({post:this.createHtml(this.children[index]['poste']['cctp']),id:elem.key})
            }
          })
          this.checkedFolder=!this.checkedFolder;
        }
      }else{
        $('.cstb-content .folder-checkbox').prop('checked', this.checkedFolder)
        this.checkedFolder=!this.checkedFolder;
        this.cstbToImport=[]
      }
    }
    else if(id && index!=-1 && !this.choosedCstb){
      let newPost=this.createHtml(this.children[index]['poste']['cctp']);
      // this.cstbToImport=this.cstbToImport+newPost;
      let foundIndex=this.cstbToImport.findIndex((post) => post.id==id)
      if(foundIndex==-1) this.cstbToImport.push({post:newPost,id:id})
      else this.cstbToImport.splice(foundIndex,1)
    }
    if(id.includes("section-icon")){
      id=id.split("icon").pop()
      $(`#section${id} .cctp-section` ).toggle();
    }
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.key == 'Enter')this.searchPostInies();
  }
  // Detect browser or tab close
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if (this.cctpChanged) { // if cctp changed & the data is not saved (by closing the sidebar) show the browser confirm dialog 
      return false;
    }
  }
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    this.cctpEdit$ = this.store.pipe(select(fromSelectors.getAny, {name : "cctpEdit"}))
    this.getUserById();
    // this.store.pipe(select(fromSelectors.getAny, {name : "cctpEdit"})).subscribe((cctpEdit) => {
    //   this.cctpEdit$ = cctpEdit;
    // })
    this.store.pipe(select(fromSelectors.getAny,{name:'projectLicence'})).subscribe((data)=>{
      this.projectLicence = data;
    })
    this.cctpEdit$.subscribe(res=>{
      this.posteId=res.posteId;
    })
    this.store.pipe(select(fromSelectors.getceProjectId)).subscribe((ceProject)=>{
      if(typeof ceProject === 'object')this.ceproject = ceProject._id;
      else this.ceproject = ceProject
    })
    // this.subscriptions.push(
      this.store.pipe(select(fromSelectors.getAny, {name : "cctpEdit"})).subscribe((cctpEdit)=>{
        if(cctpEdit){
          this.cctpEdit = cctpEdit;
          // console.log('cctpEdit',cctpEdit)
          // remove &nbsp; from designation
          if(this.cctpEdit["postDesignation"]){
            this.cctpEdit["postDesignation"] = this.cctpEdit["postDesignation"].replace(/&nbsp;/g, ' ');
          }else if(this.cctpEdit["sousLotTitle"]){
            this.cctpEdit["sousLotTitle"] = this.cctpEdit["sousLotTitle"].replace(/&nbsp;/g, ' ');
          }
          this.gid = cctpEdit.gid || "";
          this.getCSTB(cctpEdit);
        }
      })
    // )
    // this.subscriptions.push(
      this.store.pipe(select(fromSelectors.getLot)).subscribe(lot=>{
        this.lot=lot;
      })
    // )
    // this.subscriptions.push(
      this.store.pipe(select(fromSelectors.getAny, {name : "descView"})).subscribe((descView)=>{
        this.descView=descView;
      })
    // )
    this.lotsACV=[{name:'lotACV1'},{name:'lotACV2'}]
    this.licence=this.authService.licence;
    if(this.authService.isArchitect()){
      this.getAllEntities();
      this.listenForProductSearch();
      this.listenForsearchTerm()
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.displaySide){
      if(!changes.displaySide.currentValue){        
        /// stop AGLOGPT from generating the cctb (if the sidebar closes)
        setTimeout(() => {
          this.unsubscribeFromAllSubscriptions();
        }, 1000);
        // return to initial state
        this.loadingData=false;
        this.divVisibility=false;
        this.switchTo = true;
        this.cctpChanged = false;
      }
    }
    if(changes.selectedFriro && this.selectedFriro){
      this.addFrigoToPost(this.selectedFriro);
    }
  }
  public listenCollapse=()=>{
    var coll = document.getElementsByClassName("collapsible");
    var i;
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.display === "block") {
          content.style.display = "none";
        } else {
          content.style.display = "block";
        }
      });
    }
  }
  expandFilter(event){
    let parent = $(event.target).closest('.collapsible');
    let clickedItem=parent.nextAll('.content:first')
    let openArrow = parent.children(".open-arrow")
    let closeArrow = parent.children(".close-arrow")
    $('.close-arrow').addClass('hidden-item');
    $('.open-arrow').removeClass('hidden-item');

    if(clickedItem.hasClass('expand')){
      clickedItem.removeClass('expand');
    }else{
      // $('.content').removeClass('expand');
      clickedItem.addClass('expand');
      openArrow.addClass('hidden-item');
      closeArrow.removeClass('hidden-item');
    }
  }
  public getCSTB(cctpEdit){
    // saad check ccpt poste and sous poste
    if(cctpEdit.gid){
      this.lodingCstb = true;
      this.subscriptions.push(
        this.dpgfPostesService.getCCTPCSTBFromElastic(cctpEdit.gid,this.ceproject).subscribe((res)=>{
          this.lodingCstb = false;
          this.poste = res.data;
          this.error = 0;
          this.formatPosteCSTB()
          this.cd.markForCheck();
        },
        err => {
          console.log(err)
          this.error = err.status
          this.lodingCstb = false;
          this.cstb = "";
          this.cd.markForCheck();
        })
      )
    }
  }
  redirectToPricingTable(){
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
  }
  public removeCstbId(){
    let gid = this.gid
    this.store.dispatch(actions.setCctpGid(""));
    if(gid)this.cstbNav.getCstbLot({gid : gid.split("-")[0],latestGID:gid})
      setTimeout(()=>{
    },500)
  }
  public selectPost(postes){
    if(postes[0]){
      this.hasOptionsOrTextarea = true;
      this.store.dispatch(actions.setCctpGid(postes[0].gid));
      this.store.dispatch(actions.setCctpOnDpgf({}));
      // this.formatPosteCSTB()
      this.cstbNav.goBack();
    }
  }
  public setCctpText(event){
    this.cctpChanged = true;
    this.store.dispatch(actions.setCctpText(event.htmlValue));

    // this.typedCctp = event.htmlValue;
  }
  public addCctpText(text){
    this.store.dispatch(actions.addCctpTextAgloGpt(text));
  }
  public closeCctp (){
    this.closeTheCctp.emit()
  }
  // public validateCctp(){
  //   this.closeTheCctp.emit()
  // }
  public onSelectionChange(val) {
    if(this.value !== val) {
      this.value = val;
    }
  }
  public onSelectionChangeTab(val,lot={}) {
    let postId=(lot||{})['lotId']+'|'+(lot||{})['souslotId']+'|'+(lot||{})['posteId'];
    if((lot||{})['sousPosteId'])postId=(lot||{})['lotId']+'|'+(lot||{})['souslotId']+'|'+(lot||{})['posteId']+'|'+(lot||{})['sousPosteId'];
    
    let oldPostId=this.postIdInies;
    if((lot||{})['lotId']) this.postIdInies=postId
    if(this.tab !== val || oldPostId!=postId){
      /// fetch post inies posts
      if(val=='env'){
        this.getAllPosts()
        this.resetValues()
      }
      /// fetch post CSTB
      if(val=='CSTB'){
        this.getCSTB(this.cctpEdit);
      }
      /// fetch post products
      if(val == 'produit'){
        this.getAddedProducts();
      }
    }
    this.tab = val;
  }
  public formatPosteCSTB() {
    //this.poste.variantes[0]
    let cstbText = "";
    this.checkedFolder = true;
    this.hasOptionsOrTextarea = true;
    if(Array.isArray(this.poste.cctp)){
      this.choosedCstb=true;
      cstbText = this.createHtml({content : "" , children : this.poste.cctp})
    }else{
      this.choosedCstb=false;
      this.children=[];
      this.cstbToImport=[];
      cstbText = this.createHtmlGenerality(this.poste.cctp,0,0)
    }
    //change
    cstbText=cstbText.replace(/\.html/g,'');
    if(cstbText && this.fromCstb){
      this.hasOptionsOrTextarea =  cstbText.includes('type="checkbox"') || cstbText.includes('type="radio"') || cstbText.includes('<textarea') 
      if(!this.hasOptionsOrTextarea){
        let cctpJson = html2json(cstbText);
        let htmlGen = generateCCTP(cctpJson , 0);
        this.store.dispatch(actions.attachCctpText(htmlGen));
        this.cstb = ""
        htmlGen=""
        // this.checkedFolder = false;
        // this.store.dispatch(actions.setCctpGid(""));
        this.removeCstbId()
        this.setValueAndSwitch(true,'statique');
        this.fromCstb = false;
      }
    }
    if(cstbText) this.cstb = this.sanitizer.bypassSecurityTrustHtml(cstbText);
    setTimeout(()=>{
      if(!this.authService.currentEnvironment && cstbText){
        $('.cstb-content a').css( {"color":'blue','text-decoration': 'underline'} );
        $('.cstb-content a').attr("target","_blank");
      }else {
        $('.cstb-content a').css( {"cursor":'default',"color":"#4b5563"} );
        $('.cstb-content a').on("click", function(event) {
          event.preventDefault(); // Prevent the default behavior of the link
       })}
      if(Array.isArray(this.poste.cctp)){
        $('.cstb-content input:checkbox').not(this).prop('checked', true)
        $(".cstb-content input[type='radio']").get().reverse().forEach(a=>{$(a).prop("checked", true);});
      }
      else this.presetFields()
      if(!Array.isArray(this.poste.cctp)){
        let i=0;
        while(i<20){
          $(`#section${i} .cctp-section` ).toggle();
          i++
        }
      }
    },100)
    this.archiService.updateUserInfo({descView:this.descView+1},this.authService.currentUser.id).subscribe(
      (data)=>{
        this.descView=this.descView+1;
      }
    )
  }
  public presetFields(){
    if((this.gid||"") != ""){
      $('.cstb-content input:checkbox ').prop('checked', true).attr('disabled',true)
      $(".cstb-content input[type='radio']").get().reverse().forEach(a=>{$(a).attr('disabled',true);});
      $(".cstb-content textarea").get().reverse().forEach(a=>{$(a).attr('disabled',true);});
      $('.cstb-content .folder-checkbox').prop('checked', false).attr('disabled',false)
    }
  }
  public createHtml(poste){
    let cstbText = '';
    cstbText += poste.content;
    for(let i=0; i<(poste.children||[]).length; i++) {
      let cctpLevel = poste.children[i];
      // style="margin-left:${i*15}px"
      cstbText += `<div class="cctp-section" >
        ${this.createHtml(cctpLevel)}`
    }
    for(let i=0; i<(poste.children||[]).length; i++) {
      cstbText += "</div>";
    }
    // console.log('createHtml',poste)
    // console.log('createHtml result',cstbText)
    return cstbText.replace(/☐/g, '');
  }
  public createHtmlGenerality(poste,level,index){
    let cstbText = '';
    let id=`${level}.${index}`
    if((poste.children||[]).length || level==1) {
      // margin-left:14px !important
      let newTitle=poste.content.slice(0, 4) + `style='position:relative;right:14px' id="${id}"` + poste.content.slice(4);

      cstbText +=`<input class='folder-checkbox' type="checkbox" id="${id}" style="position:absolute;padding-top: 3px;left:-29px">` +newTitle
      this.children.push({key:id,poste:{cctp:poste,title:newTitle}})
    }
    else cstbText +=poste.content;

    for(let i=0; i<(poste.children||[]).length; i++) {
      let cctpLevel = poste.children[i];
      if(level==0 && _.get(poste,'children[0].children.length',null)){
        cstbText += `<img id="${`section-icon${i}`}"" style="position:absolute;left:-50px;height: 18px;" src="/assets/archi/expand_black_24dp.svg" /><div class="cctp-section" id="${`section${i}`}">
        ${this.createHtmlGenerality(cctpLevel,level+1,i)}`
        cstbText += "</div>";
      }
      else {
        cstbText += `<div class="cctp-section" id="${id}">
        ${this.createHtmlGenerality(cctpLevel,level+1,i)}`
        cstbText += "</div>";
      }
    }
    // console.log('poste 2 ',poste)
    // console.log('createHtmlGenerality 2 ',cstbText)
    // for(let i=0; i<(poste.children||[]).length; i++) {
    // }
    return cstbText.replace(/☐/g, '');
  }
  public validateCctp() {
    let parsedPosts='';
    this.choosedCstb=true;
    this.cstbToImport.forEach((elm)=>{
      parsedPosts+=elm.post;
    })
    this.checkedFolder=true;
    this.cstb=this.sanitizer.bypassSecurityTrustHtml(parsedPosts);
    setTimeout(()=>{
      if(!this.authService.currentEnvironment){
        $('.cstb-content a').css( {"color":'blue','text-decoration': 'underline'} );
        $('.cstb-content a').attr("target","_blank");
      }else {
        $('.cstb-content a').css( {"cursor":'default',"color":"black"} );
        $('.cstb-content a').on("click", function(event) {
          event.preventDefault(); // Prevent the default behavior of the link
      })}
      $('.cstb-content input:checkbox ').prop('checked', true).attr('disabled',false)
      $(".cstb-content input[type='radio']").get().reverse().forEach(a=>{$(a).attr('disabled',false);});
      $(".cstb-content textarea").get().reverse().forEach(a=>{$(a).attr('disabled',false);});
    },100)
  }
  public generateCctp(type) {
    //it's done when we need to add (to return)
    $.each($("input[type='checkbox']:checked + label"), function(){
      $(this).attr("selected", true);
    });
    $.each($("input[type='radio']:checked + label"), function(){
      $(this).attr("selected", true);
    });
    $.each($(".liradio:has(> input[type='radio']:checked)"), function(){
      $(this).attr("selected", true);
    });
    $.each($("textarea[id^='uis']"), function() {
      $(this).attr("value", $(this).val());
    })
    // html 2 json
    let cctpHtml = $('#cctp').html();
    let cctpJson = html2json(cctpHtml);
    // call to generation function
    let htmlGen = generateCCTP(cctpJson , 0);
    //remove first br
    htmlGen = htmlGen.replace("<h3><br>","<h3>")
    // Update statique cctp value
    this.cctpEditValue = htmlGen
    if(type == "replace"){
      this.store.dispatch(actions.setCctpText(this.cctpEditValue));
    }else{
      this.store.dispatch(actions.attachCctpText(this.cctpEditValue));
    }
    this.onSelectionChange('statique');
    this.switchTo = true;
  }

  public searchPost(){
    this.dpgfPostesService.searchElasticSearchPostsCSTB(this.searchTerm,13)
            .subscribe(res => {
              this.results=res.cstb;
              this.cd.markForCheck();
            })
  }

  public selectedPost(i){
    this.searchTerm='';
    this.cctpEdit = this.results[i];
    this.gid = this.results[i].gid || "";
    this.getCSTB(this.results[i]);
  }
  showFrigo(postFile){
    // return (["26720","26718","27571","27577"].includes(postFile.idFicheBase))||(!postFile.idPhase14 && (postFile.idNomenclature=='703' || postFile.idNomenclature=='946' || postFile.idNomenclature=='947' || postFile.idNomenclature=='948' || postFile.idNomenclature=='949' || postFile.idNomenclature=='950' || postFile.idNomenclature=='726'))
    return (["INIES_D[RE20210628_114606","INIES_D[RE20210628_114459","INIES_DTNP20211012_091507","INIES_DJEY20211012_091735"].includes(postFile.serialIdentifier))||(!postFile.phase14 && (postFile.idNomenclature=='703' || postFile.idNomenclature=='946' || postFile.idNomenclature=='947' || postFile.idNomenclature=='948' || postFile.idNomenclature=='949' || postFile.idNomenclature=='950' || postFile.idNomenclature=='726'))
  }
  addFileToPost(file,isChosen=false){
    if(isChosen) {
      file=this.chosenPost
      this.addingPostIniesEvent.emit({name:file.nom,addingPost:true})
    }
    file.poste=this.postIdInies;
    file.postName=(this.cctpEdit||{}).postDesignation;
    file.totalImpact=_.get(file,'impact.TotalCycleDeVie',0)

    // set phase 14
    let ind19=(file.indicateurs||[]).filter(ind=>ind.idIndicateur==19)
    let phase14;
    if(ind19.length) phase14=ind19[0].phases.filter(phase=>phase.idPhase==14)
    if((phase14||[]).length) file.phase14=phase14[0].quantite

    file.ceProject=this.ceproject;
    if((file.lotPCE||'').startsWith("Cette liste recense les composants ajoutés mais n'ayant été affilié à aucun Lot"))file.lotPCE='';
    if((file.lotPCE||'').startsWith("Ces composants concernent notamment les clôtures et la voirie hors aires de stationnement."))file.entity='Parcelle';
    this.loadingPosts=true;
    this.view="savedPosts";
    this.relatedFichesService.create(file).subscribe(
      (data)=>{
        this.relatedFiles.push(data);
        this.loadingPosts=false;
        file.addingPost=false;
        this.updateLeaf()
        if(isChosen)this.addingPostIniesEvent.emit({name:file.nom,addingPost:false})
        // this.store.dispatch(actions.setPosteData({loti : this.cctpEdit.loti,sousloti:this.cctpEdit.sousloti,posti:this.cctpEdit.posti,data:{postIniesCount:this.cctpEdit.postIniesCount+1}}));
        this.cctpEdit.postIniesCount=this.cctpEdit.postIniesCount+1;
        this.cd.detectChanges();
        // this.store.dispatch(actions.setCctp(this.cctpEdit))

        this.messageService.add({ key: 'toast', severity: 'success', summary: "Ajout d'une fiche INIES", detail: 'La fiche INIES a bien été ajoutée au poste', life: 5000 });
      },
      (err)=>{
        this.messageService.add({ key: 'toast', severity: 'error', summary: "Ajout d'une fiche INIES", detail: 'Echec', life: 5000 });
        // console.log(err);
      }
    )
  }
  addFrigoToPost(file){
    file.poste=this.postIdInies;
    file.frigoPostParent=this.frigoPostParent;
    if(this.frigoPostParent.frigogenePost) file._id=this.frigoPostParent.frigogenePost._id
    this.loadingPosts=true;
    let totalImpact=calculate(1,this.frigoPostParent,1,file);
    file.totalImpact=(totalImpact||{}).TotalCycleDeVie
    file.ceProject=this.ceproject;
    if(this.frigoPostParent.frigogenePost){
      this.relatedFichesService.update(file).subscribe(
        (data)=>{
          this.view="savedPosts";
          this.getAllPosts()
          this.loadingPosts=false;
          // this.cd.detectChanges();
        },
        (err)=>{
          console.log(err);
        }
      )
    }
    else{
      this.relatedFichesService.create(file).subscribe(
        (data)=>{
          this.view="savedPosts";
          this.getAllPosts()
          this.loadingPosts=false;
          // this.cd.detectChanges();
        },
        (err)=>{
          console.log(err);
        }
      )
    }
  }
  getAllPosts(){
    this.loadingPosts=true;
    this.relatedFichesService.listAll(this.postIdInies,'','',this.ceproject).subscribe(
      (data)=>{
        this.relatedFiles=data;
        // this.view='importPosts';
        // update post num count for leaf color
        this.updateLeaf()
        this.loadingPosts=false;
        this.cd.detectChanges()
      },
      (err)=>{
        this.loadingPosts=false;
        console.log(err);
      }
    )
  }
  getAllEntities(){
    // this.relatedFichesService.getEntities(this.ceproject._id).subscribe( // tocorrect
    this.relatedFichesService.getEntities(this.ceproject).subscribe(
      (data)=>{
        this.entities=[]
        for (const key in data) {
          // setting first batiemnt as by default
          this.selectedBatiment=key;
          // array choices in select
          let zones=data[key]
          let batimentObject = {label: `${key}`, value: `${key}`, type: 'batiment',zones:[]};
          for(let i = 0; i < zones.length; i++){
            let zoneObject = {label: `${zones[i]}`, value: `${zones[i]}`, type: 'zone'};
            // array choices in select
            batimentObject.zones.push(zoneObject);
          }
          this.entities.push(batimentObject)
        }
        this.cd.detectChanges();
      }
    )
  }
  updateLeaf(){
    let completedNum=this.relatedFiles.filter(post=>((post.contributor=='Chantier' && post.entity)||(post.contributor=='Composants' && post.entity && post.lotPCE))||(post.contributor=='Composants' && post.entity=='Parcelle')).length
    if(this.cctpEdit && this.cctpEdit.posti!=undefined && this.cctpEdit.sousPosteIndex==undefined&&this.displaySide){
      if(!this.relatedFiles.length){
        this.store.dispatch(actions.setPosteData({loti : this.cctpEdit.loti,sousloti:this.cctpEdit.sousloti,posti:this.cctpEdit.posti,data:{postIniesCount:this.relatedFiles.length}}));
        this.cctpEdit.postIniesCount=this.relatedFiles.length;
      }
      else if(completedNum!=this.relatedFiles.length){
        this.store.dispatch(actions.setPosteData({loti : this.cctpEdit.loti,sousloti:this.cctpEdit.sousloti,posti:this.cctpEdit.posti,data:{postIniesCount:this.relatedFiles.length,isIniesUncomplete:true}}));
        this.cctpEdit.isIniesUncomplete=true;
      }
      else {
        this.store.dispatch(actions.setPosteData({loti : this.cctpEdit.loti,sousloti:this.cctpEdit.sousloti,posti:this.cctpEdit.posti,data:{postIniesCount:this.relatedFiles.length,isIniesUncomplete:false}}));
        this.cctpEdit.isIniesUncomplete=false;
        this.cctpEdit.postIniesCount=this.relatedFiles.length;
      }
    }else if(this.cctpEdit && this.cctpEdit.posti!=undefined && this.cctpEdit.sousPosteIndex!=undefined&&this.displaySide){
      if(!this.relatedFiles.length){
        this.store.dispatch(actions.setSousPosteData({loti : this.cctpEdit.loti,sousloti:this.cctpEdit.sousloti,posti:this.cctpEdit.posti,sousPosteIndex:this.cctpEdit.sousPosteIndex,data:{postIniesCount:this.relatedFiles.length}}));
        this.cctpEdit.postIniesCount=this.relatedFiles.length;
      }
      else if(completedNum!=this.relatedFiles.length){
        this.store.dispatch(actions.setSousPosteData({loti : this.cctpEdit.loti,sousloti:this.cctpEdit.sousloti,posti:this.cctpEdit.posti,sousPosteIndex:this.cctpEdit.sousPosteIndex,data:{postIniesCount:this.relatedFiles.length,isIniesUncomplete:true}}));
        this.cctpEdit.isIniesUncomplete=true;
      }
      else {
        this.store.dispatch(actions.setSousPosteData({loti : this.cctpEdit.loti,sousloti:this.cctpEdit.sousloti,posti:this.cctpEdit.posti,sousPosteIndex:this.cctpEdit.sousPosteIndex,data:{postIniesCount:this.relatedFiles.length,isIniesUncomplete:false}}));
        this.cctpEdit.isIniesUncomplete=false;
        this.cctpEdit.postIniesCount=this.relatedFiles.length;
      }
    }
  }
  changeEntity(selectedBatiment,id){
    let i=this.relatedFiles.findIndex(post=>post._id==id)
    this.selectedBatiment=(selectedBatiment.target.value||'').split('^')[1];
    this.relatedFiles[i].entity=(selectedBatiment.target.value||'').split('^')[0]
    this.updatePost(this.relatedFiles[i]);
  }
  updatePost(postData){
    postData.batiment=this.selectedBatiment
    postData.entity=(postData.entity||'').split('^')[0]
    this.relatedFichesService.update(postData).subscribe(
      (data)=>{
        this.updateLeaf()
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  updateFrigogeneData(quantity,postFile){
    let post=_.get(postFile,'frigogenePost',{})
    post.frigogeneQuantity=quantity.target.value
    this.updatePost(post);
  }
  checkAllFiles(){
    if(this.selectedFiles.length==this.relatedFiles.length){
      this.selectedFiles=[];
      this.relatedFiles.forEach((elem,index) => {
        this.relatedFiles[index].checked=false;
      });
    }
    else {
      this.selectedFiles=[];
      this.relatedFiles.forEach((elem,index) => {
        this.relatedFiles[index].checked=true;
        this.selectedFiles.push(elem._id)
        this.checkedAllFiles=true
      });
    }
  }
  uncheckAllFiles(){
    this.relatedFiles.forEach((elem,index) => {
      this.relatedFiles[index].checked=false;
    });
  }
  checkOneFile(fileId){
    let index=this.selectedFiles.indexOf(fileId);
    let index2=this.relatedFiles.findIndex((a)=>a._id==fileId);
    if(index==-1) {
      this.selectedFiles.push(fileId)
      this.relatedFiles[index2].checked=true;
    }
    else {
      this.selectedFiles.splice(index,1);
      this.relatedFiles[index2].checked=false;
      this.checkedAllFiles=false;
    }
    if(this.selectedFiles.length==this.relatedFiles.length) this.checkedAllFiles=true;
  }
  deleteSelectedFiles(){
    let arr=this.relatedFiles.filter((a)=>a.checked==true)
    this.loadingPosts=true;
    this.relatedFichesService.deleteMany(arr).subscribe(
      (data)=>{
        this.relatedFiles=this.relatedFiles.filter((a)=>!a.checked);
        this.loadingPosts=false;
        // this.store.dispatch(actions.setPosteData({loti : this.cctpEdit.loti,sousloti:this.cctpEdit.sousloti,posti:this.cctpEdit.posti,data:{postIniesCount:this.cctpEdit.postIniesCount-arr.length}}));
        this.cctpEdit.postIniesCount=this.cctpEdit.postIniesCount-arr.length;
        this.selectedFiles=[];
        this.uncheckAllFiles()
        this.updateLeaf()
        this.checkedAllFiles=false        
        this.cd.detectChanges()
        // this.store.dispatch(actions.setCctp(this.cctpEdit))
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  duplicateSelectedFiles(){
    let arr=this.relatedFiles.filter((a)=>a.checked==true)
    this.loadingPosts=true;
    this.relatedFichesService.duplicate(arr).subscribe(
      (data)=>{
        this.relatedFiles=this.relatedFiles.concat(data);
        this.loadingPosts=false;
        this.store.dispatch(actions.setPosteData({loti : this.cctpEdit.loti,sousloti:this.cctpEdit.sousloti,posti:this.cctpEdit.posti,data:{postIniesCount:this.cctpEdit.postIniesCount+arr.length}}));
        this.cctpEdit.postIniesCount=this.cctpEdit.postIniesCount+arr.length;
        this.selectedFiles=[];
        this.uncheckAllFiles()
        this.checkedAllFiles=false
        this.cd.detectChanges();
        // this.store.dispatch(actions.setCctp(this.cctpEdit))
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  duplicateOneFile(file){
    this.loadingPosts=true;
    this.relatedFichesService.duplicate([file]).subscribe(
      (data)=>{
        this.relatedFiles.push(data[0]);
        this.loadingPosts=false;
        this.store.dispatch(actions.setPosteData({loti : this.cctpEdit.loti,sousloti:this.cctpEdit.sousloti,posti:this.cctpEdit.posti,data:{postIniesCount:this.cctpEdit.postIniesCount+1}}));
        this.cctpEdit.postIniesCount=this.cctpEdit.postIniesCount+1;
        this.cd.detectChanges();
        // this.store.dispatch(actions.setCctp(this.cctpEdit))
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  deleteOneFile(file){
    this.loadingPosts=true;
    this.relatedFichesService.deleteMany([file]).subscribe(
      (data)=>{
        this.relatedFiles=this.relatedFiles.filter((a)=>a._id!=file._id);
        this.loadingPosts=false;
        // this.store.dispatch(actions.setPosteData({loti : this.cctpEdit.loti,sousloti:this.cctpEdit.sousloti,posti:this.cctpEdit.posti,data:{postIniesCount:this.cctpEdit.postIniesCount-1}}));
        this.cctpEdit.postIniesCount=this.cctpEdit.postIniesCount-1;
        this.cd.detectChanges();
        this.updateLeaf()
        // this.store.dispatch(actions.setCctp(this.cctpEdit))
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  // products methods
  searchPostIniesSuggestion(){
    this.relatedFichesService.searchWord(this.searchFileTerm,this.filter,'true').subscribe(
      (data)=>{
        this.iniesSuggResult=data;
        this.iniesSuggResult=this.iniesSuggResult
        .filter((value, index, self) => self.map(x => x.categoryName).indexOf(value.categoryName) == index && ((value.categoryName||'').split('|')[1]||'').split('(')!='')
        this.iniesSuggResult=this.iniesSuggResult.slice(0,8)
        this.view="searchInterface";
        $('.autocomplete-items2').css({'visibility': 'visible'});
        this.cd.detectChanges()
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  searchPostInies(){
    this.loadingSearchPosts=true;
    $('.autocomplete-items2').css({'visibility': 'hidden'});
    this.relatedFichesService.searchWord(this.searchFileTerm,this.filter).subscribe(
      (data)=>{
        this.iniesPostPage=0;
        this.iniesResult=data;
        this.numPostsCategory={}
        this.calculatePostNumParents()
        this.loadingSearchPosts=false;
        this.view="searchInterface";
        this.cd.detectChanges()
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  getOneCategory(categoryId,fromPost=false){
    this.loadingCategories=true;
    this.loadingSearchPosts=true;
    this.view='searchInterface'
    if(fromPost){
      categoryId=((categoryId||'').split('|')[1]||'').split('(idParent:')[1].split(')')[0]
      this.searchFileTerm='';
    }
    this.filter.idCategory=categoryId;
    $('.autocomplete-items2').css({'visibility': 'hidden'});
    this.relatedFichesService.getCategory(categoryId,this.filter).subscribe(
      data=>{
        this.iniesPostPage=0;
        let keys=[];
        keys=Object.keys(data).filter(i => (data[i]||[]).length)
        keys.push('category')
        // keys.push('posts')
        this.selectedCategory=only(data,keys)
        this.iniesResult=this.selectedCategory['posts'];
        this.selectedCategory['category']=[this.selectedCategory['category']]
        if(this.selectedCategory['category'] && this.selectedCategory['category'].length){
          this.selectedCategory['category'][0].children=this.selectedCategory['children'];
          this.selectedCategory['category'][0].isSelected=true;
          delete this.selectedCategory['children']
        }
        if(this.selectedCategory['idParent'] && this.selectedCategory['idParent'].length){
          this.selectedCategory['idParent'][0].children=this.selectedCategory['category'];
          delete this.selectedCategory['category']
        }
        if(this.selectedCategory['parent0'] && this.selectedCategory['parent0'].length){
          this.selectedCategory['parent0'][0].children=this.selectedCategory['idParent'];
          delete this.selectedCategory['idParent']
        }
        if(this.selectedCategory['parent1'] && this.selectedCategory['parent1'].length){
          this.selectedCategory['parent1'][0].children=this.selectedCategory['parent0'];
          delete this.selectedCategory['parent0']
        }
        if(this.selectedCategory['parent2'] && this.selectedCategory['parent2'].length){
          this.selectedCategory['parent2'][0].children=this.selectedCategory['parent1'];
          delete this.selectedCategory['parent1'];
        }
        if(this.selectedCategory['parent3'] && this.selectedCategory['parent3'].length){
          this.selectedCategory['parent3'][0].children=this.selectedCategory['parent2'];
          delete this.selectedCategory['parent2']
        }
        let key=Object.keys(this.selectedCategory).filter(a=>a!='posts')||[]
        this.selectedCategory=this.selectedCategory[key[0]];
        // push root categories
        let allRoot=[{nom:"Equipements électriques, électroniques et de génie climatique",
        idCategory:"479",treeLevel:0},{nom:"Services",
        idCategory:"827",treeLevel:0},{nom:"Produits de construction",
        idCategory:"476",treeLevel:0}]
        allRoot=allRoot.filter(oneC=>oneC.idCategory!=this.selectedCategory[0].idCategory)
        this.selectedCategory.push(allRoot[0])
        this.selectedCategory.push(allRoot[1])
        //
        this.numPostsCategory={};
        this.loadingCategories=false;
        this.loadingSearchPosts=false;
        this.calculatePostNumParents()
        this.view='searchInterface';
        this.cd.detectChanges();
      },
      (err)=>console.log('err',err)
    )
  }
  changeView(view){
    this.view=view;
    // if(view=="searchInterface")this.listenCollapse();
    this.cd.detectChanges();
  }
  openFrirogeneModal(post){
    this.frigoPostParent=post;
    this.relatedFichesService.searchWord('',{isFrirogene:true},'true').subscribe(
      (data)=>{
        data.filter(post=>post.name)
        this.frirogeneResult.emit(data);
        $('#frirogene-modal').modal('show');
      }
    )
  }
  postFrigoCase(post){
    let phases=this.phaseToArray(post);
    // let cas=(post.declarationType==3||(!phases['11']&&!phases['12']&&!phases['13']&&!phases['14']))?"withoutBCO2":(phases['14']?"withBCO2":(post.declarationType==4?"FF":null))
    let cas=(post.declarationType==3 || ((post.idNorme==3 || post.idNorme==4) && !phases['14']))?"withoutBCO2":((phases['14'] && (post.declarationType==1||post.declarationType==2))?"withBCO2":(post.declarationType==4?"FF":"noCase"))
    return true;
  }
  phaseToArray(post){
    let phases=post.indicateurs.filter(indicator=>indicator.idIndicateur==19);
    let arr = new Array(30); for (let i=0; i<30; ++i) {
      arr[i]=0
    }
    phases=phases[0].phases;
    phases.forEach(element => {
      arr[element['idPhase']]=element['quantite'];
    });
    return arr;
  }
  openInfoModal(post){
    $('#post-info-modal').modal('show');
    // parse CategoryName
    post.categoryName=post.categoryName.replace(/ *\([^)]*\) */g, "").replaceAll('|',' > ');
    let categoryArray=post.categoryName.split('>');
    categoryArray=categoryArray.reverse()
    post.categoryName=categoryArray.join(">")
    post.categoryName=post.categoryName.substring(2);
    // parse name
    post.nom=this.parseName(post.nom)
    // parse documentPath
    post.documentPathArray=((post.documentPath||'').split(',')||[])
    // parse dateEdition
    let dateEdition=(new Date(post.dateEdition)||new Date());
    // dateEdition=dateEdition.split("/");
    // dateEdition[1]=(parseInt(dateEdition[1])+5).toString();
    // dateEdition.join("/")
    post.dateEdition=dateEdition.getMonth()+'/'+(dateEdition.getFullYear()+5)
    //parse indicateurs
    let indicateurs={};
    post.indicateurs.forEach(element => {
      let phases=(element['phases']||[]);
      let phase7=phases.find((phases)=>phases.idPhase==7)
      indicateurs[element['idIndicateur']]=phase7.quantite
    });
    let ind=[{
        name:"Impacts environnementaux",data:{
          "Réchauffement climatique (kg CO2 eq.)":(post.impact||{}).TotalCycleDeVie / post.quantiteUF||1,
          "Appauvrissement de la couche d'ozone (kg CFC-11 eq.)":indicateurs[20],
          "Acidification des sols et de l'eau (kg SO2 eq.)":indicateurs[21],
          "Eutrophisation (kg (PO4)3- eq.)":indicateurs[22],
          "Formation d'ozone photochimique (kg C2H4 eq.)":indicateurs[23],
          "Epuisement des ressources abiotiques - éléments (kg Sb eq.)":indicateurs[24],
          "Epuisement des ressources abiotiques - combustibles fossiles (MJ)":indicateurs[25],
          "Pollution de l'air (m³)":indicateurs[26],
          "Pollution de l'eau (m³)":indicateurs[27]
        }
      },{
        name:"Consommation des ressources",data:{
          "Utilisation de l'énergie primaire renouvelable, à l'exclusion des ressources d'énergie primaire ... (MJ)":indicateurs[28],
          "Utilisation des ressources d'énergie primaire renouvelables utilisées en tant que matières premières (MJ)":indicateurs[29],
          "Utilisation totale des ressources d'énergie primaire renouvelables ... (MJ)":indicateurs[30],
          "Utilisation de l'énergie primaire non renouvelable, à l'exclusion ... (MJ)":indicateurs[31],
          "Utilisation des ressources d'énergie primaire non renouvelables utilisées en tant que matières premières (MJ)":indicateurs[32],
          "Utilisation totale des ressources d'énergie primaire non renouvelables ... (MJ)":indicateurs[33],
          "Utilisation de matière secondaire (kg)":indicateurs[34],
          "Utilisation de combustibles secondaires renouvelables (MJ)":indicateurs[35],
          "Utilisation de combustibles secondaires non renouvelables (MJ)":indicateurs[36],
          "Utilisation nette d'eau douce (m³)":indicateurs[37],
        }
      },{
        name:"Déchets",data:{
        "Déchets dangereux éliminés (kg)":indicateurs[38],
        "Déchets non dangereux éliminés (kg)":indicateurs[39],
        "Déchets radioactifs éliminés (kg)":indicateurs[40],
        }
      },{
      name:"Flux sortants",data:{
        "Composants destinés à la réutilisation (kg)":indicateurs[41],
        "Matériaux destinés au recyclage (kg)":indicateurs[42],
        "Matériaux destinés à la récupération d'énergie (kg)":indicateurs[43],
        "Energie fournie à l'extérieur (MJ)":indicateurs[44],
      }}]
    post.ind=ind;
    this.infoPost.emit(post);
  }
  getPostOpenModal(post){
    this.relatedFichesService.getIniesPosts([post.idFicheBase]).subscribe(
      (data)=>{
        this.openInfoModal(data[0])
      }
    )
  }
  calculateTotal(){
    let result=0;
    for (let index = 0; index < this.relatedFiles.length; index++) {
      let frigoImpact=_.get(this.relatedFiles[index],'frigogenePost.totalImpact',0);
      let frigogeneQuantity=_.get(this.relatedFiles[index],'frigogenePost.frigogeneQuantity',0);
      result+=this.relatedFiles[index].totalImpact*this.relatedFiles[index].quantity;
      if(frigoImpact)result+=frigoImpact*frigogeneQuantity;
    }
    return result;
  }
  openConfirmationPost(post){
    this.chosenPost=post
    this.addingPostIniesEvent.emit({name:post.nom,addingPost:false})
    $('#add-post-modal').modal('show');
  }
  setFilterAttribute(value,attribute,event=null){
    if(attribute=="declarationType") {
      // $(".one-chip").removeClass (function (index, className) {
      //   return (className.match (/(^|\s)declarationTypeChip\S+/g) || []).join(' ');
      // });
      let index=this.filter.declarationType.findIndex(a=>a==value)
      if(index!=-1) this.filter.declarationType.splice(index,1);
      else this.filter.declarationType.push(value)
      if($(event.target).hasClass(`declarationTypeChip${value}`)) $(event.target).removeClass(`declarationTypeChip${value}`)
      else $(event.target).addClass(`declarationTypeChip${value}`)
    }
    else if(attribute=="airRating"){
      let index=this.filter.airRating.findIndex(a=>a==value)
      if(index!=-1) this.filter.airRating.splice(index,1);
      else this.filter.airRating.push(value)
    }
    else this.filter[attribute]=value;
  }
  resetFilter(){
    this.filter={productionPlace:{"Hors Europe":false,"Europe":false,"France":false},performanceUF:'Toutes',dateMiseEnLigne:"Toutes",organismeName:"",declarationType:[1,2,3,4],idCategory:'',airRating:[]};
    this.selectedCategory=[{nom:"Produits de construction",
        idCategory:"476",treeLevel:0},{nom:"Equipements électriques, électroniques et de génie climatique",
        idCategory:"479",treeLevel:0},{nom:"Services",
        idCategory:"827",treeLevel:0}]
    $('.one-ticket').removeClass("selected")
    for (let index = 1; index < 5; index++) {
      if(!$('.chip'+index).hasClass(`declarationTypeChip${index}`)) $('.chip'+index).addClass(`declarationTypeChip${index}`)
    }
  }
  setSelected(event){
    if($(event.target).hasClass("selected")) $(event.target).removeClass("selected")
    else $(event.target).addClass("selected")
  }
  openModal(id){
    $(id).modal('show');
  }
  openRemploi(id){
    this.openRemploiEvent.emit(id);
  }
  setaddingPostInies(event){
    this.addingPostIniesEvent.emit(event);
  }
  sendEventFather(event,type){
    if(type=='setPost') this.openConfirmationPost(event);
    else if(type=='setInfo') this.infoPost.emit(event)
    else if(type=='frirogene') this.frirogeneResult.emit(event)
  }
  searchOrganisme(){
    this.relatedFichesService.getOrganismeSugg(this.filter.organismeName).subscribe(
      (data)=>{
        this.organismeSugg=data;
        $('.autocomplete-items-organisme').css({'visibility': 'visible'});
        this.cd.detectChanges()
      }
    )
  }
  selectOrganisme(selected){
    this.filter.organismeName=selected;
    $('.autocomplete-items-organisme').css({'visibility': 'hidden'});
  }
  clearInput(attribute){
    if(this.filter[attribute]=="Toutes")this.filter[attribute]="";
  }
  unclearInput(attribute){
    if(this.filter[attribute]=="")this.filter[attribute]="Toutes";
  }
  resetValues(){
    this.searchFileTerm='';
    this.selectedFiles=[];
    this.checkedAllFiles=false
    $('.autocomplete-items2').css({'visibility': 'hidden'});
    this.resetFilter()
  }
  parseName(name){
    return name.split('- ID')[0]
  }
  setVisibilityDropdown(id,visibility){
    setTimeout(()=>$(id).css({'visibility': visibility}),10);
  }
  setPostPCE(oneLot,i){
    this.relatedFiles[i].lotPCE=oneLot
    this.updatePost(this.relatedFiles[i])
  }
  filterLotsPCE(word){
    let lots=[
      {
        name: "1- VRD",
         lots: ["1.1 Réseaux (extérieurs, jusqu'au domaine public)",
          "1.2 Stockage",
          "1.3 Aires de stationnement extérieures"]
      },
      {
        name: "2- Fondation et infrastructure",
        lots: ["2.1 Fondations",
          "2.2 Murs et structures enterrées ( escalier de cave, parking, …)",
          "2.3 Parcs de stationnement en superstructure à l'exception des garages des maisons individuelles ou accolées",
        ]
      },
      {
        name: "3- Maçonnerie et superstructure",
        lots: ["3.1 Eléments horizontaux - planchers, dalles, balcons",
          "3.2 Eléments horizontaux - poutres",
          "3.3 Eléments verticaux - façades",
          "3.4 Eléments verticaux - refends",
          "3.5 Eléments verticaux - poteaux",
          "3.6 Escaliers et rampes",
          "3.7 Eléments d'isolation",
          "3.8 Maçonneries diverses",]
      },
      {
        name: "4- Couverture - Etanchéité - Charpente - Zinguerie",
        lots: ["4.1 Toitures terrasse",
          "4.2 Toitures en pente",
          "4.3 Eléments techniques de toiture",]
      },
      {
        name: "5- Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures",
        lots: ["5.1 Cloisons et portes intérieures",
          "5.2 Doublages mur, matériaux de protection, isolants et membranes",
          "5.3 Plafonds suspendus",
          "5.4 Planchers surélevés",
          "5.5 Menuiseries, metalleries et quincalleries",]
      },
      {
        name: "6- Façades et menuiseries extérieures",
        lots: ["6.1 Revêtement, isolation et doublage extérieur",
          "6.2 Portes, fenêtres, fermetures, protections solaires",
          "6.3 Habillages et ossatures",]
      },
      {
        name: "7- Revêtements des sols, murs et plafonds - Chape - Peintures - Produits de décoration",
        lots: ["7.1 Revêtement des sols",
          "7.2 Revêtement des murs et plafonds",
          "7.3 Eléments de décoration et revêtements des menuiseries",]
      },
      {
        name: "8- CVC",
        lots: ["8.1 Equipements de production (chaud/froid) [hors cogénération]",
          "8.2 Systèmes de cogénération",
          "8.3 Systèmes d'émission",
          "8.4 Traitement de l'air et éléments de désenfumage",
          "8.5 Réseaux et conduits",
          "8.6 Stockage",
          "8.7 Fluides frigorigènes",]
      },
      {
        name: "9- Installations sanitaires",
        lots: ["9.1 Eléments sanitaires et robinetterie",
          "9.2 Canalisations, résaux et systèmes de traitement",]
      },
      {
        name: "10- Réseaux d'énergie (courant fort)",
        lots: ["10.1 Réseaux éléctriques",
          "10.2 Ensemble de dispositif pour la sécurité",
          "10.3 Eclairage intérieur",
          "10.4 Eclairage extérieur",
          "10.5 Equipements spéciaux",
          "10.6 Installations techniques",]
      },
      {
        name: "11- Réseaux de communication (courant faible)",
        lots: [ "11.1 Réseaux éléctriques et de communications",
          "11.2 Réseaux et systèmes de contrôle et régulation",
          "11.3 Installations techniques et équipements spéciaux",]
      },
      {
        name: "12- Appareils élévateurs",
        lots: ["12 Appareils élévateurs",]
      },
      {
        name: "13- Equipements de production locale d'éléctricité",
        lots: ["13 Equipements de production locale d'éléctricité"]
      }
    ];
    for (let i = 0; i < this.lotsPCECopy.length; i++) {
      this.lotsPCECopy[i].lots=lots[i].lots.filter(lot=>lot.toLowerCase().includes(word.toLowerCase()))
    }
  }
  filterPerf(){
    this.performanceUFArrayCopy=this.performanceUFArray.filter(a=>a.toLowerCase().includes(this.filter.performanceUF.toLowerCase()))
  }
  calculatePostNum(category){
    let children=_.get(category,'children',[]);
    children.forEach(element => {
      for (let i = 0; i < this.iniesResult.length; i++) {
        if(element.idCategory==this.iniesResult[i].idNomenclature || element.idCategory==this.iniesResult[i].idNomenclature2 || element.idCategory==this.iniesResult[i].idNomenclature3) this.numPostsCategory[element.idCategory]=(this.numPostsCategory[element.idCategory]||0)+1
      }
      if(_.get(element,'children.length',0)){
        this.calculatePostNum(element)
      }
    });
  }
  calculatePostNumParents(){
    for (let index = 0; index < this.iniesResult.length; index++) {
      for (let i = 0; i < ((this.iniesResult[index].categoryName||'').split('|')).length-1; i++) {
        let categoryId=((this.iniesResult[index].categoryName||'').split('|')[i]||'').split('(idParent:')[1].split(')')[0]
        if(categoryId)this.numPostsCategory[categoryId]=(this.numPostsCategory[categoryId]||0)+1
      }
    }
  }
  public listenForsearchTerm(){
    this.searchTermUpdate.pipe(
      debounceTime(600),
      distinctUntilChanged()
    ).subscribe(value => {
      this.searchPost();
    })
  }
    public listenForProductSearch(){
    this.searchProductTermUpdate.pipe(
      debounceTime(600),
      distinctUntilChanged()
    ).subscribe(value => {
      this.searchProducts();
    })
  }
  public searchProducts(){
    if(this.searchProductTerm == ''){
      return;
    }
    let body = {
      search_query : this.searchProductTerm
    }
    this.productsService.searchProducts(body).subscribe(
      (data) => {
        this.products = data['data']['hits'];
        this.showAddedProducts = false;
        this.cd.detectChanges();
      }
    )
  }
  public openConfirmProduct(product){
    $('#confirm-add-product-modal').modal('show');
    this.productsService.selectedScrappedProduct.next(product);
    this.productsService.selectedPost.next(this.cctpEdit$);
    this.selectedProduct = product;
  }
  public getAddedProducts(){
    this.subscriptions.push(
      this.productsService.getPostProducts(this.postIdInies).subscribe(
        (data) => {
          if(data['data']){
            this.addedProducts = data['data']['products'];
            this.cd.detectChanges();
          }
        }
      )
    );
  }
  public saveProduct(){
    let body = {
      postId: this.postIdInies,
      product: this.selectedProduct
    }

    this.subscriptions.push(
      this.productsService.createPostToProduct(body).subscribe(
        (data) => {
          this.addedProducts.push(this.selectedProduct);
          this.showAddedProducts = true;
          $('#confirm-add-product-modal').modal('hide');
          this.cd.detectChanges();
        }
      )
    )
  }
  public openRemoveProductModal(product){
    $('#confirm-remove-product-modal').modal('show');
    this.selectedRemoveProduct = product;

  }
  public removeProduct(){
    this.subscriptions.push(
      this.productsService.deletePostProduct(this.postIdInies, this.selectedRemoveProduct.ref).subscribe(
        (data) => {
          this.addedProducts = this.addedProducts.filter((p) => p.ref != this.selectedRemoveProduct.ref);
          this.cd.detectChanges();
          $('#confirm-remove-product-modal').modal('hide');
        }
      )
    )
  }

  public changeProductsView(){
    this.showAddedProducts = !this.showAddedProducts;
  }
  private getUserById(){
    this.subscriptions.push(
      this.authService.getUser(this.currentUser.id).subscribe(
        (res)=>{
           this.user = res;
        },
        (err)=>{
          console.log(err.message)
        }
      )
    )
  }
  public goToAgloGPTGenerator(poste){
    let licencesPro = ['plus','plus-trim','plus-annuel']
    if(licencesPro.includes(this.currentUser.licence)){
      $("#gpt-expired-pro").modal('show');
      $("#gpt-expired-pro .modal-dialog").css('margin', '30% 25%');    
      // $(".modal-backdrop").css('z-index', '1000');
      $(".modal-backdrop.fade.in").hide();
      return;
    }else if(((this.user||{}).dateEndTrial && this.user.gptCount<1) || this.currentUser.licence=='without'){
      $("#gpt-expired-free").modal('show');
      $("#gpt-expired-free .modal-dialog").css('margin', '30% 25%');    
      // $(".modal-backdrop").css('z-index', '1000');
      $(".modal-backdrop.fade.in").hide();
      return;
    }
    this.agloGptSelectedPostType = poste;
    this.loadingData=true;
    this.divVisibility=false;
    this.subscriptions.push(
      this.aiservice.genererCttpUsingAI({poste:poste,gptCount:(this.user||{}).gptCount}).subscribe(
        (res)=>{
          this.loadingData=false;
          this.divVisibility=true;
          this.result = res.data;
          console.log('this.user',this.user,'this.user.gptCount',this.user.gptCount)
          this.user.gptCount = (this.user||{}).gptCount>0 ?this.user.gptCount-1:0;
          this.setScoreColor(Math.floor(res.score*100) );
          this.cd.detectChanges();
          this.oldScore = Math.floor(res.score*100);
          if(!environment.onPremise)window.analytics.track('generate_cctp',{properties:{'requete':res.data,'fiabilite':Math.floor(res.score*100)}});
      },
      (err)=>{
        console.log(err);
        $("#chatgpt-regenerate-modal").modal("show");
        $("#chatgpt-regenerate-modal .modal-dialog").css('margin', '30% 25%');    
        $(".modal-backdrop").css('z-index', '1000');
        this.loadingData=false;
        this.divVisibility=false;
      })
    );
  }
  public regenerateCCTP(){
    $("#chatgpt-regenerate-modal").modal("hide");
    $(".modal-backdrop").css('z-index', '1040');
    this.goToAgloGPTGenerator(this.agloGptSelectedPostType);
  }

  public addCttpDescToTextEditor(cctpDescription){
    cctpDescription = cctpDescription.replace(/\n/g,'<br/>').replace(/\r/g,'<br/>');
    if(!environment.onPremise)window.analytics.track('add_cctp_gpt',{properties:{posteName:this.agloGptSelectedPostType,'requete':cctpDescription,'fiabilite':Math.floor(this.score*100)}});
    // this.cctpEdit.cctp=cctpDescription;
    this.addCctpText(cctpDescription);
    this.divVisibility=false;
  }
  public annuler(cctpDescription){
    this.divVisibility=false;
    if(!environment.onPremise)window.analytics.track('generate_cctp',{properties:{acValue: 'annuler_cctp','requete':cctpDescription,'fiabilite':this.score}});
    this.cd.detectChanges()
  }
  public regenererCctp(poste,cctpDescription){
    if(!environment.onPremise)window.analytics.track('regenerate_cctp',{properties:{posteNom:poste,'requete':cctpDescription,'fiabilite':Math.floor(this.score*100),oldFiabilite:this.oldScore}});
    this.goToAgloGPTGenerator(poste);
  }
  private setScoreColor(score){
    this.score = score;
    if(score > 85){
      this.color ='green';
    }
    else if(score < 75){
      this.color ='red';
    }
    else{
      this.color ='orange';
    }
  }
  public setValueAndSwitch(switchTo,value){
    this.value=value;
    this.switchTo=switchTo;
  }
  private unsubscribeFromAllSubscriptions(){
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }


  public ngOnDestroy(): void {
    this.checkedAllFiles=false;
    this.unsubscribeFromAllSubscriptions();
  }
}
