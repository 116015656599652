export const reseauFroidOptions = [
  {id: "0608;F", nomReseau: "BET CAP AZUR froid", Commune: "Roquebrune", contenuCO2: "0,014", contenuCO2ACV: "0,026", tauxEnrR: "-" },
  {id: "1106;F", nomReseau: "MCO CEF", Commune: "Carcassonne", contenuCO2: "0,057", contenuCO2ACV: "0,07", tauxEnrR: "-" },
  {id: "1301;F", nomReseau: "Réseau de froid Thassalia Marseille", Commune: "MARSEILLE", contenuCO2: "0,011", contenuCO2ACV: "0,023", tauxEnrR: "-" },
  {id: "1302;F", nomReseau: "Réseau du pôle Yvon Morandat", Commune: "Gardanne", contenuCO2: "0,015", contenuCO2ACV: "0,029", tauxEnrR: "-" },
  {id: "1322;F", nomReseau: "BET MASSILEO froid", Commune: "Marseille", contenuCO2: "0,025", contenuCO2ACV: "0,044", tauxEnrR: "-" },
  {id: "3101;F", nomReseau: "CANCEROPOLE", Commune: "Toulouse", contenuCO2: "0,011", contenuCO2ACV: "0,022", tauxEnrR: "-" },
  {id: "3112;F", nomReseau: "Toulouse energie durable (TED) froid", Commune: "TOULOUSE", contenuCO2: "0,01", contenuCO2ACV: "0,021", tauxEnrR: "-" },
  {id: "3301;F", nomReseau: "LASERIS", Commune: "LE BARP", contenuCO2: "0,017", contenuCO2ACV: "0,031", tauxEnrR: "-" },
  {id: "3302;F", nomReseau: "BASSINS A FLOT - Froid", Commune: "BORDEAUX", contenuCO2: "0,012", contenuCO2ACV: "0,023", tauxEnrR: "-" },
  {id: "3401;F", nomReseau: "Polygone Antigone", Commune: "Montpellier", contenuCO2: "0,061", contenuCO2ACV: "0,078", tauxEnrR: "-" },
  {id: "3402;F", nomReseau: "Ernest Granier", Commune: "Montpellier", contenuCO2: "0,013", contenuCO2ACV: "0,025", tauxEnrR: "-" },
  {id: "3403;F", nomReseau: "Port Marianne/Odysseum", Commune: "MONTPELLIER", contenuCO2: "0,012", contenuCO2ACV: "0,023", tauxEnrR: "-" },
  {id: "3406;F", nomReseau: "Réseau arche Jacques Coeur", Commune: "Montpellier", contenuCO2: "0,013", contenuCO2ACV: "0,025", tauxEnrR: "-" },
  {id: "3411;F", nomReseau: "MUSE", Commune: "MONTPELLIER", contenuCO2: "0,008", contenuCO2ACV: "0,016", tauxEnrR: "-" },
  {id: "3412;F", nomReseau: "BET BOIRARGUES froid", Commune: "Lattes", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "-" },
  {id: "3414;F", nomReseau: "BET EUREKA froid", Commune: "Castelnau-le-Lez", contenuCO2: "0,008", contenuCO2ACV: "0,016", tauxEnrR: "-" },
  {id: "3802;F", nomReseau: "Réseau froid CCIAG Grenoble", Commune: "Grenoble", contenuCO2: "0,016", contenuCO2ACV: "0,029", tauxEnrR: "-" },
  {id: "4213;F", nomReseau: "Réseaux de Froid VIACONFORT", Commune: "Saint-Etienne", contenuCO2: "0,013", contenuCO2ACV: "0,026", tauxEnrR: "-" },
  {id: "5714;F", nomReseau: "Réseau Zac Amphithéâtre", Commune: "METZ", contenuCO2: "0,012", contenuCO2ACV: "0,023", tauxEnrR: "-" },
  {id: "6905;F", nomReseau: "Réseau de Lyon", Commune: "LYON", contenuCO2: "0,011", contenuCO2ACV: "0,021", tauxEnrR: "-" },
  {id: "7503;F", nomReseau: "Réseau Climespace", Commune: "PARIS", contenuCO2: "0,007", contenuCO2ACV: "0,016", tauxEnrR: "-" },
  {id: "7619;F", nomReseau: "ZAC Luciline", Commune: "ROUEN", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "-" },
  {id: "9101;F", nomReseau: "Réseau Paris Saclay", Commune: "Saclay", contenuCO2: "0,031", contenuCO2ACV: "0,053", tauxEnrR: "-" },
  {id: "9219;F", nomReseau: "Réseau froid Seguin Rives de Seine", Commune: "BOULOGNE-BILLANCOURT", contenuCO2: "0,014", contenuCO2ACV: "0,026", tauxEnrR: "-" },
  {id: "9225;F", nomReseau: "Réseau de La Défense", Commune: "Courbevoie", contenuCO2: "0,012", contenuCO2ACV: "0,023", tauxEnrR: "-" },
  {id: "9231;F", nomReseau: "Réseau Suc - Société Urbaine de Climatisation", Commune: "COURBEVOIE", contenuCO2: "0,01", contenuCO2ACV: "0,02", tauxEnrR: "-" },
  {id: "9234;F", nomReseau: "Cristalia", Commune: "Levallois-Perret", contenuCO2: "0,009", contenuCO2ACV: "0,019", tauxEnrR: "-" },
  {id: "9235;F", nomReseau: "Réseau de froid de Suresnes", Commune: "Suresnes", contenuCO2: "0,018", contenuCO2ACV: "0,033", tauxEnrR: "-" },
  {id: "9310;F", nomReseau: "Réseau froid ADP Le Bourget", Commune: "LE BOURGET", contenuCO2: "0,043", contenuCO2ACV: "0,073", tauxEnrR: "-" },
  {id: "9322;F", nomReseau: "Stade Energies SAS", Commune: "Saint-Denis", contenuCO2: "0,005", contenuCO2ACV: "0,012", tauxEnrR: "-" },
  {id: "9425;F", nomReseau: "Réseau froid ADP Orly", Commune: "Orly", contenuCO2: "0,008", contenuCO2ACV: "0,017", tauxEnrR: "-" },
  {id: "9510;F", nomReseau: "Réseau froid ADP Roissy", Commune: "ROISSY-EN-FRANCE", contenuCO2: "0,01", contenuCO2ACV: "0,02", tauxEnrR: "-" },
]

export const reseauChaudOptions = [
  {id: "0101;C", nomReseau: "Quartier de La Reyssouze", Commune: "Bourg-en-Bresse", contenuCO2: "0,165", contenuCO2ACV: "0,194", tauxEnrR: "0,257" },
  {id: "0103;C", nomReseau: "Oyonnax BioChaleur", Commune: "Oyonnax", contenuCO2: "0,064", contenuCO2ACV: "0,095", tauxEnrR: "0,715" },
  {id: "0105;C", nomReseau: "Réseau de saint-denis-les-bourg", Commune: "SAINT-DENIS-LES-BOURG", contenuCO2: "0,059", contenuCO2ACV: "0,085", tauxEnrR: "0,8" },
  {id: "0106;C", nomReseau: "Belena", Commune: "BELLEY", contenuCO2: "0,036", contenuCO2ACV: "0,061", tauxEnrR: "0,868" },
  {id: "0107;C", nomReseau: "xavier chirol", Commune: "TREFFORT-CUISIAT", contenuCO2: "0,045", contenuCO2ACV: "0,073", tauxEnrR: "0,875" },
  {id: "0108;C", nomReseau: "Bourg-en-Bresse - La Vinaigrerie", Commune: "BOURG-EN-BRESSE", contenuCO2: "0,044", contenuCO2ACV: "0,067", tauxEnrR: "0,825" },
  {id: "0109;C", nomReseau: "Réseau chauffage DORTAN", Commune: "DORTAN", contenuCO2: "0,066", contenuCO2ACV: "0,094", tauxEnrR: "0,764" },
  {id: "0110;C", nomReseau: "Réseau de chaleur ASSURC à Bourg-en-Bresse", Commune: "Bourg-en-Bresse", contenuCO2: "0,208", contenuCO2ACV: "0,224", tauxEnrR: "0,15" },
  {id: "0201;C", nomReseau: "Zup du Quartier Europe", Commune: "Saint-Quentin", contenuCO2: "0,088", contenuCO2ACV: "0,135", tauxEnrR: "0,511" },
  {id: "0202;C", nomReseau: "Zup de Presles", Commune: "Soissons", contenuCO2: "0,101", contenuCO2ACV: "0,154", tauxEnrR: "0,483" },
  {id: "0203;C", nomReseau: "Réseau de Laon", Commune: "BARENTON BUGNY", contenuCO2: "0,053", contenuCO2ACV: "0,079", tauxEnrR: "0,815" },
  {id: "0204;C", nomReseau: "Réseau d'Urcel", Commune: "URCEL", contenuCO2: "0,039", contenuCO2ACV: "0,064", tauxEnrR: "0,863" },
  {id: "0205;C", nomReseau: "Réseau de Château Thierry", Commune: "CHATEAU-THIERRY", contenuCO2: "0,028", contenuCO2ACV: "0,051", tauxEnrR: "0,89" },
  {id: "0301;C", nomReseau: "SDC Moulins CHAMPINS", Commune: "MOULINS", contenuCO2: "0,062", contenuCO2ACV: "0,094", tauxEnrR: "0,676" },
  {id: "0302;C", nomReseau: "Quartier Fontbouillant et Bien-Assis", Commune: "MONTLUCON", contenuCO2: "0,1", contenuCO2ACV: "0,132", tauxEnrR: "0,546" },
  {id: "0303;C", nomReseau: "Réseau de Cerilly", Commune: "Cerilly", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "0304;C", nomReseau: "Réseau de Meaulne", Commune: "MEAULNE", contenuCO2: "0", contenuCO2ACV: "0,063", tauxEnrR: "1" },
  {id: "0305;C", nomReseau: "Réseau du Mayet-de-Montagne", Commune: "LE MAYET-DE-MONTAGNE", contenuCO2: "0,009", contenuCO2ACV: "0,037", tauxEnrR: "0,978" },
  {id: "0306;C", nomReseau: "Réseau - Bellenaves", Commune: "BELLENAVES", contenuCO2: "0", contenuCO2ACV: "0,017", tauxEnrR: "1" },
  {id: "0307;C", nomReseau: "Réseau de Commentry", Commune: "COMMENTRY", contenuCO2: "0,032", contenuCO2ACV: "0,056", tauxEnrR: "0,883" },
  {id: "0308;C", nomReseau: "Réseau de chaleur d'Ebreuil", Commune: "EBREUIL", contenuCO2: "0,015", contenuCO2ACV: "0,04", tauxEnrR: "0,965" },
  {id: "0309;C", nomReseau: "SDC Moulins Ville", Commune: "MOULINS", contenuCO2: "0,073", contenuCO2ACV: "0,115", tauxEnrR: "0,676" },
  {id: "0310;C", nomReseau: "Réseau de chaleur de Cusset", Commune: "Cusset", contenuCO2: "0,028", contenuCO2ACV: "0,05", tauxEnrR: "0,888" },
  {id: "0401;C", nomReseau: "RCU Manosque Zac Chanteprunier", Commune: "Manosque", contenuCO2: "0,068", contenuCO2ACV: "0,092", tauxEnrR: "0,718" },
  {id: "0402;C", nomReseau: "Réseau de la chaufferie La Tomie", Commune: "FORCALQUIER", contenuCO2: "0,211", contenuCO2ACV: "0,226", tauxEnrR: "0,276" },
  {id: "0404;C", nomReseau: "Réseau communal d'Allos", Commune: "ALLOS", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "0405;C", nomReseau: "Réseau communal de Castellane", Commune: "CASTELLANE", contenuCO2: "0", contenuCO2ACV: "0,02", tauxEnrR: "1" },
  {id: "0406;C", nomReseau: "Réseau communal de Barcelonnette", Commune: "BARCELONNETTE", contenuCO2: "0,112", contenuCO2ACV: "0,151", tauxEnrR: "0,702" },
  {id: "0501;C", nomReseau: "Réseau de chaleur bois Delaroche", Commune: "Embrun", contenuCO2: "0,016", contenuCO2ACV: "0,042", tauxEnrR: "0,954" },
  {id: "0502;C", nomReseau: "Quartier gare", Commune: "EMBRUN", contenuCO2: "0,005", contenuCO2ACV: "0,027", tauxEnrR: "0,986" },
  {id: "0504;C", nomReseau: "Réseau de l'Association La Chrysalide", Commune: "TALLARD", contenuCO2: "0,089", contenuCO2ACV: "0,115", tauxEnrR: "0,675" },
  {id: "0505;C", nomReseau: "Réseau de Veynes", Commune: "VEYNES", contenuCO2: "0,179", contenuCO2ACV: "0,2", tauxEnrR: "0,503" },
  {id: "0506;C", nomReseau: "Réseau de Briançon", Commune: "BRIANCON", contenuCO2: "0,049", contenuCO2ACV: "0,077", tauxEnrR: "0,84" },
  {id: "0507;C", nomReseau: "REMPARTS", Commune: "EMBRUN", contenuCO2: "0,025", contenuCO2ACV: "0,054", tauxEnrR: "0,942" },
  {id: "0601;C", nomReseau: "SONITHERM - Réseau de l'Ariane", Commune: "Nice", contenuCO2: "0,01", contenuCO2ACV: "0,015", tauxEnrR: "0,966" },
  {id: "0602;C", nomReseau: "Saint-Augustin (HLM)", Commune: "Nice", contenuCO2: "0,23", contenuCO2ACV: "0,258", tauxEnrR: "0" },
  {id: "0603;C", nomReseau: "Ranguin", Commune: "Cannes", contenuCO2: "0,079", contenuCO2ACV: "0,151", tauxEnrR: "0,488" },
  {id: "0605;C", nomReseau: "réseau chaleur et froid chaine de vie 06", Commune: "LEVENS", contenuCO2: "0,244", contenuCO2ACV: "0,416", tauxEnrR: "0,93" },
  {id: "0606;C", nomReseau: "MSE Réseau chaud", Commune: "NICE", contenuCO2: "0,266", contenuCO2ACV: "0,314", tauxEnrR: "0" },
  {id: "0608;C", nomReseau: "BET CAP AZUR chaud", Commune: "Roquebrune", contenuCO2: "0,043", contenuCO2ACV: "0,023", tauxEnrR: "0,662" },
  {id: "0701;C", nomReseau: "Réseau d'Aubenas", Commune: "AUBENAS", contenuCO2: "0,028", contenuCO2ACV: "0,052", tauxEnrR: "0,91" },
  {id: "0702;C", nomReseau: "Réseau du Cheylard", Commune: "LE CHEYLARD", contenuCO2: "0,021", contenuCO2ACV: "0,045", tauxEnrR: "0,95" },
  {id: "0703;C", nomReseau: "Chaufferie bois", Commune: "VALGORGE", contenuCO2: "0", contenuCO2ACV: "0,035", tauxEnrR: "1" },
  {id: "0704;C", nomReseau: "Réseau de chaleur de Banne", Commune: "BANNE", contenuCO2: "0", contenuCO2ACV: "0,043", tauxEnrR: "1" },
  {id: "0705;C", nomReseau: "Réseau de chaleur communal de Burzet", Commune: "BURZET", contenuCO2: "0", contenuCO2ACV: "0,03", tauxEnrR: "1" },
  {id: "0706;C", nomReseau: "Réseau de la chaufferie bois de Chalencon", Commune: "CHALENCON", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "0707;C", nomReseau: "Réseau de la chaufferie bois", Commune: "MONTPEZAT-SOUS-BAUZON", contenuCO2: "0", contenuCO2ACV: "0,023", tauxEnrR: "1" },
  {id: "0801;C", nomReseau: "Réseau chaufferie communale", Commune: "Ecordal", contenuCO2: "0", contenuCO2ACV: "0,018", tauxEnrR: "1" },
  {id: "0802;C", nomReseau: "La Citadelle", Commune: "CHARLEVILLE-MEZIERES", contenuCO2: "0,127", contenuCO2ACV: "0,18", tauxEnrR: "0,41" },
  {id: "0803;C", nomReseau: "Zup de Sedan", Commune: "SEDAN", contenuCO2: "0,108", contenuCO2ACV: "0,143", tauxEnrR: "0,569" },
  {id: "0804;C", nomReseau: "Réseau de chaleur bois de Machault", Commune: "MACHAULT", contenuCO2: "0,112", contenuCO2ACV: "0,16", tauxEnrR: "0,791" },
  {id: "0805;C", nomReseau: "Réseau de chaleur au bois de Clavy Warby", Commune: "CLAVY-WARBY", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "0806;C", nomReseau: "Ronde couture", Commune: "CHARLEVILLE-MEZIERES", contenuCO2: "0,103", contenuCO2ACV: "0,126", tauxEnrR: "0,604" },
  {id: "0808;C", nomReseau: "Réseau de chaleur de Rocroi", Commune: "ROCROI", contenuCO2: "0,067", contenuCO2ACV: "0,098", tauxEnrR: "0,809" },
  {id: "0809;C", nomReseau: "Réseau de chaleur bois de Lalobbe", Commune: "LALOBBE", contenuCO2: "0", contenuCO2ACV: "0,023", tauxEnrR: "1" },
  {id: "0810;C", nomReseau: "Réseau de chaleur bois de Poix Terron", Commune: "POIX-TERRON", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "0811;C", nomReseau: "Réseau de chaleur bois de Villers le Tourneur", Commune: "VILLERS-LE-TOURNEUR", contenuCO2: "0", contenuCO2ACV: "0,024", tauxEnrR: "1" },
  {id: "0812;C", nomReseau: "Réseau de chaleur bois de St Loup Terrier", Commune: "SAINT-LOUP-TERRIER", contenuCO2: "0", contenuCO2ACV: "0,017", tauxEnrR: "1" },
  {id: "0813;C", nomReseau: "Réseau de chaleur de Montcornet", Commune: "MONTCORNET", contenuCO2: "0", contenuCO2ACV: "0,017", tauxEnrR: "1" },
  {id: "0814;C", nomReseau: "Réseau de chaleur de Thin le Moutier", Commune: "THIN-LE-MOUTIER", contenuCO2: "0", contenuCO2ACV: "0,027", tauxEnrR: "1" },
  {id: "1001;C", nomReseau: "Zup de la Chapelle Saint-Luc", Commune: "LES NOES-PRES-TROYES", contenuCO2: "0,245", contenuCO2ACV: "0,271", tauxEnrR: "0" },
  {id: "1002;C", nomReseau: "Les Chartreux", Commune: "Troyes", contenuCO2: "0,085", contenuCO2ACV: "0,11", tauxEnrR: "0,678" },
  {id: "1003;C", nomReseau: "Biomasse de Piney", Commune: "PINEY", contenuCO2: "0,111", contenuCO2ACV: "0,151", tauxEnrR: "0,729" },
  {id: "1101;C", nomReseau: "Zac Saint Jean et & Saint Pierre", Commune: "Narbonne", contenuCO2: "0,1", contenuCO2ACV: "0,125", tauxEnrR: "0,616" },
  {id: "1102;C", nomReseau: "CEF - MCO", Commune: "CARCASSONNE", contenuCO2: "0,152", contenuCO2ACV: "0,165", tauxEnrR: "0,537" },
  {id: "1201;C", nomReseau: "Réseau de Decazeville", Commune: "Decazeville", contenuCO2: "0,045", contenuCO2ACV: "0,07", tauxEnrR: "0,822" },
  {id: "1202;C", nomReseau: "Réseau de Cransac", Commune: "Cransac", contenuCO2: "0,031", contenuCO2ACV: "0,056", tauxEnrR: "0,898" },
  {id: "1203;C", nomReseau: "Réseau Sarrus", Commune: "Rodez", contenuCO2: "0,282", contenuCO2ACV: "0,291", tauxEnrR: "0,244" },
  {id: "1204;C", nomReseau: "Réseau de Cantagrelh", Commune: "ONET-LE-CHATEAU", contenuCO2: "0,202", contenuCO2ACV: "0,22", tauxEnrR: "0,385" },
  {id: "1206;C", nomReseau: "RESEAU DE CAPDENAC-GARE", Commune: "CAPDENAC-GARE", contenuCO2: "0,004", contenuCO2ACV: "0,028", tauxEnrR: "0,986" },
  {id: "1207;C", nomReseau: "Réseau de Saint Affrique", Commune: "SAINT AFFRIQUE", contenuCO2: "0,137", contenuCO2ACV: "0,194", tauxEnrR: "0,424" },
  {id: "1302;C", nomReseau: "Réseau Castellane Bricarde", Commune: "MARSEILLE", contenuCO2: "0,151", contenuCO2ACV: "0,212", tauxEnrR: "0" },
  {id: "1303;C", nomReseau: "Zac des Canourgues", Commune: "SALON-DE-PROVENCE", contenuCO2: "0,22", contenuCO2ACV: "0,253", tauxEnrR: "0" },
  {id: "1306;C", nomReseau: "LUMINY", Commune: "MARSEILLE", contenuCO2: "0,344", contenuCO2ACV: "0,355", tauxEnrR: "0" },
  {id: "1310;C", nomReseau: "Zac Paradis - Saint Roch", Commune: "Martigues", contenuCO2: "0,251", contenuCO2ACV: "0,275", tauxEnrR: "0" },
  {id: "1311;C", nomReseau: "Zac Canto Perdrix", Commune: "Martigues", contenuCO2: "0,093", contenuCO2ACV: "0,161", tauxEnrR: "0,447" },
  {id: "1315;C", nomReseau: "Centre urbain - Zac des Pins", Commune: "VITROLLES", contenuCO2: "0,22", contenuCO2ACV: "0,253", tauxEnrR: "0" },
  {id: "1317;C", nomReseau: "Réseau d’Aix-en-Provence", Commune: "Aix-en-Provence", contenuCO2: "0,1", contenuCO2ACV: "0,125", tauxEnrR: "0,579" },
  {id: "1318;C", nomReseau: "La Bayanne", Commune: "ISTRES", contenuCO2: "0,078", contenuCO2ACV: "0,101", tauxEnrR: "0,648" },
  {id: "1319;C", nomReseau: "Réseau de chaleur Thassalia Marseille", Commune: "MARSEILLE", contenuCO2: "0,053", contenuCO2ACV: "0,027", tauxEnrR: "0,787" },
  {id: "1320;C", nomReseau: "Réseaux de Coudoux", Commune: "COUDOUX", contenuCO2: "0,068", contenuCO2ACV: "0,093", tauxEnrR: "0,744" },
  {id: "1321;C", nomReseau: "Réseau du pôle Yvon Morandat", Commune: "Gardanne", contenuCO2: "0,068", contenuCO2ACV: "0,034", tauxEnrR: "0,768" },
  {id: "1322;C", nomReseau: "BET MASSILEO chaud", Commune: "Marseille", contenuCO2: "0,069", contenuCO2ACV: "0,034", tauxEnrR: "0,831" },
  {id: "1401;C", nomReseau: "Hérouville St Clair", Commune: "HEROUVILLE-SAINT-CLAIR", contenuCO2: "0,013", contenuCO2ACV: "0,018", tauxEnrR: "0,947" },
  {id: "1402;C", nomReseau: "Zup de Hauteville", Commune: "Lisieux", contenuCO2: "0,089", contenuCO2ACV: "0,119", tauxEnrR: "0,622" },
  {id: "1403;C", nomReseau: "Zac de Falaise", Commune: "Falaise", contenuCO2: "0,093", contenuCO2ACV: "0,139", tauxEnrR: "0,557" },
  {id: "1405;C", nomReseau: "Réseau de Bois I", Commune: "Bayeux", contenuCO2: "0,063", contenuCO2ACV: "0,088", tauxEnrR: "0,77" },
  {id: "1406;C", nomReseau: "Réseau bois Vallée des près (Bayeux 2)", Commune: "BAYEUX", contenuCO2: "0,056", contenuCO2ACV: "0,08", tauxEnrR: "0,793" },
  {id: "1408;C", nomReseau: "Quartier Nord", Commune: "CAEN", contenuCO2: "0,299", contenuCO2ACV: "0,301", tauxEnrR: "0" },
  {id: "1409;C", nomReseau: "RCU d'Aunay-sur-Odon", Commune: "AUNAY-SUR-ODON", contenuCO2: "0,044", contenuCO2ACV: "0,069", tauxEnrR: "0,844" },
  {id: "1410;C", nomReseau: "RCU de Vire", Commune: "VIRE", contenuCO2: "0,049", contenuCO2ACV: "0,073", tauxEnrR: "0,835" },
  {id: "1411;C", nomReseau: "RCU de Val-ès-Dunes", Commune: "ARGENCES", contenuCO2: "0,078", contenuCO2ACV: "0,105", tauxEnrR: "0,737" },
  {id: "1413;C", nomReseau: "Réseau de chaleur Caen Sud", Commune: "CAEN", contenuCO2: "0,086", contenuCO2ACV: "0,116", tauxEnrR: "0,605" },
  {id: "1501;C", nomReseau: "Hôpital d'Aurillac", Commune: "AURILLAC", contenuCO2: "0,005", contenuCO2ACV: "0,024", tauxEnrR: "0,977" },
  {id: "1502;C", nomReseau: "Réseau de chaleur bois du Crozatier", Commune: "Saint-Georges", contenuCO2: "0,002", contenuCO2ACV: "0,023", tauxEnrR: "0,995" },
  {id: "1503;C", nomReseau: "Réseau de chaleur bois du Volzac", Commune: "Saint-Flour", contenuCO2: "0,006", contenuCO2ACV: "0,028", tauxEnrR: "0,984" },
  {id: "1504;C", nomReseau: "Réseau de Riom-Es-Montagnes", Commune: "RIOM-ES-MONTAGNES", contenuCO2: "0,014", contenuCO2ACV: "0,04", tauxEnrR: "0,96" },
  {id: "1505;C", nomReseau: "Réseau de Vebret", Commune: "VEBRET", contenuCO2: "0,128", contenuCO2ACV: "0,14", tauxEnrR: "0,667" },
  {id: "1506;C", nomReseau: "Réseau de l'OP HLM Du Cantal", Commune: "ARPAJON-SUR-CERE", contenuCO2: "0", contenuCO2ACV: "0,02", tauxEnrR: "1" },
  {id: "1507;C", nomReseau: "Réseau Chaleur Bois de Murat", Commune: "MURAT", contenuCO2: "0,005", contenuCO2ACV: "0,026", tauxEnrR: "0,986" },
  {id: "1511;C", nomReseau: "Réseau de chaleur d'Aurillac", Commune: "Aurillac", contenuCO2: "0,055", contenuCO2ACV: "0,085", tauxEnrR: "0,849" },
  {id: "1512;C", nomReseau: "Réseau de chaleur de Besserette", Commune: "Saint-Flour", contenuCO2: "0,011", contenuCO2ACV: "0,033", tauxEnrR: "0,968" },
  {id: "1601;C", nomReseau: "Réseau de Saint-Bonnet", Commune: "Saint-Bonnet", contenuCO2: "0,256", contenuCO2ACV: "0,273", tauxEnrR: "0,333" },
  {id: "1602;C", nomReseau: "Commune de Baignes-Sainte-Radegonde", Commune: "BAIGNES", contenuCO2: "0", contenuCO2ACV: "0,024", tauxEnrR: "1" },
  {id: "1603;C", nomReseau: "Réseau de Brossac", Commune: "Brossac", contenuCO2: "0,274", contenuCO2ACV: "0,313", tauxEnrR: "0,398" },
  {id: "1604;C", nomReseau: "Réseau de Champagne-Mouton", Commune: "Champagne-Mouton", contenuCO2: "0", contenuCO2ACV: "0,02", tauxEnrR: "1" },
  {id: "1605;C", nomReseau: "Réseau 'Champ de manœuvre'", Commune: "Soyaux", contenuCO2: "0,201", contenuCO2ACV: "0,228", tauxEnrR: "0" },
  {id: "1606;C", nomReseau: "Chaufferie bois", Commune: "MONTEMBOEUF", contenuCO2: "0", contenuCO2ACV: "0,017", tauxEnrR: "1" },
  {id: "1607;C", nomReseau: "Chauffage Urbain Public", Commune: "CHATEAUBERNARD", contenuCO2: "0,265", contenuCO2ACV: "0,271", tauxEnrR: "0,296" },
  {id: "1608;C", nomReseau: "Réseau de chaleur de Basseau", Commune: "ANGOULEME", contenuCO2: "0,115", contenuCO2ACV: "0,142", tauxEnrR: "0,564" },
  {id: "1609;C", nomReseau: "Réseau de chaleur de Segonzac", Commune: "SEGONZAC", contenuCO2: "0,006", contenuCO2ACV: "0,027", tauxEnrR: "0,973" },
  {id: "1610;C", nomReseau: "Réseau des Vauzelles", Commune: "CHATEAUBERNARD", contenuCO2: "0,027", contenuCO2ACV: "0,049", tauxEnrR: "0,895" },
  {id: "1611;C", nomReseau: "15 boulevard Jean Moulin", Commune: "Angoulême", contenuCO2: "0,209", contenuCO2ACV: "0,238", tauxEnrR: "0,162" },
  {id: "1612;C", nomReseau: "Domaine de La Combe", Commune: "St Yrieix sur Charente", contenuCO2: "0,088", contenuCO2ACV: "0,113", tauxEnrR: "0,656" },
  {id: "1701;C", nomReseau: "Villeneuve les Salines", Commune: "LA ROCHELLE", contenuCO2: "0,055", contenuCO2ACV: "0,09", tauxEnrR: "0,703" },
  {id: "1702;C", nomReseau: "Réseau de Jonzac", Commune: "Jonzac", contenuCO2: "0,01", contenuCO2ACV: "0,04", tauxEnrR: "0,982" },
  {id: "1703;C", nomReseau: "Réseau de Aytre", Commune: "AYTRE", contenuCO2: "0,037", contenuCO2ACV: "0,061", tauxEnrR: "0,853" },
  {id: "1704;C", nomReseau: "Pont Neuf Mireuil Energie", Commune: "LA ROCHELLE", contenuCO2: "0,016", contenuCO2ACV: "0,022", tauxEnrR: "0,939" },
  {id: "1705;C", nomReseau: "Réseau de Chaleur urbain de Pons", Commune: "PONS", contenuCO2: "0,07", contenuCO2ACV: "0,097", tauxEnrR: "0,752" },
  {id: "1707;C", nomReseau: "Réseau de la chaufferie bois", Commune: "GEMOZAC", contenuCO2: "0,038", contenuCO2ACV: "0,068", tauxEnrR: "0,913" },
  {id: "1801;C", nomReseau: "Chancellerie Gibjoncs - Zup de Bourges", Commune: "BOURGES", contenuCO2: "0,014", contenuCO2ACV: "0,035", tauxEnrR: "0,949" },
  {id: "1803;C", nomReseau: "Clos du Roy / Tunnel Chateau", Commune: "Vierzon", contenuCO2: "0,198", contenuCO2ACV: "0,239", tauxEnrR: "0" },
  {id: "1901;C", nomReseau: "Unité de Valorisation Energétique", Commune: "Saint-Pantaleon-de-Larche", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "1902;C", nomReseau: "Egletons Bois Energie", Commune: "EGLETONS", contenuCO2: "0,013", contenuCO2ACV: "0,024", tauxEnrR: "0,958" },
  {id: "1903;C", nomReseau: "Réseau de Sornac", Commune: "Sornac", contenuCO2: "0,106", contenuCO2ACV: "0,145", tauxEnrR: "0,741" },
  {id: "1904;C", nomReseau: "Réseau de Servières le Château", Commune: "SERVIERES-LE-CHATEAU", contenuCO2: "0", contenuCO2ACV: "0,028", tauxEnrR: "1" },
  {id: "1906;C", nomReseau: "BORG WARNER", Commune: "EYREIN", contenuCO2: "0,271", contenuCO2ACV: "0,305", tauxEnrR: "0" },
  {id: "1907;C", nomReseau: "Réseau de Brive", Commune: "BRIVE-LA-GAILLARDE", contenuCO2: "0,098", contenuCO2ACV: "0,114", tauxEnrR: "0,676" },
  {id: "2001;C", nomReseau: "Réseau de Corte", Commune: "CORTE", contenuCO2: "0,257", contenuCO2ACV: "0,265", tauxEnrR: "0,333" },
  {id: "2102;C", nomReseau: "Réseau du Grand Dijon Ouest", Commune: "Dijon", contenuCO2: "0,098", contenuCO2ACV: "0,131", tauxEnrR: "0,527" },
  {id: "2105;C", nomReseau: "Les Gresilles", Commune: "Dijon", contenuCO2: "0,088", contenuCO2ACV: "0,122", tauxEnrR: "0,514" },
  {id: "2106;C", nomReseau: "Dijon énergies", Commune: "DIJON", contenuCO2: "0,055", contenuCO2ACV: "0,077", tauxEnrR: "0,746" },
  {id: "2107;C", nomReseau: "Mairie", Commune: "BELLENEUVE", contenuCO2: "0,109", contenuCO2ACV: "0,15", tauxEnrR: "0,772" },
  {id: "2108;C", nomReseau: "Réseau de la commune de Nuits Saint Georges", Commune: "NUITS-SAINT-GEORGES", contenuCO2: "0,113", contenuCO2ACV: "0,146", tauxEnrR: "0,741" },
  {id: "2202;C", nomReseau: "RESEAU DE CHALEUR CHAUFFERIE 1", Commune: "PLOUARET", contenuCO2: "0", contenuCO2ACV: "0,103", tauxEnrR: "1" },
  {id: "2203;C", nomReseau: "RESEAU DE CHALEUR CHAUFFERIE 2", Commune: "PLOUARET", contenuCO2: "0", contenuCO2ACV: "0,089", tauxEnrR: "1" },
  {id: "2204;C", nomReseau: "SMITRED OUEST d'ARMOR", Commune: "PLUZUNET", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "2205;C", nomReseau: "Réseau de chaleur de Collinée", Commune: "COLLINEE", contenuCO2: "0,128", contenuCO2ACV: "0,141", tauxEnrR: "0,667" },
  {id: "2206;C", nomReseau: "Réseau de chaleur de Pessala", Commune: "PLESSALA", contenuCO2: "0,128", contenuCO2ACV: "0,14", tauxEnrR: "0,667" },
  {id: "2207;C", nomReseau: "Réseau de chaleur du Gouray", Commune: "LE GOURAY", contenuCO2: "0,128", contenuCO2ACV: "0,142", tauxEnrR: "0,667" },
  {id: "2208;C", nomReseau: "Réseau de chaleur de Brézillet", Commune: "Ploufragan", contenuCO2: "0,104", contenuCO2ACV: "0,131", tauxEnrR: "0,614" },
  {id: "2209;C", nomReseau: "CIDER’BOIS ENERGIE", Commune: "LOUDEAC", contenuCO2: "0", contenuCO2ACV: "0,022", tauxEnrR: "1" },
  {id: "2210;C", nomReseau: "Hôpital Rive Gauche", Commune: "LANNION", contenuCO2: "0,075", contenuCO2ACV: "0,101", tauxEnrR: "0,725" },
  {id: "2211;C", nomReseau: "Réseau de Ploumilliau", Commune: "Ploumilliau", contenuCO2: "0", contenuCO2ACV: "0,023", tauxEnrR: "1" },
  {id: "2301;C", nomReseau: "Réseau de Bourganeuf", Commune: "Bourganeuf", contenuCO2: "0,05", contenuCO2ACV: "0,079", tauxEnrR: "0,867" },
  {id: "2302;C", nomReseau: "Réseau de Felletin", Commune: "Felletin", contenuCO2: "0,016", contenuCO2ACV: "0,041", tauxEnrR: "0,95" },
  {id: "2304;C", nomReseau: "Réseau de Gentioux", Commune: "GENTIOUX-PIGEROLLES", contenuCO2: "0", contenuCO2ACV: "0,034", tauxEnrR: "1" },
  {id: "2305;C", nomReseau: "Réseau de Guéret", Commune: "GUERET", contenuCO2: "0,027", contenuCO2ACV: "0,05", tauxEnrR: "0,894" },
  {id: "2306;C", nomReseau: "réseau de chaleur de la Ville de Saint Yrieix", Commune: "SAINT-YRIEIX-LA-PERCHE", contenuCO2: "0,205", contenuCO2ACV: "0,216", tauxEnrR: "0,457" },
  {id: "2401;C", nomReseau: "Réseau de chaleur de Saint-Astier", Commune: "SAINT ASTIER", contenuCO2: "0,119", contenuCO2ACV: "0,144", tauxEnrR: "0,537" },
  {id: "2402;C", nomReseau: "L'Arche au Bois", Commune: "Périgueux", contenuCO2: "0,056", contenuCO2ACV: "0,094", tauxEnrR: "0,776" },
  {id: "2403;C", nomReseau: "chaufferie bois de Cadouin", Commune: "LE BUISSON-DE-CADOUIN", contenuCO2: "0,031", contenuCO2ACV: "0,071", tauxEnrR: "0,956" },
  {id: "2404;C", nomReseau: "Chaufferie bois Douville", Commune: "DOUVILLE", contenuCO2: "0,128", contenuCO2ACV: "0,141", tauxEnrR: "0,667" },
  {id: "2406;C", nomReseau: "Réseau de Coulounieix chamiers", Commune: "COULOUNIEIX-CHAMIERS", contenuCO2: "0,059", contenuCO2ACV: "0,083", tauxEnrR: "0,769" },
  {id: "2407;C", nomReseau: "réseau de chaleur des Deux Rives Périgueux", Commune: "PERIGUEUX", contenuCO2: "0,028", contenuCO2ACV: "0,049", tauxEnrR: "0,892" },
  {id: "2501;C", nomReseau: "Besançon - Planoise", Commune: "Besançon", contenuCO2: "0,066", contenuCO2ACV: "0,085", tauxEnrR: "0,752" },
  {id: "2502;C", nomReseau: "Zup de la Petite Hollande", Commune: "Montbéliard", contenuCO2: "0,08", contenuCO2ACV: "0,092", tauxEnrR: "0,648" },
  {id: "2503;C", nomReseau: "Champvalon", Commune: "Bethoncourt", contenuCO2: "0,261", contenuCO2ACV: "0,294", tauxEnrR: "0" },
  {id: "2504;C", nomReseau: "Chaufferie Bois du Russey", Commune: "LE RUSSEY", contenuCO2: "0,047", contenuCO2ACV: "0,08", tauxEnrR: "0,867" },
  {id: "2505;C", nomReseau: "Champs Montants", Commune: "Audincourt", contenuCO2: "0,206", contenuCO2ACV: "0,244", tauxEnrR: "0" },
  {id: "2506;C", nomReseau: "Domaine Universitaire de la Bouloie", Commune: "Besançon", contenuCO2: "0,066", contenuCO2ACV: "0,089", tauxEnrR: "0,721" },
  {id: "2507;C", nomReseau: "Réseau de chaleur de Mouthe", Commune: "MOUTHE", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "2509;C", nomReseau: "MAIRIE MYON", Commune: "MYON", contenuCO2: "0", contenuCO2ACV: "0,017", tauxEnrR: "1" },
  {id: "2510;C", nomReseau: "chaufferie du lycée E Faure", Commune: "MORTEAU", contenuCO2: "0,256", contenuCO2ACV: "0,262", tauxEnrR: "0,333" },
  {id: "2511;C", nomReseau: "RESEAU DE CHALEUR", Commune: "PONTARLIER", contenuCO2: "0,01", contenuCO2ACV: "0,015", tauxEnrR: "0,967" },
  {id: "2513;C", nomReseau: "Villars sous Ecot", Commune: "VILLARS-SOUS-ECOT", contenuCO2: "0", contenuCO2ACV: "0,023", tauxEnrR: "1" },
  {id: "2514;C", nomReseau: "Réseau de chaleur de Frasne", Commune: "FRASNE", contenuCO2: "0,159", contenuCO2ACV: "0,179", tauxEnrR: "0,59" },
  {id: "2515;C", nomReseau: "Réseau de Goux les Usiers", Commune: "GOUX-LES-USIERS", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "2516;C", nomReseau: "Réseau de AMANCEY LOUE LISON", Commune: "AMANCEY", contenuCO2: "0", contenuCO2ACV: "0,034", tauxEnrR: "1" },
  {id: "2601;C", nomReseau: "Réseau de Valence", Commune: "VALENCE", contenuCO2: "0,227", contenuCO2ACV: "0,252", tauxEnrR: "0,12" },
  {id: "2602;C", nomReseau: "Réseau de chaleur de Vassieux en Vercors", Commune: "VASSIEUX-EN-VERCORS", contenuCO2: "0,135", contenuCO2ACV: "0,154", tauxEnrR: "0,653" },
  {id: "2603;C", nomReseau: "Réseau de Pierrelatte - DES", Commune: "Pierrelatte", contenuCO2: "0", contenuCO2ACV: "0,086", tauxEnrR: "0,785" },
  {id: "2604;C", nomReseau: "Réseau de Pracomptal", Commune: "MONTELIMAR", contenuCO2: "0,203", contenuCO2ACV: "0,242", tauxEnrR: "0" },
  {id: "2605;C", nomReseau: "Réseau de chaleur du quartier de la Monnaie", Commune: "ROMANS-SUR-ISERE", contenuCO2: "0,186", contenuCO2ACV: "0,244", tauxEnrR: "0" },
  {id: "2701;C", nomReseau: "THERMEVRA", Commune: "Evreux", contenuCO2: "0,063", contenuCO2ACV: "0,088", tauxEnrR: "0,656" },
  {id: "2702;C", nomReseau: "LOUVIERS ENERGIE", Commune: "Louviers", contenuCO2: "0,079", contenuCO2ACV: "0,107", tauxEnrR: "0,639" },
  {id: "2703;C", nomReseau: "Quartier de l'Europe", Commune: "Pont-Audemer", contenuCO2: "0,25", contenuCO2ACV: "0,269", tauxEnrR: "0" },
  {id: "2704;C", nomReseau: "Tours du Levant Clos Galots", Commune: "LES ANDELYS", contenuCO2: "0,222", contenuCO2ACV: "0,248", tauxEnrR: "0" },
  {id: "2705;C", nomReseau: "Zup Les Valmeux", Commune: "Vernon", contenuCO2: "0,169", contenuCO2ACV: "0,21", tauxEnrR: "0" },
  {id: "2706;C", nomReseau: "Réseau de chaleur de Conches-en-Ouche", Commune: "CONCHES-EN-OUCHE", contenuCO2: "0,036", contenuCO2ACV: "0,058", tauxEnrR: "0,862" },
  {id: "2707;C", nomReseau: "Réseau - Canappeville", Commune: "CANAPPEVILLE", contenuCO2: "0", contenuCO2ACV: "0,018", tauxEnrR: "1" },
  {id: "2708;C", nomReseau: "RESEAU DE CHALEUR", Commune: "GRANDVILLIERS", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "2802;C", nomReseau: "Les Gauchetières", Commune: "NOGENT-LE-ROTROU", contenuCO2: "0,21", contenuCO2ACV: "0,235", tauxEnrR: "0" },
  {id: "2803;C", nomReseau: "DUNES", Commune: "CHATEAUDUN", contenuCO2: "0,059", contenuCO2ACV: "0,084", tauxEnrR: "0,773" },
  {id: "2804;C", nomReseau: "Tallemont", Commune: "MAINVILLIERS", contenuCO2: "0,197", contenuCO2ACV: "0,222", tauxEnrR: "0" },
  {id: "2805;C", nomReseau: "Réseau de logements sociaux de Luce", Commune: "LUCE", contenuCO2: "0,291", contenuCO2ACV: "0,299", tauxEnrR: "0,103" },
  {id: "2806;C", nomReseau: "Réseau de chaleur de Voves", Commune: "VOVES", contenuCO2: "0,204", contenuCO2ACV: "0,235", tauxEnrR: "0,333" },
  {id: "2807;C", nomReseau: "CHARTRES METROPOLE ENERGIES", Commune: "CHARTRES", contenuCO2: "0", contenuCO2ACV: "0,064", tauxEnrR: "0,886" },
  {id: "2901;C", nomReseau: "ECO CHALEUR DE BREST", Commune: "Brest", contenuCO2: "0,02", contenuCO2ACV: "0,03", tauxEnrR: "0,923" },
  {id: "2902;C", nomReseau: "Réseau de Plougastel Daoulas", Commune: "PLOUGASTEL-DAOULAS", contenuCO2: "0,058", contenuCO2ACV: "0,083", tauxEnrR: "0,794" },
  {id: "2903;C", nomReseau: "Réseau de chaleur UVED", Commune: "BRIEC", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "2905;C", nomReseau: "GOUENNOU FRERES", Commune: "PLOUGASTEL-DAOULAS", contenuCO2: "0", contenuCO2ACV: "0,021", tauxEnrR: "1" },
  {id: "3001;C", nomReseau: "Quartier Ouest", Commune: "NIMES", contenuCO2: "0,094", contenuCO2ACV: "0,116", tauxEnrR: "0,558" },
  {id: "3002;C", nomReseau: "DALAE", Commune: "Ales", contenuCO2: "0,072", contenuCO2ACV: "0,102", tauxEnrR: "0,663" },
  {id: "3003;C", nomReseau: "Réseau bois Lamelouze", Commune: "LAMELOUZE", contenuCO2: "0", contenuCO2ACV: "0,018", tauxEnrR: "1" },
  {id: "3101;C", nomReseau: "Réseau de Toulouse Mirail", Commune: "Toulouse", contenuCO2: "0,001", contenuCO2ACV: "0,005", tauxEnrR: "0,997" },
  {id: "3102;C", nomReseau: "Éco-quartier Balma Vidailhan", Commune: "BALMA", contenuCO2: "0,088", contenuCO2ACV: "0,111", tauxEnrR: "0,606" },
  {id: "3103;C", nomReseau: "Zac du Ritouret", Commune: "Blagnac", contenuCO2: "0,201", contenuCO2ACV: "0,197", tauxEnrR: "0,35" },
  {id: "3104;C", nomReseau: "Canceropôle", Commune: "TOULOUSE", contenuCO2: "0,015", contenuCO2ACV: "0,021", tauxEnrR: "0,933" },
  {id: "3106;C", nomReseau: "Réseau 'Saint Exupéry' ", Commune: "Toulouse", contenuCO2: "0,189", contenuCO2ACV: "0,222", tauxEnrR: "0,32" },
  {id: "3107;C", nomReseau: "Ensemble Universitaire Toulouse Rangueil", Commune: "TOULOUSE", contenuCO2: "0,065", contenuCO2ACV: "0,089", tauxEnrR: "0,768" },
  {id: "3108;C", nomReseau: "En Turet", Commune: "AYGUESVIVES", contenuCO2: "0,103", contenuCO2ACV: "0,134", tauxEnrR: "0,742" },
  {id: "3111;C", nomReseau: "CAMUS", Commune: "CASTANET-TOLOSAN", contenuCO2: "0,094", contenuCO2ACV: "0,123", tauxEnrR: "0,691" },
  {id: "3112;C", nomReseau: "Toulouse energie durable (TED) chaud", Commune: "TOULOUSE", contenuCO2: "0,059", contenuCO2ACV: "0,069", tauxEnrR: "0,777" },
  {id: "3113;C", nomReseau: "Réseau écoquartier Maragon Floralies", Commune: "RAMONVILLE SAINT-AGNE", contenuCO2: "0,151", contenuCO2ACV: "0,175", tauxEnrR: "0,302" },
  {id: "3302;C", nomReseau: "Grand Parc", Commune: "BORDEAUX", contenuCO2: "0,139", contenuCO2ACV: "0,246", tauxEnrR: "0" },
  {id: "3303;C", nomReseau: "Mériadeck", Commune: "Bordeaux", contenuCO2: "0", contenuCO2ACV: "0,012", tauxEnrR: "1" },
  {id: "3304;C", nomReseau: "Parc de Mérignac Ville Stemer", Commune: "Mérignac", contenuCO2: "0,188", contenuCO2ACV: "0,231", tauxEnrR: "0" },
  {id: "3305;C", nomReseau: "Laseris", Commune: "LE BARP", contenuCO2: "0,145", contenuCO2ACV: "0,12", tauxEnrR: "0" },
  {id: "3306;C", nomReseau: "Rive Droite Energies", Commune: "Cenon", contenuCO2: "0,06", contenuCO2ACV: "0,071", tauxEnrR: "0,775" },
  {id: "3310;C", nomReseau: "Réseau de chaleur de Gironde sur Dropt", Commune: "Gironde-sur-Dropt", contenuCO2: "0,044", contenuCO2ACV: "0,082", tauxEnrR: "0,911" },
  {id: "3311;C", nomReseau: "Réseau de chaleur de Pellegrue", Commune: "Pellegrue", contenuCO2: "0,061", contenuCO2ACV: "0,098", tauxEnrR: "0,873" },
  {id: "3312;C", nomReseau: "Réseau de chaleur de Saint Pierre d'Aurillac", Commune: "Saint-Pierre-d ‘Aurillac", contenuCO2: "0,03", contenuCO2ACV: "0,06", tauxEnrR: "0,92" },
  {id: "3313;C", nomReseau: "Réseau de La Réole", Commune: "LA REOLE", contenuCO2: "0,015", contenuCO2ACV: "0,042", tauxEnrR: "0,96" },
  {id: "3315;C", nomReseau: "Réseau de Terres Neuves", Commune: "Begles", contenuCO2: "0,097", contenuCO2ACV: "0,125", tauxEnrR: "0,659" },
  {id: "3316;C", nomReseau: "Réseau de l'éco-quartier Ginko", Commune: "BORDEAUX", contenuCO2: "0,063", contenuCO2ACV: "0,099", tauxEnrR: "0,707" },
  {id: "3317;C", nomReseau: "Saige-Formanoir", Commune: "PESSAC", contenuCO2: "0,063", contenuCO2ACV: "0,046", tauxEnrR: "0,698" },
  {id: "3318;C", nomReseau: "Bassins à Flot", Commune: "BORDEAUX", contenuCO2: "0,089", contenuCO2ACV: "0,101", tauxEnrR: "0,674" },
  {id: "3319;C", nomReseau: "Bordeaux Begles Energies", Commune: "BORDEAUX", contenuCO2: "0,009", contenuCO2ACV: "0,014", tauxEnrR: "0,974" },
  {id: "3320;C", nomReseau: "Réseau de la Clinique Lesparre", Commune: "LESPARRE-MEDOC", contenuCO2: "0,094", contenuCO2ACV: "0,129", tauxEnrR: "0,793" },
  {id: "3321;C", nomReseau: "Réseau Portes du PYLA", Commune: "La teste de buch", contenuCO2: "0,088", contenuCO2ACV: "0,185", tauxEnrR: "0,326" },
  {id: "3401;C", nomReseau: "RMCF", Commune: "Montpellier", contenuCO2: "0,078", contenuCO2ACV: "0,104", tauxEnrR: "0,677" },
  {id: "3404;C", nomReseau: "Réseau de Fraïsse - Agout", Commune: "FRAISSE-SUR-AGOUT", contenuCO2: "0", contenuCO2ACV: "0,022", tauxEnrR: "1" },
  {id: "3405;C", nomReseau: "Réseau des universités", Commune: "Montpellier", contenuCO2: "0,052", contenuCO2ACV: "0,075", tauxEnrR: "0,784" },
  {id: "3409;C", nomReseau: "ZAC des Constellations", Commune: "JUVIGNAC", contenuCO2: "0,065", contenuCO2ACV: "0,09", tauxEnrR: "0,762" },
  {id: "3410;C", nomReseau: "Eco-Quartier Le Mas de Rochet", Commune: "CASTELNAU-LE-LEZ", contenuCO2: "0,105", contenuCO2ACV: "0,13", tauxEnrR: "0,585" },
  {id: "3411;C", nomReseau: "MUSE", Commune: "MONTPELLIER", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "3412;C", nomReseau: "BET BOIRARGUES chaud", Commune: "Lattes", contenuCO2: "0,047", contenuCO2ACV: "0,024", tauxEnrR: "0,846" },
  {id: "3413;C", nomReseau: "BET ODE chaud", Commune: "PEROLS", contenuCO2: "0,053", contenuCO2ACV: "0,037", tauxEnrR: "0,706" },
  {id: "3414;C", nomReseau: "BET EUREKA chaud", Commune: "Castelnau-le-Lez", contenuCO2: "0,062", contenuCO2ACV: "0,031", tauxEnrR: "0,6" },
  {id: "3504;C", nomReseau: "Sarah Bernhardt", Commune: "Rennes", contenuCO2: "0,236", contenuCO2ACV: "0,267", tauxEnrR: "0" },
  {id: "3505;C", nomReseau: "Campus Scientifique de Beaulieu", Commune: "Rennes", contenuCO2: "0,2", contenuCO2ACV: "0,234", tauxEnrR: "0" },
  {id: "3506;C", nomReseau: "Rennes Sud", Commune: "Rennes", contenuCO2: "0,086", contenuCO2ACV: "0,116", tauxEnrR: "0,579" },
  {id: "3507;C", nomReseau: "Réseau de la centrale Thermique de Villejean", Commune: "Rennes", contenuCO2: "0,033", contenuCO2ACV: "0,041", tauxEnrR: "0,882" },
  {id: "3508;C", nomReseau: "Réseau de chaleur Bretagne romantique à Combourg", Commune: "COMBOURG", contenuCO2: "0,032", contenuCO2ACV: "0,056", tauxEnrR: "0,852" },
  {id: "3510;C", nomReseau: "Réseau EAU CHAUDE", Commune: "VITRE", contenuCO2: "0,045", contenuCO2ACV: "0,054", tauxEnrR: "0,825" },
  {id: "3511;C", nomReseau: "SILVA -- Réseau de JANZE", Commune: "JANZE", contenuCO2: "0,042", contenuCO2ACV: "0,066", tauxEnrR: "0,855" },
  {id: "3512;C", nomReseau: "Réseau de Vezin le Coquet", Commune: "VEZIN-LE-COQUET", contenuCO2: "0,111", contenuCO2ACV: "0,138", tauxEnrR: "0,578" },
  {id: "3513;C", nomReseau: "Réseau de Rennes Est (ZAC Baud -Chardonnet)", Commune: "RENNES", contenuCO2: "0,149", contenuCO2ACV: "0,164", tauxEnrR: "0,583" },
  {id: "3514;C", nomReseau: "Réseau de Chartres de Bretagne (ZAC portes de Seiche)", Commune: "CHARTRES-DE-BRETAGNE", contenuCO2: "0,152", contenuCO2ACV: "0,182", tauxEnrR: "0,491" },
  {id: "3601;C", nomReseau: "Cité Saint Jean", Commune: "Châteauroux", contenuCO2: "0,308", contenuCO2ACV: "0,281", tauxEnrR: "0" },
  {id: "3602;C", nomReseau: "Réseau de chaleur de la cité de Beaulieu", Commune: "CHATEAUROUX", contenuCO2: "0,036", contenuCO2ACV: "0,056", tauxEnrR: "0,829" },
  {id: "3603;C", nomReseau: "Réseau de chaleur Argenton sur Creuse", Commune: "ARGENTON-SUR-CREUSE", contenuCO2: "0,037", contenuCO2ACV: "0,058", tauxEnrR: "0,829" },
  {id: "3604;C", nomReseau: "Réseau communal Neuvy Saint Sepulchre", Commune: "NEUVY-SAINT-SEPULCHRE", contenuCO2: "0,047", contenuCO2ACV: "0,073", tauxEnrR: "0,846" },
  {id: "3605;C", nomReseau: "Réseau de chaleur d'Issoudun", Commune: "Issoudun", contenuCO2: "0,048", contenuCO2ACV: "0,071", tauxEnrR: "0,807" },
  {id: "3606;C", nomReseau: "Centre Départemental Gériatrique de l'Indre (CDGI)", Commune: "Châteauroux", contenuCO2: "0,049", contenuCO2ACV: "0,072", tauxEnrR: "0,746" },
  {id: "3701;C", nomReseau: "Morier et Rabière", Commune: "Joué-Lès-Tours", contenuCO2: "0,125", contenuCO2ACV: "0,153", tauxEnrR: "0,595" },
  {id: "3702;C", nomReseau: "Zup des Bords de Cher et Sanitas", Commune: "TOURS", contenuCO2: "0,068", contenuCO2ACV: "0,088", tauxEnrR: "0,671" },
  {id: "3704;C", nomReseau: "Quartier Chateaubriand", Commune: "Tours", contenuCO2: "0,057", contenuCO2ACV: "0,244", tauxEnrR: "0,049" },
  {id: "3705;C", nomReseau: "Réseau de la ville de Saint Pierre des Corps", Commune: "Saint-Pierre-des-Corps", contenuCO2: "0,06", contenuCO2ACV: "0,083", tauxEnrR: "0,754" },
  {id: "3707;C", nomReseau: "Centre de Valorisation Energétique", Commune: "SAINT-BENOIT-LA-FORET", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "3708;C", nomReseau: "Réseau de chauffage urbain de la Riche - Quartier", Commune: "LA RICHE", contenuCO2: "0,232", contenuCO2ACV: "0,261", tauxEnrR: "0" },
  {id: "3709;C", nomReseau: "Réseau DBT", Commune: "TOURS", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "3710;C", nomReseau: "Résidence Febvotte 1 et 2", Commune: "TOURS", contenuCO2: "0,102", contenuCO2ACV: "0,256", tauxEnrR: "0" },
  {id: "3711;C", nomReseau: "CFA Joué Les Tours", Commune: "JOUE LES TOURS", contenuCO2: "0,089", contenuCO2ACV: "0,118", tauxEnrR: "0,708" },
  {id: "3801;C", nomReseau: "Réseau chaufferie bois Vanne", Commune: "NOYAREY", contenuCO2: "0", contenuCO2ACV: "0,052", tauxEnrR: "1" },
  {id: "3802;C", nomReseau: "Réseau Métropole de Grenoble", Commune: "Grenoble", contenuCO2: "0,076", contenuCO2ACV: "0,105", tauxEnrR: "0,777" },
  {id: "3803;C", nomReseau: "Berjalia", Commune: "BOURGOIN-JALLIEU", contenuCO2: "0,044", contenuCO2ACV: "0,052", tauxEnrR: "0,836" },
  {id: "3804;C", nomReseau: "Réseau de chaleur de Vinay", Commune: "VINAY", contenuCO2: "0,05", contenuCO2ACV: "0,082", tauxEnrR: "0,875" },
  {id: "3805;C", nomReseau: "Réseau de Saint Marcellin", Commune: "Saint-Marcellin", contenuCO2: "0,021", contenuCO2ACV: "0,049", tauxEnrR: "0,942" },
  {id: "3806;C", nomReseau: "Réseau d'Allevard", Commune: "ALLEVARD", contenuCO2: "0,089", contenuCO2ACV: "0,125", tauxEnrR: "0,714" },
  {id: "3807;C", nomReseau: "Les Laiches", Commune: "VILLARD-DE-LANS", contenuCO2: "0,044", contenuCO2ACV: "0,075", tauxEnrR: "0,897" },
  {id: "3808;C", nomReseau: "Réseau de Lans-en-Vercors", Commune: "LANS-EN-VERCORS", contenuCO2: "0,033", contenuCO2ACV: "0,062", tauxEnrR: "0,922" },
  {id: "3809;C", nomReseau: "Réseau de Monestier-de-Clermont", Commune: "MONESTIER-DE-CLERMONT", contenuCO2: "0,022", contenuCO2ACV: "0,049", tauxEnrR: "0,943" },
  {id: "3810;C", nomReseau: "Réseau de Mens", Commune: "MENS", contenuCO2: "0,086", contenuCO2ACV: "0,12", tauxEnrR: "0,754" },
  {id: "3811;C", nomReseau: "Réseau de Chaleur Bois Pontcharra RCBP", Commune: "PONTCHARRA", contenuCO2: "0,122", contenuCO2ACV: "0,15", tauxEnrR: "0,565" },
  {id: "3813;C", nomReseau: "Réseau de chaleur de Voreppe", Commune: "VOREPPE", contenuCO2: "0,061", contenuCO2ACV: "0,087", tauxEnrR: "0,782" },
  {id: "3814;C", nomReseau: "Réseau de Coublevie", Commune: "COUBLEVIE", contenuCO2: "0,016", contenuCO2ACV: "0,036", tauxEnrR: "0,928" },
  {id: "3817;C", nomReseau: "Réseau de villars de lans", Commune: "VILLARD-DE-LANS", contenuCO2: "0,016", contenuCO2ACV: "0,041", tauxEnrR: "0,956" },
  {id: "3820;C", nomReseau: "Réseau de chaleur de Gières Centre", Commune: "Gières", contenuCO2: "0,052", contenuCO2ACV: "0,077", tauxEnrR: "0,801" },
  {id: "3821;C", nomReseau: "Réseau de chaleur de Fontaine ZAC Bastille", Commune: "Fontaine", contenuCO2: "0,089", contenuCO2ACV: "0,113", tauxEnrR: "0,698" },
  {id: "3901;C", nomReseau: "Réseau de Dole", Commune: "Dole", contenuCO2: "0,112", contenuCO2ACV: "0,144", tauxEnrR: "0,512" },
  {id: "3902;C", nomReseau: "La Marjorie", Commune: "Lons-le-Saunier", contenuCO2: "0,045", contenuCO2ACV: "0,058", tauxEnrR: "0,823" },
  {id: "3903;C", nomReseau: "Réseau de Champvans", Commune: "Champvans", contenuCO2: "0", contenuCO2ACV: "0,018", tauxEnrR: "1" },
  {id: "3904;C", nomReseau: "Réseau de chaleur de Moirans en Montagne", Commune: "MOIRANS-EN-MONTAGNE", contenuCO2: "0,062", contenuCO2ACV: "0,095", tauxEnrR: "0,845" },
  {id: "3905;C", nomReseau: "Réseau CCPM Arinthod", Commune: "ARINTHOD", contenuCO2: "0,128", contenuCO2ACV: "0,142", tauxEnrR: "0,667" },
  {id: "3906;C", nomReseau: "Réseau de la Ville de Morez", Commune: "HAUTS DE BIENNE", contenuCO2: "0,009", contenuCO2ACV: "0,032", tauxEnrR: "0,965" },
  {id: "3908;C", nomReseau: "CHAUFFERIE BOIS LES ORCHIDEES", Commune: "AVIGNON-LES-SAINT-CLAUDE", contenuCO2: "0,128", contenuCO2ACV: "0,142", tauxEnrR: "0,667" },
  {id: "4003;C", nomReseau: "Géothermie Mont-de-Marsan (GMM1)", Commune: "MONT-DE-MARSAN", contenuCO2: "0,021", contenuCO2ACV: "0,016", tauxEnrR: "0,907" },
  {id: "4004;C", nomReseau: "Eco quartier Mousse", Commune: "DAX", contenuCO2: "0,134", contenuCO2ACV: "0,107", tauxEnrR: "0,781" },
  {id: "4005;C", nomReseau: "Réseau de chaleur Peyrouat", Commune: "MONT-DE-MARSAN", contenuCO2: "0,192", contenuCO2ACV: "0,164", tauxEnrR: "0,5" },
  {id: "4006;C", nomReseau: "Géothermie Mont-de-Marsan (GMM2)", Commune: "MONT-DE-MARSAN", contenuCO2: "0", contenuCO2ACV: "0,014", tauxEnrR: "1" },
  {id: "4101;C", nomReseau: "Quartier Bégon et Chevalier", Commune: "Blois", contenuCO2: "0,017", contenuCO2ACV: "0,026", tauxEnrR: "0,934" },
  {id: "4102;C", nomReseau: "Zac des Paradis", Commune: "VINEUIL", contenuCO2: "0,203", contenuCO2ACV: "0,248", tauxEnrR: "0,576" },
  {id: "4103;C", nomReseau: "Réseau de Mondoubleau", Commune: "MONDOUBLEAU", contenuCO2: "0", contenuCO2ACV: "0,014", tauxEnrR: "1" },
  {id: "4104;C", nomReseau: "Réseau Neung sur Beuvron", Commune: "NEUNG-SUR-BEUVRON", contenuCO2: "0", contenuCO2ACV: "0,025", tauxEnrR: "1" },
  {id: "4105;C", nomReseau: "ALAIN BLANCHE", Commune: "VILLENY", contenuCO2: "0,128", contenuCO2ACV: "0,14", tauxEnrR: "0,667" },
  {id: "4106;C", nomReseau: "ECO CHALEUR DE BLOIS", Commune: "Blois", contenuCO2: "0,014", contenuCO2ACV: "0,02", tauxEnrR: "0,943" },
  {id: "4202;C", nomReseau: "Quartier la Cotonne", Commune: "Saint-Etienne", contenuCO2: "0,207", contenuCO2ACV: "0,247", tauxEnrR: "0" },
  {id: "4203;C", nomReseau: "Quartier de La Métare", Commune: "Saint-Etienne", contenuCO2: "0,194", contenuCO2ACV: "0,23", tauxEnrR: "0" },
  {id: "4204;C", nomReseau: "HLM Beaulieu Montchovet IV", Commune: "Saint-Etienne", contenuCO2: "0,286", contenuCO2ACV: "0,296", tauxEnrR: "0" },
  {id: "4206;C", nomReseau: "Réseau de Firminy", Commune: "Firminy", contenuCO2: "0,109", contenuCO2ACV: "0,141", tauxEnrR: "0,492" },
  {id: "4207;C", nomReseau: "Roanne énergies", Commune: "Roanne", contenuCO2: "0,054", contenuCO2ACV: "0,077", tauxEnrR: "0,788" },
  {id: "4208;C", nomReseau: "Quartier Parc des Sports", Commune: "Roanne", contenuCO2: "0,225", contenuCO2ACV: "0,253", tauxEnrR: "0" },
  {id: "4210;C", nomReseau: "Quartier Montreynaud", Commune: "SAINT-ETIENNE", contenuCO2: "0,082", contenuCO2ACV: "0,105", tauxEnrR: "0,668" },
  {id: "4211;C", nomReseau: "Andrézieux-Bouthéon", Commune: "Andrézieux-Bouthéon", contenuCO2: "0,083", contenuCO2ACV: "0,109", tauxEnrR: "0,672" },
  {id: "4212;C", nomReseau: "Montrond-les-Bains", Commune: "Montrond-les-Bains", contenuCO2: "0,064", contenuCO2ACV: "0,09", tauxEnrR: "0,782" },
  {id: "4213;C", nomReseau: "Réseau de Chaleur VIACONFORT", Commune: "SAINT-ETIENNE", contenuCO2: "0,049", contenuCO2ACV: "0,101", tauxEnrR: "0,805" },
  {id: "4214;C", nomReseau: "Scevia quartier de Fonsala", Commune: "SAINT-CHAMOND", contenuCO2: "0,106", contenuCO2ACV: "0,133", tauxEnrR: "0,574" },
  {id: "4215;C", nomReseau: "Usson en Forez", Commune: "USSON-EN-FOREZ", contenuCO2: "0,039", contenuCO2ACV: "0,067", tauxEnrR: "0,905" },
  {id: "4216;C", nomReseau: "LOIRE FOREZ AGGLOMERATION Espace Déchelette", Commune: "SAINT-BONNET-LE-CHATEAU", contenuCO2: "0,047", contenuCO2ACV: "0,078", tauxEnrR: "0,865" },
  {id: "4217;C", nomReseau: "Jonzieux", Commune: "JONZIEUX", contenuCO2: "0,038", contenuCO2ACV: "0,067", tauxEnrR: "0,891" },
  {id: "4218;C", nomReseau: "Roisey", Commune: "ROISEY", contenuCO2: "0", contenuCO2ACV: "0,025", tauxEnrR: "1" },
  {id: "4219;C", nomReseau: "Planfoy 2 - ZAC des Lucioles", Commune: "PLANFOY", contenuCO2: "0,02", contenuCO2ACV: "0,047", tauxEnrR: "0,891" },
  {id: "4220;C", nomReseau: "Le Bessat 1 - Ecole", Commune: "LE BESSAT", contenuCO2: "0", contenuCO2ACV: "0,026", tauxEnrR: "1" },
  {id: "4221;C", nomReseau: "St Bonnet le Courreau", Commune: "SAINT-BONNET-LE-COURREAU", contenuCO2: "0,049", contenuCO2ACV: "0,08", tauxEnrR: "0,871" },
  {id: "4222;C", nomReseau: "La Terrasse sur Dorlay", Commune: "LA TERRASSE-SUR-DORLAY", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "4223;C", nomReseau: "St Régis du Coin", Commune: "SAINT-REGIS-DU-COIN", contenuCO2: "0,013", contenuCO2ACV: "0,048", tauxEnrR: "0,975" },
  {id: "4224;C", nomReseau: "St Didier sur Rochefort", Commune: "SAINT-DIDIER-SUR-ROCHEFORT", contenuCO2: "0,002", contenuCO2ACV: "0,022", tauxEnrR: "0,983" },
  {id: "4225;C", nomReseau: "Neulise", Commune: "NEULISE", contenuCO2: "0,035", contenuCO2ACV: "0,067", tauxEnrR: "0,907" },
  {id: "4226;C", nomReseau: "Pélussin - Quartier Notre Dame", Commune: "PELUSSIN", contenuCO2: "0,021", contenuCO2ACV: "0,044", tauxEnrR: "0,899" },
  {id: "4227;C", nomReseau: "St Christo en Jarez", Commune: "SAINT-CHRISTO-EN-JAREZ", contenuCO2: "0", contenuCO2ACV: "0,022", tauxEnrR: "1" },
  {id: "4228;C", nomReseau: "St Martin la Sauveté", Commune: "SAINT-MARTIN-LA-SAUVETE", contenuCO2: "0,027", contenuCO2ACV: "0,054", tauxEnrR: "0,975" },
  {id: "4229;C", nomReseau: "St Symphorien de Lay", Commune: "SAINT-SYMPHORIEN-DE-LAY", contenuCO2: "0", contenuCO2ACV: "0,015", tauxEnrR: "1" },
  {id: "4230;C", nomReseau: "Marlhes - Interconnexion", Commune: "MARLHES", contenuCO2: "0,062", contenuCO2ACV: "0,093", tauxEnrR: "0,835" },
  {id: "4231;C", nomReseau: "St Haon le Châtel", Commune: "SAINT-HAON-LE-CHATEL", contenuCO2: "0,048", contenuCO2ACV: "0,079", tauxEnrR: "0,881" },
  {id: "4232;C", nomReseau: "St Joseph", Commune: "SAINT-JOSEPH", contenuCO2: "0,094", contenuCO2ACV: "0,136", tauxEnrR: "0,773" },
  {id: "4233;C", nomReseau: "St Cyr de Favières", Commune: "SAINT-CYR-DE-FAVIERES", contenuCO2: "0", contenuCO2ACV: "0,024", tauxEnrR: "1" },
  {id: "4234;C", nomReseau: "Roanne - Quartier de Mâtel", Commune: "ROANNE", contenuCO2: "0,009", contenuCO2ACV: "0,03", tauxEnrR: "0,964" },
  {id: "4235;C", nomReseau: "Roche en Forez", Commune: "ROCHE", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "4236;C", nomReseau: "Pélussin 2 - Quartier des Croix", Commune: "PELUSSIN", contenuCO2: "0,033", contenuCO2ACV: "0,06", tauxEnrR: "0,898" },
  {id: "4237;C", nomReseau: "Roanne - Quartier Arsenal", Commune: "ROANNE", contenuCO2: "0,026", contenuCO2ACV: "0,049", tauxEnrR: "0,899" },
  {id: "4238;C", nomReseau: "St Maurice en Gourgois", Commune: "SAINT-MAURICE-EN-GOURGOIS", contenuCO2: "0,026", contenuCO2ACV: "0,056", tauxEnrR: "0,942" },
  {id: "4239;C", nomReseau: "Pélussin - Siège CCPR", Commune: "PELUSSIN", contenuCO2: "0,052", contenuCO2ACV: "0,086", tauxEnrR: "0,878" },
  {id: "4240;C", nomReseau: "Colombier", Commune: "COLOMBIER", contenuCO2: "0,066", contenuCO2ACV: "0,11", tauxEnrR: "0,894" },
  {id: "4241;C", nomReseau: "Farnay", Commune: "FARNAY", contenuCO2: "0,076", contenuCO2ACV: "0,11", tauxEnrR: "0,821" },
  {id: "4243;C", nomReseau: "La Tuilière", Commune: "LA TUILIERE", contenuCO2: "0,026", contenuCO2ACV: "0,051", tauxEnrR: "0,946" },
  {id: "4244;C", nomReseau: "LOIRE FOREZ AGGLOMERATION Réseau", Commune: "LURIECQ", contenuCO2: "0", contenuCO2ACV: "0,021", tauxEnrR: "1" },
  {id: "4245;C", nomReseau: "Réseau de La Valla en Gier", Commune: "La Valla en Gier", contenuCO2: "0", contenuCO2ACV: "0,022", tauxEnrR: "1" },
  {id: "4246;C", nomReseau: "Réseau d'Unieux", Commune: "Unieux", contenuCO2: "0,019", contenuCO2ACV: "0,045", tauxEnrR: "0,939" },
  {id: "4247;C", nomReseau: "Réseau de Saint Héand", Commune: "Saint Héand", contenuCO2: "0,022", contenuCO2ACV: "0,043", tauxEnrR: "0,914" },
  {id: "4301;C", nomReseau: "Réseau de Tence", Commune: "Tence", contenuCO2: "0,019", contenuCO2ACV: "0,055", tauxEnrR: "0,967" },
  {id: "4302;C", nomReseau: "4302C Mairie de Langeac", Commune: "LANGEAC", contenuCO2: "0", contenuCO2ACV: "0,021", tauxEnrR: "1" },
  {id: "4303;C", nomReseau: "Réseau du Puy en Velay VERT VEINE", Commune: "LE PUY-EN-VELAY", contenuCO2: "0,017", contenuCO2ACV: "0,039", tauxEnrR: "0,932" },
  {id: "4304;C", nomReseau: "Chaufferie de la Mairie", Commune: "Dunieres", contenuCO2: "0", contenuCO2ACV: "0,029", tauxEnrR: "1" },
  {id: "4305;C", nomReseau: "Réseau d'allegre", Commune: "ALLEGRE", contenuCO2: "0,003", contenuCO2ACV: "0,029", tauxEnrR: "0,992" },
  {id: "4306;C", nomReseau: "Chaufferie de la Piscine", Commune: "Dunieres", contenuCO2: "0", contenuCO2ACV: "0,029", tauxEnrR: "1" },
  {id: "4307;C", nomReseau: "Réseaux de Chaleur YES", Commune: "Yssingeaux", contenuCO2: "0,025", contenuCO2ACV: "0,049", tauxEnrR: "0,912" },
  {id: "4308;C", nomReseau: "Pradelles", Commune: "Pradelles", contenuCO2: "0,002", contenuCO2ACV: "0,025", tauxEnrR: "0,994" },
  {id: "4309;C", nomReseau: "Réseau de Mazet Si Voy", Commune: "MAZET-SAINT-VOY", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "4310;C", nomReseau: "Réseau de St Bonnet le Froid", Commune: "SAINT-BONNET-LE-FROID", contenuCO2: "0", contenuCO2ACV: "0,024", tauxEnrR: "1" },
  {id: "4314;C", nomReseau: "LA MION 2", Commune: "MAZET-SAINT-VOY", contenuCO2: "0", contenuCO2ACV: "0,021", tauxEnrR: "1" },
  {id: "4401;C", nomReseau: "Réseau de Nantes Centre-Loire", Commune: "NANTES", contenuCO2: "0,04", contenuCO2ACV: "0,056", tauxEnrR: "0,805" },
  {id: "4402;C", nomReseau: "CHATEAUBRIANT", Commune: "CHATEAUBRIANT", contenuCO2: "0,102", contenuCO2ACV: "0,14", tauxEnrR: "0,53" },
  {id: "4403;C", nomReseau: "NANTES CHANTRERIE", Commune: "NANTES", contenuCO2: "0,1", contenuCO2ACV: "0,125", tauxEnrR: "0,591" },
  {id: "4405;C", nomReseau: "Zup de Bellevue Saint Herblain", Commune: "NANTES", contenuCO2: "0,104", contenuCO2ACV: "0,13", tauxEnrR: "0,556" },
  {id: "4406;C", nomReseau: "AFUL Rezé-Château", Commune: "REZE", contenuCO2: "0,095", contenuCO2ACV: "0,121", tauxEnrR: "0,688" },
  {id: "4407;C", nomReseau: "Réseau de Saint-Jean-de-Boiseau", Commune: "SAINT-JEAN-DE-BOISEAU", contenuCO2: "0,237", contenuCO2ACV: "0,277", tauxEnrR: "0,4" },
  {id: "4408;C", nomReseau: "Réseau ZAC de la Minais", Commune: "SAINTE-LUCE-SUR-LOIRE", contenuCO2: "0,139", contenuCO2ACV: "0,168", tauxEnrR: "0,519" },
  {id: "4410;C", nomReseau: "Réseau de Nantes Nord Chézine", Commune: "NANTES", contenuCO2: "0,193", contenuCO2ACV: "0,222", tauxEnrR: "0,257" },
  {id: "4501;C", nomReseau: "Socos source", Commune: "Orléans", contenuCO2: "0,092", contenuCO2ACV: "0,115", tauxEnrR: "0,603" },
  {id: "4503;C", nomReseau: "Quartier Centre Ville et Nord", Commune: "Orléans", contenuCO2: "0", contenuCO2ACV: "0,037", tauxEnrR: "0,965" },
  {id: "4504;C", nomReseau: "Zup du Grand Clos", Commune: "Montargis", contenuCO2: "0,097", contenuCO2ACV: "0,129", tauxEnrR: "0,528" },
  {id: "4505;C", nomReseau: "Réseau de Fleury les Aubrais", Commune: "Fleury-les-Aubrais", contenuCO2: "0,079", contenuCO2ACV: "0,112", tauxEnrR: "0,602" },
  {id: "4506;C", nomReseau: "U.V.E Pithiviers", Commune: "Pithiviers", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "4507;C", nomReseau: "Réseau de Amilly", Commune: "AMILLY", contenuCO2: "0,009", contenuCO2ACV: "0,014", tauxEnrR: "0,96" },
  {id: "4601;C", nomReseau: "Réseau de Nuzéjouls", Commune: "Nuzejouls", contenuCO2: "0,026", contenuCO2ACV: "0,054", tauxEnrR: "0,94" },
  {id: "4603;C", nomReseau: "Réseau de Gramat", Commune: "Gramat", contenuCO2: "0", contenuCO2ACV: "0,017", tauxEnrR: "1" },
  {id: "4604;C", nomReseau: "Réseau de Biars sur Cere", Commune: "BIARS-SUR-CERE", contenuCO2: "0,009", contenuCO2ACV: "0,037", tauxEnrR: "0,98" },
  {id: "4605;C", nomReseau: "Réseau de Cajarc", Commune: "CAJARC", contenuCO2: "0,008", contenuCO2ACV: "0,032", tauxEnrR: "0,978" },
  {id: "4607;C", nomReseau: "Réseau de CATUS", Commune: "CATUS", contenuCO2: "0,014", contenuCO2ACV: "0,04", tauxEnrR: "0,963" },
  {id: "4608;C", nomReseau: "Réseau de Caillac", Commune: "CAILLAC", contenuCO2: "0,038", contenuCO2ACV: "0,076", tauxEnrR: "0,933" },
  {id: "4609;C", nomReseau: "Réseau de St Germain du Bel air", Commune: "SAINT-GERMAIN-DU-BEL-AIR", contenuCO2: "0,013", contenuCO2ACV: "0,04", tauxEnrR: "0,972" },
  {id: "4610;C", nomReseau: "Réseau des 4 routes du Lot", Commune: "LES QUATRE-ROUTES-DU-LOT", contenuCO2: "0,008", contenuCO2ACV: "0,039", tauxEnrR: "0,983" },
  {id: "4611;C", nomReseau: "Réseau de Sousceyrac", Commune: "SOUSCEYRAC", contenuCO2: "0,003", contenuCO2ACV: "0,026", tauxEnrR: "0,993" },
  {id: "4612;C", nomReseau: "Réseau de Figeac", Commune: "FIGEAC", contenuCO2: "0,002", contenuCO2ACV: "0,024", tauxEnrR: "0,994" },
  {id: "4613;C", nomReseau: "Réseau de Livernon", Commune: "LIVERNON", contenuCO2: "0,027", contenuCO2ACV: "0,057", tauxEnrR: "0,937" },
  {id: "4614;C", nomReseau: "Réseau de CAHORS", Commune: "CAHORS", contenuCO2: "0,018", contenuCO2ACV: "0,04", tauxEnrR: "0,933" },
  {id: "4615;C", nomReseau: "Réseau de THEGRA", Commune: "THEGRA", contenuCO2: "0", contenuCO2ACV: "0,03", tauxEnrR: "0,999" },
  {id: "4616;C", nomReseau: "Réseau de Lacapelle Marival", Commune: "LACAPELLE-MARIVAL", contenuCO2: "0,016", contenuCO2ACV: "0,042", tauxEnrR: "0,96" },
  {id: "4617;C", nomReseau: "Réseau de GOURDON", Commune: "GOURDON", contenuCO2: "0,005", contenuCO2ACV: "0,026", tauxEnrR: "0,982" },
  {id: "4701;C", nomReseau: "SOGAD", Commune: "LE PASSAGE", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "4802;C", nomReseau: "Mende", Commune: "MENDE", contenuCO2: "0", contenuCO2ACV: "0,022", tauxEnrR: "1" },
  {id: "4803;C", nomReseau: "SCABE Saint Chely d'Apcher Bois Energie", Commune: "SAINT-CHELY-D'APCHER", contenuCO2: "0,004", contenuCO2ACV: "0,022", tauxEnrR: "0,989" },
  {id: "4901;C", nomReseau: "Réseau d'Angers La Roseraie", Commune: "Angers", contenuCO2: "0,011", contenuCO2ACV: "0,017", tauxEnrR: "0,957" },
  {id: "4902;C", nomReseau: "RESEAU DES DEUX CROIX (NOZAY-UPJM)", Commune: "Angers", contenuCO2: "0,106", contenuCO2ACV: "0,139", tauxEnrR: "0,496" },
  {id: "4903;C", nomReseau: "CHU Angers", Commune: "ANGERS", contenuCO2: "0,018", contenuCO2ACV: "0,038", tauxEnrR: "0,925" },
  {id: "4904;C", nomReseau: "Réseau de chaleur d'Andrezé", Commune: "ANDREZE", contenuCO2: "0,08", contenuCO2ACV: "0,106", tauxEnrR: "0,715" },
  {id: "4905;C", nomReseau: "Chemin Vert", Commune: "Saumur", contenuCO2: "0,085", contenuCO2ACV: "0,111", tauxEnrR: "0,681" },
  {id: "4907;C", nomReseau: "Réseau d'Orgemont", Commune: "Angers", contenuCO2: "0", contenuCO2ACV: "0,049", tauxEnrR: "0,857" },
  {id: "4908;C", nomReseau: "Réseau de chaleur d'Ecouflant", Commune: "Ecouflant", contenuCO2: "0,113", contenuCO2ACV: "0,141", tauxEnrR: "0,594" },
  {id: "4910;C", nomReseau: "Hauts de Saint Aubin", Commune: "ANGERS", contenuCO2: "0,068", contenuCO2ACV: "0,093", tauxEnrR: "0,756" },
  {id: "4911;C", nomReseau: "Réseau de Belle Beille", Commune: "ANGERS", contenuCO2: "0,075", contenuCO2ACV: "0,099", tauxEnrR: "0,693" },
  {id: "4912;C", nomReseau: "Réseau de chaleur de la Herse", Commune: "MONTREUIL-BELLAY", contenuCO2: "0,02", contenuCO2ACV: "0,043", tauxEnrR: "0,937" },
  {id: "4913;C", nomReseau: "Réseau les Mauges", Commune: "CHOLET", contenuCO2: "0,11", contenuCO2ACV: "0,139", tauxEnrR: "0,52" },
  {id: "5001;C", nomReseau: "Provinces Energies", Commune: "CHERBOURG-OCTEVILLE", contenuCO2: "0,043", contenuCO2ACV: "0,069", tauxEnrR: "0,848" },
  {id: "5002;C", nomReseau: "Ilot Divette", Commune: "CHERBOURG-OCTEVILLE", contenuCO2: "0,102", contenuCO2ACV: "0,115", tauxEnrR: "0,274" },
  {id: "5003;C", nomReseau: "Régie de chauffage du Teilleul", Commune: "LE TEILLEUL", contenuCO2: "0,077", contenuCO2ACV: "0,111", tauxEnrR: "0,788" },
  {id: "5004;C", nomReseau: "Centre Hospitalier de L'estran", Commune: "PONTORSON", contenuCO2: "0,088", contenuCO2ACV: "0,109", tauxEnrR: "0,536" },
  {id: "5005;C", nomReseau: "Réseau communal La Lucerne d'Outremer", Commune: "LA LUCERNE-D'OUTREMER", contenuCO2: "0", contenuCO2ACV: "0,017", tauxEnrR: "1" },
  {id: "5101;C", nomReseau: "Zup de Laon Neufchatel", Commune: "REIMS", contenuCO2: "0,233", contenuCO2ACV: "0,267", tauxEnrR: "0" },
  {id: "5103;C", nomReseau: "VY'ENERGIE", Commune: "VITRY-LE-FRANCOIS", contenuCO2: "0,048", contenuCO2ACV: "0,072", tauxEnrR: "0,819" },
  {id: "5104;C", nomReseau: "Quartier Bernon", Commune: "Epernay", contenuCO2: "0,192", contenuCO2ACV: "0,241", tauxEnrR: "0" },
  {id: "5106;C", nomReseau: "Croix Rouge", Commune: "Reims", contenuCO2: "0,112", contenuCO2ACV: "0,132", tauxEnrR: "0,607" },
  {id: "5107;C", nomReseau: "La renaissance Immobilière Chalonnaise", Commune: "CHALONS-EN-CHAMPAGNE", contenuCO2: "0,045", contenuCO2ACV: "0,064", tauxEnrR: "0,806" },
  {id: "5202;C", nomReseau: "DSP ST DIZIER", Commune: "SAINT-DIZIER", contenuCO2: "0,064", contenuCO2ACV: "0,086", tauxEnrR: "0,729" },
  {id: "5204;C", nomReseau: "Réseau de chaleur du sud de la ville de Chaumont", Commune: "CHAUMONT", contenuCO2: "0,053", contenuCO2ACV: "0,064", tauxEnrR: "0,793" },
  {id: "5205;C", nomReseau: "Réseau de chaleur bois de Marac", Commune: "MARAC", contenuCO2: "0", contenuCO2ACV: "0,02", tauxEnrR: "1" },
  {id: "5206;C", nomReseau: "Réseau de chaleur de l'EHPAD St Augustin", Commune: "LONGEAU-PERCEY", contenuCO2: "0", contenuCO2ACV: "0,159", tauxEnrR: "1" },
  {id: "5207;C", nomReseau: "Réseau de Wassy", Commune: "WASSY", contenuCO2: "0,128", contenuCO2ACV: "0,14", tauxEnrR: "0,667" },
  {id: "5208;C", nomReseau: "Réseau de Bourmont", Commune: "BOURMONT", contenuCO2: "0,128", contenuCO2ACV: "0,14", tauxEnrR: "0,667" },
  {id: "5209;C", nomReseau: "Réseau de Langres", Commune: "LANGRES", contenuCO2: "0,067", contenuCO2ACV: "0,096", tauxEnrR: "0,669" },
  {id: "5213;C", nomReseau: "RESEAU DE FAYL BILLOT", Commune: "FAYL-BILLOT", contenuCO2: "0,256", contenuCO2ACV: "0,262", tauxEnrR: "0,333" },
  {id: "5214;C", nomReseau: "Réseau de Saint BLIN", Commune: "SAINT-BLIN", contenuCO2: "0,256", contenuCO2ACV: "0,262", tauxEnrR: "0,333" },
  {id: "5302;C", nomReseau: "Zup Saint Nicolas", Commune: "LAVAL", contenuCO2: "0,016", contenuCO2ACV: "0,022", tauxEnrR: "0,943" },
  {id: "5303;C", nomReseau: "Réseau de chaleur (piscine et salles omnisports)", Commune: "CHATEAU-GONTIER", contenuCO2: "0,088", contenuCO2ACV: "0,109", tauxEnrR: "0,582" },
  {id: "5401;C", nomReseau: "ESTIA - Saint Julien Kennedy", Commune: "Nancy", contenuCO2: "0,097", contenuCO2ACV: "0,121", tauxEnrR: "0,621" },
  {id: "5403;C", nomReseau: "S.E.E.V - Ville de Vandoeuvre", Commune: "Vandœuvre-lès-Nancy", contenuCO2: "0,044", contenuCO2ACV: "0,055", tauxEnrR: "0,811" },
  {id: "5407;C", nomReseau: "ESTIA - Plateau de Haye", Commune: "Nancy", contenuCO2: "0,082", contenuCO2ACV: "0,131", tauxEnrR: "0,52" },
  {id: "5408;C", nomReseau: "Réseau d'Ecrouves", Commune: "ECROUVES", contenuCO2: "0,093", contenuCO2ACV: "0,119", tauxEnrR: "0,676" },
  {id: "5409;C", nomReseau: "Réseau chaufferie bois - Barbonville", Commune: "BARBONVILLE", contenuCO2: "0,128", contenuCO2ACV: "0,137", tauxEnrR: "0,667" },
  {id: "5410;C", nomReseau: "S.E.E.V - Plateau de Brabois", Commune: "VANDOEUVRE-LES-NANCY", contenuCO2: "0,087", contenuCO2ACV: "0,11", tauxEnrR: "0,612" },
  {id: "5411;C", nomReseau: "ESTIA - Blandan Medreville", Commune: "NANCY", contenuCO2: "0,094", contenuCO2ACV: "0,117", tauxEnrR: "0,587" },
  {id: "5412;C", nomReseau: "Réseau de Pont à Mousson", Commune: "PONT-A-MOUSSON", contenuCO2: "0,027", contenuCO2ACV: "0,064", tauxEnrR: "0,899" },
  {id: "5413;C", nomReseau: "ESTIA - LAXOU-VILLERS", Commune: "LAXOU", contenuCO2: "0,097", contenuCO2ACV: "0,135", tauxEnrR: "0,489" },
  {id: "5414;C", nomReseau: "RESEAU DE CHALEUR BRIEY", Commune: "BRIEY", contenuCO2: "0,054", contenuCO2ACV: "0,077", tauxEnrR: "0,804" },
  {id: "5501;C", nomReseau: "Côte Sainte Catherine", Commune: "Bar-le-Duc", contenuCO2: "0,093", contenuCO2ACV: "0,283", tauxEnrR: "0" },
  {id: "5502;C", nomReseau: "Zup Anthouard", Commune: "Verdun", contenuCO2: "0,208", contenuCO2ACV: "0,252", tauxEnrR: "0" },
  {id: "5503;C", nomReseau: "Ligny en Barrois", Commune: "Ligny-en-Barrois", contenuCO2: "0,095", contenuCO2ACV: "0,122", tauxEnrR: "0,685" },
  {id: "5505;C", nomReseau: "Réseau Meuse-Energie", Commune: "TRONVILLE-EN-BARROIS", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "5601;C", nomReseau: "Réseau de Lanester", Commune: "LANESTER", contenuCO2: "0,092", contenuCO2ACV: "0,116", tauxEnrR: "0,656" },
  {id: "5602;C", nomReseau: "Réseau de chaleur zac centre", Commune: "HENNEBONT", contenuCO2: "0", contenuCO2ACV: "0,02", tauxEnrR: "1" },
  {id: "5603;C", nomReseau: "Réseau de chaleur du GUMENEN", Commune: "AURAY", contenuCO2: "0,127", contenuCO2ACV: "0,263", tauxEnrR: "0,235" },
  {id: "5604;C", nomReseau: "Réseau de chaleur Liger", Commune: "LOCMINE", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "5607;C", nomReseau: "Réseau de Serent", Commune: "SERENT", contenuCO2: "0,005", contenuCO2ACV: "0,036", tauxEnrR: "0,931" },
  {id: "5608;C", nomReseau: "Réseau de chaleur de Locmiquelic", Commune: "LOCMIQUELIC", contenuCO2: "0,029", contenuCO2ACV: "0,048", tauxEnrR: "0,865" },
  {id: "5701;C", nomReseau: "Réseau de Metz", Commune: "Metz", contenuCO2: "0,035", contenuCO2ACV: "0,107", tauxEnrR: "0,666" },
  {id: "5702;C", nomReseau: "Réseau de la Ville de Yutz", Commune: "YUTZ", contenuCO2: "0,072", contenuCO2ACV: "0,098", tauxEnrR: "0,727" },
  {id: "5703;C", nomReseau: "Réseau de Chaleur de Farébersviller", Commune: "FAREBERSVILLER", contenuCO2: "0,084", contenuCO2ACV: "0,168", tauxEnrR: "0,393" },
  {id: "5705;C", nomReseau: "Wenheck", Commune: "Saint-Avold", contenuCO2: "0,179", contenuCO2ACV: "0,268", tauxEnrR: "0" },
  {id: "5706;C", nomReseau: "Réseau de Freyming Merlebach", Commune: "Freyming-Merlebach", contenuCO2: "0,119", contenuCO2ACV: "0,156", tauxEnrR: "0,532" },
  {id: "5707;C", nomReseau: "Réseau de Sarreguemines", Commune: "Sarreguemines", contenuCO2: "0,244", contenuCO2ACV: "0,278", tauxEnrR: "0" },
  {id: "5708;C", nomReseau: "Réseau de Holweg-Forbach-Behren", Commune: "BEHREN-LES-FORBACH", contenuCO2: "0", contenuCO2ACV: "0,065", tauxEnrR: "0,885" },
  {id: "5709;C", nomReseau: "Carrière", Commune: "Saint-Avold", contenuCO2: "0,227", contenuCO2ACV: "0,255", tauxEnrR: "0" },
  {id: "5710;C", nomReseau: "Côte de la Justice", Commune: "Saint-Avold", contenuCO2: "0,232", contenuCO2ACV: "0,261", tauxEnrR: "0" },
  {id: "5711;C", nomReseau: "Huchet", Commune: "Saint-Avold", contenuCO2: "0,199", contenuCO2ACV: "0,224", tauxEnrR: "0" },
  {id: "5712;C", nomReseau: "Stiring Wendel", Commune: "Stiring-Wendel", contenuCO2: "0", contenuCO2ACV: "0,02", tauxEnrR: "1" },
  {id: "5713;C", nomReseau: "Réseau de chauffage urbain de Creutzwald", Commune: "Creutzwald", contenuCO2: "0,22", contenuCO2ACV: "0,275", tauxEnrR: "0" },
  {id: "5714;C", nomReseau: "Réseau de Sarrebourg", Commune: "SARREBOURG", contenuCO2: "0,233", contenuCO2ACV: "0,256", tauxEnrR: "0,369" },
  {id: "5716;C", nomReseau: "Réseau Cité Belle-Roche", Commune: "COCHEREN", contenuCO2: "0,254", contenuCO2ACV: "0,285", tauxEnrR: "0" },
  {id: "5717;C", nomReseau: "Réseau Cité Pasteur", Commune: "ROUHLING", contenuCO2: "0,243", contenuCO2ACV: "0,273", tauxEnrR: "0" },
  {id: "5802;C", nomReseau: "Reseau de chaleur du Banlay", Commune: "Nevers", contenuCO2: "0,044", contenuCO2ACV: "0,069", tauxEnrR: "0,777" },
  {id: "5803;C", nomReseau: "Réseau de decize", Commune: "DECIZE", contenuCO2: "0,048", contenuCO2ACV: "0,073", tauxEnrR: "0,838" },
  {id: "5804;C", nomReseau: "Réseau de chaleur de la Maison du Parc du Morvan", Commune: "SAINT-BRISSON", contenuCO2: "0", contenuCO2ACV: "0,027", tauxEnrR: "1" },
  {id: "5805;C", nomReseau: "Réseau de chaleur d'Arleuf", Commune: "ARLEUF", contenuCO2: "0,021", contenuCO2ACV: "0,054", tauxEnrR: "0,961" },
  {id: "5806;C", nomReseau: "Réseau de chaleur de Bazolles", Commune: "BAZOLLES", contenuCO2: "0", contenuCO2ACV: "0,02", tauxEnrR: "1" },
  {id: "5807;C", nomReseau: "Réseau de chaleur de Corbigny", Commune: "CORBIGNY", contenuCO2: "0,036", contenuCO2ACV: "0,061", tauxEnrR: "0,895" },
  {id: "5808;C", nomReseau: "Réseau de chaleur de Lormes", Commune: "LORMES", contenuCO2: "0,031", contenuCO2ACV: "0,06", tauxEnrR: "0,927" },
  {id: "5809;C", nomReseau: "Réseau de chaleur de Montigny en Morvan", Commune: "MONTIGNY-EN-MORVAN", contenuCO2: "0,045", contenuCO2ACV: "0,08", tauxEnrR: "0,914" },
  {id: "5810;C", nomReseau: "Réseau de chaleur d'Ouroux en Morvan", Commune: "OUROUX-EN-MORVAN", contenuCO2: "0", contenuCO2ACV: "0,022", tauxEnrR: "1" },
  {id: "5811;C", nomReseau: "Réseau de chaleur de Planchez", Commune: "PLANCHEZ", contenuCO2: "0", contenuCO2ACV: "0,027", tauxEnrR: "1" },
  {id: "5812;C", nomReseau: "Réseau de chaleur de Saint Amand en Puisaye", Commune: "SAINT-AMAND-EN-PUISAYE", contenuCO2: "0,046", contenuCO2ACV: "0,074", tauxEnrR: "0,885" },
  {id: "5813;C", nomReseau: "Réseau de chaleur de Varzy", Commune: "VARZY", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "5814;C", nomReseau: "Réseau de chaleur de Corancy", Commune: "CORANCY", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "5815;C", nomReseau: "Réseau de chaleur de Saint Saulge", Commune: "SAINT-SAULGE", contenuCO2: "0,027", contenuCO2ACV: "0,053", tauxEnrR: "0,933" },
  {id: "5816;C", nomReseau: "Réseau de chaleur de Billy sur Oisy", Commune: "BILLY-SUR-OISY", contenuCO2: "0", contenuCO2ACV: "0,025", tauxEnrR: "1" },
  {id: "5817;C", nomReseau: "Réseau de chaleur de Brassy", Commune: "BRASSY", contenuCO2: "0,08", contenuCO2ACV: "0,12", tauxEnrR: "0,849" },
  {id: "5818;C", nomReseau: "Réseau de chaleur de Château Chinon Ville", Commune: "CHATEAU-CHINON (VILLE)", contenuCO2: "0", contenuCO2ACV: "0,021", tauxEnrR: "1" },
  {id: "5819;C", nomReseau: "Réseau de Cosne-cours-sur-loire", Commune: "COSNE-COURS-SUR-LOIRE", contenuCO2: "0,029", contenuCO2ACV: "0,051", tauxEnrR: "0,885" },
  {id: "5820;C", nomReseau: "Château Chinon EIAT", Commune: "CHATEAU-CHINON (VILLE)", contenuCO2: "0,094", contenuCO2ACV: "0,133", tauxEnrR: "0,759" },
  {id: "5821;C", nomReseau: "CHALLUY", Commune: "CHALLUY", contenuCO2: "0,051", contenuCO2ACV: "0,076", tauxEnrR: "0,826" },
  {id: "5822;C", nomReseau: "Moux en Morvan", Commune: "MOUX-EN-MORVAN", contenuCO2: "0,043", contenuCO2ACV: "0,075", tauxEnrR: "0,907" },
  {id: "5823;C", nomReseau: "La Charité sur Loire", Commune: "LA CHARITE-SUR-LOIRE", contenuCO2: "0,008", contenuCO2ACV: "0,029", tauxEnrR: "0,968" },
  {id: "5824;C", nomReseau: "Réseau de Luzy", Commune: "AVREE", contenuCO2: "0,054", contenuCO2ACV: "0,083", tauxEnrR: "0,827" },
  {id: "5901;C", nomReseau: "Réseau de Roubaix", Commune: "ROUBAIX", contenuCO2: "0,093", contenuCO2ACV: "0,127", tauxEnrR: "0,563" },
  {id: "5902;C", nomReseau: "Les Canonniers", Commune: "VALENCIENNES", contenuCO2: "0,119", contenuCO2ACV: "0,272", tauxEnrR: "0" },
  {id: "5904;C", nomReseau: "Quartier Pont de Bois", Commune: "VILLENEUVE-D'ASCQ", contenuCO2: "0,276", contenuCO2ACV: "0,319", tauxEnrR: "0,046" },
  {id: "5905;C", nomReseau: "Domaine Universitaire et Scientifique", Commune: "VILLENEUVE-D'ASCQ", contenuCO2: "0,205", contenuCO2ACV: "0,249", tauxEnrR: "0" },
  {id: "5906;C", nomReseau: "Réseaux de Wattignies", Commune: "WATTIGNIES", contenuCO2: "0,116", contenuCO2ACV: "0,189", tauxEnrR: "0,37" },
  {id: "5907;C", nomReseau: "Réseau de Wattrelos", Commune: "WATTRELOS", contenuCO2: "0,186", contenuCO2ACV: "0,21", tauxEnrR: "0,278" },
  {id: "5908;C", nomReseau: "Réseau de chaleur de Sin Le Noble", Commune: "SIN-LE-NOBLE", contenuCO2: "0,076", contenuCO2ACV: "0,1", tauxEnrR: "0,702" },
  {id: "5909;C", nomReseau: "Réseau de Mons-en-Baroeul", Commune: "MONS-EN-BAROEUL", contenuCO2: "0,097", contenuCO2ACV: "0,13", tauxEnrR: "0,553" },
  {id: "5910;C", nomReseau: "Réseau de Lille", Commune: "LILLE", contenuCO2: "0,262", contenuCO2ACV: "0,298", tauxEnrR: "0,01" },
  {id: "5911;C", nomReseau: "Réseau de LAMBERSART", Commune: "LAMBERSART", contenuCO2: "0,103", contenuCO2ACV: "0,157", tauxEnrR: "0,505" },
  {id: "5912;C", nomReseau: "MAUBEUGE ENERGIE RENOUVELABLE", Commune: "Maubeuge", contenuCO2: "0,202", contenuCO2ACV: "0,228", tauxEnrR: "0" },
  {id: "5913;C", nomReseau: "Les Rives Créatives de l'Escaut", Commune: "ANZIN", contenuCO2: "0,256", contenuCO2ACV: "0,262", tauxEnrR: "0,333" },
  {id: "5914;C", nomReseau: "Energie Grand Littoral - Dunkerque", Commune: "Dunkerque", contenuCO2: "0,127", contenuCO2ACV: "0,187", tauxEnrR: "0,471" },
  {id: "5915;C", nomReseau: "Réseau de chaleur de Douchy", Commune: "DOUCHY-LES-MINES", contenuCO2: "0,026", contenuCO2ACV: "0,033", tauxEnrR: "0,971" },
  {id: "5916;C", nomReseau: "Réseau de chaleur d'Hazebrouck", Commune: "HAZEBROUCK", contenuCO2: "0,066", contenuCO2ACV: "0,096", tauxEnrR: "0,752" },
  {id: "5917;C", nomReseau: "Quartier Tournebride Lomme-Capinghem", Commune: "LILLE", contenuCO2: "0,091", contenuCO2ACV: "0,116", tauxEnrR: "0,665" },
  {id: "5918;C", nomReseau: "Réseau de chaleur de Baisieux", Commune: "BAISIEUX", contenuCO2: "0,306", contenuCO2ACV: "0,314", tauxEnrR: "0,101" },
  {id: "5919;C", nomReseau: "Réseau du Parc St Maur", Commune: "LILLE", contenuCO2: "0,197", contenuCO2ACV: "0,232", tauxEnrR: "0" },
  {id: "5920;C", nomReseau: "Réseau ARSYEL", Commune: "GRANDE-SYNTHE", contenuCO2: "0,096", contenuCO2ACV: "0,159", tauxEnrR: "0,718" },
  {id: "6001;C", nomReseau: "Réseau de Compiègne", Commune: "Compiègne", contenuCO2: "0,244", contenuCO2ACV: "0,279", tauxEnrR: "0" },
  {id: "6002;C", nomReseau: "La Cavée et les hironvalles", Commune: "Creil", contenuCO2: "0,103", contenuCO2ACV: "0,153", tauxEnrR: "0,451" },
  {id: "6003;C", nomReseau: "Quartier des Obiers", Commune: "Nogent-sur-Oise", contenuCO2: "0,085", contenuCO2ACV: "0,112", tauxEnrR: "0,572" },
  {id: "6004;C", nomReseau: "Les Martinets", Commune: "Montataire", contenuCO2: "0,217", contenuCO2ACV: "0,25", tauxEnrR: "0" },
  {id: "6005;C", nomReseau: "Réseau du Quartier Saint Jean", Commune: "BEAUVAIS", contenuCO2: "0,046", contenuCO2ACV: "0,071", tauxEnrR: "0,833" },
  {id: "6006;C", nomReseau: "Réseau de Breteuil-sur-Noye", Commune: "BRETEUIL", contenuCO2: "0,012", contenuCO2ACV: "0,037", tauxEnrR: "0,966" },
  {id: "6007;C", nomReseau: "VSPU Villers Saint Paul Utilités", Commune: "VILLERS-SAINT-PAUL", contenuCO2: "0,048", contenuCO2ACV: "0,058", tauxEnrR: "0,83" },
  {id: "6101;C", nomReseau: "Perseigne", Commune: "Alençon", contenuCO2: "0,044", contenuCO2ACV: "0,07", tauxEnrR: "0,83" },
  {id: "6102;C", nomReseau: "Quartier Saint Sauveur", Commune: "Flers", contenuCO2: "0,062", contenuCO2ACV: "0,087", tauxEnrR: "0,783" },
  {id: "6103;C", nomReseau: "Quartier Nord - Route de la Falaise", Commune: "Argentan", contenuCO2: "0,091", contenuCO2ACV: "0,131", tauxEnrR: "0,554" },
  {id: "6104;C", nomReseau: "Réseau de la Ferté Mace", Commune: "LA FERTE-MACE", contenuCO2: "0,022", contenuCO2ACV: "0,044", tauxEnrR: "0,915" },
  {id: "6105;C", nomReseau: "RECBIA", Commune: "L'AIGLE", contenuCO2: "0,02", contenuCO2ACV: "0,043", tauxEnrR: "0,915" },
  {id: "6106;C", nomReseau: "Réseau de Perrou", Commune: "PERROU", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "6109;C", nomReseau: "ALENCON ENERGIE BOIS", Commune: "ALENCON", contenuCO2: "0,115", contenuCO2ACV: "0,147", tauxEnrR: "0,494" },
  {id: "6201;C", nomReseau: "Zup du Quartier République - Avion", Commune: "Avion", contenuCO2: "0,174", contenuCO2ACV: "0,235", tauxEnrR: "0" },
  {id: "6202;C", nomReseau: "Réseau de ARQUES", Commune: "ARQUES", contenuCO2: "0,08", contenuCO2ACV: "0,107", tauxEnrR: "0,728" },
  {id: "6203;C", nomReseau: "Réseau de chaleur de Lens", Commune: "Lens", contenuCO2: "0,011", contenuCO2ACV: "0,031", tauxEnrR: "0,961" },
  {id: "6204;C", nomReseau: "Réseau de chaleur de Béthune", Commune: "Béthune", contenuCO2: "0,241", contenuCO2ACV: "0,289", tauxEnrR: "0" },
  {id: "6205;C", nomReseau: "Le Portel-Outreau", Commune: "OUTREAU", contenuCO2: "0,094", contenuCO2ACV: "0,212", tauxEnrR: "0,388" },
  {id: "6206;C", nomReseau: "Réseau de chaleur de Liévin", Commune: "Liévin", contenuCO2: "0,24", contenuCO2ACV: "0,273", tauxEnrR: "0" },
  {id: "6207;C", nomReseau: "Réseau de Chaleur de Calais", Commune: "Calais", contenuCO2: "0,128", contenuCO2ACV: "0,156", tauxEnrR: "0,55" },
  {id: "6209;C", nomReseau: "Réseau de chaleur d'Achicourt", Commune: "ACHICOURT", contenuCO2: "0,098", contenuCO2ACV: "0,112", tauxEnrR: "0,592" },
  {id: "6210;C", nomReseau: "Réseau de chaleur d'Arras", Commune: "Arras", contenuCO2: "0,118", contenuCO2ACV: "0,153", tauxEnrR: "0,493" },
  {id: "6211;C", nomReseau: "Réseau de chaleur de Boulogne sur Mer", Commune: "BOULOGNE-SUR-MER", contenuCO2: "0,101", contenuCO2ACV: "0,137", tauxEnrR: "0,474" },
  {id: "6212;C", nomReseau: "Réseau de chaleur Le Portel-Outreau", Commune: "OUTREAU", contenuCO2: "0,146", contenuCO2ACV: "0,258", tauxEnrR: "0,273" },
  {id: "6213;C", nomReseau: "Chaufferie bois d'Anvin", Commune: "ANVIN", contenuCO2: "0,256", contenuCO2ACV: "0,353", tauxEnrR: "0,333" },
  {id: "6301;C", nomReseau: "Réseau de Riom RCBE", Commune: "RIOM", contenuCO2: "0,021", contenuCO2ACV: "0,045", tauxEnrR: "0,924" },
  {id: "6303;C", nomReseau: "HLM Saint Jacques", Commune: "Clermont-Ferrand", contenuCO2: "0,204", contenuCO2ACV: "0,231", tauxEnrR: "0" },
  {id: "6304;C", nomReseau: "Quartier du Masage", Commune: "Beaumont", contenuCO2: "0,177", contenuCO2ACV: "0,211", tauxEnrR: "0" },
  {id: "6305;C", nomReseau: "Réseau de Rochefort-Montagne", Commune: "ROCHEFORT-MONTAGNE", contenuCO2: "0,018", contenuCO2ACV: "0,05", tauxEnrR: "0,96" },
  {id: "6306;C", nomReseau: "La Gauthière", Commune: "CLERMONT-FERRAND", contenuCO2: "0,084", contenuCO2ACV: "0,138", tauxEnrR: "0,507" },
  {id: "6307;C", nomReseau: "Réseau de Royat", Commune: "ROYAT", contenuCO2: "0,232", contenuCO2ACV: "0,262", tauxEnrR: "0" },
  {id: "6308;C", nomReseau: "Croix-de-Neyrat / Champratel / Les Vergnes", Commune: "CLERMONT-FERRAND", contenuCO2: "0,022", contenuCO2ACV: "0,044", tauxEnrR: "0,915" },
  {id: "6309;C", nomReseau: "Réseau de chaleur bois de Pontaumur", Commune: "PONTAUMUR", contenuCO2: "0,068", contenuCO2ACV: "0,105", tauxEnrR: "0,84" },
  {id: "6310;C", nomReseau: "CORAL", Commune: "AMBERT", contenuCO2: "0", contenuCO2ACV: "0,024", tauxEnrR: "1" },
  {id: "6311;C", nomReseau: "Réseau de chaleur de St-Germain-l'Herm", Commune: "SAINT-GERMAIN-L'HERM", contenuCO2: "0", contenuCO2ACV: "0,027", tauxEnrR: "1" },
  {id: "6312;C", nomReseau: "Réseau de chaleur de Ardes", Commune: "ARDES", contenuCO2: "0", contenuCO2ACV: "0,027", tauxEnrR: "1" },
  {id: "6313;C", nomReseau: "Réseau de St Clément de Régnat", Commune: "SAINT-CLEMENT-DE-REGNAT", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "6401;C", nomReseau: "SPIC réseau de chaleur du hameau", Commune: "PAU", contenuCO2: "0,025", contenuCO2ACV: "0,047", tauxEnrR: "0,903" },
  {id: "6402;C", nomReseau: "EGURRETIK", Commune: "BAYONNE", contenuCO2: "0,039", contenuCO2ACV: "0,063", tauxEnrR: "0,853" },
  {id: "6501;C", nomReseau: "Réseau de Vic-en-Bigorre", Commune: "VIC-EN-BIGORRE", contenuCO2: "0,067", contenuCO2ACV: "0,094", tauxEnrR: "0,771" },
  {id: "6604;C", nomReseau: "reseau de perpignan", Commune: "perpignan", contenuCO2: "0,003", contenuCO2ACV: "0,008", tauxEnrR: "0,987" },
  {id: "6701;C", nomReseau: "Elsau", Commune: "Strasbourg", contenuCO2: "0,104", contenuCO2ACV: "0,124", tauxEnrR: "0,62" },
  {id: "6702;C", nomReseau: "Hautepierre", Commune: "Strasbourg", contenuCO2: "0,231", contenuCO2ACV: "0,261", tauxEnrR: "0" },
  {id: "6703;C", nomReseau: "L'Esplanade", Commune: "Strasbourg", contenuCO2: "0,074", contenuCO2ACV: "0,096", tauxEnrR: "0,715" },
  {id: "6704;C", nomReseau: "Eco-quartier Brasserie Cronenbourg", Commune: "Strasbourg", contenuCO2: "0,065", contenuCO2ACV: "0,036", tauxEnrR: "0,654" },
  {id: "6705;C", nomReseau: "Réseau de Hochfelden", Commune: "Strasbourg", contenuCO2: "0,091", contenuCO2ACV: "0,073", tauxEnrR: "0,618" },
  {id: "6708;C", nomReseau: "Cité de l'Ill", Commune: "Strasbourg", contenuCO2: "0,078", contenuCO2ACV: "0,101", tauxEnrR: "0,658" },
  {id: "6709;C", nomReseau: "Le Ried", Commune: "Schiltigheim", contenuCO2: "0,187", contenuCO2ACV: "0,248", tauxEnrR: "0" },
  {id: "6710;C", nomReseau: "Réseau de Haguenau", Commune: "Haguenau", contenuCO2: "0,091", contenuCO2ACV: "0,115", tauxEnrR: "0,629" },
  {id: "6711;C", nomReseau: "Réseau de la COMMUNAUTÉ DE COMMUNES SAUER-PECHELBR", Commune: "MORSBRONN-LES-BAINS", contenuCO2: "0,094", contenuCO2ACV: "0,122", tauxEnrR: "0,698" },
  {id: "6712;C", nomReseau: "Chaufferie", Commune: "SAALES", contenuCO2: "0", contenuCO2ACV: "0,024", tauxEnrR: "1" },
  {id: "6713;C", nomReseau: "Réseau des Tanneries - Bohrie", Commune: "LINGOLSHEIM", contenuCO2: "0,007", contenuCO2ACV: "0,101", tauxEnrR: "0,656" },
  {id: "6714;C", nomReseau: "Réseau MARS", Commune: "HAGUENAU", contenuCO2: "0,041", contenuCO2ACV: "0,049", tauxEnrR: "0,823" },
  {id: "6715;C", nomReseau: "Niederbronn", Commune: "NIEDERBRONN-LES-BAINS", contenuCO2: "0,295", contenuCO2ACV: "0,305", tauxEnrR: "0,203" },
  {id: "6716;C", nomReseau: "réseau de chaleur d'Allenwiller", Commune: "ALLENWILLER", contenuCO2: "0,128", contenuCO2ACV: "0,148", tauxEnrR: "0,667" },
  {id: "6717;C", nomReseau: "Réseau de chaleur du Rebberg", Commune: "LA PETITE-PIERRE", contenuCO2: "0", contenuCO2ACV: "0,024", tauxEnrR: "1" },
  {id: "6721;C", nomReseau: "Réseau de Villé-Bassemberg", Commune: "VILLE", contenuCO2: "0,022", contenuCO2ACV: "0,046", tauxEnrR: "0,928" },
  {id: "6722;C", nomReseau: "Colmar Vosges", Commune: "STRASBOURG", contenuCO2: "0,107", contenuCO2ACV: "0,132", tauxEnrR: "0,554" },
  {id: "6723;C", nomReseau: "ECOGI", Commune: "RITTERSHOFFEN", contenuCO2: "0", contenuCO2ACV: "0,015", tauxEnrR: "1" },
  {id: "6724;C", nomReseau: "Sélestat", Commune: "SELESTAT", contenuCO2: "0,033", contenuCO2ACV: "0,055", tauxEnrR: "0,859" },
  {id: "6725;C", nomReseau: "Réseau de Chaleur ECO2WACKEN", Commune: "STRASBOURG", contenuCO2: "0,036", contenuCO2ACV: "0,058", tauxEnrR: "0,869" },
  {id: "6726;C", nomReseau: "Réseau de Chaleur Quartier du Lac", Commune: "LINGOLSHEIM", contenuCO2: "0,003", contenuCO2ACV: "0,123", tauxEnrR: "0,621" },
  {id: "6727;C", nomReseau: "Réseau de Chaleur Quartier des Hirondelles", Commune: "ESCHAU", contenuCO2: "0,028", contenuCO2ACV: "0,118", tauxEnrR: "0,396" },
  {id: "6728;C", nomReseau: "Réseau de Chaleur Quartier Solaire Schiltigheim", Commune: "SCHILTIGHEIM", contenuCO2: "0,058", contenuCO2ACV: "0,032", tauxEnrR: "0,717" },
  {id: "6730;C", nomReseau: "ZAC ETOILE", Commune: "STRASBOURG", contenuCO2: "0,076", contenuCO2ACV: "0,07", tauxEnrR: "0,639" },
  {id: "6731;C", nomReseau: "Quartier des Ecrivains", Commune: "SCHILTIGHEIM", contenuCO2: "0,092", contenuCO2ACV: "0,15", tauxEnrR: "0,355" },
  {id: "6732;C", nomReseau: "Réseau de chaleur Cogénération Sarre-Union", Commune: "SARRE UNION", contenuCO2: "0,15", contenuCO2ACV: "0,227", tauxEnrR: "0" },
  {id: "6733;C", nomReseau: "Réseau de Chaleur SPL", Commune: "STRASBOURG", contenuCO2: "0", contenuCO2ACV: "0,455", tauxEnrR: "0" },
  {id: "6801;C", nomReseau: "Réseau de Colmar", Commune: "Colmar", contenuCO2: "0,054", contenuCO2ACV: "0,069", tauxEnrR: "0,848" },
  {id: "6802;C", nomReseau: "L'Illberg", Commune: "Brunstatt-Didenheim", contenuCO2: "0,079", contenuCO2ACV: "0,107", tauxEnrR: "0,554" },
  {id: "6803;C", nomReseau: "Porte de Bâle", Commune: "Mulhouse", contenuCO2: "0,185", contenuCO2ACV: "0,208", tauxEnrR: "0" },
  {id: "6804;C", nomReseau: "Réseau de la ville de Saint Louis", Commune: "Saint-Louis", contenuCO2: "0", contenuCO2ACV: "0,046", tauxEnrR: "0,912" },
  {id: "6806;C", nomReseau: "Réseau de Cernay", Commune: "CERNAY", contenuCO2: "0,064", contenuCO2ACV: "0,089", tauxEnrR: "0,783" },
  {id: "6809;C", nomReseau: "Réseau d'Heimersdorf", Commune: "Heimersdorf", contenuCO2: "0,02", contenuCO2ACV: "0,041", tauxEnrR: "0,932" },
  {id: "6810;C", nomReseau: "Réseau de chaleur de Rixheim", Commune: "RIXHEIM", contenuCO2: "0,048", contenuCO2ACV: "0,075", tauxEnrR: "0,845" },
  {id: "6814;C", nomReseau: "Réseau de chaleur de Kaysersberg", Commune: "KAYSERSBERG", contenuCO2: "0,101", contenuCO2ACV: "0,125", tauxEnrR: "0,569" },
  {id: "6815;C", nomReseau: "Réseau d'Ammerzwiller", Commune: "AMMERZWILLER", contenuCO2: "0,256", contenuCO2ACV: "0,258", tauxEnrR: "0,333" },
  {id: "6816;C", nomReseau: "Réseau de chaleur de Thann", Commune: "THANN", contenuCO2: "0", contenuCO2ACV: "0,017", tauxEnrR: "1" },
  {id: "6817;C", nomReseau: "réseau communal de Friesen", Commune: "FRIESEN", contenuCO2: "0", contenuCO2ACV: "0,017", tauxEnrR: "1" },
  {id: "6818;C", nomReseau: "Réseau de Lapoutroie", Commune: "Lapoutroie", contenuCO2: "0", contenuCO2ACV: "0,026", tauxEnrR: "1" },
  {id: "6819;C", nomReseau: "Réseau Val d'Argent", Commune: "SAINTE-MARIE-AUX-MINES", contenuCO2: "0,075", contenuCO2ACV: "0,099", tauxEnrR: "0,699" },
  {id: "6820;C", nomReseau: "Réseau chaufferies bois Freland", Commune: "FRELAND", contenuCO2: "0", contenuCO2ACV: "0,072", tauxEnrR: "1" },
  {id: "6821;C", nomReseau: "Papeteries du Rhin", Commune: "ILLZACH", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "6901;C", nomReseau: "Vénissieux énergies", Commune: "Vénissieux", contenuCO2: "0,114", contenuCO2ACV: "0,144", tauxEnrR: "0,504" },
  {id: "6902;C", nomReseau: "HLM Les Sources", Commune: "Ecully", contenuCO2: "0,221", contenuCO2ACV: "0,244", tauxEnrR: "0" },
  {id: "6904;C", nomReseau: "Quartier La Duchère et Lyon 9e", Commune: "Champagne-au-Mont-d ‘Or", contenuCO2: "0,086", contenuCO2ACV: "0,111", tauxEnrR: "0,688" },
  {id: "6905;C", nomReseau: "Réseau de Lyon", Commune: "LYON", contenuCO2: "0,112", contenuCO2ACV: "0,131", tauxEnrR: "0,574" },
  {id: "6906;C", nomReseau: "V3E - Réseau Grand Ile", Commune: "Vaulx-en-Velin", contenuCO2: "0,093", contenuCO2ACV: "0,123", tauxEnrR: "0,602" },
  {id: "6908;C", nomReseau: "Quartier La Perralière", Commune: "Villeurbanne", contenuCO2: "0,211", contenuCO2ACV: "0,238", tauxEnrR: "0" },
  {id: "6910;C", nomReseau: "RILLIEUX-LA-PAPE", Commune: "RILLIEUX-LA-PAPE", contenuCO2: "0,146", contenuCO2ACV: "0,152", tauxEnrR: "0,6" },
  {id: "6912;C", nomReseau: "Réseau de Givors", Commune: "Givors", contenuCO2: "0,108", contenuCO2ACV: "0,231", tauxEnrR: "0,221" },
  {id: "6913;C", nomReseau: "UIOM Villefranche", Commune: "VILLEFRANCHE-SUR-SAONE", contenuCO2: "0,004", contenuCO2ACV: "0,012", tauxEnrR: "0,984" },
  {id: "6914;C", nomReseau: "Plateau de Montmein", Commune: "Oullins", contenuCO2: "0,204", contenuCO2ACV: "0,263", tauxEnrR: "0" },
  {id: "6915;C", nomReseau: "ZH Champvert", Commune: "Lyon", contenuCO2: "0,201", contenuCO2ACV: "0,184", tauxEnrR: "0" },
  {id: "6917;C", nomReseau: "Réseau VALORLY de Rillieux la Pape", Commune: "RILLIEUX-LA-PAPE", contenuCO2: "0,256", contenuCO2ACV: "0,258", tauxEnrR: "0,333" },
  {id: "6918;C", nomReseau: "Qartier Belleroche Ouest", Commune: "Gleize", contenuCO2: "0,047", contenuCO2ACV: "0,063", tauxEnrR: "0,829" },
  {id: "6919;C", nomReseau: "Quartier Mermoz Sud", Commune: "Lyon", contenuCO2: "0,199", contenuCO2ACV: "0,225", tauxEnrR: "0" },
  {id: "6921;C", nomReseau: "Quartier de la Roue", Commune: "RILLIEUX-LA-PAPE", contenuCO2: "0,216", contenuCO2ACV: "0,231", tauxEnrR: "0" },
  {id: "6922;C", nomReseau: "Réseau de chaleur de Sathonay-camp", Commune: "Sathonay-Camp", contenuCO2: "0,05", contenuCO2ACV: "0,072", tauxEnrR: "0,792" },
  {id: "6923;C", nomReseau: "Réseau de chaleur de La Tour-de-Salvagny", Commune: "LA TOUR-DE-SALVAGNY", contenuCO2: "0,082", contenuCO2ACV: "0,106", tauxEnrR: "0,65" },
  {id: "6924;C", nomReseau: "LES COMBES", Commune: "YZERON", contenuCO2: "0", contenuCO2ACV: "0,025", tauxEnrR: "1" },
  {id: "6925;C", nomReseau: "LE BOURG", Commune: "YZERON", contenuCO2: "0", contenuCO2ACV: "0,025", tauxEnrR: "1" },
  {id: "6928;C", nomReseau: "Réseau chauffage Centre Hospitalier Villefranche", Commune: "GLEIZE", contenuCO2: "0,044", contenuCO2ACV: "0,065", tauxEnrR: "0,836" },
  {id: "6929;C", nomReseau: "Réseau du Breuil", Commune: "Chevigny saint sauveur", contenuCO2: "0,116", contenuCO2ACV: "0,266", tauxEnrR: "0" },
  {id: "6930;C", nomReseau: "Réseau Plateau Nord", Commune: "Rillieux-la-Pape", contenuCO2: "0,022", contenuCO2ACV: "0,03", tauxEnrR: "0,915" },
  {id: "6931;C", nomReseau: "Réseau Sytraival", Commune: "Villefranche sur Saône", contenuCO2: "0,006", contenuCO2ACV: "0,013", tauxEnrR: "0,979" },
  {id: "7001;C", nomReseau: "Zup des Capucins", Commune: "Gray", contenuCO2: "0,104", contenuCO2ACV: "0,136", tauxEnrR: "0,603" },
  {id: "7002;C", nomReseau: "Réseau de Saulnot", Commune: "Saulnot", contenuCO2: "0,035", contenuCO2ACV: "0,063", tauxEnrR: "0,905" },
  {id: "7003;C", nomReseau: "Réseau de Breurey les Faverney", Commune: "BREUREY-LES-FAVERNEY", contenuCO2: "0,155", contenuCO2ACV: "0,234", tauxEnrR: "0,86" },
  {id: "7004;C", nomReseau: "Réseau de Dampierre sur Linotte", Commune: "DAMPIERRE-SUR-LINOTTE", contenuCO2: "0,116", contenuCO2ACV: "0,156", tauxEnrR: "0,728" },
  {id: "7005;C", nomReseau: "Réseau de Plancher-bas", Commune: "PLANCHER-BAS", contenuCO2: "0,261", contenuCO2ACV: "0,287", tauxEnrR: "0,33" },
  {id: "7006;C", nomReseau: "Réseau de la chaufferie de CHAMPEY", Commune: "CHAMPEY", contenuCO2: "0,256", contenuCO2ACV: "0,267", tauxEnrR: "0,333" },
  {id: "7007;C", nomReseau: "Chaufferie de SCEY/SAONE", Commune: "SCEY-SUR-SAONE-ET-SAINT-ALBIN", contenuCO2: "0,035", contenuCO2ACV: "0,066", tauxEnrR: "0,929" },
  {id: "7008;C", nomReseau: "Chaufferie de MARNAY", Commune: "MARNAY", contenuCO2: "0,046", contenuCO2ACV: "0,078", tauxEnrR: "0,933" },
  {id: "7009;C", nomReseau: "Chaufferie de GY", Commune: "GY", contenuCO2: "0", contenuCO2ACV: "0,023", tauxEnrR: "1" },
  {id: "7011;C", nomReseau: "Réseau d'Hericourt - Quartier Maunoury", Commune: "HERICOURT", contenuCO2: "0,083", contenuCO2ACV: "0,109", tauxEnrR: "0,73" },
  {id: "7101;C", nomReseau: "Réseau de Chalon", Commune: "Chalon-sur-Saône", contenuCO2: "0,114", contenuCO2ACV: "0,147", tauxEnrR: "0,493" },
  {id: "7102;C", nomReseau: "Réseau de Montceau les mines", Commune: "Montceau-les-Mines", contenuCO2: "0,117", contenuCO2ACV: "0,146", tauxEnrR: "0,556" },
  {id: "7104;C", nomReseau: "Réseau d'Autun", Commune: "Autun", contenuCO2: "0,1", contenuCO2ACV: "0,139", tauxEnrR: "0,565" },
  {id: "7105;C", nomReseau: "Réseau de chaleur de Tramayes", Commune: "TRAMAYES", contenuCO2: "0,012", contenuCO2ACV: "0,039", tauxEnrR: "0,973" },
  {id: "7106;C", nomReseau: "Réseau d'Anost", Commune: "ANOST", contenuCO2: "0,256", contenuCO2ACV: "0,262", tauxEnrR: "0,333" },
  {id: "7107;C", nomReseau: "MÂCON", Commune: "MÂCON", contenuCO2: "0,11", contenuCO2ACV: "0,163", tauxEnrR: "0,501" },
  {id: "7108;C", nomReseau: "Réseau de Matour", Commune: "MATOUR", contenuCO2: "0,028", contenuCO2ACV: "0,054", tauxEnrR: "0,92" },
  {id: "7109;C", nomReseau: "RESEAU DE CHALEUR DES BATIMENTS COMMUNAUX", Commune: "SAINT GENGOUX DE SCISSE", contenuCO2: "0,036", contenuCO2ACV: "0,065", tauxEnrR: "0,905" },
  {id: "7202;C", nomReseau: "Percée Centrale", Commune: "LE MANS", contenuCO2: "0,233", contenuCO2ACV: "0,266", tauxEnrR: "0" },
  {id: "7203;C", nomReseau: "SYNER'GIE (ALLONNES- BDH)", Commune: "ALLONNES", contenuCO2: "0,015", contenuCO2ACV: "0,021", tauxEnrR: "0,943" },
  {id: "7204;C", nomReseau: "Bellevue", Commune: "Coulaines", contenuCO2: "0,052", contenuCO2ACV: "0,293", tauxEnrR: "0" },
  {id: "7205;C", nomReseau: "Réseau de la commune de Tuffe", Commune: "TUFFE", contenuCO2: "0", contenuCO2ACV: "0,017", tauxEnrR: "1" },
  {id: "7301;C", nomReseau: "Bissy et Croix Rouge", Commune: "Chambery", contenuCO2: "0,092", contenuCO2ACV: "0,113", tauxEnrR: "0,653" },
  {id: "7304;C", nomReseau: "La Plagne", Commune: "MACOT-LA-PLAGNE", contenuCO2: "0,064", contenuCO2ACV: "0,095", tauxEnrR: "0,809" },
  {id: "7305;C", nomReseau: "Les Arcs", Commune: "BOURG-SAINT-MAURICE", contenuCO2: "0,042", contenuCO2ACV: "0,069", tauxEnrR: "0,882" },
  {id: "7306;C", nomReseau: "Réseau de Notre Dame des Millières", Commune: "Notre-Dame-des-Millieres", contenuCO2: "0,261", contenuCO2ACV: "0,269", tauxEnrR: "0,319" },
  {id: "7307;C", nomReseau: "Réseau de Saint Etienne de Cuines", Commune: "Saint-Etienne-de-Cuines", contenuCO2: "0,01", contenuCO2ACV: "0,026", tauxEnrR: "0,968" },
  {id: "7308;C", nomReseau: "Réseau de Beaufort", Commune: "BEAUFORT", contenuCO2: "0,013", contenuCO2ACV: "0,039", tauxEnrR: "0,965" },
  {id: "7310;C", nomReseau: "Ecole", Commune: "CHANAZ", contenuCO2: "0,256", contenuCO2ACV: "0,285", tauxEnrR: "0,333" },
  {id: "7311;C", nomReseau: "Gilly-sur-Isère", Commune: "Gilly-sur-Isere", contenuCO2: "0,027", contenuCO2ACV: "0,049", tauxEnrR: "0,896" },
  {id: "7312;C", nomReseau: "Yenne", Commune: "Yenne", contenuCO2: "0,008", contenuCO2ACV: "0,034", tauxEnrR: "0,979" },
  {id: "7313;C", nomReseau: "Ilots", Commune: "CHANAZ", contenuCO2: "0,256", contenuCO2ACV: "0,267", tauxEnrR: "0,333" },
  {id: "7314;C", nomReseau: "La Bauche", Commune: "LA BAUCHE", contenuCO2: "0", contenuCO2ACV: "0,035", tauxEnrR: "1" },
  {id: "7315;C", nomReseau: "AIX ENERGIES NOUVELLES", Commune: "AIX-LES-BAINS", contenuCO2: "0,059", contenuCO2ACV: "0,082", tauxEnrR: "0,768" },
  {id: "7316;C", nomReseau: "Réseau de St Jean d'Arvey", Commune: "SAINT-JEAN-D'ARVEY", contenuCO2: "0,009", contenuCO2ACV: "0,039", tauxEnrR: "0,981" },
  {id: "7318;C", nomReseau: "Réseau d'Ugine", Commune: "UGINE", contenuCO2: "0,11", contenuCO2ACV: "0,156", tauxEnrR: "0,533" },
  {id: "7319;C", nomReseau: "Réseau d'Albertville", Commune: "Albertville", contenuCO2: "0,072", contenuCO2ACV: "0,099", tauxEnrR: "0,732" },
  {id: "7402;C", nomReseau: "Quartier de Champ Fleury", Commune: "Seynod", contenuCO2: "0,094", contenuCO2ACV: "0,108", tauxEnrR: "0,632" },
  {id: "7403;C", nomReseau: "BOIS ENERGIES ANNEMASSE", Commune: "ANNEMASSE", contenuCO2: "0,044", contenuCO2ACV: "0,066", tauxEnrR: "0,827" },
  {id: "7407;C", nomReseau: "Quartier de la Rénovation", Commune: "Thonon-les-Bains", contenuCO2: "0,232", contenuCO2ACV: "0,261", tauxEnrR: "0" },
  {id: "7408;C", nomReseau: "Cluses énergies", Commune: "Cluses", contenuCO2: "0,123", contenuCO2ACV: "0,257", tauxEnrR: "0" },
  {id: "7409;C", nomReseau: "Flaine Energie", Commune: "ARACHES-LA-FRASSE", contenuCO2: "0,223", contenuCO2ACV: "0,276", tauxEnrR: "0" },
  {id: "7410;C", nomReseau: "Faverges énergie", Commune: "Faverges", contenuCO2: "0,015", contenuCO2ACV: "0,04", tauxEnrR: "0,946" },
  {id: "7411;C", nomReseau: "Quartier du Crozets", Commune: "Scionzier", contenuCO2: "0,208", contenuCO2ACV: "0,235", tauxEnrR: "0" },
  {id: "7412;C", nomReseau: "Réseau d'Avoriaz", Commune: "MORZINE", contenuCO2: "0,103", contenuCO2ACV: "0,062", tauxEnrR: "0,53" },
  {id: "7413;C", nomReseau: "Réseau de Clarafond La Presle", Commune: "Clarafond-Arcine", contenuCO2: "0,078", contenuCO2ACV: "0,139", tauxEnrR: "0,916" },
  {id: "7416;C", nomReseau: "Réseau de Viry", Commune: "VIRY", contenuCO2: "0,008", contenuCO2ACV: "0,035", tauxEnrR: "0,978" },
  {id: "7417;C", nomReseau: "Réseau de chaleur de Vallorcine", Commune: "VALLORCINE", contenuCO2: "0,053", contenuCO2ACV: "0,08", tauxEnrR: "0,815" },
  {id: "7418;C", nomReseau: "Réseau UVE du STOC", Commune: "THONON-LES-BAINS", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "7419;C", nomReseau: "Réseau Serres Municipales du STOC", Commune: "THONON-LES-BAINS", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "7421;C", nomReseau: "Annecy Bio chaleur", Commune: "ANNECY", contenuCO2: "0,034", contenuCO2ACV: "0,055", tauxEnrR: "0,852" },
  {id: "7422;C", nomReseau: "Thorens Glières", Commune: "THORENS-GLIERES", contenuCO2: "0,045", contenuCO2ACV: "0,077", tauxEnrR: "0,884" },
  {id: "7423;C", nomReseau: "Réseau de Reignier", Commune: "REIGNIER", contenuCO2: "0,006", contenuCO2ACV: "0,029", tauxEnrR: "0,976" },
  {id: "7424;C", nomReseau: "Cran Gevrier - Les Passerelles", Commune: "CRAN-GEVRIER", contenuCO2: "0,059", contenuCO2ACV: "0,085", tauxEnrR: "0,812" },
  {id: "7426;C", nomReseau: "Réseau bois énergie et citoyen de Lucinges", Commune: "Lucinges", contenuCO2: "0,009", contenuCO2ACV: "0,034", tauxEnrR: "0,977" },
  {id: "7427;C", nomReseau: "Réseau de chaleur du Centre Bourg de Cusy", Commune: "Commune de Cusy", contenuCO2: "0,023", contenuCO2ACV: "0,054", tauxEnrR: "0,952" },
  {id: "7501;C", nomReseau: "Paris et communes limitrophes", Commune: "PARIS", contenuCO2: "0,157", contenuCO2ACV: "0,18", tauxEnrR: "0,533" },
  {id: "7507;C", nomReseau: "CLIMESPACE", Commune: "PARIS", contenuCO2: "0,198", contenuCO2ACV: "0,234", tauxEnrR: "0,533" },
  {id: "7511;C", nomReseau: "Boucle d’eau chaude Chapelle International", Commune: "PARIS", contenuCO2: "0,113", contenuCO2ACV: "0,238", tauxEnrR: "0,086" },
  {id: "7601;C", nomReseau: "Réseau Petite Bouverie", Commune: "Rouen", contenuCO2: "0,212", contenuCO2ACV: "0,261", tauxEnrR: "0,002" },
  {id: "7602;C", nomReseau: "Zac du Mont Gaillard", Commune: "LE HAVRE", contenuCO2: "0,089", contenuCO2ACV: "0,136", tauxEnrR: "0,509" },
  {id: "7603;C", nomReseau: "Resocéane", Commune: "LE HAVRE", contenuCO2: "0,253", contenuCO2ACV: "0,264", tauxEnrR: "0" },
  {id: "7605;C", nomReseau: "Réseau de Mont-Saint-Aignan", Commune: "Mont-Saint-Aignan", contenuCO2: "0,14", contenuCO2ACV: "0,169", tauxEnrR: "0,396" },
  {id: "7606;C", nomReseau: "CANTELEU ENERGIE", Commune: "Canteleu", contenuCO2: "0,089", contenuCO2ACV: "0,123", tauxEnrR: "0,589" },
  {id: "7607;C", nomReseau: "Chaufferie urbaine de Petit-Quevilly", Commune: "LE PETIT-QUEVILLY", contenuCO2: "0,063", contenuCO2ACV: "0,081", tauxEnrR: "0,7" },
  {id: "7608;C", nomReseau: "Château Blanc", Commune: "SAINT ETIENNE DU ROUVRAY", contenuCO2: "0,045", contenuCO2ACV: "0,068", tauxEnrR: "0,797" },
  {id: "7609;C", nomReseau: "SODINEUF", Commune: "DIEPPE", contenuCO2: "0,206", contenuCO2ACV: "0,24", tauxEnrR: "0" },
  {id: "7610;C", nomReseau: "SECGO", Commune: "GONFREVILLE-L'ORCHER", contenuCO2: "0,032", contenuCO2ACV: "0,056", tauxEnrR: "0,891" },
  {id: "7612;C", nomReseau: "Chu Charles Nicolle", Commune: "Rouen", contenuCO2: "0,181", contenuCO2ACV: "0,227", tauxEnrR: "0" },
  {id: "7613;C", nomReseau: "La Côte Brulée", Commune: "LE HAVRE", contenuCO2: "0,21", contenuCO2ACV: "0,237", tauxEnrR: "0" },
  {id: "7614;C", nomReseau: "Chaufferie bois Grammont", Commune: "Rouen", contenuCO2: "0,029", contenuCO2ACV: "0,051", tauxEnrR: "0,887" },
  {id: "7615;C", nomReseau: "Réseau de Maromme", Commune: "MAROMME", contenuCO2: "0,014", contenuCO2ACV: "0,037", tauxEnrR: "0,952" },
  {id: "7616;C", nomReseau: "SRGB", Commune: "NOTRE-DAME-DE-GRAVENCHON", contenuCO2: "0,04", contenuCO2ACV: "0,064", tauxEnrR: "0,854" },
  {id: "7617;C", nomReseau: "RCU de Neufchatel-en-Bray", Commune: "NEUFCHATEL-EN-BRAY", contenuCO2: "0,057", contenuCO2ACV: "0,083", tauxEnrR: "0,794" },
  {id: "7618;C", nomReseau: "Réseau de Semedi-Sedibex", Commune: "SANDOUVILLE", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "7619;C", nomReseau: "ZAC Luciline", Commune: "ROUEN", contenuCO2: "0,154", contenuCO2ACV: "0,152", tauxEnrR: "0,32" },
  {id: "7620;C", nomReseau: "VESUVE", Commune: "LE GRAND-QUEVILLY", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "7621;C", nomReseau: "Réseau vapeur SEVEDE", Commune: "SAINT-JEAN-DE-FOLLEVILLE", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "7701;C", nomReseau: "Réseaux de Meaux", Commune: "Meaux", contenuCO2: "0,1", contenuCO2ACV: "0,122", tauxEnrR: "0,619" },
  {id: "7703;C", nomReseau: "Almont - Montaigu", Commune: "Melun", contenuCO2: "0,071", contenuCO2ACV: "0,089", tauxEnrR: "0,7" },
  {id: "7704;C", nomReseau: "Réseau OSICA", Commune: "OZOIR-LA-FERRIERE", contenuCO2: "0,161", contenuCO2ACV: "0,184", tauxEnrR: "0,181" },
  {id: "7705;C", nomReseau: "Zup du Mont Saint Martin", Commune: "Nemours", contenuCO2: "0,119", contenuCO2ACV: "0,148", tauxEnrR: "0,569" },
  {id: "7706;C", nomReseau: "Réseau de Dammarie les Lys", Commune: "Dammarie-les-Lys", contenuCO2: "0,014", contenuCO2ACV: "0,03", tauxEnrR: "0,936" },
  {id: "7707;C", nomReseau: "Centrale de la Butte Monceau", Commune: "Avon", contenuCO2: "0,23", contenuCO2ACV: "0,259", tauxEnrR: "0" },
  {id: "7708;C", nomReseau: "Réseau du Mee sur Seine", Commune: "LE MEE-SUR-SEINE", contenuCO2: "0,058", contenuCO2ACV: "0,076", tauxEnrR: "0,758" },
  {id: "7710;C", nomReseau: "Réseau de Vaux le Penil", Commune: "Vaux-le-Penil", contenuCO2: "0,119", contenuCO2ACV: "0,149", tauxEnrR: "0,654" },
  {id: "7711;C", nomReseau: "Réseau de Coulommiers", Commune: "Coulommiers", contenuCO2: "0,01", contenuCO2ACV: "0,026", tauxEnrR: "0,959" },
  {id: "7715;C", nomReseau: "ZUP de Surville", Commune: "Montereau-Fault-Yonne", contenuCO2: "0,015", contenuCO2ACV: "0,028", tauxEnrR: "0,945" },
  {id: "7716;C", nomReseau: "Réseau de Marne la Vallée", Commune: "Torcy", contenuCO2: "0,028", contenuCO2ACV: "0,045", tauxEnrR: "0,89" },
  {id: "7717;C", nomReseau: "Réseau de Chelles", Commune: "Chelles", contenuCO2: "0,13", contenuCO2ACV: "0,159", tauxEnrR: "0,43" },
  {id: "7718;C", nomReseau: "La renardière", Commune: "ROISSY-EN-BRIE", contenuCO2: "0,099", contenuCO2ACV: "0,123", tauxEnrR: "0,602" },
  {id: "7721;C", nomReseau: "Réseau de Bailly Romainvilliers", Commune: "BAILLY-ROMAINVILLIERS", contenuCO2: "0,039", contenuCO2ACV: "0,048", tauxEnrR: "0,881" },
  {id: "7722;C", nomReseau: "Réseau de Village Nature", Commune: "VILLENEUVE-SAINT-DENIS", contenuCO2: "0,001", contenuCO2ACV: "0,017", tauxEnrR: "0,998" },
  {id: "7724;C", nomReseau: "Bussycomore", Commune: "BUSSY-SAINT-GEORGES", contenuCO2: "0,127", contenuCO2ACV: "0,295", tauxEnrR: "0,71" },
  {id: "7725;C", nomReseau: "Réseau Eurodisney", Commune: "Chessy", contenuCO2: "0,233", contenuCO2ACV: "0,263", tauxEnrR: "0,079" },
  {id: "7802;C", nomReseau: "Le Val Fourré", Commune: "Mantes-la-Jolie", contenuCO2: "0,106", contenuCO2ACV: "0,139", tauxEnrR: "0,536" },
  {id: "7803;C", nomReseau: "Réseau de Versailles", Commune: "Versailles", contenuCO2: "0,241", contenuCO2ACV: "0,29", tauxEnrR: "0" },
  {id: "7804;C", nomReseau: "Parly II", Commune: "LE CHESNAY", contenuCO2: "0,22", contenuCO2ACV: "0,247", tauxEnrR: "0" },
  {id: "7805;C", nomReseau: "Réseau de Saint Germain en Laye", Commune: "Saint-Germain-en-Laye", contenuCO2: "0,108", contenuCO2ACV: "0,143", tauxEnrR: "0,557" },
  {id: "7806;C", nomReseau: "DOMNIS ACHERES", Commune: "ACHERES", contenuCO2: "0,088", contenuCO2ACV: "0,109", tauxEnrR: "0,568" },
  {id: "7808;C", nomReseau: "Grand Ouest et Musiciens", Commune: "LES MUREAUX", contenuCO2: "0,113", contenuCO2ACV: "0,14", tauxEnrR: "0,568" },
  {id: "7809;C", nomReseau: "Réseau de Vélizy", Commune: "Vélizy-Villacoublay", contenuCO2: "0,236", contenuCO2ACV: "0,271", tauxEnrR: "0" },
  {id: "7811;C", nomReseau: "Réseau de Carrières - Chatou", Commune: "Carrières-sur-Seine", contenuCO2: "0,009", contenuCO2ACV: "0,014", tauxEnrR: "0,971" },
  {id: "7812;C", nomReseau: "Réseau de Plaisir - Resop", Commune: "Plaisir", contenuCO2: "0,066", contenuCO2ACV: "0,077", tauxEnrR: "0,733" },
  {id: "7816;C", nomReseau: "Carrière sous Poissy", Commune: "CARRIERES-SOUS-POISSY", contenuCO2: "0,083", contenuCO2ACV: "0,153", tauxEnrR: "0,481" },
  {id: "7901;C", nomReseau: "Le Clou Bouchet", Commune: "Niort", contenuCO2: "0,243", contenuCO2ACV: "0,274", tauxEnrR: "0" },
  {id: "7902;C", nomReseau: "Réseau de Bressuire", Commune: "BRESSUIRE", contenuCO2: "0,164", contenuCO2ACV: "0,185", tauxEnrR: "0,575" },
  {id: "7903;C", nomReseau: "Réseau de Romans", Commune: "ROMANS", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "7904;C", nomReseau: "Réseau de l'Absie", Commune: "L'ABSIE", contenuCO2: "0", contenuCO2ACV: "0,019", tauxEnrR: "1" },
  {id: "7905;C", nomReseau: "Quartier Les Brizeaux", Commune: "NIORT", contenuCO2: "0,126", contenuCO2ACV: "0,153", tauxEnrR: "0,492" },
  {id: "7906;C", nomReseau: "Réseau de chaleur CC Mellois en Poitou", Commune: "LEZAY", contenuCO2: "0", contenuCO2ACV: "0,034", tauxEnrR: "1" },
  {id: "8001;C", nomReseau: "Etouvie", Commune: "Amiens", contenuCO2: "0,088", contenuCO2ACV: "0,186", tauxEnrR: "0,347" },
  {id: "8002;C", nomReseau: "Réseau de la ville d'AMIENS", Commune: "Amiens", contenuCO2: "0,079", contenuCO2ACV: "0,153", tauxEnrR: "0,404" },
  {id: "8004;C", nomReseau: "Rodolphe BRAL", Commune: "MONTDIDIER", contenuCO2: "0,256", contenuCO2ACV: "0,263", tauxEnrR: "0,333" },
  {id: "8005;C", nomReseau: "Réseau d'Abbeville", Commune: "ABBEVILLE", contenuCO2: "0,095", contenuCO2ACV: "0,126", tauxEnrR: "0,593" },
  {id: "8006;C", nomReseau: "Réseau de chaleur de Corbie", Commune: "Corbie", contenuCO2: "0,041", contenuCO2ACV: "0,066", tauxEnrR: "0,829" },
  {id: "8101;C", nomReseau: "Reseau de Carmaux", Commune: "Carmaux", contenuCO2: "0,308", contenuCO2ACV: "0,336", tauxEnrR: "0" },
  {id: "8102;C", nomReseau: "Chauffage urbain de Mazamet", Commune: "Mazamet", contenuCO2: "0,064", contenuCO2ACV: "0,089", tauxEnrR: "0,767" },
  {id: "8103;C", nomReseau: "Réseau de Chauffage Urbain Castres Lameilhé", Commune: "Castres", contenuCO2: "0,032", contenuCO2ACV: "0,056", tauxEnrR: "0,872" },
  {id: "8104;C", nomReseau: "Gaillac - ZAC de Pouille", Commune: "GAILLAC", contenuCO2: "0,044", contenuCO2ACV: "0,067", tauxEnrR: "0,843" },
  {id: "8105;C", nomReseau: "Reseau de chaleur de Graulhet", Commune: "GRAULHET", contenuCO2: "0,024", contenuCO2ACV: "0,048", tauxEnrR: "0,921" },
  {id: "8106;C", nomReseau: "Réseau de chaleur d'ALBAN", Commune: "ALBAN", contenuCO2: "0,017", contenuCO2ACV: "0,041", tauxEnrR: "0,959" },
  {id: "8112;C", nomReseau: "Réseau de chaleur de LACAUNE", Commune: "LACAUNE", contenuCO2: "0,001", contenuCO2ACV: "0,022", tauxEnrR: "0,998" },
  {id: "8201;C", nomReseau: "RESEAUX SUD-OUEST ENERGIES SERVICES", Commune: "MONTAUBAN", contenuCO2: "0,002", contenuCO2ACV: "0,006", tauxEnrR: "0,991" },
  {id: "8202;C", nomReseau: "Réseau de Caylus", Commune: "Caylus", contenuCO2: "0", contenuCO2ACV: "0,017", tauxEnrR: "1" },
  {id: "8203;C", nomReseau: "Chaufferie bois Negrepelisse", Commune: "NEGREPELISSE", contenuCO2: "0", contenuCO2ACV: "0,017", tauxEnrR: "1" },
  {id: "8204;C", nomReseau: "Villebrumier", Commune: "VILLEBRUMIER", contenuCO2: "0", contenuCO2ACV: "0,02", tauxEnrR: "1" },
  {id: "8301;C", nomReseau: "La Beaucaire", Commune: "TOULON", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "8304;C", nomReseau: "Berthe", Commune: "LA SEYNE-SUR-MER", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "8401;C", nomReseau: "Le Triennal", Commune: "Avignon", contenuCO2: "0,093", contenuCO2ACV: "0,213", tauxEnrR: "0" },
  {id: "8501;C", nomReseau: "ZAD Nord-Est", Commune: "LA ROCHE-SUR-YON", contenuCO2: "0,205", contenuCO2ACV: "0,231", tauxEnrR: "0" },
  {id: "8502;C", nomReseau: "Réseau les Herbiers", Commune: "LES HERBIERS", contenuCO2: "0,052", contenuCO2ACV: "0,081", tauxEnrR: "0,863" },
  {id: "8503;C", nomReseau: "Réseau de chaleur Saint-pierre-du-chemin", Commune: "SAINT-PIERRE-DU-CHEMIN", contenuCO2: "0", contenuCO2ACV: "0,023", tauxEnrR: "1" },
  {id: "8601;C", nomReseau: "Zup des Couronneries", Commune: "Poitiers", contenuCO2: "0,072", contenuCO2ACV: "0,096", tauxEnrR: "0,702" },
  {id: "8605;C", nomReseau: "Réseau Lusignan", Commune: "LUSIGNAN", contenuCO2: "0,026", contenuCO2ACV: "0,056", tauxEnrR: "0,944" },
  {id: "8606;C", nomReseau: "Réseau de Chaleur Ozon Sud", Commune: "CHATELLERAULT", contenuCO2: "0,12", contenuCO2ACV: "0,229", tauxEnrR: "0,167" },
  {id: "8607;C", nomReseau: "Réseau de Civaux", Commune: "CIVAUX", contenuCO2: "0,083", contenuCO2ACV: "0,061", tauxEnrR: "0,624" },
  {id: "8608;C", nomReseau: "Réseau de BEL AIR - 3 CITES", Commune: "POITIERS", contenuCO2: "0,176", contenuCO2ACV: "0,457", tauxEnrR: "0" },
  {id: "8609;C", nomReseau: "Réseau Saint Cyprien - Clos Gaultier", Commune: "POITIERS", contenuCO2: "0,332", contenuCO2ACV: "0,372", tauxEnrR: "0" },
  {id: "8610;C", nomReseau: "Réseau chaleur université de Poitiers", Commune: "Poitiers", contenuCO2: "0,008", contenuCO2ACV: "0,027", tauxEnrR: "0,948" },
  {id: "8701;C", nomReseau: "Zup Val de l'Aurence", Commune: "Limoges", contenuCO2: "0", contenuCO2ACV: "0,085", tauxEnrR: "0,736" },
  {id: "8702;C", nomReseau: "Zac de Beaubreuil", Commune: "Limoges", contenuCO2: "0,002", contenuCO2ACV: "0,006", tauxEnrR: "0,995" },
  {id: "8703;C", nomReseau: "Réseau de Bellac", Commune: "BELLAC", contenuCO2: "0,284", contenuCO2ACV: "0,318", tauxEnrR: "0" },
  {id: "8704;C", nomReseau: "Quartier de l'Hôtel de Ville", Commune: "Limoges", contenuCO2: "0,064", contenuCO2ACV: "0,198", tauxEnrR: "0,204" },
  {id: "8707;C", nomReseau: "Réseau de chaleur Saint Yrieix la Perche", Commune: "Saint Yrieix la Perche", contenuCO2: "0,027", contenuCO2ACV: "0,051", tauxEnrR: "0,898" },
  {id: "8801;C", nomReseau: "Plateau de la Justice", Commune: "Epinal", contenuCO2: "0", contenuCO2ACV: "0,057", tauxEnrR: "0,882" },
  {id: "8802;C", nomReseau: "Quartier Kellerman", Commune: "SAINT-DIE-DES-VOSGES", contenuCO2: "0,073", contenuCO2ACV: "0,108", tauxEnrR: "0,619" },
  {id: "8805;C", nomReseau: "Réseau de Fresse sur Moselle", Commune: "Fresse-sur-Moselle", contenuCO2: "0,012", contenuCO2ACV: "0,032", tauxEnrR: "0,953" },
  {id: "8806;C", nomReseau: "Réseau de Monthureux-sur-Saône", Commune: "MONTHUREUX-SUR-SAONE", contenuCO2: "0,034", contenuCO2ACV: "0,064", tauxEnrR: "0,909" },
  {id: "8807;C", nomReseau: "Chauffage urbain Fraize", Commune: "FRAIZE", contenuCO2: "0,276", contenuCO2ACV: "0,285", tauxEnrR: "0,259" },
  {id: "8808;C", nomReseau: "Réseau de La Bresse 1", Commune: "LA BRESSE", contenuCO2: "0,173", contenuCO2ACV: "0,222", tauxEnrR: "0,616" },
  {id: "8809;C", nomReseau: "Réseau de La Bresse 2", Commune: "LA BRESSE", contenuCO2: "0,079", contenuCO2ACV: "0,114", tauxEnrR: "0,784" },
  {id: "8811;C", nomReseau: "Réseau de Fremifontaine", Commune: "FREMIFONTAINE", contenuCO2: "0", contenuCO2ACV: "0,022", tauxEnrR: "1" },
  {id: "8812;C", nomReseau: "Réseau de Ventron", Commune: "VENTRON", contenuCO2: "0,016", contenuCO2ACV: "0,036", tauxEnrR: "0,939" },
  {id: "8814;C", nomReseau: "Vittel", Commune: "VITTEL", contenuCO2: "0,077", contenuCO2ACV: "0,106", tauxEnrR: "0,623" },
  {id: "8815;C", nomReseau: "Réseau de la ville de VAGNEY", Commune: "VAGNEY", contenuCO2: "0,026", contenuCO2ACV: "0,052", tauxEnrR: "0,905" },
  {id: "8816;C", nomReseau: "BAN DE SAPT", Commune: "Ban-de-Sapt", contenuCO2: "0,193", contenuCO2ACV: "0,201", tauxEnrR: "0,5" },
  {id: "8819;C", nomReseau: "Réseau de denipaire", Commune: "DENIPAIRE", contenuCO2: "0,193", contenuCO2ACV: "0,201", tauxEnrR: "0,5" },
  {id: "8901;C", nomReseau: "Zup des Grahuches", Commune: "SENS", contenuCO2: "0,02", contenuCO2ACV: "0,033", tauxEnrR: "0,934" },
  {id: "8902;C", nomReseau: "Zup de Sainte Geneviève", Commune: "AUXERRE", contenuCO2: "0,072", contenuCO2ACV: "0,097", tauxEnrR: "0,716" },
  {id: "8903;C", nomReseau: "Les Chaillots", Commune: "Sens", contenuCO2: "0,245", contenuCO2ACV: "0,276", tauxEnrR: "0" },
  {id: "8904;C", nomReseau: "Réseau de Quarré-les-Tombes", Commune: "QUARRE-LES-TOMBES", contenuCO2: "0", contenuCO2ACV: "0,014", tauxEnrR: "1" },
  {id: "9002;C", nomReseau: "ZUP DES GLACIS", Commune: "Belfort", contenuCO2: "0,237", contenuCO2ACV: "0,282", tauxEnrR: "0" },
  {id: "9003;C", nomReseau: "Réseau de chaleur de Delle", Commune: "DELLE", contenuCO2: "0,066", contenuCO2ACV: "0,105", tauxEnrR: "0,57" },
  {id: "9101;C", nomReseau: "Réseau de Massy - Antony", Commune: "Massy", contenuCO2: "0,094", contenuCO2ACV: "0,113", tauxEnrR: "0,739" },
  {id: "9102;C", nomReseau: "Réseau des Ulis", Commune: "LES ULIS", contenuCO2: "0,068", contenuCO2ACV: "0,09", tauxEnrR: "0,648" },
  {id: "9103;C", nomReseau: "Réseau d'Evry", Commune: "Evry", contenuCO2: "0,109", contenuCO2ACV: "0,146", tauxEnrR: "0,456" },
  {id: "9104;C", nomReseau: "Domaine du Bois des Roches", Commune: "Saint-Michel-Sur-Orge", contenuCO2: "0,272", contenuCO2ACV: "0,298", tauxEnrR: "0" },
  {id: "9105;C", nomReseau: "Réseau de Grigny", Commune: "Grigny", contenuCO2: "0,151", contenuCO2ACV: "0,196", tauxEnrR: "0,151" },
  {id: "9106;C", nomReseau: "OROBIA - Réseau de Brétigny-sur-Orge", Commune: "BRETIGNY-SUR-ORGE", contenuCO2: "0,067", contenuCO2ACV: "0,091", tauxEnrR: "0,74" },
  {id: "9107;C", nomReseau: "Réseau de Dourdan", Commune: "DOURDAN", contenuCO2: "0,234", contenuCO2ACV: "0,279", tauxEnrR: "0" },
  {id: "9108;C", nomReseau: "CEA DIF", Commune: "Bruyeres-le-Chatel", contenuCO2: "0,201", contenuCO2ACV: "0,219", tauxEnrR: "0,374" },
  {id: "9109;C", nomReseau: "DSP - Vigneux - Concorde", Commune: "VIGNEUX SUR SEINE", contenuCO2: "0,048", contenuCO2ACV: "0,066", tauxEnrR: "0,805" },
  {id: "9114;C", nomReseau: "Réseau d'Epinay-sous-Sénart", Commune: "Epinay-sous-Senart", contenuCO2: "0,06", contenuCO2ACV: "0,078", tauxEnrR: "0,74" },
  {id: "9115;C", nomReseau: "Réseau de Ris-Orangis", Commune: "Ris-Orangis", contenuCO2: "0,006", contenuCO2ACV: "0,022", tauxEnrR: "0,986" },
  {id: "9116;C", nomReseau: "Zup de Saint Hubert et Louis Pergaud", Commune: "Sainte-Geneviève-des-Bois", contenuCO2: "0,336", contenuCO2ACV: "0,346", tauxEnrR: "0" },
  {id: "9118;C", nomReseau: "Réseau SEER Grigny-Viry", Commune: "GRIGNY", contenuCO2: "0,051", contenuCO2ACV: "0,068", tauxEnrR: "0,762" },
  {id: "9119;C", nomReseau: "Parc d'activités", Commune: "Villejust", contenuCO2: "0", contenuCO2ACV: "0,004", tauxEnrR: "1" },
  {id: "9120;C", nomReseau: "Réseau de Viry Chatillon", Commune: "Viry-Châtillon", contenuCO2: "0,201", contenuCO2ACV: "0,242", tauxEnrR: "0" },
  {id: "9121;C", nomReseau: "Réseau de chaleur de l'écoquartier Camille Claudel", Commune: "PALAISEAU", contenuCO2: "0,071", contenuCO2ACV: "0,094", tauxEnrR: "0,706" },
  {id: "9122;C", nomReseau: "Réseau du quartier du Grand Parc de Bondoufle", Commune: "BONDOUFLE", contenuCO2: "0,089", contenuCO2ACV: "0,115", tauxEnrR: "0,663" },
  {id: "9123;C", nomReseau: "Paris Saclay", Commune: "Saclay", contenuCO2: "0,198", contenuCO2ACV: "0,19", tauxEnrR: "0" },
  {id: "9201;C", nomReseau: "Réseau de Meudon", Commune: "Meudon", contenuCO2: "0,227", contenuCO2ACV: "0,263", tauxEnrR: "0" },
  {id: "9202;C", nomReseau: "Les Fosses Jean Nord", Commune: "Colombes", contenuCO2: "0,341", contenuCO2ACV: "0,351", tauxEnrR: "0" },
  {id: "9204;C", nomReseau: "ZAC Sainte Geneviève", Commune: "NANTERRE", contenuCO2: "0,099", contenuCO2ACV: "0,089", tauxEnrR: "0,568" },
  {id: "9205;C", nomReseau: "Réseau Gennevilliers", Commune: "Gennevilliers", contenuCO2: "0,091", contenuCO2ACV: "0,118", tauxEnrR: "0,587" },
  {id: "9209;C", nomReseau: "Réseau de Chaville", Commune: "Chaville", contenuCO2: "0,204", contenuCO2ACV: "0,247", tauxEnrR: "0" },
  {id: "9211;C", nomReseau: "Réseau de chaleur de Levallois", Commune: "LEVALLOIS PERRET", contenuCO2: "0,173", contenuCO2ACV: "0,203", tauxEnrR: "0,533" },
  {id: "9219;C", nomReseau: "Réseau chaud Seguin Rives de Seine", Commune: "BOULOGNE-BILLANCOURT", contenuCO2: "0,113", contenuCO2ACV: "0,114", tauxEnrR: "0,595" },
  {id: "9222;C", nomReseau: "Réseau de Suresnes", Commune: "Suresnes", contenuCO2: "0,239", contenuCO2ACV: "0,269", tauxEnrR: "0" },
  {id: "9224;C", nomReseau: "Réseau SEINERGIE", Commune: "Courbevoie", contenuCO2: "0,241", contenuCO2ACV: "0,271", tauxEnrR: "0,005" },
  {id: "9225;C", nomReseau: "Réseau de La Défense", Commune: "Courbevoie", contenuCO2: "0,144", contenuCO2ACV: "0,181", tauxEnrR: "0,369" },
  {id: "9226;C", nomReseau: "Résidence Villeneuve", Commune: "Villeneuve-la-Garenne", contenuCO2: "0,22", contenuCO2ACV: "0,256", tauxEnrR: "0" },
  {id: "9230;C", nomReseau: "Réseau Ciceo", Commune: "Puteaux", contenuCO2: "0,226", contenuCO2ACV: "0,254", tauxEnrR: "0" },
  {id: "9232;C", nomReseau: "RESEAU QUARTIER HOCHE", Commune: "NANTERRE", contenuCO2: "0,083", contenuCO2ACV: "0,107", tauxEnrR: "0,656" },
  {id: "9233;C", nomReseau: "Réseau de la Zac de la Marine", Commune: "Colombes", contenuCO2: "0,108", contenuCO2ACV: "0,135", tauxEnrR: "0,554" },
  {id: "9235;C", nomReseau: "Réseau de CLICHY", Commune: "CLICHY", contenuCO2: "0,165", contenuCO2ACV: "0,258", tauxEnrR: "0,396" },
  {id: "9236;C", nomReseau: "Réseau de Bagneux-Chatillon", Commune: "BAGNEUX", contenuCO2: "0,075", contenuCO2ACV: "0,087", tauxEnrR: "0,629" },
  {id: "9301;C", nomReseau: "Réseau de Saint Denis", Commune: "Saint-Denis", contenuCO2: "0,118", contenuCO2ACV: "0,146", tauxEnrR: "0,53" },
  {id: "9302;C", nomReseau: "Réseau de Bagnolet", Commune: "Bagnolet", contenuCO2: "0,13", contenuCO2ACV: "0,175", tauxEnrR: "0,472" },
  {id: "9303;C", nomReseau: "Zup de Bobigny", Commune: "Bobigny", contenuCO2: "0,205", contenuCO2ACV: "0,23", tauxEnrR: "0" },
  {id: "9304;C", nomReseau: "Zac de Sevran", Commune: "Sevran", contenuCO2: "0,091", contenuCO2ACV: "0,119", tauxEnrR: "0,59" },
  {id: "9305;C", nomReseau: "Zup des Fauvettes", Commune: "NEUILLY SUR MARNE", contenuCO2: "0,064", contenuCO2ACV: "0,077", tauxEnrR: "0,767" },
  {id: "9306;C", nomReseau: "Réseau de Villepinte", Commune: "Villepinte", contenuCO2: "0,045", contenuCO2ACV: "0,063", tauxEnrR: "0,815" },
  {id: "9309;C", nomReseau: "SEBIO", Commune: "Sevran", contenuCO2: "0,09", contenuCO2ACV: "0,133", tauxEnrR: "0,529" },
  {id: "9310;C", nomReseau: "Réseau chaud ADP le Bourget", Commune: "LE BOURGET", contenuCO2: "0,26", contenuCO2ACV: "0,292", tauxEnrR: "0" },
  {id: "9311;C", nomReseau: "Le Chêne Pointu", Commune: "Clichy-Sous-Bois", contenuCO2: "0,219", contenuCO2ACV: "0,255", tauxEnrR: "0" },
  {id: "9312;C", nomReseau: "Le Gros Saule", Commune: "Aulnay-sous-Bois", contenuCO2: "0,193", contenuCO2ACV: "0,241", tauxEnrR: "0" },
  {id: "9313;C", nomReseau: "Réseau de La Courneuve", Commune: "LA COURNEUVE", contenuCO2: "0,082", contenuCO2ACV: "0,09", tauxEnrR: "0,591" },
  {id: "9314;C", nomReseau: "Réseau de Tremblay en France", Commune: "TREMBLAY EN France", contenuCO2: "0,052", contenuCO2ACV: "0,071", tauxEnrR: "0,796" },
  {id: "9315;C", nomReseau: "Réseau du Blanc Mesnil", Commune: "LE BLANC-MESNIL", contenuCO2: "0,09", contenuCO2ACV: "0,107", tauxEnrR: "0,57" },
  {id: "9316;C", nomReseau: "Reseau de Bondy", Commune: "Bondy", contenuCO2: "0,12", contenuCO2ACV: "0,149", tauxEnrR: "0,461" },
  {id: "9317;C", nomReseau: "Garonor", Commune: "Aulnay-sous-Bois", contenuCO2: "0,305", contenuCO2ACV: "0,36", tauxEnrR: "0" },
  {id: "9324;C", nomReseau: "Réseau de Rosny-sous-Bois /Noisy-le-Sec / Montreui", Commune: "ROSNY-SOUS-BOIS", contenuCO2: "0,038", contenuCO2ACV: "0,035", tauxEnrR: "0,817" },
  {id: "9326;C", nomReseau: "Réseau de chaleur de la ZAC des docks Saint-Ouen", Commune: "SAINT-OUEN-SUR-SEINE", contenuCO2: "0,08", contenuCO2ACV: "0,096", tauxEnrR: "0,775" },
  {id: "9327;C", nomReseau: "OPH DE DRANCY", Commune: "DRANCY", contenuCO2: "0,193", contenuCO2ACV: "0,201", tauxEnrR: "0,5" },
  {id: "9401;C", nomReseau: "Réseau de Limeil-Brévannes", Commune: "LIMEIL-BREVANNES", contenuCO2: "0,061", contenuCO2ACV: "0,084", tauxEnrR: "0,768" },
  {id: "9402;C", nomReseau: "Réseaux de Créteil - Scuc", Commune: "Créteil", contenuCO2: "0,066", contenuCO2ACV: "0,079", tauxEnrR: "0,694" },
  {id: "9403;C", nomReseau: "Réseau de Choisy-Vitry", Commune: "Vitry-sur-Seine", contenuCO2: "0,128", contenuCO2ACV: "0,151", tauxEnrR: "0,687" },
  {id: "9404;C", nomReseau: "Réseau de Fontenay sous Bois", Commune: "FONTENAY-SOUS-BOIS", contenuCO2: "0,18", contenuCO2ACV: "0,215", tauxEnrR: "0,172" },
  {id: "9406;C", nomReseau: "Réseau de GEOTELLUENCE", Commune: "IVRY-SUR-SEINE", contenuCO2: "0,074", contenuCO2ACV: "0,095", tauxEnrR: "0,784" },
  {id: "9408;C", nomReseau: "Marché International de Rungis", Commune: "RUNGIS", contenuCO2: "0,003", contenuCO2ACV: "0,008", tauxEnrR: "0,99" },
  {id: "9409;C", nomReseau: "Réseau de Sucy en Brie", Commune: "Sucy-en-Brie", contenuCO2: "0,004", contenuCO2ACV: "0,019", tauxEnrR: "0,983" },
  {id: "9410;C", nomReseau: "Réseau de Cachan", Commune: "Cachan", contenuCO2: "0,069", contenuCO2ACV: "0,088", tauxEnrR: "0,715" },
  {id: "9411;C", nomReseau: "Réseau de Champigny sur Marne", Commune: "Champigny-sur-Marne", contenuCO2: "0,055", contenuCO2ACV: "0,078", tauxEnrR: "0,721" },
  {id: "9412;C", nomReseau: "Réseau de maisons-alfort", Commune: "Maisons-Alfort", contenuCO2: "0,095", contenuCO2ACV: "0,113", tauxEnrR: "0,62" },
  {id: "9413;C", nomReseau: "Réseau de Thiais", Commune: "Thiais", contenuCO2: "0,016", contenuCO2ACV: "0,032", tauxEnrR: "0,938" },
  {id: "9414;C", nomReseau: "SETBO", Commune: "Bonneuil-sur-Marne", contenuCO2: "0,016", contenuCO2ACV: "0,032", tauxEnrR: "0,938" },
  {id: "9415;C", nomReseau: "Réseau de Chevilly-Larue L'Haÿ-les-Roses Villejuif", Commune: "CHEVILLY-LARUE", contenuCO2: "0,071", contenuCO2ACV: "0,093", tauxEnrR: "0,761" },
  {id: "9419;C", nomReseau: "Réseau de Fresnes", Commune: "Fresnes", contenuCO2: "0,081", contenuCO2ACV: "0,102", tauxEnrR: "0,602" },
  {id: "9420;C", nomReseau: "Réseau d'Orly", Commune: "ORLY", contenuCO2: "0,04", contenuCO2ACV: "0,057", tauxEnrR: "0,829" },
  {id: "9421;C", nomReseau: "Reseau d'Alfortville - Smag", Commune: "Alfortville", contenuCO2: "0,021", contenuCO2ACV: "0,037", tauxEnrR: "0,91" },
  {id: "9422;C", nomReseau: "Arcueil-Gentilly", Commune: "ARCUEIL", contenuCO2: "0,035", contenuCO2ACV: "0,041", tauxEnrR: "0,845" },
  {id: "9423;C", nomReseau: "Réseau d'Ivry", Commune: "IVRY-SUR-SEINE", contenuCO2: "0,076", contenuCO2ACV: "0,1", tauxEnrR: "0,78" },
  {id: "9424;C", nomReseau: "Réseau de Villeneuve Saint Georges", Commune: "Villeneuve-Saint-Georges", contenuCO2: "0,109", contenuCO2ACV: "0,152", tauxEnrR: "0,451" },
  {id: "9425;C", nomReseau: "Réseau chaud ADP Orly", Commune: "Orly", contenuCO2: "0,092", contenuCO2ACV: "0,109", tauxEnrR: "0,62" },
  {id: "9501;C", nomReseau: "Zac Croix Rouge", Commune: "TAVERNY", contenuCO2: "0,108", contenuCO2ACV: "0,21", tauxEnrR: "0,242" },
  {id: "9502;C", nomReseau: "Grand Ensemble Sarcelles-Locheres", Commune: "Sarcelles", contenuCO2: "0,061", contenuCO2ACV: "0,074", tauxEnrR: "0,729" },
  {id: "9503;C", nomReseau: "Réseau de Cergy - Pontoise", Commune: "CERGY", contenuCO2: "0,075", contenuCO2ACV: "0,092", tauxEnrR: "0,717" },
  {id: "9505;C", nomReseau: "Van Gogh", Commune: "Garges-Lès-Gonesse", contenuCO2: "0,192", contenuCO2ACV: "0,228", tauxEnrR: "0" },
  {id: "9507;C", nomReseau: "Zup de Sannois - Ermont - Franconville", Commune: "Franconville", contenuCO2: "0,096", contenuCO2ACV: "0,123", tauxEnrR: "0,546" },
  {id: "9509;C", nomReseau: "Réseau d'Argenteuil", Commune: "Argenteuil", contenuCO2: "0,04", contenuCO2ACV: "0,049", tauxEnrR: "0,836" },
  {id: "9510;C", nomReseau: "Réseaux chaud ADP Roissy", Commune: "ROISSY-EN-FRANCE", contenuCO2: "0,212", contenuCO2ACV: "0,241", tauxEnrR: "0,182" },
  {id: "9511;C", nomReseau: "Zup de l'Epine Guyon", Commune: "Franconville", contenuCO2: "0,164", contenuCO2ACV: "0,201", tauxEnrR: "0" },
  {id: "9514;C", nomReseau: "Réseau de Villiers le Bel - Gonesse", Commune: "Villiers-le-Bel", contenuCO2: "0,071", contenuCO2ACV: "0,089", tauxEnrR: "0,658" },
  {id: "9515;C", nomReseau: "Réseau de Pontoise", Commune: "Pontoise", contenuCO2: "0,207", contenuCO2ACV: "0,246", tauxEnrR: "0" },
  {id: "9516;C", nomReseau: "Zac de Montedour", Commune: "Franconville", contenuCO2: "0,225", contenuCO2ACV: "0,254", tauxEnrR: "0" },
  {id: "9518;C", nomReseau: "Réseau de Goussainville", Commune: "GOUSSAINVILLE", contenuCO2: "0,026", contenuCO2ACV: "0,036", tauxEnrR: "0,894" },
]
