import { Injectable, NO_ERRORS_SCHEMA } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ChantierService, ErrorService } from '../../../app/_services';
import * as chantierActions from './chantier.actions';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { Store, select } from '@ngrx/store';
import 'rxjs/add/operator/withLatestFrom';
import * as fromChantier from './index';
import { Observable } from 'rxjs';

@Injectable()
export class ChantierEffects {
    constructor(private actions$: Actions,
                private store$: Store<any>,
                private chantierService: ChantierService,
                private errorService: ErrorService) { }
    
    
    loadFacture$ = createEffect(() => this.actions$.pipe(
        ofType(chantierActions.ChantierActionTypes.LoadFacture),
        mergeMap((action: chantierActions.LoadFacture) => this.chantierService.getFacture(action.payload).pipe(
            map(facture => new chantierActions.LoadFactureSuccess(facture)),
            // TODO: Change error handling
            catchError(error => of(new chantierActions.ErrorFacture(error)))
        ))
    ));

    
    updateFacture$: Observable<any> = createEffect(() => this.actions$.pipe(
        ofType(chantierActions.ChantierActionTypes.UpdateLotDevisAcompte, chantierActions.ChantierActionTypes.UpdatePosteDevisAcompte,
               chantierActions.ChantierActionTypes.AddPoste, chantierActions.ChantierActionTypes.UpdatePostePrice,
               chantierActions.ChantierActionTypes.DeletePoste, chantierActions.ChantierActionTypes.ToggleGlobalFacturation, 
               chantierActions.ChantierActionTypes.DeleteComment, chantierActions.ChantierActionTypes.UpdatePosteDesignation,
               chantierActions.ChantierActionTypes.SaveUpdateFacture),
        withLatestFrom(this.store$.pipe(select(fromChantier.getFacture))),
        mergeMap(([action, facture]) => this.chantierService.updateFacture(facture._id, facture).pipe(
            map(facture => new chantierActions.SaveUpdateFactureSuccess(facture)),
            catchError(error => of(new chantierActions.ErrorFacture(error)))
        ))
    ));

    
    finishFactureUpdate$: Observable<any> = createEffect(() => this.actions$.pipe(
        ofType(chantierActions.ChantierActionTypes.FinishFactureUpdate),
        mergeMap((action:any) => this.chantierService.updateFactureFinished(action.payload.factureId, action.payload.facture).pipe(
            map(facture => new chantierActions.SendFactureSuccess(facture)),
            catchError(error => of(new chantierActions.ErrorFacture(error)))
        ))
    ));

    
    acceptFacture$: Observable<any> = createEffect(() => this.actions$.pipe(
        ofType(chantierActions.ChantierActionTypes.AcceptFacture),
        mergeMap((action:any) => this.chantierService.acceptFacture(action.payload.factureId, action.payload.facture).pipe(
            map(facture => new chantierActions.SendFactureSuccess(facture)),
            catchError(error => of(new chantierActions.ErrorFacture(error)))
        ))
    ));

    
    rejectFacture$: Observable<any> = createEffect(() => this.actions$.pipe(
        ofType(chantierActions.ChantierActionTypes.RejectFacture),
        mergeMap((action:any) => this.chantierService.rejectFacture(action.payload.factureId, action.payload.facture).pipe(
            map(facture => new chantierActions.SendFactureSuccess(facture)),
            catchError(error => of(new chantierActions.ErrorFacture(error)))
        ))
    ));

    
    errorFacture$: Observable<any> = createEffect(() => this.actions$.pipe(
        ofType(chantierActions.ChantierActionTypes.ErrorFacture),
        mergeMap((action:any) => of(this.errorService.manageError(action.payload, null)))
    ), {dispatch: false})
}
