<div class="container-fluid">
  <div class="col-md-5 full-height colored-side hidden-xs hidden-sm">
    <div>
      <img class="aglo-logo" src="/assets/rob/aglo_logo_round_blanc.png" alt="">
      <h2 class="slogan">
          L’assistant intelligent <br>
          pour votre consultation d’entreprise
      </h2>
    </div>
    <div class="connect">
      <a [routerLink]="['/login']">Déjà un compte sur Aglo ?</a>
    </div>
  </div>
  <div class="col-md-7 full-height ">
    <div class="form" *ngIf="false">

      <h1 class="title">Vous pouvez utiliser votre compte BAM pour utiliser AGLO </h1>
      <img class="bam-logo" src="/assets/new-bam-logo.svg" alt="" height="60">
      <br>
      <div class="center">Vous avez un compte sur BAM archi ?</div>
      <div class="row">
        <div class="col-xs-6">
          <button id="archi-subscribe-question-non"
                  (click)="choice = 'type'"
                  class="300-w btn-primary-border" style="padding: 0px 50px;">
            <div id="archi-subscribe-question-non">Non</div>
          </button>
        </div>
        <div class="col-xs-6">
          <button [routerLink]="[ '/login']" class="300-w btn-primary-color" >
            <div>Oui</div>
          </button>
        </div>
      </div>
    </div>
    <div class="form" *ngIf="true">
      <h1 class="title">Vous utilisez AGLO en tant que: </h1>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <button id="archi-subscribe-question-non"
                  [routerLink]="[ '/architect/subscribe']"
                  class="300-w btn-primary-border" style="line-height: 25px;width:100%">
            <div id="archi-subscribe-question-non">
              <b>Maître d’oeuvre</b> <br>
              Architecte, bureau d’étude, économiste, …
            </div>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <button id="archi-subscribe-question-non"
                  [routerLink]="[ '/architect/subscribe']"
                  class="300-w btn-primary-border" style="line-height: 25px;width:100%">
            <div id="archi-subscribe-question-non">
              <b>Maître d’ouvrage</b> <br>
              Promoteur, foncière, société, …
            </div>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <button id="archi-subscribe-question-non"
                  [routerLink]="[ '/artisan/subscribe']"
                  class="300-w btn-primary-border" style="line-height: 25px;width:100%">
            <div id="archi-subscribe-question-non">
              <b>Entreprise du bâtiment</b> <br>
              Entreprise générale, artisan spécialisé, …
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
