<div class="all">
  <app-header cssClass="fixed-bar"></app-header>

  <div class="container" *ngIf="loadingData">
    <div class="row" style="margin-top:70px;">
      <app-spinner></app-spinner>
    </div>
  </div>

  <div class="container" *ngIf="!loadingData">
    <app-architect-new-project [project]="project" [updating]="true" (deleteThumbnail)="deleteThumbnail()"></app-architect-new-project>

    <div class="center">
      <button id="button" [disabled]="loading" (click)="goBack();" href="#" class="btn-primary-color back-btn">
       <div *ngIf="!loading">Annuler</div>
       <app-spinner *ngIf="loading"></app-spinner>
     </button>
     <button id="submit" [disabled]="loading" (click)="modify();" href="#" class="btn-primary-color">
       <div *ngIf="!loading">Modifier</div>
       <app-spinner *ngIf="loading"></app-spinner>
     </button>
    </div>

  </div>
</div>
