<div class="demandes-recommandation-wrapper">

  <!-- No Demandes Recomandations -->
  <div class="no-demands text-center margin-top-80" *ngIf="!recommandation.length">
    <h5 class="ag-heading-h5">Votre onglet "Mes demandes de recommandations" est vide</h5>
    <p>Les coordonnés des maîtres d’oeuvre succeptibles de vous recommander s'afficheront ici.</p>
    
    <button class="ag-btn-primary-sm margin-top-60" (click)="openAddContactModal()">Transmettre un contact</button>
  </div>
  <!-- END No Demandes Recomandations -->

  <!-- Contacts List -->
  <div class="contacts-list margin-top-30" *ngIf="recommandation.length">
    <p class="ag-para-2">
      <b>Contacts transmis</b> <br>
      Pour participer à des appels d’offre, merci de partager les coordonnées d’architectes avec qui vous avez travaillés pour qu’ils puissent vous recommander.
    </p>
    
    <div class="contacts-table-header horizontal-items space-between margin-top-30">
      <span class="width-25">Nom</span>
      <span class="width-25">Nom de l’agence</span>
      <span class="width-25">Email</span>
      <span class="width-25">Numéro de téléphone</span>
    </div>
    <div class="contacts-table-body">
      <div class="single-contact-item horizontal-items space-between" 
        *ngFor="let item of recommandation; let i = index;">
        <span class="ag-para-1-downriver width-25">{{ item.nameArchi || "" }}</span>
        <span class="ag-para-1-downriver width-25">{{ item.maitreDoeuvre || "" }}</span>
        <span class="ag-para-1-downriver width-25">{{ item.mailArchi || "" }}</span>
        <div class="ag-para-1-downriver width-25 horizontal-items space-between remove-continer">
          <span>{{ item.phoneNumber || "" }}</span>
          <img src="/assets/artisan-booster/remove-icon.svg" 
            class="pointer" 
            (click)="removeContact(i)">
        </div>
      </div>
    </div> 
  </div>
  <!-- END Contacts List -->

  <div class="open-add-contact-modal flex-center pointer" (click)="openAddContactModal()">
    <img src="/assets/artisan-booster/add-sign.svg" class="margin-right-8">
    <p class="ag-para-3 ag-weight-400">Ajouter un autre contact</p>
  </div>

  <!-- Add Recommandations Contact Modal -->
  <app-add-recommandations-contact-modal 
    (saveContacts)="saveNewContacts($event)">
  </app-add-recommandations-contact-modal>

</div>