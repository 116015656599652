import { Component, Input, OnInit, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { Location} from '@angular/common';

/// Services
import { AuthService } from '../../_services';
import { PaymentsService } from '../../_services/index';
import { ArchitectService } from '../../_services/index';
import { MessageService } from 'primeng/api';
// import { identifierModuleUrl } from '@angular/compiler';
import { environment } from '../../../environments/environment';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { Observable } from 'rxjs';

declare var Stripe: any;
declare var $ : any;

@Component({
    standalone: true,
    selector: 'app-architect-pricing-tables',
    imports:[NgSharedModule],
    providers: [],
    templateUrl: './architect-pricing-tables.component.html',
    styleUrls: ['./architect-pricing-tables.component.scss'],
})
export class ArchitectPricingTablesComponent implements OnInit {
  @Input() paymentMethod;
  public handler: any;
  public plans: any;
  public coupon="";
  public hasSubscription:boolean = false;subscriptionStripe="";
  public selectedPLan;
  public licence;
  public selectedLicence:String;
  public churnedraison:String;
  public loadinglicence:Boolean = false;
  public showParrainageText:Boolean = false;
  public isAnnuel:Boolean=true;
  public checked:Boolean = false;
  public parrainage: any = {};
  public selectedPlan=null;
  public loadingSub: boolean;
  public archiSub;
  public price: number=0;
  public oldAmount;
  public newAmount;
  public newPlan;disableBtnLicence;newChoosenPlan;stepDelete='one';customerId;
  public currentTab='licence';
  public noPlanSelected: boolean=false;typePayment='cb';
  public licenceC: string;emailUser='';
  public couponOff={name:'',percent_off:0,amount_off:0};currentTva;
  public periodStart="";stepPayment= 'first';stepPrice;periodEnd="";couponValid = false;disableBtnLicence3ds:boolean=false;priceWithReduction=0;endDateLicence;
  public licences:{[key: string]: string}={
    plus : "AGLO Pro Mensuel",
		premium : "AGLO Intégral Mensuel",
		base : "AGLO Essentiel",
		"plus-annuel" : "AGLO Pro Annuel",
		"premium-annuel" : "AGLO Intégral Annuel",
    "premiumn" : "AGLO Intégral mensuel",
    "premiumn-annuel" : "AGLO Intégral annuel",
    "max" : "AGLO Max-mensuel",
    "max-annuel" : "AGLO Max annuel",
    without:"Aucun abonnement",
    'plus-trim':'AGLO Pro annuel',
    'max-trim':'AGLO max annuel',
    'premiumn-trim':'AGLO integral annuel',
  };
  public licencesPrices={
    premium : 90,
		base : 0,
    plus : 40,
    'plus-trim':120,
		"plus-annuel" : 480,
		"premium-annuel" : 900,
    "premiumn" : 115,
    'premiumn-trim':345,
    "premiumn-annuel" : 1380,
    "max" : 73,
    'max-trim':220,
    "max-annuel" : 880,
    without:0
  }
  @Input() subscriptions;
  @Input() users;
  @Input() tvaUser;

  public stripe;
  
  public newAmountPrice=0;
  public step=1;
  public dateEndTrial: any;
  public daysDiffLicence: number;
  public loadinglicenceSubmit: boolean;
  public firstValueEnvirenementObservable = new Observable<boolean>(observer => {
    observer.next(environment.onPremise);
    observer.complete();
  });
  constructor(
    private location: Location,
    public authService: AuthService,
    public paymentsService: PaymentsService,
    private archiService: ArchitectService,
    private messageService: MessageService,
  ) {
    if(!environment.onPremise) this.stripe = Stripe(environment.stripeKey);
   }

  ngOnInit() {
    this.getUserData()
    this.checkHasUnpaiedParrainage();
    // segment page event
    // if(!environment.onPremise)window.analytics.page('pageview_licence');
    
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes.subscriptions && !changes.subscriptions.firstChange) {
      // console.log('changes.subscriptions',changes.subscriptions.currentValue[0])
      this.getArchiSub();
    }
    if(changes.tvaUser){
      this.currentTva = changes.tvaUser.currentValue;
    }
  }
  getArchiSub(){
    if(this.subscriptions?.length>0){
      this.archiSub=this.subscriptions[0];
      this.periodStart = new Date(this.archiSub.current_period_start * 1000).toLocaleDateString();
      this.periodEnd = new Date(this.archiSub.current_period_end * 1000).toLocaleDateString();
      if(this.archiSub.items.data[0].plan.interval=="year"){
        let price1=this.archiSub.items.data[0].plan.amount/100;
        this.price=Math.floor(this.archiSub.items.data[0].plan.amount / 1200);
        this.oldAmount=price1.toString() + "€ / an"
      }
      else {
        this.price=this.archiSub.items.data[0].plan.amount / 100;
        this.oldAmount=this.price.toString() + "€ / mois";
        // console.log('this.price else',this.price)
      }

    }
    else this.oldAmount=(0).toString() + "€ / an"
  }
  
  getUserData(){
    this.loadingSub=true;
    this.authService.getUserLicenceStripeUser()
    .subscribe((res)=>{
      this.disableBtnLicence = res.data.stripe3dsUrl || res.data.paymentCount>0;
      this.disableBtnLicence3ds =  res.data.stripe3dsUrl;
      this.customerId = res.data.stripeCustomerID || null;
      this.licence = res.data.licence;
      this.licenceC= res.data.licence;
      this.selectedPlan=res.data.licence;
      this.currentTva = res.data.defaulTVA|| {_id:'txr_1Lg8p4GtSDuV98PbCDYcKTup',percentage:20};
      if(res.data.endDateLicence){
      this.endDateLicence = res.data.endDateLicence.split('T')[0].replace('-/g','/');
      this.endDateLicence=this.endDateLicence.split('-');
      this.endDateLicence = this.endDateLicence[2] + "-" + this.endDateLicence[1] + "-" + this.endDateLicence[0];
      }
      this.emailUser = res.data.email;
      this.subscriptionStripe = res.data.stripeCustomerSubscriptionID;
      if(res.data.dateEndTrial) {
        this.dateEndTrial=new Date(res.data.dateEndTrial);
        let newDate=new Date();
        //change
        this.daysDiffLicence= Math.floor((this.dateEndTrial.getTime() - newDate.getTime()) / (1000*60*60*24)) + 1
        this.dateEndTrial=this.dateEndTrial.toLocaleDateString("en-GB");
        // this.daysDiffLicence=0;
        // if(this.daysDiffLicence==0) $('#expired-licence').modal('show');
      }
      setTimeout(()=>{
        if(this.licence=='plus' || this.licence=='premium' || this.licence=='premiumn' || this.licence=='max'){
          $('.switch').click();
          this.isAnnuel=false;
        }
      },500)

      this.hasSubscription =( res.data.stripeCustomerID||"") != "" && (res.data.stripeCustomerSubscriptionID||"") != "";
      this.loadingSub=false;
    })
  }
  checkSelected(plan,planAnnuel){
    if((this.isAnnuel && this.licence===planAnnuel) || (!this.isAnnuel && this.licence===plan)) return true;
    else return false;
  }
  setSelectedPlan(plan){
    if(!(this.licenceC==plan) || this.dateEndTrial){
      this.selectedPlan=plan;
      this.licence=plan;
      this.noPlanSelected=false;
    }
  }
  viewChange(){
    this.isAnnuel=!this.isAnnuel;
  }
  checkCoupon(){
    this.paymentsService.getCoupon(this.coupon).subscribe((data)=>{
      if(data.percent_off) this.priceWithReduction = this.stepPrice-this.stepPrice*(data.percent_off/100)
      else if(data.amount_off) this.priceWithReduction = this.stepPrice-(data.amount_off/100)
      else this.priceWithReduction =this.stepPrice
      this.couponValid = data.percent_off>0 || data.amount_off>0?false:true;
      this.couponOff=data;
    });
  }
  isPlan(plan){
    if(!plan) this.noPlanSelected=true;
    else if(plan == 'base'){
      $('#churned-form').modal('toggle');
      this.newAmountPrice=0
    }
    else{
      this.newAmount=this.getStringLicence(plan);
      this.newAmountPrice=this.licencesPrices[plan]
      this.step=2;
    }
    this.newPlan=plan;
  }
  preRequestPlan(plan){
    if(plan == 'base'){
      $('#churned-form').modal('toggle');
    }else{
      this.requestPlan(plan)
      // if()$('#upgrade-licence').modal('toggle');
    }
  }
  requestPlan(plan){
    this.loadinglicenceSubmit = true;
    let reqBody = {plan, churnedraison : this.churnedraison,taxRate:this.currentTva};
    if((this.coupon || "") != "")reqBody["coupon"] = this.coupon
    this.paymentsService.choosePlanArchitect(reqBody)
    .subscribe(data => {
      if(data.data.type == "create architect subscription" || data.data.type=="create architect subscription (existing stripe account)"){
        this.loadinglicenceSubmit = false;
        this.stripe.redirectToCheckout({
          sessionId: data.data.res.id
        }).then(function (result) {
        });
      }else{
        $('#upgrade-licence').modal('hide');
        $('#change-licence').modal('hide');
        this.loadinglicenceSubmit = false;
        // updating local storage licence
        let currentUser=JSON.parse(localStorage.getItem('currentUser'));
        currentUser['licence']=plan;
        localStorage.setItem('currentUser',JSON.stringify(currentUser))
        $('#check-done').modal('toggle');
        setTimeout(() => {
          this.location.go(`architect/profil?tab=licence` );
          location.reload();
        }, 5000);
      }
      // segment event
      // if(plan!="base")this.segmentTrack(plan,data.data.type);
      // else {
      //   this.segmentIdentify.identifyChurnedRaison(this.churnedraison,this.price,this.newAmountPrice)
      //   if(!environment.onPremise)window.analytics.track('churn',{churn_reason:this.churnedraison,acValue:'churn'});
      // }
    },
    err =>{
      this.loadinglicenceSubmit = false;
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: err.error.err.message, life: 5000 });
      if(!environment.onPremise)window.analytics.track('charge_failed',{properties:{acValue: 'charge_failed'}});
    });
  }
  openPortal(){
    // Authenticate your user.
    this.paymentsService.generateSessionPortal().subscribe(
      session=>{
        window.open(session.url, '_blank');
      }
    )
  }
  resetCard(){
    this.loadinglicence = true;
    this.paymentsService.resetPayement({methodPayment:['sepa_debit']})
    .subscribe((data) => {
      this.stripe.redirectToCheckout({
        sessionId: data.data.id
      }).then(function (result) {

      });
      // Refresh page !
    },
    err =>{
      this.loadinglicence = false;
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: err.error.err.message, life: 5000 });
    })
  }
  getStringLicence(plan){
    return (this.licencesPrices[plan]||0).toString() + ((plan.includes('annuel'))?' € / annuel':' € / mois')
  }
  truncNumber(num){
    return Math.trunc(num)
  }
  closeModalOut(){
    $('#churned-form').modal('toggle');
  }
  requestPlanEssentiel(){
    if((this.churnedraison||"") != ""){
      $('#churned-form').modal('hide');
      this.requestPlan('base');
    }
  }
  checkHasUnpaiedParrainage(){
    this.archiService.checkHasUnpaiedParrainage().subscribe(
      data => {
        data.count > 0 ? this.showParrainageText = true : this.showParrainageText = false;
        this.parrainage = data.parrainage;
      },
      err => {
        console.log(err);
      }
    );
  }
  showLicenceModal(){
    $('#change-licence').modal('toggle');
  }
  stopLicence(){
    $('#upgrade-licence').modal('toggle');
    // profitwell('start', { 'user_email': this.emailUser });
    // profitwell('init_cancellation_flow', {subscription_id: this.subscriptionStripe}).then(result => {
    //   // This means the customer either aborted the flow (i.e.
    //   // they clicked on "never mind, I don't want to cancel"), or
    //   // accepted a salvage attempt or salvage offer.
    //         // Thus, do nothing since they won't cancel.
    //         console.log('result',result)
    //         if (result.status === 'retained' || result.status === 'aborted') {
    //             return
    //         }
    
    //     // At this point, the customer ended deciding to cancel (i.e.
    //   // they rejected the salvage attempts and the salvage offer).
    //         // It could also be the case the widget couldn't be shown properly for
    //         // some reason (for which case, `result.status` will be 'error'), but that
    //         // shouldn't stop them from cancelling.
    
    //   // The normal cancel flow goes here
    //     })
  }
  isDifferentPeriodicity(newPlan,licenceC){
    if((newPlan.includes('annuel') && !licenceC.includes('annuel'))||(licenceC.includes('annuel') && !newPlan.includes('annuel'))) return true
    else return false
  }
  navigateToPaymentModalPart(step,price,plan){
    this.stepPrice = price;
    this.stepPayment = step;
    this.priceWithReduction= price;
    this.newChoosenPlan = plan;
  }
  goToLicences(step){
    this.stepPayment = step;
    if(step=='first')this.typePayment = "cb";
  }
  goToStepTwo(currentStep){
    this.stepDelete = currentStep;
  }
  
  trimestrielOrAnnuel(){
    if(this.typePayment=='sepa'){
      if(this.newChoosenPlan.includes('plus'))this.newChoosenPlan='plus-trim';
      if(this.newChoosenPlan.includes('max'))this.newChoosenPlan='max-trim';
      if(this.newChoosenPlan.includes('premiumn'))this.newChoosenPlan='premiumn-trim';
    }else{
      if(this.newChoosenPlan.includes('plus'))this.newChoosenPlan='plus-annuel';
      if(this.newChoosenPlan.includes('max'))this.newChoosenPlan='max-annuel';
      if(this.newChoosenPlan.includes('premiumn'))this.newChoosenPlan='premiumn-annuel';
    }
  }
}