
<div class="header">
  <div class="row">
    <div class="center">
      <div class="inner-container">
        <i class="click-it fa fa-times" aria-hidden="true" (click)="closeCctp()"></i>
        <p *ngIf="cctpEdit?.postDesignation && !cctpEdit?.sousLotTitle" class="post-title">{{lot?.title}} > {{cctpEdit?.postDesignation}}</p>
        <p *ngIf="!cctpEdit?.postDesignation && !cctpEdit?.sousLotTitle" class="post-title">{{lot?.title}} > Généralités</p>
        <p *ngIf="cctpEdit?.sousLotTitle" class="post-title">{{lot?.title}} > {{cctpEdit?.sousLotTitle}} > Généralités</p>
        <div *ngIf="tab=='CSTB'" class="validation-holder">
          <button *ngIf="value === 'CSTB' && !lodingCstb && choosedCstb" type="button" class="btn-valdiate" (click)="generateCctp('add')">
            Ajouter
          </button>
          <button *ngIf="value === 'CSTB' && !lodingCstb && !choosedCstb" type="button" class="btn-valdiate" (click)="validateCctp()">
            Valider
          </button>
          <button *ngIf="((cctpEdit$ | async)?.cctp||'') != '' && value === 'CSTB' && !lodingCstb && choosedCstb" type="button" class="btn-valdiate-invert" (click)="generateCctp('replace')">
            Écraser
          </button>
        </div>
      </div>

      <!-- Généralités / Description de l'ouvrage -->
      <!-- <button *ngIf="value === 'statique'" type="button" class="btn-valdiate" (click)="closeCctp()" name="button">
        Enregistrer
      </button> -->

      <div *ngIf="cctpEdit?.postDesignation" class="choice-container">
        <div class="choice" (click)="onSelectionChangeTab('CSTB')"  [ngClass]="{'selectd-choice' : tab === 'CSTB'}">
          Description technique
        </div>
        <div class="choice" (click)="onSelectionChangeTab('env')" [ngClass]="{'selectd-choice' : tab === 'env'}">
          Description environnementale
        </div>
        <!-- <div class="choice" (click)="onSelectionChangeTab('produit')" [ngClass]="{'selectd-choice' : tab === 'produit'}">
          Produit
        </div> -->
      </div>
    </div>
  </div>
</div>
<div class="content-cstb ">
  <div *ngIf="tab=='CSTB'" class="row switch-view">
    
    
      <!-- <div class="mini-nav link1" *ngIf="value=='CSTB'">
        <i class="fa fa-arrow-left"></i>
        <a (click)="value='statique'" style="margin-left: 6px;">Revenir à la rédaction</a>
      </div>
      <div class="mini-nav link2" *ngIf="value=='statique'">
        <a (click)="value='CSTB'" style="margin-right: 6px;">Accéder à la bibliothèque du CSTB</a>
        <i class="fa fa-arrow-right" ></i>
      </div> -->
    
    
    <div class="mini-nav link1" *ngIf="value=='CSTB'">
      <i class="fa fa-arrow-left"></i>
      <a (click)="setValueAndSwitch(true,'statique')" style="margin-left: 6px;">Revenir à la rédaction</a>
    </div>
    <div class="horizontal-items space-between" style="margin-top: 4px;" *ngIf="value=='statique'" >
      <div class="mini-nav link2"> 
        <span (click)="!loadingData && switchTo=true" [ngStyle]="{'background-color': switchTo?'#cbcdd3':'##eeeeee'}"> Rédaction</span>
        <span (click)="!loadingData && setValueAndSwitch(false,'CSTB')" [ngStyle]="{'background-color': !switchTo?'#cbcdd3':'##eeeeee'}">Bibliothèque CSTB</span>
      </div>
      <div *ngIf="!authService.currentEnvironment" class="mini-nav generate-poste-aglogpt">
        <button (click)="goToAgloGPTGenerator(cctpEdit?.postDesignation|| 'Généralités')"><span>New</span> Générer mon CCTP avec AGLO GPT</button>
      </div>  
    </div> 
  </div> 
  <div *ngIf="tab=='CSTB'">
    <app-spinner-with-quotes [addVerticalPadding]="true" *ngIf="loadingData"></app-spinner-with-quotes>   
    <div style="position: relative;" *ngIf="!loadingData">
      <app-editor  [ngClass]="{hide: value !== 'statique'}" class="cctp-editor editor"
                  [placeholder]="divVisibility ? '' : 'Rédigez vos CCTP' "
                  Description
                  name="name"                
                  [style]="{'height':'550px'}"
                  [ngModel]="((cctpEdit$ | async)?.cctp||'')"
                  (onTextChange)="setCctpText($event)"
                  ></app-editor>
                  <!-- (onSelectionChange)="setCctpText($event)" -->


      <div class="result scroller-1-y" *ngIf="divVisibility && value =='statique'">
        <div  class="score flex-right"> Score de fiabilité:  <span [ngStyle]=" {'color': color}">{{score}} %</span></div>
        <div><p >{{result}}</p></div>
        <div class="btns-aglo-gpt" >
          <button class="cancel-btn" (click)="annuler(result)"> Annuler</button>
          <button class="regenerate-btn" (click)="regenererCctp(cctpEdit?.postDesignation|| 'Généralités',result)"><i class="fa fa-rotate-left"></i>Regénerer</button>
          <button class="add-btn" (click)="addCttpDescToTextEditor(result)">Ajouter</button>
        </div>
      </div>
    </div>
    <div class="cstb-section floating-card" [ngClass]="{hide: value !== 'CSTB'}">
      <div>
        <input class="input-search" [(ngModel)]="searchTerm" (ngModelChange)="this.searchTermUpdate.next($event)" >
        <i class="fa fa-search search-icon" (click)='searchPost()'></i>
      </div>
      <div *ngIf="gid && hasOptionsOrTextarea && !searchTerm">
        <app-spinner *ngIf="lodingCstb"></app-spinner>
        <div *ngIf="!lodingCstb" style="display: flex;position: relative;right: 7px;">
          <button class="return" (click)="removeCstbId()"><i class="fa fa-arrow-left"></i></button>
          <div id="cctp" class="cstb-content" [innerHTML]="cstb"></div>
          <div *ngIf="error == 404" style="width: 100%;">
            <h3 class="center">Oups ! Ce poste n'existe plus dans le CSTB</h3>
          </div>
          <div *ngIf="error == 403 && !accessToCstb" style="width: 100%;">
            <h3 class="center">Le CSTB est limité aux utilisateurs AGLO Intégral</h3>
            <button (click)="redirectToPricingTable()" type="button" class="center btn-go">C'est parti !</button>
          </div>
          <div *ngIf="error == 402" style="width: 100%;">
            <h3 class="center">Vous avez atteint la limite de postes que vous pouvez importer depuis le CSTB avec l'essai gratuit. Pour pouvoir en importer plus, souscrivez à une licence AGLO Intégral.</h3>
            <button (click)="redirectToPricingTable()" type="button" class="center btn-go">C'est parti !</button>
          </div>
        </div>
      </div>
      <div [ngClass]="{hidden : (gid && hasOptionsOrTextarea || searchTerm)}">
        <app-cstb-navigation isLot="true" (selectedCstbEvent)="selectPost($event)"></app-cstb-navigation>
      </div>
      <div *ngIf="searchTerm && (licence=='premium' || licence=='premium-annuel' || licence=='premiumn' || licence=='premiumn-trim' || licence=='premiumn-annuel')">
        <p class="result-title">Resultats de <b>{{searchTerm}}</b> :</p>
        <div class="post-container" *ngFor="let post of results;let i=index">
          <input type='checkbox' (click)='selectedPost(i)'>
          <p class="post">{{post.sugg}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="env" *ngIf="tab=='env'">
    <app-acv-project-composant-sidebar *ngIf="view!='savedPosts'"
      [projectLicence]="projectLicence"
      [componentView]="'cctp'" [selectedLot]='lot' [cctpEditStore]="cctpEdit" [isDemo]="dpgf?.demo"
      (frirogeneResult)="sendEventFather($event,'frirogene')" (infoPost)="sendEventFather($event,'setInfo')"
      [selectedFriro]="selectedFriro" (addingPostIniesEvent)="sendEventFather($event,'setPost')"
      (openRemploiEvent)="openRemploi($event)" (ChangeViewEvent)="changeView($event)"
      [currentContibuteur]="'Composants'">
    </app-acv-project-composant-sidebar>
    <div class="row main-container">
      <div class="save-posts" *ngIf="view=='savedPosts'">
        <div class="main-content" *ngIf="!loadingPosts">
          <div class="options-holder">
            <div *ngIf="selectedFiles.length" class="icon-holder margin-right-24">
              <i class="fa fa-trash margin-right-12 pointer" pTooltip='Supprimer' (click)="deleteSelectedFiles()"></i>
              <i class="fa fa-copy pointer" pTooltip='Dupliquer' (click)='duplicateSelectedFiles()'></i>
            </div>
          </div>
          <div class="row margin-top-12 table-container">
            <table class="table table-striped table-borderless">
              <thead>
                <tr>
                  <th><input type="checkbox" (click)='checkAllFiles()' [checked]='checkedAllFiles'></th>
                  <th>Fiche INIES affiliée au poste</th>
                  <th>Contributeur</th>
                  <th>Entité</th>
                  <th>Lot</th>
                  <th style="text-align: center;">Quantité</th>
                  <th style="text-align: center;">Unité</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let postFile of relatedFiles;let i=index;">
                  <tr>
                    <td style="height: 1px;">
                      <div class="flex" style="height: 100%;align-items: center;padding-bottom: 5px;">
                        <input type="checkbox" (click)='checkOneFile(postFile._id)' [checked]='postFile.checked'>
                      </div>
                    </td>
                    <td (click)="getPostOpenModal(postFile)"><p class="post-name no-margin pointer">{{postFile.nom}}</p></td>
                    <td style="position: relative;">
                      <select class="post-select" (change)='updatePost(postFile)' [(ngModel)]="postFile.contributor">
                        <option [value]="undefined" disabled hidden>Choisir le contributeur</option>
                        <option *ngFor="let contributor of contributors">{{contributor}}</option>
                      </select>
                      <img src="/assets/archi/select-arrow.svg" class="select-arrow" style="top: 20px;right: 20px;">
                    </td>
                    <td style="position: relative;">
                      <select *ngIf="postFile.contributor!='Chantier'" class="post-select" (change)='changeEntity($event,postFile._id)' aria-placeholder="">
                        <option [selected]="!postFile.entity" disabled hidden>Choisir l'entité</option>
                        <option [selected]="postFile.entity=='Parcelle'">Parcelle</option>
                        <ng-container *ngFor="let batiment of entities">
                          <option [selected]="postFile.entity==batiment.label" [value]="batiment.label">{{ batiment.label }}</option>
                          <option *ngFor="let zone of batiment.zones" [selected]="postFile.entity==zone.label" [value]="zone.label+'^'+batiment.label">
                            {{ zone.label }}
                          </option>
                        </ng-container>
                      </select>
                      <select *ngIf="postFile.contributor=='Chantier'" class="post-select" (change)='changeEntity($event,postFile._id)' aria-placeholder="">
                        <option [selected]="!postFile.entity" disabled hidden>Choisir l'entité</option>
                        <ng-container *ngFor="let batiment of entities">
                          <option [selected]="postFile.entity==batiment.label" [value]="batiment.label">{{ batiment.label }}</option>
                          <!-- <option *ngFor="let zone of batiment.zones" [selected]="postFile.entity==zone.label" [value]="zone.label+'^'+batiment.label">
                            {{ zone.label }}
                          </option> -->
                        </ng-container>
                      </select>

                      <img src="/assets/archi/select-arrow.svg" class="select-arrow" style="top: 20px;right: 20px;">
                    </td>
                    <td>
                      <div style="position: relative;">
                        <input class="post-select" *ngIf="!(postFile.contributor=='Chantier' || (postFile.contributor=='Composants' && postFile.entity=='Parcelle'))"
                          style="width: 220px;z-index: 10000;padding-right: 26px;" [(ngModel)]="postFile.lotPCE"
                          (focusout)='lotsPCECopy=lotsPCE' (keyup)="filterLotsPCE(postFile.lotPCE)" autocomplete="off"
                          id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" placeholder="Choisir le lot PCE">
                        <div *ngIf="!(postFile.contributor=='Chantier' || (postFile.contributor=='Composants' && postFile.entity=='Parcelle'))" id="lot-pce-dropdown" class="dropdown-menu lot-pce scroller-1" aria-labelledby="dropdownMenuButton">
                          <div *ngIf="postFile.lotPCE">
                            <div *ngFor="let lotPCE of lotsPCECopy">
                              <span *ngIf="lotPCE.lots.length" class="title">{{lotPCE.name}}</span>
                              <div *ngIf="lotPCE.lots.length" style="padding-left: 18px;">
                                <p *ngFor="let oneLot of lotPCE.lots" (click)="setPostPCE(oneLot,i)">{{oneLot}}</p>
                              </div>
                              <hr *ngIf="lotPCE.lots.length">
                            </div>
                          </div>
                          <div *ngIf="!postFile.lotPCE">
                            <div *ngFor="let lotPCE of lotsPCE">
                              <span class="title">{{lotPCE.name}}</span>
                              <div style="padding-left: 18px;">
                                <p *ngFor="let oneLot of lotPCE.lots" (click)="setPostPCE(oneLot,i)">{{oneLot}}</p>
                              </div>
                              <hr>
                            </div>
                          </div>
                        </div>
                        <img *ngIf="!(postFile.contributor=='Chantier' || (postFile.contributor=='Composants' && postFile.entity=='Parcelle'))" src="/assets/archi/select-arrow.svg" class="select-arrow" style="top: 12px;right: 11px;">
                    </div>
                    </td>
                    <td style="text-align: center;"><input (change)='updatePost(postFile)' [(ngModel)]="postFile.quantity" type="number" step="any" class="quantity-input"></td>
                    <td style="text-align: center;">{{(postFile.uniteUF||'m3')}}</td>
                    <td>
                      <div class="dropdown">
                        <div class="pointer" aria-hidden="true" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fa fa-ellipsis-v no-open" ></i>
                        </div>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <li (click)="$event.stopPropagation();duplicateOneFile(postFile)">
                            <i class="fa fa-clone click-delete-sommaire" aria-hidden="true"></i>
                            <span class="menu-text pointer">Dupliquer</span>
                          </li>
                          <li (click)="$event.stopPropagation();deleteOneFile(postFile)">
                            <i class="fa fa-trash click-delete-sommaire" aria-hidden="true"></i>
                            <span class="menu-text pointer">Supprimer</span>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr class="friro-row" *ngIf="showFrigo(postFile)">
                    <div *ngIf="!postFile.frigogenePost" class="frirogene-container">
                      <i class="fa fa-warning frigo-icons"></i>
                      <p class="margin-left-5" style="margin-bottom: 0px;">Votre composant Climatisation utilise un fluide frigorigène qu'il faut également indiquer.</p>
                      <a class="margin-left-5" (click)="openFrirogeneModal(postFile)" style="color:#3805F2">Choisir le fluide frigorigène correspondant</a>
                    </div>
                    <div *ngIf="postFile.frigogenePost" class="frirogene-container-selected">
                      <i class="fa fa-check-circle-o frigo-icons"></i>
                      <p class="margin-left-5">Votre composant Climatisation utilise le fluide frigorigène {{(postFile?.frigogenePost?.nom)||''}}. Sa charge initiale est de </p>
                      <input type="text" class="frigogene-input" [ngModel]="postFile.frigogenePost.frigogeneQuantity" (focusout)="updateFrigogeneData($event,postFile)"> kg
                      <a class="margin-left-5" style="color: #3805F2;" (click)="openFrirogeneModal(postFile)">Modifier</a>
                    </div>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="flex" style="margin-left:58px;margin-bottom: 24px;">
            <a class="btn btn-flat" (click)="changeView('importPosts')"> Ajouter une fiche</a>
            <!-- <a class="btn btn-gradient margin-left-12" (click)="openRemploi('Reemploi')">Ajouter un composant réemploi</a>
            <a class="btn btn-purple margin-left-12" (click)="openRemploi('#import-files-modal')">Importer ma fiche XML configurée <i class="fa fa-download"></i></a> -->
            <div class="flex result-container">
              <div class="icon-container">
                <img src="/assets/acv-icons/leaf-result.svg">
              </div>
              <div class="sub-result-container">
                <p class="result-title">Réchauffement climatique</p>
                <p class="no-margin padding-left-5 margin-bottom-5"><span style="font-weight: 800;">{{calculateTotal() | formatPrice}}</span> kg CO2 eq</p>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 24px;">
          <app-spinner *ngIf="loadingPosts"></app-spinner>
        </div>
      </div>
    </div>
  </div>

  <!-- Products Container -->
  <div class="produit-container" *ngIf="tab=='produit'">
    <div class="input-container">

      <div class="row">
        <div class="col-md-6">
          <p class="search-title">Rechercher une produit</p>
        </div>
        <div class="col-md-6 show-added-products">
          <a class="back-saved-posts" (click)="changeProductsView()">
            <span *ngIf="!showAddedProducts">Voir les produits ajouter</span>
            <span *ngIf="showAddedProducts">Recherche de produits</span>
          </a>
        </div>

      </div>

      <div class="search-form-control form-group">
        <input class="form-control string required"
        [(ngModel)]="searchProductTerm" type="text"
        id="product_search_query" placeholder="Nom du produit" autocomplete="off"
        (ngModelChange)="this.searchProductTermUpdate.next($event)"
        />
        <button name="button" type="submit" (click)="searchProducts()" (keyup.enter)='searchProducts()' class="btn btn-flat">
          <i class="fa fa-search"></i>
        </button>
      </div>

    </div>

    <div class="row main-container products-cards-wrapper">
      <!-- <app-spinner></app-spinner> -->

      <!-- Products added to the post -->
      <ng-container *ngIf="showAddedProducts">
        <img *ngIf="!addedProducts.length"
          src="/assets/archi/products_empty_cart.png" class="empty-products-list-img"
          alt="" style="display: block;margin: auto;">

        <div class="col-md-2 margin-bottom-25" *ngFor="let product of addedProducts">
          <div class="single-product-card cursor">
            <span class="remove-product-icon" (click)="openRemoveProductModal(product)">
              <i class="fa fa-times"></i>
            </span>
            <span class="site-badge">{{ product.source }}</span>
            <div class="flex-middle">
              <img [src]="product.imagesLinks[0]" *ngIf="product.imagesLinks.length; else image_placeholder" />
              <ng-template #image_placeholder>
                <img src="https://via.placeholder.com/350"/>
              </ng-template>

            </div>
            <h6 class="product-title"><strong>{{ product.title }}</strong></h6>
            <p class="product-price">{{ product.price[product.source] }}€</p>
          </div>
        </div>
      </ng-container>

      <!-- products Search result -->
      <ng-container *ngIf="!showAddedProducts">

        <img *ngIf="!products.length"
          src="/assets/archi/products_empty_cart.png" class="empty-products-list-img"
          alt="" style="display: block;margin: auto;">

        <div class="col-md-2 margin-bottom-25" *ngFor="let product of products">
          <div class="single-product-card cursor" (click)="openConfirmProduct(product)">
            <span class="site-badge">{{ product.source }}</span>
            <div class="flex-middle">
              <img [src]="product.imagesLinks[0]" *ngIf="product.imagesLinks.length; else image_placeholder" />
              <ng-template #image_placeholder>
                <img src="https://via.placeholder.com/350"/>
              </ng-template>

            </div>
            <h6 class="product-title"><strong>{{ product.title }}</strong></h6>
            <p class="product-price">{{ product.price[product.source] }}€</p>
          </div>
        </div>
      </ng-container>

    </div>

  </div>
  <!-- END Products Container -->


</div>

<!-- Modals -->
<app-chatgpt-regenerate-modal
  [redirectToChatGptPage]="true"
  (regenerate)="regenerateCCTP()"
></app-chatgpt-regenerate-modal>
<!-- END Modals -->
<app-archi-expired-gpt-modal></app-archi-expired-gpt-modal>
