import { Component, Input, Output, EventEmitter } from '@angular/core';

// Modules
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  selector: 'app-lots-list-view',
  standalone: true,
  imports: [
    NgSharedModule
  ],
  templateUrl: './lots-list-view.component.html',
  styleUrl: './lots-list-view.component.scss'
})
export class LotsListViewComponent {

  @Input() dpgf: any = {};
  @Input() totalHt: any = 0;
  @Input() totalTtc: any = 0;
  @Input() nbLines: any = 0;
  

  @Output() openConfirmRemoveLotModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() duplicateLot: EventEmitter<any> = new EventEmitter<any>();
  @Output() openImportLotModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() addLot: EventEmitter<any> = new EventEmitter<any>();
  @Output() openLot: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateLotStatus: EventEmitter<any> = new EventEmitter<any>();


  public lots: any = new Array(20);

  public lotStatusList: any = [
    { label: 'Terminé', value: 'termine', cssClass: 'termine' },
    { label: 'En cours', value: 'en-cours', cssClass: 'en-cours' },
    { label: 'Reprendre', value: 'reprendre', cssClass: 'reprendre' },
    // { label: 'Signé', value: 'signe', cssClass: 'signe' } // can set signé manually
  ];
  public statusLabelMapper: any = {
    'termine': 'Terminé',
    'en-cours': 'En cours',
    'reprendre': 'Reprendre' 
  }


  public emitUpdateLotStatus(lotId, status){
    this.updateLotStatus.emit({lotId, status});
  }
  public emitDuplicateLot(lot){
    this.duplicateLot.emit(lot);
  }
  public emitOpenConfirmRemoveLotModal(lotId, lotTitle){
    this.openConfirmRemoveLotModal.emit({lotId, lotTitle});
  }
  public emitOpenImportLotModal(){
    this.openImportLotModal.emit();
  }
  public emitAddLot(){
    this.addLot.emit();
  }
  public emitOpenLot(lotId){
    this.openLot.emit(lotId);
  }

}
