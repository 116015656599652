import {Component, NgModule, EventEmitter, OnInit, Input,Output, HostListener, ViewChild,
  ElementRef, ChangeDetectorRef, ChangeDetectionStrategy, ViewChildren, QueryList, SimpleChanges }
  from '@angular/core';
import { Subscription } from 'rxjs';
import { DpgfPostesService ,AuthService} from 'src/app/_services';
import { Subject } from "rxjs";
import { Store , select} from '@ngrx/store';
import * as fromSelectors from 'src/app/dpgf/dce-edition/_store';
import { LinebreakPipe } from 'src/app/_pipe/linebreak.pipe';
import { HighLightWordsPipe } from 'src/app/_pipe/highlightWords.pipe';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';


declare var $;
@Component({
  standalone:true,
  selector: 'app-input-dpgf',
  imports:[
    DpgfModule, 
    LinebreakPipe,
    HighLightWordsPipe,
    NgSharedModule],
  templateUrl: './input-dpgf.component.html',
  styleUrls: ['./input-dpgf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputDpgfComponent implements OnInit {
    @Input()textContent;
    @Input()tags;
    @Input()mode;
    @Input()selected;
    @Input()num;
    @Input()selectedSousPostes;
    @Input()disable;
    @Input()oldValue;
    @Input()prefix = '';
    @Input()isNotPrice = true;
    @Output()changeText = new EventEmitter<any>();
    @Output()focused = new EventEmitter<any>();
    @Output()focusedOut = new EventEmitter<any>();

    @ViewChildren("elasticResult") elasticResult: QueryList<ElementRef>;
    public projectLicence;
    public queryStr ='';
    public textContentCopy ='';
    public lastRequestTime = 0 ;
    public results:any = [];
    public currentFocus:number = -1;
    public modify:boolean = false;
    public searchTerm$ = new Subject<string>();
    public isSugSelected: boolean = false;
    public collabs = (JSON.parse(sessionStorage.getItem('sharedWith')) || {sharedWith:[]});
    public userType:String ;
    private subscriptions: Array<Subscription> = [];
    constructor(private DpgfPostesService: DpgfPostesService,
                private AuthService: AuthService,
                private elRef: ElementRef,
                private store: Store,
                private cd: ChangeDetectorRef) {
    // dont know why not for also pro
  }
  ngOnInit() {
    this.store.pipe(select(fromSelectors.getAny , {name : "projectLicence"} )).subscribe((data)=>{
      this.projectLicence=data;
    })
    this.store.pipe(select(fromSelectors.getAny, {name : "mode"})).subscribe(val=>this.userType=val)
    if(this.userType=='archi')
      this.DpgfPostesService.searchElasticSearchAllPosts(this.searchTerm$,(this.AuthService.licence == "premium" || this.AuthService.licence == "premium-annuel" || this.AuthService.licence == "base" || this.AuthService.licence == "plus" || this.AuthService.licence == "plus-trim" || this.AuthService.licence == "plus-annuel"||this.AuthService.licence == "custom" || this.AuthService.licence == "premiumn" || this.AuthService.licence == "premiumn-trim" || this.AuthService.licence == "premiumn-annuel" || this.AuthService.licence == "max" || this.AuthService.licence == "max-trim" || this.AuthService.licence == "max-annuel"),this.collabs['sharedWith'])
    .subscribe(res => {
      this.results = res.cstb;
      this.cd.markForCheck();
    });
    }
    public onKeydownOnInput(event){
      if(this.modify){
        switch(event.which) {
          case 40:
            this.currentFocus += 1;
          break;
          case 38:
            this.currentFocus -= 1;
          break;
          case 27:
            this.modify = false;
            this.results = [];
          break;
          case 13:
          break;
        }
      }
    }
    public searchPoste() {
      this.results=[];
      let newResult = [];
      let tags = [];
      this.currentFocus = -1;
      let looked = (this.textContent||"").replace(/\s+$/,"");
      tags = (this.tags||"").toLowerCase().replace(/[êéè]/gi, 'e').replace(/[ç]/gi, 'c').replace(/[âà]/gi, 'a').replace(/[^\w\s]/gi, '').replace(/[\s]/gi, '_')
      if(looked.split(" ").length < 5){
        this.subscriptions.push(
          this.DpgfPostesService.suggestPoste(looked,[tags].join(","))
          .subscribe( result => {
            result.forEach(val=>{
              newResult.push({sugg : val})
            })
            this.results = newResult;
            this.cd.markForCheck();
          })
        )
      }

    }
    public capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    public manageType(word) {
      this.queryStr = word.target.innerHTML;
      // this.searchElasticPosts(word.target.textContent);
    }
    public choose(res,withCctp){
      let licence = this.AuthService.licence;

      if(["base", "plus","plus-trim", "plus-annuel"].includes(licence) && res.type=='poste' && this.projectLicence!='custom' ){
        $('#upgrade-to-integral-modal').modal('show');
      }else{        
        this.results = [];
        this.isSugSelected=true;
        let word = {word:res.sugg}
        if(withCctp && res.cctp)word["cctp"] = res.cctp
        if(res.gid)word["gid"] = res.gid
        if(res.type=="userpost") word["postType"]='archiDb';
        this.modify = false;
        this.focusedOut.emit(word)
      }
    }
    public focusedOn(){
      this.focused.emit({num:this.num || 0})
    }
    public handleClick(event){
      event.preventDefault();
    }
    public focusedOnOut(){
      if(this.isSugSelected) return;
      if(!this.results.length){ /// if not clicked on the suggestion list
        this.modify = false;
        this.focusedOut.emit({word:this.queryStr.replace(new RegExp("<br>", 'g'), "\n").replace(/&lt;/g,"<").replace(/&gt;/g,">").replace(new RegExp('<[^>]*>', 'g'), '')})
      }else{
        this.modify = false;
        this.focusedOut.emit({word:this.queryStr.replace(new RegExp("<br>", 'g'), "\n").replace(/&lt;/g,"<").replace(/&gt;/g,">").replace(new RegExp('<[^>]*>', 'g'), '')})
      }
    }
    public editable(){
      if(!this.disable){
        this.modify =true;
        this.queryStr = this.textContent;
        this.textContentCopy = (this.textContent||"").replace(new RegExp("\n", 'g'), "<br>");
        this.cd.markForCheck();
        setTimeout(() => {
          let el = this.elRef.nativeElement.getElementsByClassName("edit-span")[0];
          this.placeCaretAtEnd(el)
        }, 100)
        this.cd.detectChanges();
      }
    }
    public placeCaretAtEnd(el) {
        el.focus();
        if (typeof window.getSelection != "undefined"
                && typeof document.createRange != "undefined") {
            var range = document.createRange();
            range.selectNodeContents(el);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }
    /// ElasticSearch Searching
    public searchElasticPosts(searchQuery){
        // if(this.AuthService.licence == "premium" || this.AuthService.licence == "premium-annuel" || this.AuthService.licence == "base"){
          this.subscriptions.push(
            this.DpgfPostesService.searchElasticSearchPostsCSTB(searchQuery)
            .subscribe(res => {
              this.results=[];
              res.data.cstb.forEach((elem) => {
                this.results.push({sugg: elem.title, gid: elem.gid});
              });
              this.cd.markForCheck();
            })
          );
          // dont know why this
        // }
        // else{
        //   this.subscriptions.push(
        //     this.DpgfPostesService.searchElasticSearchPosts({query: searchQuery})
        //     .subscribe(res => {
        //       this.results=[];
        //       res.data.forEach((elem) => {
        //         this.results.push({sugg: elem._source.title, cctp: elem._source.cahierDesCharges});
        //       });
        //       this.cd.markForCheck();
        //     })
        //   );
        // }
    }

    public ngOnDestroy(): void {
          this.subscriptions.forEach((subscription: Subscription) => {
              subscription.unsubscribe();
          });
    }

}
