export class UnitClass{
   public all = {
    "Préparation chantier - Travaux préliminaires": {
      "unite": "ens"
    },
    "Démolition - Dépose": {
      "unite": "ens"
    },
    "Désamiantage": {
      "unite": "hr"
    },
    "Terrassement - Soutènements - Infrastructures": {
      "unite": "m2"
    },
    "Terrassement": {
      "unite": "m2"
    },
    "Soutènements - Infrastructures": {
      "unite": "m2"
    },
    "Maçonnerie - Gros-œuvre": {
      "unite": "m2"
    },
    "Ossature bois/métallique": {
      "unite": "ml"
    },
    "Ossature bois": {
      "unite": "ml"
    },
    "Ossature métallique": {
      "unite": "ml"
    },
    "Charpente": {
      "unite": "ml"
    },
    "Charpente bois": {
      "unite": "ml"
    },
    "Charpente métallique": {
      "unite": "ml"
    },
    "Couverture / Etanchéité": {
      "unite": "m2"
    },
    "Couverture - Zinguerie": {
      "unite": "m2"
    },
    "Etanchéité": {
      "unite": "m2"
    },
    "Façades": {
      "unite": "m2"
    },
    "Revêtement / Peinture Extérieure": {
      "unite": "m2"
    },
    "Bardage": {
      "unite": ""
    },
    "Isolation par l'extérieur": {
      "unite": "m2"
    },
    "Ravalement": {
      "unite": "m2"
    },
    "Menuiserie Extérieure": {
      "unite": "ens"
    },
    "Menuiserie Extérieure Alu": {
      "unite": "ens"
    },
    "Menuiserie Extérieure Metalique": {
      "unite": "ens"
    },
    "Menuiserie Extérieure Bois": {
      "unite": "ens"
    },
    "Menuiserie Extérieure PVC": {
      "unite": "ens"
    },
    "Stores - Fermetures - Occultations": {
      "unite": "ens"
    },
    "Serrurerie - Métallerie": {
      "unite": "ens"
    },
    "Serrurerie": {
      "unite": "ens"
    },
    "Portes de Garage": {
      "unite": "ens"
    },
    "Miroiterie - Vitrerie": {
      "unite": "ens"
    },
    "Cloisons - Doublages - Faux-plafonds": {
      "unite": "ml"
    },
    "Plâtrerie": {
      "unite": "ml"
    },
    "Isolation": {
      "unite": "ml"
    },
    "Menuiserie Intérieure - Agencement": {
      "unite": "ens"
    },
    "Menuiserie Intérieure": {
      "unite": "ens"
    },
    "Agencement - Ebenisterie": {
      "unite": "ens"
    },
    "Revêtement de sol": {
      "unite": "m2"
    },
    "Revêtement de sol dur": {
      "unite": "m2"
    },
    "Revêtement de sol souple": {
      "unite": "m2"
    },
    "Parquet": {
      "unite": "m2"
    },
    "Carrelage - Faience": {
      "unite": "m2"
    },
    "Peinture - Revêtements Muraux - Nettoyage": {
      "unite": "m2"
    },
    "Peinture": {
      "unite": "m2"
    },
    "Revêtements Muraux": {
      "unite": "m2"
    },
    "Nettoyage": {
      "unite": "hr"
    },
    "Signalétique": {
      "unite": "ens"
    },
    "Mobilier": {
      "unite": "ens"
    },
    "Electricité": {
      "unite": "ens"
    },
    "Electricité - Courant Fort": {
      "unite": "ens"
    },
    "Courant faible - Domotique": {
      "unite": "ens"
    },
    "Alarmes - Surveillance": {
      "unite": "ens"
    },
    "Sécurité incendie": {
      "unite": "ens"
    },
    "Plomberie - Sanitaire": {
      "unite": "ens"
    },
    "Chauffage - Ventilation - Climatisation (CVC)": {
      "unite": "ens"
    },
    "Chauffage": {
      "unite": "ens"
    },
    "Chauffage Electrique": {
      "unite": "ens"
    },
    "Ventilation": {
      "unite": "ens"
    },
    "Climatisation": {
      "unite": "ens"
    },
    "Gaz": {
      "unite": "ens"
    },
    "Énergies Renouvelables": {
      "unite": "ens"
    },
    "Ascenseurs": {
      "unite": "ens"
    },
    "Equipements techniques": {
      "unite": "ens"
    },
    "Voirie et Réseaux Divers": {
      "unite": "ens"
    },
    "Maçonnerie": {
      "unite": "ens"
    },
    "Voiries": {
      "unite": "ens"
    },
    "Réseaux": {
      "unite": "ens"
    },
    "Clotures": {
      "unite": "ens"
    },
    "Mobilier Urbain": {
      "unite": "ens"
    },
    "Assainissement - Récupération/Traitement des eaux": {
      "unite": "ens"
    },
    "Aménagements extérieurs - Espaces verts": {
      "unite": "ens"
    },
    "Piscine": {
      "unite": "ens"
    }
  }
 constructor(){}

}
