<div class="main-sidebar-wrapper" [ngClass]="{'opened': consulteSidebarOpened}">


  <div class="open-sidebar-section pointer" (click)="emitToggleSidebar()">
    <img src="assets/little-aglo-booster.svg"  class="litle-logo-booster" alt="">
    <img src="assets/chevron-left.svg" class="arrow-img">
    <!-- Keep this empty div to help separate the icons -->
    <div class=""></div> 
  </div>

  <div class="main-content-section">
    <div class="upper-section flex space-between">
      <img src="assets/aglo-booster-logo.svg" class="main-logo">
      <img class="cursor close-img" src="assets/close.svg" (click)="emitToggleSidebar()">
    </div>
    <div class="main-content-section flex-middle">

      <div class="text-wrapper text-center">
        <p class="title">Faites chiffrer votre projet</p>
        <p class="description">
          Notre équipe vous propose une sélection d’entreprises qualifiées prêtes à chiffrer rapidement.
        </p>
        <button (click)="emitOpenConsulteBoosterModal()">Consulter des entreprises</button>
      </div>

    </div>
  </div>

</div>