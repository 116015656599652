<div id="edit-album-title-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header">
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">        
        
        <div class="album-name-input-wrapper horizontal-items margin-bottom-20">
          <h4 class="ag-heading-h4 ag-weight-500 no-margin margin-right-10  flex-middle">Nom de l’album : </h4>
          <input type="text" [(ngModel)]="albumTitle">
        </div>

        <div class="submit-btn-container text-center margin-top-30 ">
          <button class="ag-btn-bordered-sm margin-right-40" data-dismiss="modal">Annuler</button>
          <button class="ag-btn-primary-sm" (click)="emitSaveAlbumTitle()">Valider</button>
        </div>

      </div>
    </div>
  </div>
</div>
