<div id="confirm-restore-dce-version-modal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Restaurer une version</h4>
      </div>
      <div class="modal-body">
        <p>
          Vous êtes sur le point de restaurer une précédente<br> 
          version du DCE, toutes les modifications apportées<br> 
          depuis cette version seront écrasées.<br>
          Souhaitez-vous continuer ?
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="ag-btn-secondary-sm" data-dismiss="modal">Annuler</button>
        <button type="button" class="ag-btn-primary-sm margin-left-16" (click)="emitConfirmRestoreVersion()">Oui, restaurer la version</button>
      </div>
    </div>
  </div>
</div>