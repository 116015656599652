import { Injectable } from "@angular/core";
import { AuthService } from "src/app/_services";
import { ArchitectService } from "src/app/_services/architect.service";
import { environment } from "src/environments/environment";

@Injectable()
export class IdentifySegement {

  public currentUser= this.authService.currentUser;
  public user;
  public licences={
    plus : "Pro",
		premium : "Intégral",
		base : "Essentiel",
    without:"Aucun",
		"plus-trim" : "Pro-Annuel",
		"plus-annuel" : "Pro-Annuel",
		"premium-annuel" : "Intégral-Annuel",
    "premiumn" : "New Intégral-mensuel",
    "premiumn-trim" : "New Intégral-annuel",
    "premiumn-annuel" : "New Intégral-annuel",
    "max" : "Max-mensuel",
    "max-trim" : "Max-annuel",
    "max-annuel" : "Max-annuel",
  };
  constructor(private architectService:ArchitectService,
              private authService: AuthService){
  }
  ngOnInit(): void {
    
  }
  identifyLoginArchi(){
    if(!environment.onPremise)
      this.architectService.getUserInfoSegment().subscribe(
        data=>{
          let parrainCountUser=0,parrainCountArchi=0
          this.user=data.user;
          data.parrain.forEach(element => {
            if(element.registered && element.parrainUser==this.currentUser.id) parrainCountUser++
            if(element.registered) parrainCountArchi++
          });
          window.analytics.identify(this.user._id, {
            id:this.user._id,
            email: this.user.email,
            firstName: this.user.firstName,
            lastName:this.user.lastName,
            phone:this.user.phone,
            // licence:(this.user.dateEndTrial)?'Essai gratuit':(this.licences[this.user.licence]||'Aucune'),
            type:"architect",
            is_registered:true,
            licence_offered:null,
            last_connection:new Date(),
            number_accounts_sponsored_user:parrainCountUser,
            number_accounts_sponsored_archi:parrainCountArchi,
            company_name:data.archi.name,
            number_of_collaborators:data.archi.users.length-1,
            admin_profile:`admin.bam.archi/#/architect/${this.user.archiID}/project`,
          });
          window.analytics.alias(this.user.email)
        }
    )
  }
  identifySignUp(parrain){
    if(!environment.onPremise)
      this.architectService.getUserInfoSegment().subscribe(
        data=>{
          let parrainCountArchi=0
          this.user=data.user;
          data.parrain.forEach(element => {
            if(element.registered) parrainCountArchi++
          });
          window.analytics.identify(this.user._id, {
            id:this.user._id,
            email: this.user.email,
            firstName: this.user.firstName,
            lastName:this.user.lastName,
            phone:this.user.phone,
            licence:'Essai gratuit',
            type:"architect",
            is_registered:true,
            licence_offered:null,
            sponsored:(parrain) ? true:false,
            last_connection:new Date(),
            number_accounts_sponsored_user:0,
            number_accounts_sponsored_archi:parrainCountArchi,
            company_name:data.archi.name,
            number_of_collaborators:data.archi.users.length-1,
            createdAt:new Date(),
            lifetime_value:0,
            status:'Trial',
            admin_profile:`admin.bam.archi/#/architect/${this.user.archiID}/project`,
          });
          window.analytics.alias(this.user.email)
        }
    )
  }
  identifyLicence(newPrice,plan){
    if(!environment.onPremise)
    window.analytics.identify(this.currentUser.id, {
      churn_date:null,
      churn_reason:null,
      churn_mrr:0,
      MRR:newPrice,
      licence:this.licences[plan],
      status:'Paying',
    })
  }
  identifyChurnedRaison(churn,oldPrice,newPrice){
    if(!environment.onPremise)
    window.analytics.identify(this.currentUser.id, {
      churn_date:new Date(),
      churn_reason:churn,
      churn_mrr:oldPrice,
      MRR:newPrice,
      licence:this.licences['without'],
      status:'Churn', 
    })
  }
  identifyNumCollabs(numCollabs){
    if(!environment.onPremise)
    window.analytics.identify(this.currentUser.id, {
      number_of_collab_invites:numCollabs,
    })
  }
  identifyNumUsers(numCollabs){
    if(!environment.onPremise)
    window.analytics.identify(this.currentUser.id, {
      number_of_collaborators:numCollabs,
    })
  }
  identifyNumRequestsArtisan(numRequests){
    if(!environment.onPremise)
    window.analytics.identify(this.currentUser.id, {
      requests_for_artisans:numRequests.numOfRequests
    })
  }
  identifyNumProjects(numProject){
    if(!environment.onPremise)
    window.analytics.identify(this.currentUser.id, {
      number_of_dpgf:numProject,
    })
  }
}