<div class="" *ngIf="loadingPage">
  <app-spinner></app-spinner>
</div>
<div class="row" *ngIf="!loadingPage && pricingList.length">
  <app-dpgf-result-tabs-navbar></app-dpgf-result-tabs-navbar>
</div>
<!-- No Pricing Image -->
<div style="margin-left: 80px; margin-top:200px;" *ngIf="!loadingPage && !pricingList.length">
  <div class="container" style="margin-top: 70px">
    <div class="empty-box" style="text-align: center;">
      <h3 class="center">Vous n'avez pas encore reçu de réponse à votre consultation !</h3>
      <img src="https://media.giphy.com/media/tXL4FHPSnVJ0A/giphy.gif" alt="" height="400" class="empty-devis">
    </div>
  </div>
</div>
<!-- END No Pricing Image -->

<!-- List of lots  -->
<div id="result-hypothese" *ngIf="!loadingPage && pricingList.length">
    <div class="row" >
        <div id="teams" class="col-sm-8 col-md-offset-4 flex-col">
            <div *ngFor="let team of teams; let i = index"
                class="flex-col-content text-center team-title"
                [@divStage]="stateName(team.title)" [class.activeTeam]="modifiedTeam === team">
                <div>
                    <ng-container *ngIf="!hideTitle || modifiedTeam === team">
                        <div class="team-name">
                            <span class="rounded-span-outline"
                                [style.border]="'1px solid ' + teamColors[i]">
                                {{ team.title | capitalize}}
                                <i (click)="openChangeTeam(team)" class="fa fa-pencil click-it"></i>
                            </span>
                        </div>
                        <div  class="total">{{ team.total | number:'1.0-0':'fr'  | priceUnite:'€' }}
                            HT
                        </div>
                        <div class="team-menu">
                            <span class="delete-btn" (click)="deleteTeam(team.title)">
                                <i class="fa fa-trash"></i>
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="flex-col-content text-center team-title" id="add-container"
              *ngIf="teams.length < 3 && modifiedTeam === null ">
                <div>
                    <span class="add-team" data-toggle="modal" data-target="#addTeamModal">
                        <i class="fa fa-plus"></i>
                    </span>
                </div>
            </div>
            <div [class.invisible]="!modifiedTeam" #teamModifier
                id="team-modifier" [@artisList]="artisListShown ? 'show' : 'hide'">
                <app-team-modifier [lot]="activeLot" [team]="modifiedTeam" [selectedArtisan]="selectArtisan"
                    [pricings]="pricings" [colors]="artisansColorsMap" [dpgfId]="dpgfId" (onPricingAdd)="addPricing($event)"
                    (onPricingRemove)="removePricing($event)" (onSave)="onTeamSave()">
                </app-team-modifier>
            </div>
        </div>
    </div>
    <div id="pricings-container">
        <div *ngFor="let lot of dpgf.data; let i = index" onselectstart="return false">
            <div class="row lot-data" [id]="lot.id" (click)="selectLot(lot.id)">
                <div class="col-sm-4 lot-items row" [attr.title]="lot.title"
                 [class.active]="activeLot === lot.id">
                    <div class="lot-item col-md-9" >
                        <span class="lot-pill">
                            {{ lot.title }}
                        </span>
                    </div>
                    <div class="col-md-3 estimation">
                        <ng-container *ngIf="estimationsPerLot[lot.id] !== 0">
                            {{ estimationsPerLot[lot.id] | number:'1.0-0':'fr'  | priceUnite:'€' }}
                        </ng-container>
                    </div>
                </div>
                <div class="col-sm-8 flex-col">
                    <div *ngFor="let team of teams; let i = index" (click)="onTeamEdit(team)"
                        class="flex-col-content white-col"  [@divStage]="stateName(team.title)"
                        [ngClass]="{hideit: modifiedTeam && modifiedTeam.title !== team.title,
                            active: activeLot === lot.id}">
                        <app-team-member-pricing [pricing]="getTeamLotPricing(i, lot.id)"
                           [lotPricingAvg]="lotPricingAvgs[lot.id]" [colors]="artisansColorsMap" [estimation]="estimationsPerLot[lot.id]"
                            *ngIf="!modifiedTeam || modifiedTeam.title === team.title">
                        </app-team-member-pricing>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div style="height: 400px;"></div>
</div>

<div class="modal fade" id="addTeamModal" role="dialog">
<div class="modal-dialog modal-sm">
    <div class="modal-content">
    <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Ajouter une hypothèse</h4>
    </div>
    <div class="modal-body">
        <form class="form-inline">
            <input #teamName type="text" class="form-control" placeholder="Titre de l'hypothèse">
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" style="margin-bottom: 0px;">Annuler</button>
        <button class="btn btn-default" (click)="addTeam(teamName.value)" data-dismiss="modal" style="margin-bottom: 0px;">Ajouter</button>
    </div>
    </div>
</div>
</div>

<p-dialog header="Renommer l'hypothèse" [(visible)]="renamePopUp" [style]="{width: '400px'}" >
  <label for="float-input">Nom de l'hypothèse</label>
  <input id="float-input" type="text" pInputText [(ngModel)]="selectedTeam._title"/>
  <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="rename()" label="Enregistrer"></button>
  </p-footer>
</p-dialog>
