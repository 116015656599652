/////////////////////////////////////////
/// 1- Project
/////////////////////////////////////////
export const natureGeotechniqueOptions = [
  {value: "1", label: "Limons, argiles limoneuse"},
  {value: "2", label: "Terre végétale sèche"},
  {value: "3", label: "Terre végétale humide"},
  {value: "4", label: "Terre végétale saturée d'eau"},
  {value: "5", label: "Remblai anciens stabilisés"},
  {value: "6", label: "Argile sèche"},
  {value: "7", label: "Argile humide"},
  {value: "8", label: "Argile plastique"},
  {value: "9", label: "Argile sèche sableuse"},
  {value: "10", label: "Argile graveleuse humide"},
  {value: "11", label: "Argile sèche compacte"},
  {value: "12", label: "Sable"},
  {value: "13", label: "Gravier"},
  {value: "14", label: "Marne compacte"},
  {value: "15", label: "Marne très compacte"},
  {value: "16", label: "Craie"},
  {value: "17", label: "Mâchefer"},
  {value: "18", label: "Tourbe"},
  {value: "19", label: "Vase"},
  {value: "20", label: "Roche en formation stratifiée"},
  {value: "21", label: "Roche en formation massive"},
];

export const zoneClimatiqueOptions = [
  { number: '2', value: 'H1A'},
  { number: '1', value: 'H1B'},
  { number: '3', value: 'H1C'},
  { number: '3', value: 'H2A'},
  { number: '3', value: 'H2B'},
  { number: '3', value: 'H2C'},
  { number: '3', value: 'H2D'},
  { number: '3', value: 'H3'},

]
//add these entries to array in back H2B
/////////////////////////////////////////
/// 2- Batiment
/////////////////////////////////////////
export const batimentUsageOptions = [
  {value: "1", label: "Bâtiment à usage d'habitation, maison individuelle ou accolée"},
  {value: "2", label: "Bâtiment à usage d'habitation, logement collectif"},
  {value: "3", label: "Bureau"},
  {value: "4", label: "Enseignement primaire"},
  {value: "5", label: "Enseignement secondaire (partie jour)"},
]

// Structure générale
export const batimentTypeFondation = [
  {value: "0", label: "Autre"},
  {value: "1", label: "Superficielle: semelles filantes"},
  {value: "2", label: "Superficielle: plots"},
  {value: "3", label: "Superficielle: radier"},
  {value: "4", label: "Superficielle: micro-pieux"},
  {value: "5", label: "Profonde: pieux"},
  {value: "6", label: "Profonde: parois"},
]

export const batimentTypeStructurePorteuse = [
  {value: "0", label: "Autre"},
  {value: "1", label: "Maçonnerie"},
  {value: "2", label: "Poteaux/poutres"},
  {value: "3", label: "Voiles porteurs"},
  {value: "4", label: "Ossature"}
]
export const batimentMateriauPrincipalStructure = [
  {value: "0", label: "Autre"},
  {value: "1", label: "Béton "},
  {value: "2", label: "Béton cellulaire"},
  {value: "3", label: "Béton de chanvre "},
  {value: "4", label: "Béton de bois"},
  {value: "5", label: "Béton fibré"},
  {value: "6", label: "Béton haute performance"},
  {value: "7", label: "Terre cuite"},
  {value: "8", label: "Terre crue"},
  {value: "9", label: "Bois massif "},
  {value: "10", label: "Bois massif reconstitué"},
  {value: "11", label: "Acier"},
  {value: "12", label: "Mixte: bois-béton"},
  {value: "13", label: "Mixte: béton-acier"},
  {value: "14", label: "Mixte: bois-acier"},
  {value: "15", label: "Pierre"}
]
export const batimentMateriauPrincipalRemplissageFacades = [
  {value: "0", label: "Autre"},
  {value: "1", label: "béton ordinaire"},
  {value: "2", label: "béton haute performance"},
  {value: "3", label: "bloc de béton manufacturé (parpaing...)"},
  {value: "4", label: "béton cellulaire"},
  {value: "5", label: "béton fibré"},
  {value: "6", label: "béton de chanvre"},
  {value: "7", label: "béton de bois"},
  {value: "8", label: "terre cuite"},
  {value: "9", label: "terre crue"},
  {value: "10", label: "verre"},
  {value: "11", label: "panneaux sandwich"},
  {value: "12", label: "bois massif"},
  {value: "13", label: "bois massif reconstitué"},
  {value: "14", label: "panneaux de particules et de fibres de bois (ossature bois...)"},
  {value: "15", label: "paille"},
  {value: "16", label: "pierre"},
  {value: "17", label: "autre matériau biosourcé"}
]
export const batimentModeIsolationMurs = [
  {value: "0", label: "Autre"},
  {value: "1", label: "Isolation Thermique Répartie (ITR)"},
  {value: "2", label: "Isolation Thermique entre ossature"},
  {value: "3", label: "Isolation Thermique par l’Intérieur (ITI)"},
  {value: "4", label: "Isolation Thermique par l’Extérieur (ITE)"}
]
export const batimentRevetementMursExterieurs = [
  {value: "0", label: "Autre"},
  {value: "1", label: "Enduit simple"},
  {value: "2", label: "Enduit isolant"},
  {value: "3", label: "Peinture"},
  {value: "4", label: "Bardage métallique"},
  {value: "5", label: "Bardage bois"},
  {value: "6", label: "Bardage composite"}
]
export const batimentTypeProtectionMobileMenuiserie = [
  {value: "0", label: "Sans protection mobile"},
  {value: "1", label: "Volet battant"},
  {value: "2", label: "Volet roulant"},
  {value: "3", label: "Store vénitien/enroulable"},
  {value: "4", label: "Brise Soleil Orientable"}
]
export const batimentNatureIsolationParoisVerticalesExterieures = [
  {value: "0", label: "Autre"},
  {value: "1", label: "Polystyrène Expansé (PSE)"},
  {value: "2", label: "Polystyrène Extrudé (XPS)"},
  {value: "3", label: "Polyuréthane (PU)"},
  {value: "4", label: "Laine de roche (LR)"},
  {value: "5", label: "Laine de verre (LV)"},
  {value: "6", label: "Produits réfléchissant"},
  {value: "7", label: "Laine de bois"},
  {value: "8", label: "Laine de lin"},
  {value: "9", label: "Laine de chanvre"},
  {value: "10", label: "Ouate de cellulose"},
  {value: "11", label: "Paille"},
  {value: "12", label: "Laines textiles"},
  {value: "13", label: "Autre matériaux biosourcés", "14":"Mix : Laine minérale / biossourcé"}
]
/// Toiture
export const batimentNatureIsolantToiture = [
  {value: "0", label: "Autre"},
  {value: "1", label: "Laine de roche"},
  {value: "2", label: "Laine de verre"},
  {value: "3", label: "Polystyrène"},
  {value: "4", label: "Polyuréthane"},
  {value: "5", label: "Laine de bois"},
  {value: "6", label: "Laine de lin"},
  {value: "7", label: "Laine de chanvre"},
  {value: "8", label: "Ouate de cellulose"},
  {value: "9", label: "Laines textiles"},
  {value: "10", label: "Autre matériaux biosourcés"},
  {value: "11", label: "Produits réfléchissant"}
];
export const batimentModeIsolationToiture = [
  {value: "0", label: "Autre"},
  {value: "1", label: "Sarking"},
  {value: "2", label: "En combles perdus"},
  {value: "3", label: "Sous rampants"},
  {value: "4", label: "Isolation conventionnelle (toiture-terrasse)"},
  {value: "5", label: "Isolation inversée (toiture-terrasse)"}
];
export const batimentCouvertureToiture = [
  {value: "0", label: "Autre"},
  {value: "1", label: "Tuile"},
  {value: "2", label: "Ardoise"},
  {value: "3", label: "Tôles bac acier"},
  {value: "4", label: "Plancher béton"},
  {value: "5", label: "Bois"},
  {value: "6", label: "Verre"},
  {value: "7", label: "Matériaux biosourcés"}
];
export const batimentTypeToiture = [
  {value: "1", label: "Monopente"},
  {value: "2", label: "2 pans"},
  {value: "3", label: "3 pans et plus"},
  {value: "4", label: "Terrasse non accessible"},
  {value: "5", label: "Terrasse accessible"}
];
/// Plancher
export const batimentPlancherNatureIsolant = [
  {value: "0", label: "Autre"},
  {value: "1", label: "Laine de roche"},
  {value: "2", label: "Laine de verre"},
  {value: "3", label: "Polystyrène"},
  {value: "4", label: "Polyuréthane"},
  {value: "5", label: "Laine de bois"},
  {value: "6", label: "Laine de lin"},
  {value:"7", label: "Laine de chanvre"},
  {value:"8", label: "Ouate de cellulose"},
  {value:"9", label: "Laines textiles"},
  {value:"10", label: "Autre matériaux biosourcés"},
  {value:"11", label: "Produits réfléchissant" }
];
/// Chuffage
export const batimentVecteurEnergiePrincipalCh = [
  {value: "0", label: "Sans"},
  {value: "1", label: "Gaz naturel"},
  {value: "2", label: "GPL butane et propane"},
  {value: "3", label: "Fioul"},
  {value: "4", label: "Charbon"},
  {value: "5", label: "Bois granulés"},
  {value: "6", label: "Bois bûches"},
  {value: "7", label: "Bois plaquettes"},
  {value: "8", label: "Bois granules poêle"},
  {value: "9", label: "Bois buches poêle"},
  {value: "10", label: "Bois plaquettes poêle"},
  {value: "11", label: "Electricité"},
  {value: "12", label: "Réseau"},
  {value: "13", label: "Solaire"}
]
export const batimentGenerateurPrincipalCh = [
  {value: "0", label: "Sans Objet"},
  {value: "100", label: "Chaudiere Standard Gaz"},
  {value: "101", label: "Chaudiere Basse Temperature Gaz"},
  {value: "102", label: "Chaudiere Condensation Gaz"},
  {value: "103", label: "Radiateurs Gaz"},
  {value: "104", label: "Chauffe Eau Gaz"},
  {value: "105", label: "Accumulateur Gaz"},
  {value: "106", label: "Generateur Air Chaud Standard"},
  {value: "107", label: "Generateur Air Chaud Condensation"},
  {value: "108", label: "Tube Radiant Gaz"},
  {value: "109", label: "Panneau Radiant Au Gaz"},
  {value: "200", label: "Chaudiere Standard Fioul"},
  {value: "201", label: "Chaudiere Condensation Fioul"},
  {value: "202", label: "Chaudiere Condensation"},
  {value: "203", label: "Chaudiere Basse Temperature Fioul"},
  {value: "400", label: "Chaudiere au Bois"},
  {value: "403", label: "Poele a bois"},
  {value: "404", label: "Insert bois"},
  {value: "500", label: "Generateur Effet Joule Direct"},
  {value: "501", label: "Generateur ECS Effet Joule Direct"},
  {value: "502", label: "Ballon Effet Joule"},
  {value: "503", label: "PAC Compression Electrique"},
  {value: "504", label: "PAC A Absorption"},
  {value: "505", label: "PAC Moteur Gaz"},
  {value: "506", label: "Generateur DRV Moteur Gaz"},
  {value: "507", label: "Pac Sur Boucle Eau"},
  {value: "508", label: "Thermofrigopompe"},
  {value: "509", label: "Generateur DRV"},
  {value: "510", label: "PAC Electrique NonReversible"},
  {value: "511", label: "PAC Electrique Reversible"},
  {value: "512", label: "PAC Electrique DoubleService"},
  {value: "513", label: "Ballon Base PAC Electrique TripleService"},
  {value: "513", label: "Ballon Base PAC Electrique TripleService"},
  {value: "600", label: "Reseau De Chaleur"},
  {value: "601", label: "Reseau De Froid"},
  {value: "700", label: "Systeme cogeneration gaz"},
  {value: "701", label: "Systeme cogeneration fioul"},
  {value: "702", label: "Systeme cogeneration biomasse"},
  {value: "800", label: "Production Stockage"},
  {value: "900", label: "Pile Combustible"},
  {value: "1502", label: "Ballon Base Effet Joule"},
  {value: "1503", label: "Ballon Base PAC Absorption Electrique"},
  {value: "1504", label: "Ballon Base PAC Absorption Gaz"},
  {value: "1505", label: "Ballon Base PAC Moteur Gaz Avec ECS NonReversible"},
  {value: "1506", label: "Ballon Base PAC Moteur Gaz Avec ECS Reversible"},
  {value: "1507", label: "Ballon Base Sur Boucle Solaire"},
  {value: "1508", label: "Ballon Base PAC Absorption Gaz DoubleService"},
  {value: "1511", label: "Ballon Base PAC Electrique"},
  {value: "1512", label: "Ballon Base PAC Electrique DoubleService"},
  {value: "1600", label: "Ballon Base Reseau fourniture"},
  {value: "1700", label: "Ballon base micro cogeneration"},
  {value: "1800", label: "Ballon base"},
  {value: "1900", label: "Ballon base pile combustible"}
]
/// Refroisissement
export const batimentVecteurEnergiePrincipalFr = [
  {value: "0", label: "Sans"},
  {value: "1", label: "Gaz naturel"},
  {value: "2", label: "GPL butane et propane"},
  {value: "3", label: "Fioul"},
  {value: "4", label: "Charbon"},
  {value: "5", label: "Bois granulés"},
  {value: "6", label: "Bois bûches"},
  {value: "7", label: "Bois plaquettes"},
  {value: "8", label: "Bois granules poêle"},
  {value: "9", label: "Bois buches poêle"},
  {value: "10", label: "Bois plaquettes poêle"},
  {value: "11", label: "Electricité"},
  {value: "12", label: "Réseau"},
  {value: "13", label: "Solaire"}
]
export const batimentGenerateurPrincipalFr = [
  {value: "0", label: "Sans Objet"},
  {value: "100", label: "Chaudiere Standard Gaz"},
  {value: "101", label: "Chaudiere Basse Temperature Gaz"},
  {value: "102", label: "Chaudiere Condensation Gaz"},
  {value: "103", label: "Radiateurs Gaz"},
  {value: "104", label: "Chauffe Eau Gaz"},
  {value: "105", label: "Accumulateur Gaz"},
  {value: "106", label: "Generateur Air Chaud Standard"},
  {value: "107", label: "Generateur Air Chaud Condensation"},
  {value: "108", label: "Tube Radiant Gaz"},
  {value: "109", label: "Panneau Radiant Au Gaz"},
  {value: "200", label: "Chaudiere Standard Fioul"},
  {value: "201", label: "Chaudiere Condensation Fioul"},
  {value: "202", label: "Chaudiere Condensation"},
  {value: "203", label: "Chaudiere Basse Temperature Fioul"},
  {value: "400", label: "Chaudiere au Bois"},
  {value: "403", label: "Poele a bois"},
  {value: "404", label: "Insert bois"},
  {value: "500", label: "Generateur Effet Joule Direct"},
  {value: "501", label: "Generateur ECS Effet Joule Direct"},
  {value: "502", label: "Ballon Effet Joule"},
  {value: "503", label: "PAC Compression Electrique"},
  {value: "504", label: "PAC A Absorption"},
  {value: "505", label: "PAC Moteur Gaz"},
  {value: "506", label: "Generateur DRV Moteur Gaz"},
  {value: "507", label: "Pac Sur Boucle Eau"},
  {value: "508", label: "Thermofrigopompe"},
  {value: "509", label: "Generateur DRV"},
  {value: "510", label: "PAC Electrique NonReversible"},
  {value: "511", label: "PAC Electrique Reversible"},
  {value: "512", label: "PAC Electrique DoubleService"},
  {value: "513", label: "Ballon Base PAC Electrique TripleService"},
  {value: "513", label: "Ballon Base PAC Electrique TripleService"},
  {value: "600", label: "Reseau De Chaleur"},
  {value: "601", label: "Reseau De Froid"},
  {value: "700", label: "Systeme cogeneration gaz"},
  {value: "701", label: "Systeme cogeneration fioul"},
  {value: "702", label: "Systeme cogeneration biomasse"},
  {value: "703", label: "Systeme cogeneration"},
  {value: "800", label: "Production Stockage"},
  {value: "900", label: "Pile Combustible"},
  {value: "1502", label: "Ballon Base Effet Joule"},
  {value: "1503", label: "Ballon Base PAC Absorption Electrique"},
  {value: "1504", label: "Ballon Base PAC Absorption Gaz"},
  {value: "1505", label: "Ballon Base PAC Moteur Gaz Avec ECS NonReversible"},
  {value: "1506", label: "Ballon Base PAC Moteur Gaz Avec ECS Reversible"},
  {value: "1507", label: "Ballon Base Sur Boucle Solaire"},
  {value: "1508", label: "Ballon Base PAC Absorption Gaz DoubleService"},
  {value: "1511", label: "Ballon Base PAC Electrique"},
  {value: "1512", label: "Ballon Base PAC Electrique DoubleService"},
  {value: "1600", label: "Ballon Base Reseau fourniture"},
  {value: "1700", label: "Ballon base micro cogeneration"},
  {value: "1800", label: "Ballon base"},
  {value: "1900", label: "Ballon base pile combustible"}
]

// ECS
export const batimentVecteurEnergiePrincipalECS = [
  {value: "0", label: "Sans"},
  {value: "1", label: "G, labela z naturel"},
  {value: "2", label: "G, labelP L butane et propane"},
  {value: "3", label: "F, labeli oul"},
  {value: "4", label: "C, labelh arbon"},
  {value: "5", label: "B, labelo is granulés"},
  {value: "6", label: "B, labelo is bûches"},
  {value: "7", label: "B, labelo is plaquettes"},
  {value: "8", label: "B, labelo is granules poêle"},
  {value: "9", label: "B, labelo is buches poêle"},
  {value: "10", label: "Bois plaquettes poêle"},
  {value: "11", label: "Electricité"},
  {value: "12", label: "Réseau"},
  {value: "13", label: "Solaire"}
];
export const batimentGenerateurPrincipalECS = [
  {value: "0", label: "Sans Objet"},
  {value: "100", label: "Chaudiere Standard Gaz"},
  {value: "101", label: "Chaudiere Basse Temperature Gaz"},
  {value: "102", label: "Chaudiere Condensation Gaz"},
  {value: "103", label: "Radiateurs Gaz"},
  {value: "104", label: "Chauffe Eau Gaz"},
  {value: "105", label: "Accumulateur Gaz"},
  {value: "106", label: "Generateur Air Chaud Standard"},
  {value: "107", label: "Generateur Air Chaud Condensation"},
  {value: "108", label: "Tube Radiant Gaz"},
  {value: "109", label: "Panneau Radiant Au Gaz"},
  {value: "200", label: "Chaudiere Standard Fioul"},
  {value: "201", label: "Chaudiere Condensation Fioul"},
  {value: "202", label: "Chaudiere Condensation"},
  {value: "203", label: "Chaudiere Basse Temperature Fioul"},
  {value: "400", label: "Chaudiere au Bois"},
  {value: "403", label: "Poele a bois"},
  {value: "404", label: "Insert bois"},
  {value: "500", label: "Generateur Effet Joule Direct"},
  {value: "501", label: "Generateur ECS Effet Joule Direct"},
  {value: "502", label: "Ballon Effet Joule"},
  {value: "503", label: "PAC Compression Electrique"},
  {value: "504", label: "PAC A Absorption"},
  {value: "505", label: "PAC Moteur Gaz"},
  {value: "506", label: "Generateur DRV Moteur Gaz"},
  {value: "507", label: "Pac Sur Boucle Eau"},
  {value: "508", label: "Thermofrigopompe"},
  {value: "509", label: "Generateur DRV"},
  {value: "510", label: "PAC Electrique NonReversible"},
  {value: "511", label: "PAC Electrique Reversible"},
  {value: "512", label: "PAC Electrique DoubleService"},
  {value: "513", label: "Ballon Base PAC Electrique TripleService"},
  {value: "513", label: "Ballon Base PAC Electrique TripleService"},
  {value: "600", label: "Reseau De Chaleur"},
  {value: "601", label: "Reseau De Froid"},
  {value: "700", label: "Systeme cogeneration gaz"},
  {value: "701", label: "Systeme cogeneration fioul"},
  {value: "702", label: "Systeme cogeneration biomasse"},
  {value: "703", label: "Systeme cogeneration"},
  {value: "800", label: "Production Stockage"},
  {value: "900", label: "Pile Combustible"},
  {value: "1502", label: "Ballon Base Effet Joule"},
  {value: "1503", label: "Ballon Base PAC Absorption Electrique"},
  {value: "1504", label: "Ballon Base PAC Absorption Gaz"},
  {value: "1505", label: "Ballon Base PAC Moteur Gaz Avec ECS NonReversible"},
  {value: "1506", label: "Ballon Base PAC Moteur Gaz Avec ECS Reversible"},
  {value: "1507", label: "Ballon Base Sur Boucle Solaire"},
  {value: "1508", label: "Ballon Base PAC Absorption Gaz DoubleService"},
  {value: "1511", label: "Ballon Base PAC Electrique"},
  {value: "1512", label: "Ballon Base PAC Electrique DoubleService"},
  {value: "1600", label: "Ballon Base Reseau fourniture"},
  {value: "1700", label: "Ballon base micro cogeneration"},
  {value: "1800", label: "Ballon base"},
  {value: "1900", label: "Ballon base pile combustible"}
];

// Electricité
export const batimentTypeEclairage = [
  {value: "0", label: "Autre"},
  {value: "1", label: "Halogène"},
  {value: "2", label: "Fluorocompacte"},
  {value: "3", label: "Fluorescente "},
  {value: "4", label: "Halogénures métalliques "},
  {value: "5", label: "Sodium haute pression "},
  {value: "6", label: "LED "}
];

/////////////////////////////////////////
/// 3- Zone
/////////////////////////////////////////
export const zoneDonneesGenerales = [
  {value: "1", label: "Bâtiment à usage d'habitation - maison individuelle ou accolée"},
  {value: "2", label: "Bâtiment à usage d'habitation - logement collectif"},
  {value: "3", label: "Bureaux"},
  {value: "4", label: "Enseignement primaire"},
  {value: "5", label: "Enseignement secondaire (partie jour)"}
];
