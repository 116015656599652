import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';

import mainRegionsList from './regionsList-list';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone: true,
    selector: 'app-booster-profile-precision-form',
    imports:[
      NgSharedModule 
    ],
    templateUrl: './booster-profile-precision-form.component.html',
    styleUrls: ['./booster-profile-precision-form.component.scss'],
})
export class BoosterProfilePrecisionFormComponent implements OnInit, OnChanges {

  @Input() artisanData:any = {};
  @Output() saveData: EventEmitter<any> = new EventEmitter<any>();
  @Output() formIsValid: EventEmitter<any> = new EventEmitter<any>();

  public domaineSpe:string = ""; // domaine de spécialisation
  public regionSelectedDepartementsNumber: any = {};
  public selectedDepartements: any = {};

  public showSuggestionsList: boolean = false;
  public regionsList: any = [];

  constructor() { }

  ngOnInit(): void {
    this.regionsList = mainRegionsList;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.artisanData && changes.artisanData?.currentValue?.workAreaMain.length){
      let { domaineSpe, workAreaMain } = changes.artisanData.currentValue;
      
      this.domaineSpe = domaineSpe;

      /// wail till the list is Loaded
      setTimeout(() => {
        this.setAlreadySelectedData(workAreaMain);
      }, 500);

    }
  }
  private setAlreadySelectedData(workAreaMain){
    let selectedIds = workAreaMain.map(item => item.id);

    // get the region id from departement id, then select departement
    for(let i = 0; i < this.regionsList.length; i++){
      let currentRegion = this.regionsList[i];
      for (let j = 0; j < currentRegion["departements"].length; j++) {
        let currentDepartement = currentRegion["departements"][j];
        if(selectedIds.includes(currentDepartement.id)){
          this.selectDepartement(currentRegion.value, currentDepartement.id, false);
        }
      }
    }

    // Emit if form is valid or not
    let isFormValid = this.checkFormIsValid();
    this.emitFormIsValid(isFormValid);
  }

  public toggleShowSuggestionsList(){
    this.showSuggestionsList = !this.showSuggestionsList;
  }
  public checkRegionDepartements(regionValue, regionDepartements){
    let isChecked = this.regionSelectedDepartementsNumber[regionValue] == regionDepartements.length;

    if(!isChecked){ // Check All Departements
      regionDepartements.forEach(item => this.selectedDepartements[item.id] = true);

      this.regionSelectedDepartementsNumber[regionValue] = regionDepartements.length;
    }else{ // UnCheck all Departements
      
      for(let i = 0; i < regionDepartements.length; i++){
        let currentDepartement = regionDepartements[i];

        if(this.selectedDepartements[currentDepartement.id]){
          delete this.selectedDepartements[currentDepartement.id];
        }
      }
      this.regionSelectedDepartementsNumber[regionValue] = 0;
    }

    // save after every check
    this.emitSaveData();
  }
  public selectDepartement(regionValue, id, emitSave=true){

    let departementAlreadySelected = this.selectedDepartements[id];

    if(departementAlreadySelected){ // is Exists delete
      
      delete this.selectedDepartements[id];
      // add count
      this.regionSelectedDepartementsNumber[regionValue]--;
      if(this.regionSelectedDepartementsNumber[regionValue] == 0){
        delete this.regionSelectedDepartementsNumber[regionValue];
      }

    }else{ // Add
      this.selectedDepartements[id] = true;
      
      if(this.regionSelectedDepartementsNumber[regionValue]){
        this.regionSelectedDepartementsNumber[regionValue]++;
      }else{
        this.regionSelectedDepartementsNumber[regionValue] = 1;
      }
    }

    // save after every check
    if(emitSave){
      this.emitSaveData();
    }
  }

  private getDepartementsData(departementsIdsArray){
    let departements = [];

    for(let i = 0; i < this.regionsList.length; i++){
      let currentRegion = this.regionsList[i];
      for (let j = 0; j < currentRegion["departements"].length; j++) {
        let currentDepartement = currentRegion["departements"][j];
        if(departementsIdsArray.includes(currentDepartement.id)){
          departements.push({id: currentDepartement.id, text: currentDepartement.text});
        } 
      }
    }

    return departements;
  }

  public emitSaveData(){
    let workAreaMainData = this.getDepartementsData(Object.keys(this.selectedDepartements));

    let body = {
      domaineSpe: this.domaineSpe,
      workAreaMain: workAreaMainData
    };

    this.saveData.emit(body);

    // Emit if form is valid or not
    let isFormValid = this.checkFormIsValid();
    this.emitFormIsValid(isFormValid);
  }
  private emitFormIsValid(isValid){
    this.formIsValid.emit(isValid);
  }

  private checkFormIsValid(){
    let isValid = Object.keys(this.selectedDepartements).length ? true : false;
    
    return isValid;
  }



}
