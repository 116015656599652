import { Component, OnInit } from '@angular/core';
import { ArchitectService, AuthService } from '../../../_services/index';
import { Subscription } from 'rxjs';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { ModalUpdateUserComponent } from "../modal-update-user/modal-update-user.component";
import { ModalCreateUserComponent } from "../modal-create-user/modal-create-user.component";
import { ModalConfirmationToDeleteUserComponent } from "../modal-confirmation-to-delete-user/modal-confirmation-to-delete-user.component";
import { ModalResetPasswordComponent } from "../modal-reset-password/modal-reset-password.component";
import { HeaderComponent } from "../../../all/header/header.component";
import { Title } from '@angular/platform-browser';
import { NotificationsService } from 'angular2-notifications';

declare var $:any;
@Component({
    selector: 'app-admin-users',
    standalone: true,
    templateUrl: './admin-users.component.html',
    styleUrl: './admin-users.component.scss',
    imports: [NgSharedModule, ModalUpdateUserComponent, ModalCreateUserComponent, ModalConfirmationToDeleteUserComponent, ModalResetPasswordComponent, HeaderComponent]
})
export class AdminUsersComponent {
  public loadingPage=false;dataArchitect;cinquanteUsers;searchText='';user;
  public page:number = 0;
  public perPage:number = 50;
  public totalPages:number = 1;userEmail;loading:boolean;
  public numbers:number[] = [1];
  private subscriptions: Array<Subscription> = [];resetPasswordLink = "";isFirstStep=true;

  constructor(private architectService: ArchitectService,private titleService: Title,private authService: AuthService,private _notificationService: NotificationsService) { }


  ngOnInit(): void {
    this.titleService.setTitle('Utilisateurs');
    this.getUsers();
  }
  changePage(newPage){
    this.page = newPage;
    this.getUsers()
  }
  getUsers(){
    this.loadingPage = false;
    this.subscriptions.push(
    this.architectService.getAllUsersProfile(this.page,this.perPage,this.searchText)
        .subscribe(
            resp => {
                this.cinquanteUsers = resp.data.users.map(user=>{
                  resp.data.departementNames.map(departement=>{
                    if(user.archiID==departement._id){
                      user['departementName'] = departement.name;
                    }
                  }
                  )
                  return user;
                });
                this.totalPages = Math.ceil(resp.metadata.count / this.perPage);
                this.numbers = Array(this.totalPages).fill(1).map((x,i)=>i+1);
                this.loadingPage = true;
            },
            error => {
                console.log("error",error)
            })
    )
  }
  public sendDataUser(item){
    this.isFirstStep = true
    this.user = item;
  }
  public deleteUser(item){
    this.loadingPage = false;
    this.subscriptions.push(
    this.architectService.deleteUserAndRemoveItFromDepartement(item._id)
        .subscribe(
            resp => {
              $('#delete-user').modal('hide');
              this.getUsers();
            },
            error => {
                console.log("error",error)
            })
    )
  }
  public addNewUser(newUser){
    this.cinquanteUsers.unshift(newUser);
  }
  public showModalForReset(event){
    this.userEmail = event;
    // $("#recovery-password-link").modal('show');
  }
  public forgetPassword(){
    this.loading = true;
    this.authService.forgetPassword(this.user.email)
        .subscribe(
            data => {
              this._notificationService.success("Succés","Le mail a été envoyé", {timeOut: 3000});
                console.log(`Lien pour rénitialisation du mot de passe pour l'utilisateur ${this.user.firstName} ${this.user.lastName}`,data.link)
                this.user['linkToCopy'] = data.link;
                this.resetPasswordLink = data.link;
                this.isFirstStep = false;
                this.loading = false;
            },
            error => {
              this._notificationService.error("Erreur","Le mail n'a pas été envoyé", {timeOut: 3000});
              console.log(error)
            })
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }
}
