import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  selector: 'app-consultations-types-list',
  standalone: true,
  imports: [
    NgSharedModule
  ],
  templateUrl: './consultations-types-list.component.html',
  styleUrl: './consultations-types-list.component.scss'
})
export class ConsultationsTypesListComponent {

  @Input() consultationsTypesListCount: [];
  @Output() typeSelected: EventEmitter<any> = new EventEmitter();

  public consultationTypes: any = [
    {label : 'Offre remise', value: 'offre_remise', color: 'bg-green', enterprisesCount: 0},
    {label : 'En cours', value: 'en_cours', color: 'bg-yellow', enterprisesCount: 0},
    {label : 'En attente', value: 'en_attente', color: 'bg-light-blue', enterprisesCount: 0},
    {label : 'À consulter', value: 'a_consulter', color: 'bg-blue', enterprisesCount: 0},
    {label : 'Ne chiffre pas', value: 'ne_chiffre_pas', color: 'bg-grey', enterprisesCount: 0},    
  ];
  public selectedTypes:any = {}
  public activeTypes: string[] = ['offre_remise','en_cours','en_attente','a_consulter',  'ne_chiffre_pas']


  ngOnChanges(changes: SimpleChanges){
    if(changes && changes["consultationsTypesListCount"]){
      this.setConsultationTypesCounter();
    }
  }

  private setConsultationTypesCounter(){
    this.consultationsTypesListCount.forEach((item) => {
      const foundConsultationType = this.consultationTypes.find(consultation => consultation["value"] == item["value"]);
      if(foundConsultationType){
        foundConsultationType["enterprisesCount"] = item["count"];
      }
    });
  }
  public toggleType(type){
    let typeIsSelected = !!this.selectedTypes[type];
    this.selectedTypes[type] = !typeIsSelected;

    if(this.activeTypes.includes(type)){
      let itemIndex = this.activeTypes.indexOf(type);
      this.activeTypes.splice(itemIndex, 1);
    }else{
      this.activeTypes.push(type);
    }

    this.emitTypeSelected();
  }

  public emitTypeSelected(){
    this.typeSelected.emit(this.activeTypes);
  }

}
