import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  standalone:true,
  name: 'formatMO'
})
export class FormatMOPipe implements PipeTransform {

  transform(string,num): unknown {
    let newStr;
    let MOArray=string.replace(/(\r\n|\n|\r)/gm, "$").split("$")
    if(num==2)newStr=(MOArray[0]||'')+(MOArray[1]?' - ':'')+(MOArray[1]||'')
    else if(num==3)newStr=(MOArray[0]||'')+(MOArray[1]?' - ':'')+(MOArray[1]||'')+(MOArray[2]?' - ':'')+(MOArray[2]||'')
    return newStr
  }

}
