<div class="modal fade modal-wrapper-card ag-card-1" role="dialog" id="assignedLotsModal" *ngIf="openAddAssignedLotsModal">

  <!-- Header -->
  <div class="header-container flex space-between">
    <h4 class="ag-heading-h4 ag-weight-400">Assigner les lots à l’entreprise</h4>
    <span style="cursor: pointer;" (click)="closeModal()"><img  src="assets/close-icone-black.svg"  alt=""></span>
  </div>
  <div class="top-section flex center-items space-between" style=" margin: 10px; display: flex;flex-direction: row;align-items: center;gap: 24px;width: 445px;height: 30px;">
    <!-- <div style="width: 432px;height: 32px; display: flex;flex-direction: row; justify-content: space-between;"> -->
      <p style="    width: 85px;height: 24px;left: 0px;top: 4px;font-family: 'Poppins';font-style: normal;font-weight: 400;font-size: 14px;line-height: 24px;display: flex;align-items: center;color: #181818;margin-left: 14px;margin-bottom: 0px;" >Tous les lots</p>
      <p-inputSwitch class="input-switch" (onChange)="allLotsChecked($event)"></p-inputSwitch>
    <!-- </div> -->

  </div>

  <!-- Body -->
  <div class="body-container">          

    <div class="artisan-lots-view">
        <div class="lots-wrapper">
          <div class="lots-list scroller-2-y margin-right-10">
            <div class="single-lot-item flex pointer"
               *ngFor="let lot of dpgfLot"
               [ngClass]="{'selected': selectedLots[lot.id] }" 
              (click)="toggleLot(lot.id)">
              <div class="img-wrapper">
                <img *ngIf="lot.icon" [src]="'/assets/dpgf/Icon_DPGF/'+lot.icon">
              </div>          
              <p>{{ lot.name}}</p>
            </div>
          </div>
        </div>
    </div>        
   </div>
   <div class="bottom-action flex-middle" >
      <button class="ag-btn-primary-sm add-btn" [disabled]="numberOfLotsSelected==0" (click)="emitAddLots()">Ajouter</button>
   </div>
   
</div>
