import { createAction } from '@ngrx/store';

//Global
export const setSaveDone = createAction('[DPGF] set save done / saveDone', (payload)=>({payload}));
export const addChange = createAction('[DPGF] add change', (payload)=>({payload}));
export const setMode = createAction('[DPGF] set Mode', (payload)=>({payload}));
export const setAny = createAction('[DPGF] set anything', (payload)=>({payload}));
export const setType = createAction('[DPGF] set Type', (payload)=>({payload}));
export const setLoadingDpgfLot = createAction('[DPGF] set loading Dpgf Lot', (payload)=>({payload}));

//DPGF Data
export const loadDpgf = createAction('[DPGF] load Dpgf', (payload)=>({payload}));
export const loadDpgfPricing = createAction('[DPGF] load Dpgf Pricing', (payload)=>({payload}));
export const setDpgf = createAction('[DPGF] set Dpgf');
export const setDpgfPricing = createAction('[DPGF] set Dpgf Pricing');
export const setDpgfData = createAction('[DPGF] set Dpgf Data', (payload)=>({payload}));
export const setDpgfAttribut = createAction('[DPGF] set Dpgf Attribut', (payload)=>({payload}));
export const saveDpgf = createAction('[DPGF] save Dpgf / saveDpgf', (payload)=>({payload}));
export const saveDpgfToServer = createAction('[DPGF] save Dpgf to server', (payload)=>({payload}));
export const changeOrderLot = createAction('[DPGF] change order lot', (payload)=>({payload}));
export const finishDpgfPricing = createAction('[DPGF] finish Dpgf pricing', (payload)=>({payload}));
export const finishDpgfPricingDone = createAction('[DPGF] finish Dpgf pricing done', (payload)=>({payload}));
export const calculate = createAction('[DPGF] caculate / saveLotNow', (payload)=>({payload}));
export const nbLine = createAction('[DPGF] count lines / saveLot');
export const emptyTypeAmount = createAction('[DPGF] Empty Type Amount');
export const updateDiscount = createAction('[DPGF] Update Discount / saveDpgf',(payload)=>({payload}));
/// after checking Numérotation automatique, change indexLot to "i"
export const resetLotsIndex = createAction('[DPGF] Reset lots lotIndex to i',(payload)=>({payload})); 

//Files
export const addInFile = createAction('[DPGF] add file ', (payload)=>({payload}));
export const addInManyFile = createAction('[DPGF] add many file ', (payload)=>({payload}));
export const updateLotFiles = createAction('[DPGF] update lot file', (payload)=>({payload}));
export const renameFile = createAction('[DPGF] rename file ', (payload)=>({payload}));
export const deleteFile = createAction('[DPGF] delete file ', (payload)=>({payload}));
export const addInFileArtisan = createAction('[DPGF] add file artisan / saveDpgf', (payload)=>({payload}));
export const removeFileArtisan = createAction('[DPGF] remove file artisan / saveDpgf', (payload)=>({payload}));

//Lot
export const saveLotNow = createAction('[DPGF] save lot / saveLotNow', (payload)=>({payload}));
export const loadDpgfLot = createAction('[DPGF] load Dpgf Lot', (payload)=>({payload}));
export const loadSameDpgfLot = createAction('[DPGF] load Same Dpgf Lot', (payload)=>({payload}));
export const setDpgfLot = createAction('[DPGF] set Dpgf Lot', (payload)=>({payload}));
export const emptyLot = createAction('[DPGF] empty Dpgf Lot', (payload)=>({payload}));
export const saveDpgfLotToServer = createAction('[DPGF] save Dpgf Lot to server / saveLocalisations', (payload)=>({payload}));
export const addnewLot = createAction('[DPGF] add new Lot',(payload)=>({payload}));
export const addnewLotDone = createAction('[DPGF] add new Lot Done', (payload)=>({payload}));
export const addDuplicatedLotDone = createAction('[DPGF] add duplicated Lot Done', (payload)=>({payload}));
export const addLot = createAction('[DPGF] add Lot', (payload)=>({payload}) );
export const duplicateSection = createAction('[DPGF] add duplicated section / countLine', (payload)=>({payload}) );
export const removeLot = createAction('[DPGF] remove Lot', (payload)=>({payload}));
export const removeLotDone = createAction('[DPGF] remove Lot Done', (payload)=>({payload}));
export const updateLot = createAction('[DPGF] update Lot', (payload)=>({payload}));
export const updateLotStatus = createAction('[DPGF] update status Lot', (payload)=>({payload}));
export const updatePriceLot = createAction('[DPGF] update price Lot / recalculatetotal', (payload)=>({payload}));
export const updatePriceSousLot = createAction('[DPGF] update price sousLot / recalculatetotal', (payload)=>({payload}));
export const selectAllPostes = createAction('[DPGF] select all postes in lot', (payload)=>({payload}));

//SousLot
export const addNewSousLot = createAction('[DPGF] add new sous Lot / countLine', (payload)=>({payload}) );
export const removeSousLot = createAction('[DPGF] remove sous Lot / recalculatetotal', (payload)=>({payload}) );
export const updateSousLot = createAction('[DPGF] update sous Lot / addChange', (payload)=>({payload}) );
export const switchSousLot = createAction('[DPGF] switch Lot / addChange', (payload)=>({payload}) );
export const updateSousLotState = createAction('[DPGF] update sous LotState / addChange', (payload)=>({payload}) );

//Poste
export const addNewPoste = createAction('[DPGF] add new poste / countLine', (payload)=>({payload}) );
export const addNewSousPoste = createAction('[DPGF] add new sous poste / saveLotNow', (payload)=>({payload}) );
export const addNewPosteFromSousPoste = createAction('[DPGF] convert sous-poste to post / countLine', (payload)=>({payload}) );
export const addNewPosteBim = createAction('[DPGF] add new posteBIM / countLine', (payload)=>({payload}) );
export const addSuggestionPoste = createAction('[DPGF] add suggestion poste / countLine', (payload)=>({payload}) );
export const addSuggestionSousPoste = createAction('[DPGF] add suggestion sous poste / countLine', (payload)=>({payload}) );
export const removePoste = createAction('[DPGF] remove poste / recalculatetotal', (payload)=>({payload}) );
export const removeSousPoste = createAction('[DPGF] remove sous poste / recalculatetotal', (payload)=>({payload}) );
export const addManyPostes = createAction('[DPGF] add many poste / recalculatetotal', (payload)=>({payload}) );
export const addManyPostesCstb = createAction('[DPGF] add many poste CSTB / addChange', (payload)=>({payload}) );
export const setPosteData = createAction('[DPGF] set poste data / addChange', (payload)=>({payload}) );
export const setSousPosteData = createAction('[DPGF] set sous poste data / addChange', (payload)=>({payload}) );
export const setAllPosteInSouLot = createAction('[DPGF] set all poste in lot / recalculatetotal', (payload)=>({payload}) );
export const setOrderSousPosteInPoste = createAction('[DPGF] set sçousPoste in Poste / recalculatetotal', (payload)=>({payload}) );
export const setPosteAmount = createAction('[DPGF] set poste amount / recalculatetotal', (payload)=>({payload}) );
export const setSousPosteAmount = createAction('[DPGF] set Sous poste amount / recalculatetotal', (payload)=>({payload}) );
export const addPosteComment = createAction('[DPGF] add comment to poste / addChange', (payload)=>({payload}) );
export const addSousPosteComment = createAction('[DPGF] add comment to sous poste / addChange', (payload)=>({payload}) );
export const removePosteComment = createAction('[DPGF] remove comment from poste / addChange', (payload)=>({payload}) );
export const removeSousPosteComment = createAction('[DPGF] remove comment from sous poste / addChange', (payload)=>({payload}) );
export const setPosteCommentData = createAction('[DPGF] set comment data / addChange', (payload)=>({payload}) );
export const setSousPosteCommentData = createAction('[DPGF] set comment data to sous poste / addChange', (payload)=>({payload}) );
export const duplicatePoste = createAction('[DPGF] duplicate poste / recalculatetotal', (payload)=>({payload}) );
export const duplicateSousPoste = createAction('[DPGF] duplicate sous poste / recalculatetotal', (payload)=>({payload}) );
export const addManyPostsArchiDb = createAction('[DPGF] add many poste ArchiDb / addChange', (payload)=>({payload}) );
export const selectPost = createAction('[DPGF] select poste / noChange', (payload)=>({payload}) );
export const unselectPosts = createAction('[DPGF] unselect poste / noChange', (payload)=>({payload}) );
export const moveSection = createAction('[DPGF] move section / recalculatetotal', (payload)=>({payload}) );
export const movePostes = createAction('[DPGF] move postes / recalculatetotal', (payload)=>({payload}) );
export const removeManyPosts = createAction('[DPGF] delete many postes / recalculatetotal', (payload)=>({payload}) );
export const removeSection = createAction('[DPGF] delete section / recalculatetotal', (payload)=>({payload}) );
export const duplicateManyPosts = createAction('[DPGF] duplicate many postes / recalculatetotal', (payload)=>({payload}) );
export const flagPost = createAction('[DPGF] flag post / addChange', (payload)=>({payload}) );
export const flagSoustPost = createAction('[DPGF] flag sous post / addChange', (payload)=>({payload}) );
export const flagManyPosts = createAction('[DPGF] flag many postes / addChange', (payload)=>({payload}) );
export const setExportPostsDb = createAction('[DPGF] set export post db / noChange', (payload)=>({payload}) );
export const setDescView = createAction('[DPGF] set description view count / noChange', (payload)=>({payload}) );
//CCTP
export const setCctp = createAction('[DPGF] set CCTP', (payload)=>({payload}));
export const setCctpText = createAction('[DPGF] set CCTP text', (payload)=>({payload}));
export const addCctpTextAgloGpt = createAction('[DPGF] add CCTP text', (payload)=>({payload}));
export const attachCctpText = createAction('[DPGF] attach CCTP text', (payload)=>({payload}));
export const setCctpCstbData = createAction('[DPGF] set CCTP CSTB data', (payload)=>({payload}));
export const setCctpGid = createAction('[DPGF] set CCTP gid', (payload)=>({payload}));
export const setCctpOnDpgf = createAction('[DPGF] set CCTP in DPGF / addChange', (payload)=>({payload}));

//Error Management
export const errorSaveDpgf = createAction('[DPGF] error save DPGF / error', (payload)=>({payload}));
export const errorloadDpgf = createAction('[DPGF] error load DPGF / error', (payload)=>({payload}));
export const errorSaveDpgfPricing = createAction('[DPGF] error save DPGF Pricing / error', (payload)=>({payload}));
export const errorloadDpgfPricing = createAction('[DPGF] error load DPGF Pricing / error', (payload)=>({payload}));

//Archi Db
export const setSelectedPosts = createAction('[DPGF] set ArchiDbPostes', (payload)=>({payload}));
export const setExportDoc = createAction('[DPGF] set setExportDoc', (payload)=>({payload}));
export const setProjLicence = createAction('[DPGF] set setProjLicence', (payload)=>({payload}));

//suggestion button State
export const changeStateButtonSuggestion = createAction('[DPGF] set suggButtonState', (payload)=>({payload}) );

//new action to get size on dpgf
export const getSizeDPGF = createAction('[DPGF] get size dpgf', (payload)=>({payload}) );
export const setSizeDPGF = createAction('[DPGF] set size dpgf', (payload)=>({payload}) );

export function getSousLotsData(getSousLotsData: any) {
  throw new Error('Function not implemented.');
}

