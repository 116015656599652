import { Component,Output,EventEmitter, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';


@Component({
  standalone:true,
  selector: 'app-dce-switch',
  imports:[
    DpgfModule,
    NgSharedModule
  ],
  templateUrl: './dce-switch.component.html',
  styleUrls: ['./dce-switch.component.scss'],
})
export class DceSwitchComponent implements OnInit {
  public value = "dpgf";
  @Output() view: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
  onSelectionChange(val){
    this.value = val;
    this.view.emit(val)
  }

}
