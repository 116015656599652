import { Component, OnInit ,ViewChild, NO_ERRORS_SCHEMA} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
//Components
import { ArtisanSignupDetailComponent } from '../../../artisan/artisan-signup-detail/artisan-signup-detail.component';
import { HeaderComponent } from 'src/app/all/header/header.component';

//Services
import { MessageService } from 'primeng/api';
import { ArtisanService } from '../../../_services/index';

import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import {NgSharedModule} from 'src/app/all/ngshared.module'

declare var $;
@Component({
    selector: 'app-artisan-subscribe-detail',
    imports:[
      HeaderComponent,
      SpinnerComponent,
      ArtisanSignupDetailComponent,
      NgSharedModule
    ],
    providers: [],
    templateUrl: './artisan-subscribe-detail.component.html',
    styleUrls: ['./artisan-subscribe-detail.component.scss'],
    standalone: true,
    
})
export class ArtisanSubscribeDetailComponent implements OnInit {
  public detail: any;
  public loading:Boolean = false;
  @ViewChild(ArtisanSignupDetailComponent) form: ArtisanSignupDetailComponent
  constructor(private title: Title,
              private router: Router,
              private messageService: MessageService,
              private artisanService: ArtisanService,) {
    this.detail = {}
   }
   ngOnInit() {
     this.title.setTitle('BAM - S\'inscrire' );
     this.scrollToTop()
   }
   //Scroll to Top Page
   public scrollToTop(){
     $("html, body").animate({ scrollTop: 0 }, "slow");
  }
  //Save Page
  save(){
    this.loading = true;
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let artisanId = currentUser.artisanId;
    let isValid = this.form.isValid()
    let formData = this.form.getFormData()
    if(isValid){
      this.artisanService.update(artisanId, formData)
          .subscribe(
              data => {
                  this.loading = false;
                  this.router.navigate(['artisan/subscribe4']);

                  this.messageService.add({ key: 'toast', severity: 'success', summary: 'Informations principales enregistrées', detail: 'Vos informations principales ont été enregistrées', life: 5000 });
              },
              error => {
                  console.log("error",error)
                  this.loading = false;
                  this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: error.message, life: 5000 });
              });
    }else{
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Formulaire incomplet', detail: "Veuillez remplir tous les champs obligatoires", life: 5000 });
      this.loading = false;
    }
  }

  }
