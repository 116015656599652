<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 sidebar-wrapper">

      <div class="options-dropdown">
        <ul *ngFor="let option of projectDescriptionOptions; index as i">
          <li class="main-level ag-para-1 ag-bold flex space-between"
            [ngClass]="{'open': (option.value == selectedOption || option.value == selectedbatiment['value'])}"
            (click)="openNestedList(option, 'nested-options-'+removeWhiteSpaces(option.value))"
            >
            <span>{{ option.title }}</span>
            <img *ngIf="option.value!='parcelle'" src="/assets/acv-icons/nav-arrow-bottom.svg">
          </li>
          <!-- Nest List -->
          <ul class="nested-items-ul" [id]="'nested-options-'+removeWhiteSpaces(option.value)"
            [ngClass]="{'open': option.value == selectedbatiment['value']}">
            <li class="ag-para-1" [id]="'nested-li'+'-'+nestedItem.value"
              [ngClass]="{'selected': nestedItem.value == currentView}"
              *ngFor="let nestedItem of sidebarLists[option.type]; index as i"
              (click)="listItemSelected($event, nestedItem.value)"
              >
              {{ nestedItem.title }}
            </li>
          </ul>
        </ul>
      </div>

    </div>
    <div class="col-md-9 main-content-wrapper silver-arthens-grey-bg scroller-1">
      <!-- FORM
      ================================= -->
      <div class="main-form-wrapper scroller-1"  [ngClass]="{'prevent-event':isProjectDemo}">
        <div *ngIf="currentView=='batiment_donnees_de_chantier'">
          <h4 class="ag-heading-h4 margin-bottom-16">{{ forms[currentView].mainTitle || '' }}</h4>
          <!-- <p class="ag-para-1 margin-bottom-59" *ngIf="forms[currentView].mainDescription != ''">{{ forms[currentView].mainDescription || '' }}</p> -->
          <button class="btn action-btn margin-bottom-20" *ngIf="forms[currentView].mainButton"
            (click)="formButtonClicked(forms[currentView].mainButton.buttonId)">
            {{ forms[currentView].mainButton.title }}
          </button>

          <p class="ag-para-2 ag-bold" *ngIf="forms[currentView].subTitle">{{ forms[currentView].subTitle || '' }}</p>
          <div *ngIf="forms[currentView].headerSteps" style="margin-top: 3px;">
            <ul class="header-steps-ul">
                <li class="ag-para-1" *ngFor="let step of forms[currentView].headerSteps; index as i">
                  <span class="padding-left-10" *ngIf="i !== 0">•</span>
                  {{ step }}
                </li>
            </ul>
          </div>
          <div class="form-rows-container">
            <!-- Single row -->
            <div class="form-row" *ngFor="let row of forms[currentView].items">
              <div class="flex space-between center-items" [ngClass]="{'grey-bg' : row.type != 'section_title' && row.type != 'zone_text', 'ag-italic' : row.isItalic }">
                <div class="row-label">
                  <p class="ag-para-1 label-text" [ngClass]="{'ag-text-bold' : row.labelIsBold}">{{ row.label }}</p>
                </div>
                <ng-container [ngSwitch]="row.type">

                  <!-- Select Input -->
                  <div class="row-widget" *ngSwitchCase="'select'">
                    <select class="ag-select-1" (change)="optionSelected(currentView, row.modelVariable, $event.target.value)">
                      <option selected disabled hidden>Choix</option>
                      <option *ngFor="let option of row.options" [value]="option.value"
                      [selected]="option.value === getStateValue(row.modelVariable) ? true : false"
                      >
                      <!-- [selected]="option.value === ( prevState |  getStatePath: selectedbatiment['trimmedValue']+'.'+row.modelVariable : false ) ? true : false" -->
                      {{ option.label }}</option>

                    </select>
                  </div>
                  <!-- END Select Input -->
                  <!-- Radio Input -->
                  <div class="row-widget flex" *ngSwitchCase="'radio'">
                    <p *ngFor="let option of row.options; index as i" [ngClass]="{'margin-left-32' : i > 0 }">
                      <input type="radio" [name]="row.optionName" class="ag-input-1 margin-right-8" [value]="option.value"
                        (change)="radioSelected(currentView, row.modelVariable, $event.target.value)"
                        [checked]="option.value === getStateValue(row.modelVariable) ? true : false"
                      >
                      <!-- [checked]="option.value === ( prevState |  getStatePath: selectedbatiment['trimmedValue']+'.'+row.modelVariable : false ) ? true : false" -->
                      <b class="margin-left-8">{{ option.label }}</b>
                    </p>
                  </div>
                  <!-- END Radio Input -->
                  <!-- Section Title -->
                  <div class="row-widget full-width" *ngSwitchCase="'section_title'">
                    <h4 class="ag-para-2 ag-bold" *ngIf="row.rowTitle && row.rowTitle != ''">{{ row.rowTitle }}</h4>
                    <p class="ag-para-1 label-text">{{ row.rowDescription }}</p>
                  </div>
                  <!-- END Section Title -->
                  <!-- Table Header -->
                  <div class="table-header-container full-width" *ngSwitchCase="'table_header'">
                    <ul class="flex center-items no-padding-left">
                      <li [ngStyle]="{'width': item.width}"
                        class="ag-para-1 ag-bold"
                        *ngFor="let item of row.options" >
                          {{ item.label }}
                      </li>
                    </ul>
                  </div>
                  <!-- END Table Header -->
                  <!-- Table Row -->
                  <div class="table-body-container full-width" *ngSwitchCase="'table_row'">
                    <ul class="flex center-items  no-padding-left">
                      <li [ngStyle]="{'width': item.width}"
                        class="ag-para-1"
                        *ngFor="let item of row.options">
                          <input *ngIf="item.isInput" [type]="item.type"
                            [placeholder]="item.placeholder || ''"
                            [ngClass]="{'ag-input-1' : item.type != 'checkbox' }"
                             />
                          <span *ngIf="item.type == 'text'">{{ item.value }}</span>
                      </li>
                    </ul>
                  </div>
                  <!-- END Table Row -->

                  <!-- Other Inputs -->
                  <div class="row-widget flex center-items fields-wrapper" *ngSwitchDefault>
                    <input *ngIf="row.type != 'text_only'" [type]="row.type" [placeholder]="row.placeholder"
                      [ngClass]="{'ag-input-1': true, 'month-input': row.inputLabel}"
                      (change)="inputTyped(currentView, row.modelVariable, $event.target.value)"
                      [value]="getStateValue(row.modelVariable) || '' "
                      >
                      <!-- [value]="( prevState |  getStatePath: selectedbatiment['trimmedValue']+'.'+row.modelVariable : false ) || '' " -->

                    <!-- Default Unite -->
                    <span class="input-label-text margin-left-32">{{row.inputLabel || ''}}</span>
                  </div>
                  <!-- END Other Inputs -->

                </ng-container>
              </div>
            </div>
            <!-- END Single row -->
          </div>
        </div>

        <!-- Zones Form -->
        <div *ngIf="currentView == 'parcelle_donnees_generales'">
          <h4 class="ag-heading-h4 margin-bottom-16">{{ forms[currentView].mainTitle || '' }}</h4>
          <div class="form-rows-container">
            <!-- Single row -->
            <!-- <div class="form-row" *ngFor="let row of forms[currentView].items;let i=index;"> -->
            <div class="form-row" *ngFor="let row of parcellZoneInputs;let i=index;">

              <!-- Zone Input title -->
              <div class="row-widget full-width flex space-between">
                <h4 class="ag-heading-h4">{{ row.title || 'Zone d’import' }}</h4>
                <span (click)="removeZone(row.id)" class="pointer" *ngIf="i > 0">
                  <i class="fa fa-minus margin-right-6"></i>
                  Retirer la zone d’import
                </span>
              </div>
              <!-- END Zone Input title -->

              <div class="flex space-between center-items grey-bg" *ngFor="let input of row.items">

                <div class="flex space-between center-items">
                  <div class="row-label">
                    <p class="ag-para-1 label-text">{{ input.label }}</p>
                  </div>
                </div>

                <div class="row-widget flex center-items fields-wrapper">
                  <input class="ag-input-1"
                    [type]="input.type"
                    [placeholder]="input.placeholder"
                    [value]="input.value || ''"
                    (change)="ZoneInputTyped(row.id, input.modelVariable, $event.target.value)"
                    >
                  <span class="input-label-text margin-left-32">{{input.inputLabel || ''}}</span>
                </div>

              </div>

          </div>
        </div>
        </div>
        <!-- END Zones Form -->





        <div *ngIf="currentView=='batiment_composants_du_chantier'">
          <h4 class="ag-heading-h4 margin-bottom-16">Votre consommation d'énergie sur le chantier</h4>
          <!-- <button class="btn action-btn margin-bottom-20">
            Composants du chantier
          </button> -->
          <p class="ag-para-2 ag-bold">Composants du chantier</p>
          <div style="margin-top: 3px;">
            <ul class="header-steps-ul">
                <li class="ag-para-1">
                  <span class="padding-left-10">•</span> Réaliser des ouvrages provisoires nécessaires au chantier <i class="fa fa-info-circle" pTooltip='Ex : voies de livraison, plateformes de terrassement... ' style="color: #0A1747;"></i>
                </li>
                <li class="ag-para-1">
                  <span class="padding-left-10">•</span> Protéger certaines parties de l'ouvrage ou composants pendant le chantier <i class="fa fa-info-circle" pTooltip='Pour ce cas, il convient de s’assurer que ces composants ne sont pas déjà
                  pris en compte dans la donnée environnementale du composant protégé.
                  Par exemple, certaines données environnementales tiennent déjà compte
                  de bâches de protection lors de la mise en œuvre.' style="color: #0A1747;"></i>
                </li>
            </ul>
          </div>
        </div>
        <div *ngIf="selectedFiles.length && currentView=='batiment_composants_du_chantier'" class="icon-holder margin-right-24 top-buttons">
          <i class="fa fa-trash margin-right-12 pointer" pTooltip='Supprimer' (click)="deleteSelectedFiles()"></i>
          <i class="fa fa-copy pointer" pTooltip='Dupliquer' (click)='duplicateSelectedFiles()'></i>
        </div>
        <div *ngIf="currentView=='batiment_composants_du_chantier'" class="env scroller-1">
          <div *ngIf="!loadingPosts">
            <table class="table table-striped table-borderless" style="width: 90%;">
              <thead>
                <tr>
                  <th><input type="checkbox" (click)='checkAllFiles()' [checked]='checkedAllFiles'></th>
                  <th>Fiche INIES affiliée au poste</th>
                  <th>Désignation du poste</th>
                  <th style="text-align: center;">Quantité</th>
                  <th style="text-align: center;">Unité</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let postFile of relatedFiles;let i=index;">
                  <tr>
                    <td style="height: 1px;">
                      <div class="flex" style="height: 100%;align-items: center;padding-bottom: 5px;">
                        <input type="checkbox" (click)='checkOneFile(postFile._id)' [checked]='postFile.checked'>
                      </div>
                    </td>
                    <td (click)="getPostOpenModal(postFile)"><p class="post-name no-margin pointer">{{postFile.nom}}</p></td>
                    <td><p class="post-name no-margin pointer">{{postFile.postName}}</p></td>
                    <td style="text-align: center;"><input (change)='updatePost(postFile)' [(ngModel)]="postFile.quantity" type="number" step="any" class="quantity-input"></td>
                    <td style="text-align: center;">{{(postFile.uniteUF||'m3')}}</td>
                    <td>
                      <div class="dropdown">
                        <div class="pointer" aria-hidden="true" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fa fa-ellipsis-v no-open" ></i>
                        </div>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <li (click)="$event.stopPropagation();duplicateOneFile(postFile)">
                            <i class="fa fa-clone click-delete-sommaire" aria-hidden="true"></i>
                            <span class="menu-text pointer">Dupliquer</span>
                          </li>
                          <li (click)="$event.stopPropagation();deleteOneFile(postFile)">
                            <i class="fa fa-trash click-delete-sommaire" aria-hidden="true"></i>
                            <span class="menu-text pointer">Supprimer</span>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr class="friro-row" *ngIf="showFrigo(postFile)">
                    <div *ngIf="!postFile.frigogenePost" class="frirogene-container">
                      <i class="fa fa-warning frigo-icons"></i>
                      <p class="margin-left-5" style="margin-bottom: 0px;">Votre composant Climatisation utilise un fluide frigorigène qu'il faut également indiquer.</p>
                      <a class="margin-left-5" (click)="openFrirogeneModal(postFile)" style="color:#3805F2">Choisir le fluide frigorigène correspondant</a>
                    </div>
                    <div *ngIf="postFile.frigogenePost" class="frirogene-container-selected">
                      <i class="fa fa-check-circle-o frigo-icons"></i>
                      <p class="margin-left-5">Votre composant Climatisation utilise le fluide frigorigène {{(postFile?.frigogenePost?.nom||'')}}. Sa charge initiale est de </p>
                      <input type="text" class="frigogene-input" [ngModel]="postFile.frigogenePost.frigogeneQuantity" (focusout)="updateFrigogeneData($event,postFile)"> kg
                      <a class="margin-left-5" style="color: #3805F2;" (click)="openFrirogeneModal(postFile)">Modifier</a>
                    </div>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="flex pointer" style="margin-bottom: 12px;align-items: baseline;">
              <i class="fa fa-plus purple-color"></i>
              <p class="purple-color no-margin margin-left-10" [ngClass]="{'prevent-event':isProjectDemo}" (click)="openSide()">Ajouter un composant</p>
            </div>
          </div>
          <div *ngIf="loadingPosts" class="margin-top-24">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="margin-top-20 add-zone-container" *ngIf="currentView == 'parcelle_donnees_generales'">
          <button (click)="addNewZone()"><i class="fa fa-plus"></i> Ajouter une zone d'import</button>
        </div>


      </div>
      <!-- END FORM
      ================================= -->
    </div>
  </div>
</div>
<p-sidebar [(visible)]="displaySide" position="right" (onHide)="closeSide()" autoZIndex="false" styleClass="p-sidebar-lg">
  <div class="header">
    <div class="row">
      <div class="center">
        <div class="inner-container">
          <i class="click-it fa fa-times" aria-hidden="true" (click)="closeSide()"></i>
          <p class="post-title">{{selectedEntity}} > {{selectedbatiment['value']}}</p>
        </div>
      </div>
    </div>
  </div>
  <app-acv-project-composant-sidebar 
    [projectLicence]="projectLicence"
    [isDemo]="isProjectDemo" 
    [selectedFriro]="selectedFriro"
    [currentContibuteur]="'Chantier'"
    (addingPostIniesEvent)="setaddingPostInies($event)" 
    (openRemploiEvent)="openModalChoice($event)" 
    (closeTheCctp)="closeSide()" 
    (frirogeneResult)="frirogeneResult=$event" 
    (infoPost)="setInfoPost($event)" 
     >
  </app-acv-project-composant-sidebar>
</p-sidebar>
<div id="choose-demand-modal"
  class="modal fade choose-demand-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog no-padding">
    <div class="modal-container no-padding">
      <!-- <p class="upgrade-title">{{lot?.title}} > {{cctpEdition?.cctpEdit?.postDesignation}}</p> -->
      <p class="upgrade-title">Vous ne trouvez pas votre fiche ?</p>
      <p class="upgrade-sub-title">Il est possible que votre fiche n'existe pas encore dans la base de donnée. Souhaitez-vous faire la demande création ?</p>
      <div class="button-container">
        <button class="btn btn-forms" data-dismiss="modal" (click)="demandePost('Update')">Une demande existe déjà</button>
        <button class="btn btn-forms" style="margin-left: 24px;" data-dismiss="modal" (click)="demandePost('Creation')">Faire une demande</button>
      </div>
    </div>
  </div>
</div>
<div id="add-demand-modal"
  class="modal fade add-demand-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog no-padding" style="width: 770px;">
    <div class="modal-container no-padding">
      <div class="row justify-content-center main-container scroller-1 col-md-12">
        <form class="form-container1">
          <p *ngIf="demandeData.nature=='Creation'" class="text-center title">Demande de création de composant</p>
          <p *ngIf="demandeData.nature=='Update'" class="text-center title">Demande de composant déjà existant</p>
          <p *ngIf="demandeData.nature=='Reemploi'" class="text-center title">Votre composant réemploi</p>
            <div *ngIf="!currentUser.idDemandeur && demandeData.nature!='Reemploi'">
              <p class="sub-title" style="margin-bottom: 3px;">Vos informations</p>
              <p class="info">Vos informations vous seront demandées une seule fois pour l'ajout d'un composant.</p>
              <div class="form-row">
                <div class="col-md-6 form-group">
                  <label class="required-star" for="first-name">Prénom</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.prenom" name="first-name" placeholder="Prénom">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="last-name">Nom</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.nom" name="last-name" placeholder="Nom">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="email">Email</label>
                  <input class="input-demand" type="email" [(ngModel)]="demandeData.mail" name="email" placeholder="architecte@gmail.com">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="phone_number">Numéro de téléphone</label>
                  <input class="input-demand" type="tel" [(ngModel)]="demandeData.telephone" name="phone_number" placeholder="06 09 06 09 06">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="job">Profession</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.metier" name="job" placeholder="Architecte">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="company">Entreprise</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.entreprise" name="company" placeholder="Bam Architecture">
                </div>
              </div>
            </div>
            <p class="sub-title">Les informations sur le composant</p>
            <!-- <p>Ces informations vous sont demandées afin de faire la demande de création de composant.</p> -->
            <div *ngIf="demandeData.nature=='Creation'">
              <div class="form-row">
                <div class="col-md-12 form-group">
                  <label class="required-star" for="ded-name">Intitulé de la donnée environnementale par défaut proposée</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.content.creationLabel" name="ded-name" placeholder="">
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 form-group">
                  <label class="required-star" for="quantity">Quantité à modéliser</label>
                  <input class="input-demand" type="number" [(ngModel)]="demandeData.quantity" name="quantity" placeholder="1">
                </div>
                <div class="col-md-4 form-group">
                  <label class="required-star" for="UF">Unité de l'unité fonctionnelle</label>
                  <select class="input-demand scroller-1" name="UF" [(ngModel)]="demandeData.unity">
                    <option *ngFor="let unit of unitsCodes | keyvalue">{{unit.value}}</option>
                  </select>
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="product_life">Durée de vie estimée</label>
                  <input class="input-demand" type="number" [(ngModel)]="demandeData.dve" name="product_life" placeholder="50 ans">
                </div>
                <div class="col-md-12 form-group">
                  <label class="required-star" for="argumentation">Argumentaire(s)/ Justification(s) de la demande</label>
                  <textarea class="input-demand no-resize" style="height: 100px;" [(ngModel)]="demandeData.content.creationArgument" name="argumentation" rows="4" placeholder="Expliciter au maximum votre constat et votre demande "></textarea>
                </div>
                <div class="col-md-12 form-group">
                  <label class="required-star" for="argumentation">Identification du produit de référence servant à construire la donnée environnementale par défaut et dimensionnement du produit</label>
                  <textarea class="input-demand no-resize" style="height:75px" [(ngModel)]="demandeData.content.creationIdentification" name="argumentation" rows="4" placeholder="Produit de référence à utiliser pour contruire la donnée environnementale par défaut"></textarea>
                </div>
                <div class="col-md-12 form-group">
                  <label for="argumentation">Identification de l'échantillon/donnée source pour établir le calcul</label>
                  <textarea class="input-demand no-resize" style="height:75px" [(ngModel)]="demandeData.content.creationSource" name="argumentation" rows="4" placeholder="Echantillon source à utiliser pour établir le calcul"></textarea>
                </div>
                <div class="col-md-12 form-group">
                  <label for="UF">Famille de de produit</label>
                  <select class="input-demand" name="CR" [(ngModel)]="demandeData.content.creationRank.creationRankId">
                    <option *ngFor="let item of iniesCategories" [value]="item.id"><img [src]="'assets/dpgf/Icon_DPGF/'+item.icon"/>{{item.title}}</option>
                  </select>
                </div>
                <div [hidden]="demandeData.content.creationRank.creationRankId!=0">
                  <div class="col-md-12">
                    <p class="sub-title1">Votre famille de produit n'est pas dans liste.</p>
                  </div>
                  <div class="col-md-12 form-group">
                    <label for="product_life">Renseigner votre nom de famille de produit</label>
                    <input class="input-demand" type="text" [(ngModel)]="demandeData.content.creationRank.creationRankName" name="nom-famille">
                  </div>
                  <div class="col-md-12 form-group">
                    <label for="product_life">Dans quelle catégorie votre famille de produit se trouve-t-elle ?</label>
                    <select class="input-demand" name="UF" [(ngModel)]="demandeData.content.creationRank.creationRankParent">
                      <option *ngFor="let item of iniesCategories" [value]="item.id"><img [src]="'assets/dpgf/Icon_DPGF/'+item.icon"/>{{item.title}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div style="position: relative;" *ngIf="demandeData.nature=='Update'">
              <div class="col-md-6 form-group">
                <label class="required-star">Numéro de la demande Mlab</label>
                <input id="id-input" class="input-demand" type="text" autocomplete="off" [(ngModel)]="demandeData.id" (focus)="getDemendSugg()" name="num-mlab">
                <div id="myInputautocomplete-list" class="autocomplete-items-demand">
                  <div style="overflow: auto;height: 156px;" class="scroller-1">
                    <div *ngFor="let result of demandesSugg;let i = index" (mousedown)="setChosenDemand(result)" class="unit-proposition">
                      <div class="flex">
                        <p style="font-weight: 900;">{{result.idDemand}} - {{result.name}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label class="required-star">Intitulé de la donnée environnementale</label>
                <input class="input-demand" type="text" [(ngModel)]="demandeData.content.creationLabel" name="creationLabel">
              </div>
              <div class="col-md-6 form-group">
                <label class="required-star">Durée de vie estimée</label>
                <input class="input-demand" type="number" [(ngModel)]="demandeData.dve" name="dve">
              </div>
              <div class="col-md-6 form-group">
                <label class="required-star">Quantité à modéliser</label>
                <input class="input-demand" type="number" [(ngModel)]="demandeData.quantity" name="quantity">
              </div>
              <div class="col-md-5 form-group">
                <label class="required-star">Unité de l'unité fonctionnelle</label>
                <select class="input-demand" name="UF" [(ngModel)]="demandeData.unity">
                  <option *ngFor="let unit of unitsCodes | keyvalue">{{unit.value}}</option>
                </select>
              </div>
            </div>
            <div *ngIf="demandeData.nature=='Reemploi'">
              <div class="col-md-6 form-group">
                <label>Nom du composant</label>
                <input class="input-demand" type="text" [(ngModel)]="demandeData.content.creationLabel" name="num-mlab">
              </div>
              <div class="col-md-6 form-group">
                <label>Durée de vie estimée</label>
                <input class="input-demand" type="number" [(ngModel)]="demandeData.dve" name="dve">
              </div>
              <div class="col-md-6 form-group">
                <label>Quantité à modéliser</label>
                <input class="input-demand" type="number" [(ngModel)]="demandeData.quantity" name="quantity">
              </div>
              <div class="col-md-5 form-group" style="margin-bottom: 30px;">
                <label>Unité de l'unité fonctionnelle</label>
                <select class="input-demand" name="UF" [(ngModel)]="demandeData.unity">
                  <option *ngFor="let unit of unitsCodes | keyvalue">{{unit.value}}</option>
                </select>
              </div>
            </div>
            <div *ngIf="demandeData.nature!='Reemploi'" class="terms-container col-md-12">
              <input class="checkbox-box" type="checkbox" [checked]="demandeData.terms" name="flexCheckIndeterminate" [(ngModel)]="demandeData.terms">
              <label for="flexCheckIndeterminate" style="margin-top: 20px;padding-left: 17px;">
                Je consens à la politique de collecte et protection des données personnelles de MLab
              </label>
            </div>
            <div class="btn-form col-md-12">
              <button *ngIf="demandeData.nature=='Creation'" type="submit" class="btn btn-forms" (click)="submitDemande()" [disabled]="!((currentUser.idDemandeur && demandeData.terms && demandeData.content.creationLabel && demandeData.quantity && demandeData.unity && demandeData.content.creationArgument && demandeData.content.creationIdentification) || (!currentUser.idDemandeur && demandeData.nom && demandeData.prenom && demandeData.mail && demandeData.telephone && demandeData.metier && demandeData.entreprise && demandeData.content.creationLabel && demandeData.quantity && demandeData.unity && demandeData.content.creationArgument && demandeData.content.creationIdentification && demandeData.terms))">
                <p *ngIf="!loadingDemand" class="no-margin">Envoyer ma demande de création de fiche INIES</p>
                <app-spinner *ngIf="loadingDemand"></app-spinner>
              </button>
              <button *ngIf="demandeData.nature=='Update'" type="submit" class="btn btn-forms" (click)="submitDemande()" [disabled]="!((currentUser.idDemandeur && demandeData.id && demandeData.terms) || (!currentUser.idDemandeur && demandeData.nom && demandeData.prenom && demandeData.mail && demandeData.telephone && demandeData.metier && demandeData.entreprise && demandeData.id && demandeData.terms))">
                <p *ngIf="!loadingDemand" class="no-margin">Renseigner ma demande de création de fiche</p>
                <app-spinner *ngIf="loadingDemand"></app-spinner>
              </button>
              <button *ngIf="demandeData.nature=='Reemploi'" type="submit" class="btn btn-forms" (click)="addPostIniesReemploi()">Ajouter mon composant réemploi</button>
            </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div id="add-post-modal" class="modal fade add-post-modal" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
        <h3 class="modal-title modal-header-title">{{selectedEntity}} > {{selectedbatiment['value']}}</h3>
        <div class="flex margin-top-24">
          <img src="/assets/archi/correct-circle.svg" class="emoji">
          <p class="description">
            La fiche <b>{{nameChosenPost}}</b> va être ajoutée au contributeur <b>{{selectedEntity}}</b>
          </p>
        </div>
      <div class="margin-top-24 flex align-centre" style="height: 37px;justify-content: center;">
        <button type="button" class="btn btn-grey" data-dismiss="modal">Annuler l'ajout</button>
        <button type="button" class="btn btn-forms margin-left-24" (click)="addFileToPost()">
          <span *ngIf="!addingPostInies">Continuer</span>
          <app-spinner *ngIf="addingPostInies" style="position: relative;bottom: 10px;"></app-spinner>
        </button>
      </div>
    </div>
  </div>
</div>

<div id="import-files-modal" class="modal fade add-post-modal" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
        <h3 class="modal-title modal-header-title" style="text-align: center;">Votre fiche configurée </h3>
        <div class="margin-top-24">
          <!-- <p class="description"> -->
            Les fiches configurées peuvent être réalisées sur les sites homologués suivants :
            <div style="padding-left: 10px;">
              <div style="display: flex;height: 24px;margin-top: 2px;">&bull;&nbsp; <a class="one-link" href="https://www.snbpe.org/index.php/developpement_durable/calculette" target="_blank">BETIE  <i class="fa fa-external-link"></i></a></div>              
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://www.save-construction.com/" target="_blank">Solutions Acier Valeurs environnementales  <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://de-bois.fr/fr" target="_blank">DE-BOIS  <i class="fa fa-external-link"></i></a></div>
              <!-- <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">AKacia  <i class="fa fa-external-link"></i></a></div> -->
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="http://www.environnement-ib.com/login" target="_blank">Environnement IB  <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://www.de-boisdefrance.fr/" target="_blank">DE - Bois De France   <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">Fichier extrapolation PEP </a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://calculateurco2.ciments-hoffmann.fr/" target="_blank">Hoffmann Green Cement Technologies   <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://www.bankiz-fdes.fr/app/" target="_blank">BANKIZ  <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">VICAT </a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">VINCI Construction </a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://www.de-baie.fr/" target="_blank">DE-Baie   <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">3B </a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">BSM </a></div>
            </div>
            <p class="margin-top-12">Elles permettent de modéliser des composants qui ne seraient pas présents dans la base INIES</p>
        </div>
      <div class="margin-top-24 flex align-centre" style="height: 37px;justify-content: center;">
        <button type="button" class="btn btn-forms" (click)="fileInput.click()">
          <input
            #fileInput
            id="file"
            type="file"
            class="form-control"
            style="display:none;"
            (change)="importXml($event)">
          <span *ngIf="!addingPostInies">Importer ma fiche configurée</span>
          <app-spinner *ngIf="addingPostInies" style="position: relative;bottom: 10px;"></app-spinner>
        </button>
      </div>
    </div>
  </div>
</div>
<div id="post-info-modal"
  class="modal fade post-info-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-container">
      <div class="choice-container">
        <div class="choice" (click)="onSelectionChangeTab('resume')"  [ngClass]="{'selectd-choice' : postInfoTab === 'resume'}">
          Résumé
        </div>
        <div class="choice" (click)="onSelectionChangeTab('detail')" [ngClass]="{'selectd-choice' : postInfoTab === 'detail'}">
          Détail
        </div>
      </div>
      <div class="margin-top-12" style="width: 80%;">
        <!-- <div class="rating-container">
          <img src="/assets/archi/leaf.svg"/>
          <p class="rating">B</p>
          <p>Faible Impact</p>
        </div> -->
        <p class="post-title no-margin">{{infoPost?.nom}}</p>
        <p class="post-title">ID : {{infoPost?.idFicheBase}}</p>
        <p class="sub-title">{{infoPost?.categoryName}}</p>
      </div>
      <div class="resume-post" *ngIf="postInfoTab=='resume'">
        <div *ngIf="(infoPost||{}).declarationType"><p class="mini-title">Type de déclaration :</p> {{infoPost?.declarationType}}<br></div>
        <div *ngIf="(infoPost||{}).organismeName"><p class="mini-title">Organisme déclarant :</p> {{infoPost?.organismeName}}<br></div>
        <div *ngIf="(infoPost||{}).descriptionUF"><p class="mini-title">Unité fonctionnelle : <span class="span-black">{{infoPost?.descriptionUF||'Aucun'}}</span></p><br></div>
        <div *ngIf="(infoPost||{}).productionPlace"><p class="mini-title">Lieu de production  :</p> {{infoPost?.productionPlace||'Aucun'}}<br></div>
        <div *ngIf="(infoPost||{}).performanceUFUnite"><p class="mini-title">Performance principale de l'UF :</p> {{infoPost?.performanceUFQuantite||0}} {{infoPost?.performanceUFUnite||''}}<br><br></div>
        <a style="color: #0275d8 !important" target="_blank" [href]='"https://www.base-inies.fr/iniesV4/dist/consultation.html?id=" + (infoPost||{}).idFicheBase'>Voir la fiche</a> <i class="fa fa-external-link" style="color: #0275d8 !important;margin-left: 3px;"></i>
      </div>
      <div class="detail-post" *ngIf="postInfoTab=='detail'">
        <div class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Informations générales</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <div *ngIf="(infoPost||{}).nbrReferencesCommerciales"><p class="mini-title">Nombre de références commerciales couvertes :</p> {{infoPost?.nbrReferencesCommerciales||0}}<br></div>
            <div *ngIf="(infoPost||{}).declarationType"><p class="mini-title">Type de déclaration :</p> {{infoPost?.declarationType||1}}<br></div>
            <div *ngIf="(infoPost||{}).dateEdition"><p class="mini-title">Date d'édition de la déclaration :</p> {{infoPost?.dateEdition| date: 'dd/MM/yyyy'}}<br></div>
            <div *ngIf="(infoPost||{}).dateMiseAJour"><p class="mini-title">Date de dernière mise à jour :</p> {{infoPost?.dateMiseAJour| date: 'dd/MM/yyyy'}}<br></div>
            <div *ngIf="(infoPost||{}).dateVerification"><p class="mini-title">Date de vérification :</p> {{infoPost?.dateVerification| date: 'dd/MM/yyyy'}}<br></div>
            <div *ngIf="(infoPost||{}).Version"><p class="mini-title">Version :</p> {{infoPost?.Version||'Aucun'}}<br></div>
            <div *ngIf="(infoPost||{}).dateEdition"><p class="mini-title">Date de fin de validité :</p> {{infoPost?.dateEdition||'Aucun'}}<br></div>
            <div *ngIf="(infoPost||{}).referencesCommerciales"><p class="mini-title">Références commerciales couvertes et fabricants :</p> {{infoPost?.referencesCommerciales||'Aucun'}}<br></div>
            <div *ngIf="(infoPost||{}).aptitudeUsage"><p class="mini-title">Preuves d'aptitude à l'usage :</p> {{infoPost?.aptitudeUsage||'Aucun'}}<br></div>
          </div>
        </div>
        <div class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Unités fonctionnelles</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <div *ngIf="(infoPost||{}).descriptionUF"><p class="mini-title">Unité fonctionnelle :<span class="span-black">{{infoPost?.descriptionUF||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost||{}).performanceUF"><p class="mini-title">Performance principale de l'UF :</p> {{infoPost?.performanceUF||'Aucun'}} {{infoPost?.performanceUF?':':''}} {{infoPost?.performanceUFQuantite}} {{infoPost?.performanceUFUnite}}<br></div>
            <div *ngIf="(infoPost||{}).quantiteUF"><p class="mini-title">Quantité :</p> {{infoPost?.quantiteUF||'Aucun'}} {{unitsCodes[infoPost?.uniteUF]}}<br></div>
            <div *ngIf="(infoPost||{}).dvt"><p class="mini-title">Durée de vie de référence (DVR) :</p> {{infoPost?.dvt}} ans<br></div>
            <div *ngIf="(infoPost||{}).infosComplementaires"><p class="mini-title">Caractéristiques non contenues dans l'U.F. :<span class="span-black"> {{infoPost?.infosComplementaires||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost||{}).tauxChute"><p class="mini-title">Taux de chute à l'étape du processus de construction :</p> {{infoPost?.tauxChute||0}}%<br></div>
            <div *ngIf="(infoPost||{}).frequenceEntretien"><p class="mini-title">Fréquence d'entretien :</p> {{infoPost?.frequenceEntretien+1||'Aucun'}} ans<br></div>
            <div *ngIf="(infoPost||{}).declarationContenu"><p class="mini-title">Déclaration de contenu :<span class="span-black">{{infoPost?.declarationContenu||'Aucun'}}</span></p><br></div>
          </div>
        </div>
        <div class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Indicateurs</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <div *ngFor="let indicateur of infoPost.ind">
              <p class="green-title">{{indicateur.name}}</p>
              <div class="flex" style="padding: 0px 0px 6px 0px;">
                <p style="font-weight: bold;">Indicateurs</p>
                <p class="float-right">Total cycle de vie</p>
              </div>
              <div *ngFor="let item of indicateur.data | keyvalue; let i=index">
                <div class="flex" style="padding: 5px 0px;" [ngStyle]="{'background': i%2==0?'#DFF8EC':'none'}">
                  {{item.key}}
                  <p class="float-right">{{(item.value||0) | formatPrice}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Santé</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <p class="green-title">Air intérieur</p>
            <div *ngIf="(infoPost.sante||{}).airRating"><p class="mini-title">Arrêté du 19 avril 2011 relatif à l'étiquetage des produits de construction ou de revêtement de mur ou de sol et des peintures et vernis sur leurs émissions de polluants volatils : <span class="span-black">{{infoPost?.sante?.airRating||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).eCovFormaldehyde"><p class="mini-title">Autres émissions de polluants volatils dans l'air intérieur hors etiquette réglementaire :<span class="span-black">{{infoPost?.sante?.eCovFormaldehyde||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).eRadioactive"><p class="mini-title">Émissions radioactives naturelles : <span class="span-black">{{infoPost?.sante?.eRadioactive||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).otherHealthInfo"><p class="mini-title">Autres informations sur la qualité sanitaire des espaces intérieurs : <span class="span-black">{{infoPost?.sante?.otherHealthInfo||'Aucun'}}</span></p><br></div>
            <p class="green-title">Sol et eau</p>
            <div *ngIf="(infoPost.sante||{}).infosDrinkingWater"><p class="mini-title">Le produit est-il en contact avec de l'eau destinée à la consommation humaine ? <span class="span-black">{{infoPost?.sante?.infosDrinkingWater||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).isContactNotDrinkingWater"><p class="mini-title">Le produit est-il en contact avec les eaux de ruissellement, les eaux d'infiltration, les eaux de surface ou les eaux de la nappe phréatique ? <span class="span-black">{{infoPost?.sante?.isContactNotDrinkingWater||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).infosNotDrinkingWater"><p class="mini-title">Informations sur la qualité des eaux de ruissellement, des eaux d'infiltration, des eaux de surface ou des eaux de la nappe phréatique <span class="span-black">{{infoPost?.sante?.infosNotDrinkingWater||'Aucun'}}</span></p><br></div>
          </div>
        </div>
        <div *ngIf="(infoPost||{}).confort.comfortHygrothermal || infoPost.confort.comfortAcoustic || infoPost.confort.comfortVisual || infoPost.confort.comfortOlfactory || infoPost.confort.otherComfortInfos" class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Confort</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <p *ngIf="(infoPost||{}).confort.comfortHygrothermal" class="green-title1">Confort hygrothermique</p><span class="span-black">{{infoPost?.confort?.comfortHygrothermal||'Aucun'}}</span><br>
            <p *ngIf="(infoPost||{}).confort.comfortAcoustic" class="green-title1">Confort acoustique</p><span class="span-black"> {{infoPost?.confort?.comfortAcoustic||'Aucun'}}</span><br>
            <p *ngIf="(infoPost||{}).confort.comfortVisual" class="green-title1">Confort visuel</p><span class="span-black">{{infoPost?.confort?.comfortVisual||'Aucun'}}</span><br>
            <p *ngIf="(infoPost||{}).confort.comfortOlfactory" class="green-title1">Confort olfactif</p><span class="span-black"> {{infoPost?.confort?.comfortOlfactory||'Aucun'}}</span><br>
            <p *ngIf="(infoPost||{}).confort.otherComfortInfos" class="green-title1">Autres informations sur le confort</p><span class="span-black">{{infoPost?.confort?.otherComfortInfos||'Aucun'}}</span><br>
          </div>
        </div>
      </div>
      <div class="margin-top-12 add-post-detail" *ngIf="detailModalFromSideBar" style="width: 100%;">
        <button class="btn btn-cards" style="background-color: #5A9E6F;width: 304px;" (click)="openConfirmationPost(infoPost)">Ajouter la fiche</button>
      </div>
    </div>
  </div>
</div>


<!-- Consomation Chantier Modal -->
<div id="estimer-consomation-chantier-modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title ag-heading-h4">Estimation de l’impact de la construction du batiment</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body" *ngIf="selectedZoneUsage == 1">

        <!-- Group -->
        <div class="sec-group">
          <p class="ag-para-2 ag-bold margin-bottom-12">Utilisation de grues</p>
          <div class="flex space-between center-items inputs grey-bg">
            <p class="no-margin-bottom justify-center ag-para-1">Quantité de matière importée</p>
            <div class="flex center-items">
              <input type="number" class="ag-input-1" placeholder="10"
                [(ngModel)]="estimerConsomationChantier.estiquantiteMatiereImportee2"
                (change)="estimationInputTyped('estiquantiteMatiereImportee2', $event.target.value)"
                >
              <span class="input-label ag-para-1">T</span>
            </div>
          </div>
          <!--  -->
          <div class="flex space-between center-items inputs">
            <p class="no-margin-bottom justify-center ag-para-1">Surface de plancher du RDC du batiment</p>
            <div class="flex center-items">
              <input type="number" class="ag-input-1" placeholder="10"
                [(ngModel)]="estimerConsomationChantier.estisurfacePlancherRDCBatiment"
                (change)="estimationInputTyped('estisurfacePlancherRDCBatiment', $event.target.value)">
              <span class="input-label ag-para-1">m²</span>
            </div>
          </div>
        </div>

      </div>

      <div class="modal-body" *ngIf="selectedZoneUsage > 1">

          <!-- Group -->
          <div class="sec-group">

            <p class="ag-para-2 ag-bold margin-bottom-12">Informations générales</p>

            <div class="flex space-between center-items inputs grey-bg">
              <p class="no-margin-bottom justify-center ag-para-1">Nombre de mois d’été avec grue(s)</p>
              <div class="flex center-items">
                <input type="number" class="ag-input-1" placeholder="10"
                  [(ngModel)]="estimerConsomationChantier.estiNombreMoisEteAvecGrue"
                  (change)="estimationInputTyped('estiNombreMoisEteAvecGrue', $event.target.value)">
                <span class="input-label ag-para-1">mois</span>
              </div>
            </div>
            <!--  -->
            <div class="flex space-between center-items inputs">
              <p class="no-margin-bottom justify-center ag-para-1">Nombre de mois d’hiver avec grue(s)</p>
              <div class="flex center-items">
                <input type="number" class="ag-input-1" placeholder="10"
                  [(ngModel)]="estimerConsomationChantier.estinombreMoisHiverAvecGrue"
                  (change)="estimationInputTyped('estinombreMoisHiverAvecGrue', $event.target.value)">
                <span class="input-label ag-para-1">mois</span>
              </div>
            </div>
            <!--  -->
            <div class="flex space-between center-items inputs">
              <p class="no-margin-bottom justify-center ag-para-1">Date de livraison du projet (prévue)</p>
              <div class="flex center-items">
                <input type="date" class="ag-input-1 month-input min-width-170"
                  [(ngModel)]="estimerConsomationChantier.DateLivraison"
                  (change)="ProjectInputTyped('DateLivraison', $event.target.value)"
                  >
                <span class="input-label ag-para-1"></span>
              </div>
            </div>
            <div class="flex space-between center-items inputs">
              <p class="no-margin-bottom justify-center ag-para-1">Durée du chantier</p>
              <div class="flex center-items">
                <input type="number" class="ag-input-1" placeholder="0"
                  [(ngModel)]="estimerConsomationChantier.DureeChantier"
                  (change)="ProjectInputTyped('DureeChantier', $event.target.value)"
                  >
                <span class="input-label ag-para-1">mois</span>

              </div>
            </div>

          </div>
          <!-- Group -->
          <div class="sec-group">
            <p class="ag-para-2 ag-bold margin-bottom-5">Terres</p>
            <p class="ag-para-1 ag-bold margin-bottom-12">Terres importées</p>
            <!-- <div class="flex space-between center-items inputs grey-bg">
              <p class="no-margin-bottom justify-center ag-para-1">Distance de la zone d'import au chantier</p>
              <div class="flex center-items">
                <input type="number" class="ag-input-1" placeholder="10"
                  [(ngModel)]="estimerConsomationChantier.estidistanceZoneImportChantier"
                  (change)="estimationInputTyped('estidistanceZoneImportChantier', $event.target.value)">
                <span class="input-label ag-para-1">km</span>
              </div>
            </div> -->
            <!--  -->
            <div class="flex space-between center-items inputs">
              <p class="no-margin-bottom justify-center ag-para-1">Quantité de matière importée</p>
              <div class="flex center-items">
                <input type="number" class="ag-input-1" placeholder="10"
                  [(ngModel)]="estimerConsomationChantier.estiquantiteMatiereImportee"
                  (change)="estimationInputTyped('estiquantiteMatiereImportee', $event.target.value)">
                <span class="input-label ag-para-1">T</span>
              </div>
            </div>
          </div>
          <!-- Group -->
          <div class="sec-group">
            <p class="ag-para-2 ag-bold margin-bottom-12">Terres évacuées </p>
            <div class="flex space-between center-items inputs grey-bg">
              <p class="no-margin-bottom justify-center ag-para-1">Quantité de terres évacuées</p>
              <div class="flex center-items">
                <input type="number" class="ag-input-1" placeholder="10"
                  [(ngModel)]="estimerConsomationChantier.estiquantiteTerresEvacuees"
                  (change)="estimationInputTyped('estiquantiteTerresEvacuees', $event.target.value)">
                <span class="input-label ag-para-1">t</span>
              </div>
            </div>
          </div>
          <!-- Group -->
          <div class="sec-group">
            <p class="ag-para-2 ag-bold margin-bottom-12">Terres excavées</p>
            <div class="flex space-between center-items inputs grey-bg">
              <p class="no-margin-bottom justify-center ag-para-1">Volume de terres excavées lors du chantier</p>
              <div class="flex center-items">
                <input type="number" class="ag-input-1" placeholder="10"
                  [(ngModel)]="estimerConsomationChantier.estivolumeTerresExcaveesLorsChantier"
                  (change)="estimationInputTyped('estivolumeTerresExcaveesLorsChantier', $event.target.value)">
                <span class="input-label ag-para-1">m³</span>
              </div>
            </div>
          </div>

      </div>
      <div class="modal-footer text-center">
        <button type="button" class="ag-btn-primary-sm" (click)="addDonnesChantierCalculations()">
          <span *ngIf="!loadingCalculs">Remplir les données de chantier</span>
          <app-spinner *ngIf="loadingCalculs"></app-spinner>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- END Consomation Chantier Modal -->
<div id="frirogene-modal"
  class="modal fade frirogene-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-container">
      <p class="modal-title">Choisir le fluide frigorigène correspondant :</p>
      <div class="detail-container scroller-1">
        <ul *ngFor="let item of frirogeneResult;let i=index" [ngClass]="{'selected-friro': selectedFriroi==i}">
          <li (click)="selectFriro(i)">{{item.nom}}</li>
        </ul>
      </div>
      <div class="button-container" style="justify-content: left;">
        <button class="btn btn-flat" (click)='validateFriro()' data-dismiss="modal">Valider le nouveau fluide frigorigène</button>
      </div>
    </div>
  </div>
</div>
