export class LotPricing {
    private _idLot: string;
    private _idPricing: string;
    private _artisanName: string;
    private _total: number;
    private _dpgfId: string;
    private _posts: any[];
    private _currentVersion: any[];
    private _color: string;
    private _sousLoti: number;
    private _loti: number;
    private _isModifying: boolean;

    constructor(idLot: string, idPricing: string, artisanName:string, total: number, dpgfId: string ) {
        this._idLot = idLot;
        this._idPricing = idPricing;
        this._artisanName = artisanName;
        this._total = total;
        this._dpgfId = dpgfId;
        this._posts = [];
    }
//#region Poperties
    public get idLot(): string {
        return this._idLot
    }
    public set idLot(idLot: string) {
        this._idLot = idLot;
    }
    public get idpricing(): string {
        return this._idPricing
    }
    public set idpricing(idPricing: string) {
        this._idPricing = idPricing;
    }
    public get artisanName(): string {
        return this._artisanName;
    }
    public set artisanName(artisanName: string) {
        this._artisanName = artisanName;
    }
    public get total(): number {
        return this._total
    }
    public set total(total: number) {
        this._total = total;
    }
    public get dpgfId(): string {
        return this._dpgfId;
    }
    public set dpgfId(id: string) {
        this._dpgfId = id;
    }
    public set posts(posts: any[]) {
        this._posts = posts;
    }
    public get posts(): any[] {
        return this._posts;
    }
    public get loti(): number {
        return this._loti;
    }
    public set loti(i){
        this._loti = i;
    }
    public set currentVersion(v: any[]){
        this._currentVersion = v;
    }
    public get currentVersion(): any[] {
        return this._currentVersion;
    }
    public get color(): string {
        return this._color;
    }
    public set color(c: string) {
        this._color = c;
    }
    public get isModifying(): boolean {
        return this._isModifying;
    }
    public set isModifying(value: boolean) {
        this._isModifying = value;
    }
}
