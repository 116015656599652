import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';

export class Helpers {

  private CE_PROJECT_INVITE_PRICING_STATUS_MAPPER: any = {
    "no-pricing": {
      "artisan-invite": "a_consulter"
    },
    "pricing-sent": {
      "artisan-invite": "en_attente",
      "artisan-accepter": "en_cours",
      "selectionne": "en_cours",
      "refuse": "en_cours",
      "artisan-refuser": "ne_chiffre_pas",
      "signe": "offre_remise"
    },
    "pricing-done": {
      "artisan-invite": "offre_remise",
      "artisan-accepter": "offre_remise",
      "selectionne": "offre_remise",
      "refuse": "offre_remise",
      "signe": "offre_remise",
    },
  } 
  
  public CE_PROJECT_INVITE_PRICING_STATUS_LABEL: any ={
    "a_consulter": "A consulter",
    "en_attente": "En attente",
    "en_cours": "En cours",
    "offre_remise": "Offre remise",
    "ne_chiffre_pas": "Ne chiffre pas",
  }
  
  getHeader() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'x-access-token': currentUser.token
      });
      return {headers : headers};
    }else{
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      });
      return {headers : headers};
    }
  }

  // this function returns one of the new status : 'offre_remise', 'en_cours', 'en_attente', 'a_consulter', 'ne_chiffre_pas', 
  // @params : ceProjectInvite['status']
  // @params : ceProjectInvite['pricingStatus']
  public getCeProjetInviteStatus(status, pricingStatus){
    if(status == 'artisan-refuser') return "ne_chiffre_pas";
    
    const pricingStatusObj = this.CE_PROJECT_INVITE_PRICING_STATUS_MAPPER[pricingStatus];
    const newProjectInviteStatus = pricingStatusObj[status];

    return newProjectInviteStatus;
  }

  // this function returns the label of the new status (input) : a_consulter, en_attente, en_cours,offre_remise,ne_chiffre_pas
  // @params : string
  public pricingStatusLabel(status){
    return this.CE_PROJECT_INVITE_PRICING_STATUS_LABEL[status];
  }


}
