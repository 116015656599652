<div id="doc-file-is-downloaded-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle">
    <div class="modal-content padding-16">
      <div class="modal-body no-padding">

        <div class="flex-horizontal space-between">
          <h4 class="ag-heading-h4 ag-weight-400 no-padding no-margin margin-bottom-16">Votre fichier word est en cours de génération</h4>
          <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
        </div>        
        
        <p class="ag-para-1-sm margin-bottom-16 ag-weight-400">A l’ouverture du fichier, acceptez la demande de mise à jour des champs pour que le sommaire se génère automatiquement.</p>
        <p class="margin-bottom-16">
          <input type="checkbox" [(ngModel)]="dontShowTheMessage">
          <span class="margin-left-12">Ne plus afficher ce message</span>
        </p>

      </div>
      <div class="modal-footer text-right no-border-top no-padding">
        <button type="button" class="ag-btn-primary-sm" (click)="submit()">J’ai compris</button>
      </div>
    </div>
  </div>
</div>

