import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArchitectService } from 'src/app/_services';
//import { ArchitectModule } from '../architect.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

declare var $ : any;

@Component({
    selector: 'app-archi-reef-redirect',
    imports:[
      
      NgSharedModule
    ],
    templateUrl: './archi-reef-redirect.component.html',
    styleUrls: ['./archi-reef-redirect.component.scss'],
    standalone: true,

})
export class ArchiReefRedirectComponent implements OnInit {
  docId: any;
  doctype: any;
  isLibrary: any;

  constructor(private architectService:ArchitectService,private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    $('#reef').modal({backdrop: 'static', keyboard: false})  
    this.route.queryParams.subscribe(params => {
      this.docId = (params['docId']||'');
      this.doctype = (params['docType']||'');
      if(!environment.onPremise){
        if(params['library'])window.analytics.track('reef_from_library',{properties:{acValue: 'reef_from_library'}});
        else window.analytics.track('reef_from_post',{properties:{acValue: 'reef_from_post'}});
      }
    });
  }
  hasIt(){
    this.architectService.accessReef({docType:this.doctype,docId:this.docId},true).subscribe(
      (data)=>{
        window.location.href = data;
      },(err)=>{
        console.log('err',err);
      }
    )
  }
  ngOnDestroy(): void {
    $('#reef').modal('hide');
  }
}
