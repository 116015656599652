<div class="outer-wrapper">

  <!-- Vous êtes :  section block -->
  <div class="section-block">
    <p class="ag-para-2 ag-weight-600 margin-bottom-8">Vous êtes : </p>
    <div class="checkboxes-container horizontal-items">
      <span class="checkbox-item" 
        *ngFor="let typeItem of typeList" 
        (click)="addToFormData('type', typeItem.value)"
        [ngClass]="{'choosed' : informationData['type'].includes(typeItem.value) }"
        >{{ typeItem.name }}</span>
    </div>
  </div>
  <!-- END Vous êtes : section block -->

  <!-- travaux pouvez-vous intervenir :  section block -->
  <div class="section-block">
    <p class="ag-para-2 ag-weight-600 margin-bottom-8">Sur quel type de travaux pouvez-vous intervenir ?</p>
    <div class="checkboxes-container horizontal-items">
      
      <span class="checkbox-item" 
        *ngFor="let travauxItem of travauxList" 
        (click)="addToFormData('travaux', travauxItem.value)"
        [ngClass]="{'choosed' : informationData['travaux'].includes(travauxItem.value) }"
        >{{ travauxItem.name }}</span>
    </div>
  </div>
  <!-- END travaux pouvez-vous intervenir : section block -->

  <!-- programmes prenez-vous en charge :  section block -->
  <div class="section-block">
    <p class="ag-para-2 ag-weight-600 margin-bottom-8">Quels programmes prenez-vous en charge ?</p>
    <div class="checkboxes-container horizontal-items">
      
      <span class="checkbox-item" 
        *ngFor="let programmeItem of programmesList" 
        (click)="addToFormData('programme', programmeItem.value)"
        [ngClass]="{'choosed' : informationData['programme'].includes(programmeItem.value) }"
        >{{ programmeItem.name }}</span>
    </div>
  </div>
  <!-- END programmes prenez-vous en charge : section block -->

  <!-- année avez-vous crée votre entreprise : section block -->
  <div class="section-block">
    <p class="ag-para-2 ag-weight-600 margin-bottom-8">En quelle année avez-vous crée votre entreprise ? </p>
    <div class="input-item-container">
      <input type="number" [(ngModel)]="informationData.creationYearAgency" 
        (ngModelChange)="dataChanged(informationData.creationYearAgency)" #creationYearAgency>
    </div>
  </div>
  <!-- END année avez-vous crée votre entreprise : section block -->
  
  <!-- année avez-vous crée votre entreprise : section block -->
  <div class="section-block">
    <p class="ag-para-2 ag-weight-600 margin-bottom-8">Chiffre d’affaire</p>
    <p>Pour l’année précédente, quel a été le chiffre d’affaire de votre entreprise ?</p>
    <div class="input-item-container">
      <img src="/assets/euro-icon.svg" class="right-drawable" alt="">
      <input type="number" [(ngModel)]="informationData.ca"
        (ngModelChange)="dataChanged(informationData.ca)" #ca>
    </div>
  </div>
  <!-- END année avez-vous crée votre entreprise : section block -->

</div>