<app-header></app-header>
<app-spinner *ngIf="!loadingPage"></app-spinner>
<div class="all flex justify-center">
<div *ngIf="loadingPage" style="width:90%">
    <div >
      <h1 class="title-user">Utilisateurs</h1>
    </div>
    <div class="flex padding-bottom-10 padding-top-10">
        <!-- <label class="padding-right-10 padding-top-21"></label> -->
        <input type="text" class="wid300" [(ngModel)]="searchText" placeholder="Chercher un utilisateur">
        <button class="button-search"(click)="getUsers()" ><i class="fa fa-search" aria-hidden="true"></i></button>
        <div class="justify-end flex wid100">
          <button class="button-search" data-toggle="modal" data-target="#create-user">
            + Créer un utilisateur
          </button>
        </div>
    </div>
    <table class="table">
        <thead>
          <tr>
            <!-- <th scope="col">Utilisateurs</th> -->
            <th scope="col">Nom</th>
            <th scope="col">Prenom</th>
            <th scope="col">Email</th>
            <th scope="col">Num de telephone</th>
            <th scope="col">Nom du département</th>
            <th scope="col" class="center">Opérations</th>
          </tr>
        </thead>
        <tbody >
          <tr *ngFor="let item of cinquanteUsers;let i = index; ">
            <!-- <th scope="row">{{page*50+i+1}}</th> -->
            <td> {{item?.lastName || ''}} </td>
            <td> {{item?.firstName || ''}} </td>
            <td> {{item?.email || ''}} </td>
            <td> {{item?.phones[0] || ''}} </td>
            <td> {{item?.departementName || ''}} </td>
            <td  class="flex space-evenly">  
            <div class="cursor" data-toggle="modal" data-target="#update-user-info" (click)="sendDataUser(item)"> <i class="fa fa-pencil" pTooltip="Modifier"  aria-hidden="true" ></i>
            </div>
            <div class="cursor" data-toggle="modal" data-target="#reset-password" (click)="sendDataUser(item)"> <i class="fa fa-key" pTooltip="Rénitialiser le mot de passe"  aria-hidden="true" ></i>
            </div>
            <div class="cursor" data-toggle="modal" data-target="#delete-user" (click)="sendDataUser(item)"><i class="fa fa-solid fa-trash" pTooltip="Supprimer"></i>
            </div>
            </td>
            <!-- <td  class="cursor"  data-toggle="modal" data-target="#delete-user" (click)="sendDataUser(item)"><i class="fa fa-solid fa-trash"></i></td> -->
          </tr>
        </tbody>
    </table>
    <div class="flex justify-center">
      <ul class="pagination">
          <li *ngFor="let number of numbers; let i = index" ><a (click)="changePage(i)" [ngClass]="{ 'actived': page == i}">{{number}}</a></li>
      </ul>
      </div>
</div>
</div>
<app-modal-update-user [userInfo]="user"></app-modal-update-user>
<app-modal-create-user (userCreated)="addNewUser($event)"></app-modal-create-user>
<app-modal-confirmation-to-delete-user [user]="user" (userValidation)="deleteUser($event)"></app-modal-confirmation-to-delete-user>
<!-- <app-modal-reset-password (linkForReset)="showModalForReset($event)" [user]="user"></app-modal-reset-password> -->

<div class="modal" id="reset-password" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body padding42-bottom20" *ngIf="isFirstStep">
        <div class="title-text">
         <h2> Rénitialisation du mot de passe</h2>
      </div>
      <div>
          <p>Un email va être envoyé a cette adresse <span class="bold-black">{{user?.email}}.</span> pour la rénitialisation du mots de passe</p>
      </div>
      <div class="modal-footer" >
        <div class="flex float-right">
        <button type="button" class="save-button" data-dismiss="modal">Annuler</button>
        <button type="button" class="save-button"  >
          <p *ngIf="!loading" (click)="forgetPassword()" class="no-margin">Valider</p>
          <div *ngIf="loading" style="margin-top:-7px;">
            <app-spinner ></app-spinner>
          </div>
        </button>
      </div>
     </div>
      </div>
      <div class="modal-body padding42-bottom20" *ngIf="!isFirstStep">
        <div class="title-text">
         <h2>Lien de rénitialisation</h2>
      </div>
      <div>
          <p>Voici le lien de rénitialisation du mot de passe pour <span class="bold-black">{{user?.email}}.</span></p>
          <span> {{user?.linkToCopy}}</span>
      </div>
      <div class="modal-footer" >
        <div class="flex justify-center">
        <button type="button" class="annuler-button" data-dismiss="modal">Fermer</button>
        </div>
     </div>
      </div>
    </div>
</div>