import { Component, OnInit, Input, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  standalone:true,
  selector: 'app-spinner-with-quotes',
  imports: [NgSharedModule],
  templateUrl: './spinner-with-quotes.component.html',
  styleUrls: ['./spinner-with-quotes.component.scss']
})
export class SpinnerWithQuotesComponent implements OnInit {
  @Input() addVerticalPadding: boolean = false;

  public randomNumber: number = 0;
  
  public quotesList: any = [
    `
    <div class="horizontal-items justify-center">
      <p class="ag-para-1 ag-weight-700 margin-right-10">Good to know</p>
      <img src="/assets/thumbs-up.svg"  />
    </div> 
     <p class="ag-para-1 margin-top-8">
      Avec AGLO Booster, élargissez vos consultations d'entreprises à des entreprises du BTP pertinentes pour votre projet, notées et recommandées par des architectes et maîtres d'ouvrage.
     </p>
     `,
    `
    <div class="horizontal-items justify-center">
      <p class="ag-para-1 ag-weight-700 margin-right-10">Le saviez-vous</p>
      <img src="/assets/bulb.svg"  />
    </div> 
    <p class="ag-para-1 margin-top-8">
      AGLO est né en 2019 sur un ras-le-bol: il était temps d'avoir une app simple et sympa pour faciliter la rédaction des pièces écrites du DCE et d'accélérer les consultations d'entreprises.
    </p>
    `,
    `
    <div class="horizontal-items justify-center">
      <p class="ag-para-1 ag-weight-700 margin-right-10">Good to know</p>
      <img src="/assets/thumbs-up.svg"  />
    </div> 
      <p class="ag-para-1 margin-top-8">
        Avec AGLO bénéficiez des 40 000 descriptifs techniques du CSTB directement dans l'application.
      </p>
    `,
    `
    <div class="horizontal-items justify-center">
      <p class="ag-para-1 ag-weight-700 margin-right-10">Le saviez-vous</p>
      <img src="/assets/bulb.svg"  />
    </div> 
    <p class="ag-para-1 margin-top-8">
      AGLO c'est une équipe répartie entre Marseille, Paris et Casablanca. Notre but: rendre plus simple et plus sympa la phase DCE (de la rédaction des pièces écrites jusqu'à la consultation des entreprises).
    </p>
    `,
    `
    <div class="horizontal-items justify-center">
      <p class="ag-para-1 ag-weight-700 margin-right-10">Le saviez-vous</p>
      <img src="/assets/bulb.svg"  />
    </div> 
    <p class="ag-para-1 margin-top-8">
      AGLO fait partie du groupe BAM (Because Architecture Matters) fondé en 2015 dont l'activité historique est de réinventer l'organisation de concours d'architecture pour des MOA privés et publics.
    </p>
    `,
    `
    <div class="horizontal-items justify-center">
      <p class="ag-para-1 ag-weight-700 margin-right-10">Good to know</p>
      <img src="/assets/thumbs-up.svg"  />
    </div> 
    <p class="ag-para-1 margin-top-8">
      AGLO est également une app de consultation d'entreprises. Vous partagez vos DCE aux entreprises via AGLO et elles répondent chiffrent sur la plateforme.
    </p>
    `,
    `
    <div class="horizontal-items justify-center">
      <p class="ag-para-1 ag-weight-700 margin-right-10">Pas de planète B</p>
      <img src="/assets/earth.svg"  />
    </div> 
    <p class="ag-para-1 margin-top-8">
      La construction émet 30% des émissions de carbone. 80% de ces émissions peuvent être évitées lors de la conception en faisant les bons choix
    </p>
    `,
    `
    <div class="horizontal-items justify-center">
      <p class="ag-para-1 ag-weight-700 margin-right-10">Pas de planète B</p>
      <img src="/assets/earth.svg"  />
    </div> 
    <p class="ag-para-1 margin-top-8">
      Architectes, B.E, MOA, à vous seuls pouvez réduire de 25% les émissions de carbone en faisant les bons choix lors de la conception.
    </p>
    `,

    `
    <div class="horizontal-items justify-center">
      <p class="ag-para-1 ag-weight-700 margin-right-10">Pas de planète B</p>
      <img src="/assets/thumbs-up.svg"  />
    </div> 
      <p class="ag-para-1 margin-top-8">
        Avec AGLO Carbone calculez automatiquement l'empreinte carbone de votre projet directement en rédigeant votre DCE.
      </p>
      `,
    `
    <div class="horizontal-items justify-center">
      <p class="ag-para-1 ag-weight-700 margin-right-10">Good to know</p>
      <img src="/assets/thumbs-up.svg"  />
    </div> 
      <p class="ag-para-1 margin-top-8">
      Avec AGLO vous pouvez constituer votre propre bibliothèque de descriptifs avec vos estimatifs prix. Plus besoin de chercher dans vos anciens dossiers, tout est centralisé et à jour.
      </p>
      `,
    `
    <div class="horizontal-items justify-center">
      <p class="ag-para-1 ag-weight-700 margin-right-10">Pas de planète B</p>
      <img src="/assets/earth.svg"  />
    </div> 
    <p class="ag-para-1 margin-top-8">
      La première étape pour pourvoir réduire l'empreinte carbone de ses projets c'est déjà de savoir la calculer. #Aglocarbone
    </p>
    `,
    `
    <p class="ag-para-1 margin-top-8">
      Veuillez patientez, je compare votre requête avec toutes les données à ma disposition pour vous fournir un descriptif de qualité 
    </p>
    `,
    `
    <p class="ag-para-1 margin-top-8">
      Laissez-moi explorer les profondeurs de mon répertoire de savoir technique. 
    </p>
    `,
    `
    <p class="ag-para-1 margin-top-8">
      Requête pertinente ! Je vais tâcher d'y répondre au mieux, laissez-moi quelques instants 
    </p>
    `,
    `
    <p class="ag-para-1 margin-top-8">
      Pendant que je réponds à votre requête j'en profite pour vous poser à mon tour une question dont je n'ai ici pas la réponse: cette prescription est sans doute la meilleure pour le projet mais est-elle la meilleure pour notre chère planète
    </p>
    `,
  ];

  constructor(
  ) { }

  ngOnInit(): void {
    this.randomNumber = Math.floor(Math.random()*this.quotesList.length);
  }

}
