import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";
import { Helpers } from './helpers'

@Injectable()
export class AttributService {
    public token: string;

    constructor(private http: HttpClient) {
    }
    create(data): Observable<boolean> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.post('/api/v1/attributs?type=' + data.type, body, options)
            .pipe(map((response: any) => {
                return true;
            }));
    }
    update(attributID, data): Observable<boolean> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.patch('/api/v1/attributs/' + attributID + '?type=' + data.type, body, options)
            .pipe(map((response: any) => {
                return true;
            }));
    }
    getAll(attributType) : Observable<any[]>{
      let options = new Helpers().getHeader();
        return this.http.get('/api/v1/attributs?type=' + attributType , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    getAllActive(attributType) : Observable<any[]>{
      let options = new Helpers().getHeader();
        return this.http.get('/api/v1/attributs?type=' + attributType +'&status=A', options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    getRelationTags (){
      return [
        {value: "general", name: "Tout corps d'états"},
        {
          value: "Démolition - Dépose",
          name: "Démolition - Dépose",
        },
        {
          value: "Désamiantage",
          name: "Désamiantage",
        },
        {
          value: "Terrassement - Soutènements - Infrastructures",
          name: "Terrassement - Soutènements - Infrastructures",
        },
        {
          value: "Maçonnerie - Gros-œuvre",
          name: "Maçonnerie - Gros-œuvre",
        },
        {
          value: "Ossature bois/métallique",
          name: "Ossature bois/métallique",
        },

        {
          value: "Charpente",
          name: "Charpente",
        },
        {
          value: "Couverture / Etanchéité",
          name: "Couverture / Etanchéité",
        },
        {
          value: "Façades",
          name: "Façades",
        },
        {
          value: "Menuiserie Extérieure",
          name: "Menuiserie Extérieure",
        },
        {
          value: "Stores - Fermetures - Occultations",
          name: "Stores - Fermetures - Occultations",
        },
        {
          value: "Miroiterie - Vitrerie",
          name: "Miroiterie - Vitrerie",
        },
        {
          value: "Cloisons - Doublages - Faux-plafonds",
          name: "Cloisons - Doublages - Faux-plafonds",
        },
        {
          value: "Menuiserie Intérieure - Agencement",
          name: "Menuiserie Intérieure - Agencement",
        },
        {
          value: "Revêtement de sol",
          name: "Revêtement de sol",
        },
        {
          value: "Carrelage - Faience",
          name: "Carrelage - Faience",
        },
        {
          value: "Peinture - Revêtements Muraux - Nettoyage",
          name: "Peinture - Revêtements Muraux - Nettoyage",
        },
        {
          value: "Signalétique",
          name: "Signalétique",
        },
        {
          value: "Mobilier",
          name: "Mobilier",
        },
        {
          value: "Electricité",
          name: "Electricité",
        },
        {
          value: "Alarmes - Surveillance",
          name: "Alarmes - Surveillance",
        },
        {
          value: "Plomberie - Sanitaire",
          name: "Plomberie - Sanitaire",
        },
        {
          value: "Chauffage - Ventilation - Climatisation (CVC)",
          name: "Chauffage - Ventilation - Climatisation (CVC)",
        },
        {
          value: "Gaz",
          name: "Gaz",
        },
        {
          value: "Énergies Renouvelables",
          name: "Énergies Renouvelables",
        },
        {
          value: "Ascenseurs",
          name: "Ascenseurs",
        },
        {
          value: "Equipements techniques",
          name: "Equipements techniques",
        },
        {
          value: "Voirie et Réseaux Divers",
          name: "Voirie et Réseaux Divers",
        },
        {
          value: "Serrurerie",
          name: "Serrurerie",
        },
        {
          value: "Assainissement - Récupération/Traitement des eaux",
          name: "Assainissement - Récupération/Traitement des eaux",
        },
        {
          value: "Aménagements extérieurs - Espaces verts",
          name: "Aménagements extérieurs - Espaces verts",
        },
        {
          value: "Piscine",
          name: "Piscine",
        }
      ]
    }

}
