<div id="garantie-decennale-view-only-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="ag-heading-h4 ag-weight-600 text-center no-margin">Garantie décénnale</h4>
        
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">
        <p class="ag-para-1 text-center" *ngIf="!isPublicProfile">Activités professionnelles garanties par votre assurance décénnale</p>
        <p class="ag-para-1 text-center" *ngIf="isPublicProfile">Activités professionnelles garanties par l'assurance décénnale de l'entreprise</p>

        <div class="outer-wrapper scroller-1 margin-top-10">

          <!-- Single Activity Item -->
          <div class="single-activity-item horizontal-items" *ngFor="let agencyActivity of agencyActivitiesList">
            <ng-container *ngIf="!agencyActivity.isCustom">

            <!-- Icon Side -->
            <div class="icon-side text-center width-15" *ngIf="false">
              <img [src]="'/assets/corps-etats/'+agencyActivity.icon">
              <h5 class="ag-heading-h5 ag-weight-600 margin-vertical-8" [innerHtml]="agencyActivity.title"></h5>
              <p class="ag-para-1">
                <input type="checkbox"> <span class="margin-left-12">Tout séléctionner</span>
              </p>
            </div>
            <!-- END Icon Side -->
            
            <!-- Checkboxes Side -->
            <div class="inputs-side padding-left-50 width-85">
              
              <!-- single check boxes section -->
              <div class="single-checkboxs-section" 
                *ngFor="let singleCheckboxSection of agencyActivity.sectionsLists; let i = index;"
                [ngClass]="{'hidden-item': !singleCheckboxSection.list.length}"
                >
                <h6 class="ag-heading-h6 ag-heading-h6 margin-bottom-7">{{ singleCheckboxSection.title }}</h6>
                <div class="check-boxes-list horizontal-items">
                  <span class="checkbox-item" 
                    *ngFor="let checkboxItem of singleCheckboxSection.list; let k = index;"
                    [ngClass]="{'choosed': selectedItems.includes(agencyActivity.id+'_'+singleCheckboxSection.id+'_'+checkboxItem.value)}"
                    
                    >{{ checkboxItem.label }}</span>
                </div>
              </div>
              <!-- END single check boxes section -->

            </div>
            <!-- END Checkboxes Side -->
            </ng-container>
          </div>
          <!-- END Single Activity Item -->

          <!-- Add new corps d’état Button -->
          <div class="add-new-corps-etat-wrapper horizontal-items margin-top-20">

            <div class="custom-coprs-list padding-left-50 horizontal-items width-75">
              <span class="checkbox-item" 
                *ngFor="let customCheckboxItem of customAgencyActivitiesList">
                {{ customCheckboxItem.label }}
              </span>
            </div>

          </div>
          <!-- END Add new corps d’état Button -->

        </div>
        
      </div>
    </div>
  </div>
</div>
