import { Component, OnInit, Input, Output, EventEmitter, NO_ERRORS_SCHEMA, Renderer2, ElementRef, ViewChild } from '@angular/core';

import { NgSharedModule } from 'src/app/all/ngshared.module';
import { FileUpload } from 'primeng/fileupload';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/_services';

@Component({
    standalone: true,
    selector: 'app-booster-profile-documents-form',
    imports:[
      NgSharedModule
    ],
    templateUrl: './booster-profile-documents-form.component.html',
    styleUrls: ['./booster-profile-documents-form.component.scss'],
})
export class BoosterProfileDocumentsFormComponent implements OnInit {
  @ViewChild('fileUpload1') fileUpload: FileUpload;
  @Input() docsData: any = {};
  @Output() updateDocument: EventEmitter<any> = new EventEmitter<any>();
  @Output() formIsValid: EventEmitter<any> = new EventEmitter<any>();

  public showLogoImage: boolean = false;
  public filesLoading: number[] = [];
  public firstValueEnvirenementObservable = new Observable<boolean>(observer => {
    observer.next(environment.onPremise);
    observer.complete();
  });
  constructor(private renderer: Renderer2, private el: ElementRef,public authService:AuthService) { }

  ngOnInit(): void { }
  ngAfterViewInit(){
    setTimeout(() => {
      this.emitFormIsValid();
    }, 200);
  }
  public onUploadArtisanLogo(event){
    let currentFile = event.originalEvent.body[0];
    let data = {logo: currentFile};
    this.emitUpdateDocument(data);
    /// show added files on interface
    this.docsData.logo = currentFile;
  }
  public onUploadAssuranceFile(event){
    let currentFile = event.originalEvent.body[0];
    let assuranceData = {assuranceFile: currentFile};
    this.emitUpdateDocument(assuranceData);
    /// show added files on interface
    this.docsData.assuranceFile = currentFile;
  }
  public onUploadKbisFiles(event){
    let currentFile = event.originalEvent.body[0];
    this.filesLoading.pop();
    let kbisData = {kbis: currentFile}
    this.emitUpdateDocument(kbisData);
    this.docsData.kbis = currentFile;
  }
  public onUploadAttestationsCertification(event){
    let currentFiles = event.originalEvent.body;
    let artisanFiles = [...this.docsData.attestationsCertification, ...currentFiles];
    this.docsData.attestationsCertification = artisanFiles;
  }
 
  public onUploadOthers(event){
    let currentFile = event.originalEvent.body;
    let artisanFiles = [...this.docsData.files,...currentFile];
    let artisanFilesData = {files: artisanFiles};
    this.emitUpdateDocument(artisanFilesData);
    /// show added files on interface
    this.docsData.files = artisanFiles;
  }

  public removeDocument(documentType, index=-1){
    
    if(index>-1){
      let filteredDocuments = this.docsData[documentType].filter((item, i) => i != index);

      this.emitUpdateDocument({[documentType]: filteredDocuments});

      /// remove data from the view
      this.docsData[documentType] = filteredDocuments;
    }else{
      this.emitUpdateDocument({[documentType]: {}});
    
      /// remove data from the view
      this.docsData[documentType] = {};
    }    
  }


  private emitUpdateDocument(document){
    this.updateDocument.emit(document);
  }

  private emitFormIsValid(){
    this.formIsValid.emit(true);
  }
  openPfileUpload(){
    const fileInput = this.el.nativeElement.querySelector('input[type="file"]');
    if (fileInput) {
      this.renderer.setProperty(fileInput, 'value', null);
      fileInput.click();
    }
  }
}
