import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ArtisanService } from '../../../_services/index';
import { Subscription } from 'rxjs/Subscription';
import { NgSharedModule } from 'src/app/all/ngshared.module';

import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';

@Component({
    selector: 'app-artisan-edit-info-entreprise',
    imports:[
    SpinnerComponent,
    NgSharedModule
  ],
    templateUrl: './artisan-edit-info-entreprise.component.html',
    styleUrls: ['./artisan-edit-info-entreprise.component.scss'],
    standalone: true,

})
export class ArtisanEditInfoEntrepriseComponent implements OnInit {
  @Input() artisan: any;
  @Output() artisanData: EventEmitter<any> = new EventEmitter<any>();
  public isAdminLoggedIn: boolean = false;

  public subscriptionForm: UntypedFormGroup;artisanId;disabled= true;loadingButton:boolean=false;
  public formErrors = {
    address: "",
    assuranceNumber: "",
    siret: "",
    tvaNumber: "",
    name: "",
    assurancesName: "",
    telAgency:""
  };
  private subscriptions: Array<Subscription> = [];

  private validationMessages = {
    assuranceNumber: {
      required: "Le N° d'assurance est obligatoire."
    },
    address: {
      required: "L'adresse est obligatoire."
    },
    siret: {
      required: "Le siret est obligatoire."
    },
    tvaNumber: {
      required: "Le taux de taxe est obligatoire."
    },
    name: {
      required: "Le nom de l'entreprise est obligatoire."
    },
    assurancesName: {
      required: "Le nom de l'assurance est obligatoire."
    },
    telAgency: {
      required: "Le téléphone de l'entreprise est obligatoire."
    }
  };
  constructor(private fb: UntypedFormBuilder,private artisanService: ArtisanService) { }

  ngOnInit(): void {
    this.createForm();
    this.subscriptionForm.patchValue(this.artisan || {});
    this.isAdminLoggedIn = this.checkAuthUserIsAdmin();
  }
  private createForm() {
    this.subscriptionForm = this.fb.group({
      address: ['', Validators.required],
      assuranceNumber: ['', Validators.required],
      siret: ['', [Validators.required]],
      tvaNumber: ['', Validators.required],
      name: ['', [Validators.required]],
      assurancesName: ['', Validators.required],
      telAgency: ['', Validators.required]
    });
    this.subscriptions.push(
      this.subscriptionForm.valueChanges.subscribe(data => this.onValueChanged(data))
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    this.createForm();
    if (changes["artisan"] && this.subscriptionForm) {
      this.artisanId = changes["artisan"].currentValue._id
      this.artisan = changes["artisan"].currentValue;
      this.subscriptionForm.patchValue(changes["artisan"].currentValue || {});
    }
  }
  private checkAuthUserIsAdmin(){
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let isAdmin = currentUser["isAdmin"] || false;

    return isAdmin;
  }
  public UpdateUserData(){
    let body = this.subscriptionForm.value
    this.loadingButton = true;
    if(!this.isAdminLoggedIn && !this.isValid()){
      return;
    }

    this.subscriptions.push(
      this.artisanService.updateDataAgency(this.artisanId,body)
        .subscribe(
            data => {
              this.disabled = true;
              this.artisan = {...this.artisan,...body};
              this.artisanData.emit(this.artisan)
              this.loadingButton = false;
            },
            error => {
              console.log("error",error)
            }
        )
    );
  }
  public valueChanged (val){
    this.disabled = false;
  }
  private onValueChanged(data ? : any) {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
            this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
  public markFormAsTouched():void {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      control.markAsDirty();
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
    }
  }
  public isValid(){
    this.markFormAsTouched();
    return this.subscriptionForm.valid
  }

  public getFormData() {
    return this.subscriptionForm.value;
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
}
}
