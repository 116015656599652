<div id="documents-errors-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header">
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">
        <div class="warning-header-wrapper margin-bottom-27">
          <div class="warning-header flex">
            <div class="flex-middle">
              <img src="/assets/docs_error_icon.svg">
            </div>
            <h3 class="ag-heading-downriver-h3 no-margin margin-left-10">Le téléchargement du PDF a échoué</h3>
          </div>
        </div>
        
        <p class="error-location ag-para-3 ag-weight-400" [innerHtml]="messageLocation"></p>
      </div>
      <div class="modal-footer text-center">
        <button type="button" data-dismiss="modal" class="ag-btn-tertiary-sm margin-right-20">Ignorer</button>
        <button type="button" (click)="goToErrorLocation()" class="ag-btn-primary-sm submit-btn">Aller à l’erreur</button>
      </div>
    </div>
  </div>
</div>
