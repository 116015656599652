<div class="modal-body">
  <div class="options" *ngIf="!uploadedCsv && !isPaste">
    <div class="option-item">
      <p style="font-weight: 700;">Coller depuis Excel</p>
      <p style="margin-bottom: 14px;">Si vous souhaitez importer uniquement une partie des données de votre excel, copiez-la puis collez-la sur la zone de texte ci-dessous</p>
      <span pTooltip="Cliquez sur le texte et collez depuis votre tableau excel." tooltipPosition="top" contentEditable="true" (change)="$event.preventDefault()" class="past-excel" (paste)="pasteData($event)">
        Coller depuis Excel
      </span>
    </div>
    <div class="option-item">
      <p style="font-weight: 700;">Importer depuis un fichier csv</p>
      <p style="margin-bottom: 38px;">Si vous voulez importer votre fichier excel entièrement dans ce dossier, téléchargez votre fichier CSV</p>
      <!-- <input type="file" #fileImportInput name="File Upload" id="csvFileUpload"
          (change)="fileChangeListener($event)" 
          accept=".csv" style="margin-left:292px;outline: none;" /> -->
          <input type="file" class="upload" (change)="changeListener($event.target.files)" style="margin-left:292px;outline: none;">
    </div>
  </div>
  <form *ngIf="!isPaste && uploadedCsv">
    <div class="form-group">
      <!-- class="required-star" -->
      <label for="">Titre du poste</label>
      <div class="row">
        <div class="col-md-7">
          <p>Choisissez la colonne de titre du poste</p>
        </div>
        <div class="col-md-5">
          <select class="form-control form-control-fix" (change)="fieldSelected($event.target.value, 'title')">
            <option value="" selected hidden>Choisissez</option>
            <option [value]="title" *ngFor="let title of csvHeaders">{{ title }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="">Unité</label>
      <div class="row">
        <div class="col-md-7">
          <p>Choisissez la colonne d'unité</p>
        </div>
        <div class="col-md-5">
          <select class="form-control form-control-fix" (change)="fieldSelected($event.target.value, 'unity')">
            <option value="" selected hidden>Choisissez</option>
            <option [value]="title" *ngFor="let title of csvHeaders">{{ title }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="">PU HT</label>
      <div class="row">
        <div class="col-md-7">
          <p>Choisissez la colonne de Prix U. HT</p>
        </div>
        <div class="col-md-5">
        <select class="form-control form-control-fix" (change)="fieldSelected($event.target.value, 'puht')">
          <option value="" selected hidden>Choisissez</option>
          <option [value]="title" *ngFor="let title of csvHeaders">{{ title }}</option>
        </select>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="">Descriptif</label>
      <div class="row">
        <div class="col-md-7">
          <p>Choisissez la colonne de Descriptif</p>
        </div>
        <div class="col-md-5">
        <select class="form-control form-control-fix" (change)="fieldSelected($event.target.value, 'cctp')">
          <option value="" selected hidden>Choisissez</option>
          <option [value]="title" *ngFor="let title of csvHeaders">{{ title }}</option>
        </select>
        </div>
      </div>
    </div>
  </form>
  <table class="table" *ngIf="isPaste && !uploadedCsv">
    <thead>
      <tr>
        <th scope="col">Valeur</th>
        <th scope="col">Clé</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let match of matching">
        <td>
          <div class="value-data">
            {{match.value}}
          </div>
        </td>
        <td>
          <p-dropdown [style]="{'border-bottom': '1px solid gray','border-top': '0px'}" [options]="matchingOption" [(ngModel)]="match.key"></p-dropdown>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button *ngIf="uploadedCsv || isPaste" class="ag-btn-secondary-sm" (click)="cancelChoosing()">Annuler</button>
  <button *ngIf="submittingCSV" class="ag-btn-primary-sm import-button"><app-spinner style="position: relative;bottom: 9px;"></app-spinner></button>
  <button *ngIf="!submittingCSV && !isPaste" [disabled]="!isFormValid()" type="button" class="ag-btn-primary-sm import-button" (click)="saveRecords()">Coller</button>
  <button *ngIf="!submittingCSV && isPaste" type="button" class="ag-btn-primary-sm import-button" (click)="saveRecordsPaste()">Coller</button>
</div>