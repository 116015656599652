import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  selector: 'app-modal-confirmation-to-delete-user',
  standalone: true,
  imports: [NgSharedModule],
  templateUrl: './modal-confirmation-to-delete-user.component.html',
  styleUrl: './modal-confirmation-to-delete-user.component.scss'
})
export class ModalConfirmationToDeleteUserComponent {
  @Input() user;
  @Output() userValidation = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    if(!changes.user.firstChange){
      this.user = changes.user.currentValue;
    }
  }
  public validationToRemoveUser(){
    this.userValidation.emit(this.user);
  }
}
