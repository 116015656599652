<div class="flex justify-content-center">
    <p-autoComplete [(ngModel)]="adresseValue" 
                    [suggestions]="suggestions" 
                    (completeMethod)="search($event)" 
                    (ngModelChange)="adresseValueChange.emit(adresseValue)"
                    (onSelect)="onAdressChange($event)"
                    [style]=""
                    optionLabel="description"
                    placeholder="Adresse">
                    <!-- <ng-template let-$event pTemplate="item">
                        <img src="assets/0-Construction.png" style="width:32px;display:inline-block;margin:5px 0 2px 5px"/>
                        <div style="font-size:18px;float:right;margin:10px 10px 0 0">{{$event}}</div>
                </ng-template> -->
            </p-autoComplete>
</div>