export class PrefillClass{
  contraintes = [{
   name:"Présence d'un ascenseur",
   value:"ascenseur",
  },{
   name:"Escalier exiguë",
   value:"escalier_exigue",
  },{
   name:"Fenêtres donnent sur la rue",
   value:"fenetre_rue",
  },{
   name:"Facilite de garer un véhicule",
   value:"garer",
  }]

  demolutionOption = {
    ensemble : [{
      name:"L'ensemble de la plomberie existante",
      value:"demol_ensemble_plomberie",
    },{
      name:"L'ensemble de l'installation électrique",
      value:"demol_ensemble_electrique",
    },{
      name:"L'ensemble des faux plafond",
      value:"demol_ensemble_plafond",
    },{
      name:"L'ensemble des cloisons - doublages existants",
      value:"demol_ensemble_cloisons",
    }
    ],
    sdb : [{
      name:"L'ensemble de l'installation sanitaire",
      value:"demol_sdb_equipement",
    },{
      name:"Carrelage et faience existant",
      value:"demol_sdb_carrelage",
    },{
      name:"Cabine douche / baignoire",
      value:"demol_sdb_cabine_douche",
    },{
      name:"Ballons d'eau chaude",
      value:"demol_sdb_ballon_chaud",
    }],
    cuisine : [{
      name:"L'ensemble des meubles cuisine",
      value:"demol_cuisine_meubles",
    },{
      name:"Carrelage et crédence existant",
      value:"demol_cuisine_carrelage",
    }]
  }
  peintureOption = {
    "peinture" : [{
        "name": "Faux plafond",
        "value": "peinture_fauxPlafond"
      },{
        "name": "Cloisons",
        "value": "peinture_cloison"
      },{
        "name": "Plafonds",
        "value": "peinture_plafond"
      },{
        "name": "Mur",
        "value": "peinture_mur"
      },{
        "name": "Porte",
        "value": "peinture_porte"
      },{
        "name": "Meuble",
        "value": "peinture_meuble"
    }],
    "revetement" : [{
        "name": "Carrelage dans la cuisine",
        "value": "revetement_carrelageCuisine"
      },{
        "name": "Faïence",
        "value": "revetement_faience"
      },{
        "name": "Carrelage dans la salle de bain",
        "value": "revetement_carrelageSDB"
      },{
        "name": "Papier peint",
        "value": "revetement_papierPeint"
      }]
  }
  electriciteOption = [{
      "name": "Tableau électrique",
      "value": "electricite_tableau"
    },{
      "name": "Prise électrique",
      "value": "electricite_prise"
    },{
      "name": "Prise téléphonique",
      "value": "electricite_priseTelephone"
    },{
      "name": "Ventilation",
      "value": "electricite_ventilation"
    },{
      "name": "Radiateur",
      "value": "electricite_radiateur"
    },{
      "name": "Plaque électrique",
      "value": "electricite_plaqueElectrique"
    },{
      "name": "Réfrigérateur",
      "value": "electricite_refrigerateur"
    },{
      "name": "Lave-linge",
      "value": "electricite_laveLinge"
    },{
      "name": "Sèche Serviette",
      "value": "electricite_secheServiette"
    },{
      "name": "Ballon d'eau chaude",
      "value": "electricite_ballon"
    }
  ]
  menuiserieInterieureOption =   {
    "pose" : [{
        "name": "Cuisine",
        "value": "pose_cuisine"
      },{
        "name": "Placard",
        "value": "pose_placard"
      },{
        "name": "Plan de Travail",
        "value": "pose_planDeTravail"
      },{
        "name": "Plinthe",
        "value": "pose_plinthes"
      },{
        "name": "Rideau occultant",
        "value": "pose_rideauOccultant"
      },{
        "name": "Escalier",
        "value": "pose_escalier"
      }
    ],
    "creation" : [{
        "name": "Porte",
        "value": "creation_porte"
      },{
        "name": "Coffrage",
        "value": "creation_coffrage"
      },{
        "name": "Lit",
        "value": "creation_lit"
      },{
        "name": "Main Courante",
        "value": "creation_mainCourante"
      }
    ]
  }
  revetementSolOption = {
    "realisation" : [{
        "name": "Chape",
        "value": "realisation_chape"
      },{
        "name": "Réagréage",
        "value": "realisation_reagreage"
      }
    ],
    "pose" : [{
      "name": "Parquet",
      "value": "pose_parquet"
    },{
      "name": "Plinthe",
      "value": "pose_plinthe"
    },{
      "name": "Carrelage",
      "value": "pose_carrelage"
    },{
      "name": "Carreaux de ciment",
      "value": "pose_carreaux"
    },{
      "name": "Sol souple",
      "value": "pose_solSouple"
    },{
      "name": "Pâte de verrière",
      "value": "pose_pate"
    }]
  }

  all = [
    {
      "color1": "#ffd24a",
      "color2": "#ffeb93",
      "value": "Démolition - Dépose",
      "name": "Démolition - Dépose",
      "gid": "AICR",
      "icon": "assets/dpgf/Icon_DPGF/demolition__depose.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ]
    },
    {
      "color1": "#d79d00",
      "color2": "#e4d3a2",
      "value": "Désamiantage",
      "name": "Désamiantage",
      "icon": "assets/dpgf/Icon_DPGF/desamiantage.png",
      "project": [
        "construction",
        "extension"
      ]
    },
    {
      "color1": "#f5aa77",
      "color2": "#f7d4be",
      "value": "Terrassement - Soutènements - Infrastructures",
      "name": "Terrassement - Soutènements - Infrastructures",
      "icon": "assets/dpgf/Icon_DPGF/terrassement__soutenements__infrastructures.png",
      "project": [
        "construction",
        "extension"
      ],
      "options": [
        {
          "color1": "#f5aa77",
          "color2": "#f7d4be",
          "value": "Terrassement",
          "name": "Terrassement",
          "icon": "assets/dpgf/Icon_DPGF/terrassement__soutenements__infrastructures.png",
          "gid": "AHGB"
        },
        {
          "color1": "#f5aa77",
          "color2": "#f7d4be",
          "value": "Soutènements - Infrastructures",
          "name": "Soutènements - Infrastructures",
          "icon": "assets/dpgf/Icon_DPGF/terrassement__soutenements__infrastructures.png",
        }
      ],
      "gid": "AKJW"
    },
    {
      "color1": "#efa0b8",
      "color2": "#fbd3df",
      "value": "Maçonnerie - Gros-œuvre",
      "name": "Maçonnerie - Gros-œuvre",
      "icon": "assets/dpgf/Icon_DPGF/maconnerie__grosuvre.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ],
      "gid": "AHGC"
    },
    {
      "color1": "#ffaa00",
      "color2": "#ffda8d",
      "value": "Ossature bois/métallique",
      "name": "Ossature bois/métallique",
      "icon": "assets/dpgf/Icon_DPGF/ossature_boismetallique.png",
      "project": [
        "construction",
        "extension"
      ],
      "options": [
        {
          "color1": "#ffaa00",
          "color2": "#ffda8d",
          "value": "Ossature bois",
          "name": "Ossature bois",
          "gid": "AHEN",
          "icon": "assets/dpgf/Icon_DPGF/ossature_boismetallique.png",
        },
        {
          "color1": "#ffaa00",
          "color2": "#ffda8d",
          "value": "Ossature métallique",
          "name": "Ossature métallique",
          "gid": "AHEW",
          "icon": "assets/dpgf/Icon_DPGF/ossature_boismetallique.png",
        }
      ]
    },
    {
      "color1": "#ffaa00",
      "color2": "#ffd989",
      "value": "Charpente",
      "name": "Charpente",
      "icon": "assets/dpgf/Icon_DPGF/charpente.png",
      "project": [
        "construction",
        "extension"
      ],
      "options": [
        {
          "color1": "#ffaa00",
          "color2": "#ffd989",
          "value": "Charpente bois",
          "name": "Charpente bois",
          "gid": "AHEN",
          "icon": "assets/dpgf/Icon_DPGF/charpente.png",
        },
        {
          "color1": "#ffaa00",
          "color2": "#ffd989",
          "value": "Charpente métallique",
          "name": "Charpente métallique",
          "gid": "AHEW",
          "icon": "assets/dpgf/Icon_DPGF/charpente.png",
        }
      ],
      "gid": "AHEW"
    },
    {
      "color1": "#00d283",
      "color2": "#a0ffb2",
      "value": "Couverture / Etanchéité",
      "name": "Couverture / Etanchéité",
      "icon": "assets/dpgf/Icon_DPGF/couverture__etancheite.png",
      "project": [
        "construction",
        "extension"
      ],
      "options": [
        {
          "color1": "#00d283",
          "color2": "#a0ffb2",
          "value": "Couverture - Zinguerie",
          "name": "Couverture - Zinguerie",
          "gid": "AHES",
          "icon": "assets/dpgf/Icon_DPGF/couverture__etancheite.png",
        },
        {
          "color1": "#00d283",
          "color2": "#a0ffb2",
          "value": "Etanchéité",
          "name": "Etanchéité",
          "gid": "AHEU",
          "icon": "assets/dpgf/Icon_DPGF/couverture__etancheite.png",
        }
      ],
      "gid": "AHES"
    },
    {
      "color1": "#ff9483",
      "color2": "#ffd1c9",
      "value": "Façades",
      "name": "Façades",
      "icon": "assets/dpgf/Icon_DPGF/facades.png",
      "project": [
        "construction",
        "extension"
      ],
      "options": [
        {
          "color1": "#ff9483",
          "color2": "#ffd1c9",
          "value": "Revêtement / Peinture Extérieure",
          "name": "Revêtement / Peinture Extérieure",
          "icon": "assets/dpgf/Icon_DPGF/facades.png",
        },
        {
          "color1": "#ff9483",
          "color2": "#ffd1c9",
          "value": "Bardage",
          "name": "Bardage",
          "gid": "AHFF",
          "icon": "assets/dpgf/Icon_DPGF/facades.png",
        },
        {
          "color1": "#ff9483",
          "color2": "#ffd1c9",
          "value": "Isolation par l'extérieur",
          "name": "Isolation par l'extérieur",
          "gid": "AHGE",
          "icon": "assets/dpgf/Icon_DPGF/facades.png",
        },
        {
          "color1": "#ff9483",
          "color2": "#ffd1c9",
          "value": "Ravalement",
          "name": "Ravalement",
          "icon": "assets/dpgf/Icon_DPGF/facades.png",
        }
      ]
    },
    {
      "color1": "#c5b5aa",
      "color2": "#dcdcdc",
      "value": "Menuiserie Extérieure",
      "name": "Menuiserie Extérieure",
      "icon": "assets/dpgf/Icon_DPGF/menuiserie_exterieure.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ],
      "options": [
        {
          "color1": "#c5b5aa",
          "color2": "#dcdcdc",
          "value": "Menuiserie Extérieure Alu",
          "name": "Menuiserie Extérieure Alu",
          "gid": "AHET",
          "icon": "assets/dpgf/Icon_DPGF/menuiserie_exterieure.png",
        },
        {
          "color1": "#c5b5aa",
          "color2": "#dcdcdc",
          "value": "Menuiserie Extérieure Metalique",
          "name": "Menuiserie Extérieure Metalique",
          "gid": "AHET"
        },
        {
          "color1": "#c5b5aa",
          "color2": "#dcdcdc",
          "value": "Menuiserie Extérieure Bois",
          "name": "Menuiserie Extérieure Bois",
          "gid": "AHEO",
          "icon": "assets/dpgf/Icon_DPGF/menuiserie_exterieure.png",
        },
        {
          "color1": "#c5b5aa",
          "color2": "#dcdcdc",
          "value": "Menuiserie Extérieure PVC",
          "name": "Menuiserie Extérieure PVC",
          "gid": "AHEM",
          "icon": "assets/dpgf/Icon_DPGF/menuiserie_exterieure.png",
        }
      ]
    },
    {
      "color1": "#6bd2fd",
      "color2": "#b5e7fb",
      "value": "Stores - Fermetures - Occultations",
      "name": "Stores - Fermetures - Occultations",
      "icon": "assets/dpgf/Icon_DPGF/stores__fermetures__occultations.png",
      "project": [
        "construction",
        "extension"
      ],
      "gid": "AHFC"
    },
    {
      "color1": "#ffb7a2",
      "color2": "#ffdbd1",
      "value": "Serrurerie - Métallerie",
      "name": "Serrurerie - Métallerie",
      "icon": "assets/dpgf/Icon_DPGF/serrurerie__metallerie.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ],
      "options": [
        {
          "color1": "#ffb7a2",
          "color2": "#ffdbd1",
          "value": "Serrurerie",
          "name": "Serrurerie",
          "gid": "AHEX",
          "icon": "assets/dpgf/Icon_DPGF/serrurerie__metallerie.png",
        },
        {
          "color1": "#ffb7a2",
          "color2": "#ffdbd1",
          "value": "Portes de Garage",
          "name": "Portes de Garage",
          "gid": "AHFC",
          "icon": "assets/dpgf/Icon_DPGF/serrurerie__metallerie.png",
        }
      ],
      "gid": "AHEX"
    },
    {
      "color1": "#ffd867",
      "color2": "#fff4c6",
      "value": "Miroiterie - Vitrerie",
      "name": "Miroiterie - Vitrerie",
      "icon": "assets/dpgf/Icon_DPGF/miroiterie__vitrerie.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ],
      "gid": "AHER"
    },
    {
      "color1": "#c59a65",
      "color2": "#d8c1a3",
      "value": "Cloisons - Doublages - Faux-plafonds",
      "name": "Cloisons - Doublages - Faux-plafonds",
      "icon": "assets/dpgf/Icon_DPGF/cloisons__doublages__fauxplafonds.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ],
      "options": [
        {
          "color1": "#c59a65",
          "color2": "#d8c1a3",
          "value": "Plâtrerie",
          "name": "Plâtrerie",
          "gid": "AHBI",
          "icon": "assets/dpgf/Icon_DPGF/cloisons__doublages__fauxplafonds.png",
        },
        {
          "color1": "#c59a65",
          "color2": "#d8c1a3",
          "value": "Isolation",
          "name": "Isolation",
          "gid": "AHGE",
          "icon": "assets/dpgf/Icon_DPGF/cloisons__doublages__fauxplafonds.png",
        }
      ],
      "gid": "AHBI"
    },
    {
      "color1": "#dab69f",
      "color2": "#eae0d9",
      "value": "Menuiserie Intérieure - Agencement",
      "name": "Menuiserie Intérieure - Agencement",
      "icon": "assets/dpgf/Icon_DPGF/menuiserie_interieure__agencement.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ],
      "options": [
        {
          "color1": "#dab69f",
          "color2": "#eae0d9",
          "value": "Menuiserie Intérieure",
          "name": "Menuiserie Intérieure",
          "gid": "AHFB",
          "icon": "assets/dpgf/Icon_DPGF/menuiserie_interieure__agencement.png",
        },
        {
          "color1": "#dab69f",
          "color2": "#eae0d9",
          "value": "Agencement - Ebenisterie",
          "name": "Agencement - Ebenisterie",
          "icon": "assets/dpgf/Icon_DPGF/menuiserie_interieure__agencement.png",
        }
      ],
      "gid": "AHFB"
    },
    {
      "color1": "#ff9700",
      "color2": "#ffd190",
      "value": "Revêtement de sol",
      "name": "Revêtement de sol",
      "icon": "assets/dpgf/Icon_DPGF/revetement_de_sol.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ],
      "options": [
        {
          "color1": "#ff9700",
          "color2": "#ffd190",
          "value": "Revêtement de sol dur",
          "name": "Revêtement de sol dur",
          "gid": "AHEY",
          "icon": "assets/dpgf/Icon_DPGF/revetement_de_sol.png",
        },
        {
          "color1": "#ff9700",
          "color2": "#ffd190",
          "value": "Revêtement de sol souple",
          "name": "Revêtement de sol souple",
          "gid": "AHGF",
          "icon": "assets/dpgf/Icon_DPGF/revetement_de_sol.png",
        },
        {
          "color1": "#ff9700",
          "color2": "#ffd190",
          "value": "Parquet",
          "name": "Parquet",
          "gid": "AHEV",
          "icon": "assets/dpgf/Icon_DPGF/revetement_de_sol.png",
        }
      ],
      "gid": "AHEY"
    },
    {
      "color1": "#6ff2ff",
      "color2": "#bbfbff",
      "value": "Carrelage - Faience",
      "name": "Carrelage - Faience",
      "icon": "assets/dpgf/Icon_DPGF/carrelage__faience.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ],
      "gid": "AHBK"
    },
    {
      "color1": "#ffb800",
      "color2": "#ffdd7b",
      "value": "Peinture - Revêtements Muraux - Nettoyage",
      "name": "Peinture - Revêtements Muraux - Nettoyage",
      "icon": "assets/dpgf/Icon_DPGF/peinture__revetements_muraux__nettoyage.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ],
      "options": [
        {
          "color1": "#ffb800",
          "color2": "#ffdd7b",
          "value": "Peinture",
          "name": "Peinture",
          "gid": "AHBJ",
          "icon": "assets/dpgf/Icon_DPGF/peinture__revetements_muraux__nettoyage.png",
        },
        {
          "color1": "#ffb800",
          "color2": "#ffdd7b",
          "value": "Revêtements Muraux",
          "name": "Revêtements Muraux",
          "gid": "AHEQ",
          "icon": "assets/dpgf/Icon_DPGF/peinture__revetements_muraux__nettoyage.png",
        },
        {
          "color1": "#ffb800",
          "color2": "#ffdd7b",
          "value": "Nettoyage",
          "name": "Nettoyage",
          "icon": "assets/dpgf/Icon_DPGF/peinture__revetements_muraux__nettoyage.png",
        }
      ],
      "gid": "AHBJ"
    },
    {
      "color1": "#d2cdbd",
      "color2": "#e2e1d9",
      "value": "Signalétique",
      "name": "Signalétique",
      "icon": "assets/dpgf/Icon_DPGF/signaletique.png",
      "project": [
        "construction"
      ]
    },
    {
      "color1": "#edc276",
      "color2": "#efdebc",
      "value": "Mobilier",
      "name": "Mobilier",
      "icon": "assets/dpgf/Icon_DPGF/mobilier.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ]
    },
    {
      "color1": "#ffe800",
      "color2": "#fffc9d",
      "value": "Electricité",
      "name": "Electricité",
      "icon": "assets/dpgf/Icon_DPGF/electricite.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ],
      "options": [
        {
          "color1": "#ffe800",
          "color2": "#fffc9d",
          "value": "Electricité - Courant Fort",
          "name": "Electricité - Courant Fort",
          "gid": "AHFD",
          "icon": "assets/dpgf/Icon_DPGF/electricite.png",
        },
        {
          "color1": "#ffe800",
          "color2": "#fffc9d",
          "value": "Courant faible - Domotique",
          "name": "Courant faible - Domotique",
          "gid": "AHFD",
          "icon": "assets/dpgf/Icon_DPGF/electricite.png",
        }
      ],
      "gid": "AHFD"
    },
    {
      "color1": "#ffe800",
      "color2": "#fffc9d",
      "value": "Alarmes - Surveillance",
      "name": "Alarmes - Surveillance",
      "icon": "assets/dpgf/Icon_DPGF/electricite.png",
      "project": [
        "construction",
        "extension"
      ]
    },
    {
      "color1": "#d68f92",
      "color2": "#e6cbcc",
      "value": "Sécurité incendie",
      "name": "Sécurité incendie",
      "icon": "assets/dpgf/Icon_DPGF/securite_incendie.png",
      "project": [
        "construction",
        "extension"
      ]
    },
    {
      "color1": "#c5c3c9",
      "color2": "#d8d8d8",
      "value": "Plomberie - Sanitaire",
      "name": "Plomberie - Sanitaire",
      "icon": "assets/dpgf/Icon_DPGF/plomberie__sanitaire.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ],
      "gid": "AHEZ"
    },
    {
      "color1": "#84ceff",
      "color2": "#bbe2ff",
      "value": "Chauffage - Ventilation - Climatisation (CVC)",
      "name": "Chauffage - Ventilation - Climatisation (CVC)",
      "icon": "assets/dpgf/Icon_DPGF/chauffage__ventilation__climatisation_cvc.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ],
      "options": [
        {
          "color1": "#84ceff",
          "color2": "#bbe2ff",
          "value": "Chauffage",
          "name": "Chauffage",
          "gid": "AHMT",
          "icon": "assets/dpgf/Icon_DPGF/chauffage__ventilation__climatisation_cvc.png",
        },
        {
          "color1": "#84ceff",
          "color2": "#bbe2ff",
          "value": "Chauffage Electrique",
          "name": "Chauffage Electrique",
          "gid": "AHMT",
          "icon": "assets/dpgf/Icon_DPGF/chauffage__ventilation__climatisation_cvc.png",
        },
        {
          "color1": "#84ceff",
          "color2": "#bbe2ff",
          "value": "Ventilation",
          "name": "Ventilation",
          "gid": "AHLI",
          "icon": "assets/dpgf/Icon_DPGF/chauffage__ventilation__climatisation_cvc.png",
        },
        {
          "color1": "#84ceff",
          "color2": "#bbe2ff",
          "value": "Climatisation",
          "name": "Climatisation",
          "gid": "AHGH",
          "icon": "assets/dpgf/Icon_DPGF/chauffage__ventilation__climatisation_cvc.png",
        }
      ],
      "gid": "AHMT"
    },
    {
      "color1": "#d4a9a7",
      "color2": "#dcd6d5",
      "value": "Gaz",
      "name": "Gaz",
      "icon": "assets/dpgf/Icon_DPGF/gaz.png",
      "project": [
        "construction",
        "extension"
      ]
    },
    {
      "color1": "#00ef34",
      "color2": "#cbffb5",
      "value": "Énergies Renouvelables",
      "name": "Énergies Renouvelables",
      "icon": "assets/dpgf/Icon_DPGF/energies_renouvelables.png",
      "project": [
        "construction",
        "extension"
      ]
    },
    {
      "color1": "#ffbf5d",
      "color2": "#ffda93",
      "value": "Ascenseurs",
      "name": "Ascenseurs",
      "icon": "assets/dpgf/Icon_DPGF/ascenseurs.png",
      "project": [
        "construction"
      ]
    },
    {
      "color1": "#77cfdb",
      "color2": "#afeaf1",
      "value": "Equipements techniques",
      "name": "Equipements techniques",
      "icon": "assets/dpgf/Icon_DPGF/equipements_techniques.png",
      "project": [
        "construction",
        "extension",
        "renovation",
        "transformation"
      ]
    },
    {
      "color1": "#ecc487",
      "color2": "#efe0c5",
      "value": "Voirie et Réseaux Divers",
      "name": "Voirie et Réseaux Divers",
      "icon": "assets/dpgf/Icon_DPGF/voirie_et_reseaux_divers.png",
      "project": [
        "construction"
      ],
      "options": [
        {
          "color1": "#ecc487",
          "color2": "#efe0c5",
          "value": "Maçonnerie",
          "name": "Maçonnerie",
          "gid": "AHGC",
          "icon": "assets/dpgf/Icon_DPGF/voirie_et_reseaux_divers.png",
        },
        {
          "color1": "#ecc487",
          "color2": "#efe0c5",
          "value": "Voiries",
          "name": "Voiries",
          "gid": "AKJW",
          "icon": "assets/dpgf/Icon_DPGF/voirie_et_reseaux_divers.png",
        },
        {
          "color1": "#ecc487",
          "color2": "#efe0c5",
          "value": "Réseaux",
          "name": "Réseaux",
          "gid": "AKJW",
          "icon": "assets/dpgf/Icon_DPGF/voirie_et_reseaux_divers.png",
        },
        {
          "color1": "#ecc487",
          "color2": "#efe0c5",
          "value": "Clotures",
          "name": "Clotures",
          "icon": "assets/dpgf/Icon_DPGF/voirie_et_reseaux_divers.png",
        },
        {
          "color1": "#ecc487",
          "color2": "#efe0c5",
          "value": "Mobilier Urbain",
          "name": "Mobilier Urbain",
          "icon": "assets/dpgf/Icon_DPGF/voirie_et_reseaux_divers.png",
        }
      ],
      "gid": "AKJW"
    },
    {
      "color1": "#70d6f5",
      "color2": "#b5eeff",
      "value": "Assainissement - Récupération/Traitement des eaux",
      "name": "Assainissement - Récupération/Traitement des eaux",
      "icon": "assets/dpgf/Icon_DPGF/assainissement__recuperationtraitement_des_eaux.png",
      "project": [
        "construction"
      ],
      "gid": "AIGG"
    },
    {
      "color1": "#00d283",
      "color2": "#9fffb1",
      "value": "Aménagements extérieurs - Espaces verts",
      "name": "Aménagements extérieurs - Espaces verts",
      "icon": "assets/dpgf/Icon_DPGF/amenagements_exterieurs__espaces_verts.png",
      "project": [
        "construction"
      ]
    },
    {
      "color1": "#92daf5",
      "color2": "#c9f0ff",
      "value": "Piscine",
      "name": "Piscine",
      "icon": "assets/dpgf/Icon_DPGF/piscine.png",
      "project": [
        "construction"
      ]
    }
  ]
  constructor(){

  }
}
