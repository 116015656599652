<div class="container-fluid">
  <div class="col-md-5 full-height colored-side">
    <div>
      <img class="aglo-logo" src="/assets/rob/aglo_logo_round_blanc.png" alt="">
      <h2 class="slogan">
          L’assistant intelligent <br>
          pour votre consultation d’entreprise
      </h2>
    </div>
    <div class="connect">
      <a [routerLink]="['/login']">Déjà un compte sur Aglo ?</a>
    </div>
  </div>
  <div class="col-md-7 full-height">
    <div class="form">
      <div class="row">
        <div class="col-md-6 progress-text">Généralités</div>
        <div class="col-md-6 progress-text">Connexion</div>
      </div>
      <div class="progress">
        <div class="progress-bar" [ngStyle]="{'width': (showAgencyComponent) ? '40%' : '90%' }"></div>
      </div>
      <h1 class="title">Créez votre compte</h1>
       <div [ngStyle]="{'display': (!showAgencyComponent) ? 'block' : 'none' }">
         <app-artisan-signup-login [isSignUp]='true' [(artisan)]="dataArtisan"></app-artisan-signup-login>
       </div>
       <div [ngStyle]="{'display': (showAgencyComponent) ? 'block' : 'none' }">
         <app-artisan-singup-agency-info (agencyData)="agencySubmitted($event)"></app-artisan-singup-agency-info>
       </div>

      <div class="center" *ngIf="!showAgencyComponent">
        <button [disabled]="loading" (click)="register();" href="#" class="btn-primary-color">
          <div *ngIf="!loading">Valider</div>
          <app-spinner *ngIf="loading"></app-spinner>
        </button>
      </div>

    </div>
  </div>
</div>
