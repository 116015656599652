import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
//import { ArchitectModule } from '../architect.module';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $;
@Component({
    selector: 'app-archi-subscribe5',
    imports:[
      
      HeaderComponent,
      NgSharedModule
    ],
    templateUrl: './archi-subscribe5.component.html',
    styleUrls: ['./archi-subscribe5.component.scss'],
    standalone: true,

})
export class ArchiSubscribe5Component implements OnInit {
  public lang = "fr";
  constructor(private title: Title) {
    this.title.setTitle('Aglo - Bienvenue' );
  }

  ngOnInit() {
    var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    this.lang = currentUser.language || 'fr';
    this.scrollToTop()
  }
  public scrollToTop(){
    $('html, body').animate({
        scrollTop: ($('.hero').offset().top)
    },1500);
  }
}
