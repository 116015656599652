import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  standalone:true,
  name: 'fileFolder'
})

export class FileFolderPipe implements PipeTransform {
  transform(files, ...args: any[]): any {
    let type=args[0];
    let isLength=args[1]
    if(type=='folder'){
      if(isLength) return files.filter(f=>f.type== 'folder' || f.title).length
      return files.filter(f=>f.type== 'folder' || f.title)
    }
    else{
      if(isLength) return files.filter(f=>!(f.type== 'folder' || f.title)).length
      return files.filter(f=>!(f.type== 'folder' || f.title))
    } 

  }
}