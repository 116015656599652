<div id="booster-profile-edit-agency-name-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header no-padding">
        <h4 class="ag-heading-h4 ag-weight-500 no-margin">Profil</h4>
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">        
        
        <h5 class="ag-heading-h5 ag-weight-600">Vous êtes : </h5>

        <div class="checkboxes-list-wrapper">
          <span class="checkbox-item margin-bottom-16" 
          *ngFor="let item of type"
          [ngClass]="{'choosed': selectedTypes.includes(item.value)}"
          (click)="chooseItem(item.value)"
          >
          {{ item.name }}
        </span>
        </div>
        
        <!-- Agency Name -->
        <div class="margin-top-30 margin-bottom-55 agency-name-container">
          <p class="ag-para-3 ag-weight-400 margin-bottom-4">Nom de l’entreprise :</p>
          <input type="text" [(ngModel)]="agenyName" />
          <p class="text-danger no-padding margin-top-5" *ngIf="agenyName == ''">Ce champ est obligatoire</p>
        </div>
        <!-- END Agency Name -->
        
        <div class="submit-btn-container text-center margin-top-30 ">
          <button class="ag-btn-primary-sm" (click)="update()" [disabled]="agenyName == ''">Modifier les informations</button>
        </div>

      </div>
    </div>
  </div>
</div>
