import { Component, OnInit, ViewChild , NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute , Params,Router} from '@angular/router';
import { Form1Component } from '../../../architect/form1/form1.component';
import {Title,Meta} from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { NgSharedModule } from 'src/app/all/ngshared.module';
//Service
import { MessageService } from 'primeng/api';
import { AuthService } from '../../../_services/index';
import { LogService } from '../../../_services/index';
import { param } from 'jquery';
import { IdentifySegement } from 'src/app/all/segment/identify';
//import { ArchitectModule } from '../architect.module';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';


@Component({
    selector: 'app-archi-subscribe1',
    templateUrl: './archi-subscribe1.component.html',
    imports:[
      
      Form1Component,
      SpinnerComponent,
      NgSharedModule
    ],
    providers: [],
    styleUrls: ['./archi-subscribe1.component.scss'],
    standalone: true,

})
export class ArchiSubscribe1Component implements OnInit {
  dataArchi1:any;
  // added
  public email;

  public loading:Boolean = false;
  public archiId:string = "";
  public parrain:string = "";
  public parrainageId:string = "";
  public gotopay:string = "false";
  public userToken:string ="";
  public source:string ="";
  private subscriptions: Array<Subscription> = [];
  @ViewChild(Form1Component) form: Form1Component
  constructor(private title: Title,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private logService: LogService,
              private messageService: MessageService,
              private identify: IdentifySegement) {
    this.dataArchi1={}
  }

  ngOnInit() {
    this.title.setTitle('Aglo - Inscription architecte' );
    window.prerenderReady = true;
    this.getArchiId();
  }
  //Get Archi ID
  public getArchiId(){
    this.subscriptions.push(
      this.route.queryParams.subscribe((params: Params) => {
          this.archiId = params['archiId'] || "";
          this.parrain = params['parrain'] || ""; //prainage ID
          this.parrainageId = params['parrainageId'] || ""; //prainage ID
          this.gotopay = params['gotopay'] || "false";
          this.userToken = params['token'] || "";
          this.source = params['source'] || "";
          if(params['email']) this.email = params['email'].replace(' ', '+') || "";
        })
    )
  }

  //Register
  register(){
    this.loading = true;
    let isValid = this.form.isValid()
    let archiData = this.form.getFormData();
    archiData.archiId = this.archiId;
    archiData.parrain = this.parrain;
    archiData.parrain = this.parrain;
    archiData.parrainageId = this.parrainageId;
    archiData.userToken=this.userToken;
    archiData.source=this.source;
    if(isValid){
      this.subscriptions.push(
        this.authService.registerArchi(archiData)
            .subscribe(
                data => {
                  this.loading = false;
                  if(this.userToken) {
                    this.logService.logEvent("Architect User Register")
                    this.router.navigate(['/architect/my-consultations']);
                  }
                  else {
                    this.logService.logEvent("Architect Register")
                    this.router.navigate(['/architect/subscribe2'],{ queryParams:{email: archiData.email,gotopay : this.gotopay}});
                  }
                  // identify user segment
                  let isParrain=(this.parrainageId!="");
                  // this.identify.identifySignUp(isParrain);
                  this.messageService.add({ key: 'toast', severity: 'success', summary: 'Compte créé', detail: 'Votre compte a été crée', life: 5000 });

                },
                error => {
                    this.loading = false;
                    let err = (() => { try { return JSON.parse(error._body) } catch (something) { return error }})()
                    this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: err.message, life: 5000 });
                })
      )
    }else{
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Formulaire incomplet', detail: 'Veuillez remplir tous les champs obligatoires et attendre la fin du chargement des fichiers', life: 5000 });      
      this.loading = false;
    }
  }

  public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
  }

}
