import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {  AuthService} from 'src/app/_services';
import { WebView2Service } from 'src/app/_services/webview2.service.service';
import * as fromSelectors from '../_store';
import * as actions from '../_store/dpgf.actions';
import {  Subscription } from 'rxjs';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { data } from 'jquery';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { DpgfModule } from '../../dpgf.module';
const getId = (type) =>{
  return type + '_' + Math.random().toString(36).substr(2, 9);
}

@Component({
  standalone:true,
  selector: 'app-post-to-revit',
  imports:[
    DpgfModule,
    SpinnerComponent,
    NgSharedModule
  ],
  templateUrl: './post-to-revit.component.html',
  styleUrls: ['./post-to-revit.component.scss']
})
export class PostToRevitComponent implements OnInit,OnChanges {
  @Input() dpgfId:string;
  @Input() posteId:any;
  @Input() posteTitle:any;
  @Input() postI;
  @Input() sousLotI;
  @Input() lotI;
  @Input() lotId;
  @Output() changeOpenSliderPostToRevitValue = new EventEmitter<any>();
  @Output() posteIdChange = new EventEmitter<any>();
  @Output() changeRevitIcon = new EventEmitter<any>();
  private subscriptions: Array<Subscription> = [];
  public nbLine = 0;
  public openSliderPostToRevit:boolean =false;
  public revitElements:any=[];
  public lot:any;
  public licence: string;
  public maxLibraryPostsModalshowLibraryText: boolean = false;
  public projectLicence: string;
  public cctpEdit$: any;
  public cctpEdit: any;
  public selectedUnit: string;
  public saveSelectedUnit:string;
  public quantity: any=0;
  public loadingElements: boolean = false;
  public units: Array<any> = [
    { value: 'ens', label: 'Ens' },
    { value: 'm', label: 'm' },
    { value: 'm2', label: 'm2' },
    { value: 'm3', label: 'm3' }
  ];
  public dataStructureOfProject: any[]=[];
  isRevit: boolean;

  constructor( private wvservice : WebView2Service,
              private cd: ChangeDetectorRef,
              private store: Store,
              public authService: AuthService,
              private wv2Service:WebView2Service) {
                }
  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if(propName=="posteId"){
        if(!this.posteId)return;
        this.openRevitSide();
        this.getPosteElementsRevit(this.posteId);
      }
    }
  }
  ngOnInit(): void {
    this.selectedUnit=localStorage.getItem(this.posteId)||'ens';
    this.isRevit = navigator.userAgent.includes("Autodesk.Revit")
    this.subscribeElementRevitOnClick();
    this.cctpEdit$ = this.store.pipe(select(fromSelectors.getAny, {name : "cctpEdit"}))
    this.store.pipe(select(fromSelectors.getLot)).subscribe(lot=>{
      this.lot=lot;
    })
    this.store.pipe(select(fromSelectors.getAny, {name : "cctpEdit"})).subscribe((cctpEdit)=>{
      if(cctpEdit){
        this.cctpEdit = cctpEdit;
      }
    })
    this.store.pipe(select(fromSelectors.getTotal, { name: "nbLines" })).subscribe(data=>{
      this.nbLine = data;
    })
    this.licence = this.authService.licence || "base";
    this.store.pipe(select(fromSelectors.getAny , {name : "projectLicence"} )).subscribe((data)=>{
      this.projectLicence=data;
    })
  }

  public subscribeElementRevitOnClick(){
    this.wv2Service.subscribeToWebView2Event("selectionchanged", (e) => {
      let selectedRevitElements = [...e.detail];
      if(selectedRevitElements.length==0)return;
      if(!this.openSliderPostToRevit)return;
      this.addRevitElement(this.posteId,selectedRevitElements);
    });
  }
  public getPosteElementsRevit(posteId){
    let revitIcon;
    this.loadingElements = true
    this.revitElements=[];
    this.subscriptions.push(
      this.wv2Service.getPosteElments(posteId,this.dpgfId).subscribe(
        (res)=>{
          this.loadingElements = false;
          this.revitElements=res;
          revitIcon=(res.length!=0)? true:false;
          this.store.dispatch(actions.setPosteAmount({loti : this.lotI , sousloti:this.sousLotI, posti:this.postI,data:{revitIcon:revitIcon}}));
          this.cd.detectChanges();
          this.changePostUnit();
          this.selectAllElementInRevit();
        },
        (err)=>{
          this.loadingElements = false;
          console.log(err.message)
        }
      )
    )
  }

  public changePostUnit(){
    this.quantity=0;
    let selectedUnit = String(this.selectedUnit); 
    localStorage.setItem(this.posteId, selectedUnit);
    this.saveSelectedUnit=this.selectedUnit;
    let matching = {
      "ens" : "ens",
      "m" : "longeur",
      "m2" : "surface",
      "m3" : "volume"
    }
    this.revitElements.map(el => this.quantity+=el[matching[this.selectedUnit]]);
    //this.quantity= this.quantity.toFixed(2);
    (this.quantity === parseInt(this.quantity, 10)) ? this.quantity: (this.quantity=this.quantity.toFixed(2));
    //this.quantity= Math.round(this.quantity);
    this.store.dispatch(actions.setPosteData({loti : this.lotI,sousloti:this.sousLotI,posti:this.postI,data:{unite:this.selectedUnit}}));
    this.store.dispatch(actions.setPosteAmount({loti : this.lotI,sousloti:this.sousLotI,posti:this.postI,data:{qte:this.quantity}}));
  }

  public addRevitElement(posteId,selectedRevitElements){
    const isRevitElementsContainsSelectedElm = selectedRevitElements.map(e=>e.id).every(element => {return this.revitElements.map(e=>e.id).includes(element);});
    if(isRevitElementsContainsSelectedElm) return ;
    let revitIcon;
    this.subscriptions.push(
    this.wvservice.addBimElements(posteId,this.dpgfId,{selectedRevitElements, lotId:this.lotId, posti:this.postI, sousloti:this.sousLotI}).subscribe(
      (res)=>{
        this.revitElements=res;
        revitIcon=(res.length)?true:false;
        this.store.dispatch(actions.setPosteAmount({loti : this.lotI , sousloti:this.sousLotI, posti:this.postI,data:{revitIcon:revitIcon}}));
        this.selectAllElementInRevit()
        this.changePostUnit();
        this.cd.detectChanges()
      },
      (err) =>{
        console.log(err.message)
      }
    ));
  }

  public deleteElemenet(elementId){
    this.subscriptions.push(
      this.wv2Service.deleteElementFromPoste(this.posteId,elementId).subscribe(
        (res)=>{
          this.getPosteElementsRevit(this.posteId);
        },
        (err)=>{
          console.log(err.message)
        }
      )
    );
  }
  public openRevitSide(){
    this.openSliderPostToRevit=true;
    this.changeOpenSliderPostToRevitValue.emit(this.openSliderPostToRevit);
  }
  public closeRevitSide (){
    this.openSliderPostToRevit=false;
    this.posteIdChange.emit(null);
    this.changeOpenSliderPostToRevitValue.emit(this.openSliderPostToRevit);
    $(".p-component-overlay").css("display","none");
  }
  public selectAllElementInRevit(){
    this.wv2Service.postWebView2Message({action:"select",payload: this.revitElements.map(re=>re.id) });
  }
  public selectOneIdElementInRevit(id){
    this.wv2Service.postWebView2Message({action:"select",payload: [id]});
  }

  public sortLots(lots){
    return lots.sort((a,b) =>  a.indexLot - b.indexLot );
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }  
}
