<div class="navbar-wrapper" style="margin-top:48px;">
  <!-- Main Title -->
  <div class="header-wrapper flex space-between">
    <h3>
      <span class="ag-text-success-green">Analyse du cycle de vie</span>
    </h3>
    <h3 [ngStyle]="{'margin-right': showWelcomeScreen?'0':'44%'}">
      <span class="ag-text-success-tertiary" >{{ projectTitle }}</span>
    </h3>
    <!-- Buttons -->
    <div *ngIf="showWelcomeScreen" class="buttons-container">
      <!-- <button class="btn btn-purple green-btn" (click)="generateJsonComenv()"> -->
      <button class="btn btn-purple green-btn no-outline" (click)="clickRSETinput()" [disabled]="isProjectDemo">
        <div *ngIf="!loadingFiles">
          <img src="/assets/acv-icons/download-green.svg" />
          Importer mon RSET (.xml)
        </div>
        <div *ngIf="loadingFiles" style="position: relative;bottom: 10px;">
          <app-spinner></app-spinner>
        </div>
      </button>
      <input
          #fileInput
          id="fileInput"
          type="file"
          class="form-control"
          style="display:none;"
          (change)="fileEvent($event)">
      <button *ngIf="selectedTab=='results'" [disabled]="!checkRSET()" class="btn btn-purple green-btn margin-left-5 no-outline" (click)="generateJsonComenv()">
        <span *ngIf="!loadingGeneration">Mettre à jour les résultats</span>
        <div *ngIf="loadingGeneration" style="position: relative;bottom: 10px;">
          <app-spinner></app-spinner>
        </div>
      </button>
    </div>
    <!-- <div class="margin-top-24 flex align-centre" style="height: 37px;justify-content: center;">
    </div> -->
  </div>
  <!-- END Main Title -->
  <!-- <div class="container navigation-list-container"> -->

  <div class="container-fluid navigation-list-container">
    <ul class="horizontal-items space-between">
      <div class="horizontal-items">
        <li><a class="selected-tab" (click)="selectTab($event, 'description_du_project')">Description du projet</a></li>
        <li><a (click)="selectTab($event, 'composant')" id="composant-div">Composant</a></li>
        <li><a (click)="selectTab($event, 'energie')">Energie</a></li>
        <li><a (click)="selectTab($event, 'eau')">Eau</a></li>
        <li><a (click)="selectTab($event, 'chantier')">Chantier</a></li>
      </div>
      <li id="results-nav"><a (click)="selectTab($event, 'results')">Résultats et attestations</a></li>
    </ul>
  </div>

</div>
