import { Component, OnInit,Input, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,

  selector: 'app-spinner',
  imports:[CommonModule,RouterModule],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input()size:String="small";
  constructor() { }

  ngOnInit() {
  }

}
