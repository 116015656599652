<div class="row">
  <div *ngIf="collabs && collabs.length==0" class="empty-collabs">
    <h3>Vous n'avez pas encore partagé votre bibliothèque</h3>
  </div>
  <table *ngIf="collabs && collabs.length>0" class="table table-striped">
    <thead class="thead-dark">
      <tr>
        <td class="title">Email</td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let collab of collabs">
        <td>{{ collab.emailAgency }}</td>
        <td>
          <i
            class="fa fa-trash click-it"
            aria-hidden="true"
            (click)="deleteCollab(collab)"
          ></i>
        </td>
      </tr>
    </tbody>  
  </table>
</div>
<div class="row add-collab-container">
  <div class="input-mail">
    <input
      type="email"
      [(ngModel)]="mail"
      placeholder="Ajouter une adresse e-mail"
      name="mail"
      #emailInput
    />
  </div>
  <p *ngIf="!mailMessage" class="error-message">{{message}}</p>
  <button [disabled]="!mailMessage" *ngIf="!addingCollab" type="button" class="ag-btn-secondary-sm add-collab" (click)="addCollab()">
    Ajouter
  </button>
  <app-spinner *ngIf="addingCollab" style="float: right;margin-left: auto;"></app-spinner>
</div>
<p-confirmDialog key="collab"></p-confirmDialog>
