<p-sidebar class="revit-sidebar" [ngClass]="isRevit?'revit-sidebar': ''" [(visible)]="openSliderPostToRevit"  [(visible)]="openSliderPostToRevit" position="right" (onHide)="closeRevitSide()" styleClass="p-sidebar-lg">
  <div class="header">
    <div class="row">
      <div class="center">
        <div class="inner-container">
          <i class="click-it fa fa-times" aria-hidden="true" (click)="closeRevitSide()"></i>
          <p *ngIf="cctpEdit?.postDesignation " class="post-title">{{lot?.title}} > {{cctpEdit?.postDesignation}}</p>
          <p *ngIf="!cctpEdit?.postDesignation " class="post-title">{{lot?.title}} > {{posteTitle}} </p>
        </div>
      </div>
    </div>
  </div>
  <app-spinner *ngIf="loadingElements"></app-spinner>
  <div *ngIf="!loadingElements" class="revit-section">
    <div class="row">
      <div class="col-xs-6">
        Unité
        <select name="unit" id="units"  [(ngModel)]="selectedUnit"
          (change)="changePostUnit()">
          <option *ngFor="let unit of units"  [ngValue]="unit.value">{{unit.label}}</option>
        </select>
      </div>
      <div class="col-xs-6">
        Quantité calculée <span style ="margin-left: 60px; font-weight: 800; font-size: 16px;">{{quantity || revitElements.length}}</span>
      </div>        
    </div>
  </div>
  <div class="revit-section" *ngIf="!loadingElements">
    <div class="paraghra">
      <div>Liste des élèments BIM attachés</div>
      <div class="result">
        <table style="border: none;">
          <tr>
            <th style="border-left: none; font-weight: 900;">Catégorie</th>
            <th style="width: 40%; border-left: none; font-weight: 900;">Famille</th>
            <th style="border-left: none; font-weight: 900;">L|S|V</th>
            <th style="border-left: none; text-align: center; font-weight: 900;">Supprimer</th>
          </tr>
          <tr *ngFor="let element of revitElements;  let i = index">
            <td class="padding-left-5" (click)="selectOneIdElementInRevit(element.id)">{{element.category}}</td>
            <td class="padding-left-5">{{element.family}}</td>
            <td class="padding-left-5">{{element.longeur}}|{{element.surface}}|{{element.volume}}</td>
            <td class="padding-left-5" style="text-align: center" (click)="deleteElemenet(element._id)"><i
                style="cursor: pointer; " class="fa fa-trash"></i>
            </td>
          </tr>
        </table>
        <!-- <table border="" style="border: 1px solid #113cdf;border-color: #003cff; border-radius: 6px;font-size: 17px; width:100% " >
          <tr>
            <th style="border-left: none; font-weight: 900;">Catégorie</th>
            <th style="width: 40%; border-left: none; font-weight: 900;">Famille</th>
            <th style="border-left: none; font-weight: 900;">L|S|V</th>
            <th style="border-left: none; text-align: center; font-weight: 900;">Supprimer</th>
          </tr>
          <tr *ngFor="let element of data;">
            <td class="padding-left-5">
              <div >{{element.name}}</div>             
            </td>
            <td class="padding-left-5">
              <tr *ngFor="let fm of element.families;">
                <div style="    margin-left: 41px;">{{fm.name}}</div>
              </tr>
            </td>
            <td class="padding-left-5">
              <tr *ngFor="let fm of element.families;">
                <td class="padding-left-5">
                  <div *ngFor="let ty of fm.types;">
                   <div *ngFor="let prd of ty.products">
                      {{prd.parameters.longeur}}|{{prd.parameters.surface}}| {{prd.parameters.volume}}    
                   </div>
                  </div>  
                </td> 
              </tr>
            </td>
            <td class="padding-left-5">
              <tr *ngFor="let fm of element.families;">
                <div *ngFor="let ty of fm.types;">
                   <div style="cursor: pointer; " *ngFor="let prd of ty.products" (click)="deleteElemenet(prd.id)">
                  delete
                   </div>
                </div>                
              </tr>
            </td>
          </tr>
        </table> -->
      </div>
    </div>
  </div>
</p-sidebar>