<div class="container-fluid">
  <div class="col-md-5 full-height colored-side hidden-xs hidden-sm">
    <div>
      <img class="aglo-logo" src="/assets/rob/aglo_logo_round_blanc.png" alt="">
      <h2 class="slogan">
          L’assistant intelligent <br>
          pour votre consultation d’entreprise
      </h2>
    </div>
    <!-- <div class="connect">
      <a [routerLink]="['/login']">Déjà un compte sur Aglo ?</a>
    </div> -->
  </div>
  <div class="col-md-7 full-height ">
    <div class="form">
      <div class="row hide-min">
        <div class="col-md-6 progress-text">Informations générales</div>
        <div class="col-md-6 progress-text">Informations secondaires</div>
      </div>
      <div class="progress hide-min">
        <div class="progress-bar" style="width: 75%;"></div>
      </div>
      <h1 class="title">Créez votre compte</h1>
      <app-form3 [(agency)]="agencyArchi" ></app-form3>
      <div class="center">
        <button id="archi-subscribe-2" [disabled]="loading" (click)="save()" class="btn-primary-color">
          <div id="archi-subscribe-2" *ngIf="!loading">Terminer mon inscription</div>
          <app-spinner *ngIf="loading"></app-spinner>
        </button>
      </div>
    </div>
  </div>
</div>
