import { Component, EventEmitter, OnInit, Output, Input, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute , Params,Router} from '@angular/router';
import { Subscription } from 'rxjs';
import { CeTaskService, ErrorService, AuthService } from 'src/app/_services'
import { MessageService } from 'primeng/api';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';


declare var $;

@Component({
  standalone:true,
  selector: 'app-ce-project-tasks',
  imports:[DpgfModule,NgSharedModule],
  providers: [],
  templateUrl: './ce-project-tasks.component.html',
  styleUrls: ['./ce-project-tasks.component.scss']
})
export class CeProjectTasksComponent implements OnInit {
  @Input()projectId:string;
  @Input()isDemoProject:boolean = false;
  @Input()collaborateurs:any = [];
  public getLoading:boolean = false;
  public setLoading:boolean = false;
  public addingTask:boolean = false;
  public creatingTask:boolean = false;
  public task = {title : "",task : "" , date : new Date() , status : "" , users : []};
  public showNewTaskErrors: boolean = false;
  public waitingTasks = [];
  public doneTasks = [];
  public features;
  private subscriptions: Array<Subscription> = [];

  constructor(private router: Router,
              private messageService: MessageService,
              private ceTaskService: CeTaskService,
              private ErrorService: ErrorService,
              private authService: AuthService,
            ) { }

  ngOnInit() {
    this.getTasks()
    this.authService.features.subscribe((f)=>{
      this.features = f ;
    })
  }
  public getTasks(){
    this.getLoading = true;
    this.subscriptions.push(
      this.ceTaskService.getAllProjectTasks(this.projectId)
        .subscribe(
            (data) => {
                this.waitingTasks = data.filter(tsk =>(tsk.status == "waiting"));
                this.doneTasks = data.filter(tsk =>(tsk.status == "done"));;
                this.getLoading = false;
            },
            error => {
                this.getLoading = false;
                this.ErrorService.manageError(error,'/client/project/' + this.projectId + '/architect');
                console.log("error",error)
            })
        )
  }

  public addTask(){
    this.creatingTask = true;
    this.showNewTaskErrors = false;
    let task = {...this.task};
    task.date = new Date();
    task.status = "waiting";
    let selectedUsers = task.users;
    task.users = task.users.map(user=>user._id);
    if(task.title!="" && task.task != ""){
      this.subscriptions.push(
        this.ceTaskService.create(this.projectId, task)
          .subscribe(
              (data) => {
                  this.setLoading = false;
                  data.users = selectedUsers;
                  this.waitingTasks.push(data);
                  this.task = {title : "",task : "" , date : new Date() , status : "" , users : []};
                  this.addingTask = false;
                  this.creatingTask = false;
                  if(!environment.onPremise)window.analytics.track('create_task',{properties:{acValue: 'create_task'}});
              },
              error => {
                  this.setLoading = false;
                  this.ErrorService.manageError(error,'/client/project/' + this.projectId + '/architect');
                  console.log("error",error);
              })
      )
    }else{
      this.showNewTaskErrors = true;
      this.creatingTask = false;
    }
  }
  public doneTask(taskId){
    this.setLoading = true;
    this.subscriptions.push(
      this.ceTaskService.update(this.projectId, taskId, {status : "done"})
        .subscribe(
            () => {
                this.getTasks()
                if(!environment.onPremise)window.analytics.track('finish_task',{properties:{acValue: 'finish_task'}});
            },
            error => {
                this.setLoading = false;
                this.ErrorService.manageError(error,'/client/project/' + this.projectId + '/architect');
                console.log("error",error)
            })
    )
  }
  openTaskPopup(){
    /// if project is demo don't add collabs
    if(this.isDemoProject) return;
    
    if(this.features.tasks){
        this.addingTask = !this.addingTask;
    }else{
      $('#upgrade-modal').modal('show');
    }
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }


}
