import { Component, OnInit, ViewChild , NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Router, Params} from '@angular/router';
import { Form1Component } from '../../../architect/form1/form1.component';
import {Title} from '@angular/platform-browser';
import { Subscription } from 'rxjs';
//Service
import { MessageService } from 'primeng/api';
import { AuthService } from '../../../_services/index';
//import { ArchitectModule } from '../architect.module';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

//Interface
interface Window
{
    prerenderReady:boolean;
}
declare var window:Window;
@Component({
    selector: 'app-collaborateur-subscribe1',
    imports:[
      
      Form1Component,
      SpinnerComponent,
      NgSharedModule
    ],
    providers: [],
    templateUrl: './collaborateur-subscribe1.component.html',
    styleUrls: ['./collaborateur-subscribe1.component.scss'],
    standalone: true,

})
export class CollaborateurSubscribe1Component implements OnInit {
  dataMoa1:any;
  public userId:string = "";
  public loading:Boolean = false;
  private subscriptions: Array<Subscription> = [];
  @ViewChild(Form1Component) form: Form1Component
  constructor(private title: Title,
              private messageService: MessageService,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,) {
    this.dataMoa1={}
  }

  ngOnInit() {
    this.title.setTitle('Aglo - Inscription architecte' );
    window.prerenderReady = true;
    this.route.params.forEach((params: Params) => {
      if (params['userId']) {
        this.userId = params['userId'];
      }
    })
  }
  //Get Archi ID

  //Register
  register(){
    this.loading = true;
    let isValid = this.form.isValid()
    let moaData = this.form.getFormData();
    if(isValid){
      this.subscriptions.push(
        this.authService.registerCollaborateur(this.userId,moaData)
            .subscribe(
                () => {
                    this.loading = false;
                    this.router.navigate(['/architect/my-consultations']);
                    this.messageService.add({ key: 'toast', severity: 'success', summary: 'Compte créé', detail: 'Votre compte a été crée', life: 5000 });
                },
                error => {
                    this.loading = false;
                    let err = (() => { try { return JSON.parse(error._body) } catch (something) { return error }})()
                    console.log("error",err)
                    this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: 'Le mail utilisé doit être le même que le mail de réception', life: 5000 });
                })
      )
    }else{
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Formulaire incomplet', detail: 'Veuillez remplir tous les champs obligatoires', life: 5000 });
      this.loading = false;
    }
  }

  public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
  }

}
