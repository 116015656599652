import { Component, OnInit, ViewChild, NO_ERRORS_SCHEMA } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { ArtisanSignupLoginComponent } from '../../../artisan/artisan-signup-login/artisan-signup-login.component';
import {Title,Meta} from '@angular/platform-browser';
import { NgSharedModule } from 'src/app/all/ngshared.module';

//Service
import { MessageService } from 'primeng/api';
import { AuthService } from '../../../_services/index';
import { IdentifySegement } from 'src/app/all/segment/identify';

import { ArtisanSingupAgencyInfoComponent } from 'src/app/artisan/artisan-singup-agency-info/artisan-singup-agency-info.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';

declare var AmCharts: any;
@Component({
    selector: 'app-artisan-subscribe-signup',
    imports:[
    ArtisanSignupLoginComponent,
    ArtisanSingupAgencyInfoComponent,
    SpinnerComponent,
    NgSharedModule
    ],
    providers: [],
    templateUrl: './artisan-subscribe-signup.component.html',
    styleUrls: ['./artisan-subscribe-signup.component.scss'],
    standalone: true,
    
})
export class ArtisanSubscribeSignupComponent implements OnInit {
  dataArtisan:any;
  public agencyData: any;
  public loading:Boolean = false;
  public showAgencyComponent:Boolean = true;
  public artisanId;currentUrl;lastName;firstName;
  public map: any;
  @ViewChild(ArtisanSignupLoginComponent) form: ArtisanSignupLoginComponent
  franceDepartmentsMap: any;
  constructor(private title: Title,
              private route: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              private authService: AuthService,
              private identify: IdentifySegement) {
    this.dataArtisan={}
  }

  ngOnInit() {
    // this.createMap()
    // this.franceDepartmentsMap = AmCharts.maps.franceDepartmentsLow;
    this.title.setTitle('BAM - Inscription artisan' );
    window.prerenderReady = true;
    this.route.queryParams.subscribe(params => {
      this.artisanId = params.artisanId;
    })
    this.currentUrl = new URL(window.location.href);
      this.firstName = this.currentUrl.searchParams.get('firstName') || '';
      this.lastName = this.currentUrl.searchParams.get('lastName') || '';      
  }
  //Register
  register(){
    let data = {
      artisanData: this.form.getFormData(),
      agencyData: this.agencyData
    };
    data.artisanData.phone = data.artisanData.codePhone.concat(data.artisanData.phone)
    /// delete for back-end val middleware
    // delete data.agencyData.nom;
    // delete data.agencyData.prenom;
    delete  data.artisanData.codePhone
    this.loading = true;
    let isValid = this.form.isValid()
    let artisanData = this.form.getFormData()
    if(this.artisanId) data.artisanData.artisanId = this.artisanId
    if(isValid){
      this.authService.registerArtisan(data)
        .subscribe(
            data => {
              this.router.navigate(['artisan/project-list'], { queryParams: { showUpdateModal: 'true' } });
              this.messageService.add({ key: 'toast', severity: 'success', summary: 'Informations principales enregistrées', detail: 'Vos informations principales ont été enregistrées', life: 5000 });
            },
            error => {
                this.loading = false;
                let err = (() => { try { return JSON.parse(error._body) } catch (something) { return error }})()
                console.log("error",err)
                this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: err.message, life: 5000 });
            });
    }else{
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Formulaire incomplet', detail: "Veuillez remplir tous les champs obligatoires", life: 5000 });
      this.loading = false;
    }
  }
  public agencySubmitted(agencyData){
    this.dataArtisan = {
      ...this.dataArtisan,
      firstName : this.firstName || '',
      lastName : this.lastName || ''
    }
    
    this.showAgencyComponent = false;
    this.agencyData = agencyData;
  }
  private createMap() {
    this.map = new AmCharts.AmMap();
    this.map.balloon.color = "#000000";

    var dataProvider = {
      mapVar: AmCharts.maps.franceDepartmentsLow,
      getAreasFromMap: true
    };

    this.map.dataProvider = dataProvider;

    this.map.listeners = [
      {
        event: "clickMapObject",
        method: event => {
          this.map.selectedObject = this.map.dataProvider;
          event.mapObject.showAsSelected = !event.mapObject.showAsSelected;
          this.map.returnInitialColor(event.mapObject);
          var workAreaMain = [];
          for (var i in this.map.dataProvider.areas) {
            var area = this.map.dataProvider.areas[i];
            if (area.showAsSelected) {
              workAreaMain.push({ text: area.title, id: area.id });
            }
          }
          console.log('workAreaMain :', workAreaMain)
          //this.detail.workAreaMain = workAreaMain;
        }
      }
    ];
    this.map.areasSettings = {
      selectedColor: "#A387FF",
      color: "#eee",
      colorSolid: "#eee",
      rollOverColor: "#A387FF",
      rollOverOutlineColor: "#A387FF",
      selectable: true
    };
    //this.map.smallMap = new AmCharts.SmallMap();
    this.map.write("mapdiv");
  }
}
