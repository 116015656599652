<div id="booster-profile-edit-agency-garantie-decennale-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header no-padding">
        <h4 class="ag-heading-h4 ag-weight-600 no-margin margin-bottom-24">Garantie décénnale</h4>
        <p class="ag-para-1 ag-weight-500 text-center margin-bottom-70">Quelles sont les activités professionnelles garanties par votre assurance décénnale ?</p>
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">        
        
        <div class="outter-wrapper scroller-1">
          <!-- Single Activity Item -->
          <div class="single-activity-item horizontal-items margin-bottom-24" *ngFor="let agencyActivity of agencyActivitiesList">
            <ng-container *ngIf="!agencyActivity.isCustom">
            <!-- Icon Side -->
            <div class="icon-side text-center width-15">
              <img [src]="'/assets/corps-etats/'+agencyActivity.icon">
              <h5 class="ag-heading-h5 ag-weight-600 margin-vertical-8" [innerHtml]="agencyActivity.title"></h5>
              <p class="ag-para-1">
                <input type="checkbox" 
                  (change)="checkAllSections($event.target.checked, agencyActivity)"
                  [checked]="checkedActivities[agencyActivity.id]"
                  > <span class="margin-left-12">Tout séléctionner</span>
              </p>
            </div>
            <!-- END Icon Side -->
            
            <!-- Checkboxes Side -->
            <div class="inputs-side padding-left-50 width-85">
              
              <!-- single check boxes section -->
              <div class="single-checkboxs-section" *ngFor="let singleCheckboxSection of agencyActivity.sectionsLists; let i = index;">
                <h6 class="ag-heading-h6 ag-heading-h6 margin-bottom-7">{{ singleCheckboxSection.title }}</h6>
                <div class="check-boxes-list horizontal-items">
                  <span class="checkbox-item" 
                    *ngFor="let checkboxItem of singleCheckboxSection.list; let k = index;"
                    [ngClass]="{'choosed': selectedCorps[agencyActivity.id+'_'+singleCheckboxSection.id+'_'+checkboxItem.value] }"
                    (click)="chooseItem(agencyActivity.id, singleCheckboxSection.id, checkboxItem.value)"
                    >{{ checkboxItem.label }}</span>
                </div>
              </div>
              <!-- END single check boxes section -->

            </div>
            <!-- END Checkboxes Side -->
            </ng-container>
          </div>
          <!-- END Single Activity Item -->

          <!-- Custom Corps D'etat -->
          <div class="add-new-corps-etat-wrapper horizontal-items margin-top-20">

            <div class="custom-coprs-list padding-left-50 horizontal-items width-75">
              <span class="checkbox-item" 
                *ngFor="let customCheckboxItem of customAgencyActivitiesList.inputsList; let i = index;">
                {{ customCheckboxItem.label }}
                <i class="fa fa-times remove-icon" 
                (click)="removeCustomItem(i)"></i>
              </span>
            </div>

          </div>
          <!-- END Custom Corps D'etat -->

        </div>

        
        <div class="submit-btn-container text-center margin-top-30 ">
          <button class="ag-btn-primary-sm" (click)="emitSave()">Modifier les informations</button>
        </div>

      </div>
    </div>
  </div>
</div>
