<div class="row all" [ngClass]="{'back-img' : (waitingPass$|async)}">
<!-- <app-header></app-header> -->
<app-artisan-cgu-modal></app-artisan-cgu-modal>
  <div  *ngIf="(waitingPass$|async) && !(loadingData$ |async)" class="floating-card hello-artisan">
    <h1 class="center">Bienvenue sur votre outil de chiffrage</h1>
    <div class="code-input">
      <p-inputMask [(ngModel)]="code" mask=" 9 9 9 9 " placeholder=" _ _ _ _ " styleClass="big-letter" ></p-inputMask>
    </div>
    <button class="btn-primary-color" (click)="getDPGF()">
      <div>Commencer le chiffrage</div>
    </button>
  </div>

  <div class="spinner-center" *ngIf="(loadingData$ |async)">
    <app-spinner [size]="'big'"></app-spinner>
  </div>

  <div [@appear]='state' class="toast">
    <span *ngIf="!(done$|async)">
      <i *ngIf="(saving$ | async)" class="fa fa-spinner fa-spin fa-fw"></i>
      <i  *ngIf="!(saving$ | async)" class="fa fa-floppy-o" aria-hidden="true"></i> Enregistré
    </span>
    <span *ngIf="(done$|async)">
      Modifier mon chiffrage
    </span>
  </div>

  <div *ngIf="!(loadingData$ |async) && !(waitingPass$|async)" id="tool-bar" class="horizontal-items space-between center-items" [ngClass]='{sticky : isSticky}'>
    <div *ngIf="backbutton" class="margin-left-15  cursor width-fit-content"(click)="back()"> <img  class="padding-right-6"src="assets/arrow-left.svg">Retour</div>
    
    <div class="margin-top-6 margin-left-19percent">
      <div class="flex">
      <span  class="font-size-16">Chiffrage du projet <span class="color-blue font-weight-700" > {{(dpgf$|async)?.ceProject?.title}}</span></span>
      <div class="dropdown">
        <button class="btn-boo dropdown-toggle font-size-14 margin-top-0" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          Version {{(dpgf$|async)?.versions.length +1}}
          <span class="caret" *ngIf="(dpgf$|async)?.versions.length>0"></span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenu1" *ngIf="(dpgf$|async)?.versions.length>0">
          <li *ngFor="let version of (dpgf$|async)?.versions,let i = index">
            <a *ngIf="!version.createdAt" [href]="'/artisan/dpgf-version/' + version + '?code=' + code" target="_blank">
              <span>Voir la V.{{i+1}} , {{(dpgf$|async)?.createdAt | date : 'dd/MM/yyyy'}}</span>
            </a>
            <a *ngIf="version.createdAt" [href]="'/artisan/dpgf-version/' + version.id + '?code=' + code" target="_blank">
              <span>Voir la V.{{i+1}} , {{ version.createdAt | date : 'dd/MM/yyyy' }}</span>
            </a>
          </li>
        </ul>
      </div>
      </div>
    </div>
      <div class="flex" [ngStyle]="{'visibility': canEditOrSubmit ? 'visible' : 'hidden'}">
        <button *ngIf="!(done$|async)" (click)="saveDpgfModification()" class="btn-boo btn-outline-success">
          <span *ngIf="(saving$|async)"><i  class="fa fa-spinner fa-spin fa-fw"></i> Enregistrement en cours</span>
          <span *ngIf="!(saving$|async)"> <i class="fa fa-floppy-o" aria-hidden="true"></i> Enregistrer</span>
        </button> 
        <div class="dropdown dropdown-list" *ngIf="canEditOrSubmit">
        <button class="download-dropdown dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          <img src="assets/dpgf/arrow-down-to-line.svg"> Télécharger le chiffrage
        </button>
        <ul class="dropdown-menu wid95-padding-margins" aria-labelledby="dropdownMenu2">
            <a class="flex padding-bottom-10" >
              <span class="font-size14-black-color" (click)="openDevisPopup()"><img src="assets/download-pdf-icone.svg" class="padding-right-8" > Télécharger au format PDF</span>
            </a>
            <a class="flex">
              <span class="font-size14-black-color" (click)="downloadChiffrageExcel()"> 
                <!-- <img src="assets/excel.svg" class="padding-right-8" >  -->
                <i class="fa fa-file-excel-o padding-right-8"></i>
                Télécharger au format Excel</span>
            </a>
        </ul>
        </div>
      </div>
      <p *ngIf="(done$|async) && (dpgf$|async)?.ceProjectInvite.status == 'selectionne'" class="accepted-text">Devis validé, non éditable</p>
      <div  class="floatin" *ngIf="(changes$ | async) != 0">
        <div type="button" class="click-it modif-alert" (click)="saveDpgfModification()">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          {{changes$ | async }} Modifications non enregistrées
        </div>
      </div>

    <!-- Accept or resfuse ceProject Invitation -->
    <div class="accept-or-refuse-buttons-container flex-middle" *ngIf="showAcceptOrRefuseButtons && !isFactice">
      <ng-container *ngIf="!acceptingOrRefusingLoader">
        <button class="ag-btn-danger-bordered" (click)="showConfirmRefuseInvitationModal()">Refuser le projet</button>
        <button class="ag-btn-dark-green" (click)="openAcceptInviteToEditModal()">Accepter le projet</button>
      </ng-container>

      <app-spinner *ngIf="acceptingOrRefusingLoader"></app-spinner>

    </div>

    <!-- END Accept or resfuse ceProject Invitation -->

  </div>

  <div class="container full-width" *ngIf="!(loadingData$ |async) && !(waitingPass$|async)">
    <div class="row">
      <div class="col-md-12" >

        <!-- <div class="row floating-card" style="margin-top: 70px;"> -->
          <div class="row margin-top-70">
          <div class="col-md-12">
            <app-head-dpgf 
              [canEditOrSubmit]="canEditOrSubmit"
              mode="artisan" 
              (updateAgencyInfo)="updateAgencyInfo($event)"
              (updateCeInviteFacticeArtisanInfo)="updateCeInviteFacticeArtisanInfo($event)"
              > </app-head-dpgf>
          </div>

        </div>

        <app-dpgf-folders mode="artisan" [dpgfPricingId]="dpgfPricingId" [code]="code"></app-dpgf-folders>
        <div class="row">
          <app-table-dpgf 
            [mode]="(((dpgf$|async)?.ceProjectInvite||{}).status=='signe')?'view':'artisan'" 
            [dpgf]="dpgf$|async" 
            [isFactice]="isFactice"
            [open]="openLotJson"
            [canEdit]="canEditOrSubmit"
            (showAcceptModal)="openAcceptInviteToEditModal()" 
            ></app-table-dpgf>
        </div>


        <!-- send chiffrage -->
        <div class="horizontal-items space-between send-chiffrage-cards-wrapper margin-top-25">
          
          <!-- Pièces jointes -->
          <div class="ag-card-1 width-30 attachments-card">
            <h5 class="ag-heading-h5 ag-text-blue margin-bottom-13">Pièces jointes</h5>
            

            <div class="attachments-list">
              <ul>
                <li *ngFor="let file of (dpgf$|async)?.artisanFiles; let i = index" class="horizontal-items space-between">
                  <a *ngIf="!file.isStaticFile" class="ag-para-1 ag-text-link-blue margin-bottom-8"  [href]="file.Location">
                    {{file.Key.substring(file.Key.indexOf("-")+1) }}
                  </a>
                  <a *ngIf="file.isStaticFile" class="ag-para-1 ag-text-link-blue margin-bottom-8" [href]="'/api/v1/static/'+file.Location" target="_blank">
                    {{file.key}}
                  </a>
                  <img class="cursor" src="/assets/delete-icone.svg" (click)="removeFile(i)">
                </li>
              </ul>
            </div>

            <div class="flex-horizontal">
              <button *ngIf="!authService.currentEnvironment &&!loadingFiles" class="ag-btn-bordered-xs add-files-btn" [disabled]="!canEditOrSubmit"  (click)="openUploadArtisanFiles()">
                <img src="/assets/dpgf/add-sign.svg" alt="" style="padding-bottom:4px;">
                Ajouter des fichiers
              </button>
              <button *ngIf="authService.currentEnvironment && !loadingFiles" class="ag-btn-bordered-xs add-files-btn"  (click)="openUploadArtisanFiles()">
                <img src="/assets/dpgf/add-sign.svg" alt="" style="padding-bottom:4px;">
                Ajouter des fichiers
              </button>
            </div>
            <!-- when button above clicked trigger click on this p-fileUpload -->
            <p-fileUpload 
              name="myfile[]"
              *ngIf="!loadingFiles"
              multiple="multiple"
              customUpload="true"
              (uploadHandler)="fileEvent($event)"
              auto="true"
              chooseLabel="Ajouter"
              mode="basic"></p-fileUpload>
            <app-spinner *ngIf="loadingFiles"></app-spinner>

          </div>
          <!-- END Pièces jointes -->

          <!-- Chiffrage réalisé -->
          <div class="ag-card-1 width-70 chiffrage-info-card">
            <h5 class="ag-heading-h5 ag-text-blue margin-bottom-17">Chiffrage réalisé à {{((nbLineDone$ |async)||0) / ((nbLines$ |async)||1) * 100 | number:'1.0-0'}}%</h5>
            <div *ngIf="!remiseButton" >
            <div class="horizontal-items space-between margin-bottom-17">
              <p class="ag-heading-h5">Prix total HT</p>
              <p class="ag-heading-h5">{{total$ |async | currency: 'EUR':'symbol':undefined:'fr-FR' }}</p>
            </div>
            <div class="horizontal-items space-between">
              <p class="ag-heading-h5">Prix total TTC</p>
              <p class="ag-heading-h5">{{totalTtcAfterRemise$ |async | currency: 'EUR':'symbol':undefined:'fr-FR' }} TTC</p>
            </div>
            </div>
            <!-- Remise -->
            <div class="discount-wrapper margin-top-19">
              <div class="font-size-20" *ngIf="remiseButton">
                <div class="row" style="margin-bottom:18px;">Prix total HT:
                  <span class="total-recap">{{total$ |async | currency: 'EUR':'symbol':undefined:'fr-FR' }}</span>
                </div>
                <div *ngIf="remiseButton" class="row flex" >
                  <div class="relative size-14-top-16">
                  <img class="relative paddingright-13-bottom-2" src="assets/remise-icones-violet.svg" alt=""> Remise commerciale
                 </div>
                  <div class="flex float-right">
                  <input id="enter" type="number" (focusout)="ammountChange($event)" [(ngModel)]="discountAmount" min="0" (keydown.enter)="ammountChange($event)" class="discount-input" />
                  <select (click)="ammountChange($event)" [(ngModel)]="discountUnite" class="cursor select-color-borders">
                    <option>€</option>
                    <option>%</option>
                  </select>
                  <img style="padding-left:9.75px;cursor:pointer;"(click)="remiseButtonState()" src="assets/delete-icone.svg" />
                </div>
                </div>
                <div *ngIf="remiseButton" class="row bordertop-margins-padding"></div>
                <div *ngIf="remiseButton" class="row" style="padding-bottom:18px;" >Prix total HT après remise
                  <span class="total-recap"> {{totalHtAfterRemise$ |async | currency: 'EUR':'symbol':undefined:'fr-FR'}}</span>
                </div>
                <div class="row">Prix total TTC
                  <span  class="total-recap">{{totalTtcAfterRemise$ |async | currency: 'EUR':'symbol':undefined:'fr-FR' }} TTC</span>
                </div>
             </div>
              <div class="add-discount flex-right margin-top-15" *ngIf="!remiseButton" >
                <button (click)="remiseButtonState()" class="flex padding-3" style="background-color:white;border-radius: 4px;"> 
                  <img src="/assets/remise-icones.svg" class="margin-right-8" style="margin-top:1px;">
                  <p  class="no-margin-bottom" style="font-size:16px;">Ajouter une remise commerciale</p>
                </button>
              </div>
            </div>
          </div>
          <!-- END Chiffrage réalisé -->

        </div>
        <div class="flex-middle margin-top-32 padding-bottom-32">
          
          <button class="ag-btn-primary-xs"
            [disabled]="!canEditOrSubmit"
            *ngIf="!(done$|async)" 
            (click)="toogleSendChiffrageModal()">Envoyer le chiffrage</button>

          <div class="disabled-send-chiffrage-wrapper" *ngIf="(done$|async)" >
            <button 
              class="ag-btn-primary-xs"
              (click)="toogleSendChiffrageModal()" disabled>
              <span class="tooltip-text">La version actuelle du chiffrage a été envoyée</span>

              Envoyer le chiffrage
            </button>
          </div>


        </div>
        <!-- END send chiffrage -->



        <!-- Send Chiffrage Modal -->
        <div class="modal fade" tabindex="-1" role="dialog" id="send-chiffrage-popup">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                
                <div class="flex-horizontal margin-bottom-24 margin-top-30">
                  <img src="/assets/dpgf/send-chiffrage-mail-icon.svg">
                </div>
                <h4 class="modal-title ag-heading-h4 ag-text-down-river text-center">Envoyer le chiffrage</h4>

              </div>
        
              <div class="modal-body">
                <p class="ag-para-1 text-center margin-bottom-16">
                  Vous êtes sur le point de soumettre la Version {{ (dpgf$|async)?.pricingVersion }} du chiffrage pour le projet 
                  <span class="ag-text-success-tertiary">{{(dpgf$|async)?.ceProject?.title}}.</span> 
                </p>

                <p class="ag-para-1 text-center">Vous pourrez créer une version ultérieure à tout moment.</p>

                <div class="separator margin-top-32 margin-bottom-24"></div>

                <div class="message-box">
                  <p class="ag-para-1 ag-text-12 padding-bottom-4">Message à destination de l'architecte <span>(Optionel)</span></p>
                  <textarea placeholder="Ecrire ici" (keyup)="setArtisanMessage($event.target.value)"></textarea>
                </div>
                
              </div>
        
              <!-- Footer -->
              <div class="modal-footer text-center">
                <button class="ag-btn-primary" *ngIf="!(finishing$|async)" (click)="finishDpgfModification()">Envoyer le chiffrage</button>
                <app-spinner *ngIf="(finishing$|async)"></app-spinner>
              </div><!-- /.modal-footer -->
            </div><!-- /.modal-content -->
          </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
        <!-- 
          ===========================================
          END Send Chiffrage Modal
          ===========================================
         -->

      </div>
    </div>
  </div>
</div>

<!-- Print dpgfPricing lots  Popup -->
<div id="print-dpgf-devis"
      tabindex="-1"
      class="modal"
      role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <app-dpgf-devis-popup 
      [dpgfPricing]="dpgfPricing" [documentType]="documentType" 
      (closeModal)="closeDevisPopup()"
      (openPdfErrorsModal)="showErrorLocationModal($event)"
      ></app-dpgf-devis-popup>
  </div>
</div>
<!-- END Print dpgfPricing lots  Popup -->

<div id="accept-invite-to-edit-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60" style="width:639px;">
    <div class="modal-content padding-16">
      <div class="modal-header no-border no-padding">
        <h3 class="modal-title modal-header-title" style="padding-left:11px;">Commencer le chiffrage</h3>
      </div>
      <div class="modal-body">
        <p class="ag-para-2">
          Vous êtes sur le point de commencer à chiffrer le projet <b>{{projectName}}.</b>
        </p>
        <div class="ag-para-2">
          <span>
            Merci d'indiquer la date prévisionnelle de remise de votre offre :
            <input type="date" name="" id="" style="width:127px;border:1px solid rgba(219, 221, 225, 1);border-radius:7px;" [(ngModel)]='deliveryDate' (ngModelChange)="onDateChange($event)">
            </span>
            </div>
            <p class="ag-para-2">
            La Maîtrise d'Oeuvre en sera notifiée
          </p>
      </div>
      <div class="modal-footer text-center no-border no-padding">
        <button type="button" data-dismiss="modal" class="ag-btn-tertiary-sm margin-right-20">Annuler</button>
        <button type="button" class="ag-btn-primary-sm submit-btn" (click)="acceptCeProjectInvitation()">Commencer le chiffrage</button>
      </div>
    </div>
  </div>
</div>

<!-- Confirm Refuse Invitation -->
<div id="confirm-refuse-invite-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content padding-16">
      <div class="modal-header no-border no-padding">
        <h3 class="modal-title modal-header-title">Refuser le projet</h3>
      </div>
      <div class="modal-body">
        <p>
          Vous êtes sur le point de refuser le projet {{projectName}}, l’architecte sera notifié que vous refusez le projet. Vous ne pourrez plus consulter le DCE ni envoyer une offre.
          <br><br>
          Souhaitez-vous continuer ?
        </p>
      </div>
      <div class="modal-footer text-center no-border no-padding">
        <button type="button" data-dismiss="modal" class="ag-btn-tertiary-sm margin-right-20">Annuler</button>
        <button type="button" class="ag-btn-primary-sm submit-btn" (click)="refuseCeProjectInvitation()">Oui, refuser le projet</button>
      </div>
    </div>
  </div>
</div>
<!-- END Confirm Refuse Invitation -->


<!-- Errors on the Document Modal -->
<app-documents-errors-modal [messageObject]="documentsErrorInfo"></app-documents-errors-modal>
<!-- Errors on the Document Modal -->

<p-confirmDialog 
  acceptButtonStyleClass="oui-version" 
  acceptLabel="Oui" 
  rejectLabel="Non" 
  header="Changement de version" 
  width="425"></p-confirmDialog>
 