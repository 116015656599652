import { createFeatureSelector, createSelector } from "@ngrx/store";

const getChantierFeatureState = createFeatureSelector<any>('chantier');

export const getFacture = createSelector(
    getChantierFeatureState,
    state => state.facture
);

export const getFlags = createSelector(
    getChantierFeatureState,
    state => state.flags
);