import { Component, OnInit, Input,Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit, ChangeDetectorRef, NO_ERRORS_SCHEMA, ElementRef, Renderer2 } from '@angular/core';
import {TreeModule} from 'primeng/tree';
import {TreeNode} from 'primeng/api';
import {ArrayDataSource, SelectionModel} from '@angular/cdk/collections';
import { WebView2Service } from 'src/app/_services/webview2.service.service';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { param } from 'jquery';


//Interfaces
interface categoriesNode{
  label?:string;
  children?:categoriesNode[];
  selected?: boolean;
  indeterminate?: boolean;
  quantity?:Number;
  data?: any;
  isBeforeLastNode?:boolean;
  parent?: categoriesNode;
}
//consts
const FEET = 3.28084;
const FEET_SQUARE = 10.7639;
const FEET_CUBE = 35.3146;

//Component
@Component({
  standalone:true,
  selector: 'app-revit-project-arborescence',
  imports:[
    DpgfModule,
    SpinnerComponent,
    NgSharedModule
  ],
  templateUrl: './revit-project-arborescence.component.html',
  styleUrls: ['./revit-project-arborescence.component.scss']
})

export class RevitProjectArborescenceComponent implements OnInit {
  //Global variables
  @Input()openProjectArborescence;
  @Output() changeOpenProjectArborescence = new EventEmitter<any>();
  public dataStructureOfProject:any = [];
  public selectedNodes: categoriesNode[] = [];
  public isDataLoaded: boolean=false;
  public selectedUnit: string='ens';
  public quantity:any;
  public dataArrived: number = 0;
  public units: Array<any> = [
    { value: 'ens', label: 'Ens' },
    { value: 'LENGTH', label: 'm' },
    { value: 'AREA', label: 'm2' },
    { value: 'VOLUME', label: 'm3' }
  ];
  public isRevit: boolean;
  public dataSource :categoriesNode[]
  public data=[
    {"name":"Site",
    "childreen":
      [
       {"name":"Surface:492456",
        "childreen":[
                     {"name":"492456 : Surface:492456",
                      "childreen":
                      [{"name":"150613",
                        "identifier":"0b306943-d2be-468a-8335-3fdf7fd509c1-00024c55",
                        "arrayOfParameters":[
                        {"parameter":"CURVE_ELEM_LENGTH","value":0},
                        {"parameter":"HOST_VOLUME_COMPUTED","value":122663.96393458545},
                        {"parameter":"HOST_AREA_COMPUTED","value":32329.372663900765}]}]}]}
    ]
  }
]

  constructor(
    private wv2Service: WebView2Service,
    private cd: ChangeDetectorRef,
    private el: ElementRef, private renderer: Renderer2 ) {}

  ngOnInit(): void {
    this.isRevit = navigator.userAgent.includes("Autodesk.Revit")
    const treeElement = this.el.nativeElement.querySelector('p-tree');
    if (treeElement) {
      this.renderer.listen(treeElement, 'click', (event) => {
        this.handleTreeClick(event);
      });
    }
      this.subscribeElementRevitOnClick();
    this.cd.detectChanges()
  }
  handleTreeClick(event: Event): void {
    const clickedElement = event.target as HTMLElement;
    
    // Check if the clicked element or its parent has the desired class
    if (clickedElement.classList.contains('p-tree-container') || clickedElement.parentElement?.classList.contains('p-tree-container')) {
      event.stopPropagation();
      // Your custom logic for handling the div click goes here
      console.log('Clicked on element with class "your-class-name"');
    }
  }
  // .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight
  
  public dtatransform(){
    let itemsArray: categoriesNode[] = [];
    
    for (let i = 0; i < this.data.length; i++) {
      let categoryNode: categoriesNode = {
        label: this.data[i].name,
        children : this.transformDataToTreeNode(this.data[i].childreen),
      };
      itemsArray.push(categoryNode);
    }
    this.dataSource = itemsArray;

  }
  public subscribeElementRevitOnClick() {
    this.dataStructureOfProject = [];
    this.isDataLoaded = true;
    this.wv2Service.subscribeToWebView2Event('getdatastructureofcategories', (e) => {
      if (this.dataArrived < 2 && e.detail && e.detail.length) {
        this.dataStructureOfProject = [...e.detail];
        this.dataArrived++;
        let itemsArray: categoriesNode[] = [];
    
        for (let i = 0; i < this.dataStructureOfProject.length; i++) {
          let categoryNode: categoriesNode = {
            label: this.dataStructureOfProject[i].name,
            data: {ifcGuid:this.dataStructureOfProject[i].ifc|| null, arrayOfParameters:this.dataStructureOfProject[i].arrayOfParameters || null, identifier:this.dataStructureOfProject[i].identifier|| null},
            children : this.transformDataToTreeNode(this.dataStructureOfProject[i].childreen),
          };
          itemsArray.push(categoryNode);
        }
        this.dataSource = itemsArray;
        this.isDataLoaded = false;
      }
      this.cd.detectChanges();
    });
  }
  
  public isLastLeaf(node: TreeNode): boolean {
    return !node.children; 
  }

  private transformDataToTreeNode(data: any): categoriesNode[] {
    return data.map((item) => {
      
      const node: categoriesNode = {
        label: item.name,
        data: {ifcGuid:item.ifc|| null, arrayOfParameters:item.arrayOfParameters || null, identifier:item.identifier|| null},
      };
  
      if (item.childreen && item.childreen.length > 0) {
        node.isBeforeLastNode=(item.childreen && item.childreen[0].arrayOfParameters)?true:false;
        node.children = this.transformDataToTreeNode(item.childreen);
      }
  
      return node;
    });
  }
  
  public stopSelectionPropagation(event){      
    event.stopPropagation();
  }

  public changeNodeSelectedValue(node){
      node.selected=true
      if (node.children) {
        node.children.forEach((child) => {
          this.changeNodeSelectedValue(child);
        });
      }
    this.checkAllParents(node);
  }

  private convertToTreeNodes(childreen): categoriesNode[] {
    if (!childreen || childreen.length === 0) {
      return [];
    }
  
    return childreen.map(child => ({
      label: child.name,
      children: this.convertToTreeNodes(child.childreen),
    }));
  }

  public itemToggle(checked: boolean, node: categoriesNode) {
    node.selected = checked;
    if (node.children) {
      node.children.forEach((child) => {
        this.itemToggle(checked, child);
      });
    }
    this.checkAllParents(node);
  }

  public checkAllParents(node: categoriesNode) {
    if (node.parent) {
      const descendants = this.getDescendants(node.parent);
      node.parent.selected = descendants.every((child) => child.selected);
      node.parent.indeterminate = descendants.some(
        (child) => child.selected && !child.indeterminate
      );
      this.checkAllParents(node.parent);
    }
  }
  
  public getSelectedElement(node) {
    if(node.selected){
    this.selectAllElementInRevit([node.data.identifier]);
    //this.selectAllElementInRevit([node.label]);
    }
  }

  public nodeUnselect(node){
    node.selected=false
    if (node.children) {
      node.children.forEach((child) => {
        this.nodeUnselect(child);
      });
    }
  this.checkAllParents(node);
  }
  
  public getSelectedElements(node) {
    let result=[] ;
    //if(node.selected){
      node.children.forEach(item=>{
        if(item.selected &&item.data.identifier){
          result.push(item.data.identifier)
        }
      })
      this.selectAllElementInRevit(result);
    //}
  }

  public isAnyChildSelescted(node):boolean{
    let isAnyChildSelescted;
     node.children.forEach(child => {
      if (child.selected) {
        isAnyChildSelescted=true;
      }
    });
    return isAnyChildSelescted;
  }

  public getAllDifferentElements() {
    let result=[];
    this.selectedNodes.forEach(element => {
      if( element.data.identifier && element.data.identifier.length==45){
        result.push(element.data.identifier)
      }
    });
    this.selectAllElementInRevit(result);
  }

  public selectAllElementInRevit(ids) {
    this.wv2Service.postWebView2Message({
      action: 'select',
      payload: ids,
    });
  }

  public countSingleQuantity(value,  node) {
    this.selectedUnit = value;

    this.quantity = 1;
    (node.data.arrayOfParameters || []).forEach((p) => {
      if (p.parameter.includes('VOLUME') && this.selectedUnit === 'm3') {
        this.quantity = Math.round((p.value + Number.EPSILON) * 100 / FEET_CUBE) / 100;
      }
      if (p.parameter.includes('AREA') && this.selectedUnit=== 'm2') {

        this.quantity = Math.round((p.value + Number.EPSILON) * 100 / FEET_SQUARE) / 100;
      }
      if (p.parameter.includes('LENGTH') && this.selectedUnit=== 'm') {

        this.quantity = Math.round((p.value + Number.EPSILON) * 100 / FEET) / 100;
      }
      if (this.selectedUnit === 'Ens') {
        this.quantity = 1;
      }
    });
    node.quantity= this.quantity === parseInt(this.quantity, 10) ? this.quantity : this.quantity.toFixed(2);
  }

  public countAllQuantities(value, node) {
    let units = {
      m: 'LENGTH',
      m2: 'AREA',
      m3: 'VOLUME',
    };

    let conts = {
      m: 3.28084,
      m2: 10.7639,
      m3: 35.3146,
    };

    this.quantity = 0;
    this.selectedUnit = value;
    let children = node.children;
    for (let i = 0; i < children.length; i++) {
      let temp;
      
      children[i].data.arrayOfParameters.forEach((parm) => {
        if (parm.parameter.includes(units[this.selectedUnit])) {
          temp = parm.value;
        }
      });
      this.quantity += Math.round((temp + Number.EPSILON) * 100 / conts[this.selectedUnit]) / 100;
    }
    node.quantity = this.quantity;
  }

  public closeProjectArborescence() {
    this.openProjectArborescence = false;
    this.changeOpenProjectArborescence.emit(this.openProjectArborescence);
    $(".p-component-overlay").css("display","none");
  }

  private getDescendants(node: categoriesNode): categoriesNode[] {
    const descendants: categoriesNode[] = [];
    if (node.children) {
      node.children.forEach((child) => {
        descendants.push(child, ...this.getDescendants(child));
      });
    }
    return descendants;
  }
}