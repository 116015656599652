import {Component,OnInit,Input,Output,SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup,  Validators} from '@angular/forms';
import { trigger,style,animate,transition,keyframes } from '@angular/animations';
import {ActivatedRoute} from '@angular/router'
import { Subscription } from 'rxjs/Subscription';

import { PasswordValidation } from '../../_helpers/password-validation';
import { AuthService } from '../../_services/index';

import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $;

@Component({
    selector: 'app-form1',
    standalone:true,
    imports:[NgSharedModule],
    templateUrl: './form1.component.html',
    styleUrls: ['./form1.component.scss'],
    animations: [
        trigger('error', [
            transition('* => true', [
                animate('2s', keyframes([
                    style({ backgroundColor: 'transparent' }),
                    style({ backgroundColor: 'rgba(255, 0, 0, 0.15)' }),
                    style({ backgroundColor: 'transparent' })
                ]))
            ]),
        ])
    ],
    
})
export class Form1Component implements OnInit {
  @Input() architect: any;
  //added
  @Input() email: any;

  @Input() disabled: boolean;
  @Input() noEmailValidation: boolean;
  @Input() hidePassword: boolean = false;
  @Input() isProfile;
  public oldEmail: string ='';
  public error: string='';
  public index = 0;
  public subscriptionForm: UntypedFormGroup;
  public validEmail:boolean = true;
  private subscriptions: Array<Subscription> = [];
  public formErrors = {'firstName': '','lastName': '','email': '','password': '','phone': ''};
  private validationMessages = {
    'firstName': {
      'required': 'Le prénom est obligatoire.'
    },
    'lastName': {
      'required': 'Le nom est obligatoire.',
    },
    'email': {
      'required': 'Le mail est obligatoire.',
      // 'email':'Email invalide',
      "email": "",
      'pattern': 'Email invalide' // this is for regex pattern 
    },
    'password': {
      'required': 'Le mot de passe est obligatoire.',
      'minlength':"Minimum 6 caractères",
    },
    'phone': {
      'required': 'Le numéro de téléphone est obligatoire.',
    }
  };
  constructor(private fb: UntypedFormBuilder,
              private authService: AuthService,
              private router: ActivatedRoute) {}

  ngOnInit() {
    this.createForm();
    this.subscriptionForm.patchValue(this.architect || {});
    this.oldEmail = this.architect.email;
  }
  //Set Archi on creation
  ngOnChanges(changes: SimpleChanges) {
        if (changes['architect'] && this.subscriptionForm) {
            this.subscriptionForm.patchValue(this.architect);
        }
    }
  //Create Form
  private createForm() {
    this.subscriptionForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [this.email, [
        Validators.required, 
        Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$")
      ]],
      phone: [{value: '',disabled: this.disabled}, [Validators.required]],
      password: [{value: '',disabled: this.disabled}, [Validators.required,Validators.minLength(6)]],
      // password2: [{value: '',disabled: this.disabled}, [Validators.required,Validators.minLength(6)]],
      cgu: [{value: false , disabled: this.disabled}, [Validators.required]],
    });
    this.subscriptions.push(
      this.subscriptionForm.valueChanges.subscribe(data => this.onValueChanged(data))
    )
    this.onValueChanged();
  }
  //Check email
  public focusOutEmail(scroll){
    if(!this.noEmailValidation && !this.router.snapshot.queryParamMap.get('token')){
      if(!this.formErrors.email){
        this.subscriptions.push(
          this.authService.validateEmail(this.subscriptionForm.value.email)
          .subscribe(
            data => {
              this.validEmail = data;
              if (scroll) {
                this.scrollToError()
              }
            },
            error => {
              console.log(error)
            })
          )
        } else if (scroll) {
          this.scrollToError()
        }
    }
  }
  //Generate Error
  private onValueChanged(data ? : any) {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  //Mark as touched
  private markFormAsTouched():void {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      control.markAsDirty();
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
            this.formErrors[field] += messages[key] + ' ';
        }
    }
  }
  //Scroll to error
  public scrollToError(){
    let loop = 0;
    let keys = Object.keys(this.formErrors);
    let errorKey = ""
    while(loop < keys.length && this.formErrors[keys[loop]] =="" ){
      loop ++;
    }
    if (!this.validEmail) {
      this.scrollTo(`[formControlName=email]`, 40);
      this.error = 'email'
      setTimeout(()=>{this.error = ''},2000)
    } else if (keys[loop]) {
      this.scrollTo(`[formControlName=${keys[loop]}]`, 40);
      this.error = keys[loop]
      setTimeout(()=>{this.error = ''},2000)
    }
  }
  //Validate Form
  public isValid(scroll = true){
      this.markFormAsTouched()
      this.focusOutEmail(scroll)
      let validation;
      if(this.email) validation=this.subscriptionForm.valid && (this.validEmail || this.noEmailValidation) && this.subscriptionForm.value.cgu && (this.subscriptionForm.value.email==this.email)
      else validation=this.subscriptionForm.valid && (this.validEmail || this.noEmailValidation) && this.subscriptionForm.value.cgu
      return validation
      // return this.subscriptionForm.valid && this.subscriptionForm.value.cgu
  }
  public getFormData(){
    let val = this.subscriptionForm.value;
    val.password2 = val.password;
    val.email == this.oldEmail ? delete val.email : null;
    return val;
  }
  cancelChanges(){
    this.subscriptionForm.patchValue(this.architect);
  }
  public scrollTo(element, offset = 0) {
    $('html, body').animate({
      scrollTop: ($(element).offset().top - offset)
    });
  }
  public ngOnDestroy(): void {
      this.subscriptions.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
      });
  }
}
