import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {  ActivatedRoute, Params, Router} from '@angular/router';
import { AuthService } from '../../../_services/index';
import { HeaderComponent } from "../../../all/header/header.component";
import { NgSharedModule } from "../../../all/ngshared.module";

@Component({
    selector: 'app-openid-connected-page',
    standalone: true,
    templateUrl: './openid-connected-page.component.html',
    styleUrl: './openid-connected-page.component.scss',
    imports: [HeaderComponent, NgSharedModule]
})
export class OpenidConnectedPageComponent {
  private cookieValue: string;

  constructor(private cookieService: CookieService,
    private _authService: AuthService,
    private router:Router,
    private route:ActivatedRoute
    ) {
  }

  ngOnInit(): void {
    const decodedString = this.cookieService.get('cokieUser')
    const jsonStringWithoutPrefix = decodedString.substring(2);
    if(jsonStringWithoutPrefix){
      this.cookieValue = JSON.parse(jsonStringWithoutPrefix);
      delete this.cookieValue['message']
      localStorage.setItem('currentUser', JSON.stringify(this.cookieValue));
      let isTokenHere = this._authService.saveResponseJson(this.cookieValue);
      if(isTokenHere){
        this.cookieService.delete('cokieUser');
        this.route.queryParams.subscribe((params: Params) => {
          const cleanedReturnUrl = params.returnUrl.replace(/"/g, '');
          this.router.navigate([cleanedReturnUrl?cleanedReturnUrl:'architect/my-consultations']);
        })
      }else{
          let currentToken = localStorage.getItem('currentUser');
          if(currentToken){
            this.router.navigate(['/login']);
          }
          else this._authService.logout();
      }
  }
  else {
      this.router.navigate(['/login']);
  }
}
}
