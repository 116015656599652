
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ArchitectService } from 'src/app/_services';
import {FormBuilder, FormGroup,  Validators} from '@angular/forms';
import { NgSharedModule } from 'src/app/all/ngshared.module';
declare var $:any;
@Component({
  selector: 'app-modal-update-user',
  standalone: true,
  imports: [NgSharedModule],
  templateUrl: './modal-update-user.component.html',
  styleUrl: './modal-update-user.component.scss'
})
export class ModalUpdateUserComponent {
  @Input() userInfo;
  public lastName;firstName;email;userId;validEmail=true;mailAlreadyExist = false;emailExist="";
  private subscriptions: Array<Subscription> = [];
  public subscriptionForm: FormGroup;
  public formErrors = {'firstName': '','lastName': '','email': ''};
  private validationMessages = {
    'firstName': {
      'required': 'Le prénom est obligatoire.'
    },
    'lastName': {
      'required': 'Le nom est obligatoire.',
    },
    'email': {
      'required': 'Le mail est obligatoire.',
      'email':'Email invalide'
    }
  };
  constructor(private architectService :ArchitectService,private fb: FormBuilder) { }

  ngOnInit(): void {
    this.createForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if(!changes.userInfo.firstChange){
      this.lastName = changes.userInfo.currentValue.lastName
      this.firstName = changes.userInfo.currentValue.firstName
      this.email = changes.userInfo.currentValue.email
      this.userId = changes.userInfo.currentValue._id;
      this.createForm();
    }
  }
  public createForm(){
    this.subscriptionForm = this.fb.group({
      firstName: [this.firstName||'', Validators.required],
      lastName: [this.lastName||'', Validators.required],
      email: [this.email||'', [Validators.required,Validators.email]],
    });
    this.subscriptions.push(
      this.subscriptionForm.valueChanges.subscribe(data => this.onValueChanged(data))
    )
    this.onValueChanged();
  }
  public valider(){
    let user = {
      lastName:this.subscriptionForm.value.lastName,
      firstName:this.subscriptionForm.value.firstName,
      email:this.subscriptionForm.value.email
    }
    if(this.isValid()){
      this.emailExist='';
    this.subscriptions.push(
      this.architectService.updateUser(this.userId,user).subscribe((data) => {
        $('#update-user-info').modal('hide');
        // console.log('data',data)
      },err=>{
        if(err.error.err.message=='Mail already exist'){
          this.emailExist = 'Email déja lié a un autre utilisateur';
        }
      })
    )}
  }
  private onValueChanged(data ? : any) {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
            this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
  public markFormAsTouched():void {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      control.markAsDirty();
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
    }
  }
  public isValid(){
    this.markFormAsTouched();
    return this.subscriptionForm.valid
  }
}
