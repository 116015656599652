<div class="modal-wrapper-card ag-card-1">

  <!-- Header -->
  <div class="header-container flex space-between">
    <h4 class="ag-heading-h4 ag-weight-400" *ngIf="currentView == 'booster' || currentView == 'carnet-adress'">Obtenir un chiffrage</h4>
    <h4 class="ag-heading-h4 ag-weight-400" *ngIf="currentView == 'artisan-lots'">{{ selectedAgencyName }}</h4>
    <h4 class="ag-heading-h4 ag-weight-400" *ngIf="currentView == 'add-to-carnet-address'">Ajouter une entreprise à mon carnet d’adresse</h4>
    
    <span><img src="assets/close-icone-black.svg" class="pointer" (click)="closeAddArtisanModal()"></span>
  </div>
  <!-- END Header -->


  <!-- Body -->
  <div class="body-container">

    <!-- Tabs -->
    <div class="tabs-container flex" *ngIf="currentView == 'booster' || currentView == 'carnet-adress'">
      <div class="single-tab pointer" [ngClass]="{'selected': currentView=='booster' }" (click)="switchView('booster')">
        <p class="ag-para-2 ag-text-grey" >AGLO <span class="ag-weight-700">BOOSTER©</span></p>
      </div>
      <div class="single-tab pointer" [ngClass]="{'selected': currentView=='carnet-adress' }" (click)="switchView('carnet-adress')">
        <p class="ag-para-2 ag-text-grey">Carnet d’adresse</p>
      </div>
    </div>
    <!-- END Tabs -->

    <!-- Views Container -->
    <div class="views-container">

      <!-- Aglo Booster View
      ================================================= -->
      <div class="aglo-booster-view" *ngIf="currentView == 'booster'">
        
        <div class="top-text-wrapper">
          
          <p class="margin-bottom-10 upper-txt">
            <span class="ag-weight-700">Faites chiffrer des entreprises qualifiées et suivies par nos équipes</span><br>
            L’accès à notre réseau est inclus dans votre licence. <a class="see-more-link" href="https://www.aglo.ai/booster/" target="_blank">En savoir plus</a>
          </p>
          
          <!-- text List -->
          <div class="text-list">
            <p>
              <img src="assets/checked-icon.svg" alt="">
              Entreprises <span class="ag-weight-700">recommandées</span> et <span class="ag-weight-700">notées</span> par plusieurs architectes
            </p>
            <p>
              <img src="assets/checked-icon.svg" alt="">
              Profil complet avec <span class="ag-weight-700">portfolio</span>, accessible en clin d’oeil sur AGLO
            </p>
            <p>
              <img src="assets/checked-icon.svg" alt="">
              Dossier <span class="ag-weight-700">administratif</span> prêt à être consulté
            </p>
            <p>
              <img src="assets/checked-icon.svg" alt="">
              <span class="ag-weight-700">Mise en relation,</span> suivi du chiffrage et <span class="ag-weight-700">relance</span>
            </p>
          </div>
          <!-- END text List -->

        </div>

         <!-- Lots Wrapper -->
         <div class="lots-wrapper">
            
          <div class="top-section flex center-items space-between">
            <p>Tous les lots</p>
            <p-inputSwitch (onChange)="allLotsChecked($event)"></p-inputSwitch>
          </div>

          <div class="lots-list scroller-2-y margin-right-10">
            <div class="single-lot-item flex pointer" 
              [ngClass]="{'selected': selectedLots[lot.id]}" *ngFor="let lot of dpgfLot"
              (click)="toggleLot(lot.id)"
              >
              <div class="img-wrapper">
                <img src="/assets/rob/helmet.png" *ngIf="!lot.icon">
                <img [src]="'/assets/dpgf/Icon_DPGF/'+lot.icon" *ngIf="lot.icon">
              </div>             
              <p>{{ lot.name }}</p>
            </div>
          </div>

        </div>
        <!-- END Lots Wrapper -->

        <!-- Submit Button -->
        <div class="submit-button-wrapper flex-middle">
          <button class="ag-btn-primary-sm" *ngIf="!sumittingRequest"
            [disabled]="numberOfLotsSelected==0" 
            (click)="emitSendDemandeChiffrage()">
            Envoyer ma demande de chiffrage
          </button>
          
          <app-spinner *ngIf="sumittingRequest"></app-spinner>

        </div>
        <!-- END Submit Button -->

      </div>
      <!-- END Aglo Booster View
      ================================================= -->

      <!-- Carnet d'address View
      ================================================= -->
      <div class="carnet-address-view" *ngIf="currentView == 'carnet-adress'">

        <!-- top text container -->
        <div class="top-text-container">
          <p>Choisissez une entreprise, assignez-lui les lots à chiffrer et validez votre sélection.</p>
        </div>
        <!-- END top text container -->

        <!-- Search Inputs Container -->
        <div class="search-inputs-container">

          <div class="search flex">
            <input type="text" placeholder="Chercher une entreprise" [(ngModel)]="relationsSearchQuery" (keyup)="emitSearchRelations()">
            <i class="fa fa-search"></i>
          </div>

          <div class="filter-dropdown borderd-element p-fluid">
            <p-multiSelect
              [placeholder]="'Filtrer par corps d’état'"
              [selectedItemsLabel]="'Plusieurs éléments sélectionnés'"
              [panelStyle]="{minWidth:'100%'}"
              [options]="tagsList"
              optionLabel="name"
              (onChange)="filterArtisans($event.value)"
            ></p-multiSelect>
          </div>

        </div>
        <!-- END Search Inputs Container -->

        <!-- Artisans List Wrapper -->
        <div class="artisans-list-wrapper scroller-2-y">
          <div class="single-artisan-item pointer flex" *ngFor="let relation of relationsList" (click)="selectArtisan(relation)">
            <div class="img-side">
              <div class="img-container">
                <img [src]="relation?.artisanOwned?.logo?.cdnUrl || '/assets/worker.png' ">
              </div>
            </div>
            <div class="info-side">
              <p>{{ relation.artisanOwned?.name || '' }} ( {{ relation.artisanOwned?.emailAgency || '' }} )</p>
              <div class="tags-wrapper scroller-1">
                <span *ngFor="let tag of relation.tags">{{ tag?.title || tag?.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- END Artisans List Wrapper -->

        <!-- Add to carnet button container -->
        <div class="add-to-carnet-button-container">

          <div class="next-prev-buttons-wrapper flex space-between margin-bottom-10">
            <button class="ag-btn-bordered-xs"
              [disabled]="currentRelationsPage == 1" 
              (click)="loadRelationsPage('prev')">
              <i class="fa fa-chevron-left"></i>
              Précédent
            </button>
            <button class="ag-btn-bordered-xs" 
              [disabled]="currentRelationsPage == relationsListNumberOfPages"
              (click)="loadRelationsPage('next')">
              Suivant
              <i class="fa fa-chevron-right"></i>
            </button>
          </div>
          
          <div class="flex center-items">
            <p class="pointer" (click)="switchView('add-to-carnet-address')">
              <img src="/assets/add-icon.svg" alt="">
              <span>Ajouter une entreprise à mon carnet d’adresse</span>
            </p>
          </div>

        </div>
        <!-- END Add to carnet button container -->

      </div>
      <!-- END Carnet d'address View
      ================================================= -->


      <!-- Carnet d'address View
      ================================================= -->
      <div class="artisan-lots-view" *ngIf="currentView == 'artisan-lots'">

        <!-- Lots Wrapper -->
        <div class="lots-wrapper">
            
          <div class="top-section flex center-items space-between">
            <p>Tous les lots</p>
            <div class="input-switch-container">
              <p-inputSwitch (onChange)="allLotsChecked($event)"></p-inputSwitch>
            </div>
          </div>

          <div class="lots-list scroller-2-y margin-right-10">
            <div class="single-lot-item flex pointer" 
              [ngClass]="{'selected': selectedLots[lot.id]}" *ngFor="let lot of dpgfLot"
              (click)="toggleLot(lot.id)"
              >
              <div class="img-wrapper">
                <img src="/assets/rob/helmet.png" *ngIf="!lot.icon">
                <img [src]="'/assets/dpgf/Icon_DPGF/'+lot.icon" *ngIf="lot.icon">
              </div>          
              <p>{{ lot.name }}</p>
            </div>
          </div>

        </div>
        <!-- END Lots Wrapper -->

        <!-- Bottom Buttons -->
        <div class="bottom-action-buttons flex-middle" *ngIf="!sumittingRequest">
          <button class="ag-btn-primary-sm return-btn" (click)="switchView('carnet-adress')"> 
            <img src="/assets/chevron-left.svg" alt="" srcset=""> Retour
          </button>
          <button class="ag-btn-primary-sm" [disabled]="numberOfLotsSelected==0" (click)="emitAddAgency()">Ajouter l’entreprise</button>
        </div>
        
        <div class="bottom-action-buttons flex-middle" *ngIf="sumittingRequest">
          <div>
            <app-spinner></app-spinner>
          </div>
        </div>
        <!-- END Bottom Buttons -->

      </div>
      <!-- END Carnet d'address View
      ================================================= -->

      <!-- Add to Carnet d'address View
      ================================================= -->
      <div class="add-to-carnet-address-view" *ngIf="currentView == 'add-to-carnet-address'">

        <!-- Form Body -->
        <div class="form-body">

          <div class="single-form-item">
            <p>Nom de l’entreprise *</p>
            <input type="text" placeholder="Nom de l’entreprise" [(ngModel)]="carnetAddressForm.name">
          </div>

          <div class="single-form-item">
            <p>Prénom du contact</p>
            <input type="text" placeholder="Prénom" [(ngModel)]="carnetAddressForm.firstName">
          </div>

          <div class="single-form-item">
            <p>Nom du contact</p>
            <input type="text" placeholder="Nom" [(ngModel)]="carnetAddressForm.lastName">
          </div>

          <div class="single-form-item">
            <p>Email *</p>
            <input type="text" placeholder="adresse@email.com" [(ngModel)]="carnetAddressForm.emailAgency">
          </div>

          <div class="single-form-item">
            <p>Numéro de téléphone</p>
            <input type="text" placeholder="0612345" [(ngModel)]="carnetAddressForm.telAgency">
          </div>

          <div class="single-form-item">
            <p>Tag</p>
            <div class="p-fluid">
              <p-multiSelect
                [placeholder]="'Sélectionner un/des corps d’état'"
                [selectedItemsLabel]="'Plusieurs éléments sélectionnés'"
                [panelStyle]="{minWidth:'100%'}"
                [options]="tagsList"
                optionLabel="name"
                [(ngModel)]="carnetAddressForm.tags"
              ></p-multiSelect>
            </div>
          </div>
          
        </div>
        <!-- END Form Body -->

        <!-- Bottom Buttons -->
        <div class="bottom-action-buttons flex-middle">

          <div class="" *ngIf="!sumittingRequest">
              <button class="ag-btn-tertiary-sm return-btn" (click)="switchView('carnet-adress')"> 
                <img src="/assets/chevron-left.svg" alt="" srcset=""> Retour
              </button>
              <button class="ag-btn-primary-sm"
              [disabled]="carnetAddressForm.emailAgency == '' || carnetAddressForm.name == ''" 
              (click)="addAgencyToCarnetAddress()">
              Ajouter l’entreprise à mon carnet d’adresse
            </button>
          </div>

          <div *ngIf="sumittingRequest">
            <app-spinner></app-spinner>
          </div>

        </div>
        <!-- END Bottom Buttons -->

      </div>
      <!-- END Add to Carnet d'address View
      ================================================= -->


    </div>
    <!-- END Views Container -->


  </div>
  <!-- END Body -->


</div>
