<div class="precision-form-wrapper">

  <div class="single-input-item margin-bottom-20">
    <h5 class="ag-heading-h5 ag-weight-600">Dans quel(s) département(s) exercez-vous principalement ?</h5>
    <div class="suggestions-container flex-vertical relative width-100">
      <div class="horizontal-items space-between pointer" (click)="toggleShowSuggestionsList()">
        <p class="ag-para-1" id="dropdown-label">Sélectionnez un ou plusieurs départements</p>
        <img src="/assets/artisan-booster/arrow-down.svg" alt="" srcset="">
      </div>

      <div class="suggestions-list-scroll-wrapper">

      <!-- Suggestions List -->
      <div class="suggestions-list" *ngIf="showSuggestionsList">
        <ul class="no-padding">          
          
          <li *ngFor="let region of regionsList" 
            class="main-list-item full-width relative">
            <div class="full-width horizontal-items space-between">
              <span>{{ region.name }}</span>
              <img src="/assets/artisan-booster/arrow-right.svg" *ngIf="!regionSelectedDepartementsNumber[region.value]">
              <span class="departements-counter flex-middle" *ngIf="regionSelectedDepartementsNumber[region.value]">
                {{ regionSelectedDepartementsNumber[region.value] }}
              </span>
            </div>
            <ul class="nested-list no-padding">
              <li (click)="checkRegionDepartements(region.value, region.departements)">
                <input type="checkbox"
                  [checked]="regionSelectedDepartementsNumber[region.value] == region.departements.length"> 
                  Tous les départements
              </li>
              <li *ngFor="let departement of region.departements" 
                (click)="selectDepartement(region.value, departement.id, true)">
                <input type="checkbox" [checked]="selectedDepartements[departement.id]">
                {{ departement.departName }}
              </li>
            </ul>
          </li>

        </ul>
      </div>
      <!-- END Suggestions List -->

      </div>

    </div>
  </div>

  <div class="input-item margin-bottom-30">
    <h5 class="ag-heading-h5">Avez-vous un domaine de spécialisation ?</h5>
    <input type="text" class="no-border-focus" [(ngModel)]="domaineSpe" placeholder="Rensignez ici les domaines dans lesquels vous excellez">
  </div>  

</div>