import { LotPricing } from "./team-lot-pricing.interface";

export class ArtisanTeam {

    private _id: string;
    private _title: string;
    private _lotPricing: LotPricing[];

    constructor(title: string) {
        this._lotPricing = [];
        this._title = title;
    }

    public get id(): string {
        return this._id;
    }
    public set id(id: string) {
        this._id = id;
    }
    public get lotPricing(): LotPricing[] {
        return this._lotPricing;
    }
    public set lotPricing(lotPricing: LotPricing[]) {
        this._lotPricing = lotPricing;
    }
    public get title(): string {
        return this._title;
    }
    public set title(title: string) {
        this._title = title;
    }
    public get total(): number{
        return this.lotPricing.reduce((value: number, pricing: LotPricing) => {
            return value += ((pricing||{total : 0}).total);
        }, 0);
    }
    public getLotPricing(idLot: string): LotPricing {
        return this._lotPricing.find((lot: LotPricing) => lot.idLot == idLot);
    }
    public addPricing(pricing: LotPricing) {
        this.lotPricing = this.lotPricing.filter(p => p.idLot !== pricing.idLot);
        this._lotPricing.push(pricing);
    }
    removePricing(pricing: LotPricing) {
        this.lotPricing = this.lotPricing.filter(p => p.idLot !== pricing.idLot);
    }
    cheapest() {
        //
        Math.min(...this.lotPricing.map(p => p.total));
    }
}
