<div class="main-wrapper">

  <!-- Upper Section 
  ======================================== -->
  <div class="upper-section flex space-between">

    <!-- 1- lots actions buttons -->
    <div class="lots-actions-buttons flex">
      
      <div class="action-btn flex-middle pointer margin-right-16" (click)="emitAddLot()">
        <img src="/assets/add-icon.svg" class="margin-right-12" alt="">
        <p>Ajouter un lot</p>
      </div>
      <div class="action-btn flex-middle pointer" (click)="emitOpenImportLotModal()">
        <img src="/assets/icon_button.svg" class="margin-right-12" alt="">
        <p>Importer un lot</p>
      </div>

    </div>
    <!-- 1- END lots actions buttons -->

    <!-- 2- Lots Counter -->
    <div class="global-dpgf-data-count flex-middle">

      <div class="posts-count">
        <p>{{ nbLines }} postes</p>
      </div>
      <div class="lots-count">
        <p>{{ dpgf.totalFinishedLots }}/{{ dpgf.data.length }} lots terminés</p>
      </div>

    </div>
    <!-- 2- END Lots Counter -->

    <!-- 3 Total Dpgf Pricing -->
    <div class="total-dpgf-pricing">
      <div class="total-ht">
        <p>{{ totalHt | formatPrice  }} € HT</p>
      </div>
      <div class="total-ttc">
        <p>{{ totalTtc | formatPrice }} € TTC</p>
      </div>
    </div>
    <!-- 3 END Total Dpgf Pricing -->
    
    





  </div>
  <!-- END Upper Section 
  ======================================== -->



  <!-- Lots List
  ======================================== -->
  <div class="lots-list-wrapper">

    <!-- Table Header -->
    <div class="lots-list-table-header flex center-items">
      <div class="table-header-cell" class="icon-cell"></div>
      <div class="table-header-cell" class="title-cell">
        <p>LOT</p>
      </div>
      <div class="table-header-cell" class="status-cell">
        <p>STATUT</p>
      </div>
      <div class="table-header-cell" class="cctp-cell">
        <p>CCTP</p>
      </div>
      <div class="table-header-cell" class="estimation-cell padding-right-10">
        <p>ESTIMATION</p>
      </div>
      <div class="table-header-cell" class="action-cell"></div>
    </div>
    <!-- END Table Header -->
    
    <!-- Table Body -->
    <div class="lots-list-table-body scroller-1-y">

      <!-- Single Lot Row -->
      <div class="single-lot-row flex center-items pointer" *ngFor="let lot of dpgf.data; let i = index;">

        <!-- Lot Icon -->
        <div class="table-body-cell icon-cell flex-middle" (click)="emitOpenLot(lot.id)">
          <div class="">
            <span class="lot-index margin-right-12">{{ lot.indexLot }}</span>

            <img src="/assets/rob/helmet.png" *ngIf="!lot.icon">
            <img [src]="'/assets/dpgf/Icon_DPGF/'+lot.icon" *ngIf="lot.icon">
          </div>
        </div>
        <!-- END Lot Icon -->

        <!-- Lot Title -->
        <div class="table-body-cell title-cell" (click)="emitOpenLot(lot.id)">
          <p class="title">{{ lot.title }}</p>
          <span class="posts-number">{{ lot.nbLines }} poste{{ lot?.nbLines > 1 ? 's' : '' }}</span>
        </div>
        <!-- END Lot Title -->

        <!-- Lot Status -->
        <div class="table-body-cell status-cell">
          
          <!-- Status Dropdown -->
          <div class="dropdown">
            
            <div class="status-badge flex center-items pointer" id="dropdownMenuButton" data-toggle="dropdown"
              [ngClass]="lot.status||'en-cours'">
              <span class="status-badge-dot"></span>
              <span class="status-badge-title">{{ statusLabelMapper[(lot.status||"en-cours")] }}</span>            
            </div>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

              <li class="dropdown-item pointer flex" *ngFor="let lotStatus of lotStatusList"
                (click)="emitUpdateLotStatus(lot.id,lotStatus.value)"
                [ngClass]="{'hidden-item': (lot.status||'en-cours') == lotStatus.value }"
                >
                <div class="status-badge flex center-items pointer" id="dropdownMenuButton" data-toggle="dropdown" 
                  [ngClass]="lotStatus.cssClass">
                  <span class="status-badge-dot"></span>
                  <span class="status-badge-title">{{ lotStatus.label }}</span>            
                </div>
              </li>

            </div>
          </div>
          <!-- Status Dropdown -->


        </div>
        <!-- END Lot Status -->

        <!-- Lot CCTP -->
        <div class="table-body-cell cctp-cell" (click)="emitOpenLot(lot.id)">
          <p class="title">{{ lot.numberOfPostsWithCctp || '0' }} description{{ lot?.numberOfPostsWithCctp > 1 ? 's' : '' }} </p>
          <span class="posts-number">{{ lot.percentageOfPostsWithCctp || '0' }}% complété</span>
        </div>
        <!-- END Lot CCTP -->

        <!-- Lot Estimation -->
        <div class="table-body-cell estimation-cell" (click)="emitOpenLot(lot.id)">
          <p class="title">{{ (lot.totalArchi||0) | formatPrice }} € HT</p>
          <span class="posts-number" *ngIf="lot.priceAll">100% estimé</span>
          <span class="posts-number" *ngIf="!lot.priceAll">
            {{ lot.percentageOfEstimation || '0' }}% estimé
          </span>
        </div>  
        <!-- END Lot Estimation -->
        
        <!-- Lot Action -->
        <div class="table-body-cell action-cell text-center">

          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li class="dropdown-item pointer flex" (click)="emitDuplicateLot(lot)">
                Dupliquer
                <img src="/assets/duplicate-icon.svg" class="margin-left-8" alt="">
              </li>
              <li class="dropdown-item pointer flex" (click)="emitOpenConfirmRemoveLotModal(lot.id, lot.title)">
                Supprimer
                <img src="/assets/remove-icon.svg" class="margin-left-8" alt="">
              </li>
            </div>
          </div>

        </div>
        <!-- END Lot Action -->

      </div>
      <!-- END Single Lot Row -->

    </div>
    <!-- END Table Body -->


  </div>
  <!-- END Lots List
  ======================================== -->





</div>