import { Component, OnInit, Input,Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';


@Component({
  standalone:true,
  selector: 'app-delete-dce-post-modal',
  imports:[DpgfModule,NgSharedModule],
  templateUrl: './delete-dcepost-modal.component.html',
  styleUrls: ['./delete-dcepost-modal.component.scss']
})
export class DeleteDCEPostModalComponent implements OnInit {
  
  @Input() selectedPostToDelete:any;
  @Input() selectedSousPostToDelete:any;
  @Input() selectedSousLotToDelete:any;
  

  @Output() deletePoste: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteSousPoste: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteSousLot: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public confirmDeletePost(){
    this.deletePoste.emit();
  }
  public confirmDeleteSousPost(){
    this.deleteSousPoste.emit();
  }
  public confirmDeleteSousLot(){
    this.deleteSousLot.emit();
  }

}
