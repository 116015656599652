import { Component, OnInit, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSharedModule } from 'src/app/all/ngshared.module';


@Component({
    standalone:true,
    selector: 'app-booster-profile-add-corps-etat-modal',
    imports:[
      CommonModule,
      NgSharedModule
    ],
    templateUrl: './booster-profile-add-corps-etat-modal.component.html',
    styleUrls: ['./booster-profile-add-corps-etat-modal.component.scss'],
    
})
export class BoosterProfileAddCorpsEtatModalComponent implements OnInit {
  
  @Output() createCustomCorpsEtats: EventEmitter<any> = new EventEmitter<any>();

  public newCorpsEtatTitle: string = "";
  public showEtatAlreadyExistsError: boolean = false;

  public corpsEtatList: any = [];

  constructor() { }

  ngOnInit(): void {
  }

  public addNewCorpsEtat(){
    this.showEtatAlreadyExistsError = false;

    let itemValue = this.newCorpsEtatTitle.toLocaleLowerCase().replace(/\s/g, '-');
    let itemTitle = this.newCorpsEtatTitle;

    /// if item already exists show the error
    if(this.corpEtatExists(itemValue)){
      this.showEtatAlreadyExistsError = true;
      return;
    }
    
    let newCorpsEtat = {
      label: itemTitle,
      value: itemValue,
      custom: true
    };

    this.corpsEtatList.push(newCorpsEtat);
    this.newCorpsEtatTitle = "";
  }

  private corpEtatExists(value){
    let exists = false;

    for(let i = 0; i < this.corpsEtatList.length; i++){
      if(this.corpsEtatList[i].value == value){
        exists = true;
        break;
      }
    }

    return exists;
  }

  public createNewCustomCorps(){
    this.createCustomCorpsEtats.emit(this.corpsEtatList);
  }
  public removeCorpEtatItem(itemIndex){
    this.corpsEtatList = this.corpsEtatList.filter((item, index) => index != itemIndex);
  }

}
