import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { Helpers } from './helpers';

@Injectable()
export class ChantierService {

  constructor(private http: HttpClient) { }

  getFactures(inviteId, criteria) : Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.get(`/api/v1/ceproject-invite/${inviteId}/dpgf-facture?type=${criteria.type}`, options)
      .pipe(map((response: any) => {
        return response.data;
    }));
  }
  getArtisanFactures() : Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.get(`/api/v1/ceproject-invite/dpgf-facture-artisan`, options)
      .pipe(map((response: any) => {
        return response.data;
    }));
  }
  postFacture(inviteId) : Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/ceproject-invite/${inviteId}/dpgf-facture`, null, options)
      .pipe(map((response: any) => {
        return response.data;
    }));
  }

  getFacture(factureId) : Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.get(`/api/v1/dpgf-facture/${factureId}`, options)
      .pipe(map((response: any) => {
        return response.data;
    }));
  }

  updateFacture(factureId, factureData) : Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.patch(`/api/v1/dpgf-facture/${factureId}`, factureData, options)
      .pipe(map((response: any) => {
        return response.data;
    }));
  }

  updateFactureFinished(factureId, factureData) : Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.patch(`/api/v1/dpgf-facture/${factureId}/finished`, factureData, options)
      .pipe(map((response: any) => {
        return response.data;
    }));
  }

  acceptFacture(factureId, acceptedFacture) : Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.patch(`/api/v1/dpgf-facture/${factureId}/accepted`, acceptedFacture, options)
      .pipe(map((response: any) => {
        return response.data;
    }));
  }

  rejectFacture(factureId, rejectedFacture) : Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.patch(`/api/v1/dpgf-facture/${factureId}/rejected`, rejectedFacture, options)
      .pipe(map((response: any) => {
        return response.data;
    }));
  }

}
