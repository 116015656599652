<!-- <app-header></app-header>


<div class="text-center margin-top-60">
  <img src="/assets/not_allowed.png" alt="" class="main-img">

  <h1 class="main-title">Nouvelle connexion détectée</h1>
  <p class="text-details">
    Une session est déjà en cours sur votre compte depuis un autre appareil,<br> 
    vous allez donc être redirigé vers la page d’accueil.
  </p>

  <p class="text-details">
    Si vous n’êtes pas à l’origine de cette connexion,<br> 
    nous vous recommandons de modifier votre mot de passe.
  </p> -->

  <!-- <p class="text-details">
    Localisation de la session en cours : {{ userSessionLocation.country }}, {{ userSessionLocation.city }}
  </p> -->

  <!-- <div class="text-counter">
    Retour à la page de connexion dans {{ count }} secondes
  </div>

</div> -->

<app-header></app-header>

<div class="flex justify-center " style="width: 100%;height: 90%;align-items: center;">
    <div style="width: 520px;height: 456px;">
      <div class="flex justify-center padding-bottom-16"  >
        <img src="/assets/errors-svg/error-451.svg">
      </div>
      <div class="padding-bottom-16" style="font-size: 25px;text-align: center;font-weight: 700;">
        Nouvelle connexion détectée 
      </div>
      <div class="padding-bottom-16" style="font-size: 16px;text-align: center;">
       <p>
        Une session est déjà en cours sur votre compte depuis un autre appareil, vous allez donc être redirigé vers la page d’accueil.
        <br>
        <br>
        Si vous n’êtes pas à l’origine de cette connexion, nous vous recommandons de modifier votre mot de passe.   
       </p>
      <div class="flex justify-center">
        Retour à la page de connexion dans {{ count }} secondes
      </div>
    </div>
  </div>