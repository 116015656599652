<div id="3ds-stripe" class="modal"  tabindex="-1" data-keyboard="false">
	<div class="modal-dialog">
		<div class="modal-content">
				<div class="icon-holder">
					<img src="/assets/archi/round-aglo-icon.svg" alt="">
				</div>
				<!-- d'un montant de <b>€79.00 EUR</b> -->
				<p class="content-title">Vous êtes en train d'effectuer un paiement qui utilise l'authentification 3D secure. </p>
				<p class="sub-title">Après avoir terminé la validation, <b>rafraîchissez la page pour continuer</b></p>
				<div style="margin-top:18px;text-align: center;">
					<button type="button" class="ag-btn-primary-sm b1" ><a style="color: white;" target="_blank" [href]="url">Continuer la transaction</a></button>
					<!-- <button type="button" class="ag-btn-primary-sm b2" (click)="cancelPayement()"><p style="color: #A9A9A9;">Annuler la transaction</p></button> -->
					<button type="button" class="ag-btn-primary-sm b2"><p style="color: #A9A9A9;">Veuillez nous contacter</p></button>
				</div>
			</div>
	</div>
</div>