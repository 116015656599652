export class formateImage {

    static do(info:any,key:string) {
      let image = info;
      let icons = {
        "image/jpeg" : "jpg.png",
        "image/jpg" : "jpg.png",
        "image/png" : "png.png",
        "application/pdf" : "pdf.png",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" : "doc.png",
        "application/msword" : "doc.png",
      }
      delete image.originalImageInfo;
      delete image.sourceInfo;
      // image.cdn = "uploadcare";
      image.key = key;
      image.icon = icons[image.mimeType]||"file.png"
      return image;
    }
}
