import { Component, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, Input, NO_ERRORS_SCHEMA } from '@angular/core';

// static data
import activitiesList from './activities-list';
import { BoosterProfileAddCorpsEtatModalComponent } from '../booster-profile-add-corps-etat-modal/booster-profile-add-corps-etat-modal.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $: any;
@Component({
    standalone:true,
    selector: 'app-booster-profile-corps-etat-form',
    imports:[
      NgSharedModule,
      BoosterProfileAddCorpsEtatModalComponent, 
    ],
    templateUrl: './booster-profile-corps-etat-form.component.html',
    styleUrls: ['./booster-profile-corps-etat-form.component.scss'],
})
export class BoosterProfileCorpsEtatFormComponent implements OnInit, OnChanges  {

  @Input() corpsDetatData: any = {
    corpsDetat: []
  };
  @Output() saveData: EventEmitter<any> = new EventEmitter<any>();
  @Output() formIsValid: EventEmitter<any> = new EventEmitter<any>();

  public loading: boolean = true;
  public agencyActivitiesList: any = [];
  public customAgencyActivitiesList: any = [];
  public selectedItems: any = {};

  /// Check Activites
  public checkedActivities: any = {};

  
  constructor() { }

  ngOnInit(): void {
    this.agencyActivitiesList = activitiesList;
    this.loading = false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.corpsDetatData && changes.corpsDetatData.currentValue.corpsDetat.length){
      let artisanCorpsDetat = changes.corpsDetatData.currentValue.corpsDetat;
      setTimeout(() => {
        this.setSelectedData(artisanCorpsDetat);
      }, 500);
    }
  }

  private checkIfAllItemsSelectedInSection(artisanCorpsDetat){
    let sectionsMapper = {};

    /// count all Activities items
    for(let i = 0; i < this.agencyActivitiesList.length; i++){
      let currentActivity = this.agencyActivitiesList[i];
      
      sectionsMapper[currentActivity.id] = {};

      let listItemsCount = 0;
      for(let j = 0; j < currentActivity.sectionsLists.length; j++){
        let currentSection = currentActivity.sectionsLists[j];

        listItemsCount+=currentSection.list.length;
      }
      sectionsMapper[currentActivity.id] = listItemsCount;
    }


    /// count already checked items for every section
    let checkedItems = {};

    let clonedArtisanCorpsDetat = artisanCorpsDetat.filter(item => typeof item == "string");

    for(let i = 0; i < clonedArtisanCorpsDetat.length; i++){
      let splittedItems = clonedArtisanCorpsDetat[i].split("_");

      let activityId = splittedItems[0];

      if(!checkedItems[activityId]){
        checkedItems[activityId] = 1;
      }else{
        checkedItems[activityId]++;
      }
        
    }

    /// see if all items checked on a single activity, if it does make the ativity id tru
    for(let key in checkedItems){
      if(sectionsMapper[key] && (sectionsMapper[key] == checkedItems[key])){
        this.checkedActivities[key] = true;
      }
    }
  }

  private setSelectedData(artisanCorpsDetat){
    this.checkIfAllItemsSelectedInSection(artisanCorpsDetat);

    // 
    for(let i = 0; i < artisanCorpsDetat.length; i++){
      let item = artisanCorpsDetat[i];

      if(typeof item == "string"){
        this.selectedItems[item] = true;
      }else{
        this.customAgencyActivitiesList.push(item);
      }

    }
    let isFormValid = this.checkFormIsValid();
    this.emitFormIsValid(isFormValid);
  }

  public chooseItem(activityId, sectionId, checkboxValue){    
    let selected_item_id = `${activityId}_${sectionId}_${checkboxValue}`;

    // remove
    if(this.selectedItems[selected_item_id]){
      delete this.selectedItems[selected_item_id];
    }else{ // add
      this.selectedItems[selected_item_id] = true;
    }
    
    /// save after every check/uncheck
    this.emitSave();
  }
  public checkAllActivitySection(checked, activity){
    
    for(let i = 0; i < activity.sectionsLists.length; i++){
      let currentSection = activity.sectionsLists[i];

      for(let j = 0; j < currentSection.list.length; j++){
        let singleListItem = currentSection.list[j];
        let itemId = `${activity.id}_${currentSection.id}_${singleListItem.value}`;

        if(checked){
          this.selectedItems[itemId] = true;
        }else{
          if(this.selectedItems[itemId]){
            delete this.selectedItems[itemId];
          }
        }

      }
    }

    this.emitSave();
  }

  public getActivtiesList(){
    return this.agencyActivitiesList;
  }

  public openAddNewCorpsEtatModal(){
    $("#add-new-corps-etat-modal").modal("show");
  }

  public createCustomCorpsEtats(customCorpsEtatList){
    $("#add-new-corps-etat-modal").modal("hide");
    
    this.customAgencyActivitiesList.push(...customCorpsEtatList);

    this.emitSave();
  }
  public removeCustomItem(itemIndex){
    this.customAgencyActivitiesList = 
    this.customAgencyActivitiesList.filter((item, index) => index != itemIndex);

    this.emitSave();
  }

  public emitSave(){
    /// Merge Custom Lists & non Custom Lists into one Array
    let mainData = Object.keys(this.selectedItems);
    
    // if there is custom data merge it
    if(this.customAgencyActivitiesList.length){
      mainData.push(...this.customAgencyActivitiesList);
    }
    
    let formIsValid = mainData.length ? true : false;

    // emit Save Data
    this.saveData.emit({corpsDetat: mainData});

    // Emit Form Is Valid or Not
    this.emitFormIsValid(formIsValid);
  }

  private checkFormIsValid(){
    let isValid = false;
    
    if(Object.keys(this.selectedItems).length || this.customAgencyActivitiesList.length){
      isValid = true;
    }
    
    return isValid;
  }

  public emitFormIsValid(isValid){
    this.formIsValid.emit(isValid);
  }

}
