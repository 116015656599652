<ng-container [ngSwitch]="row.type">

  <!-- Select -->
  <div class="row-widget" *ngSwitchCase="'select'">
  <select class="ag-select-1"
    >
    <!-- [(ngModel)]="requiredFormInputs[row.modelVariable]" -->
      <option selected disabled hidden>Choix</option>
      <option *ngFor="let option of row.options"
        [value]="option.value"
        (change)="inputTyped(row.modelVariable, $event.target.value)">
        {{ option.label }}
      </option>
    </select>
  </div>
  <!-- END Select -->

  <!-- Radio Input -->
  <div class="row-widget flex" *ngSwitchCase="'radio'">
    <p *ngFor="let option of row.options; index as i"  [ngClass]="{'margin-left-32' : i > 0 }">
      <input type="radio" [name]="row.optionName" class="ag-input-1" [value]="option.value"
      (change)="inputTyped(row.modelVariable, $event.target.value)"
      >
      <!-- [(ngModel)]="requiredFormInputs[row.modelVariable]" -->
      {{ option.label }}
    </p>
  </div>
  <!-- END Radio Input -->

  <!-- Other Inputs -->
  <div class="row-widget flex center-items" *ngSwitchDefault>
      <input [type]="row.type" [placeholder]="row.placeholder"
        [ngClass]="{'ag-input-1': true, 'month-input': row.inputLabel}"
        (change)="inputTyped(row.modelVariable, $event.target.value)"
        >

        <span *ngIf="row.inputLabel" class="margin-left-32 input-label">{{row.inputLabel}}</span>
        
        <!-- [(ngModel)]="requiredFormInputs[row.modelVariable]" -->
  </div>
  <!-- END Other Inputs -->

</ng-container>
