import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArtisanService, AuthService } from '../../../_services/index';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $;

@Component({
    selector: 'app-artisan-modal-change-password',
    imports:[
    FormsModule,
    NgSharedModule
],
    providers: [],
    templateUrl: './artisan-modal-change-password.component.html',
    styleUrls: ['./artisan-modal-change-password.component.scss'],
    standalone: true,
    
})
export class ArtisanModalChangePasswordComponent implements OnInit {
  private subscriptions: Array<Subscription> = [];currentUser;validityPassword;actualPassword;newPassword;compareNewPwd;submited=false;keysUp = false
  public passwordWrong="";
  constructor(
    private artisanService: ArtisanService,
    private authService: AuthService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }
  public checkValidityPass(password){
    
    this.subscriptions.push(
      this.artisanService.verifyIfPasswordIsTrue(this.currentUser.id,{password:password})
        .subscribe(
          data => {
            this.validityPassword = data.validPassword;
            this.keysUp = true
          },
          error => {
            console.log("error",error)
          })
    );
  }
  public changeUserPassword(newPassword){
    let body = {
      password:this.actualPassword,
      newPass: newPassword
    }
    this.submited = true;
    this.subscriptions.push(
      this.authService.updatePassword(this.currentUser.id,body)
        .subscribe(
          data => {
            this.submited = false;
            $("#change-password").modal('hide');
            
            this.messageService.add({ key: 'toast', severity: 'success', summary: 'Succès', detail: 'Votre mot de passe a été changé', life: 5000 });
            this.actualPassword = "";
            this.newPassword= "";
            this.compareNewPwd = "";
            // this.validityPassword = data;
          },
          error => {
            this.passwordWrong = error.error.err.message;
            console.log(' error.message;', error.error.err.message)
            console.log("error",error)
          })
    );
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }
}
