<div class="modal-body">
  <!-- <input type="file" #fileImportInput name="File Upload" id="csvFileUpload"
      (change)="fileChangeListener($event)" *ngIf="!uploadedCsv"
      accept=".csv" /> -->
  <input *ngIf="!uploadedCsv" type="file" class="upload" (change)="changeListener($event.target.files)" accept=".csv">

<form *ngIf="uploadedCsv">
  <div class="form-group">
    <label class="required-star" for="">Email</label>
    <div class="row">
      <div class="col-md-7">
        <p>Choisissez la colonne de Email</p>
      </div>
      <div class="col-md-5">
        <select class="form-control form-control-fix" (change)="fieldSelected($event.target.value, 'email')">
          <option value="" selected hidden>Choisissez</option>
          <option [value]="title" *ngFor="let title of csvHeaders">{{ title }}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="required-star" for="">Nom de l’entreprise</label>
    <div class="row">
      <div class="col-md-7">
        <p>Choisissez la colonne de Nom de l’entreprise</p>
      </div>
      <div class="col-md-5">
        <select class="form-control form-control-fix" (change)="fieldSelected($event.target.value, 'name')">
          <option value="" selected hidden>Choisissez</option>
          <option [value]="title" *ngFor="let title of csvHeaders">{{ title }}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label for="">Téléphone</label>
    <div class="row">
      <div class="col-md-7">
        <p>Choisissez la colonne de Téléphone</p>
    </div>
    <div class="col-md-5">
      <select class="form-control form-control-fix" (change)="fieldSelected($event.target.value, 'phone')">
        <option value="" selected hidden>Choisissez</option>
        <option [value]="title" *ngFor="let title of csvHeaders">{{ title }}</option>
      </select>
    </div>
  </div>
  </div>
  <div class="form-group">
    <label for="">Nom</label>
    <div class="row">
      <div class="col-md-7">
        <p>Choisissez la colonne de Nom</p>
    </div>
    <div class="col-md-5">
      <select class="form-control form-control-fix" (change)="fieldSelected($event.target.value, 'lastName')">
        <option value="" selected hidden>Choisissez</option>
        <option [value]="title" *ngFor="let title of csvHeaders">{{ title }}</option>
      </select>
    </div>
  </div>
  </div>
  <div class="form-group">
    <label for="">Prénom</label>
    <div class="row">
      <div class="col-md-7">
        <p>Choisissez la colonne de Prénom</p>
    </div>
    <div class="col-md-5">
      <select class="form-control form-control-fix" (change)="fieldSelected($event.target.value, 'firstName')">
        <option value="" selected hidden>Choisissez</option>
        <option [value]="title" *ngFor="let title of csvHeaders">{{ title }}</option>
      </select>
    </div>
  </div>
  </div>
  <div class="form-group cancel-btn-wrapper">
    <button class="btn cancel-btn" (click)="cancelChoosing()">Annuler</button>
  </div>
</form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" data-dismiss="modal">Fermer</button>
  <button *ngIf="submittingCSV" class="btn spinner-btn"><app-spinner></app-spinner></button>
  <button *ngIf="!submittingCSV" [disabled]="!isFormValid()" type="button" class="btn btn-danger supp-btn" (click)="saveRecords()">Importer</button>
</div>
