import { Pipe, PipeTransform, NO_ERRORS_SCHEMA } from '@angular/core';

@Pipe({
  standalone:true,
  name: 'typeProjet'
})
export class TypeProjetPipe implements PipeTransform {

  transform(value:string) {
      if (value) {
          let result = value;
          if(value == "renovation"){
            result = "Rénovation";
          }
          else if(value == "surelevation"){
            result = "Surélévation";
          }
          else{
            result = result.charAt(0).toUpperCase() + result.slice(1);
          }
          return result;
      }
      return value;
  }
}
