<div id="add-recommandation-contact-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="ag-heading-h4 ag-weight-500 no-margin margin-right-10">
          <b>Coordonnés du maître d’oeuvre</b> 
        </h4>
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">   
        
        <!-- Contacts Table -->
        <div class="contacts-table margin-bottom-40" *ngIf="contactsList.length">
          <h5 class="ag-heading-h5 ag-heading-h5 margin-bottom-16">Contacts transmis</h5>

          <div class="contacts-table-header horizontal-items space-between">
            <span class="width-25">Nom</span>
            <span class="width-25">Nom de l’agence</span>
            <span class="width-25">Email</span>
            <span class="width-25">Numéro de téléphone</span>
          </div>
          <div class="contacts-table-body">
            <div class="single-contact-item horizontal-items space-between" 
              *ngFor="let contact of contactsList; let i = index;">
              <span class="ag-para-1-downriver width-25">{{ contact.nameArchi || "" }}</span>
              <span class="ag-para-1-downriver width-25">{{ contact.maitreDoeuvre || "" }}</span>
              <span class="ag-para-1-downriver width-25">{{ contact.mailArchi || "" }}</span>
              <div class="ag-para-1-downriver width-25 horizontal-items space-between remove-continer">
                <span>{{ contact.phoneNumber || "" }}</span>
                <img src="/assets/artisan-booster/remove-icon.svg" 
                  class="pointer" 
                  (click)="removeContact(i)">
              </div>
            </div>
          </div>          
          <!-- contactsList -->
        </div>
        <!-- END Contacts Table -->


        <!-- New Contact Form -->
        <div class="new-contact-form-container" *ngIf="showContactForm">
          
          <div class="horizontal-items space-between margin-bottom-16">
            <div class="form-input-item">
              <p>Nom de l’agence*</p>
              <input type="text" [(ngModel)]="newContactForm.nameArchi">
            </div>
            <div class="form-input-item">
              <p>Numéro de téléphone*</p>
              <input type="text" [(ngModel)]="newContactForm.phoneNumber">
            </div>
          </div>
          <div class="horizontal-items space-between">
            <div class="form-input-item">
              <p>Nom du maître d’oeuvre</p>
              <input type="text" [(ngModel)]="newContactForm.maitreDoeuvre">
            </div>
            <div class="form-input-item">
              <p>Email</p>
              <input type="email" [(ngModel)]="newContactForm.mailArchi">
            </div>
          </div>

        </div>
        <!-- END New Contact Form -->        

        <div class="add-new-contact-btn horizontal-items pointer" (click)="showAddNewContactForm()">
          <img src="/assets/artisan-booster/add-sign.svg" class="margin-right-8">
          <p class="ag-para-3 ag-weight-400">Ajouter un autre contact</p>
        </div>
        
        <div class="submit-btn-container text-center margin-top-30 ">
          <button class="ag-btn-primary-sm" 
            [disabled]="newContactForm.nameArchi == '' || newContactForm.phoneNumber== '' "
            (click)="saveNewContact()"
            >Transmettre</button>
        </div>

      </div>
    </div>
  </div>
</div>
