import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgSharedModule } from 'src/app/all/ngshared.module';

import { MessageService } from 'primeng/api';

import { AcvService, relatedFichesService, DpgfPostesService } from 'src/app/_services/index';
import { calculate } from 'src/app/all/cstb/cctp-edition/calcul-frigo';
import { unitsCodes } from '../acv-project-composant-sidebar/calcul-frigo';
import { AcvProjectComposantSidebarComponent } from '../acv-project-composant-sidebar/acv-project-composant-sidebar.component'
import { environment } from 'src/environments/environment';
declare var $:any;
var _ = require('lodash');


@Component({
    standalone: true,

    selector: 'app-acv-project-chantier-page',
    imports: [
      NgSharedModule,
      AcvProjectComposantSidebarComponent
    ],
    templateUrl: './acv-project-chantier-page.component.html',
    styleUrls: ['./acv-project-chantier-page.component.scss'],
    
})
export class AcvProjectChantierPageComponent implements OnInit {
  @Input() dpgfId;
  @Input() ceProjectId;
  @Input() projectLicence:String="none";
  @Input() isProjectDemo:Boolean=false;
  @Input() acvData;
  @Output() sendToParent: EventEmitter<any> = new EventEmitter<any>();
  private subscriptions: Array<Subscription> = [];
  public currentView: string = 'batiment_donnees_de_chantier';
  public selectedOption: string = 'batiment';
  /// Forms
  public selectedNatureGeotechnique;

  public prevState: any = {};
  public estimerConsomationChantier: any = {
    estiNombreMoisEteAvecGrue: 0,
    estinombreMoisHiverAvecGrue: 0,
    estidistanceZoneImportChantier: 0,
    estiquantiteMatiereImportee: 0,
    estiquantiteTerresEvacuees: 0,
    estivolumeTerresExcaveesLorsChantier: 0,
    // Zone is 1
    estiquantiteMatiereImportee2: 0,
    estisurfacePlancherRDCBatiment: 0
  };
  public selectedZoneUsage: any = 1;
  public selectedSurfaceParcelle: any = 1;
  public firstBatimentName: String = '';

  public selectedbatiment: string;
  public selectedZone: string;
  public zoneIsSelected: boolean = false;

  // inies table variables
  public checkedAllFiles: boolean;
  public selectedFiles=[];
  public relatedFiles=[]
  public addedZoneCount = 1;
    /// Initial Zone d'import
  public initialParcellZone = {
    id: '1',
    title: 'Zone d’import 1',
    items: [
      {
        label: "Distance de la zone d’import sur le chantier",
        type: "number", placeholder: "0",
        modelVariable:"distanceZoneImportChantier",
        inputLabel: "km"
      },
      {
        label: "Quantité de matière importée pour comblement de cavités sous parcelle",
        type: "number", placeholder: "0",
        modelVariable:"quantiteMatiereImporteeComblementCavitesParcelle",
        inputLabel: "T"
      },
    ],
  };
  public parcellZoneInputs: any = [];

  public contributors=['Composants','Chantier']
  public lotsPCE = [
    {
      name: "1- VRD",
       lots: ["1.1 Réseaux (extérieurs, jusqu'au domaine public)",
        "1.2 Stockage",
        "1.3 Aires de stationnement extérieures"]
    },
    {
      name: "2- Fondation et infrastructure",
      lots: ["2.1 Fondations",
        "2.2 Murs et structures enterrées ( escalier de cave, parking, …)",
        "2.3 Parcs de stationnement en superstructure à l'exception des garages des maisons individuelles ou accolées",
      ]
    },
    {
      name: "3- Maçonnerie et superstructure",
      lots: ["3.1 Eléments horizontaux - planchers, dalles, balcons",
        "3.2 Eléments horizontaux - poutres",
        "3.3 Eléments verticaux - façades",
        "3.4 Eléments verticaux - refends",
        "3.5 Eléments verticaux - poteaux",
        "3.6 Escaliers et rampes",
        "3.7 Eléments d'isolation",
        "3.8 Maçonneries diverses",]
    },
    {
      name: "4- Couverture - Etanchéité - Charpente - Zinguerie",
      lots: ["4.1 Toitures terrasse",
        "4.2 Toitures en pente",
        "4.3 Eléments techniques de toiture",]
    },
    {
      name: "5- Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures",
      lots: ["5.1 Cloisons et portes intérieures",
        "5.2 Doublages mur, matériaux de protection, isolants et membranes",
        "5.3 Plafonds suspendus",
        "5.4 Planchers surélevés",
        "5.5 Menuiseries, metalleries et quincalleries",]
    },
    {
      name: "6- Façades et menuiseries extérieures",
      lots: ["6.1 Revêtement, isolation et doublage extérieur",
        "6.2 Portes, fenêtres, fermetures, protections solaires",
        "6.3 Habillages et ossatures",]
    },
    {
      name: "7- Revêtements des sols, murs et plafonds - Chape - Peintures - Produits de décoration",
      lots: ["7.1 Revêtement des sols",
        "7.2 Revêtement des murs et plafonds",
        "7.3 Eléments de décoration et revêtements des menuiseries",]
    },
    {
      name: "8- CVC",
      lots: ["8.1 Equipements de production (chaud/froid) [hors cogénération]",
        "8.2 Systèmes de cogénération",
        "8.3 Systèmes d'émission",
        "8.4 Traitement de l'air et éléments de désenfumage",
        "8.5 Réseaux et conduits",
        "8.6 Stockage",
        "8.7 Fluides frigorigènes",]
    },
    {
      name: "9- Installations sanitaires",
      lots: ["9.1 Eléments sanitaires et robinetterie",
        "9.2 Canalisations, résaux et systèmes de traitement",]
    },
    {
      name: "10- Réseaux d'énergie (courant fort)",
      lots: ["10.1 Réseaux éléctriques",
        "10.2 Ensemble de dispositif pour la sécurité",
        "10.3 Eclairage intérieur",
        "10.4 Eclairage extérieur",
        "10.5 Equipements spéciaux",
        "10.6 Installations techniques",]
    },
    {
      name: "11- Réseaux de communication (courant faible)",
      lots: ["11.1 Réseaux éléctriques et de communications",
        "11.2 Réseaux et systèmes de contrôle et régulation",
        "11.3 Installations techniques et équipements spéciaux",]
    },
    {
      name: "12- Appareils élévateurs",
      lots: ["12 Appareils élévateurs",]
    },
    {
      name: "13- Equipements de production locale d'éléctricité",
      lots: ["13 Equipements de production locale d'éléctricité"]
    }
  ]
  public lotsPCECopy = [
    {
      name: "1- VRD",
       lots: ["1.1 Réseaux (extérieurs, jusqu'au domaine public)",
        "1.2 Stockage",
        "1.3 Aires de stationnement extérieures"]
    },
    {
      name: "2- Fondation et infrastructure",
      lots: ["2.1 Fondations",
        "2.2 Murs et structures enterrées ( escalier de cave, parking, …)",
        "2.3 Parcs de stationnement en superstructure à l'exception des garages des maisons individuelles ou accolées",
      ]
    },
    {
      name: "3- Maçonnerie et superstructure",
      lots: ["3.1 Eléments horizontaux - planchers, dalles, balcons",
        "3.2 Eléments horizontaux - poutres",
        "3.3 Eléments verticaux - façades",
        "3.4 Eléments verticaux - refends",
        "3.5 Eléments verticaux - poteaux",
        "3.6 Escaliers et rampes",
        "3.7 Eléments d'isolation",
        "3.8 Maçonneries diverses",]
    },
    {
      name: "4- Couverture - Etanchéité - Charpente - Zinguerie",
      lots: ["4.1 Toitures terrasse",
        "4.2 Toitures en pente",
        "4.3 Eléments techniques de toiture",]
    },
    {
      name: "5- Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures",
      lots: ["5.1 Cloisons et portes intérieures",
        "5.2 Doublages mur, matériaux de protection, isolants et membranes",
        "5.3 Plafonds suspendus",
        "5.4 Planchers surélevés",
        "5.5 Menuiseries, metalleries et quincalleries",]
    },
    {
      name: "6- Façades et menuiseries extérieures",
      lots: ["6.1 Revêtement, isolation et doublage extérieur",
        "6.2 Portes, fenêtres, fermetures, protections solaires",
        "6.3 Habillages et ossatures",]
    },
    {
      name: "7- Revêtements des sols, murs et plafonds - Chape - Peintures - Produits de décoration",
      lots: ["7.1 Revêtement des sols",
        "7.2 Revêtement des murs et plafonds",
        "7.3 Eléments de décoration et revêtements des menuiseries",]
    },
    {
      name: "8- CVC",
      lots: ["8.1 Equipements de production (chaud/froid) [hors cogénération]",
        "8.2 Systèmes de cogénération",
        "8.3 Systèmes d'émission",
        "8.4 Traitement de l'air et éléments de désenfumage",
        "8.5 Réseaux et conduits",
        "8.6 Stockage",
        "8.7 Fluides frigorigènes",]
    },
    {
      name: "9- Installations sanitaires",
      lots: ["9.1 Eléments sanitaires et robinetterie",
        "9.2 Canalisations, résaux et systèmes de traitement",]
    },
    {
      name: "10- Réseaux d'énergie (courant fort)",
      lots: ["10.1 Réseaux éléctriques",
        "10.2 Ensemble de dispositif pour la sécurité",
        "10.3 Eclairage intérieur",
        "10.4 Eclairage extérieur",
        "10.5 Equipements spéciaux",
        "10.6 Installations techniques",]
    },
    {
      name: "11- Réseaux de communication (courant faible)",
      lots: ["11.1 Réseaux éléctriques et de communications",
        "11.2 Réseaux et systèmes de contrôle et régulation",
        "11.3 Installations techniques et équipements spéciaux",]
    },
    {
      name: "12- Appareils élévateurs",
      lots: ["12 Appareils élévateurs",]
    },
    {
      name: "13- Equipements de production locale d'éléctricité",
      lots: ["13 Equipements de production locale d'éléctricité"]
    }
  ]
  public entities=['Parcelle','Bâtiment','Zone']
  public entitiesBatiments=['Bâtiment']
  public loadingPosts: boolean;
  public frigoPostParent: any;
  public displaySide: boolean;
  public selectedLot: { lot: any; father: any; };
  public selectedEntity='Chantier'
  public frirogeneResult;
  public infoPost: any;
  public postInfoTab='resume';
  public selectedFriro;
  public addingPostInies: any;
  public nameChosenPost: any;
  public postName="";
  public selectedPost={"contributor": "", "lotPCE": ""};
  public xmlFileToUpload: File | null = null;
  public loadingDemand: boolean;
  public demandesSugg: any[];
  public currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};dpgf;
  public demandeData={id:"",terms:false,nom:"",prenom:"",metier:"",mail:"",telephone:"",entreprise:"",nature:"Creation",id_demandeur:parseInt(this.currentUser['idDemandeur']||''),quantity:1,dve:"",unity:"",poste:'',postName:this.postName,content:{
    creationArgument:"",
    creationIdentification:"",
    creationSource:"",
    creationUf:"",
    creationLabel:"",
    creationRank:{
      creationRankId:1,
      creationIsNewRank:false,
      creationRankName:"",
      creationRankParent:1
    }
  }}
  public iniesCategories=[{title:"Voirie / réseaux divers (y compris réseaux intérieurs) et aménagements extérieurs de la parcelle",icon:'voirie_et_reseaux_divers.png',id:1},
  {title:"Strucure / maçonnerie / gros oeuvre / charpente divers",icon:'charpente.png',id:2},
  {title:"Façades",icon:'facades.png',id:3},
  {title:"Couverture / étanchéité",icon:'couverture__etancheite.png',id:4},
  {title:"Menuiseries intérieures et extérieures / fermetures",icon:'menuiserie_exterieure.png',id:5},
  {title:"Isolation",icon:'maconnerie__grosuvre.png',id:6},
  {title:"Cloisonnement / plafonds-suspendus",icon:'cloisons__doublages__fauxplafonds.png',id:7},
  {title:"Revêtements des sols et murs / peintures / produits de décorations",icon:'revetement_de_sol.png',id:8},
  {title:"Produits de préparation et de mise en oeuvre",icon:'demolition__depose.png',id:9},
  {title:"Equipements sanitaires et salle d'eau",icon:'plomberie__sanitaire.png',id:446},
  {title:"Panneau sandwich agroalimentaire",icon:"Aglo-Panneau_sandwich_agroalimentaire.svg",id:860},
  {title:"Approche simplifiée",icon:"Aglo-Approche_simplifiee.svg",id:822},
  {title:"Appareillage d'installation pour les réseaux d'énergie électrique et de communication (≤ 63 Ampères)",icon:"Aglo-Appareillage_installation_reseaux.svg",id:480},
  {title:"Fils et câbles",icon:"Aglo-Fils_cables.svg",id:481},
  {title:"Sécurité des personnes et contrôle d'accès",icon:'signaletique.png',id:482},
  {title:"Sécurité du bâtiment",icon:'securite_incendie.png',id:483},
  {title:"Equipements de génie climatique",icon:"Aglo-Equipements_genie_climatique.svg",id:484},
  {title:"Production locale d'énergie",icon:"Aglo-Production_locale_energie.svg",id:485},
  {title:"Matériel d'éclairage",icon:"Aglo-Eclairage.svg",id:773},
  {title:"Approche simplifiée",icon:"Aglo-Approche_simplifiee.svg",id:823},
  {title:"Eaux",icon:'assainissement__recuperationtraitement_des_eaux.png',id:828},
  {title:"Traitement des déchets",icon:"Aglo-Traitement_dechets.svg",id:849},
  {title:"Transports",icon:"transport.svg",id:852},
  {title:"Energies",icon:"Aglo-Energie.svg",id:831},
  {title:"Autre",icon:'generalites.png',id:0},
  ]
  public unitsCodes=unitsCodes
  @ViewChild(AcvProjectComposantSidebarComponent) cctpEdition: AcvProjectComposantSidebarComponent;
  jsonData: any;
  detailModalFromSideBar: boolean;
  selectedFriroi: any;
  firstTimeBatiment=true
  loadingCalculs: boolean;

  constructor(
    private acvService: AcvService,
    private relatedFichesService :relatedFichesService,
    private dpgfPostesService :DpgfPostesService,
    private cd: ChangeDetectorRef,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    // this.getAllPosts('');
    let surfaceParcelle = parseFloat(_.get(this.acvData,'projectDescription.projet.surfaceParcelle', 1));
    this.selectedSurfaceParcelle = surfaceParcelle;

    let batimentNamesRSET = _.get(this.acvData,'projectRSET.batimentsNames',[]);
    this.fillProjectDescriptionOptions(batimentNamesRSET);

    /// Get Parcell Zones
    let parcellZones = _.get(this.acvData['projectChantier'], 'parcelle.zones', []);
    this.parcellZoneInputs.push(...parcellZones);
    this.addInitialZone(parcellZones);

    this.fillEstimationPopup();
    this.fillFormFields();
    this.fillInputs(this.acvData['projectChantier']);
    this.currentView = 'batiment_donnees_de_chantier'
    this.selectedZone = 'batiment_donnees_de_chantier';
    // if(!environment.onPremise)window.analytics.page('pageview_chiffrage');
    /// Initial Batiment Selected Usage
    this.getSelectedBatimentUsage();
  }
  private getSelectedBatimentUsage(){
    this.selectedZoneUsage = parseInt(_.get(this.acvData, `projectDescription.${this.firstBatimentName}.Typology`, 1));
  }
  // 1- Sidebar Options
  public projectDescriptionOptions: any = [];

  // 2- Sidebar NavList
  public sidebarLists: any = {
    'batiment': [
      {icon: '', title: 'Données de chantier', value: 'batiment_donnees_de_chantier'},
      {icon: '', title: 'Composants du chantier', value: 'batiment_composants_du_chantier'},
    ]
  };

  // 3- forms
  public forms: any = {
    'batiment_donnees_de_chantier': {
      mainTitle: 'Contributeur Chantier',
      mainDescription: `Le contributeur Chantier couvre les consommations d’énergie du chantier de construction, les consommations et rejets d’eau du chantier, l’évacuation et le traitement des déchets du terrassement non pris en compte dans la contribution « composants ».⁤`,
      mainButton: {
        title: 'Je préfère estimer la consommation sur le chantier',
        buttonId: 'openEstimerConsomationChantier',
      },
      items: [
        // Title
        {
          label: "", type: "section_title",
          rowTitle: "Energie", rowDescription: `Cette section reflète les consommations réelles d’énergie sur le chantier (base vie, grues et engins de chantier).`,
        },
        {
          label: "Electricité consommée sur le chantier",
          type: "number", placeholder: "0", modelVariable:"electriciteConsommeeChantier", inputLabel: "kWh"
        },
        {
          label: "Carburant consommé sur le chantier",
          type: "number", placeholder: "0", modelVariable:"carburantConsommeChantier", inputLabel: "L"
        },
        // Title
        {
          label: "", type: "section_title",
          rowTitle: "Eau", rowDescription: `Cette section reflète la consommation  et rejets d’eau sur le chantier.`,
        },
        {
          label: "Quantité d'eau potable consommée",
          type: "number", placeholder: "0", modelVariable:"quantitePotableConsommee", inputLabel: "m³"
        },
        {
          label: "Quantité d'eaux usées rejetées par le chantier",
          type: "number", placeholder: "0", modelVariable:"quantiteEauxUseesRejeteesChantier", inputLabel: "m³"
        },
        // Title
        {
          label: "", type: "section_title",
          rowTitle: "Terres", rowDescription: `Cette partie reflète la quantité de terres importées et évacuées.`,
        },
        // Title
        {
          label: "", type: "section_title",
          rowTitle: "Zone d'import", rowDescription: ``
        },
        {
          label: "Distance de la zone d'import au chantier",
          type: "number", placeholder: "0", modelVariable:"distanceZoneImportChantier", inputLabel: "Km"
        },
        {
          label: "Quantité de matière importée",
          type: "number", placeholder: "0", modelVariable:"quantiteMatiereImportee", inputLabel: "T"
        },
        /////////////////
        // Ajouter une zone d’import
        /////////////////
        {
          label: "", type: "section_title",
          rowTitle: "Traitement des terres exportées ", rowDescription: ``
        },
        {
          label: "Traitement des déchets inertes par enfouissement",
          labelIsBold: true,
          // lock: true,
          type: 'radio',
          modelVariable: "traitementDechetsInertesEnfouissement",
          optionName: 'traitementDechetsInertesEnfouissement',
          options: [
            {label: 'Oui', value: 'Oui'},
            {label: 'Non', value: 'Non'},
          ]
        },
        {
          label: "Distance de la zone d'export au chantier",
          type: "number", placeholder: "0", modelVariable:"distanceZoneExportChantier1", inputLabel: "Km"
        },
        {
          label: "Quantité de matière exportée",
          type: "number", placeholder: "0", modelVariable:"quantiteMatiereExportee1", inputLabel: "T"
        },
        {
          label: "Traitement des déchets dangereux par incinération ",
          labelIsBold: true,
          type: 'radio',
          modelVariable: "traitementDechetsDangereuxIncineration",
          optionName: 'traitementDéchetsDangereuxIncinération',
          options: [
            {label: 'Oui', value: 'Oui'},
            {label: 'Non', value: 'Non'},
          ]
        },
        {
          label: "Distance de la zone d'export au chantier",
          type: "number", placeholder: "0", modelVariable:"distanceZoneExportChantier2", inputLabel: "Km"
        },
        {
          label: "Quantité de matière exportée",
          type: "number", placeholder: "0", modelVariable:"quantiteMatiereExportee2", inputLabel: "T"
        },
        {
          label: "Traitement des déchets non dangereux par enfouissement",
          type: "radio",
          // modelVariable: "traitementDechetsNonDangereuxIncineration",
          modelVariable: "traitementDechetsNonDangereuxEnfouissement",
          optionName: 'traitementDechetsNonDangereuxEnfouissement',
          labelIsBold: true,
          options: [
            {label: 'Oui', value: 'Oui'},
            {label: 'Non', value: 'Non'},
          ]
        },
        {
          label: "Distance de la zone d'export au chantier",
          type: "number", placeholder: "0", modelVariable:"distanceZoneExportChantier3", inputLabel: "Km"
        },
        {
          label: "Quantité de matière exportée",
          type: "number", placeholder: "0", modelVariable:"quantiteMatiereExportee3", inputLabel: "T"
        },
        // {
        //   label: "",
        //   type: "number", placeholder: "0", modelVariable:"", inputLabel: "km"
        // }

      ]
    },
    'batiment_composants_du_chantier': {
      mainTitle: 'Votre consommation d’énergie sur le chantier',
      subTitle: `Composants du chantier`,
      headerSteps: [
        `Il s’agit de composants utilisés pour :`,
        `Réaliser des ouvrages provisoires nécessaires au chantier`,
        `Protéger certaines parties de l’ouvrage ou composants pendant le chantier`
      ],
      items: [
        {
          type: 'table_header',
          options: [
            {label: '', width: '10%'},
            {label: 'Désignation fiche INIES', width: '22.5%'},
            {label: 'Désignation du poste', width: '22.5%'},
            {label: 'Quantité', width: '22.5%'},
            {label: 'Unité', width: '22.5%'},
          ]
        },
        {
          type: 'table_row',
          options: [
            {type: 'checkbox', isInput: true, width: '10%'},
            {type: 'text', value: 'Réseau de chaleur ', width: '22.5%'},
            {type: 'text', value: 'Réseau de chaleur ', width: '22.5%'},
            {type: 'number', placeholder: '0', isInput: true, width: '22.5%'},
            {type: 'text', value: 'Unité', width: '22.5%'},
          ]
        },
        {
          type: 'table_row',
          options: [
            {type: 'checkbox', isInput: true, width: '10%'},
            {type: 'text', value: 'Tube raccordement réseau de chaleur  ', width: '22.5%'},
            {type: 'text', value: 'Tuyauterie ', width: '22.5%'},
            {type: 'number', placeholder: '1', isInput: true, width: '22.5%'},
            {type: 'text', value: 'm linéraire', width: '22.5%'},
          ]
        },
        {
          type: 'table_row',
          options: [
            {type: 'checkbox', isInput: true, width: '10%'},
            {type: 'text', value: 'Cuve de récupération des eaux de pluie', width: '22.5%'},
            {type: 'text', value: 'Cuve ', width: '22.5%'},
            {type: 'number', placeholder: '0', isInput: true, width: '22.5%'},
            {type: 'text', value: 'Unité', width: '22.5%'},
          ]
        },

      ]

    },
    'parcelle_donnees_generales': {
      mainTitle: 'Votre consommation d’énergie sur le chantier',
      mainDescription: `Les impacts relatifs au chantier affectés à la contribution parcelle concernent les comblements de cavité (préparation de la parcelle avant construction).
          Cet impact comprend donc un transport des matériaux de comblement et leur mise en œuvre.⁤`,
      items: this.parcellZoneInputs
    }
  }

  public optionsMapping = {
    'batiment': 'batiment_donnees_de_chantier',
    'parcelle': 'parcelle_donnees_generales',
  }
  private fillProjectDescriptionOptions(batiments=[]){
    /// Batiments
    for(let i = 0; i < batiments.length; i++){
      // set default values
      let trimmedBatiment = batiments[i].replace(/ /g, "-");
      this.setDefaultValues(trimmedBatiment);
      let batimentObject = { title: `${batiments[i]}`, value: `${batiments[i]}`, trimmedValue: trimmedBatiment, type: 'batiment' };
      this.projectDescriptionOptions.push(batimentObject);

      /// radio defaults to Non
      _.set(this.prevState, `${batimentObject['trimmedValue']}.traitementDechetsInertesEnfouissement`, 'Non');
      _.set(this.prevState, `${batimentObject['trimmedValue']}.traitementDechetsDangereuxIncineration`, 'Non');
      _.set(this.prevState, `${batimentObject['trimmedValue']}.traitementDechetsNonDangereuxEnfouissement`, 'Non');
    }
    /// set initial currentView
    if(batiments.length){
      this.currentView = 'batiment_donnees_de_chantier';
    }else{
      /// add default batiment
      let batiment = {
        title: `Bâtiment`,
        value: `Bâtiment`,
        trimmedValue: 'Bâtiment',
        type: 'batiment',
        zones:[
          {
            label: `Zone`, value: `Zone`, trimmedValue: 'Zone', type: 'zone'
          }
        ]
      };
      this.projectDescriptionOptions.push(batiment);

      /// radio defaults to Non
      _.set(this.prevState, `${batiment['trimmedValue']}.traitementDechetsInertesEnfouissement`, 'Non');
      _.set(this.prevState, `${batiment['trimmedValue']}.traitementDechetsDangereuxIncineration`, 'Non');
      _.set(this.prevState, `${batiment['trimmedValue']}.traitementDechetsNonDangereuxEnfouissement`, 'Non');
    }
    this.projectDescriptionOptions.push({ title: 'Parcelle', value: 'parcelle', type: 'parcelle', trimmedValue: 'parcelle' });

    this.firstBatimentName = this.projectDescriptionOptions[0]['trimmedValue'] || '';

    /// initial form
    this.selectedbatiment = this.projectDescriptionOptions[0];
    this.selectedOption = `${this.selectedbatiment['value']}`;
    this.currentView = 'batiment_donnees_de_chantier';
    this.selectedZone = 'batiment_donnees_de_chantier';
  }
  public getStateValue(value){
    return _.get(this.prevState, `${this.selectedbatiment['trimmedValue']}.${value}`, undefined);
  }
  public optionSelected(currentView, variable, value){
    // let batimentKey = `${this.selectedbatiment['trimmedValue']}.batiment_donnees_de_chantier.${variable}`;
    let batimentKey = `${this.selectedbatiment['trimmedValue']}.${variable}`;
    let zoneKey = `${this.selectedbatiment['trimmedValue']}.${this.selectedZone}.${variable}`;
    let key = !this.zoneIsSelected ? batimentKey : zoneKey;

    this.prevState[key] = value;
    let updateData = {
      [key]: value
    }
    this.sendDataToParent(updateData);
  }
  public radioSelected(currentView, variable, value){
    // let batimentKey = `${this.selectedbatiment['trimmedValue']}.batiment_donnees_de_chantier.${variable}`;
    let batimentKey = `${this.selectedbatiment['trimmedValue']}.${variable}`;
    let zoneKey = `${this.selectedbatiment['trimmedValue']}.${this.selectedZone}.${variable}`;
    // let key = !this.zoneIsSelected ? batimentKey : zoneKey;
    let key = `${this.selectedbatiment['trimmedValue']}.${variable}`;
    /// _.set
    this.prevState[key] = value;
    let updateData = {
      [key]: value
    }
    this.sendDataToParent(updateData);
  }
  public inputTyped(currentView, variable, value){
    // let batimentKey = `${this.selectedbatiment['trimmedValue']}.${variable}`;
    // let batimentKey = `${this.selectedbatiment['trimmedValue']}.batiment_donnees_de_chantier.${variable}`;
    let batimentKey = `${this.selectedbatiment['trimmedValue']}.${variable}`;
    let zoneKey = `${this.selectedbatiment['trimmedValue']}.${this.selectedZone}.${variable}`;
    let key = !this.zoneIsSelected ? batimentKey : zoneKey;

    this.prevState[key] = value;
    let updateData = {
      [key]: value
    }
    this.sendDataToParent(updateData);
  }
  public ProjectInputTyped(variable, value){
    let valueMap = {
      'DateLivraison': 'projectDescription.projet.DateLivraison',
      'DureeChantier': `projectDescription.${this.selectedbatiment['trimmedValue']}.DureeChantier`
    };

    let key = valueMap[variable];

    let updateData = {
      [key]: value
    }

    this.subscriptions.push(
      this.acvService.updateAcvProject(this.ceProjectId, updateData)
        .subscribe(
          data => {
            // console.log(valueMap[variable], value);
            /// Set Local Data
            _.set(this.acvData, valueMap[variable], value);
          },
          error => {
            console.log(error);
          }
        )
    );
  }
  public ZoneInputTyped(zoneId, modelVariable, value){
    let body = {
      variable: modelVariable,
      value
    }
    let parcelleZonesKey = 'projectChantier.parcelle';

    this.subscriptions.push(
      this.acvService.updateChantierZoneValue(this.ceProjectId, zoneId, body)
      .subscribe(
        data => {
          let updatedParcell = data['data']['projectChantier']['parcelle'];
          // console.log('data : ', data.newZone);
          _.set(this.acvData, parcelleZonesKey, updatedParcell);
        },
        error => {
          console.log('Error : ', error);
        },
      )
    );
  }

  private sendDataToParent(updateData){
    this.sendToParent.emit(updateData);
  }
  public estimationInputTyped(variable, value){
    let key = `${this.selectedbatiment['trimmedValue']}.${variable}`;
    this.prevState[key] = value;

    _.set(this.acvData['projectChantier'], `${this.firstBatimentName}.${variable}`, value)

    let updateData = {
      [key]: value
    }

    this.sendDataToParent(updateData);
  }
  private fillFormFields(){
    let chantierCalculationsAdded = _.get(this.acvData['projectChantier'], `${this.selectedbatiment['trimmedValue']}.chantierCalculationsAdded`, false);

    if(!chantierCalculationsAdded) return;

    let {
        estiquantiteMatiereImportee, estiquantiteMatiereImportee2, estisurfacePlancherRDCBatiment, estiquantiteTerresEvacuees,
        estiNombreMoisEteAvecGrue, estinombreMoisHiverAvecGrue, estinombreMoisEteSansGrue, nombreMoisHiverSansGrue,
        estivolumeTerresExcaveesLorsChantier
      } = this.estimerConsomationChantier;

      let nGrue = parseFloat(estiNombreMoisEteAvecGrue) + parseFloat(estinombreMoisHiverAvecGrue);
      let durreChantierValue = _.get(this.acvData['projectDescription'], `${this.selectedbatiment['trimmedValue']}.DureeChantier`, 0);
      let nsansGrue = parseFloat(durreChantierValue) - nGrue;
      let carburantConsommeChantier = this.selectedZoneUsage == 1 ? 0 : estivolumeTerresExcaveesLorsChantier;
      carburantConsommeChantier = parseFloat(carburantConsommeChantier);


      let electriciteConsommeeChantierCalc = 10400*estiNombreMoisEteAvecGrue + 19500*estinombreMoisHiverAvecGrue + 5200*estinombreMoisEteSansGrue + 10400*nombreMoisHiverSansGrue;
      let electriciteConsommeeChantier = this.selectedZoneUsage == 1 ? 0 : (electriciteConsommeeChantierCalc || 0);
      let quantitePotableConsommee = this.selectedZoneUsage == 1 ? 10 : (60*nGrue + 40*nsansGrue);
      let quantiteEauxUseesRejeteesChantier =  this.selectedZoneUsage == 1 ? 10 : (60*nGrue + 40*nsansGrue);
      let quantiteMatiereExportee1Value = this.selectedSurfaceParcelle < 500 ? (1.12 * estisurfacePlancherRDCBatiment *1.45) : 0 ;
      let quantiteMatiereExportee1 = this.selectedZoneUsage == 1 ? quantiteMatiereExportee1Value : estiquantiteTerresEvacuees;

      let quantiteMatiereExportee2 = this.selectedZoneUsage == 1 ? 0 : 0;
      let quantiteMatiereExportee3 = this.selectedZoneUsage == 1 ? 0 : 0;

      let distanceZoneExportChantier1 = this.selectedZoneUsage == 1 ? 30 : 30;
      let distanceZoneExportChantier2 = this.selectedZoneUsage == 1 ? 0 : 30;
      let distanceZoneExportChantier3 = this.selectedZoneUsage == 1 ? 0 : 30;
      let distanceZoneImportChantier = this.selectedZoneUsage == 1 ? 30 : 30;

      let quantiteMatiereImporteeValue = this.selectedZoneUsage == 1 ? estiquantiteMatiereImportee2 : estiquantiteMatiereImportee;
      // quantiteMatiereImportee
      let quantiteMatiereImporteeCalc = quantiteMatiereImporteeValue;

      // _.set(this.prevState, `${this.firstBatimentName}.carburantConsommeChantier`, carburantConsommeChantier);
      _.set(this.prevState, `${this.firstBatimentName}.carburantConsommeChantier`, estivolumeTerresExcaveesLorsChantier);
      _.set(this.prevState, `${this.firstBatimentName}.electriciteConsommeeChantier`, electriciteConsommeeChantier);
      _.set(this.prevState, `${this.firstBatimentName}.quantitePotableConsommee`, quantitePotableConsommee);
      _.set(this.prevState, `${this.firstBatimentName}.quantiteEauxUseesRejeteesChantier`, quantiteEauxUseesRejeteesChantier);
      _.set(this.prevState, `${this.firstBatimentName}.quantiteMatiereExportee1`, quantiteMatiereExportee1);
      _.set(this.prevState, `${this.firstBatimentName}.quantiteMatiereExportee2`, quantiteMatiereExportee2);
      _.set(this.prevState, `${this.firstBatimentName}.quantiteMatiereExportee3`, quantiteMatiereExportee3);
      _.set(this.prevState, `${this.firstBatimentName}.distanceZoneExportChantier1`, distanceZoneExportChantier1);
      _.set(this.prevState, `${this.firstBatimentName}.distanceZoneExportChantier2`, distanceZoneExportChantier2);
      _.set(this.prevState, `${this.firstBatimentName}.distanceZoneExportChantier3`, distanceZoneExportChantier3);
      _.set(this.prevState, `${this.firstBatimentName}.distanceZoneImportChantier`, 30);
      _.set(this.prevState, `${this.firstBatimentName}.quantiteMatiereImportee`, quantiteMatiereImporteeCalc);

      ///
      let dateLivraison = _.get(this.acvData['projectDescription'], 'projet.DateLivraison', '');
      let dureeChantier = _.get(this.acvData['projectDescription'], `${this.firstBatimentName}.DureeChantier`, '');

      _.set(this.estimerConsomationChantier, 'DateLivraison', dateLivraison);
      _.set(this.estimerConsomationChantier, 'DureeChantier', dureeChantier);

  }
  private fillInputs(obj){
    for(let key in obj){
      this.prevState[key] = {...this.prevState[key], ...obj[key]};
    }
  }
  private fillEstimationPopup(){
    /// reset to 0
    for(let key in this.estimerConsomationChantier){
      this.estimerConsomationChantier[key] = 0;
    }

    let obj = this.acvData['projectChantier'];
    let estimationData = _.get(obj, `${this.firstBatimentName}`, {});

    for(let key in estimationData){
      if(key.startsWith('esti')){
        this.estimerConsomationChantier[key] = estimationData[key];
      }
    }

    // console.log('fillEstimationPopup : ', this.estimerConsomationChantier);
  }

  private addInitialZone(zones){
    let initianZoneIndex = zones.find(zone => zone.id == '1');
    if(!initianZoneIndex){
      this.addNewZone(true);
    }
  }
  public addNewZone(initialZone=false){
    let lastZoneIndex = this.parcellZoneInputs.length+1;
    let zoneBody = {
      // id: '1',
      title: `Zone d’import ${lastZoneIndex}`,
      items: [
        {
          label: "Distance de la zone d’import sur le chantier",
          type: "number", placeholder: "0",
          modelVariable:"distanceZoneImportChantier",
          inputLabel: "km"
        },
        {
          label: "Quantité de matière importée pour comblement de cavités sous parcelle",
          type: "number", placeholder: "0",
          modelVariable:"quantiteMatiereImporteeComblementCavitesParcelle",
          inputLabel: "T"
        },
      ],
    };

    let newZoneOrInitial = initialZone ? this.initialParcellZone : zoneBody;

    this.subscriptions.push(
      this.acvService.addChantierZone(this.ceProjectId, newZoneOrInitial)
        .subscribe(
          data => {
            let prevZones=_.get(this.acvData,'projectChantier.parcelle.zones',[])
            prevZones.push(data.newZone)
            _.set(this.acvData,'projectChantier.parcelle.zones',prevZones)
            this.parcellZoneInputs.push(data.newZone);
          },
          error => {
            console.log('Error : ', error);
          },
        )
    );
  }
  public removeZone(zoneId){
    this.subscriptions.push(
      this.acvService.removeChantierZone(this.ceProjectId, zoneId)
        .subscribe(
          data => {
            this.parcellZoneInputs = this.parcellZoneInputs.filter(z => z.id != zoneId);
          },
          error => {
            console.log('Error : ', error);
          },
        )
    );
  }

// inies table methods
getAllPosts(entity){
  this.loadingPosts=true;
  this.relatedFichesService.listAll('',entity,'Chantier',this.ceProjectId).subscribe(
    (data)=>{
      this.relatedFiles=data;
      this.loadingPosts=false;
      this.cd.detectChanges()
    },
    (err)=>{
      this.loadingPosts=false;
      console.log(err);
    }
  )
}
isLotStartsWithNumber(lot){
  return lot.match(/^\d/);
}
checkAllFiles(){
  if(this.selectedFiles.length==this.relatedFiles.length){
    this.selectedFiles=[];
    this.relatedFiles.forEach((elem,index) => {
      this.relatedFiles[index].checked=false;
    });
  }
  else {
    this.selectedFiles=[];
    this.relatedFiles.forEach((elem,index) => {
      this.relatedFiles[index].checked=true;
      this.selectedFiles.push(elem._id)
      this.checkedAllFiles=true
    });
  }
  // console.log('all',this.checkedAllFiles,this.selectedFiles);
}
checkOneFile(fileId){
  let index=this.selectedFiles.indexOf(fileId);
  let index2=this.relatedFiles.findIndex((a)=>a._id==fileId);
  if(index==-1) {
    this.selectedFiles.push(fileId)
    this.relatedFiles[index2].checked=true;
  }
  else {
    this.selectedFiles.splice(index,1);
    this.relatedFiles[index2].checked=false;
    this.checkedAllFiles=false;
  }
  if(this.selectedFiles.length==this.relatedFiles.length) this.checkedAllFiles=true;
  // console.log('one',index,this.checkedAllFiles,this.selectedFiles);
}
getPostOpenModal(post){
  this.relatedFichesService.getIniesPosts([post.idFicheBase]).subscribe(
    (data)=>{
      this.openInfoModal(data[0])
    }
  )
}
openInfoModal(post){
  this.detailModalFromSideBar=false
  $('#post-info-modal').modal('show');
  // parse CategoryName
  post.categoryName=(post.categoryName||'').replace(/ *\([^)]*\) */g, "").replaceAll('|',' > ');
  let categoryArray=(post.categoryName||'').split('>');
  categoryArray=categoryArray.reverse()
  post.categoryName=categoryArray.join(">")
  post.categoryName=post.categoryName.substring(2);
  // parse name
  post.nom=this.parseName(post.nom)
  // parse documentPath
  post.documentPathArray=((post.documentPath||'').split(',')||[])
  // parse dateEdition
  let dateEdition=(new Date(post.dateEdition)||new Date());
  // dateEdition=dateEdition.split("/");
  // dateEdition[1]=(parseInt(dateEdition[1])+5).toString();
  // dateEdition.join("/")
  post.dateEdition=dateEdition.getMonth()+'/'+(dateEdition.getFullYear()+5)
  //parse indicateurs
  let indicateurs={};
  post.indicateurs.forEach(element => {
    let phases=(element['phases']||[]);
    let phase7=phases.find((phases)=>phases.idPhase==7)
    indicateurs[element['idIndicateur']]=phase7.quantite
  });
  let ind=[{
      name:"Impacts environnementaux",data:{
        "Réchauffement climatique (kg CO2 eq.)":(post.impact||{}).TotalCycleDeVie / post.quantiteUF||1,
        "Appauvrissement de la couche d'ozone (kg CFC-11 eq.)":indicateurs[20],
        "Acidification des sols et de l'eau (kg SO2 eq.)":indicateurs[21],
        "Eutrophisation (kg (PO4)3- eq.)":indicateurs[22],
        "Formation d'ozone photochimique (kg C2H4 eq.)":indicateurs[23],
        "Epuisement des ressources abiotiques - éléments (kg Sb eq.)":indicateurs[24],
        "Epuisement des ressources abiotiques - combustibles fossiles (MJ)":indicateurs[25],
        "Pollution de l'air (m³)":indicateurs[26],
        "Pollution de l'eau (m³)":indicateurs[27]
      }
    },{
      name:"Consommation des ressources",data:{
        "Utilisation de l'énergie primaire renouvelable, à l'exclusion des ressources d'énergie primaire ... (MJ)":indicateurs[28],
        "Utilisation des ressources d'énergie primaire renouvelables utilisées en tant que matières premières (MJ)":indicateurs[29],
        "Utilisation totale des ressources d'énergie primaire renouvelables ... (MJ)":indicateurs[30],
        "Utilisation de l'énergie primaire non renouvelable, à l'exclusion ... (MJ)":indicateurs[31],
        "Utilisation des ressources d'énergie primaire non renouvelables utilisées en tant que matières premières (MJ)":indicateurs[32],
        "Utilisation totale des ressources d'énergie primaire non renouvelables ... (MJ)":indicateurs[33],
        "Utilisation de matière secondaire (kg)":indicateurs[34],
        "Utilisation de combustibles secondaires renouvelables (MJ)":indicateurs[35],
        "Utilisation de combustibles secondaires non renouvelables (MJ)":indicateurs[36],
        "Utilisation nette d'eau douce (m³)":indicateurs[37],
      }
    },{
      name:"Déchets",data:{
      "Déchets dangereux éliminés (kg)":indicateurs[38],
      "Déchets non dangereux éliminés (kg)":indicateurs[39],
      "Déchets radioactifs éliminés (kg)":indicateurs[40],
      }
    },{
    name:"Flux sortants",data:{
      "Composants destinés à la réutilisation (kg)":indicateurs[41],
      "Matériaux destinés au recyclage (kg)":indicateurs[42],
      "Matériaux destinés à la récupération d'énergie (kg)":indicateurs[43],
      "Energie fournie à l'extérieur (MJ)":indicateurs[44],
    }}]
  post.ind=ind;
  // console.log(ind);
  this.infoPost=post;
  }
  parseName(name){
    return name.split('- ID')[0]
  }
  onSelectionChangeTab(tab){
    this.postInfoTab=tab;
  }
  updatePost(postData){
    this.relatedFichesService.update(postData).subscribe(
      (data)=>{
        // console.log(data);
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  filterLotsPCE(word){
    for (let i = 0; i < this.lotsPCECopy.length; i++) {
      this.lotsPCECopy[i].lots=this.lotsPCE[i].lots.filter(lot=>lot.toLowerCase().includes(word.toLowerCase()))
    }
  }
  setPostPCE(oneLot,i){
    this.relatedFiles[i].lotPCE=oneLot
    this.updatePost(this.relatedFiles[i])
  }
  deleteSelectedFiles(){
    let arr=this.relatedFiles.filter((a)=>a.checked==true)
    this.loadingPosts=true;
    this.relatedFichesService.deleteMany(arr).subscribe(
      (data)=>{
        this.relatedFiles=this.relatedFiles.filter((a)=>!a.checked);
        this.loadingPosts=false;
        this.selectedFiles=[]
        this.cd.detectChanges()
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  duplicateSelectedFiles(){
    let arr=this.relatedFiles.filter((a)=>a.checked==true)
    this.loadingPosts=true;
    this.relatedFichesService.duplicate(arr).subscribe(
      (data)=>{
        this.relatedFiles=this.relatedFiles.concat(data);
        this.loadingPosts=false;
        this.selectedFiles=[]
        this.cd.detectChanges();
        // this.store.dispatch(actions.setCctp(this.cctpEdit))
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  duplicateOneFile(file){
    this.loadingPosts=true;
    this.relatedFichesService.duplicate([file]).subscribe(
      (data)=>{
        this.relatedFiles.push(data[0]);
        this.loadingPosts=false;
        this.cd.detectChanges();
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  deleteOneFile(file){
    this.loadingPosts=true;
    this.relatedFichesService.deleteMany([file]).subscribe(
      (data)=>{
        this.relatedFiles=this.relatedFiles.filter((a)=>a._id!=file._id);
        this.loadingPosts=false;
        this.cd.detectChanges();
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  openFrirogeneModal(post){
    this.frigoPostParent=post;
    this.relatedFichesService.searchWord('',{isFrirogene:true},'true').subscribe(
      (data)=>{
        data.filter(post=>post.name)
        // show frigogene in same page
        this.frirogeneResult=data;
        $('#frirogene-modal').modal('show');
      }
    )
  }
  showFrigo(postFile){
    // return (["26720","26718","27571","27577"].includes(postFile.idFicheBase))||(!postFile.idPhase14 && (postFile.idNomenclature=='703' || postFile.idNomenclature=='946' || postFile.idNomenclature=='947' || postFile.idNomenclature=='948' || postFile.idNomenclature=='949' || postFile.idNomenclature=='950' || postFile.idNomenclature=='726'))
    return (["INIES_D[RE20210628_114606","INIES_D[RE20210628_114459","INIES_DTNP20211012_091507","INIES_DJEY20211012_091735"].includes(postFile.serialIdentifier))||(!postFile.phase14 && (postFile.idNomenclature=='703' || postFile.idNomenclature=='946' || postFile.idNomenclature=='947' || postFile.idNomenclature=='948' || postFile.idNomenclature=='949' || postFile.idNomenclature=='950' || postFile.idNomenclature=='726'))

  }
  updateFrigogeneData(quantity,postFile){
    let post=_.get(postFile,'frigogenePost',{})
    post.frigogeneQuantity=quantity.target.value
    this.updatePost(post);
  }
  openSide(){
    $("body").css("overflow","hidden");
    this.displaySide=!this.displaySide;
  }
  public closeSide(){
    this.displaySide=false;
    $("body").css("overflow","scroll");
  }
  setInfoPost(post){
    this.infoPost=post;
    this.postInfoTab='resume'
    this.detailModalFromSideBar=true
  }
  openConfirmationPost(post){
    this.cctpEdition.openConfirmationPost(post);
    $('#post-info-modal').modal('hide');
  }
  setaddingPostInies(file){
    this.addingPostInies=file.addingPost;
    this.nameChosenPost=file.name;
    if(!file.addingPost)$('#add-post-modal').modal('hide');
    else {
      this.relatedFiles.push(file)
      this.displaySide=false;
    }
  }
  openModalChoice(id){
    if(id=='Reemploi') this.demandePost(id)
    else $(id).modal('show');
  }
  demandePost(type){
    this.demandeData={id:"",terms:false,nom:"",prenom:"",metier:"",mail:"",telephone:"",entreprise:"",nature:"Creation",id_demandeur:parseInt(this.currentUser['idDemandeur']||''),quantity:1,dve:"",unity:"",poste:'',postName:this.postName,content:{
      creationArgument:"",
      creationIdentification:"",
      creationSource:"",
      creationUf:"",
      creationLabel:"",
      creationRank:{
        creationRankId:1,
        creationIsNewRank:false,
        creationRankName:"",
        creationRankParent:1
      }
    }}
    this.demandeData.nature=type
    $('#add-demand-modal').modal('show');
  }
  submitDemande(){
    this.loadingDemand=true;
    // this.demandeData.poste=this.postIdInies;
    this.demandeData.postName=this.postName;
    this.demandeData['contributor']="Chantier"
    this.relatedFichesService.sendDemande(this.demandeData).subscribe(
      (data)=>{
        let post={
          idBase : 0,
          idFicheBase : 25765,
          postName:this.postName,
          serialIdentifier : "INIES_ICOM20210120_120948",
          nom : this.demandeData.content.creationLabel,
          uniteUF : this.demandeData.unity,
          quantite : this.demandeData.quantity,
          quantity:this.demandeData.quantity,
          dve : this.demandeData.dve,
          typeDonnees : 8,
          contributor:"Chantier",
          entity:this.selectedbatiment['value'],
          // poste:this.postIdInies,
          // commentaire:this.demandeData.id
          commentaire:(data['data']||{}).idDemand || this.demandeData.id,
        }
        if(this.demandeData.nature=="Creation"){
          this.currentUser['idDemandeur']=data['idDemandeur'];
          localStorage.setItem('currentUser',JSON.stringify(this.currentUser))
          this.messageService.add({ key: 'toast', severity: 'success', summary: 'Demande envoyée', detail: 'Votre demande de création de DED a bien été transmise aux services INIES, un mail récapitulatif vous a été envoyé', life: 5000 });
        }
        this.cctpEdition.addFileToPost(post);
        // else this._notificationService.success("Mise à jour Demande","La demande a été mis à jour", {timeOut: 3000});
        this.loadingDemand=false;
        $('#add-demand-modal').modal('hide');
        this.displaySide=false;
        this.cd.detectChanges();
      },
      (err)=>{
        this.loadingDemand=false;
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Traiter Demande', detail: 'Echec', life: 5000 });
      }
    )
  }
  getDemendSugg(){
    this.relatedFichesService.getDemandSugg().subscribe(
      (data)=>{
        this.demandesSugg=data;
        // $('.autocomplete-items-demand').css({'visibility': 'visible'});
        this.cd.detectChanges()
        // console.log('data',data);
      }
    )
  }
  setChosenDemand(result){
    this.demandeData.id=result.idDemand;
    this.demandeData.dve=result.dve;
    this.demandeData.quantity=result.quantity;
    this.demandeData.unity=result.unit;
    this.demandeData.content.creationLabel=result.name;
    // $('.autocomplete-items-demand').css({'visibility': 'hidden'});
  }
  addPostIniesReemploi(){
    let post={
      idBase : 0,
      idFicheBase : 27546,
      postName:this.postName,
      serialIdentifier : "INIES_ICOM20211011_160700",
      nom : this.demandeData.content.creationLabel,
      uniteUF : this.demandeData.unity,
      quantity : this.demandeData.quantity,
      dve : this.demandeData.dve,
      typeDonnees : 8,
      contributor:"Chantier",
      entity:this.selectedbatiment['value'],
      // poste:this.postIdInies
    }
    this.cctpEdition.addFileToPost(post);
    $('#add-demand-modal').modal('hide');
    // this.displaySide=false
  }
  private RemoveJsonTextAttribute(value,parentElement){
    var keyNo = Object.keys(parentElement._parent).length;
    var keyName = Object.keys(parentElement._parent)[keyNo-1];
    parentElement._parent[keyName] = value;
  }
  importXml(event){
    this.addingPostInies=true;
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.readUploadedFileAsText(file).then((data)=>{
        let parsedXml = this.relatedFichesService.parseXmlStringToJSON(data.toString());

        this.relatedFichesService.checkXml({data:data.toString()}).subscribe(
          (data)=>{
            let file={
              idBase : parsedXml['EPDC']['ConfiguratorCode'],
              idFicheBase : parsedXml['EPDC']['EPDCId'],
              postName:this.postName,
              nom : parsedXml['EPDC']['Name'],
              uniteUF : this.unitsCodes[(parsedXml['EPDC']['UnitId']||1)],
              quantite : parsedXml['EPDC']['Quantity'],
              dve : parsedXml['EPDC']['TLD'],
              entity:this.selectedbatiment['value'],
              contributor:"Composant"
            }

            this.addingPostInies=false;
            this.cctpEdition.addFileToPost(file);
            $('#import-files-modal').modal('hide');
            this.displaySide=false;
          },
          error => {
            /// if the CSTB license is suspended console this message (For Louis)
            if(error.status == 500 && error.error.err.message.includes("Cannot read properties of undefined")){
              console.log("Louis it's CSTB GUY");
            }
          }
        )
      })
    }
  }
  readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  }
  addFileToPost(){
    this.cctpEdition.addFileToPost({entity:this.selectedbatiment['value']},true);
    $('#add-post-modal').hide();
  }
  expandDetail(event){
    let parent = $(event.target).closest('.single-detail');
    let clickedItem = parent.find('.detail-description');
    let openArrow = parent.find('.open-arrow');
    let closeArrow = parent.find('.close-arrow');

    $('.close-arrow').addClass('hidden-item');
    $('.open-arrow').removeClass('hidden-item');

    if(clickedItem.hasClass('expand')){
      clickedItem.removeClass('expand');
    }else{
      $('.detail-description').removeClass('expand');
      clickedItem.addClass('expand');
      openArrow.addClass('hidden-item');
      closeArrow.removeClass('hidden-item');
    }
  }
  selectFriro(i){
    this.selectedFriroi=i;
  }
  validateFriro(){
    this.selectedFriro=this.frirogeneResult[this.selectedFriroi];
    if(this.frigoPostParent.frigogenePost) this.selectedFriro._id=this.frigoPostParent.frigogenePost._id
    // this.selectedFriro.poste=this.postIdInies;
    this.selectedFriro.frigoPostParent=this.frigoPostParent;
    this.loadingPosts=true;
    let totalImpact=calculate(1,this.frigoPostParent,1,this.selectedFriro);
    this.selectedFriro.totalImpact=(totalImpact||{}).TotalCycleDeVie
    this.selectedFriro.ceProject=this.ceProjectId;
    if(this.frigoPostParent.frigogenePost){
      this.relatedFichesService.update(this.selectedFriro).subscribe(
        (data)=>{
          this.getAllPosts(this.selectedOption)
          $('#frirogene-modal').modal('hide');
          this.loadingPosts=false;
          // this.cd.detectChanges();
        },
        (err)=>{
          console.log(err);
        } 
      )
    }
    else{
      this.relatedFichesService.create(this.selectedFriro).subscribe(
        (data)=>{
          this.getAllPosts(this.selectedOption)
          $('#frirogene-modal').modal('hide');
          this.loadingPosts=false;
          // this.cd.detectChanges();
        },
        (err)=>{
          console.log(err);
        }
      )
    }
  }
  setContributeur(contributor){
    this.selectedPost['contributor']=contributor;
    this.updatePost(this.selectedPost)

  }
  public calculateZoneImport(zones){

    const zonesView = 'parcelle_donnees_generales-';

    // distancezoneimport1*quantiteImporteeZone1 + distancezoneimport2*quantiteImporteeZone2
    // Modal variable: projectChantier.calculation-parcelle_donnees_generales-parcelleZoneDistance1
    let parcelleZoneDistance1 = [];

    // If you have 3 zones it's the sum of 3 quantity fields
    // Modal variable: projectChantier.calculation-parcelle_donnees_generales-parcelleZoneQuantity
    let parcelleZonequantity = 0;

    // First Calculation
    for(let i = 0; i < zones.length; i++){
      let SingleZone = zones[i];
      let zoneItems = SingleZone['items'];

      let distanceZoneImport = zoneItems[0]['value'] || false;
      let quantiteImporteeZone = zoneItems[1]['value'] || false;

      if(distanceZoneImport && quantiteImporteeZone){
        let sum = parseFloat(distanceZoneImport) * parseFloat(quantiteImporteeZone);
        parcelleZoneDistance1.push(sum);
        parcelleZonequantity+=parseFloat(quantiteImporteeZone);
      }
    }

    //// First Calculation
    let caluculationsPost = 0;
    for(let i = 0; i < parcelleZoneDistance1.length; i++){
      caluculationsPost += parcelleZoneDistance1[i];
    }
    // let key = `calculation-${zonesView}parcelleZoneDistance1`;
    let key = `parcelle.calculation-parcelleZoneDistance`;
    // this.prevState[key] = caluculationsPost;
    let updateData = {
      [key]: caluculationsPost
    }
    // console.log('FIRST : ', updateData);
    this.sendDataToParent(updateData);

    // 2nd Calculation parcelleZonequantity
    // Modal variable: calculation-parcelle_donnees_generales-parcelleZonequantity
    // let parcelleZonequantityKey = `calculation-${zonesView}parcelleZonequantity`;
    let parcelleZonequantityKey = `parcelle.calculation-parcelleZonequantity`;
    // this.prevState[parcelleZonequantityKey] = parcelleZonequantity;
    let parcelleZonequantityUpdateData = {
      [parcelleZonequantityKey]: parcelleZonequantity
    }
    // console.log('SECOND : ', parcelleZonequantityUpdateData);
    this.sendDataToParent(parcelleZonequantityUpdateData);
  }
  public removeWhiteSpaces(string){
    return string.replaceAll(' ', '');
  }
  public openNestedList(currentOption, ulId){
    /// open Nested Ul
    $('.nested-items-ul').removeClass('open');
    $(`#${ulId}`).addClass('open');
    if(currentOption['type'] == 'parcelle'){
      this.currentView = 'parcelle_donnees_generales';
      this.zoneIsSelected = false;
    }else{
      this.currentView = 'batiment_donnees_de_chantier';
    }
    //
    this.selectedOption = currentOption.value;
    //
    this.selectedbatiment = currentOption;
    this.firstBatimentName = currentOption['trimmedValue'];
    this.firstTimeBatiment=true;

    this.fillEstimationPopup();
    // this.fillFormFields();
    this.getSelectedBatimentUsage();
  }
  public listItemSelected(event, item){
    if(item=='batiment_composants_du_chantier' && this.selectedbatiment['value'] && this.firstTimeBatiment){
      this.firstTimeBatiment=false
      this.getAllPosts(this.selectedbatiment['value']);
    }
    this.currentView = item;
    this.selectedZone = item;
    this.zoneIsSelected = true;

    this.selectedOption = `${this.selectedbatiment['value']}.${item}`;

    $('ul.nested-items-ul li').removeClass('selected');
    $(event.currentTarget).addClass('selected');
  }
  private setDefaultValues(batimentName){
    _.set(this.prevState, `${batimentName}.traitementDechetsInertesEnfouissement`, 'Non');
    _.set(this.prevState, `${batimentName}.traitementDechetsDangereuxIncineration`, 'Non');
  }
  public formButtonClicked(buttonId){
    // console.log('formButtonClicked : ', buttonId);
    if(buttonId == 'openEstimerConsomationChantier'){
      $('#estimer-consomation-chantier-modal').modal('show');
    }
  }

  private calculateElectriciteConsommee(mois_fin, duree, mois_avec_grue_ete, mois_avec_grue_hiver){
    let nb_mois_annee = 12;
    let mois_ete = [4,5,6,7,8,9];
    let mois_hiver = [10,11,0,1,2,3];
    // let mois_fin = 4.6; // #month of the object "Date de fin du chantier"
    // let duree = 28.8; // #DureeChantier

    // let mois_avec_grue_ete = 4; // # NombreMoisEteAvecGrue
    // let mois_avec_grue_hiver = 1; // # nombreMoisHiverAvecGrue

    let mois_debut = Math.round((12+mois_fin - duree%nb_mois_annee)%nb_mois_annee);
    mois_fin = Math.round(mois_fin);

    let nb_mois_ete = Math.round(6*(Math.floor(duree/nb_mois_annee)));
    let nb_mois_hiver = Math.round(6*(Math.floor(duree/nb_mois_annee)));

    let mois_fin_adapte = mois_fin;
    if(mois_debut > mois_fin){
      mois_fin_adapte = mois_fin + 12;
    }

    for(let i = mois_debut; i < mois_fin_adapte; i++){
      let value = i%12;
      if(mois_ete.includes(value)){
        nb_mois_ete += 1;
      }else{
        nb_mois_hiver += 1;
      }
    }

    let mois_sans_grue_ete = nb_mois_ete - mois_avec_grue_ete // #nombreMoisEteSansGrue
    let mois_sans_grue_hiver = nb_mois_hiver - mois_avec_grue_hiver // #nombreMoisHiverSansGrue

    let electricite_consommee = 10400*nb_mois_ete + 19500*nb_mois_hiver + 5200*mois_sans_grue_ete + 10400*mois_sans_grue_hiver;

    return electricite_consommee;
  }

  public addDonnesChantierCalculations(){
    this.loadingCalculs=true;
    let {
        estiquantiteMatiereImportee, estiquantiteMatiereImportee2, estisurfacePlancherRDCBatiment, estiquantiteTerresEvacuees,
        estiNombreMoisEteAvecGrue, estinombreMoisHiverAvecGrue, estinombreMoisEteSansGrue, nombreMoisHiverSansGrue,
        estivolumeTerresExcaveesLorsChantier,
        //
        dateLivraisonProjet, dureeChantier
      } = this.estimerConsomationChantier;

    let nGrue = parseFloat(estiNombreMoisEteAvecGrue) + parseFloat(estinombreMoisHiverAvecGrue);
    let durreChantierValue = _.get(this.acvData['projectDescription'], `${this.selectedbatiment['trimmedValue']}.DureeChantier`, 0);
    let nsansGrue = parseFloat(durreChantierValue) - nGrue;
    let carburantConsommeChantier = this.selectedZoneUsage == 1 ? 0 : estivolumeTerresExcaveesLorsChantier;
    carburantConsommeChantier = parseFloat(carburantConsommeChantier);

    /// @Params
    // mois_fin : #month of the object "Date de fin du chantier"
    // duree : #DureeChantier
    // mois_avec_grue_ete : # NombreMoisEteAvecGrue
    // mois_avec_grue_hiver : # nombreMoisHiverAvecGrue
    let dateLivraison = _.get(this.acvData['projectDescription'], `projet.DateLivraison`, '');
    let DureeChantier = _.get(this.acvData['projectDescription'], `${this.selectedbatiment['trimmedValue']}.DureeChantier`, 0);

    // if(!dateLivraison || !DureeChantier) return;

    let moisFin = dateLivraison.split('-')[1];

    let electriciteConsommeeChantierCalc = this.calculateElectriciteConsommee(moisFin, DureeChantier, estiNombreMoisEteAvecGrue, estinombreMoisHiverAvecGrue);

    let electriciteConsommeeChantier = this.selectedZoneUsage == 1 ? 0 : (electriciteConsommeeChantierCalc || 0);

    let quantitePotableConsommee = this.selectedZoneUsage == 1 ? 10 : (60*nGrue + 40*nsansGrue);
    let quantiteEauxUseesRejeteesChantier =  this.selectedZoneUsage == 1 ? 10 : (60*nGrue + 40*nsansGrue);
    let quantiteMatiereExportee1Value = this.selectedSurfaceParcelle < 500 ? (1.12 * estisurfacePlancherRDCBatiment *1.45) : 0 ;
    let quantiteMatiereExportee1 = this.selectedZoneUsage == 1 ? quantiteMatiereExportee1Value : estiquantiteTerresEvacuees;
    let quantiteMatiereExportee2 = this.selectedZoneUsage == 1 ? 0 : 0;
    let quantiteMatiereExportee3 = this.selectedZoneUsage == 1 ? 0 : 0;

    let distanceZoneExportChantier1 = this.selectedZoneUsage == 1 ? 30 : 30;
    let distanceZoneExportChantier2 = this.selectedZoneUsage == 1 ? 0 : 30;
    let distanceZoneExportChantier3 = this.selectedZoneUsage == 1 ? 0 : 30;
    let distanceZoneImportChantier = 30;

    let quantiteMatiereImporteeValue = this.selectedZoneUsage == 1 ? estiquantiteMatiereImportee2 : estiquantiteMatiereImportee;
    // quantiteMatiereImportee
    let quantiteMatiereImportee = quantiteMatiereImporteeValue;
    let body = {
      carburantConsommeChantier,
      electriciteConsommeeChantier,
      quantitePotableConsommee,
      quantiteEauxUseesRejeteesChantier,
      quantiteMatiereExportee1,
      quantiteMatiereExportee2,
      quantiteMatiereExportee3,
      distanceZoneExportChantier1,
      distanceZoneExportChantier2,
      distanceZoneExportChantier3,
      distanceZoneImportChantier,
      quantiteMatiereImportee,
      /// when submitting set those radios
      'chantierCalculationsAdded': true,
      'traitementDechetsInertesEnfouissement': 'Oui',
      'traitementDechetsDangereuxIncineration': 'Non',
      'traitementDechetsNonDangereuxEnfouissement': 'Non'
    }
    let key = `projectChantier.${this.selectedbatiment['trimmedValue']}`;
    /// Set Them On Local
    // _.set(this.prevState, key)
    let updateData = {
    }
    for(let bodyKey in body){
      let path = `${key}.${bodyKey}`;
      updateData[path] = body[bodyKey];
    }

    this.subscriptions.push(
      this.acvService.updateAcvProject(this.ceProjectId, updateData)
        .subscribe(
          data => {
            $('#estimer-consomation-chantier-modal').modal('hide');
            this.acvData['projectChantier'] = data.data['projectChantier'];
            this.fillInputs(this.acvData['projectChantier']);
            this.loadingCalculs=false;
          },
          error => {
            this.loadingCalculs=false;
            console.log(error);
          }
        )
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }
  newVersionCategory(){
    return [...this.iniesCategories]
  }
}
