import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { Router} from '@angular/router';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  standalone:true,
  selector: 'app-dpgf-result-tabs-navbar',
  imports:[
    DpgfModule,
    NgSharedModule
  ],
  templateUrl: './dpgf-result-tabs-navbar.component.html',
  styleUrls: ['./dpgf-result-tabs-navbar.component.scss']
})
export class DpgfResultTabsNavbarComponent implements OnInit {
  public currentRoutePath: string = '';

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getCurrentRoute();
  }

  public getCurrentRoute(){
    let currentUrl = this.router.url;
    let n = currentUrl.lastIndexOf("/");
    // remove Query if Exists
    currentUrl.includes('?') ?
    currentUrl = currentUrl.split('?')[0] : '';
    let mainRoute = currentUrl.substr(n+1, currentUrl.length - 1);
    this.currentRoutePath = mainRoute;
  }
  public navigateTo(destination){
    const currentUrl = this.router.url;
    let n = currentUrl.lastIndexOf("/");
    let mainRoute = currentUrl.substr(0, n);
    // console.log(mainRoute);
    let routeDestination = mainRoute.includes('result')
      ? `${mainRoute}/${destination}`
      : `${mainRoute}/result/${destination}`;

    this.router.navigate([routeDestination]);
  }

}
