<div class="modal fade" id="delete-user" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body padding42-bottom20">
          <div class="title-text">
           <h2> Suppression d'un utilisateur</h2>
        </div>
        <div>
            <p>Voullez-vous vraiment supprimer l'utilisateur <span class="bold-black">{{user?.lastName}} {{user?.firstName}}.</span></p>
        </div>
        <div class="modal-footer" >
          <div class="flex float-right">
          <button type="button" class="annuler-button" data-dismiss="modal">Annuler</button>
          <button type="button" class="send-button"  (click)="validationToRemoveUser()">Valider</button>
        </div>
       </div>
        </div>
      </div>
  </div>