<!-- Versions Modal -->
<div class="modal fade" id="versions-modal" >
  <div class="modal-dialog">
    <div class="modal-container">

      <div class="flex-middle indice-upgareded-notif">
        <span>Indice mis à jour</span>
      </div>

      <div class="head">
        <a class="close" data-dismiss="modal"><i class="fa fa-times" aria-hidden="true"></i></a>
      </div>

      <div class="row">

        <div class="modal-header">
          <h3>Indices et historique des modifications</h3>
        </div>


        <!-- Upper Section -->
        <div class="upper-section margin-top-10">
          <p class="ag-weight-700">Indices</p>

          <div class="flex">
              
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <p>
                  <span>Ind. {{dpgfCurrentVersion | pricingIndex}}</span>
                  <span class="indice-caret caret"></span>
                </p>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li class="dropdown-element" *ngFor="let version of versionsCount,let i = index" (click)='changeIndice(version)'>
                  Indice {{version | pricingIndex}}
                </li>
              </div>
            </div>

            <button id="increase-indice-button" class="margin-left-16" (click)="openConfirmIndiceModal()">
              <img src="/assets/increase_dce_arrow.svg" >
              Passer à l’indice suivant
            </button>

          </div>

        </div>
        <!-- END Upper Section -->


        <!-- History Section -->
        <div class="history-section">
          <p class="ag-weight-900">Historique des modifications</p>
          
          <div class="row col-md-12" *ngIf="loadingVersions" >
            <app-spinner></app-spinner>
          </div>
          <div class="history-table-container" *ngIf="!loadingVersions && dpgfCurrentVersion">
            <div class="header flex">
              <div class="width-25"><span>Indice</span></div>
              <div class="width-25"><span>Date</span></div>
              <div class="width-25"><span>Heure</span></div>
              <div class="width-25"></div>
            </div>
            
            <div class="body">
              <div class="single-row flex" *ngFor="let version of versions,let i = index">
                <div class="width-25"><span>{{version.currentVersion | pricingIndex}}</span></div>
                <div class="width-25"><span>{{version.createdAt | date: 'dd/MM/yyyy'}}</span></div>
                <div class="width-25"><span>{{version.createdAt | date: 'HH:mm'}}</span></div>
                <div class="width-25">
                  <i  class="fa fa-external-link pointer margin-right-20"(click)="goToMinorVersion(version._id)" aria-hidden="true" title='Voir dans un nouvel onglet' ></i>
                  <i  class="fa fa-undo pointer" aria-hidden="true" title='Restaurer' (click)="restorer(version._id)"></i>
                </div>
              </div>

            </div>

          </div>


        </div>
        <!-- END History Section -->

        <!-- Footer -->
        <div class="footer margin-top-25">
          <p-paginator *ngIf="!loadingNewIndices" #paginator [rows]="10" [totalRecords]="countHistory" (onPageChange)="getVersions($event)"></p-paginator>
        </div>
        

        <!-- <div class="row col-md-12" *ngIf="loadingVersions" >
          <app-spinner style="margin:20px"></app-spinner>
        </div>
        <div class="row col-md-12" style="padding-left: 0px;margin-top: 12px;">
          <p style="font-weight: 900;">Historique des modifications</p>
        </div>
        <table *ngIf="!loadingVersions && selectedIndice">
          <tr>
            <th>Indice</th>
            <th>Date</th>
            <th>Heure</th>
            <th style="width: 5%"></th>
            <th style="width: 9%"></th>
          </tr>
          <tr *ngFor="let version of filterVersions()">
            <td>{{version.currentVersion | pricingIndex}}</td>
            <td>{{version.createdAt | date: 'dd/MM/yyyy'}}</td>
            <td>{{version.createdAt | date: 'HH:mm'}}</td>
            <td ><i (click)="goToMinorVerson(version._id)" class="fa fa-external-link clickable" aria-hidden="true" title='Voir dans un nouvel onglet' ></i></td>
            <td ><i (click)="restorer(version._id)" class="fa fa-undo clickable" aria-hidden="true" title='Restaurer'></i></td>
          </tr>
        </table>
        <br> -->

      </div>
    </div>
  </div>
</div>
<!-- END Versions Modal -->