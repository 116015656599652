import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customFormatDate',
  standalone: true
})
export class CustomFormatDatePipe implements PipeTransform {

  transform(inputDate: Date | string): string {
    if (!inputDate) {
      return '';
    }

    let currentDate = new Date();
    let providedDate = new Date(inputDate);

    // Calculate the difference in milliseconds
    let timeDifference = currentDate.getTime() - providedDate.getTime();
    let secondsDifference = Math.floor(timeDifference / 1000);
    let minutesDifference = Math.floor(secondsDifference / 60);
    let hoursDifference = Math.floor(minutesDifference / 60);
    let daysDifference = Math.floor(hoursDifference / 24);

    if (secondsDifference < 60) {
      return `Il y a ${secondsDifference} ${secondsDifference === 1 ? 'seconde' : 'secondes'}`;
    } else if (minutesDifference < 60) {
      return `Il y a ${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'}`;
    } else if (hoursDifference < 24) {
      return `Il y a ${hoursDifference} ${hoursDifference === 1 ? 'heure' : 'heures'}`;
    } else {
      // Format the date as "DD/MM/YYYY"
      let formattedDate = `${this.paddingZero(providedDate.getDate())}/${this.paddingZero(providedDate.getMonth() + 1)}/${providedDate.getFullYear()}`;
      return formattedDate;
    }
  }
  private paddingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

}
