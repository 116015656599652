<div class="row">
  <div class="col-md-3 sticky-sommaire">
    <div class="row floating-card sommaire-container">
      <h1>Sommaire</h1>
      <div>
        <div *ngFor="let lot of lots,let loti = index" >
          <div (click)="scrollTo(lot.id)" class="sommaire-pill" [ngStyle]="{'background-color': lot.color}">
            <i *ngIf="(lot.icon||'') == ''" class="fa fa-arrows handle grab-it" aria-hidden="true"></i>
            <img *ngIf="(lot.icon||'') != ''" class="handle grab-it" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" alt="" height="15">
            <span class="lot-sommaire">{{lot.indexLot}} - {{lot.title | dpgfLotTitle}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-9">
    <div *ngFor="let lot of lots,let loti = index" class="row floating-card" [attr.id]="lot.id">
      <app-table-lot-facture 
      [lot]="lot"
      [loti]="loti"
      [lotIndex]="lot.indexLot||loti"
      [isFirst]="isFirst"
      [status]="status"
      [response]="response"
      [mode]="mode"
      ></app-table-lot-facture>
    </div>
  </div>
</div>
  

