import { Action } from '@ngrx/store';

export enum ChantierActionTypes {
    LoadFacture = '[Chantier] Load Facture',
    LoadFactureSuccess = '[Chantier] Load Facture Success',
    UpdateLotDevisAcompte = '[Chantier] Update Lot Devis Acompte',
    UpdatePosteDevisAcompte = '[Chantier] Update Poste Devis Acompte',
    AddPoste = '[Chantier] Add Poste',
    UpdatePostePrice = '[Chantier] Update Poste Price',
    DeletePoste = '[Chantier] Delete Poste',
    ToggleGlobalFacturation = '[Chantier] Toggle Global Facturation',
    AddComment = '[Chantier] Add Comment',
    DeleteComment = '[Chantier] Delete Comment',
    UpdatePosteDesignation = '[Chantier] Update Poste Designation',
    SaveUpdateFacture = '[Chantier] Save Update Facture',
    SaveUpdateFactureSuccess = '[Chantier] Save Update Facture Success',
    FinishFactureUpdate = '[Chantier] Finish Facture Update',
    AcceptFacture = '[Chantier] Accept Facture',
    RejectFacture = '[Chantier] Reject Facture',
    SendFactureSuccess = '[Chantier] Send Facture Success',
    ErrorFacture = '[Chantier] ErrorFacture'
}

export class LoadFacture implements Action {
    readonly type = ChantierActionTypes.LoadFacture;

    constructor(public payload) { }
}
export class LoadFactureSuccess implements Action {
    readonly type = ChantierActionTypes.LoadFactureSuccess;
    
    constructor(public payload) { }
}
export class UpdateLotDevisAcompte implements Action {
    readonly type = ChantierActionTypes.UpdateLotDevisAcompte;
    
    constructor(public payload) { }
}
export class UpdatePosteDevisAcompte implements Action {
    readonly type = ChantierActionTypes.UpdatePosteDevisAcompte;
    
    constructor(public payload) { }
}
export class AddPoste implements Action {
    readonly type = ChantierActionTypes.AddPoste;
    
    constructor(public payload) { }
}
export class UpdatePostePrice implements Action {
    readonly type = ChantierActionTypes.UpdatePostePrice;
    
    constructor(public payload) { }
}
export class DeletePoste implements Action {
    readonly type = ChantierActionTypes.DeletePoste;
    
    constructor(public payload) { }
}
export class ToggleGlobalFacturation implements Action {
    readonly type = ChantierActionTypes.ToggleGlobalFacturation;
    
    constructor(public payload) { }
}
export class AddComment implements Action {
    readonly type = ChantierActionTypes.AddComment;
    
    constructor(public payload) { }
}
export class DeleteComment implements Action {
    readonly type = ChantierActionTypes.DeleteComment;
    
    constructor(public payload) { }
}
export class UpdatePosteDesignation implements Action {
    readonly type = ChantierActionTypes.UpdatePosteDesignation;
    
    constructor(public payload) { }
}



export class FinishFactureUpdate implements Action {
    readonly type = ChantierActionTypes.FinishFactureUpdate;
    
    constructor(public payload) { }
}
export class AcceptFacture implements Action {
    readonly type = ChantierActionTypes.AcceptFacture;
    
    constructor(public payload) { }
}
export class RejectFacture implements Action {
    readonly type = ChantierActionTypes.RejectFacture;
    
    constructor(public payload) { }
}
export class SaveUpdateFacture implements Action {
    readonly type = ChantierActionTypes.SaveUpdateFacture;
}
export class SaveUpdateFactureSuccess implements Action {
    readonly type = ChantierActionTypes.SaveUpdateFactureSuccess;

    constructor(public payload) { }
}
export class SendFactureSuccess implements Action {
    readonly type = ChantierActionTypes.SendFactureSuccess;
    
    constructor(public payload) { }
}
export class ErrorFacture implements Action {
    readonly type = ChantierActionTypes.ErrorFacture;
    
    constructor(public payload) { }
}

export type ChantierActions = LoadFacture | LoadFactureSuccess 
                              | UpdateLotDevisAcompte | UpdatePosteDevisAcompte| AddPoste  
                              | UpdatePostePrice| DeletePoste | ToggleGlobalFacturation
                              | AddComment | DeleteComment | UpdatePosteDesignation
                              | FinishFactureUpdate | AcceptFacture | RejectFacture
                              | SaveUpdateFacture | SaveUpdateFactureSuccess | SendFactureSuccess
                              | ErrorFacture;