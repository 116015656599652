import { Component, OnInit, Input, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone: true,
    selector: 'app-booster-profile-forms-progress',
    imports:[
      NgSharedModule, 
    ],
    templateUrl: './booster-profile-forms-progress.component.html',
    styleUrls: ['./booster-profile-forms-progress.component.scss'],
})
export class BoosterProfileFormsProgressComponent implements OnInit {

  @Input() selectedStep: string = "information";

  public progressMenuItems:any = [
    {label: "Informations", value: "information", itemIndex: 0},
    {label: "Corps d’état", value: "corpsEtat", itemIndex: 1},
    {label: "Documents", value: "documents", itemIndex: 2},
    {label: "Précisions", value: "precision", itemIndex: 3},
    {label: "Recommandations", value: "recommandations", itemIndex: 4},
  ];
  public progressMenuItemsNumbers: any = {
    "information": 0,
    "corpsEtat": 1,
    "documents": 2,
    "precision": 3,
    "recommandations": 4,
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}
