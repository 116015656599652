import { Pipe, PipeTransform } from '@angular/core';
import { registerLocaleData , formatNumber} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);


@Pipe({
  standalone:true,
  name: 'formatPrice'
})

export class FormatPrice implements PipeTransform {
  transform(value: any): any {
    if(!value) return value;
    else {
      if(typeof(value)=="string") {
        value=value.replace(/,/g, '.')
        value=parseFloat(value)
      }
      let numString=(value||1).toString()
      let numDec = numString.split(".");
      let formatedString;
      if(numDec.length==1) formatedString=formatNumber(value,'fr','1.0-0')
      else formatedString=formatNumber(value,'fr','1.2-2')
      return formatedString
    }
  }
}