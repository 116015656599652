import { Pipe, PipeTransform, NO_ERRORS_SCHEMA } from '@angular/core';

@Pipe({
  standalone:true,
  name: 'remainingDays'
})
export class RemainingDaysPipe implements PipeTransform {
  transform(value: string) {
    let date = new Date(value);
    let remainingDays = Math.abs(date.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);
    
    return Math.round(remainingDays);
  }
}
