<div class="page-wrapper">
  
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12" *ngIf="!loading">

        <div class="project-info-wrapper">
          <h4 class="text-center">{{ projectInfo.title }}</h4>
          <h3 class="text-center">{{ projectInfo.project }}</h3>
          <!-- Rénovation - Appartement - 80 m2 -->
        </div>

        <div class="suggested-artisans-wrapper">
          <!-- Lot Row -->
          <div class="single-row margin-bottom-25" *ngFor="let section of suggestionsData">
            <div class="row-title">
              <h4 class="ag-text-down-river no-margin-bottom">{{ section.lotName }}</h4>
            </div>

            <div class="row-artisans flex">
              <!-- Single Artisan Card -->
              <div class="single-artisan-card"
              [ngClass]="section.artisans.length < 4 ? 'width-32 margin-right-1p' : 'width-23 margin-right-2p'"
              *ngFor="let artisan of section.artisans">
                <div class="images-container">
                  <div class="available-badge">
                    <span class="circle green-circle"></span>
                    <span class="txt ag-text-down-river">Disponible</span>
                  </div>
                  <img [src]="artisan?.logo?.cdnUrl || artisan?.logoPlaceholder || '/assets/archi-prospect.png' " alt="">

                  <div class="artisan-location">
                    <h3 class="artisan-name">{{ artisan.name }}</h3>
                    <p class="no-margin-bottom" *ngIf="artisan?.address">
                      <i class="fa fa-map-marker margin-right-8"></i>
                      <span>Localisé(e) à {{ artisan.address }}</span>
                    </p>
                  </div>
                </div>
                
                <!-- Agency Type  -->
                <div class="agency-type-container margin-bottom-10">
                  <!-- <h4>Artisan / Entreprise spécialisée</h4> -->
                  <h4>
                    <span *ngFor="let artisanType of artisan.type; let last = last;">
                      {{ typeList[artisanType] || artisanType }}<i *ngIf="!last">, </i> 
                    </span>
                  </h4>
                  <p class="flex space-between" *ngIf="artisan.creationYearAgency && artisan.ca">
                    <span>Depuis {{ artisan.creationYearAgency }}</span>
                    <span>{{ artisan.ca }}€ CA/2023</span>
                  </p>
                </div>
                <!-- END Agency Type  -->

                <!-- Rating -->
                <div class="rating-container margin-bottom-10" *ngIf="artisansRatings[artisan._id]">
                  <span class="rating-badge">
                    <i class="fa fa-star"></i>
                    {{ artisansRatings[artisan._id] }}
                  </span>
                </div>
                <!-- END Rating -->

                <!-- Message  -->
                <div class="message-container margin-bottom-10">
                  <p>{{ artisan.message }}</p>
                </div>
                <!-- END Message  -->

                <div class="noting-container">
                  <p class="note no-margin-bottom flex" [ngClass]="artisan.budget"> 
                    <span class="circle"></span>
                    <span class="txt">{{ budgetTexts[artisan.budget] }}</span>
                  </p>
                  <p class="date">
                    {{ section.updatedAt | date: 'dd/MM/yyyy' }}
                  </p>
                </div>

                <!-- Have documents container -->
                <div class="documents-container horizontal-items center-items margin-bottom-16" *ngIf="artisan.docsAvailable">
                  <img src="/assets/verified.png" height="15" class="margin-right-5">
                  <p class="no-margin-bottom">Documents de l’entreprise disponibles</p>
                </div>
                <!-- END Have documents container -->

                <!-- Artisan type de travaux -->
                <div class="type-de-travaux" *ngIf="artisan?.travaux?.length">
                  <p>TYPES DE TRAVAUX</p>
                  <div class="types-list">
                    <span class="single-type" *ngFor="let travType of artisan.travaux">{{ travauxList[travType] || travType }}</span>
                  </div>
                </div>
                <!-- END Artisan type de travaux -->

              </div>
              <!-- END Single Artisan Card -->
            </div>

          </div>
          <!-- END Lot Row -->

        </div>

      </div>
    </div>
  </div>

</div>
