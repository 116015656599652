<form [formGroup]="subscriptionForm" novalidate>
  <!-- <div *ngIf="!isProfile"> -->
    <div [ngStyle]="{'display': !isProfile ? 'block' : 'none'}">
    
    <label id="couverture-label" >Logo de l'organisation</label><br>
    <div *ngIf="((agency.logo||{}).cdnUrl|| '') != ''">
      <img *ngIf="!authService.currentEnvironment" src="{{agency.logo.cdnUrl}}" alt="" class="thumb-nail" >
      <img *ngIf="authService.currentEnvironment"[src]="'api/v1/static/'+agency.logo.cdnUrl" alt="" class="thumb-nail">      </div>
    <!-- <div class="logo-archi"></div> -->
    <p-fileUpload mode="basic" [chooseLabel]="(agency.logo||{}).cdnUrl?'Modifier l\'image':'Sélectionner une image'" name="demo1" url="/api/v1/upload-file" [auto]="true"
      (onUpload)="onUploadArchiLogo($event)"></p-fileUpload>

    <label *ngIf="disabled">Nom de l'organisation</label>
    <div class="input-group input-group-lg">
      <!-- <span class="input-group-addon" id="sizing-addon1" [@error]="error=='name'"><span [@error]="error=='name'" class="glyphicon secondary glyphicon-bullhorn" aria-hidden="true"></span></span> -->
      <input id="name"
             formControlName="name"
             type="text"
             class="form-control"
             placeholder="Nom de votre entreprise"
             aria-describedby="sizing-addon1">
    </div>
    <small *ngIf="formErrors.name" class="text-danger">
      {{ formErrors.name }}
    </small><br>
  
    <label *ngIf="disabled">Email de votre agence</label>
    <div class="input-group input-group-lg">
      <!-- <span [@error]="error=='emailAgency'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='emailAgency'" class="secondary" >@</span></span> -->
      <input id="emailAgency"
             formControlName="emailAgency"
             type="text"
             class="form-control"
             placeholder="Email"
             aria-describedby="sizing-addon1">
    </div>
    <small *ngIf="formErrors.emailAgency" class="text-danger">
      {{ formErrors.emailAgency }}
    </small><br>
  
  
    <label *ngIf="disabled">Adresse</label>
    <div class="input-group input-group-lg">
      <!-- <span [@error]="error=='address'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='address'" class="glyphicon secondary glyphicon-pushpin" aria-hidden="true"></span></span> -->
      <!-- <input id="address"
             formControlName="address"
             type="text"
             class="form-control"
             placeholder="Adresse"
             aria-describedby="sizing-addon1" #search> -->
        <app-google-autocomplete ></app-google-autocomplete>

    </div>
    <small *ngIf="formErrors.address" class="text-danger">
      {{ formErrors.address }}
    </small><br>
  </div>
  <!-- <div *ngIf="isProfile"> -->
    <div [ngStyle]="{'display': isProfile && !authService.currentEnvironment? 'block' : 'none'}">
    
    <p class="title">Organisation</p>
    <div style="margin-left: 24px;">
      <label id="couverture-label" >Logo de l'organisation</label>
      <div style="text-align: left;width: 13%;" *ngIf="((agency.logo||{}).cdnUrl|| '') != ''">
        <img *ngIf="!authService.currentEnvironment" src="{{agency.logo.cdnUrl}}" alt="" class="thumb-nail" >
        <img *ngIf="authService.currentEnvironment" [src]="'api/v1/static/'+agency.logo.cdnUrl" alt="" class="thumb-nail"> 
      </div>

      <!-- Add and Remove Logo -->
      <div class="horizontal-items margin-bottom-30">
        <div *ngIf="authService.currentEnvironment">
          <p-fileUpload mode="basic" chooseLabel="Sélectionner une image" name="demo1" url="/api/v1/upload-file?type=logo" [auto]="true"
          (onUpload)="onUploadAgencyLogo($event)"></p-fileUpload>
        </div>
        <!-- END Choose Image (onpremise) -->
  
  
        <!-- Choose Image (Not onpremise) -->
        <div *ngIf="!authService.currentEnvironment">
          <p class="img-p" *ngIf="((agency.logo||{}).cdnUrl|| '') == ''">Aucune image n’a été sélectionnée</p>
         
          <p-fileUpload mode="basic" [chooseLabel]="(agency.logo||{}).cdnUrl?'Modifier l\'image':'Sélectionner une image'" name="demo1" url="/api/v1/upload-file?type=logo" [auto]="true"
          (onUpload)="onUploadAgencyLogo($event)"></p-fileUpload>
        </div>
        
        <button class="ag-btn-danger-xs remove-logo-btn" *ngIf="((agency.logo||{}).cdnUrl|| '') != ''"
          (click)="openConfirmRemoveLogo()">
          <i class="fa fa-trash-o"></i>
          Retirer l'image
        </button>

      </div>


      <label *ngIf="disabled">Nom de l'organisation</label>
      <div class="input-group input-group-lg">
        <!-- <span class="input-group-addon" id="sizing-addon1" [@error]="error=='name'"><span [@error]="error=='name'" class="glyphicon secondary glyphicon-bullhorn" aria-hidden="true"></span></span> -->
        <input id="name"
              formControlName="name"
              type="text"
              class="input-data"
              placeholder="Nom de votre entreprise"
              aria-describedby="sizing-addon1">
      </div>
      <small *ngIf="formErrors.name" class="text-danger">
        {{ formErrors.name }}
      </small><br>
  
      <label *ngIf="disabled">Email de votre agence</label>
      <div class="input-group input-group-lg">
        <!-- <span [@error]="error=='emailAgency'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='emailAgency'" class="secondary" >@</span></span> -->
        <input id="emailAgency"
              formControlName="emailAgency"
              type="text"
              class="input-data"
              placeholder="Email"
              aria-describedby="sizing-addon1">
      </div>
      <small *ngIf="formErrors.emailAgency" class="text-danger">
        {{ formErrors.emailAgency }}
      </small><br>
  
  
      <label *ngIf="disabled">Adresse</label>
      <div class="input-group input-group-lg address-container input-data">
        <!-- <span [@error]="error=='address'" class="input-group-addon" id="sizing-addon1"><span [@error]="error=='address'" class="glyphicon secondary glyphicon-pushpin" aria-hidden="true"></span></span> -->
        <app-google-autocomplete [adresseValue]="agency.address" (adresseValueChange)="patchAdress($event)"></app-google-autocomplete>
      </div>
      <small *ngIf="formErrors.address" class="text-danger">
        {{ formErrors.address }}
      </small>
      <br>
    </div>
    
  </div>


</form>

<div id="uppy-files-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header">
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">

        <div id="DashboardContainer"></div>

      </div>
      <div class="modal-footer text-center">
        <button type="button" data-dismiss="modal" class="ag-btn-tertiary-sm margin-right-20">Ignorer</button>
        <button type="button" class="ag-btn-primary-sm submit-btn">Allez à l’erreur</button>
      </div>
    </div>
  </div>
</div>
<!-- Confirm Delete Logo Modal -->
<app-confirm-modal
  [modalId]="'confirm-remove-modal'"
  [headerTitle]="'Suppression du logo'"
  [bodyText]="'Vous êtes sur le point de supprimer votre logo, êtes-vous sûr(e) ?'"
  (confirm)="emitDeleteLogo()"
></app-confirm-modal>
<!-- END Confirm Delete Logo Modal -->
