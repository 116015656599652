import { Component, OnInit, AfterViewInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgSharedModule } from 'src/app/all/ngshared.module';

import { CeProjectInvitesService } from '../../../_services'
import { ArtisanService } from '../../../_services/index';
import { ErrorService } from '../../../_services/index';
import { ChantierService } from '../../../../app/_services/chantier.service';

import { HeaderComponent } from 'src/app/all/header/header.component';
import { ArtisanCguModalComponent } from 'src/app/all/artisan-cgu-modal/artisan-cgu-modal.component';
import { ArtisanProjectListComponent } from 'src/app/artisan/artisan-project-list/artisan-project-list.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';

declare var $: any;

@Component({
    selector: 'app-artisan-projects',
    imports:[
    HeaderComponent,
    ArtisanCguModalComponent,
    ArtisanProjectListComponent,
    SpinnerComponent,
    NgSharedModule
],
    templateUrl: './artisan-projects.component.html',
    styleUrls: ['./artisan-projects.component.scss'],
    standalone: true,
    
})
export class ArtisanProjectsComponent implements OnInit {
  private subscriptions: Array<Subscription> = [];
  public loading:Boolean = true;
  public loadingAdpot:Boolean = false;
  public invites:any = [];
  public artisan: any = {};
  public loadingArtisanInfo:Boolean = true;showProject = 'encours';projectSent:any = [];projectInvit:any = [];projectUpdated:any = [];projectSigned:any = [];stateFilter:any = [];
  public codeRecuperation:String = "";projectArchive;seletedStatus;searchProject:{}[];projectsFound='';
  public statusProjects;projectChantier;

  constructor(private ceProjectInvitesService:CeProjectInvitesService,
              private ErrorService: ErrorService,
              private artisanService: ArtisanService,
              private chantierService: ChantierService,
            ) {
              
             }

  ngOnInit() {
    this.getAllArtisanInvites();
    this.getArtisanProfile();
  }
  public getAllArtisanInvites(){
    this.subscriptions.push(
      this.ceProjectInvitesService.getArtisanInvite()
        .subscribe(
            data => {
                this.projectSigned = data.ceProject.filter(project => project.status=="signe" && !data.facture.includes(project._id));
                this.invites = data.ceProject.filter(project => project.status!=('artisan-refuser' || 'refuse'));
                this.invites.map(invite=>{
                  if(invite?.ceProject?.addressCity && invite?.ceProject?.addressCity != ""){
                    let adresse = invite?.ceProject['adresse']||'';
                    let addressCodePostal = invite?.ceProject['addressCodePostal']||'';
                    let addressCity = invite?.ceProject['addressCity']||'';
                    let addressCountry = invite?.ceProject['addressCountry']||'';
                    invite['ceProject']['adresse'] = `${addressCodePostal} ${addressCity}, ${addressCountry}`;
                  }
                  if(data.facture.includes(invite._id)){
                   invite['statusChantier'] = 'chantier'
                  }else if(invite.pricingStatus=="pricing-sent" && invite.dpgfPricing.totalHt >0){
                   invite['statusInProgress'] = 'encours'
                }
                })
                this.stateFilter = Object.assign(this.invites)
                this.projectSent = data.ceProject.filter(project => project.pricingStatus=="pricing-done" && !data.facture.includes(project._id) && project.status!='signe');
                this.projectInvit = data.ceProject.filter(project => project.status=="artisan-invite");
                this.projectUpdated = data.ceProject.filter(project => project.status=='artisan-accepter' && project.pricingStatus!="pricing-done");
                this.projectChantier = data.ceProject.filter(signed => data.facture.includes(signed._id))
                this.projectArchive = data.ceProject.filter(project =>(project.status=='refuse' || project.status=='artisan-refuser'));
                this.seletedStatus = {name: 'Toutes',value:this.stateFilter}
                this.statusProjects=[
                  {name: 'Toutes',value:this.stateFilter},
                  {name: 'Demande de chiffrage',value:this.projectInvit},
                  {name: 'Chiffrage en cours',value:this.projectUpdated},
                  {name: 'Chiffrage envoyé',value:this.projectSent},
                  {name: 'Marché signé',value:this.projectSigned},
                  {name: 'En chantier',value:this.projectChantier?this.projectChantier: null }
                ];
                let hiddenProjectsList = ['artisan-refuser', 'refuse'];
                // this.invites = data.ceProject.filter(project => project.status!=('artisan-refuser' || 'refuse'));
                this.invites = data.ceProject.filter(project => (!hiddenProjectsList.includes(project.status) && project.pricingStatus != "no-pricing") );
                this.loading = false;
            },
            error => {
                console.log("error",error)
                this.loading = false;
                this.ErrorService.manageError(error,'/client/nouveau-projet')
            })
          );
  }
  public adoptCeInvite(){
    if((this.codeRecuperation||"" )!= ""){
      this.loadingAdpot = true;
      this.subscriptions.push(
        this.ceProjectInvitesService.adoptCeInvite(this.codeRecuperation)
        .subscribe(
          data => {
            this.codeRecuperation = "";
            this.getAllArtisanInvites()
            this.loadingAdpot = false;
            $("#addProjectPopup").modal('show');
          },
          error => {
            this.loadingAdpot = false;
            this.ErrorService.manageError(error,'/client/nouveau-projet')
          })
        );
    }
  }
  public toogleProject (status){
    this.showProject = status;
  }
  public getArtisanProfile(){
    this.loadingArtisanInfo = true;
    let currentUser = JSON.parse(localStorage.getItem("currentUser"))
    this.subscriptions.push(
      this.artisanService.getArtisanProfile(currentUser.artisanId)
        .subscribe(
          data => {
            this.loadingArtisanInfo = false;
            this.artisan = {
              name: data.name,
              assurance: data.assuranceFile,
              type: data.type, /// Vous êtes
            };
            // show CGU Modal
          },
          error => {
            this.loadingArtisanInfo = false;
            console.log("error",error)
          })
    );
  }
  public search(e,dropdown,allDropdown){
    this[dropdown] = [];
      for(let i = 0; i < allDropdown.length; i++) {
          let selectable = allDropdown[i];
          if(selectable.architect['name'].toLowerCase().indexOf(this.projectsFound.toLowerCase()) != -1 || selectable.ceProject['title'].toLowerCase().indexOf(this.projectsFound.toLowerCase()) != -1 ||selectable.ceProject['adresse'].toLowerCase().indexOf(this.projectsFound.toLowerCase()) != -1 ) {
            this[dropdown].push(selectable);
          }
      }
      this.searchProject = this[dropdown];
  }
  public viewStateProject(view){
    this.searchProject = view.value;
    this.invites = view.value;
    let e;
    if(this.projectsFound.length>0)
    this.search(e,[],this.searchProject)
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
