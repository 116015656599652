import { Component, OnInit, Input, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';


@Component({
    selector: 'app-rating-stars',
    imports:[
      NgSharedModule, 
    ],
    templateUrl: './rating-stars.component.html',
    styleUrls: ['./rating-stars.component.scss'],
    standalone: true,
})
export class RatingStarsComponent implements OnInit {

  @Input() rating: any = 1;

  public starsMapper: any = {
    // 1 star
    "1": `<i class="fa fa-star"></i>
    <i class="fa fa-star-o"></i>
    <i class="fa fa-star-o"></i>
    <i class="fa fa-star-o"></i>
    <i class="fa fa-star-o"></i>`,
    // 1.5 star
    "1.5": `<i class="fa fa-star"></i>
    <i class="fa fa-star-half-o"></i>
    <i class="fa fa-star-o"></i>
    <i class="fa fa-star-o"></i>
    <i class="fa fa-star-o"></i>`,
    // 2 star
    "2": `<i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star-o"></i>
    <i class="fa fa-star-o"></i>
    <i class="fa fa-star-o"></i>`,
    // 2.5 star
    "2.5": `<i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star-half-o"></i>
    <i class="fa fa-star-o"></i>
    <i class="fa fa-star-o"></i>`,
    // 3 star
    "3": `<i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star-o"></i>
    <i class="fa fa-star-o"></i>`,
    // 3.5 star
    "3.5": `<i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star-half-o"></i>
    <i class="fa fa-star-o"></i>`,
    // 4 star
    "4": `<i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star-o"></i>`,
    // 4.5 star
    "4.5": `<i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star-half-o"></i>`,
    // 5 star
    "5": `<i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star"></i>
    <i class="fa fa-star"></i>`,
  };

  constructor() { }

  ngOnInit(): void {
  }

}
