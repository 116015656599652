import * as Quill from 'quill';

const Link = Quill.import('formats/link')
// Override the existing property on the Quill global object and add custom protocols
const PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms']
var builtInFunc = Link.sanitize;

export default class CustomLinkSanitizer extends Link {
  static sanitize(url) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url)
    if(sanitizedUrl.startsWith('document')) {
      return builtInFunc.call(this, url);
    }
    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl

    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = PROTOCOL_WHITELIST.some(function(protocol) {
      return sanitizedUrl.startsWith(protocol)
    })

    if (hasWhitelistedProtocol) return sanitizedUrl

    // if not, then append only 'http' to not to be a relative URL
    return `http://${sanitizedUrl}`
  }
}
