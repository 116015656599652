import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone:true,
  name: 'dateRDV'
})
export class DateRDVPipe implements PipeTransform {
  public months = ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
  transform(value:string) {
      if (value) {
          let d = value.split("T")[0];
          let h = value.split("T")[1]||"";
          return "Le " + d + " à " + h
      }
      return value;
  }
}
