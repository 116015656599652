<div class="folder floating-card" *ngIf="downloadAllVar">
  <app-spinner></app-spinner>
  <h3 class="center">Téléchargement et création du dossier .zip </h3>
</div>
<div class="row" class="folder floating-card" *ngIf="!downloadAllVar">
  <div class="row">
    <div class="col-md-12">
      <h5>
        <span class="main-title">Documents du projet</span>
        <button type="button" name="button" class="btn-primary-border" style="margin-left: 5px !important;height: 26px;" (click)="addFolder()" *ngIf="mode == 'archi'&& !selectedFolder.noFolder">
          Ajouter un dossier
        </button>
        <button type="button" class="btn-primary-border dowload-div" data-toggle="modal" data-target="#download-zip">
          <i class="fa fa-download " aria-hidden="true" ></i>
          Télécharger tous les documents (.zip)
        </button>
      </h5>
    </div>
  </div>

  
  <br>
  <div class="margin-left-15 flex">
    <div class="margin-right-15 pointer" *ngIf="pathArray.length > 1" (click)="goToPath(pathArray.length -2)">
      <i class="fa fa-arrow-left gray-file" style="bottom: 2px;position: relative;"></i>
    </div>
    <span>Documents du &nbsp;</span> <span class="click-it" *ngFor="let path of pathArray,let i = index" (click)="goToPath(i)"> {{path.name}} / </span>
  </div>
  <br>
  <div *ngIf="((files$|async)||[]).length == 0 && pathArray.length!=1" class="center">
    <img src="/assets/inbox-50.png" alt="" style="display: block;margin: auto;">
    <h3> Ce dossier est vide </h3>
  </div>
  <div *ngIf="((files$|async)||[] | fileFolder :'folder':true) || pathArray.length==1" class="row padding-left-15">
      <span class="section-title-files">Dossiers</span>
      <div class="files-container">
        <div *ngIf="pathArray.length == 1" class="row" class="col-md-4">
          <div class="file-icon click-it" (click)="open({type : 'lot'},0)">
            <img src="/assets/dpgf/default-folder.svg">
            <span class="icon-text-span">
              Par lot
            </span>
          </div>
        </div>
        <div *ngFor="let file of (files$|async)||[] | fileFolder :'folder':false;let i = index"  class="col-md-4">
          <div *ngIf="file.type== 'folder' || file.title" class="file-icon">
            <div class="click-it" (click)="open(file,i)" style="width:80%;display: inline-block;" [pTooltip]="file.name || file.Key || file.title " tooltipPosition="top">
              <img src="/assets/dpgf/default-folder.svg" aria-hidden="true" *ngIf="file.type== 'folder' || file.title">
              <span class="icon-text-span">
                {{file.name || file.Key || file.title }}
              </span>
            </div>
            
            <div class="dropdown" style="float:right" *ngIf="pathArray.length && pathArray[1]?.path != 'data'">
              <button *ngIf="licence=='without' && projectLicence == 'none'" class="btn btn-default dropdown-toggle more-open" type="button" (click)='showPop()'>
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
              </button>
              <button *ngIf="licence!='without' || projectLicence == 'custom'" class="btn btn-default dropdown-toggle more-open" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
              </button>
              <ul class="dropdown-menu">
                <li class="dropdown-element click-it" (click)="downloadSingleLotFiles(file)">
                  <img src="/assets/archi/download.svg" class="download-icon"/> Télécharger
                </li>
                <li class="dropdown-element click-it" (click)="openRename(file,i)" *ngIf="mode == 'archi'">
                  <i class="fa fa-pencil"  aria-hidden="true" ></i> Renommer
                </li>
                <li class="dropdown-element click-it" *ngIf="!isSafari && mode == 'archi'" (click)="openDeletePopup(file,i)">
                  <i class="fa fa-trash-o"  aria-hidden="true" ></i> Supprimer
                </li>
                <li class="dropdown-element click-it" *ngIf="isSafari && mode == 'archi'" (click)="directDelete(file,i)">
                  <i class="fa fa-trash-o"  aria-hidden="true" ></i> Supprimer
                </li>
              </ul>
            </div>
            <div class="child-indicator" style="float:right" *ngIf="(file.children||[]).length != 0">
              {{(file.children||[]).length}}
            </div>
            <div class="child-indicator" style="float:right" *ngIf="(file.lotFiles||[]).length != 0">
              {{(file.lotFiles||[]).length}}
            </div>
          </div>
        </div>
      </div>
  </div>
  <div *ngIf="((files$|async)||[] | fileFolder :'files':true) || (pathArray.length == 1 && dpgfPricingId)" class="row padding-left-15">
    <span class="section-title-files">Fichiers</span>
    <div class="files-container">
      <div *ngFor="let file of (files$|async)||[] | fileFolder :'files':false;let i = index"  class="col-md-6 ">
        <div class="file-icon">
          <div class="click-it" (click)="open(file,i)" style="width:80%;display: inline-block;" [pTooltip]="file.name || file.Key || file.title " tooltipPosition="top">
            <i class="fa fa-file-word-o one-icon blue-file" aria-hidden="true" *ngIf="file.extension == 'docx'"></i>
            <i class="fa fa-file-pdf-o one-icon red-file" aria-hidden="true" *ngIf="file.extension == 'pdf'"></i>
            <i class="fa fa-file-excel-o one-icon green-file" aria-hidden="true" *ngIf="file.extension == 'xlsx'"></i>
            <i class="fa fa-file-image-o one-icon cadetblue-file" aria-hidden="true" *ngIf="imageExtension.indexOf(file.extension) != -1"></i>
            <img src="/assets/dpgf/default-file.svg" aria-hidden="true" *ngIf="file.type != 'folder' && allExtension.indexOf(file.extension||'') == -1 && !file.title">
            <span class="icon-text-span">
              {{file.name || file.Key || file.title }}
            </span>
          </div>
          <div class="dropdown" style="float:right" *ngIf="!uploading && mode == 'archi'&& !selectedFolder.noEdit">
            <button *ngIf="licence=='without' && projectLicence == 'none'" class="btn btn-default dropdown-toggle more-open" type="button" (click)='showPop()'>
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <button *ngIf="licence!='without' || projectLicence == 'custom'" class="btn btn-default dropdown-toggle more-open" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
              <li class="dropdown-element click-it" (click)="openRename(file,i,'files')">
                <i class="fa fa-pencil"  aria-hidden="true" ></i> Renommer
              </li>
              <li class="dropdown-element click-it" *ngIf="!isSafari" (click)="openDeletePopup(file,i,'files')">
                <i class="fa fa-trash-o"  aria-hidden="true" ></i> Supprimer
              </li>
              <li class="dropdown-element click-it" *ngIf="isSafari" (click)="directDelete(file,i,'files')">
                <i class="fa fa-trash-o"  aria-hidden="true" ></i> Supprimer
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div (click)="downloadDpgfPdfArtisanF()" *ngIf="pathArray.length == 1 && dpgfPricingId" class="col-md-6 ">
        <div class="file-icon click-it" *ngIf="!downloadingPdf">
          <i class="fa fa-file-pdf-o one-icon red-file" aria-hidden="true"></i>
          <span class="icon-text-span">DPGF</span>
        </div>
        <app-spinner *ngIf="downloadingPdf"></app-spinner>
      </div>
      <div (click)="downloadCctp()" *ngIf="pathArray.length == 1 && dpgfPricingId" class="col-md-6 ">
        <div class="file-icon click-it" *ngIf="!downloadingCCTP">
          <i class="fa fa-file-pdf-o one-icon red-file" aria-hidden="true"></i>
          <span class="icon-text-span">CCTP</span>
        </div>
        <app-spinner *ngIf="downloadingCCTP"></app-spinner>
      </div>
    </div>

  </div>
</div>
<div class="row" [class.hide]="mode != 'archi' || selectedFolder.noUpload || downloadAllVar || isSafari || (licence=='without' && projectLicence=='none') ">
    <!-- <div id="DashboardContainer"></div> -->
    <p-fileUpload name="demo2" chooseLabel="Sélectionnez des fichiers" url="/api/v1/upload-file" [auto]="true" (onUpload)="onUploadMultiFiles($event)" [multiple]="true" 
       maxFileSize="30000000">
    </p-fileUpload>
</div>
<div class="row floating-card" *ngIf="userLoggedIn && licence=='without' && projectLicence == 'none'">
  <p style="font-size: 20px;text-align: center;">Cette fonctionnalité n'est pas accessible en mode consultation. <a href="/architect/profil?tab=licence">Souscrivez à une licence Pro ou Intégral</a>  pour y avoir accès.</p>
</div>
<div *ngIf="isSafari" id="safari-upload">
  <!-- <div class="alert alert-warning" role="alert">
    Notre outil de téléchargement n'est pas compatible avec <strong>Safari</strong> ! pour une meilleur experience vous pouvez passer sur <strong>Chrome ou Firefox</strong>
  </div> -->
  <p-fileUpload name="myfile[]"
                *ngIf="!loadingFiles"
                multiple="multiple"
                customUpload="true"
                (uploadHandler)="fileEvent($event)"
                auto="true"
                chooseLabel="Ajouter un fichier à votre DCE."
                mode="basic"></p-fileUpload>
  <app-spinner *ngIf="loadingFiles"></app-spinner>
</div>

<div id="download-zip"
  class="modal fade upgrade-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-2 no-padding" style="margin-top: 25vh;width: 465px;">
    <div class="modal-container no-padding">
      <p class="upgrade-title">Fichier zip</p>
      <p class="upgrade-sub-title upgrade-sub-title-2">Le fichier que vous allez télécharger est un fichier zip.<br>
        Si vous n’avez pas de logiciel pour ouvrir ce type de fichier vous pouvez utiliser <a href="https://www.win-rar.com/start.html?&L=10">winrar</a>.</p>
      <p class="upgrade-sub-title upgrade-sub-title-2">Si le teléchargement est trop long, un email vous sera envoyé avec un lien de téléchargement.</p>  
      <button *ngIf="!downloadAllVar && dpgfPricingId" class="ag-btn-primary-sm b1" (click)="downloadAllChiffrage()">Télécharger</button>
      <button *ngIf="!downloadAllVar && !dpgfPricingId" class="ag-btn-primary-sm b1" (click)="downloadAllCheck()">Télécharger</button>
      <div *ngIf="downloadAllVar" style="float: right;margin-right: 52px;">
        <app-spinner></app-spinner>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Renommer le document" [(visible)]="renamePopUp" [style]="{width: '400px', overflow: 'hidden'}" >
  <label for="float-input">Nom du document</label>
  <input id="float-input" type="text" pInputText [(ngModel)]="renameData.name"/>
  <p-footer>
      <button type="button" pButton icon="pi pi-times" (click)="renamePopUp=false" label="Annuler" class="ui-button-secondary ag-btn-bordered-sm margin-right-15"></button>
      <button type="button" pButton icon="pi pi-check" (click)="rename()" label="Enregistrer" class="ag-btn-primary-sm"></button>
  </p-footer>
</p-dialog>
