<div class="all row">
  <app-header cssClass="fixed-bar" [quickLinks]="quickLinks"></app-header>
  <app-ce-project-nav
    [disable]="loadingData"
    [projectId]="projectId"
    selected="artisan"
    [devisNumber]="0"
    [DPGFId]="(project.dpgf||{})._id"></app-ce-project-nav>

  <div class="container loading-page" *ngIf="loadingData">
    <div style="margin-top:70px;">
      <app-spinner></app-spinner>
    </div>
  </div>
  
  <!-- Upper InfoBar -->
  <div class="upper-info-bar">
    <app-upper-info-bar 
      [projectTitle]="projectTitle"
      [dateDce]="dateDce"
      [consultedEnterprisesCount]="consultedEnterprisesCount"
      [showSendDCEIcon]="showSendDCEIcon"
      [showUpdateDCEIcon]="showUpdateDCEIcon"
      [showSendMailIcon]="showSendMailIcon"
      (tabChanged)="changeView($event)"
      (dateDceChanged)="setNewDate($event)"
      (openAddEnterpriseModal)="openAddEnterpriseModal()"
      (openCreateChiffrageModal)="openCreateChiffrageModal()"
      (openSendDceToMultipleArtisansModal)="openSendDceToMultipleArtisansModal()"
      (openUpdateDceForMultipleArtisansModal)="openUpdateDceForMultipleArtisansModal()"
      (openSendMailToMultipleArtisansModal)="openSendMailToMultipleArtisansModal()"      
      >
    </app-upper-info-bar>
  </div>
  <!-- END Upper InfoBar -->
    <div class="ce-container artisans-content-container">
      <ng-container *ngIf="!loadingData">
        <div class="text-center" *ngIf="!(project.dpgf||{})._id">
          <h2>Créer votre DCE</h2>
          <span class="span-btn">
            Vous devez commencer par créer votre DCE, puis vous pourrez le partager aux différentes entreprises
          </span>
          <a [routerLink]="['/architect/new-dpgf',projectId]">
            <button class="btn-primary-color create-dce-btn" type="button" name="button">
              C'est parti !
            </button>
          </a>
        </div>
      </ng-container>

    <ng-container *ngIf="!loadingData">

    <div class="row" *ngIf="(project.dpgf||{})._id">

      <div id="own" [ngClass]="{'hide': view == 'lots'}">
        <div class="row full-height">
          <app-enterprises-invite-columns
            [allArtisans]="allArtisans"
            [project]="project"
            [projectId]="projectId"
            [invites]="artisans"
            [dpgf]="dpgf"
            [collabs]="collabs"
            [canAdd]="canAdd"
            (openArtisanModal)="openArtisanModal($event)"
          ></app-enterprises-invite-columns>
        </div>

      </div>

      <div id="template" [ngClass]="{'hide': view == 'enterprises'}">
        <div class="col-md-12 lots-wrapper">
          <app-ce-project-artisan-lot
            [projectId]="projectId"
            [dpgfLot]="dpgfLot"
            [dpgf]="dpgf"
            [lots]="lots"
            (openAddArtisan)="setAddType($event,'own')"
            (openArchitectAgencyModal)="openArtisanModal($event)"
          ></app-ce-project-artisan-lot>

        </div>
      </div>

    </div>
    </ng-container>

  <div id="manage-dispos" tabindex="-1" class="modal" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="dispo-dialog">
        <h1>Disponibilités</h1>
        <app-rdv-calendar [(rdv)]="rdvDispo"></app-rdv-calendar>
        <button class="btn-secondary-color" (click)="setProjectData()" [disabled]="setLoading">
          <div *ngIf="!setLoading">Enregistrer mes disponibilités</div>
          <app-spinner *ngIf="setLoading"></app-spinner>
        </button>
      </div>
    </div>
  </div>

  <div id="add-artisan"
        tabindex="-1"
        class="modal"
        role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      
      <!-- [dpgfLot]="dpgfLot"  -->

      <app-choose-agency-modal
        [tagsList]="allTags" 
        [dpgfLot]="dpgfLot"
        [projectType]="project.typeConsultation" 
        [projectId]="projectId"
        [addType]="addType" 
        [artisans]="artisans"
        [currentRelationsPage]="currentRelationsPage"
        [relationsList]="relationsList"
        [choosenLot]="choosenLot"
        [sumittingRequest]="sumittingRequest"
        [resetValues]="resetValues"
        [relationsListNumberOfPages]="relationsListNumberOfPages"
        (closeModal)="closeModal()"
        (done)="getInvite();getRdvDispo()" 
        (sendDemandeChiffrage)="sendBoosterDemandeChiffrage($event)"
        (addAgency)="addAgency($event)"
        (addToCarnetAddress)="addToCarnetAddress($event)"
        (searchRelationsPage)="searchEnterprises($event)"
      ></app-choose-agency-modal>
      
      <!-- <app-ce-project-add-artisan
      [projectType]="project.typeConsultation" (done)="getInvite();getRdvDispo()" [projectId]="projectId"
      [dpgfLot]="dpgfLot" [addType]="addType" (closeModal)="closeModal()"
      [artisans]="artisans" [choosenLot]="choosenLot"
      ></app-ce-project-add-artisan> -->

    </div>
  </div>

  <!-- NEw Artisan Popup -->
  <!-- <div id="booster-my-consultation-modal"
    tabindex="-1"
    class="modal"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <app-booster-my-consultation-popup
        [dpgfLot]="dpgfLot"
        
        [projectId]="projectId"
        (done)="getInvite();getRdvDispo()"
        (closeModal)="closeBoosterModal()">
      </app-booster-my-consultation-popup>
    </div>
  </div> -->

  <!-- Need Subscriiption -->
  <app-need-subscription-popup-2></app-need-subscription-popup-2>

  <!-- Send DCE to multi arisans Popup -->
    <!-- [projectTitle]="projectTitle" -->
  <app-send-dce-to-multiple-popup
    [artisansList]="sendDceToMultiArtisans"
    [isDemo]="project?.demo"
    [sendDceType]="sendDceToMultiType"
    [dpgfCurrentVersion]="dpgf?.currentVersion"
    [dpgfLots]="dpgfLot"
    [loading]="manyDceLoading"
    [forwarderName]="forwarderName"
    [architectEmailTemplates]="architectEmailTemplates"
    (createMultiDce)="sendDceToManyArtisans($event)"
    (updateMultiDce)="updateDceForManyArtisans($event)"
  ></app-send-dce-to-multiple-popup>
  <!-- END Send DCE to multi arisans Popup -->

  <!-- Send Mail to multi arisans Popup; -->
  <app-send-mail-to-multiple-artisans
    [artisansList]="sendMailToMultiArtisans"
    [dpgfCurrentVersion]="dpgf?.currentVersion"
    [dpgfLots]="dpgfLot"
    [isDemo]="project?.demo"
    [loading]="manyDceLoading"
    [architectEmailTemplates]="architectEmailTemplates"
    (sendMailsToManyArtisans)="sendMailsToManyArtisans($event)"
  ></app-send-mail-to-multiple-artisans>
  <!-- END Send Mail to multi arisans Popup -->

  <app-new-architect-artisans-popup #artisanPopup *ngIf="openArchitectArtisanPopUP"
    [invitation]="popUpArtisan"
    [isProjectDemo]="project?.demo"
    [dpgf]="dpgf"
    [collabs]="collabs"
    [projectLicence]="project.licence"
    [numberOfPricingLots]="numberOfPricingLots"
    [openArchitectArtisanPopUP]="openArchitectArtisanPopUP"
    (refresh)="getInvite()" 
  ></app-new-architect-artisans-popup>

  <!-- Create Chiffrage Modal -->
  <app-create-artisan-factice *ngIf="artisansFiltered"
    [dpgf]="dpgf"
    [artisans]="allArtisans"
    [isProjectDemo]="project?.demo"
    (refresh)="getInvite()"
  ></app-create-artisan-factice>
  <!-- END Create Chiffrage Modal -->

</div>

</div>
