import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute , Params,} from '@angular/router';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { Subscription } from 'rxjs/Subscription';
// Services Imports
import { MessageService } from 'primeng/api';
import { CeProjectService, ErrorService, CeProjectInvitesService,AuthService } from '../../../_services/index';
import { ChantierService ,DpgfPostesService} from '../../../../app/_services/index';
// PrimeNG
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
//import { ArchitectModule } from '../architect.module';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { CeProjectNavComponent } from 'src/app/dpgf/global/ce-project-nav/ce-project-nav.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { FactureListComponent } from 'src/app/chantier/facture-list/facture-list.component';
import { FactureGraphicComponent } from 'src/app/chantier/facture-graphic/facture-graphic.component';
import { NeedSubscriptionPopup2Component } from 'src/app/architect/need-subscription-popup2/need-subscription-popup2.component';
import { environment } from 'src/environments/environment';

declare var $;

@Component({
    selector: 'app-archi-ce-project-payement',
    imports:[
      
      HeaderComponent,
      CeProjectNavComponent,
      SpinnerComponent,
      FactureListComponent,
      FactureGraphicComponent,
      NeedSubscriptionPopup2Component,
      NgSharedModule
    ],
    providers: [],
    templateUrl: './archi-ce-project-payement.component.html',
    styleUrls: ['./archi-ce-project-payement.component.scss'],
    standalone: true,

})
export class ArchiCeProjectPayementComponent implements OnInit {
  private subscriptions: Array<Subscription> = [];

  public inviteFactures:any = [];
  public project:any = {};
  public loadingData:boolean = false;
  public loadingFactures:boolean = false;
  public projectId:string = "";
  public invites:any = [];
  public activeInvite:any = {};
  public artisanSelected:boolean = false;
  public signing:boolean = false;
  public lotsToSign:any = [];
  public activeLot:any = {};
  public lotSelected:boolean = false;
  public view:boolean = false;
  public signedLots:any = [];dpgf;
  public isDemoProject:boolean = false;

  constructor(private ceProjectService: CeProjectService,
              private messageService: MessageService,
              private route: ActivatedRoute,
              private ErrorService: ErrorService,
              private authService: AuthService,
              private ceProjectInvitesService: CeProjectInvitesService,
              private chantierService: ChantierService,
              private confirmationService: ConfirmationService,
              private dpgfPostesService: DpgfPostesService,
  ) { }

  ngOnInit() {
    this.noMultiAccountsAllowed();
    this.getProjectAndAcceptedInvites();
    // segment page event
    if(!environment.onPremise)window.analytics.page('pageview_chantier');
  }

  public getProjectAndAcceptedInvites() {
    this.loadingData = true;
    this.route.params.forEach((params: Params) => {
      if (params['projectId']) {
        this.projectId = params['projectId'];
        this.subscriptions.push(
          this.ceProjectService.getProject(this.projectId)
            .subscribe(
              data => {
                this.project = data;
                if((data.dpgf||{})._id)this.getDpgf(data.dpgf._id);
                this.signedLots = data.signedLots;
                this.getAcceptedInvites(this.projectId);
              }
            )
        )
      }
    })
  }
  public getDpgf(dpgfId){
    this.dpgfPostesService.getDpgf(dpgfId)
    .subscribe(
        data => {
          this.dpgf = data;
          this.isDemoProject = data?.projectData?.demo || false;
        },
        error => {
            console.log("error",error)
            this.ErrorService.manageError(error,'/architect/ce-project/' + this.projectId);
        })
  }
  public getAcceptedInvites(projectId) {
    this.subscriptions.push(
      this.ceProjectInvitesService.getProjectAcceptedInvites(projectId)
        .subscribe(
          data => {
              this.invites = data;
              this.loadingData = false;
          },
          error => {
              console.log("error",error)
              this.ErrorService.manageError(error,'ce-project/' + projectId + '/ce-project-invites');
          })
    )
  }

  public selectLot(lot) {
    this.lotSelected = true;
    this.activeLot = lot;
  }

  public selectInvite(invite) {
    this.artisanSelected = true;
    this.activeInvite = invite;
    if(invite.status === 'signe') {
      this.loadFactures(invite);
    }
    else {
      this.loadLotsToSign(invite);
    }
  }

  public loadFactures(invite) {
    this.loadingFactures = true;
      let criteria = {
        type: 'archi'
      }
      this.subscriptions.push(
        this.chantierService.getFactures(invite._id, criteria)
          .subscribe(factures => {
            this.inviteFactures = factures;
            this.loadingFactures = false;
          })
      )
  }

  public loadLotsToSign(invite) {
    // Not yet signed lots
    /*this.lotsToSign = invite.dpgfPricing.data.filter(lot => {
      return !(this.signedLots.some(signlot => signlot.id === lot.id));
    });*/
    this.lotsToSign = invite.dpgfPricing.data;
    // Add invited flag to lots
    this.lotsToSign.map(lot => {
      // signed
      const signed = this.signedLots.some(signlot => signlot.id === lot.id);
      lot.alreadySigned = signed;
      // invited
      // const invited = invite.lots.some(invlot => invlot.id === lot.title);
      // console.log('invited',invited)
      // lot.invited = invited;
    })
  }
  unsignWithArtisan(){
    let idLotToUnsign = this.activeInvite.signedLots.map((lot)=>lot.id)
    this.subscriptions.push(
      this.ceProjectInvitesService.unsignWithArtisan(this.projectId,this.activeInvite._id,{lotToUnsign:idLotToUnsign})
        .subscribe(
          data => {
            if(!environment.onPremise)window.analytics.track('unsign_marche',{properties:{acValue: 'unsign_marche'}});
            this.signedLots = this.signedLots.filter((lot)=>!idLotToUnsign.includes(lot.id))
            this.getProjectAndAcceptedInvites();
            let index = data.findIndex((lot)=>lot._id==this.activeInvite._id)
            this.selectInvite(data[index]);
          },
          error => {
              console.log("error",error)
              // this.ErrorService.manageError(error,'ce-project/' + projectId + '/ce-project-invites');
          })
    )
  }
  public chooseLot(lot) {
    lot.signed = !lot.signed;
  }

  confirmSignature() {
    if(this.authService.licence == "without"){
      $('#upgrade-modal').modal('show');
      return;
    }
    this.confirmationService.confirm({
        message: 'Voulez-vous vraiment continuer ?',
        accept: () => {
            this.saveSignature();
        }
    });
  }
  confirmUnsign() {
    if(this.authService.licence == "without"){
      $('#upgrade-modal').modal('show');
      return;
    }
    this.confirmationService.confirm({
        message: 'Attention, vous vous apprêtez à annuler la signature avec cette entreprise, elle ne pourra plus accéder à la partie de chantier. Êtes-vous sûr ?',
        header: 'Annuler la signature',

        accept: () => {
            this.unsignWithArtisan();
        }
    });
  }
  public saveSignature() {
    this.signing = true;
    let signedLots = this.lotsToSign.filter(lot => lot.signed === true);
    let signingDate =new Date();
    if(signedLots.length === 0) {
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Signature échouée', detail: 'Sélectionnez au moins un lot dans la liste.', life: 5000 });
      this.signing = false;
      return;
    }
    this.addToProjectSignedLots(signedLots, this.activeInvite.artisan);
    /* Add signed lots to invite and change status to signe */
    let data = {
      status: 'signe',
      signedLots,
      signingDate
    }
    this.subscriptions.push(
      this.ceProjectInvitesService.setCeInvite(this.activeInvite._id, data)
        .subscribe(invite => {
          this.messageService.add({ key: 'toast', severity: 'success', summary: 'Signature', detail: 'Votre signature a été enregistrée.', life: 5000 });
          if(!environment.onPremise)window.analytics.track('sign_marche',{properties:{acValue: 'sign_marche'}});
          this.getProjectAndAcceptedInvites();
          this.selectInvite(invite);
          this.signing = false;
        })
    )
  }

  public addToProjectSignedLots(signedLots, artisan) {
    let logo = '';
    if(artisan.logo)
      logo = artisan.logo.cdnUrl;
    const storedSignedLots = signedLots.map(lot =>
      lot = {
        id: lot.id,
        title: lot.title,
        icon: lot.icon,
        total: lot.total,
        invited: lot.invited,
        artisan: {name: artisan.name, logo, telAgency: artisan.telAgency, emailAgency: artisan.emailAgency}
      })
    let ceProjectSignedLots = this.signedLots.concat(storedSignedLots);
    this.subscriptions.push(
      this.ceProjectService.update(this.activeInvite.ceProject._id, {signedLots: ceProjectSignedLots})
        .subscribe()
    )
  }
  private noMultiAccountsAllowed(){
    this.subscriptions.push(
      this.authService.noMultiAccountsAllowed()
        .subscribe(
          data => {
            
          }  
        )
    );
  }

  public ngOnDestroy(): void {
      this.subscriptions.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
      });
  }
}
