import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../_services';
//import { ArchitectModule } from '../architect.module';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

declare var $;

@Component({
    selector: 'app-validation-account-architect',
    imports:[
      
      SpinnerComponent,
      HeaderComponent,
      NgSharedModule
    ],
    templateUrl: './validation-account-architect.component.html',
    styleUrls: ['./validation-account-architect.component.scss'],
    standalone: true,

})
export class ValidationAccountArchitectComponent implements OnInit {
  private tokenValidation = "";
  public currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
  public loading;
  public accountValidated;emailLoading;emailSended;errorValidation;
  constructor(
    private activatedroute: ActivatedRoute,
    private authService:AuthService,
    private router:Router,
  ) { }

  ngOnInit(): void {
    this.activatedroute.queryParams.forEach(param=>{
      if(param['token'])this.tokenValidation=param['token'];
      // console.log('this',this.tokenValidation)
    })
    this.validateAccount(this.tokenValidation)
  }

  validateAccount(token){
    //api
    this.loading = true;
    $("#activation-account-modal").modal('show');
    this.authService.validateAccount(this.currentUser.id,token||null).subscribe((data)=>{
      this.accountValidated = true;
      this.currentUser.isNewAccount = false;
      localStorage.setItem('currentUser',JSON.stringify(this.currentUser))
      if(!environment.onPremise)window.analytics.track('account_verified',{properties:{acValue: 'account_verified'}});
      // change local storage to false isNewAccount to false
      setTimeout(() => {
        this.router.navigate(['architect/new-ce-project'])
        $("#activation-account-modal").modal('hide');
      }, 5000);
      this.loading=false;
    },
    err=>{
      if(err.error.err.message=='Already verified')this.router.navigate(['architect/my-consultations'])
      this.errorValidation = err.error.err.message;
      this.loading=false;
    }
    )
  }
  sendEmail(){
    this.emailLoading = true;
    this.authService.sendEmailValidationAccount(this.currentUser.id).subscribe((data)=>{
      this.emailLoading = false;
      this.emailSended = true;
      this.errorValidation = "";
    })
  }
// add guard
}
