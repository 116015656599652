import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { RSETpaths } from './RSEToptions';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { DpgfPostesService } from 'src/app/_services/index';
import { MessageService } from 'primeng/api';
import { AcvService } from 'src/app/_services';
// import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';
const _ = require('lodash');

@Component({
    standalone: true,

    selector: 'app-acv-main-navbar',
    imports: [
      NgSharedModule,
      // SpinnerComponent
    ],
    providers: [],
    templateUrl: './acv-main-navbar.component.html',
    styleUrls: ['./acv-main-navbar.component.scss'],
    
})
export class AcvMainNavbarComponent implements OnInit {

  @Output() tabSelected: EventEmitter<String> = new EventEmitter<String>();
  @Output() resultEvent: EventEmitter<String> = new EventEmitter<String>();
  @Output() updateAcv: EventEmitter<any> = new EventEmitter<any>();
  @Input() dpgfId;
  @Input() isProjectDemo:Boolean = false;
  @Input() ceProject;
  @Input() projectTitle;
  @Input() showWelcomeScreen;
  @Input() acvData;

  public importRSET: boolean;
  public result: any;
  public uploading;
  public loadingFiles: boolean;
  public loadingGeneration: boolean;
  public selectedTab;dpgf;
  constructor(private acvService: AcvService,
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private dpgfPostesService: DpgfPostesService
    ) { }

  ngOnInit(): void {
  }
  public selectTab(event,tab){
    let clickedItem = event.target;
    this.selectedTab=tab
    $('.horizontal-items li a').removeClass('selected-tab');
    $(clickedItem).addClass('selected-tab');
    this.tabSelected.emit(tab);
  }
  public fileEvent(event) {
    const AWSService = AWS;
    const region = 'eu-central-1';
    const bucketName = 'bam-consultation-slickshot';
    const IdentityPoolId = 'eu-central-1:7a0af26b-a2d9-4bd5-aa01-dff3f719b7db';
    let files = [...event.target.files] ||[];
    this.loadingFiles = true;
    this.loadingGeneration=true
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
      })
    });
    const s3 = new S3({
      apiVersion: '2006-03-01',
      params: { Bucket: bucketName}
    });
    const ID = function () {
      return Math.random().toString(36).substr(2, 9);
    };
    let filesUpload = files.map(file=>s3.upload({ Key: ID()+"-"+file.name , Bucket: bucketName, Body: file, ACL: 'public-read'}).promise());
    Promise.all(filesUpload).then((filesUploaded)=> {
      this.acvService.importRSET(this.ceProject,{RSETFile:{...filesUploaded[0],name:files[0].name}}).subscribe(
        (data)=>{
          // this.loadingFiles = false;
          if(!environment.onPremise)window.analytics.track('Import RSET',{properties:{acValue: 'Import RSET'}});
          this.updateAcv.emit(data['newAcv'])
          this.generateJsonComenv(true)
        }),
        (err)=>{
          this.loadingFiles = false;
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Generation Resultat', detail: `Error ${err}`, life: 5000 });
          console.log('err',err);
        }
    }).catch(err=>{
      console.log(err);
      this.loadingFiles = false;
    })
  }
  checkRSET(){
    return Object.keys(((this.acvData||{}).RSETFile||{})).length > 0
  }
  removeKeys(obj, keys) {
    var index;
    for (var prop in obj) {
      // important check that this is objects own property
      // not from prototype prop inherited
      if (Object.hasOwnProperty.bind(obj)(prop)) {
        switch (typeof (obj[prop])) {
          case 'string':
            index = keys.indexOf(prop);
            if (index > -1 && Object.entries(obj).length>1) {
              delete obj[prop];
            }
            break;
          case 'object':
            index = keys.indexOf(prop);
            if (index > -1 && Object.entries(obj).length>1) {
              delete obj[prop];
            } else {
              this.removeKeys(obj[prop], keys);
            }
            break;
        }
      }
    }
  }
  clickRSETinput(){
    $("#fileInput").trigger('click')
  }
  readUploadedFileAsText = (inputFile) => {
    let temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  }
  generateJsonComenv(hideNotif=false){
    this.loadingGeneration=true
    this.acvService.generateJsonComenv(this.ceProject).subscribe(
      (data)=>{
        this.loadingGeneration=false
        this.loadingFiles = false
        this.result=data.data
        this.resultEvent.emit(data.data)
        if(!hideNotif) this.messageService.add({ key: 'toast', severity: 'success', summary: 'Mise à jour des résultats', detail: 'Les résultats ont bien été mis à jour', life: 5000 });
        else this.messageService.add({ key: 'toast', severity: 'success', summary: 'Import RSET', detail: 'le RSET et la resultat ont été généré', life: 5000 });
        
      },
      (err)=>{
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: 'Mise à jour des résultats', life: 5000 });
        this.loadingFiles = false;
        this.loadingGeneration=false
      }
    )
  }
}
