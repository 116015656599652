import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, NO_ERRORS_SCHEMA } from '@angular/core';
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { AcvService } from 'src/app/_services/index'

const _ = require('lodash');

import {
  requiredFormsInputs,
} from './required-fields-form';
import { MessageService } from 'primeng/api';
import { FormInputsComponent } from '../form-inputs/form-inputs.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

declare var $;

@Component({
    standalone: true,

    selector: 'app-acv-results-brief-view',
    imports:[NgSharedModule,FormInputsComponent],
    providers: [MessageService],
    templateUrl: './acv-results-brief-view.component.html',
    styleUrls: ['./acv-results-brief-view.component.scss'],
   
})
export class AcvResultsBriefViewComponent implements OnInit {

  @Input() acvData;
  @Input() dpgfId;
  @Input() ceProject;
  @Input() projectTitle;
  @Output() showPostsEvent: EventEmitter<String> = new EventEmitter<String>();
  @Output() showComposantEvent: EventEmitter<any> = new EventEmitter<any>();

  public acvDataLoading: boolean = true;
  public formsInputs;
  public selectedFormInputs: string = 'Projet';
  public requiredFormInputs: any = {};
  public numberOfMissinsInputs: any = 0;
  public acvFilledFields: any = {
    "Projet": {
      "projectDescription.projet.pluviometrieAnnuelleSite": false,
      "projectDescription.projet.surfaceVegetalisee": false,

    },
  };

  public batiments: {};
  public selectedBatiment="";
  public numberPosts=5;
  public selectedAttestationType: string = 'xml';
  private subscriptions: Array<Subscription> = [];
  public batimentPropertyMaping={
    "Bbio":{uniteValue:"O_Bbio_pts_annuel",barValue1:"O_Bbio_Max",isVaild:true},
    "Cep":{uniteValue:"O_Cep_annuel",barValue1:"O_Cep_Max",isVaild:true},
    "Cep nr":{uniteValue:"O_Cep_nr_annuel",barValue1:"O_Cep_nr_Max",isVaild:true},
    "Icc":{uniteValue:"IcConstruction",barValue1:"IcConstructionMax",isVaild:true},
    "Ice":{uniteValue:"IcEnergie",barValue1:"IcEnergieMax",isVaild:true},
    "CO2DynamicTotalCDV":{uniteValue:"CO2DynamicTotalCDV",barValue1:"",isVaild:true},
    "DH":{uniteValue:"DH",barValue1:"DHMax",isVaild:true}
  }


  public etatCardList = [
    {
      labelIcon: '/assets/acv-icons/cross.svg',
      label: '<span>Le <b>RSET</b> n’a pas été importé</span>',
      actionLabel: 'Importer mon RSET',
      actionIcon: '/assets/acv-icons/download.svg',
      id:'importRSET',
      missingFieldsCount: 0,
      show:true
    },
    {
      labelIcon: '/assets/acv-icons/cross.svg',
      label: `Il manque <b>${this.numberOfMissinsInputs} champs</b> à remplir pour le calcul`,
      actionLabel: 'Voir les champs manquants',
      actionIcon: '/assets/acv-icons/search.svg',
      id:'missingFields',
      // missingFieldsCount: 0,
      missingFieldsCount: this.numberOfMissinsInputs,
      show:true
    },
    {
      labelIcon: '',
      label: 'Tous les lots ont été renseigné',
      actionLabel: 'Voir les lots non renseignés',
      actionIcon: '/assets/acv-icons/search.svg',
      id:'missingLots',
      missingFieldsCount: 0,
      show:false
    },
  ];
  public cardsList =  {'performanceCardsList':[
    {
      icon: '/assets/acv-icons/plant.svg', title: 'Bbio',id:'Bbio',
      description: 'Besoin bioclimatique',
      uniteValue: '0', unite: 'Points',
      dotColor: 'grey',
      barType: 'double', barValue1: '63'
    },
    {
      icon: '/assets/acv-icons/seat.svg', title: 'DH',id: 'DH',
      description: 'Degré-heure',
      uniteValue: '0', unite: 'DH',
      dotColor: 'grey',
      barType: 'double',
      barValue1: '350'
    },
    {
      icon: '/assets/acv-icons/sun.svg', title: 'Cep',id: 'Cep',
      description: 'Consommation d’énergie primaire',
      uniteValue: '0', unite: 'kWh/m2/an',
      dotColor: 'grey',
      barType: 'double', barValue1: '78'
    },
    {
      icon: '/assets/acv-icons/dislike.svg', title: 'Cep nr',id:'Cep nr',
      description: 'Consommation d’énergie primaire non renouvelable',
      uniteValue: '0', unite: 'kWh/m2/an',
      dotColor: 'grey',
      barType: 'double', barValue1: '57'
    }
  ],'performanceEnvironnementaleCardsList':[{
    icon: '/assets/acv-icons/construction.svg', title: 'Ic Construction',id:'Icc',
    description: '',
    uniteValue: '0', unite: 'kg éq. CO2/m2',
    dotColor: 'grey',
    barType: 'double', barValue1: '10'
  },
  {
    icon: '/assets/acv-icons/energy.svg', title: 'Ic énergie',id:'Ice',
    description: '',
    uniteValue: '0', unite: 'kg éq. CO2/m2',
    dotColor: 'grey',
    barType: 'double', barValue1: '10',isBar:true
  }],'impactBatiment':[
    {
      icon: '/assets/acv-icons/house-batiment.svg', id:1,
      uniteValue: '0', unite: 'kg éq. CO2',
    },
    {
      icon: '/assets/acv-icons/plane.svg', id:500,
      uniteValue: '0', unite: 'Paris - New York',
    }
    // ,
    // {
    //   icon: '/assets/acv-icons/meat.svg', id:27,
    //   uniteValue: '0', unite: 'Kilos de boeuf',
    // }
  ]};
  public cardListArray=[];
  public attestationSideBarList = {
    1:{
      title: 'Attestation pour le dépôt du permis de construire',
      description: `<p>Il est nécéssaire de joindre une attestation lors du dépôt de permis de construire concernant la validation de la <b>RE2020</b>.
      Cette attestation se base sur les résultats de l’étude thermique, il faut donc joindre le <b>RSET</b> pour la générer.</p>`,
      buttonLabel: 'Obtenir le RSEE'
    },
    2:{
      title: 'Télécharger le RS2E',
      description: `<p>Le RS2E est le document qui recense toutes les informations de votre projet dans le cadre de la RE2020. Il est utilisé notamment pour le dépot de permis de construire ainsi que pour le contrôle de conformité à la livraison du bâtiment.</p>`,
      buttonLabel: 'Obtenir le RSEE'
    },
    3:{
      title: 'Attestation de fin de travaux',
      description: `<p>Il est nécéssaire de joindre une attestation à la livraison du bâtiment afin de certfier de la conformité du projet concernant la réglementation.
      Cette attestation nécessite que tous les champs soient remplis pour être complète.</p>`,
      buttonLabel: 'Obtenir le RSEE'
    }
  };

  public ficheCards: Array<Object> = []
  public notValidValues=''
  public attestationCard=this.attestationSideBarList['2'];
  public loadingFiles: boolean;
  public zones={};
  public lotPCEArray=[
    "Lot 1- VRD",
    "Lot 2- Fondation et infrastructure",
    "Lot 3- Maçonnerie et superstructure",
    "Lot 4- Couverture - Etanchéité - Charpente - Zinguerie",
    "Lot 5- Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures",
    "Lot 6- Façades et menuiseries extérieures",
    "Lot 7- Revêtements des sols, murs et plafonds - Chape - Peintures - Produits de décoration",
    "Lot 8- CVC",
    "Lot 9- Installations sanitaires",
    "Lot 10- Réseaux d'énergie (courant fort)",
    "Lot 11- Réseaux de communication (courant faible)",
    "Lot 12- Appareils élévateurs",
    "Lot 13- Equipements de production locale d'éléctricité",
  ]
  public loadingDownload: boolean;
  public isInvalidProject=false;
  public permis;
  public batimentNamesRSET=[]
  public zoneNamesRSET=[]
  notValidBool: boolean;
  public zoneNamesWithFather:any;

  constructor(
    private acvService: AcvService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    // let zoneNamesWithFather=_.get(this.acvData,'projectRSET.zonesNamesWithFather',{});
    this.zoneNamesWithFather = _.get(this.acvData,'projectRSET.zonesNamesWithFather',{});
    for (const key in this.zoneNamesWithFather) {
      this.batimentNamesRSET.push(key)
      // this.zones[key]=Array.from(Array(13),(x,i)=>i+1)
      for (let index = 0; index < this.zoneNamesWithFather[key].length; index++) {
        // this.zoneNamesRSET=[...this.zoneNamesRSET,...(this.zoneNamesWithFather[key]||[])]
        this.zoneNamesRSET=_.union(this.zoneNamesRSET,this.zoneNamesWithFather[key]||[])
        this.zones[key+'/'+this.zoneNamesWithFather[key][index]]=Array.from(Array(13),(x,i)=>i+1)
      }
    }
    this.permis = parseInt(_.get(this.acvData,'projectDescription.projet.permisDeConstruire','0'));

    this.formsInputs = requiredFormsInputs(this.zoneNamesWithFather);
    this.fillAcvFilledFields(this.zoneNamesWithFather);
    this.checkIfFieldsAreFilled(this.acvData);

    this.selectedBatiment=(this.batimentNamesRSET||[])[0];
    this.parseLotPCEZones()
    this.parseACVResult()
    this.parsePosts();
    this.checkProjectValid()
  }
  public fillAcvFilledFieldsTitles = {};

  private fillAcvFilledFields(batiments){
    for(let key in batiments){
      let trimmedBatiment = key.replace(/ /g, "-");
      let batimentZones = batiments[key];
      this.fillAcvFilledFieldsTitles[trimmedBatiment] = key;

      this.acvFilledFields[trimmedBatiment] = {
        // [`projectDescription.${trimmedBatiment}.EmpriseAuSol`]: true,
        // [`projectDescription.${trimmedBatiment}.DureeChantier`]: true,
      }
      /// Zones
      for(let i = 0; i < batimentZones.length; i++){
        let trimmedZone = batimentZones[i].replace(/ /g, "-");

        this.fillAcvFilledFieldsTitles[trimmedZone] = batimentZones[i];

        this.acvFilledFields[trimmedBatiment][trimmedZone] = {
          // [`projectDescription.${trimmedBatiment}.${trimmedZone}.Usage`]: true,
          // [`projectDescription.${trimmedBatiment}.${trimmedZone}.NbLogements`]: true,
          [`projectDescription.${trimmedBatiment}.${trimmedZone}.NbOccupants`]: true,
        }
      }
    }
  }
  parseACVResult(){
    let batiments={}
    let batimentRSET=_.get(this.acvData,'projectRSET.Datas_Comp.batiment_collection.batiment',[])
    let batimentResult=_.get(this.acvData,'projectResult.batiments',[])
    let phase=_.get(this.acvData,'projectDescription.projet.Phase','')
    for (let i = 0; i < batimentRSET.length; i++) {
      let index=batimentResult.findIndex(bat=>bat.Name==batimentRSET[i]['Name'])
      batiments[batimentRSET[i]['Name']]={...batimentRSET[i],...batimentResult[index]}
    }
    this.selectedBatiment=((batimentRSET[0]||{})['Name']||'')
    this.batiments=batiments
    // set etat cards
    let index= this.etatCardList.findIndex(oneEtat=>oneEtat.id=='importRSET')
    if(this.checkRSET()){
      this.etatCardList[index]['label']='<span>Le <b>RSET</b> a été importé</span>'
      this.etatCardList[index]['labelIcon']=''
      this.etatCardList[index]['show']=false
    }
    // set permis card
    if(this.permis==0 && phase!="8") this.attestationCard=this.attestationSideBarList['1']
    else if(this.permis!=0 && phase!="8") this.attestationCard=this.attestationSideBarList['2']
    else if(this.permis==2 && phase=="8") this.attestationCard=this.attestationSideBarList['3']
  }
  parsePosts(){
    let posts=this.acvData.posts;
    if(this.numberPosts>(posts||[]).length)this.numberPosts=(posts||[]).length
    for (let i = 0; i < this.numberPosts; i++) {
      this.ficheCards.push({
        type: 'warning', title: (posts[i]||{}).nom, content: 'Changement climatique',
        icon: '/assets/acv-icons/leaf.svg', value: (posts[i]||{}).totalImpact,declarationType:(posts[i]||{}).declarationType,
        quantity:(posts[i]||{}).quantity
      })
    }
  }
  putBatimentValues(batimentSelected=''){
    // this.notValidValues='';
    if(batimentSelected)this.selectedBatiment=batimentSelected
    let batiment=this.batiments[this.selectedBatiment];
    for (const key in this.cardsList) {
      for (let i = 0; i < this.cardsList[key].length; i++) {
        if(this.batimentPropertyMaping[this.cardsList[key][i]['id']]){
          let path1=this.batimentPropertyMaping[this.cardsList[key][i]['id']]['uniteValue']
          let path2=this.batimentPropertyMaping[this.cardsList[key][i]['id']]['barValue1']
          this.cardsList[key][i]['uniteValue']=parseFloat(_.get(batiment,path1,0))
          this.cardsList[key][i]['barValue1']=parseFloat(_.get(batiment,path2,0))

          // this.cardsList[key][i]['uniteValue']=parseFloat(batiment[this.batimentPropertyMaping[this.cardsList[key][i]['id']]['uniteValue']] || '0')
          // this.cardsList[key][i]['barValue1']=parseFloat(batiment[this.batimentPropertyMaping[this.cardsList[key][i]['id']]['barValue1']] || '0')
          // && (this.cardsList[key][i]['id']=='Bbio' || this.cardsList[key][i]['id']=='DH')
          if(this.cardsList[key][i]['uniteValue']>this.cardsList[key][i]['barValue1']) this.isInvalidProject=true;
          if(this.cardsList[key][i]['uniteValue']>this.cardsList[key][i]['barValue1'] && (this.cardsList[key][i]['id']=='Bbio' || this.cardsList[key][i]['id']=='DH') && !this.notValidValues.includes(this.cardsList[key][i]['id'])){
            this.notValidBool=true;
            this.notValidValues+= (this.notValidValues?'et le ':'') +this.cardsList[key][i]['id']+ ' '
          }
        }
        else{
          this.cardsList[key][i]['uniteValue']=((batiment||{})['CO2DynamicImpacts']||{})[this.batimentPropertyMaping['CO2DynamicTotalCDV']['uniteValue']]/this.cardsList[key][i]['id']
        }
      }
    }
  }
  isProjectCompleted(){
    return !this.etatCardList[1].show && !this.etatCardList[2].show && this.checkRSET()
  }
  checkProjectValid(){
    for (let index = 0; index < this.batimentNamesRSET.length; index++) {
      if(this.batimentNamesRSET[index]!=this.selectedBatiment)this.putBatimentValues(this.batimentNamesRSET[index])
    }
    this.putBatimentValues(this.selectedBatiment);
  }
  parseToast(){
    // setting Toast
    for (const key1 in this.batiments) {
      for (const key in this.cardsList) {
        for (let i = 0; i < this.cardsList[key].length; i++) {
          if(this.batimentPropertyMaping[this.cardsList[key][i]['id']]){
            if(this.batiments[key1][this.batimentPropertyMaping[this.cardsList[key][i]['id']]['uniteValue']]  >  this.batiments[key1][this.batimentPropertyMaping[this.cardsList[key][i]['id']]['barValue1']]) {
              this.isInvalidProject=false
            }
          }
        }
      }
    }
  }
  parseLotPCEZones(){
    let zoneNamesWithFather=_.get(this.acvData,'projectRSET.zonesNamesWithFather',{});
    let posts=this.acvData.posts;
    for (let i = 0; i < (posts||[]).length; i++) {
      if(posts[i].contributor=='Composants'){
        let index=this.zoneNamesRSET.findIndex(zone=>zone==(posts[i]['entity']||''))
        let indexBatiment=this.batimentNamesRSET.findIndex(zone=>zone==(posts[i]['batiment']||''))
        let indexBatimentEntity=this.batimentNamesRSET.findIndex(zone=>zone==(posts[i]['entity']||''))
        if(index!=-1 && indexBatiment!=-1) {
          let lotPCE=parseInt((posts[i].lotPCE||'').split('.')[0])
          if(!Number.isNaN(lotPCE)) {
            this.zones[(posts[i]['batiment']||'')+'/'+posts[i]['entity']]=(this.zones[(posts[i]['batiment']||'')+'/'+posts[i]['entity']]||[]).filter(lotPCEV=>lotPCEV!=lotPCE)
          }
        }
        if(indexBatimentEntity!=-1){
          let lotPCE=parseInt((posts[i].lotPCE||'').split('.')[0])
          // console.log(indexBatimentEntity,posts[i].nom,lotPCE);
          let zonesOfBatiment=zoneNamesWithFather[posts[i]['entity']]
          if(!Number.isNaN(lotPCE)){
            for (let j = 0; j < zonesOfBatiment.length; j++) {
              // console.log('zoneess',(posts[i]['entity']||'')+'/'+zonesOfBatiment[j])
              this.zones[(posts[i]['entity']||'')+'/'+zonesOfBatiment[j]]=(this.zones[posts[i]['entity']+'/'+zonesOfBatiment[j]]||[]).filter(lotPCEV=>lotPCEV!=lotPCE)
            }
          }
        }
      }
      // console.log(this.zones);
    }
    // set lots uncomplete icon
    if(Object.keys(this.zones).length){
      for (const key in this.zones) {
        if ((this.zones[key]||[]).length > 4) {
          this.etatCardList[2].labelIcon='/assets/acv-icons/cross.svg'
          this.etatCardList[2].label='Un nombre insuffisant de lots a été renseigné'
          this.etatCardList[2]['show']=true
          break
        }
      }
    }else{
      this.etatCardList[2].labelIcon='/assets/acv-icons/cross.svg'
      this.etatCardList[2].label='Un nombre insuffisant de lots a été renseigné'
      this.etatCardList[2]['show']=true
    }
  }
  clickRSETinput(){
    // console.log('clicked');
    $("#fileInput").trigger('click')
  }
  missingFieldsModal(){
    $('#required-fields-calcul-acv').modal('show');
  }
  missingLotsModal(){
    $('#required-lot-pce-acv').modal('show');
  }
  clickEvent(id){
    // console.log('id',id);
    if(id=="importRSET") this.clickRSETinput()
    if(id=="missingFields") this.missingFieldsModal()
    if(id=="missingLots") this.missingLotsModal()
  }
  public OpenAttestationModal(){
    // if(!parseInt(this.permis||'')) {
      this.selectedAttestationType='xml'
      this.loadingDownload=true
      this.subscriptions.push(
      this.acvService.downloadAttestationFinTravaux(this.ceProject, {type:'xml'})
        .subscribe(
          data => {
            this.loadingDownload=false
            const ab = new ArrayBuffer(data['data'].data.length);
            const view = new Uint8Array(ab);
            for (let i = 0; i < data['data'].data.length; i++) {
              view[i] = data['data'].data[i];
            }
            const file = new Blob([ab], { type: "application/zip" });
            var fileS3 = new File([file], "RS2E.zip");
            this.fileEvent(fileS3,file)
            if(!environment.onPremise)window.analytics.track('Download RSEE',{properties:{acValue: 'Download RSEE'}});
          },
          error => {
            this.loadingDownload=false
            console.log('ERROR : ', error);
          }
        )
      );
    // }
    // else
    // $('#telecharger-attestation-fin-travaux').modal('show');
  }
  public downloadAttestation(){
    this.loadingDownload=true
    let body = {
      type: this.selectedAttestationType
    }
    if(this.selectedAttestationType == '') return;
    this.subscriptions.push(
      this.acvService.downloadAttestationFinTravaux(this.ceProject, body)
        .subscribe(
          data => {
            this.loadingDownload=false
            const ab = new ArrayBuffer(data['data'].data.length);
            const view = new Uint8Array(ab);
            for (let i = 0; i < data['data'].data.length; i++) {
              view[i] = data['data'].data[i];
            }
            const file = new Blob([ab], { type: "application/zip" });
            var fileS3 = new File([file], "RS2E.zip");
            this.fileEvent(fileS3,file)
            if(!environment.onPremise)window.analytics.track('Download RSEE',{properties:{acValue: 'Download RSEE'}});
          },
          error => {
            this.loadingDownload=false
            console.log('ERROR : ', error);
          }
        )
    );
  }
  goToPosts(){
    this.showPostsEvent.emit()
  }
  goToZone(zoneName,lot){
    let batiment=zoneName.split('/')[0]
    zoneName=zoneName.split('/')[1]
    $('#required-lot-pce-acv').modal('hide');
    this.showComposantEvent.emit({zoneName:zoneName,batiment:batiment,lot:lot})
  }
  barValue(n1,n2){
    return (n1/(n2*2))*100<100 ? (n1/(n2*2))*100 + '%': '98%'
  }
  borderValue(n1,n2){
    return n1<n2
  }
  public missingFieldsStructure = {};
  private checkIfFieldsAreFilled(acvData){
    this.numberOfMissinsInputs = 0;

    for(let key in this.acvFilledFields){
      /// Project
      for(let nestedKey in this.acvFilledFields[key]){
        if(key == 'Projet'){
          let fieldvalue = _.get(this.acvData, nestedKey, undefined);

          let valueIsFilled = fieldvalue ? true : false;
          let valueKey = `${key}-${nestedKey}`;
          this.missingFieldsStructure[valueKey] = valueIsFilled;

          if(!valueIsFilled){
            this.numberOfMissinsInputs++;
          }

        }else{
          /// batiment fields
          if(typeof this.acvFilledFields[key][nestedKey] != 'object'){
            let fieldvalue = _.get(this.acvData, nestedKey, undefined);

            if(fieldvalue){
              nestedKey = nestedKey.replace(/\./g, '-');
              _.set(this.acvFilledFields, `${key}.${nestedKey}`, false);
              this.numberOfMissinsInputs++;
            }
          }else{
            /// Zones Fields
            for(let zoneKey in this.acvFilledFields[key][nestedKey]){
              let fieldvalue = _.get(acvData, zoneKey, undefined);
              let valueIsFilled = fieldvalue ? true : false;

              let valueKey = `${zoneKey}`;
              this.missingFieldsStructure[valueKey] = valueIsFilled;
              if(!valueIsFilled){
                this.numberOfMissinsInputs++;
              }
            }
          }
        }
      }
      /// Batiments
    }

    // console.log(this.missingFieldsStructure);
    /// set Etat Cards label
    for(let i = 0; i < this.etatCardList.length; i++){
      if(this.etatCardList[i].id == 'missingFields'){
        this.etatCardList[i].label = `Il manque <b>${this.numberOfMissinsInputs} champs</b> à remplir`;
        this.etatCardList[i]['missingFieldsCount'] = this.numberOfMissinsInputs;
        if(this.numberOfMissinsInputs == 0){
          this.etatCardList[i].label = 'Tous les champs nécessaires au calcul ont été remplis';
          this.etatCardList[i].show = false;
          this.etatCardList[i].labelIcon = '';
        }
      }
    }
  }
  public updateRequiredFields(){
    if(!Object.keys(this.requiredFormInputs).length) return;

    this.subscriptions.push(
      this.acvService.updateAcvProject(this.ceProject, this.requiredFormInputs)
        .subscribe(
          (data) => {
            this.acvData = data['data'];
            this.checkIfFieldsAreFilled(this.acvData);
            // console.log('data', data);
            $('#required-fields-calcul-acv').modal('hide');
          },
          (error) => {
            console.log('error', error);
          },
        )
    );
  }
  public acvFieldsFilledInput(value){
    let key = Object.keys(value)[0];
    let val = Object.values(value)[0];

    this.requiredFormInputs[key] = val;
  }
  public fileEvent(fileS3,file) {
    const AWSService = AWS;
    const region = 'eu-central-1';
    const bucketName = 'bam-consultation-slickshot';
    const IdentityPoolId = 'eu-central-1:7a0af26b-a2d9-4bd5-aa01-dff3f719b7db';
    let files=[fileS3]
    this.loadingFiles = true;
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
      })
    });
    const s3 = new S3({
      apiVersion: '2006-03-01',
      params: { Bucket: bucketName}
    });
    const ID = function () {
      return Math.random().toString(36).substr(2, 9);
    };

    let filesUpload = files.map(file => s3.upload({ Key: ID() + "-" + file.name, Bucket: bucketName, Body: file, ACL: 'public-read' }).promise());
    Promise.all(filesUpload).then((filesUploaded)=> {
      this.loadingFiles = false;
      let body = {
        RSEEPath:filesUploaded[0].Location,
        type: this.selectedAttestationType
      }
      if(this.selectedAttestationType!='xml'){
        this.acvService.downloadAttestationFinTravauxPDF(this.ceProject,body).subscribe(
          (data)=>{
            if(!environment.onPremise)window.analytics.track('Download RSEE',{properties:{acValue: 'Download RSEE'}});
          }
        )
      }
      else saveAs(file, "RS2E.zip");
    }).catch(err=>{
      console.log(err);
      this.loadingFiles = false;
    })
  }
  checkRSET(){
    return Object.keys(((this.acvData||{}).RSETFile||{})).length > 0
  }
  checkNumValidValues(){
    // console.log(this.notValidValues.split('et').length);
    if(this.notValidValues.split('et').length>1) return true
    else return false
  }
  public returnZero() {
    return 0
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }

}
