import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NO_ERRORS_SCHEMA } from '@angular/core';
import { ArtisanTeam } from 'src/app/_interfaces/team.interface';
import { LotPricing } from 'src/app/_interfaces/team-lot-pricing.interface';
import { NgSharedModule } from 'src/app/all/ngshared.module';

import { DpgfPostesService, ErrorService } from 'src/app/_services';
import { TeamService } from 'src/app/_services/team.service';

import { Subscription } from 'rxjs';
import { DpgfModule } from 'src/app/dpgf/dpgf.module';

@Component({
  standalone:true,
  selector: 'app-team-modifier',
  imports:[
    DpgfModule,
    NgSharedModule
  ],
  templateUrl: './team-modifier.component.html',
  styleUrls: ['./team-modifier.component.scss']
})
export class TeamModifierComponent implements OnInit {
  public activeCollapse: string[] = [];

  @Input() selectedArtisan: string;
  @Input() team: ArtisanTeam;
  @Input() pricings: LotPricing[];
  @Input() lot: string;
  @Input() lotX: number;
  @Input() colors: any = {};
  @Input() dpgfId: string;


  @Output() onPricingAdd = new EventEmitter();
  @Output() onPricingRemove = new EventEmitter();
  @Output() onSave = new EventEmitter();

  public loadingPosts: boolean = false;
  public subscriptions:  Array<Subscription> = [];

  constructor(
    public dpgfPostesService: DpgfPostesService,
    private _teamService: TeamService
  ) { }

  ngOnInit() {

  }
  filterPricings(): LotPricing[] {
    let p = this.pricings.filter(pricing => pricing.idLot === this.lot);
    p = p.filter(pricing=> (pricing.total != 0))
    return p;
  }
  getPosts(dpgfId) {
    const posts = this.team.lotPricing.filter(p => p.dpgfId === dpgfId);
    return posts ? posts : [];
  }
  teamClick(pricing: LotPricing) {
    if(this.selectedArtisan === pricing.artisanName) {
      this.selectedArtisan = "";
      this.onPricingRemove.emit(pricing);
      return;
    }

    this.onPricingAdd.emit(pricing);
    this.selectedArtisan = pricing.artisanName;
  }
  onClose() {
    this.onSave.emit();
  }
  setActiveCollapse(idPricing: string) {    
    const el = document.getElementById(idPricing);
    const index = this.activeCollapse.indexOf(idPricing);
    if(el.classList.contains('in')) {
      if(index > -1)
        this.activeCollapse.splice(index, 1);
    }
    else {
      this.loadLotPosts(this.lot, idPricing);
      if(index === -1)
        this.activeCollapse.push(idPricing);
    }
  }
  public extractPricingPosts(pricing, pricingId){
    for(let i = 0; i < pricing[0].data.length; i++){
      let lot = pricing[0].data[i];
      if(lot.id == this.lot){
        const l = new LotPricing(lot.id, pricing._id, pricing.artisanName, lot.total, pricing.dpgf);
        l.posts = this._teamService.getPosts(lot.sousLots);
        l.currentVersion = pricing.currentVersion;
        l.loti = i;
        let selectedPricing = this.filterPricings().find(p => p.idpricing == pricingId);
        selectedPricing.posts = l.posts;
        break;
      }
    }
    $('.posts-loader').addClass('hide-loader');
    this.loadingPosts = false;
  }
  public loadLotPosts(lotId, pricingId){
    $(`#loader-${pricingId}`).removeClass('hide-loader');
    let body = [pricingId];
    this.subscriptions.push(
      this.dpgfPostesService.getLotPostsById(this.dpgfId, lotId, body)
        .subscribe(
          data => {
            this.extractPricingPosts(data, pricingId);
          },
          error => {
            console.log(error);
            $('.posts-loader').addClass('hide-loader');
            this.loadingPosts = false;
          }
        )
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
