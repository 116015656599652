<!-- App Header -->
<div class="all">
  <div [@appear]='state' class="toast">
    <i class="fa fa-floppy-o" aria-hidden="true"></i> Enregistré
  </div>
  <app-header></app-header>
  <app-spinner cssClass="fixed-bar" *ngIf="loadingData$|async"></app-spinner>
  <!-- <app-ce-project-nav [disable]="loadingData$|async" [projectId]="projectId" selected="dpgf" [devisNumber]="sentToArchi" [DPGFId]="dpgfId"></app-ce-project-nav> -->
  <div *ngIf="!(loadingData$|async)" id="tool-bar" class="row" [ngClass]='{sticky : isSticky}'>
    <div class="floatin save-btn" style="float: left">
      <app-spinner *ngIf="downloadingDoc"></app-spinner>
    </div>
    <div *ngIf="!downloadingDoc" (click)="getPdf()" class="floatin save-btn" style="float: left">
      <i class="fa fa-download" aria-hidden="true"></i> PDF
    </div>
    <!-- <div (click)="getPdf()" class="floatin save-btn" >
      <i class="fa fa-download" aria-hidden="true"></i> Revenir à la version encours
    </div> -->
    <div class="read-only-mode">Disponible en lecture seule</div>
    <div class="floatin save-btn">
      <i class="fa fa-eye" aria-hidden="true"></i> Version Ind.{{(dpgf$|async)?.currentVersion | pricingIndex}}
    </div>

  </div>

  <div class="container full-width" *ngIf="!(loadingData$|async)">
    <div class="row">
      <div class="col-md-12">

        <div class="row floating-card">
          <div class="col-md-12" data-step="1" data-intro="Information du generale du projet">
            <app-head-dpgf mode="view"> </app-head-dpgf>
          </div>
        </div>

        <div class="row">
          <app-table-dpgf mode="view" [dpgf]="dpgf$|async"></app-table-dpgf>
        </div>
      </div>
    </div>

  </div>
  <!-- <app-support-box [interface]="'gestion-projet'" [traduction]="true"></app-support-box> -->

</div>
