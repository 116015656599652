<div [id]="modalId" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="ag-heading-h4 ag-weight-600 no-margin">{{ headerTitle }}</h4>
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">

        <p class="main-text">
          {{ bodyText }}
        </p>

        <div class="submit-btn-container text-right margin-top-30 ">
          <button class="ag-btn-bordered-sm margin-right-40" data-dismiss="modal">{{ cancelButtonLabel }}</button>
          <button class="ag-btn-primary-sm" (click)="emitConfirm()">{{ confirmButtonLabel }}</button>
        </div>

      </div>
    </div>
  </div>
</div>
