<app-header></app-header>

<div class="container-fluid">
  <div class="col-md-12 full-height2 ">
    <div class="form">
      <div class="row">
        <div class="col-md-3 progress-text">Connexion</div>
        <div class="col-md-3 progress-text">Généralités</div>
        <div class="col-md-3 progress-text">Details</div>
        <div class="col-md-3 progress-text">Documents</div>
      </div>
      <div class="progress">
        <div class="progress-bar" style="width: 37%;"></div>
      </div>
      <!-- <h1 class="title">Créez votre compte</h1> -->
      <!-- <app-artisan-signup-general [(agency)]="artisan" ></app-artisan-signup-general> -->
      <div class="center">
        <button [disabled]="loading" (click)="save()" class="btn-primary-color">
          <div *ngIf="!loading">Valider</div>
          <app-spinner *ngIf="loading"></app-spinner>
        </button>
      </div>
    </div>
  </div>
</div>
