import { Pipe, PipeTransform } from '@angular/core'
import { LoggsClass } from 'src/app/dpgf/dce-artisan-management/architect-artisans-popup/loggs'

@Pipe({
  standalone:true,
  name: 'loggs'
})
export class LoggsPipe implements PipeTransform {
    public loggs = (new LoggsClass).trasnform;
        
    transform(log:any) {
      if(log.type && this.loggs[log.type]){
        return this.loggs[log.type](log)
      }
      return "";
    }
}
