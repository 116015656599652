import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat',
  standalone: true
})
export class DateFormatPipe implements PipeTransform {

  transform(inputDate: Date | string): unknown {
    if (!inputDate) {
      return '';
    }
    let date=new Date(inputDate);
    let formattedDate = `${this.paddingZero(date.getDate())}/${this.paddingZero(date.getMonth() + 1)}/${date.getFullYear()}`;
    return formattedDate;
  }
  private paddingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

}
