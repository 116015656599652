<div class="outer-wrapper">

  <!-- Agency Logo -->
  <div class="agency-logo-container text-center">
    
    <!-- Logo -->
    <div class="flex-middle">
      <!-- artisan Logo -->
      <img [src]="artisanData?.logo?.cdnUrl" class="ageny-logo" *ngIf="((artisanData.logo||{}).cdnUrl|| '') != ''">

      <!-- Choose logo -->
      <img class="ageny-logo placeholder-logo" src="/assets/choose-logo-image.svg"
      (click)="openPfileUpload()"
      *ngIf="((artisanData.logo||{}).cdnUrl|| '') == '' && showEditButtons">
      <div style="display:none">
        <p-fileUpload  #fileUpload mode="basic" name="demo1" url="/api/v1/upload-file?type=assuranceFile" [auto]="true"
        (onUpload)="onUploadAgencyLogo($event)" accept="image/*"/>
      </div>
      <!-- Helmet -->
      <img src="/assets/rob/helmet.png" class="ageny-logo" 
        *ngIf="((artisanData.logo||{}).cdnUrl|| '') == '' && !showEditButtons ">

      <!-- Edit & Remove Agency Logo Buttons -->
      <div class="action-btns-container vertical-items" [ngClass]="{'remove-item-from-view': !showEditButtons}">
        
        <div class="">
          <div class="upload-btn"></div>
          <button class="action-btns margin-bottom-20" 
            *ngIf="((artisanData.logo||{}).cdnUrl|| '') != ''"> 
            <span class="tooltip downriver-bg ag-text-white">Modifier le logo</span>
            <img src="/assets/artisan-booster/edit-pen-sign.svg" (click)="openPfileUpload()"/> 
          </button>
        </div>

        <button class="action-btns"
          *ngIf="((artisanData.logo||{}).cdnUrl|| '') != ''" 
          (click)="openModal('removeAgencyLogo')"> 
          <span class="tooltip downriver-bg ag-text-white">Supprimer le logo</span>
          <img src="/assets/artisan-booster/remove-icon.svg" /> 
        </button>

      </div>
      <!-- END Edit & Remove Agency Logo Buttons -->
      
    </div>
    <!-- END Logo -->

    <p class="agency-name ag-para-1 ag-weight-500">{{ artisanData.name }}</p>
    <div class="margin-top-16 horizontal-items flex-middle">
      
      <div class="">
        <h5 class="ag-heading-h5 no-margin margin-right-27" *ngFor="let item of artisanData.type">
          {{ typeList[item] }}
          <!-- Artisan / Entreprise spécialisée -->
        </h5>
      </div>

      <img src="/assets/artisan-booster/edit-pen-sign.svg" class="pointer"
        *ngIf="showEditButtons" 
        (click)="openModal('agencyName')">
    </div>
    <div class="margin-top-18">
      <span class="badge-accent aglo-booster-chip">AGLO BOOSTER©</span>
    </div>
  </div>
  <!-- END Agency Logo -->

  <!-- Agency Information -->
  <div class="agency-information-container margin-top-30">
    <div class="horizontal-items margin-bottom-20">
      <h5 class="ag-heading-h5 no-margin margin-right-27">Informations de l’entreprise</h5>
      <img src="/assets/artisan-booster/edit-pen-sign.svg" class="pointer"
        *ngIf="showEditButtons" 
        (click)="openModal('information')">
    </div>

    <!-- Type de travaux  -->
    <p class="ag-para-3 ag-weight-500 margin-bottom-4">Type de travaux </p>
    <div class="check-boxes-list">
      <span class="checkbox-item choosed margin-bottom-10" *ngFor="let item of artisanData.travaux">
        {{ travauxList[item] || "" }}
      </span>
    </div>
    <!-- END Type de travaux -->
    
    <!-- Programme  -->
    <p class="ag-para-3 ag-weight-500 margin-bottom-4 margin-top-16">Programme </p>
    <div class="check-boxes-list">
      <span class="checkbox-item choosed" *ngFor="let programme of artisanData.programme">
        {{ programmesList[programme] || "" }}
      </span>
    </div>
    <!-- END Programme -->
    
    <!-- Année de création -->
    <div class="">
      <p class="ag-para-3 ag-weight-500 margin-bottom-4 margin-top-16">Année de création de l’entreprise</p>
      <p class="ag-para-1"><b>{{ artisanData.creationYearAgency }}</b></p>
    </div>
    <!-- END Année de création -->

    <!--  -->
    <div class="">
      <p class="ag-para-3 ag-weight-500 margin-bottom-4 margin-top-16">Chiffre d’affaire de l’année précédente</p>
      <p class="ag-para-1"><b>{{ artisanData.ca }}€</b></p>
    </div>
    <!--  -->    

  </div>
  <!-- END Agency Information -->


  <!-- Garantie décénnale -->
  <div class="garantie-decennale-container">
    <div class="horizontal-items margin-bottom-20">
      <h5 class="ag-heading-h5 no-margin margin-right-27">Garantie décénnale</h5>
      <img src="/assets/artisan-booster/edit-pen-sign.svg" class="pointer" 
        *ngIf="showEditButtons" 
        (click)="openModal('garantieDecennale')">
    </div>
    
    <!-- Type de travaux  -->
    <p class="ag-para-3 ag-weight-500 margin-bottom-10">Corps d’états pris en charge</p>
    
    <div class="check-boxes-list" *ngFor="let item of corpsDetatItemsList | slice:0:5;">
      <p class="ag-para-3 ag-weight-500 margin-bottom-5">{{ item.title }}</p>
      <span class="checkbox-item choosed" *ngFor="let listItem of item.itemsList">{{ listItem.label }}</span>
    </div>
    <!-- END Type de travaux -->

    <!-- If Corps D'etat is Empty -->
    <div class="check-boxes-list" *ngIf="!corpsDetatItemsList.length">
      <span class="checkbox-item choosed" *ngFor="let singlePost of artisanData.poste">{{ singlePost.text }}</span>
    </div>    
    <!-- END If Corps D'etat is Empty -->

    <span class="show-more-btn ag-para-1 pointer" 
      *ngIf="corpsDetatItemsList.length"
      (click)="openModal('garantieDecennaleViewOnly')">Tout voir ({{corpsDetatItemsListCount}})</span>

  </div>
  <!-- END Garantie décénnale -->

  <!-- Documents -->
  <div class="documents-container">
    <div class="horizontal-items margin-bottom-20">
      <h5 class="ag-heading-h5 no-margin margin-right-27">Documents de l’entreprise</h5>
      <img src="/assets/artisan-booster/edit-pen-sign.svg" class="pointer" 
        *ngIf="showEditButtons" 
        (click)="openModal('documents')">
    </div>

    <div class="docs-item margin-bottom-12">
      <p class="ag-para-1 ag-weight-500 margin-bottom-4"><b>Attestation d’assurance</b></p>
      <a [href]="artisanData?.assuranceFile?.cdnUrl" target="_blank">{{ artisanData?.assuranceFile?.name }}</a>
    </div>

    <div class="docs-item margin-bottom-12">
      <p class="ag-para-1 ag-weight-500 margin-bottom-4"><b>Extrait KBIS</b></p>
      <a [href]="artisanData?.kbis?.cdnUrl" target="_blank">{{ artisanData?.kbis?.name }}</a>
    </div>

    <div class="docs-item margin-bottom-12">
      <p class="ag-para-1 ag-weight-500 margin-bottom-4"><b>Certification (Qualibat...)</b></p>
      <a [href]="file.cdnUrl" target="_blank" class="block-item" 
        *ngFor="let file of artisanData.attestationsCertification">
        {{ file.name }}
      </a>
    </div>

    <div class="docs-item margin-bottom-12">
      <p class="ag-para-1 ag-weight-500 margin-bottom-4"><b>Autres</b></p>
      <a [href]="file.cdnUrl" target="_blank" class="block-item"
         *ngFor="let file of artisanData.files">
         {{ file.name }}
      </a>
    </div>    
    
  </div>
  <!-- END Documents -->

  <!-- Construction bas carbone -->
  <div class="construction-carbon-container" 
    *ngIf="artisanData.materiauxBasCarbones.length && artisanData.materiauxIssusReemploi.length">
    <div class="horizontal-items margin-bottom-20">
      <h5 class="ag-heading-h5 no-margin margin-right-27">Construction bas carbone</h5>
      <img src="/assets/artisan-booster/edit-pen-sign.svg" class="pointer" 
        *ngIf="showEditButtons" 
        (click)="openModal('constructionCarbone')">
    </div>

    <!-- Réemploi -->
    <div class="carbone-item margin-bottom-20">
      <p class="ag-para-1 ag-weight-500 margin-bottom-8">Réemploi</p>
      <ul class="no-padding">
        <li  *ngFor="let item of artisanData.materiauxIssusReemploi">
          <img src="/assets/artisan-booster/pink-check-sign.svg" class="margin-right-17" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <!-- END Réemploi -->

    <!-- Matériaux bas carbone -->
    <div class="carbone-item">
      <p class="ag-para-1 ag-weight-500 margin-bottom-8">Matériaux bas carbone</p>
      <ul class="no-padding">
        <li  *ngFor="let item of artisanData.materiauxBasCarbones">
          <img src="/assets/artisan-booster/pink-check-sign.svg" class="margin-right-17" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <!-- END Matériaux bas carbone -->
    
  </div>
  <!-- END Construction bas carbone -->

  <!-- Précisions AGLO BOOSTER -->
  <div class="preisions-aglo-booster-container" *ngIf="showEditButtons">
    <div class="horizontal-items margin-bottom-20">
      <h5 class="ag-heading-h5 no-margin margin-right-27">Précisions AGLO BOOSTER© </h5>
      <img src="/assets/artisan-booster/edit-pen-sign.svg" class="pointer" 
        *ngIf="showEditButtons" 
        (click)="openModal('precisionsAgloBooster')">
    </div>

      <p class="ag-para-1 margin-bottom-16">Ces informations sont seulement visibles par vous et notre équipe.</p>

      <!-- <p class="ag-para-1 ag-weight-500 margin-bottom-8">Domaine de spécialisation</p> -->
      <p class="ag-para-1 ag-weight-500 margin-bottom-8"><b>Domaine de spécialisation</b></p>

      {{ artisanData.domaineSpe }}
      <!-- <a class="link-btn" (click)="openModal('precisionsAgloBooster')">Renseigner ma spécialisation</a> -->

      <div class="work-area-list-wrapper">
        <!-- <p class="ag-para-1-sm margin-bottom-8">Département d’intervention</p> -->
        <p class="ag-para-1 ag-weight-500 margin-bottom-8"><b>Département d’intervention</b></p>


        <span class="ag-para-1" *ngFor="let item of departementsInterventionList">{{item}}, </span>        
      </div>
    
  </div>
  <!-- END Précisions AGLO BOOSTER -->


</div>

