<div class="switch-toggle switch-3 switch-candy">

  <!-- <div class="choice" (click)="onSelectionChange('files')" [ngClass]="{'selectd-choice' : value === 'files'}">
    Documents
  </div> -->
  <div class="choice" (click)="onSelectionChange('dpgf')" [ngClass]="{'selectd-choice' : value === 'dpgf'}">
    DPGF
  </div>
  <div class="choice" (click)="onSelectionChange('cctp')"  [ngClass]="{'selectd-choice' : value === 'cctp'}">
    CCTP
  </div>


  <!-- <div class="choice" (click)="onSelectionChange('files')"  [ngClass]="{'selectd-choice' : value === 'files'}">
    FICHIER
  </div> -->


</div>
