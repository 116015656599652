import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup,  Validators} from '@angular/forms';
import { AuthService } from '../../../_services/index';
import { ArchitectService } from '../../../_services/index';
import { PaymentsService } from '../../../_services/index';
import { NgSharedModule } from 'src/app/all/ngshared.module';

import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
//import { ArchitectModule } from '../architect.module';

declare var $;

@Component({
    selector: 'app-archi-parrainage',
    imports:[
      
      NgSharedModule    
    ],
    providers:[],
    templateUrl: './archi-parrainage.component.html',
    styleUrls: ['./archi-parrainage.component.scss'],
    standalone: true,

})
export class ArchiParrainageComponent implements OnInit {
  public archiId:String = "";
  public architect : any = {};
  public recommendations : any = [];
  public filleuls : any = [];
  public parrainages: any = [];
  public loadingData:Boolean = true;
  private subscriptions: Array<Subscription> = [];
  public invitationForm: UntypedFormGroup;
  public totalSold: any = 0;
  public userId: string = "";
  public active: boolean = false;
  public invitedEmails: any = [];

  constructor(
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private authService: AuthService,
    private archiService: ArchitectService,
    private paymentService: PaymentsService,) { }

  ngOnInit() {
    this.createForm();
    this.userId = JSON.parse(localStorage.getItem("currentUser")).id;
    this.getProfile();
  }

  getProfile(){
    if(this.userId  != ""){
      this.subscriptions.push(
        this.authService.getUserLicenceStripeUser()
        .subscribe(
          data => {
            if((data.data.stripeCustomerID || "") != ""){
              this.active = true;
            }
            this.loadParrainageList();
          },
          error => {
            console.log("error",error)
          })
        )
    }else{
    }
  }
  public createUser(){
    this.loadingData = true;
    this.subscriptions.push(
      this.paymentService.createUser()
      .subscribe(
        data => {
          this.getProfile()
        },
        error => {
          console.log("error",error)
          this.loadingData = false;
        })
      )
  }
  //Create Form
  private createForm() {
    const EMAIL_VALIDATION_PATTERN = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$";

    this.invitationForm = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(EMAIL_VALIDATION_PATTERN)]],
    });
  }

  private loadParrainageList(){
    this.subscriptions.push(
      this.archiService.getParrainageList()
      .subscribe(res => {
        this.parrainages = res.parrainages;
        this.parrainages.forEach((parr) => {
          this.totalSold += parr.solde;
          this.invitedEmails.push(parr.filleulEmail);
        });
        this.loadingData = false;
      })
    );
  }

  public inviteUser(): void{
    if(!this.invitationForm.valid) return;
    /// if email already invited don't send invitation
    if(!this.invitedEmails.includes(this.invitationForm.value.email)){
      this.subscriptions.push(
        this.archiService.sendParrainageInvitation(this.invitationForm.value)
        .subscribe(
          res => {
            this.parrainages.unshift(res.data.parrainage);
            this.invitedEmails.unshift(res.data.parrainage.filleulEmail);
            this.invitationForm.reset();


            this.messageService.add({ key: 'toast', severity: 'success', summary: "Envoi d'une invitation", detail: "Message d'invitation envoyé avec succès", life: 5000 });            
            $('#add-parrainage-popup').modal('hide');
            // segment event
            if(!environment.onPremise)window.analytics.track('sponsorship_sent',{properties:{acValue: 'sponsorship_sent'}});
          },
          err => {
            this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: 'Erreur serveur', life: 5000 });
          }
        )
      );
    }else{
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Déjà invité', detail: "Cet e-mail est déjà invité", life: 5000 });
    }
  }


  public openAddParrainageModal(): void{
    $('#add-parrainage-popup').modal('show');
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }

}
