<div class="row" style="margin-right:-15px !important" *ngIf="dpgf">
  <div class="col-md-3 sticky-sommaire hidden-xs hidden-sm">
    <div class="row floating-card sommaire-container">
      <app-dce-switch (view)="changeView($event)" *ngIf="mode != 'artisan'"></app-dce-switch>
      <h1>Sommaire</h1>
      <!-- <div class="flex space-between"> -->
      <!-- </div> -->
      <div dragula="SOMMAIRE" [(dragulaModel)]="dpgf.data" (dragulaModelChange)="changeLotOrder($event)">
        <div *ngFor="let lot of sortLots(dpgf?.data),let loti = index;trackBy:trackById" (click)="getLot($event, lot.id, loti)">
          <div   class="sommaire-pill" [ngClass]="{'selected': (lot$ | async)?.id == lot.id , 'not-consulted' : lot.notConsulted}">
            <i id="lot-icon-arrow" *ngIf="(lot.icon||'') == ''" class="fa fa-arrows handle grab-it" aria-hidden="true"></i>
            <img id="lot-icon" *ngIf="(lot.icon||'') != ''" class="handle grab-it" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" alt="" height="15">
            <span class="lot-sommaire" [pTooltip]="lot.title"  *ngIf="isAutomateNumberIsChecked" > {{loti}}- {{lot.title}}</span>
            <span class="lot-sommaire" [pTooltip]="lot.title"  *ngIf="!isAutomateNumberIsChecked" > {{lot.indexLot}}- {{lot.title}}</span>
            <div class="delete-sommaire-lot delete" *ngIf="modeList[mode].deleteLot">
              <div class="poste-menu">
                <div class="menubtn dropdown">
                  <div class="more-dropdown no-open" aria-hidden="true" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <i class="fa fa-ellipsis-v no-open" ></i>
                  </div>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <li (click)="$event.stopPropagation();dupliquateLot(lot)">
                      <i class="fa fa-files-o" aria-hidden="true"></i>
                      <span class="menu-text">Dupliquer</span>
                    </li>
                    <li (click)="$event.stopPropagation();deleteLot(lot.id)">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                      <span class="menu-text">Supprimer</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sommaire-pill add-pill" (click)="addLot(true,true, dpgf?.data.length)" *ngIf="modeList[mode].addLot && !(addnewLot$|async)">
        Ajouter un lot +
      </div>
      <div class="import-btn" aria-hidden="true" (click)="importLot()" *ngIf="modeList[mode].addLot && !(addnewLot$|async)">
        <i class="fa fa-cloud-download" aria-hidden="true"></i>
        Importer un lot
      </div>
      <app-spinner *ngIf="(addnewLot$|async)"></app-spinner>
      @if((sizeDpgf$|async)>=75 && mode != 'artisan'){
        <div class="flex space-between">
          <div class="padding-top-10">
            Espace utilisé:
            <span class="ag-bold" *ngIf="(sizeDpgf$|async)>=0&&(sizeDpgf$|async)<75"> {{(sizeDpgf$|async) | number: '1.2-2'}}%</span>
            <span class="ag-bold" *ngIf="(sizeDpgf$|async)>=75&&(sizeDpgf$|async)<95" style="color:orange"> {{(sizeDpgf$|async) | number: '1.2-2'}}%</span>
            <span class="ag-bold" *ngIf="(sizeDpgf$|async)>=95" style="color:red"> {{(sizeDpgf$|async) | number: '1.2-2'}}%</span>
          </div>
          <div class="nb-lines">
            {{nbLine$ | async}} lignes
          </div>
        </div>
      }
    </div>

  </div>
  <div id="top-lot"></div>
  <div class="dpgf-lots-wrapper" [ngClass]="sideBarIsExpanded && currentUser.archiId && !isFactice && !authService.currentEnvironment ? 'col-md-6' : cssColDependindOfUserTypeConnected" *ngIf="view == 'cctp' || view == 'dpgf' && showDpgfTable" >
    <div class="row">
      <app-spinner *ngIf="(loadingLot$ | async)"></app-spinner>
    </div>
    <div  *ngIf="!(lot$ | async) && !(loadingLot$ | async)"  >
      <div *ngFor="let lot of sortLots(dpgf?.data),let loti = index;trackBy:trackById"
            style="padding: 20px 10px;"
            class="row floating-card click-it" (click)="getLot($event, lot.id, loti)">
        <div class="col-md-9">  
          <app-pill-dpgf  #lotinputs
                          placeholder="Titre du lot"
                          [id]="lot.id"
                          [disable]="true"
                          [textContent]="lot.title"
                          [icon]="lot.icon"
                          [color]="lot.color"
                          [prefix]="lot.indexLot || loti"
                          [loadingLot]="loadingLot$"
                          [attr.id]="'designation' + lot.indexLot"></app-pill-dpgf>
        </div>
        <div class="col-md-3" *ngIf="lot[modeList[mode].totalName]">
          <div class="lot-indicator">
            <div class="total-estime" *ngIf="(lot.nbLines||0) != 0">
              {{((lot[modeList[mode].nbLineDone] || 0) / lot.nbLines) * 100 | formatPrice}} %
              <span *ngIf="mode != 'artisan'">Estimé</span>
              <span *ngIf="mode == 'artisan'">Chiffré</span>
            </div>
            <div>
              {{lot[modeList[mode].totalName] | formatPrice}} € HT
            </div>
          </div>
        </div>
        <div class="col-md-3 not-lot-consulted" *ngIf="!lot[modeList[mode].totalName] && lot.notConsulted">
          Vous n'avez pas été spécifiquement consulté sur ce lot,Cependant vous pouvez tout de même y répondre
        </div>
      </div>
      <div  style="padding: 20px 20px;"
            class="row floating-card click-it">
            <div class="row totalht">
              <div class="col-md-6" >
                <span class="title">
                  Total HT
                </span>
              </div>
              <div class="col-md-6" >
                <span class="lot-indicator">
                  <strong>{{(total$ |async) | formatPrice}} € HT</strong>
                </span>
              </div>
            </div>
            <div class="row totalttc">
              <div class="col-md-6" >
                <span class="title">
                  Total TTC
                </span>
              </div>
              <div class="col-md-6" >
                <span class="lot-indicator">
                  <strong>{{(totalTTC$ |async) | formatPrice}} € TTC</strong>
                </span>
              </div>
            </div>
      </div>
    </div>
    <div class="row floating-card" id="selected-lot" *ngIf="(lot$ | async) && !(loadingLot$ | async)" style="padding-right: 20px; margin-right:16px !important;">
     
      <app-table-lot-dpgf [lot]="lot$ | async"
                          [loti]="isAutomateNumberIsChecked?currentIndexLot:lot.indexLot"
                          [showQuantity]="dpgf.showQuantity"
                          [isAutomateNumberIsChecked]="isAutomateNumberIsChecked"
                          [modify]="modify"
                          [editingTitle]="editingTitle"
                          [canEdit]="canEdit"
                          [mode]="mode"
                          [view]="view"
                          [estimate]="mode != 'view'"
                          [modeList]="modeList"
                          [(currentLot)]="currentLot"
                          [currentSousLot]="currentSousLot"
                          [currentPoste]="currentPoste"
                          [displaySide]="displaySide"
                          [currentColumn]="currentColumn"
                          [localisations]="(dpgf.localisations||[])"
                          (openCctp)="openSide($event)"
                          (openPostToRevitSlider)="changeSliderPostToRevitValue($event)"
                          (openSideCstb)="openSideCstb($event)"
                          (openSideArchiDb)="openSideArchiDb($event)"
                          (lineAdded)="calculateLines()"
                          (showDpgfTable)="showDpgfTableFnction($event)"
                          (showAcceptModal)="emitShowAcceptModal()"
                          ></app-table-lot-dpgf>
    </div>
  </div>
  <!-- <div class="col-md-9" *ngIf="view == 'files'" >
    <app-dpgf-folders [mode]="mode"></app-dpgf-folders>
  </div> -->
  
  <div *ngIf="currentUser.archiId && view != 'files' && !isFactice && !authService.currentEnvironment">
    <!-- <app-right-bar-consult-add-entreprise (sideBarIsExpandedOutput)="getStateSideBar($event)"></app-right-bar-consult-add-entreprise> -->
  </div>

</div>

<!--********************Sommaire Artisan ***********************-->
<!-- <div id="send-to-architect" class="row floating-card" *ngIf="mode=='archi'">
  <h1>Estimatif</h1>
  <div *ngFor="let lot of dpgf?.data,let loti = index;trackBy:trackById">
    <div (click)="getLot(lot.id, loti)" class="recap-pill">
      <img *ngIf="(lot.icon||'') != ''" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" alt="" height="15">
      <span>{{loti}} - {{lot.title}}</span>
      <span class="total-recap" *ngIf="lot[modeList[mode].totalName]">
        <span *ngIf="(lot.nbLines||0) != 0">{{((lot.nbLineDoneArchi || 0) / lot.nbLines) * 100 | number:'1.0-0'}} % {{modeList[mode].estimated}}  | </span>
       {{lot[modeList[mode].totalName] | number}} € HT
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-push-6 col-md-6 recap-total">
      Total HT
      <span class="total-recap">{{(total$ |async) | number}} € HT</span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-push-6 col-md-6 recap-total">
      Total TTC
      <span class="total-recap">{{(totalTTC$ |async) | number}} € TTC</span>
    </div>
  </div>
</div> -->

<!--********************Importer un lot***********************-->
<app-dpgf-import-lot-popup (selectedLots)="addSelectedLots($event)"></app-dpgf-import-lot-popup>

<p-sidebar #sidebarWrapper [(visible)]="displaySide" position="right" (onHide)="closeSide()" autoZIndex="false" id="cctp-edition-sidebar" [style]="{width:'70rem'}">
    <app-cctp-edition  #cctpEdition  (closeTheCctp)="closeSide()" (frirogeneResult)="frirogeneResult=$event" (infoPost)="setInfoPost($event)" [selectedFriro]="selectedFriro" (addingPostIniesEvent)="setaddingPostInies($event)" (openRemploiEvent)="openModalChoice($event)" [displaySide]="displaySide" [dpgfId]="dpgf._id"></app-cctp-edition>
</p-sidebar>

<app-revit-to-post [lots]="dpgf?.data" 
                   [postToRevitSliderOpen]="isPostToRevitSliderOpen" 
                   (openRevitToPostSlider)="openRevitToSlider($event)" 
                   [dpgfId]="dpgf._id"
                   >
</app-revit-to-post>

<p-sidebar  *ngIf="modeList[mode].exportPost"  [(visible)]="displaySideDb" position="right"  autoZIndex="false" (onHide)="closeSideCstb()" [style]="{width:'49rem !important'}">
  <div style="background-color: white;height: 100vh;">
  <div class="side-header">
        <i class="click-it fa fa-times padding-right-8" aria-hidden="true" (click)="closeSideCstb()"></i>
        <span style="font-size: 18px;color:rgba(10, 23, 71, 1);">
          Importer un poste
        </span>
  </div>
  <div class="cstb-content">
    <div class="flex space-between" style="align-items: center;padding-bottom: 24px;">
      <div class="flex space-between">
        <span class="pointer text-box" [ngStyle]="{'background-color':currentTab == 'CSTB'?'rgba(10, 23, 71, 0.16)':'white' }" (click)="updateTab('CSTB')" >CSTB</span>
        <span class="pointer text-box" [ngStyle]="{'background-color':currentTab == 'BIBLIO'?'rgba(10, 23, 71, 0.16)':'white' }" (click)="updateTab('BIBLIO')" >Bibliothèque</span>
        <span class="pointer text-box" [ngStyle]="{'background-color':currentTab == 'EXCEL'?'rgba(10, 23, 71, 0.16)':'white' }" (click)="updateTab('EXCEL')" >Excel</span>
      </div>
      <div style="position:relative;">
        <input class="input-search" placeholder='Poste' [(ngModel)]="searchTerm" (ngModelChange)="this.searchTermUpdate.next($event)" >
        <img class="search-icon"src="/assets/search-icon.svg" (click)='searchPost()'>
        <span *ngIf="!searchTerm" class="search-button pointer" (click)='searchPost()'>Rechercher</span>
        <span *ngIf="searchTerm" class="search-button pointer" (click)="emptySearchTerm()">Annuler</span>
      </div>
    </div>
    <div *ngIf="searchTerm" class="scroller-1-y" style="padding-left: 30px;" [ngStyle]="{'height':searchTerm!=''?'calc(60vh - 130px)':'0'}">
      <p class="research-text">Resultats de la recherche <b>{{searchTerm}}</b> :</p>
      <p class="result-title pointer" (click)="toogleCstb()"><img class="padding-bottom-4 padding-right-8" [src]="cstbToogle?'/assets/arrow_drop-down.svg':'/assets/arrow_right.svg'">Bibliothèque du cstb </p>
      <div  *ngIf="cstbToogle">
      <div  class="padding-bottom-8 post-container padding-left-12" *ngFor="let post of results;let i=index">
          <label class="form-control pointer">
            <input class="pointer" type='checkbox' [(ngModel)]="post.selected" (change)="selectedCstbPost(post)">
          </label>
          <p class="post margin-0">{{post.sugg}}</p>
        </div>
      </div>
      <div  class="scroller-1-y" *ngFor="let result of resultsBiblio;let i=index">
      <p class="result-title pointer" *ngIf="result._id==currentUser.archiId" (click)="toogleBiblio(i)" ><img class="padding-bottom-4 padding-right-8" [src]="biblioToggle[i]?'/assets/arrow_drop-down.svg':'/assets/arrow_right.svg'"> Ma bibliothèque</p>
      <p class="result-title pointer" *ngIf="result._id!=currentUser.archiId" (click)="toogleBiblio(i)" ><img class="padding-bottom-4 padding-right-8" [src]="biblioToggle[i]?'/assets/arrow_drop-down.svg':'/assets/arrow_right.svg'"> Bibliothèque de {{result.name}}</p>
        <div *ngIf="biblioToggle[i]">
      <div  class="padding-bottom-8 post-container padding-left-12"  *ngFor="let post of result.data;let j=index">
          <label class="form-control pointer">
             <input class="pointer" type='checkbox' (click)='selectedBiblioPost(post)'>
            </label>
            <p class="post margin-0">{{post.title}}</p>
        </div>
      </div>
      </div>
      
    </div>

    <div class="scroller-1-y"  [ngStyle]="{'height':(currentTab=='CSTB' && searchTerm=='')?'90vh':'0'}">
      <app-cstb-navigation [ngStyle]="{'height':(currentTab=='CSTB' && searchTerm=='')?'block':'none'}" [hidden]="searchTerm" manySelect="true" (selectedCstbEvent)="selectPosteCstb($event)" (showButton)="showButton($event)"></app-cstb-navigation>
      <div class="flex justify-center padding-top-10">
        <button *ngIf="currentTab=='CSTB' && searchTerm=='' && cstbAddButton"  [disabled]="selectedPosteCstb?.length==0" [class.disabled]="selectedPosteCstb?.length==0" type="button" class="btn-valdiate import-cstb" (click)="importCstbPostes()" name="button">
          Ajouter
        </button>
      </div>
    </div>
    <div class="scroller-1-y" [ngStyle]="{'height':(currentTab=='BIBLIO' && searchTerm=='')?'90vh':'0'}">
      <app-archi-db-navigation [ngStyle]="{'display':(currentTab=='BIBLIO' && searchTerm=='')?'block':'none'}"  [firstTime]="true" [sousLot]="archiDbSousloti" (selectedBiblioEvent)="selectPosteCstb($event)"></app-archi-db-navigation>
      <div class="flex justify-center padding-top-10">
        <button *ngIf="currentTab=='BIBLIO' && searchTerm==''"  [disabled]="(selectedPosts$|async)?.length==0" [class.disabled]="(selectedPosts$|async)?.length==0" type="button" class="btn-valdiate" name="button" (click)="importArchiDbPostes()">
          Ajouter
        </button>
      </div>
    </div>
    
    
    
    <div class="scroller-1-y" *ngIf="currentTab=='EXCEL' && searchTerm==''"  [ngStyle]="{'height':(currentTab=='EXCEL' && searchTerm=='')?'800px':'0'}">
      <app-excel-import [loti]="loti" [sousloti]="cstbSousloti" (posteFromExcelAdded)="closeSideCstb()"></app-excel-import>
    </div>  
    <div class="flex justify-center padding-top-10">
      <button *ngIf="searchTerm!=''"  [disabled]="selectedPosteCstb?.length==0" [class.disabled]="selectedPosteCstb?.length==0" type="button" class="btn-valdiate" name="button" (click)="importCstbPostes()">
        Ajouter
      </button>
    </div>
  </div>
</div>
</p-sidebar>
<!-- <p-sidebar *ngIf="modeList[mode].exportPost" [(visible)]="displaySideDb" position="right" autoZIndex="false" (onHide)="closeSideDb()" [style]="{'overflow-y': 'scroll','overflow-x':'hidden','width':'70rem'}" styleClass="p-sidebar-lg">
  <div class="side-header">
    <div class="row">
      <h2 class="center">
        <i class="click-it fa fa-times" aria-hidden="true" (click)="displaySideDb=false"></i>
        Importer un poste depuis
        <button type="button" class="btn-valdiate" name="button" (click)="importArchiDbPostes()">
          Importer
        </button>
      </h2>
    </div>
  </div>
  <div class="cstb-content">
    <app-archi-db-navigation [firstTime]="true" [sousLot]="archiDbSousloti"></app-archi-db-navigation>
  </div>
</p-sidebar> -->
<p-overlayPanel #op [showCloseIcon]="true" [appendTo]="'body'">
  <ng-template pTemplate>
    <ul *ngIf="!(loadingLot$ | async)" class="icon-list">
      <li
        class="icons"
        *ngFor="let icon of icons"
        (click)="setIcon(icon); op.hide()"
      >
        <img [src]="'assets/dpgf/Icon_DPGF/' + icon" />
      </li>
    </ul>
    <app-spinner *ngIf="(loadingLot$ | async)"></app-spinner>
  </ng-template>
</p-overlayPanel>
<div id="frirogene-modal"
  class="modal fade frirogene-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-container">
      <p class="modal-title">Choisir le fluide frigorigène correspondant :</p>
      <div class="detail-container scroller-1">
        <ul *ngFor="let item of frirogeneResult;let i=index" [ngClass]="{'selected-friro': selectedFriroi==i}">
          <li (click)="selectFriro(i)">{{item.nom}}</li>
        </ul>
      </div>
      <div class="button-container" style="justify-content: left;">
        <button class="btn btn-flat" (click)='validateFriro()' data-dismiss="modal">Valider le nouveau fluide frigorigène</button>
      </div>
    </div>
  </div>
</div>
<div id="post-info-modal"
  class="modal fade post-info-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-container">
      <div class="choice-container">
        <div class="choice" (click)="onSelectionChangeTab('resume')"  [ngClass]="{'selectd-choice' : postInfoTab === 'resume'}">
          Résumé
        </div>
        <div class="choice" (click)="onSelectionChangeTab('detail')" [ngClass]="{'selectd-choice' : postInfoTab === 'detail'}">
          Détail
        </div>
      </div>
      <div class="margin-top-12" style="width: 80%;">
        <!-- <div class="rating-container">
          <img src="/assets/archi/leaf.svg"/>
          <p class="rating">B</p>
          <p>Faible Impact</p>
        </div> -->
        <p class="post-title no-margin">{{infoPost?.nom}}</p>
        <p class="post-title">ID : {{infoPost?.idFicheBase}}</p>
        <p class="sub-title">{{infoPost?.categoryName}}</p>
      </div>
      <div class="resume-post" *ngIf="postInfoTab=='resume'">
        <div *ngIf="(infoPost||{}).declarationType"><p class="mini-title">Type de déclaration :</p> {{infoPost?.declarationType}}<br></div>
        <div *ngIf="(infoPost||{}).organismeName"><p class="mini-title">Organisme déclarant :</p> {{infoPost?.organismeName}}<br></div>
        <div *ngIf="(infoPost||{}).descriptionUF"><p class="mini-title">Unité fonctionnelle : <span class="span-black">{{infoPost?.descriptionUF||'Aucun'}}</span></p><br></div>
        <div *ngIf="(infoPost||{}).productionPlace"><p class="mini-title">Lieu de production  :</p> {{infoPost?.productionPlace||'Aucun'}}<br></div>
        <div *ngIf="(infoPost||{}).performanceUFUnite"><p class="mini-title">Performance principale de l'UF :</p> {{infoPost?.performanceUFQuantite||0}} {{infoPost?.performanceUFUnite||''}}<br><br></div>
        <a style="color: #0275d8 !important" target="_blank" [href]='"https://www.base-inies.fr/iniesV4/dist/consultation.html?id=" + (infoPost||{}).idFicheBase'>Voir la fiche</a> <i class="fa fa-external-link" style="color: #0275d8 !important;margin-left: 3px;"></i>
      </div>
      <div class="detail-post" *ngIf="postInfoTab=='detail'">
        <div class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Informations générales</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <div *ngIf="(infoPost||{}).nbrReferencesCommerciales"><p class="mini-title">Nombre de références commerciales couvertes :</p> {{infoPost?.nbrReferencesCommerciales||0}}<br></div>
            <div *ngIf="(infoPost||{}).declarationType"><p class="mini-title">Type de déclaration :</p> {{infoPost?.declarationType||1}}<br></div>
            <div *ngIf="(infoPost||{}).dateEdition"><p class="mini-title">Date d'édition de la déclaration :</p> {{infoPost?.dateEdition| date: 'dd/MM/yyyy'}}<br></div>
            <div *ngIf="(infoPost||{}).dateMiseAJour"><p class="mini-title">Date de dernière mise à jour :</p> {{infoPost?.dateMiseAJour| date: 'dd/MM/yyyy'}}<br></div>
            <div *ngIf="(infoPost||{}).dateVerification"><p class="mini-title">Date de vérification :</p> {{infoPost?.dateVerification| date: 'dd/MM/yyyy'}}<br></div>
            <div *ngIf="(infoPost||{}).Version"><p class="mini-title">Version :</p> {{infoPost?.Version||'Aucun'}}<br></div>
            <div *ngIf="(infoPost||{}).dateEdition"><p class="mini-title">Date de fin de validité :</p> {{infoPost?.dateEdition||'Aucun'}}<br></div>
            <div *ngIf="(infoPost||{}).referencesCommerciales"><p class="mini-title">Références commerciales couvertes et fabricants :</p> {{infoPost?.referencesCommerciales||'Aucun'}}<br></div>
            <div *ngIf="(infoPost||{}).aptitudeUsage"><p class="mini-title">Preuves d'aptitude à l'usage :</p> {{infoPost?.aptitudeUsage||'Aucun'}}<br></div>
          </div>
        </div>
        <div class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Unités fonctionnelles</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <div *ngIf="(infoPost||{}).descriptionUF"><p class="mini-title">Unité fonctionnelle :<span class="span-black">{{infoPost?.descriptionUF||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost||{}).performanceUF"><p class="mini-title">Performance principale de l'UF :</p> {{infoPost?.performanceUF||'Aucun'}} {{infoPost?.performanceUF?':':''}} {{infoPost?.performanceUFQuantite}} {{infoPost?.performanceUFUnite}}<br></div>
            <div *ngIf="(infoPost||{}).quantiteUF"><p class="mini-title">Quantité :</p> {{infoPost?.quantiteUF||'Aucun'}} {{unitsCodes[infoPost?.uniteUF]}}<br></div>
            <div *ngIf="(infoPost||{}).dvt"><p class="mini-title">Durée de vie de référence (DVR) :</p> {{infoPost?.dvt}} ans<br></div>
            <div *ngIf="(infoPost||{}).infosComplementaires"><p class="mini-title">Caractéristiques non contenues dans l'U.F. :<span class="span-black"> {{infoPost?.infosComplementaires||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost||{}).tauxChute"><p class="mini-title">Taux de chute à l'étape du processus de construction :</p> {{infoPost?.tauxChute||0}}%<br></div>
            <div *ngIf="(infoPost||{}).frequenceEntretien"><p class="mini-title">Fréquence d'entretien :</p> {{infoPost?.frequenceEntretien+1||'Aucun'}} ans<br></div>
            <div *ngIf="(infoPost||{}).declarationContenu"><p class="mini-title">Déclaration de contenu :<span class="span-black">{{infoPost?.declarationContenu||'Aucun'}}</span></p><br></div>
          </div>
        </div>
        <div class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Indicateurs</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <div *ngFor="let indicateur of infoPost.ind">
              <p class="green-title">{{indicateur.name}}</p>
              <div class="flex" style="padding: 0px 0px 6px 0px;">
                <p style="font-weight: bold;">Indicateurs</p>
                <p class="float-right">Total cycle de vie</p>
              </div>
              <div *ngFor="let item of indicateur.data | keyvalue; let i=index">
                <div class="flex" style="padding: 5px 0px;" [ngStyle]="{'background': i%2==0?'#DFF8EC':'none'}">
                  {{item.key}}
                  <p class="float-right">{{(item.value||0) | formatPrice}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Santé</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <p class="green-title">Air intérieur</p>
            <div *ngIf="(infoPost.sante||{}).airRating"><p class="mini-title">Arrêté du 19 avril 2011 relatif à l'étiquetage des produits de construction ou de revêtement de mur ou de sol et des peintures et vernis sur leurs émissions de polluants volatils : <span class="span-black">{{infoPost?.sante?.airRating||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).eCovFormaldehyde"><p class="mini-title">Autres émissions de polluants volatils dans l'air intérieur hors etiquette réglementaire :<span class="span-black">{{infoPost?.sante?.eCovFormaldehyde||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).eRadioactive"><p class="mini-title">Émissions radioactives naturelles : <span class="span-black">{{infoPost?.sante?.eRadioactive||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).otherHealthInfo"><p class="mini-title">Autres informations sur la qualité sanitaire des espaces intérieurs : <span class="span-black">{{infoPost?.sante?.otherHealthInfo||'Aucun'}}</span></p><br></div>
            <p class="green-title">Sol et eau</p>
            <div *ngIf="(infoPost.sante||{}).infosDrinkingWater"><p class="mini-title">Le produit est-il en contact avec de l'eau destinée à la consommation humaine ? <span class="span-black">{{infoPost?.sante?.infosDrinkingWater||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).isContactNotDrinkingWater"><p class="mini-title">Le produit est-il en contact avec les eaux de ruissellement, les eaux d'infiltration, les eaux de surface ou les eaux de la nappe phréatique ? <span class="span-black">{{infoPost?.sante?.isContactNotDrinkingWater||'Aucun'}}</span></p><br></div>
            <div *ngIf="(infoPost.sante||{}).infosNotDrinkingWater"><p class="mini-title">Informations sur la qualité des eaux de ruissellement, des eaux d'infiltration, des eaux de surface ou des eaux de la nappe phréatique <span class="span-black">{{infoPost?.sante?.infosNotDrinkingWater||'Aucun'}}</span></p><br></div>
          </div>
        </div>
        <div *ngIf="(infoPost||{}).confort.comfortHygrothermal || infoPost.confort.comfortAcoustic || infoPost.confort.comfortVisual || infoPost.confort.comfortOlfactory || infoPost.confort.otherComfortInfos" class="single-detail">
          <div class="one-detail" (click)="expandDetail($event)">
            <span>Confort</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow hidden-item">
          </div>
          <div class="detail-description">
            <p *ngIf="(infoPost||{}).confort.comfortHygrothermal" class="green-title1">Confort hygrothermique</p><span class="span-black">{{infoPost?.confort?.comfortHygrothermal||'Aucun'}}</span><br>
            <p *ngIf="(infoPost||{}).confort.comfortAcoustic" class="green-title1">Confort acoustique</p><span class="span-black"> {{infoPost?.confort?.comfortAcoustic||'Aucun'}}</span><br>
            <p *ngIf="(infoPost||{}).confort.comfortVisual" class="green-title1">Confort visuel</p><span class="span-black">{{infoPost?.confort?.comfortVisual||'Aucun'}}</span><br>
            <p *ngIf="(infoPost||{}).confort.comfortOlfactory" class="green-title1">Confort olfactif</p><span class="span-black"> {{infoPost?.confort?.comfortOlfactory||'Aucun'}}</span><br>
            <p *ngIf="(infoPost||{}).confort.otherComfortInfos" class="green-title1">Autres informations sur le confort</p><span class="span-black">{{infoPost?.confort?.otherComfortInfos||'Aucun'}}</span><br>
          </div>
        </div>
      </div>
      <div class="margin-top-12 add-post-detail" *ngIf="detailModalFromSideBar" style="width: 100%;">
        <button class="btn btn-cards" style="background-color: #5A9E6F;width: 304px;" (click)="openConfirmationPost(infoPost)"  [ngClass]="{'disabled':!sendChiffrageButton}" [disabled]="!sendChiffrageButton">Ajouter la fiche</button>
      </div>
    </div>
  </div>
</div>

<div id="choose-demand-modal"
  class="modal fade choose-demand-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog no-padding">
    <div class="modal-container no-padding">
      <!-- <p class="upgrade-title">{{lot?.title}} > {{cctpEdition?.cctpEdit?.postDesignation}}</p> -->
      <p class="upgrade-title">Vous ne trouvez pas votre fiche ?</p>
      <p class="upgrade-sub-title">Il est possible que votre fiche n'existe pas encore dans la base de donnée. Souhaitez-vous faire la demande création ?</p>
      <div class="button-container">
        <button class="btn btn-forms" data-dismiss="modal" (click)="demandePost('Update')">Une demande existe déjà</button>
        <button class="btn btn-forms" style="margin-left: 24px;" data-dismiss="modal" (click)="demandePost('Creation')">Faire une demande</button>
      </div>
    </div>
  </div>
</div>
<div id="add-demand-modal"
  class="modal fade add-demand-modal"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog no-padding">
    <div class="modal-container no-padding">
      <div class="row justify-content-center main-container scroller-1 col-md-12">
        <form class="form-container1">
          <p *ngIf="demandeData.nature=='Creation'" class="text-center title">Demande de création de composant</p>
          <p *ngIf="demandeData.nature=='Update'" class="text-center title">Demande de composant déjà existant</p>
          <p *ngIf="demandeData.nature=='Reemploi'" class="text-center title">Votre composant réemploi</p>
            <div *ngIf="!currentUser.idDemandeur && demandeData.nature!='Reemploi'">
              <p class="sub-title" style="margin-bottom: 3px;">Vos informations</p>
              <p class="info">Vos informations vous seront demandées une seule fois pour l'ajout d'un composant.</p>
              <div class="form-row">
                <div class="col-md-6 form-group">
                  <label class="required-star" for="first-name">Prénom</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.prenom" name="first-name" placeholder="Prénom">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="last-name">Nom</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.nom" name="last-name" placeholder="Nom">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="email">Email</label>
                  <input class="input-demand" type="email" [(ngModel)]="demandeData.mail" name="email" placeholder="architecte@gmail.com">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="phone_number">Numéro de téléphone</label>
                  <input class="input-demand" type="tel" [(ngModel)]="demandeData.telephone" name="phone_number" placeholder="06 09 06 09 06">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="job">Profession</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.metier" name="job" placeholder="Architecte">
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="company">Entreprise</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.entreprise" name="company" placeholder="Bam Architecture">
                </div>
              </div>
            </div>
            <p class="sub-title">Les informations sur le composant</p>
            <!-- <p>Ces informations vous sont demandées afin de faire la demande de création de composant.</p> -->
            <div *ngIf="demandeData.nature=='Creation'">
              <div class="form-row">
                <div class="col-md-12 form-group">
                  <label class="required-star" for="ded-name">Intitulé de la donnée environnementale par défaut proposée</label>
                  <input class="input-demand" type="text" [(ngModel)]="demandeData.content.creationLabel" name="ded-name" placeholder="">
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 form-group">
                  <label class="required-star" for="quantity">Quantité à modéliser</label>
                  <input class="input-demand" type="number" [(ngModel)]="demandeData.quantity" name="quantity" placeholder="1">
                </div>
                <div class="col-md-4 form-group">
                  <label class="required-star" for="UF">Unité de l'unité fonctionnelle</label>
                  <select class="input-demand" name="UF" [(ngModel)]="demandeData.unity">
                    <option *ngFor="let unit of unitsCodes | keyvalue">{{unit.value}}</option>
                  </select>
                </div>
                <div class="col-md-6 form-group">
                  <label class="required-star" for="product_life">Durée de vie estimée</label>
                  <input class="input-demand" type="number" [(ngModel)]="demandeData.dve" name="product_life" placeholder="50 ans">
                </div>
                <div class="col-md-12 form-group">
                  <label class="required-star" for="argumentation">Argumentaire(s)/ Justification(s) de la demande</label>
                  <textarea class="input-demand no-resize" style="height: 100px;" [(ngModel)]="demandeData.content.creationArgument" name="argumentation" rows="4" placeholder="Expliciter au maximum votre constat et votre demande "></textarea>
                </div>
                <div class="col-md-12 form-group">
                  <label class="required-star" for="argumentation">Identification du produit de référence servant à construire la donnée environnementale par défaut et dimensionnement du produit</label>
                  <textarea class="input-demand no-resize" style="height:75px" [(ngModel)]="demandeData.content.creationIdentification" name="argumentation" rows="4" placeholder="Produit de référence à utiliser pour contruire la donnée environnementale par défaut"></textarea>
                </div>
                <div class="col-md-12 form-group">
                  <label for="argumentation">Identification de l'échantillon/donnée source pour établir le calcul</label>
                  <textarea class="input-demand no-resize" style="height:75px" [(ngModel)]="demandeData.content.creationSource" name="argumentation" rows="4" placeholder="Echantillon source à utiliser pour établir le calcul"></textarea>
                </div>
                <div class="col-md-12 form-group">
                  <label for="UF">Famille de de produit</label>
                  <select class="input-demand" name="CR" [(ngModel)]="demandeData.content.creationRank.creationRankId">
                    <option *ngFor="let item of iniesCategories" [value]="item.id"><img [src]="'assets/dpgf/Icon_DPGF/'+item.icon"/>{{item.title}}</option>
                  </select>
                </div>
                <div [hidden]="demandeData.content.creationRank.creationRankId!=0">
                  <div class="col-md-12">
                    <p class="sub-title1">Votre famille de produit n'est pas dans liste.</p>
                  </div>
                  <div class="col-md-12 form-group">
                    <label for="product_life">Renseigner votre nom de famille de produit</label>
                    <input class="input-demand" type="text" [(ngModel)]="demandeData.content.creationRank.creationRankName" name="nom-famille">
                  </div>
                  <div class="col-md-12 form-group">
                    <label for="product_life">Dans quelle catégorie votre famille de produit se trouve-t-elle ?</label>
                    <select class="input-demand" name="UF" [(ngModel)]="demandeData.content.creationRank.creationRankParent">
                      <option *ngFor="let item of iniesCategories" [value]="item.id"><img [src]="'assets/dpgf/Icon_DPGF/'+item.icon"/>{{item.title}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div style="position: relative;" *ngIf="demandeData.nature=='Update'">
              <div class="col-md-6 form-group">
                <label class="required-star">Numéro de la demande Mlab</label>
                <input id="id-input" class="input-demand" type="text" autocomplete="off" [(ngModel)]="demandeData.id" (focus)="getDemendSugg()" name="num-mlab">
                <div id="myInputautocomplete-list" class="autocomplete-items-demand">
                  <div style="overflow: auto;height: 156px;" class="scroller-1">
                    <div *ngFor="let result of demandesSugg;let i = index" (mousedown)="setChosenDemand(result)" class="unit-proposition">
                      <div class="flex">
                        <p style="font-weight: 900;">{{result.idDemand}} - {{result.name}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label class="required-star">Intitulé de la donnée environnementale</label>
                <input class="input-demand" type="text" [(ngModel)]="demandeData.content.creationLabel" name="creationLabel">
              </div>
              <div class="col-md-6 form-group">
                <label class="required-star">Durée de vie estimée</label>
                <input class="input-demand" type="number" [(ngModel)]="demandeData.dve" name="dve">
              </div>
              <div class="col-md-6 form-group">
                <label class="required-star">Quantité à modéliser</label>
                <input class="input-demand" type="number" [(ngModel)]="demandeData.quantity" name="quantity">
              </div>
              <div class="col-md-5 form-group">
                <label class="required-star">Unité de l'unité fonctionnelle</label>
                <select class="input-demand" name="UF" [(ngModel)]="demandeData.unity">
                  <option *ngFor="let unit of unitsCodes | keyvalue">{{unit.value}}</option>
                </select>
              </div>
            </div>
            <div *ngIf="demandeData.nature=='Reemploi'">
              <div class="col-md-6 form-group">
                <label>Nom du composant</label>
                <input class="input-demand" type="text" [(ngModel)]="demandeData.content.creationLabel" name="num-mlab">
              </div>
              <div class="col-md-6 form-group">
                <label>Durée de vie estimée</label>
                <input class="input-demand" type="number" [(ngModel)]="demandeData.dve" name="dve">
              </div>
              <div class="col-md-6 form-group">
                <label>Quantité à modéliser</label>
                <input class="input-demand" type="number" [(ngModel)]="demandeData.quantity" name="quantity">
              </div>
              <div class="col-md-5 form-group" style="margin-bottom: 30px;">
                <label>Unité de l'unité fonctionnelle</label>
                <select class="input-demand scroller-1" name="UF" [(ngModel)]="demandeData.unity">
                  <option *ngFor="let unit of unitsCodes | keyvalue">{{unit.value}}</option>
                </select>
              </div>
            </div>
            <div *ngIf="demandeData.nature!='Reemploi'" class="terms-container col-md-12 ">
              <input class="checkbox-box" type="checkbox" [checked]="demandeData.terms" name="flexCheckIndeterminate" [(ngModel)]="demandeData.terms">
              <label for="flexCheckIndeterminate" style="margin-top: 20px;padding-left: 17px;">
                Je consens à la politique de collecte et protection des données personnelles de MLab
              </label>
            </div>
            <div class="btn-form col-md-12">
              <button *ngIf="demandeData.nature=='Creation'" type="submit" class="btn btn-forms" (click)="submitDemande()" [disabled]="!((currentUser.idDemandeur && demandeData.terms && demandeData.content.creationLabel && demandeData.quantity && demandeData.unity && demandeData.dve && (demandeData.content.creationRank.creationRankId || (demandeData.content.creationRank.creationRankName && demandeData.content.creationRank.creationRankParent )) && demandeData.content.creationArgument && demandeData.content.creationIdentification) || (!currentUser.idDemandeur && demandeData.nom && demandeData.prenom && demandeData.mail && demandeData.telephone && demandeData.metier && demandeData.entreprise && demandeData.content.creationLabel && demandeData.quantity && demandeData.unity && demandeData.content.creationArgument && demandeData.content.creationIdentification && demandeData.terms))">
                <p *ngIf="!loadingDemand" class="no-margin">Envoyer ma demande de création de fiche INIES</p>
                <app-spinner *ngIf="loadingDemand"></app-spinner>
              </button>
              <button *ngIf="demandeData.nature=='Update'" type="submit" class="btn btn-forms" (click)="addPostExistDemand()" [disabled]="!((currentUser.idDemandeur && demandeData.id && demandeData.terms) || (!currentUser.idDemandeur && demandeData.nom && demandeData.prenom && demandeData.mail && demandeData.telephone && demandeData.metier && demandeData.entreprise && demandeData.id && demandeData.terms))">
                <p *ngIf="!loadingDemand" class="no-margin">Renseigner ma demande de création de fiche</p>
                <app-spinner *ngIf="loadingDemand"></app-spinner>
              </button>
              <button *ngIf="demandeData.nature=='Reemploi'" type="submit" class="btn btn-forms" (click)="addPostIniesReemploi()">Ajouter mon composant réemploi</button>
            </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div id="add-post-modal" class="modal fade add-post-modal" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <!-- <div class="modal-header"> -->
        <h3 class="modal-title modal-header-title">{{lot?.title}} > {{cctpEdition?.cctpEdit?.postDesignation}}</h3>
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      <!-- </div> -->
      <!-- <div class="modal-body"> -->
        <div class="flex margin-top-24">
          <img src="/assets/archi/correct-circle.svg" class="emoji">
          <p class="description">
            La fiche <b>{{nameChosenPost}}</b> va être ajoutée au poste <b>{{cctpEdition?.cctpEdit?.postDesignation}}</b>
          </p>
        </div>
      <!-- </div> -->
      <div class="margin-top-24 flex align-centre" style="height: 37px;justify-content: center;">
        <button type="button" class="btn btn-grey" data-dismiss="modal">Annuler l'ajout</button>
        <button type="button" class="btn btn-forms margin-left-24" (click)="addFileToPost()">
          <span *ngIf="!addingPostInies">Continuer</span>
          <app-spinner *ngIf="addingPostInies" style="position: relative;bottom: 10px;"></app-spinner>
        </button>
        
      </div>
    </div>
  </div>
</div>

<div id="import-files-modal" class="modal fade add-post-modal" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
        <h3 class="modal-title modal-header-title" style="text-align: center;">Votre fiche configurée </h3>
        <div class="margin-top-24">
          <!-- <p class="description"> -->
            Les fiches configurées peuvent être réalisées sur les sites homologués suivants :
            <div style="padding-left: 10px;">
              <div style="display: flex;height: 24px;margin-top: 2px;">&bull;&nbsp; <a class="one-link" href="https://www.snbpe.org/index.php/developpement_durable/calculette" target="_blank">BETIE  <i class="fa fa-external-link"></i></a></div>              
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://www.save-construction.com/" target="_blank">Solutions Acier Valeurs environnementales  <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://de-bois.fr/fr" target="_blank">DE-BOIS  <i class="fa fa-external-link"></i></a></div>
              <!-- <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">AKacia  <i class="fa fa-external-link"></i></a></div> -->
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="http://www.environnement-ib.com/login" target="_blank">Environnement IB  <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://www.de-boisdefrance.fr/" target="_blank">DE - Bois De France   <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">Fichier extrapolation PEP </a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://calculateurco2.ciments-hoffmann.fr/" target="_blank">Hoffmann Green Cement Technologies   <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://www.bankiz-fdes.fr/app/" target="_blank">BANKIZ  <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">VICAT </a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">VINCI Construction </a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link" href="https://www.de-baie.fr/" target="_blank">DE-Baie   <i class="fa fa-external-link"></i></a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">3B </a></div>
              <div style="display: flex;height: 24px;">&bull;&nbsp;<a class="one-link">BSM </a></div>
            </div>
            <p class="margin-top-12">Elles permettent de modéliser des composants qui ne seraient pas présents dans la base INIES</p>
        </div>
      <div class="margin-top-24 flex align-centre" style="height: 37px;justify-content: center;">
        <button type="button" class="btn btn-forms" (click)="fileInput.click()">
          <input 
            #fileInput
            id="file" 
            type="file" 
            class="form-control"
            style="display:none;"
            (change)="importXml($event)">
          <span *ngIf="!addingPostInies">Importer ma fiche configurée</span>
          <app-spinner *ngIf="addingPostInies" style="position: relative;bottom: 10px;"></app-spinner>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Scrapped Product Modals -->
<div id="confirm-add-product-modal" class="modal fade" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title modal-header-title">Ajout de produit</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="modal-header-description">
          Le produit <b>{{ selectedProduct?.title }} {{ selectedProduct?.ref }}</b>
          vient d'être ajouté avec succès au poste <b> {{ choosenPost?.lot.indexLot }}.{{ choosenPost?.sousloti }}.{{ choosenPost?.posti }} - {{ choosenPost?.postDesignation }}</b>
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-grey" data-dismiss="modal">Annuler l'ajout</button>
        <button type="button" class="btn btn-forms" (click)="saveProduct()">Continuer</button>
      </div>
    </div>
  </div>
</div>



<div id="confirm-add-product-modal-NEW" class="modal fade" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Information sur le produit</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="thumbnail-wrapper">
          <div class="thumbnail-image flex-middle">
            <img [src]="selectedProduct?.imagesLinks[0]" *ngIf="selectedProduct?.imagesLinks.length; else image_placeholder" />
            <ng-template #image_placeholder>
              <img src="https://via.placeholder.com/350"/>
            </ng-template>
          </div>
          <!-- <div class="images-slider">
            <img *ngFor="let singleImage of selectedProduct?.imagesLinks" [src]="singleImage"/>
          </div> -->
        </div>

        <div class="product-info-wrapper">
          <h4>{{ selectedProduct?.title }}</h4>
          <p class="product-info-item">
            <span class="prod-label">Site: </span>
            <span class="value">{{ selectedProduct?.source }}</span>
          </p>
          <p class="product-info-item">
            <span class="prod-label">Ref: </span>
            <span class="value">{{ selectedProduct?.ref }}</span>
          </p>
          <p class="product-info-item">
            <span class="prod-label">Prix: </span>
            <span class="value">{{ selectedProduct?.price[selectedProduct?.source] }} €</span>
          </p>
        </div>

        <!-- <p>vous souhaitez ajouter ce produit?</p> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-flat" (click)="saveProduct()">Add to My List</button>
        <button type="button" class="btn btn-flat" (click)="openProductUrl()">Buy</button>
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
      </div>
    </div>
  </div>
</div>

<div id="confirm-remove-product-modal" class="modal fade" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content card-style-1">
      <div class="modal-header">
        <h3 class="modal-title modal-header-title">Suppression de produit</h3>
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <div class="modal-body">
        <p class="modal-header-description">Êtes-vous sûr(e) de vouloir supprimer le produit ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-grey" data-dismiss="modal">Annuler</button>
        <button type="button" class="btn btn-forms" (click)="removeProduct()">Valider</button>
      </div>
    </div>
  </div>
</div>
<!-- *ngIf="isRevitDesktop"  -->
<div *ngIf="isRevitDesktop" class="round-button" (click)="openRevitOrbarescenceProject($event)"><img src="assets/file_revit.png" height="30px" width="30px" class="revit-icon"></div>
<app-revit-project-arborescence *ngIf="openProjectArborescence" [openProjectArborescence]="openProjectArborescence" (changeOpenProjectArborescence)="changeOpenProjectArborescenceValue($event)"></app-revit-project-arborescence>
<app-modal-consult-entreprise [ceProjectId]="dpgf.ceProject" [dpgf]="dpgf"></app-modal-consult-entreprise>

<!-- END Scrapped Product Modals -->


<!-- Need Subscription Modal -->
<app-user-on-without-license-modal
></app-user-on-without-license-modal>
<!-- END Need Subscription Modal -->

<app-need-subscription-popup-2></app-need-subscription-popup-2>

<!-- Max Dpgf & Library Posts Modal -->
<app-max-library-posts-modal [showLibraryText]="maxLibraryPostsModalshowLibraryText"></app-max-library-posts-modal>
<!-- END Max Dpgf & Library Posts Modal -->
