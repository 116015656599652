import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indice',
  standalone: true
})
export class IndicePipe implements PipeTransform {

  transform(number: number, ...args: unknown[]): unknown {
    var n = number-1;
    var ordA = 'a'.charCodeAt(0);
    var ordZ = 'z'.charCodeAt(0);
    var len = ordZ - ordA + 1;
    var s = "";
    while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s.toUpperCase();
  }

}
