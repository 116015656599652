import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { AuthService } from 'src/app/_services';
import { NgSharedModule } from '../ngshared.module';
declare var $ : any;

@Component({
  standalone:true,
  selector: 'app-archi-expired-modal',
  imports:[NgSharedModule],
  templateUrl: './archi-expired-modal.component.html',
  styleUrls: ['./archi-expired-modal.component.scss']
})
export class ArchiExpiredModalComponent implements OnInit {
  
  public expiredLicence= (JSON.parse(localStorage.getItem("expiredLicence")) || {})
  public currentUser = (JSON.parse(localStorage.getItem("currentUser"))||{})

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
    if(!this.expiredLicence.isChecked || this.expiredLicence.userId!=this.currentUser.id){
      this.authService.getUserLicenceStripeUser()
      .subscribe((res)=>{
        if(res.data.licence=='without') {
          // let dateEndTrial=new Date(res.data.dateEndTrial);
          // let newDate=new Date();
          // let daysDiffLicence= Math.floor((dateEndTrial.getTime() - newDate.getTime()) / (1000*60*60*24))
          // if(daysDiffLicence==0) $('#expired-licence').modal('show');
          $('#expired-licence').modal('show');
          let storage={"isChecked":true,"userId":this.currentUser.id}
          localStorage.setItem("expiredLicence",JSON.stringify(storage));
      }})
    }
  }

}
