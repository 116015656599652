import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  standalone:true,
  name: 'formatNumberPrice'
})
export class FormatNumberPricePipe implements PipeTransform {

  transform(value: number): string {
    let decimalPart = value.toString().split('.')[1] || '';
    let num = value.toString().split('.')[0];
    let formattedNum = '';
    for (let i = num.length - 1, j = 1; i >= 0; i--, j++) {
      formattedNum = num[i] + formattedNum;
      if (j % 3 === 0 && i > 0) {
        formattedNum = ' ' + formattedNum;
      }
    }
    /// reduce decimal length to 2 if needed
    if(decimalPart.length > 2){
      decimalPart = decimalPart.substring(0, 2);
    }

    return formattedNum + (decimalPart ? ',' + decimalPart : '');
  }
}
