<div class="col-md-12" data-step="6" data-intro="Ceci est un Lot">
  <!--********************Lot Head Pill ***********************-->
    <div class="row">
      <div class="pill-col-lot">
        <button class="collapse-btn" data-toggle="collapse" [attr.data-target]="'#demo' + lotIndex">
          <i class="fa fa-caret-right" aria-hidden="true"></i>
        </button>
        <div class="keyword-wrapper">            
          <span class="title-lot">
                <img [src]="lot.icon !='' ? '/assets/dpgf/Icon_DPGF/'+lot.icon : '/assets/rob/helmet.png'" alt="" height="20">
                {{lotIndex}} -
                {{lot.title}}
          </span>
        </div>
      </div>
      <div class="total-col-lot">
        <table class="total-table">
          <tr>
            <td class="total-title">Avoir HT</td>
            <td class="total-title">Montant Total HT</td>
            <td class="total-title" *ngIf="!isFirst">Avancement préc.</td>
            <td class="total-title">Avancement actuel</td>
            <td class="total-title">Situation HT</td>
          </tr>
          <tr>
            <td class="total-td">
              <app-unite-input-dpgf [disable]="modeList[mode].chantier"
                                [unit]="lot.avoir"
                                placeHolder=""
                                [style]="{'font-size' : '18px','font-weight': 700, 'color': 'green'}"
                                (changeText)="lot.avoir=converteNumber($event)"
                                tooltip="Champs bloqué"
                                sign="€"
                                typeInput="number"></app-unite-input-dpgf>
            </td>
            <td class="total-td">
              <app-unite-input-dpgf [disable]="modeList[mode].chantier"
                                [unit]="lot.total"
                                placeHolder=""
                                [style]="{'font-size' : '18px','font-weight': 700}"
                                (changeText)="lot.total=converteNumber($event)"
                                tooltip="Champs bloqué"
                                sign="€"
                                typeInput="number"></app-unite-input-dpgf>
            </td>
            <td class="total-td" *ngIf="!isFirst">
              <app-unite-input-dpgf
                                [disable]="modeList[mode].chantier"
                                [ngClass]="{'green-text': lot.lastAcompte == 100}"
                                [unit]="lot.lastAcompte"
                                placeHolder="lastAcompte"
                                [style]="{'font-size' : '18px','font-weight': 700}"
                                tooltip="Champs bloqué"
                                sign="%"
                                typeInput="number"></app-unite-input-dpgf>
            </td>
            <td class="total-td" (focusout)="calculateLotAcompte(loti)" [ngClass]="{'red-back': status!='submited' && response!='accepted' &&
            modeList[mode].acompte && lot.acompteAll && !lot.acompte, 'depasser': lot.devis+lot.lastDevis > lot.total}">
              <app-unite-input-dpgf
                                [disable]="!lot.acompteAll || !lot.total || !modeList[mode].acompte || 
                                status=='submited' || response=='accepted'"
                                [unit]="lot.acompte"
                                placeHolder="lotAcompte"
                                [style]="{'font-size' : '18px','font-weight': 700}"
                                (changeText)="lot.acompte=converteNumber($event)"
                                tooltip="Champs bloqué"
                                sign="%"
                                typeInput="number"></app-unite-input-dpgf>
            </td>
            <td class="total-td" (focusout)="calculateLotDevis(loti)" [ngClass]="{'red-back': status!='submited' && response!='accepted' &&
            modeList[mode].acompte && lot.acompteAll && !lot.acompte, 'depasser': lot.devis+lot.lastDevis > lot.total}">
              <app-unite-input-dpgf
                                [disable]="!lot.acompteAll || !lot.total || !modeList[mode].acompte || 
                                (status=='submited' || response=='accepted')"
                                [unit]="lot.devis"
                                placeHolder="lotDevis"
                                [style]="{'font-size' : '18px','font-weight': 700}"
                                (changeText)="lot.devis=converteNumber($event)"
                                tooltip="Champs bloqué"
                                sign="€"
                                typeInput="number"></app-unite-input-dpgf>
            </td>
          </tr>
          <tr *ngIf="(modeList[mode].acompte && !lot.priceAll) && (status!='submited' && response!='accepted')">
            <td class="total-td"></td>
            <td class="total-td"></td>
            <td class="total-td" *ngIf="!isFirst"></td>
            <td class="total-td" colspan="2">
              <input type="checkbox" [checked]="lot.acompteAll" (change)="toggleGlobalFacturation(loti)"/> Facturer ce lot dans sa globalité
            </td>
          </tr>
        </table>
      </div>

    <!--********************Lot Description ***********************-->
    <div [id]="'demo'+lotIndex" class="collapse in">
      
      <!--********************Sous Lot Pill ***********************-->
        <div class="sous-lot row" [ngClass]="{'fixed-sous-lot' : sousLot.fixed}" *ngFor="let sousLot of lot.sousLots,let sousloti = index" data-step="7" data-intro="Ceci est un Sous Lot">
        <div class="row" *ngIf="!sousLot.fixed">
          <div class="pill-col">
            <span class="title-lot">
              - {{sousLot.title}}
            </span>
          </div>
          <div class="total-col">
            <div class="total center">{{sousLot.total | number}} €</div>
          </div>
        </div>
        <table class="marged-table">
          <!--********************Table***********************-->
          <tr *ngIf="sousLot.fixed">
            <td class="title"></td>
            <td class="title des-col">Désignation du poste</td>
            <td class="title poste-col">Montant HT</td>
            <td class="title poste-col" *ngIf="!isFirst">Avancement préc.</td>
            <td class="title poste-col">Avancement actuel</td>
            <td class="title poste-col">Situation HT</td>
            <td class="title"></td>
          </tr>
          <tbody >
            <tr *ngFor="let post of sousLot.postes; let posti = index">
              <td colspan="9">
                <table>
                  <tbody>
                    <tr [ngClass]="{'new-back': post.new, 'post-strike': post.deleted}" [id]="post.id" class="post-line">
                      <td class="relative handle">
                        {{lineNumber(lotIndex,sousloti,posti)}}
                      </td>
                      <td class="relative des-col" style="width: 40%">
                        <app-input-dpgf #inputs
                                        [ngStyle]="{'color': post.isAtisan?'red':'black'}"
                                        [disable]="!post.new || (post.new && post.lastDevis || response == 'accepted' || status == 'submited')"
                                        [textContent]="post.designation"
                                        (focusedOut)="updateDesignation(loti,sousloti,posti,$event)"
                                        [oldValue]="post.oldDesignation"
                                        [prefix]="lineNumber(lot.indexLot,sousloti,posti)"
                                        [tags]="lot.title"
                                        [attr.id]="'designation' + posti"></app-input-dpgf>
                      </td>
                      <td class="poste-col" (focusout)="calculatePrice(loti)"
                      id="total-price">
                        <app-unite-input-dpgf #unitInputs
                        [disable]="!post.new || (post.new && post.lastDevis || response == 'accepted' || status == 'submited')"
                        [unit]="post.price"
                        placeHolder=""
                        (changeText)="post.price=converteNumber($event)"
                        typeInput="number"
                        sign="€"
                        tooltip="Champs bloqué"></app-unite-input-dpgf>
                      </td>
                      <td class="poste-col" *ngIf="!isFirst">
                        <app-unite-input-dpgf #unitInputs
                        [ngClass]="{'green-text': post.lastAcompte == 100}"
                        [disable]="modeList[mode].chantier"
                        [unit]="post.lastAcompte"
                        placeHolder="Acompte"
                        (changeText)="post.acompte=converteNumber($event)"
                        typeInput="number"
                        sign="%"
                        tooltip="Champs bloqué"
                        >
                      </app-unite-input-dpgf>
                      </td>
                      <td class="poste-col" (focusout)="calculatePosteAcompte(loti, sousloti, posti)" [ngClass]="{'red-back': status!='submited' && response!='accepted' && 
                      modeList[mode].acompte && !lot.acompteAll && !post.acompte && !post.new,
                      'depasser': post.devis+post.lastDevis  > post.price}">
                        <app-unite-input-dpgf #unitInputs
                        [disable]="!modeList[mode].acompte || lot.acompteAll || status=='submited' 
                        || response=='accepted' || !post.price || post.deleted" 
                        [unit]="post.acompte"
                        placeHolder="Acompte"
                        (changeText)="post.acompte=converteNumber($event)"
                        typeInput="number"
                        sign="%"
                        tooltip="Champs bloqué"
                        >
                      </app-unite-input-dpgf>
                      </td>
                      <td class="poste-col" (focusout)="calculatePosteDevis(loti, sousloti, posti)" [ngClass]="{'red-back': status!='submited' && response!='accepted' && 
                      modeList[mode].acompte && !lot.acompteAll && !post.acompte && !post.new,
                      'depasser': post.devis+post.lastDevis > post.price}">
                        <app-unite-input-dpgf #unitInputs
                        [disable]="!modeList[mode].acompte || lot.acompteAll || status=='submited' 
                        || response=='accepted' || !post.price || post.deleted"
                        [unit]="post.devis"
                        placeHolder="Devis"
                        (changeText)="post.devis=converteNumber($event)"
                        typeInput="number"
                        sign="€"
                        tooltip="Champs bloqué"
                        >
                      </app-unite-input-dpgf>
                      </td>
                      <td>
                        <div [ngClass]="{'disable': (modeList[mode].acompte && status=='submited') || response=='accepted'}" style="text-align: center;">
                          <i *ngIf="post.price && !post.deleted && !post.devis" class="delete-row fa fa-trash" (click)="deletePoste('delete',loti,sousloti,posti)"></i>
                          <i *ngIf="post.price && post.deleted && !post.devis" class="delete-row fa fa-undo" (click)="deletePoste('restore',loti,sousloti,posti)"></i>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="modeList[mode].comment">
                      <td></td>
                      <td colspan="9">
                        <section class="comment-section" >
                          <div *ngFor="let comment of post.comment; let i = index" style="display:flex">
                            <i *ngIf="(modeList[mode].acompte && comment.by == 'artisan') || (!modeList[mode].acompte && comment.by == 'archi')" [ngClass]="{'disable': (modeList[mode].acompte && status=='submited') || response=='accepted' || !comment.new}" 
                            class="delete-comment fa fa-trash" aria-hidden="true" (click)="deleteComment(loti, sousloti, posti, i)"></i>
                            <input (focusout)="updateComment()" [ngClass]="{'comment-input-artisan': comment.by == 'artisan', 'comment-input-archi': comment.by == 'archi', 'disable': (!modeList[mode].acompte && comment.by == 'artisan') || (modeList[mode].acompte && comment.by == 'archi')}" 
                            type="text" name="" value="" [(ngModel)]="comment.com" placeholder="Saisir une annotation" />
                          </div>
                        </section>
                        <section class="add-comment">
                          <span [ngClass]="{'disable': (modeList[mode].acompte && status=='submited') || response=='accepted'}" 
                          (click)="addComment(loti, sousloti, posti)">Ajouter un commentaire +</span>
                        </section>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <!--********************Poste Ajouter***********************-->
        <div [ngClass]="{'disable': status=='submited' || response=='accepted'}" *ngIf="modeList[mode].addPost && !lot.acompteAll" class="disable add-poste pointing" (click)="addPoste(loti, sousloti)" data-step="8" data-intro="Vous pouvez ajouter des postes en cliquant ici">
          Ajouter une ligne +
        </div>
      </div>
    </div>
  </div>
</div>
