import { Component, OnInit, ViewChild , NO_ERRORS_SCHEMA } from '@angular/core';
import { Subscription } from 'rxjs';
import { Title,Meta } from '@angular/platform-browser';
import { ActivatedRoute , Params,Router} from '@angular/router';
import { ArchitectNewProjectComponent } from '../../../architect/architect-new-project/architect-new-project.component';
import { GoogleAutocompleteComponent } from 'src/app/all/google-autocomplete/google-autocomplete.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

import { MessageService } from 'primeng/api';
import { AuthService, CeProjectService } from '../../../_services/index';
import { ErrorService } from '../../../_services/index';
//import { ArchitectModule } from '../architect.module';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { NeedSubscriptionPopup2Component } from 'src/app/architect/need-subscription-popup2/need-subscription-popup2.component';
import { HeaderComponent } from 'src/app/all/header/header.component';
// design system components
import { UserOnWithoutLicenseModalComponent } from 'src/app/_design_system_components/user-on-without-license-modal/user-on-without-license-modal.component';

import { environment } from 'src/environments/environment';

declare var $:any;

@Component({
    selector: 'app-archi-new-ce',
    imports:[
      GoogleAutocompleteComponent,
      SpinnerComponent,
      NeedSubscriptionPopup2Component,
      ArchitectNewProjectComponent,
      HeaderComponent,
      UserOnWithoutLicenseModalComponent,
      NgSharedModule
    ],
    providers: [],
    templateUrl: './archi-new-ce.component.html',
    styleUrls: ['./archi-new-ce.component.scss'],
    standalone: true,

})
export class ArchiNewCeComponent implements OnInit {
  public newProject: any = {};
  public loading:Boolean = false;
  public adresseValue: any;
  @ViewChild(ArchitectNewProjectComponent) form: ArchitectNewProjectComponent;
  private subscriptions: Array<Subscription> = [];

  constructor(private title: Title,
              private messageService: MessageService,
              private router: Router,
              private route: ActivatedRoute,
              private ceProjectService: CeProjectService,
              private ErrorService: ErrorService,
              private authService: AuthService) { }

  ngOnInit() {
    // this.scrollToTop()
    this.title.setTitle("Aglo -  Déposer une demande de consultation d'entreprise" )
  }
  //Register
  register(){
    this.loading = true;
    let licence=this.authService.licence;
    let isValid = this.form.isValid()
    let projectData = this.form.getFormData();
    if(isValid && licence!='without'){
      this.subscriptions.push(
        this.ceProjectService.create(projectData)
          .subscribe(
              data => {
                  this.loading = false;
                  let currentUser = JSON.parse(localStorage.getItem('currentUser'))||{};
                  if(!currentUser.hasDpgf){
                  this.router.navigate(['/architect/new-dpgf/'+ data.projectID]);
                  }else{
                    this.router.navigate(['/architect/ce-project/'+ data.projectID]);
                  }
                  this.messageService.add({ key: 'toast', severity: 'success', summary: 'Projet créé', detail: 'Votre projet a été créé.', life: 5000 });
                  // segment event
                  if(!environment.onPremise)window.analytics.track('create_project',{properties:{acValue: 'create_project'}});
              },
              error => {
                  console.log("error",error)
                  this.loading = false;
                  this.ErrorService.manageError(error,'/client/nouveau-projet')
              })
      )
    }else if (licence!='without'){
      this.loading = false;
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Formulaire incomplet', detail: 'Veuillez remplir tous les champs obligatoires et attendre la fin du chargement des fichiers', life: 5000 });
    }
    else{
      this.loading = false;
      $('#upgrade-modal').modal('show');
    }
  }
  redirectToPricingTable(){
    $('#upgrade-modal').modal('hide');
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
  }
  public scrollToTop(){
    $('html, body').animate({
        scrollTop: ($('#q1').offset().top)
    },1500);
  }

  public ngOnDestroy(): void {
      this.subscriptions.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
      });
  }
}
