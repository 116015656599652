import { Component } from '@angular/core';
import {Title,Meta} from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  selector: 'app-403',
  standalone: true,
  imports: [
    NgSharedModule,
    HeaderComponent
  ],
  templateUrl: './403.component.html',
  styleUrl: './403.component.scss'
})
export class accessDeniedComponent {
  public returnUrl ;
  constructor(private title: Title,
    private router:Router,
    private route:ActivatedRoute 
  ) { }

  ngOnInit() {
    this.title.setTitle('Aglo - Oops 403' );
    this.route.queryParams.subscribe((params: Params) => {
      this.returnUrl = params.returnURL;
      const cleanedReturnUrl = params.returnURL.replace(/"/g, '');
      // console.log('cleanedReturnUrl',cleanedReturnUrl)
      // this.router.navigate([cleanedReturnUrl?cleanedReturnUrl:'architect/my-consultations']);
    })
  }
}
