<div class="outter-wrapper">

  <!-- Upper Section -->
  <div class="upper-section flex space-between">

    <!-- Page Title -->
    <div class="page-title flex center-items">
      <p class="no-margin-bottom">Consultation d’entreprise</p>
    </div>
    <!-- END Page Title -->

    <!-- project Title -->
    <div class="project-title flex center-items">
      <p class="no-margin-bottom">{{ projectTitle }}</p>
      <span class="enterprises-count">{{ consultedEnterprisesCount }} entreprises consultées</span>
    </div>
    <!-- END project Title -->

    <!-- Date -->
    <div class="date-container">
      <span class="text-label">Date souhaitée de remise des offres</span>
      <span class="date-input artisan-archi-calendar">
        <p-calendar 
          [(ngModel)]="dateDce" 
          [monthNavigator]="true" 
          [yearNavigator]="true"
          [inputStyle]="{'border-bottom': 'none'}" 
          yearRange="2000:2040" 
          placeholder="jj/mm/aaaa" 
          dateFormat="dd/mm/yy"
          (onSelect)="emitDateSelected()" 
        ></p-calendar>
        <i class="fa fa-calendar-check-o"></i>
      </span>
    </div>
    <!-- END Date -->

  </div>
  <!-- END Upper Section -->
  
  <!-- Bottom Section -->
  <div class="bottom-section flex space-between">
    <!-- Left Section -->
    <div class="left-section">
      <div class="tabs-container flex">
        <div class="single-tab-item" [ngClass]="{'selected': selectedTab == 'enterprises'}" (click)="changeTab('enterprises')">
          <span>Entreprises</span>
        </div>
        <div class="single-tab-item" [ngClass]="{'selected': selectedTab == 'lots'}" (click)="changeTab('lots')">
          <span>Lots</span>
        </div>
      </div>
    </div>
    <!-- END Left Section -->
    
    <!-- Right Section -->
    <div class="right-section flex">
      <!-- Icons -->
      <div class="icons-wrapper flex">        
        <span class="padding-5 single-icon-wrapper margin-right-10 flex" *ngIf="showSendDCEIcon" (click)="emitSendDceToMultipleArtisans()">
          <div class="ttip">Envoyer le DCE</div>
          <img src="/assets/dpgf/send-many-dce.svg" class="pointer">
        </span>
        
        <span class="padding-5 single-icon-wrapper margin-right-10 flex" *ngIf="showUpdateDCEIcon">
          <div class="ttip">Mettre à jour le DCE</div>
          <img src="/assets/dpgf/history.svg" class="pointer" (click)="emitUpdateDceForMultipleArtisans()">
        </span>
        <span class="padding-5 single-icon-wrapper flex" *ngIf="showSendMailIcon">
          <div class="ttip">Envoyer un email</div>
          <img src="/assets/dpgf/envolope.svg" class="pointer" (click)="emitSendMailToMultipleArtisans()">
        </span>
      </div>
      <!-- END Icons -->
      
      <!-- Buttons -->
      <div class="buttons-wrapper flex">

        <button class="ag-btn-primary-sm margin-right-8" (click)="emitOpenAddEnterpriseModal()">
          <img src="/assets/add-icon-white.svg" class="margin-right-10" alt="" >
          <span>Ajouter une entreprise</span>
        </button>

        <button class="ag-btn-bordered-xs" (click)="emitOpenCreateChiffrageModal()">
          <img src="/assets/add-icon.svg" class="margin-right-10" alt="" >
          <span>Créer un chiffrage</span>
        </button>

      </div>
      <!-- END Buttons -->

    </div>
    <!-- END Right Section -->

  </div>
  <!-- END Bottom Section -->

</div>