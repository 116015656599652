<form [formGroup]="dpgfForm" novalidate>


  <div class="floating-card" id="q1" (click)="scrollTo('#q1')">
    <label class="required-star question-label">Quel est le type de travaux à réaliser ?</label><br>
    <div class="cc-selector row">
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
          <input id="construction" type="radio" name="natureTravaux" formControlName="natureTravaux" value="construction" (click)="setTva(20,'construction')" />
          <label class="drinkcard-cc construction" for="construction"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="extension" type="radio" name="natureTravaux" formControlName="natureTravaux" value="extension" (click)="setTva(20,'extension')"/>
        <label class="drinkcard-cc extension"for="extension"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="renovation" type="radio" name="natureTravaux" formControlName="natureTravaux" value="renovation" (click)="setTva(10,'renovation')" />
        <label class="drinkcard-cc renovation"for="renovation"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="transformation" type="radio" name="natureTravaux" formControlName="natureTravaux" value="transformation" (click)="setTva(10,'transformation')"/>
        <label class="drinkcard-cc transformation" for="transformation"></label>
      </div>
    </div>
    <small *ngIf="formErrors.type" class="text-danger">
      {{ formErrors.type }}
    </small>
  </div>

  <div class="floating-card" id="q2" (click)="scrollTo('#q2')">
    <label class="required-star question-label">Quel est le programme ?</label><br>
    <div class="cc-selector row">
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="maison" type="radio" name="type" formControlName="type" value="maison" />
        <label class="drinkcard-cc four maison" for="maison"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6" *ngIf="this.dpgfForm.value.natureTravaux != 'construction'">
        <input id="appartement" type="radio" name="type" formControlName="type" value="appartement" />
        <label class="drinkcard-cc four appartement"for="appartement"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="hotel" type="radio" name="type" formControlName="type" value="hotel" />
        <label class="drinkcard-cc four hotel"for="hotel"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="commerce" type="radio" name="type" formControlName="type" value="commerce" />
        <label class="drinkcard-cc four commerce"for="commerce"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="logement" type="radio" name="type" formControlName="type" value="logement" />
        <label class="drinkcard-cc four logement"for="logement"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="bureaux" type="radio" name="type" formControlName="type" value="bureaux" />
        <label class="drinkcard-cc four bureaux"for="bureaux"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="agricole" type="radio" name="type" formControlName="type" value="agricole" />
        <label class="drinkcard-cc four agricole"for="agricole"></label>
      </div>
      <div class="ui-radio col-lg-3 col-md-4 col-sm-4 col-xs-6">
        <input id="autre" type="radio" name="type" formControlName="type" value="autre" />
        <label class="drinkcard-cc four autre"for="autre"></label>
      </div>
    </div>
    <small *ngIf="formErrors.natureTravaux" class="text-danger">
      {{ formErrors.natureTravaux }}
    </small>
  </div>

  <div class="floating-card" id="q3" (click)="scrollTo('#q3')">
    <label class="required-star question-label">Nom du maitre d'ouvrage</label>
    <div class="row">
      <input formControlName="nomPrenom" placeholder="Nom du maitre d'ouvrage" type="text" class="form-control" >
    </div>
    <small *ngIf="formErrors.nomPrenom" class="text-danger">
      {{ formErrors.nomPrenom }}
    </small>
  </div>

  <div class="floating-card" id="q4" (click)="scrollTo('#q4')">
    <label class="required-star question-label">Adresse du projet</label>
    <div class="row">
      <app-google-autocomplete (adresseValueChange)="getGoogleMapValueChange($event)"></app-google-autocomplete>
    </div>
    <small *ngIf="formErrors.adresse" class="text-danger">
      {{ formErrors.adresse }}
    </small>
  </div>


  <div class="floating-card" id="q6" (click)="scrollTo('#q6')">
    <label class="question-label">Taux de TVA par default en %</label>
    <div class="row">
      <input formControlName="defaultTva" placeholder="TVA par default" type="text" class="form-control" >
    </div>
    <small *ngIf="formErrors.defaultTva" class="text-danger">
      {{ formErrors.defaultTva }}
    </small>
  </div>

  <div class="floating-card" >
    <label class="question-label">Sélectionnez les lots que vous voulez décrire :</label><br>
    <span class="sub-label">Ces lots vont être créés automatiquement dans votre DPGF</span>
    <div class="row">
      <div class="ng-radio-button">
        <app-lot-select [options]="travauxListe" formControlName="prefillData"  multiple="multiple" (onChange)="changeLot($event)"></app-lot-select>
      </div>
    </div>
  </div>

  <!-- <div  class="floating-card" *ngIf="showLot">
    <label class="question-label">Sélectionnez les lots que vous voulez décrire :</label><br>
    <span class="sub-label">Ces lots vont êtres créés automatiquement dans votre DPGF</span>
    <div class="row">
      <div class="ng-radio-button">
        <app-checkbox-simple styleClass="full-width-check" formControlName="prefillData"  [options]="travauxListe" multiple="multiple" (onChange)="changeLot($event)"></app-checkbox-simple>
      </div>
    </div>
  </div> -->
</form>

  <div  class="floating-card" *ngIf="questions.demolition" id="q8" (click)="scrollTo('#q8')">
    <label class="question-label">
      <img class="icon-clique" src="assets/dpgf/Icon_DPGF/demolition__depose.png" height="40">
      Démolition/Dépose - Sélectionnez les éléments concernés :
    </label>
    <br>
    <div class="row">
      <div class="ng-radio-button">
        <span class="title-radio">Dans l'ensemble</span>
        <app-checkbox-simple  [(ngModel)]="poste.demolition.ensemble"  [options]="demolutionOption.ensemble" multiple="multiple"></app-checkbox-simple>
      </div>
      <div class="ng-radio-button">
        <span class="title-radio">Dans la cuisine</span>
        <app-checkbox-simple  [(ngModel)]="poste.demolition.cuisine"   [options]="demolutionOption.cuisine" multiple="multiple"></app-checkbox-simple>
      </div>
      <div class="ng-radio-button">
        <span class="title-radio">Dans la salle de bain</span>
        <app-checkbox-simple  [(ngModel)]="poste.demolition.sdb"   [options]="demolutionOption.sdb" multiple="multiple"></app-checkbox-simple>
      </div>
    </div>
  </div>


  <div  class="floating-card" *ngIf="questions.menuiserie_interieure" id="q13" (click)="scrollTo('#q13')">
    <label class="question-label">
      <img class="icon-clique" src="assets/dpgf/Icon_DPGF/menuiserie_interieure__agencement.png" height="40">
      Menuiserie interieure - Sélectionnez les éléments à poser ou à créer :
    </label>
    <br>
    <div class="row">
      <div class="ng-radio-button">
        <span class="title-radio">Pose</span>
        <app-checkbox-simple  [(ngModel)]="poste.menuiserie_interieure.pose"  [options]="menuiserieInterieureOption.pose" multiple="multiple"></app-checkbox-simple>
      </div>
      <div class="ng-radio-button">
        <span class="title-radio">Création</span>
        <app-checkbox-simple  [(ngModel)]="poste.menuiserie_interieure.creation"   [options]="menuiserieInterieureOption.creation" multiple="multiple"></app-checkbox-simple>
      </div>
    </div>
  </div>

  <div  class="floating-card" *ngIf="questions.revetement_sol" id="q14" (click)="scrollTo('#q14')">
    <label class="question-label">
      <img class="icon-clique" src="assets/dpgf/Icon_DPGF/revetement_de_sol.png" height="40">
      Revêtement de sol - Sélectionnez les éléments à réaliser ou à poser :
    </label><br>
    <div class="row">
      <div class="ng-radio-button">
        <span class="title-radio">Réalisation</span>
        <app-checkbox-simple  [(ngModel)]="poste.revetement_sol.realisation"  [options]="revetementSolOption.realisation" multiple="multiple"></app-checkbox-simple>
      </div>
      <div class="ng-radio-button">
        <span class="title-radio">Pose</span>
        <app-checkbox-simple  [(ngModel)]="poste.revetement_sol.pose"   [options]="revetementSolOption.pose" multiple="multiple"></app-checkbox-simple>
      </div>
    </div>
  </div>

  <div  class="floating-card" *ngIf="questions.peinture" id="q15" (click)="scrollTo('#q15')">
    <label class="question-label">
      <img class="icon-clique" src="assets/dpgf/Icon_DPGF/peinture__revetements_muraux__nettoyage.png" height="40">
      Peinture/Revêtements Muraux - Sélectionnez les éléments concernés :
    </label><br>
    <div class="row">
      <div class="ng-radio-button">
        <span class="title-radio">Par la peinture</span>
        <app-checkbox-simple  [(ngModel)]="poste.peinture.peinture"  [options]="peintureOption.peinture" multiple="multiple"></app-checkbox-simple>
      </div>
      <div class="ng-radio-button">
        <span class="title-radio">Par le revêtement mural </span>
        <app-checkbox-simple  [(ngModel)]="poste.peinture.revetement"   [options]="peintureOption.revetement" multiple="multiple"></app-checkbox-simple>
      </div>
    </div>
  </div>

  <div  class="floating-card" *ngIf="questions.electricite" id="q16" (click)="scrollTo('#q16')">
    <label class="question-label">
      <img class="icon-clique" src="assets/dpgf/Icon_DPGF/electricite.png" height="40">
      Electricité - Sélectionnez les éléments à poser :
    </label><br>
    <div class="row">
      <div class="ng-radio-button">
        <app-checkbox-simple  [(ngModel)]="poste.electricite.electricite"  [options]="electriciteOption" multiple="multiple"></app-checkbox-simple>
      </div>
    </div>
  </div>
