<div id="booster-profile-edit-agency-precision-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header no-padding">
        <h4 class="ag-heading-h4 no-margin">Précisions</h4>
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding margin-top-24">        
        
        <div class="warning-message-container horizontal-items margin-bottom-20">
          <div class="flex-middle">
            <img src="/assets/artisan-booster/exclamation-light-blue.svg">
          </div>
          <div class="">
            <p class="ag-para-1 ag-weight-500 padding-left-8">
              Ces informations nous permettent de pouvoir vous proposer des projets cohérents avec vos compétences<br>
              Elles n’apparaissent pas sur votre profil.
            </p>
          </div>
        </div>


        <div class="inputs-container">

          <div class="single-input-item margin-bottom-20">
            <h5 class="ag-heading-h5 ag-weight-600">Avez-vous un domaine de spécialisation ?</h5>
            <input type="text" [(ngModel)]="domaineSpe">
          </div>

          <div class="single-input-item margin-bottom-20">
            <h5 class="ag-heading-h5 ag-weight-600">Dans quel(s) département(s) exercez-vous principalement ?</h5>
            <div class="suggestions-container flex-vertical relative width-100">
              <div class="horizontal-items space-between pointer" (click)="toggleShowSuggestionsList()">
                <p class="ag-para-1" id="dropdown-label">Sélectionnez un ou plusieurs départements</p>
                <img src="/assets/artisan-booster/arrow-down.svg" alt="" srcset="">
              </div>

              <!-- Suggestions List -->
              <div class="suggestions-list" *ngIf="showSuggestionsList">
                <ul class="no-padding">
                  <li *ngFor="let region of regionsList" 
                    class="main-list-item full-width relative">
                    <div class="full-width horizontal-items space-between">
                      <span>{{ region.name }}</span>
                      <img src="/assets/artisan-booster/arrow-right.svg" *ngIf="!regionSelectedDepartementsNumber[region.value]">
                      <span class="departements-counter flex-middle" *ngIf="regionSelectedDepartementsNumber[region.value]">
                        {{ regionSelectedDepartementsNumber[region.value] }}
                      </span>
                    </div>
                    <ul class="nested-list no-padding">
                      <li (click)="checkRegionDepartements(region.value, region.departements)">
                        <input type="checkbox"
                          [checked]="regionSelectedDepartementsNumber[region.value] == region.departements.length"> 
                          Tous les départements
                      </li>
                      <li *ngFor="let departement of region.departements" 
                        (click)="selectDepartement(region.value, departement.id)">
                        <input type="checkbox" [checked]="selectedDepartements[departement.id]">
                        {{ departement.departName }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <!-- END Suggestions List -->

            </div>
          </div>

        </div>
        
        <div class="submit-btn-container text-center margin-top-30 ">
          <button class="ag-btn-primary-sm" (click)="emitSaveData()">Modifier les informations</button>
        </div>

      </div>
    </div>
  </div>
</div>
