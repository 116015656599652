import { Component, OnInit, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone: true,
    selector: 'app-doc-file-is-downloaded',
    imports:[NgSharedModule],
    templateUrl: './doc-file-is-downloaded.component.html',
    styleUrls: ['./doc-file-is-downloaded.component.scss'],
})
export class DocFileIsDownloadedComponent implements OnInit {

  @Output() emitDontShowMessage: EventEmitter<any> = new EventEmitter<any>();


  public dontShowTheMessage: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public submit(){
    this.emitDontShowMessage.emit(this.dontShowTheMessage);
  }

}
