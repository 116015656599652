<div class="ag-card-1 horizontal-items space-between header-card">
  
  <div class="left-side">
    <h1 class="no-margin ag-heading-h1 ag-text-34 margin-bottom-32">Vous nous recommandez, on vous récompense.</h1>
    <p class="ag-text-down-river ag-text-20 no-margin">AGLO vous a permis de gagner du temps dans la rédaction de vos DCE ? </p>
    <p class="ag-text-down-river ag-text-20 no-margin">Pourquoi n'aideriez-vous pas vos confrères à gagner en efficience ?</p>
    <button class="ag-btn-blue margin-top-32" (click)="openAddParrainageModal()">Parrainer un confrère</button>
  </div>

  <div class="right-side">
    <img src="/assets/archi/cheque.svg" alt="Cheque Image">
  </div>

</div>

<div class="parrainage-cards-wrapper">
  <h4 class="ag-heading-downriver-h3 ag-text-24 text-center margin-top-60 margin-bottom-44">
    En parrainant un confrère, vous reçevez...
  </h4>
  <div class="parrainage-cards-container horizontal-items justify-center baseline-items">

    <!-- PARRAIN -->
    <div class="single-parrainage-card margin-right-40">
      <div class="card-title">
        <h4>PARRAIN</h4>  
      </div>
      <div class="card-separator margin-bottom-24"></div>
      <div class="card-price flex-middle">
        <span class="ag-text-blue">Jusqu’à</span>
        <h2 class="ag-text-blue no-margin">100 €*</h2>
        <span class="ag-text-blue">par chèque à votre nom après 2 mois<br>
          d’abonnement de votre filleul
        </span>
      </div>

      <div class="card-separator under-price"></div>

      <div class="card-benefits">
        <ul>
          <li class="horizontal-items">
            <img src="/assets/money-icon.svg" alt=""> 
            <p class="no-margin">30€ pour une souscription à une Licence AGLO<br> Pro par votre filleul</p>
          </li>
          <li class="horizontal-items">
            <img src="/assets/money-icon.svg" alt="">
            <p class="no-margin">50€ pour une licence AGLO Max</p>
          </li>
          <li class="horizontal-items">
            <img src="/assets/money-icon.svg" alt="">
            <p class="no-margin">100€ pour une licence AGLO Intégrale</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- END PARRAIN -->

    <!-- FILLEUL -->
    <div class="single-parrainage-card">
      <div class="card-title">
        <h4>FILLEUL</h4>  
      </div>
      <div class="card-separator"></div>
      <div class="card-price flex-middle">
        <h2 class="ag-text-blue">50%</h2>
        <span class="ag-text-blue">
          de réduction pour votre filleul sur ses<br>
          deux premiers mois d'abonnement
        </span>
      </div>
    </div>
    <!-- END FILLEUL -->

  </div>
  
  <div class="text-center">
    <button class="ag-btn-blue margin-top-44" (click)="openAddParrainageModal()">Parrainer un confrére</button>
  </div>

</div>


<!-- Add Parrainage -->
<div id="add-parrainage-popup" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header no-padding-bottom margin-bottom-20">
        <h3 class="modal-title ag-heading-downriver-h3">Parrainer un confrère</h3>
      </div>
      <div class="modal-body no-padding-top no-padding-bottom">
        
        <div class="validation-description horizontal-items center-items">
          <img src="/assets/exclamation-mark.svg">
          <p class="ag-para-1">
            Afin de valider le parrainage, vos confrères invités doivent créer leur compte grâce au lien d’inscription envoyé.
          </p>
        </div>

        <div class="email_input_container margin-top-24">
          <p class="ag-text-grey no-margin margin-bottom-6">Email de votre confrère</p>
          <form [formGroup]="invitationForm">
            <input type="email" class="ag-input-1" formControlName="email">
            <p class="ag-text-danger" 
              *ngIf="invitationForm.get('email').dirty && invitationForm.get('email').errors?.pattern">
              L'adresse email que vous avez renseignée n'est pas valide
            </p>
          </form>
        </div>
      </div>
      <div class="modal-footer no-padding-top">
        <button type="button" class="ag-btn-grey no-hover" data-dismiss="modal">Annuler l'ajout</button>
        <button type="button" class="ag-btn-primary-sm margin-left-10" (click)="inviteUser()">Envoyer l'invitation</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
