<div>

  <label id="assuranceNumber">Assurance et N° de contrat</label>
  <div class="input-group input-group-lg">
    <input id="assuranceNumber"
          [(ngModel)]="artisan.assuranceNumber"
           type="text"
           class="form-control"
           placeholder="Assurance et N° de contrat"
           aria-describedby="sizing-addon1" #adresse>
  </div>
  <br>
  <!-- <small *ngIf="formErrors.assuranceNumber" class="text-danger">
    {{ formErrors.assuranceNumber }}
  </small><br> -->

  <label class="required-star">
    Attestation d'assurance
  </label>
  <div *ngIf="artisan.assuranceFile">
    <a [href]="artisan.assuranceFile.cdnUrl" target="_blank">{{ artisan.assuranceFile.name }}</a>
  </div>
</div>


  <label id="kbis-file">Extrait de KBIS</label><br>
  <div *ngIf="artisan.kbis">
    <a [href]="artisan.kbis.cdnUrl" target="_blank">{{ artisan.kbis.name }}</a>
  </div>
  <p class="uploader-secondary-color">
    <input id="kbisFile"
           type="hidden"
           role="uploadcare-uploader"
           data-preview-step=true
           name="kbisFile"
           /><br>
  </p>
  <br>



<label id="file-label">Vos documents</label><br>
<ul>
  <li class="check">Attestation RC Pro</li>
  <li class="check">Extrait Kbis de moins de 3 mois</li>
  <li class="check">Attestation de certifications (type Qualibat, ou RGE) - si vous en avez</li>
  <li class="check">Attestation de fourniture de déclarations et de paiement des cotisations sociales de l’Urssaf</li>
  <li class="check">Attestation de régularité fiscale</li>
</ul>

<app-file-manager (imageDeleted)="imageRemoved()" [(files)]="artisan.files"></app-file-manager>

<p class="uploader-secondary-color">
  <img src="" alt="">
  <input id="file"
         type="hidden"
         role="uploadcare-uploader"
         data-preview-step=true
         data-multiple=true
         /><br>
</p>
<br>


<label id="portfolio-photo-label">Vos réalisations</label><br>
<app-file-manager (imageDeleted)="imageRemoved()" [(files)]="artisan.photos" [isImage]="true" [order]="true"></app-file-manager>

<p class="uploader-secondary-color">
  <img src="" alt="">
  <input id="portfolio-photo"
          type="hidden"
          role="uploadcare-uploader"
          data-preview-step=true
          data-multiple=true
          data-images-only
          data-crop="free"
          data-image-shrink="1600x1600"/><br>
</p>
<br>
