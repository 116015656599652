<form [formGroup]="subscriptionForm" novalidate>
  <div class="row">
    <div class="col-md-6">
      <div class="input-group input-group-lg">
        <input formControlName="lastName" type="text" class="form-control" placeholder="Nom">
      </div>
      <small *ngIf="formErrors.lastName" class="text-danger">
        {{ formErrors.lastName }}
      </small>
    </div>
    <div class="col-md-6">
      <div class="input-group input-group-lg">
        <input formControlName="firstName"  type="text" class="form-control" placeholder="Prénom" aria-describedby="sizing-addon1">
      </div>
      <small *ngIf="formErrors.firstName" class="text-danger">
        {{ formErrors.firstName }}
      </small>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-md-12">
      <div class="input-group input-group-lg">
        <input formControlName="email" type="email" class="form-control" placeholder="Cet email sera votre identifiant de connexion sur la plateforme" (focusout)="focusOutEmail()">
      </div>
      <small *ngIf="formErrors.email" class="text-danger">
        {{ formErrors.email }}
      </small>
      <small *ngIf="!validEmail" class="text-danger">
        Vous avez déjà un compte avec cette email
      </small>
    </div>
  </div>
  <br><div class="row" style="margin-right:-31px !important;">
    <div class="col-md-12">
      <div class="input-group input-group-lg flex">
        <select id="countryCode" class="form-control cursor" formControlName="codePhone"  name="codePhone" style="width: 78px;height: 67px;">
          <option *ngFor="let code of countries_codes" [value]="code.dial_code">{{ code.emoji }} {{ code.name}}</option>
        </select>
        <input id="phoneId"  name="phone" formControlName="phone" type="tel" class="form-control" placeholder="Numéro de téléphone" (keyup)="formatPhoneNumber()">
      </div>
      <small *ngIf="formErrors.phone" class="text-danger">
        {{ formErrors.phone }}
      </small>
    </div>
  </div>
  <br>
  <div class="row" *ngIf="!hidePassword">
      <div class="input-group-lg col-md-12">
        <input formControlName="password"  type="password" class="form-control" placeholder="Mot de passe">
      </div>
      <small *ngIf="formErrors.password" class="text-danger">
        {{ formErrors.password }}
      </small>

    <!-- <div class="col-md-6">
      <div class="input-group input-group-lg">
        <input formControlName="password2"  type="password" class="form-control" placeholder="Mot de passe">
      </div>
      <small *ngIf="formErrors.password2" class="text-danger">
        {{ formErrors.password2 }}
      </small>
    </div> -->
  </div>
  <div>
    <button *ngIf="!loadingData && !isSignUp" class="btn-primary-color" (click)='update()'>Enregistrer les modifications</button>
    <app-spinner *ngIf="loadingData"></app-spinner>
  </div>
  <br>
  <div class="row" *ngIf="!hidePassword">
    <div class="col-md-12">
      <p-checkbox formControlName="cgu" binary="true"></p-checkbox>
      J'ai lu et j'accepte les
      <a href="https://www.aglo.ai/conditions-generales-utilisation/" class="remove-visited" target="_blank">
        Conditions Générales d'Utilisation
      </a>
      et les
      <a href="https://www.aglo.ai/conditions-generales-de-vente-14-04-2022/" class="remove-visited" target="_blank">
        Conditions Générales de Vente d'AGLO
      </a>
    </div>
  </div>
</form>
