<div id="photos-slider-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <!-- <div class="modal-header">
      </div> -->
      <div class="modal-body no-padding">    

        <div class="slider-wrapper" *ngIf="photosList.length">
          
          <span class="slider-arrow left" (click)="prevSlide()">
            <i class="fa fa-chevron-left"></i>
          </span>

          <div class="img-container">
            <img [src]="photosList[currentPhotoIndex].uploadURL">
          </div>
          
          <span class="slider-arrow right" (click)="nextSlide()">
            <i class="fa fa-chevron-right"></i>
          </span>

        </div>

      </div>
    </div>
  </div>
</div>






