
<div class="modal fade" id="update-user-info" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body padding42-bottom20">
          <div class="title-text">
           <h4> Modification des informations personelles de l'Utilisateurs</h4>
        </div>
        <form [formGroup]="subscriptionForm">
          <p>Nom</p>
          <input type="text" formControlName="lastName"  type="text" class="form-control"> 
          <small *ngIf="formErrors.lastName" class="text-danger">
            {{ formErrors.lastName }}
          </small>
          <p>Prenom</p>
          <input type="text" formControlName="firstName"  type="text" class="form-control"> 
          <small *ngIf="formErrors.firstName" class="text-danger">
            {{ formErrors.firstName }}
          </small>
          <p>Email</p>
          <input type="text" formControlName="email"  type="text" class="form-control">
          <small *ngIf="formErrors.email" class="text-danger">
            {{ formErrors.email }}
          </small>
          <small *ngIf="emailExist" class="text-danger">
            {{ emailExist }}
          </small>
        </form>
        <div class="modal-footer" >
          <div class="flex float-right">
          <button type="button" class="annuler-button" data-dismiss="modal">Annuler</button>
          <button type="button" class="send-button"  (click)="valider()">Valider</button>
        </div>
       </div>
        </div>
      </div>
  </div>