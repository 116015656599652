import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../_services/index';
import { environment } from 'src/environments/environment';
//Interface
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { MessageService } from 'primeng/api';

import { IdentifySegement } from "../../../all/segment/identify";
// import { AllModule } from '../all.module';
declare var $: any;
declare var profitwell;

@Component({
  standalone: true,
  selector: 'app-login-page',
  imports: [
    NgSharedModule
  ],
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  public user: any;
  public loading: boolean = false;
  public returnUrl: string = "";
  public infoBubble: string = "";
  public switch: string = "connecte";
  public currentUser;
  private subscriptions: Array<Subscription> = [];statusAccountQuery;
  public firstValueEnvirenementObservable = new Observable<boolean>(observer => {
    observer.next(environment.onPremise);
    observer.complete();
  });
  constructor(private title: Title,
    private router: Router,
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private identify: IdentifySegement,
    private messageService:MessageService) {
    this.user = {}
  }

  ngOnInit() {
    this.title.setTitle('Aglo - Se connecter');
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.returnUrl = params['returnUrl'] || "";
        this.infoBubble = params['infoBubble'];
        this.statusAccountQuery = params['userSubscribed'];
      })
    )
    setTimeout(() => {
      if(this.statusAccountQuery)this.showMessage();
    }, 700);
  }
  showMessage(){
    this.statusAccountQuery = this.statusAccountQuery =='true';
    if(this.statusAccountQuery)this.messageService.add({ key: 'toast', severity: 'error', summary: "Aucun département associé", detail: "Vous n'êtes relié à aucun département et vous ne pouvez donc pas vous connecter pour le moment. Contactez votre administrateur afin qu'il vous rattache à un département.", life: 5000 });
    else this.messageService.add({ key: 'toast', severity: 'error', summary: "Compte innexistant", detail: "Contactez l'administrateur afin qu'il vous crée un compte", life: 5000 });
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 7000);
  }
  //Login
  login() {
    this.loading = true;
    $('#wrong-form-login').removeClass('animated slideInDown');
    $('#wrong-form-login').addClass('hide-wrong');
    this.user.email = this.user.email.trim()
    this.subscriptions.push(
      this.authService.login(this.user)
        .subscribe(
          data => {
            let type;
            this.loading = false;
            this.emitToProfitwell();
            if (this.returnUrl != "") {
              this.router.navigate([this.returnUrl]);
            }else if (this.authService.currentUser.isAdmin && environment.onPremise) {
              type = 'admin';
              this.router.navigate(['/admin/utilisateurs']);
            }
            else if (this.authService.currentUser.isAdmin && !environment.onPremise) {
              type = 'admin';
              this.router.navigate(['/architect/my-consultations']);
            } else if (this.authService.isArchitect() || this.authService.isCollaborateur()) {
              type = 'architect';
              this.router.navigate(['/architect/my-consultations']);
            } else if (this.authService.isArtisan()) {
              type = 'artisan';
              this.router.navigate(['/artisan/project-list'], { queryParams: { showUpdateModal: 'true' } });
            }
            // identify user segment
            if (type == "architect" && !environment.onPremise){
              // segment
              setTimeout(() => this.identify.identifyLoginArchi(), 1500);
              // Produktly
              this.emitToProduktly();
            } 
          },
          error => {
            $('#wrong-form-login').removeClass('hide-wrong');
            $('#wrong-form-login').addClass('animated slideInDown');
            console.log("error", error)
            if(error.error.message=="You still not assigned to any departement" && environment.onPremise)this.messageService.add({ key: 'toast', severity: 'error', summary: "Aucun département associé", detail: "Vous n'êtes relié à aucun département et vous ne pouvez donc pas vous connecter pour le moment. Contactez votre administrateur afin qu'il vous rattache à un département.", life: 5000 });
            this.loading = false;
          })
    )
  }
  loginWithOpenId(){
    window.location.href='api/v1/login/openid';
    window.open();
  }
  //subscribe
  gotoSubcribe(url) {
    this.router.navigate([url]);
  }
  private emitToProfitwell() {
    let currentUser = this.authService.getAuthUser();
    if (environment.production && currentUser) {
      profitwell('start', { 'user_email': currentUser["email"] });
    }
  }
  private emitToProduktly(){
    const authUser = JSON.parse(localStorage.getItem('currentUser'));
    const { id:userId, archiId:architectId, licence } = authUser;
    const newDate = new Date().toISOString();

    window.Produktly.identifyUser(
      userId,
      {
        plan: licence,
        companyId: architectId,
        createdAt: newDate
      }
    );
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
