import { Injectable, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Helpers } from './helpers'
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
@Injectable()
export class UserService {

  private USER_API: String = "/api/v1/user";

  constructor(
    private http: HttpClient
  ) {}

  public updateUser(userId,body){
    let {headers} = new Helpers().getHeader();
    return this.http.patch(`${this.USER_API}/${userId}`,body, {headers})
      .pipe(map((response: any) => {
        let done = response;
        return done;
      }));
  }
  updateHasDpgfState(userId,data): Observable<any> {
    let body = JSON.stringify(data)
    let options = new Helpers().getHeader();
      return this.http.post('/api/v1/user/'+userId, body, options)
          .pipe(map((response: any) => {
              return true;
          }));
  }
    
}
