import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, NO_ERRORS_SCHEMA } from '@angular/core';
import { relatedFichesService, AcvService, DpgfPostesService} from 'src/app/_services';
import { AcvProjectComposantSidebarComponent } from '../acv-project-composant-sidebar/acv-project-composant-sidebar.component'
import { MessageService } from 'primeng/api';

import { calculate } from 'src/app/all/cstb/cctp-edition/calcul-frigo';
import { unitsCodes } from '../acv-project-composant-sidebar/calcul-frigo';

import { NgSharedModule } from 'src/app/all/ngshared.module';


declare var $:any;
var _ = require('lodash');

@Component({
    standalone: true,
    
    selector: 'app-acv-project-composant-page',
    imports: [
      NgSharedModule,  
      AcvProjectComposantSidebarComponent],
    providers: [],
    templateUrl: './acv-project-composant-page.component.html',
    styleUrls: ['./acv-project-composant-page.component.scss'],
    
})
export class AcvProjectComposantPageComponent implements OnInit {

  @ViewChild(AcvProjectComposantSidebarComponent) cctpEdition: AcvProjectComposantSidebarComponent;

  @Input() dpgfId;
  @Input() ceProject;
  @Input() acvData;
  @Input() isProjectDemo:Boolean=false;
  @Input() selectedZone;

  public ficheNoLotString='Fiches non affiliées à un lot PCE';stateModal;
  public ficheIncompleteString='Composants incomplets'
  public contributors=['Composants','Chantier']
  public entities=[];lotId:number;serialIdentif:String = '';tooltipenableLotForfaitaire = "Pour ajouter un lot forfaitaire, il faut enlever les composants présents dans ce lot";tooltipAddLotForfaitaire="Ajouter un lot forfaitaire"
  public sideBarEntities: any = [
    { label: 'Parcelle', value: 'parcelle', type: 'parcelle', trimmedValue: 'parcelle' }
  ];
  public selectEntities=[
    { label: 'Parcelle', value: 'parcelle', type: 'parcelle', trimmedValue: 'parcelle' }
  ]
  public selectedOption: any;

  public lotsPCE = [
    {
      name: "1- VRD",
       lots: ["1.1 Réseaux (extérieurs, jusqu'au domaine public)",
        "1.2 Stockage",
        "1.3 Aires de stationnement extérieures"]
    },
    {
      name: "2- Fondation et infrastructure",
      lots: ["2.1 Fondations",
        "2.2 Murs et structures enterrées ( escalier de cave, parking, …)",
        "2.3 Parcs de stationnement en superstructure à l'exception des garages des maisons individuelles ou accolées",
      ]
    },
    {
      name: "3- Maçonnerie et superstructure",
      lots: ["3.1 Eléments horizontaux - planchers, dalles, balcons",
        "3.2 Eléments horizontaux - poutres",
        "3.3 Eléments verticaux - façades",
        "3.4 Eléments verticaux - refends",
        "3.5 Eléments verticaux - poteaux",
        "3.6 Escaliers et rampes",
        "3.7 Eléments d'isolation",
        "3.8 Maçonneries diverses",]
    },
    {
      name: "4- Couverture - Etanchéité - Charpente - Zinguerie",
      lots: ["4.1 Toitures terrasse",
        "4.2 Toitures en pente",
        "4.3 Eléments techniques de toiture",]
    },
    {
      name: "5- Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures",
      lots: ["5.1 Cloisons et portes intérieures",
        "5.2 Doublages mur, matériaux de protection, isolants et membranes",
        "5.3 Plafonds suspendus",
        "5.4 Planchers surélevés",
        "5.5 Menuiseries, metalleries et quincalleries",]
    },
    {
      name: "6- Façades et menuiseries extérieures",
      lots: ["6.1 Revêtement, isolation et doublage extérieur",
        "6.2 Portes, fenêtres, fermetures, protections solaires",
        "6.3 Habillages et ossatures",]
    },
    {
      name: "7- Revêtements des sols, murs et plafonds - Chape - Peintures - Produits de décoration",
      lots: ["7.1 Revêtement des sols",
        "7.2 Revêtement des murs et plafonds",
        "7.3 Eléments de décoration et revêtements des menuiseries",]
    },
    {
      name: "8- CVC",
      lots: ["8.1 Equipements de production (chaud/froid) [hors cogénération]",
        "8.2 Systèmes de cogénération",
        "8.3 Systèmes d'émission",
        "8.4 Traitement de l'air et éléments de désenfumage",
        "8.5 Réseaux et conduits",
        "8.6 Stockage",
        "8.7 Fluides frigorigènes",]
    },
    {
      name: "9- Installations sanitaires",
      lots: ["9.1 Eléments sanitaires et robinetterie",
        "9.2 Canalisations, résaux et systèmes de traitement",]
    },
    {
      name: "10- Réseaux d'énergie (courant fort)",
      lots: ["10.1 Réseaux éléctriques",
        "10.2 Ensemble de dispositif pour la sécurité",
        "10.3 Eclairage intérieur",
        "10.4 Eclairage extérieur",
        "10.5 Equipements spéciaux",
        "10.6 Installations techniques",]
    },
    {
      name: "11- Réseaux de communication (courant faible)",
      lots: ["11.1 Réseaux éléctriques et de communications",
        "11.2 Réseaux et systèmes de contrôle et régulation",
        "11.3 Installations techniques et équipements spéciaux",]
    },
    {
      name: "12- Appareils élévateurs",
      lots: ["12 Appareils élévateurs",]
    },
    {
      name: "13- Equipements de production locale d'éléctricité",
      lots: ["13 Equipements de production locale d'éléctricité"]
    }
  ]
  public lotPCE = [
    {
      name: "1- VRD",
       lots: ["1.1 Réseaux (extérieurs, jusqu'au domaine public)",
        "1.2 Stockage",
        "1.3 Aires de stationnement extérieures"]
    },
    {
      name: "2- Fondation et infrastructure",
      lots: ["2.1 Fondations",
        "2.2 Murs et structures enterrées ( escalier de cave, parking, …)",
        "2.3 Parcs de stationnement en superstructure à l'exception des garages des maisons individuelles ou accolées",
      ]
    },
    {
      name: "3- Maçonnerie et superstructure",
      lots: ["3.1 Eléments horizontaux - planchers, dalles, balcons",
        "3.2 Eléments horizontaux - poutres",
        "3.3 Eléments verticaux - façades",
        "3.4 Eléments verticaux - refends",
        "3.5 Eléments verticaux - poteaux",
        "3.6 Escaliers et rampes",
        "3.7 Eléments d'isolation",
        "3.8 Maçonneries diverses",]
    },
    {
      name: "4- Couverture - Etanchéité - Charpente - Zinguerie",
      lots: ["4.1 Toitures terrasse",
        "4.2 Toitures en pente",
        "4.3 Eléments techniques de toiture",]
    },
    {
      name: "5- Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures",
      lots: ["5.1 Cloisons et portes intérieures",
        "5.2 Doublages mur, matériaux de protection, isolants et membranes",
        "5.3 Plafonds suspendus",
        "5.4 Planchers surélevés",
        "5.5 Menuiseries, metalleries et quincalleries",]
    },
    {
      name: "6- Façades et menuiseries extérieures",
      lots: ["6.1 Revêtement, isolation et doublage extérieur",
        "6.2 Portes, fenêtres, fermetures, protections solaires",
        "6.3 Habillages et ossatures",]
    },
    {
      name: "7- Revêtements des sols, murs et plafonds - Chape - Peintures - Produits de décoration",
      lots: ["7.1 Revêtement des sols",
        "7.2 Revêtement des murs et plafonds",
        "7.3 Eléments de décoration et revêtements des menuiseries",]
    },
    {
      name: "8- CVC",
      lots: ["8.1 Equipements de production (chaud/froid) [hors cogénération]",
        "8.2 Systèmes de cogénération",
        "8.3 Systèmes d'émission",
        "8.4 Traitement de l'air et éléments de désenfumage",
        "8.5 Réseaux et conduits",
        "8.6 Stockage",
        "8.7 Fluides frigorigènes",]
    },
    {
      name: "9- Installations sanitaires",
      lots: ["9.1 Eléments sanitaires et robinetterie",
        "9.2 Canalisations, résaux et systèmes de traitement",]
    },
    {
      name: "10- Réseaux d'énergie (courant fort)",
      lots: ["10.1 Réseaux éléctriques",
        "10.2 Ensemble de dispositif pour la sécurité",
        "10.3 Eclairage intérieur",
        "10.4 Eclairage extérieur",
        "10.5 Equipements spéciaux",
        "10.6 Installations techniques",]
    },
    {
      name: "11- Réseaux de communication (courant faible)",
      lots: ["11.1 Réseaux éléctriques et de communications",
        "11.2 Réseaux et systèmes de contrôle et régulation",
        "11.3 Installations techniques et équipements spéciaux",]
    },
    {
      name: "12- Appareils élévateurs",
      lots: ["12 Appareils élévateurs",]
    },
    {
      name: "13- Equipements de production locale d'éléctricité",
      lots: ["13 Equipements de production locale d'éléctricité"]
    }
  ]
  public lotsPCECopy = [
    {
      name: "1- VRD",
       lots: ["1.1 Réseaux (extérieurs, jusqu'au domaine public)",
        "1.2 Stockage",
        "1.3 Aires de stationnement extérieures"]
    },
    {
      name: "2- Fondation et infrastructure",
      lots: ["2.1 Fondations",
        "2.2 Murs et structures enterrées ( escalier de cave, parking, …)",
        "2.3 Parcs de stationnement en superstructure à l'exception des garages des maisons individuelles ou accolées",
      ]
    },
    {
      name: "3- Maçonnerie et superstructure",
      lots: ["3.1 Eléments horizontaux - planchers, dalles, balcons",
        "3.2 Eléments horizontaux - poutres",
        "3.3 Eléments verticaux - façades",
        "3.4 Eléments verticaux - refends",
        "3.5 Eléments verticaux - poteaux",
        "3.6 Escaliers et rampes",
        "3.7 Eléments d'isolation",
        "3.8 Maçonneries diverses",]
    },
    {
      name: "4- Couverture - Etanchéité - Charpente - Zinguerie",
      lots: ["4.1 Toitures terrasse",
        "4.2 Toitures en pente",
        "4.3 Eléments techniques de toiture",]
    },
    {
      name: "5- Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures",
      lots: ["5.1 Cloisons et portes intérieures",
        "5.2 Doublages mur, matériaux de protection, isolants et membranes",
        "5.3 Plafonds suspendus",
        "5.4 Planchers surélevés",
        "5.5 Menuiseries, metalleries et quincalleries",]
    },
    {
      name: "6- Façades et menuiseries extérieures",
      lots: ["6.1 Revêtement, isolation et doublage extérieur",
        "6.2 Portes, fenêtres, fermetures, protections solaires",
        "6.3 Habillages et ossatures",]
    },
    {
      name: "7- Revêtements des sols, murs et plafonds - Chape - Peintures - Produits de décoration",
      lots: ["7.1 Revêtement des sols",
        "7.2 Revêtement des murs et plafonds",
        "7.3 Eléments de décoration et revêtements des menuiseries",]
    },
    {
      name: "8- CVC",
      lots: ["8.1 Equipements de production (chaud/froid) [hors cogénération]",
        "8.2 Systèmes de cogénération",
        "8.3 Systèmes d'émission",
        "8.4 Traitement de l'air et éléments de désenfumage",
        "8.5 Réseaux et conduits",
        "8.6 Stockage",
        "8.7 Fluides frigorigènes",]
    },
    {
      name: "9- Installations sanitaires",
      lots: ["9.1 Eléments sanitaires et robinetterie",
        "9.2 Canalisations, résaux et systèmes de traitement",]
    },
    {
      name: "10- Réseaux d'énergie (courant fort)",
      lots: ["10.1 Réseaux éléctriques",
        "10.2 Ensemble de dispositif pour la sécurité",
        "10.3 Eclairage intérieur",
        "10.4 Eclairage extérieur",
        "10.5 Equipements spéciaux",
        "10.6 Installations techniques",]
    },
    {
      name: "11- Réseaux de communication (courant faible)",
      lots: ["11.1 Réseaux éléctriques et de communications",
        "11.2 Réseaux et systèmes de contrôle et régulation",
        "11.3 Installations techniques et équipements spéciaux",]
    },
    {
      name: "12- Appareils élévateurs",
      lots: ["12 Appareils élévateurs",]
    },
    {
      name: "13- Equipements de production locale d'éléctricité",
      lots: ["13 Equipements de production locale d'éléctricité"]
    }
  ]
  public lotsPCEwithParents= [
    {
      name: "1- VRD",
       lots: ["1- VRD",
        "1.1 Réseaux (extérieurs, jusqu'au domaine public)",
        "1.2 Stockage",
        "1.3 Aires de stationnement extérieures"]
    },
    {
      name: "2- Fondation et infrastructure",
      lots: ["2- Fondation et infrastructure",
      "2.1 Fondations",
        "2.2 Murs et structures enterrées ( escalier de cave, parking, …)",
        "2.3 Parcs de stationnement en superstructure à l'exception des garages des maisons individuelles ou accolées",
      ]
    },
    {
      name: "3- Maçonnerie et superstructure",
      lots: ["3- Maçonnerie et superstructure","3.1 Eléments horizontaux - planchers, dalles, balcons",
        "3.2 Eléments horizontaux - poutres",
        "3.3 Eléments verticaux - façades",
        "3.4 Eléments verticaux - refends",
        "3.5 Eléments verticaux - poteaux",
        "3.6 Escaliers et rampes",
        "3.7 Eléments d'isolation",
        "3.8 Maçonneries diverses",]
    },
    {
      name: "4- Couverture - Etanchéité - Charpente - Zinguerie",
      lots: ["4- Couverture - Etanchéité - Charpente - Zinguerie","4.1 Toitures terrasse",
        "4.2 Toitures en pente",
        "4.3 Eléments techniques de toiture",]
    },
    {
      name: "5- Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures",
      lots: ["5- Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures","5.1 Cloisons et portes intérieures",
        "5.2 Doublages mur, matériaux de protection, isolants et membranes",
        "5.3 Plafonds suspendus",
        "5.4 Planchers surélevés",
        "5.5 Menuiseries, metalleries et quincalleries",]
    },
    {
      name: "6- Façades et menuiseries extérieures",
      lots: ["6- Façades et menuiseries extérieures","6.1 Revêtement, isolation et doublage extérieur",
        "6.2 Portes, fenêtres, fermetures, protections solaires",
        "6.3 Habillages et ossatures",]
    },
    {
      name: "7- Revêtements des sols, murs et plafonds - Chape - Peintures - Produits de décoration",
      lots: ["7- Revêtements des sols, murs et plafonds - Chape - Peintures - Produits de décoration","7.1 Revêtement des sols",
        "7.2 Revêtement des murs et plafonds",
        "7.3 Eléments de décoration et revêtements des menuiseries",]
    },
    {
      name: "8- CVC",
      lots: ["8- CVC","8.1 Equipements de production (chaud/froid) [hors cogénération]",
        "8.2 Systèmes de cogénération",
        "8.3 Systèmes d'émission",
        "8.4 Traitement de l'air et éléments de désenfumage",
        "8.5 Réseaux et conduits",
        "8.6 Stockage",
        "8.7 Fluides frigorigènes",]
    },
    {
      name: "9- Installations sanitaires",
      lots: ["9- Installations sanitaires","9.1 Eléments sanitaires et robinetterie",
        "9.2 Canalisations, résaux et systèmes de traitement",]
    },
    {
      name: "10- Réseaux d'énergie (courant fort)",
      lots: ["10- Réseaux d'énergie (courant fort)","10.1 Réseaux éléctriques",
        "10.2 Ensemble de dispositif pour la sécurité",
        "10.3 Eclairage intérieur",
        "10.4 Eclairage extérieur",
        "10.5 Equipements spéciaux",
        "10.6 Installations techniques",]
    },
    {
      name: "11- Réseaux de communication (courant faible)",
      lots: ["11- Réseaux de communication (courant faible)","11.1 Réseaux éléctriques et de communications",
        "11.2 Réseaux et systèmes de contrôle et régulation",
        "11.3 Installations techniques et équipements spéciaux",]
    },
    {
      name: "12- Appareils élévateurs",
      lots: ["12- Appareils élévateurs","12 Appareils élévateurs",]
    },
    {
      name: "13- Equipements de production locale d'éléctricité",
      lots: ["13- Equipements de production locale d'éléctricité","13 Equipements de production locale d'éléctricité"]
    }
  ]
  public iniesCategories=[{title:"Voirie / réseaux divers (y compris réseaux intérieurs) et aménagements extérieurs de la parcelle",icon:'voirie_et_reseaux_divers.png',id:1},
  {title:"Strucure / maçonnerie / gros oeuvre / charpente divers",icon:'charpente.png',id:2},
  {title:"Façades",icon:'facades.png',id:3},
  {title:"Couverture / étanchéité",icon:'couverture__etancheite.png',id:4},
  {title:"Menuiseries intérieures et extérieures / fermetures",icon:'menuiserie_exterieure.png',id:5},
  {title:"Isolation",icon:'maconnerie__grosuvre.png',id:6},
  {title:"Cloisonnement / plafonds-suspendus",icon:'cloisons__doublages__fauxplafonds.png',id:7},
  {title:"Revêtements des sols et murs / peintures / produits de décorations",icon:'revetement_de_sol.png',id:8},
  {title:"Produits de préparation et de mise en oeuvre",icon:'demolition__depose.png',id:9},
  {title:"Equipements sanitaires et salle d'eau",icon:'plomberie__sanitaire.png',id:446},
  {title:"Panneau sandwich agroalimentaire",icon:"Aglo-Panneau_sandwich_agroalimentaire.svg",id:860},
  {title:"Approche simplifiée",icon:"Aglo-Approche_simplifiee.svg",id:822},
  {title:"Appareillage d'installation pour les réseaux d'énergie électrique et de communication (≤ 63 Ampères)",icon:"Aglo-Appareillage_installation_reseaux.svg",id:480},
  {title:"Fils et câbles",icon:"Aglo-Fils_cables.svg",id:481},
  {title:"Sécurité des personnes et contrôle d'accès",icon:'signaletique.png',id:482},
  {title:"Sécurité du bâtiment",icon:'securite_incendie.png',id:483},
  {title:"Equipements de génie climatique",icon:"Aglo-Equipements_genie_climatique.svg",id:484},
  {title:"Production locale d'énergie",icon:"Aglo-Production_locale_energie.svg",id:485},
  {title:"Matériel d'éclairage",icon:"Aglo-Eclairage.svg",id:773},
  {title:"Approche simplifiée",icon:"Aglo-Approche_simplifiee.svg",id:823},
  {title:"Eaux",icon:'assainissement__recuperationtraitement_des_eaux.png',id:828},
  {title:"Traitement des déchets",icon:"Aglo-Traitement_dechets.svg",id:849},
  {title:"Transports",icon:"transport.svg",id:852},
  {title:"Energies",icon:"Aglo-Energie.svg",id:831},
  {title:"Autre",icon:'generalites.png',id:0},
  ]
  public selectedEntity="Parcelle";
  public selectedLotPCE={
    name:"Composants relatifs à la parcelle",
    lots:["Composants relatifs à la parcelle","Ces composants concernent notamment les clôtures et la voirie hors aires de stationnement."]
  };
  public firstLotPCE={
    name: "1- VRD",
     lots: ["1- VRD",
      "1.1 Réseaux (extérieurs, jusqu'au domaine public)",
      "1.2 Stockage",
      "1.3 Aires de stationnement extérieures"]
  };
  public parcelleLot={
    name:"Composants relatifs à la parcelle",
    lots:["Composants relatifs à la parcelle","Ces composants concernent notamment les clôtures et la voirie hors aires de stationnement."]
  }
  public noLot={
    name:this.ficheNoLotString,
    lots:[this.ficheNoLotString,"Cette liste recense les composants ajoutés mais n'ayant été affilié à aucun Lot."]
  }
  public incompleteLot={
    name:this.ficheIncompleteString,
    lots:[this.ficheIncompleteString,"Cette liste recense les composants ajoutés mais n'ayant été affilié à aucun Composant ou Entité."]
  }
  public postName='';
  public postIdInies='';
  public currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
  public demandeData={id:"",terms:false,nom:"",prenom:"",metier:"",mail:"",telephone:"",entreprise:"",nature:"Creation",id_demandeur:parseInt(this.currentUser['idDemandeur']||''),quantity:1,dve:"",unity:"",poste:this.postIdInies,postName:this.postName,entity:'',contributor:'Composants',content:{
    creationArgument:"",
    creationIdentification:"",
    creationSource:"",
    creationUf:"",
    creationLabel:"",
    creationRank:{
      creationRankId:1,
      creationIsNewRank:false,
      creationRankName:"",
      creationRankParent:1
    }
  }}
  public unitsCodes=unitsCodes
  public selectedFiles=[];dpgf;
  public relatedFiles=[];
  public checkedAllFiles=[];
  public loadingPosts: boolean;
  public frigoPostParent: any;
  public frirogeneResult: any[];
  public displaySide;
  public selectedFriro;
  public addingPostInies: any;
  public nameChosenPost: any;
  public lotsACV: { name: string; }[];
  public selectedFriroi: any;
  public postInfoTab='resume';
  public infoPost: any;
  public demandesSugg: any[];
  public loadingDemand: boolean;
  public selectedLot={lot:'',father:''}
  public relatedFilesIncomplete: any[];
  public relatedFilesNoLot: any[];
  public selectedPost={lotPCE:'',contributor:''}
  public xmlFileToUpload: any;
  public currentContributor='Composants'
  public selectedBatiment=''
  public entitiesArray=[]
  public relatedFilesAllIncomplete: any[];
  public detailModalFromSideBar: boolean;relatedFicheLotForfaitaire = [];loadingForfaitaire:boolean=false;
  constructor(private relatedFichesService :relatedFichesService,
              private acvService :AcvService,
              private dpgfPostesService :DpgfPostesService,
              private messageService: MessageService,
              private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.getAllPosts('Parcelle');
    this.getLotForfaitaire();
    this.getAllPostsIncomplete();
    // this.entities=batimentNamesRSET.concat(zoneNamesRSET);

    let zonesNamesWithFather= _.get(this.acvData,'projectRSET.zonesNamesWithFather',[]);
    this.fillSidebarEntities(zonesNamesWithFather);

    $('body').css('overflow','hidden')
    this.lotsACV=[{name:'lotACV1'},{name:'lotACV2'}]
    this.selectedOption='parcelle'
    if(this.selectedZone){
      this.selectedEntity=this.selectedZone.zoneName;
      this.selectedOption=this.selectedZone.zoneName
      this.selectedBatiment=this.selectedZone.batiment
      this.selectedLotPCE=this.lotsPCEwithParents[this.selectedZone.lot-1]
    }
  }
  ngAfterViewInit(): void {
    let idElem=this.removeWhiteSpaces('nested-options-'+this.selectedOption+this.selectedBatiment);
    $(`#${idElem}`).addClass('open')
  }
  private fillSidebarEntities(batiments=[]){
    for (const key in batiments) {
      let zones=batiments[key]
      let trimmedBatiment = key.replace(/ /g, "-");
      let batimentObject = {label: `${key}`, value: `${key}`, trimmedValue: trimmedBatiment, type: 'batiment',zones:[]};
      this.sideBarEntities.push(batimentObject)
      for(let i = 0; i < zones.length; i++){
        let trimmedZone = zones[i].replace(/ /g, "-");
        let zoneObject = {label: `${zones[i]}`, value: `${zones[i]}`, trimmedValue: trimmedZone, type: 'zone',batiment:`${key}`};
        this.sideBarEntities.push(zoneObject);
        // array choices in select
        batimentObject.zones.push(zoneObject)
      }
      this.selectEntities.push(batimentObject)
    }
  }
  public removeWhiteSpaces(string){
    return string.replaceAll(' ', '').replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-')
  }
  public openNestedList(currentOption, ulId){
    /// open Nested Ul
    let trimmedId = this.removeWhiteSpaces(ulId);
    let isOpen=$(`#${trimmedId}`).hasClass('open')

    $('.nested-items-ul').removeClass('open');
    if(currentOption.value!='parcelle' && !isOpen)$(`#${trimmedId}`).addClass('open');
    /// select first item
    this.selectedOption = currentOption.value;
    this.selectedBatiment=(currentOption.type=='batiment')?currentOption.value:currentOption.batiment;
    // if(currentOption['type'] == 'parcelle'){
      // this.changeEntity('Parcelle');
    // }
    if(!isOpen) this.changeEntity(currentOption.label);
  }
  public listItemSelected(event, item){
    ///////////// NEW
    $('.nested-items-ul > li').removeClass('selected');
    $(event.currentTarget).addClass('selected');
    this.selectedZone=item.value;
    this.changeEntity(item.value);
  }

// when importing configured and others set batiment correctly selectedBatiment

  getAllPosts(entity){
    this.loadingPosts=true;
    this.relatedFichesService.listAll('',entity,'Composants',this.ceProject,false,this.selectedBatiment).subscribe(
      (data)=>{
        this.relatedFiles=data;
        this.relatedFilesNoLot=data.filter(post=>post.contributor=='Composants' && !post.lotPCE);
        this.noLot.name=this.ficheNoLotString+' ('+ this.relatedFilesNoLot.length +')'
        this.loadingPosts=false;
        this.cd.detectChanges()
      },
      (err)=>{
        this.loadingPosts=false;
        console.log(err);
      }
    )
  }
  getAllPostsIncomplete(){
    this.loadingPosts=true;
    this.relatedFichesService.listAll('','','',this.ceProject).subscribe(
      (data)=>{
        this.relatedFilesAllIncomplete=data
        this.relatedFilesIncomplete=data.filter(post=>(!((post.contributor=='Chantier' && post.entity)||(post.contributor=='Composants' && post.entity))||(!post.entity && !post.contributor && !post.lotPCE)));
        this.incompleteLot.name=this.ficheIncompleteString+' ('+ this.relatedFilesIncomplete.length +')'
        this.loadingPosts=false;
      },
      (err)=>{
        this.loadingPosts=false;
        console.log(err);
      }
    )
  }
  
  getLotForfaitaire(){
    this.relatedFichesService.getLotForfaitaire({acvID:this.acvData.ceProject,entity:this.selectedEntity}).subscribe((data)=>{
      this.relatedFicheLotForfaitaire = data;
    })
  }
  checkAllFiles(lot){
    // console.log('lot',lot);
    // console.log('selectedLotPCE',this.selectedLotPCE);
    // console.log('selectedLot',this.selectedLot);
    if(!this.selectedLotPCE.name[0].match(/^\d/)){
      if(this.selectedLotPCE.name.startsWith(this.ficheIncompleteString)){
        let filteredFiles=this.relatedFilesIncomplete
        if(this.selectedFiles && this.selectedFiles.length==this.relatedFilesIncomplete.length){
          this.selectedFiles=[];
          this.relatedFilesIncomplete.forEach((elem,index) => {
            this.relatedFilesIncomplete[index].checked=false;
          });
        }
        else {
          this.selectedFiles=[];
          this.relatedFilesIncomplete.forEach((elem,index) => {
            this.relatedFilesIncomplete[index].checked=true;
            this.selectedFiles.push(elem._id)
            this.checkedAllFiles[lot]=true
          });
        }
      }
      else if(this.selectedLotPCE.name.startsWith(this.ficheNoLotString)){
        let filteredFiles=this.relatedFilesIncomplete.filter(post=>!post.lotPCE)
        if(this.selectedFiles && this.selectedFiles.length==filteredFiles.length){
          this.selectedFiles=[];
          this.relatedFilesIncomplete.forEach((elem,index) => {
            if(!elem.lotPCE)this.relatedFilesIncomplete[index].checked=false;
          });
        }
        else {
          this.selectedFiles=[];
          this.relatedFilesIncomplete.forEach((elem,index) => {
            if(!elem.lotPCE)this.relatedFilesIncomplete[index].checked=true;
            if(!elem.lotPCE)this.selectedFiles.push(elem._id)
            this.checkedAllFiles[lot]=true
          });
        }
      }
      else{
        if(this.selectedFiles && this.selectedFiles.length==this.relatedFiles.length){
          this.selectedFiles=[];
          this.relatedFiles.forEach((elem,index) => {
            this.relatedFiles[index].checked=false;
          });
        }
        else {
          this.selectedFiles=[];
          this.relatedFiles.forEach((elem,index) => {
            this.relatedFiles[index].checked=true;
            this.selectedFiles.push(elem._id)
            this.checkedAllFiles[lot]=true
          });
        }
      }
    }
    else{
      let filteredFiles=this.relatedFiles.filter(post=>post.lotPCE==lot)
      if(this.selectedFiles[lot] && this.selectedFiles[lot].length==filteredFiles.length){
        this.selectedFiles[lot]=[];
        this.relatedFiles.forEach((elem,index) => {
          if(elem.lotPCE==lot)this.relatedFiles[index].checked=false;
        });
      }
      else {
        this.selectedFiles[lot]=[];
        this.relatedFiles.forEach((elem,index) => {
          if(elem.lotPCE==lot){
            this.relatedFiles[index].checked=true;
            this.selectedFiles[lot].push(elem._id)
          }
          this.checkedAllFiles[lot]=true
        });
      }
    }
  }
  checkOneFile(fileId,lot){
    if(!this.selectedLotPCE.name[0].match(/^\d/)){
      let filteredFiles=[];
      let isIncomplete=false;
      if(this.selectedLotPCE.name.startsWith(this.ficheIncompleteString)) filteredFiles=this.relatedFilesIncomplete
      else if(this.selectedLotPCE.name.startsWith(this.ficheNoLotString)) filteredFiles=this.relatedFilesIncomplete.filter(post=>!post.lotPCE)
      else filteredFiles=this.relatedFiles

      let index=this.selectedFiles.indexOf(fileId);
      let index2=this.relatedFiles.findIndex((a)=>a._id==fileId);
      if(index2==-1) {
        isIncomplete=true;
        index2=this.relatedFilesIncomplete.findIndex((a)=>a._id==fileId);
      }
      if(index==-1) {
        this.selectedFiles.push(fileId)
        if(!isIncomplete) this.relatedFiles[index2].checked=true;
        else this.relatedFilesIncomplete[index2].checked=true;
      }
      else {
        this.selectedFiles.splice(index,1);
        if(!isIncomplete) this.relatedFiles[index2].checked=false;
        else this.relatedFilesIncomplete[index2].checked=false;
        this.checkedAllFiles[lot]=false;
      }
      if(this.selectedFiles.length==filteredFiles.length) this.checkedAllFiles[lot]=true;
    }
    else{
      let filteredFiles=this.relatedFiles.filter(post=>post.lotPCE==lot)
      let index=this.selectedFiles[lot].indexOf(fileId);
      let index2=this.relatedFiles.findIndex((a)=>a._id==fileId);
      if(index2==-1) index2=this.relatedFilesIncomplete.findIndex((a)=>a._id==fileId);
      if(index==-1) {
        this.selectedFiles[lot].push(fileId)
        this.relatedFiles[index2].checked=true;
      }
      else {
        this.selectedFiles[lot].splice(index,1);
        this.relatedFiles[index2].checked=false;
        this.checkedAllFiles[lot]=false;
      }
      if(this.selectedFiles[lot].length==filteredFiles.length) this.checkedAllFiles[lot]=true;
    }
  }
  getPostOpenModal(post){
    this.relatedFichesService.getIniesPosts([post.idFicheBase]).subscribe(
      (data)=>{
        this.openInfoModal(data[0])
      }
    )
  }
  openInfoModal(post){
    this.detailModalFromSideBar=false
    $('#post-info-modal').modal('show');
    // parse CategoryName
    post.categoryName=post.categoryName.replace(/ *\([^)]*\) */g, "").replaceAll('|',' > ');
    let categoryArray=post.categoryName.split('>');
    categoryArray=categoryArray.reverse()
    post.categoryName=categoryArray.join(">")
    post.categoryName=post.categoryName.substring(2);
    // parse name
    post.nom=this.parseName(post.nom)
    // parse documentPath
    post.documentPathArray=((post.documentPath||'').split(',')||[])
    // parse dateEdition
    let dateEdition=(new Date(post.dateEdition)||new Date());
    // dateEdition=dateEdition.split("/");
    // dateEdition[1]=(parseInt(dateEdition[1])+5).toString();
    // dateEdition.join("/")
    post.dateEdition=dateEdition.getMonth()+'/'+(dateEdition.getFullYear()+5)
    //parse indicateurs
    let indicateurs={};
    post.indicateurs.forEach(element => {
      let phases=(element['phases']||[]);
      let phase7=phases.find((phases)=>phases.idPhase==7)
      indicateurs[element['idIndicateur']]=phase7.quantite
    });
    let ind=[{
        name:"Impacts environnementaux",data:{
          "Réchauffement climatique (kg CO2 eq.)":(post.impact||{}).TotalCycleDeVie / post.quantiteUF||1,
          "Appauvrissement de la couche d'ozone (kg CFC-11 eq.)":indicateurs[20],
          "Acidification des sols et de l'eau (kg SO2 eq.)":indicateurs[21],
          "Eutrophisation (kg (PO4)3- eq.)":indicateurs[22],
          "Formation d'ozone photochimique (kg C2H4 eq.)":indicateurs[23],
          "Epuisement des ressources abiotiques - éléments (kg Sb eq.)":indicateurs[24],
          "Epuisement des ressources abiotiques - combustibles fossiles (MJ)":indicateurs[25],
          "Pollution de l'air (m³)":indicateurs[26],
          "Pollution de l'eau (m³)":indicateurs[27]
        }
      },{
        name:"Consommation des ressources",data:{
          "Utilisation de l'énergie primaire renouvelable, à l'exclusion des ressources d'énergie primaire ... (MJ)":indicateurs[28],
          "Utilisation des ressources d'énergie primaire renouvelables utilisées en tant que matières premières (MJ)":indicateurs[29],
          "Utilisation totale des ressources d'énergie primaire renouvelables ... (MJ)":indicateurs[30],
          "Utilisation de l'énergie primaire non renouvelable, à l'exclusion ... (MJ)":indicateurs[31],
          "Utilisation des ressources d'énergie primaire non renouvelables utilisées en tant que matières premières (MJ)":indicateurs[32],
          "Utilisation totale des ressources d'énergie primaire non renouvelables ... (MJ)":indicateurs[33],
          "Utilisation de matière secondaire (kg)":indicateurs[34],
          "Utilisation de combustibles secondaires renouvelables (MJ)":indicateurs[35],
          "Utilisation de combustibles secondaires non renouvelables (MJ)":indicateurs[36],
          "Utilisation nette d'eau douce (m³)":indicateurs[37],
        }
      },{
        name:"Déchets",data:{
        "Déchets dangereux éliminés (kg)":indicateurs[38],
        "Déchets non dangereux éliminés (kg)":indicateurs[39],
        "Déchets radioactifs éliminés (kg)":indicateurs[40],
        }
      },{
      name:"Flux sortants",data:{
        "Composants destinés à la réutilisation (kg)":indicateurs[41],
        "Matériaux destinés au recyclage (kg)":indicateurs[42],
        "Matériaux destinés à la récupération d'énergie (kg)":indicateurs[43],
        "Energie fournie à l'extérieur (MJ)":indicateurs[44],
      }}]
    post.ind=ind;
    this.infoPost=post
  }
  parseName(name){
    return name.split('- ID')[0]
  }
  changeEntitySelect(selectedBatiment,id){
    let allFiles=this.relatedFilesIncomplete.concat(this.relatedFilesNoLot).concat(this.relatedFiles)
    let i=allFiles.findIndex(post=>post._id==id)
    allFiles[i].entity=(selectedBatiment.target.value||'').split('^')[0]
    if((selectedBatiment.target.value||'').split('^').length>1 && (selectedBatiment.target.value||'').split('^')[1]) this.selectedBatiment=(selectedBatiment.target.value||'').split('^')[1]
    this.updatePost(allFiles[i]);
    this.refreshPosts(false,[allFiles[i]])
  }
  updatePost(postData,reload=false){
    postData.batiment=this.selectedBatiment
    // postData.entity=(postData.entity||'').split('^')[0]
    if(postData.lotPCE=='Ces composants concernent notamment les clôtures et la voirie hors aires de stationnement.')postData.lotPCE=''
    this.relatedFichesService.update(postData).subscribe(
      (data)=>{
        if(reload) this.getAllPosts(this.selectedEntity)
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  duplicateOneFile(file){
    this.loadingPosts=true;
    this.relatedFichesService.duplicate([file]).subscribe(
      (data)=>{
        this.refreshPosts(true,data)
        this.cd.detectChanges();
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  deleteOneFile(file){
    this.loadingPosts=true;
    this.relatedFichesService.deleteOne(file).subscribe(
      (data)=>{
        this.refreshPosts(false,[file])
        this.cd.detectChanges();
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  deleteSelectedFiles(){
    let arr=this.relatedFiles.filter((a)=>a.checked==true)
    let arr2=this.relatedFilesIncomplete.filter((a)=>a.checked==true)
    arr=arr.concat(arr2)
    this.loadingPosts=true;
    this.relatedFichesService.deleteMany(arr).subscribe(
      (data)=>{
        this.relatedFiles=this.relatedFiles.filter((a)=>!a.checked);
        this.loadingPosts=false;
        this.clearSelected()
        this.refreshPosts(false,arr)
        this.cd.detectChanges()
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  duplicateSelectedFiles(){
    let arr=this.relatedFiles.filter((a)=>a.checked==true)
    let arr2=this.relatedFilesIncomplete.filter((a)=>a.checked==true)
    arr=arr.concat(arr2)
    this.loadingPosts=true;
    this.relatedFichesService.duplicate(arr).subscribe(
      (data)=>{
        this.clearSelected()
        this.refreshPosts(true,data)
        this.cd.detectChanges();
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  
  refreshPosts(isDuplicate,postsToTreat){
    if(this.selectedEntity=='Incomplete'){
      if(isDuplicate) this.relatedFilesIncomplete=this.relatedFilesIncomplete.concat(postsToTreat)
      else this.relatedFilesIncomplete=this.relatedFilesIncomplete.filter((a)=>postsToTreat.indexOf(a) === -1)
      this.relatedFilesNoLot=this.relatedFiles.filter(post=>post.contributor=='Composants' && !post.lotPCE);
    }
    else if(this.selectedLotPCE.name==this.noLot.name){
      if(isDuplicate) this.relatedFilesNoLot=this.relatedFilesNoLot.concat(postsToTreat)
      else this.relatedFilesNoLot=this.relatedFilesNoLot.filter((a)=>postsToTreat.indexOf(a) === -1)
    }
    else {
      if(isDuplicate) this.relatedFiles=this.relatedFiles.concat(postsToTreat)
      else {this.relatedFiles=this.relatedFiles.filter((a)=>postsToTreat.indexOf(a) === -1);this.relatedFicheLotForfaitaire=this.relatedFicheLotForfaitaire.filter((lot)=>postsToTreat[0].lotPCE!=lot.lotPCE);}
      this.relatedFilesNoLot=this.relatedFiles.filter(post=>post.contributor=='Composants' && !post.lotPCE);
    }
    this.noLot.name=this.ficheNoLotString+' ('+ this.relatedFilesNoLot.length +')'
    this.incompleteLot.name=this.ficheIncompleteString+' ('+ this.relatedFilesIncomplete.length +')'
    this.loadingPosts=false;
  }
  openFrirogeneModal(post){
    this.frigoPostParent=post;
    this.relatedFichesService.searchWord('',{isFrirogene:true},'true').subscribe(
      (data)=>{
        data.filter(post=>post.name)
        // show frigogene in same page
        this.frirogeneResult=data;
        $('#frirogene-modal').modal('show');
      }
    )
  }
  updateFrigogeneData(quantity,postFile){
    let post=_.get(postFile,'frigogenePost',{})
    post.frigogeneQuantity=quantity.target.value
    this.updatePost(post);
  }
  calculateTotal(){
    let result=0;
    for (let index = 0; index < this.relatedFiles.length; index++) {
      let frigoImpact=_.get(this.relatedFiles[index],'frigogenePost.totalImpact',0);
      let frigogeneQuantity=_.get(this.relatedFiles[index],'frigogenePost.frigogeneQuantity',0);
      result+=this.relatedFiles[index].totalImpact*this.relatedFiles[index].quantity;
      if(frigoImpact)result+=frigoImpact*frigogeneQuantity;
    }
    return result;
  }
  openRemploi(){
    // this.openRemploiEvent.emit();
  }
  // parent
  public closeSide(){
    this.displaySide=false;
    // this.store.dispatch(actions.setCctpOnDpgf({}));
    $("body").css("overflow","scroll");
  }
  selectFriro(i){
    this.selectedFriroi=i;
  }
  validateFriro(){
    this.selectedFriro=this.frirogeneResult[this.selectedFriroi];
    if(this.frigoPostParent.frigogenePost) this.selectedFriro._id=this.frigoPostParent.frigogenePost._id
    this.selectedFriro.poste=this.postIdInies;
    this.selectedFriro.frigoPostParent=this.frigoPostParent;
    this.loadingPosts=true;
    let totalImpact=calculate(1,this.frigoPostParent,1,this.selectedFriro);
    this.selectedFriro.totalImpact=(totalImpact||{}).TotalCycleDeVie
    this.selectedFriro.ceProject=this.ceProject;
    if(this.frigoPostParent.frigogenePost){
      this.relatedFichesService.update(this.selectedFriro).subscribe(
        (data)=>{
          this.getAllPosts(this.selectedEntity)
          $('#frirogene-modal').modal('hide');
          this.loadingPosts=false;
          // this.cd.detectChanges();
        },
        (err)=>{
          console.log(err);
        }
      )
    }
    else{
      this.relatedFichesService.create(this.selectedFriro).subscribe(
        (data)=>{
          this.getAllPosts(this.selectedEntity)
        $('#frirogene-modal').modal('hide');
        this.loadingPosts=false;
          // this.cd.detectChanges();
        },
        (err)=>{
          console.log(err);
        }
      )
    }
  }
  addFrigoToPost(file){
    file.poste=this.postIdInies;
    file.frigoPostParent=this.frigoPostParent;
    this.loadingPosts=true;
    let totalImpact=calculate(1,this.frigoPostParent,1,file);
    file.totalImpact=(totalImpact||{}).TotalCycleDeVie
    file.ceProject=this.ceProject;
    
  }
  showFrigo(postFile){
    // return (["26720","26718","27571","27577"].includes(postFile.idFicheBase))||(!postFile.phase14 && (postFile.idNomenclature=='703' || postFile.idNomenclature=='946' || postFile.idNomenclature=='947' || postFile.idNomenclature=='948' || postFile.idNomenclature=='949' || postFile.idNomenclature=='950' || postFile.idNomenclature=='726'))
    return (["INIES_D[RE20210628_114606","INIES_D[RE20210628_114459","INIES_DTNP20211012_091507","INIES_DJEY20211012_091735"].includes(postFile.serialIdentifier))||(!postFile.phase14 && (postFile.idNomenclature=='703' || postFile.idNomenclature=='946' || postFile.idNomenclature=='947' || postFile.idNomenclature=='948' || postFile.idNomenclature=='949' || postFile.idNomenclature=='950' || postFile.idNomenclature=='726'))
  }
  onSelectionChangeTab(tab){
    this.postInfoTab=tab;
  }
  setInfoPost(post){
    this.infoPost=post;
    this.postInfoTab='resume'
    this.detailModalFromSideBar=true
  }
  expandDetail(event){
    let parent = $(event.target).closest('.single-detail');
    let clickedItem = parent.find('.detail-description');
    let openArrow = parent.find('.open-arrow');
    let closeArrow = parent.find('.close-arrow');

    $('.close-arrow').addClass('hidden-item');
    $('.open-arrow').removeClass('hidden-item');

    if(clickedItem.hasClass('expand')){
      clickedItem.removeClass('expand');
    }else{
      $('.detail-description').removeClass('expand');
      clickedItem.addClass('expand');
      openArrow.addClass('hidden-item');
      closeArrow.removeClass('hidden-item');
    }
  }
  addFileToPost(){
    this.cctpEdition.addFileToPost({batiment:this.selectedBatiment,entity:this.selectedEntity,lotPCE:this.selectedLot.lot},true);
  }
  openConfirmationPost(post){
    this.cctpEdition.openConfirmationPost(post);
    $('#post-info-modal').modal('hide');
  }
  setaddingPostInies(file){
    this.addingPostInies=file.addingPostModal;
    this.nameChosenPost=file.name;
    if(!file.addingPost) {
      $('#add-post-modal').modal('hide');
    }
    else{
      this.displaySide=false;
      if(file.lotPCE && file.lotPCE.includes('Lot forfaitaire'))file.lotPCE = file.lotPCE;
      else file.lotPCE=this.selectedLot.lot;
      if(this.selectedLotPCE.name.startsWith('Fiches non')){
        file.lotPCE='';
        this.relatedFilesIncomplete.push(file);
        let noLot=this.relatedFilesIncomplete.filter(post=>!post.lotPCE);
        this.noLot.name=this.ficheNoLotString+' ('+ noLot.length +')'
        let incomplete=this.relatedFilesIncomplete.filter(post=>post.lotPCE==''||!post.contributor);
        this.incompleteLot.name=this.ficheIncompleteString+' ('+ incomplete.length +')'
      }
      else {this.relatedFiles.push(file);}
      $('#add-post-modal').modal('hide');
      $('#add-lot-forfitaire').modal('hide');
      this.cd.detectChanges();
    }
    $('body').css('overflow','hidden');
  }
  demandePost(type){
    this.demandeData={id:"",terms:false,nom:"",prenom:"",metier:"",mail:"",telephone:"",entreprise:"",nature:"Creation",id_demandeur:parseInt(this.currentUser['idDemandeur']||''),quantity:1,dve:"",unity:"",poste:this.postIdInies,postName:this.postName,entity:'',contributor:"Composant",content:{
      creationArgument:"",
      creationIdentification:"",
      creationSource:"",
      creationUf:"",
      creationLabel:"",
      creationRank:{
        creationRankId:1,
        creationIsNewRank:false,
        creationRankName:"",
        creationRankParent:1
      }
    }}
    this.demandeData.nature=type
    $('#add-demand-modal').modal('show');
  }
  submitDemande(){
    this.loadingDemand=true;
    this.demandeData.poste='';
    this.demandeData.postName='';
    this.demandeData.entity=this.selectedLot.lot;
    this.demandeData['batiment']=this.selectedBatiment
    this.demandeData['idProject']= this.ceProject;
    // console.log('this demande',this.demandeData)
    this.relatedFichesService.sendDemande(this.demandeData).subscribe(
      (data)=>{
        // console.log('data',data)
        if(this.demandeData.nature=="Creation"){
          this.currentUser['idDemandeur']=data['idDemandeur'];
          localStorage.setItem('currentUser',JSON.stringify(this.currentUser))

          this.messageService.add({ key: 'toast', severity: 'success', summary: 'Demande envoyée', detail: 'Votre demande de création de DED a bien été transmise aux services INIES, un mail récapitulatif vous a été envoyé', life: 5000 });
        }
        let post={
          idBase : 0,
          idFicheBase : 25765,
          postName:this.postName,
          serialIdentifier : "INIES_ICOM20210120_120948",
          nom : this.demandeData.content.creationLabel,
          uniteUF : this.demandeData.unity,
          entity:this.selectedEntity,
          batiment:this.selectedBatiment,
          quantity : this.demandeData.quantity,
          dve : this.demandeData.dve,
          typeDonnees : 8,
          poste:this.postIdInies,
          commentaire:(data['data']||{}).idDemand || this.demandeData.id,
          lotPCE:this.selectedLot.lot||'',
          contributor:this.currentContributor,
        }
        this.cctpEdition.addFileToPost(post);
        // else this._notificationService.success("Mise à jour Demande","La demande a été mis à jour", {timeOut: 3000});
        this.loadingDemand=false;
        $('#add-demand-modal').modal('hide');
        this.cd.detectChanges();
      },
      (err)=>{
        this.loadingDemand=false;
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Traiter Demande', detail: 'Echec', life: 5000 });
      }
    )
  }
  getDemendSugg(){
    this.relatedFichesService.getDemandSugg().subscribe(
      (data)=>{
        this.demandesSugg=data;
        $('.autocomplete-items-demand').css({'visibility': 'visible'});
        this.cd.detectChanges()
      }
    )
  }
  setChosenDemand(result){
    this.demandeData.id=result.idDemand;
    this.demandeData.dve=result.dve;
    this.demandeData.quantity=result.quantity;
    this.demandeData.unity=result.unit;
    this.demandeData.content.creationLabel=result.name;
    $('.autocomplete-items-demand').css({'visibility': 'hidden'});
  }
  addPostIniesReemploi(){
    let post={
      idBase : 0,
      idFicheBase : 27546,
      postName:this.postName,
      serialIdentifier : "INIES_ICOM20211011_160700",
      nom : this.demandeData.content.creationLabel,
      uniteUF : this.demandeData.unity,
      entity:this.selectedEntity,
      batiment:this.selectedBatiment,
      quantity : this.demandeData.quantity,
      dve : this.demandeData.dve,
      typeDonnees : 8,
      poste:this.postIdInies,
      lotPCE:this.selectedLot.lot=="Ces composants concernent notamment les clôtures et la voirie hors aires de stationnement."?'':this.selectedLot.lot||'',
      contributor:this.currentContributor,
    }
    this.cctpEdition.addFileToPost(post);
    $('#add-demand-modal').modal('hide');
  }
  openSide(lot){
    $("body").css("overflow","hidden");
    this.displaySide=!this.displaySide;
    this.selectedLot={lot:lot,father:this.selectedLotPCE.name}
  }
  changeEntity(selectedEntity){
    this.selectedEntity=selectedEntity;
    if(selectedEntity=='Parcelle') this.setLotSelected(this.parcelleLot)
    else if(selectedEntity=='Incomplete'){
      this.setLotSelected(this.incompleteLot)
      this.selectedOption='';
      this.selectedBatiment='';
      $('.nested-items-ul').removeClass('open');
    } 
    else this.setLotSelected(this.firstLotPCE)
    if(selectedEntity!='Incomplete') {this.getAllPosts(selectedEntity);this.getLotForfaitaire()}
    this.cd.detectChanges()
  }
  setLotSelected(lot){
    // console.log('lot',lot)
    // console.log('this.ficheIncompleteString',this.ficheIncompleteString)
    // console.log('this.ficheNoLotString',this.ficheNoLotString)
    this.lotId = parseInt(lot.name.split("-")[0]);
    if(!lot.lots.filter(a=>a==lot.name).length && !lot.name.startsWith(this.ficheIncompleteString) && !lot.name.startsWith(this.ficheNoLotString)) lot.lots.unshift(lot.name)
    else lot.lots[0]=lot.name
    this.selectedLotPCE=lot
    this.checkedAllFiles=[]
    this.clearSelected()
  }
  filterPostByLot(lot){
    if(this.selectedLotPCE.name.startsWith(this.ficheIncompleteString)) return this.relatedFilesIncomplete
    else if(this.selectedLotPCE.name.startsWith(this.ficheNoLotString)) return this.relatedFilesNoLot
    else if(this.selectedEntity=='Parcelle') return this.relatedFiles
    else return this.relatedFiles.filter(post=>post.lotPCE==lot);
  }
  FilerLotForfaitaire(lot){
    if(lot.includes('10-'))return this.relatedFiles.filter(post=>post.lotPCE=='10- Lot forfaitaire');
    else if(lot.includes('11-')) return this.relatedFiles.filter(post=>post.lotPCE=='11- Lot forfaitaire');
  }
  iterateLots(){
    if(this.selectedLotPCE.name.startsWith(this.ficheIncompleteString) || this.selectedLotPCE.name.startsWith('Fiches non')) return this.selectedLotPCE.lots
    else if(this.selectedEntity=='Parcelle') return this.parcelleLot.lots
    else return this.selectedLotPCE.lots
  }
  showAdd(){
    return !(this.selectedLotPCE.name.startsWith(this.ficheIncompleteString) || this.selectedLotPCE.name.startsWith(this.ficheNoLotString))
  }
  showOperations(){
    if(typeof(this.selectedFiles)=='object'){
      for (const [key, value] of Object.entries(this.selectedFiles)) {
        if(Array.isArray(value) && value.length) return true
        else if(typeof(value)=='string') return true
      }
    }
  }
  checkedLot(lot){
    return this.checkedAllFiles[lot]
  }
  isLotStartsWithNumber(lot){
    return lot.match(/^\d/);
  }
  openAffiliationModal(post){
    this.selectedPost=post;
    $('#edit-post-modal').modal('show');
  }
  setPostPCE(oneLot){
    this.selectedPost['lotPCE']=oneLot
    // this.updatePost(this.selectedPost)
  }
  setContributeur(contributor){
    this.selectedPost['contributor']=contributor;
    // this.updatePost(this.selectedPost)
  }
  setUpdatePost(post){
    this.updatePost(post,true)
    $('#edit-post-modal').modal('hide');
  }
  clearSelected(){
    this.selectedFiles=[]
    this.relatedFiles.forEach(post=>post.checked=false)
    this.relatedFilesIncomplete.forEach(post=>post.checked=false)
    this.checkedAllFiles=[];
    this.selectedLotPCE.lots.forEach(element => {
      if(element[0].match(/^\d/)){
        this.selectedFiles[element]=[]
      }
    });
  }
  openModalChoice(id){
    if(id=='Reemploi') this.demandePost(id)
    else $(id).modal('show');
  }
  private RemoveJsonTextAttribute(value,parentElement){
    var keyNo = Object.keys(parentElement._parent).length;
    var keyName = Object.keys(parentElement._parent)[keyNo-1];
    parentElement._parent[keyName] = value;
  }
  importXml(event){
    this.addingPostInies=true;
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.readUploadedFileAsText(file).then((data)=>{
        let parsedXml = this.relatedFichesService.parseXmlStringToJSON(data.toString());
        
        this.relatedFichesService.checkXml({data:data.toString()}).subscribe(
          (data)=>{
            let file={
              idBase : parsedXml['EPDC']['ConfiguratorCode'],
              idFicheBase : parsedXml['EPDC']['EPDCId'],
              postName:this.postName,
              nom : parsedXml['EPDC']['Name'],
              uniteUF : this.unitsCodes[(parsedXml['EPDC']['UnitId']||1)],
              quantite : parsedXml['EPDC']['Quantity'],
              dve : parsedXml['EPDC']['TLD'],
              poste:this.postIdInies,
              lotPCE:this.selectedLot.lot||'',
              contributor:"Composant",
              entity:this.selectedEntity,
              batiment:this.selectedBatiment,
            }
            this.addingPostInies=false;
            this.cctpEdition.addFileToPost(file);
            $('#import-files-modal').modal('hide');
          },
          error => {
            /// if the CSTB license is suspended console this message (For Louis)
            if(error.status == 500 && error.error.err.message.includes("Cannot read properties of undefined")){
              console.log("Louis it's CSTB GUY");
            }
          }
        )
      })
    }
  }
  showButtonLotForfaitaire(lot){
    if(lot==10)return (this.relatedFicheLotForfaitaire.filter(post=>post.lotPCE=='10- Lot forfaitaire'&&post.entity==this.selectedEntity)).length>0?false:true;
    else if(lot==11)return (this.relatedFicheLotForfaitaire.filter(post=>post.lotPCE=='11- Lot forfaitaire'&&post.entity==this.selectedEntity)).length>0?false:true;
  }
  disabledButtonAddLotForfaitaire(lot){
    if(lot==10)return this.relatedFiles.filter(post=>post['lotPCE']?.includes('10.')).length==0 ? true : false;
    else if(lot==11) return this.relatedFiles.filter(post=>post['lotPCE']?.includes('11.')).length==0 ? true : false;
  }
  readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  }
  goNextPrevious(type){
    let index=this.lotsPCE.findIndex(lot=>lot.name==this.selectedLotPCE.name)
    if(index!=-1 && index<this.lotsPCE.length-1 && type=='next') this.setLotSelected(this.lotsPCE[index+1])
    if(index!=-1 && index>0 && type=='previous') this.setLotSelected(this.lotsPCE[index-1])
  }
  filterLotsPCE(word){
    for (let i = 0; i < this.lotsPCECopy.length; i++) {
      this.lotsPCECopy[i].lots=this.lotsPCE[i].lots.filter(lot=>lot.toLowerCase().includes(word.toLowerCase()))
    }
  }
  openModalLotForfitaire(state,lotIdSelected){
    this.stateModal = state;
    this.lotId = lotIdSelected;
    $('#add-lot-forfitaire').modal('show');
  }
  getIdSerialFiche(serialId){
    this.serialIdentif = serialId;
  }
  disableButtonAddLotForfaitaire(){
    let [x] = this.relatedFiles.filter((file)=>file.serialIdentifier==this.serialIdentif);
    return x ? true:false;
  }
  showLotForfaitaire(id){
    let x = this.relatedFiles.find((file)=> file.lotPCE==`${id}- Lot forfaitaire`);
    return x ? true:false;
  }
  getFicheIniesById(id){
    this.loadingForfaitaire = true;
    this.acvService.getFicheIniesById(this.serialIdentif,{entity:this.selectedEntity,lotPCE:`${id}- Lot forfaitaire`,acvID:this.acvData.ceProject,stateModal:this.stateModal}).subscribe(
      (data)=>{
        let lotForfitaire = data;
        lotForfitaire['lotPCE']=`${id}- Lot forfaitaire`;
        lotForfitaire['entity']=this.selectedEntity;
        lotForfitaire['batiment']=this.selectedBatiment;
        this.relatedFicheLotForfaitaire.push(lotForfitaire)
        if(this.stateModal=='update'){
          let toDelete = this.relatedFiles.filter((file)=>file.lotPCE==`${id}- Lot forfaitaire`&& file.serialIdentifier!=this.serialIdentif)
          this.refreshPosts(false,toDelete)
        }
        // this.relatedFiles.push(lotForfitaire)
        this.cctpEdition.addFileToPost(lotForfitaire,false);
        this.loadingForfaitaire = false;;
      },
      (err)=>{
        console.log(err);
        this.loadingForfaitaire = false;;
      }
    )
  }
}
