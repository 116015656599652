<!-- App Header -->
<div class="container loading-page" *ngIf="loadingData">
  <div style="margin-top:70px;">
    <!-- <app-spinner></app-spinner> -->
  </div>
</div>
<div class="all">
  <app-header [cssClass]="'fixed-bar'"></app-header>
  <app-ce-project-nav
    [disable]="loadingData"
    [projectId]="projectId"
    selected="devis"
    [DPGFId]="dpgfId">
    </app-ce-project-nav>

  <div class="dpgf-container">
    <div class="row">
      <router-outlet></router-outlet>
    </div>
  </div>

  <!-- Need Subscriiption -->
  <app-need-subscription-popup-2></app-need-subscription-popup-2>
</div>
