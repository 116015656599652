import { Component, OnInit , HostListener, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute , Params} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Store , select} from '@ngrx/store';
import * as fromSelectors from 'src/app/dpgf/dce-edition/_store';
import { loadDpgf,setMode,setType,setAny } from 'src/app/dpgf/dce-edition/_store/dpgf.actions';
import { trigger,state,style,transition,animate} from '@angular/animations';
//import { ArchitectModule } from '../architect.module';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { TableDpgfComponent } from 'src/app/dpgf/dce-edition/table-dpgf/table-dpgf.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { HeadDpgfComponent } from 'src/app/dpgf/dce-edition/head-dpgf/head-dpgf.component';


@Component({
    selector: 'app-archi-dpgf-minor-version',
    imports:[
      
      SpinnerComponent,
      HeaderComponent,
      TableDpgfComponent,
      HeadDpgfComponent,
      NgSharedModule
    ],

    templateUrl: './archi-dpgf-minor-version.component.html',
    styleUrls: ['./archi-dpgf-minor-version.component.scss'],
    animations: [
        trigger('appear', [
            state('hide', style({
                top: '-50px',
            })),
            state('show', style({
                top: '10px',
            })),
            transition('hide => show', animate('2000ms ease-in')),
            transition('show => hide', animate('2000ms ease-in')),
        ]),
    ],
    standalone: true,

})
export class ArchiDpgfMinorVersionComponent implements OnInit {
  public dpgfId:string;
  public loadingData$;
  public isSticky:boolean = false;
  public dpgf;
  // public dpgf$;
  public state: string = 'hide';

  @HostListener('window:scroll', ['$event']) onScrollEvent() {
    if (window.pageYOffset > 70) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }
  constructor(private title: Title,
              private route: ActivatedRoute,
              private store: Store) { }

  ngOnInit() {
    this.store.dispatch(setType("dpgf-minor-v"));
    this.store.dispatch(setAny({name : "loadingData" , value : true}));
    this.getDPGF()
    this.loadingData$ = this.store.pipe(select(fromSelectors.getAny , {name : "loadingData"} ))
    this.store.dispatch(setMode("view") );
    this.title.setTitle('Aglo - Editer Mon DPGF' );
  }
  public getDPGF(){
    this.route.params.forEach((params: Params) => {
      if (params['dpgfId']) {
        this.dpgfId = params['dpgfId'];
        this.store.dispatch(loadDpgf( {dpgfId : this.dpgfId}));
        // this.dpgf$ = this.store.pipe(select(fromSelectors.getDpgf))
        this.store.pipe(select(fromSelectors.getDpgf)).subscribe(val=>{
          if(val.data){
            this.dpgf = val;
          }
        })
      }
    })
  }
  public getPdf(){
    //@yassine
  }
}
