import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";
import { Helpers } from './helpers'

@Injectable()
export class CeTaskService {
    public token: string;
    public savedProjects:any = {};

    constructor(private http: HttpClient) {
    }

    getAllProjectTasks(projectID) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/ce-projects/${projectID}/tasks`, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    create(projectID, data): Observable<any> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/ce-projects/${projectID}/tasks`, body, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    update(projectId,tasksId, data): Observable<any> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.patch(`/api/v1/ce-projects/${projectId}/tasks/${tasksId}` , body, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    delete(projectId,tasksId, data): Observable<any> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
      return this.http.delete(`/api/v1/ce-projects/${projectId}/tasks/${tasksId}`, options)
      .pipe(map((response: any) => {
        return response.data;
      }));
    }

}
