import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ArchitectService, AuthService } from 'src/app/_services';
//import { ArchitectModule } from '../architect.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    selector: 'app-archi-reef-doc-redirect',
    templateUrl: './archi-reef-doc-redirect.component.html',
    imports:[
      
      NgSharedModule
    ],
    styleUrls: ['./archi-reef-doc-redirect.component.scss'],
    standalone: true,

})
export class ArchiReefDocRedirectComponent implements OnInit {
  public docId: string;
  userData: any;
  doctype: string;

  constructor(private router: ActivatedRoute,private route: Router,private architectService:ArchitectService,private authService: AuthService) { }

  ngOnInit(): void {
    this.docId = this.router.snapshot.paramMap.get('docId');
    this.doctype = this.router.snapshot.paramMap.get('doctype');
    this.architectService.accessReef({docType:this.doctype,docId:this.docId},false).subscribe(
      (data)=>{
        window.location.href = data;
      },(err)=>{
        console.log('err',err);
      }
    )
    // this.authService.getUserLicenceStripeUser().subscribe(
    //   (data)=>{
    //     if(data.data.hasReef)this.architectService.accessReef({docType:this.doctype,docId:this.docId},false).subscribe(
    //       (data)=>{
    //         window.location.href = data;
    //       },(err)=>{
    //         console.log('err',err);
    //       }
    //     )
    //     else this.route.navigate(['/architect/reef-redirect'],{ queryParams: { docId:this.docId,docType:this.doctype }})
    //     // this.userData.hasReef=true;
    //   }
    // )
  }

}
