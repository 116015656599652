import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";
import { Helpers } from './helpers'

@Injectable()
export class CeProjectService {
    public token: string;
    public savedProjects:any = {};

    constructor(private http: HttpClient) {
    }
    getProject(projectID) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get('/api/v1/ce-projects/' + projectID, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    getProjectLoggs(projectID) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/ce-projects/${projectID}/loggs`, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    getAllProject(sortBy='createdAt', direction='-1') : Observable<any>{
      let fields = 'licence,typeConsultation,archiStatus,createdAt,architect,adresse,architect,budget,dateTravaux,demo,_id,typeConsultation,type,title,status,natureTravaux,filesNotificationActivated,dpgf,inviteArtisan,rdvDispo,dateDce,dateTravaux,mainImage,addressCodePostal,addressCity,addressCountry,user,isFavorite, collaborateurs'
      let options = new Helpers().getHeader();
      let query = `sortBy=${sortBy}&direction=${direction}`;
      return this.http.get(`/api/v1/ce-projects?fields=${fields}&${query}`, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
    }
    create(data): Observable<any> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.post('/api/v1/ce-projects', body, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    update(projectId, data): Observable<any> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.patch('/api/v1/ce-projects/' + projectId , data, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    updateRdv(projectId, data): Observable<any> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
      return this.http.patch('/api/v1/ce-projects/' + projectId +'/rdv-artisan', body, options)
      .pipe(map((response: any) => {
        return response.data;
      }));
    }
    updateStatus(projectId, data): Observable<any> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
      return this.http.patch('/api/v1/ce-projects/' + projectId +'/status', body, options)
      .pipe(map((response: any) => {
        return response.data;
      }));
    }
    requestArtisan(projectId, data): Observable<any> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
      return this.http.post('/api/v1/ce-projects/' + projectId +'/request-artisan', body, options)
      .pipe(map((response: any) => {
        return response.data;
      }));
    }
    inviteCollaborateur(projectId, data): Observable<any> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
      return this.http.post('/api/v1/ce-projects/' + projectId +'/invite-collaborateur', body, options)
      .pipe(map((response: any) => {
        return response.data;
      }));
    }
    updateRequestArtisan(projectId, data): Observable<any> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
      return this.http.patch('/api/v1/ce-projects/' + projectId +'/request-artisan', body, options)
      .pipe(map((response: any) => {
        return response.data;
      }));
    }
    delete(projectId): Observable<any> {
      let options = new Helpers().getHeader();
        return this.http.delete('/api/v1/ce-projects/' + projectId, options)
            .pipe(map((response: any) => {
                return response;
            }));
    }

    removeCollaborateur(projectId, collabId): Observable<any> {
      let options = new Helpers().getHeader();
      let body ={collabId};

      return this.http.post('/api/v1/ce-projects/' + projectId +'/remove-collaborateur', body, options)
      .pipe(map((response: any) => {
        return response;
      }));
    }

    removeProject(projectId) : Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.delete('/api/v1/ce-projects/' + projectId +'/remove-project', options)
      .pipe(map((response: any) => {
        return response;
      }));
    }
    getCeProjectDateAndBudget(ceProjectId) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/ce-projects/${ceProjectId}/getDateAndBudget`, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    // duplicate project, with it's dpgf
    public duplicate(ceProjectId): Observable<any> {
      let body = {};
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/ce-projects/${ceProjectId}/duplicate`, body, options)
        .pipe(map((response: any) => {
          return response.data;
        }));
    }
}
