<div class="row">
  <div class="col-md-1"></div>
  <div id="form-top" class="col-md-10 col-xs-12 top">
    <ul class="nav nav-pills nav-justified">
      <li [ngClass]="{'active' : currentRoutePath == 'chiffrage'}" class="nav-item"><a data-toggle="tab" (click)="navigateTo('chiffrage')">Chiffrage</a></li>
      <li [ngClass]="{'active' : currentRoutePath == 'comparatif'}" class="nav-item active"><a data-toggle="tab" (click)="navigateTo('comparatif')">Comparatif</a></li>
      <li [ngClass]="{'active' : currentRoutePath == 'hypothese'}" class="nav-item"><a data-toggle="tab" (click)="navigateTo('hypothese')">Hypothèse</a></li>
      <li [ngClass]="{'active' : currentRoutePath == 'graphique'}" class="nav-item"><a data-toggle="tab" (click)="navigateTo('graphique')">Graphique</a></li>
    </ul>
  </div>
</div>
