<app-acv-welcome-screen *ngIf="!showWelcomeScreen" [isDemo]="isDemo"></app-acv-welcome-screen>
<div *ngIf="showWelcomeScreen" class="content-cstb">
  <div class="env">
    <div *ngIf="view!='savedPosts'" class="input-container">
      <p class="search-title">Rechercher une fiche INIES</p>
      <div class="flex">
        <div class="search-form-control form-group">
          <input class="form-control string required" [(ngModel)]="searchFileTerm" type="text"
          (ngModelChange)="triggerSearch($event)"
          id="search_query" placeholder="Nom du poste" autocomplete="off"/>
          <button name="button" type="submit" (click)="searchPostInies()" class="btn btn-flat">
            <i class="fa fa-search"></i>
          </button>
          <div id="myInputautocomplete-list" class="autocomplete-items1">
            <div *ngIf="!loadingSugg">
              <div *ngFor="let result of iniesSuggResult;let i = index" (mousedown)="getOneCategory(result.categoryName,true)" class="unit-proposition">
                <div class="flex">
                  <p style="font-weight: 900;">{{searchFileTerm}}</p>
                  <p style="padding:0px 4px">dans</p>
                  <a class="category-suggestion" [innerHTML]="result | iniesSuggestion : searchFileTerm"></a>
                </div>
              </div>
            </div>
            <div *ngIf="loadingSugg">
              <app-spinner></app-spinner>
            </div>
          </div>
        </div>
        <div>
          <div class="bottom-buttons-container">
            <a class="btn btn-purple options-a" (click)="openModal('#choose-demand-modal')">Je ne trouve pas ma fiche</a>
            <a class="btn btn-purple options-a" aria-hidden="true" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="margin-left: 12px;"> Ajouter un autre type de composant</a>
            <ul class="dropdown-menu dropdown-import" aria-labelledby="dropdownMenuButton">
              <li (click)="$event.stopPropagation();openRemploi('#import-files-modal')">
                <span class="menu-text pointer">Importer une fiche configurée</span>
              </li>
              <li (click)="$event.stopPropagation();openRemploi('Reemploi')">
                <span class="menu-text pointer">Ajouter un composant issu du réemploi</span>
              </li>
            </ul>
          </div>
          <a class="back-saved-posts" *ngIf="componentView=='cctp'" (click)="sendChangeView('savedPosts')">Voir les fiches affiliées à mon poste</a>
        </div>
      </div>
    </div>
    <div class="row main-container">
      <div class="import-post" *ngIf="view=='importPosts' && iniesCategories.length">
        <p >Le poste {{cctpEditStore?.postDesignation}} ne dispose pas encore de description environnementale...</p>
        <h5 style="margin: 24px 0px;color: #0A1747;"><strong>Parcourez les fiches par catégorie de produit</strong> </h5>
        <div *ngFor="let oneLotInies of iniesCategories">
          <h6><strong>{{oneLotInies?.title}}</strong> </h6>
          <div class="row search-engine-category">
            <div class="col-md-4" *ngFor="let oneCategoryKey of oneLotInies.sousCategories" (click)="getOneCategory(oneCategoryKey.id)">
              <div class="notification">
                <div class="img-container">
                  <img [src]="'assets/dpgf/Icon_DPGF/'+oneCategoryKey.icon" height="38"/>
                </div>
                <div class="notification-content">
                  <p>{{oneCategoryKey?.title}}</p>
                </div>
              </div>
            </div>
          </div>
          <br><br>
        </div>
        <div class="bottom-buttons">
        </div>
      </div>
      <div class="row mb-5 category-bar" *ngIf="view=='searchInterface'">
        <div class="col-lg-3 no-padding filter-bar" style="width: 26%;min-height: 780px;">
          <div class="collapsible" >
            <span (click)="expandFilter($event)">Famille de produit</span>
            <img src="/assets/dpgf/arrow-right.svg" (click)="expandFilter($event)" class="open-arrow float-arrow">
            <img src="/assets/dpgf/arrow-down.svg" (click)="expandFilter($event)" class="close-arrow float-arrow hidden-item">
          </div>
          <div class="content expand">
            <app-tree-list *ngIf="!loadingCategories" [treeData]="selectedCategory" [isRoot]='true' [numPostsCategory]='numPostsCategory' (getCategoryEvent)="getOneCategory($event)"></app-tree-list>
            <app-spinner *ngIf="loadingCategories"></app-spinner>
          </div>
          <div class="delete-filter" (click)='resetFilter()'>Réinitialiser</div>
          <button class="collapsible" (click)="expandFilter($event)">
            <span>Type de fiche</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow float-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow float-arrow hidden-item">
          </button>
          <div class="content">
            <br>
            <div class="chip-filter">
              <div (click)="setFilterAttribute([1,2],'declarationType',$event)" class="chip pointer no-shadow chip2 one-chip declarationTypeChip2 declarationTypeChip1" pTooltip="'Les données environnementales spécifiques comprennent les FDES/PEP déclarés par les fabricants ou par des groupes ou syndicats de fabricants.">
                Données spécifiques (FDES/PEP)
              </div>
              <div (click)="setFilterAttribute([3],'declarationType',$event)" class="chip pointer no-shadow chip1 one-chip declarationTypeChip3" pTooltip="Les données environnementales par défaut (DED), qui correspondent à des données fournies par le ministère pour assurer une bonne complétude de la base. Pour encourager la création de données spécifiques, les impacts sont largement majorés.">
                Donnée par défaut
              </div>
              <div (click)="setFilterAttribute([4],'declarationType',$event)" class="chip pointer no-shadow chip4 one-chip declarationTypeChip4" pTooltip="Les données conventionnelles de service (DES) sont mises à disposition par le ministère en charge de la construction et portent sur les impacts des énergies ou des services (transport, eau potable, eaux usées, déchets, fluides frigorigènes).">
                Conventionnelle
              </div>
            </div>
          </div>
          <div class="collapsible flex" (click)="expandFilter($event)">
            <span>Nom de l'organisme déclarant</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow float-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow float-arrow hidden-item">
          </div>
          <div class="content">
            <br>
            <div style="margin-bottom: 16px;">
              <input type="text" class="form-control mb4" style="margin-bottom: 0px;" placeholder="Nom de l'organisme déclarant" (keyup)="searchOrganisme()" (focusout)="setVisibilityDropdown('.autocomplete-items-organisme','hidden')" [(ngModel)]="filter.organismeName" autocomplete="off">
              <div class="autocomplete-items-organisme">
                <div *ngFor="let oneOrganismeSugg of organismeSugg;let i = index" (mousedown)="selectOrganisme(oneOrganismeSugg.organismeName)" class="unit-proposition">
                  <div class="flex">
                    <p style="font-weight: 900;">{{oneOrganismeSugg.organismeName}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div class="collapsible" (click)="expandFilter($event)">
            <span>Etiquette COV</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow float-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow float-arrow hidden-item">
          </div>
          <div class="content">
            <div class="flex-wrap margin-top-12">
              <div class="one-ticket pointer" (click)="setFilterAttribute('APlus','airRating');setSelected($event)">A+</div>
              <div class="one-ticket pointer" (click)="setFilterAttribute('A','airRating');setSelected($event)">A</div>
              <div class="one-ticket pointer" (click)="setFilterAttribute('B','airRating');setSelected($event)">B</div>
              <div class="one-ticket pointer" (click)="setFilterAttribute('C','airRating');setSelected($event)">C</div>
            </div>
            <br>
          </div>
          <div class="collapsible" (click)="expandFilter($event)">
            <span>Performance principale de l'UF</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow float-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow float-arrow hidden-item">
          </div>
          <div class="content performance-container">
            <br>
            <div class="col-12 form-group">
              <!-- <label for="UF">Performance principale de l'UF</label> -->
              <!-- <select class="form-control" style="background: white;" [(ngModel)]="filter.performanceUF"> -->
                <!-- <option value="AND MORE">AND MORE</option> -->
              <!-- </select> -->
              <!-- (focus)="clearInput('performanceUF')" (focusout)="unclearInput('performanceUF')" -->
              <input class="form-control mb4" type="text" [(ngModel)]="filter.performanceUF"  (keyup)="filterPerf()" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" autocomplete="off"/>
              <img src="/assets/archi/select-arrow.svg" class="select-arrow" style="top: 43px;">
              <div id="performance-dropdown" class="dropdown-menu performance-dropdown scroller-1" aria-labelledby="dropdownMenuButton1">
                <div *ngIf="!filter.performanceUF">
                  <p style="font-weight: 900;" (click)="filter.performanceUF='Toutes';searchPostInies()">Toutes</p>
                  <div *ngFor="let perf of performanceUFArray">
                    <p (click)="filter.performanceUF=perf;searchPostInies()">{{perf}}</p>
                  </div>
                </div>
                <div *ngIf="filter.performanceUF">
                  <p style="font-weight: 900;" (click)="filter.performanceUF='Toutes';searchPostInies()">Toutes</p>
                  <div *ngFor="let perf of performanceUFArrayCopy">
                    <p (click)="filter.performanceUF=perf;searchPostInies()">{{perf}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="collapsible" (click)="expandFilter($event)">
            <span>Lieu de production</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow float-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow float-arrow hidden-item">
          </div>
          <div class="content">
            <div class="flex-space-between" style="padding: 17px 8px;">
              <div *ngFor="let item of filter.productionPlace| keyvalue" class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" [checked]="item.value" (change)="filter.productionPlace[item.key]=!filter.productionPlace[item.key];searchPostInies()">
                <label class="form-check-label" (click)="filter.productionPlace[item.key]=!filter.productionPlace[item.key];searchPostInies()">{{item.key}}</label>
              </div>
            </div>
          </div>
          
          <div class="collapsible" (click)="expandFilter($event)">
            <span>Date de mise en ligne</span>
            <img src="/assets/dpgf/arrow-right.svg" class="open-arrow float-arrow">
            <img src="/assets/dpgf/arrow-down.svg" class="close-arrow float-arrow hidden-item">
          </div>
          <div class="content performance-container" style="top: -3px;">
            <div class="col-12 form-group">
              <div class="form-control mb4" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" autocomplete="off">{{filter.dateMiseEnLigne}}</div>
              <img src="/assets/archi/select-arrow.svg" class="select-arrow">
              <div id="date-dropdown" class="dropdown-menu performance-dropdown scroller-1" aria-labelledby="dropdownMenuButton2">
                <p style="font-weight: 900;" (click)="filter.dateMiseEnLigne='Toutes';searchPostInies()" value="Toutes">Toutes</p>
                <p (click)="filter.dateMiseEnLigne='Dernière semaine';searchPostInies()" value="Dernière semaine">Dernière semaine</p>
                <p (click)="filter.dateMiseEnLigne='Dernier mois';searchPostInies()" value="Dernier mois">Dernier mois</p>
                <p (click)="filter.dateMiseEnLigne='Trois dernier mois';searchPostInies()" value="Trois dernier mois">Trois dernier mois</p>
                <p (click)="filter.dateMiseEnLigne='Six dernier mois';searchPostInies()" value="Six dernier mois">Six dernier mois</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-9 filter-bar" style="width: 74%;">
          <app-spinner *ngIf="loadingSearchPosts" style="margin-top: 48px;"></app-spinner>
          <div *ngIf="!loadingSearchPosts" class="row">
            <!-- correct -->
            <!-- <div class="col-md-4" style="height: 117px;margin-top: 12px;" *ngFor="let post of iniesResult | paginate: { itemsPerPage: 15, currentPage: iniesPostPage}"> -->
              <div class="col-md-4" style="height: 117px;margin-top: 12px;" *ngFor="let post of iniesResult">
              <div class="card" [ngClass]="post.declarationType? 'declarationType'+post.declarationType:'declarationType1'">
                <div class="card-title">{{post.nom}}</div>
                <p>Changement climatique</p>
                <div class="card-score"> <i class="fa fa-leaf goodscore"> </i><strong>{{post.impact?.TotalCycleDeVie | formatPrice}}</strong> kg éq.CO2 </div>
                <div class="hoverbtn ">
                  <div class="hoverbtncenter">
                    <a class="btn btn-cards" (click)="openConfirmationPost(post)">Ajouter à mon poste</a>
                  </div>
                  <a class="btn btn-gradient btn-gradient-position no-shadow" (click)="openInfoModal(post)">En savoir plus <i class="fa fa-arrow-right"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-container" *ngIf="iniesResult.length && !loadingSearchPosts">
            <pagination-controls previousLabel="Précédente" nextLabel="Suivante" (pageChange)="iniesPostPage = $event"></pagination-controls>
          </div>
          <div *ngIf="!iniesResult.length && !loadingSearchPosts">
            <img src="assets/archi/empty_db.svg" class="empty-result">
            <p class="no-result-title">Aucun resultat !</p>
          </div>
        </div>
      </div>
      <!-- <app-spinner *ngIf="loadingPosts"></app-spinner> -->
    </div>
  </div>
</div>