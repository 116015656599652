import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone:true,
  name: 'parsePercentageImpact'
})
export class ParsePercentageImpactPipe implements PipeTransform {

  transform(value, co2) {
    if(value) return (value / co2||100) * 100;
    else return 0
  }

}
