import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';

// Helpers
import { Helpers } from 'src/app/_services/helpers';
import { CeProjectInvitesService } from 'src/app/_services';

@Component({
  selector: 'app-enterprises-lots-table',
  standalone: true,
  imports: [
    NgSharedModule
  ],
  templateUrl: './enterprises-lots-table.component.html',
  styleUrl: './enterprises-lots-table.component.scss'
})
export class EnterprisesLotsTableComponent {

  @Input() lots:any = [];
  @Input() totalHt:number = 100;
  @Output() openAddAgenyModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchAgencies: EventEmitter<any> = new EventEmitter<any>();
  @Output() openArchitectAgencyModal: EventEmitter<any> = new EventEmitter<any>();  

  public formattedLotsData: any = [];
  public lotsDataFormatted: boolean = false;
  public searchQuery:string = "";
  public showSearchInput: boolean = true;
  public openedLots:any = {};

  public StatusColorsClasses: any = {
    'offre_remise': 'sign-green',
    'en_cours': 'sign-yellow',
    'en_attente': 'sign-light-blue',
    'a_consulter': 'sign-blue',
    'ne_chiffre_pas': 'sign-grey',
  }
  /// remove the disabled State
  public disabledColorsActiveState: any = {
    'sign-disabled-green': 'sign-green',
    'sign-disabled-yellow': 'sign-yellow',
    'sign-disabled-light-blue': 'sign-light-blue',
    'sign-disabled-blue': 'sign-blue',
    'sign-disabled-grey': 'sign-grey',
  }
  private indexOrderStatusMapper: any = {
    'offre_remise': 1,
    'en_cours': 2,
    'en_attente': 3,
    'a_consulter': 4,
    'ne_chiffre_pas': 5,
  };
  
  public numberOfPricingLots: any = 0;

  constructor(
    private ceProjectInvitesService: CeProjectInvitesService,
  ){}

  ngOnChanges(changes: SimpleChanges){
    if(changes.lots){
      this.formatLots(changes.lots.currentValue);
    }
  }
  
  public toggleSearchInput(){
    this.showSearchInput = !this.showSearchInput;
  }

  public expandTableRow(lotId){
    let lotIsOpened = !!this.openedLots[lotId];
    
    this.openedLots[lotId] = !lotIsOpened;
  }

  private formatLots(lots){
    const helpers = new Helpers();

    let result = [];
    
    for(let i = 0; i < lots.length; i++){
      let currentLot = lots[i];

      const { id, indexLot, name, icon, totalArchi, invites } = currentLot;
      
      let singleLotData = {
        id,
        indexLot,
        indexOrder: 0,
        name,
        icon,
        totalArchi: totalArchi || null,
        resumes: [
          {count: 0, key: 'offre_remise', color: 'sign-disabled-green'},
          {count: 0, key: 'en_cours', color: 'sign-disabled-yellow'},
          {count: 0, key: 'en_attente', color: 'sign-disabled-light-blue'},
          {count: 0, key: 'a_consulter', color: 'sign-disabled-blue'},
          {count: 0, key: 'ne_chiffre_pas', color: 'sign-disabled-grey'},
        ],
        artisansNames: [], // only artisans names
        artisansCards: [] // artisans details
      }

      for(let j = 0; j < invites.length; j++){
        let currentInvite = currentLot.invites[j];
        const { artisan, signedLots, isFactice, facticeArtisanInfo, pricingStatus, status, dateDceSent, deliveryDate, sentPricingDate, refusalDate } = currentInvite;
        const { name, logo } = artisan;
        const agencyName = isFactice ? (facticeArtisanInfo?.name||'') : name;
        const dpgfPricing = currentInvite.dpgfPricing || null;

        // the combination of status & pricingStatus of (ceProjectInvites) returns a specific status  
        const newProjectInviteStatus = helpers.getCeProjetInviteStatus(status, pricingStatus);
        
        /// returns the css class based on artisan Status 
        const pricingColor = this.getStatusColors(newProjectInviteStatus);    

        // set ceprojectInvite
        singleLotData["invitation"] = currentInvite;

        // set column
        singleLotData["column"] = newProjectInviteStatus;

        ///////////////////////
        // artisans names
        ///////////////////////
        const indexOrder = this.indexOrderStatusMapper[newProjectInviteStatus]; // set indexOrder based on the status

        const artisanName = {
          name: agencyName, 
          color: pricingColor,
          indexOrder,
        };
        singleLotData.artisansNames.push(artisanName);

        ///////////////////////
        /// resumes
        ///////////////////////
        // modify resumes count
        singleLotData.resumes = this.setResumesCount(singleLotData.resumes, newProjectInviteStatus);

        ///////////////////////
        // artisans cards
        ///////////////////////
        let singleCardData = {
          name: agencyName,
          logo: logo?.cdnUrl || "/assets/rob/helmet.png",
          color: pricingColor,
          column: newProjectInviteStatus,
          invitation:currentInvite,
          status,
          indexOrder,
          //dpgfPricing,
          deliveryDate,
          refusalDate,
          sentPricingDate,
          dateDceSent
        }

        // the artisan pricing for each lot
        if(dpgfPricing){
          let foundLot = dpgfPricing.data.find(lot => lot["id"] == id);
          singleCardData["artisanLotPrice"] = foundLot?.total || "";
        }
        // check if the lot is signed
        if(signedLots){
          let lotIsSigned = signedLots.find(lot => lot["id"] == id);
          if(lotIsSigned){
            singleCardData["lotIsSigned"] = true;
          }
        }

        singleLotData.artisansCards.push(singleCardData);        
      }
      // sort artisans names by indexOrder
      singleLotData.artisansNames = singleLotData.artisansNames.sort((a, b) => a.indexOrder - b.indexOrder);

      // sort artisans cards by indexOrder
      singleLotData.artisansCards = singleLotData.artisansCards.sort((a, b) => a.indexOrder - b.indexOrder);

      result.push(singleLotData);
    }

    this.formattedLotsData = result; 

    this.lotsDataFormatted = true;
  }

  private getStatusColors(status){
    return this.StatusColorsClasses[status];
  }

  private setResumesCount(resumesArray, projectInviteStatus){
    let updatedResumes = [];

    for(let i = 0; i < resumesArray.length; i++){
      let currentResume = resumesArray[i];
      const { key, color } = currentResume;

      if(key == projectInviteStatus){
        // increase the count 
        currentResume["count"]++;
      }
      updatedResumes.push(currentResume);
    }

    return updatedResumes;
  }

  public emitOpenAddAgenyModal(lot){
    this.openAddAgenyModal.emit(lot);
  }
  public emitSearchAgencies(){    
    this.searchAgencies.emit(this.searchQuery);
  }

  public emitOpenArchitectAgencyModal({invitation, column}){
    let assignedLots=invitation.lots;
    let dpgfPricingLots=invitation.dpgfPricing?.data;
    
    let openArchitectArtisanPopUP=true;
    if(dpgfPricingLots){
      this.addTotalLotToAssignedLots(assignedLots,dpgfPricingLots);
      if(invitation.deliveryDate){
        invitation.deliveryDate=new Date(invitation.deliveryDate);
      }
    }
    console.log();
    invitation.lots = assignedLots;
    if(invitation.pricingStatus=='pricing-done' && invitation.status!='signe' ){
      this.getSentPricingDate(invitation);
    }
    this.openArchitectAgencyModal.emit({invitation, column,numberOfPricingLots:this.numberOfPricingLots, openArchitectArtisanPopUP});
  } 

  private getSentPricingDate(invitation){
    let finishDcePricingArray=[];
    let countFinishDcePricing=0;
    this.ceProjectInvitesService.loadArchiLogs(invitation._id).subscribe(
      data => {
        for(let i=0; i<data.length;i++){
          if(data[i].type=='finish_dce_pricing'){
            finishDcePricingArray.push(data[i]);
            countFinishDcePricing++;
          }
        }
        for(let j=0; j<finishDcePricingArray.length;j++){
          if(finishDcePricingArray[j].params.indice==countFinishDcePricing){
            invitation.sentPricingDate=finishDcePricingArray[j].createdAt;
          }
        }
      },
      err => {
       // this.messageService.add({ key: 'toast', severity: 'danger', summary: "Loading Mails", detail: "Mails Loading Failed", life: 5000 });
      }
    )
  }

  private addTotalLotToAssignedLots(assignedLots,dpgfPricingLots){
    this.numberOfPricingLots = 0;
    for(let i=0;i<assignedLots.length;i++){
      for(let j=0;j<dpgfPricingLots.length;j++){
        if(dpgfPricingLots[j].id==assignedLots[i].id){
          assignedLots[i]['totalttcArchi']=dpgfPricingLots[j]['totalttcArchi'];
          assignedLots[i]['total']=dpgfPricingLots[j]['total'];
          if(dpgfPricingLots[j]['total']!=0){
            this.numberOfPricingLots++;
          }
        }
      }
    }
  }
  public clearSearchQuery(){
    this.searchQuery = "";
    this.emitSearchAgencies();
  }


}
