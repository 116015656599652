import { Component, OnInit, Input, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { AddRecommandationsContcatModalComponent } from '../add-recommandations-contcat-modal/add-recommandations-contact-modal.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $: any;
@Component({
    standalone: true,
    selector: 'app-booster-profile-page-demndes-recommandation',
    imports:[
      NgSharedModule,
      AddRecommandationsContcatModalComponent
    ],
    templateUrl: './booster-profile-page-demndes-recommandation.component.html',
    styleUrls: ['./booster-profile-page-demndes-recommandation.component.scss'],
})
export class BoosterProfilePageDemndesRecommandationComponent implements OnInit {

  @Input() recommandation: any = [];
  @Output() saveContacts: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public openAddContactModal(){
    $("#add-recommandation-contact-modal").modal("show");
  }

  public saveNewContacts(contacts){
    this.recommandation.push(...contacts);
    
    this.emitSaveContacts(this.recommandation);
  }

  public removeContact(contactIndex){
    this.recommandation = this.recommandation.filter((item, index) => index != contactIndex);

    this.emitSaveContacts(this.recommandation);
  } 

  public emitSaveContacts(contacts){
    this.saveContacts.emit(contacts);
  }

}
