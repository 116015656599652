import { Component, OnInit,Input, EventEmitter,Output, NO_ERRORS_SCHEMA } from '@angular/core';
import { UntypedFormBuilder,  Validators,  } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { IdentifySegement } from 'src/app/all/segment/identify';
import { ErrorService,CeProjectService, AuthService, ArchitectService } from 'src/app/_services';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';


declare var $;

@Component({
  standalone:true,
  selector: 'app-ce-project-collaborateurs',
  imports:[DpgfModule,NgSharedModule],
  providers: [],
  templateUrl: './ce-project-collaborateurs.component.html',
  styleUrls: ['./ce-project-collaborateurs.component.scss']
})
export class CeProjectCollaborateursComponent implements OnInit {
  @Input()projectId:String = "";
  @Input()projectLicence:String = "";
  @Input()isDemoProject:boolean = false;
  @Input()collaborateurs:any = [];
  @Output()done = new EventEmitter<boolean>();

  public projectForm:any;
  private subscriptions: Array<Subscription> = [];
  public formErrors = {'role':'', 'email': '', 'rigths':''};
  public features;
  public setLoading:Boolean = false;emailExist=false;emailAlreadyExist="";
  public firstValueEnvirenementObservable = new Observable<boolean>(observer => {
    observer.next(environment.onPremise);
    observer.complete();
  });
  private validationMessages = {
   'role': {
     'required': "Le titre du collaborateur est obligatoire."
   },
   'email': {
     'required': "L'email du collaborateur est obligatoire.",
   }
  }
  constructor(private fb: UntypedFormBuilder,
              private CeProjectService: CeProjectService,
              public authService: AuthService,
              private ErrorService: ErrorService,
              private router: Router,
              private identifySegement:IdentifySegement,
              private architectService :ArchitectService
              ) { }

  ngOnInit() {
    this.createForm();
    this.authService.features.subscribe((f)=>{
      this.features = f ;
    })
  }
  public createForm() {
      this.projectForm = this.fb.group({
        role: ['', Validators.required],
        email: ['', Validators.required],
        rigths :{value: ['admin'], disabled: true},
      });
      this.subscriptions.push(this.projectForm.valueChanges.subscribe(data => this.onValueChanged(data)));
  }
  //Generate Error
  public onValueChanged(data ? : any) {
    if (!this.projectForm) {
      return;
    }
    const form = this.projectForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
  //Mark as touched
  public markFormAsTouched():void {
    if (!this.projectForm) {
      return;
    }
    const form = this.projectForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      control.markAsDirty();
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
    }
  }
  public isValid(){
      this.markFormAsTouched();
      return this.projectForm.valid
  }

  public sendCollabInvite(){
    if(this.isValid()) {
      this.setLoading = true;
      let newInvite:any = this.projectForm.value;
      newInvite.email = newInvite.email.trim();
      this.subscriptions.push(
        this.CeProjectService.inviteCollaborateur(this.projectId, newInvite)
        .subscribe(()=>{
          $("#add-collaborateur").modal("hide")
          this.setLoading = false;
          this.done.emit(true)
          // segment event
          if(!environment.onPremise){
            window.analytics.track('add_partner',{properties:{acValue: 'add_partner'}});
            this.identifySegement.identifyNumCollabs(this.collaborateurs.length+1);
          }
        },
        error => {
            $("#add-collaborateur").modal("hide")
            this.setLoading = false;
            this.ErrorService.manageError(error,'' );
            console.log("error",error)
        })
      )
    }
  }
  public checkMailExistence(){
    this.subscriptions.push(
      this.architectService.checkMailExistence({email:this.projectForm.value.email}).subscribe((data) => {
        this.emailExist = Object.keys(data).length>0;
        this.emailAlreadyExist = !this.emailExist ? "L'utilisateur ne fait pas partie d'Aglo" : ''
      })
    )
  }
  redirectToLicencesPage(){
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
    $('#upgrade-licence').modal('toggle');
  }

  removeCollaborater(collabId){
    this.subscriptions.push(
      this.CeProjectService.removeCollaborateur(this.projectId, collabId).subscribe(
        data => {
          this.collaborateurs =this.collaborateurs.filter((e, i) => e._id != collabId);
          // segment event
          if(!environment.onPremise)window.analytics.track('remove_partner',{properties:{acValue: 'remove_partner'}});
        },
        error => {
            this.ErrorService.manageError(error,'' );
        }
      )
    );
  }

  openCollabPopup(){
    /// if project is demo don't add collabs
    if(this.isDemoProject) return;

    if(this.features.collaborateur){
      $('#add-collaborateur').modal('show');
    }else{
      $('#upgrade-modal').modal('show');
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }
}
