import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-restore-dce-version-modal',
  standalone: true,
  imports: [],
  templateUrl: './confirm-restore-dce-version-modal.component.html',
  styleUrl: './confirm-restore-dce-version-modal.component.scss'
})
export class ConfirmRestoreDceVersionModalComponent {

  @Output() confirmRestoreVersion: EventEmitter<any> = new EventEmitter<any>();

  public emitConfirmRestoreVersion(){
    this.confirmRestoreVersion.emit();
  }

}
