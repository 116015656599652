import { Component, OnInit ,ViewChild, NO_ERRORS_SCHEMA } from '@angular/core';
import { Router, ActivatedRoute,Params } from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { NgSharedModule } from 'src/app/all/ngshared.module';

//Components
import { ArtisanSignupGeneralComponent } from '../../../artisan/artisan-signup-general/artisan-signup-general.component';

//Services
import { MessageService } from 'primeng/api';
import { ArtisanService } from '../../../_services/index';

import { ArtisanSignupFileComponent } from 'src/app/artisan/artisan-signup-file/artisan-signup-file.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { HeaderComponent } from 'src/app/all/header/header.component';
declare var $:any;


@Component({
    selector: 'app-artisan-subscribe-general',
    imports:[
    HeaderComponent,
    ArtisanSignupGeneralComponent,
    SpinnerComponent,
    NgSharedModule
],
    providers: [],
    templateUrl: './artisan-subscribe-general.component.html',
    styleUrls: ['./artisan-subscribe-general.component.scss'],
    standalone: true,
})
export class ArtisanSubscribeGeneralComponent implements OnInit {
  public artisan: any;
  public loading:Boolean = false;
  private subscriptions: Array<Subscription> = [];
  @ViewChild(ArtisanSignupGeneralComponent) form: ArtisanSignupGeneralComponent
  constructor(private title: Title,
              private router: Router,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private artisanService: ArtisanService,) {
    this.artisan = {}
   }

  ngOnInit() {
    this.title.setTitle('BAM - S\'inscrire' );
    this.scrollToTop();
    this.getMail();
  }
  public getMail(){
    this.subscriptions.push(
      this.route.queryParams.subscribe((params: Params) => {
        if(params['email']){
          this.artisan.emailAgency = params['email'];
        }
      })
    )
  }
  private scrollToTop(){
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }
  save(){
    this.loading = true;
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let artisanId = currentUser.artisanId;
    let isValid = this.form.isValid()
    let agencyData = this.form.getFormData()
    if(isValid){
      this.artisanService.update(artisanId, agencyData)
          .subscribe(
              data => {
                  this.loading = false;
                  this.router.navigate(['artisan/subscribe3']);
                  this.messageService.add({ key: 'toast', severity: 'success', summary: 'Informations principales enregistrées', detail: 'Vos informations principales ont été enregistrées', life: 5000 });
              },
              error => {
                  console.log("error",error)
                  this.loading = false;
                  this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: error.message, life: 5000 });

              });
    }else{
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Formulaire incomplet', detail: "Veuillez remplir tous les champs obligatoires", life: 5000 });
      this.loading = false;
    }
  }

  }
