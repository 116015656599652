import { Component, EventEmitter, Input, OnInit, Output, NO_ERRORS_SCHEMA } from '@angular/core';

import { AcvResultsBriefViewComponent } from '../acv-results-components/acv-results-brief-view/acv-results-brief-view.component';
import { AcvResultsExigencesViewComponent } from '../acv-results-components/acv-results-exigences-view/acv-results-exigences-view.component';
import { AcvResultsAutresIndicateursViewComponent } from '../acv-results-components/acv-results-autres-indicateurs-view/acv-results-autres-indicateurs-view.component';
import { AcvResultsFichesPlusImpactantesViewComponent } from '../acv-results-components/acv-results-fiches-plus-impactantes-view/acv-results-fiches-plus-impactantes-view.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone: true,

    selector: 'app-acv-results-page',
    imports:[
      NgSharedModule,
      AcvResultsBriefViewComponent,
      AcvResultsExigencesViewComponent,
      AcvResultsAutresIndicateursViewComponent,
      AcvResultsFichesPlusImpactantesViewComponent
    ],
    templateUrl: './acv-results-page.component.html',
    styleUrls: ['./acv-results-page.component.scss'],
})
export class AcvResultsPageComponent implements OnInit {
  @Input() dpgfId;
  @Input() ceProject;
  @Input() projectTitle;
  @Input() acvData;
  @Output() showComposantEvent: EventEmitter<String> = new EventEmitter<String>();

  public selectedOption: string = 'en_bref';
  public currentView: string = 'en_bref';

  public sidebarList: any = [
    {
      title: 'En bref', value :'en_bref'
    },
    {
      title: 'En détail', value :'en_detail',
      children: [
        { title: 'Exigences RE2020', value: 'exigences_re2020' },
        { title: 'Les fiches les plus impactantes', value: 'les_fiches_les_plus_impactantes' },
        { title: 'Autres indicateurs', value: 'autres_indicateurs' },
      ]
    },
  ]

  constructor() { }

  ngOnInit(): void {
    $('body').css('overflow : auto')
  }

  public selectOption(option){
    this.selectedOption = option.value;
    if(option.value == 'en_bref'){
      this.currentView = 'en_bref';
    }else{
      this.currentView = 'exigences_re2020';
    }

  }
  public goToPosts(){
    this.selectedOption='en_detail'
    this.currentView='les_fiches_les_plus_impactantes'
  }
  public goToZone(event){
    this.showComposantEvent.emit(event)
  }
  public selectListItem(listItem){
    this.currentView = listItem.value;
    // console.log(listItem);
  }

}
