import { Component, OnInit , ViewChild, NO_ERRORS_SCHEMA} from '@angular/core';
import { ArtisanSignupGeneralComponent } from '../../../artisan/artisan-signup-general/artisan-signup-general.component';
import { ArtisanSignupDetailComponent } from '../../../artisan/artisan-signup-detail/artisan-signup-detail.component';
import { ArtisanSignupFileComponent } from '../../../artisan/artisan-signup-file/artisan-signup-file.component';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
// Services Imports
import { MessageService } from 'primeng/api';
import { ErrorService } from '../../../_services/index';
import { ArtisanService } from '../../../_services/index';
import { AuthService } from '../../../_services/index';

import { HeaderComponent } from 'src/app/all/header/header.component';
import { ArtisanCguModalComponent } from 'src/app/all/artisan-cgu-modal/artisan-cgu-modal.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { ArtisanEditInfoPersoComponent } from '../artisan-edit-info-perso/artisan-edit-info-perso.component';
import { ArtisanEditInfoEntrepriseComponent } from '../artisan-edit-info-entreprise/artisan-edit-info-entreprise.component';
import { ArtisanEditGestionCompteComponent } from '../artisan-edit-gestion-compte/artisan-edit-gestion-compte.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var only:any;
declare var jsonConcat:any;
declare var $:any;

// const documentFields:String = "photos files assuranceFile";
const documentFields:String = "photos files assuranceFile assuranceNumber kbis";
const generalFields:String = "logo name address place typeAgency telAgency emailAgency website siret ca about nafApe artisanImage creationYearAgency nCollaborateur assurances assuranceNumber tvaNumber assuranceNumber";
const detailFields:String = "workAreaMain workAreaOther travaux type programme poste posteSousTraite minBudgetMain minBudgetOther nbProjetRealise budgetMoyen domaineSpe recommandation";


@Component({
    selector: 'app-artisan-edit-profil',
    imports:[
    HeaderComponent,
    ArtisanCguModalComponent,
    SpinnerComponent,
    ArtisanEditInfoPersoComponent,
    ArtisanEditInfoEntrepriseComponent,
    ArtisanEditGestionCompteComponent,
    NgSharedModule
],
    providers: [],
    templateUrl: './artisan-edit-profil.component.html',
    styleUrls: ['./artisan-edit-profil.component.scss'],
    standalone: true,

})
export class ArtisanEditProfilComponent implements OnInit {
  public user : any;
  public document : any;
  public general : any;
  public detail : any;
  public artisan : any = {};userArtisan : any = {};
  public departement : any = [];
  public visibleButton:Boolean = true;
  public loading:Boolean = false;
  public congragulation:Boolean = false;
  public loadingData:Boolean = true;page;
  @ViewChild(ArtisanSignupGeneralComponent) formGeneral: ArtisanSignupGeneralComponent
  @ViewChild(ArtisanSignupDetailComponent) formDetail: ArtisanSignupDetailComponent
  @ViewChild(ArtisanSignupFileComponent) formForm: ArtisanSignupFileComponent

  constructor(private title: Title,
              private messageService: MessageService,
              public authService: AuthService,
              private ErrorService: ErrorService,
              private router: Router,
              private route: ActivatedRoute,
              private artisanService: ArtisanService,) {}

  ngOnInit() {
    this.title.setTitle('BAM - Editer le profil' );
    this.getProfile();
    this.route.queryParams.subscribe(params => {
      if(params['tab']=='perso' || !params['tab']){
        this.setButtonHide();
      }
    })
    this.showProfil('perso');
  }
  // goto
  public goto(element){
    $(`a[href="#${element}"`).click()
  }
  //Scroll to
  public scrollTo(element){
    $('html, body').animate({
        scrollTop: ($(element).offset().top)
    },1500);
  }
  //Get Current profile
  getProfile(){
    let queryArtisanId;
    this.route.queryParams.subscribe(params => {
        if(params['artisanId']){
          queryArtisanId = params['artisanId'];
        }else if(params['tab']){
          // perso
          // genral
          // detail
          // doc
          this.goto(params['tab']);
        }
    });

    let currentUser = JSON.parse(localStorage.getItem("currentUser"))
    this.user = currentUser;
    this.document = {};
    this.general = {};
    this.detail = {};
    this.artisanService.getArtisanProfile(queryArtisanId ? queryArtisanId : currentUser.artisanId)
        .subscribe(
            data => {
              this.userArtisan = data.users[0];
              this.artisan = data;
                this.document = only(data,documentFields);
                this.general = only(data,generalFields);
                this.detail = only(data,detailFields);
                this.departement = data.workAreaMain;
                this.setStripeUser();
                this.loadingData = false;
            },
            error => {
                console.log("error",error)
                this.ErrorService.manageError(error,'/artisan/edit/profil');
            });
  }
  userNewInfo(event){
    this.userArtisan = event.user[0]
    this.artisan.users = event.user
    this.artisan.emailAgency = event.artisant;
  }
  getTheNewEmailUser(event){
    // console.log('event',event)
    this.userArtisan = event[0];
    this.artisan.users = event;
  }
  updateDataArtisan(event){
    this.artisan = event;
  }
  //Save Data
  update(){
    let queryArtisanId;
    this.route.queryParams.subscribe(params => {
        if(params['artisanId']){
          queryArtisanId = params['artisanId'];
        }
    });
    //Set Variables
    this.loading = true;
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let artisanId = currentUser.artisanId;
    let archiNewData = jsonConcat(jsonConcat(this.formGeneral.getFormData(), this.formDetail.getFormData()), this.formForm.getFormData());
    let validGeneral = this.formGeneral.isValid(false);
    let validDetail = this.formDetail.isValid(false);
    let validForm = this.formForm.isValid();
    let isValide = validGeneral && validDetail && validForm;

    //Switch to the rigth onglet
    if(!currentUser.isAdmin){
      if(!validGeneral){
        $('a[href="#genral"]').click();
        setTimeout(()=>{this.formGeneral.scrollToError()},500);
      } else if(!validDetail){
        $('a[href="#detail"]').click();
        setTimeout(()=>{this.formDetail.scrollToError()},500);
      } else if(!validForm){
        $('a[href="#doc"]').click();
      }
    }

    if(isValide || currentUser.isAdmin){
      this.artisanService.update(queryArtisanId ? queryArtisanId : artisanId, archiNewData)
          .subscribe(
              data => {
                  this.loading = false;
                  this.scrollTo("#all-tabs");

                  this.messageService.add({ key: 'toast', severity: 'success', summary: 'Merci', detail: 'Vos modifications ont été enregistrées', life: 5000 });
              },
              error => {
                  this.loading = false;
                  if(error.status == 401){
                    this.authService.logout()
                    this.router.navigate(['login'], { queryParams: { infoBubble: "login-required" }});
                  }else{
                    this.ErrorService.manageError(error,'/artisan/edit/profil');
                  }
              });
    }else{
      this.loading = false;
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Formulaire incomplet', detail: 'Veuillez remplir tous les champs obligatoires', life: 5000 });
    }
  }
  //Set Stripe Code
  public setStripeUser(){
    this.route.queryParams.subscribe((params: Params) => {
      if( params['code'] && params['code'] != '' ){
        let data = {code : params['code'] }
        this.loadingData = true;
        this.artisanService.createStripeUser(this.user.artisanId, data)
            .subscribe(
                data => {
                    this.goto("payement")
                    this.loadingData = false;
                    this.congragulation = true;
                },
                error => {
                    this.loading = false;
                    if(error.status == 401){
                      this.authService.logout()
                      this.router.navigate(['login'], { queryParams: { infoBubble: "login-required" }});
                    }else{
                      this.ErrorService.manageError(error,'/artisan/edit/profil');
                    }
                });
      }
    })
  }
  //Set visible
  public setButtonVisible(){
    this.visibleButton =true;
  }
  public setButtonHide(){
    this.visibleButton =false;
  }
  public docFileUploaded(){
    this.update();
  }
  public showProfil(activePage){
    this.page = activePage;
  }
}
