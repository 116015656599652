<div id="add-new-corps-etat-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="ag-heading-h4 ag-weight-600 text-center no-margin">Ajouter un corps d’état</h4>
        <img src="/assets/docs_error_close_item.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">        
        <h5 class="ag-heading-h5 ag-weight-600">Corps d’état</h5>
        
        <div class="input-container horizontal-items space-between">
          <div class="input-wrapper">
            <input type="text" [(ngModel)]="newCorpsEtatTitle">
            <p class="text-danger no-padding-left" *ngIf="showEtatAlreadyExistsError">Cette option existe déjà dans le questionnaire</p>
          </div>
          <button class="ag-btn-primary-sm" [disabled]="newCorpsEtatTitle == ''" 
            (click)="addNewCorpsEtat()">Soumettre</button>
        </div>
        
        <div class="corps-list-container margin-top-16">
          <span class="corps-item" *ngFor="let item of corpsEtatList; let i = index;">
            {{ item.label }}
            <i class="fa fa-times remove-icon" (click)="removeCorpEtatItem(i)"></i>
          </span>
        </div>

        <div class="submit-btn-container text-center margin-top-30 ">
          <button class="ag-btn-primary-sm" (click)="createNewCustomCorps()">Ajouter</button>
        </div>

      </div>
    </div>
  </div>
</div>
