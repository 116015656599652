import { Component, Input, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { PaymentsService } from 'src/app/_services';
import { NgSharedModule } from '../ngshared.module';
declare var $:any;


@Component({
  standalone:true,
  selector: 'app-architect-three-ds-modal',
  imports:[NgSharedModule],
  templateUrl: './architect-three-ds-modal.component.html',
  styleUrls: ['./architect-three-ds-modal.component.scss']
})
export class ArchitectThreeDSModalComponent implements OnInit {
  @Input() url:String;
  constructor(public paymentsService: PaymentsService) { }

  ngOnInit(): void {
    $('#3ds-stripe').modal('toggle');
  }
  cancelPayement(){
    this.paymentsService.cancelPayement().subscribe(
      (data)=>{
      }
    );
    $('#3ds-stripe').modal('toggle');
  }
}
