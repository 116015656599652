function generateCCTP(cctpJson, level) {
    let spacing = '';
    let res = ""
    //For Children
    for(let i=0; i<(cctpJson.child||[]).length; i++) {
      if(["h2","h3","h4"].indexOf(cctpJson.child[i].tag) != -1){
        res+= `<h3><br><strong>${(generateCCTP(cctpJson.child[i], level) || "").trim()} </strong></h3>`
      }else if((cctpJson.child[i].tag === 'span' && (cctpJson.child[i].attr||{}).class === "num") ||
              (cctpJson.child[i].tag === 'label' && (cctpJson.child[i].attr||{}).selected !== "selected") ||
              (((cctpJson.child[i].attr||{}).class||[]).indexOf("liradio") != -1 && (cctpJson.child[i].attr||{}).selected !== "selected") ||
              (cctpJson.child[i].tag === 'h1' && (cctpJson.child[i].attr||{}).class === "uis-h-title") ) {
        res+= "";
      }else if(["li"].indexOf(cctpJson.child[i].tag) !== -1) {
        let content = (generateCCTP(cctpJson.child[i], level) || "").trim();
        if(content !== ""){
          res+= `<li>&nbsp;&nbsp;${content}</li>`
        }
      }else if(cctpJson.child[i].tag === 'textarea') {
            if(typeof (cctpJson.child[i].attr||{}).value === 'object'){
              res += `${cctpJson.child[i].attr.value.join(' ')}`
            }else{
              res +=`${cctpJson.child[i].attr.value}`
            }
      }else if (["ul"].indexOf(cctpJson.child[i].tag) !== -1){
        let contentDat = (generateCCTP(cctpJson.child[i], level)||"")
        if(contentDat != "" )res+= "<br> <ul>" + contentDat + "</ul>"
      }else if (["header"].indexOf(cctpJson.child[i].tag) !== -1){
        res+= "<strong>" + (generateCCTP(cctpJson.child[i], level)||"") + "</strong>"
      }else if (["p"].indexOf(cctpJson.child[i].tag) !== -1){
        res+= "<br>" + (generateCCTP(cctpJson.child[i], level)||"")
      }
      else if (["a"].indexOf(cctpJson.child[i].tag) !== -1){
        res+= `<a style='color: blue; text-decoration: underline;' href=${cctpJson.child[i].attr.href.replace('.html','')} target=_blank'>` + (generateCCTP(cctpJson.child[i], level)||"")+ "</a>"
      }
      else{
        res+= (generateCCTP(cctpJson.child[i], level)||"")
      }
    }
    //contente
    if((cctpJson.text||"").trim() !== '') {
        res+= `${spacing} ${cctpJson.text}`
    }
    return res;
}

export default generateCCTP;
