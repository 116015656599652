import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/index';
import { Location } from '@angular/common';



@Injectable({
  providedIn: 'root'
})
export class NewAccountArchitectRouteGuard  {
  constructor(private router: Router,private authService: AuthService,private location: Location) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if ((this.authService.isArchitect() || this.authService.isAdmin() || this.authService.isCollaborateur()) && this.authService.isNewAccount()) {
          return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/architect/my-consultations']);
        return false;
  }
}
