import { ChangeDetectorRef, Component, OnInit, ViewChild, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute , Params,Router} from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';

// services
import { AcvService, ErrorService } from 'src/app/_services/index'
import { AuthService, relatedFichesService, DpgfPostesService } from 'src/app/_services';
import { NgSharedModule } from 'src/app/all/ngshared.module';

import { Subscription } from 'rxjs';

// Services Imports
import { CeProjectNavComponent } from 'src/app/dpgf/global/ce-project-nav/ce-project-nav.component';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { AcvMainNavbarComponent } from 'src/app/architect/acv/acv-main-navbar/acv-main-navbar.component';
import { AcvProjectDescriptionPageComponent } from 'src/app/architect/acv/acv-project-description-page/acv-project-description-page.component';
import { AcvProjectComposantPageComponent } from 'src/app/architect/acv/acv-project-composant-page/acv-project-composant-page.component';
import { AcvProjectEauPageComponent } from 'src/app/architect/acv/acv-project-eau-page/acv-project-eau-page.component';
import { AcvProjectEnergiePageComponent } from 'src/app/architect/acv/acv-project-energie-page/acv-project-energie-page.component';
import { AcvProjectChantierPageComponent } from 'src/app/architect/acv/acv-project-chantier-page/acv-project-chantier-page.component';
import { AcvResultsPageComponent } from 'src/app/architect/acv/acv-results-page/acv-results-page.component';
import { NeedSubscriptionPopup2Component } from 'src/app/architect/need-subscription-popup2/need-subscription-popup2.component';
import { AcvWelcomeScreenComponent } from 'src/app/architect/acv/acv-welcome-screen/acv-welcome-screen.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { environment } from 'src/environments/environment';
const _ = require('lodash');

@Component({
    selector: 'app-acv-page',
    imports:[
      CeProjectNavComponent,
      HeaderComponent,
      AcvMainNavbarComponent,
      AcvProjectDescriptionPageComponent,
      AcvProjectComposantPageComponent,
      AcvProjectEauPageComponent,
      AcvProjectEnergiePageComponent,
      AcvProjectChantierPageComponent,
      AcvResultsPageComponent,
      NeedSubscriptionPopup2Component,
      AcvWelcomeScreenComponent,
      SpinnerComponent,
      NgSharedModule
    ],
    templateUrl: './acv-page.component.html',
    styleUrls: ['./acv-page.component.scss'],
    standalone: true,
})
export class AcvPageComponent implements OnInit {
  // public selectedTab:string = 'chantier';
  public showWelcomeScreen: boolean = false;
  public selectedWelcomeScreen: string = 'screen_1';

  public selectedTab:string = 'description_du_project';
  public tabsData: any = {
    'projectDescription': {},
    'projectComposant': {},
    'projectEnergie': {},
    'projectEau': {},
    'projectChantier': {},
  };
  public tabEventName={
    'composant':'Access Composant Tab',
    'energie':'Access Energie Tab',
    'eau':'Access Eau Tab',
    'chantier':'Access Chantier Tab',
    'description_du_project':'Access Description Tab',
    'results':'Access Résultats tab'
  }
  public acvData={};
  public dpgf;
  public globalTabsState:any = {};

  public loadingData:boolean = false;
  public dpgfId:string;
  public projectId:string;projectLicence;
  public projectTitle:string = "";isProjectDemo:Boolean=false;
  public RSETEnergy={};
  private subscriptions: Array<Subscription> = [];
  @ViewChild(AcvMainNavbarComponent) acvNavComponent: AcvMainNavbarComponent;
  selectedZone: any;

  constructor(
    private route: ActivatedRoute,
    private ErrorService: ErrorService,
    private acvService: AcvService,
    private dpgfPostesService: DpgfPostesService,
    private cd: ChangeDetectorRef,
    private relatedFichesService :relatedFichesService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.noMultiAccountsAllowed();
    this.getCurrentRoute();
    this.getIniesPosts();
    this.showWelcomeScreen=this.projectLicence!='none' ||(this.authService.premuimFeatures[this.authService.licence]||{}).carbone||false
    if(!environment.onPremise)window.analytics.page(this.tabEventName['description_du_project']);
  }
  private getCurrentRoute(){
    this.loadingData = true;
    this.route.params.forEach((params: Params) => {
      if (params['ceProjectId']) {
        this.projectId = params['ceProjectId'];
        this.loadDpgf(this.projectId);
      }
    });
  }

  public selectTab(event){
    if(event=='results') {
      this.loadDpgf(this.projectId,event)
      this.getIniesPosts()
    }
    else this.selectedTab = event;
    if(!environment.onPremise)window.analytics.page(this.tabEventName[event]);
  }
  public dataChanged(tab, json){
    let key = Object.keys(json)[0];
    let value = Object.values(json)[0];

    if(this.tabsData[tab]){
      this.tabsData[tab] = json;
    }
    let updateField = {
      [`${tab}.${key}`] : value
    };

    /// Update Global State
    _.set(this.globalTabsState, `${tab}.${key}`, value);
    _.set(this.acvData, `${tab}.${key}`, value);

    // console.log('updateField : ', updateField);

    /// Update the Acv Inputs
    this.updateAcv(updateField);
  }
  public setNewAcv(newAcv){
    /// Update Global State
    this.globalTabsState=newAcv
    this.acvData=newAcv
  }

  private loadDpgf(ceProjectId,tab=''){
    this.loadingData = true;
    this.subscriptions.push(
      this.acvService.getAcvAndDpgf(ceProjectId).subscribe((data) => {
        this.projectId = data['ceProject']['_id'];
        this.isProjectDemo= data['ceProject']['demo'];
        this.projectLicence = data['ceProject']['licence'];
        if(data['ceProject']['dpgf']){
          this.dpgfId = data['ceProject']['dpgf'];
        }
        this.projectTitle = data['ceProject']['title'];
        this.acvData = {...this.acvData,...data['acvproject']};
        if(!this.acvData['projectDescription'])this.acvData['projectDescription']={'Bâtiment':{'Zone':{}},'projet':{}}
        this.loadingData = false;
        // parse energy data
        this.parseEnergy(data)
        if(tab) this.selectedTab=tab
      })
    )
  }
  parseEnergy(data){
    let RSET=data['acvproject'].projectRSET;
    let batiments=[].concat(_.get(RSET,'Datas_Comp.batiment_collection.batiment',[]))
    for(let i = 0; i < batiments.length; i++) {
      let zones=[].concat(_.get(batiments[i],'zone_collection.zone',[]))
      for(let j = 0; j < zones.length; j++) {
        this.RSETEnergy[batiments[i].Name]=this.RSETEnergy[batiments[i].Name]||{};
        this.RSETEnergy[batiments[i].Name][zones[j].Name]=zones[j];
      }
      this.RSETEnergy[batiments[i].Name]['typeReseau']=parseInt(batiments[i]['Type_Reseau']||'')
    }
  }
  private updateAcv(updateField){
    this.subscriptions.push(
      this.acvService.updateAcvProject(this.projectId, updateField).subscribe((data) => {
        this.acvData['acvproject'] = data.data;
      })
    );
  }

  clickRSETinput(){
    this.acvNavComponent.clickRSETinput();
  }
  getIniesPosts(){
    this.relatedFichesService.listAll('','','',this.projectId,true).subscribe(
      (data)=>{
        this.acvData['posts']=data
        this.parseData()
        // console.log('acv',this.acvData);
      }
    )
  }
  setResult(result){
    this.getIniesPosts();
    this.loadDpgf(this.projectId)
  }
  parseData(){
    let postIds=this.acvData['posts'].map(post=>post.idFicheBase)
    this.relatedFichesService.getIniesPosts(postIds).subscribe(
      (data)=>{
        let indicateurs={};
        let CO2DynamicTotalCDV= _.get(this.acvData,'projectResult.CO2DynamicImpacts.CO2DynamicTotalCDV',0)
        // Autres indicateurs environnementaux réglementaires
        for (let i = 0; i < this.acvData['posts'].length; i++) {
          let id=this.acvData['posts'][i]['idFicheBase']
          let onePost=data.filter(post=>post.idFicheBase==id);
          ((onePost[0]||{})['indicateurs']||[]).forEach(element => {
            let phases=(element['phases']||[]);
            let phase7=phases.find((phases)=>phases.idPhase==7)
            // phase 7 multiply by quantity of post
            indicateurs[element['idIndicateur']]=phase7.quantite*this.acvData['posts'][i]['quantity']+indicateurs[element['idIndicateur']]||0
          });
          indicateurs[19]=(this.acvData['posts'][i]['totalImpact']*this.acvData['posts'][i]['quantity']+indicateurs[19]||0)/(this.acvData['posts'][i].quantiteUF||1)
        }
        this.acvData['ind']=[{
            name:"Impacts environnementaux",data:{
              "Réchauffement climatique (kg CO2 eq.)":CO2DynamicTotalCDV,
              "Appauvrissement de la couche d'ozone (kg CFC-11 eq.)":indicateurs[20],
              "Acidification des sols et de l'eau (kg SO2 eq.)":indicateurs[21],
              "Eutrophisation (kg (PO4)3- eq.)":indicateurs[22],
              "Formation d'ozone photochimique (kg C2H4 eq.)":indicateurs[23],
              "Epuisement des ressources abiotiques - éléments (kg Sb eq.)":indicateurs[24],
              "Epuisement des ressources abiotiques - combustibles fossiles (MJ)":indicateurs[25],
              "Pollution de l'air (m³)":indicateurs[26],
              "Pollution de l'eau (m³)":indicateurs[27]
            }
          },{
            name:"Consommation des ressources",data:{
              "Utilisation de l'énergie primaire renouvelable, à l'exclusion des ressources d'énergie primaire ... (MJ)":indicateurs[28],
              "Utilisation des ressources d'énergie primaire renouvelables utilisées en tant que matières premières (MJ)":indicateurs[29],
              "Utilisation totale des ressources d'énergie primaire renouvelables ... (MJ)":indicateurs[30],
              "Utilisation de l'énergie primaire non renouvelable, à l'exclusion ... (MJ)":indicateurs[31],
              "Utilisation des ressources d'énergie primaire non renouvelables utilisées en tant que matières premières (MJ)":indicateurs[32],
              "Utilisation totale des ressources d'énergie primaire non renouvelables ... (MJ)":indicateurs[33],
              "Utilisation de matière secondaire (kg)":indicateurs[34],
              "Utilisation de combustibles secondaires renouvelables (MJ)":indicateurs[35],
              "Utilisation de combustibles secondaires non renouvelables (MJ)":indicateurs[36],
              "Utilisation nette d'eau douce (m³)":indicateurs[37],
            }
          },{
            name:"Déchets et flux sortants",data:{
            "Déchets dangereux éliminés (kg)":indicateurs[38],
            "Déchets non dangereux éliminés (kg)":indicateurs[39],
            "Déchets radioactifs éliminés (kg)":indicateurs[40],
            "Composants destinés à la réutilisation (kg)":indicateurs[41],
            "Matériaux destinés au recyclage (kg)":indicateurs[42],
            "Matériaux destinés à la récupération d'énergie (kg)":indicateurs[43],
            "Energie fournie à l'extérieur (MJ)":indicateurs[44],
          }}]
      }
    )
  }
  goToZone(zoneName){
    $('.horizontal-items li a').removeClass('selected-tab');
    $('#composant-div').addClass('selected-tab');

    this.selectedTab="composant"
    this.selectedZone=zoneName
  }
  private noMultiAccountsAllowed(){
    this.subscriptions.push(
      this.authService.noMultiAccountsAllowed()
        .subscribe(
          data => {
            
          }  
        )
    );
  }
  public ngOnDestroy(): void {
    $('body').css('overflow','auto')
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }

}
