<div class="row all" [ngClass]="{'back-img' : (waitingPass$|async)}">
<!-- <app-header></app-header> -->
<app-artisan-cgu-modal></app-artisan-cgu-modal>
  <div  *ngIf="(waitingPass$|async) && !(loadingData$ |async)" class="floating-card hello-artisan">
    <h1 class="center">Bienvenue sur votre outil de chiffrage</h1>
    <div class="code-input">
      <p-inputMask [(ngModel)]="code" mask=" 9 9 9 9 " placeholder=" _ _ _ _ " styleClass="big-letter" ></p-inputMask>
    </div>
    <button class="btn-primary-color" (click)="getDPGF()">
      <div>Voir la version</div>
    </button>
  </div>

  <div class="spinner-center" *ngIf="!(waitingPass$|async) && (loadingData$ |async)">
    <app-spinner [size]="'big'"></app-spinner>
  </div>

  <div [@appear]='state' class="toast">
    <i class="fa fa-floppy-o" aria-hidden="true"></i> Enregistré
  </div>

  <div *ngIf="!(loadingData$ |async) && !(waitingPass$|async)" id="tool-bar" class="row" [ngClass]='{sticky : isSticky}'>
    <div class="bonjour">Bonjour {{(dpgf$|async)?.artisanName}}</div>
    <div class="read-only-mode">Version antécédente ! Disponible en lecture seule</div>
    <div class="version" >
      V.{{(dpgf$|async)?.pricingVersion}}.0
    </div>

    <!-- Devis List Dropdown -->
    <div class="down-devis-cont">
        <button (click)="showDevisDropDown = !showDevisDropDown">Télécharger le devis
          <span class="arr-ic">
            <i class="fa fa-sort-down"></i>
          </span>
            <div class="drop-items" [ngClass]="{'unvisible': !showDevisDropDown}">
              <div class="padding-5 cursor" class="pdf-item" (click)="downloadCurrentPdfVersion()">
                Télécharger en PDF
              </div>
              <div class="padding-5 cursor" class="excel-item" (click)="downloadCurrentExcelVersion()">
                Télécharger en Excel
              </div>
            </div>
        </button>
    </div>
    <!-- END Pdf List Dropdown -->

  </div>



  <div class="container full-width" *ngIf="!(loadingData$ |async) && (dpgf$|async)">
    <div class="row">
      <div class="col-md-12" >

        <div class="row floating-card" style="margin-top: 70px;">
          <div class="col-md-12">
            <app-head-dpgf> </app-head-dpgf>
          </div>
        </div>
        <div class="row floating-card">
          <div class="col-md-12">
            <span class="total">
              Total HT {{total$ |async | number}}€ <br>
              <span class="sub-total">Total TTC {{totalTTC$ |async | number}} €</span>
            </span>
          </div>
        </div>
        <app-dpgf-folders mode="artisan" [dpgfPricingId]="dpgfPricingId" [code]="code"></app-dpgf-folders>
        <div class="row">
          <app-table-dpgf mode="view" [dpgf]="(dpgf$|async)" ></app-table-dpgf>
        </div>


        <div id="send-to-architect" class="row floating-card">
          <!--********************Sommaire Artisan ***********************-->
          <h1>Récapitulatif</h1>
          <div  *ngFor="let lot of (dpgf$ |async)?.data,let loti = index" >
            <div (click)="scrollTo(lot.id)" class="recap-pill" >
              <img *ngIf="(lot.icon||'') != ''" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" alt="" height="15">
              <span>{{loti+1}} - {{lot.title}}</span>
              <span class="total-recap">{{lot.total}} € HT</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-push-6 col-md-6 recap-total">
              Total HT
              <span class="total-recap">{{total$ |async | number}} € HT</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-push-6 col-md-6 recap-total">
              Total TTC
              <span class="total-recap">{{totalTTC$ |async | number}} € TTC</span>
            </div>
          </div>
          <hr>

        </div>
      </div>

    </div>

  </div>

</div>
