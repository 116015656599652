<div id="team-modifier">
    <div class="row title-row">
        <div class="col-md-7">
            AGENCE
        </div>
        <div class="col-md-3">
            PRIX
        </div>
        <div class="col-md-2">
            INDICE
        </div>
    </div>
    <div class="panel-wrapper" *ngIf="pricings.length">
      <div *ngIf="filterPricings().length == 0" class="not-estimated">
        Aucune entreprise n'a chiffré ce lot
      </div>
        <ng-container *ngFor="let pricing of filterPricings(); let i = index">
            <div class="row artisan" onselectstart="return false"
                [class.activeArtisan]="selectedArtisan === pricing.artisanName">
                <div class="col-md-1 ">
                    <h2 class="panel-title">
                        <a role="button" data-toggle="collapse" data-parent="#accordion"
                            class="collapse-link"
                            [href]="'#' + pricing.idpricing" aria-expanded="true"
                            [attr.aria-controls]="pricing.idpricing"
                            (click)="setActiveCollapse(pricing.idpricing)">
                            <i class="fa" aria-hidden="true" [ngClass]="{'fa-chevron-down': activeCollapse.includes(pricing.idpricing), 'fa-chevron-right': !activeCollapse.includes(pricing.idpricing)}" ></i>
                        </a>
                    </h2>
                </div>
                <div class="col-md-6 align-vertical" (click)="teamClick(pricing)">
                    <span class="check">
                    </span>
                    &nbsp;&nbsp;
                    <span class="artisanName" [style.background]="colors[pricing.artisanName]">
                        {{ pricing.artisanName }}
                    </span>
                </div>
                <div class="col-md-3">
                    {{ pricing.total | number:'1.0-0':'fr' | priceUnite:'€ HT'}}
                </div>
                <div class="col-md-2 text-center small-gray">
                    ind.{{ pricing.currentVersion | pricingIndex }}
                </div>
            </div>
            <div class="row">
                <div [id]="pricing.idpricing" class="panel-collapse collapse" role="tabpanel"
                    aria-labelledby="headingOne">
                    <div class="posts-list">
                        <div class="row post">
                            <div class="col-md-9">
                               <h5>Posts</h5>
                            </div>
                            <div class="col-md-3">
                               <h5>Prix</h5>
                            </div>
                        </div>
                        <!-- <div [id]="'loader-'+pricing._idLot" class="row post hide-loader" *ngIf="loadingPosts"> -->
                        <div [id]="'loader-'+pricing.idpricing" class="row post posts-loader hide-loader">
                          <app-spinner></app-spinner>
                        </div>
                        <!-- <div class="ng-container" *ngIf="!loadingPosts"> -->
                        <div class="ng-container">
                          <div class="row post" *ngFor="let post of pricing.posts;let i = index">
                             <div class="col-md-9 designation">
                                 <b>{{pricing.loti}}.{{post.sousLoti}}.{{ i + 1 }}-</b> {{ post.designation.length === 0 ? "(Aucun texte)" : post.designation}}
                             </div>
                             <div class="col-md-3">
                                 {{ post.price | formatPrice | priceUnite:'€ HT' }}
                             </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <i class="fa fa-times" id="close" (click)="onClose()" aria-hidden="true"></i>
</div>
