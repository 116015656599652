import { Component, OnInit, Input, ChangeDetectionStrategy, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { ChantierModule } from '../chantier.module';

// comps
import { TableLotFactureComponent } from '../table-lot-facture/table-lot-facture.component';


declare var $;
@Component({
    selector: 'app-table-facture',
    imports:[
      ChantierModule,
      NgSharedModule,
      TableLotFactureComponent
    ],
    templateUrl: './table-facture.component.html',
    styleUrls: ['./table-facture.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,

})
export class TableFactureComponent implements OnInit {
  
  @Input() lots;
  @Input()mode:string;
  @Input()isFirst:boolean;
  @Input()status:string;
  @Input()response:string
  
  constructor() { }

  ngOnInit() { }

  public scrollTo(element) {
    $('html, body').animate({
      scrollTop: ($("#" +element).offset().top - 60)
    });
  }
}
