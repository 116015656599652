<!-- <div class="child" *ngIf="childLot.children">
    <div *ngIf="level == 1" class="nav-header">
        <button class="return" (click)="returnToList()">
            <i class="fa fa-chevron-left"></i>
        </button>
        <h3 class="lot-title">{{childLot.title}}</h3>
    </div>
    <div *ngFor="let child of childLot.children; let i = index">
        <div class="one-dce">
            <i *ngIf="!child.open && child.children" (click)="openChildren(i)" class="fa fa-chevron-right" aria-hidden="true"></i>
            <i *ngIf="child.open && child.children" (click)="child.open = false" class="fa fa-chevron-down" aria-hidden="true"></i>
            <i *ngIf="!child.open && child.children" class="fa fa-folder" aria-hidden="true"></i>
            <i *ngIf="child.open && child.children" class="fa fa-folder-open" aria-hidden="true"></i>
            <span *ngIf="!child.children">
              <input class="pointer" [class.disabled]="((!licence.cstbGeneralite || !child.generalite) && !licence.cstbPoste) && !(licence.cstbGeneralite && showForPro)" type="checkbox" *ngIf="manySelect" [ngModel]="child.isSelected" (change)="changeCheck(child,i)">
              <input class="pointer" [class.disabled]="((!licence.cstbGeneralite || !child.generalite) && !licence.cstbPoste) && !(licence.cstbGeneralite && showForPro)" type="checkbox" *ngIf="!manySelect"  (change)="selectOne(child,i)">
            </span>
            <span *ngIf="manySelect" class="post-title" [class.generalite]="child.generalite" [class.disabled]="((!licence.cstbGeneralite || !child.generalite) && !licence.cstbPoste) && !(licence.cstbGeneralite && showForPro)" (click)='changeCheck(child,i)'> {{child.title}} </span>
            <span *ngIf="!manySelect" class="post-title" [class.generalite]="child.generalite" [class.disabled]="((!licence.cstbGeneralite || !child.generalite) && !licence.cstbPoste) && !(licence.cstbGeneralite && showForPro)" (click)='selectOne(child,i)'> {{child.title}} </span>
            <div *ngIf="child.open">
                <app-cstb-navigation-lot [manySelect]="manySelect" (childSelectedEvent)="emitPostes($event)" [childLot]="activeChild" [licence]="licence" [showForPro]="showForPro"></app-cstb-navigation-lot>
            </div>
        </div>
    </div>
</div> -->
<div class="child" *ngIf="childLot.children">
    <div *ngIf="level == 1" class="nav-header">
        <img class="margin-right-8 pointer img-icon" src="/assets/arrow-back.svg" (click)="returnToList()">
        <img class="margin-right-8 pointer img-icon margin-left-8" src="/assets/folder-open-full.svg">
        <span class="lot-title">{{childLot.title}}</span>
    </div>
    <div *ngFor="let child of childLot.children; let i = index">
        <div class="one-dce">
        <img class="margin-right-8 pointer img-icon margin-bottom-2half" *ngIf="!child.open && child.children" src="/assets/chevron-right.svg" (click)="openChildren(i)">
        <img class="margin-right-8 pointer img-icon margin-bottom-2half" *ngIf="child.open && child.children" (click)="child.open = false" src="/assets/chevron-down.svg">
        <img class="margin-right-8 pointer img-icon margin-bottom-2half" *ngIf="!child.open && child.children" src="/assets/blue-folder-icone.svg">
        <img class="margin-right-8 pointer img-icon margin-bottom-2half" *ngIf="child.open && child.children" src="/assets/folder-open-full.svg">
           
            <span *ngIf="!child.children" style="padding-right: 12px;">
                <label class="form-control">
                    <input class="pointer" [class.disabled]="((!licence.cstbGeneralite || !child.generalite) && !licence.cstbPoste) && !(licence.cstbGeneralite && showForPro)" type="checkbox" *ngIf="manySelect" [ngModel]="child.isSelected" (change)="changeCheck(child,i)">
                    <input class="pointer" [class.disabled]="((!licence.cstbGeneralite || !child.generalite) && !licence.cstbPoste) && !(licence.cstbGeneralite && showForPro)" type="checkbox" *ngIf="!manySelect"  (change)="selectOne(child,i)">
                </label>
            </span>
            <span *ngIf="manySelect" class="post-title" [class.generalite]="child.generalite" [class.disabled]="((!licence.cstbGeneralite || !child.generalite) && !licence.cstbPoste) && !(licence.cstbGeneralite && showForPro)" (click)='changeCheck(child,i)'> {{child.title}} </span>
            <span *ngIf="!manySelect" class="post-title" [class.generalite]="child.generalite" [class.disabled]="((!licence.cstbGeneralite || !child.generalite) && !licence.cstbPoste) && !(licence.cstbGeneralite && showForPro)" (click)='selectOne(child,i)'> {{child.title}} </span>
            <div *ngIf="child.open">
                <app-cstb-navigation-lot [manySelect]="manySelect" (childSelectedEvent)="emitPostes($event)" [childLot]="activeChild" [licence]="licence" [showForPro]="showForPro"></app-cstb-navigation-lot>
            </div>
        </div>
    </div>
</div>
