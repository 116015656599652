import { Component, OnInit,ViewChild, HostListener, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute , Params,Router} from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
import { trigger,state,style,transition,animate,keyframes } from '@angular/animations';
import { Store , select} from '@ngrx/store';
import {Promise}  from 'bluebird';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import * as actions from 'src/app/dpgf/dce-edition/_store/dpgf.actions';
import * as fromSelectors from 'src/app/dpgf/dce-edition/_store';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';

// Services Imports
import { MessageService } from 'primeng/api';
import { ErrorService } from '../../../_services/index';
import { DpgfPostesService } from '../../../_services/index';
import { PrintPdf } from '../../../_interfaces/printPdf.interface';
import { ConfirmationService } from 'primeng/api';

import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { ArtisanCguModalComponent } from 'src/app/all/artisan-cgu-modal/artisan-cgu-modal.component';
import { HeadDpgfComponent } from 'src/app/dpgf/dce-edition/head-dpgf/head-dpgf.component';
import { DpgfFoldersComponent } from 'src/app/dpgf/dce-edition/dpgf-folders/dpgf-folders.component';
import { TableDpgfComponent } from 'src/app/dpgf/dce-edition/table-dpgf/table-dpgf.component';
import {NgSharedModule} from 'src/app/all/ngshared.module'
declare var introJs: any;
declare var $: any;
@Component({
    selector: 'app-artisan-version-dpgf',
    templateUrl: './artisan-version-dpgf.component.html',
    imports:[
    SpinnerComponent,
    ArtisanCguModalComponent,
    HeadDpgfComponent,
    DpgfFoldersComponent,
    TableDpgfComponent,
    NgSharedModule
],
    providers: [],
    styleUrls: ['./artisan-version-dpgf.component.scss'],
    animations: [
        trigger('appear', [
            state('hide', style({
                top: '-50px',
            })),
            state('show', style({
                top: '10px',
            })),
            transition('hide => show', animate('2000ms ease-in')),
            transition('show => hide', animate('2000ms ease-in')),
        ]),
    ],
    standalone: true,
    
})

export class ArtisanVersionDpgfComponent implements OnInit {
  public dpgfPricingId:string;
  public archiMessage:string;
  public code:string="";
  public saving:boolean = false;
  public isSticky:boolean = true;
  public planDocument:boolean = false;
  public loadingFiles:boolean = false;
  public respecter:boolean = false;
  public assurer:boolean = false;
  public errorSend:boolean = false;
  public downloadingDoc:boolean = false;
  public showDevisDropDown:boolean = false;
  public waitingPass$;loadingData$;finishing$;done$;
  public dpgf$;
  public total$ ;
  public totalTTC$ ;
  public nbLines$;nbLineDone$
  public state: string = 'hide';
  constructor(private title: Title,
              private router: Router,
              private DpgfPostesService: DpgfPostesService,
              private route: ActivatedRoute,
              private store: Store,
              private messageService: MessageService,
              private ErrorService: ErrorService,
              private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.title.setTitle('Aglo - Chiffrer mon DPGF' );
    let code = this.route.snapshot.queryParams.code;
    this.code = (code||"").replace(/ /g,'');
    this.store.dispatch(actions.setType("pricing"));
    this.store.dispatch(actions.setAny( {name  : "mode" , value : "artisan"}));
    this.loadingData$ = this.store.pipe(select(fromSelectors.getAny , {name : "loadingData"} ))
    this.finishing$ = this.store.pipe(select(fromSelectors.getAny , {name : "finishing"} ))
    this.done$ = this.store.pipe(select(fromSelectors.getAny , {name : "pricingDone"} ))
    this.dpgf$ = this.store.pipe(select(fromSelectors.getDpgf))
    this.waitingPass$ = this.store.pipe(select(fromSelectors.getAny , {name : "waitingPass"}))
    this.total$ = this.store.pipe(select(fromSelectors.getTotal, { name: "total" }))
    this.totalTTC$ = this.store.pipe(select(fromSelectors.getTotal, { name: "totalttc" }))
    this.nbLines$ = this.store.pipe(select(fromSelectors.getTotal, { name: "nbLines" }))
    this.nbLineDone$ = this.store.pipe(select(fromSelectors.getTotal, { name: "nbLineDone" }))
    if(this.code != "" )this.getDPGF();
  }
  public getDPGF(){
    this.route.params.forEach((params: Params) => {
      this.dpgfPricingId = params['dpgfPricingId'];
      if (params['dpgfPricingId'] && this.code.replace(/ /g,'') != "") {
        this.store.dispatch(actions.setAny( {name  : "loadingData" , value : true}));
        this.store.dispatch(actions.setAny( {name  : "code" , value : this.code.replace(/ /g,'')}));
        this.store.dispatch(actions.loadDpgfPricing( {dpgfId : this.dpgfPricingId , code : this.code.replace(/ /g,'')}));
      }
    })
  }
  public getPdf(){
    this.downloadingDoc = true;
    this.DpgfPostesService.downloadDevisPdf(this.dpgfPricingId, {}, "", {}, this.code)
      .subscribe(
          (data) => {
              saveAs(data.data.data, `${data.data.pdfName}.pdf`);
              this.downloadingDoc = false;
          },
          err => {
            this.downloadingDoc = false;
          },
      );
  }
  public downloadCurrentPdfVersion(){
    let dpgfId;
    this.dpgf$.subscribe(val => dpgfId = val.dpgf);
    this.downloadingDoc = true;
    this.DpgfPostesService.downloadCCTPPricingdf(this.dpgfPricingId, [], this.code,false)
      .subscribe(
          (data) => {
              saveAs(data.data.data, `${data.data.pdfName}.pdf`);
              this.downloadingDoc = false;
          },
          err => {
            this.downloadingDoc = false;
          },
      );
  }
  public downloadCurrentExcelVersion(){
    this.downloadingDoc = true;
    let fileName;
    let version;
    this.dpgf$.subscribe(val => {
      version = this.getIncide(val.pricingVersion);
      fileName = `Devis - ${val.nomPrenom} - Ind-${version}`;
    });
    this.DpgfPostesService.downloadArtisanChiffrageExcel(this.dpgfPricingId, [], this.code)
      .subscribe(
          (data) => {
            var zip = new JSZip();
            zip.loadAsync(data.data).then((data) => {
              return zip.generateAsync({type:"blob"})
            }).then(function(content) {
              saveAs(content, `${fileName}.xlsx`);
            });
            this.downloadingDoc =false;
          },
          err => {
            this.downloadingDoc = false;
          },
      );
  }
  public getIncide(number){
    var n = number-1;
    var ordA = 'a'.charCodeAt(0);
    var ordZ = 'z'.charCodeAt(0);
    var len = ordZ - ordA + 1;
    var s = "";
    while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s.toUpperCase();
  }



  public scrollTo(element){
    $('html, body').animate({
      scrollTop: ($("#" +element).offset().top - 60)
    });
  }
}
