import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild, ElementRef, NO_ERRORS_SCHEMA } from '@angular/core';

// RxJs
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone: true,
    selector: 'app-booster-profile-information-form',
    imports:[
      NgSharedModule, 
    ],
    templateUrl: './booster-profile-information-form.component.html',
    styleUrls: ['./booster-profile-information-form.component.scss'],
})
export class BoosterProfileInformationFormComponent implements OnInit, AfterViewInit {

  @Input() informationData: any = {
    type: [],
    travaux: [],
    programme: [],
    creationYearAgency: "",
    ca: "", // Chiffrge d'Affaire
  };
  @Output() saveInformation: EventEmitter<any> = new EventEmitter<any>();
  @Output() formIsValid: EventEmitter<any> = new EventEmitter<any>();
  
  @ViewChild('creationYearAgency') creationYearAgency: ElementRef;
  @ViewChild('ca') ca: ElementRef; // chiffrage affaire

  //Type
  public typeList = [
    {value:'contractant-general',name:"Contractant général"},
    {value:'entreprise-generale',name:"Entreprise générale"},
    {value:'bureau-d-etude',name:"Bureau d'études"},
    {value:'artisan-entreprise-specialisee',name:"Artisan / Entreprise spécialisée"},
    {value:'fabriquant-revendeur-materiaux',name:"Fabriquant / Revendeur matériaux"},
  ];
  //travaux
  public travauxList:any = [
    { value: "renovation", name: "Rénovation"},
    { value: "extension", name: "Extension"},
    { value: "surelevation", name: "Surélévation"},
    { value: "construction-neuve", name: "Construction Neuve"},
  ];
  //Programme
  public programmesList:any = [
    { value: "maison", name: "Maison individuelle"}, // DONE
    { value: "appartement", name: "Appartement"}, // DonE
    { value: "bureaux-tertiaire", name: "Bureaux"}, // DONE
    { value: "immeuble", name: "Logements collectifs"},
    { value: "equipements-publics", name: "Équipements publics"}, // DONE
    { value: "batiment-industriel-agricole", name: "Bâtiments industriels/Agricoles"}, // DONE
    { value: "boutique-conceptstore", name: "Boutique/Concept store"}, // DONE
    { value: "cafe-hotel-restaurant", name: "Café/Hôtel/Restaurant"}, // DONE
  ];

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.emitFormIsValid();
    }, 500);
  }

  ngAfterViewInit(): void {
    this.doubounceInput("ca", this.ca);
    this.doubounceInput("creationYearAgency", this.creationYearAgency);
  }
  private doubounceInput(key, input: ElementRef){
    fromEvent(input.nativeElement,'keyup')
      .pipe(
          filter(Boolean),
          debounceTime(1000),
          distinctUntilChanged(),
          tap((event:KeyboardEvent) => {
            let value = input.nativeElement.value;
            
            console.log(key, value);

            if(this.informationData[key]){
              this.informationData[key] = +value; /// plus sign is used to cast string to number
                            
              // save data
              this.emitFormData();

              // emit is form Valid
              this.emitFormIsValid();
            }
          })
      )
      .subscribe();
  }

  // if isMulti is true push to the array, else set that value 
  public addToFormData(field, value){
    if(!Object.keys(this.informationData).includes(field)) return;
    
    // if not already clicked add, else remove
    this.addOrRemoveFromArray(this.informationData[field], value);

    // save data on each checkbox
    this.emitFormData();
  }
  private addOrRemoveFromArray(array, value){
    /// if already exists & clicked remove it
    if(array.includes(value)){
      let itemIndex = array.indexOf(value);
      array = array.splice(itemIndex, 1);
    // add item to array (if not exists)
    }else{
      array.push(value);
    }
  }

  public isValid(){
    let isValid = false;

    let typeIsFilled = this.arrayHasValues(this.informationData.type);
    let travauxIsFilled = this.arrayHasValues(this.informationData.travaux);
    let programmeIsFilled = this.arrayHasValues(this.informationData.programme);
    let creationYearAgencyIsFilled = this.stringIsEmpty(this.informationData.creationYearAgency);
    let caIsFilled = this.stringIsEmpty(this.informationData.ca);        
    
    let validFields = [typeIsFilled, travauxIsFilled, programmeIsFilled, creationYearAgencyIsFilled, caIsFilled];

    isValid = validFields.includes(false) ? false : true;

    return isValid;
  }
  
  private emitFormData(){
    this.saveInformation.emit(this.informationData);

    ///
    this.emitFormIsValid();
  }
  
  private arrayHasValues(array){
    return array.length ? true : false;
  }
  private stringIsEmpty(str){
    // if number cast to string
    if(typeof str === "number"){
      str = str.toString(); 
    }

    if(str && str.trim().length){
      return true;
    }else{
      return false;
    }
  }

  public dataChanged(data){
    this.emitFormIsValid();
  }

  private emitFormIsValid(){
    let formIsValid = this.isValid();
    
    this.formIsValid.emit(formIsValid);
  }
  public getFormData(){
    return this.informationData;
  }

}
